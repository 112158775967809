import { Button, Form, Input, Modal, notification, Select } from "antd";
import { useContext, useState } from "react";
import { Flex, Div } from "styles/layout";
import { Text } from "styles/typography";
import appContext from "contexts/appContext";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";

interface DownloadCompanyProps {
    onCancel: () => void;
    downloadCompanyLimit: number;
    city: string[];
    state: string[];
    country: string[];
    industry: string[];
    subIndustry: string[];
    employees: string[];
    revenue: string[];
    companyName: string[];
    domains: string[];
}

const DownloadCompanyDialog = ({
    onCancel,
    downloadCompanyLimit,
    city,
    state,
    country,
    industry,
    subIndustry,
    employees,
    revenue,
    companyName,
    domains
}: DownloadCompanyProps) => {
    const { setLoadingMessage } = useContext(appContext);
    const { callApi } = useApi();
    const [form] = Form.useForm();
    const [fileName, setFileName] = useState('');


    const [emailId, setEmailId] = useState('');

    const getNotifyEmails = (e: any) => {
        setEmailId(e);
    }

    const getPayload = () => {
        let data = {
            "action": "DOWNLOAD",
            "adaptEntity": "COMPANY",
            "city": city,
            "state": state,
            "country": country,
            "industry": industry,
            "subIndustry": subIndustry,
            "employees": employees,
            "revenue": revenue,
            "companyName": companyName,
            "domains": domains,
            "downloadFilename" : fileName,
            "notifyEmails" : emailId
        }
        return data;
    }

    const downloadContacts = async () => {

        if (fileName && emailId) {
            setLoadingMessage("Downloading Contacts ...");
            const { url, method } = API_ENDPOINTS.CONTACT_SEARCH.adaptSearch();
            const response = await callApi({
                url,
                method,
                data: getPayload()
            });
            if (response.success) {
                notification.success({
                    message: "Companies download request has been initiated. When it is completed, you will be emailed with the results file.",
                    description: ``,
                    duration: 10
                });
            }
            setLoadingMessage("");
        } else {
            return
        }
    };

    return (
        <Modal
            visible
            centered
            onCancel={onCancel}
            okText="Confirm"
            onOk={downloadContacts}
        >
            <Div>
                <Text variant="h6" block mb={20}>
                    Download Companies
                </Text>
                <Form
                    form={form}
                    name="addtocampaign"
                    scrollToFirstError
                    layout="vertical"
                >
                    <Text variant="overline" color="secondary">
                        Filename
                    </Text>
                    <Form.Item name="Filename" rules={[{ required: true, message: "Filename is required" },]}>
                        <Input onChange={(e) => setFileName(e.target.value)} />
                    </Form.Item>
                    <Text variant="overline" color="secondary">
                        Notification email ADDRESSES
                    </Text>
                    <Flex style={{ marginBottom: '24px' }}>
                        <Form.Item style={{ width: "100%", margin: 0 }} name="Email" rules={[{ required: true, message: "Notification email address is required" },]}>
                            <Select dropdownClassName="display-none" className="mt-2"
                                mode="tags" style={{ width: '100%' }}
                                onChange={(e) => getNotifyEmails(e)} tokenSeparators={[',']}>
                            </Select>
                            {/* <Input onChange={(e) => getNotifyEmails(e)} /> */}
                        </Form.Item>
                    </Flex>
                    <Text variant="caption" color="secondary" mb={20}>
                        Companies will be created in a file using the above filename and will be sent to you by email.
                    </Text>
                    {/* <Form.Item style={{ textAlign: "right" }}>
                        <Button type="text" onClick={onCancel} htmlType="submit">
                            Cancel
                        </Button>
                        <Button type="primary" onClick={downloadContacts} htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item> */}
                </Form>
            </Div>
        </Modal>
    )
}

export default DownloadCompanyDialog;
