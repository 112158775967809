import { useCallback } from "react";
import { Form, Select, FormInstance } from "antd";
import { FormFooter, Label, FormControlWrapper } from "./styles";
import { Flex, Div } from "styles/layout";
import { Text } from "styles/typography";

const { Option } = Select;

export type ProspectFieldType = {
  name: string;
  caseNeutralName: string;
  isMandatory?: boolean;
  columnIndex?: number;
  matchingRegEx?: RegExp;
};
export interface ColumnMappingType {
  outwinField: string;
  columnNumber: number;
  name?: string;
}

export interface ProspectMappingType {
  columnMappings: ColumnMappingType[];
  // timeZone: string;
}

export interface MapColumnsProps {
  columnHeaders: { header: string; index: number }[];
  sampleProspect: { prospect: string; index: number }[];
  onContinue: (prospectMapping: ProspectMappingType) => void;
  form: FormInstance<any>;
  totalProspects: number;
  prospectFields?: ProspectFieldType[];
}

const OUTWIN_PROSPECT_FIELDS = [
  {
    name: "First Name",
    caseNeutralName: "first_name",
    matchingRegEx: new RegExp(/(^firstname$|^first.+name)/gi),
    isMandatory: true
  },
  {
    name: "Last Name",
    caseNeutralName: "last_name",
    matchingRegEx: new RegExp(/(^lastname$|^last.+name)/gi),
    isMandatory: true
  },
  {
    name: "Email",
    caseNeutralName: "email",
    matchingRegEx: new RegExp(/^email/gi),
    isMandatory: true
  },
  {
    name: "Company Name",
    caseNeutralName: "company",
    matchingRegEx: new RegExp(/(^company$|^company.+name)/gi),
    isMandatory: true
  },
  {
    name: "LinkedIn URL",
    caseNeutralName: "linkedin",
    matchingRegEx: new RegExp(/^linkedin$/gi)
  },
  // {
  //   name: "Salesforce Lead Record Id",
  //   caseNeutralName: "salesforceid",
  //   matchingRegEx: new RegExp(/^salesforceid$/gi)
  // }
];

const getClosestFieldMatch = (fields: ProspectFieldType[], columnHeaders: MapColumnsProps["columnHeaders"]) => {
  let index = -1;
  let result = [];
  for (let j = 0; j < fields.length; j++) {
    for (let i = 0; i < columnHeaders.length; i++) {
      if (fields[j].matchingRegEx?.test(columnHeaders[i].header)) {
        index = i;
        continue;
      }
    }
    result.push(index === -1 ? "" : index);
    index = -1;
  }
  return result;
};

const MapColumns = ({
  columnHeaders,
  sampleProspect,
  onContinue,
  form,
  totalProspects,
  prospectFields = OUTWIN_PROSPECT_FIELDS
}: MapColumnsProps) => {
  const onSave = useCallback(
    (form) => {
      const { fields }: { fields: number[] } = form;
      const columnMappings: ColumnMappingType[] = prospectFields.map(
        ({ caseNeutralName: outwinField, name }, index) => ({
          outwinField,
          name,
          columnNumber: fields[index] === ("" as any) ? -1 : fields[index]
        })
      );

      onContinue({ columnMappings });
    },
    [onContinue, prospectFields]
  );

  const getSampleProspectField = useCallback(
    (index) => sampleProspect.find((prospect) => prospect.index === index)?.prospect,
    [sampleProspect]
  );

  const onValueChange = (changedValue: { fields: number[] }, allValues: { fields: number[] }) => {
    const changed = changedValue.fields
      ?.map?.((e, idx) => ({ dropdownIdx: idx, selectedColIdx: e }))
      .filter((e) => !!e)?.[0];

    const updated = (allValues.fields ?? []).map((val, idx) =>
      changed?.dropdownIdx === idx ? val : changed?.selectedColIdx === val ? "" : val
    );
    form.setFieldsValue({ fields: updated });
  };

  return (
    <Div>
      <Text variant="h6" align="center" mb={15} block>
        Prospects data mapping
      </Text>
      <Form
        scrollToFirstError
        form={form}
        onFinish={onSave}
        onValuesChange={onValueChange}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          fields: getClosestFieldMatch(prospectFields, columnHeaders)
        }}
        onError={() => alert("Error")}
      >
        <Flex mb={20} pb={10} pt={10}>
          <Div mw="50%">
            <Text variant="overline" color="secondary">
              Emy Field
            </Text>
          </Div>
          <Div mw="50%">
            <Text variant="overline" color="secondary">
              CSV Header
            </Text>
          </Div>
        </Flex>
        {prospectFields.map(({ name: outwinField, isMandatory }, index) => (
          <FormControlWrapper flex key={outwinField}>
            <Label mw="50%" mb={24}>
              {outwinField} {!!isMandatory ? "*" : ""}
            </Label>
            <Form.Item
              required={!!isMandatory}
              name={["fields", index]}
              style={{ minWidth: "50%" }}
              rules={[{ required: !!isMandatory, message: "Required" }]}
            >
              <Select style={{ minWidth: "50%" }}>
                {columnHeaders.map((col) => (
                  <Option key={col.header} value={col.index} title={col.header}>
                    {col.header}
                    <Text variant="caption" color="secondary" block>
                      {getSampleProspectField(col.index)}
                    </Text>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </FormControlWrapper>
        ))}
        {/* <Text mr={10} variant="caption" block align="center" color="secondary">
          Rest of fields will be available as custom variables for email templates.
        </Text> */}
        <FormFooter>
          <Text variant="body1" mr={20}>
            <Text color="primary" variant="body1" bold>
              {totalProspects}
            </Text>{" "}
            prospects included
          </Text>
        </FormFooter>
      </Form>
    </Div>
  );
};

export default MapColumns;
