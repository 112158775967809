import { DOMAIN_TRACKING_LABEL } from "pages/Domain/metaData";

export const ROUTE_PATHS = {
  INSIGHTS: { path: "/insights", label: "pageLabels.insights" },
  CAMPAIGNS: { label: "Campaigns" },
  ACCOUNTS: { path: "/accounts", label: "pageLabels.accounts" },
  SETTINGS: { path: "/settings", label: "pageLabels.settings" },
  INTEGRATION: { path: "/integrations", label: "Integration" },
  PROFILE: { path: "/profile", label: "Profile", thirdParty: "ivy" },
  COMPANY_SEARCH: { path: "/search", label: "Prospects Finder" },
  MAILBOXES: { path: "/mailboxes", label: "Mailboxes" },
  MY_TEAM: { path: "/team", label: "My Team" },
  HOLIDAYS: { path: "/holidays", label: "Holidays" },
  DNC_LIST: { path: "/dnclist", label: "Do not Contact List" },
  LOGIN: { path: "/login", label: "pageLabels.login" },
  REGISTER: { path: "/register", label: "pageLabels.register" },
  FORGOT_PASSWORD: {
    path: "/forgot-password",
    label: "pageLabels.forgotPassword",
  },
  DOMAIN: {
    label: DOMAIN_TRACKING_LABEL,
    path: '/domains',
  },
  NOTIFICATIONS: {
    label: "pageLabels.notifications",
  },
  UNIBOX: { label: "Unibox" },
  NOTIFICATION: { label: "Notifications" },
  SUBSCRIPTION: { label: "Subscription", path: '/subscription', },
  REPORT: { label: "Report" },
};

export const IVY_PATH = 'ivy';