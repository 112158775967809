import React, { useCallback, useMemo, useState,useEffect } from "react";
import { Flex } from "styles/layout";
import { Layout, Menu, Select } from "antd";
// import { MailOutlined, RetweetOutlined } from "@ant-design/icons";
import TouchList from "./TouchList";
import { CampaignType } from "types/Campaign";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  areCampaignSequencesLoadingSelector,
  getCampaignSequencesSelector,
} from "state/selectors";
import { getSequenceName } from "utils/campaign";

const { Sider } = Layout;

interface SelectedViewType {
  key: string;
  type: "touches" | "spinner";
  props: {} | null;
}

interface CampaignSequencesProps {
  campaignDetail: CampaignType;
}

const Sequences = ({ campaignDetail }: CampaignSequencesProps) => {
  const [selectedView, setSelectedView] = useState<SelectedViewType>({
    key: "touches",
    type: "touches",
    props: null,
  });
  const campaignId = useMemo(() => campaignDetail.id, [campaignDetail]);
  const match = useRouteMatch<{ sequenceId: string }>();
  const sequences = useSelector(
    getCampaignSequencesSelector(campaignDetail.id)
  );
  const [seq, setSequenceOrder] = useState(sequences);
  
  useEffect(() => {
    const order = ["INITIAL", "INFO", "POWER", "REFERRAL", "FUTURE"]
    if (sequences) {
      setSequenceOrder(sequences.sort(function (a, b) {
        return order.indexOf(a.type) - order.indexOf(b.type)
      }))
    }
  }, [sequences])

  const areSequencesLoading = useSelector(
    areCampaignSequencesLoadingSelector(campaignDetail.id)
  );
  const history = useHistory();
  const sequenceId = useMemo(() => {
    const {
      params: { sequenceId: id },
    } = match;
    return id;
  }, [match]);

  const onClickSideNav: any = useCallback(
    ({ key }: { key: string; keyPath: string[] }) => {
      if (key === selectedView.key) {
        return;
      }

      if (key === "touches") {
        setSelectedView({
          key,
          type: key,
          props: null,
        });
        return;
      }

      setSelectedView({
        key,
        type: "spinner",
        props: {},
      });
    },
    [selectedView]
  );

  const onChangeSequence = useCallback(
    (newSequenceId) => {
      history.push(`/campaigns/${campaignId}/sequences/${newSequenceId}`);
    },
    [campaignId, history]
  );

  const MenuComponent = useMemo(() => {
    if (selectedView.type === "touches") {
      return <TouchList campaignId={campaignId} sequenceId={sequenceId} />;
    }

    // return <SpinnerList campaignId={campaignId} sequenceId={sequenceId} />;
  }, [selectedView, campaignId, sequenceId]);

  return (
    <Flex mt={15}>
      {/* <Sider width={200} style={{ background: "transparent" }}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[selectedView.key]}
          defaultOpenKeys={[]}
          onClick={onClickSideNav}
        >
          <Menu.Item
            key="touches"
            style={{ margin: 0 }}
            icon={<MailOutlined />}
            title="Touches"
          >
            Touches
          </Menu.Item>
          <Menu.Item
            key="spinners"
            style={{ margin: 0 }}
            icon={<RetweetOutlined />}
            title="Spinners"
          >
            Spinners
          </Menu.Item>
        </Menu>
      </Sider> */}
      <Flex
        ml={20}
        grow={1}
        // style={{ maxWidth: "calc(100% - 220px)" }}
        direction="column"
      >
        <Flex justify="center" mb={10}>
          <Select
            style={{ width: 200 }}
            value={sequenceId}
            onChange={onChangeSequence}
            loading={areSequencesLoading}
          >
            {seq.map(({ id, type }) => (
              <Select.Option value={id} key={id}>
                {getSequenceName(type)}
              </Select.Option>
            ))}
          </Select>
        </Flex>
        {MenuComponent}
      </Flex>
    </Flex>
  );
};

export default Sequences;
