import {
  CheckCircleOutlined, CloseCircleOutlined, CloudDownloadOutlined, DeleteOutlined, DiffOutlined, InfoCircleOutlined, LoadingOutlined, LogoutOutlined,
  MenuOutlined,
  MoreOutlined, PauseCircleOutlined,
  PlayCircleOutlined, PlusSquareOutlined, RollbackOutlined
} from "@ant-design/icons";
import { Badge, Button, Col, Dropdown, Form, Menu, Modal, notification, Popover, Row, Table, Tag, Tooltip } from "antd";
import {
  getStageColor, PROSPECT_STAGES,
  PROSPECT_STATUSES
} from "constants/prospect";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignActions from "state/actions/campaign";
import {
  areCampaignProspectsLoadingSelector,
  canModifyCampaignState, getCampaignProspectsSelector, getCampaignProspectVariablesSelector
} from "state/selectors";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { CampaignProspectType } from "types/Campaign";
import { ProspectStageEnum, ProspectStatusEnum } from "types/Prospect";
import { BOX_SHADOW } from "utils/colors";
import AddPropspectsModal from "./AddProspectsModal";
import { useAuth } from "auth/AuthProvider";
import { ProspectFieldType } from "components/UploadPropects/MapColumns";
import { API_ENDPOINTS } from "constants/api";
import translationStrings from "constants/strings.json";
import LogDetailsModal from "containers/LogDetailsModal";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { isNumber } from "lodash";
import ProspectDetailModal from "./ProspectDetailModal";
import { ProspectFilter } from "./ProspectFilter";
import ProspectStatusChangeConfirmModal from "./ProspectStatusChangeConfirmModal";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface PropspectsProps {
  campaignId: string;
}

type ProspectDownloadResponse = {
  status: "REQUESTED" | "STARTED" | "COMPLETED" | "FAILED" | "NOT_STARTED";
  s3URL?: string;
};

let record: CampaignProspectType;

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex pt={10} pb={5} ml={10} m={30} direction="column">
    {children}
  </Flex>
);

const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="caption" color="secondary" style={{ paddingLeft: 3 }}>
    {children}
  </Text>
);

const Prospects = ({ campaignId }: PropspectsProps) => {
  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(appContext);
  const { theme } = useAuth();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [Data, setData] = useState({
    // firstName: '',
    // lastName: '',
    emailAddress: "",
    domain: "",
    subStatus: []
    // companyName: ''
  });
  // const touches = useSelector(getCampaignTouchesSelector(campaignId));
  // const areTouchesLoading = useSelector(areCampaignTouchesLoadingSelector(campaignId));
  const timezoneList = useSelector((state: any) => {
    return state?.campaign[campaignId]?.timezones?.entities;
  });

  const { prospects, totalPages } = useSelector(getCampaignProspectsSelector(campaignId));
  const prospectVariables = useSelector(getCampaignProspectVariablesSelector(campaignId)) as ProspectFieldType[];
  const areProspectsLoading = useSelector(areCampaignProspectsLoadingSelector(campaignId));

  // console.log(`[zzz] prospects:`, prospectVariables);
  // console.log(`[zzz] campaign:`, useSelector(state=>(state as any)?.campaign?.[campaignId]));
  // console.log(`[zzz] campaign2:`, useSelector(state=>(state as any)));

  const [showProspectsModal, setShowProspectsModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showAddProspectsModal, setShowAddProspectsModal] = useState(false);
  const [prospectStatusOptions, setPropspectStatusOptions] = useState(
    PROSPECT_STATUSES.map((ps) => ({ ...ps, disabled: false }))
  );
  const [prospectStages, setProspectStages] = useState<ProspectStageEnum[]>([]);
  const [prospectTouches, setProspectTouches] = useState([]);
  const [prospectStatuses, setProspectStatuses] = useState<ProspectStatusEnum[]>([]);
  const [pageInfo, setPageInfo] = useState({
    pageSize: 50,
    page: 1
  });
  const [selectedProspect, setSelectedProspect] = useState<CampaignProspectType | null>(null);

  const [newProspectStatus, setNewProspectStatus] =
    useState<{
      prospect: CampaignProspectType;
      operation: string;
      status: ProspectStatusEnum;
      stage: ProspectStageEnum;
    } | null>(null);

  const [selectedProspectIds, setSelectedPropspectIds] = useState<React.Key[]>([]);
  const [showLogsModal, setShowLogsModal] = useState<{ show: boolean; prospect: CampaignProspectType | null }>({
    show: false,
    prospect: null
  });

  const [prospectDownload, setProspectDownload] = useState<ProspectDownloadResponse>({ status: "NOT_STARTED" });

  const isFree = useSelector(canModifyCampaignState(campaignId));

  const toggleAddProspectsModal = useCallback(
    () => setShowAddProspectsModal((show) => !show),
    [setShowAddProspectsModal]
  );

  const onSelectPropspectTouch = useCallback(async (values: any[]) => {
    let newArray = [];
    newArray.push(values);
    setProspectTouches(newArray as []);
  }, []);

  const onChangePropspectStage = useCallback((values: any[]) => {
    setProspectStages(values as ProspectStageEnum[]);

    const allowedProspectStatuses = !!values.length
      ? PROSPECT_STAGES.filter(({ value }) => values.includes(value))
        .map(({ statuses }) => statuses)
        .flat()
      : PROSPECT_STATUSES.map(({ value }) => value);
    setPropspectStatusOptions(
      PROSPECT_STATUSES.map((ps) => ({
        ...ps,
        disabled: !allowedProspectStatuses.includes(ps.value)
      }))
    );
    setProspectStatuses((prevProspectStatuses) =>
      prevProspectStatuses.filter((status) => allowedProspectStatuses.includes(status))
    );
  }, []);

  // const onChangeProspectStatus = useCallback((values: any[]) => {
  //   setProspectStatuses(values as ProspectStatusEnum[]);
  // }, []);

  const onChangeProspectFiltersStatus = useCallback((payload: any) => {
    if (payload?.emailAddress || payload.emailAddress === "") {
      setData({ emailAddress: payload.emailAddress, domain: "", subStatus: [] });
    }
    if (payload?.domain || payload.domain === "") {
      setData({ domain: payload.domain, emailAddress: "", subStatus: [] });
    }
    if (payload?.subStatus || payload?.subStatus?.length >= 0) {
      setData({ domain: "", emailAddress: "", subStatus: payload.subStatus });
    }
  }, []);

  const onProspectStatusChangeModalCancel = useCallback(() => setNewProspectStatus(null), []);

  const fetchProspects = useCallback(() => {
    dispatch(
      CampaignActions.fetchProspects(
        campaignId,
        {
          subStatus: Data.subStatus,
          domain: Data.domain,
          emailAddress: Data.emailAddress,
          touches: prospectTouches
        },
        pageInfo
      )
    );
  }, [campaignId, dispatch, pageInfo, prospectStages, prospectTouches, Data]);

  const onProspectStatusConfirm = useCallback(async () => {
    if (!newProspectStatus) return;
    // const status = newProspectStatus?.status.toLowerCase() === "ready" ? "resumed" : newProspectStatus?.status
    const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getUpdateStatusUrl(newProspectStatus?.prospect.id, newProspectStatus?.status)

    setLoadingMessage("Updating Prospect status");
    try {
      await callApi({
        url,
        method,
        // data: {
        //   subStatus: newProspectStatus?.status
        // }
      });
      fetchProspects();
    } catch (err) { }
    setLoadingMessage("");
    setNewProspectStatus(null);
  }, [newProspectStatus, setLoadingMessage, callApi, fetchProspects]);

  useEffect(() => {
    // dispatch(CampaignActions.fetchTouches(campaignId));
    fetchProspects();
  }, [dispatch, campaignId, fetchProspects]);

  useEffect(() => { }, []);

  const onPaginationChange = useCallback((page, pageSize = 50) => setPageInfo({ page, pageSize }), []);

  const onCancelProspectDetailModal = useCallback(() => {
    setSelectedProspect(null);
    setShowProspectsModal(false);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text: string, record: CampaignProspectType) => {
          return (
            <Popover content={`${record.firstname} ${record.lastname}`}>
              <Div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {record.firstname} {record.lastname}
              </Div>
            </Popover>
          );
        },
        ellipsis: true,
        width: 150
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        render: (email: string) => (
          <Popover content={email}>
            <Div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {email}
            </Div>
          </Popover>
        )
        // width: 200
      },
      {
        title: "",
        render: (text: any, record: CampaignProspectType) => {
          return (
            <>
              <Tag>
                {record.subStatus}
              </Tag>
              {/* <Tag color={getStageColor(record.status)}>
                {record.sequenceType}
              </Tag> */}
              {(isNumber(record.lastTouchNumber) && record.lastTouchNumber > 0) && (
                <Popover
                  content={
                    (record.sequenceType
                      ? record.sequenceType.slice(0, 1) + record.sequenceType.slice(1).toLowerCase() + " Sequence "
                      : "") +
                    "Touch " +
                    record.lastTouchNumber
                  }
                  trigger="hover"
                >
                  <Badge
                    showZero={true}
                    count={record.lastTouchNumber}
                    title={"Touch " + record.lastTouchNumber}
                    style={{ backgroundColor: "#13c2c2", marginRight: "10px" }}
                  ></Badge>
                </Popover>
              )}
            </>
          );
        }
      },
      {
        title: "Actions",
        width: 80,
        render: (text: any, prospect: CampaignProspectType) => (
          <Flex
            justify="flex-end"
            p="0 10px"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="pause-resume"
                    onClick={() =>
                      setNewProspectStatus({
                        prospect,
                        stage:
                          prospect.subStatus !== ProspectStatusEnum.PAUSED
                            ? ProspectStageEnum.INACTIVE
                            : ProspectStageEnum.ACTIVE,
                        operation:
                          prospect.subStatus === ProspectStatusEnum.PAUSED ? "resume prospect" : "pause prospect",
                        status:
                          prospect.subStatus === ProspectStatusEnum.PAUSED
                            ? ProspectStatusEnum.RESUMED
                            : ProspectStatusEnum.PAUSED
                      })
                    }
                  >
                    {prospect.subStatus === ProspectStatusEnum.PAUSED ? (
                      <>
                        <PlayCircleOutlined /> Resume
                      </>
                    ) : (
                      <>
                        <PauseCircleOutlined /> Pause
                      </>
                    )}
                  </Menu.Item>
                  <Menu.Item
                    key="replied"
                    onClick={() =>
                      setNewProspectStatus({
                        prospect,
                        operation: "mark prospect as replied",
                        status: ProspectStatusEnum.REPLIED,
                        stage: ProspectStageEnum.FINISHED
                      })
                    }
                  >
                    <RollbackOutlined /> Mark as replied
                  </Menu.Item>
                  <Menu.Item
                    key="opted"
                    onClick={() =>
                      setNewProspectStatus({
                        prospect,
                        operation: "mark prospect as Unsubscribe",
                        status: ProspectStatusEnum.UNSUBSCRIBE,
                        stage: ProspectStageEnum.FINISHED
                      })
                    }
                  >
                    <LogoutOutlined /> Unsubscribe
                  </Menu.Item>
                  {/*       <Menu.Item
                    key="dropped"
                    disabled={!isFree}
                    onClick={() =>
                      setNewProspectStatus({
                        prospect,
                        operation: "mark prospect as dropped",
                        status: ProspectStatusEnum.DROPPED,
                        stage: ProspectStageEnum.FINISHED
                      })
                    }
                  >
                    <StopOutlined /> Mark as dropped
                  </Menu.Item> */}
                  <Menu.Item key="view-logs" onClick={() => setShowLogsModal({ show: true, prospect })}>
                    <InfoCircleOutlined /> View Logs
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <Div align="center" w="100%">
                <MenuOutlined />
              </Div>
            </Dropdown>
          </Flex>
        )
      }
    ],
    []
  );

  const onChangeRowSelection = useCallback((selectedRowKeys: React.Key[]) => {
    setSelectedPropspectIds(selectedRowKeys);
  }, []);

  const onAddProspectClick = useCallback(() => {
    setShowProspectsModal(!!selectedProspect ? false : true);
  }, [selectedProspect]);

  // const onFinish = useCallback(
  //   async (formValues: any) => {
  //     let {
  //       // firstName, lastName, companyName,
  //       domain,
  //       email
  //     } = formValues;

  //     setData({
  //       // firstName: firstName || '',
  //       // lastName: lastName || '',
  //       emailAddress: email || "",
  //       domain: domain || ""
  //       // companyName: companyName || ''
  //     });
  //     let payload = {
  //       // firstName: firstName || '',
  //       // lastName: lastName || '',
  //       emailAddress: email || "",
  //       domain: domain || ""
  //       // companyName: companyName || '',
  //     };
  //   },
  //   [setData]
  // );

  const onDelProspectClick = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getAllDeleteUrl(campaignId);

    setLoadingMessage("Deleting the Prospect");

    try {
      await callApi({ url, method, data: { prospectIds: selectedProspectIds } });
      fetchProspects();
    } catch (err) { }
    setSelectedPropspectIds([]);
    setLoadingMessage("");
  }, [selectedProspectIds, campaignId, callApi, fetchProspects, setLoadingMessage]);

  const onDelAllProspectClick = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getAllDeleteUrl(campaignId);
    setLoadingMessage("Deleting All the Prospect ...");
    try {
      await callApi({
        url,
        method,
        data: {
          prospectIds: [],
          deleteAll: true
        }
      });
      fetchProspects();
    } catch (err) { }
    setSelectedPropspectIds([]);
    setLoadingMessage("");
  }, [campaignId, callApi, fetchProspects, setLoadingMessage]);

  const handleActivateProspects = async () => {
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getStatusChangeUrl(campaignId);
    setLoadingMessage("Activating failed prospects ...");
    const payload = { status: "ActivateFailedProspects" };
    const response = await callApi({
      url,
      method,
      data: payload
    });
    if (response.success) {
      notification.success({
        message: "Your request is submitted. Failed prospects will be activated shortly."
      })
    }
    setLoadingMessage("");
  };
  const handleProspectConfirmModal = useCallback(
    () => setShowDeleteConfirmModal(!showDeleteConfirmModal),
    [showDeleteConfirmModal]
  );

  const isProspectDownloadBusy = useMemo(
    () => prospectDownload.status === "STARTED" || prospectDownload.status === "REQUESTED",
    [prospectDownload.status]
  );

  const downloadBusyRef = useRef(isProspectDownloadBusy);
  downloadBusyRef.current = isProspectDownloadBusy;
  useEffect(() => {
    return () => {
      if (downloadBusyRef.current) {
        notification.info({
          message: "Prospect download is in progress, it will downloaded when ready."
        });
      }
    };
  }, []);

  const onProspectDownload = async () => {
    if (isProspectDownloadBusy) {
      return;
    }

    const downloadApi = async (requestId: string): Promise<ProspectDownloadResponse> => {
      const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.downloadProspectStatus(requestId);
      try {
        const downloadStatus = await callApi({ url, method });
        if (downloadStatus.success) {
          const status = downloadStatus?.data?.status;
          return { s3URL: downloadStatus?.data?.s3URL, status };
        }
      } catch (ex) {
        return { status: "FAILED" };
      }
      return { status: "FAILED" };
    };

    const delay = (timeout: number) => new Promise((resolve) => setTimeout(() => resolve(true), timeout));

    const download = async (tries: number, requestId: string, MAX_TRIES = 15): Promise<ProspectDownloadResponse> => {
      const response = await downloadApi(requestId);
      if (tries <= MAX_TRIES && response.status !== "FAILED" && response.status !== "COMPLETED") {
        await delay(9_000);
        return await download(tries + 1, requestId);
      }
      return response;
    };

    const openInNewTab = (url: string) => {
      // const newWindow = window.open(url, "_blank");
      // if (newWindow) newWindow.opener = null;
      window.location.href = url;
    };

    try {
      notification.info({
        message: "Prospect download started. Please wait.."
      });
      setProspectDownload({ status: "REQUESTED" });
      const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.downloadProspectInitiate(campaignId);
      const initiateRes = await callApi({ url, method, data: { subStatus: prospectStatuses } });
      if (initiateRes.success) {
        const requestId = initiateRes.data?.requestId;
        const response = await download(0, requestId);
        if (response.status === "COMPLETED" && response.s3URL) {
          openInNewTab(response.s3URL);
        }

        if (response.status === "FAILED") {
          notification.destroy();
          notification.error({
            message: "Download Prospects failed."
          });
        }

        if (response.status === "STARTED") {
          notification.destroy();
          notification.error({
            message: "Download Prospects timeout. Please try again."
          });
        }
      }
    } catch (error) { }
    setProspectDownload({ status: "NOT_STARTED" });
  };

  const getOverLayMenu = () => {
    return (
      <Menu>
        <Menu.Item key="edit" style={{ padding: "10px" }} onClick={() => handleActivateProspects()}>
          <CheckCircleOutlined style={{ marginRight: "10px" }} /> Activate Failed Prospects
        </Menu.Item>

        <Menu.Item key="change" style={{ padding: "10px" }} onClick={() => handleProspectConfirmModal()}>
          <CloseCircleOutlined style={{ marginRight: "10px" }} /> Delete All Prospects
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Flex
      bgColor={theme.whiteColor}
      mt={10}
      style={{
        boxShadow: BOX_SHADOW
      }}
      mb={10}
    >
      <Div mw={350} w={350} mr={20} ml={20} mt={20} className="left-col">
        {/* <Text variant="sub2" mt={10}>
          Filters
        </Text> */}
        <ProspectFilter campaignId={campaignId} onChange={onChangeProspectFiltersStatus} />
        {/* <Div w="100%" mt={10}>
          <Form scrollToFirstError form={form} onFinish={onFinish} className="form-filter">
            <DetailWrapper>
              <DetailHeading>Email</DetailHeading>
              <Form.Item name={"email"}>
                <Input className={classes.inputStyle} tabIndex={1} size="middle" />
              </Form.Item>
              <DetailHeading>Domain</DetailHeading>
              <Form.Item name={"domain"}>
                <Input className={classes.inputStyle} tabIndex={1} size="middle" />
              </Form.Item>
            </DetailWrapper>
            <Button htmlType="submit" type="primary" style={{ display: "none" }}>
              Validate
            </Button>
          </Form>
        </Div>
        <Div w="100%" mt={20}>
          <Text variant="overline" mb={10} block>
            Stage
          </Text>
          <Checkbox.Group value={prospectStages} onChange={onChangePropspectStage}>
            {PROSPECT_STAGES.map(({ label, value }) => (
              <Div key={value}>
                <Checkbox value={value}>{label}</Checkbox>
              </Div>
            ))}
          </Checkbox.Group>
        </Div>
        <Div w="100%" mt={20}>
          <Text variant="overline" mb={10} block>
            Status
          </Text>
          <Checkbox.Group value={prospectStatuses} onChange={onChangeProspectStatus}>
            {prospectStatusOptions.map(({ label, value, disabled }) => (
              <Div key={value}>
                <Checkbox value={value} disabled={disabled}>
                  {label}
                </Checkbox>
              </Div>
            ))}
          </Checkbox.Group>
        </Div>
         */}
      </Div>
      <Flex
        style={{
          minHeight: "calc(100vh - 180px)",
          width: "calc(100% - 350px)"
        }}
        grow={1}
        mt={20}
        mr={15}
        direction="column"
      >
        <Flex align="center" justify="flex-end" gap={15} mb={22} wrap="wrap" w={"100%"}>
          {!!selectedProspectIds.length && (
            <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
              <Button
                size="small"
                disabled={!isFree}
                onClick={() => onDelProspectClick()}
                icon={<DeleteOutlined />}
                type="default"
                style={{ color: theme.antdConf.errorColor }}
              >
                Delete {selectedProspectIds.length} Prospect
                {selectedProspectIds.length > 1 ? "s" : ""}
              </Button>
            </Tooltip>
          )}
          {/* <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
            <Button
              size="small"
              disabled={!isFree}
              onClick={() => handleProspectConfirmModal()}
              icon={<CloseCircleOutlined />}
              type="text"
              style={{ color: theme.antdConf.primaryColor }}
            >
              Delete all
            </Button>
          </Tooltip> */}
          <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
            <Button
              size="small"
              // disabled={!isFree}
              onClick={() => onAddProspectClick()}
              icon={<PlusSquareOutlined />}
              type="text"
              style={{ color: theme.antdConf.primaryColor }}
            >
              Add single
            </Button>
          </Tooltip>
          <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
            <Button
              size="small"
              // disabled={!isFree}
              onClick={toggleAddProspectsModal}
              icon={<DiffOutlined />}
              type="text"
              style={{ color: theme.antdConf.primaryColor }}
            >
              Add bulk
            </Button>
          </Tooltip>

          <Flex style={{ position: "relative" }}>
            <Button
              size="small"
              disabled={isProspectDownloadBusy}
              onClick={onProspectDownload}
              icon={<CloudDownloadOutlined spin={isProspectDownloadBusy} />}
              type="text"
              style={{ color: theme.antdConf.primaryColor }}
            >
              Download
            </Button>
            {!!prospectStatuses?.length && (
              <Popover
                content={
                  <Flex direction="column">
                    <Row>
                      <Col span={18}>Status Filter</Col>
                      <Col offset={1} span={5}>
                        {prospectStatuses?.length}
                      </Col>
                      {/* {!!selectedProspectIds?.length && (
                        <>
                          <Col span={18}>Prospect selected</Col>
                          <Col offset={1}> {selectedProspectIds?.length}</Col>
                        </>
                      )} */}
                    </Row>
                  </Flex>
                }
                title="Summary"
              >
                <Badge
                  style={{ position: "absolute" }}
                  count={prospectStatuses?.length + selectedProspectIds?.length}
                  overflowCount={9}
                  offset={[5, -15]}
                ></Badge>
              </Popover>
            )}
          </Flex>
          <Flex>
            <Dropdown overlay={getOverLayMenu()} trigger={["click"]}>
              <MenuOutlined style={{ cursor: "pointer" }} />
            </Dropdown>
          </Flex>
        </Flex>
        <Div style={{ flex: 1 }}>
          <Table
            size="small"
            rowSelection={{
              type: "checkbox",
              onChange: onChangeRowSelection
            }}
            pagination={{ pageSize: 50, size: "small", showSizeChanger: false, position: ["topRight"] }}
            columns={columns}
            rowKey="id"
            dataSource={prospects}
            className="outwin-table"
            loading={{ spinning: areProspectsLoading, indicator: antIcon }}
            onRow={(record: CampaignProspectType) => {
              return {
                className: "outwin-table-row-hover",
                onClick: () => setSelectedProspect(record)
              };
            }}
          />
        </Div>
      </Flex>
      {showAddProspectsModal && (
        <AddPropspectsModal
          onCancel={toggleAddProspectsModal}
          prospectVariables={prospectVariables}
          campaignId={campaignId}
          afterUpload={fetchProspects}
        />
      )}
      {(!!selectedProspect || showProspectsModal) && (
        <ProspectDetailModal
          prospect={!!selectedProspect ? selectedProspect : record}
          timezoneList={timezoneList}
          prospectVariables={prospectVariables}
          onCancel={onCancelProspectDetailModal}
          campaignId={campaignId}
          onFetch={fetchProspects}
          isCreate={!!selectedProspect ? false : true}
          canEdit={isFree}
        />
      )}
      {!!newProspectStatus && (
        <ProspectStatusChangeConfirmModal
          prospect={newProspectStatus.prospect}
          newStatus={newProspectStatus.status}
          operation={newProspectStatus.operation}
          onCancel={onProspectStatusChangeModalCancel}
          onOk={onProspectStatusConfirm}
        />
      )}
      {showDeleteConfirmModal && (
        <Modal
          visible={showDeleteConfirmModal}
          okButtonProps={{
            type: "primary",
            danger: true,
            disabled: !isFree,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          onOk={() => onDelAllProspectClick()}
          cancelText="Cancel"
          onCancel={handleProspectConfirmModal}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <Text variant="body2">
            {" "}
            You are about to{" "}
            <Text variant="body2" bold>
              delete all
            </Text>{" "}
            the prospects from this campaign. If the campaign was already launched, it can cause issues to the inbound
            emails.{" "}
            <Text variant="body2" bold>
              Are you sure?
            </Text>{" "}
          </Text>
        </Modal>
      )}
      {showLogsModal.show && showLogsModal.prospect && (
        <LogDetailsModal
          onCancel={() => setShowLogsModal((state) => ({ ...state, show: false }))}
          id={showLogsModal.prospect?.id}
          subTitle={showLogsModal.prospect.email}
          logType="Prospect"
        />
      )}
    </Flex>
  );
};

export default Prospects;
