import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Row, Col, Input, notification, Select, Checkbox, Tooltip, Typography, Modal, Card, Steps } from "antd";
import Onboarding from "components/Campaign/Onboarding";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { Flex, Div, PageBackground } from "styles/layout";
import { DARK_GRAY_COLOR, WHITE_COLOR } from "utils/colors";
import MailBoxList, { MAILBOXLIST_FILTER } from "components/MailBox/List";
import LocalStorage from "utils/localstorage";
import MailBoxProviderDetailModal from "components/MailBox/Modal/MailBoxDetailProviderModal";
import { prefixSelector } from "components/MailBox/CountryCodePicker";
import UploadCsv, { CSVContentsType } from "components/UploadPropects/UploadCsv";
import ProspectMapColumns, { ProspectMappingType } from "components/UploadPropects/MapColumns";
import ProspectSummary from "components/UploadPropects/UploadSummary";
import { NEW_CAMPAIGN_LS_KEY, OAUTH_STATE_LS_KEY } from "constants/localstorage";
import CampaignSettings, { CampaignSettingsType } from "components/Campaign/Settings";
import MailboxProvider, { getProviderAndProtocol, getProviderDetails, PROVIDERS } from "components/MailBox/Provider";
import MailBoxSettings, { MailBoxSettingsType } from "components/MailBox/Settings";
import { MailboxProtocolEnum, MailboxTypeEnum } from "types/MailBox";
import { v4 as uuid } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import { getRandomAvatar } from "utils/avatar";
import Actions from "components/Actions";
import { Text } from "styles/typography";
import classes from "./styles.module.css";
import { io, Socket } from "socket.io-client";
import { SelectColumns } from "components/UploadPropects/SelectColumns";
import VerficationModal from "pages/CampaignDetail/Modals/VerficationModal";
import RecoveryEmailModal from "pages/CampaignDetail/Modals/RecoveryEmailModal";
import UpdatePasswordModal from "pages/CampaignDetail/Modals/UpdatePasswordModal";
import VerifyPhoneNoModal from "pages/CampaignDetail/Modals/VerifyPhoneNoModal";
import VerifyAccessCodeModal from "pages/CampaignDetail/Modals/VerifyAccessCodeModal";
import VerifyEmailByPhoneNoModal from "pages/CampaignDetail/Modals/VerifyEmailByPhoneNoModal";
import VerificationOptionsModal from "pages/CampaignDetail/Modals/VerificationOptionsModal";
import TroubleShootModal from "pages/CampaignDetail/Modals/TroubleShootModal";
import ManualGmailLoginModal from "pages/CampaignDetail/Modals/ManualGmailLoginModal";
import GmailIMAPLoginModal from "pages/CampaignDetail/Modals/GmailIMAPLoginModal";
import MailExistModal from "pages/CampaignDetail/Modals/MailExistModal";
import { WHISPER_BASE_URL } from "constants/api";
import Axios from "utils/axios";
import { isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { DedupeProspects } from "components/UploadPropects/DedupeProspects";
import { Applydnc } from "components/UploadPropects/ApplyDnc";
import GsuiteLoginModal from "pages/CampaignDetail/Modals/GsuiteLoginModal";
import GsuiteSetupModal from "pages/CampaignDetail/Modals/GsuiteSetupModal";
const { Step } = Steps;

// const DetailWrapper = ({ children }: { children: ReactNode }) => (
//   <Flex pt={10} pb={5} ml={10} m={30} direction="column">
//     {children}
//   </Flex>
// );

// const DetailHeading = ({ children }: { children: ReactNode }) => (
//   <Text variant="caption" color="secondary" style={{ paddingLeft: 3 }}>
//     {children}
//   </Text>
// );

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex pt={10} pb={5} direction="column">
    {children}
  </Flex>
);

const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="caption" color="secondary" mr={20}>
    {children}
  </Text>
);

type ProspectUploadResponse = {
  status: "REQUESTED" | "STARTED" | "COMPLETED" | "FAILED" | "NOT_STARTED";
};
export enum NewCampaignSteps {
  ONBOARDING = "ONBOARDING",
  MAILBOX_LISTING = "MAILBOX_LISTING",
  MAILBOX_PROVIDER = "MAILBOX_PROVIDER",
  MAILBOX_SETTINGS = "MAILBOX_SETTINGS",
  PROSPECT_UPLOAD = "PROSPECT_UPLOAD",
  PROSPECT_COLUMN_MAPPING = "PROSPECT_COLUMN_MAPPING",
  PROSPECT_COLUMN_SELECT = "PROSPECT_COLUMN_SELECT",
  // PROSPECT_SUMMARY = "PROSPECT_SUMMARY",
  SETUP = "SETUP",
  PROSPECT_DEDUPE_SELECT = "PROSPECT_DEDUPE_SELECT",
  APPLY_DNC = "APPLY_DNC"
}

const STEPS = [
  {
    title: "Mailboxes"
  },
  {
    title: "Prospects"
  },
  {
    title: "Dedupe"
  },
  // {
  //   title: "Apply DNC"
  // },
  {
    title: "Setup"
  }
];

interface NewCampaignFlowProps {
  onCancel: () => void;
  isFromAddCampaign?: boolean;
  afterSave: () => void;
}

interface Proxy {
  id: string;
  host: string;
  port: string;
  user: string;
  pwd: string;
}

export interface CampaignStateType {
  campaignId: null | string;
  currentStepName: NewCampaignSteps;
  prospectData: {
    fileUrl: null | string;
    columnHeaders: string[];
    sampleProspectData: string[];
    totalProspects: number;
  };
  prospectSummary: {
    total: number;
    failed: number;
    failedCsvLink: string;
    added: number;
  };
  selectedMailBoxesIds: string[];
}

export const CAMPAIGN_INITIAL_STATE: CampaignStateType = {
  campaignId: null,
  currentStepName: NewCampaignSteps.ONBOARDING,
  prospectData: {
    fileUrl: null,
    columnHeaders: [],
    sampleProspectData: [],
    totalProspects: 0
  },
  prospectSummary: {
    total: 0,
    failed: 0,
    failedCsvLink: "",
    added: 0
  },
  selectedMailBoxesIds: []
};

const NewCampaignFlow = ({ onCancel, isFromAddCampaign, afterSave }: NewCampaignFlowProps) => {
  const {
    campaignId: campaignIdInLS = CAMPAIGN_INITIAL_STATE.campaignId,
    currentStepName: currentStepNameInLs = CAMPAIGN_INITIAL_STATE.currentStepName,
    prospectData: prospectDataFromLs = CAMPAIGN_INITIAL_STATE.prospectData,
    prospectSummary: prospectSummaryFromLs = CAMPAIGN_INITIAL_STATE.prospectSummary,
    selectedMailBoxesIds: selectedMailBoxIdsFromLs = CAMPAIGN_INITIAL_STATE.selectedMailBoxesIds
  } = (LocalStorage.getValue(NEW_CAMPAIGN_LS_KEY) as CampaignStateType) || {};
  const location = useLocation<{ addEmailState?: string; email?: string; lastname?: string; firstname?: string }>();
  const history = useHistory();
  const [selectedMailBoxesIds, setSelectedMailBoxIds] = useState<string[]>(selectedMailBoxIdsFromLs);
  const [currentStepName, setCurrentStepName] = useState(currentStepNameInLs as NewCampaignSteps);

  const [showConnectMailboxModal, setShowConnectMailboxModal] = useState(false);
  const [providerType, setProviderType] = useState("");
  const [socket, setSocket] = useState<Socket | null>(null);
  const [publicDns, setPublicDns] = useState("");

  const [providers, setProviders] = useState("");
  const [mailBoxOfficeSmtp] = Form.useForm();
  const [accessCodeValidation] = Form.useForm();
  const [alernateEmailValidation] = Form.useForm();
  const [updatePasswordform] = Form.useForm();
  const [showConnectEmailValidationModal, setshowConnectEmailValidationModal] = useState(false);
  const [showConnectMicrosoftValidation, setshowConnectMicrosoftValidationl] = useState(false);
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [isEmailValidation, setisEmailValidation] = useState(false);
  const [isWrongPassword, setisWrongPassword] = useState(false);
  const [isVerifyEmail, setisVerifyEmail] = useState(false);
  const [editableId, setEditableId] = useState("");
  const [showTextVerifyModal, setshowTextVerifyModal] = useState(false);
  const [showText, setShowText] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [task, setTask] = useState("");
  const [instanceId, setInstanceId] = useState("");
  const [browserId, setBrowserId] = useState("");
  const [showUpdatePasswordModal, setshowUpdatePasswordModal] = useState(false);
  const [campaignId, setCampaignId] = useState(campaignIdInLS);
  const [prospectData, setProspectData] = useState(prospectDataFromLs);
  const [showConnectMailStmpModal, setshowConnectMailStmpModal] = useState(false);
  const [isWrongEmail, setisWrongEmail] = useState(false);
  const [isInBoundConnection, setisInBoundConnection] = useState(false);
  const [isOutBoundConnection, setisOutBoundConnection] = useState(false);
  const [isBoundConnection, setisBoundConnection] = useState(false);
  const [showManualGmailLogin, setManualGmailLogin] = useState(false);
  const [extensionStatus, setExtensionStatus] = useState("");
  const [installExtension, setInstallExtension] = useState(false);
  // const [smtpValidationStatus, setsmtpValidationStatus] = useState('');
  // const [smtpValidationError, setsmtpValidationError] = useState('');
  const [showMailBoxProviderDetailModal, setShowMailBoxProviderDetailModal] = useState(false);
  const [prospectSummary, setProspectSummary] = useState(prospectSummaryFromLs);
  const [nextButton, setNextButton] = useState({
    label: "Next",
    show: true,
    disabled: false
  });
  const [backButton, setBackButton] = useState({
    label: "back",
    show: false,
    disabled: false
  });
  const [skipButton, setSkipButton] = useState({
    label: "back",
    show: false,
    disabled: false
  });
  const [mailBoxes, setMailBoxes] = useState([]);
  const [isMailboxExist, setIsMailboxExist] = useState(false);
  const [exisMailMessage, setExisMailMessage] = useState("");
  const [isEditModeForWorkspacePersonal, setEditModeWhileProviderWorkspacePersonal] = useState(false);

  const { callApi, uploadFileToS3 } = useApi();
  const { setLoadingMessage } = useContext(appContext);
  const [mailBoxForm] = Form.useForm();
  const [prospectsUploadForm] = Form.useForm();
  const [prospectMappingForm] = Form.useForm();
  const [prospectMappingFormSecondary] = Form.useForm();
  const [prospectDedupeCamapiaign] = Form.useForm();
  const [prospectApplyDnc] = Form.useForm();

  const [campaignSetupForm] = Form.useForm();
  const [mailboxSettingsForm] = Form.useForm();
  const [mailBoxProviderForm] = Form.useForm();
  const [subform] = Form.useForm();
  const [otherform] = Form.useForm();
  const [smtpInBoundError, setSmtpInBoundError] = useState("");
  const [smtpOutBoundError, setSmtpOutBoundError] = useState("");
  const [validating, setValidating] = useState(false);
  const [connectModalStep, setStepWhenConnectionModalClosed] = useState(0);
  const [dedupeStateData, setDedupeState] = useState<any>(null);
  const [proxy, setProxy] = useState<Proxy>({
    id: "",
    host: "",
    port: "",
    user: "",
    pwd: ""
  });
  const [showTroubleShootModal, setShowTroubleShootModal] = useState(false);
  const [showGmailConfirmationMailModal, setshowGmailConfirmationMailModal] = useState(false);
  const [show2StepVerificationModal, setshow2StepVerificationModal] = useState(false);
  const [subMessage, setSubMessage] = useState("");
  const [isVerifyEmailForGmail, setisVerifyEmailForGmail] = useState(false);
  const [showVerificationOptionsModal, setVerificationOptionsModal] = useState(false);
  const [customFieldsDedupe, setColumnsSecondaryForm] = useState<any>(null);
  const [showGsuiteMail, setShowGsuiteModal] = useState(false)
  const [gsuiteSetupModlal, setOpenSetupModal] = useState(false)
  const [settingModal, setSettingModal] = useState(true)

  // const [isSettingSmtp, setIsSettingSmtp] = useState(false);
  const [filedMapping, setFieldMapping] = useState<{ primary: { [x: string]: number } }>({
    primary: {}
  });
  const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";

  const [Data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    imapUserName: "",
    imapPassword: ""
  });
  const [isIMAPPart, setIsIMAPPart] = useState(false);
  const localStorageValues = LocalStorage.getValue(OAUTH_STATE_LS_KEY);

  const oauthState = useMemo(() => {
    const oauthLocalState: {
      state: string;
    } | null = localStorageValues;

    if (location.state?.addEmailState && oauthLocalState?.state && location.state?.addEmailState === oauthLocalState.state) {
      return oauthLocalState.state;
    }
    return "";
  }, [location, localStorageValues]);

  const disableNextButton = useCallback(() => setNextButton((prevState) => ({ ...prevState, disabled: true })), [setNextButton]);

  const enableNextButton = useCallback(() => setNextButton((prevState) => ({ ...prevState, disabled: false })), [setNextButton]);
  const handleRecoveryEmailModal = () => setshowGmailConfirmationMailModal((show) => !show);
  const handleVerificationModal = () => setshow2StepVerificationModal((show) => !show);
  const handleVerificationOptionsModal = () => setVerificationOptionsModal((show) => !show);
  const handleTroubleShootModal = () => setShowTroubleShootModal((show) => !show);
  const handleManualGmailLoginModal = () => setManualGmailLogin((show) => !show);

  const [disableUploadCSV, setDisableUploadCSV] = useState(false);
  const urlState = location?.state;
  useEffect(() => {
    if (!isEmpty(urlState)) {
      const localStore = localStorageValues;
      if (localStore?.provider === MailboxTypeEnum.OFFICE_OAUTH) {
        setProviderType(localStore?.provider);
        // setCurrentStepName(NewCampaignSteps.PROSPECT_UPLOAD)
      }
      if (localStore?.provider === MailboxTypeEnum.GSUITE) {
        setProviderType("GOOGLE");
        // setCurrentStepName(NewCampaignSteps.PROSPECT_UPLOAD)
      }
      setData((prevState) => ({
        ...prevState,
        email: urlState?.email || "",
        firstName: urlState?.firstname || "",
        lastName: urlState?.lastname || ""
      }));
      if (localStore?.provider) {
        setProviders(localStore?.provider);
      }
    }
  }, [urlState]);


  const getDnsPublicDnsUrl = useCallback(
    async (email: string, password: string) => {
      let payload = {
        email: email,
        password: password,
        inboxProvider: "GOOGLE",
        test: false
      };
      let response;
      if (window.location && window.location.origin.includes("localhost")) {
        response = {
          success: true,
          data: {
            browserId: uuid(),
            publicDNS: "localhost",
            instanceId: "i-0864145ea18867fcb",
            proxy: {
              host: "135.148.125.52",
              id: uuid(),
              port: "8181",
              pwd: "vendisys@135",
              user: "vendisys"
            }
          }
        };
      } else {
        const { url, method } = API_ENDPOINTS.DNSCONNECTION.getListUrl();
        response = await callApi({
          url,
          method,
          data: payload,
          external: true
        });
      }
      // let publicDns = 'https://brooklyn.outwinhq.io';
      let instanceId = "";
      let browserIds = "";
      let proxyServer: Proxy = {} as Proxy;
      if (response.success && response.data) {
        instanceId = response.data.instanceId;
        // publicDns = `https://brooklyn.outwinhq.io`;
        browserIds = response.data.browserId;
        setBrowserId(browserIds);
        if (response.data && response.data.proxy) {
          proxyServer = response.data?.proxy;
          setProxy(proxyServer);
        }
        // setPublicDns(publicDns);
        setInstanceId(instanceId);
      } else {
        setLoadingMessage("");
        notification.error({
          message: "Server resources are currently being allocated. Please try again after 5 minutes."
        });
      }
      return { browserId: browserIds, instanceId, proxy: proxyServer };
    },
    [callApi, setProxy, setBrowserId, setInstanceId]
  );

  const onOptionSelectHandler = useCallback(
    async (formValues: any) => {
      setLoadingMessage("Connect to the Mailbox");
      if (!formValues) {
        notification.error({
          message: "Please select any one option"
        });
        setLoadingMessage("");
      } else {
        if (formValues === "recovery") {
          let data = {
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "G_recovery_email_option",
            browserId
          };
          await performTask(data);
        } else {
          let data = {
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "G_access_code_on_email_option",
            browserId
          };
          await performTask(data);
        }
      }
    },
    [setLoadingMessage, Data, instanceId]
  );

  const { Title } = Typography;

  const remainingProspectData = useMemo(() => {
    const selectedIndexes = Object.keys(filedMapping.primary).reduce((acc, key) => ({ ...acc, [filedMapping.primary[key]]: true }), {}) as {
      [key: string]: boolean;
    };

    const availProspectData = {
      columnHeaders: prospectData.columnHeaders.map((col, idx) => ({ header: col, index: idx })).filter((_, idx: number) => !selectedIndexes[idx])
    };
    return availProspectData;
  }, [filedMapping.primary, prospectData]);

  const stepNumber = useMemo(() => {
    switch (currentStepName) {
      case NewCampaignSteps.ONBOARDING:
        return -1;
      case NewCampaignSteps.MAILBOX_LISTING:
        return 0;
      case NewCampaignSteps.PROSPECT_UPLOAD:
      case NewCampaignSteps.PROSPECT_COLUMN_MAPPING:
      case NewCampaignSteps.PROSPECT_COLUMN_SELECT:
        // case NewCampaignSteps.PROSPECT_SUMMARY:
        return 1;
      case NewCampaignSteps.PROSPECT_DEDUPE_SELECT:
      case NewCampaignSteps.APPLY_DNC:
        return 2;
      case NewCampaignSteps.SETUP:
        return 3;
    }
  }, [currentStepName]);

  const onNext = useCallback(async () => {
    switch (currentStepName) {
      case NewCampaignSteps.ONBOARDING:
        setCurrentStepName(NewCampaignSteps.MAILBOX_LISTING);
        break;
      case NewCampaignSteps.MAILBOX_LISTING:
        mailBoxForm.submit();
        break;
      case NewCampaignSteps.MAILBOX_PROVIDER:
        if (providers === MailboxTypeEnum.OFFICE_BROWSER) {
          mailBoxOfficeSmtp.resetFields();
          setshowConnectMailStmpModal(true);
        } else if (providers === MailboxTypeEnum.OFFICE_OAUTH) {
          setShowConnectMailboxModal(false);
          mailBoxProviderForm.submit();
        }
        else if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
          subform.resetFields();
          otherform.resetFields();
          setShowConnectMailboxModal(true);
        } else {
          mailBoxProviderForm.submit();
          otherform.resetFields();
          setShowConnectMailboxModal(false);
        }
        break;
      case NewCampaignSteps.MAILBOX_SETTINGS:
        mailboxSettingsForm.submit();
        break;
      case NewCampaignSteps.PROSPECT_UPLOAD:
        prospectsUploadForm.submit();
        break;
      case NewCampaignSteps.PROSPECT_COLUMN_MAPPING:
        prospectMappingForm.submit();
        break;
      case NewCampaignSteps.PROSPECT_COLUMN_SELECT:
        prospectMappingFormSecondary.submit();
        break;
      // case NewCampaignSteps.PROSPECT_SUMMARY:
      //   setCurrentStepName(NewCampaignSteps.PROSPECT_DEDUPE_SELECT);
      //   break;
      case NewCampaignSteps.PROSPECT_DEDUPE_SELECT:
        prospectDedupeCamapiaign.submit();
        break;
      case NewCampaignSteps.APPLY_DNC:
        prospectApplyDnc.submit();
        break;
      case NewCampaignSteps.SETUP:
        campaignSetupForm.submit();
        break;
      default:
        return currentStepName;
    }
  }, [
    currentStepName,
    mailBoxForm,
    mailBoxOfficeSmtp,
    providers,
    setshowConnectMailStmpModal,
    mailboxSettingsForm,
    prospectsUploadForm,
    prospectMappingForm,
    campaignSetupForm,
    subform,
    mailBoxProviderForm,
    prospectMappingFormSecondary,
    prospectDedupeCamapiaign,
    prospectApplyDnc,
    setShowGsuiteModal
  ]);

  const onBack = () => {
    setCurrentStepName((prevStepName) => {
      switch (prevStepName) {
        case NewCampaignSteps.MAILBOX_LISTING:
          return NewCampaignSteps.ONBOARDING;
        case NewCampaignSteps.MAILBOX_PROVIDER:
          return NewCampaignSteps.MAILBOX_LISTING;
        case NewCampaignSteps.PROSPECT_COLUMN_MAPPING:
          return NewCampaignSteps.PROSPECT_UPLOAD;
        case NewCampaignSteps.PROSPECT_COLUMN_SELECT:
          return NewCampaignSteps.PROSPECT_COLUMN_MAPPING;
        case NewCampaignSteps.PROSPECT_DEDUPE_SELECT:
          return NewCampaignSteps.APPLY_DNC;
        case NewCampaignSteps.APPLY_DNC:
          return NewCampaignSteps.PROSPECT_UPLOAD;
        case NewCampaignSteps.MAILBOX_SETTINGS:
          return NewCampaignSteps.MAILBOX_PROVIDER;
        // case NewCampaignSteps.PROSPECT_SUMMARY:
        //   return NewCampaignSteps.PROSPECT_UPLOAD;
        default:
          return prevStepName;
      }
    });
  };
  const toggleShowModalSmtp = () => setshowConnectMailStmpModal((show) => !show);
  const onMailBoxFormSubmit = useCallback(
    async ({ selectedMailBoxes: inboxIds }) => {
      setShowConnectMailboxModal(false);
      if (inboxIds && !inboxIds.length) {
        notification.error({
          message: "No mailbox selected",
          description: "Select atleast one mailbox to continue"
        });
        return;
      }

      const payload = {
        name: `New Campaign ${moment(new Date()).format("YYYY-MM-DD, H:mm A")}`,
        imageName: getRandomAvatar(),
        accountId: String(localStorage.getItem("account_id")),
        // inboxIds,
        active: false
      };

      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getCreateUrl();

      setLoadingMessage("Creating a new campaign ...");

      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        setCampaignId(response.data.id);
        setSelectedMailBoxIds(inboxIds);
        setCurrentStepName(NewCampaignSteps.PROSPECT_UPLOAD);
        const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(response.data.id);
        await callApi({ url, method, data: { inboxIds, action: "InboxAdd" } });
      } else {
        setIsMailboxExist(true);
        setExisMailMessage(response.error);
      }
      setLoadingMessage("");
    },
    [setCurrentStepName, setLoadingMessage, callApi]
  );

  const onUploadCsvFormSubmit = useCallback(
    async ({ columnHeaders, file, prospects }: CSVContentsType) => {
      disableNextButton();
      setDisableUploadCSV(true);
      const { fileUrl } = await uploadFileToS3(file);
      setDisableUploadCSV(false);
      if (fileUrl) {
        setProspectData({
          fileUrl,
          columnHeaders,
          sampleProspectData: prospects[0],
          totalProspects: prospects.length
        });
        setCurrentStepName(NewCampaignSteps.PROSPECT_COLUMN_MAPPING);
        return;
      }
      enableNextButton();
    },
    [uploadFileToS3, disableNextButton, enableNextButton]
  );

  const delay = (timeout: number) => new Promise((resolve) => setTimeout(() => resolve(true), timeout));

  // const callUpdateSuccessResponse: any = useCallback(
  //   async (requestId: string, endTime: number) => {
  //     const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getUploadStatus(requestId);

  //     const response = await callApi({
  //       url,
  //       method
  //     });

  //     if (response.success) {
  //       if (response.data.status === "COMPLETED") {
  //         const { failed, total, failedCsvLink } = response.data;

  //         setProspectSummary({
  //           added: total - failed,
  //           failed: failed,
  //           total: total,
  //           failedCsvLink: failedCsvLink ? failedCsvLink : ""
  //         });
  //         setCurrentStepName(NewCampaignSteps.PROSPECT_SUMMARY);

  //         return;
  //       }
  //       if (response.data.status === "FAILED") {
  //         notification.error({
  //           message: response.data && response.data.error ? response.data.error : "Something went wrong"
  //         });
  //         return;
  //       }
  //       if (response.data.status !== "COMPLETED" || response.data.status !== "FAILED") {
  //         if (endTime - new Date().getTime() > 0) {
  //           await delay(10_000);
  //           return await callUpdateSuccessResponse(requestId, endTime);
  //         }
  //       }
  //     }
  //   },
  //   [callApi]
  // );
  // olf function before dedupe
  // const onMapProspectColumnsSecondaryFormSubmit = useCallback(
  //   async (prospectMapping: ProspectMappingType, standardFields?: typeof filedMapping["primary"]) => {
  //     if (!campaignId) {
  //       return;
  //     }
  //     standardFields = standardFields ?? filedMapping.primary;
  //     const { fileUrl: key } = prospectData;
  //     const { columnMappings } = prospectMapping;
  //     const customFields = columnMappings.map((col) => col.outwinField);
  //     const payload = {
  //       key,
  //       standardFields: standardFields,
  //       crossFileMappings: [],
  //       customFields
  //     };

  //     setLoadingMessage("Adding prospects to the campaign ...");
  //     const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getUploadUrl(campaignId);

  //     const response = await callApi({
  //       url,
  //       method,
  //       data: payload
  //     });

  //     if (response && response.success) {
  //       let toDate = new Date();
  //       let nextTime = toDate.getTime() + 2 * 60 * 1000;
  //       // setLoadingMessage("File Uploading is in progress");
  //       await callUpdateSuccessResponse(response.data.requestId, nextTime);
  //       // const {
  //       //   failedProspects,
  //       //   failedProspectsS3Url: failedCsvLink,
  //       //   totalProspects,
  //       // } = response.data;

  //       // setProspectSummary({
  //       //   added: totalProspects - failedProspects,
  //       //   failed: failedProspects,
  //       //   total: totalProspects,
  //       //   failedCsvLink,
  //       // });
  //       // setCurrentStepName(NewCampaignSteps.PROSPECT_SUMMARY);
  //     }
  //     setLoadingMessage("");
  //   },
  //   [campaignId, callApi, prospectData, callUpdateSuccessResponse, setLoadingMessage, filedMapping]
  // );

  const onMapProspectColumnsSecondaryFormSubmit = useCallback(
    async (prospectMapping: ProspectMappingType) => {
      if (!campaignId) {
        return;
      }
      const { columnMappings } = prospectMapping;
      const customFields = columnMappings.map((col) => col.outwinField);
      setColumnsSecondaryForm(customFields);
      setCurrentStepName(NewCampaignSteps.PROSPECT_DEDUPE_SELECT);
    },
    [campaignId]
  );

  const onMapProspectDedupeFormSubmit = async (dedupeStates: any, isFromSkip?: boolean) => {
    console.log("campaignId______", campaignId, "uId______",uId)
    if (!campaignId || !uId) {
      return;
    }
    if (!isFromSkip) {
      setDedupeState(dedupeStates);
    }
    setCurrentStepName(NewCampaignSteps.APPLY_DNC);
  };

  const onMapProspectApplyDncFormSubmit = async (applyDncStates: any, isFromSkip?: boolean) => {
    if (!campaignId || !uId) {
      return;
    }


    const { fileUrl: key } = prospectData;
    const standardFields = filedMapping.primary;

    // const { columnMappings } = prospectMapping;
    // const customFields = columnMappings.map((col) => col.outwinField);
    const payload = {
      key,
      standardFields: standardFields,
      crossFileMappings: [],
      customFields: customFieldsDedupe,
      dedupeAllCampaigns: isFromSkip ? false : dedupeStateData?.dedupeAllCampaigns,
      dedupeCampIds: isFromSkip ? [] : dedupeStateData?.dedupeCampIds ? dedupeStateData?.dedupeCampIds : [],
      applyDnc: applyDncStates?.applyDnc,
      userId: uId
    };
    // stopped to save data here before dedupe process
    setLoadingMessage("Adding prospects to the campaign ...");
    const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getUploadUrl(campaignId);

    const response = await callApi({
      url,
      method,
      data: payload
    });
    if (response && response.success) {
      // let toDate = new Date();
      // let nextTime = toDate.getTime() + 2 * 60 * 1000;
      // setLoadingMessage("File Uploading is in progress");
      // dedupe stopped calling till the dedupe case satisfy
      // notification.success({
      //   message: "Thank you, we will notify via email once upload is complete"
      // });
      // await callUpdateSuccessResponse(response.data.requestId, nextTime);
      const { failedProspects, failedProspectsS3Url: failedCsvLink, totalProspects } = response.data;
      setProspectSummary({
        added: totalProspects - failedProspects,
        failed: failedProspects,
        total: totalProspects,
        failedCsvLink
      });
      // setCurrentStepName(NewCampaignSteps.PROSPECT_SUMMARY);
      setCurrentStepName(NewCampaignSteps.SETUP);
      setLoadingMessage("");
    }
    setLoadingMessage("");
  };

  console.log("campaignIdcampaignId", campaignId)
  const onMapProspectColumnsFormSubmit = useCallback(
    async (prospectMapping: ProspectMappingType) => {
      const { columnMappings } = prospectMapping;
      if (!campaignId) {
        return;
      }

      const fieldMappings = columnMappings.reduce(
        (acc, { columnNumber, outwinField }) => ({
          ...acc,
          [outwinField]: columnNumber
        }),
        {}
      ) as typeof filedMapping["primary"];

      setFieldMapping({ primary: fieldMappings });
      const secondaryColExist = prospectData.columnHeaders.length > columnMappings.filter((co) => co.columnNumber !== -1).length;
      if (secondaryColExist) {
        setCurrentStepName(NewCampaignSteps.PROSPECT_COLUMN_SELECT);
      } else {
        // applied dedupe to load here
        setCurrentStepName(NewCampaignSteps.PROSPECT_DEDUPE_SELECT);
        // onMapProspectColumnsSecondaryFormSubmit({ columnMappings: [] }, fieldMappings);
      }
    },
    [campaignId, prospectData]
  );

  const abortCampaignFlow = useCallback(() => {
    onCancel();
    LocalStorage.deleteKey(NEW_CAMPAIGN_LS_KEY);
  }, [onCancel]);

  const onCampainCreationEnd = useCallback(
    (campaignId?: string) => {
      abortCampaignFlow();

      if (campaignId) {
        history.push(`/campaigns/${campaignId}`);
      }
    },
    [abortCampaignFlow, history]
  );

  const onCampaignSetupFormSubmit = useCallback(
    async (settings: CampaignSettingsType) => {
      if (!campaignId) {
        return;
      }
      setLoadingMessage("Setting up the campaign ...");
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(campaignId);

      const response = await callApi({
        url,
        method,
        data: {
          ...settings,
          imageName: getRandomAvatar(),
          inboxIds: selectedMailBoxesIds,
          accountId: String(localStorage.getItem("account_id"))
        }
      });
      setLoadingMessage("");

      if (response.success) {
        notification.destroy();
        notification.success({
          message: "Campaign created",
          description: "Your campaign has been created."
        });
        notification.success({
          message: "Thank you, we will notify via email once prospects have been created."
        });
        onCampainCreationEnd(campaignId);
      }
    },
    [callApi, campaignId, onCampainCreationEnd, setLoadingMessage, selectedMailBoxesIds]
  );

  const getTaskFromAPI = useCallback(
    (task: string) => {
      setLoadingMessage("");
      switch (task) {
        case "wrong_password":
          setisWrongPassword(true);
          notification.error({
            message: "Your Password is Wrong"
          });
          break;
        case "verify_email":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(false);
          setisVerifyEmail(true);
          setshowConnectEmailValidationModal(true);
          break;
        case "error_notify":
          if (errorMsg.length) {
            notification.error({
              message: errorMsg
            });
          }
          break;
        case "wrong_email_account":
          setisWrongEmail(true);
          if (errorMsg.length) {
            notification.error({
              message: errorMsg
            });
          }
          break;
        case "accountLock":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(true);
          if (errorMsg.length) {
            notification.error({
              message: errorMsg
            });
          }
          break;
        case "phoneNoCheckVerify":
          setshowConnectMailStmpModal(false);
          setshowTextVerifyModal(true);
          break;
        case "invalidPhoneNo":
          //setisWrongPassword(true);
          if (errorMsg.length) {
            notification.error({
              message: errorMsg
            });
          }
          break;
        case "update_password":
          setisWrongPassword(false);
          setShowAccessCodeModal(false);
          setshowUpdatePasswordModal(true);
          break;
        case "accessCode":
          setisEmailValidation(false);
          setshowConnectMicrosoftValidationl(false);
          setShowAccessCodeModal(true);
          break;
        case "code_screen":
          setisEmailValidation(true);
          setshowConnectEmailValidationModal(false);
          setShowAccessCodeModal(true);
          break;
        case "login_success":
          // setshowEmailAccessCodeModal(false);
          setShowConnectMailboxModal(false);
          setisEmailValidation(false);
          setisVerifyEmail(false);
          setisVerifyEmailForGmail(false);
          setshowConnectMailStmpModal(false);
          setShowAccessCodeModal(false);
          setshowGmailConfirmationMailModal(false);
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setExtensionStatus("");
          setManualGmailLogin(false);
          setCurrentStepName(NewCampaignSteps.MAILBOX_SETTINGS);
          break;
        case "gmail_recover_email":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(false);
          setVerificationOptionsModal(false);
          setisVerifyEmail(true);
          setisVerifyEmailForGmail(true);
          setshowGmailConfirmationMailModal(true);
          break;
        case "gamil_2_step_verification":
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(true);
          break;
        case "gmail_invalid_email":
          setisWrongEmail(true);
          setisWrongPassword(false);
          showError(errorMsg);
          break;
        case "gmail_process_again":
          setshowConnectMailStmpModal(true);
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(false);
          showError(errorMsg);
          break;
        case "gmail_wrong_password":
          setisWrongPassword(true);
          setisWrongEmail(false);
          showError(errorMsg);
          break;
        case "gamil_login_success":
          setshowConnectMailStmpModal(false);
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(false);
          setShowAccessCodeModal(false);
          setCurrentStepName(NewCampaignSteps.MAILBOX_SETTINGS);
          break;
        case "G_ChooseVerification":
          setisEmailValidation(false);
          setVerificationOptionsModal(false);
          setshowConnectMicrosoftValidationl(false);
          setShowAccessCodeModal(true);
          break;
        case "G_verification_options":
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setVerificationOptionsModal(true);
          break;
        case "unable_to_connect":
          setShowConnectMailboxModal(false);
          setisEmailValidation(false);
          setisVerifyEmail(false);
          setisVerifyEmailForGmail(false);
          setshowConnectMailStmpModal(false);
          setShowAccessCodeModal(false);
          setshowGmailConfirmationMailModal(false);
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setShowTroubleShootModal(true);
          break;
        case "navigation_timeout":
          setExtensionStatus("installed");
          setManualGmailLogin(true);
          break;
        default:
          break;
      }
    },
    [task, errorMsg, setCurrentStepName]
  );

  useEffect(() => {
    if (task) {
      getTaskFromAPI(task);
    }
  }, [getTaskFromAPI, task, errorMsg]);

  const showError = (error: any) => {
    if (error && error.length) {
      notification.error({
        message: error
      });
    }
  };

  useEffect(() => {
    const isIMAPEnable =
      MailboxTypeEnum.SENDGRID === getProviderDetails(providers)?.type ||
      MailboxTypeEnum.MAIL_GUN === getProviderDetails(providers)?.type ||
      MailboxTypeEnum.TECHCOMPILER === getProviderDetails(providers)?.type;
    setIsIMAPPart(isIMAPEnable);
  }, [providers]);

  useEffect(() => {
    return () => {
      socket?.disconnect();
    };
  }, []);

  const onAddNewMailbox = useCallback(() => {
    setCurrentStepName(NewCampaignSteps.MAILBOX_PROVIDER);
  }, [setCurrentStepName]);

  const toggleMailBoxProviderDatialDialog = () => setShowMailBoxProviderDetailModal((prev) => !prev);

  const gotoBackButton = () => {
    // setShowMailBoxProviderModal((prev) => !prev);
    setShowMailBoxProviderDetailModal((prev) => !prev);
  };

  const onMailboxProviderContinue = useCallback(
    async (provider: MailboxTypeEnum, email: string, isFromGsuiteCall?: boolean) => {
      console.log("onMailboxProviderContinueonMailboxProviderContinue", provider, email,isFromGsuiteCall)
      // if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
      //   mailBoxOfficeSmtp.resetFields();
      //   setShowMailBoxProviderDetailModal((prev) => !prev);
      //   return;
      // }
      // setLoadingMessage("Connecting to mailbox...");
      // if (!getProviderDetails(provider)?.smtpImapDetailsKnown) {
      //   setshowConnectMailStmpModal((prev) => !prev);
      //   setProviderType(provider);
      //   setLoadingMessage("");
      //   return;
      // }
      if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
        setLoadingMessage("Connecting to mailbox...");
      } else if (provider === MailboxTypeEnum.GSUITE && !isFromGsuiteCall) {
        setShowGsuiteModal(true);
        return;
      }
      else if (provider === MailboxTypeEnum.OFFICE_SMTP) {
        mailBoxOfficeSmtp.resetFields();
        setshowConnectMailStmpModal((prev) => !prev);
        return;
      } else if (!getProviderDetails(provider)?.smtpImapDetailsKnown) {
        setShowMailBoxProviderDetailModal((prev) => !prev);
        return;
      } else if(provider !== MailboxTypeEnum.GSUITE){
        setLoadingMessage("Connecting to mailbox...");
        subform.resetFields();
        setShowConnectMailboxModal((prev) => !prev);
      }
      const state = uuid();
      if (provider === MailboxTypeEnum.GSUITE) {
        const { url, method } = API_ENDPOINTS.OAUTH.getGoogleOauthUrl({
          provider: 'GOOGLE',
          state,
          reconnect: false
        });
        // Get oauth url
        const response = await Axios.callApi({
          method: method,
          url: `${WHISPER_BASE_URL}${url}`
        });
        if (response.success) {

          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            state,
            redirectTo: `/account/${String(localStorage.getItem("account_id"))}/campaigns`,
            email,
            provider: provider
          });

          try {
            const { oauth_url } = response.data;
            window.location.assign(oauth_url);
          } catch (err) {
            notification.error({
              message: "Something went wrong!"
            });
            setLoadingMessage("");
          }
          return;
        } else {
          LocalStorage.resetKey(OAUTH_STATE_LS_KEY)
        }
      } else {
        const { url, method } = API_ENDPOINTS.OAUTH.getOauthUrl({
          provider,
          state
        });
        const response = await Axios.callApi({
          method: method,
          url: `${WHISPER_BASE_URL}${url}`
        });
        // const response = await callApi({ url, method });

        if (response.success) {
          // setCurrentStepName(NewCampaignSteps.PROSPECT_UPLOAD)
          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            state,
            redirectTo: `/account/${String(localStorage.getItem("account_id"))}/campaigns`,
            email,
            provider: provider
          });
          try {
            const { oauth_url } = response.data;
            window.location.assign(oauth_url);
          } catch (err) {
            notification.error({
              message: "Something went wrong!"
            });
          }
        }
      }
      setLoadingMessage("");
    },
    [callApi, setLoadingMessage]
  );

  const updateSpotBrowser = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.DNSCONNECTION.getCreateUrl();
    let payload = { browserId: browserId };
    const response = await callApi({
      url,
      method,
      data: payload,
      external: true
    });

    if (response.success) {
    } else {
    }
  }, [browserId]);

  const onMailBoxSettingsSave = useCallback(
    async (settings: MailBoxSettingsType) => {
      console.log("hiiiiiiiiiiiiiiiii")
      const { minEmails, maxEmails, incrementPercent, signature, timeZone, days, startHour, endHour, firstName, lastName, replyTo } = settings;
      const { url, method } = API_ENDPOINTS.MAILBOX.getCreateUrl();
      let payload = {};
      const providerInfo = getProviderDetails(providers);
      if (providers !== MailboxTypeEnum.AMAZON) {

        payload = {
          state: oauthState,
          minEmails,
          maxEmails,
          incrementPercent,
          signature,
          firstname: firstName && firstName.length ? firstName : Data.firstName,
          lastname: lastName && lastName.length ? lastName : Data.lastName,
          timeZone,
          days,
          startHour,
          endHour,
          accountId: String(localStorage.getItem("account_id")),
          email: Data.email,
          username: Data.email,
          password: Data.password,
          protocol: providerInfo?.protocol,
          provider: providerInfo?.providerName,
          replyTo
        };
      } else {
        payload = {
          ...payload,
          state: oauthState
        };
      }

      setLoadingMessage("Linking new mailbox ...");

      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        setCurrentStepName(NewCampaignSteps.PROSPECT_UPLOAD);
        LocalStorage.resetKey(OAUTH_STATE_LS_KEY);
        // history.replace("/campaigns");
        setLoadingMessage("");
        // get new campaign id to create new 
        if (!campaignId) {
          const payload = {
            name: `New Campaign ${moment(new Date()).format("YYYY-MM-DD, H:mm A")}`,
            imageName: getRandomAvatar(),
            accountId: String(localStorage.getItem("account_id")),
            active: false
          };
          const { url, method } = API_ENDPOINTS.CAMPAIGNS.getCreateUrl();
          setLoadingMessage("Creating a new campaign ...");
          const res = await callApi({
            url,
            method,
            data: payload
          });
          if (res.success) {
            setCampaignId(res.data.id);
            const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(res.data.id);
            await callApi({ url, method, data: { inboxIds: [response.data.inbox.id], action: "InboxAdd" } });
          }
        }
        // return;
      }
      // Save settings
      if (providers === MailboxTypeEnum.OFFICE_BROWSER) await updateSpotBrowser(); // todo: NC verify
      setLoadingMessage("");
    },
    [Data, callApi, history, oauthState, providers, setLoadingMessage, updateSpotBrowser]
  );

  const toggleShowModal = () => {
    setShowConnectMailboxModal((show) => !show);
    setEditModeWhileProviderWorkspacePersonal(false);
  };
  const handleVerifyEmailByPhoneNo = () => setshowConnectMicrosoftValidationl((show) => !show);
  const toggleShowModalEmailValidation = () => setshowConnectEmailValidationModal((show) => !show);
  const handleVerifyAccessCodeModal = () => setShowAccessCodeModal((show) => !show);
  const toggleVerifyPhoneNoForm = () => setshowTextVerifyModal((show) => !show);
  const handleUpdatePasswordModal = () => setshowTextVerifyModal((show) => !show);

  const submitPhoneNoToVerify = async (formValues: any) => {
    setLoadingMessage("verifying Phone Number.");
    const { phone, prefix } = formValues;

    let data = {
      email: Data.email,
      instanceId: instanceId,
      phoneNo: phone,
      countryCode: prefix,
      site: "OUTWIN",
      task: "phoneNoCheck"
    };
    await performTask(data);
  };

  const submitAccessCode = async (formValues: any) => {
    setLoadingMessage("verifying Access Code.");
    const { accessCode } = formValues;
    if (providers === MailboxTypeEnum.GMAILONE) {
      let data = {
        email: Data.email,
        instanceId: instanceId,
        accessCode: accessCode,
        site: "OUTWIN",
        task: "G_EmailVerificationCode",
        type: "browserGmail"
      };
      await performTask(data);
    } else {
      if (isEmailValidation) {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          accessCode: accessCode,
          site: "OUTWIN",
          task: "enterTheCode"
        };
        await performTask(data);
      } else {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          accessCode: accessCode,
          site: "OUTWIN",
          task: "enterAccessCode"
        };
        await performTask(data);
      }
    }
  };

  const submitMicroSoftEmail = useCallback(
    async (formValues: any) => {
      setLoadingMessage("verifying Email.");
      const { alternate_email } = formValues;
      if (isVerifyEmail) {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          alternate_email: alternate_email.split("@")[0],
          site: "OUTWIN",
          task: "verify_alterEmail"
        };

        await performTask(data);
      } else {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          alternate_email: alternate_email,
          site: "OUTWIN",
          task: "alternateEmail"
        };

        await performTask(data);
      }
    },
    [isVerifyEmail, setLoadingMessage]
  );

  const verifyPhoneNoForm = async (formValues: any) => {
    setLoadingMessage("Verifying your Phone No");
    const { phone, prefix } = formValues;
    let data = {
      email: Data.email,
      instanceId: instanceId,
      phoneNo: phone,
      site: "OUTWIN",
      task: "verifyPhoneNo"
    };
    await performTask(data);
  };

  const updatePassword = async (formValues: any) => {
    setLoadingMessage("Verifying your Phone No");
    const { Password } = formValues;
    let data = {
      email: Data.email,
      instanceId: instanceId,
      updatedPassword: Password,
      site: "OUTWIN",
      task: "enter_New_Password"
    };
    await performTask(data);
  };

  const getTask: any = useCallback(
    async (data: any, startTime: number = new Date().getTime(), currTime: number = new Date().getTime()) => {
      let { email, site, instanceId } = data;

      let payload = {
        email: email,
        site: site,
        instanceId: instanceId
      };
      const { url, method } = API_ENDPOINTS.OTHERS.browserBased(instanceId);
      const response = await callApi({
        url,
        method,
        data: payload,
        external: true
      });

      if (response && response.success) {
        const { data } = response;
        if (data && data.task && data.isSuccess) {
          if (data.task === "login_success") {
            let textMsg = data.msg;
            if (textMsg) {
              textMsg = textMsg.split(" ");
              if (textMsg.length) {
                let firstName = textMsg.shift();
                let lastName = textMsg.join(" ");
                setData((prevState) => ({
                  ...prevState,
                  lastName: lastName,
                  firstName: firstName
                }));
              }
            }
          }
          setTask(data.task);
          setShowText("");
          setErrorMsg("");
        } else if (data && data.task && data.isError) {
          if (data.msg && data.msg.length) {
            setErrorMsg(data.msg);
          }
          if (data.showText && data.showText.length) {
            setShowText(data.showText);
          }
          setTask(data.task);
        } else {
          setTask("");
          setShowText("");
          setErrorMsg("");
          await timeout(1000);
          if (currTime - startTime <= 1.5 * 60 * 1000) {
            return await getTask(payload, startTime);
          } else {
            setLoadingMessage("");
            notification.error({
              message: "Something went wrong"
            });
          }
        }
        if (
          data.task === "gmail_recover_email" ||
          data.task === "G_ChooseVerification" ||
          data.task === "gamil_2_step_verification" ||
          data.task === "G_verification_options"
        ) {
          setSubMessage(data.showText);
        }
      }
    },
    [setTask, setShowText, callApi]
  );

  const timeout = (ms: any) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const performTask = useCallback(
    async (data: any) => {
      if (!data.proxy || !Object.keys(data.proxy).length) {
        data.proxy = proxy;
      }
      let payload = {
        ...data
      };
      if (!payload.browserId) {
        payload.browserId = browserId;
      }
      setTask("");
      const { instanceId } = data;
      const { url, method } = API_ENDPOINTS.OTHERS.launchedBrowser(instanceId);
      const response = await callApi({
        url,
        method,
        data: payload,
        external: true
      });

      if (response.success) {
        await getTask(data);
        // }, 10000)
      } else {
        setTimeout(() => setLoadingMessage(""), 1000);
      }
    },
    [setTask, browserId, proxy, callApi]
  );

  const socketAndPerformLogin = useCallback(
    async (email: string, password: string, browserId: string, instanceId: string, proxy: Proxy) => {
      const data = {
        email: email,
        instanceId: instanceId,
        password: password,
        browserId: browserId,
        site: "OUTWIN",
        task: "login",
        proxy: proxy
      };
      await performTask(data);
      return;
    },
    [proxy, browserId]
  );

  const callProviderSetupSuccessResponse: any = useCallback(
    async (requestId: string, endTime: number) => {
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.smtpValidateStatus(requestId);

      const response = await callApi({
        url,
        method,
        external: true
      });

      if (response.success) {
        const { outbound, inbound } = response.data;

        // if (outbound.status === "Succeeded" && inbound.status === "Succeeded") {
        //   outbound.status = "Failed"
        // }

        if (
          (outbound.status === "Succeeded" && inbound.status === "Succeeded") ||
          (outbound.status === "Failed" && inbound.status === "Failed") ||
          (outbound.status === "Succeeded" && inbound.status === "Failed") ||
          (outbound.status === "Failed" && inbound.status === "Succeeded")
        ) {
          setValidating(true);
        }
        let taskDone = false;

        if (inbound.status !== "Created" && inbound.status !== "Started") {
          if (inbound.status === "Succeeded") {
            taskDone = true;
            setisInBoundConnection(true);
          } else {
            taskDone = true;
            setSmtpInBoundError(response.data.inbound.error);
            setisInBoundConnection(false);
          }
        }

        if (outbound.status !== "Created" && outbound.status !== "Started") {
          if (outbound.status === "Succeeded") {
            taskDone = true;
            setisOutBoundConnection(true);
          } else {
            taskDone = true;
            setSmtpOutBoundError(response.data.outbound.error);
            setisOutBoundConnection(false);
          }
        }

        if (inbound.status === "Succeeded" && outbound.status === "Succeeded") {
          await delay(3000);
          if (taskDone) {
            setisBoundConnection(false);
            // setShowConnectMailboxModal(false);
            // setIsSettingSmtp(true);
            setSmtpInBoundError("");
            setSmtpOutBoundError("");
            setCurrentStepName(NewCampaignSteps.MAILBOX_SETTINGS);
            return;
          }
        }
        if (inbound.status === "Created" || inbound.status === "Started" || outbound.status === "Created" || outbound.status === "Started") {
          let currentTime = new Date().getTime();
          if (endTime - currentTime > 0) {
            await delay(3000);
            return await callProviderSetupSuccessResponse(requestId, endTime);
          }
        }
      }
    },
    [callApi]
  );

  const onFinish = async (formValues: any) => {
    const { email, password, firstName, lastName, imapUserName, imapPassword } = formValues;
    // setProviders(providers);
    setData({
      email,
      password,
      lastName,
      firstName,
      imapUserName,
      imapPassword
    });
    if (providers === MailboxTypeEnum.GMAILONE) {
      if (isWrongPassword) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email: email,
          password: password,
          instanceId: instanceId,
          site: "OUTWIN",
          task: "reEnter_password",
          browserId
        };
        await performTask(data);
      } else if (isWrongEmail) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email: email,
          instanceId: instanceId,
          password: password,
          site: "OUTWIN",
          task: "reEnterLoginCredentials",
          browserId
        };
        await performTask(data);
      } else {
        setLoadingMessage("Connecting to mailbox...");
        const { browserId, instanceId, proxy } = await getDnsPublicDnsUrl(email, password);
        if (browserId && browserId.length && instanceId && instanceId.length) {
          setProviderType(providers);
          await socketAndPerformLogin(email, password, browserId, instanceId, proxy);
        }
      }
      return;
    } else {
      setisBoundConnection(true);
      setValidating(false);
      setSmtpInBoundError("");
      setSmtpOutBoundError("");
      setisInBoundConnection(false);
      setisOutBoundConnection(false);
    }

    // setLoadingMessage("Validating Your Mailbox ...");
    if (providers === MailboxTypeEnum.OFFICE_BROWSER) {
      // todo:NC verify this if
      if (isWrongPassword) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email: email,
          password: password,
          site: "OUTWIN",
          task: "reEnter_password"
        };

        await performTask(data);
      } else if (isWrongEmail) {
        let data = {
          email: email,
          password: password,
          site: "OUTWIN",
          task: "reEnterLoginCredentials"
        };
        await performTask(data);
      } else {
        const { browserId, instanceId, proxy } = await getDnsPublicDnsUrl(email, password);
        if (browserId && browserId.length && instanceId && instanceId.length) {
          setProviderType(providers);
          await socketAndPerformLogin(email, password, browserId, instanceId, proxy);
        }
      }
      return;
    }
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.smtpValidate();
    let payload = {
      provider: getProviderDetails(providers === "MICROSOFT" ? "MICROSOFT_SMTP" : providers)?.providerName,
      protocol: getProviderDetails(providers === "MICROSOFT" ? "MICROSOFT_SMTP" : providers)?.protocol,
      smtp: {
        username: email,
        password: password
        // tls: true,
      },
      ...(imapUserName && {
        imap: {
          username: imapUserName,
          password: imapPassword
          // host: imapHost,
          // port: imapPort,
          // tls: imapTLS,
        }
      }),
      inboxEmail: "",
      action: "VALIDATE",
      accountId: String(localStorage.getItem("account_id"))
    };
    const response = await callApi({
      url,
      method,
      data: payload,
      external: true
    });
    if (response.success) {
      if (providers === MailboxTypeEnum.OFFICE_SMTP || providers === MailboxTypeEnum.OFFICE_OAUTH) {
        setshowConnectMailStmpModal(false);
      } else if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
        setShowConnectMailboxModal(false);
      } else {
        // setShowMailBoxProviderDetailModal(false);
      }

      setProviderType(providers);
      let toDate = new Date();
      let nextTime = toDate.getTime() + 300000;
      await callProviderSetupSuccessResponse(response.data.requestId, nextTime);
      // setCurrentStepName(NewCampaignSteps.MAILBOX_SETTINGS);
    }
    setLoadingMessage("");
  };

  const setEmail = (email: string) => {
    setData((prevState) => ({
      ...prevState,
      email
    }));
    setEditModeWhileProviderWorkspacePersonal(true);
  };

  const providesType = useCallback(async (type: any) => {
    setProviders(type);
  }, []);

  useEffect(() => {
    return () => {
      LocalStorage.resetKey(OAUTH_STATE_LS_KEY)
    }
  }, [])

  console.log("providerTypeproviderType", providerType)

  const CurrentStepComponent = useMemo(() => {
    switch (currentStepName) {
      case NewCampaignSteps.ONBOARDING:
        return <OnBoadingStep />;

      case NewCampaignSteps.MAILBOX_LISTING:
        return (
          <MailBoxList
            isCardView={false}
            mailBoxes={mailBoxes}
            onAdd={onAddNewMailbox}
            onSubmit={onMailBoxFormSubmit}
            selectedMailBoxes={[]}
            form={mailBoxForm}
            filterStatus={MAILBOXLIST_FILTER.AVAILABLE}
            isFromAddCampaign={isFromAddCampaign}
          />
        );
      case NewCampaignSteps.MAILBOX_PROVIDER:
        return (
          <MailboxProvider
            onContinue={onMailboxProviderContinue}
            form={mailBoxProviderForm}
            setProviders={(type) => {
              if (type.includes("MICROSOFT")) providesType(MailboxTypeEnum.OFFICE_OAUTH);
              else providesType(type);
            }}
            submitBtn={onNext}
            setEmail={setEmail}
          />
        );
      case NewCampaignSteps.MAILBOX_SETTINGS:
        return (
          <MailBoxSettings form={mailboxSettingsForm} provider={providerType} email={Data.email} formValues={cloneDeep(Data)} onSave={onMailBoxSettingsSave} />
        );
      case NewCampaignSteps.PROSPECT_UPLOAD:
        return <UploadCsv onContinue={onUploadCsvFormSubmit} form={prospectsUploadForm} disabled={disableUploadCSV} continueButtonCheck={() => { }} />;
      case NewCampaignSteps.PROSPECT_COLUMN_MAPPING:
        return (
          <ProspectMapColumns
            onContinue={onMapProspectColumnsFormSubmit}
            form={prospectMappingForm}
            sampleProspect={prospectData.sampleProspectData.map((prospect, index) => ({ prospect, index }))}
            columnHeaders={prospectData.columnHeaders.map((header, index) => ({ header, index }))}
            totalProspects={prospectData.totalProspects}
          />
        );
      case NewCampaignSteps.PROSPECT_COLUMN_SELECT:
        return (
          <SelectColumns
            columnHeaders={remainingProspectData.columnHeaders}
            form={prospectMappingFormSecondary}
            onContinue={onMapProspectColumnsSecondaryFormSubmit}
          />
        );
      // case NewCampaignSteps.PROSPECT_SUMMARY:
      //   return (
      //     <ProspectSummary
      //       added={prospectSummary.added}
      //       total={prospectSummary.total}
      //       failed={prospectSummary.failed}
      //       failedCsvLink={prospectSummary.failedCsvLink}
      //     />
      //   );
      case NewCampaignSteps.PROSPECT_DEDUPE_SELECT:
        return <DedupeProspects form={prospectDedupeCamapiaign} onContinue={onMapProspectDedupeFormSubmit} />;
      case NewCampaignSteps.APPLY_DNC:
        return <Applydnc form={prospectApplyDnc} onContinue={onMapProspectApplyDncFormSubmit} />;
      case NewCampaignSteps.SETUP:
        return <CampaignSettings newCampaign onContinue={onCampaignSetupFormSubmit} form={campaignSetupForm} afterSave={afterSave} />;
      default:
        return null;
    }
  }, [
    currentStepName,
    mailBoxes,
    onAddNewMailbox,
    onMailBoxFormSubmit,
    mailBoxForm,
    onMailboxProviderContinue,
    mailBoxProviderForm,
    providesType,
    mailboxSettingsForm,
    onMailBoxSettingsSave,
    onUploadCsvFormSubmit,
    prospectsUploadForm,
    disableUploadCSV,
    onMapProspectColumnsFormSubmit,
    prospectMappingForm,
    prospectData.sampleProspectData,
    prospectData.columnHeaders,
    prospectData.totalProspects,
    prospectSummary.added,
    prospectSummary.total,
    prospectSummary.failed,
    prospectSummary.failedCsvLink,
    onCampaignSetupFormSubmit,
    campaignSetupForm,
    providerType,
    prospectMappingFormSecondary,
    remainingProspectData.columnHeaders,
    onMapProspectColumnsSecondaryFormSubmit
  ]);

  const fetchMailBoxes = useCallback(async () => {
    setLoadingMessage("Fetching your mailboxes");
    // const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl();
    let account_id = String(localStorage.getItem("account_id"));
    const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl(account_id);

    const response = await callApi({
      url,
      method
    });

    if (response.success) {
      let sortedResult = response.data.inboxes;
      const mailList = sortedResult.map((item: any, index: number) => ({
        ...item,
        Campaign: index < Math.floor(Math.random() * (6 - 2 + 1) + 2) ? "xxxxxxxs" : null
      }));
      setMailBoxes(mailList);
    }
    setLoadingMessage("");
  }, [setMailBoxes, setLoadingMessage, callApi]);

  useEffect(() => {
    if (oauthState && currentStepName !== NewCampaignSteps.MAILBOX_SETTINGS && providers !== 'GSUITE') {
      setCurrentStepName(NewCampaignSteps.MAILBOX_SETTINGS);
    } else if (isEmpty(oauthState) && currentStepName === NewCampaignSteps.MAILBOX_SETTINGS && providers === "MICROSOFT") {
      setCurrentStepName(NewCampaignSteps.PROSPECT_UPLOAD);
    }
  }, [oauthState, currentStepName]);

  useEffect(() => {
    // Mamage the button states
    switch (currentStepName) {
      case NewCampaignSteps.ONBOARDING: {
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: false,
          disabled: false
        });
        break;
      }
      case NewCampaignSteps.MAILBOX_LISTING: {
        fetchMailBoxes();
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      case NewCampaignSteps.MAILBOX_PROVIDER: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      case NewCampaignSteps.MAILBOX_SETTINGS: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      case NewCampaignSteps.PROSPECT_UPLOAD: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: true
        });
        break;
      }
      case NewCampaignSteps.PROSPECT_COLUMN_MAPPING: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      case NewCampaignSteps.PROSPECT_COLUMN_SELECT: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      case NewCampaignSteps.PROSPECT_DEDUPE_SELECT: {
        setSkipButton({
          label: "Skip",
          show: true,
          disabled: false
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      case NewCampaignSteps.APPLY_DNC: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      // case NewCampaignSteps.PROSPECT_SUMMARY: {
      //   const isUploadSuccessfull = prospectSummary.added !== 0;

      //   setNextButton({
      //     label: "Next",
      //     show: true,
      //     disabled: isUploadSuccessfull ? false : true
      //   });
      //   setBackButton({
      //     label: "Back",
      //     show: true,
      //     disabled: isUploadSuccessfull ? true : false
      //   });
      //   break;
      // }
      case NewCampaignSteps.SETUP: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Finish",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: true
        });
        break;
      }
    }
  }, [currentStepName, fetchMailBoxes, prospectSummary]);

  useEffect(() => {
    LocalStorage.setValue(NEW_CAMPAIGN_LS_KEY, {
      campaignId,
      currentStepName,
      prospectData,
      prospectSummary,
      selectedMailBoxesIds
    });
  }, [currentStepName, campaignId, prospectData, prospectSummary, selectedMailBoxesIds]);

  const toggleMailboxValidating = () => {
    setisBoundConnection((prev) => !prev);
    if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
      setShowConnectMailboxModal((prev) => !prev);
      setStepWhenConnectionModalClosed(3);
    }
    // backButton()
    setValidating(false);
    setSmtpInBoundError("");
    setSmtpOutBoundError("");
    setisInBoundConnection(false);
    setisOutBoundConnection(false);
  };

  const confirmRecoveryEmail = useCallback(
    async (formValues: any) => {
      setLoadingMessage("verifying Email.");
      const { alternate_email } = formValues;
      // gmailMailConfirmation.resetFields();
      if (isVerifyEmail) {
        if (isVerifyEmailForGmail) {
          let data = {
            alternate_email: alternate_email,
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "verify_recovery_email",
            browserId
          };
          await performTask(data);
        }
      }
    },
    [isVerifyEmail, Data, browserId, setLoadingMessage, isVerifyEmailForGmail]
  );

  const verifyTwoStep = async () => {
    let data = {
      email: Data.email,
      instanceId: instanceId,
      site: "OUTWIN",
      task: "",
      browserId
    };
    await getTask(data);
  };

  const detectExtension = (extensionId: string, callback: any) => {
    let s = document.createElement("script");
    s.onload = function () {
      callback(true);
    };
    s.onerror = function () {
      callback(false);
    };
    s.src = "chrome-extension://" + extensionId + "/js/control.js";
    document.body.appendChild(s);
  };

  const checkExtension = () => {
    return new Promise((resolve, reject) => {
      detectExtension("bpekpmdcgljafocafpmjjghmhfnlggah", (sss: any) => {
        resolve(sss);
      });
    });
  };

  useEffect(() => {
    if (extensionStatus === "check") {
      var datavalue = setInterval(async () => {
        let data: any = await checkExtension();
        if (data) {
          setInstallExtension(true);
          clearInterval(datavalue);
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(datavalue);
      }, 180000);
    } else if (extensionStatus === "installed") {
      const checkFn = async () => {
        let data: any = await checkExtension();
        if (data) {
          setInstallExtension(true);
        }
      };
      checkFn();
    } else {
    }
    return () => {
      setExtensionStatus("");
    };
  }, [extensionStatus]);

  const handleInstallExtension = useCallback(async () => {
    const url = "https://chrome.google.com/webstore/";
    window.open(url, "_blank", "noopener,noreferrer");
    setExtensionStatus("check");
  }, []);

  const getCookie = (name: string) => {
    const pattern = RegExp(name + "=.[^;]*");
    const matched = document.cookie.match(pattern);
    if (matched) {
      const cookie = matched[0].split("=");
      return cookie[1];
    }
    return false;
  };

  const clearCookies = () => {
    document.cookie = "brooklyn_inbox_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "brooklyn_inbox_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  const handleGoogleLogin = () => {
    clearCookies();
    const sessionId = uuid();
    document.cookie = `brooklyn_site=${window.origin}; path=/`;
    document.cookie = "brooklyn_app=Emy; path=/";
    document.cookie = `brooklyn_session_id=${sessionId}; path=/`;
    const redirectUrl = "https://accounts.google.com/AddSession?service=mail&passive=truecontinue=https://mail.google.com/mail";
    window.open(redirectUrl, "_blank", "noopener,noreferrer");

    var datavalue = setInterval(() => {
      let cookieValue = getCookie("brooklyn_inbox_name");
      if (cookieValue) {
        const fullName: any = cookieValue.split(" ");
        if (fullName.length) {
          setData((prevState) => ({
            ...prevState,
            firstName: fullName.shift(),
            lastName: fullName.join(" ")
          }));
        }
        getTaskFromAPI("login_success");
        clearCookies();
        clearInterval(datavalue);
      }
    }, 1500);

    setTimeout(() => {
      clearInterval(datavalue);
    }, 300000);
  };
  const handleExistMailError = async () => {
    setLoadingMessage("checking the mail again");
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(campaignId!);
    await callApi({ url, method, data: { action: "UpdateInbox", inboxId: selectedMailBoxesIds } });
    setIsMailboxExist(false);
    setExisMailMessage("");
    setLoadingMessage("");
  };

  const onSkip = (formData: any) => {
    // prospectDedupeCamapiaign.submit();
    onMapProspectDedupeFormSubmit(formData, true);
  };

  const setGsuiteSetupModal = () => {
    setOpenSetupModal(true)
    setShowGsuiteModal(false)
  }
  return (
    <>
      <Modal
        visible
        className="outwin-full-width-modal"
        onCancel={abortCampaignFlow}
        footer={null}
        title="New Campaign"
        width="100%"
        style={{
          maxWidth: "100%",
          top: 0,
          position: "fixed"
        }}
        bodyStyle={{
          height: "calc(100vh - 35px)",
          padding: 0,
          overflowY: "scroll",
          paddingBottom: "20px"
        }}
      >
        <PageBackground style={{ minHeight: "calc(100vh - 55px)" }}>
          <Flex bgColor={WHITE_COLOR} mb={15}>
            <Flex w={600} m="0 auto" align="center" direction="column">
              <Flex mt={10} mb={10} w="100%" bgColor={WHITE_COLOR}>
                <Steps current={stepNumber}>
                  {STEPS?.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </Flex>
            </Flex>
          </Flex>
          <Flex w={1024} m="0 auto" align="center" direction="column">
            <Flex align="center" direction="column" w={700} mb={20}>
              <Card style={{ width: "100%" }}>{CurrentStepComponent}</Card>
              <Actions
                onBack={onBack}
                showBack={backButton.show}
                backButtonLabel={backButton.label}
                onNext={onNext}
                showNext={nextButton.show}
                nextButtonLabel={nextButton.label}
                backDisabled={backButton.disabled}
                nextDisabled={nextButton.disabled}
                skipButtonLabel={skipButton?.label}
                onSkip={() => onSkip(prospectDedupeCamapiaign.getFieldsValue())}
                showSkip={skipButton?.show}
                skipDisabled={skipButton.disabled}
              />
            </Flex>
          </Flex>
        </PageBackground>
        {getProviderDetails(providers)?.name === "Google (SMTP)" && getProviderDetails(providers)?.subText === "Workspace" ? (
          <>
            {!!showConnectMailboxModal && (
              <GmailIMAPLoginModal
                handleOpenClose={() => {
                  setShowConnectMailboxModal(!showConnectMailboxModal);
                  setEditModeWhileProviderWorkspacePersonal(false);
                }}
                handleSubmit={onFinish}
                connectModalStep={connectModalStep}
                isEditModeForWorkspacePersonal={isEditModeForWorkspacePersonal}
                formData={isEditModeForWorkspacePersonal ? { email: Data?.email } : {}}
              />
            )}
          </>
        ) : (
          <>
            {!!showConnectMailboxModal && (
              <Modal visible footer={null} onCancel={toggleShowModal} width={600} centered>
                <Form scrollToFirstError form={subform} requiredMark={false} onFinish={onFinish}>
                  <Text variant="h6" mb={5} ml={10} mt={20} color={"secondary"}>
                    {getProviderDetails(providers)?.name + " Setup"}
                  </Text>
                  <Flex style={{ flexDirection: "row" }}>
                    <DetailWrapper>
                      <DetailHeading>Email *</DetailHeading>
                      <Form.Item
                        name={"email"}
                        rules={[
                          {
                            type: "email",
                            message: "Please enter valid email"
                          },
                          {
                            required: true,
                            message: "Please enter email"
                          }
                        ]}
                        initialValue={isEditModeForWorkspacePersonal ? Data.email : ""}
                      >
                        <Input className={classes.inputStyle} size="middle" tabIndex={1} />
                      </Form.Item>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailHeading>Password *</DetailHeading>
                      <Form.Item
                        name={"password"}
                        rules={[
                          {
                            required: true,
                            message: "Please write password"
                          }
                        ]}
                        style={{ marginLeft: "5px" }}
                      >
                        <Input.Password
                          tabIndex={2}
                          autoComplete="new-password"
                          style={{
                            width: 250,
                            borderWidth: 0,
                            paddingLeft: 2,
                            boxShadow: "none",
                            borderRight: "none",
                            borderBottom: `1px solid ${DARK_GRAY_COLOR}`
                          }}
                          size="middle"
                        />
                      </Form.Item>
                    </DetailWrapper>
                  </Flex>
                  <Flex direction="row" ml={10} justify="flex-end">
                    <Flex>
                      <Button onClick={() => setShowConnectMailboxModal(false)} tabIndex={5}>
                        Back
                      </Button>
                    </Flex>
                    <Flex ml={10}>
                      <Button htmlType="submit" type="primary" tabIndex={6}>
                        Validate
                      </Button>
                    </Flex>
                  </Flex>
                </Form>
              </Modal>
            )}
          </>
        )}

        {!!showConnectEmailValidationModal && (
          <Modal visible footer={null} onCancel={() => toggleShowModalEmailValidation()} width={300} centered>
            <Form scrollToFirstError form={alernateEmailValidation} requiredMark={false} onFinish={submitMicroSoftEmail}>
              {/* <p style={{ fontSize: '12px', marginBottom: '15px' }}>ddd.outwin@gmail.com</p> */}
              <Title level={5}>Help us protect your account</Title>
              <p className="fs-12" style={{ marginBottom: "15px" }}>
                Passwords can be forgotten or stolen. Just in case, add security info now to help you get back into your account if something goes wrong. We
                won't use this info to spam you—just to keep your account more secure.
              </p>
              <Div>
                <label className="fs-12 fw-600">Email *</label>
                <Form.Item
                  name={"alternate_email"}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter valid email"
                    },
                    {
                      required: true,
                      message: "Please enter email"
                    }
                  ]}
                >
                  <Input tabIndex={1} className={classes.inputStyle} size="middle" />
                </Form.Item>
                {isVerifyEmail ? (
                  <p className="fs-12" style={{ marginBottom: "10px" }}>
                    We'll send a security code to your email <strong>{showText.split("Email")[1]}</strong> address in the next step.
                  </p>
                ) : (
                  <p className="fs-12" style={{ marginBottom: "10px" }}>
                    We'll send a security code to your alternate email address in the next step.
                  </p>
                )}
              </Div>
              <Flex direction="row" ml={10} justify="flex-end">
                <Flex ml={10}>
                  <Button htmlType="submit" type="primary">
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Form>
          </Modal>
        )}
        {!!showConnectMicrosoftValidation && (
          <VerifyEmailByPhoneNoModal submitPhoneNoToVerify={submitPhoneNoToVerify} handleVerifyEmailByPhoneNo={handleVerifyEmailByPhoneNo} />
        )}

        {!!showAccessCodeModal && (
          <VerifyAccessCodeModal subMessage={subMessage} submitAccessCode={submitAccessCode} handleVerifyAccessCodeModal={handleVerifyAccessCodeModal} />
        )}
        {!!showUpdatePasswordModal && (
          <UpdatePasswordModal showText={showText} updatePassword={updatePassword} handleUpdatePasswordModal={handleUpdatePasswordModal} />
        )}
        {!!showConnectMailStmpModal && (
          <Modal visible footer={null} onCancel={() => toggleShowModalSmtp()} width={600} centered>
            <Form scrollToFirstError form={mailBoxOfficeSmtp} requiredMark={false} onFinish={onFinish}>
              <Text variant="h6" mb={5} ml={10} mt={20} color={"secondary"}>
                {"Microsoft"} {/* {"Gmail"}{" "} */}
                setup
              </Text>
              <Flex style={{ flexDirection: "row" }}>
                <DetailWrapper>
                  <DetailHeading>Email *</DetailHeading>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        type: "email",
                        message: "Please enter valid email"
                      },
                      {
                        required: true,
                        message: "Please enter email"
                      }
                    ]}
                  >
                    <Input tabIndex={1} className={classes.inputStyle} size="middle" />
                  </Form.Item>
                </DetailWrapper>
                <DetailWrapper>
                  <DetailHeading>Password *</DetailHeading>
                  <Form.Item
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Please write password"
                      }
                    ]}
                    style={{ marginLeft: "5px" }}
                  >
                    <Input.Password
                      tabIndex={2}
                      autoComplete="new-password"
                      style={{
                        width: 250,
                        borderWidth: 0,
                        paddingLeft: 2,
                        boxShadow: "none",
                        borderRight: "none",
                        borderBottom: `1px solid ${DARK_GRAY_COLOR}`
                      }}
                      size="middle"
                    />
                  </Form.Item>
                </DetailWrapper>
              </Flex>
              <Flex direction="row" ml={10} justify="flex-end">
                <Flex>
                  <Button onClick={() => setshowConnectMailStmpModal(false)}>Back</Button>
                </Flex>
                <Flex ml={10}>
                  <Button htmlType="submit" type="primary">
                    Validate
                  </Button>
                </Flex>
              </Flex>
            </Form>
          </Modal>
        )}
        {!!showTextVerifyModal && (
          <VerifyPhoneNoModal showText={showText} verifyPhoneNoForm={verifyPhoneNoForm} toggleVerifyPhoneNoForm={toggleVerifyPhoneNoForm} />
        )}
        {showMailBoxProviderDetailModal && (
          <Modal
            visible
            onCancel={toggleMailBoxProviderDatialDialog}
            footer={null}
            width={600}
            centered
          //bodyStyle={{ padding: 30 }}
          >
            <Form scrollToFirstError onFinish={onFinish} form={otherform} requiredMark={false}>
              <Text variant="h6" mb={15} mt={5} color={"secondary"}>
                {getProviderDetails(providers)?.providerName + " Setup"}
              </Text>
              <Flex style={{ flexDirection: "row" }} className="configuration-title">
                <DetailWrapper>
                  <DetailHeading>SMTP Configuration</DetailHeading>
                </DetailWrapper>
              </Flex>

              <Flex style={{ flexDirection: "row" }} className="configuration-form">
                <DetailWrapper>
                  <DetailHeading>SMTP UserName *</DetailHeading>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter SMTP User Name"
                      }
                    ]}
                  >
                    <Input tabIndex={1} className={classes.inputStyle} size="small" />
                  </Form.Item>
                </DetailWrapper>

                <DetailWrapper>
                  <DetailHeading>SMTP Password *</DetailHeading>
                  <Form.Item
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter SMTP password"
                      }
                    ]}
                  >
                    <Input.Password tabIndex={2} autoComplete="new-password" className={classes.inputStyle} size="small" />
                  </Form.Item>
                </DetailWrapper>
              </Flex>
              {isIMAPPart ? null : (
                <>
                  <Flex style={{ flexDirection: "row" }}>
                    <DetailWrapper>
                      <DetailHeading>IMAP Configuration</DetailHeading>
                    </DetailWrapper>
                  </Flex>
                  <Flex style={{ flexDirection: "row" }}>
                    <DetailWrapper>
                      <DetailHeading>IMAP UserName *</DetailHeading>
                      <Form.Item
                        name={"imapUserName"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter IMAP UserName"
                          }
                        ]}
                      >
                        <Input tabIndex={3} className={classes.inputStyle} size="small" />
                      </Form.Item>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailHeading>IMAP Password *</DetailHeading>
                      <Form.Item
                        name={"imapPassword"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter IMAP Password"
                          }
                        ]}
                      >
                        <Input.Password tabIndex={4} className={classes.inputStyle} size="small" />
                      </Form.Item>
                    </DetailWrapper>
                  </Flex>
                </>
              )}
              <Flex direction="row" ml={10} justify="flex-end">
                <Flex>
                  <Button tabIndex={14} onClick={gotoBackButton}>
                    Back
                  </Button>
                </Flex>
                <Flex ml={10}>
                  <Button tabIndex={15} htmlType="submit" type="primary">
                    Validate
                  </Button>
                </Flex>
              </Flex>
            </Form>
          </Modal>
        )}
      </Modal>
      {isBoundConnection && (
        <Modal visible onCancel={toggleMailboxValidating} footer={null} width={600} maskClosable={false}>
          {/* <Div className="custom-loader"> */}
          <Text variant="h6" className="fs-16px" mt={0} mb={25} block color={"secondary"}>
            Validating
          </Text>
          <Div className="d-flex flex-column align-items-center checking-mn-box">
            <Row className="mb-2 checking-box">
              <Col md="auto" className="me-2 d-flex">
                <Div className="d-flex flex-column icon-round">
                  {!validating ? (
                    <span
                      role="img"
                      aria-label="loading"
                      className="anticon anticon-loading anticon-spin"
                      style={{ color: "rgb(250, 140, 22)", fontSize: "16px" }}
                    >
                      <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1.2em" height="1.2em" fill="currentColor" aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                    </span>
                  ) : isInBoundConnection ? (
                    <span className="fs-20px text-success">
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </span>
                  ) : (
                    <span className="fs-20px text-danger ml-2px">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </span>
                  )}
                </Div>

                <Div>
                  <Text variant="sub2" className="ms-1 check-label">
                    Checking Inbound Connection
                  </Text>
                  {smtpInBoundError && smtpInBoundError.length ? <p className="ml-10px text-danger lh-16px fs-12px">Error: {smtpInBoundError}</p> : null}
                </Div>
              </Col>
            </Row>

            <Row className="mb-2 checking-box">
              <Col md="auto" className="me-2 d-flex">
                <Div className="d-flex flex-column icon-round">
                  {!validating ? (
                    <span
                      role="img"
                      aria-label="loading"
                      className="anticon anticon-loading anticon-spin"
                      style={{ color: "rgb(250, 140, 22)", fontSize: "16px" }}
                    >
                      <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1.2em" height="1.2em" fill="currentColor" aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                    </span>
                  ) : isOutBoundConnection ? (
                    <span className="fs-20px text-success">
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </span>
                  ) : (
                    <span className="fs-20px text-danger ml-2px">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </span>
                  )}
                </Div>

                <Div>
                  <Text variant="sub2" className="ms-1 check-label">
                    Checking Outbound Connection
                  </Text>
                  {smtpOutBoundError && smtpOutBoundError.length ? <p className="ml-10px text-danger lh-16px fs-12px">Error: {smtpOutBoundError}</p> : null}
                </Div>
              </Col>
            </Row>
          </Div>
          {/* </Div> */}
        </Modal>
      )}
      {!!showGmailConfirmationMailModal && (
        <RecoveryEmailModal subMessage={subMessage} handleRecoveryEmailModal={handleRecoveryEmailModal} confirmRecoveryEmail={confirmRecoveryEmail} />
      )}
      {!!show2StepVerificationModal && (
        <VerficationModal subMessage={subMessage} verifyTwoStep={verifyTwoStep} handleVerificationModal={handleVerificationModal} />
      )}
      {!!showVerificationOptionsModal && (
        <VerificationOptionsModal
          subMessage={subMessage}
          handleVerificationOptionsModal={handleVerificationOptionsModal}
          onOptionSelectHandler={onOptionSelectHandler}
        />
      )}
      {!!showTroubleShootModal && <TroubleShootModal proxyDetail={proxy} handleTroubleShootModal={handleTroubleShootModal} />}
      {!!showManualGmailLogin && (
        <ManualGmailLoginModal
          handleManualGmailLoginModal={handleManualGmailLoginModal}
          installExtension={installExtension}
          handleInstallExtension={handleInstallExtension}
          handleGoogleLogin={handleGoogleLogin}
        />
      )}
      {isMailboxExist && (
        <MailExistModal
          handleOpenClose={() => {
            setIsMailboxExist(false);
            setExisMailMessage("");
            //   setShowMailBoxProviderModal(true);
            // setShowConnectMailboxModal(true);
          }}
          message={exisMailMessage}
          handleSubmit={handleExistMailError}
        />
      )}
      {/* {!!gsuiteSetupModlal && (
        <GsuiteSetupModal
          handleOpenClose={() => {
            setProviders("");
            setOpenSetupModal(false)
            LocalStorage.resetKey(OAUTH_STATE_LS_KEY)
          }}
          handleSubmit={onFinish}
          formData={Data}
          // setOpenSetupModal={() => setOpenSetupModal(true)}
          instantSetup={instantSetup}
          detailedSetup={detailedSetup}
        />
      )} */}

      {getProviderDetails(providers)?.name === "Google (OAUTH)" && getProviderDetails(providers)?.subText === "Workspace" ? (
        <>
          {!!showGsuiteMail && (
            <GsuiteLoginModal
              handleOpenClose={() => {
                setProviders("");
                setShowGsuiteModal(false)
              }}
              handleSubmit={onFinish}
              formData={Data}
              connectModalStep={connectModalStep}
              setOpenSetupModal={() => setGsuiteSetupModal()}
            onContinue={(provider, email) => onMailboxProviderContinue(provider, email, true)}
              providerType={providers}
            />
          )}
        </>)
        : null}
    </>
  );
};

const OnBoadingStep = () => (
  <Flex justify="center">
    <Onboarding />
  </Flex>
);

export default NewCampaignFlow;
