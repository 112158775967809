import { Modal } from "antd";
import LogDetails from "pages/CampaignDetail/LogDetails";
import classes from "./styles.module.css";

interface LogDetailsModalProps {
  id: string;
  onCancel: () => void;
  logType: string;
  subTitle?: string;
}

const LogDetailsModal = ({ onCancel, id, logType, subTitle }: LogDetailsModalProps) => {
  return (
    <Modal visible onCancel={onCancel} footer={null} width={"90vw"} >
      <h2 style={{ color: "#999" }}>Logs</h2>
      <h4 style={{ color: "#999" }}>{subTitle}</h4>
      <LogDetails id={id} logType={logType}></LogDetails>
    </Modal>
  );
};

export default LogDetailsModal;
