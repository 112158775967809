import { Switch, Table, TablePaginationConfig } from "antd";
import Column from "antd/lib/table/Column";
import { SkeletonTable } from "pages/Settings/MailboxTableView";
import { BgImage, Div, Flex } from "styles/layout";
import { CampaignSummaryType } from "types/Campaign";
import { getAvatarImage } from "utils/avatar";
import { LIGHT_BLUE_COLOR, SECONDARY_COLOR } from "utils/colors";
import TimeAgo from "react-timeago";

type CampaignTableViewProps = {
  dataSource: Array<CampaignSummaryType>;
  pagination?: false | TablePaginationConfig;
  loading?: boolean;
  pageSize?: number;
  onStatusChange: (campaignId: CampaignSummaryType) => void;
  onClick?: (campaignId: string) => void;
};

export const CampaignTableView = ({ pageSize = 10, dataSource, pagination, ...props }: CampaignTableViewProps) => {
  const columns = [{ dataIndex: "personInfo" }, { dataIndex: "queue" }, { dataIndex: "sent" }, { dataIndex: "ips" }];

  const pageDefaults = { pageSize, size: "small", position: ["topRight"] };
  const paginationUpd =
    pagination === false || dataSource.length <= pageSize ? false : { ...pageDefaults, ...(pagination as any) };

  return props.loading ? (
    <SkeletonTable columns={columns} />
  ) : dataSource.length ? (
    <Table
      dataSource={dataSource}
      pagination={paginationUpd}
      rowKey="campId"
      style={{ cursor: "pointer" }}
      loading={props.loading}
      onRow={(record) => {
        return {
          onClick: () => props.onClick && props?.onClick(record.campId)
        };
      }}
      className="antd__table"

    >
      <Column
        // className={classes.mailboxTableCell}
        title="Name"
        key="name"
        render={(_, record: CampaignSummaryType) => (
          <Flex gap={15} align="center">
            <BgImage
              imageUrl={getAvatarImage(record.imageName)}
              h={40}
              w={40}
              br="50%"
              display="inline-block"
              bc={SECONDARY_COLOR}
              bs={25}
              bgColor={LIGHT_BLUE_COLOR}
            />
            {record.name}
          </Flex>
        )}
      />
      <Column title="Prospect" dataIndex="activeRecipients" key="activeRecipients" />
      <Column title="Replied" dataIndex="mailboxes" key="mailboxes" />
      <Column
        title="Last Sent"
        key="lastSentTime"
        render={(_, record: CampaignSummaryType) => (
          <Flex>{!!record.lastSentTime ? <TimeAgo date={record.lastSentTime} /> : "NA"}</Flex>
        )}
      />
      <Column
        title="Actions"
        key="actions"
        render={(_, record: CampaignSummaryType) => (
          <Div align="center" mt={10} onClick={(e) => e.stopPropagation()}>
            <Switch onChange={(r) => props.onStatusChange(record)} checked={record.status === "Active"} />
          </Div>
        )}
      />
    </Table>
  ) : null;
};