import { Button, Checkbox, Form, notification, Modal } from "antd";
import { useEffect, useMemo, useState, useCallback } from "react";
import { Flex, Div } from "styles/layout";
import { Table } from "antd";
import UploadCsv, { CSVContentsType } from "components/UploadPropects/UploadCsv";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import Column from "antd/lib/table/Column";

//const MAX_CSV_FILE_SIZE = 0.286 * 1024;
const ONE_MB_VALUE = 1024;
const MAX_CSV_FILE_SIZE = 50 * 1024;
const NOTIFICATION_KEY = "CSV_UPLOAD_ERROR";

type PreviewTableProps = {
  columns: string[];
  data: any[][];
  previewData: any[][];
  onColumnSelected?: (id: string) => void;
};

const PreviewTable = ({ columns, previewData, data, onColumnSelected }: PreviewTableProps) => {
  // debugger
  const [curColSelected, setCurColSelected] = useState("");

  useEffect(() => {
    onColumnSelected && onColumnSelected(curColSelected);
  }, [curColSelected, onColumnSelected]);

  let removedIndex: any = [];
  previewData[0].map((d, i) => {
    if (!(d.indexOf(" ") > -1) && !(d.indexOf("@") > -1)) {
      if (!(d.indexOf(".") > -1))
        removedIndex.push(i);
      else
        return d;
    }
    else
      removedIndex.push(i);
  });

  // const getDomainColumnData = () => {
  //   let arr: any = [];
  //   for (let i = 0; i < data.length; i++) {
  //     let columnData = data[i]

  //     columnData.map((abc: any) => {
  //       if ((abc.includes(".")) && !abc.includes("@")) {
  //         // debugger;
  //         let domainArr = abc.replace('http://www.', '').replace('https://www.', '').replace('http://', '').replace('https://', '')
  //         var substringindex = domainArr.indexOf('/');
  //         if (substringindex > 0)
  //           domainArr = domainArr.substring(0, substringindex);
  //         // let domainSplit = domainArr.split('.');
  //         // let domainSplitLength = domainSplit.length;
  //         // let realDomain = domainSplit[domainSplitLength - 1] + "." + domainSplit[domainSplitLength]
  //         // console.log(realDomain);
  //         arr.push(domainArr)
  //       }
  //     })
  //     const uniqueDomains: string[] = Array.from(new Set(arr));
  //     selectedColumnData && selectedColumnData(uniqueDomains)
  //   }
  // }

  // useEffect(() => {
  //   getDomainColumnData();
  // }, [])

  return (
    <Table dataSource={previewData} pagination={false}>
      {columns.map((col, idx) => (
        (!removedIndex.includes(idx)) && (
          <Column
            title={() => {
              return (
                <Flex>
                  <Checkbox
                    checked={curColSelected === idx.toString()}
                    onChange={() => setCurColSelected(idx.toString())}
                  >
                    {col}
                  </Checkbox>
                </Flex>
              );
            }}
            dataIndex={idx}
            key={col}
          ></Column>
        )
      ))}
    </Table>
  );
};

export type CSVContactsProps = {
  onCancel: () => void;
  onDone?: (domainIndex: string, domainCount: number, uploadedFileName: string, domainColumnData: string[]) => void;
};

type CSVContactsState = {
  step: number;
  isProcessing: boolean;
  disableUploadCSV?: boolean;
  csvData?: CSVContentsType;
  domainColumn: string;

};

export const CSVContacts = ({ onCancel, onDone, }: CSVContactsProps) => {
  const [domainUploadForm] = Form.useForm();
  const [state, setState] = useState<CSVContactsState>({ step: 1, isProcessing: false, domainColumn: "" });
  const processing = useMemo(() => state.disableUploadCSV || state.isProcessing, [
    state.disableUploadCSV,
    state.isProcessing
  ]);

  const { callApi, uploadFileToS3 } = useApi();
  const [isFileSelected, setIsFileSelected] = useState(true)
  const [SelectedColumnId, setSelectedColumnId] = useState(0)
  const [Prospects, setProspects] = useState<string[][]>([]);
  // console.log(callApi);


  const onUploadCsvFormSubmit = useCallback(async ({ columnHeaders, file, prospects }: CSVContentsType) => {

    let arr: any = [];
    for (let i = 0; i < prospects.length; i++) {
      let columnData = prospects[i]
      // let abc = columnData[SelectedColumnId]

      columnData.map((abc: any) => {
        if ((abc.includes(".")) && !abc.includes("@")) {
          let domainArr = abc.replace('http://www.', '').replace('https://www.', '').replace('http://', '').replace('https://', '')
          var substringindex = domainArr.indexOf('/');
          if (substringindex > 0)
            domainArr = domainArr.substring(0, substringindex);
          // let domainSplit = domainArr.split('.');
          // let domainSplitLength = domainSplit.length;
          // let realDomain = domainSplit[domainSplitLength - 1] + "." + domainSplit[domainSplitLength]
          // console.log(realDomain);
          arr.push(domainArr)
        }
      })
    }
    const uniqueDomains: string[] = Array.from(new Set(arr));
    // setSelectedColumnData(uniqueDomains);
    if (uniqueDomains.length > 20000) {
      notification.error({
        message: "Maximum domains or websites allowed in a single file is 20,000. Please reduce the entries and retry.",
        description: "",
        key: NOTIFICATION_KEY
      });
    } else {
      prospects = prospects.filter(prospects => {
        if (prospects.filter((one) => one !== '').length > 0) {
          return prospects;
        }
      });
      setProspects(prospects);
      setState((st) => ({ ...st, step: 2, csvData: { columnHeaders, file, prospects } }));
    }
    const fsize = file.size;
    const fileSize = Math.round((fsize / ONE_MB_VALUE));
    // if (fileSize > MAX_CSV_FILE_SIZE) {
    //   notification.error({
    //     message: "File size should not exceed 50mb.",
    //     description: "",
    //     key: NOTIFICATION_KEY
    //   });
    // }
    // else {
    //   prospects = prospects.filter(prospects => {
    //     if (prospects.filter((one) => one !== '').length > 0) {
    //       return prospects;
    //     }
    //   });
    //   setState((st) => ({ ...st, step: 2, csvData: { columnHeaders, file, prospects } }));
    // }
  }, []);

  const onCSVFormSubmit = useCallback(() => domainUploadForm.submit(), [domainUploadForm]);

  const onBack = useCallback(() => {
    setState((st) => ({ ...st, step: 1 }));
  }, []);

  const onColumnSelected = useCallback((id) => {
    setState((st) => ({ ...st, domainColumn: id }));
    setSelectedColumnId(id);
    setIsFileSelected(true);
  }, []);

  const onUpload = () => {

    let arr: any = [];
    for (let i = 0; i < Prospects.length; i++) {
      // debugger
      let columnData = Prospects[i]
      let abc = columnData[SelectedColumnId]

      // columnData.map((abc: any) => {
      // if ((abc.includes(".")) && !abc.includes("@")) {
      let domainArr = abc.replace('http://www.', '').replace('https://www.', '').replace('http://', '').replace('https://', '')
      var substringindex = domainArr.indexOf('/');
      if (substringindex > 0)
        domainArr = domainArr.substring(0, substringindex);
      // let domainSplit = domainArr.split('.');
      // let domainSplitLength = domainSplit.length;
      // let realDomain = domainSplit[domainSplitLength - 1] + "." + domainSplit[domainSplitLength]
      // console.log(realDomain);
      arr.push(domainArr)
      // }
      // })
    }
    const uniqueDomains: string[] = Array.from(new Set(arr));

    if (!state.domainColumn) {
      return notification.error({
        message: "No domain column",
        description: `Please select one column for domain to proceed further.`
      });
    }

    if (!state.csvData?.file) {
      return;
    }

    const uploadedFileName = state.csvData?.file.name;
    setState((st) => ({ ...st, isProcessing: true }));
    // const { fileUrl, response } = await uploadFileToS3(state.csvData.file);
    setState((st) => ({ ...st, isProcessing: false }));
    // if (fileUrl) {
    onDone && onDone(state.domainColumn, state.csvData.prospects.length, uploadedFileName, uniqueDomains);
    // }
  }

  return (
    <>

      <Modal
        maskClosable={!processing}
        // confirmLoading={state.disableUploadCSV || !state.isProcessing}
        closable={!processing}
        visible
        title="Select the column that has the company domain or website"
        onCancel={onCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        width={"65vw"}
        style={{ overflowX: "auto" }}
        footer={null}
      >
        {state.step === 1 && (
          <Div style={{ textAlign: "right" }}>
            <UploadCsv continueButtonCheck={() => setIsFileSelected(!isFileSelected)} onContinue={onUploadCsvFormSubmit} form={domainUploadForm} />
            <Button disabled={isFileSelected} onClick={onCSVFormSubmit} style={{ marginTop: "10px" }} type="primary">
              Continue
            </Button>
          </Div>
        )}
        {state.step === 2 && (
          <>
            <Flex direction="column" style={{ overflow: "auto" }}>
              <PreviewTable
                columns={state.csvData?.columnHeaders ?? []}
                previewData={state.csvData?.prospects.slice(0, 5) ?? []}
                data={state.csvData?.prospects ?? []}
                onColumnSelected={onColumnSelected}
              />
            </Flex>

            <Flex justify="end" mt={20} style={{ gap: "10px" }}>
              <Button disabled={state.isProcessing} onClick={onBack} type="text">
                Back
              </Button>
              <Button disabled={state.isProcessing} onClick={onUpload} type="primary">
                Upload
              </Button>
            </Flex>
          </>
        )}
      </Modal>
    </>
  );
};
