import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
} from "state/actions/actionTypes";

const reducer = (state: any = {}, action: { type: string; payload: any }) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS: {
      return {
        ...state,
        entities: state?.entities || [],
        loading: true,
      };
    }

    case FETCH_NOTIFICATIONS_SUCCESS: {
      const { notifications } = action.payload;
      return {
        ...state,
        entities: notifications,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
