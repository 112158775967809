import { DeleteOutlined, EditOutlined, MenuOutlined, MoreOutlined } from "@ant-design/icons";
import { Card, Dropdown, Menu, Skeleton } from "antd";
import { useAuth } from "auth/AuthProvider";
import { BgImage, Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { AvatarAccountEnum } from "types/Accounts";
import { getAvatarImage } from "utils/avatar";
import { BOX_SHADOW } from "utils/colors";
import classes from "./styles.module.css";

export interface AccountCardProps {
  id: string;
  name: string;
  timezone: string;
  activeCampaigns: string[];
  inactiveCampaigns?: string[];
  campaignsNo?: number;
  imageName: AvatarAccountEnum;
  acountInfo: any;
  onClick: () => void;
  onSelect: () => void;
  showDeleteConfirm: () => void;
  showEditConfirm: () => void;
  loading?: boolean;
}

export const AccountActionMenu = ({ showDeleteConfirm, showEditConfirm }: any) => {
  const { theme } = useAuth();

  const DropDownMenu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} key="edit" style={{ width: "100%" }} onClick={() => showEditConfirm()}>
        Edit
      </Menu.Item>

      <Menu.Item icon={<DeleteOutlined />} key="deletep" style={{ width: "100%", color: theme.antdConf.errorColor }} onClick={() => showDeleteConfirm()}>
        Delete
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={DropDownMenu} trigger={["click"]}>
      <MenuOutlined />
    </Dropdown>
  );
};

const AccountCard = ({
  id,
  name,
  timezone,
  activeCampaigns,
  imageName,
  acountInfo,
  onClick,
  onSelect,
  showDeleteConfirm,
  showEditConfirm,
  loading = false
}: AccountCardProps) => {
  const { theme } = useAuth();

  return (
    <>
      <Card style={{ width: "100%", boxShadow: BOX_SHADOW, cursor: "pointer" }} onClick={loading ? () => {} : onClick}  >
        <Skeleton loading={loading} active>
          <Flex justify="space-between">
            <BgImage
              className={classes.bgPrimary1}
              imageUrl={getAvatarImage(imageName)}
              h={80}
              w={80}
              br="50%"
              display="inline-block"
              bc={theme.lightGrayColor}
              bs={55}
            />
          </Flex>

          <Div mt={20} className="w-100">
            <Text variant="body1" color="primary" block mb={20} ellipsis title={name}>
              {name}
            </Text>

            <Text variant="caption">
              <span className="text-secondary">{timezone}</span>
            </Text>
            <br />
            <Text variant="caption" mb={10} block>
              <b> {activeCampaigns} </b>
              <span className="text-secondary">Active Campaigns</span>
            </Text>
            <br />
            {acountInfo && acountInfo.email ? (
              <>
                <Text variant="caption">
                  <span className="text-secondary">{acountInfo.companyName}</span>
                </Text>
                <br />
                <Text variant="caption">
                  <span className="text-secondary">{acountInfo.userFullname}</span>
                </Text>
                <br />
                <Text variant="caption">
                  <span className="text-secondary">{acountInfo.email}</span>
                </Text>
              </>
            ) : null}
          </Div>
        </Skeleton>
      </Card>
      <Div className={classes.dotHovermore}>
        <Flex gap={25} mt={10}>
          <AccountActionMenu showDeleteConfirm={showDeleteConfirm} showEditConfirm={showEditConfirm} />
        </Flex>
      </Div>
    </>
  );
};

export default AccountCard;
