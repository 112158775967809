import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useApi from "hooks/useApi";
import { Div, Flex } from "styles/layout";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import AppContext from "contexts/appContext";
import { API_ENDPOINTS } from "constants/api";
import { Text } from "styles/typography";
import { Button, Row, Col, notification, Tabs, Modal, Form, Select } from "antd";
import AccountCard from "components/Campaign/AccounrtCard";
import AddAccountModal from "./Modal/AddAccountModal";
import { TimeZoneType } from "utils/dateTime";
import EditAccountModal from "./Modal/EditAccountModal";
import { useHistory, useLocation } from "react-router-dom";
import { AccountSummaryType } from "types/Accounts";
import { getRandomAccountAvatar } from "utils/avatar";
import { CompassOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import TimeZones from "../../commonAPIs/timezones";
import { MailboxToggleView, MailboxViewEnum } from "../Settings/ViewMode";
import { LOCAL_STORAGE_MAP, useLocalStorage } from "hooks/useLocalStorage";
import { AccountTableView } from "./accountTable";
const { Option } = Select;

interface AgencyPageProps {
  setNavPaths: SetNavPathsFn;
}
const AccountsPage = ({ setNavPaths }: AgencyPageProps) => {
  const [accounts, setAccounts] = useState<AccountSummaryType[]>([]);
  const [accountsUsername, setAccountsUsername] = useState<[]>([]);
  const [accountTempArray, setAccountTempArray] = useState<AccountSummaryType[]>([]);

  const [areAccountsLoading, setAreAccountsLoading] = useState(true);
  // const [statusFilter, setStatusFilter] = useState("Account");
  const { setLoadingMessage } = useContext(AppContext);
  const [accountProviderForm] = Form.useForm();
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<any>({});
  const location = useLocation();
  const [timezonArr, setTimezonArr] = useState<TimeZoneType[] | []>([]);
  const [accountViewMode, setAccountViewMode] = useLocalStorage(
    LOCAL_STORAGE_MAP.ACCOUNT_VIEW_MODE,
    MailboxViewEnum.CARD
  );

  const { callApi } = useApi();
  let history = useHistory();


  const fetchTimezones = async () => {
    var timezones = await TimeZones.getTimezones();
    setTimezonArr(timezones);
  };

  useEffect(() => {
    fetchTimezones();
  }, [])

  const fetchAccounts = useCallback(async () => {
    setAreAccountsLoading(true);
    // setLoadingMessage("Fetching Your Accounts ...");
    const uId= localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : ""
    const { url, method } = API_ENDPOINTS.ACCOUNTS.getListUrl(uId||"");

    const response = await callApi({
      url,
      method
    });

    if (response.success) {
      setAccounts(response.data.accounts);
      setAccountTempArray(response.data.accounts);
      let usernameArray: any = JSON.parse(JSON.stringify(response.data?.accounts))
      usernameArray = response.data.accounts.map((ac: any) => ac.userFullname).filter((ac: any) => ac !== undefined)
      setAccountsUsername(usernameArray && usernameArray.length ? usernameArray.concat('All Users') : []);
      if (response.data.accounts.length == 1) {
        if (!location?.state) {
          history.push({
            pathname: `/account/${response.data.accounts[0].id}/campaigns`,
            state: {
              user: "agency",
              name: `${response.data.accounts[0].name}`
            }
          });
        }
      }
      // setAccounts(response.data.accounts);
    }
    // else {
    //   setAccounts(data);
    // }

    setLoadingMessage("");
    setAreAccountsLoading(false);
  }, [setLoadingMessage, callApi, localStorage.getItem("selectedUserId")]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  useEffect(() => {
    setNavPaths([{ label: "Accounts", linkTo: "/accounts" }]);
    return () => setNavPaths([]);
  }, [setNavPaths]);

  const showAccountsPlaceholder = useMemo(
    () => !areAccountsLoading && accounts.length === 0,
    [areAccountsLoading, accounts]
  );

  // const onChangeStatusFilter = (newStatusFilter: string) => {
  //   setStatusFilter(newStatusFilter);
  // };
  //campaigns?status=Active

  const toggleNewAccountModal = () => setShowNewAccountModal((show) => !show);
  const toggleEditAccountModal = () => setShowEditConfirmModal((show) => !show);

  const getOnClick = useCallback(
    (id: string, accountName: string) => () => {
      localStorage.setItem("account_id", String(id));
      history.push({
        pathname: `/account/${id}/campaigns`,
        state: {
          user: "agency",
          name: accountName
        }
      });
    },
    [history]
  );

  const onAddAccount = useCallback(
    async (accountName: string, timeZone: string) => {
      setShowNewAccountModal((show) => !show);
      const uId= localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : ""

      const { url, method } = API_ENDPOINTS.ACCOUNTS.getCreateUrl(uId||"");
      setLoadingMessage("Creating New Account...");
      try {
        const response = await callApi({ url, method, data: { name: accountName, avatar: getRandomAccountAvatar(), timezone: timeZone } });
        if (response.success) {
          await fetchAccounts();
          notification.success({
            message: "Account Created"
          });
        }
      } catch (err) {
      } finally {
        setLoadingMessage("");
      }
    },
    [setShowNewAccountModal, setLoadingMessage, callApi]
  );

  const onEditAccount = useCallback(
    async (id: string, accountName: string, timeZone: string) => {
      setShowEditConfirmModal((show) => !show);
      const { url, method } = API_ENDPOINTS.ACCOUNTS.getUpdateUrl(id);
      setLoadingMessage("Updating Account...");
      try {
        const response = await callApi({ url, method, data: { name: accountName, timezone: timeZone } });
        if (response.success) {
          await fetchAccounts();
          notification.success({
            message: "Account Updated"
          });
          // const updateData = accounts.map((ac: any) => {
          //   if (ac.id === id) {selectedUserIdta);
        }
      } catch (err) {
      } finally {
        setLoadingMessage("");
      }
    },
    [accounts, setAccounts, setShowEditConfirmModal, setLoadingMessage, callApi]
  );

  const toggleDeleteAccountModal = useCallback(() => setShowDeleteConfirmModal((value) => !value), []);

  const toggleShowDeleteConfirmModal = useCallback(
    (account: any) => () => {
      setSelectedAccount(account);
      setShowDeleteConfirmModal((value) => !value);
    },
    [setSelectedAccount, setShowDeleteConfirmModal]
  );

  const toggleShowEditConfirmModal = useCallback(
    (account: any) => () => {
      console.log("edit toggle called ...");
      setSelectedAccount(account);
      setShowEditConfirmModal((value) => !value);
    },
    [setSelectedAccount, setShowEditConfirmModal]
  );

  const onDeleteHandle = useCallback(
    async (account: any) => {
      const { id } = account;
      toggleDeleteAccountModal();
      const { url, method } = API_ENDPOINTS.ACCOUNTS.getDeleteUrl(id);
      setLoadingMessage("Deleting Account...");
      try {
        const response = await callApi({ url, method });
        if (response.success) {
          notification.success({
            message: "Account Deleted"
          });
          setAccounts(accounts.filter((ac: any) => ac.id !== id));
        }
      } catch (err) {
      } finally {
        setLoadingMessage("");
      }
    },
    [accounts, setAccounts, toggleDeleteAccountModal, setLoadingMessage, callApi]
  );

  const handleOnSelect = (account: any) => () => {
    setSelectedAccount(account);
  };

  const getTimeZoneLabelValue = (timezone: string) => {
    const result = timezonArr.filter((time) => time.tzCode === timezone ? time.label : "")
    return result && result.length ? result[0]?.['label'] : ""
  }

  const filterAccountByName = useCallback((field: string) => {
    if (field === "All Users") {
      setAccounts(accountTempArray)
    } else {
      setAccounts(accountTempArray.filter((ac: any) => ac.userFullname === field))
    }
  }, [accountTempArray])

  console.log("accounts", accounts)
  return (
    <>
      <Flex justify="flex-end" w={"100%"}>
        {/* {accountsUsername && accountsUsername.length ?
          <Select placeholder="All Users" onChange={filterAccountByName} style={{ width: 200, marginRight: 10 }}>
            {accountsUsername.map((value): any => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
          : null
        } */}

        <MailboxToggleView active={accountViewMode} onChange={setAccountViewMode} />

        {/* <Tabs defaultActiveKey={statusFilter} onChange={onChangeStatusFilter}>
          <Tabs.TabPane tab="Accounts" key="Account" />
        </Tabs> */}
        {!showAccountsPlaceholder && (
          <Flex ml={10}>
            <Button type="primary" icon={<PlusOutlined />} onClick={toggleNewAccountModal}>
              Add Account
            </Button>
          </Flex>
        )}
      </Flex>
      <Div mt={10}>
        {accountViewMode === MailboxViewEnum.CARD ? (
          <Row gutter={[10, 10]}>
            {(areAccountsLoading ? Array(6).fill(0) : accounts).map((account, idx) => (
              <Col span={6} key={account.id ?? idx}>
                <AccountCard
                  onClick={getOnClick(account.id, account.name)}
                  onSelect={handleOnSelect(account)}
                  timezone={getTimeZoneLabelValue(account.timezone)}
                  id={account.id}
                  name={account.name}
                  acountInfo={account.email ? { email: account.email, companyName: account.companyName, userFullname: account.userFullname } : {}}
                  imageName={account.avatar}
                  activeCampaigns={account.totalActiveCampaigns}
                  // inactiveCampaigns={account.inactiveCampaigns}
                  // campaignsNo={account.activeCampaigns?.length + account.inactiveCampaigns?.length}
                  showDeleteConfirm={toggleShowDeleteConfirmModal(account)}
                  showEditConfirm={toggleShowEditConfirmModal(account)}
                  loading={areAccountsLoading}
                />
              </Col>
            ))}
          </Row>) : (
          <AccountTableView
            pagination={false}
            dataSource={accounts}
            loading={areAccountsLoading}
            onClick={(accountId, accountName) => getOnClick(accountId, accountName)()}
            onDelete={(account) => toggleShowDeleteConfirmModal(account)()}
            onEdit={(account) => toggleShowEditConfirmModal(account)()}
            fetchTimezone={(tz: any) => getTimeZoneLabelValue(tz)}
          // onSelect={(account) => handleOnSelect(account)()}
          // onStatusChange={(camp) => getOnClickStatusSwitch(camp)()}
          />
        )}
      </Div>

      {showDeleteConfirmModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary",
            danger: true,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={toggleDeleteAccountModal}
          onOk={() => onDeleteHandle(selectedAccount)}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <Text variant="body2">
            Are you sure you want to{" "}
            <Text variant="body2" bold>
              delete{" "}
            </Text>
            {" "}
            {selectedAccount?.name}?
          </Text>
        </Modal>
      )}

      {showNewAccountModal && (
        <Modal visible footer={null} onCancel={toggleNewAccountModal} width={600} centered>
          <AddAccountModal onContinue={onAddAccount} form={accountProviderForm} />
        </Modal>
      )}

      {showEditConfirmModal && (
        <Modal visible footer={null} onCancel={toggleEditAccountModal} width={600} centered>
          <EditAccountModal selectedAccount={selectedAccount} onContinue={onEditAccount} form={accountProviderForm} />
        </Modal>
      )}
    </>
  );
};

export default withAppBar(AccountsPage);