import { DeleteOutlined, EditOutlined, MenuOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Table, TablePaginationConfig } from "antd";
import Column from "antd/lib/table/Column";
import { useAuth } from "auth/AuthProvider";
import { SkeletonTable } from "pages/Settings/MailboxTableView";
import { BgImage, Div, Flex } from "styles/layout";
import { AccountSummaryType } from "types/Accounts";
import { getAvatarImage } from "utils/avatar";
import { LIGHT_BLUE_COLOR } from "utils/colors";

type AccountTableViewProps = {
  dataSource: Array<AccountSummaryType>;
  pagination?: false | TablePaginationConfig;
  loading?: boolean;
  pageSize?: number;
  onDelete: (account: AccountSummaryType) => void;
  onEdit: (account: AccountSummaryType) => void;
  onClick?: (accountId: string, accountName: string) => void;
  fetchTimezone: (tz: string) => string;
};

export const AccountActionMenu = ({
  data,
  showDeleteConfirm,
  showEditConfirm,
}: any) => {
  const { theme } = useAuth();

  const DropDownMenu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} key="edit" style={{ width: "100%" }} onClick={() => showEditConfirm(data)}>
        Edit
      </Menu.Item>

      <Menu.Item icon={<DeleteOutlined />} key="deletep" style={{ width: "100%", color: theme.antdConf.errorColor }} onClick={() => showDeleteConfirm(data)}>
        Delete
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={DropDownMenu} trigger={["click"]}>
      <MenuOutlined />
    </Dropdown>
  );
};

export const AccountTableView = ({ pageSize = 10, dataSource, pagination, ...props }: AccountTableViewProps) => {
  const { theme } = useAuth();
  const columns = [{ dataIndex: "personInfo" }, { dataIndex: "queue" }, { dataIndex: "sent" }, { dataIndex: "ips" }];
  const pageDefaults = { pageSize, size: "small", position: ["topRight"] };
  const paginationUpd =
    pagination === false || dataSource.length <= pageSize ? false : { ...pageDefaults, ...(pagination as any) };

  return props.loading ? (
    <SkeletonTable columns={columns} />
  ) : dataSource.length ? (
    <Table
      dataSource={dataSource}
      pagination={paginationUpd}
      rowKey="campId"
      style={{ cursor: "pointer" }}
      loading={props.loading}
      onRow={(record) => {
        return {
          onClick: () => props.onClick && props?.onClick(record.id, record.name)
        };
      }}
      className="antd__table"
    >
      <Column
        title="Name"
        key="name"
        render={(_, record: AccountSummaryType) => (
          <Flex gap={15} align="center">
            <BgImage
              imageUrl={getAvatarImage(record.avatar)}
              h={40}
              w={40}
              br="50%"
              display="inline-block"
              bc={theme.lightGrayColor}
              bs={25}
              bgColor={LIGHT_BLUE_COLOR}
            />
            {record.name}
          </Flex>
        )}
      />
      <Column
        title="Active Campaigns"
        key="activeCampaigns"
        render={(_, record: AccountSummaryType) => (
          <Flex>{record.activeCampaigns}</Flex>
        )}
      />
      <Column
        title="Timezone"
        key="timezone"
        render={(_, record: AccountSummaryType) => (
          <Flex>{!!record.timezone ? props.fetchTimezone(record.timezone) : "NA"}</Flex>
        )}
      />
      <Column
        title="Actions"
        key="actions"
        render={(_, record: AccountSummaryType) => (
          <Div align="left" mt={10} onClick={(e) => e.stopPropagation()}>
            <AccountActionMenu
              data={record}
              showEditConfirm={props.onEdit}
              showDeleteConfirm={props.onDelete}
            />
          </Div>
        )}
      />
    </Table>
  ) : null;
};