import React from 'react'
import { Modal, Form, Input, Button, Popconfirm } from "antd"
import { Div, Flex } from "styles/layout";
import { DeleteOutlined } from '@ant-design/icons';
import { CampaignSequnceTypeEnum } from "types/Campaign";

const { TextArea } = Input;
interface CampaignSequencesProps {
  id: string;
  touches: number;
  type: CampaignSequnceTypeEnum,
  name?: string,
  description?: string
}
interface CustomSequenceModalProps {
  onClose: () => void;
  addCustomSequence: () => void;
  saving: boolean;
  deleteCustomSequence: () => void;
  editCustomSequence: () => void;
  customSeqDetail: CampaignSequencesProps
  onHandleChange: (label: string, value: string) => void;
}

const CustomSequenceModal = ({
  onClose,
  addCustomSequence,
  saving,
  deleteCustomSequence,
  editCustomSequence,
  customSeqDetail,
  onHandleChange,
}: CustomSequenceModalProps) => {

  const onFinish = (values: any) => {
    console.log("values", values);
    if (customSeqDetail && customSeqDetail.id.length) {
      editCustomSequence();
    } else {
      addCustomSequence();
    }
  };


  return (
    <Modal
      visible
      width={400}
      onCancel={onClose}
      centered
      zIndex={1030}
      title={null}
      closable={false}
      footer={null}
    >
      <Div className="d-flex w-100">
        <Div className="w-100">
          {customSeqDetail && customSeqDetail.id && (
            <Flex direction="row" justify="flex-end" style={{ marginBottom: "10px" }}>
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteCustomSequence()}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Flex>

          )}

          <Form
            name="basic"
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >

            <Form.Item
              label={null}
              name="customValue"
              rules={[{ required: true, message: 'Please enter a name' }]}
              initialValue={customSeqDetail.name}
            >
              <Input value={customSeqDetail.name}
                onChange={(e) => onHandleChange("name", e.target.value)}
                placeholder='Enter name'
                maxLength={55}
                showCount />
            </Form.Item>

            <Form.Item
              label={null}
              name="customDesc"
              initialValue={customSeqDetail.description}
            >
              <TextArea value={customSeqDetail.description}
                onChange={(e) => onHandleChange("description", e.target.value)}
                placeholder='Enter description'
                maxLength={200} showCount />
            </Form.Item>

            {customSeqDetail && customSeqDetail.id ? (
              <>
                <Flex direction="row" justify="flex-end" style={{ marginTop: "30px" }}>
                  <Button type="default" style={{ marginRight: '15px' }} onClick={onClose} > Cancel</Button>
                  <Button type="primary" htmlType="submit" loading={saving}> Save</Button>
                </Flex>
              </>
            ) : (
              <Flex direction="row" justify="flex-end" style={{ marginTop: "30px" }}>
                <Button type="default" style={{ marginRight: '15px' }} onClick={onClose} > Cancel</Button>
                <Button type="primary" htmlType="submit" loading={saving}> Save</Button>
              </Flex>
            )}

          </Form>
        </Div>
      </Div>
    </Modal>
  )
}

export default CustomSequenceModal