import Icon, { DownloadOutlined, EyeOutlined, PlusSquareOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Modal, notification, Select, Table, Tag } from "antd";
import { useAuth } from "auth/AuthProvider";
import { CSVContentsType } from "components/UploadPropects/UploadCsv";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { ReactComponent as UndrawContactsSvg } from "images/undraw_personal_file.svg";
import { subIndustries } from "pages/CompanySearch/CompanyFilterValues";
import React, { useCallback, useContext, useEffect, useState } from "react";
import classes from "styles/common.module.css";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { BOX_SHADOW } from "utils/colors";
import AddToCampaignDialog from "./AddToCampaignDialog";
import './contactsFilter.css';
import {
  defaultDeliveryScore, defaultDepartments, defaultEmployeeSize, defaultIndustries, defaultLevels, defaultRevenue
} from "./ContactsFiltersValues";
import { CSVContacts } from "./CSVUpload";
import DomainModal from "./domainModal";
import DownloadContactsDialog from "./DownloadContactsDialog";
import locationFilterValues from "./LocationFilterValues";
import adaptClasses from "./styles.module.css";
const { Option }: any = Select;

type ContactsFilterProps = {
  adaptComapnies: any,
  contactObj: any,
  companyCount: any,
  companyNames: any,
  companyDomains: any,
  selectedIndustries: any,
  selectedSubIndustries: any,
  selectedCities: any,
  selectedStates: any,
  selectedCountries: any,
  employeeSizes: any,
  revenues: any,
  companyNameState: any,
  companyDomainState: any,
  selectedEmployeeSizeState: any,
  selectedRevenueState: any,
  selectedIndustryState: any,
  selectedSubInsustryState: any,
  selectedLocations: any,
  selectedLocationsState: any,
  Departments: any,
  setDepartments: any
  selectedLevel: any
  setSelectedLevel: any
  selectedTitles: any
  setselectedTitles: any
  minimumDeliveryScore: any
  setMinimumDeliveryScore: any
  titleExactCheckbox: any
  setTitleExactCheckbox: any
};

type ContactsFilterState = {
  isLoading: boolean;
  contactList: any; // todo: add type
};

type ContactSearchPageState = {
  step: number;
  isProcessing: boolean;
  disableUploadCSV?: boolean;
  csvData?: CSVContentsType;
  domainColumn: string;
};


enum ContactSearchTypeEnum {
  CSV = "CSV",
  COMPANY = "COMPANY",
  NONE = "NONe"
}

export const ContactsFilter = ({
  adaptComapnies,
  contactObj,
  companyCount,
  companyNames,
  companyDomains,
  selectedIndustries,
  selectedSubIndustries,
  selectedCities,
  selectedStates,
  selectedCountries,
  employeeSizes,
  revenues,
  companyNameState,
  companyDomainState,
  selectedEmployeeSizeState,
  selectedRevenueState,
  selectedIndustryState,
  selectedSubInsustryState,
  selectedLocations,
  selectedLocationsState,
  Departments,
  setDepartments,
  selectedLevel,
  setSelectedLevel,
  selectedTitles,
  setselectedTitles,
  minimumDeliveryScore,
  setMinimumDeliveryScore,
  titleExactCheckbox,
  setTitleExactCheckbox, }: ContactsFilterProps) => {
  const { theme } = useAuth();

  const [state, setState] = useState({ index: "", uploadedFileName: "", domainCount: 0, domainColumnData: [] });
  const { setLoadingMessage } = useContext(appContext);
  const [adaptContacts, setAdaptContacts] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [responseDataCount, setResponseDataCount] = useState(0);
  const [count, setCount] = useState(0);
  const [tableDataCount, setTableDataCount] = useState(0)
  const [CompanyCount, setCompanyCount] = useState(0);
  const [showAddToCampaignDialog, setShowAddToCampaignDialog] = useState(false);
  const [showDownloadContactsDialog, setShowDownloadContactsDialog] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [toggleUpload, setToggleUpload] = useState(false);
  const [toggleDomainModal, setToggleDomainModal] = useState(false);
  const { callApi } = useApi();
  const [titles, setTitles] = useState<string[]>(selectedTitles || []);
  const [titleExactMatch, setTitleExactMatch] = useState(titleExactCheckbox || false);
  const [loading, setLoading] = useState(false);
  const [fileUploadText, setFileUploadText] = useState("Add");
  const [domainsModal, setDomainsModal] = useState<string[]>([]);
  const byViewContacts = localStorage.getItem('fromCompanyTab');

  const onTitleChange = (e: any) => {
    setTitles(e);
    setselectedTitles(e);
  };

  const onTitleExactMatchChange = (e: any) => {
    setTitleExactMatch(e.target.checked)
    setTitleExactCheckbox(e.target.checked)
  }

  const toggleShowAddToCampaignDialog = () => {
    if (count > 1000000) {
      return notification.warn({
        message: "Maximum contact you can add is 20,000. Please apply more filters to reduce the total contacts."
      })
    } else
      setShowAddToCampaignDialog((prev) => !prev);
  }

  const handleAddToCampaign = () => {
    toggleShowAddToCampaignDialog();
    notification.success({
      message: "Your request to add these contacts to campaign " + campaignName + " has been initiated. When it is completed, you will receive an email notification.",
      description: ``,
      duration: 10
    });
  };

  const toggleShowDownloadContactsDialog = () => {
    if (count > 100000) {
      return notification.warn({
        message: "Maximum contact you can download is 100,000. Please apply more filters to reduce the total contacts."
      })
    } else
      setShowDownloadContactsDialog((prev) => !prev);
  }

  const handleDownloadContacts = () => {
    toggleShowDownloadContactsDialog();
    notification.success({
      message: "Contacts download request has been initiated. When it is completed, you will be emailed with the results file.",
      description: ``,
      duration: 10
    });
  };

  let getLocation = (city: string, state: string, country: string) => {
    let arr = [city, state, country];
    let count = 1;
    let lable = "";
    for (let i = 0; i < 3; i++) {
      if (arr[i]) {
        if (count > 1 && count < 4) {
          lable += (", ");
        }
        lable += (arr[i]);
        count++;
      }
    }
    if (lable.length === 0)
      return "NA";
    else
      return lable
  }

  const getPayload = (action: any, companyName: any, domains: any, accountDomainKey: any, departments: any, levels: any, cities: any, states: any,
    countries: any, employees: any, revenue: any, deliveryScore: any, titleExactMatch: any, industry: any, subIndustry: any, title: any) => {
    let contactspercompany: number = 0;

    let data = {
      "action": action,
      "companyName": companyName,
      "domains": domains,
      "departments": departments,
      "levels": levels,
      "city": cities,
      "state": states,
      "country": countries,
      "employees": employees,
      "revenue": revenue,
      "deliveryScore": deliveryScore,
      "titleExactMatch": titleExactMatch,
      "industry": industry,
      "subIndustry": subIndustry,
      "adaptEntity": "CONTACT",
      "title": title
    }

    return data;
  }
 
  useEffect(() => {
    setDomainsModal(state.domainColumnData);
    let Arr = [companyName.length > 0, companyDomain.length > 0, selectedState.length > 0, selectedCountry.length > 0, selectedIndustry.length > 0, selectedRevenue.length > 0, selectedEmployeeSize.length > 0, listOfSubIndustries.length > 0]
    for (let index = 0; index < Arr.length; index++) {
      if (Arr[index]) {
        fetchContactsApplyFilter(selectedDepartment, selectedLevels, selectedCity, selectedState, selectedCountry, selectedEmployeeSize, selectedRevenue, selectedDeliveryScore, selectedIndustry, listOfSubIndustries);
        break
      }
    }

  }, [setDomainsModal]);

  const [departments, setDepartment] = useState(defaultDepartments);
  const [selectedDepartment, setSelectedDepartment] = useState(Departments || []);

  const [deliveryScore, setDeliveryScore] = useState(defaultDeliveryScore);
  const [selectedDeliveryScore, setSelectedDeliveryScore] = useState(minimumDeliveryScore || null);

  const [levels, setLevels] = useState(defaultLevels);
  const [selectedLevels, setSelectedLevels] = useState(selectedLevel || []);

  const [industry, setIndustry] = useState(defaultIndustries);
  const [selectedIndustry, setSelectedIndustry] = useState(selectedIndustries || []);
  const [listOfSubIndustries, setListOfSubIndustries] = useState(selectedSubIndustries || [])
  const [selectedSubIndustry, setSelectedSubIndustry] = useState(subIndustries);

  const [locations, setLocations] = useState(locationFilterValues);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedState, setSelectedState] = useState(selectedStates || []);
  const [selectedCountry, setSelectedCountry] = useState(selectedCountries || []);

  const [employeeSize, setEmployeeSize] = useState(defaultEmployeeSize);
  const [selectedEmployeeSize, setSelectedEmployeeSize] = useState(employeeSizes || []);

  const [revenue, setRevenue] = useState(defaultRevenue);
  const [selectedRevenue, setSelectedRevenue] = useState(revenues || []);

  const [companyName, setCompanyName] = useState<string[]>(companyNames || []);
  const [companyDomain, setCompanyDomain] = useState<string[]>(companyDomains || []);
  const [disableButtons, setDisableButtons] = useState<boolean>(true)
  const [isUpload, setIsUpload] = useState(true)

  const fetchContactsApplyFilter = async (departmentsFilter: any, levelsFilter: any,
    cityFilter: any, stateFilter: any, countryFilter: any, employeeSizeFilter: any,
    revenueFilter: any, deliveryScoreFilter: any, industryFilter: any, subIndustryFilter: any) => {
    if (departmentsFilter.length === 0)
      departmentsFilter = null
    if (levelsFilter.length === 0)
      levelsFilter = null
    if (cityFilter.length === 0)
      cityFilter = null
    if (stateFilter.length === 0)
      stateFilter = null
    if (countryFilter.length === 0)
      countryFilter = null
    if (employeeSizeFilter.length === 0)
      employeeSizeFilter = null
    if (revenueFilter.length === 0)
      revenueFilter = null
    if (deliveryScoreFilter === 0)
      deliveryScoreFilter = null
    if (industryFilter.length === 0)
      industryFilter = null
    if (subIndustryFilter.length === 0)
      subIndustryFilter = null
    if (adaptComapnies.length === 0)
      adaptComapnies = null

    setLoadingMessage("Fetching Contacts ...");
    const accountDomainKey = localStorage.getItem("accountDomainKey");
    const { url, method } = API_ENDPOINTS.CONTACT_SEARCH.adaptSearch();
    const response = await callApi({
      url,
      method,
      data: getPayload("SEARCH", companyName, (companyDomain.length > 0) ? companyDomain : state.domainColumnData, accountDomainKey, departmentsFilter,
        levelsFilter, cityFilter,
        stateFilter, countryFilter, employeeSizeFilter,
        revenueFilter, deliveryScoreFilter, titleExactMatch, industryFilter, subIndustryFilter, titles)
    });
    if (response.success) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setAdaptContacts(response.data.items);
      setCount(response.data.count);
      setTableDataCount(response.data.items.length)
      setCompanyCount(state.domainColumnData.length || companyCount);
      if (response.data.items.length > 0) {
        setDisableButtons(false)
      } else {
        setDisableButtons(true)
      }
    }
    localStorage.removeItem('fromCompanyTab');
    setLoadingMessage("");
  };

  const datasource = adaptContacts ?
    adaptContacts.map((contact: any, i: any) => {
      return (
        {
          key: i,
          name: contact.firstname + ' ' + contact.lastname,
          title: contact.title,
          company: contact.company,
          location: getLocation(contact.city, contact.state, contact.country)
        }
      )
    }) : [];



  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render(name: any) {
        return (
          <>
            <div style={{ color: '#33a3ff', fontWeight: 500 }}>{name.split('\n')[0]}</div>
          </>
        );
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    }

  ];

  const getChildrenOption = (options: any) => {
    let arr: any = []
    options.forEach((one: any) => {
      arr.push(<Option key={one.name}>{one.name}</Option>)
    })
    return arr;
  }

  const getChildrenOptionDeliveryScore = (options: any) => {
    let arr: any = []
    options.forEach((one: any) => {
      arr.push(<Option key={one.id}>{one.name}</Option>)
    })
    return arr;
  }

  const getSubindustires = (primaryIndustryName: string) => {
    let filteredArray = subIndustries.filter(item => item.industryName == primaryIndustryName);
    console.log(filteredArray);
    return filteredArray.map(item => item.name);
  }

  const handleChange = (value: any, type: any) => {
    if (type === 'Department') {
      setSelectedDepartment(value);
      setDepartments(value);
      console.log(`selected depat ${value}`);

    }
    if (type === 'Level') {
      setSelectedLevels(value);
      setSelectedLevel(value);
      console.log(`selected levelt ${value}`);

    }
    if (type === 'Industry') {
      setSelectedIndustry(value);
      selectedIndustryState(value);
      let filterArr = industry.filter((obj) => {
        if (value.includes(obj.name)) {
          return obj;
        }
      })
      let sub: any = [];
      filterArr.forEach((one: any) => {
        sub = [...sub, ...getSubindustires(one.name)]
      })
      console.log(sub);
      let options: any = []
      sub.forEach((one: any, i: number) => {
        let obj = { id: i, name: one };
        options.push(obj);
      })
      console.log(options)
      setSelectedSubIndustry(options);
      if (value == '') {
        setSelectedSubIndustry(subIndustries);
      }
      console.log(`selected industry ${value}`);
    }
    if (type === 'SubIndustry') {
      setListOfSubIndustries(value)
      selectedSubInsustryState(value)
      console.log(`selected subindustry ${value}`);

    }
    if (type === 'Location') {
      var cityArray: any = [];
      var stateArray: any = [];
      var countryArray: any = [];

      for (var i = 0; i < value.length; i++) {
        var locationSplitArray = value[i].split(',');
        if (locationSplitArray.length === 3) {
          cityArray.push(locationSplitArray[0].trim());
          stateArray.push(locationSplitArray[1].trim());
          countryArray.push(locationSplitArray[2].trim());
        }
        else if (locationSplitArray.length === 2) {
          stateArray.push(locationSplitArray[0].trim());
          countryArray.push(locationSplitArray[1].trim());
        }
        else if (locationSplitArray.length === 1) {
          countryArray.push(locationSplitArray[0].trim());
        }
      }
      setSelectedCity(cityArray);
      setSelectedState(stateArray);
      setSelectedCountry(countryArray);
      selectedLocationsState(value);
      console.log(`selected location ${value}`);
    }
    if (type === 'EmployeeSize') {
      setSelectedEmployeeSize(value);
      selectedEmployeeSizeState(value);
      console.log(`selected employeesize ${value}`);

    }
    if (type === 'Revenue') {
      setSelectedRevenue(value);
      selectedRevenueState(value);
      console.log(`selected revenue ${value}`);

    }
    if (type === 'DeliveryScore') {
      // debugger;
      setSelectedDeliveryScore(parseInt(value));
      setMinimumDeliveryScore(parseInt(value));
      console.log(`selected score ${value}`);

    }
  }
  // const handleRemoveItem = (item: any, type: any) => {
  //   let filterSelectedItemDepartment: any = selectedDepartment;
  //   let filterSelectedItemLevel: any = selectedLevels;
  //   if (type == 'Department') {
  //     filterSelectedItemDepartment = selectedDepartment.filter((one: any) => one !== item);
  //     setSelectedDepartment(filterSelectedItemDepartment);
  //   }
  //   if (type == 'Level') {
  //     filterSelectedItemLevel = selectedLevels.filter((one: any) => one !== item);
  //     setSelectedLevels(filterSelectedItemLevel);
  //   }
  //   fetchContactsApplyFilter(filterSelectedItemDepartment, filterSelectedItemLevel);
  // }

  const onAddToCampaignDone = useCallback(
    (campaignName: string) => setCampaignName((st) => (campaignName)),
    []
  );

  const onUploadDone = (domainIndex: string, domainCount: number, uploadedFileName: string, domainColumnData: any) => {
    setState({ index: domainIndex, uploadedFileName: uploadedFileName, domainCount: domainCount, domainColumnData: domainColumnData })
    setToggleUpload(false);
    setCompanyDomain([]);
    setIsUpload(false);
    setDomainsModal(domainColumnData);
  }

  const onCompanyNameChange = (e: any) => {
    setCompanyName(e);
    companyNameState(e);
  };

  const onCompanyDomainChange = (e: any) => {
    setCompanyDomain(e);
    companyDomainState(e);
  };

  let formRef: any = React.createRef();

  const clearAllTheFilters = () => {
    formRef.current.resetFields();
    setSelectedRevenue([]);
    setSelectedEmployeeSize([]);
    setListOfSubIndustries([]);
    setSelectedIndustry([]);
    setSelectedCountry([]);
    setSelectedState([]);
    setCompanyName([]);
    setCompanyDomain([]);
    setSelectedDepartment([]);
    setLevels([]);
    setTitles([]);
    setSelectedDeliveryScore(null);
    setTitleExactMatch(false);
    setDomainsModal([]);
    setCount(0);
    setDisableButtons(true);
    setState({ index: "", uploadedFileName: "", domainCount: 0, domainColumnData: [] });
    setCompanyCount(0);
    setIsUpload(true);
    selectedLocationsState([]);
    companyNameState([]);
    companyDomainState([]);
    selectedEmployeeSizeState([]);
    selectedRevenueState([]);
    selectedIndustryState([]);
    selectedSubInsustryState([]);
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Flex justify="space-between" align="center" className="top-btns" >
        <Div>
          {new Intl.NumberFormat('en-US').format(count)} Contacts
        </Div>
      </Flex>
      <Div
        bgColor={theme.whiteColor}
        mt={20}
        style={{
          boxShadow: BOX_SHADOW,
          paddingBottom: "20px"
        }}
      >
        <Flex
          mb={10}
        >
          {/* LEFT COLUMN */}

          <Div mw={250} w={250} mr={15} ml={15} mt={15} className="filters-left-col">
            <Text variant="sub2">
              Filters
            </Text>

            <Div w="100%" mt={20}>
              <Form
                className="filter-form"
                ref={formRef}
              >

                {/* <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Company Domain
                </Text>
                <Form.Item name={"Company Domain"}>
                  <Select
                    dropdownClassName="display-none"
                    className="mt-2"
                    defaultValue={companyDomain}
                    mode="tags" style={{ width: '100%' }}
                    onChange={onCompanyDomainChange} tokenSeparators={[',']}>
                  </Select>
                </Form.Item> */}

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Company Domain
                </Text>
                <Form.Item name={"Company Domain"} style={{ cursor: 'pointer' }}>
                  {domainsModal.length != 0 &&
                    <>
                      <div className="domainsFilterBox" style={{ marginTop: "10px" }}>
                        {
                          domainsModal.map((companyWebsite: any, index: any) => {
                            return (
                              (index < 3) ? <Tag style={{ cursor: 'default' }}>{companyWebsite}</Tag> : ''
                            )
                          })
                        }
                      </div>
                    </>
                  }
                  {isUpload && (
                    <Select
                      dropdownClassName="display-none"
                      className="mt-2"
                      defaultValue={companyDomain}
                      mode="tags" style={{ width: '100%' }}
                      onChange={onCompanyDomainChange} tokenSeparators={[',']}>
                    </Select>
                  )}
                  <Flex mt={1} justify="space-between">
                    {(domainsModal.length > 3) &&
                      <a
                        style={{ display: 'inline-block', fontSize: '10px', marginTop: '3px' }}
                        onClick={() => { setToggleDomainModal(true) }}>
                        <EyeOutlined />
                        <span style={{ fontSize: "11px", marginLeft: "4px" }}>Show More</span>
                      </a>
                    }
                    {/* {adaptComapnies.length === 0 && */}
                    <a style={{ display: 'inline-block', fontSize: '10px', marginTop: '3px' }}
                      onClick={() => { setToggleUpload(!toggleUpload) }}>
                      <UploadOutlined />
                      <span style={{ fontSize: "11px", marginLeft: "4px" }}>Upload</span>
                    </a>
                    {/* } */}
                  </Flex>


                  {toggleDomainModal &&
                    <>
                      <Modal
                        visible
                        footer={false}
                        onCancel={() => setToggleDomainModal(false)}
                        className='domainsFilter-popup'
                      >
                        <DomainModal uploadFileDomains={state.domainColumnData} adaptComapnies={adaptComapnies} />
                      </Modal>
                    </>
                  }


                  {toggleUpload &&
                    <CSVContacts onCancel={() => setToggleUpload(false)} onDone={onUploadDone} />
                  }
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Company Name
                </Text>
                <Form.Item name={"Company Name"}>
                  <Select
                    dropdownClassName="display-none"
                    className="mt-2"
                    defaultValue={companyName}
                    mode="tags"
                    style={{ width: '100%' }}
                    onChange={onCompanyNameChange}
                    tokenSeparators={[',']}>
                  </Select>
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Location
                </Text>
                <Form.Item name={"Locations"}>
                  <Select
                    mode="multiple"
                    className="mt-2"
                    showSearch style={{ width: '100%' }}
                    onChange={(value) => handleChange(value, 'Location')}
                    defaultValue={selectedLocations}
                  >
                    {getChildrenOption(locations)}
                  </Select>
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Industry
                </Text>
                <Form.Item name={"Industry"}>
                  <Select mode="multiple" className="mt-2" defaultValue={selectedIndustry} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'Industry')}>
                    {getChildrenOption(industry)}
                  </Select>
                </Form.Item>
                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Sub Industry
                </Text>
                <Form.Item name={"SubIndustry"}>
                  <Select mode="multiple" className="mt-2" defaultValue={listOfSubIndustries} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'SubIndustry')}>
                    {getChildrenOption(selectedSubIndustry)}
                  </Select>
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Employee Size
                </Text>
                <Form.Item name={"EmployeeSize"}>
                  <Select mode="multiple" className="mt-2" defaultValue={selectedEmployeeSize} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'EmployeeSize')}>
                    {getChildrenOption(employeeSize)}
                  </Select>
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Revenue
                </Text>

                <Form.Item name={"Revenue"}>
                  <Select mode="multiple" className="mt-2" defaultValue={selectedRevenue} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'Revenue')}>
                    {getChildrenOption(revenue)}
                  </Select>
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Department
                </Text>
                <Form.Item name={"Departments"}>
                  <Select
                    mode="multiple"
                    className="mt-2"
                    defaultValue={selectedDepartment}
                    showSearch
                    style={{ width: '100%' }}
                    onChange={(value) => handleChange(value, 'Department')}
                  >
                    {getChildrenOption(departments)}
                  </Select>
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Level
                </Text>
                <Form.Item name={"Levels"}>
                  <Select mode="multiple" className="mt-2" defaultValue={selectedLevels} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'Level')}>
                    {getChildrenOption(levels)}
                  </Select>
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Title
                </Text>
                <Form.Item name={"Title"}>
                  <Select dropdownClassName="display-none" className="mt-2"
                    mode="tags" style={{ width: '100%' }} defaultValue={titles}
                    onChange={onTitleChange} tokenSeparators={[',']}>
                  </Select>
                  <Checkbox style={{ color: 'rgba(96,99,103,.55)', fontSize: '12px' }} defaultChecked={titleExactMatch} onChange={onTitleExactMatchChange}>Find exact matches only</Checkbox>
                </Form.Item>

                <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                  Minimum Delivery Score
                </Text>
                <Form.Item name={"DeliveryScore"}>
                  <Select
                    allowClear
                    className="mt-2"
                    defaultValue={selectedDeliveryScore}
                    showSearch style={{ width: '100%' }}
                    onChange={(key) => handleChange(key, 'DeliveryScore')}>
                    {getChildrenOptionDeliveryScore(deliveryScore)}
                  </Select>
                </Form.Item>
                <Flex justify="end" >
                  <Button type="text" onClick={clearAllTheFilters}>Clear</Button>
                  <Button type="primary" style={{ marginLeft: "10px", pointerEvents: 'auto' }} onClick={(e) => fetchContactsApplyFilter(selectedDepartment, selectedLevels, selectedCity, selectedState, selectedCountry, selectedEmployeeSize, selectedRevenue, selectedDeliveryScore, selectedIndustry, listOfSubIndustries)}>Search</Button>
                </Flex>

              </Form>
            </Div>
          </Div>

          {/* RIGHT COLUMN */}

          <Flex
            style={{
              minHeight: "calc(100vh - 140px)",
              width: "calc(100% - 250px)"
            }}
            className="records-right-col"
            grow={1}
            mr={15}
            direction="column"
          >

            {/* SELECTED FILTERS */}
            {/* <Div
            className="selected-tiles"
          >
            {selectedDepartment.map((one: any) => {
              return (
                <Div className="tile" onClick={() => handleRemoveItem(one, 'Department')}>{one}
                  <span className="close-tile">
                    <CloseOutlined />
                  </span>
                </Div>
              )
            })}

            {selectedLevels.map((one: any) => {
              return (
                <Div className="tile" onClick={() => handleRemoveItem(one, 'Level')}>{one}
                  <span className="close-tile">
                    <CloseOutlined />
                  </span>
                </Div>
              )
            })}
          </Div> */}

            <Div
              mt={15}
              className={"contacts-table " + classes.scrollContainer}
            >
              <Flex justify="end" mb={20}>
                {!disableButtons && (
                  <>
                    <Button
                      style={{ color: theme.antdConf.primaryColor, padding: 0, background: "none" }}
                      type="text"
                      icon={<DownloadOutlined />}
                      onClick={toggleShowDownloadContactsDialog}>

                      Download Contacts
                    </Button>
                    <Button
                      style={{ color: theme.antdConf.primaryColor, padding: 0, background: "none", marginLeft: "15px" }}
                      type="text"
                      icon={<PlusSquareOutlined />}
                      onClick={toggleShowAddToCampaignDialog}>
                      Add to Campaign
                    </Button>
                  </>
                )}
                {showDownloadContactsDialog && (
                  <DownloadContactsDialog
                    companyName={companyName}
                    downloadContactsLimit={count}
                    onCancel={toggleShowDownloadContactsDialog}
                    onOk={handleDownloadContacts}
                    uploadedFilename={contactObj.uploadedFileName}
                    departments={selectedDepartment}
                    levels={selectedLevels}
                    industry={selectedIndustry}
                    subIndustry={listOfSubIndustries}
                    city={selectedCity}
                    state={(selectedStates.length > 0) ? selectedStates : selectedState}
                    country={(selectedCountries.length > 0) ? selectedCountries : selectedCountry}
                    revenue={selectedRevenue}
                    employees={selectedEmployeeSize}
                    title={titles}
                    titleExactMatch={titleExactMatch}
                    deliveryScore={selectedDeliveryScore}
                    domains={(companyDomain.length > 0) ? companyDomain : state.domainColumnData}
                  />
                )}
                {showAddToCampaignDialog && (
                  <AddToCampaignDialog
                    companyName={companyName}
                    downloadContactsLimit={count}
                    onOk={handleAddToCampaign}
                    onAddToCampaignDone={onAddToCampaignDone}
                    onCancel={toggleShowAddToCampaignDialog}
                    departments={selectedDepartment}
                    levels={selectedLevels}
                    domains={(companyDomain.length > 0) ? companyDomain : state.domainColumnData}
                    industry={selectedIndustry}
                    subIndustry={listOfSubIndustries}
                    city={selectedCity}
                    state={(selectedStates.length > 0) ? selectedStates : selectedState}
                    country={(selectedCountries.length > 0) ? selectedCountries : selectedCountry}
                    revenue={selectedRevenue}
                    employees={selectedEmployeeSize}
                    title={titles}
                    titleExactMatch={titleExactMatch}
                    deliveryScore={0}
                  />
                )}
              </Flex>
              {!disableButtons && (<Table
                size="small"
                id="adaptContactSearch-table"
                className="outwin-table"
                style={{ marginBottom: "20px", marginTop: "0" }}
                columns={columns}
                dataSource={datasource}
                pagination={false}
                onRow={() => {
                  return {
                    className: "outwin-table-row-hover",
                  };
                }}
              />)}
              {disableButtons && (
                <Flex className={adaptClasses.illustrationDiv}>
                  <Icon component={UndrawContactsSvg} style={{ fontSize: "200px" }} />
                </Flex>
              )}
              {!disableButtons && (count > tableDataCount) && (
                <Flex justify="end">
                  <p style={{ marginTop: 20, color: 'rgba(96,99,103,.55)', fontSize: '12px' }}>
                    This table shows only limited contacts. To view all contacts, click the download button and you will be
                    charged for the total contacts that you download.
                  </p>
                </Flex>
              )
              }
            </Div>
          </Flex>


        </Flex>
      </Div >
    </>
  );
};
