import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Tag, Tooltip, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useAuth } from "auth/AuthProvider";
import MailBoxList, { MAILBOXLIST_FILTER } from "components/MailBox/List";
import MailBoxProviderDetailModal from "components/MailBox/Modal/MailBoxDetailProviderModal";
import MailBoxProvider, { getProviderDetails } from "components/MailBox/Provider";
import MailBoxSettings, { MailBoxSettingsType } from "components/MailBox/Settings";
import { API_ENDPOINTS, WHISPER_BASE_URL } from "constants/api";
import { NEW_CAMPAIGN_LS_KEY, OAUTH_STATE_LS_KEY } from "constants/localstorage";
import translationStrings from "constants/strings.json";
import IpsDetailsModal from "containers/IpsDetailsModal";
import LogDetailsModal from "containers/LogDetailsModal";
import SendEmailModal from "containers/sendEmailModal";
import appContext from "contexts/appContext";
import withLoader from "hoc/withLoader";
import useApi from "hooks/useApi";
import { isEmpty } from "lodash";
import ManualGmailLoginModal from "pages/CampaignDetail/Modals/ManualGmailLoginModal";
import RecoveryEmailModal from "pages/CampaignDetail/Modals/RecoveryEmailModal";
import TroubleShootModal from "pages/CampaignDetail/Modals/TroubleShootModal";
import UpdatePasswordModal from "pages/CampaignDetail/Modals/UpdatePasswordModal";
import VerficationModal from "pages/CampaignDetail/Modals/VerficationModal";
import VerificationOptionsModal from "pages/CampaignDetail/Modals/VerificationOptionsModal";
import VerifyAccessCodeModal from "pages/CampaignDetail/Modals/VerifyAccessCodeModal";
import VerifyEmailByPhoneNoModal from "pages/CampaignDetail/Modals/VerifyEmailByPhoneNoModal";
import VerifyPhoneNoModal from "pages/CampaignDetail/Modals/VerifyPhoneNoModal";
import { EmptyMailboxPlaceholder } from "pages/Settings/Mailbox";
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { canModifyCampaignState } from "state/selectors";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { CampaignType } from "types/Campaign";
import { MailBoxResponseType, MailboxTypeEnum } from "types/MailBox";
import Axios from "utils/axios";
import { BOX_SHADOW } from "utils/colors";
import LocalStorage from "utils/localstorage";
import { v4 as uuid } from "uuid";
import classes from "../Campaigns/styles.module.css";
import BoundConnectionModal from "./Modals/ConnectionModal";
import GmailIMAPLoginModal from "./Modals/GmailIMAPLoginModal";
import MailExistModal from "./Modals/MailExistModal";
import ReponseSkipModal from "../Settings/ReponseSkipModal";
import GsuiteLoginModal from "pages/CampaignDetail/Modals/GsuiteLoginModal";
// import GsuiteSetupModal from "pages/CampaignDetail/Modals/GsuiteSetupModal";
interface CampaignMailboxesProps {
  campaignDetail: CampaignType;
  afterSave: () => void;
  loading: boolean;
  setLoading: (loadingMessage: string) => void;
}

const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="caption" color="secondary" style={{ paddingLeft: 3 }}>
    {children}
  </Text>
);

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex pt={10} pb={5} ml={10} m={30} direction="column">
    {children}
  </Flex>
);
interface Proxy {
  id: string;
  host: string;
  port: string;
  user: string;
  pwd: string;
}

export enum NewCampaignSteps {
  ONBOARDING = "ONBOARDING",
  MAILBOX_LISTING = "MAILBOX_LISTING",
  MAILBOX_PROVIDER = "MAILBOX_PROVIDER",
  MAILBOX_SETTINGS = "MAILBOX_SETTINGS",
  PROSPECT_UPLOAD = "PROSPECT_UPLOAD",
  PROSPECT_COLUMN_MAPPING = "PROSPECT_COLUMN_MAPPING",
  PROSPECT_SUMMARY = "PROSPECT_SUMMARY",
  SETUP = "SETUP"
}
export interface CampaignStateType {
  campaignId: null | string;
  currentStepName: NewCampaignSteps;
  prospectData: {
    fileUrl: null | string;
    columnHeaders: string[];
    sampleProspectData: string[];
    totalProspects: number;
  };
  prospectSummary: {
    total: number;
    failed: number;
    failedCsvLink: string;
    added: number;
  };
  selectedMailBoxesIds: string[];
}

export const CAMPAIGN_INITIAL_STATE: CampaignStateType = {
  campaignId: null,
  currentStepName: NewCampaignSteps.ONBOARDING,
  prospectData: {
    fileUrl: null,
    columnHeaders: [],
    sampleProspectData: [],
    totalProspects: 0
  },
  prospectSummary: {
    total: 0,
    failed: 0,
    failedCsvLink: "",
    added: 0
  },
  selectedMailBoxesIds: []
};

const Mailboxes = ({ campaignDetail, loading, setLoading, afterSave }: CampaignMailboxesProps) => {
  const { currentStepName: currentStepNameInLs = CAMPAIGN_INITIAL_STATE.currentStepName } =
    (LocalStorage.getValue(NEW_CAMPAIGN_LS_KEY) as CampaignStateType) || {};
  const { callApi } = useApi();
  const { theme } = useAuth();
  const location = useLocation<{ addEmailState?: string; email?: string; lastname?: string; firstname?: string }>();
  const [instanceId, setInstanceId] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [task, setTask] = useState("");
  const [showText, setShowText] = useState("");

  const [mailBoxSettingsForm] = Form.useForm();
  const [subform] = Form.useForm();
  const [mailBoxOfficeSmtp] = Form.useForm();
  const [microsoftValidation] = Form.useForm();
  const [accessCodeValidation] = Form.useForm();
  const [alernateEmailValidation] = Form.useForm();
  const [updatePasswordform] = Form.useForm();
  // const [gmailMailConfirmation] = Form.useForm();

  const [showConnectEmailValidationModal, setshowConnectEmailValidationModal] = useState(false);
  const [showConnectMicrosoftValidation, setshowConnectMicrosoftValidationl] = useState(false);
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [isEmailValidation, setisEmailValidation] = useState(false);
  const [isWrongPassword, setisWrongPassword] = useState(false);
  const [isVerifyEmail, setisVerifyEmail] = useState(false);
  const [browserId, setBrowserId] = useState("");
  const [isWrongEmail, setisWrongEmail] = useState(false);
  const [editableId, setEditableId] = useState("");
  const [isEditMode, setisEditMode] = useState(false);
  const [isEditModeForWorkspacePersonal, setEditModeWhileProviderWorkspacePersonal] = useState(false);

  const [isInBoundConnection, setisInBoundConnection] = useState(false);
  const [isOutBoundConnection, setisOutBoundConnection] = useState(false);
  const [isBoundConnection, setisBoundConnection] = useState(false);
  const [smtpInBoundError, setSmtpInBoundError] = useState("");
  const [smtpOutBoundError, setSmtpOutBoundError] = useState("");
  const [validating, setValidating] = useState(false);
  const [action, setAction] = useState("");
  const [showGmailConfirmationMailModal, setshowGmailConfirmationMailModal] = useState(false);
  const [show2StepVerificationModal, setshow2StepVerificationModal] = useState(false);
  const [showVerificationOptionsModal, setVerificationOptionsModal] = useState(false);
  const [isVerifyEmailForGmail, setisVerifyEmailForGmail] = useState(false);
  const [showManualGmailLogin, setManualGmailLogin] = useState(false);
  const [extensionStatus, setExtensionStatus] = useState("");
  const [installExtension, setInstallExtension] = useState(false);
  const [subMessage, setSubMessage] = useState("");
  const [proxy, setProxy] = useState<Proxy>({
    id: "",
    host: "",
    port: "",
    user: "",
    pwd: ""
  });
  const [showTroubleShootModal, setShowTroubleShootModal] = useState(false);
  const [settingEditView, setSettingEditView] = useState(false); //inboxId
  // const [smtpValidationStatus, setsmtpValidationStatus] = useState('');
  const [connectModalStep, setStepWhenConnectionModalClosed] = useState(0);
  const [providers, setProviders] = useState("");
  const [currentStepName, setCurrentStepName] = useState(currentStepNameInLs as NewCampaignSteps);
  const [Data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: ""
  });
  const [showIMAPVerification, setShowIMAPVerification] = useState(false);
  const [state, setState] = useState({ responseSkipModalState: false, responseSkipValue: "", selectedEmail: "" });
  const { responseSkipModalState, responseSkipValue, selectedEmail } = state;
  const isFree = useSelector(canModifyCampaignState(campaignDetail.id));
  const history = useHistory();
  const inboxIds = useMemo(() => {
    const { inboxIds = [] } = campaignDetail;
    return inboxIds;
  }, [campaignDetail]);

  const [mailBoxes, setMailBoxes] = useState<MailBoxResponseType[]>([]);
  const [form] = Form.useForm();
  const [linkExistingForm] = Form.useForm();
  const [mailBoxProviderForm] = Form.useForm();

  const { setLoadingMessage } = useContext(appContext);
  const [showMailBoxProviderModal, setShowMailBoxProviderModal] = useState(false);
  const [showPausedMailboxModal, setShowPausedMailboxModal] = useState(false);
  const [showLinkExistingEmailModal, setShowLinkExistingEmailModal] = useState(false);
  const [showConnectMailboxModal, setShowConnectMailboxModal] = useState(false);
  const [showConnectMailStmpModal, setshowConnectMailStmpModal] = useState(false);
  const [isSettingSmtp, setIsSettingSmtp] = useState(false);
  const [showTextVerifyModal, setshowTextVerifyModal] = useState(false);
  const [showUpdatePasswordModal, setshowUpdatePasswordModal] = useState(false);
  const [mailboxesStats, setMailboxesStats] = useState({ mailboxes: 0, capacity: 0, maxEmails: 0 });
  const [showMailBoxProviderDetailModal, setShowMailBoxProviderDetailModal] = useState(false);

  const [providerType, setProviderType] = useState("");

  const [selectedEmailForIps, setSelectedEmailForIps] = useState("");
  const [selectedInboxIdForLogs, setselectedInboxIdForLogs] = useState("");
  const [selectedInboxIdForSendEmail, setselectedInboxIdForSendEmail] = useState("");
  const [selectedInboxEmailForSendEmail, setselectedInboxEmailForSendEmail] = useState(""); //inboxId
  const [showGsuiteMail, setShowGsuiteModal] = useState(false)
  // const [gsuiteSetupModlal, setOpenSetupModal] = useState(false)
  // const [settingModal, setSettingModal] = useState(true)

  const oauthState = useMemo(() => {
    const oauthLocalState: {
      state: string;
    } | null = LocalStorage.getValue(OAUTH_STATE_LS_KEY);

    if (location.state?.addEmailState && oauthLocalState?.state && location.state?.addEmailState === oauthLocalState.state) {
      return oauthLocalState.state;
    }
    return "";
  }, [location]);
  const urlState = location.state;
  const [selectedMailbox, setSelectedMailbox] = useState<{
    email: string;
    campaignName: string | null;
    campaignId: string | null;
    paused: boolean | null;
    id: null | string;
    settings?: MailBoxSettingsType;
    provider?: string;
    protocol?: string;
  } | null>({
    email: "",
    id: null,
    campaignId: "",
    paused: false,
    campaignName: ""
  });
  const [isMailboxExist, setIsMailboxExist] = useState(false);
  const [exisMailMessage, setExisMailMessage] = useState("");
  const isSelectedMailboxNew = useMemo(() => !!oauthState, [oauthState]);
  const localStorageValues = LocalStorage.getValue(OAUTH_STATE_LS_KEY);
  const showSettingsModal = useMemo(() => (isSelectedMailboxNew || selectedMailbox?.id || isSettingSmtp), [isSelectedMailboxNew, selectedMailbox, isSettingSmtp]);
  const fetchInboxes = useCallback(async () => {
    // const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl();
    let account_id = String(localStorage.getItem("account_id"));
    const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl(account_id);

    setLoading("Fetching Campaign mailboxes ...");
    const response = await callApi({
      url,
      method
    });
    setLoading("");
    if (response.success) {
      response.data.inboxes.sort((first: any, second: any) => (first.email < second.email ? -1 : first.email > second.email ? 1 : 0));
      let sortedResult = sortingByCheckedAndUnchecked(response.data.inboxes, inboxIds);
      // const mailList = sortedResult.map((item: any, index: number) => ({...item, Campaign: index < Math.floor(Math.random() * (6 - 2 + 1) + 2) ? campaignDetail?.id : null }));
      let Ids = new Set(inboxIds);
      let result = sortedResult.filter((sr: any) => Ids.has(sr.id));
      setMailboxesStats({
        mailboxes: result.length,
        capacity: result.reduce(function (sum: any, current: any) {
          return sum + current.capacity;
        }, 0),
        maxEmails: result.reduce(function (sum: any, current: any) {
          return sum + current?.maxEmails;
        }, 0)
      });
      setMailBoxes(sortedResult);
    }
  }, [callApi, setLoading]);
  const toggleShowModalSmtp = () => setshowConnectMailStmpModal((show) => !show);
  const handleVerifyEmailByPhoneNo = () => setshowConnectMicrosoftValidationl((show) => !show);
  const toggleShowModalEmailValidation = () => setshowConnectEmailValidationModal((show) => !show);
  const handleVerifyAccessCodeModal = () => setShowAccessCodeModal((show) => !show);
  const handleRecoveryEmailModal = () => setshowGmailConfirmationMailModal((show) => !show);
  const handleVerificationModal = () => setshow2StepVerificationModal((show) => !show);
  const handleVerificationOptionsModal = () => setVerificationOptionsModal((show) => !show);
  const handleTroubleShootModal = () => setShowTroubleShootModal((show) => !show);
  const handleManualGmailLoginModal = () => setManualGmailLogin((show) => !show);

  useEffect(() => {
    if (!isEmpty(campaignDetail)) {
      const localStore = localStorageValues;
      setSelectedMailbox({
        email: urlState?.email || "",
        campaignId: campaignDetail?.id,
        campaignName: campaignDetail?.name,
        paused: false,
        id: null,
        provider: localStore?.provider
      });
      setData((prevState) => ({
        ...prevState,
        lastName: urlState?.lastname || "",
        firstName: urlState?.firstname || ""
      }));

      if (localStore?.provider) {
        setProviders(localStore?.provider);
      }
    }
  }, [campaignDetail, urlState]);

  const sortingByCheckedAndUnchecked = (mailObj: any, inboxIds: any) => {
    var filteredArray = mailObj.filter(function (itm: any) {
      return inboxIds.indexOf(itm.id) > -1;
    });
    var filteredNotCheckedArray = mailObj.filter(function (itm: any) {
      return inboxIds.indexOf(itm.id) === -1;
    });
    Array.prototype.push.apply(filteredArray, filteredNotCheckedArray);
    return filteredArray;
  };
  console.log("selectedMailboxselectedMailbox",selectedMailbox)
  const getDnsPublicDnsUrl = useCallback(
    async (email: string, password: string) => {
      let payload = {
        email: email,
        password: password,
        inboxProvider: "GOOGLE",
        test: false
      };
      let response;
      if (window.location && window.location.origin.includes("localhost")) {
        console.log("localhost called............");
        response = {
          success: true,
          data: {
            browserId: uuid(),
            publicDNS: "localhost",
            instanceId: "i-0864145ea18867fcb",
            proxy: {
              host: "135.148.125.52",
              id: uuid(),
              port: "8181",
              pwd: "vendisys@135",
              user: "vendisys"
            }
          }
        };
      } else {
        console.log("dev and live called............");
        const { url, method } = API_ENDPOINTS.DNSCONNECTION.getListUrl();
        response = await callApi({
          url,
          method,
          data: payload,
          external: true
        });
      }
      // let publicDns = 'https://brooklyn.outwinhq.io';
      let instanceId = "";
      let browserIds = "";
      let proxyServer: Proxy = {} as Proxy;
      if (response.success && response.data) {
        instanceId = response.data.instanceId;
        // publicDns = `https://brooklyn.outwinhq.io`;
        browserIds = response.data.browserId;
        setBrowserId(browserIds);
        if (response.data && response.data.proxy) {
          proxyServer = response.data?.proxy;
          setProxy(proxyServer);
          // proxyServer = {
          //   "host": "144.217.35.166",
          //   "port": "8181",
          //   "user": "vendisys",
          //   "pwd": "vendisys@135"
          // }
        }
        // setPublicDns(publicDns);
        setInstanceId(instanceId);
      } else {
        setLoadingMessage("");
        notification.error({
          message: "Server resources are currently being allocated. Please try again after 5 minutes."
        });
      }
      return { browserId: browserIds, instanceId, proxy: proxyServer };
    },
    [callApi, setProxy, setBrowserId, setInstanceId]
  );

  const { Title } = Typography;

  const onSubmit = useCallback(
    async ({ selectedMailBoxes }) => {
      const collectedMail = inboxIds.concat(selectedMailBoxes);
      // selectedMailBoxes.filter((arr1Item: any) => !inboxIds.includes(arr1Item));
      notification.destroy();
      if (!collectedMail.length) {
        notification.error({
          message: "No mailboxes selected",
          description: "Select at least one mailbox to the campaign"
        });
        return;
      }
      const { id } = campaignDetail;

      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(id);

      const {
        // autoRemoveEmailReplied,
        // days,
        // endHour,
        name
        // removeProspectEmailLinkClick,
        // removeProspectEmailOpen,
        // removeProspectEmailReplied,
        // startHour,
        // sendEmailOnHolidays,
        // timeZone,
      } = campaignDetail;

      const payload = {
        // name,
        // days,
        // sendEmailOnHolidays,
        // removeProspectEmailOpen,
        // removeProspectEmailLinkClick,
        // removeProspectEmailReplied,
        // autoRemoveEmailReplied,
        // startHour,
        // endHour,
        // timeZone,
        action: "InboxAdd",
        inboxIds: collectedMail
        // accountId: String(localStorage.getItem("account_id"))
      };
      setLoadingMessage(`Updating mailboxes for ${name} ...`);
      const response = await callApi({
        url,
        method,
        data: payload
      });
      setLoadingMessage("");
      if (response.success) {
        notification.success({
          message: "Campaign Mailboxes updated"
        });

        afterSave();
      } else {
        setIsMailboxExist(true); // selectedMailBoxes.filter((arr1Item: any) => !inboxIds.includes(arr1Item));
      }
    },
    [callApi, campaignDetail, setLoadingMessage, afterSave]
  );

  const [mailboxDelinking, setMailboxDelinking] = useState(false);
  const onDelete = useCallback(
    async (inboxId: string, email: string) => {
      setMailboxDelinking(true);
      notification.destroy();
      const { id } = campaignDetail;
      // debugger;
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(id);
      const payload = {
        action: "InboxRemove",
        inboxId: inboxId
      };
      const response = await callApi({
        url,
        method,
        data: payload
      });
      if (response.success) {
        onCloseSettingsModal();
        notification.success({
          message: `"${email}" removed from ${campaignDetail?.name}`
        });

        afterSave();
      }
      setMailboxDelinking(false);
    },
    [callApi, campaignDetail, afterSave]
  );

  const onSave = () => form.submit();

  const showMailBoxList = useMemo(() => !loading && !!mailBoxes.length, [loading, mailBoxes]);

  const areNoMailBoxesLinked = useMemo(() => !loading && !mailBoxes.length, [loading, mailBoxes]);

  const toggleMailBoxProviderDialog = () => {
    setShowMailBoxProviderModal((prev) => !prev);
    setAction("");
    setisEditMode(false);
  };
  const togglePausedMailboxDialog = () => setShowPausedMailboxModal((prev) => !prev);

  const toggleLinkExistingMailboxDialog = () => setShowLinkExistingEmailModal((prev) => !prev);

  const toggleMailBoxProviderDatialDialog = () => {
    setShowMailBoxProviderDetailModal((prev) => !prev);
    setisEditMode(false);
  };

  const gotoBackButton = (isFromValidationModal?: boolean) => {
    // setShowMailBoxProviderModal((prev) => !prev);
    if (isFromValidationModal) {
      setShowMailBoxProviderDetailModal((prev) => !prev);
    }
    setisEditMode(false);
  };

  const toggleMailboxValidating = () => {
    setisBoundConnection((prev) => !prev);
    console.log("==============>");
    if (providers === MailboxTypeEnum.OFFICE_SMTP || providers === MailboxTypeEnum.OFFICE_OAUTH) {
      console.log("=======1111111111111=======>");

      setshowConnectMailStmpModal((prev) => !prev);
    } else if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
      console.log("=======222222222222222=======>");

      setShowConnectMailboxModal((prev) => !prev);
      setStepWhenConnectionModalClosed(3);
    } else {
      console.log("=====33333333333333=========>");

      setShowMailBoxProviderDetailModal((prev) => !prev);
    }
    setValidating(false);
    setSmtpInBoundError("");
    setSmtpOutBoundError("");
    setisInBoundConnection(false);
    setisOutBoundConnection(false);
  };

  const toggleVerifyPhoneNoForm = () => setshowTextVerifyModal((show) => !show);

  useEffect(() => {
    fetchInboxes();
  }, [fetchInboxes]);

  const getTaskFromAPI = useCallback(
    (task: string) => {
      // console.log('task', task)
      setLoadingMessage("");
      switch (task) {
        case "wrong_password":
          setisWrongPassword(true);
          showError("Your Password is Wrong");
          break;
        case "verify_email":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(false);
          setisVerifyEmail(true);
          setshowConnectEmailValidationModal(true);
          break;
        case "error_notify":
          showError(errorMsg);
          break;
        case "wrong_email_account":
          setisWrongEmail(true);
          showError(errorMsg);
          break;
        case "accountLock":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(true);
          showError(errorMsg);
          break;
        case "phoneNoCheckVerify":
          setshowConnectMailStmpModal(false);
          setshowTextVerifyModal(true);
          break;
        case "invalidPhoneNo":
          showError(errorMsg);
          break;
        case "update_password":
          setisWrongPassword(false);
          setShowAccessCodeModal(false);
          setshowUpdatePasswordModal(true);
          break;
        case "accessCode":
          setisEmailValidation(false);
          setshowConnectMicrosoftValidationl(false);
          setShowAccessCodeModal(true);
          break;
        case "code_screen":
          setisEmailValidation(true);
          setshowConnectEmailValidationModal(false);
          setShowAccessCodeModal(true);
          break;
        case "login_success":
          setisEmailValidation(false);
          setisVerifyEmail(false);
          setisVerifyEmailForGmail(false);
          setshowConnectMailStmpModal(false);
          setShowAccessCodeModal(false);
          setshowGmailConfirmationMailModal(false);
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setExtensionStatus("");
          setManualGmailLogin(false);
          if (action === "RECONNECT" || action === "UPDATE" || action === "VALIDATE") {
            if (action === "RECONNECT") showSucess('Reconnected successfully"');
            if (action === "UPDATE") showSucess("Password updated successfully");
            if (action === "VALIDATE") showSucess("Connection validated successfully");
            setIsSettingSmtp(false);
          } else {
            setIsSettingSmtp(true);
            setAction("");
          }
          break;
        case "gmail_recover_email":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(false);
          setVerificationOptionsModal(false);
          setisVerifyEmail(true);
          setisVerifyEmailForGmail(true);
          setshowGmailConfirmationMailModal(true);
          break;
        case "gamil_2_step_verification":
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(true);
          break;
        case "gmail_invalid_email":
          setisWrongEmail(true);
          setisWrongPassword(false);
          showError(errorMsg);
          break;
        case "gmail_process_again":
          setshowConnectMailStmpModal(true);
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(false);
          showError(errorMsg);
          break;
        case "gmail_wrong_password":
          setisWrongPassword(true);
          setisWrongEmail(false);
          showError(errorMsg);
          break;
        case "gamil_login_success":
          setshowConnectMailStmpModal(false);
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(false);
          setShowAccessCodeModal(false);
          setIsSettingSmtp(true);
          break;
        case "G_ChooseVerification":
          setisEmailValidation(false);
          setVerificationOptionsModal(false);
          setshowConnectMicrosoftValidationl(false);
          setShowAccessCodeModal(true);
          break;
        case "G_verification_options":
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setVerificationOptionsModal(true);
          break;
        case "unable_to_connect":
          setisEmailValidation(false);
          setisVerifyEmail(false);
          setisVerifyEmailForGmail(false);
          setshowConnectMailStmpModal(false);
          setShowAccessCodeModal(false);
          setshowGmailConfirmationMailModal(false);
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setShowTroubleShootModal(true);
          break;
        case "navigation_timeout":
          setExtensionStatus("installed");
          setManualGmailLogin(true);
          break;
        default:
          break;
      }
    },
    [task, action, errorMsg]
  );

  const showError = (error: any) => {
    if (error && error.length) {
      notification.error({
        message: error
      });
    }
  };

  const showSucess = (msg: string) => {
    if (msg && msg.length) {
      notification.success({
        message: msg
      });
    }
  };

  useEffect(() => {
    if (task) {
      getTaskFromAPI(task);
    }
  }, [getTaskFromAPI, task, errorMsg]);

  const confirmRecoveryEmail = useCallback(
    async (formValues: any) => {
      setLoadingMessage("verifying Email.");
      const { alternate_email } = formValues;
      // gmailMailConfirmation.resetFields();
      if (isVerifyEmail) {
        if (isVerifyEmailForGmail) {
          let data = {
            alternate_email: alternate_email,
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "verify_recovery_email",
            browserId
          };
          await performTask(data);
        }
      }
    },
    [isVerifyEmail, Data, browserId, setLoadingMessage, isVerifyEmailForGmail]
  );

  const onOptionSelectHandler = useCallback(
    async (formValues: any) => {
      setLoadingMessage("Connect to the Mailbox");
      if (!formValues) {
        notification.error({
          message: "Please select any one option"
        });
        setLoadingMessage("");
      } else {
        if (formValues === "recovery") {
          let data = {
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "G_recovery_email_option",
            browserId
          };
          await performTask(data);
        } else {
          let data = {
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "G_access_code_on_email_option",
            browserId
          };
          await performTask(data);
        }
      }
    },
    [setLoadingMessage, Data, instanceId]
  );

  const onMailboxProviderContinue = useCallback(
    async (provider: MailboxTypeEnum, email: string) => {
      // use gmail username dialog for MICROSOFT also
      // if (provider === 'microsoftone') {
      //   setLoadingMessage("Fetching server Details ....");
      //   setLoadingMessage("");
      //   mailBoxOfficeSmtp.resetFields();
      //   setshowConnectMailStmpModal(true);
      //   return;

      // }
      setLoadingMessage("Connecting to mailbox...");
      console.log("pppppppppppppppppppp", MailboxTypeEnum.OFFICE_BROWSER, provider);
      if (provider === MailboxTypeEnum.OFFICE_BROWSER) {
        setLoadingMessage("");
        mailBoxOfficeSmtp.resetFields();
        setshowConnectMailStmpModal(true);
        return;
      }
      if (provider === MailboxTypeEnum.OFFICE_SMTP) {
        setLoadingMessage("");
        mailBoxOfficeSmtp.resetFields();
        setshowConnectMailStmpModal(true);
        return;
      }

      if (!getProviderDetails(provider)?.smtpImapDetailsKnown) {
        setShowMailBoxProviderDetailModal((prev) => !prev);
        setProviderType(provider);
        setLoadingMessage("");
        return;
      }

      if (getProviderDetails(provider)?.smtpImapDetailsKnown && (provider !== MailboxTypeEnum.OFFICE_OAUTH && provider !== MailboxTypeEnum.GSUITE)) {
        setLoadingMessage("");
        subform.resetFields();
        setShowConnectMailboxModal(true);
        return;
      }
      // if (provider !== MailboxTypeEnum.OFFICE_OAUTH) {
      //   return;
      // }
      setShowConnectMailboxModal(false);

      const state = uuid();
      if (provider === MailboxTypeEnum.GSUITE) {
        const { url, method } = API_ENDPOINTS.OAUTH.getGoogleOauthUrl({
          provider: 'GOOGLE',
          state,
          reconnect: false
        });
        // Get oauth url
        console.log("222222222");
        const response = await Axios.callApi({
          method: method,
          url: `${WHISPER_BASE_URL}${url}`
        });
        if (response.success) {
          console.log("response.data;response.data;", response.data);

          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            state,
            redirectTo: `/campaigns/${campaignDetail.id}/mailboxes`,
            email,
            provider: provider
          });

          try {
            const { oauth_url } = response.data;
            console.log("oauth_urloauth_urloauth_url", oauth_url);
            window.location.assign(oauth_url);
          } catch (err) {
            notification.error({
              message: "Something went wrong!"
            });
            setLoadingMessage("");
          }
          return;
        }
      } else {
        const { url, method } = API_ENDPOINTS.OAUTH.getOauthUrl({
          provider,
          state
        });
        const response = await Axios.callApi({
          method: method,
          url: `${WHISPER_BASE_URL}${url}`
        });
        // const response = await callApi({ url, method });

        if (response.success) {
          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            state,
            redirectTo: `/campaigns/${campaignDetail.id}/mailboxes`,
            email,
            provider: provider
          });
          try {
            const { oauth_url } = response.data;
            console.log("oauth_urloauth_url", oauth_url);
            window.location.assign(oauth_url);
          } catch (err) {
            notification.error({
              message: "Something went wrong!"
            });
            setLoadingMessage("");
          }
          return;
        }
      }
      setLoadingMessage("");
    },
    [callApi, setLoadingMessage, subform, campaignDetail, mailBoxOfficeSmtp]
  );

  const onCloseSettingsModal = useCallback(() => {
    setSelectedMailbox(null);
    setIsSettingSmtp(false);
    setErrorMsg("");
    if (isSelectedMailboxNew) {
      history.replace(`/campaigns/${campaignDetail.id}/mailboxes`);
    }
  }, [isSelectedMailboxNew, history, campaignDetail.id]);

  const updateSpotBrowser = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.DNSCONNECTION.getCreateUrl();

    let payload = { browserId: browserId };
    const response = await callApi({
      url,
      method,
      data: payload,
      external: true
    });

    if (response.success) {
      console.log("spot updated scuccessfully :::");
    } else {
      console.log("some thing going wrong.");
    }
  }, [browserId]);

  const onSettingsFormSubmit = useCallback(() => {
    mailBoxSettingsForm.submit();
  }, [mailBoxSettingsForm]);

  const createMailBox = useCallback(
    async (settings: MailBoxSettingsType) => {
      const { minEmails, maxEmails, incrementPercent, signature, timeZone, days, startHour, endHour, firstName, lastName, autoForwardEmail, replyTo } =
        settings;

      const { url, method } = API_ENDPOINTS.CAMPAIGNS.createMailBox();

      let payload = {};
      const providerInfo = getProviderDetails(providers);
      if (providers !== MailboxTypeEnum.AMAZON) {
        payload = {
          minEmails,
          maxEmails,
          incrementPercent,
          signature,
          timeZone,
          days,
          startHour,
          endHour,
          firstname: Data.firstName ? Data.firstName : firstName,
          lastname: Data.lastName ? Data.lastName : lastName,
          accountId: String(localStorage.getItem("account_id")),
          email: Data.email || selectedMailbox?.email,
          username: Data.email || selectedMailbox?.email,
          password: Data.password,
          protocol: providerInfo?.protocol,
          provider: providerInfo?.providerName,
          autoForwardEmail,
          replyTo
        };
      } else {
        payload = {
          ...payload,
          state: oauthState
        };
      }

      setLoadingMessage("Setting up the mailbox... ...");

      const response = await callApi({
        url,
        method,
        data: payload
      });
      console.log("mailbox mail", response);
      if (response.success) {
        // setIsMailboxExist(false);
        LocalStorage.resetKey(OAUTH_STATE_LS_KEY);
        setShowMailBoxProviderModal(false);
        setShowConnectMailboxModal(false);
        setIsSettingSmtp(false);
        if (!settingEditView) {
          setSelectedMailbox(null);
          onCloseSettingsModal();
        }
        setLoadingMessage("");
        fetchInboxes();
        return;
      } else {
        // setShowMailBoxProviderModal(false);
        // setShowConnectMailboxModal(false);
        // setIsMailboxExist(true);
        // setExisMailMessage(response?.error?.message);
      }
      if (providers === MailboxTypeEnum.OFFICE_BROWSER) await updateSpotBrowser(); // todo: NC verify
      setLoadingMessage("");
    },
    [Data, callApi, fetchInboxes, settingEditView, oauthState, providers, setLoadingMessage, onCloseSettingsModal, updateSpotBrowser]
  );

  const updateMailbox = useCallback(
    async (settings: MailBoxSettingsType, id) => {
      console.log("222222222222", settings);

      const {
        signature,
        incrementPercent,
        minEmails,
        maxEmails,
        days,
        timeZone,
        startHour,
        endHour,
        firstName,
        lastName,
        capacity,
        replyTo,
        autoForwardEmail
      } = settings;
      const { url, method } = API_ENDPOINTS.MAILBOX.getUpdatelUrl(id);
      const payload = {
        accountId: String(localStorage.getItem("account_id")),
        incrementPercent,
        minEmails,
        maxEmails,
        signature,
        days,
        timeZone,
        startHour,
        endHour,
        firstname: firstName,
        lastname: lastName,
        capacity: capacity,
        replyTo,
        autoForwardEmail
      };

      setLoadingMessage("Updating your mailbox ...");

      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        if (!settingEditView) {
          setSelectedMailbox(null);
        }
        fetchInboxes();

        setShowConnectMailboxModal(false);
        setLoadingMessage("");
        return;
      }

      setLoadingMessage("");
    },
    [callApi, settingEditView, setLoadingMessage, fetchInboxes]
  );

  const handleMailboxSettingsSave = useCallback(
    async (settings: MailBoxSettingsType) => {
      console.log("settingssettings", settings)
      if (!selectedMailbox?.id) {
        createMailBox(settings);
        return;
      }
      updateMailbox(settings, selectedMailbox.id);
    },
    [selectedMailbox, createMailBox, updateMailbox]
  );

  const handleEditClick = useCallback(
    async (id: string) => {
      setLoadingMessage("Fetching your mailbox settings ...");
      const { url, method } = API_ENDPOINTS.MAILBOX.getDetailUrl(id);

      const response = await callApi({
        url,
        method
      });
      if (response.success) {
        const {
          email,
          campaignName,
          campaignId,
          paused,
          signature = "",
          incrementPercent = 20,
          minEmails,
          maxEmails,
          timezone,
          days,
          startHour,
          endHour,
          provider,
          protocol,
          capacity,
          firstname,
          lastname,
          autoForwardEmail,
          replyTo
        } = response.data.inbox;
        setSelectedMailbox({
          email,
          campaignName,
          campaignId,
          paused,
          id,
          provider,
          protocol,
          settings: {
            incrementPercent,
            minEmails,
            email,
            maxEmails,
            signature,
            timeZone: timezone,
            days,
            startHour,
            endHour,
            firstName: firstname,
            lastName: lastname,
            capacity,
            autoForwardEmail,
            replyTo
          }
        });
        mailBoxSettingsForm.setFieldsValue({
          firstName: firstname,
          lastName: lastname,
          email
        });
        // setSettingModal(true)

        setLoadingMessage("");
        return;
      }
      setLoadingMessage("");
    },
    [setLoadingMessage, setSelectedMailbox, callApi]
  );

  useEffect(() => {
    if (oauthState && currentStepName !== NewCampaignSteps.MAILBOX_SETTINGS) {
      setCurrentStepName(NewCampaignSteps.MAILBOX_SETTINGS);
    }
  }, [oauthState, currentStepName]);

  const toggleShowModal = () => {
    setShowConnectMailboxModal((show) => !show);
  };
  const handleUpdatePasswordModal = () => setshowTextVerifyModal((show) => !show);

  const providesType = useCallback(async (type: any) => {
    setProviders(type);
  }, []);

  const submitPhoneNoToVerify = async (formValues: any) => {
    setLoadingMessage("verifying Phone Number.");
    const { phone, prefix } = formValues;

    let data = {
      email: Data.email,
      instanceId: instanceId,
      phoneNo: phone,
      countryCode: prefix,
      site: "OUTWIN",
      task: "phoneNoCheck"
    };
    await performTask(data);
  };

  const submitAccessCode = async (formValues: any) => {
    setLoadingMessage("verifying Access Code.");
    const { accessCode } = formValues;
    if (providers === MailboxTypeEnum.GMAILONE) {
      let data = {
        email: Data.email,
        instanceId: instanceId,
        accessCode: accessCode,
        site: "OUTWIN",
        task: "G_EmailVerificationCode",
        type: "browserGmail"
      };
      await performTask(data);
    } else {
      if (isEmailValidation) {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          accessCode: accessCode,
          site: "OUTWIN",
          task: "enterTheCode"
        };
        await performTask(data);
      } else {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          accessCode: accessCode,
          site: "OUTWIN",
          task: "enterAccessCode"
        };
        await performTask(data);
      }
    }
  };

  const submitMicroSoftEmail = useCallback(
    async (formValues: any) => {
      setLoadingMessage("verifying Email.");
      const { alternate_email } = formValues;
      if (isVerifyEmail) {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          alternate_email: alternate_email.split("@")[0],
          site: "OUTWIN",
          task: "verify_alterEmail"
        };

        await performTask(data);
      } else {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          alternate_email: alternate_email,
          site: "OUTWIN",
          task: "alternateEmail"
        };

        await performTask(data);
      }
    },
    [isVerifyEmail, setLoadingMessage]
  );

  const getTask: any = useCallback(
    async (data: any, startTime: number = new Date().getTime(), currTime: number = new Date().getTime()) => {
      let { email, site, instanceId } = data;

      let payload = {
        email: email,
        site: site,
        instanceId: instanceId
      };
      const { url, method } = API_ENDPOINTS.OTHERS.browserBased(instanceId ? instanceId : "localhost");
      const response = await callApi({
        url,
        method,
        data: payload,
        external: true
      });
      if (response && response.success) {
        const { data } = response;
        if (data && data.task && data.isSuccess) {
          if (data.task === "login_success") {
            let textMsg = data.msg;
            if (textMsg) {
              textMsg = textMsg.split(" ");
              if (textMsg.length) {
                let firstName = textMsg.shift();
                let lastName = textMsg.join(" ");
                setData((prevState) => ({
                  ...prevState,
                  lastName: lastName,
                  firstName: firstName
                }));
                const payload: any = { email: email as string, provider: providers, id: null, campaignName: null };
                setSelectedMailbox(payload);
                // if (action === "RECONNECT" && editableId) {
                //   await reconnectApi(editableId)
                // }
              }
            }
          }
          setTask(data.task);
          setShowText("");
          setErrorMsg("");
        } else if (data && data.task && data.isError) {
          if (data.msg && data.msg.length) {
            setErrorMsg(data.msg);
          }
          if (data.showText && data.showText.length) {
            setShowText(data.showText);
          }
          setTask(data.task);
        } else {
          setTask("");
          setShowText("");
          setErrorMsg("");
          await timeout(1000);
          console.log(currTime - startTime);
          if (currTime - startTime <= 1.5 * 60 * 1000) {
            return await getTask(payload, startTime);
          } else {
            setLoadingMessage("");
            notification.error({
              message: "Something went wrong"
            });
          }
        }
        if (
          data.task === "gmail_recover_email" ||
          data.task === "G_ChooseVerification" ||
          data.task === "gamil_2_step_verification" ||
          data.task === "G_verification_options"
        ) {
          setSubMessage(data.showText);
        }
      }
    },
    [editableId, action, setTask, setShowText, callApi]
  );

  const timeout = (ms: any) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const performTask = useCallback(
    async (data: any) => {
      if (!data.proxy || !Object.keys(data.proxy).length) {
        data.proxy = proxy;
      }
      let payload = {
        ...data
      };
      if (!payload.browserId) {
        payload.browserId = browserId;
      }
      setTask("");
      // console.log("data 3", data);
      const { instanceId } = data;
      const { url, method } = API_ENDPOINTS.OTHERS.launchedBrowser(instanceId ? instanceId : "localhost");
      const response = await callApi({
        url,
        method,
        data: payload,
        external: true
      });

      if (response.success) {
        await getTask(data);
      } else {
        setTimeout(() => setLoadingMessage(""), 1000);
      }
    },
    [setTask, browserId, proxy, callApi]
  );

  const socketAndPerformLogin = useCallback(
    async (email: string, password: string, browserId: string, instanceId: string, proxy: Proxy) => {
      const data = {
        email: email,
        instanceId: instanceId,
        password: password,
        browserId: browserId,
        site: "OUTWIN",
        task: "login",
        proxy: proxy
      };
      await performTask(data);
      return;
    },
    [proxy, browserId, action, editableId]
  );

  const onSendEmail = (name: string, email: string) => {
    setselectedInboxIdForSendEmail(name);
    setselectedInboxEmailForSendEmail(email);
  };

  const updateCredentials = useCallback(
    async (id: string, action: string) => {
      setErrorMsg("");
      setTask("");
      let fetchData = mailBoxes.filter((x) => x.id === id);
      let { email, provider, firstname, lastname, protocol } = fetchData[0];
      setData((prevState) => ({
        ...prevState,
        email: email || "",
        password: "",
        firstName: firstname || "",
        lastName: lastname || ""
      }));
      if (provider === "MICROSOFT") {
        setProviders(MailboxTypeEnum.OFFICE_SMTP);
      } else if (provider === "GOOGLE" && protocol === "BROWSER") {
        setProviders(MailboxTypeEnum.GMAILONE);
      } else {
        setProviders(provider);
      }
      setisEditMode(true);
      setAction(action);
      if (provider === MailboxTypeEnum.OFFICE_OAUTH || provider === MailboxTypeEnum.OFFICE_SMTP) {
        setshowConnectMailStmpModal(true);
      } else if (getProviderDetails(provider)?.smtpImapDetailsKnown) {
        setShowConnectMailboxModal(true);
      } else {
        setShowMailBoxProviderDetailModal(true);
      }
      setEditableId(id);
    },
    [setAction, mailBoxes]
  );
  // console.log('provider', providers);
  const resetMailboxeSettings = () => {
    setisBoundConnection(false);
    setShowConnectMailboxModal(false);
    setSmtpInBoundError("");
    setSmtpOutBoundError("");
    setisInBoundConnection(false);
    setisOutBoundConnection(false);
    setValidating(false);
    setisEditMode(false);
  };

  const onFinish = async (formValues: any) => {
    const { email, password, firstName, lastName } = formValues;
    console.log("callll 2", formValues, providers);
    if (providers) {
      setProviders(providers);
    }
    setData((prevState) => ({
      ...prevState,
      email,
      password,
      lastName,
      firstName
    }));
    if (providers === MailboxTypeEnum.GMAILONE) {
      if (isWrongPassword) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email,
          password,
          instanceId: instanceId,
          site: "OUTWIN",
          task: "reEnter_password",
          browserId
        };
        await performTask(data);
      } else if (isWrongEmail) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email,
          instanceId: instanceId,
          password,
          site: "OUTWIN",
          task: "reEnterLoginCredentials",
          browserId
        };
        await performTask(data);
      } else {
        setLoadingMessage("Connecting to mailbox...");
        const { browserId, instanceId, proxy } = await getDnsPublicDnsUrl(email, password);
        if (browserId && browserId.length && instanceId && instanceId.length) {
          setProviderType(providers);
          await socketAndPerformLogin(email, password, browserId, instanceId, proxy);
        }
      }
      return;
    } else {
      if (providers === MailboxTypeEnum.OFFICE_SMTP || providers === MailboxTypeEnum.OFFICE_OAUTH) {
        setshowConnectMailStmpModal(false);
      } else if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
        setShowConnectMailboxModal(false);
      } else {
        setShowMailBoxProviderDetailModal(false);
      }
      setisBoundConnection(true);
    }
    setLoadingMessage("");
  };

  useEffect(() => {
    if (!isBoundConnection) {
      setValidating(false);
      setSmtpInBoundError("");
      setisInBoundConnection(false);
      setisOutBoundConnection(false);
      setSmtpOutBoundError("");
      if (action === "UPDATE" || action === "VALIDATE" || action === "RECONNECT") {
        setIsSettingSmtp(false);
      }
    }
  }, [isBoundConnection]);

  const resetSelectedEmailForIps = () => setSelectedEmailForIps(""); //setSelectedEmailForLogs
  const resetSelectedInboxIdForlogs = () => setselectedInboxIdForLogs("");
  const resetSelectedInboxIdForSendEmail = () => {
    setselectedInboxIdForSendEmail("");
    setselectedInboxEmailForSendEmail("");
  };

  const verifyPhoneNoForm = async (formValues: any) => {
    setLoadingMessage("Verifying your Phone No");
    const { phone, prefix } = formValues;
    let data = {
      email: Data.email,
      instanceId: instanceId,
      phoneNo: phone,
      site: "OUTWIN",
      task: "verifyPhoneNo"
    };
    await performTask(data);
  };

  const updatePassword = async (formValues: any) => {
    setLoadingMessage("Verifying your Phone No");
    const { Password } = formValues;
    let data = {
      email: Data.email,
      instanceId: instanceId,
      updatedPassword: Password,
      site: "OUTWIN",
      task: "enter_New_Password"
    };
    await performTask(data);
  };

  const handleSettingViewStatus = (value: boolean) => {
    setSettingEditView(value);
  };

  const verifyTwoStep = async () => {
    let data = {
      email: Data.email,
      instanceId: instanceId,
      site: "OUTWIN",
      task: "",
      browserId
    };
    await getTask(data);
  };

  useEffect(() => {
    form.setFieldsValue({
      Email: Data.email,
      Password: Data.password,
      Firstname: Data.firstName,
      Lastname: Data.lastName
    });
  }, [Data]);

  const reconnectApi = useCallback(async (mailboxId: string) => {
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.reconnectMailBox(mailboxId);
    const payload = {
      brooklynReconnect: false
    };
    const reconnectResponse = await callApi({
      url,
      method,
      data: payload,
      external: false
    });

    if (reconnectResponse.success) {
      setShowConnectMailboxModal(false);
      notification.success({
        message: "Email Reconnected"
      });
    } else {
      notification.error({
        message: "Something went wrong"
      });
    }
  }, []);
  const handleOnReconnect = (id: any, action: string) => () => {
    console.log("id, ", id, action);
    let fetchData = mailBoxes.filter((x) => x.id === id);
    let { email, provider, firstname, lastname, protocol } = fetchData[0];
    setData((prevState) => ({
      ...prevState,
      email: email || "",
      password: "",
      lastName: lastname || "",
      firstName: firstname || ""
    }));
    // setProviders(provider);
    if (provider === "MICROSOFT") {
      setProviders(MailboxTypeEnum.OFFICE_SMTP);
    } else if (provider === "GOOGLE" && protocol === "BROWSER") {
      setProviders(MailboxTypeEnum.GMAILONE);
    } else {
      setProviders(provider);
    }
    setisEditMode(true);
    setAction(action);
    // isEditMode, setisEditMode
    setShowConnectMailboxModal(true);
    // if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
    //   setshowConnectMailStmpModal(true);
    // } else if (getProviderDetails(provider)?.smtpImapDetailsKnown) {
    //   setShowConnectMailboxModal(true);
    // } else {
    //   setShowMailBoxProviderDetailModal(true);
    // }
    setEditableId(id);
    setLoadingMessage("");
  };

  const detectExtension = (extensionId: string, callback: any) => {
    let s = document.createElement("script");
    s.onload = function () {
      callback(true);
    };
    s.onerror = function () {
      callback(false);
    };
    s.src = "chrome-extension://" + extensionId + "/js/control.js";
    document.body.appendChild(s);
  };

  const checkExtension = () => {
    return new Promise((resolve, reject) => {
      detectExtension("bpekpmdcgljafocafpmjjghmhfnlggah", (sss: any) => {
        console.log("callededddddddd", sss);
        resolve(sss);
      });
    });
  };

  useEffect(() => {
    if (extensionStatus === "check") {
      var datavalue = setInterval(async () => {
        let data: any = await checkExtension();
        console.log("datassssssssss", data);
        if (data) {
          setInstallExtension(true);
          clearInterval(datavalue);
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(datavalue);
      }, 180000);
    } else if (extensionStatus === "installed") {
      const checkFn = async () => {
        let data: any = await checkExtension();
        console.log("else if", data);
        if (data) {
          setInstallExtension(true);
        }
      };
      checkFn();
    } else {
    }
    return () => {
      setExtensionStatus("");
    };
  }, [extensionStatus]);

  const handleInstallExtension = useCallback(async () => {
    const url = "https://chrome.google.com/webstore/";
    window.open(url, "_blank", "noopener,noreferrer");
    setExtensionStatus("check");
  }, []);

  const getCookie = (name: string) => {
    const pattern = RegExp(name + "=.[^;]*");
    const matched = document.cookie.match(pattern);
    if (matched) {
      const cookie = matched[0].split("=");
      return cookie[1];
    }
    return false;
  };

  const clearCookies = () => {
    document.cookie = "brooklyn_inbox_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "brooklyn_inbox_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  const handleGoogleLogin = () => {
    clearCookies();
    const sessionId = uuid();
    document.cookie = `brooklyn_site=${window.origin}; path=/`;
    document.cookie = "brooklyn_app=Emy; path=/";
    document.cookie = `brooklyn_session_id=${sessionId}; path=/`;
    const redirectUrl = "https://accounts.google.com/AddSession?service=mail&passive=truecontinue=https://mail.google.com/mail";
    window.open(redirectUrl, "_blank", "noopener,noreferrer");

    var datavalue = setInterval(() => {
      let cookieValue = getCookie("brooklyn_inbox_name");
      if (cookieValue) {
        const fullName: any = cookieValue.split(" ");
        if (fullName.length) {
          setData((prevState) => ({
            ...prevState,
            firstName: fullName.shift(),
            lastName: fullName.join(" ")
          }));
        }
        setIsSettingSmtp(true);
        getTaskFromAPI("login_success");
        clearCookies();
        clearInterval(datavalue);
      }
    }, 1500);

    setTimeout(() => {
      clearInterval(datavalue);
    }, 300000);
  };

  const handleToogleIMAP = () => setShowIMAPVerification(!showIMAPVerification);

  useEffect(() => {
    if (isEditMode) subform.setFieldsValue(Data);
  }, [subform, Data, isEditMode]);

  useEffect(() => {
    if (isEditMode) mailBoxOfficeSmtp.setFieldsValue(Data);
  }, [mailBoxOfficeSmtp, Data, isEditMode]);

  const handleExistMailError = async () => {
    setLoadingMessage("checking the mail again");
    const { id } = campaignDetail;
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(id);
    await callApi({ url, method, data: { action: "UpdateInbox", inboxId: inboxIds } });
    setIsMailboxExist(false);
    setExisMailMessage("");
    setLoadingMessage("");
  };

  const responseSkip = async (email: string) => {
    if (!email) {
      return;
    }
    // const { url, method } = API_ENDPOINTS.MAILBOX.getResponseSkipCodes(inboxId);
    const response = await Axios.callApi({
      method: "get",
      url: `https://api.whisper.email/inbox/${email}/skip-response-codes`,
      headers: {}
    });
    if (response?.success) {
      setState((prev) => ({ ...prev, selectedEmail: email, responseSkipValue: response?.data?.codes.join("\n"), responseSkipModalState: true }));
    }
  };

  const onChangeResponseSkip = (value: string) => {
    setState((prev) => ({ ...prev, responseSkipValue: value }));
  };

  const saveResponseSkipCodes = async () => {
    if (!isEmpty(selectedEmail)) {
      let addedCodes = responseSkipValue ? responseSkipValue?.split("\n") : [];
      addedCodes = addedCodes.filter((str) => !isEmpty(str.trim()));
      const response = await Axios.callApi({
        method: "put",
        url: `https://api.whisper.email/inbox/${selectedEmail}/skip-response-codes`,
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          codes: addedCodes
        })
      });
      if (response?.success) {
        setState((prev) => ({ ...prev, responseSkipModalState: false, responseSkipValue: "", selectedEmail: "" }));
      }
    }
  };

  const handleModal = () => {
    setState((prev) => ({ ...prev, responseSkipModalState: false, responseSkipValue: "", selectedEmail: "" }));
  };

  const setEmail = (email: string) => {
    setSelectedMailbox((prev: any) => ({
      ...prev,
      email: email || ""
    }));
    setData((prevState) => ({
      ...prevState,
      email
    }));
    setEditModeWhileProviderWorkspacePersonal(true);
  };

  const calculateIps = async (mailboxId: string) => {
    setLoadingMessage("fetching your mailbox IPS");
    const { url, method } = API_ENDPOINTS.MAILBOX.calculateIps(mailboxId);
    const response = await callApi({
      url,
      method
    });
    console.log("responseresponse", response);
    if (response.success) {
      notification.success({
        message:
          "IPS calculation process has been initiated. It may take about 1-2 minutes to complete this process. To check the latest IPS score, refresh this mailbox."
      });
      setLoadingMessage("");
    }
  };

  // useEffect(() => {
  //   if (localStorageValues?.provider === MailboxTypeEnum.GSUITE) {
  //     setGsuiteSetupModal()
  //     // setSettingModal(false)
  //   }
  // }, [localStorageValues?.provider])

  const setGsuiteSetupModal = () => {
    // setOpenSetupModal(true)
    setShowGsuiteModal(false)
  }

  // const instantSetup = () => {
  //   setOpenSetupModal(false)
  //   createMailBox({
  //     "incrementPercent": 20,
  //     "minEmails": 100,
  //     "maxEmails": 1000,
  //     "signature": "",
  //     "days": [
  //       1,
  //       2,
  //       3,
  //       4,
  //       5,
  //       6,
  //       7
  //     ],
  //     "timeZone": String(localStorage.getItem("accountTimezone")) || "America/New_York",
  //     "startHour": 8,
  //     "endHour": 17,
  //     "firstName": Data?.firstName || '',
  //     "lastName": Data?.lastName || '',
  //     "capacity": 100,
  //     "autoForwardEmail": "",
  //     "email": selectedMailbox?.email || Data?.email,
  //     "replyTo": ""
  //   })
  // }

  // const detailedSetup = () => {
  //   // setState((prev) => ({ ...prev, openDetailedSetup: true }))
  //   setSettingModal(true)
  //   setOpenSetupModal(false)
  //   LocalStorage.resetKey(OAUTH_STATE_LS_KEY)
  //   setSelectedMailbox((prev: any) => ({
  //     ...prev,
  //     provider: "GOOGLE" || ""
  //   }));
  // }
  const connectClick = (providerType: any) => {
    if (providerType) {
      setProviderType(providerType)
    }
    if (providerType === MailboxTypeEnum.GSUITE) {
      setShowGsuiteModal(true);
    }
  };

  return (
    <Div mt={10} mh="calc(100vh - 180px)" bgColor={theme.whiteColor} p={20} style={{ boxShadow: BOX_SHADOW }}>
      {showMailBoxList && (
        <>
          <Flex w="100%" justify="space-between" align="center">
            {/* <Div className={classes.tagList}>
              <Tag className={classes.tagCustom}>mailboxes - {mailboxesStats.mailboxes}</Tag>
              <Tag className={classes.tagCustom}>capacity - {mailboxesStats.capacity}</Tag>
            </Div> */}
            <Div className={classes.tagList}>
              <Div>
                {mailboxesStats.mailboxes} <p style={{ color: "rgba(96,99,103,.55)", marginTop: "10px" }}>Total Inboxes</p>
              </Div>
              <Div>
                {mailboxesStats.capacity}
                <p style={{ color: "rgba(96,99,103,.55)", marginTop: "10px" }}>Today's Capacity</p>
              </Div>
              <Div>
                {mailboxesStats.maxEmails}
                <p style={{ color: "rgba(96,99,103,.55)", marginTop: "10px" }}>Max Capacity</p>
              </Div>
            </Div>
            <Text variant="sub2" bold>
              {/* {campaignDetail.name} Mailboxes */}
            </Text>
            <Flex>
              <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
                <Button
                  icon={<PlusSquareOutlined />}
                  disabled={!isFree}
                  type="text"
                  onClick={toggleMailBoxProviderDialog}
                  style={{ color: theme.antdConf.primaryColor, padding: 0, background: "none" }}
                >
                  Link New Mailbox
                </Button>
              </Tooltip>
              <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
                <Button
                  icon={<PlusSquareOutlined />}
                  disabled={!isFree}
                  type="text"
                  onClick={toggleLinkExistingMailboxDialog}
                  style={{
                    marginLeft: "15px",
                    color: theme.antdConf.primaryColor,
                    padding: 0,
                    background: "none"
                  }}
                >
                  Link Existing Mailbox
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
          <Div>
            <MailBoxList
              isCardView={true}
              mailBoxes={mailBoxes}
              selectedMailBoxes={inboxIds}
              form={form}
              onSubmit={onSubmit}
              title=""
              subTitle=""
              onEdit={handleEditClick}
              updateCredentails={updateCredentials}
              mailboxDelinking={mailboxDelinking}
              onDelete={isFree ? onDelete : null}
              onViewIpsDetails={setSelectedEmailForIps}
              onViewLogsDetails={setselectedInboxIdForLogs}
              onSendEmail={onSendEmail}
              filterStatus={MAILBOXLIST_FILTER.CONNECTED}
              onReconnect={handleOnReconnect}
              responseSkip={(email) => responseSkip(email)}
              calculateIps={calculateIps}
            />
          </Div>
        </>
      )}
      {showMailBoxProviderModal && (
        <Modal visible onCancel={toggleMailBoxProviderDialog} footer={null} width={700} maskClosable={false}>
          <MailBoxProvider
            onContinue={onMailboxProviderContinue}
            form={mailBoxProviderForm}
            setProviders={providesType}
            submitBtn={(type) => type === 'GSUITE' ? connectClick(type) : mailBoxProviderForm.submit()}
            setEmail={setEmail}
          />
          {/* <Flex justify="flex-end">
            <Button type="primary" onClick={mailBoxProviderForm.submit}>
              Connect
            </Button>
          </Flex> */}
        </Modal>
      )}
      {getProviderDetails(providers)?.name === "Google (OAUTH)" && getProviderDetails(providers)?.subText === "Workspace" ? (
        <>
          {!!showGsuiteMail && (
            <GsuiteLoginModal
              handleOpenClose={() => {
                setProviders("");
                setShowGsuiteModal(false)
              }}
              handleSubmit={onFinish}
              formData={Data}
              // isEditMode={isEditMode}
              connectModalStep={connectModalStep}
              // isEditModeForWorkspacePersonal={isEditModeForWorkspacePersonal}
              setOpenSetupModal={() => setGsuiteSetupModal()}
              onContinue={onMailboxProviderContinue}
              providerType={providerType}
            />
          )}
        </>)
        : null}
      {showPausedMailboxModal && (
        <Modal visible onCancel={toggleMailBoxProviderDialog} footer={null} width={700} maskClosable={false}>
          <MailBoxProvider
            onContinue={onMailboxProviderContinue}
            form={mailBoxProviderForm}
            setProviders={providesType}
            submitBtn={mailBoxProviderForm.submit}
            setEmail={setEmail}
          />
          {/* <Flex justify="flex-end">
            <Button type="primary" onClick={mailBoxProviderForm.submit}>
              Connect
            </Button>
          </Flex> */}
        </Modal>
      )}

      {showMailBoxProviderDetailModal && (
        <MailBoxProviderDetailModal
          showAddMailbox={setShowMailBoxProviderDetailModal}
          setShowMailBoxProviderModal={setShowMailBoxProviderModal}
          fetchInboxes={fetchInboxes}
          isEditMode={isEditMode}
          editableData={mailBoxes.find((x) => x.id === editableId)}
          mailId={editableId}
          onDismiss={toggleMailBoxProviderDatialDialog}
          provider={providers}
          isNewCampFlow={false}
          backButton={gotoBackButton}
          action={action}
        />
      )}
      {showSettingsModal && (
        <Modal visible footer={null} centered className="modal-mainwrap" onCancel={onCloseSettingsModal}>
          <MailBoxSettings
            onSave={handleMailboxSettingsSave}
            form={mailBoxSettingsForm}
            id={selectedMailbox?.id}
            paused={selectedMailbox?.paused}
            email={selectedMailbox?.email || Data.email}
            campaignName={selectedMailbox?.campaignName ? selectedMailbox?.campaignName : ``}
            campaignId={selectedMailbox?.campaignId}
            settings={selectedMailbox?.settings}
            provider={selectedMailbox?.provider ==='GSUITE' ?'GOOGLE':selectedMailbox?.provider || providerType}
            protocol={selectedMailbox?.protocol || providerType}
            formValues={Data}
            mailboxDelinking={mailboxDelinking}
            settingEditViewMode={handleSettingViewStatus}
            validateCredentails={updateCredentials}
            updateCredentails={updateCredentials}
            onDelete={isFree ? onDelete : null}
            onViewIpsDetails={setSelectedEmailForIps}
            onViewLogsDetails={setselectedInboxIdForLogs}
            onSendEmail={onSendEmail}
            onReconnect={updateCredentials}
            showSkipCodes
            responseSkip={() => responseSkip(selectedMailbox?.email ?? "")}
          />
          <Flex mt={20} justify="flex-end">
            <Button type="primary" onClick={onSettingsFormSubmit}>
              Save
            </Button>
          </Flex>
        </Modal>
      )}
      {areNoMailBoxesLinked && <EmptyMailboxPlaceholder onAdd={toggleMailBoxProviderDialog} />}
      {!!selectedEmailForIps && <IpsDetailsModal onCancel={resetSelectedEmailForIps} email={selectedEmailForIps} />}
      {!!selectedInboxIdForLogs && (
        <LogDetailsModal
          onCancel={resetSelectedInboxIdForlogs}
          id={selectedInboxIdForLogs}
          logType="Inbox"
          subTitle={mailBoxes.find((mb) => mb.id === selectedInboxIdForLogs)?.email}
        />
      )}
      {!!selectedInboxEmailForSendEmail && (
        <SendEmailModal onCancel={resetSelectedInboxIdForSendEmail} email={selectedInboxEmailForSendEmail} name={selectedInboxIdForSendEmail} />
      )}
      {getProviderDetails(providers)?.name === "Google (OAUTH)" && getProviderDetails(providers)?.subText === "Workspace" ? (
        <>
          {!!showGsuiteMail && (
            <GsuiteLoginModal
              handleOpenClose={() => {
                // setErrorMsg("");
                // setSelectedMailbox(null);
                // setShowConnectMailboxModal(!showGsuiteMail);
                // setEditModeWhileProviderWorkspacePersonal(false);
                setProviders("");
                setShowGsuiteModal(false)
              }}
              handleSubmit={onFinish}
              formData={Data}
              // isEditMode={isEditMode}
              connectModalStep={connectModalStep}
              onContinue={onMailboxProviderContinue}
              providerType={providerType}
            // isEditModeForWorkspacePersonal={isEditModeForWorkspacePersonal}
            />
          )}
        </>)
        : null}
      {getProviderDetails(providers)?.name === "Google (SMTP)" && getProviderDetails(providers)?.subText === "Workspace" ? (
        <>
          {!!showConnectMailboxModal && (
            <GmailIMAPLoginModal
              handleOpenClose={() => {
                setShowConnectMailboxModal(!showConnectMailboxModal);
                setEditModeWhileProviderWorkspacePersonal(false);
              }}
              handleSubmit={onFinish}
              formData={Data}
              isEditMode={isEditMode}
              connectModalStep={connectModalStep}
              isEditModeForWorkspacePersonal={isEditModeForWorkspacePersonal}
            />
          )}
        </>
      ) : (
        <>
          {!!showConnectMailboxModal && (
            <Modal
              visible
              footer={null}
              onCancel={() => {
                setData({ email: "", password: "", firstName: "", lastName: "" });
                setShowConnectMailboxModal(false);
                setEditModeWhileProviderWorkspacePersonal(false);
              }}
              width={600}
              centered
            >
              <Form
                scrollToFirstError
                form={subform}
                requiredMark={false}
                onFinish={onFinish}
                initialValues={
                  isEditMode
                    ? Data
                    : isEditModeForWorkspacePersonal
                      ? {
                        email: Data?.email,
                        password: "",
                        firstName: "",
                        lastName: ""
                      }
                      : { email: "", password: "", firstName: "", lastName: "" }
                }
              >
                <Text variant="h6" mb={5} ml={10} mt={20} color={"secondary"}>
                  {"Google Setup"}
                </Text>
                <Flex style={{ flexDirection: "row" }}>
                  <DetailWrapper>
                    <DetailHeading>Email *</DetailHeading>
                    <Form.Item
                      name={"email"}
                      rules={[
                        {
                          type: "email",
                          message: "Please enter valid email"
                        },
                        {
                          required: true,
                          message: "Please enter email"
                        }
                      ]}
                    // initialValue={isEditMode ? Data.email : ""}
                    >
                      <Input disabled={isEditMode ? true : false} tabIndex={1} className={classes.inputStyle} size="middle" />
                    </Form.Item>
                  </DetailWrapper>
                  <DetailWrapper>
                    <DetailHeading>Password *</DetailHeading>
                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: "Please write password"
                        }
                      ]}
                      // initialValue={isEditMode ? Data.password : ""}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input.Password
                        tabIndex={2}
                        autoComplete="new-password"
                        style={{
                          width: 250,
                          borderWidth: 0,
                          paddingLeft: 2,
                          boxShadow: "none",
                          borderRight: "none",
                          borderBottom: `1px solid ${theme.darkGrayColor}`
                        }}
                        size="middle"
                      />
                    </Form.Item>
                  </DetailWrapper>
                </Flex>
                {/* <Flex direction="row" ml={10}>
              <p className="fs-12" style={{ marginBottom: "15px" }}>{smtpValidationError}</p>
            </Flex> */}
                <Flex direction="row" ml={10} justify="flex-end">
                  <Flex>
                    <Button
                      onClick={() => {
                        setData({ email: "", password: "", firstName: "", lastName: "" });
                        setShowConnectMailboxModal(false);
                      }}
                    >
                      Back
                    </Button>
                  </Flex>
                  <Flex ml={10}>
                    <Button htmlType="submit" type="primary">
                      Validate
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            </Modal>
          )}
        </>
      )}
      {!!showConnectMailStmpModal && (
        <Modal visible footer={null} onCancel={() => toggleShowModalSmtp()} width={600} centered>
          <Form
            scrollToFirstError
            form={mailBoxOfficeSmtp}
            requiredMark={false}
            onFinish={onFinish}
            initialValues={isEditMode ? Data : { email: "", password: "" }}
          >
            <Text variant="h6" mb={5} ml={10} mt={20} color={"secondary"}>
              {"Microsoft"} {/* {"Gmail"}{" "} */}
              setup
            </Text>
            <Flex style={{ flexDirection: "row" }}>
              <DetailWrapper>
                <DetailHeading>Email *</DetailHeading>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter valid email"
                    },
                    {
                      required: true,
                      message: "Please enter email"
                    }
                  ]}
                // initialValue={isEditMode ? Data.email : ""}
                >
                  <Input disabled={isEditMode ? true : false} tabIndex={1} className={classes.inputStyle} size="middle" />
                </Form.Item>
              </DetailWrapper>
              <DetailWrapper>
                <DetailHeading>Password *</DetailHeading>

                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please write password"
                    }
                  ]}
                  // initialValue={isEditMode ? Data['password'] : ""}
                  style={{ marginLeft: "5px" }}
                >
                  <Input.Password
                    tabIndex={2}
                    autoComplete="new-password"
                    style={{
                      width: 250,
                      borderWidth: 0,
                      paddingLeft: 2,
                      boxShadow: "none",
                      borderRight: "none",
                      borderBottom: `1px solid ${theme.darkGrayColor}`
                    }}
                    size="middle"
                  />
                </Form.Item>
              </DetailWrapper>
            </Flex>
            {/* <Flex direction="row" ml={10}>
              <p className="fs-12" style={{ marginBottom: "15px" }}>{smtpValidationError}</p>
            </Flex> */}
            <Flex direction="row" ml={10} justify="flex-end">
              <Flex>
                <Button onClick={() => setshowConnectMailStmpModal(false)}>Back</Button>
              </Flex>
              <Flex ml={10}>
                <Button htmlType="submit" type="primary">
                  Validate
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      )}
      {/* {!!gsuiteSetupModlal && (
        // <GsuiteSetupModal
          handleOpenClose={() => {
            setProviders("");
            setOpenSetupModal(false)
            LocalStorage.resetKey(OAUTH_STATE_LS_KEY)
          }}
          handleSubmit={onFinish}
          formData={Data}
          // setOpenSetupModal={() => setOpenSetupModal(true)}
          instantSetup={instantSetup}
          detailedSetup={detailedSetup}
        />
      )} */}

      {!!showConnectEmailValidationModal && (
        <Modal visible footer={null} onCancel={() => toggleShowModalEmailValidation()} width={300} centered>
          <Form scrollToFirstError form={alernateEmailValidation} requiredMark={false} onFinish={submitMicroSoftEmail}>
            {/* <p style={{ fontSize: '12px', marginBottom: '15px' }}>ddd.outwin@gmail.com</p> */}
            <Title level={5}>Help us protect your account</Title>
            <p className="fs-12" style={{ marginBottom: "15px" }}>
              Passwords can be forgotten or stolen. Just in case, add security info now to help you get back into your account if something goes wrong. We won't
              use this info to spam you—just to keep your account more secure.
            </p>
            <Div>
              <label className="fs-12 fw-600">Email *</label>
              <Form.Item
                name={"alternate_email"}
                rules={[
                  {
                    type: "email",
                    message: "Please enter valid email"
                  },
                  {
                    required: true,
                    message: "Please enter email"
                  }
                ]}
              >
                <Input tabIndex={1} className={classes.inputStyle} size="middle" />
              </Form.Item>
              {isVerifyEmail ? (
                <p className="fs-12" style={{ marginBottom: "10px" }}>
                  We'll send a security code to your email <strong>{showText.split("Email")[1]}</strong> address in the next step.
                </p>
              ) : (
                <p className="fs-12" style={{ marginBottom: "10px" }}>
                  We'll send a security code to your alternate email address in the next step.
                </p>
              )}
            </Div>
            <Flex direction="row" ml={10} justify="flex-end">
              <Flex ml={10}>
                <Button htmlType="submit" type="primary">
                  Next
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      )}
      {!!showConnectMicrosoftValidation && (
        <VerifyEmailByPhoneNoModal submitPhoneNoToVerify={submitPhoneNoToVerify} handleVerifyEmailByPhoneNo={handleVerifyEmailByPhoneNo} />
      )}

      {!!showAccessCodeModal && (
        <VerifyAccessCodeModal subMessage={subMessage} submitAccessCode={submitAccessCode} handleVerifyAccessCodeModal={handleVerifyAccessCodeModal} />
      )}
      {!!showLinkExistingEmailModal && (
        <Modal visible centered bodyStyle={{ maxHeight: "90vh", overflow: "auto" }} footer={null} onCancel={toggleLinkExistingMailboxDialog}>
          <Div style={{ overflow: "auto" }}>
            <Flex>
              <Button type="primary" onClick={() => linkExistingForm.submit()}>
                Save
              </Button>
            </Flex>
            <MailBoxList
              isCardView={false}
              mailBoxes={mailBoxes}
              selectedMailBoxes={inboxIds}
              form={linkExistingForm}
              onSubmit={(args) => {
                toggleLinkExistingMailboxDialog();
                onSubmit(args);
              }}
              title=""
              subTitle=""
              filterStatus={MAILBOXLIST_FILTER.NOTCONNECTED}
            />
          </Div>
        </Modal>
      )}
      {!!showTextVerifyModal && (
        <VerifyPhoneNoModal showText={showText} verifyPhoneNoForm={verifyPhoneNoForm} toggleVerifyPhoneNoForm={toggleVerifyPhoneNoForm} />
      )}
      {!!showUpdatePasswordModal && (
        <UpdatePasswordModal showText={showText} updatePassword={updatePassword} handleUpdatePasswordModal={handleUpdatePasswordModal} />
      )}
      {isBoundConnection && (
        <BoundConnectionModal
          isEnable={isBoundConnection}
          toggleMailboxValidating={toggleMailboxValidating}
          validating={validating}
          isInBoundConnection={isInBoundConnection}
          smtpInBoundError={smtpInBoundError}
          isOutBoundConnection={isOutBoundConnection}
          smtpOutBoundError={smtpOutBoundError}
          editableId={editableId}
          providers={providers}
          setValidating={setValidating}
          setisInBoundConnection={setisInBoundConnection}
          setSmtpInBoundError={setSmtpInBoundError}
          setisOutBoundConnection={setisOutBoundConnection}
          setSmtpOutBoundError={setSmtpOutBoundError}
          isEditMode={isEditMode}
          action={action}
          resetMailboxeSettings={resetMailboxeSettings}
          setIsSettingSmtp={setIsSettingSmtp}
          settingEditView={settingEditView}
          setSelectedMailbox={setSelectedMailbox}
          fromValue={{ email: Data?.email, password: Data?.password }}
        />
      )}
      {!!showGmailConfirmationMailModal && (
        <RecoveryEmailModal subMessage={subMessage} handleRecoveryEmailModal={handleRecoveryEmailModal} confirmRecoveryEmail={confirmRecoveryEmail} />
      )}
      {!!show2StepVerificationModal && (
        <VerficationModal subMessage={subMessage} verifyTwoStep={verifyTwoStep} handleVerificationModal={handleVerificationModal} />
      )}
      {!!showVerificationOptionsModal && (
        <VerificationOptionsModal
          subMessage={subMessage}
          handleVerificationOptionsModal={handleVerificationOptionsModal}
          onOptionSelectHandler={onOptionSelectHandler}
        />
      )}
      {!!showTroubleShootModal && <TroubleShootModal proxyDetail={proxy} handleTroubleShootModal={handleTroubleShootModal} />}
      {!!showManualGmailLogin && (
        <ManualGmailLoginModal
          handleManualGmailLoginModal={handleManualGmailLoginModal}
          installExtension={installExtension}
          handleInstallExtension={handleInstallExtension}
          handleGoogleLogin={handleGoogleLogin}
        />
      )}
      {isMailboxExist && (
        <MailExistModal
          handleOpenClose={() => {
            setIsMailboxExist(false);
            setExisMailMessage("");
            //   setShowMailBoxProviderModal(true);
            // setShowConnectMailboxModal(true);
          }}
          message={exisMailMessage}
          handleSubmit={handleExistMailError}
        />
      )}
      {responseSkipModalState && (
        <ReponseSkipModal
          responseSkipValue={responseSkipValue}
          modalState={responseSkipModalState}
          saveResponseSkipCodes={saveResponseSkipCodes}
          handleModal={handleModal}
          onChangeResponseSkip={onChangeResponseSkip}
        />
      )}
    </Div>
  );
};

export default withLoader(Mailboxes);
