import React, { useMemo, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Text } from "styles/typography";

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const withLoader = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, "setLoading" | "loading">> => ({ ...props }) => {
  const [loadingMessage, setLoading] = useState("");
  const loading = useMemo(() => !!loadingMessage, [loadingMessage]);

  const wrapperStyles: React.CSSProperties = useMemo(
    () =>
      loading
        ? {
            position: "relative",
            width: "100%",
            height: "max-content",
            pointerEvents: "none",
          }
        : { width: "100%" },
    [loading]
  );

  return (
    <div style={wrapperStyles}>
      {loading && (
        <div
          style={{
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Spin indicator={antIcon} />
          <Text variant="caption" uppercase mt={10} color="secondary">
            {loadingMessage}
          </Text>
        </div>
      )}
      <div style={{ opacity: loading ? 0.5 : 1 }}>
        <Component
          {...(props as P)}
          setLoading={setLoading}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default withLoader;
