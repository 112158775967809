import { Form, FormInstance, notification } from "antd";
import { useAuth } from "auth/AuthProvider";
import { useCallback, useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { Div } from "styles/layout";
import classes from "./styles.module.css";

export interface CSVContentsType {
  columnHeaders: string[];
  prospects: string[][];
  file: File;
}

export interface UploadCsvProps {
  onContinue: (fileContent: CSVContentsType) => void;
  form: FormInstance<any>;
  disabled?: boolean;
  continueButtonCheck?: any;
}

export interface ParsedDataRowType {
  data: Array<string>;
  errors: Array<{}>;
}

const MAX_CSV_FILE_SIZE = 100 * 1024 * 1024;
const NOTIFICATION_KEY = "CSV_UPLOAD_ERROR";

const UploadCsv = ({ onContinue, form, disabled = false, continueButtonCheck }: UploadCsvProps) => {
  const [parsedRows, setParsedRows] = useState<ParsedDataRowType[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const { theme } = useAuth();

  const parserRef = useRef<any>();

  const handleOnDrop = (data: ParsedDataRowType[], uploadedFile: File) => {
    notification.destroy();
    setParsedRows([]);

    if (uploadedFile.size > MAX_CSV_FILE_SIZE) {
      parserRef.current?.removeFile();
      notification.error({
        message: "File more than 50 MB",
        description: "Please upload in batches of 50 MB files.",
        key: NOTIFICATION_KEY
      });
      return;
    }
    continueButtonCheck();
    parseData(data, uploadedFile);
  };

  const parseData = (data: ParsedDataRowType[], uploadedFile: File) => {
    const saneRows = data.filter(({ errors }) => !errors.length);

    if (saneRows.length === 0) {
      parserRef.current?.removeFile();
      notification.error({
        message: "Malformed data",
        description: "The file has malformed data. Please upload a again after correcting.",
        key: NOTIFICATION_KEY
      });
      return;
    }

    if (saneRows.length < data.length) {
      const insaneRows = data.length - saneRows.length;
      notification.warning({
        message: "Invalid rows",
        description: `There ${insaneRows > 1 ? "are" : "is"} ${data.length - saneRows.length} malformed prospect${
          insaneRows > 1 ? "s" : ""
        }. You can continue uploading the other prospects or upload again.`,
        key: NOTIFICATION_KEY
      });
    }

    setFile(uploadedFile);
    setParsedRows(saneRows);
  };

  const handleOnError = () => {
    notification.destroy();
    parserRef.current?.removeFile();
    notification.error({
      message: "Something went wrong",
      description: "Please upload a csv file with valid data.",
      key: NOTIFICATION_KEY
    });
  };

  const handleContinue = useCallback(() => {
    if (!file || !parsedRows.length) {
      notification.error({
        message: "Missing CSV file",
        description: "Please upload a csv file to continue",
        key: NOTIFICATION_KEY
      });
      return;
    }

    const columnHeaders = parsedRows[0].data;
    const prospects = parsedRows
      .slice(1)
      .map(({ data }) => data)
      .filter((prospectColumns) => !!prospectColumns.join().trim());

    onContinue({ columnHeaders, prospects, file });
  }, [file, parsedRows, onContinue]);

  const handleRemoveFile = () => {
    notification.destroy();
    continueButtonCheck();
    setParsedRows([]);
  };

  const csvReaderStyle = {
    dropArea: {
      borderColor: theme.textColorDark,
      borderRadius: 20,
      height: "300px",
      maxHeight: "300px",
      padding: 0,
      overflow: "hidden"
    },
    dropAreaActive: {
      boderColor: theme.antdConf.primaryColor,
      backgroundColor: theme.pageBackground
    },
    dropFile: {
      width: 100,
      height: 120,
      backgroundSize: "70px",
      backgroundPosition: "center 18px",
      background:
        "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNjAgNjAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYwIDYwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8cGF0aCBkPSJNNDIuNSwyMmgtMjVjLTAuNTUyLDAtMSwwLjQ0Ny0xLDFzMC40NDgsMSwxLDFoMjVjMC41NTIsMCwxLTAuNDQ3LDEtMVM0My4wNTIsMjIsNDIuNSwyMnoiLz4NCgk8cGF0aCBkPSJNMTcuNSwxNmgxMGMwLjU1MiwwLDEtMC40NDcsMS0xcy0wLjQ0OC0xLTEtMWgtMTBjLTAuNTUyLDAtMSwwLjQ0Ny0xLDFTMTYuOTQ4LDE2LDE3LjUsMTZ6Ii8+DQoJPHBhdGggZD0iTTQyLjUsMzBoLTI1Yy0wLjU1MiwwLTEsMC40NDctMSwxczAuNDQ4LDEsMSwxaDI1YzAuNTUyLDAsMS0wLjQ0NywxLTFTNDMuMDUyLDMwLDQyLjUsMzB6Ii8+DQoJPHBhdGggZD0iTTQyLjUsMzhoLTI1Yy0wLjU1MiwwLTEsMC40NDctMSwxczAuNDQ4LDEsMSwxaDI1YzAuNTUyLDAsMS0wLjQ0NywxLTFTNDMuMDUyLDM4LDQyLjUsMzh6Ii8+DQoJPHBhdGggZD0iTTQyLjUsNDZoLTI1Yy0wLjU1MiwwLTEsMC40NDctMSwxczAuNDQ4LDEsMSwxaDI1YzAuNTUyLDAsMS0wLjQ0NywxLTFTNDMuMDUyLDQ2LDQyLjUsNDZ6Ii8+DQoJPHBhdGggZD0iTTM4LjkxNCwwSDYuNXY2MGg0N1YxNC41ODZMMzguOTE0LDB6IE0zOS41LDMuNDE0TDUwLjA4NiwxNEgzOS41VjMuNDE0eiBNOC41LDU4VjJoMjl2MTRoMTR2NDJIOC41eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=) no-repeat"
    },
    fileSizeInfo: {
      borderRadius: 3,
      lineHeight: 1,
      marginBottom: "0.5em",
      padding: "0 0.4em",
      transform: "translateY(70px)",
      fontWeight: 300
    },
    fileNameInfo: {
      borderRadius: 3,
      fontSize: 14,
      lineHeight: 1,
      padding: "0 0.4em",
      maxWidth: "200px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      transform: "translateY(70px)",
      whiteSpace: "nowrap"
    },
    removeButton: {
      color: theme.antdConf.warningColor,
      height: "10px",
      width: "10px"
    },
    progressBar: {
      backgroundColor: theme.antdConf.primaryColor,
      height: "2px"
    }
  };

  return (
    <Div position="relative">
      {disabled && <Div position="absolute" style={{ top: 0, right: 0, left: 0, bottom: 0, zIndex: 100 }} bgColor="rgba(255,255,255,0.5)" />}
      <Form onFinish={handleContinue} form={form} />
      <CSVReader
        removeButtonColor="red"
        ref={parserRef}
        onDrop={handleOnDrop}
        onError={handleOnError}
        onRemoveFile={handleRemoveFile}
        addRemoveButton
        style={csvReaderStyle}
        config={{ delimiter: "," }}
      >
        <div className={classes.dropAreaPlaceholder}>Drop a csv file or click to upload</div>
      </CSVReader>
    </Div>
  );
};

export default UploadCsv;
