import { DomainItem } from './Domain.types';

export const DOMAIN_LIST_F: DomainItem[] = [
  {
    "domain": "hcltech-solutions.com",
    "status": "Not Started"
  },
  {
    "domain": "foobar.com",
    "status": "Complete"
  },
  {
    "domain": "xpmm.com",
    "status": "Not Started"
  },
  {
    "domain": "rspvvnns.com",
    "status": "Not Started"
  },
  {
    "domain": "seosn.com",
    "status": "Not Started"
  },
  {
    "domain": "utp.com",
    "status": "Not Started"
  },
  {
    "domain": "mnoi.com",
    "status": "Not Started"
  },
  {
    "domain": "hsnnns.com",
    "status": "Not Started"
  },
  {
    "domain": "ajava.com",
    "status": "Not Started"
  },
  {
    "domain": "gogls.com",
    "status": "Not Started"
  },
  {
    "domain": "apple.com",
    "status": "Not Started"
  },
  {
    "domain": "emy.com",
    "status": "Not Started"
  },
  {
    "domain": "ivy.com",
    "status": "Not Started"
  },
  {
    "domain": "fxt.com",
    "status": "Not Started"
  },
  {
    "domain": "hoions.com",
    "status": "Not Started"
  },
  {
    "domain": "potato.com",
    "status": "Not Started"
  },
  {
    "domain": "banana.com",
    "status": "Not Started"
  },
  {
    "domain": "oil.com",
    "status": "Complete"
  },
]
export const DOMAIN_LIST_RESPONSE = {
  "success": true,
  "data": {
    "domains": DOMAIN_LIST_F.slice(0, 11)
  }
};

export const DOMAIN_TRACKING_LABEL  = 'Custom Domain Tracking';