import { useState, useEffect } from "react";

function getStorageValue(key: string, defaultValue: any) {
  const saved = localStorage.getItem(key);
  const initial = saved && JSON.parse(saved);
  return initial ?? defaultValue;
}

export const LOCAL_STORAGE_MAP = {
  MAILBOX_VIEW_MODE: "_em.mvt",
  ACCOUNT_VIEW_MODE: "_ea.mvt",
};

export const useLocalStorage = (key: string, defaultValue: any = null) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
