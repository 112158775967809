import React, { useState } from "react";
import { Modal, Input, notification } from "antd";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { Editor as WYSIWYGEditor } from "components/Froala";
import { useCallback } from "react";
import { InboundMailWithThreadType } from "types/Campaign";
import { isEmailValid } from "utils/validators";

interface ReplyModalProps {
  onOk: (reply: { toEmails: string[]; subject: string; body: string, ccsMails: string[] }) => void;
  onDismiss: () => void;
  inboundMailWithThread: InboundMailWithThreadType;
}

const ReplyModal = ({
  onDismiss,
  onOk,
  inboundMailWithThread,
}: ReplyModalProps) => {
  const [mailData, setMailData] = useState({
    mailTo: inboundMailWithThread.mailThread.from.address,
    subject: inboundMailWithThread.mailThread.subject,
    body: `<div style="margin-bottom: 10px">
    <p></p>
    </div>${inboundMailWithThread.mailThread.html_body}`,
    ccs: inboundMailWithThread?.mailThread?.ccs?.map(item => item?.address).join(', ')
  });

  const getOnChange = useCallback(
    (field: string) => (value: string) => {
      setMailData((prev) => ({ ...prev, [field]: value }));
    },
    []
  );

  const onSend = useCallback(() => {
    const { mailTo, subject, body, ccs } = mailData;
    let areEmailsValid = true;
    const toEmails = mailTo.split(/[ ,]+/);
    const ccsMails = ccs.split(/[ ,]+/);

    toEmails.forEach((mail) => {
      if (!isEmailValid(mail)) {
        areEmailsValid = false;
      }
    });
    // ccsMails.forEach((mail) => {
    //   if (!isEmailValid(mail)) {
    //     areEmailsValid = false;
    //   }
    // });
    if (areEmailsValid) {
      onOk({
        toEmails,
        subject,
        body,
        ccsMails
      });

      return;
    }

    notification.error({
      message: "Please enter valid emails.",
      description: "Emails should be seperated by a comma or a space.",
    });
  }, [mailData, onOk]);

  return (
    <Modal
      visible
      onCancel={onDismiss}
      onOk={onSend}
      okText="Send"
      width={600}
      centered
    >
      <Flex mt={30} direction="column" mb={20}>
        <Text variant="overline" mb={5}>
          To
        </Text>
        <Input
          value={mailData.mailTo}
          onChange={({ target: { value } }) =>
            setMailData((prev) => ({ ...prev, mailTo: value }))
          }
        />
      </Flex>
      <Flex direction="column" mb={20}>
        <Text variant="overline" block color="secondary">
          CC
        </Text>
        <Input
          value={mailData?.ccs}
          onChange={({ target: { value } }) => setMailData((prev) => ({ ...prev, ccs: value }))}
          style={{ color: "black" }}
        />
          <Text variant="caption" color="secondary" style={{ fontSize: "10px" }}>
            Email addresses can be seperated by commas or spaces.
          </Text>{" "}
      </Flex>
      <Flex mt={20} direction="column">
        <Text variant="overline" mb={5}>
          Subject
        </Text>
        <Input
          value={mailData.subject}
          onChange={({ target: { value } }) =>
            setMailData((prev) => ({ ...prev, subject: value }))
          }
        />
      </Flex>
      <Div mh={350} mt={15}>
        <WYSIWYGEditor
          value={mailData.body}
          onChange={getOnChange("body")}
          config={{
            height: 325,
            toolbarBottom: true,
            imageMaxSize: 1024 * 1024 * 1,
            imageEditButtons: ["imageDisplay", "imageAlign", "imageRemove"],
            placeholderText: "Enter email body",
            pastePlain: true,
          }}
          moreRichButtons={[]}
          moreTextButtons={[
            "textColor",
            "bold",
            "italic",
            "backgroundColor",
            "underline",
            "insertLink",
            "fontFamily",
            "fontSize",
          ]}
          moreTextButtonsVisible={5}
          tag="textarea"
          variables={[]}
          spinners={[]}
        />
      </Div>
    </Modal>
  );
};

export default ReplyModal;
