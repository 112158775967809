import { Button, Form, Input, Modal } from "antd";
import React, { ChangeEvent } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";

interface InviteMemberDialogProps {
  onOk: (newMember: { name: string; email: string }) => void;
  onCancel: () => void;
}
const InviteMemberDialog = ({ onOk, onCancel }: InviteMemberDialogProps) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible
      okButtonProps={{
        type: "primary",
        danger: true,
      }}
      footer={null}
      centered
      onCancel={onCancel}
    >
      <Text variant="h6" mb={10}>
        Invite member
      </Text>
      <Form
        form={form}
        name="invite"
        onFinish={onOk}
        initialValues={{
          name: "",
          email: "",
        }}
        scrollToFirstError
      >
        <Text variant="overline">Name</Text>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter new member name.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Text variant="overline">Email</Text>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid email.",
            },
            {
              required: true,
              message: "Please input member email.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Flex justify="flex-end">
          <Button type="text" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default InviteMemberDialog;
