import { Modal } from "antd";
import React from "react";
import { Div } from "styles/layout";
import { Text } from "styles/typography";
import { CampaignProspectType } from "types/Campaign";
import { ProspectStatusEnum } from "types/Prospect";

interface PropspectStatusChangeModalProps {
  prospect: CampaignProspectType;
  newStatus: ProspectStatusEnum;
  onOk: () => void;
  onCancel: () => void;
  operation: string;
}

const ProspectStatusChangeModal = ({
  onOk,
  onCancel,
  operation,
  prospect,
  newStatus,
}: PropspectStatusChangeModalProps) => (
  <Modal
    visible
    onOk={onOk}
    onCancel={onCancel}
    okText={newStatus === ProspectStatusEnum.PAUSED ? "Pause" : newStatus === ProspectStatusEnum.CREATED  ? "Resume" : "Proceed"}
    centered
  >
    <Div>
      <Text variant="sub2" block>
        Are you sure you want to {operation} {prospect.email}?
      </Text>
    </Div>
  </Modal>
);

export default ProspectStatusChangeModal;
