import React from "react";
import { Flex, BgImage } from "styles/layout";
import { Text } from "styles/typography";
import { Link } from "react-router-dom";
import { Empty } from "antd";

const NotFoundPage = () => (
  <Flex align="center" justify="center" style={{ minHeight: "100vh" }} direction="column">
    <Empty
      description={false}
      imageStyle={{
        height: 300,
        width: 300
      }}
    />
    <Text mt={30} variant="h6" color="secondary">
      We couldn't find what you were looking for.
    </Text>
    <Text variant="sub1" mt={20}>
      <Link to="/">Take me Home</Link>
    </Text>
  </Flex>
);

export default NotFoundPage;
