import { Method } from "axios";

interface ApiUrlType {
  url: string;
  method: Method;
}

export const API_ENDPOINTS = {
  CAMPAIGNS: {
    // getListUrl: (active: boolean = true): ApiUrlType => ({
    //   url: `/campaigns?active=${active}`,
    //   method: "GET",
    // }),
    getListUrl: (status: string): ApiUrlType => ({
      url: `/campaigns?status=${status}`,
      method: "GET"
    }),
    getUpdateUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}`,
      method: "PUT"
    }),
    getCreateUrl: (): ApiUrlType => ({
      url: "/campaign",
      method: "POST"
    }),
    getStatusChangeUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/status`,
      method: "POST"
    }),
    getStatus: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/status`,
      method: "GET"
    }),
    getDetailUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}`,
      method: "GET"
    }),
    getDeleteUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}`,
      method: "DELETE"
    }),
    getCloneUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/clone`,
      method: "POST"
    }),
    getListVariablesUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/variables`,
      method: "GET"
    }),
    getProspectVariablesUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/variables?prospect=true`,
      method: "GET"
    }),
    getListEventsUrl: (id: string): ApiUrlType => ({
      url: `/events/campaign/${id}`,
      method: "GET"
    }),
    getDeltaEventsUrl: (id: string, time: string): ApiUrlType => ({
      url: `/events/campaign/${id}/time/${time}`,
      method: "GET"
    }),
    getSequencesListUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/sequences`,
      method: "GET"
    }),
    createCustomSequence: (id: string): ApiUrlType => ({
      url: `/sequence/${id}`,
      method: "POST"
    }),
    deleteCustomSequence: (id: string): ApiUrlType => ({
      url: `/sequence/${id}`,
      method: "DELETE"
    }),
    editCustomSequence: (id: string): ApiUrlType => ({
      url: `/sequence/${id}`,
      method: "PUT"
    }),
    getTimeZonesUrl: (): ApiUrlType => ({
      url: `/timezones`,
      method: "GET"
    }),
    smtpValidate: (): ApiUrlType => ({
      url: `/auth`,
      method: "POST"
    }),
    creatSmtpMailBox: (): ApiUrlType => ({
      url: `/mailbox`,
      method: "POST"
    }),
    createMailBox: (): ApiUrlType => ({
      url: `/mailbox`,
      method: "POST"
    }),
    reconnectMailBox: (id: string): ApiUrlType => ({
      url: `/mailbox/${id}`,
      method: "PUT"
    }),
    downloadProspect: (campaignId: string, raw: boolean): ApiUrlType => ({
      url: `/campaigns/${campaignId}/metrics` + (raw ? "?raw=1" : ""),
      method: "GET"
    }),
    getRestartUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/restart`,
      method: "POST"
    }),
    smtpValidateStatus: (id: string): ApiUrlType => ({
      url: `/auth/${id}`,
      method: "GET"
    }),
    moveCampaign: (id: string): ApiUrlType => ({
      url: `campaign/${id}`,
      method: "PUT"
    }),
    reConnectMail: (id: string): ApiUrlType => ({
      url: `/reconnect/mailbox/${id}`,
      method: "PUT"
    })
  },
  CAMPAIGN_TAG: {
    getTagListUrl: (uId: string): ApiUrlType => {
      let url = "/tags";
      if (uId !== "null" && uId !== "undefined" && uId && uId.length) {
        url = `${url}?uId=${uId}`;
      }
      return {
        url,
        method: "GET"
      };
    },
    getTagList: (campaignId: string): ApiUrlType => ({
      url: `/v2/campaigns/${campaignId}/tags`,
      method: "GET"
    }),
    getEditTagUrl: (tagId: string): ApiUrlType => ({
      url: `/tags/${tagId}`,
      method: "PUT"
    }),
    getTagDeleteUrl: (tagId: string): ApiUrlType => ({
      url: `/tags/${tagId}`,
      method: "DELETE"
    }),
    createTagUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/tags`,
      method: "PUT"
    }),
    updateTagUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/tags`,
      method: "PUT"
    }),
    updateTagNameUrl: (campaignId: string, tagID: string, uId: string): ApiUrlType => ({
      url: uId && uId.length ? `/tags/${tagID}?uId=${uId}` : `/tags/${tagID}`,
      method: "PUT"
    }),
    getDeleteUrl: (id: string, tagId: string): ApiUrlType => ({
      url: `campaign/${id}/tags/${tagId}`,
      method: "DELETE"
    }),
    createNewTagUrl: (): ApiUrlType => ({
      url: `/tags`,
      method: "POST"
    })
  },
  GET_LOG: {
    getLogListUrl: (id: string, type: string): ApiUrlType => ({
      url: `log/${id}/${type}`,
      method: "GET"
    })
  },
  MAILBOX_LOG: {
    getLogListUrl: (id: string): ApiUrlType => ({
      url: "log/" + id + "?filter=critical",
      // url: `log/${id}?filter=critical`,
      method: "GET"
    })
  },
  CAMPAIGN_PROSPECT: {
    getUploadUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/prospects/upload/v2`,
      method: "POST"
    }),
    getUploadStatus: (id: string): ApiUrlType => ({
      url: `prospect/upload/status/${id}`,
      method: "GET"
    }),
    getListUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/prospects`,
      method: "POST"
    }),
    getUrl: (id: string): ApiUrlType => ({
      url: `/prospects/${id}`,
      method: "GET"
    }),
    getUpdateStatusUrl: (id: string, status: string): ApiUrlType => ({
      url: `/prospect/${id}/status/${status.toLowerCase()}`,
      method: "PUT"
    }),
    getUpdateDetailsUrl: (): ApiUrlType => ({
      url: `/prospect/upsert`,
      method: "PUT"
    }),
    getDeleteUrl: (): ApiUrlType => ({
      url: `/prospect`,
      method: "DELETE"
    }),
    getAllDeleteUrl: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/prospect`,
      method: "DELETE"
    }),
    downloadProspect: (campaignId: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/prospects/download`,
      method: "POST"
    }),
    getDownloadStatus: (requestId: string): ApiUrlType => ({
      url: `/prospect/download/status/${requestId}`,
      method: "GET"
    }),
    downloadProspectInitiate: (campaignId: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/prospects/download`,
      method: "POST"
    }),
    downloadProspectStatus: (requestId: string): ApiUrlType => ({
      url: `/prospect/download/status/${requestId}`,
      method: "GET"
    }),
    prospectSummary: (campaignId: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/prospect/summary?action=prospect`,
      method: "GET"
    })
  },
  CAMPAIGN_TOUCH: {
    getListUrl: (id: string, sequenceId: string): ApiUrlType => ({
      url: `/campaign/${id}/sequence/${sequenceId}/touches`,
      method: "GET"
    })
  },
  CAMPAIGN_TEMPLATE: {
    getCreateUrl: (id: string, sequenceId: string): ApiUrlType => ({
      url: `/campaign/${id}/sequence/${sequenceId}/template`,
      method: "POST"
    }),
    getUpdateUrl: (templateId: string): ApiUrlType => ({
      url: `/template/${templateId}`,
      method: "PUT"
    })
  },
  CAMPAIGN_SPIN_TYPE: {
    getDetailUrl: (campaignId: string, sequenceId: string, spinType: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/sequence/${sequenceId}/spintype/${spinType}`,
      method: "GET"
    }),
    getSpinnerList: (campaignId: string, spinType: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/spinner/${spinType}`,
      method: "GET"
    }),
    deleteSpinner: (campaignId: string, spinType: string, lineId: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/spinner/${spinType}/${lineId}`,
      method: "DELETE"
    })
  },
  CAMPAIGN_METRIC: {
    getOverallMetricsUrl: (campaignId: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/metrics`,
      method: "GET"
    }),
    getDetailMetricsUrl: (campaignId: string): ApiUrlType => ({
      url: `/metrics/campaign/${campaignId}/detail`,
      method: "POST"
    }),
    getInsightMetricsUrl: (campaignId: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/insight`,
      method: "GET"
    }),
    getFixedMetricUrl: (campaignId: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/metrics/fixed`,
      method: "GET"
    }),
    getTimedMetricsUrl: (campaignId: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/metrics/timed`,
      method: "POST"
    })
  },
  TOUCH: {
    getDeleteUrl: (id: string): ApiUrlType => ({
      url: `/touch/${id}`,
      method: "DELETE"
    }),
    getListTemplatesUrl: (id: string): ApiUrlType => ({
      url: `/touch/${id}/templates`,
      method: "GET"
    }),
    getTemplatePreview: (id: string): ApiUrlType => ({
      url: `/preview/${id}`,
      method: "GET"
    })
  },
  SPIN_TYPES: {
    getListUrl: (): ApiUrlType => ({
      url: `/spintypes`,
      method: "GET"
    }),
    getUpdateUrl: (campaignId: string, name: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/spinner/${name}`,
      method: "PUT"
    }),
    getListSampleUrl: (spinType: string): ApiUrlType => ({
      url: `/spintype/sample/${spinType}`,
      method: "GET"
    }),
    addSpinners: (campaignId: string, name: string): ApiUrlType => ({
      url: `/campaign/${campaignId}/spinner/${name}`,
      method: "POST"
    })
  },
  MAILBOX: {
    // getListUrl: (): ApiUrlType => ({
    //   url: "/mailboxes/",
    //   method: "GET",
    // }),
    getListUrl: (id: string): ApiUrlType => ({
      url: `/account/${id}/mailboxes/`,
      method: "GET"
    }),
    getCreateUrl: (): ApiUrlType => ({
      url: "/mailbox/",
      method: "POST"
    }),
    getDetailUrl: (id: string): ApiUrlType => ({
      url: `/mailbox/${id}`,
      method: "GET"
    }),
    getUpdatelUrl: (id: string): ApiUrlType => ({
      url: `/mailbox/${id}`,
      method: "PUT"
    }),
    getUpdateMailboxPwdUrl: (id: string): ApiUrlType => ({
      url: `/mailbox/${id}/pwd`,
      method: "PUT"
    }),
    getDeletelUrl: (id: string): ApiUrlType => ({
      url: `/mailbox/${id}`,
      method: "DELETE"
    }),
    getIpsDetailUrl: (email: string): ApiUrlType => ({
      url: `/mailbox/ips/${email}`,
      method: "GET"
    }),
    getLogsDetailUrl: (id: string): ApiUrlType => ({
      url: `/inbox/${id}/log`,
      method: "GET"
    }),
    createCName: (domain: string): ApiUrlType => ({
      url: `/domains/${domain}/cname`,
      method: "POST"
    }),
    getCNames: (domain: string): ApiUrlType => ({
      url: `/domains/${domain}/cname`,
      method: "GET"
    }),
    validateCName: (domain: string): ApiUrlType => ({
      url: `/domains/${domain}/cname/validate`,
      method: "GET"
    }),
    webhookList: (campaignId: string): ApiUrlType => ({
      url: `/campaigns/${campaignId}/webhooks`,
      method: "GET"
    }),
    webhookUpdate: (campaignId: string): ApiUrlType => ({
      url: `/campaigns/${campaignId}/webhooks`,
      method: "PUT"
    }),
    getDomainList: (accountId: string): ApiUrlType => ({
      url: `/account/${accountId}/domains`,
      method: "GET"
    }),
    calculateIps: (mailboxId: string): ApiUrlType => ({
      url: `/mailbox/${mailboxId}/calculate-ips`,
      method: "POST"
    }),
    sendEmailTemplate: (): ApiUrlType => ({
      url: `/forward/response`,
      method: "POST"
    }),
    createDeliveryTemplate: (): ApiUrlType => ({
      url: `/forward/template`,
      method: "POST"
    }),
    fetchTemplateList: (uid: string): ApiUrlType => ({
      url: `/forward/templates/?uid=${uid}`,
      method: "GET"
    }),
    fetchSelectedTemplatedData: (responseId: string, templateId: string, uid: string): ApiUrlType => ({
      url: `forward/response/${responseId}/template/${templateId}?uid=${uid}`,
      method: "GET"
    }),
    deleteTemplates: (templateId: string, uid: string): ApiUrlType => ({
      url: `forward/template/${templateId}?uid=${uid}`,
      method: "DELETE"
    }),
    Ipsupdate: (id:string): ApiUrlType => ({
      url: `/mailbox/${id}`,  
      method: "PUT"
    }),
    transferMailboxes: (): ApiUrlType => ({
      url: `/mailboxes/transfer`,
      method: "POST"
    })
  },
  DNSCONNECTION: {
    getListUrl: (): ApiUrlType => ({
      url: "/spot/",
      method: "POST"
    }),
    getCreateUrl: (): ApiUrlType => ({
      url: "/spot/used/",
      method: "POST"
    }),
    updateProxy: (proxyId: string): ApiUrlType => ({
      url: `/proxy/${proxyId}/replace`,
      method: "POST"
    })
  },
  SUBSCRIPTION: {
    getSubscription: (): ApiUrlType => ({
      url: "/subscription",
      method: "GET"
    }),
    updateSubscription: (): ApiUrlType => ({
      url: "/subscription",
      method: "PUT"
    }),
    getPaymentPortalUrl: (callback: string): ApiUrlType => ({
      url: `/payment/portal?callback=${callback}`,
      method: "GET"
    })
  },
  DONOTCONTACT: {
    // getListUrl: (): ApiUrlType => ({
    //   url: `/organization/dnc`,
    //   method: "GET",
    // }),
    getDncListUrl: (id: string | null): ApiUrlType => ({
      url: `/account/${id}/dnc`,
      method: "POST"
    }),
    getListUrl: (id: string): ApiUrlType => ({
      url: `/account/${id}/dnc`,
      method: "GET"
    }),
    setEmailsAndDomais: (): ApiUrlType => ({
      url: `/organization/dnc`,
      method: "POST"
    }),
    getUnSubscribeRequestId: (id: string): ApiUrlType => ({
      url: `/account/${id}/unsubscribed?action=start`,
      method: "GET"
    }),
    downloadUnSubscribeProspects: (id: string, requestId: string): ApiUrlType => ({
      url: `/account/${id}/unsubscribed?action=status&id=${requestId}`,
      method: "GET"
    })
  },
  OAUTH: {
    getOauthUrl: ({ provider, state, email, reconnect = false }: { provider: string; state: string; email?: string; reconnect?: boolean }): ApiUrlType => ({
      url: email
        ? `/oauthurl?provider=${provider}&state=${state}&callback=${window.location.origin}/oauth/callback&email=${email}&reconnect=${reconnect}`
        : `/oauthurl?provider=${provider}&state=${state}&reconnect=${reconnect}&callback=${window.location.origin}/oauth/callback`,
      method: "GET"
    }),
    getGoogleOauthUrl: (props: any): ApiUrlType => ({
      url: `/oauthurl?provider=${props.provider}&state=${props.state}&reconnect=${props.reconnect}&callback=${window.location.origin}/oauth/callback`,
      method: "GET"
    })
  },
  EVENTS: {
    CAMPAIGN_EVENTS: {
      getFullEventsUrl: (id: string): ApiUrlType => ({
        url: `/events/campaign/${id}`,
        method: "GET"
      }),
      getDeltaEventsUrl: (id: string, time: number): ApiUrlType => ({
        url: `/events/campaign/${id}/time/${time}`,
        method: "GET"
      }),
      getActivitiesByTabs: (id: string, activeTab: string, rangeType: string): ApiUrlType => ({
        url: `/campaign/${id}/stats/${activeTab}?range=${rangeType}`,
        method: "GET"
      })
    }
  },
  NOTIFICATIONS: {
    getListUrl: (): ApiUrlType => ({
      url: `/inbound/unreads`,
      method: "GET"
    })
  },
  INBOUND: {
    getListUrl: (): ApiUrlType => ({
      url: `/inbound`,
      method: "POST"
    }),
    getTagResponseUrl: (
      campaignId: string,
      tagId: string,
      page: number,
      pageSize: number,
      prospectEmail: any,
      startDateInEpoch: any,
      endDateInEpoch: any,
      readStatus: any,
      accountId: string,
      levelType: string,
      sortKey: string
    ): ApiUrlType => {
      let url = `/tags/${tagId}/responses?page=${page}&pageSize=${pageSize}&sort=${sortKey}`;
      if (readStatus !== "ALL") {
        url = `${url}&readStatus=${readStatus}`;
      }
      if (prospectEmail !== "null" && prospectEmail !== "undefined" && prospectEmail && prospectEmail.length) {
        url = `${url}&prospectEmail=${prospectEmail}`;
      }
      if (startDateInEpoch && endDateInEpoch) {
        url = `${url}&startDateInEpoch=${startDateInEpoch}&endDateInEpoch=${endDateInEpoch}`;
      }
      if (accountId && accountId.length) {
        url = `${url}&aId=${accountId}`;
      }
      if (campaignId && campaignId.length) {
        url = `${url}&cId=${campaignId}`;
      }
      // if (levelType !== 'null' && levelType !== 'undefined' && levelType && levelType.length) {
      //   if (levelType === "ACCOUNT") {
      //     if (accountId && accountId.length) {
      //       url = `${url}&aId=${accountId}`
      //     }
      //   } else if (levelType === "CAMPAIGN") {
      //     if (campaignId && campaignId.length) {
      //       url = `${url}&cId=${campaignId}`
      //     }
      //   }
      //   else {
      //     /* user level */
      //   }
      // }

      return {
        url: url,
        method: "GET"
      };
    },
    addTagToMailThred: (inboundMessageId: string): ApiUrlType => ({
      url: `/inbound/${inboundMessageId}/tags`,
      method: "PUT"
    }),
    addMultipleTagToMailThred: (): ApiUrlType => ({
      url: `/inbound/tag`,
      method: "POST"
    }),
    addMultipleResponseTagMailThread: (responseId: string): ApiUrlType => ({
      url: `/replies/${responseId}/tags`,
      method: "PUT"
    }),
    deleteMultipleResponseTagMailThread: (responseId: string, tagId: string): ApiUrlType => ({
      url: `/replies/${responseId}/tags/${tagId}`,
      method: "DELETE"
    }),
    fectListUrl: (tagId: string): ApiUrlType => ({
      url: `/tags/${tagId}`,
      method: "GET"
    }),
    getMarkAsUnReadUrl: (id: string): ApiUrlType => ({
      url: `/inbound/unread/${id}`,
      method: "POST"
    }),
    getMoveToInitialSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence/initial`,
      method: "PUT"
    }),
    getMoveToInfoSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence/info`,
      method: "PUT"
    }),
    getMoveToPowerSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence/power`,
      method: "PUT"
    }),
    getMoveToFutureSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence/future`,
      method: "PUT"
    }),
    getMoveToReferralSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence/referral`,
      method: "PUT"
    }),
    getMoveToMeetingSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence/meeting`,
      method: "PUT"
    }),
    getMoveToRescheduleSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence/reschedule`,
      method: "PUT"
    }),
    getMoveToCustomSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence/custom`,
      method: "PUT"
    }),
    getInboundTagUrl: (): ApiUrlType => ({
      url: `/inbound/tag`,
      method: "POST"
    }),
    getDeliverUrl: (): ApiUrlType => ({
      url: `/leads/send`,
      method: "POST"
    }),
    getMailDetailUrl: (id: string, reply: string): ApiUrlType => ({
      url: `/inbound/fetch?id=${id}&reply=${reply}`,
      method: "GET"
    }),
    getResponseDetailUrl: (id: string, reply: string): ApiUrlType => ({
      url: `/replies/${id}/fetch?reply=${reply}`,
      method: "GET"
    }),
    getReplyToMailUrl: (id: string): ApiUrlType => ({
      url: `/inbound/reply/${id}`,
      method: "POST"
    }),
    getMarkAsReadUrl: (id: string): ApiUrlType => ({
      url: `/inbound/read/${id}`,
      method: "POST"
    }),
    getOnStatusUpdateUrl: (id: string, status: string): ApiUrlType => ({
      url: `/inbound/${id}/status/${status.toLowerCase()}`,
      method: "PUT"
    }),
    getSpamMailUpdateUrl: (id: string): ApiUrlType => ({
      url: `/message/${id}`,
      method: "PUT"
    }),
    getMoveToSequenceUrl: (): ApiUrlType => ({
      url: `/prospect/inbound/sequence`,
      method: "PUT"
    }),
    fetchInboundTagUrl: (): ApiUrlType => ({
      url: `/v2/inbound/tag`,
      method: "POST"
    }),
    discardMails: (): ApiUrlType => ({
      url: `/messages`,
      method: "DELETE"
    }),
    generateProspectsCreationEmail: (id: string): ApiUrlType => ({
      url: `/campaign/${id}/prospect-email-service`,
      method: "PUT"
    }),
    markAsReadEmails: (): ApiUrlType => ({
      url: `/responses/messages/read`,
      method: "PUT"
    }),
    getMarkAsProcessedUrl: (id: string): ApiUrlType => ({
      url: `/inbound/processed/${id}`,
      method: "POST"
    })
  },
  ACCOUNTS: {
    getUserListUrl: (): ApiUrlType => ({
      url: `/users/shared`,
      method: "GET"
    }),
    getListUrl: (uId: string): ApiUrlType => {
      let url = "/accounts/v2";
      if (uId !== "null" && uId !== "undefined" && uId && uId.length) {
        url = `${url}?uId=${uId}`;
      }
      return {
        url,
        method: "GET"
      };
    },
    getCreateUrl: (uId: string): ApiUrlType => ({
      url: uId && uId.length ? `/account?uId=${uId}` : `/account`,
      method: "POST"
    }),
    getUpdateUrl: (id: string): ApiUrlType => ({
      url: `/account/${id}`,
      method: "PUT"
    }),
    getDeleteUrl: (id: string): ApiUrlType => ({
      url: `/account/${id}`,
      method: "DELETE"
    }),
    getDetailUrl: (id: string): ApiUrlType => ({
      url: `account/${id}/campaigns`,
      method: "GET"
    }),
    getDetailUrlByFilter: (id: string, statusFilter: string): ApiUrlType => ({
      url: `account/${id}/campaigns?status=${statusFilter}`,
      method: "GET"
    }),
    getAccountUrl: (id: string): ApiUrlType => ({
      url: `account/${id}`,
      method: "GET"
    }),
    getGlobalSearch: (search: string): ApiUrlType => ({
      url: `search?value=*${search}*`,
      method: "GET"
    }),
    getUserDetails: (id: string): ApiUrlType => ({
      url: `/users/${id}`,
      method: "GET"
    }),
  },
  HOLIDAYS: {
    getHolidays: (accountId: string): ApiUrlType => ({
      url: `/account/${accountId}/holidays`,
      method: "GET"
    }),
    getHoliday: (userId: string): ApiUrlType => ({
      url: `/user/${userId}/holidays`,
      method: "GET"
    }),
    addUpdateHoliday: (userId: string): ApiUrlType => ({
      url: `/account/${userId}/holiday`,
      method: "POST"
    }),
    addUpdateHolidays: (userId: string): ApiUrlType => ({
      url: `/user/${userId}/holidays`,
      method: "POST"
    }),
    deleteHoliday: (accountId: string, id: string): ApiUrlType => ({
      url: `/account/${accountId}/holiday/${id}`,
      method: "DELETE"
    }),
    deleteHolidays: (id: string, userId: string): ApiUrlType => ({
      url: `user/${userId}/holidays/${id}`,
      method: "DELETE"
    }),
    updateHolidays: (userId: string, holidayId: string): ApiUrlType => ({
      url: `/user/${userId}/holidays/${holidayId}`,
      method: "PUT"
    })
  },
  CONTACT_SEARCH: {
    contactSearch: (): ApiUrlType => ({
      url: "/search/contact",
      method: "POST"
    }),
    adaptSearch: (): ApiUrlType => ({
      url: "/adapt/search",
      method: "POST"
    }),
    addToCampaign: (): ApiUrlType => ({
      url: "/contact/addToCampaign",
      method: "POST"
    }),
    downloadContacts: (): ApiUrlType => ({
      url: "/contact/download-initiate",
      method: "POST"
    })
  },
  INTEGRATIONS: {
    getIntegrationSettingsUrl: (): ApiUrlType => ({
      url: "/integration/settings",
      method: "GET"
    }),
    saveIntegrationSettings: (): ApiUrlType => ({
      url: "/integration/settings",
      method: "POST"
    }),
    getInboxesLimit: (provider: string): ApiUrlType => ({
      url: `/inboxes/limit/config?provider=${provider}`,
      method: "GET"
    })
  },
  META: {
    config: (): ApiUrlType => ({
      url: "/site/config",
      method: "GET"
    }),
    pricing: (id: string = ""): ApiUrlType => ({
      url: "/pricing" + (id ? `?id=${id}` : ""),
      method: "GET"
    }),
    cardValidate: (): ApiUrlType => ({
      url: "/card/validate",
      method: "POST"
    })
  },
  OTHERS: {
    browserBased: (instanceId: string): ApiUrlType => ({
      url: `/api/${instanceId}/checkStatus`,
      method: "POST"
    }),
    launchedBrowser: (instanceId: string): ApiUrlType => ({
      url: `/api/${instanceId}/task`,
      method: "POST"
    })
  },
  UNIBOX: {
    getUniboxListUrl: (accountId: string): ApiUrlType => ({
      url: `/account/${accountId}/inbound`,
      method: "POST"
    }),
    getTags: (accountId: string): ApiUrlType => ({
      url: "/tags" + (accountId ? `?uId=${accountId}` : ""),
      method: "GET"
    })
  },
  NOTIFICATION: {
    getAlertCounts: (uId: string): ApiUrlType => {
      let url = "/alerts/unread/count";
      if (uId !== "null" && uId !== "undefined" && uId && uId.length) {
        url = `${url}?uId=${uId}`;
      }
      return {
        url,
        method: "GET"
      };
    },
    getAlertLists: (uId: string, severity: string): ApiUrlType => {
      let url = "/alerts?read=false";
      if (uId !== "null" && uId !== "undefined" && uId && uId.length) {
        url = `${url}&uId=${uId}`;
      }
      if (severity !== "null" && severity !== "undefined" && severity && severity.length) {
        url = `${url}&severity=${severity}`;
      }
      return {
        url,
        method: "GET"
      };
    },
    markAsRead: (alertId: string): ApiUrlType => {
      let url = `/alerts/${alertId}`;
      // if (uId !== 'null' && uId !== 'undefined' && uId && uId.length) {
      //   url = `${url}&uId=${uId}`
      // }
      // if (severity !== 'null' && severity !== 'undefined' && severity && severity.length) {
      //   url = `${url}&severity=${severity}`
      // }
      // if (alertId !== 'null' && alertId !== 'undefined' && alertId && alertId.length) {
      //   url = `${url}&alertId=${alertId}`
      // }
      return {
        url,
        method: "PUT"
      };
    }
  },
  REPORT: {
    getReportList: (useid: string): ApiUrlType => ({
      url: `/${useid}/reportingrules`,
      method: "GET"
    }),
    addReport: (): ApiUrlType => ({
      url: `/reportingrules`,
      method: "POST"
    }),
    updateReport: (reportId: string): ApiUrlType => ({
      url: `/reportingrules/${reportId}`,
      method: "PUT"
    }),
    getReportById: (reportId: string): ApiUrlType => ({
      url: `/reportingrules/${reportId}`,
      method: "GET"
    }),
    deleteReport: (reportId: string): ApiUrlType => ({
      url: `/reportingrules/${reportId}`,
      method: "DELETE"
    }),
  },

};

export const BASE_URL = "https://api.outwin.io";
export const WHISPER_BASE_URL = "https://whisper.outwin.io";

export const CAMPAIGN_SORTABLE_FIELDS = ["Created", "Updated", "Last sent", "Replied"];
