import { Button, Form, Input, Modal, Typography } from "antd";
import { Div, Flex } from "styles/layout";
import { DARK_GRAY_COLOR } from "utils/colors";

interface VerifyPhoneNoModalProps {
  showText: string;
  verifyPhoneNoForm: (formValues: any) => void;
  toggleVerifyPhoneNoForm: () => void;
}

const VerifyPhoneNoModal = ({
  showText,
  verifyPhoneNoForm,
  toggleVerifyPhoneNoForm,
}: VerifyPhoneNoModalProps) => {
  const { Title } = Typography;
  const [microsoftValidation] = Form.useForm();


  return (
    <Modal visible footer={null} onCancel={() => toggleVerifyPhoneNoForm()} width={300} centered>
      <Form scrollToFirstError form={microsoftValidation} requiredMark={false} onFinish={verifyPhoneNoForm}>
        <Title level={5}>To verify that this is your phone number, enter the last 4 digits of {showText}</Title>

        <Div>
          <label style={{ marginBottom: "5px" }} className="fs-12 fw-600 text-dark">
            Phone Number
          </label>
          <Form.Item name="phone" rules={[{ required: true, message: "Please enter the last 4 digits!" }]}>
            <Input />
          </Form.Item>
        </Div>
        <Flex direction="row" ml={10} justify="flex-end">
          <Flex></Flex>
          <Flex ml={10}>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default VerifyPhoneNoModal;
