import { ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import { Modal, Form, Input, Button, Checkbox, Select } from "antd";
import { useState } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { LIGHT_GRAY_COLOR } from "utils/colors";
import classes from "./styles.module.css";
import { OAUTH_STATE_LS_KEY } from "constants/localstorage";
import { useLocation } from "react-router-dom";
import appContext from "contexts/appContext";
import MailBoxSettings, { MailBoxSettingsType } from "components/MailBox/Settings";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import LocalStorage from "utils/localstorage";
import { getProviderAndProtocol, getProviderDetails } from "../Provider";
import { Label } from "../Settings/styles";
import { MailboxTypeEnum } from "types/MailBox";
import BoundConnectionModal from "../../../pages/CampaignDetail/Modals/ConnectionModal";
const { Option } = Select;
interface MailBoxProviderModalProps {
  onDismiss: () => void;
  provider: any;
  backButton: (isFromValidationModal?: boolean) => void;
  fetchInboxes: any;
  showAddMailbox: any;
  isEditMode: boolean;
  isNewCampFlow: boolean;
  mailId: string;
  editableData: any;
  action?: string;
  setShowMailBoxProviderModal: any;
}

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex pt={10} w={"100%"}>
    {children}
  </Flex>
);
const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="sub1" color="secondary" mr={20} style={{ width: 150 }}>
    {children}
  </Text>
);
const MailBoxProviderModal = ({
  onDismiss,
  provider,
  backButton,
  fetchInboxes,
  isNewCampFlow,
  showAddMailbox,
  isEditMode,
  mailId,
  editableData,
  action = "",
  setShowMailBoxProviderModal
}: MailBoxProviderModalProps) => {
  const { setLoadingMessage } = useContext(appContext);
  const { callApi } = useApi();

  const [isSettingSmtp, setIsSettingSmtp] = useState(false);
  const [isMainModal, setIsMainModal] = useState(true);
  const [form] = Form.useForm();
  const [smtpTLS, setSmtpTls] = useState(true);
  const [imapTLS, setImapTls] = useState(true);
  const [isInBoundConnection, setisInBoundConnection] = useState(false);
  const [isOutBoundConnection, setisOutBoundConnection] = useState(false);
  const [isBoundConnection, setisBoundConnection] = useState(false);
  const [smtpInBoundError, setSmtpInBoundError] = useState("");
  const [smtpOutBoundError, setSmtpOutBoundError] = useState("");
  const [validating, setValidating] = useState(false);
  // const [action, setAction] = useState('')

  // const [smtpHostPort, setsmtpHostPort] = useState({
  //   smtpHost: "192.168.121",
  //   smtpPort: "3000"
  // });

  const [isIMAPPart, setIsIMAPPart] = useState(false);
  const [sendgridWebhook, setSendgridWebhook] = useState<any>("");

  const [Data, setData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    smtpUserName: "",
    imapUserName: "",
    imapPassword: "",
    smtpPasssword: "",
    smtpHost: "",
    smtpPort: "",
    smtpTls: true,
    imapHost: "",
    imapPort: "",
    imapTls: true
  });
  let timer: any = null;
  const location = useLocation<{ addEmailState?: string }>();
  const oauthState = useMemo(() => {
    const oauthLocalState: {
      state: string;
    } | null = LocalStorage.getValue(OAUTH_STATE_LS_KEY);

    if (location.state?.addEmailState && oauthLocalState?.state && location.state?.addEmailState === oauthLocalState.state) {
      return oauthLocalState.state;
    }
    return "";
  }, [location]);
  const [mailBoxSettingsForm] = Form.useForm();

  const onSettingsFormSubmit = useCallback(() => {
    mailBoxSettingsForm.submit();
  }, [mailBoxSettingsForm]);

  const updatesmtpHostPort = useCallback(async () => {
    // let data = await fetchInboxes()
    // console.log("fet ::",data);
    if (provider === "sendgrid") {
      // todo: NC verify
      form.setFieldsValue({
        smtpHost: "smtp.sendgrid.net",
        smtpPort: "465"
      });
    } else if (provider === "amazonses") {
      form.setFieldsValue({
        smtpHost: "email-smtp.us-east-1.amazonaws.com",
        smtpPort: "465"
      });
    } else {
      form.setFieldsValue({
        smtpHost: "",
        smtpPort: ""
      });
    }
  }, [provider, form, isEditMode]);

  useEffect(() => {
    updatesmtpHostPort();
  }, [updatesmtpHostPort]);

  const handleMailboxSettingsSave = useCallback(
    async (settings: MailBoxSettingsType) => {
      console.log("1111111111111", settings);
      const { minEmails, maxEmails, incrementPercent, signature, timeZone, days, startHour, endHour, email, firstName, lastName, autoForwardEmail, replyTo } =
        settings;
      setLoadingMessage("Setting up the mailbox... ...");
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.creatSmtpMailBox();

      let payload = {};

      payload = {
        state: oauthState,
        minEmails,
        maxEmails,
        incrementPercent,
        signature,
        timeZone,
        days,
        startHour,
        endHour,
        email,
        // firstName,
        // lastName,
        // email: Data.email,
        firstname: settings.firstName ? settings.firstName : Data.firstName,
        lastname: settings.lastName ? settings.lastName : Data.lastName,
        smtp: {
          username: Data.smtpUserName,
          password: Data.smtpPasssword,
          host: Data.smtpHost,
          port: Data.smtpPort,
          tls: Data.imapTls
        },
        imap: {
          username: Data.imapUserName,
          password: Data.imapPassword,
          host: Data.imapHost,
          port: Data.imapPort,
          tls: Data.imapTls
        },
        accountId: String(localStorage.getItem("account_id")),
        protocol: getProviderAndProtocol(provider).protocol,
        provider: getProviderAndProtocol(provider).provider,
        autoForwardEmail,
        replyTo
      };

      const response = await callApi({
        url,
        method,
        data: payload
      });
      if (response.success) {
        showAddMailbox(false);
        setShowMailBoxProviderModal(false);
        setIsSettingSmtp(false);
        fetchInboxes();
      }
      setLoadingMessage("");
    },
    [Data, oauthState]
  );

  const [selectedMailbox, setSelectedMailbox] = useState<{
    email: string;
    id: null | string;
    settings?: MailBoxSettingsType;
  } | null>({
    email: "",
    id: null
  });
  const callValidateApi = async (payload: any) => {
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.smtpValidate();
    const response = await callApi({
      url,
      method,
      data: payload
    });
    setIsSettingSmtp(true);
    setIsMainModal(false);
  };

  const isSelectedMailboxNew = useMemo(() => !!oauthState, [oauthState]);

  const getOnChange = async (configurationType: string) => {
    if (configurationType === "smtpTls") {
      setSmtpTls(!smtpTLS);
    } else {
      setImapTls(!imapTLS);
    }
  };

  // const reconnectMailBox = async (smtpUserName: string = '', smtpPasssword: string = '', imapUserName: string = '', imapPassword: string = '') => {
  //   // console.log('edit', editableId);
  //   const { url, method } = API_ENDPOINTS.CAMPAIGNS.reConnectMail(mailId);
  //   let data: any = {
  //     "brooklynReconnect": true,
  //   };
  //   data['smtp'] = { host: '', port: '', username: smtpUserName, password: smtpPasssword, tls: false };
  //   data['imap'] = { host: '', port: '', username: imapUserName, password: imapPassword, tls: false };
  //   await callApi({
  //     url,
  //     method,
  //     data
  //   });
  // }

  // const callProviderSetupSuccessResponse: any = useCallback(async (requestId: string, endTime: number, userInfo: any) => {
  // if(timer) clearTimeout(timer);
  //   const { url, method } = API_ENDPOINTS.CAMPAIGNS.smtpValidateStatus(
  //     requestId
  //   );

  //   const response = await callApi({
  //     url,
  //     method,
  //     external: true,
  //   });

  //   if (response.success) {
  //     const { outbound, inbound } = response.data
  //     // inbound.status = "Succeeded"
  //     // outbound.status = "Succeeded"
  //     // if (outbound.status === "Succeeded" && inbound.status === "Succeeded") {
  //     //   outbound.status = "Failed"
  //     // }

  //     if ((outbound.status === "Succeeded" && inbound.status === "Succeeded") ||
  //       (outbound.status === "Failed" && inbound.status === "Failed")
  //       || (outbound.status === "Succeeded" && inbound.status === "Failed") ||
  //       (outbound.status === "Failed" && inbound.status === "Succeeded")
  //     ) {
  //       setValidating(true)
  //     }
  //     let taskDone = false;

  //     if (inbound.status !== "Created" && inbound.status !== "Started") {
  //       if (inbound.status === "Succeeded") {
  //         taskDone = true;
  //         setisInBoundConnection(true)
  //       } else {
  //         taskDone = true;
  //         setSmtpInBoundError(response.data.inbound.error)
  //         setisInBoundConnection(false)
  //       }
  //     }

  //     if (outbound.status !== "Created" && outbound.status !== "Started") {
  //       if (outbound.status === "Succeeded") {
  //         taskDone = true;
  //         setisOutBoundConnection(true)
  //       } else {
  //         taskDone = true;
  //         setSmtpOutBoundError(response.data.outbound.error)
  //         setisOutBoundConnection(false)
  //       }
  //     }

  //     if (inbound.status === "Succeeded" && outbound.status === "Succeeded") {
  //       // await delay(3000)
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       timer = setTimeout(async() => {
  //         if (taskDone) {
  //           setisBoundConnection(false);
  //           // setShowConnectMailboxModal(false);
  //           await reconnectMailBox(userInfo);
  //           setIsSettingSmtp(true);
  //           setSmtpInBoundError('');
  //           setSmtpOutBoundError('');
  //           return;
  //         }
  //       }, 5000)
  //     }
  //     if (inbound.status === "Created" || inbound.status === "Started"
  //       || outbound.status === "Created" || outbound.status === "Started") {
  //       let currentTime = new Date().getTime();
  //       if (endTime - currentTime > 0) {
  //         setTimeout(() => {
  //           timer = callProviderSetupSuccessResponse(requestId, endTime, userInfo)
  //         }, 5000);
  //       }
  //     }
  //   }
  // }, [action, isEditMode, callApi])

  const onFinish = async (formValues: any) => {
    const { email, firstName, lastName, imapUserName, imapPassword, smtpUserName, smtpPasssword, imapHost, imapPort, smtpHost, smtpPort } = formValues;
    console.log("formValuesformValues", formValues);
    setData({
      email: email,
      firstName: firstName,
      lastName: lastName,
      smtpUserName: smtpUserName,
      imapUserName: imapUserName,
      smtpHost: smtpHost,
      smtpPort: smtpPort,
      smtpTls: smtpTLS,
      imapPassword: imapPassword,
      smtpPasssword: smtpPasssword,
      imapHost: imapHost,
      imapPort: imapPort,
      imapTls: imapTLS
    });
    // setLoadingMessage("Validating Your Mailbox ...");
    setisBoundConnection(true);

    setLoadingMessage("");
  };

  useEffect(() => {
    if (!isBoundConnection) {
      setValidating(false);
      setSmtpInBoundError("");
      setisInBoundConnection(false);
      setisOutBoundConnection(false);
      setSmtpOutBoundError("");
      console.log("action 4", action);
      if (action === "UPDATE" || action === "VALIDATE" || action === "RECONNECT") {
        setIsSettingSmtp(false);
      }
    }
  }, [isBoundConnection]);

  const toggleMailboxValidating = () => {
    setisBoundConnection((prev) => !prev);
    backButton();
    setValidating(false);
    setSmtpInBoundError("");
    setSmtpOutBoundError("");
    setisInBoundConnection(false);
    setisOutBoundConnection(false);
  };

  const onCloseSettingsModal = () => setIsSettingSmtp(false);

  useEffect(() => {
    const isIMAPEnable =
      MailboxTypeEnum.SENDGRID === getProviderDetails(provider)?.type ||
      MailboxTypeEnum.MAIL_GUN === getProviderDetails(provider)?.type ||
      MailboxTypeEnum.TECHCOMPILER === getProviderDetails(provider)?.type;
    setIsIMAPPart(isIMAPEnable);
  }, [provider]);
  const provideName = getProviderDetails(provider)?.providerName;
  const protocol = getProviderDetails(provider)?.protocol;

  useEffect(() => {
    if (isEditMode) {
      const config: any = { smtpUserName: "", smtpPasssword: "" };
      if (isIMAPPart) {
        // config['imapUserName'] = editableData?.email;
        // config['imapPassword'] = '';
      }
      form.setFieldsValue(config);
    }
  }, [form, editableData, isEditMode, isIMAPPart]);

  useEffect(() => {
    if (provideName === "SENDGRID") {
      const config: any = { smtpUserName: "apikey", smtpPasssword: "" };
      form.setFieldsValue(config);
    }
  }, [form, provideName]);

  console.log("provider", provider);
  console.log("editableData", editableData, isIMAPPart);
  // if(isEditMode) form.setFieldsValue(editableData);
  const handleFormValuesChange = (data: any) => {
    const currOptiosnObj = currOptions.find((vl: any) => vl?.id === data?.mailType);
    if (currOptiosnObj) {
      form.setFieldsValue({ imapPort: currOptiosnObj?.port, imapHost: currOptiosnObj?.host });
    }
    if ((data?.mailType === "sendGridWebhook" || data?.mailType === "MailGunWebhook") && currOptiosnObj) {
      setSendgridWebhook(currOptiosnObj?.htmlData);
    } else if ("mailType" in data && data?.mailType !== "sendGridWebhook") {
      setSendgridWebhook("");
    }
  };

  const sendGridOptions: any = [
    { id: "gmail", label: "Gmail", host: "imap.gmail.com", port: "993" },
    { id: "outlook", label: "Outlook", host: "outlook.office365.com", port: "993" },
    { id: "custom", label: "Custom IMAP", host: "", port: "" },
    {
      id: "sendGridWebhook",
      label: "SendGrid Webhook",
      host: "",
      port: "",
      htmlData: (
        <Div>
          <Text variant="caption" mb={10}>
            SendGrid webhook allows Outwin to fetch inbound emails directly from SendGrid using API calls. You don't have to procure any third party (eg: google
            workspace) mailboxes. You must perform the following configuration changes:
          </Text>
          <Text variant="caption" mb={10}>
            <b>Step1:</b> Setup the following MX record on your domain provider website (eg: godaddy)
            <br />
            10 mx.sendgrid.net{" "}
          </Text>
          <Text variant="caption" mb={10}>
            {" "}
            <b>Step2:</b> Setup the following web hook URL on SendGrid website. <br /> https://whisper.outwin.io/inbound/sendgrid/mime
          </Text>

          <Text variant="caption">
            {" "}
            For more details, <a href="#">check out this video.</a>{" "}
          </Text>
        </Div>
      )
    }
  ];
  const mailGridOptions = [
    { id: "gmail", label: "Gmail", host: "imap.gmail.com", port: "993" },
    { id: "outlook", label: "Outlook", host: "outlook.office365.com", port: "993" },
    { id: "custom", label: "Custom IMAP", host: "", port: "" },
    {
      id: "MailGunWebhook",
      label: "MailGun Webhook",
      host: "",
      port: "",
      htmlData: (
        <Div>
          <Text variant="caption" mb={10}>
            MailGun webhook allows Outwin to fetch inbound emails directly from MailGun using API calls. You don't have to procure any third party (eg: google
            workspace) mailboxes. You must perform the following configuration changes:
          </Text>
          <Text variant="caption" mb={10}>
            <b>Step1:</b> Setup the following MX record on your domain provider website (eg: godaddy)
            <br />
            10 mxa.mailgun.org <br /> 20 mxb.mailgun.org{" "}
          </Text>
          <Text variant="caption" mb={10}>
            {" "}
            <b>Step2:</b> Setup the following web hook URL on MailGun website. <br /> https://whisper.outwin.io/inbound/mailgun/mime
          </Text>
          <Text variant="caption">
            {" "}
            For more details, <a href="#">check out this video.</a>{" "}
          </Text>
        </Div>
      )
    }
  ];

  const customOptions = [
    { id: "gmail", label: "Gmail", host: "imap.gmail.com", port: "993" },
    { id: "outlook", label: "Outlook", host: "outlook.office365.com", port: "993" },
    { id: "custom", label: "Custom IMAP", host: "", port: "" }
  ];

  const currOptions = provideName === "SENDGRID" ? sendGridOptions : provideName === "CUSTOM" ? customOptions : mailGridOptions;

  return (
    <Div>
      {isMainModal && (
        <Modal visible width={600} centered onCancel={onDismiss} bodyStyle={{ padding: 30 }} footer={null}>
          <Form
            scrollToFirstError
            onFinish={onFinish}
            form={form}
            requiredMark={false}
            initialValues={isEditMode ? editableData : {}}
            onValuesChange={handleFormValuesChange}
          >
            <Text variant="h6" mb={15} mt={5} color={"secondary"}>
              {getProviderDetails(provider)?.providerName + " Setup"}
              {/* {provider === 'amazonses' ? 'Amazon Setup' : (provider === 'sendgrid' ?  'SendGrid Setup' : 'TechCompiler Setup')} */}
              {/* <br />
              {
                isEditMode ?
                  <Label>email:{editableData.email}</Label>
                  : null
              } */}
            </Text>
            <Flex direction="column" mb={10}>
              <Flex className="setup-email">
                <DetailWrapper>
                  <DetailHeading>Email *</DetailHeading>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        type: "email",
                        message: "Please enter valid email"
                      },
                      {
                        required: true,
                        message: "Please enter email"
                      }
                    ]}
                    initialValue={isEditMode ? editableData.email : ""}
                    wrapperCol={{ sm: 24 }}
                    style={{ width: "100%", marginRight: 0 }}
                  >
                    <Input tabIndex={1} className={classes.inputStyle} size="middle" style={{ width: "100%" }} />
                  </Form.Item>
                </DetailWrapper>
              </Flex>
              {/* <Flex className="setup-user">
                <DetailWrapper>
                  <DetailHeading>First Name *</DetailHeading>
                  <Form.Item
                    name={"firstName"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                    ]}
                    initialValue={isEditMode ? editableData.firstName : ""}
                  >
                    <Input tabIndex={2}
                      className={classes.inputStyle} size="small"
                    />
                  </Form.Item>
                </DetailWrapper>
                <DetailWrapper>
                  <DetailHeading>Last Name *</DetailHeading>
                  <Form.Item
                    name={"lastName"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Last name",
                      },
                    ]}
                    initialValue={isEditMode ? editableData.lastName : ""}
                  >
                    <Input tabIndex={3}
                      className={classes.inputStyle} size="small"
                    />
                  </Form.Item>
                </DetailWrapper>
              </Flex> */}
            </Flex>
            <Flex direction="column" mb={10}>
              <Flex className="configuration-title">
                <DetailWrapper>
                  <DetailHeading>Outbound Setting</DetailHeading>
                </DetailWrapper>
                {/* <DetailWrapper>
                  <DetailHeading>IMAP Configuration</DetailHeading>
                </DetailWrapper> */}
              </Flex>
            </Flex>
            <Flex direction="column" mb={10}>
              <Flex className="configuration-form">
                <DetailWrapper>
                  <DetailHeading>SMTP UserName *</DetailHeading>
                  <Form.Item
                    name={"smtpUserName"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter SMTP User Name"
                      }
                    ]}
                    // initialValue={isEditMode ? editableData.smtpUserName : ""}
                  >
                    <Input tabIndex={1} className={classes.inputStyle} size="small" />
                  </Form.Item>
                </DetailWrapper>

                <DetailWrapper>
                  <DetailHeading>SMTP Password *</DetailHeading>
                  <Form.Item
                    name={"smtpPasssword"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter SMTP password"
                      }
                    ]}
                    // initialValue={isEditMode ? editableData.smtpPasssword : ""}
                  >
                    <Input.Password tabIndex={2} autoComplete="new-password" className={classes.inputStyle} size="small" />
                  </Form.Item>
                </DetailWrapper>
                {/* <DetailWrapper>
                  <DetailHeading>IMAP Password *</DetailHeading>
                  <Form.Item
                    name={"imapPassword"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter IMAP Password",
                      },
                    ]}
                  >
                    <Input.Password tabIndex={10}
                      className={classes.inputStyle} size="small"
                    />
                  </Form.Item>
                </DetailWrapper> */}
                {provider === "SMTP" ? (
                  <Flex
                    direction="column"
                    mb={10}
                    style={{
                      width: "100%",
                      flex: "0 0 100%"
                    }}
                  >
                    <Flex className="configuration-form" align="end">
                      <Flex direction="row" style={{ flexDirection: "row", width: "100%", flex: "0 0 100%" }}>
                        <Flex style={{ borderBottom: `1px solid ${LIGHT_GRAY_COLOR}` }} direction="column">
                          <DetailHeading>Host *</DetailHeading>
                          <Form.Item
                            name={"smtpHost"}
                            rules={[
                              {
                                required: true,
                                message: "Please enter SMTP Host"
                              }
                            ]}
                            initialValue=""
                            style={{ marginBottom: "0" }}
                          >
                            <Input tabIndex={11} className={classes.inputStyle} size="small" />
                          </Form.Item>
                        </Flex>
                        <Flex style={{ borderBottom: `1px solid ${LIGHT_GRAY_COLOR}` }} ml={15} w={"60px"} direction="column">
                          <DetailHeading>Port *</DetailHeading>
                          <Form.Item
                            name={"smtpPort"}
                            rules={[
                              {
                                required: true,
                                message: "Please enter SMTP Port"
                              }
                            ]}
                            initialValue=""
                            style={{ marginBottom: "0" }}
                          >
                            <Input tabIndex={12} className={classes.inputStyle} size="small" />
                          </Form.Item>
                        </Flex>
                        <DetailWrapper>
                          <Div style={{ marginLeft: "40px", alignSelf: "center", paddingTop: "10px" }}>
                            <Checkbox tabIndex={8} checked={smtpTLS} onChange={() => getOnChange("smtpTls")}>
                              Use SMTP SSL/TLS
                            </Checkbox>
                          </Div>
                        </DetailWrapper>
                      </Flex>
                    </Flex>
                  </Flex>
                ) : null}
              </Flex>
              <Flex direction="column" mb={10}>
                <Flex className="">
                  <DetailWrapper>
                    <Text variant="sub1" color="secondary" className={classes.inboundSettingHeading}>
                      Inbound Setting
                    </Text>
                    <Form.Item name="mailType" rules={[{ required: true, message: "Required" }]} initialValue="custom" className={classes.inboundSettingSelect}>
                      <Select style={{ width: "100%", marginBottom: 0 }} placeholder="Select mail type">
                        {currOptions?.map((pr: any) => (
                          <Option key={pr.id} value={pr.id}>
                            <Div>
                              <Text variant="sub2">{pr.label}</Text>
                            </Div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </DetailWrapper>
                  {/* <DetailWrapper>
                  <DetailHeading>IMAP Configuration</DetailHeading>
                </DetailWrapper> */}
                </Flex>
              </Flex>
              {!sendgridWebhook ? (
                <>
                  <Flex direction="column" mb={10}>
                    <Flex className="configuration-form">
                      <DetailWrapper>
                        <DetailHeading>IMAP UserName *</DetailHeading>
                        <Form.Item
                          name={"imapUserName"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter IMAP UserName"
                            }
                          ]}
                          initialValue={isEditMode ? editableData.imapUserName : ""}
                        >
                          <Input tabIndex={3} className={classes.inputStyle} size="small" />
                        </Form.Item>
                      </DetailWrapper>
                      <DetailWrapper>
                        <DetailHeading>IMAP Password *</DetailHeading>
                        <Form.Item
                          name={"imapPassword"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter IMAP Password"
                            }
                          ]}
                          // initialValue={isEditMode ? editableData.imapPassword : ""}
                        >
                          <Input.Password tabIndex={4} className={classes.inputStyle} size="small" />
                        </Form.Item>
                      </DetailWrapper>
                    </Flex>
                  </Flex>
                  <Flex direction="column" mb={10}>
                    <Flex className="configuration-form" align="end">
                      <Flex direction="row" style={{ flexDirection: "row" }}>
                        <Flex style={{ borderBottom: `1px solid ${LIGHT_GRAY_COLOR}` }} direction="column">
                          <DetailHeading>Host *</DetailHeading>
                          <Form.Item
                            name={"imapHost"}
                            rules={[
                              {
                                required: true,
                                message: "Please enter IMAP Host"
                              }
                            ]}
                            initialValue=""
                            style={{ marginBottom: "0" }}
                          >
                            <Input tabIndex={11} className={classes.inputStyle} size="small" />
                          </Form.Item>
                        </Flex>
                        <Flex style={{ borderBottom: `1px solid ${LIGHT_GRAY_COLOR}` }} ml={15} w={"60px"} direction="column">
                          <DetailHeading>Port *</DetailHeading>
                          <Form.Item
                            name={"imapPort"}
                            rules={[
                              {
                                required: true,
                                message: "Please enter IMAP Port"
                              }
                            ]}
                            initialValue=""
                            style={{ marginBottom: "0" }}
                          >
                            <Input tabIndex={12} className={classes.inputStyle} size="small" />
                          </Form.Item>
                        </Flex>
                      </Flex>

                      <DetailWrapper>
                        <Div>
                          <Checkbox tabIndex={13} checked={imapTLS} onChange={() => getOnChange("imapTls")}>
                            Use IMAP SSL/TLS
                          </Checkbox>
                        </Div>
                      </DetailWrapper>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <Flex direction="column" mb={10}>
                  <div>{sendgridWebhook}</div>
                </Flex>
              )}
            </Flex>
            {/* {
              isIMAPPart ? null : (
                <>
                  <Flex direction="column" mb={10}>
                    <Flex className="configuration-title">
                      <DetailWrapper>
                        <DetailHeading>IMAP Configuration</DetailHeading>
                      </DetailWrapper>

                    </Flex>
                  </Flex>
                  <Flex direction="column" mb={10}>
                    <Flex className="configuration-form">
                      <DetailWrapper>
                        <DetailHeading>IMAP UserName *</DetailHeading>
                        <Form.Item
                          name={"imapUserName"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter IMAP UserName",
                            },
                          ]}

                        >
                          <Input disabled={isEditMode} tabIndex={3}
                            className={classes.inputStyle} size="small"
                          />
                        </Form.Item>
                      </DetailWrapper>
                      <DetailWrapper>
                        <DetailHeading>IMAP Password *</DetailHeading>
                        <Form.Item
                          name={"imapPassword"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter IMAP Password",
                            },
                          ]}
                        >
                          <Input.Password tabIndex={4}
                            className={classes.inputStyle} size="small"
                          />
                        </Form.Item>
                      </DetailWrapper>
                    </Flex>
                  </Flex>
                </>)} */}
            <Flex direction="row" ml={10} justify="flex-end">
              <Flex>
                <Button tabIndex={14} onClick={() => backButton(true)}>
                  Back
                </Button>
              </Flex>
              <Flex ml={10}>
                <Button tabIndex={15} htmlType="submit" type="primary">
                  Validate
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      )}
      {isSettingSmtp && (
        <Modal visible footer={null} centered className="modal-mainwrap" onCancel={onCloseSettingsModal}>
          <MailBoxSettings
            onSave={handleMailboxSettingsSave}
            form={mailBoxSettingsForm}
            email={Data?.email}
            settings={selectedMailbox?.settings}
            provider={provider}
            protocol={protocol}
          />
          <Flex mt={20} justify="flex-end">
            <Button type="primary" onClick={onSettingsFormSubmit}>
              Save
            </Button>
          </Flex>
        </Modal>
      )}

      {isBoundConnection && (
        <BoundConnectionModal
          isEnable={isBoundConnection}
          toggleMailboxValidating={toggleMailboxValidating}
          validating={validating}
          isInBoundConnection={isInBoundConnection}
          smtpInBoundError={smtpInBoundError}
          isOutBoundConnection={isOutBoundConnection}
          smtpOutBoundError={smtpOutBoundError}
          editableId={mailId}
          providers={provider}
          setValidating={setValidating}
          setisInBoundConnection={setisInBoundConnection}
          setSmtpInBoundError={setSmtpInBoundError}
          setisOutBoundConnection={setisOutBoundConnection}
          setSmtpOutBoundError={setSmtpOutBoundError}
          isEditMode={isEditMode}
          action={action}
          resetMailboxeSettings={() => {
            setisBoundConnection(false);
            setSmtpInBoundError("");
            setSmtpOutBoundError("");
            setisInBoundConnection(false);
            setisOutBoundConnection(false);
            setValidating(false);
          }}
          setIsSettingSmtp={setIsSettingSmtp}
          settingEditView={false}
          setSelectedMailbox={setSelectedMailbox}
          fromValue={{
            email: Data?.smtpUserName,
            password: Data?.smtpPasssword,
            imapUsername: Data?.imapUserName,
            imapPassword: Data?.imapPassword,
            imapPort: Data?.imapPort,
            imapHost: Data?.imapHost,
            inboxEmail: Data?.email,
            smtpHost: Data?.smtpHost,
            smtpPort: Data?.smtpPort,
            smtpTLS: Data?.smtpTls,
            imapTLS: Data?.imapTls
          }}
          setLoadingMessage={setLoadingMessage}
        />
      )}
    </Div>
  );
};
export default MailBoxProviderModal;
