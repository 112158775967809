export const FETCH_SPIN_TYPES = "FETCH_SPIN_TYPES";
export const FETCH_SPIN_TYPES_SUCCESS = "FETCH_SPIN_TYPES_SUCCESS";
export const FETCH_SPIN_TYPES_FAILURE = "FETCH_SPIN_TYPES_FAILURE";

export const FETCH_CAMPAIGN_VARIABLES = "FETCH_CAMPAIGN_VARIABLES";
export const FETCH_CAMPAIGN_VARIABLES_SUCCESS =
  "FETCH_CAMPAIGN_VARIABLES_SUCCESS";
export const FETCH_CAMPAIGN_VARIABLES_FAILURE =
  "FETCH_CAMPAIGN_VARIABLES_FAILURE";

export const FETCH_CAMPAIGN_TOUCHES = "FETCH_CAMPAIGN_TOUCHES";
export const FETCH_CAMPAIGN_TOUCHES_SUCCESS = "FETCH_CAMPAIGN_TOUCHES_SUCCESS";
export const FETCH_CAMPAIGN_TOUCHES_FAILURE = "FETCH_CAMPAIGN_TOUCHES_FAILURE";

export const FETCH_CAMPAIGN_PROSPECTS = "FETCH_CAMPAIGN_PROSPECTS";
export const FETCH_CAMPAIGN_PROSPECTS_SUCCESS =
  "FETCH_CAMPAIGN_PROSPECTS_SUCCESS";
export const FETCH_CAMPAIGN_PROSPECTS_FAILURE =
  "FETCH_CAMPAIGN_PROSPECTS_FAILURE";

export const FETCH_SEQUENCES = "FETCH_SEQUENCES";
export const FETCH_SEQUENCES_SUCCESS = "FETCH_SEQUENCES_SUCCESS";
export const FETCH_SEQUENCES_FAILURE = "FETCH_SEQUENCES_FAILURE";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";

export const FETCH_CAMPAIGN_PROSPECTS_VARIABLES = "FETCH_CAMPAIGN_PROSPECTS_VARIABLES";
export const FETCH_CAMPAIGN_PROSPECTS_VARIABLES_SUCCESS =
  "FETCH_CAMPAIGN_PROSPECTS_VARIABLES_SUCCESS";
export const FETCH_CAMPAIGN_PROSPECTS_VARIABLES_FAILURE =
  "FETCH_CAMPAIGN_PROSPECTS_VARIABLES_FAILURE";

export const FETCH_TIMEZONES = "FETCH_TIMEZONES";
export const FETCH_TIMEZONES_SUCCESS = "FETCH_TIMEZONES_SUCCESS";
export const FETCH_TIMEZONES_FAILURE = "FETCH_TIMEZONES_FAILURE";

export const FETCH_CAMPAIGN_DETAILS_SUCCESS = "FETCH_CAMPAIGN_DETAILS_SUCCESS";
