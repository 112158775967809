import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import Axios from "utils/axios";
import { TimeZoneType } from "utils/dateTime";
import { DURATION_IN_MS } from "constants/time";

class TimeZones {
    static getTimezones = async () => {
        let TIMEZONES: TimeZoneType[] = [];
        let localTimezones = JSON.parse(localStorage.getItem('timezones') as string);

        /* temporary code for making the same changes over the system */
        if (localTimezones && localTimezones.length && localTimezones[0].hasOwnProperty('tzCode')) {
            localStorage.removeItem('timezones')
            localTimezones = ''
        }

        if (!localTimezones) {
            const { url, method } = API_ENDPOINTS.CAMPAIGNS.getTimeZonesUrl();
            const response = await Axios.callApi({
                url,
                method,
            });

            if (response.success) {
                localStorage.setItem('timezones', JSON.stringify(response.data.timezones));
                setInterval(() => { localStorage.removeItem('timezones') }, 60000);
            }
        }
        localTimezones = JSON.parse(localStorage.getItem('timezones') as string);
        localTimezones && localTimezones.map((data: any) => {
            TIMEZONES.push({ tzCode: data.name, label: data.label });
        });
        return TIMEZONES;
    };
};

export default TimeZones;