import { UserOutlined } from "@ant-design/icons";
import { Col, Form, Input, Modal, notification, Row } from "antd";
import { useAuth } from "auth/AuthProvider";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { useContext, useEffect, useState } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { CampaignSummaryType, CampaingStatusEnum } from "types/Campaign";

interface AddToCampaignDialogProps {
    companyName: string[];
    downloadContactsLimit: number;
    onOk: () => void;
    onAddToCampaignDone?: (campaignName: string) => void;
    onCancel: () => void;
    domains: string[];
    departments: string[];
    levels: string[];
    industry: string[];
    subIndustry: string[];
    city: string[];
    state: string[];
    country: string[];
    revenue: string[];
    employees: string[];
    title: string[];
    titleExactMatch: boolean;
    deliveryScore: number;

}

const AddToCampaignDialog = ({
    companyName,
    onAddToCampaignDone,
    onOk,
    onCancel,
    downloadContactsLimit,
    departments,
    levels,
    industry,
    subIndustry,
    city,
    state,
    country,
    revenue,
    employees,
    title,
    titleExactMatch,
    deliveryScore,
    domains }: AddToCampaignDialogProps) => {

    const { setLoadingMessage } = useContext(appContext);
    const { callApi } = useApi();
    const [form] = Form.useForm();
    const [selectedCampaignIndex, setSelectedCampaignIndex] = useState()
    const [selectedCampaignId, setSelectedCampaignId] = useState('')
    const [selected, setSelected] = useState(false)
    const [contactPerCompany, setContactPerCompany] = useState('')
    const { theme } = useAuth();
    

    const hadleCampaignClick = (e: any, i: any, campaignId: string, campaignName: string) => {
        setSelectedCampaignIndex(i);
        setSelectedCampaignId(campaignId);
        setSelected(true);
        onAddToCampaignDone && onAddToCampaignDone(campaignName);
    }

    const validateNumber = (e: any) => {
        const re = /^([1-9]|1[0123456789]|2[0123456789]|3[0123456789]|4[0123456789]|5[0])$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setContactPerCompany(e.target.value)
        }
    }

    const [campaignData, setCampaignData] = useState<CampaignSummaryType[]>([]);

    const fetchCampaigns = (async () => {
        let account_id = String(localStorage.getItem("account_id"));
        setLoadingMessage("Fetching campaigns ...");
        const { url, method } = API_ENDPOINTS.ACCOUNTS.getDetailUrlByFilter(account_id, "Active,Inactive");
        const response = await callApi({
            url,
            method
        });
        if (response.success) {
            setCampaignData(response.data.campaigns);
        }
        setLoadingMessage("");
    });

    useEffect(() => {
        fetchCampaigns();
    }, []);

    const postAddToCampaign = async () => {

        if (selected) {
            setLoadingMessage("Adding To Campaign ...");
            const { url, method } = API_ENDPOINTS.CONTACT_SEARCH.adaptSearch();
            const response = await callApi({
                url,
                method,
                data: getPayload(contactPerCompany)
            });
            if (response.success) {
                onOk();
            }
            setLoadingMessage("");
        }
        else
            notification.error({ message: "Please select a campaign." });
    };

    const getPayload = (contactsPerCompany: any) => {
        if (contactsPerCompany === '') {
            contactsPerCompany = '0';
        }
        const data = {
            "action": "ADD",
            "adaptEntity": "CONTACT",
            "domains": domains,
            "companyName": companyName,
            "departments": departments,
            "levels": levels,
            "industry": industry,
            "subIndustry": subIndustry,
            "city": city,
            "state": state,
            "country": country,
            "revenue": revenue,
            "employees": employees,
            "title": title,
            "titleExactMatch": titleExactMatch,
            "deliveryScore": deliveryScore
        }
        return data;
    }

    const getStatusColor = (status: string) => (status === CampaingStatusEnum.ACTIVE ? "success" : "error");


    return (
        <Modal
            visible
            centered
            onOk={postAddToCampaign}
            onCancel={onCancel}
            okText="Add"
            className="addCampaign-modal"
        >
            <Div>
                <Text variant="h6" block mb={20}>
                    Select Campaign
                </Text>
                <Form
                    form={form}
                    name="addtocampaign"
                    scrollToFirstError
                >
                    <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                        Contacts per Company (1-50)
                    </Text>
                    <Form.Item>
                        <Input value={contactPerCompany} className="mt-2" style={{ width: '34%' }} onChange={(e) => validateNumber(e)} />
                    </Form.Item>
                    <Row gutter={[16, 16]} style={{ height: '60vh', overflow: 'auto' }}>
                        {
                            campaignData.map((item, i) => {
                                return (
                                    <Col span={8}>

                                        <Flex p="10px 10px" direction="column"
                                            onClick={(e) => hadleCampaignClick(e, i, item.campId, item.name)}
                                            borderColor={i == selectedCampaignIndex ? theme.antdConf.primaryColor : theme.headerColor}
                                            hoverBorderColor={theme.antdConf.primaryColor}
                                            cursor={"pointer"}
                                            bgColor={theme.headerColor}
                                        >
                                            <Text variant="body1" block mb={20} ellipsis title={item.name}>
                                                {item.name}
                                            </Text>
                                            <Text variant="caption" bold block>
                                                {item.activeRecipients} <UserOutlined />
                                            </Text>
                                            <Text variant="caption" uppercase color={getStatusColor(item.status.toUpperCase())} bold>
                                                {item.status}
                                            </Text>
                                        </Flex>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Form>
            </Div>
        </Modal>
    );
};

export default AddToCampaignDialog;
