import React from "react";
import { MailboxTypeEnum } from "types/MailBox";
import { ReactComponent as GmailSvg } from "images/gmail.svg";
import { ReactComponent as OfficeSvg } from "images/office.svg";
import { ReactComponent as AmazonSvg } from "images/amazon.svg";
import { ReactComponent as SendGridSvg } from "images/sendgrid.svg";
import { ReactComponent as TechCompilerSvg } from "images/techcompile.svg";
import { ReactComponent as MailGunSvg } from "images/mailgun.svg";
import Icon, { MailOutlined } from "@ant-design/icons";

const GmailIcon = (props: any) => <Icon component={GmailSvg} {...props} style={{ fontSize: "30px" }} />;
const AmazonIcon = (props: any) => <Icon component={AmazonSvg} {...props} style={{ fontSize: "30px" }} />;
const SendGridIcon = (props: any) => <Icon component={SendGridSvg} {...props} style={{ fontSize: "30px" }} />;

const TechComplierIcon = (props: any) => <Icon component={TechCompilerSvg} {...props} style={{ fontSize: "30px" }} />;

const OfficeIcon = (props: any) => <Icon component={OfficeSvg} {...props} style={{ fontSize: "30px" }} />;

const MailGunIcon = (props: any) => <Icon component={MailGunSvg} {...props} style={{ fontSize: "30px" }} />;

const OtherMailIcon = () => <MailOutlined style={{ fontSize: "30px" }} />;

export interface MailBoxIconProps {
  type: MailboxTypeEnum;
}
const MailBoxIcon = ({ type }: MailBoxIconProps) => {
  return (
    <>
      {type === MailboxTypeEnum.GMAIL || type === MailboxTypeEnum.DUMMY ? (
        <GmailIcon />
      ) : type === MailboxTypeEnum.OFFICE_OAUTH ? (
        <OfficeIcon />
      ) : type === MailboxTypeEnum.GMAILONE ? (
        <GmailIcon />
      ) : type === MailboxTypeEnum.AMAZON ? (
        <AmazonIcon />
      ) : type === MailboxTypeEnum.SENDGRID ? (
        <SendGridIcon />
      ) : type === MailboxTypeEnum.TECHCOMPILER ? (
        <TechComplierIcon />
      ) : type === MailboxTypeEnum.OFFICE_BROWSER ? (
        <OfficeIcon />
      ) : type === MailboxTypeEnum.MAIL_GUN ? (
        <MailGunIcon />
      ) : type === MailboxTypeEnum.OFFICE_SMTP ? (
        <OfficeIcon />
      ) : type === MailboxTypeEnum.GSUITE ? (
        <GmailIcon />
      ) : (
        <OtherMailIcon />
      )}
    </>
  );
};

export default MailBoxIcon;