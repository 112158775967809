import { Tag } from 'antd'
import React from 'react'
import { Div } from 'styles/layout'

interface DomainModalrops {
    adaptComapnies: any,
    uploadFileDomains: any
}

const DomainModal = ({ uploadFileDomains, adaptComapnies }: DomainModalrops) => {
    return (
        <>
            <Div>
                {uploadFileDomains.map((domains: any) => {
                    return <Tag style={{ marginTop: '5px' }}>{domains}</Tag>
                })}
            </Div>
        </>
    )
}

export default DomainModal