
export const defaultIndustries = [
    { id: '1', name: 'Agriculture & Mining' },
    { id: '2', name: 'Business Services' },
    { id: '3', name: 'Computers & Electronics' },
    { id: '4', name: 'Consumer Services' },
    { id: '5', name: 'Education' },
    { id: '6', name: 'Energy & Utilities' },
    { id: '7', name: 'Financial Services' },
    { id: '8', name: 'Government' },
    { id: '9', name: 'Healthcare, Pharmaceuticals, & Biotech' },
    { id: '10', name: 'Manufacturing' },
    { id: '11', name: 'Media & Entertainment' },
    { id: '12', name: 'Non-Profit' },
    { id: '13', name: 'Other' },
    { id: '14', name: 'Real Estate & Construction' },
    { id: '15', name: 'Retail' },
    { id: '16', name: 'Software & Internet' },
    { id: '17', name: 'Telecommunications' },
    { id: '18', name: 'Transportation & Storage' },
    { id: '19', name: 'Travel, Recreation, and Leisure' },
    { id: '20', name: 'Wholesale & Distribution' }
]

export const subIndustries = [
    { industryName: 'Agriculture & Mining', name: 'Dairy' },
    { industryName: 'Agriculture & Mining', name: 'Farming' },
    { industryName: 'Agriculture & Mining', name: 'Fishery' },
    { industryName: 'Agriculture & Mining', name: 'Food & Beverages' },
    { industryName: 'Agriculture & Mining', name: 'Food Production' },
    { industryName: 'Agriculture & Mining', name: 'Mining & Metals' },
    { industryName: 'Agriculture & Mining', name: 'Paper & Forest Products' },
    { industryName: 'Agriculture & Mining', name: 'Ranching' },
    { industryName: 'Agriculture & Mining', name: 'Tobacco' },
    { industryName: 'Business Services', name: 'Alternative Dispute Resolution' },
    { industryName: 'Business Services', name: 'Animation' },
    { industryName: 'Business Services', name: 'Business Supplies and Equipment' },
    { industryName: 'Business Services', name: 'Design' },
    { industryName: 'Business Services', name: 'Environmental Services' },
    { industryName: 'Business Services', name: 'Events Services' },
    { industryName: 'Business Services', name: 'Executive Office' },
    { industryName: 'Business Services', name: 'Facilities Services' },
    { industryName: 'Business Services', name: 'Fund-Raising' },
    { industryName: 'Business Services', name: 'Graphic Design' },
    { industryName: 'Business Services', name: 'Human Resources' },
    { industryName: 'Business Services', name: 'Import and Export' },
    { industryName: 'Business Services', name: 'Individual & Family Services' },
    { industryName: 'Business Services', name: 'Information Services' },
    { industryName: 'Business Services', name: 'International Trade and Development' },
    { industryName: 'Business Services', name: 'Law Practice' },
    { industryName: 'Business Services', name: 'Legal Services' },
    { industryName: 'Business Services', name: 'Management Consulting' },
    { industryName: 'Business Services', name: 'Market Research' },
    { industryName: 'Business Services', name: 'Marketing and Advertising' },
    { industryName: 'Business Services', name: 'Outsourcing/Offshoring' },
    { industryName: 'Business Services', name: 'Professional Training & Coaching' },
    { industryName: 'Business Services', name: 'Program Development' },
    { industryName: 'Business Services', name: 'Public Relations and Communications' },
    { industryName: 'Business Services', name: 'Public Safety' },
    { industryName: 'Business Services', name: 'Security and Investigations' },
    { industryName: 'Business Services', name: 'Staffing and Recruiting' },
    { industryName: 'Business Services', name: 'Think Tanks' },
    { industryName: 'Business Services', name: 'Translation and Localization' },
    { industryName: 'Business Services', name: 'Writing and Editing' },
    { industryName: 'Computers & Electronics', name: 'Computer Games' },
    { industryName: 'Computers & Electronics', name: 'Computer Hardware' },
    { industryName: 'Computers & Electronics', name: 'Computer Networking' },
    { industryName: 'Computers & Electronics', name: 'Consumer Electronics' },
    { industryName: 'Computers & Electronics', name: 'Semiconductors' },
    { industryName: 'Consumer Services', name: 'Consumer Goods' },
    { industryName: 'Consumer Services', name: 'Consumer Services' },
    { industryName: 'Education', name: 'Education Management' },
    { industryName: 'Education', name: 'E-Learning' },
    { industryName: 'Education', name: 'Higher Education' },
    { industryName: 'Education', name: 'Primary/Secondary Education' },
    { industryName: 'Education', name: 'Research' },
    { industryName: 'Energy & Utilities', name: 'Oil & Energy' },
    { industryName: 'Energy & Utilities', name: 'Renewables & Environment' },
    { industryName: 'Energy & Utilities', name: 'Utilities' },
    { industryName: 'Financial Services', name: 'Accounting' },
    { industryName: 'Financial Services', name: 'Banking' },
    { industryName: 'Financial Services', name: 'Capital Markets' },
    { industryName: 'Financial Services', name: 'Financial Services' },
    { industryName: 'Financial Services', name: 'Insurance' },
    { industryName: 'Financial Services', name: 'Investment Banking' },
    { industryName: 'Financial Services', name: 'Investment Management' },
    { industryName: 'Financial Services', name: 'Venture Capital & Private Equity' },
    { industryName: 'Government', name: 'Defense & Space' },
    { industryName: 'Government', name: 'Government Administration' },
    { industryName: 'Government', name: 'Government Relations' },
    { industryName: 'Government', name: 'International Affairs' },
    { industryName: 'Government', name: 'Judiciary' },
    { industryName: 'Government', name: 'Law Enforcement' },
    { industryName: 'Government', name: 'Legislative Office' },
    { industryName: 'Government', name: 'Military' },
    { industryName: 'Government', name: 'Museums and Institutions' },
    { industryName: 'Government', name: 'Public Policy' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Alternative Medicine' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Biotechnology' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Health, Wellness and Fitness' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Hospital & Health Care' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Medical Devices' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Medical Practice' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Mental Health Care' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Pharmaceuticals' },
    { industryName: 'Healthcare, Pharmaceuticals, & Biotech', name: 'Veterinary' },
    { industryName: 'Manufacturing', name: 'Automotive' },
    { industryName: 'Manufacturing', name: 'Aviation & Aerospace' },
    { industryName: 'Manufacturing', name: 'Chemicals' },
    { industryName: 'Manufacturing', name: 'Electrical/Electronic Manufacturing' },
    { industryName: 'Manufacturing', name: 'Furniture' },
    { industryName: 'Manufacturing', name: 'Industrial Automation' },
    { industryName: 'Manufacturing', name: 'Machinery' },
    { industryName: 'Manufacturing', name: 'Mechanical or Industrial Engineering' },
    { industryName: 'Manufacturing', name: 'Plastics' },
    { industryName: 'Manufacturing', name: 'Railroad Manufacture' },
    { industryName: 'Manufacturing', name: 'Shipbuilding' },
    { industryName: 'Manufacturing', name: 'Textiles' },
    { industryName: 'Media & Entertainment', name: 'Broadcast Media' },
    { industryName: 'Media & Entertainment', name: 'Media Production' },
    { industryName: 'Media & Entertainment', name: 'Motion Pictures and Film' },
    { industryName: 'Media & Entertainment', name: 'Music' },
    { industryName: 'Media & Entertainment', name: 'Newspapers' },
    { industryName: 'Media & Entertainment', name: 'Online Media' },
    { industryName: 'Media & Entertainment', name: 'Printing' },
    { industryName: 'Media & Entertainment', name: 'Publishing' },
    { industryName: 'Non-Profit', name: 'Civic & Social Organization' },
    { industryName: 'Non-Profit', name: 'Libraries' },
    { industryName: 'Non-Profit', name: 'Non-Profit Organization Management' },
    { industryName: 'Non-Profit', name: 'Philanthropy' },
    { industryName: 'Non-Profit', name: 'Political Organization' },
    { industryName: 'Non-Profit', name: 'Religious Institutions' },
    { industryName: 'Other', name: 'Arts and Crafts' },
    { industryName: 'Other', name: 'Nanotechnology' },
    { industryName: 'Real Estate & Construction', name: 'Architecture & Planning' },
    { industryName: 'Real Estate & Construction', name: 'Building Materials' },
    { industryName: 'Real Estate & Construction', name: 'Civil Engineering' },
    { industryName: 'Real Estate & Construction', name: 'Commercial Real Estate' },
    { industryName: 'Real Estate & Construction', name: 'Construction' },
    { industryName: 'Real Estate & Construction', name: 'Glass, Ceramics & Concrete' },
    { industryName: 'Real Estate & Construction', name: 'Real Estate' },
    { industryName: 'Retail', name: 'Apparel & Fashion' },
    { industryName: 'Retail', name: 'Cosmetics' },
    { industryName: 'Retail', name: 'Luxury Goods & Jewelry' },
    { industryName: 'Retail', name: 'Retail' },
    { industryName: 'Retail', name: 'Supermarkets' },
    { industryName: 'Software & Internet', name: 'Computer & Network Security' },
    { industryName: 'Software & Internet', name: 'Computer Software' },
    { industryName: 'Software & Internet', name: 'Information Technology and Services' },
    { industryName: 'Software & Internet', name: 'Internet' },
    { industryName: 'Telecommunications', name: 'Telecommunications' },
    { industryName: 'Telecommunications', name: 'Wireless' },
    { industryName: 'Transportation & Storage', name: 'Airlines/Aviation' },
    { industryName: 'Transportation & Storage', name: 'Logistics and Supply Chain' },
    { industryName: 'Transportation & Storage', name: 'Maritime' },
    { industryName: 'Transportation & Storage', name: 'Package/Freight Delivery' },
    { industryName: 'Transportation & Storage', name: 'Packaging and Containers' },
    { industryName: 'Transportation & Storage', name: 'Warehousing' },
    { industryName: 'Transportation & Storage', name: 'Transportation/Trucking/Railroad' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Entertainment' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Fine Art' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Gambling & Casinos' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Hospitality' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Leisure, Travel & Tourism' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Performing Arts' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Photography' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Recreational Facilities and Services' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Restaurants' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Sporting Goods' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Sports' },
    { industryName: 'Travel, Recreation, and Leisure', name: 'Wine and Spirits' },
    { industryName: 'Wholesale & Distribution', name: 'Wholesale' },
]

export const defaultEmployeeSize = [
    { id: '1', name: '0 - 25' },
    { id: '2', name: '25 - 100' },
    { id: '3', name: '100 - 250' },
    { id: '4', name: '250 - 1000' },
    { id: '5', name: '1K - 10K' },
    { id: '6', name: '10K - 50K' },
    { id: '7', name: '50K - 100K' },
    { id: '8', name: '> 100K' }
]

export const defaultRevenue = [
    { id: '1', name: '$0 - 1M' },
    { id: '2', name: '$1 - 10M' },
    { id: '3', name: '$10 - 50M' },
    { id: '4', name: '$50 - 100M' },
    { id: '5', name: '$100 - 250M' },
    { id: '6', name: '$250 - 500M' },
    { id: '7', name: '$500M - 1B' },
    { id: '8', name: '> $1B' }
]