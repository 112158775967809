import React, { useState } from 'react';
import { Modal, Button, Form, Input, notification } from "antd";
import GmailSvg from "images/gmail.svg";
import classes from "../styles.module.css";
import { RightOutlined, CopyOutlined } from '@ant-design/icons';
import axios from "axios";
import { MailboxTypeEnum } from "types/MailBox";

const { TextArea } = Input;
interface GmailIMAPLoginModalProps {
   handleOpenClose: () => void;
   handleSubmit: (form: any) => Promise<void>;
   formData?: any;
   isEditMode?: boolean;
   connectModalStep?: Number;
   isEditModeForWorkspacePersonal?: boolean;
   setOpenSetupModal?: () => void;
   onContinue?: (providerType: MailboxTypeEnum, email: string) => void;
   providerType: any
}

const GsuiteLoginModal = ({ handleOpenClose, setOpenSetupModal, onContinue, providerType }: GmailIMAPLoginModalProps) => {
   // console.log("fromData", fromData);
   const [subform] = Form.useForm();
   const copyToClipboard = () => {
      navigator.clipboard.writeText("543861219222-msktpiue2qas6h8oa2cakr593i2ibvjo.apps.googleusercontent.com ")
         .then(() => {
            notification.success({ message: 'Copied!' })
         })
         .catch(err => {
            notification.error({ message: 'Failed to copy!' })
         });
   };

   // getOauthUrl(provider: string, state: string, accId: string, inboxId: string = '', reconnect: boolean = false) {
   //    const callback = environment.production ? {
   //      callback: inboxId ? `https://${window.location.hostname}/#/dash/${inboxId}` : `https://${window.location.hostname}/#/account/${accId}`
   //    } : {
   //      callback: inboxId ? `http://localhost:5100/#/dash/${inboxId}` : `http://localhost:5100/#/account/${accId}`
   //    };
   //    return this.httpClient.get('https://oauth.inboxy.io/oauthurl', { params: { provider, state, reconnect, ...callback }, headers: HEADER_JSON })
   //      .pipe(map((res: any) => res.data));
   //  }

   const getOauthUrl = async (provider: string, state: string, accId: string, inboxId: string = '', reconnect: boolean = false) => {
      const callback = "http://localhost:3000/mailboxes"; // Ensure this is the callback URL without query params

      try {
         const response = await axios.get('https://oauth.inboxy.io/oauthurl', {
            params: { provider: "google", state: "7ab437103539-45c5-a106-7cb5877ac454", reconnect, callback: callback },
            headers: { 'Content-Type': 'application/json' }
         });
         console.log("response.data", response.data);

         if (response.data && response.data?.data?.oauth_url) {
            // Strip any query parameters from the OAuth URL
            const url = new URL(response.data.data.oauth_url);
            window.location.href = url.origin + url.pathname; // Redirect without query parameters in the same tab
            setOpenSetupModal && setOpenSetupModal()
         } else {

            console.error('OAuth URL not found in response', response);
         }
      } catch (error) {
         console.error('Error fetching OAuth URL', error);
         throw error;
      }
   };


   return (
      <Modal
         visible
         closable={true}
         onCancel={() => handleOpenClose()}
         centered
         footer={null}
      >
         <div className={classes.gmailOnboarding}>
            <div className={classes.gmailOnboarding_header}>
               <div>
                  <img src={GmailSvg} alt="gmail" />
               </div>
               <div className="ml-15px">
                  <h5 className="mb-0 fs-20px">Connect Your Google Account</h5>
                  <p className="text-muted mb-0 font-600 fs-15px">G-Suite</p>
               </div>
            </div>
            <ul className={classes.gmailOnboarding_orderlist} style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
               <li>1. Go to your G-Suite-Admin
               </li>
               <li>2. Click the "Add APP" and select "OAuth App Name or Client ID".
               </li>
               <li>3. Copy the below client ID to search for Emy Login App.
               </li>
               <li>   <div className="mt-20 text-center">
                  <TextArea
                     rows={4}
                     value={"543861219222-msktpiue2qas6h8oa2cakr593i2ibvjo.apps.googleusercontent.com "}
                     style={{ color: "black", resize: 'none' }}
                     disabled
                  />
                  <span style={{ display: 'flex', justifyContent: 'end', height: '30px', cursor: 'pointer', marginTop: '10px' }} onClick={copyToClipboard}>
                     <CopyOutlined height={20} width={20} /> Copy
                  </span>
               </div>

               </li>
               <li>4. Select Emy Login and approve the app.
               </li>
            </ul>

            <div className="text-center mt-20">
               <Button type='primary' onClick={() => onContinue && onContinue(providerType, '')}>
                  Login {" "} <RightOutlined />
               </Button>

            </div>
         </div>
      </Modal >
   )
}

export default GsuiteLoginModal;