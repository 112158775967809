import React, { useCallback, useEffect, useState } from "react";
import { Modal, Input, Form, notification, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useApi from "hooks/useApi";
import { MailBoxIpsDetailType } from "types/MailBox";
import { Div, Flex } from "styles/layout";
import moment from "moment";
import Axios from "utils/axios";
import { Text } from "styles/typography";
import TextArea from "antd/lib/input/TextArea";
import { WHISPER_BASE_URL } from "constants/api";

interface SendEmailModalProps {
    name: any;
    email: any;
    onCancel: () => void;
}

const SendEmailModal = ({ onCancel, name, email }: SendEmailModalProps) => {

    const [Email, setEmail] = useState("");
    const [subject, setSubject] = useState("")
    const [EmailBody, setEmailBody] = useState("")
    const [disabled, setDisabled] = useState(false);

    const payload = {
        "fromName": name,
        "fromEmail": email,
        "toEmail": Email,
        "subject": subject,
        "bodyText": EmailBody
    }

    const onSendEmail = async () => {
        setDisabled(true);
        const response = await Axios.callApi({
            method: 'POST',
            url: `${WHISPER_BASE_URL}/send`,
            data: payload
        });
        if (response.success) {
            notification.success({ message: "Your email will go out shortly", duration: 3 });
            onCancel();
        } else {
            setDisabled(false);
        }
    }

    return (
        <Modal
            visible
            footer={null}
            onCancel={onCancel}
        >
            <Text variant="h6" block mb={20}>
                Send Email
            </Text>
            <Div>
                <Form
                    requiredMark={true}
                    onFinish={onSendEmail}
                >
                    <Text variant="overline" color="secondary">
                        To
                    </Text>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input an Email!' }]}
                    >
                        <Input type='email' onChange={(e) => setEmail(e.target.value)}></Input>
                    </Form.Item>
                    <Text variant="overline" color="secondary">
                        Subject
                    </Text>
                    <Form.Item>
                        <Input type='text' onChange={(e) => setSubject(e.target.value)}></Input>
                    </Form.Item>
                    <Text variant="overline" color="secondary">
                        Body
                    </Text>
                    <Form.Item>
                        <TextArea rows={5} onChange={(e) => setEmailBody(e.target.value)}></TextArea>
                    </Form.Item>

                    <Flex direction="row" ml={10} justify="flex-end">
                        <Flex>
                            <Button onClick={onCancel} disabled={disabled} tabIndex={5}>
                                Cancel
                            </Button>
                        </Flex>
                        <Flex ml={10}>
                            <Button htmlType="submit" disabled={disabled} type="primary" tabIndex={6}>
                                {disabled ? <LoadingOutlined /> : ''}
                                Send
                            </Button>
                        </Flex>
                    </Flex>
                </Form>
            </Div>
        </Modal>
    );
};

export default SendEmailModal;