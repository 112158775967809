/* eslint-disable array-callback-return */
import React, { useCallback, useContext, useEffect } from "react";
import { Input, Modal, notification, Select } from "antd";
import { useState } from "react";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import CampaignActions from "state/actions/campaign";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignTagsSelector } from "state/selectors";
import { isEmailValid, stringToArray } from "utils/validators";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";

interface DeliverMailModalProps {
  onDismiss: () => void;
  campaignId: string;
  inboundMessageId: string;
  tag: any;
}

const DeliverMailModal = ({
  onDismiss,
  campaignId,
  inboundMessageId,
  tag,
}: DeliverMailModalProps) => {
  const dispatch = useDispatch();
  const { setLoadingMessage } = useContext(appContext);
  const { callApi } = useApi();
  const [tagName, setTag] = useState<null | string>(null);
  const { tags } = useSelector(getCampaignTagsSelector(campaignId));
  const { TextArea } = Input;

  const [tagId, setTagId] = useState("");

  const [toArr, setToArr] = useState("");
  const [bccArr, setBccArr] = useState("");
  const [ccArr, setCcArr] = useState("");

  const [toArrMemory, setToArrMemory] = useState("");
  const [bccArrMemory, setBccArrMemory] = useState("");
  const [ccArrMemory, setCcArrMemory] = useState("");

  const [toArrEdit, setToArrEdit] = useState("");
  const [bccArrEdit, setBccArrEdit] = useState("");
  const [ccArrEdit, setCcArrEdit] = useState("");

  const fetchTags = useCallback(() => {
    dispatch(CampaignActions.fetchTags(campaignId));
  }, [campaignId, dispatch]);

  useEffect(() => {
    fetchTags();
    onLoadData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoadData = () => {
    tags.map((data) => {
      setTagId(tag.tagId);
      if (data.tagId === tag.tagId) {
        loadDataonSelect(data);
      }
    });
  };
  const onSave = useCallback(async () => {
    setLoadingMessage("Sending email ...");

    const { url, method } = API_ENDPOINTS.INBOUND.getDeliverUrl();

    let payload = {
      inboundMessageId,
      campaignId,
      name: tagName,
      tagId,
      recipients: {
        tos: stringToArray(toArrEdit),
        ccs: stringToArray(ccArrEdit),
        bccs: stringToArray(bccArrEdit),
      },
    };

    const response = await callApi({
      url,
      method,
      data: payload,
    });
    if (response.success) {
      notification.success({ message: "Email sent successfully" });
      onDismiss();
    }

    setLoadingMessage("");
  }, [
    bccArrEdit,
    callApi,
    campaignId,
    ccArrEdit,
    inboundMessageId,
    onDismiss,
    setLoadingMessage,
    tagId,
    tagName,
    toArrEdit,
  ]);

  function removeDuplicates(array: any) {
    var valuesSoFar = [];
    for (var i = 0; i < array.length; ++i) {
      var value = array[i];
      if (valuesSoFar.indexOf(value) !== -1) {
        continue;
      }
      valuesSoFar.push(value);
    }
    return valuesSoFar;
  }

  const onChangeHandle = (value: any, type: any) => {
    let newArray: any[] = [];
    let diffArray: any[] = [];
    const editedArrayy = stringToArray(value);
    switch (type) {
      case "to":
        newArray = stringToArray(toArr);
        break;
      case "cc":
        newArray = stringToArray(ccArr);
        break;
      case "bcc":
        newArray = stringToArray(bccArr);
        break;
      default:
        break;
    }
    diffArray = newArray
      .filter((x) => !editedArrayy.includes(x))
      .concat(editedArrayy.filter((x: any) => !newArray.includes(x)));
    diffArray = diffArray.filter((x: any) => {
      if (isEmailValid(x)) {
        return x;
      }
    });
    if (diffArray.length > 0) {
      let toArrMemoryArray = stringToArray(
        type === "to"
          ? toArrMemory
          : type === "cc"
          ? ccArrMemory
          : type === "bcc"
          ? bccArrMemory
          : ""
      );
      let aConcated = toArrMemoryArray.concat(diffArray);
      aConcated = removeDuplicates(aConcated);
      aConcated = aConcated.filter((x: any) => {
        if (editedArrayy.indexOf(x) >= 0) {
          return x;
        }
      });

      const singleStringNew = aConcated?.join("\n");

      if (type === "to") {
        setToArrMemory(singleStringNew);
      } else if (type === "cc") {
        setCcArrMemory(singleStringNew);
      } else if (type === "bcc") {
        setBccArrMemory(singleStringNew);
      }
    }
  };

  const onChangeTo: React.ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setToArrEdit(value);
    onChangeHandle(value, "to");
  };

  const onChangeCC: React.ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCcArrEdit(value);
    onChangeHandle(value, "cc");
  };

  const onChangeBcc: React.ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBccArrEdit(value);
    onChangeHandle(value, "bcc");
  };

  const onChangeClick = (v: any) => {
    setTag(v as string);
    // eslint-disable-next-line array-callback-return
    tags.map((data) => {
      if (v === data.name) {
        setTagId(data.tagId);
        loadDataonSelect(data);
      }
    });
  };

  const loadDataonSelect = (data: any) => {
    const toInSingleString = data.recipients.tos?.join("\n");
    const ccInSingleString = data.recipients.ccs?.join("\n");
    const bccInSingleString = data.recipients.bccs?.join("\n");
    setToArr(toInSingleString);
    setCcArr(ccInSingleString);
    setBccArr(bccInSingleString);

    if (toArrMemory) {
      let originalToArray = stringToArray(toInSingleString);
      let toMemoryArray = stringToArray(toArrMemory);
      let aConcated = originalToArray.concat(toMemoryArray);
      aConcated = removeDuplicates(aConcated);
      const toInSingleStringNew = aConcated?.join("\n");
      setToArrEdit(toInSingleStringNew);
    } else {
      setToArrEdit(toInSingleString);
    }

    if (ccArrMemory) {
      let originalCCArray = stringToArray(ccInSingleString);
      let ccMemoryArray = stringToArray(ccArrMemory);
      let aConcated = originalCCArray.concat(ccMemoryArray);
      aConcated = removeDuplicates(aConcated);
      const ccInSingleStringNew = aConcated?.join("\n");
      setCcArrEdit(ccInSingleStringNew);
    } else {
      setCcArrEdit(ccInSingleString);
    }

    if (bccArrMemory) {
      let originalBccArray = stringToArray(bccInSingleString);
      let bccMemoryArray = stringToArray(bccArrMemory);
      let aConcated = originalBccArray.concat(bccMemoryArray);
      aConcated = removeDuplicates(aConcated);
      const bccInSingleStringNew = aConcated?.join("\n");
      setBccArrEdit(bccInSingleStringNew);
    } else {
      setBccArrEdit(bccInSingleString);
    }
  };
  return (
    <Modal
      visible
      onCancel={onDismiss}
      bodyStyle={{ padding: 30 }}
      okText="Send Email"
      onOk={onSave}
    >
      <Flex direction="column" mb={10}>
        <Text variant="overline" block color="secondary">
          Select Delivery
        </Text>
        <Select
          style={{ width: "200px", marginBottom: 10 }}
          onChange={(v) => onChangeClick(v)}
          defaultValue={tags.find((x) => x.tagId === tag.tagId)?.name}
        >
          {tags.map((data, index) => (
            <Select.Option value={data.name} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Flex>
      <Flex direction="column" mb={20}>
        <Text variant="overline" block color="secondary">
          To
        </Text>
        <TextArea
          rows={4}
          value={toArrEdit}
          onChange={onChangeTo}
          style={{ color: "black" }}
        />
      </Flex>
      <Flex direction="column" mb={20}>
        <Text variant="overline" block color="secondary">
          CC
        </Text>
        <TextArea
          rows={4}
          value={ccArrEdit}
          onChange={onChangeCC}
          style={{ color: "black" }}
        />
      </Flex>
      <Flex direction="column" mb={20}>
        <Text variant="overline" block color="secondary">
          BCC
        </Text>
        <TextArea
          rows={4}
          value={bccArrEdit}
          onChange={onChangeBcc}
          style={{ color: "black" }}
        />
      </Flex>
    </Modal>
  );
};
export default DeliverMailModal;
