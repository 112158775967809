import { useMemo, useState } from "react";
import { Button, Form, Input, Modal, notification, Select, Spin } from "antd";
import { ProspectVariableType } from "types/Prospect";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { TimeZoneType } from "utils/dateTime";
import { FormControlWrapper, Label } from "components/UploadPropects/styles";
import { LoadingOutlined } from "@ant-design/icons";
import { getTimeZoneSelector } from "state/selectors";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

const { Option } = Select;

interface ReferralSequenceModalProps {
  onOk: (data?: any) => void;
  onCancel: () => void;
  variables: ProspectVariableType[];
  isLoading: boolean;
}

const ReferralSequenceModal = ({
  onOk,
  onCancel,
  variables,
  isLoading,
}: ReferralSequenceModalProps) => {
  const [form] = Form.useForm();
  const match = useRouteMatch<{ id: string; sequenceId?: string }>();
  const campaignId = useMemo(() => {
    const {
      params: { id },
    } = match;
    return id;
  }, [match]);
  const [showStartModal, setShowStartModal] = useState(false);

  const [formData, setFormData] = useState<any>({});

  const [timeZoneSelected, setTimeZoneSelected] = useState();
  let attributeArr: any[] = [];
  let refereeProspect: any[] = [];

  const timeZoneSelector = useMemo(() => getTimeZoneSelector(campaignId), [
    campaignId,
  ]);
  const timezonArr = useSelector(timeZoneSelector);

  const onFinish = (formValues: any) => {
    setFormData(formValues);
    setTimeZoneSelected(formValues.timeZone);
    // if (formValues.timeZone === undefined || formValues.timeZone === "") {
    //   notification.error({
    //     message: "Please select campaign timezone.",
    //   });
    // } else {
    //   setShowStartModal(true);
    //   // onOk(refereeProspect);
    // }

    //setShowStartModal(true);
    modalStart(formValues);
  };

  const modalStart = (formData:any) => {
    let isFormValid = checkFormValidity(formData);
    if (isFormValid) {
      attributeArr.push(formData);
      let result: any[] = [];
      let keyArr: any[] = [];
      let valArr: any[] = [];

      for (const [key, value] of Object.entries(attributeArr[0])) {
        if (key === "timeZone") continue;
        let variableName = variables.find((x) => x.name === key);
        keyArr.push(variableName?.caseNeutralName);
        valArr.push(value === undefined ? "" : value);
      }

      // eslint-disable-next-line array-callback-return
      keyArr.map((item, index) => {
        let t: any = {};
        t[item] = valArr[index];
        result.push(t);
      });
      refereeProspect.push({
        timezone: timeZoneSelected,
        attributes: result,
      });

      onOk(refereeProspect);
    }
  };

  const checkFormValidity = (formValues: any) => {
    let keys = Object.keys(formValues);
    let isValid = true;
    for (let i = 0; i < keys.length; i++) {
      let checkIfMandatory = variables.find((key) => key.name === keys[i])
        ?.isMandatory;
      let element = formValues[keys[i]];
      if (
        (checkIfMandatory && element === undefined) ||
        (checkIfMandatory && element === "")
      ) {
        isValid = false;
        notification.error({
          message: `Please write something in ${keys[i]}.`,
        });
        break;
      }
    }
    return isValid;
  };

  let withColumnIndex = variables;

  return (
    <Modal onCancel={onCancel} visible footer={null} width={600} centered>
      <Text variant="h6">Start Referral Sequence</Text>
      <Text variant="body2">
        Please enter the following details for the referee to whom the follow up
        email will be sent.
      </Text>
      <Flex mt={20} style={{ overflow: "hidden" }}>
        <Flex
          style={{ maxHeight: 500, overflowY: "scroll", overflowX: "hidden" }}
          p={10}
        >
          <Form form={form} onFinish={onFinish} style={{ padding: 5 }}>
            {/* <Flex mt={-25}>
              <FormControlWrapper>
                <Label htmlFor="timeZone">
                  <Text variant="overline" color="secondary">
                    Referral Timezone
                  </Text>
                </Label>
                <Form.Item name="timeZone">
                  <Select showSearch style={{ width: "300px" }}>
                    {timezonArr.map(({ label, tzCode }: TimeZoneType) => (
                      <Option key={label} value={tzCode}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FormControlWrapper>
            </Flex> */}
            <Flex style={{ flexWrap: "wrap" }} justify="space-between">
              {withColumnIndex.map(({ name, isMandatory }, i) => (
                <Div w="calc(50% - 10px)" key={i}>
                  <Flex direction="row">
                    <Text block variant="overline" color="secondary">
                      {name}
                    </Text>
                    <Text variant="overline" color="error">
                      <sup>{isMandatory ? "*" : ""}</sup>
                    </Text>
                  </Flex>
                  <Form.Item name={name}>
                    <Input />
                  </Form.Item>
                </Div>
              ))}
            </Flex>
            <Flex justify="flex-end">
              <Button style={{ marginRight: 10 }} onClick={onCancel}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary">
                Start
              </Button>
            </Flex>
          </Form>
          {isLoading && (
            <Flex
              align="center"
              justify="center"
              position="absolute"
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 26 }} spin />}
              />
            </Flex>
          )}
        </Flex>
        {showStartModal && (
          <Modal
            maskClosable={!isLoading}
            confirmLoading={isLoading}
            closable={!isLoading}
            visible
            onOk={modalStart}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => setShowStartModal(false)}
            okText="Confirm"
          >
            <Div>
              <Text variant="sub2" block>
                Are you sure you want to start referral sequence for
                {` ${formData["First Name"]} ${formData["Last Name"]}?`}
              </Text>
            </Div>
          </Modal>
        )}
      </Flex>
    </Modal>
  );
};

export default ReferralSequenceModal;
