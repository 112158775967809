class LocalStorage {
  static getOutwinKey = (key: string) => `OUTWIN_${key}`;
  static setValue = (key: string, value: any) => {
    window.localStorage.setItem(
      LocalStorage.getOutwinKey(key),
      JSON.stringify({ value })
    );
  };
  static getValue = (key: string) => {
    const localStorageValue = window.localStorage.getItem(
      LocalStorage.getOutwinKey(key)
    );
    try {
      return localStorageValue ? JSON.parse(localStorageValue).value : null;
    } catch (err) {
      return null;
    }
  };
  static resetKey = (key: string) => LocalStorage.setValue(key, null);
  static deleteKey = (key: string) =>
    window.localStorage.removeItem(LocalStorage.getOutwinKey(key));
}

export default LocalStorage;
