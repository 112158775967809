import { CheckCircleOutlined, ClockCircleOutlined, EllipsisOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useMachine } from "@xstate/react";
import { Button, Card, Modal, notification, Skeleton } from "antd";
import Link from "antd/lib/typography/Link";
import { CopyIcon } from "components/ClickToCopy";
import { API_ENDPOINTS } from "constants/api";
import useApi, { ApiResponseType } from "hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { LIGHT_GRAY_COLOR } from "utils/colors";
import { FETCH_MACHINE_OPEN_TRACKING } from "./OpenTracking.machine";
import { OPEN_TRACKING_META, OPEN_TRACKING_STEPS, OPEN_TRACKING_STRINGS } from "./OpenTrackingMeta";
import {
  OpenTrackingActionDataArgs,
  OpenTrackingActionType,
  OpenTrackingModalProps,
  OpenTrackingProps,
  OpenTrackingStep,
  OpenTrackingStepProps
} from "./OpenTrackingTypes";

export const OPEN_TRACKING_STATUS = {
  NotStarted: { icon: <EllipsisOutlined />, label: "Not started" },
  Pending: { icon: <ClockCircleOutlined />, label: "Pending" },
  Success: { icon: <CheckCircleOutlined />, label: "Success" }
  // Failed: { icon: <CloseCircleOutlined />, label: "Failed" }
};

const apiResHandler = (res: ApiResponseType, stepName?: OpenTrackingStep) => {
  if (res.success) {
    return { ...res.data, stepName };
  } else {
    throw res.error;
  }
};

const TrackingStep = (props: OpenTrackingStepProps) => {
  const status = (!props.trackerData ? "NotStarted" : props.trackerData?.complete ? "Success" : "Pending") as keyof typeof OPEN_TRACKING_STATUS;
  const operation: OpenTrackingActionType = status === "NotStarted" ? "CREATE" : "VALIDATE";
  const actionBtnLbl = status === "NotStarted" ? OPEN_TRACKING_STRINGS.create_cname : OPEN_TRACKING_STRINGS.validate;
  const actionArgs: OpenTrackingActionDataArgs = { operation: operation, stepName: props.trackerType, idx: props.idx };
  const { icon, label } = OPEN_TRACKING_STATUS[status];
  const { cname, cvalue } = props?.trackerData ?? {};

  const [showHelpModal, setShowHelpModal] = useState(false);
  const onHelp = (evt: any) => {
    evt?.preventDefault();
    setShowHelpModal(true);
  };

  return (
    <>
      <Card bordered={!false} style={{ backgroundColor: LIGHT_GRAY_COLOR }}>
        <Flex direction="column" gap={25}>
          <Flex gap={15} align="center" justify="space-between">
            <Flex direction="column" gap={5}>
              <Flex gap={10} align="center">
                <Text variant="sub1">{props.vc?.title}</Text>
                <Flex gap={5} align="center">
                  <Text variant="body2" color={status === "Success" ? "success" : "secondary"}>
                    {icon}
                  </Text>
                  <Text variant="body2" color={status === "Success" ? "success" : "secondary"}>
                    {label}
                  </Text>
                </Flex>
              </Flex>
              {status !== "NotStarted" && (
                <Flex gap={10}>
                  <Text variant="caption" color="secondary">
                    {props.vc?.description(props.domain)}{" "}
                    <Link title="Help" onClick={onHelp}>
                      <QuestionCircleOutlined />
                    </Link>
                  </Text>
                </Flex>
              )}
            </Flex>
            <Button type="primary" onClick={() => props?.onAction?.(actionArgs)} loading={props.action?.loading} disabled={!props.action?.available}>
              {actionBtnLbl}
            </Button>
          </Flex>
          {cname && cvalue && (
            <Flex direction="column" gap={10}>
              <Flex direction="column" gap={2}>
                <Text variant="caption" color="secondary">
                  {OPEN_TRACKING_STRINGS.cname_name}
                </Text>
                <Flex gap={10}>
                  <Text variant="body2">{cname}</Text>
                  <CopyIcon text={cname} />
                </Flex>
              </Flex>
              <Flex direction="column" gap={2}>
                <Text variant="caption" color="secondary">
                  {OPEN_TRACKING_STRINGS.cname_value}
                </Text>
                <Flex gap={10}>
                  <Text variant="body2">{cvalue}</Text>
                  <CopyIcon text={cvalue} />
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Card>
      {showHelpModal && <HelpModal onCloseModal={() => setShowHelpModal(false)} />}
    </>
  );
};

const OpenTracking = (props: OpenTrackingProps) => {
  const { callApi } = useApi();

  const { url: urlCName, method: methodCName } = API_ENDPOINTS.MAILBOX.getCNames(props.inbox);
  const { url: urlCNameValidate, method: methodCNameValidate } = API_ENDPOINTS.MAILBOX.validateCName(props.inbox);
  const { url: urlCreate, method: methodCreate } = API_ENDPOINTS.MAILBOX.createCName(props.inbox);
  const [state, send] = useMachine(FETCH_MACHINE_OPEN_TRACKING, {
    actions: {
      notifySuccess: (ctx, evt) => {
        ctx.data && ctx.data?.[ctx.data.stepName]?.complete && notification.success({ message: OPEN_TRACKING_STRINGS.validate_success });
      }
    },
    services: {
      fetchData: (_, evt) => {
        switch (evt.data?.operation) {
          case "LIST":
            return callApi({ url: urlCName, method: methodCName }).then((res) => apiResHandler(res));
          case "VALIDATE":
            return callApi({ url: urlCNameValidate + "/" + evt.data?.stepName, method: methodCNameValidate }).then((res) =>
              apiResHandler(res, (evt.data as any)?.stepName)
            );
          default:
            // CREATE
            return callApi({ url: urlCreate, method: methodCreate, data: { type: evt.data?.stepName } }).then((res) => apiResHandler(res));
        }
      }
    }
  });

  useEffect(() => {
    send({ type: "FETCH", data: { operation: "LIST" } });
  }, [send]);

  const isFirstTime = !state.context.data;
  const trackerProps = (trackerType: OpenTrackingStep, idx: number): OpenTrackingStepProps => {
    // cert is first so its enabled, but gateway will enabled once cert is complete
    const available = trackerType === "gateway" ? !!state.context?.data?.["certificate"]?.complete : true;
    const loading = (state.event as any)?.data?.idx === idx && state.value === "loading";
    return {
      action: { available, loading },
      domain: props.inbox,
      idx,
      ...OPEN_TRACKING_META[trackerType],
      ...(state.context.data && { trackerData: state.context.data[trackerType] })
    };
  };

  const onAction = useCallback(
    (data: OpenTrackingActionDataArgs) => {
      send({ type: "FETCH", data });
    },
    [send]
  );

  if (!isFirstTime) {
    return (
      <Flex direction="column" gap={15}>
        {OPEN_TRACKING_STEPS.map((type, idx) => (
          <TrackingStep key={idx} {...trackerProps(type, idx)} onAction={onAction} />
        ))}
      </Flex>
    );
  }

  switch (state.value) {
    case "loading":
      return <Skeleton active />;
    case "failure":
      return (
        <Div>
          <Text variant="sub2" color="error">
            {state.context.error.message}
          </Text>
          <Button onClick={() => send({ type: "FETCH", data: { operation: "LIST" } })}>Retry</Button>
        </Div>
      );
    default:
      return null;
  }
};

export const OpenTrackingModal = (props: OpenTrackingModalProps) => {
  return (
    <Modal
      title={
        <Flex direction="column" gap={10}>
          <Text variant="h6">{OPEN_TRACKING_STRINGS.setup_open_tracking}</Text>
          {props.inbox && <Text variant="body2">{props.inbox}</Text>}
        </Flex>
      }
      width={700}
      visible
      onCancel={props.onCloseModal}
      footer={null}
      maskClosable={false}
    >
      <OpenTracking inbox={props.inbox.split("@").pop() ?? ""} />
    </Modal>
  );
};

const HelpModal = ({ onCloseModal }: { onCloseModal: () => void }) => {
  return (
    <Modal
      title={
        <Flex direction="column" gap={10}>
          <Text variant="h6">{OPEN_TRACKING_STRINGS.help}</Text>
        </Flex>
      }
      width={700}
      visible
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
    >
      <Flex direction="column" gap={10}>
        <Text variant="sub1" color="secondary">
          {OPEN_TRACKING_STRINGS.helpText.titleInfo}
        </Text>
        <ul style={{ marginLeft: "20px" }}>
          {OPEN_TRACKING_STRINGS.helpText.domains.map((hp) => {
            return (
              <li style={{ padding: "10px 0" }}>
                <Link target="_blank" href={hp.link} title={hp.label}>
                  {hp.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </Flex>
    </Modal>
  );
};
