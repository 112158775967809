import React, { useCallback, useEffect, useState } from "react";
import { Modal, Table } from "antd";
import useApi from "hooks/useApi";
import { MailBoxIpsDetailType } from "types/MailBox";
import { API_ENDPOINTS } from "constants/api";
import { Div } from "styles/layout";
import moment from "moment";

interface IpsDetailModalProps {
  email: string;
  onCancel: () => void;
}

const COLUMNS = [
  {
    title: "Provider",
    dataIndex: "provider",
    key: "provider",
  },
  {
    title: "Delivered to",
    dataIndex: "deliveredTo",
    key: "deliveredTo",
  },
  {
    title: "SPF",
    dataIndex: "SPF",
    key: "SPF",
  },
  {
    title: "DKIM",
    dataIndex: "DKIM",
    key: "DKIM",
  },
  {
    title: "Delivered at",
    dataIndex: "deliveredAt",
    key: "deliveredAt",
    render: (text: string) => moment(text).format("MMM DD YYYY, hh:mm a"),
  },
];

const IpsDetailsModal = ({ onCancel, email }: IpsDetailModalProps) => {
  const { callApi } = useApi();
  const [ipsDetails, setIpsDetails] = useState<{
    entities: MailBoxIpsDetailType[];
    loading: boolean;
  }>({ entities: [], loading: true });

  const fetchIpsDetails = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.MAILBOX.getIpsDetailUrl(email);

    try {
      const response = await callApi({
        url,
        method,
      });

      if (response.success) {
        setIpsDetails({
          entities: response.data.details,
          loading: false,
        });
        return;
      }
    } catch (err) {}
    setIpsDetails({ entities: [], loading: false });
  }, [callApi, email]);

  useEffect(() => {
    fetchIpsDetails();
  }, [fetchIpsDetails]);

  return (
    <Modal visible onCancel={onCancel} footer={null} width={600}>
      <Div p="25px 10px 0px 10px">
        <Table
          bordered
          dataSource={ipsDetails.entities}
          columns={COLUMNS}
          loading={ipsDetails.loading}
          size="small"
          pagination={{ pageSize: 5, position: ["bottomCenter"] }}
          className="outwin-table"
        />
      </Div>
    </Modal>
  );
};

export default IpsDetailsModal;
