import { Card, Skeleton } from "antd";
import React from "react";
import { Div, BgImage, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { getAvatarImage } from "utils/avatar";
import { Switch } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { BOX_SHADOW, LIGHT_BLUE_COLOR, SECONDARY_COLOR } from "utils/colors";
import { CampaingStatusEnum, AvatarEnum } from "types/Campaign";
import TimeAgo from "react-timeago";

export interface CampaignCardProps {
  name: string;
  prospectsCount: number;
  repliesCount: number;
  status: CampaingStatusEnum;
  lastEvent: {
    time: number;
    name: string;
  };
  onStatusChange: () => void;
  imageName: AvatarEnum;
  onClick: () => void;
  loading?: boolean;
}

const getStatusColor = (status: CampaingStatusEnum) => (status === CampaingStatusEnum.ACTIVE ? "success" : "error");

const CampaignCard = ({ name, prospectsCount, repliesCount, status, lastEvent, onStatusChange, imageName, onClick, loading = false }: CampaignCardProps) => {
  return (
    <Card style={{ width: "100%", boxShadow: BOX_SHADOW, cursor: "pointer" }} onClick={!loading ? onClick : () => {}}>
      <Skeleton loading={loading} active>
        <Flex justify="space-between">
          <BgImage imageUrl={getAvatarImage(imageName)} h={80} w={80} br="50%" display="inline-block" bc={SECONDARY_COLOR} bs={55} bgColor={LIGHT_BLUE_COLOR} />
          <Div>
            {lastEvent.time ? (
              <>
                <Text variant="caption" block bold align="right">
                  <TimeAgo date={lastEvent.time} />
                </Text>
                <Text variant="caption" block uppercase color="secondary" align="right">
                  {lastEvent.name}
                </Text>
              </>
            ) : null}
            {status !== CampaingStatusEnum.COMPLETED ? (
              <Div align="center" mt={10} onClick={(e) => e.stopPropagation()}>
                <Switch onChange={onStatusChange} checked={status === CampaingStatusEnum.ACTIVE} />
              </Div>
            ) : null}
          </Div>
        </Flex>
        <Div mt={20}>
          <Text variant="body1" color="primary" block mb={20} ellipsis title={name}>
            {name}
          </Text>
          <Flex justify="space-between">
            <Div>
              <Text variant="caption" bold block>
                {prospectsCount} <UserOutlined />
              </Text>
              <Text variant="caption" uppercase color={getStatusColor(status)}>
                {status}
              </Text>
            </Div>
            {/* <Div>
              <Text variant="caption" bold block align="right">
                {repliesCount} <MailOutlined />
              </Text>
              <Text variant="caption" uppercase bold color="secondary">
                Replied
              </Text>
            </Div> */}
          </Flex>
        </Div>
      </Skeleton>
    </Card>
  );
};

export default CampaignCard;
