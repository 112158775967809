import React, {
  ReactNode,
} from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import useApi from "hooks/useApi";
import { Flex, Div } from "styles/layout";
import AppContext from "contexts/appContext";
import { API_ENDPOINTS } from "constants/api";
import { MailBoxSettingsType } from "components/MailBox/Settings";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { NavPathType } from "components/AppBar";
import { Input } from "antd";
import { Text } from "styles/typography";
import { capitalize } from "utils/string";
import { ProspectsType } from "types/Prospect";
import { Link } from "react-router-dom";

const res = {
  "success": true,
  "data": {
    "prospect": {
      "campaignId": "0b7f3daf-39d9-4067-aa6b-db4cec837d2c",
      "email": "hothinhunglun@gmail.com",
      "id": "8a3a6403-b615-469e-bb27-2bb942347c07",
      "sequenceId": "db64d402-04e3-444b-b28a-27cfb6bcf38c",
      "sequenceType": "INITIAL",
      "firstname": "Lucy",
      "lastname": "Carvajal",
      "domain": "gmail.com",
      "company": "Vendisys",
      "attributes": [
        {
          "{{first_name}}": "Lucy"
        },
        {
          "{{last_name}}": "Carvajal"
        },
        {
          "{{email}}": "hothinhunglun@gmail.com"
        },
        {
          "{{company}}": "Vendisys"
        },
        {
          "{{city}}": "New York"
        },
        {
          "{{country}}": "USA"
        }
      ],
      // "status": "INACTIVE",
      // "subStatus": "Sent",
      "prepared": 1648030644249,
      "lastTouchNumber": 1,
      "error": "Inappropriate options for sending email {\"smtp\":{\"s\":\"smtp.gmail.com\",\"p\":\"465\",\"u\":\"luzziibelle@gmail.com\",\"t\":true},\"proxyUrl\":\"http://vendisys:vendisys%40135@51.161.91.164:8181\"}",
      "inboxEmail": "luzziibelle@gmail.com",
      "created": "2022-03-23T10:17:24Z",
      "campaignName": "Fresh",
      "linkedinId": "",
      "sequenceName": "Initial",
      "accountId": "023d99cb-fec8-4b87-af8c-bef35c6a0a3f",
      "accountName": "Name"
    }
  }
}
interface MailBoxPageProps {
  setNavPaths: SetNavPathsFn;
}

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex style={{ borderBottom: `1px solid #eee` }} pt={10}>
    {children}
  </Flex>
);

const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="sub1" color="secondary" mr={20} style={{ width: 150 }}>
    {children}
  </Text>
);

const variableToText = (variable: string) =>
  capitalize(variable.replaceAll("{", "").replaceAll("}", ""));

const MailBoxDetail = ({ setNavPaths }: MailBoxPageProps) => {
  const [form] = Form.useForm();
  const location = useLocation<{ addEmailState?: string }>();
  const { setLoadingMessage } = useContext(AppContext);
  const { callApi } = useApi();
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const [attributes, setAttributes] = useState<
    { value: any; isEdit: boolean }[]
  >(() =>
    ([]).map((val) => ({ value: val, isEdit: false }))
  );
  const [prospect, setProspect] = useState<ProspectsType>()

  const fetchProspects = useCallback(async (id: string) => {
    setLoadingMessage("Fetching your Prospect detail ...");
    const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getUrl(id);

    const response = await callApi({
      url,
      method
    });
    if (response.success) {
      setAccountData({
        accountId: response?.data?.prospect.accountId,
        accountName: response?.data?.prospect.accountName
      });

      setProspect(response?.data?.prospect)
      setAttributes(
        (response?.data?.prospect.attributes || []).map((val: any) => ({ value: val, isEdit: false })
        ))
    } else {

    }
    setLoadingMessage("")
  }, [setLoadingMessage]);

  useEffect(() => {
    const prospectId = location.pathname.replace("/prospects/", "")
    if (prospectId) {
      fetchProspects(prospectId);
    }

  }, [location, fetchProspects]);

  useEffect(() => {
    const navPaths: NavPathType[] = [];
    if (accountData) {
      navPaths.push({
        label: accountData.accountName,
        linkTo: `/account/${accountData.accountId}/campaigns`,
      },
        { label: "Prospect" }
      );
    }

    setNavPaths(navPaths);
    return () => setNavPaths([]);
  }, [setNavPaths, location, accountData]);


  const handleMailboxSettingsSave = useCallback(
    async (settings: MailBoxSettingsType) => {
    },
    []
  );

  const onAttributeChange = useCallback((attrKey, newVal, index) => {
    setAttributes((attr) =>
      attr.map((attrElem, idx) =>
        index === idx ? { ...attrElem, value: { [attrKey]: newVal } } : attrElem
      )
    );
  }, []);

  return (
    <>
      <Div
        style={{
          padding: "20px",
          borderRadius: "4px",
          boxShadow: "rgb(0 0 0 / 4%) 2px 2px 8px 0px",
          background: "#fff",
          width: "750px",
          margin: "0 auto",
          maxWidth: "100%"
        }}
        className="container"
      >
        {prospect && <Flex direction="column" p="0 20px" w={600} m="0 auto">
          <Form
            scrollToFirstError
            onFinish={handleMailboxSettingsSave}
            form={form}
            requiredMark={false}
          >
            <Text variant="h6" align="center" block>
              Prospect Detail
            </Text>
            {prospect.email && (
              <Div>
                <Text variant="overline" color="secondary" block mb={5}>
                  <p className="text-email-center" style={{ fontSize: "12px", textTransform: 'none' }}>{prospect.email}</p>
                  {/* Email */}
                </Text>
              </Div>

            )}
            {prospect.campaignName && prospect.campaignId && (
              <Div mb={20}>
                <Text variant="overline" color="secondary" block mb={5}>
                  <p className="text-email-center" style={{ fontSize: "12px", textTransform: 'none', textDecoration: "underline" }}>
                    {<Link to={`/campaigns/${prospect.campaignId}/sequences`}>{prospect.campaignName}</Link>}
                  </p>
                </Text>
              </Div>
            )}
            <Div mt={5} mb={10}>
              <span className="ant-tag ant-tag-geekblue">Finished - Unsubscribed</span>
            </Div>
            <DetailWrapper>
              <DetailHeading>First Name</DetailHeading>
              <Form.Item
                name={"firstname"}
                initialValue={prospect && prospect.firstname}
                style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                ]}
              >
                <Input
                  style={{ color: "#000", border: "none", pointerEvents: "none" }}
                  bordered={true}
                  size="small"
                />
              </Form.Item>
            </DetailWrapper>
            <DetailWrapper>
              <DetailHeading>Last Name</DetailHeading>
              <Form.Item
                name={"lastname"}
                initialValue={prospect && prospect.lastname}
                style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                ]}
              >
                <Input
                  style={{ color: "#000", border: "none", pointerEvents: "none" }}
                  bordered={true}
                  size="small"
                />
              </Form.Item>
            </DetailWrapper>
            {/* <DetailWrapper>
              <DetailHeading>Email</DetailHeading>
              <Form.Item
                name={"email"}
                initialValue={prospect && prospect.email}
                style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input
                  style={{ color: "#000", border: "none", pointerEvents: "none" }}
                  bordered={true}
                  size="small"
                />
              </Form.Item>
            </DetailWrapper> */}
            <DetailWrapper>
              <DetailHeading>Company</DetailHeading>
              <Form.Item
                name={"company"}
                initialValue={prospect && prospect.company}
                style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter company",
                  },
                ]}
              >
                <Input
                  style={{ color: "#000", border: "none", pointerEvents: "none" }}
                  bordered={true}
                  size="small"
                />
              </Form.Item>
            </DetailWrapper>
            <DetailWrapper>
              <DetailHeading>Linkedin Id</DetailHeading>
              <Form.Item
                name={"linkedinId"}
                initialValue={prospect && prospect.linkedinId}
                style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter linkedinId",
                  },
                ]}
              >
                <Input
                  style={{ color: "#000", border: "none", pointerEvents: "none" }}
                  bordered={true}
                  size="small"
                />
              </Form.Item>
            </DetailWrapper>

            <>
              {attributes?.map((attribute: any, index: number) =>
                Object.keys(attribute.value).map((attrKey: string) => (
                  <>
                    {/* {console.log("attribute.value[attrKey]", attrKey, attribute.value[attrKey])} */}
                    {
                      attribute.value[attrKey].length ?
                        <DetailWrapper key={attrKey + index}>
                          <DetailHeading>{variableToText(attrKey)}</DetailHeading>
                          <Form.Item
                            name={variableToText(attrKey) + index}
                            initialValue={attribute.value[attrKey]}
                            style={{ marginBottom: `10px`, width: "100%", flex: "1" }}
                          >
                            <Input
                              style={{ color: "#000", border: "none", pointerEvents: "none" }}
                              bordered={true}
                              size="small"
                              onChange={(text) =>
                                onAttributeChange(attrKey, text.target.value, index)
                              }
                            />
                          </Form.Item>
                        </DetailWrapper> : null
                    }
                  </>
                ))
              )}
            </>
          </Form>
        </Flex>
        }
      </Div>
    </>
  );
};

export default withAppBar(MailBoxDetail);