export const changeFavicon = (logo?: string) => {
  if (logo) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      (link as any).rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    (link as any).href = logo;
  }
}
export const JSONParse = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (ex) {
    return {};
  }
};
export const getCookie = (name: string) => {
  const pattern = RegExp(name + "=.[^;]*");
  const matched = document.cookie.match(pattern);
  if (matched) {
    const cookie = matched[0].split("=");
    return cookie[1];
  }
  return false;
};
