import { API_ENDPOINTS } from "constants/api";
import { Dispatch } from "redux";
import {
  FETCH_CAMPAIGN_PROSPECTS_VARIABLES,
  FETCH_CAMPAIGN_PROSPECTS_VARIABLES_SUCCESS,
} from "./actionTypes";
import Axios from "utils/axios";

/* const res = {
  "success": true, "data": {
    "variables": [
      { "caseNeutralName": "{{company_address}}", "name": "Company Address", "isMandatory": false },
      { "caseNeutralName": "{{company_city}}", "name": "Company City", "isMandatory": false },
      { "caseNeutralName": "{{company_country}}", "name": "Company Country", "isMandatory": false },
      { "caseNeutralName": "{{company_state}}", "name": "Company State", "isMandatory": false },
      { "caseNeutralName": "{{day_1}}", "name": "Day 1", "isMandatory": false },
      { "caseNeutralName": "{{day_of_week}}", "name": "Day Of Week", "isMandatory": false },
      { "caseNeutralName": "{{egobooster}}", "name": "Egobooster", "isMandatory": false },
      { "caseNeutralName": "{{email}}", "name": "EmailNarendra", "isMandatory": true },
      { "caseNeutralName": "{{my_firstname}}", "name": "My Firstname", "isMandatory": false },
      { "caseNeutralName": "{{my_lastname}}", "name": "My Lastname", "isMandatory": false },
      { "caseNeutralName": "{{my_signature}}", "name": "My Signature", "isMandatory": false },
      { "caseNeutralName": "{{prospect_lastname}}", "name": "Prospect Lastname", "isMandatory": false },
      { "caseNeutralName": "{{prospect_state}}", "name": "Prospect State", "isMandatory": false },
    ]
  }
} */

class ProspectVariablesActions {
  static fetch = (campaignId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_CAMPAIGN_PROSPECTS_VARIABLES,
      payload: { campaignId },
    });

    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getProspectVariablesUrl(
      campaignId
    );

    try {
      const response = await Axios.callApi({
        url,
        method,
      });

      if (response.success) {
        dispatch({
          type: FETCH_CAMPAIGN_PROSPECTS_VARIABLES_SUCCESS,
          payload: { variables: response.data?.prospectVariables || [], campaignId },
        });
      }
    } catch (err) { }

    /* const response = await Axios.callApi({
          url,
          method,
        });
    
        if (!response || !response.success) {
          dispatch({
            type: FETCH_CAMPAIGN_PROSPECTS_VARIABLES_SUCCESS,
            payload: { variables: res.data?.variables || [], campaignId },
          });
        } */
  };
}

export default ProspectVariablesActions;
