import { Form, Input, Space, Button, notification, Row, Col, Select } from "antd";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { ReactNode, useContext, useEffect, useMemo, useState, useCallback } from "react";
import { isEmailValid, stringToArray } from "utils/validators";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import { EditOutlined } from "@ant-design/icons";
import { LIGHT_GRAY_COLOR } from "utils/colors";
import { useSelector } from "react-redux";
import { getCampaignTagsSelector } from "state/selectors";
import { Editor as WYSIWYGEditor } from "components/Froala";
import TemplateList from './TemplateList'

const { TextArea } = Input;

const DetailWrapper = ({ children }: { children: ReactNode }) => (
    <Flex pt={5} pb={0} direction="row">
        {children}
    </Flex>
);

const DetailHeading = ({ children }: { children: ReactNode }) => (
    <Text variant="sub1" color="secondary" mb={8} mr={10} style={{ flex: ' 0 0 33px', fontSize: "14px" }}>
        {children}
    </Text>
);
const DetailHeadingForm = ({ children }: { children: ReactNode }) => (
    <Text variant="sub1" color="secondary" mb={8} mr={10} style={{ flex: ' 0 0 33px', fontSize: "14px" }}>
        {children}
    </Text>
);


const CreateTemplateModal = ({
    onCancel,
    tagsData,
    campaignId,
    onFetch,
    isCreate,
    to,
    bcc,
    cc,
    canEdit = true,
    onBack,
    responseBody,
    mailData,
    subjectTitle
}: any) => {
    const [form] = Form.useForm();
    const { setLoadingMessage } = useContext(appContext);
    const { callApi } = useApi();
    const { tags } = useSelector(getCampaignTagsSelector(campaignId));
    const [disabledTextBoxs, setDisabled] = useState(false);

    const toInSingleString = useMemo(() => to?.join("\n"), [to]);

    const bccInSingleString = useMemo(() => bcc?.join("\n"), [bcc]);

    const ccInSingleString = useMemo(() => cc?.join("\n"), [cc]);
    const [toArr, setToArr] = useState('');
    const [bccArr, setBccArr] = useState(bccInSingleString);
    const [ccArr, setCcArr] = useState(ccInSingleString);
    const [bodyPara, setBodyPara] = useState(responseBody);
    const [name, setName] = useState(tagsData && tagsData.name);
    const [state, setState] = useState({
        responseEmail: '',
        emailArray: [],
        isSaveTemplate: false,
        subject: subjectTitle,
        openImportTemplateModal: false,
        selectedTemplate: null,
        error: { responseEmail: false, templatename: false, toArr: false }
    });
    let account_id = String(localStorage.getItem("account_id"));

    const { error, selectedTemplate, responseEmail, emailArray, isSaveTemplate, subject, openImportTemplateModal } = state
    const onChangeTo: React.ChangeEventHandler<HTMLTextAreaElement> = ({
        target: { value },
    }: React.ChangeEvent<HTMLTextAreaElement>) => {
        setState((prev) => ({ ...prev, error: { ...error, toArr: false } }))
        setToArr(value);
    };

    const onChangeBcc: React.ChangeEventHandler<HTMLTextAreaElement> = ({
        target: { value },
    }: React.ChangeEvent<HTMLTextAreaElement>) => {
        setBccArr(value);
    };
    console.log("toArrtoArrtoArr", toArr)
    const onChangeCC: React.ChangeEventHandler<HTMLTextAreaElement> = ({
        target: { value },
    }: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCcArr(value);
    };

    const onChangeSubject: React.ChangeEventHandler<HTMLTextAreaElement> = ({
        target: { value },
    }: React.ChangeEvent<HTMLTextAreaElement>) => {
        // setSubject(value);
        setState((prev) => ({ ...prev, subject: value }))
    };

    useEffect(() => {
        if (tagsData) {
            setName(tagsData.name)
        }
    }, [tagsData])

    const onChangeData: any = (value: string) => {
        setBodyPara(value);
    };
    useEffect(() => {
        if (Array.isArray(emailArray) && emailArray?.length > 0 && toInSingleString) {
            const findMailExist = emailArray.find((vl) => vl === toInSingleString)
            if (findMailExist) {
                setState((prev) => ({ ...prev, responseEmail: toInSingleString }))
            }
        }
    }, [toInSingleString, emailArray?.length])
    // console.log("isCreateisCreate", isCreate)
    // useEffect(() => {
    //     if (isCreate) {
    //         setName('')
    //         setToArr(toInSingleString)
    //         setCcArr(bccInSingleString)
    //         setBccArr(ccInSingleString)
    //         // setSubject('')
    //         setState((prev) => ({ ...prev, subject: '' }))
    //         // setBodyPara('')
    //         setDisabled(false);
    //     }
    // }, [isCreate, name, tags]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function hasDuplicates(array: any) {
        var valuesSoFar = [];
        for (var i = 0; i < array.length; ++i) {
            var value = array[i];
            if (valuesSoFar.indexOf(value) !== -1) {
                return true;
            }
            valuesSoFar.push(value);
        }
        return false;
    }

    const onEditPress = () => setDisabled(false);

    const setReadStatus = (value: any) => {
        setState((prev) => ({ ...prev, responseEmail: value, error: { ...error, responseEmail: false } }))
    }

    const fetchInboxes = useCallback(async () => {
        const accountId = mailData?.inboundMessage?.accountId || account_id
        const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl(accountId);
        const response = await callApi({
            url,
            method
        });
        if (response.success) {
            const allMailsIds = response?.data?.inboxes?.map((resMail: any) => resMail?.email);
            setState((prev) => ({ ...prev, emailArray: allMailsIds }))

        }
    }, [callApi]);


    useEffect(() => {
        fetchInboxes();
    }, []);


    const sendEmailCreateTemplate = async () => {
        // call here send emal api and on response open create template modal
        sendEmailOnly(true)
    }

    const checkValidation = () => {
        let hasErorr = false
        const toNewArray = stringToArray(toArr === undefined ? "" : toArr);
        const invalidTo: string[] = [];
        const bccNewArray = stringToArray(bccArr === undefined ? "" : bccArr);
        const invalidBcc: string[] = [];
        const ccNewArray = stringToArray(ccArr === undefined ? "" : ccArr);
        const invalidCc: string[] = [];
        let clorErr: any = error

        toNewArray.forEach((to) => {
            if (!isEmailValid(to)) {
                invalidTo.push(to);
            }
        });
        bccNewArray.forEach((bcc) => {
            if (!isEmailValid(bcc)) {
                invalidBcc.push(bcc);
            }
        });
        ccNewArray.forEach((cc) => {
            if (!isEmailValid(cc)) {
                invalidCc.push(cc);
            }
        })

        if (hasDuplicates(toNewArray)) {
            notification.error({
                message: `To contains duplicate emails`,
            });
            hasErorr = true
        }

        if (invalidTo.length) {
            notification.error({
                message: `${invalidTo.length} Invalid email's in To`,
            });
            hasErorr = true
        }

        if (hasDuplicates(bccNewArray)) {
            notification.error({
                message: `Bcc contains duplicate emails`,
            });
            hasErorr = true
        }

        if (invalidBcc.length) {
            notification.error({
                message: `${invalidBcc.length} Invalid email's in Bcc`,
            });
            hasErorr = true
        }

        if (hasDuplicates(ccNewArray)) {
            notification.error({
                message: `Cc contains duplicate emails`,
            });
            hasErorr = true
        }

        if (invalidCc.length) {
            notification.error({
                message: `${invalidCc.length} Invalid email's in Cc`,
            });
            hasErorr = true
        }
        if (!toArr) {
            clorErr["toArr"] = true
            hasErorr = true
        }
        if (!responseEmail) {
            let clorErr: any = error
            clorErr["responseEmail"] = true
            notification.error({ message: "Select From email" });
            hasErorr = true
        }
        setState((prev) => ({ ...prev, error: clorErr }))
        return hasErorr
    }
    const sendEmailOnly = async (isfromSendEmailAndCreatetemplate?: Boolean) => {
        // call here api to send mail onluy
        if (!checkValidation()) {
            const { url, method } = API_ENDPOINTS.MAILBOX.sendEmailTemplate();
            if (responseEmail) {
                const toArrayEmails = toArr.split(/[ ,]+/);
                setLoadingMessage("Sending email ...");
                const requestBody = {
                    "fromEmail": responseEmail,
                    "prospectId": mailData?.mailThread?.prospectId,
                    "accountId": mailData?.mailThread?.accountId,
                    "campaignId": mailData?.mailThread?.campaignId,
                    "subject": subject,
                    "html_body": bodyPara,
                    "tagIds": mailData?.mailThread?.tagIds,
                    // "plain_text_body": "Hi this is forward email\nnull",
                    // "tos": mailData?.mailThread?.tos || [],
                    tos: toArrayEmails?.map((email: string) => ({ address: email })),
                    "inboundMessageId": mailData?.mailThread?.inboundMessageId,
                    "campaignName": mailData?.mailThread?.campaignName
                }
                const response = await callApi({
                    url,
                    method,
                    data: requestBody
                });
                if (response.success) {
                    if (isfromSendEmailAndCreatetemplate) {
                        setState((prev) => ({ ...prev, isSaveTemplate: true }))
                    } else {
                        setState((prev) => ({ ...prev, isSaveTemplate: false, error: { responseEmail: false, templatename: false, toArr: false } }))
                        onBack()
                    }
                    setLoadingMessage("");
                    notification.success({ message: "Email sent successfully" });
                }
            } else {
                let clorErr: any = error
                clorErr["responseEmail"] = true
                setState((prev) => ({ ...prev, error: clorErr }))
                notification.error({ message: "Select From email" });
            }
        }
    }

    const createTemplate = async () => {
        if (name) {
            setLoadingMessage("Saving Template ...");

            const { url, method } = API_ENDPOINTS.MAILBOX.createDeliveryTemplate();
            const requestBody = {
                "name": name,
                "tos": toArr,
                "ccs": ccArr,
                "bccs": bccArr,
                "body": bodyPara
            }
            const response = await callApi({
                url,
                method,
                data: requestBody
            });
            if (response.success) {
                setState((prev) => ({ ...prev, isSaveTemplate: false, error: { responseEmail: false, templatename: false, toArr: false } }))
                onBack()
                setLoadingMessage("");
            }
        } else {
            setState((prev) => ({ ...prev, error: { responseEmail: false, templatename: true, toArr: false } }))
            setLoadingMessage("");
        }
    }

    const closeTemplate = () => {
        setState((prev) => ({ ...prev, isSaveTemplate: false, openImportTemplateModal: false }))
        onBack()
    }

    const importTemplate = () => {
        setState((prev) => ({ ...prev, isSaveTemplate: false, openImportTemplateModal: true }))
    }
    const proceedWithoutTemplate = () => {
        setState((prev) => ({ ...prev, isSaveTemplate: false, openImportTemplateModal: false }))
    }
    const handleTemplateSelection = async (template: any) => {
        const { url, method } = API_ENDPOINTS.MAILBOX.fetchSelectedTemplatedData(mailData?.mailThread?.inboundMessageId, template?.id, account_id);
        const response = await callApi({
            url,
            method,
        });
        if (response?.success) {
            // onBack()
            const resData = response?.data
            setState((prev) => ({
                ...prev,
                isSaveTemplate: false,
                selectedTemplate: response?.data,
                openImportTemplateModal: false,
                subject: resData?.subject,

            }))
            setToArr(resData?.tos?.map((vl: any) => vl?.address).join(', '))
            setBccArr(resData?.bccs?.map((vl: any) => vl?.address).join(', '))
            setCcArr(resData?.tos?.map((vl: any) => vl?.address).join(', '))
            setBodyPara(resData?.html_body)
        }
    }

    const onbackOfSaveTemplate = () => {
        setState((prev) => ({
            ...prev,
            isSaveTemplate: false,
        }))
    }
    return (
        <>{
            openImportTemplateModal ?
                <TemplateList
                    onCancel={closeTemplate}
                    campaignId={mailData?.mailThread?.campaignId}
                    proceedWithoutTemplate={proceedWithoutTemplate}
                    handleTemplateSelection={handleTemplateSelection}
                /> :
                <>
                    <Flex justify="space-between">
                        <Text variant="h6" pl={20} mb={5}>{!isSaveTemplate ? "Forward Email" : "Save Template"} </Text>
                        <Div mr={10}>
                            <Button type="link" onClick={importTemplate} >
                                <span style={{ fontSize: "13px" }}>Import Template</span>
                            </Button>
                        </Div>
                    </Flex>

                    <Flex direction="column" p="0 20px" >
                        <Form
                            scrollToFirstError
                            form={form}
                            requiredMark={false}
                        >
                            <Div>
                                {disabledTextBoxs === true && (
                                    <Flex justify="space-between" mb={10} mr={10} mt={20}>
                                        <Button
                                            disabled={!canEdit}
                                            icon={<EditOutlined />}
                                            type="default"
                                            onClick={onEditPress}
                                        >
                                            Edit
                                        </Button>
                                    </Flex>
                                )}


                                <Row>
                                    <Col span={12}>
                                        {(isSaveTemplate) && <DetailWrapper>
                                            <DetailHeading>Template name</DetailHeading>
                                            <Form.Item
                                                name={"Tag"}
                                                initialValue={name}
                                                style={{ marginLeft: "0", width: "100%" }}
                                            >
                                                <Input
                                                    onChange={(e) => setName(e.target.value)}
                                                    style={{ color: "#000", width: "100%" }}
                                                    size="middle"
                                                    disabled={disabledTextBoxs}
                                                    bordered={disabledTextBoxs ? false : true}
                                                />
                                                {error?.templatename && <span style={{ color: 'red' }}>Template name is required</span>}

                                            </Form.Item>
                                        </DetailWrapper>}
                                        {(!isSaveTemplate) &&
                                            <Div mb={10}>
                                                <DetailWrapper>
                                                    <DetailHeadingForm>From</DetailHeadingForm>
                                                    <div>
                                                        <Select
                                                            value={responseEmail}
                                                            style={{ width: 336, }}
                                                            onChange={setReadStatus}
                                                            placeholder="response TO field email"
                                                            status={error?.responseEmail ? "error" : ""}


                                                        >{
                                                                emailArray?.map((emai: any) => {
                                                                    return <Select.Option value={emai}>{emai}</Select.Option>
                                                                })
                                                            }
                                                        </Select>
                                                        {error?.responseEmail && <span style={{ color: 'red', width: "100%", fontSize: "12px" }}>Please select "From" field.</span>}
                                                    </div>

                                                </DetailWrapper>

                                            </Div>
                                        }
                                    </Col>

                                    <Col span={12}>
                                        <Div
                                            style={{
                                                //borderBottom: `1px solid ${LIGHT_GRAY_COLOR}`,
                                                paddingBottom: 0,
                                                marginLeft: "20px",

                                            }}
                                        >
                                            <DetailWrapper>
                                                <DetailHeading>To</DetailHeading>
                                                <TextArea
                                                    rows={1}
                                                    value={toArr}
                                                    onChange={onChangeTo}
                                                    style={{ color: "black" }}
                                                    disabled={disabledTextBoxs}
                                                    bordered={disabledTextBoxs ? false : true}
                                                    prefix="To"
                                                />
                                            </DetailWrapper>
                                            {error?.toArr && <span style={{ color: 'red', width: "100%", marginLeft: "43px", fontSize: "12px" }}>This field cannot be empty.</span>}
                                        </Div>
                                    </Col>
                                </Row>



                                <Row>
                                    <Col span={12}>
                                        <Div
                                            style={{
                                                //borderBottom: `1px solid ${LIGHT_GRAY_COLOR}`,
                                                paddingBottom: 5,
                                            }}
                                        >
                                            <DetailWrapper>
                                                <DetailHeading>Cc</DetailHeading>
                                                <TextArea
                                                    rows={1}
                                                    value={ccArr}
                                                    style={{ color: "black" }}
                                                    onChange={onChangeCC}
                                                    disabled={disabledTextBoxs}
                                                    bordered={disabledTextBoxs ? false : true}
                                                />
                                            </DetailWrapper>
                                            <Flex mt={1} ml={44}  >
                                                <Text variant="caption" color="secondary" style={{ fontSize: "10px" }}>
                                                    Email addresses can be seperated by commas or spaces.
                                                </Text>{" "}
                                            </Flex>
                                        </Div>
                                    </Col>
                                    <Col span={12}>
                                        <Div
                                            style={{
                                                //borderBottom: `1px solid ${LIGHT_GRAY_COLOR}`,
                                                paddingBottom: 5,
                                                marginLeft: 20
                                            }}
                                        >
                                            <DetailWrapper>
                                                <DetailHeading>Bcc</DetailHeading>
                                                <TextArea
                                                    rows={1}
                                                    value={bccArr}
                                                    onChange={onChangeBcc}
                                                    style={{ color: "black" }}
                                                    disabled={disabledTextBoxs}
                                                    bordered={disabledTextBoxs ? false : true}
                                                />
                                            </DetailWrapper>

                                        </Div>
                                    </Col>
                                </Row>

                                {!isSaveTemplate && <Div
                                    style={{
                                        borderBottom: `1px solid ${LIGHT_GRAY_COLOR}`,
                                        paddingBottom: 5,
                                        marginTop: "15px"
                                    }}
                                >
                                    <DetailWrapper>
                                        {/* <DetailHeading>Subject</DetailHeading> */}
                                        <TextArea
                                            rows={1}
                                            value={subject}
                                            onChange={onChangeSubject}
                                            style={{ color: "black" }}
                                            disabled={disabledTextBoxs}
                                            bordered={disabledTextBoxs ? false : true}
                                            placeholder="Subject"
                                        />
                                    </DetailWrapper>

                                </Div>}
                                <Div
                                    style={{
                                        borderBottom: `1px solid ${LIGHT_GRAY_COLOR}`,
                                        paddingBottom: 5,
                                    }}
                                >
                                    <DetailWrapper>
                                        {/* <DetailHeading>Body</DetailHeading> */}
                                        {disabledTextBoxs ?
                                            <TextArea
                                                //  rows={4}
                                                autoSize={{ minRows: 2, maxRows: 8, }}
                                                value={bodyPara}
                                                style={{ color: "black" }}
                                                disabled={disabledTextBoxs}
                                                bordered={disabledTextBoxs ? false : true}
                                                placeholder="Enter email body"
                                            /> : <Div mh={300} id={"editor"} style={{ width: '100%' }}>
                                                <WYSIWYGEditor
                                                    value={bodyPara}
                                                    onChange={onChangeData}
                                                    config={{
                                                        height: 300,
                                                        toolbarBottom: true,
                                                        imageMaxSize: 1024 * 1024 * 1,
                                                        imageEditButtons: ["imageDisplay", "imageAlign", "imageRemove"],
                                                        placeholderText: "Enter email body",
                                                        pastePlain: true,
                                                    }}
                                                    moreRichButtons={[]}
                                                    moreTextButtons={[
                                                        "textColor",
                                                        "bold",
                                                        "italic",
                                                        "backgroundColor",
                                                        "underline",
                                                        "insertLink",
                                                        "fontFamily",
                                                        "fontSize",
                                                    ]}
                                                    moreTextButtonsVisible={5}
                                                    tag="textarea"

                                                />
                                            </Div>}
                                    </DetailWrapper>
                                </Div>
                            </Div>
                            <Flex mb={5} justify="flex-end" mt={0}>
                                <Space>
                                    <Flex mb={10} justify="space-between" mt={10}>

                                        {isSaveTemplate ? <>
                                            <Space>
                                                <Button htmlType="button" type="text" onClick={onbackOfSaveTemplate}>
                                                    Back
                                                </Button>
                                            </Space>
                                            <Space>
                                                <Button htmlType="button" type="primary" onClick={createTemplate} style={{ marginLeft: "15px" }}>
                                                    Save template
                                                </Button>
                                            </Space>
                                        </> : <>
                                            <Space>
                                                <Button htmlType="button" onClick={sendEmailCreateTemplate}>
                                                    Send email and create template
                                                </Button>
                                            </Space>
                                            <Space>
                                                <Button htmlType="button" type="primary" onClick={() => sendEmailOnly(false)} style={{ marginLeft: "15px" }}>
                                                    Send Email
                                                </Button>
                                            </Space>

                                        </>}

                                    </Flex>
                                </Space>
                            </Flex>
                        </Form>
                    </Flex>
                </>
        }
        </>
    );
};

export default CreateTemplateModal;