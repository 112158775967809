import Icon, {
  NotificationOutlined, RollbackOutlined,
  SendOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { useAuth } from "auth/AuthProvider";
import { ReactComponent as ClickSvg } from "images/click.svg";
import { ReactComponent as MailOpenSvg } from "images/open-mail.svg";
import { useMemo } from "react";
import { EventEnum } from "types/Event";
import {
  FLAT_BLUE_COLOR, FLAT_PURPLE_COLOR, FLAT_TURQUOISE_COLOR
} from "utils/colors";

export interface EventIconProps {
  type: EventEnum;
}

const MailOpenIcon = (props: any) => (
  <Icon component={MailOpenSvg} {...props} />
);

const ClickIcon = (props: any) => <Icon component={ClickSvg} {...props} />;

const EventIcon = ({ type }: EventIconProps) => {
  const { theme } = useAuth();

  const Icon = useMemo(() => {
    switch (type) {
      case EventEnum.BOUNCED:
        return <WarningOutlined style={{ color: theme.antdConf.errorColor, fontSize: 16 }} />;
      case EventEnum.REPLIED:
        return (
          <RollbackOutlined style={{ color: theme.antdConf.successColor, fontSize: 16 }} />
        );
      case EventEnum.OPENED:
        return (
          <MailOpenIcon style={{ color: FLAT_TURQUOISE_COLOR, fontSize: 16 }} />
        );
      case EventEnum.SENT:
        return (
          <SendOutlined style={{ color: FLAT_PURPLE_COLOR, fontSize: 16 }} />
        );
      case EventEnum.CLICKED:
        return <ClickIcon style={{ color: FLAT_BLUE_COLOR, fontSize: 20 }} />;
      default:
        return <NotificationOutlined />;
    }
  }, [type]);

  return Icon;
};

export default EventIcon;
