import { CopyOutlined, DeleteOutlined, FileDoneOutlined, InfoCircleOutlined, MenuOutlined, MoreOutlined, UndoOutlined } from "@ant-design/icons";
import { Dropdown, Form, Menu, Modal, notification, Select, Skeleton, Switch, Tooltip } from "antd";
import { useAuth } from "auth/AuthProvider";
import { NavPathType } from "components/AppBar";
import ChangeCampaignStatusModal from "components/Campaign/ChangeCampaignStatusModal";
import CloneCampaign from "components/Campaign/CloneCampaign";
import { API_ENDPOINTS } from "constants/api";
import LogDetailsModal from "containers/LogDetailsModal";
import appContext from "contexts/appContext";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import useApi from "hooks/useApi";
import { ReactComponent as SwitchSvg } from "images/switch.svg";
import { Moment } from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Redirect, Route, Switch as RouterSwitch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import CampaignActions from "state/actions/campaign";
import CampaignVariableActions from "state/actions/campaignVariables";
import ProspectVariablesActions from "state/actions/prospectVariables";
import SpinnerActions from "state/actions/spinner";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { AccountSummaryType } from "types/Accounts";
import { CampaignStatusType, CampaignType } from "types/Campaign";
import { getCampaignResponseText } from "utils/campaign";
import Mailboxes from "./Mailboxes";
import Prospects from "./Prospects";
// import Responses from "./Responses";
import SequencesList from "./Sequences/SequenceList";
import SequenceTouches from "./Sequences/SequenceTouches";
import Settings from "./Settings";
import SpinnerList from "./Spinners/SpinnerList";
import { identity } from "lodash";
import { useParams } from "react-router";
import TagsV2 from "./TagV2";
import Stats from "./Stats";
import TagP from "./Tag";
import { Webhook } from "./Webhook";
import Notification from "./Notification";
import StatsDeprecated from "./StatsDeprecated";
import NewStats from "./NewStats";
const NOTIFICATION_KEY = "METRIC_DOWNLOAD_INFO";
interface CampaignDetailProps {
  setNavPaths: SetNavPathsFn;
}

const CampaignDetail = ({ setNavPaths }: CampaignDetailProps) => {
  const dispatch = useDispatch();

  const { callApi } = useApi();
  const { user, theme } = useAuth();
  const { setLoadingMessage } = useContext(appContext);
  const [campaignDetail, setCampaignDetail] = useState<CampaignType | null>(null);
  const location = useLocation();
  const match = useRouteMatch<{ id: string; sequenceId?: string }>();
  const history = useHistory();
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [timeZone, setTimeZone] = useState("");
  const [isUserAgent, setUserAgent] = useState(false);
  const [showCampaignStatusModal, setShowCampaignStatusModal] = useState(false);
  const [showCampaignCloneModal, setCampaignCloneStatusModal] = useState(false);
  const [showCampaignRestartModal, setCampaignRestartModal] = useState(false);
  const [showCampaignCompleteModal, setShowCampaignCompleteModal] = useState(false);
  const [accounts, setAccounts] = useState<AccountSummaryType[]>([]);
  const [accountList, setAccountList] = useState<AccountSummaryType[]>([]);
  const [showAccountList, setshowAccountList] = useState(false);
  const [showLogModal, setshowLogModal] = useState(false);
  const [mailBoxProviderForm] = Form.useForm();
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const [providers, setProviders] = useState<string[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPath, setIsPath] = useState("");
  const [campaignFetched, setCampaignFetched] = useState(false);
  const [selectdAccountName, setselectdAccountName] = useState("");
  const [selectdAccountId, setselectdAccountId] = useState("");
  // const paramsSS = useParams();
  // console.log("id::::location.state:::", location.state);
  const getAccountName = useMemo((): any => {
    return location.state;
  }, []);
  const { Option } = Select;

  const selectedKeys = useMemo(() => {
    return [location.pathname.replace("/sequenceId", "")];
  }, [location]);

  const campaignId = useMemo(() => {
    const {
      params: { id }
    } = match;
    return id;
  }, [match]);

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const fetchCampaignDetail = useCallback(async () => {
    const {
      params: { id }
    } = match;
    // setLoadingMessage("Fetching campaign details ...");
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getDetailUrl(id);

    const response = await callApi({ url, method });
    if (response.success) {
      const res = response;
      // console.log("response.data.campaign", res.data.campaign);
      // const inboxes = res.data.inboxes.map((item) => (item.id));
      setAccountData({
        accountId: res.data.campaign.accountId,
        accountName: res.data.campaign.accountName
        // inboxes: res.data.inboxes,
      });
      localStorage.setItem("account_id", String(res.data.campaign.accountId));
      localStorage.setItem("accountName", String(res.data.campaign.accountName));
      // localStorage.setItem("inboxes", String(res.data.inboxes));
      // const campaignData = {...res.data.campaign, inboxIds: inboxes };
      setCampaignDetail(res.data.campaign as CampaignType);
      dispatch(CampaignActions.fetchCampaignSuccess(res.data.campaign));
    }
    setCampaignFetched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi, match.params]);

  const dispatchFetchCampaignVariables = useCallback(() => {
    dispatch(CampaignVariableActions.fetch(campaignId));
  }, [campaignId, dispatch]);

  const dispatchFetchProspectVariables = useCallback(() => {
    dispatch(ProspectVariablesActions.fetch(campaignId));
  }, [campaignId, dispatch]);

  const dispatchFetchTimezones = useCallback(() => {
    dispatch(CampaignActions.fetchTimezones(campaignId));
  }, [campaignId, dispatch]);

  const dispatchFetchSpinTypes = useCallback(() => {
    dispatch(SpinnerActions.fetch());
  }, [dispatch]);

  const dispatchFetchSequences = useCallback(() => {
    dispatch(CampaignActions.fetchSequences(campaignId));
  }, [dispatch, campaignId]);

  useEffect(() => {
    // console.log("match.params.id,", match.params.id);
    fetchCampaignDetail();
    dispatchFetchCampaignVariables();
    // dispatchFetchTimezones();
    dispatchFetchProspectVariables();
    dispatchFetchSpinTypes();
    dispatchFetchSequences();
  }, [
    match.params.id,
    // fetchCampaignDetail,
    dispatchFetchCampaignVariables,
    dispatchFetchProspectVariables,
    // dispatchFetchTimezones,
    // dispatchFetchSpinTypes,
    dispatchFetchSequences
  ]);

  useEffect(() => {
    if (user) {
      setUserAgent(isAgency(user));
    }
  }, [user]);

  useEffect(() => {
    if (isLoading && isActive) {
      // setIsPath("responses");
      setIsPath("sequences");

      setLoadingMessage("");
    } else if (isLoading && !isActive) {
      setIsPath("sequences");
      setLoadingMessage("");
    }
  }, [isLoading, isActive]);

  const isAgency = (user: any) => {
    // return !!user?.attributes?.['custom:agency_user'];
    //return !!user?.attributes?.["custom:agency"];
    return true;
  };
  useEffect(() => {
    if (isUserAgent) {
      if (getAccountName && Object.keys(getAccountName).length) {
        // setAccountData({
        //   accountId: getAccountName.accountId,
        //   accountName: getAccountName.name
        // });
        // localStorage.setItem("accountId", String(getAccountName.accountId));
        // localStorage.setItem("accountName", String(getAccountName.name));
      } else {
        // setAccountData({
        //   accountId: String(localStorage.getItem("account_id")),
        //   accountName: String(localStorage.getItem("accountName"))
        // });
      }
    }
  }, [getAccountName, setAccountData, isUserAgent]);

  useEffect(() => {
    const navPaths: NavPathType[] = [];
    if (isUserAgent) {
      // navPaths.push({ label: "Accounts", linkTo: "/accounts" });
      if (accountData && accountData.accountId) {
        navPaths.push({
          label: accountData.accountName /* || localStorage.getItem('accountName') */,
          linkTo: `/account/${accountData.accountId}/campaigns`
        });
      }
    } else {
      navPaths.push({ label: "Campaigns", linkTo: "/campaigns" });
    }
    if (campaignDetail) {
      // console.log("campaignDetail::::::::2111111111::", campaignDetail);
      if (campaignDetail.status === "Active") {
        setIsActive(true);
      }
      setIsLoading((prev) => !prev);
      // setLoadingMessage("")
      const [, , pathFragment] = location.pathname.split("/").filter((p) => !!p);
      navPaths.push({ label: campaignDetail.name });
      // console.log("pathFragment", pathFragment)
      switch (pathFragment) {
        case "spinners":
          navPaths.push({
            label: "Spinners"
          });
          break;
        case "sequences":
          navPaths.push({
            label: "Sequences"
          });
          break;
        case "mailboxes":
          navPaths.push({ label: "Mailboxes" });
          break;
        case "prospects":
          navPaths.push({ label: "Prospects" });
          break;
        case "settings":
          navPaths.push({ label: "Settings" });
          break;
        case "tag":
          navPaths.push({ label: "Tags" });
          break;
        // case "stats":
        //   navPaths.push({ label: "Stats" });
        //   break;
        case "stats-deprecated":
          navPaths.push({ label: "Stats Deprecated" });
          break;
        // case "responses":
        //   navPaths.push({ label: "Responses" });
        //   break;
        case "logs":
          navPaths.push({ label: "Logs" });
          break;
        case "stats":
          navPaths.push({ label: "Stats" });
          break;
        default:
          break;
      }
    }

    setNavPaths(navPaths);
  }, [setNavPaths, setIsLoading, match.params?.id, campaignDetail, location, campaignId, accountData]);

  useEffect(() => {
    return () => setNavPaths([]);
  }, []);

  const toggleShowDeleteConfirmModal = useCallback(() => setShowDeleteConfirmModal((value) => !value), []);

  const confirmDelete = useCallback(async () => {
    if (!campaignDetail) {
      return;
    }
    toggleShowDeleteConfirmModal();
    const { id } = campaignDetail;
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getDeleteUrl(id);
    setLoadingMessage(`Deleting ${campaignDetail.name} ...`);

    const response = await callApi({ url, method });
    setLoadingMessage("");
    if (response.success) {
      notification.success({ message: "Your request to delete campaign has been submitted and will be processed shortly!" });
      history.push(`/account/${accountData.accountId}/campaigns`);
    }
  }, [campaignDetail, setLoadingMessage, callApi, history, toggleShowDeleteConfirmModal]);

  const onDelete = useCallback(() => toggleShowDeleteConfirmModal(), [toggleShowDeleteConfirmModal]);

  const onClone = useCallback(
    async (provider: any, campaignName: string) => {
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getCloneUrl(campaignId);
      setLoadingMessage("Cloning campaign");
      try {
        const response = await callApi({
          url,
          method,
          data: { name: campaignName, sections: provider }
        });

        if (response.success) {
          onCloneToggle();
          notification.success({
            message: "Campaign cloned"
          });
          history.push(`/campaigns/${response.data.newUuid}`);
        }
      } catch (err) {
      } finally {
        setLoadingMessage("");
      }
    },
    [providers, campaignId, history, callApi, setLoadingMessage]
  );

  const onCloneToggle = useCallback(async () => {
    setCampaignCloneStatusModal((prev) => !prev);
  }, []);

  const onRestart = useCallback(async () => {
    setCampaignRestartModal((prev) => !prev);
  }, []);

  const providesType = useCallback(
    async (type: any) => {
      setProviders(type);
    },
    [setProviders]
  );

  const onDownloadProspect = useCallback(
    async (raw = false) => {
      notification.info({
        message: `Downloading prospect ${raw ? "details" : "summary"}`,
        description: "Please wait while download completes",
        key: NOTIFICATION_KEY,
        duration: 10000000
      });
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.downloadProspect(campaignId, raw);

      const response = await callApi({
        url,
        method,
        raw: true,
        responseType: "blob"
      });

      if (response.success) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${campaignDetail?.name ?? "file"}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }, 0);
        notification.close(NOTIFICATION_KEY);
        notification.success({
          message: "Download success"
        });
      } else {
        notification.close(NOTIFICATION_KEY);
      }
    },
    [campaignId, callApi]
  );

  const confirmComplete = useCallback(async () => {
    if (!campaignDetail) {
      return;
    }
    notification.destroy();
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getStatusChangeUrl(campaignDetail.id);
    setLoadingMessage("Marking the campaign as completed ...");

    const payload: { status: CampaignStatusType } = { status: "Completed" };
    const response = await callApi({
      url,
      method,
      data: payload
    });

    if (response.success) {
      notification.success({
        message: "Success",
        description: `Your request has been submitted and the campaign will be restarted shortly.`
      });
      fetchCampaignDetail();
    }

    setLoadingMessage("");
  }, [campaignDetail, callApi, setLoadingMessage, fetchCampaignDetail]);

  useEffect(() => {
    if (campaignDetail) {
      mailBoxProviderForm.setFieldsValue({
        campaignName: `Clone of ${campaignDetail?.name}`
      });
    }
  }, [campaignDetail]);

  const restartHandler = useCallback(async () => {
    if (!campaignDetail) {
      return;
    }
    notification.destroy();
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getStatusChangeUrl(campaignDetail.id);
    setLoadingMessage("Marking the campaign as completed ...");

    const d = new Date();
    const datestring =
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2) +
      "/" +
      d.getFullYear() +
      " " +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2);

    const payload = {
      active: true,
      status: "Restart",
      startDate: new Date().getTime(),
      startTime: datestring,
      timezone: campaignDetail.timezone,
      deleteAll: false
    };

    const response = await callApi({
      url,
      method,
      data: payload
    });

    if (response.success) {
      notification.success({
        message: "Success",
        description: `Your request has been submitted and the campaign will be restarted shortly.`
      });
      fetchCampaignDetail();
    }

    setLoadingMessage("");
  }, [campaignDetail, callApi, setLoadingMessage, fetchCampaignDetail]);
  const toggleMoveToAnotherAccount = () => {
    setshowAccountList((prev) => !prev);
    setselectdAccountName("");
    setselectdAccountId("");
  };

  const toggleShowLogModal = () => {
    setshowLogModal((prev) => !prev);
  };

  const fetchAccounts = useCallback(async () => {
    const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";

    const { url, method } = API_ENDPOINTS.ACCOUNTS.getListUrl(uId || "");
    const response = await callApi({
      url,
      method
    });

    // setselectdAccountName(String(localStorage.getItem("accountName")))
    // setselectdAccountId(String(localStorage.getItem("account_id")))

    if (response.success) {
      if (response.data.accounts.length) {
        let newAccountList = [];
        newAccountList = response.data.accounts.filter((elements: { id: string }) => elements.id !== String(localStorage.getItem("account_id")));
        newAccountList = newAccountList.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setAccounts(newAccountList);
      }
    }
  }, [setselectdAccountName, setAccounts, setselectdAccountId]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const changeAccount = useCallback(
    async (e) => {
      if (accounts) {
        let accountName = accounts.filter((elements: { id: string }) => elements.id === e);
        setselectdAccountName(accountName[0].name);
        setselectdAccountId(accountName[0].id);
      }
    },
    [setselectdAccountName, setselectdAccountId, accounts]
  );

  const moveToAnotherAccount = useCallback(async () => {
    if (!selectdAccountId) {
      notification.error({ message: "Please select account." });
      return;
    }

    setLoadingMessage("Move Campaign to another Account");
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.moveCampaign(campaignId);
    const response = await callApi({
      url,
      method,
      data: { accountId: selectdAccountId, action: "ChangeAccount" }
    });

    if (response && response.success) {
      setAccountData({
        accountId: selectdAccountId,
        accountName: selectdAccountName
      });

      localStorage.setItem("account_id", selectdAccountId);
      localStorage.setItem("accountName", selectdAccountName);
    }
    setLoadingMessage("");
    setshowAccountList(false);
  }, [selectdAccountId, selectdAccountName]);

  const DropDownMenu = () => {
    return (
      <Menu>
        <Menu.Item style={{ color: theme.blackColor }} onClick={onCloneToggle}>
          <CopyOutlined /> Clone Campaign
        </Menu.Item>

        {campaignDetail?.status !== "Active" && (
          <Menu.Item style={{ color: theme.blackColor }} onClick={onRestart}>
            <UndoOutlined /> Restart Campaign
          </Menu.Item>
        )}

        {/* <Menu.Item style={{ color: theme.blackColor }} onClick={() => onDownloadProspect()}>
            <ApartmentOutlined /> Download Prospects Summary
          </Menu.Item>
          <Menu.Item style={{ color: theme.blackColor }} onClick={() => onDownloadProspect(true)}>
            <FundOutlined /> Download Prospects Detail
          </Menu.Item> */}
        {campaignDetail && campaignDetail.status && campaignDetail.status !== "Completed" ? (
          <Menu.Item style={{ color: theme.blackColor }} onClick={() => setShowCampaignCompleteModal((prev) => !prev)}>
            <FileDoneOutlined /> Mark as Completed
          </Menu.Item>
        ) : null}
        {/* {campaignDetail?.status === "Inactive" && (
          <Menu.Item style={{ color: theme.blackColor }} onClick={toggleMoveToAnotherAccount}>
            <span className="pta-4px pal-2px me-2px">
              <SwitchSvg />
            </span>
            Move to Another Account
          </Menu.Item>
        )} */}
        <Menu.Item style={{ color: theme.blackColor }} onClick={toggleShowLogModal}>
          <InfoCircleOutlined /> Logs
        </Menu.Item>
        <Menu.Item style={{ color: theme.antdConf.errorColor }} onClick={onDelete}>
          <DeleteOutlined /> Delete Campaign
        </Menu.Item>
      </Menu>
    );
  };

  const toggleShowCampaignStausModal = useCallback(() => {
    setStartDate(null);
    setShowCampaignStatusModal((prev) => !prev);
  }, []);

  const onCampaignStatusChange = useCallback(async () => {
    if (!campaignDetail) {
      return;
    }
    notification.destroy();

    const active = campaignDetail.status === "Active";

    if (!active && !startDate) {
      notification.error({
        message: "Please select start date"
      });
      return;
    }

    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getStatusChangeUrl(campaignDetail.id);
    setLoadingMessage("Changing the campaign status ...");

    let payload: {
      status: CampaignStatusType;
      startDate?: string;
    } = {
      status: active ? "Inactive" : "Active"
    };

    if (startDate) {
      payload.startDate = startDate.format("MM/DD/YYYY HH:mm");
    }

    const response = await callApi({
      url,
      method,
      data: payload
    });

    if (response.success) {
      notification.success({
        message: "Success",
        description: `Campaign ${getCampaignResponseText(!active)}d successfully!`
      });
      notification.success({
        message: "Your campaign has been activated successfully and the emails will start going out as per the scheduled time."
      });
      fetchCampaignDetail();
      toggleShowCampaignStausModal();
    }

    setLoadingMessage("");
  }, [campaignDetail, startDate, timeZone, setLoadingMessage, callApi, toggleShowCampaignStausModal, fetchCampaignDetail]);

  return (
    <>
      <Div>
        <Flex position="relative" align="center">
          <Menu
            mode="horizontal"
            selectedKeys={selectedKeys}
            style={{
              background: "transparent",
              borderBottom: "none",
              flex: 1
            }}
          >
            <Menu.Item key={`${match.url}/spinners`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/spinners`}>Spinners</Link>
            </Menu.Item>
            <Menu.Item key={`${match.url}/sequences`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/sequences`}>Sequences</Link>
            </Menu.Item>
            <Menu.Item key={`${match.url}/mailboxes`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/mailboxes`}>Mailboxes</Link>
            </Menu.Item>
            <Menu.Item key={`${match.url}/prospects`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/prospects`}>Prospects</Link>
            </Menu.Item>
            <Menu.Item key={`${match.url}/settings`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/settings`}>Settings</Link>
            </Menu.Item>
            {/* <Menu.Item key={`${match.url}/tag`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/tag`}>Tags</Link>
            </Menu.Item> */}
            {/* <Menu.Item key={`${match.url}/stats`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/stats`}>Stats</Link>
            </Menu.Item> */}
            <Menu.Item key={`${match.url}/stats`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/stats`}>Stats</Link> {/* Stats V2 (beta) */}
            </Menu.Item>
            {/* <Menu.Item key={`${match.url}/stats-deprecated`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/stats-deprecated`}>Stats Deprecated</Link>
            </Menu.Item> */}
            {/* <Menu.Item key={`${match.url}/responses`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/responses`}>Responses</Link>
            </Menu.Item> */}
            {/* <Menu.Item key={`${match.url}/notifications`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/notifications`}>Notifications</Link>
            </Menu.Item> */}
            <Menu.Item key={`${match.url}/webhooks`} style={{ margin: 0, marginRight: 20 }}>
              <Link to={`${match.url}/webhooks`}>Webhooks</Link>
            </Menu.Item>

            {/* <Menu.Item key={`${match.url}/logs`} style={{ margin: 0 }}>
              <Link to={`${match.url}/logs`}>Logs</Link>
            </Menu.Item> */}
          </Menu>
          <Flex align="inherit" ml="auto" p={10}>
            {campaignDetail?.status !== "Completed" && (
              <Tooltip placement="bottom" title={campaignDetail?.status === "Active" ? "Click to inactivate" : "Click to activate"}>
                <Flex mr={10}>
                  <Switch size="default" checked={campaignDetail?.status === "Active"} onChange={toggleShowCampaignStausModal} />
                </Flex>
                <Text variant="caption" mt={5} ml={5}>
                  {campaignDetail?.status === "Active" ? "Active" : "Inactive"}
                </Text>
              </Tooltip>
            )}
            <Dropdown overlay={DropDownMenu} trigger={["click"]} placement="bottomRight">
              <MenuOutlined />
            </Dropdown>
          </Flex>
        </Flex>
        {!campaignFetched && <Skeleton active round paragraph={{ rows: 6 }} />}
        <RouterSwitch>
          <Route path={`${match.path}/spinners`} exact>
            {campaignDetail && <SpinnerList campaignId={campaignId} />}
          </Route>
          <Route path={`${match.path}/sequences`} exact>
            {campaignDetail && <SequencesList campaignId={campaignId} />}
          </Route>
          <Route path={`${match.path}/sequences/:sequenceId`} exact>
            {campaignDetail && <SequenceTouches campaignDetail={campaignDetail} />}
          </Route>
          <Route path={`${match.path}/mailboxes`} exact>
            {campaignDetail && <Mailboxes campaignDetail={campaignDetail} afterSave={fetchCampaignDetail} />}
          </Route>
          {/* <Route path={`${match.path}/logs`} exact>
            {campaignDetail && <LogDetails id={campaignDetail.id} logType='Campaign' />}
          </Route> */}
          <Route path={`${match.path}/prospects`} exact>
            {campaignDetail && <Prospects campaignId={campaignId} />}
          </Route>
          <Route path={`${match.path}/settings`} exact>
            {campaignDetail && <Settings campaignDetail={campaignDetail} afterSave={fetchCampaignDetail} />}
          </Route>
          {/* <Route path={`${match.path}/tag`} exact>
            {campaignDetail && <TagP campaignId={campaignId} />}
          </Route> */}
          {/* <Route path={`${match.path}/stats`} exact> */}
          {/* {campaignDetail && <Stats campaignId={campaignId} status={campaignDetail.status} prospects={campaignDetail.prospects} />} */}
          {/* {campaignDetail && <NewStats campaignId={campaignId} status={campaignDetail.status} prospects={campaignDetail.prospects} />} */}

          {/* </Route> */}
          <Route path={`${match.path}/stats`} exact>
            {/* {campaignDetail && <Stats campaignId={campaignId} status={campaignDetail.status} prospects={campaignDetail.prospects} />} */}
            {campaignDetail && (
              <NewStats
                campaignId={campaignId}
                life={
                  campaignDetail && campaignDetail.life
                    ? campaignDetail.life
                    : {
                        elapsed: 0,
                        remaining: 0
                      }
                }
                status={campaignDetail.status}
                prospects={campaignDetail.prospects}
              />
            )}
          </Route>
          {/* <Route path={`${match.path}/stats-deprecated`} exact>
            {campaignDetail && <StatsDeprecated campaignId={campaignId} status={campaignDetail.status} />}
          </Route> */}
          {/* <Route path={`${match.path}/responses`} exact>
            {campaignDetail && <TagsV2 campaignId={campaignId} levelType="CAMPAIGN" accountName={campaignDetail?.accountName} />}

          </Route> */}
          {/* <Route path={`${match.path}/notifications`} exact>
            {campaignDetail && <Notification campaignId={campaignId} status={campaignDetail.status} />}
          </Route> */}
          <Route path={`${match.path}/webhooks`} exact>
            {campaignDetail && <Webhook campaignId={campaignId} />}
          </Route>
          {isPath && isPath.length && <Redirect exact from={match.path} to={`${match.path}/${isPath}`} />}
          {/* <Redirect exact from="*" to="/404" /> */}
        </RouterSwitch>
      </Div>
      {showDeleteConfirmModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary",
            danger: true,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={toggleShowDeleteConfirmModal}
          onOk={confirmDelete}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <Text variant="body2">
            Are you sure you want to{" "}
            <Text variant="body2" bold>
              delete
            </Text>{" "}
            {campaignDetail ? campaignDetail.name : "this Campaign"}?
          </Text>
        </Modal>
      )}
      {showCampaignCompleteModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary"
          }}
          okText="Complete"
          cancelText="Cancel"
          onCancel={() => setShowCampaignCompleteModal((prev) => !prev)}
          onOk={() => {
            setShowCampaignCompleteModal((prev) => !prev);
            confirmComplete();
          }}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <Text variant="body2">Are you sure you would like to mark {campaignDetail ? campaignDetail.name : "this Campaign"} as completed?</Text>
        </Modal>
      )}
      {showCampaignStatusModal && !!campaignDetail && (
        <ChangeCampaignStatusModal
          active={campaignDetail?.active!}
          name={campaignDetail.name}
          status={campaignDetail.status}
          detail={true}
          startDate={startDate}
          timeZone={campaignDetail.timezone ? campaignDetail.timezone : ""}
          setTimeZone={setTimeZone}
          setStartDate={setStartDate}
          onCancel={toggleShowCampaignStausModal}
          onOk={onCampaignStatusChange}
          campId={campaignDetail?.id}
        />
      )}
      {showCampaignCloneModal && (
        <Modal visible footer={null} onCancel={onCloneToggle} width={600} centered>
          <CloneCampaign onContinue={onClone} campaignId={campaignId} form={mailBoxProviderForm} setProviders={providesType} />
        </Modal>
      )}
      {showCampaignRestartModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary"
          }}
          okText="Confirm"
          cancelText="Cancel"
          onCancel={() => onRestart()}
          onOk={() => {
            onRestart();
            restartHandler();
          }}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <div style={{ padding: "25px 0 0 0" }}>
            <Text variant="body2" className="text-muted">
              <p style={{ color: "#000" }}>
                Restarting the campaign will reset the following type of prospects back to day 1 scenario so that system can start sending out first touch email
                for the initial sequence.
              </p>
              <ul
                style={{
                  padding: "15px 0 15px 18px",
                  color: "#666",
                  margin: "15px 0",
                  backgroundColor: "#fff7e6",
                  borderLeft: "3px solid #fa8c16",
                  listStyle: "none",
                  lineHeight: "25px"
                }}
              >
                <li>Prospects never received any of the emails</li>
                <li>Prospects who received emails but never replied to any of them</li>
                <li>Prospects who replied with out of office notice</li>
                <li>Prospects failed due to data errors</li>
              </ul>
              <p style={{ color: "#000" }}>The following type of prospects will not be contacted again.</p>
              <ul
                style={{
                  padding: "15px 0 15px 18px",
                  color: "#666",
                  margin: "15px 0",
                  backgroundColor: "#fff7e6",
                  borderLeft: "3px solid #fa8c16",
                  listStyle: "none",
                  lineHeight: "25px"
                }}
              >
                <li>Prospects who replied</li>
                <li>Prospects who have bad email address</li>
                <li>Prospects who are in DNC list</li>
                <li>Prospects who have unsubscribed</li>
              </ul>
              <p style={{ color: "#000" }}>Rest of the data (mailboxes, templates etc) will be left as is. Would you like to continue?</p>
            </Text>
          </div>
        </Modal>
      )}
      {showAccountList && (
        <Modal
          visible
          okButtonProps={{
            type: "primary"
          }}
          width={450}
          okText="Move"
          cancelText="Cancel"
          onCancel={() => toggleMoveToAnotherAccount()}
          // cancelButtonProps={{ style: { display: 'none' } }}
          onOk={moveToAnotherAccount}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <h2 className="mb-3">Select Account</h2>
          <>
            <Select value={selectdAccountName} onChange={(e) => changeAccount(e)} style={{ width: "100%" }}>
              {accounts.map((element) => {
                return element.id !== accountData.accountId ? (
                  <Option key={element.id} value={element.id}>
                    {element.name}
                  </Option>
                ) : null;
              })}
            </Select>
          </>
        </Modal>
      )}
      {showLogModal && <LogDetailsModal onCancel={toggleShowLogModal} id={campaignId} subTitle={campaignDetail?.name} logType="Campaign" />}
    </>
  );
};

export default withAppBar(CampaignDetail);
