import { Button, Modal, Select } from "antd";
import { Div } from "styles/layout";
import { Text } from "styles/typography";
import { useState } from "react";
import { API_ENDPOINTS } from "constants/api";
import { Table, Space, notification } from "antd";
import Column from "antd/lib/table/Column";
import classes from "./styles.module.css";
import { Flex } from "styles/layout";
import MailBoxIcon from "components/MailBox/Icon";
import { useUserlistState } from "contexts/userListContext";
import useApi from "hooks/useApi";
import { toast } from "aws-amplify";

const { Option } = Select;

const MailTransferModal = ({ closeModal, mailboxList, refreshMailboxesList }: any) => {
  const [state, setState] = useState<any>({ loading: false, inactiveMailboxes: mailboxList, selectedUser: null });
  const { loading, inactiveMailboxes, selectedUser } = state;
  const { userList } = useUserlistState();
  const [selectedMailboxesIds, setSelectedMailboxesIds] = useState([]);
  const { callApi } = useApi();

  const transferMailboxes = async () => {
    if (selectedMailboxesIds?.length > 0) {
      const payload = {
        targetUserId: selectedUser,
        mailboxIds: selectedMailboxesIds
      };
      const { url, method } = API_ENDPOINTS.MAILBOX.transferMailboxes();
      const response = await callApi({
        url,
        method,
        data: payload
      });
      console.log("responseresponseresponse", response);
      if (response?.success) {
        closeModal();
        refreshMailboxesList();
      }
    } else {
      notification.error({
        message: "Error",
        description: "Please select atleast one mailbox to transfer"
      });
    }
  };
  const selectUser = (e: any) => {
    setState((prev: any) => ({ ...prev, selectedUser: e?.value }));
  };

  const onSelectChange = (selectedKeys: any) => {
    setSelectedMailboxesIds(selectedKeys);
  };

  const rowSelection = {
    selectedMailboxesIds,
    onChange: onSelectChange,
    columnWidth: 50,
    renderCell: (selected: any, record: any, index: any, originNode: any) => {
      return <Space>{originNode}</Space>;
    }
  };
  const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";
  return (
    <>
      <Modal visible onCancel={closeModal} centered footer={null} width={800}>
        <Text variant="h6" style={{ display: "flex" }}>
          <div>
            <span>Transfer Inactive Mailboxes </span>
            <br />
            <span style={{ color: "grey", fontSize: "15px" }}>Move mailboxes from one user to another</span>
          </div>
          {userList && userList.length ? (
            <div style={{ marginLeft: "20px" }}>
              <Select placeholder="select user" onSelect={(val: any, event: any) => selectUser(event)} style={{ width: "300px" }} value={selectedUser}>
                {(userList.filter((vl: any) => vl.userId !== uId) || []).map(({ companyName, userId }: any) => (
                  <Option key={userId} value={userId}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <span>{companyName}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
          ) : null}
        </Text>
        <Table
          dataSource={inactiveMailboxes}
          pagination={false}
          rowKey="id"
          rowClassName={classes.mailboxTableRow}
          loading={loading}
          className="antd__table"
          rowSelection={rowSelection}
        >
          <Column
            className={classes.mailboxTableCell}
            key="PersonInfo"
            render={(_, record: any) => (
              <Flex gap={15} align="center">
                <MailBoxIcon type={record.provider} />
                <Flex direction="column">
                  <Text variant="sub1">
                    {`${record.firstname} ${record.lastname}`}
                    <Text variant="sub2" color="secondary" ml={8}>
                      {record.email}
                    </Text>
                  </Text>
                  <Text variant="sub2" color="secondary">
                    {record && record?.campaignName ? record.campaignName : "No Campaign"}
                  </Text>
                </Flex>
              </Flex>
            )}
          />
          <Column title="Current capacity" dataIndex="capacity" key="capacity" />
          <Column title="IPS" dataIndex="ips" key="ips" />
        </Table>
        <Div mt={30} style={{ textAlign: "right" }}>
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button disabled={!selectedUser || selectedMailboxesIds?.length === 0} type="primary" style={{ marginLeft: "10px" }} onClick={transferMailboxes}>
            Transfer
          </Button>
        </Div>
      </Modal>
    </>
  );
};

export default MailTransferModal;
