import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, InputNumber, Modal, notification, Row, Select, Switch, Tooltip } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useAuth } from "auth/AuthProvider";
import { Editor as WYSIWYGEditor } from "components/Froala";
import PlainTextEditor from "components/Froala/PlainTextEditor";
import translationStrings from "constants/strings.json";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { ProspectVariableType, ProspectVariableTypeV2 } from "types/Prospect";
import { SpinnerTypeEnum, SpinType } from "types/Spinner";
import { TouchType, TouchTypeEnum } from "types/Touch";
import SubjectInput from "./SubjectInput";

const { Option } = Select;

const TOUCH_TYPE_OPTIONS = [
  {
    value: TouchTypeEnum.NEW_THREAD,
    label: "New Thread"
  },
  {
    value: TouchTypeEnum.REPLY_TO_FIRST,
    label: "Reply to First Touch"
  },
  {
    value: TouchTypeEnum.REPLY_TO_PREVIOUS,
    label: "Reply to Previous Touch"
  },
  {
    value: TouchTypeEnum.REPLY_TO_PREVIOUS_SEQUENCE,
    label: "Reply to Previous Sequence"
  }
];

const OTHER_SEQ_FIRST_TOUCH_TYPE_OPTIONS = [
  {
    value: TouchTypeEnum.REPLY_TO_PREVIOUS_SEQUENCE,
    label: "Reply to Previous Sequence"
  }
];

const FIRST_TOUCH_TYPE_OPTIONS = [
  {
    value: TouchTypeEnum.NEW_THREAD,
    label: "New Thread"
  }
];

const SECOND_TOUCH_TYPE_OPTIONS = [
  {
    value: TouchTypeEnum.NEW_THREAD,
    label: "New Thread"
  },
  {
    value: TouchTypeEnum.REPLY_TO_FIRST,
    label: "Reply to First Touch"
  }
];

const OTHER_SECOND_TOUCH_TYPE_OPTIONS = [
  {
    value: TouchTypeEnum.NEW_THREAD,
    label: "New Thread"
  },
  {
    value: TouchTypeEnum.REPLY_TO_FIRST,
    label: "Reply to First Touch"
  },
  {
    value: TouchTypeEnum.REPLY_TO_PREVIOUS_SEQUENCE,
    label: "Reply to Previous Sequence"
  }
];

const OTHER_TOUCH_TYPE_OPTIONS = [
  {
    value: TouchTypeEnum.NEW_THREAD,
    label: "New Thread"
  },
  {
    value: TouchTypeEnum.REPLY_TO_FIRST,
    label: "Reply to First Touch"
  },
  {
    value: TouchTypeEnum.REPLY_TO_PREVIOUS,
    label: "Reply to Previous Touch"
  }
];

const OPEN_TRACKING_RATE = Array(10)
  .fill(0)
  .map((_, idx) => 10 + idx * 10)
  .map((val) => ({ value: val, label: val + "%" }));

export interface TouchPayloadType {
  type: TouchTypeEnum;
  waitDays: number;
  subject: string;
  body: string;
  plainText: boolean;
  // bodyText?: string;
  trackLinkClicked: boolean;
  trackEmailOpened: boolean;
  useOriginalSubject: boolean;
  openTrackingRate: number;
  linkTrackingValue: number;
  isLinkTrackingEnable: boolean;
}

interface NewTouchProps {
  spinTypes: SpinType[];
  variables: ProspectVariableType[];
  quicklinks?: ProspectVariableType[];
  variablesV2?: ProspectVariableTypeV2;
  isFirstTouch: () => boolean;
  isSecondTouch: () => boolean;
  activeTouchId: string;
  sequenceType: string;
  canEdit?: boolean;
  touches: TouchType[];
  getOnChange: (key: string, dataType: "value" | "checkbox") => (value: any) => void;
  handlePlainTextChange: (key: string, dataType: any) => void;
  insertAtCursor: (text: string, areaId: string) => void;
  formValues: TouchPayloadType;
  onSave: (autoSave: boolean) => void;
  onDelete: () => void;
  editMode: boolean;
  // handleEditorWarningModal: () => void;
  onClose?: () => void;
}

const NewTouch = ({
  isFirstTouch,
  isSecondTouch,
  touches,
  activeTouchId,
  getOnChange,
  handlePlainTextChange,
  insertAtCursor,
  spinTypes,
  sequenceType,
  variables,
  quicklinks,
  formValues,
  onSave,
  onDelete,
  canEdit = true,
  editMode,
  onClose
}: // handleEditorWarningModal,
NewTouchProps) => {
  // const [isPreviousTouch]
  const { theme } = useAuth();

  const subjectSpinners = useMemo(() => spinTypes.filter(({ contentType }) => contentType === SpinnerTypeEnum.PLAIN_TEXT), [spinTypes]);
  const [isSubjectDisable, setisSubjectDisable] = useState(false);
  const [firstTouch, setisFirstTouch] = useState(false);
  const [secondTouch, setisSecondTouch] = useState(false);
  // const [showWarningModal, setShowWarningModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const disableSubjectInput = useCallback(async () => {
    if (formValues.useOriginalSubject) setisSubjectDisable(true);
    else setisSubjectDisable(false);
  }, [formValues]);

  useEffect(() => {
    disableSubjectInput();
    const value = isFirstTouch();
    const isSec = isSecondTouch();
    setisSecondTouch(isSec);
    setisFirstTouch(value);
  }, [disableSubjectInput, isFirstTouch, isSecondTouch]);

  const handleSave = useCallback(() => {
    if (formValues.useOriginalSubject && formValues.subject.length) {
      notification.error({
        message: `You can either Add Subject or Check Use the Original Subject.`
      });
      return;
    }
    onSave(false);
  }, [onSave, formValues]);

  const handleEditorWarningModal = () => {
    if (formValues.body && formValues.body.length) {
      setShowWarningModal((show) => !show);
    } else {
      handlePlainTextChange("plainText", !formValues.plainText);
    }
  };

  const switchEditorMode = useCallback(() => {
    if (formValues.body && formValues.body.length) {
      let html = formValues.body;
      let div = document.createElement("div");
      div.innerHTML = html;
      const plainText = div.innerHTML
        .replace(/<br[^>]*>/gi, "\n")
        .replace(/<p[^>]*>/gi, "\n")
        .replace(/<[^>]*>?/gm, "");
      handlePlainTextChange("plainText", !formValues.plainText);
      // handlePlainTextChange("body", div.innerText)
      handlePlainTextChange("body", plainText);
      setShowWarningModal((show) => !show);
    }
  }, [formValues]);
  console.log("formValuesformValuesformValues", formValues);
  return (
    <Div p={10}>
      <Row className="d-flex justify-conetnt-between">
        <Col>
          <Text variant="overline" mb={5} color="secondary">
            Type
          </Text>
          <Div>
            <Select style={{ width: 200 }} onChange={getOnChange("type", "value")} value={formValues.type}>
              {sequenceType === "INITIAL" && firstTouch ? (
                <>
                  {FIRST_TOUCH_TYPE_OPTIONS.map(({ value, label }) => (
                    <Option value={value} key={value}>
                      {label}
                    </Option>
                  ))}
                </>
              ) : sequenceType === "INITIAL" && secondTouch ? (
                <>
                  {SECOND_TOUCH_TYPE_OPTIONS.map(({ value, label }) => (
                    <Option value={value} key={value}>
                      {label}
                    </Option>
                  ))}
                </>
              ) : sequenceType !== "INITIAL" && secondTouch ? (
                <>
                  {OTHER_SECOND_TOUCH_TYPE_OPTIONS.map(({ value, label }) => (
                    <Option value={value} key={value}>
                      {label}
                    </Option>
                  ))}
                </>
              ) : sequenceType !== "INITIAL" && firstTouch ? (
                <>
                  {OTHER_SEQ_FIRST_TOUCH_TYPE_OPTIONS.map(({ value, label }) => (
                    <Option value={value} key={value}>
                      {label}
                    </Option>
                  ))}
                </>
              ) : sequenceType === "INITIAL" && !firstTouch && !secondTouch ? (
                <>
                  {OTHER_TOUCH_TYPE_OPTIONS.map(({ value, label }) => (
                    <Option value={value} key={value}>
                      {label}
                    </Option>
                  ))}
                </>
              ) : (
                <>
                  {TOUCH_TYPE_OPTIONS.map(({ value, label }) => (
                    <Option value={value} key={value}>
                      {label}
                    </Option>
                  ))}
                </>
              )}
            </Select>
          </Div>
        </Col>
        {!firstTouch && formValues && formValues.waitDays ? (
          <Col>
            <Text variant="overline" mb={5} color="secondary">
              Send After
            </Text>
            <Div>
              <InputNumber style={{ width: 50 }} value={formValues.waitDays} onChange={getOnChange("waitDays", "value")} min={1} />
              <Text variant="sub2" mb={5} ml={10} mt={5}>
                days of performing previous touch
              </Text>
            </Div>
          </Col>
        ) : null}

        <Col>
          {editMode && (
            <Button
              style={{ border: "none", background: "none", boxShadow: "none", color: theme.antdConf.errorColor }}
              type="default"
              icon={<DeleteOutlined />}
              disabled={!canEdit}
              onClick={onDelete}
            ></Button>
          )}
        </Col>
      </Row>

      {sequenceType === "INITIAL" && firstTouch ? null : (
        <Div className="mt-15px">
          <Checkbox checked={formValues.useOriginalSubject} onChange={getOnChange("useOriginalSubject", "checkbox")}>
            {/* Use the Original Subject */}
            Use Previous Touch Subject
          </Checkbox>
        </Div>
      )}
      <Div mt={15} className={isSubjectDisable ? "event-pointer-none" : ""}>
        <SubjectInput
          value={formValues.subject}
          onChange={getOnChange("subject", "value")}
          spinners={subjectSpinners}
          variables={variables}
          isSubjectDisable={isSubjectDisable}
          quicklinks={quicklinks}
        />
      </Div>
      {!formValues.plainText && (
        <Div mh={250} mt={15} id={"editor"}>
          <WYSIWYGEditor
            value={formValues.body}
            onChange={getOnChange("body", "value")}
            config={{
              height: `calc(100vh - 460px)`,
              toolbarBottom: true,
              imageMaxSize: 1024 * 1024 * 1,
              imageEditButtons: ["imageDisplay", "imageAlign", "imageRemove"],
              placeholderText: "Enter email body",
              pastePlain: true
              // disable:{true}
            }}
            moreRichButtons={[]}
            moreTextButtons={["textColor", "bold", "italic", "backgroundColor", "underline", "insertLink", "fontFamily", "fontSize"]}
            moreTextButtonsVisible={5}
            tag="textarea"
            variables={variables}
            spinners={spinTypes}
            quicklinks={quicklinks}
          />
        </Div>
      )}
      {formValues.plainText && (
        <PlainTextEditor
          formValues={formValues}
          handlePlainTextChange={handlePlainTextChange}
          insertAtCursor={insertAtCursor}
          spinTypes={spinTypes}
          variables={variables}
          quicklinks={quicklinks}
        />
      )}

      <Flex direction="column">
        <Div mt={10} style={{ display: "flex", justifyContent: "flex-end", marginBottom: formValues.plainText ? "50px" : "0px" }}>
          Plain Text Mode
          <Div ml={10}>
            <span>
              <Switch checked={formValues.plainText} onChange={() => handleEditorWarningModal()} />
            </span>
          </Div>
        </Div>
        {!formValues.plainText && (
          <>
            <Div>
              <Checkbox checked={formValues.trackLinkClicked} onChange={getOnChange("trackLinkClicked", "checkbox")}>
                Link tracking
              </Checkbox>
            </Div>
            <Flex gap={10} align="center">
              <Checkbox checked={formValues.trackEmailOpened} onChange={getOnChange("trackEmailOpened", "checkbox")}>
                Enable open tracking for
              </Checkbox>
              <Select
                disabled={!formValues.trackEmailOpened}
                defaultValue={formValues.openTrackingRate}
                style={{ width: 120 }}
                onChange={getOnChange("openTrackingRate", "value")}
                options={OPEN_TRACKING_RATE}
              />
              <Text variant="sub2">of the emails for this touch</Text>
            </Flex>
            {/* <Flex gap={10} align="center" className="mt-2">
              <Checkbox checked={formValues.isLinkTrackingEnable} onChange={getOnChange("isLinkTrackingEnable", "checkbox")}>
                Enable link tracking for
              </Checkbox>
              <Select
                disabled={!formValues.isLinkTrackingEnable}
                defaultValue={formValues.linkTrackingValue}
                style={{ width: 120 }}
                onChange={getOnChange("linkTrackingValue", "value")}
                options={OPEN_TRACKING_RATE}
              />
              <Text variant="sub2">of the emails for this touch</Text>
            </Flex> */}
          </>
        )}
      </Flex>
      <Flex direction="row" justify="flex-end" ml={10}>
        <Flex mr={10}>
          <Button onClick={() => onClose && onClose()} tabIndex={5}>
            Back
          </Button>
        </Flex>
        <Tooltip title={!canEdit ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
          <Button type="primary" disabled={!canEdit} onClick={handleSave}>
            {"Save"}
          </Button>
        </Tooltip>
      </Flex>
      {showWarningModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary"
          }}
          okText="Confirm"
          cancelText="Cancel"
          onCancel={handleEditorWarningModal}
          onOk={switchEditorMode}
          cancelButtonProps={{ type: "text" }}
          centered
          zIndex={1030}
        >
          <Text variant="body2">
            Changes made to this view will be lost if you confirm.
            <Text variant="body2" bold>
              Are you sure you want to switch the editor mode ?
            </Text>
          </Text>
        </Modal>
      )}
    </Div>
  );
};

export default NewTouch;

function uuid(): string {
  throw new Error("Function not implemented.");
}
