import React from "react";
import { BgImage, Flex, Div } from "styles/layout";
import NoCampaignsImage from "images/no-campaigns.svg";
import { Text } from "styles/typography";
import { Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export interface NoCampaignsProps {
  onAdd: () => void;
  activeTabsTitle?: string
}

const NoCampaigns = ({ onAdd, activeTabsTitle }: NoCampaignsProps) => {
  return (
    <Flex direction="column" align="center">
      <BgImage imageUrl={NoCampaignsImage} mh={200} mw={200} />
      {/* <Empty
        description={false}
        imageStyle={{
          height: 200,
          width: 200
        }}
      /> */}
      <Text variant="body1" block mt={10}>
        {`No ${activeTabsTitle?.toLocaleLowerCase() || ''} campaigns found`}
      </Text>
      {/* <Text variant="sub2" block color="secondary" mt={10}>
        You haven't created any campaigns yet.
      </Text> */}
      <Div mt={20}>
        <Button icon={<PlusOutlined />} type="primary" onClick={onAdd}>
          Add Campaign
        </Button>
      </Div>
    </Flex>
  );
};

export default NoCampaigns;
