import { Col, Row } from "antd";
import { FunctionComponent } from "react";

type CardLayoutProps<T> = {
  loading: boolean;
  list: T[];
  children: FunctionComponent<{ item: T }>;
};

export const CardLayout = <T extends object = any>(props: CardLayoutProps<T>) => {
  return (
    <Row gutter={[10, 10]}>
      {(props.loading ? Array(6).fill(0) : props.list).map((item: T & { id: string }, idx) => (
        <Col span={8} key={item?.id ?? idx}>
          {props.children({ item })}
        </Col>
      ))}
    </Row>
  );
};
