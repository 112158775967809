import React, { useCallback, useEffect } from 'react';
import { Modal, Col, Row, notification } from "antd";
import { Text } from "styles/typography";
import { Div } from 'styles/layout';
import { API_ENDPOINTS } from 'constants/api';
import useApi from "hooks/useApi";
import { getProviderAndProtocol, getProviderDetails } from 'components/MailBox/Provider';

// interface ConnectionModalProps {
//     toggleMailboxValidating: () => void;
//     validating: boolean;
//     isInBoundConnection: boolean;
//     smtpInBoundError: string;
//     isOutBoundConnection: boolean;
//     smtpOutBoundError: string;
// }

const ConnectionModal = ({
  isEnable,
  toggleMailboxValidating,
  validating,
  isInBoundConnection,
  smtpInBoundError,
  isOutBoundConnection,
  smtpOutBoundError,
  editableId,
  providers,
  setValidating,
  setisInBoundConnection,
  setSmtpInBoundError,
  setisOutBoundConnection,
  setSmtpOutBoundError,
  isEditMode,
  action,
  resetMailboxeSettings,
  setIsSettingSmtp,
  settingEditView,
  setSelectedMailbox,
  fromValue,
}: any) => {
  console.log("54555555555555")
  const { callApi } = useApi();
  let timer: any = null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reconnectMailBox = async (userInfo: { email: string, password: string, imapUserName: string, imapPassword: string, payload: any }) => {
    // console.log('edit', editableId);
    const { email, password, payload } = userInfo;
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.reConnectMail(editableId);
    let data: any = {
      "brooklynReconnect": false
    };
    console.log("calllllllllllllllllllllllllllllllllll GOOGLEONE", providers, payload, userInfo)
    if (providers !== 'GOOGLEONE') {
      // const { smtp, imap } = payload
      data['smtp'] = {
        host: payload && payload.smtpHost ? payload.smtpHost : "",
        port: payload && payload.smtpPort ? payload.smtpPort : "",
        username: email,
        password: password,
        tls: payload && payload.smtpTLS ? payload.smtpTLS : false
      };
      data['imap'] = {
        host: payload && payload.imapHost ? payload.imapHost : "",
        port: payload && payload.imapPort ? payload.imapPort : "",
        username: email,
        password: password,
        tls: payload && payload.imapTLS ? payload.imapTLS : false
      };
    }
    return await callApi({
      url,
      method,
      data
    });
  }

  const callProviderSetupSuccessResponse: any = async (requestId: string, endTime: number, userInfo: { email: string, password: string, imapUserName: string, imapPassword: string, payload: any }) => {

    // console.log('isEnable', isEnable)
    if (!isEnable) {
      return clearTimeout(timer);
    }
    if (timer) clearTimeout(timer);
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.smtpValidateStatus(
      requestId
    );

    const response: any = await callApi({
      url,
      method,
      external: true,
    });

    if (response.success) {
      const { outbound, inbound } = response.data
      // outbound.status = "Succeeded"
      // inbound.status = "Succeeded"
      if ((outbound.status === "Succeeded" && inbound.status === "Succeeded") ||
        (outbound.status === "Failed" && inbound.status === "Failed")
        || (outbound.status === "Succeeded" && inbound.status === "Failed") ||
        (outbound.status === "Failed" && inbound.status === "Succeeded")
      ) {
        setValidating(true)
      }
      let taskDone = false;

      if (inbound.status !== "Created" && inbound.status !== "Started") {
        if (inbound.status === "Succeeded") {
          taskDone = true;
          setisInBoundConnection(true)
        } else {
          taskDone = true;
          setSmtpInBoundError(inbound.error)
          setisInBoundConnection(false)
        }
      }

      if (outbound.status !== "Created" && outbound.status !== "Started") {
        if (outbound.status === "Succeeded") {
          taskDone = true;
          setisOutBoundConnection(true)
        } else {
          taskDone = true;
          setSmtpOutBoundError(outbound.error)
          setisOutBoundConnection(false)
        }
      }

      if (inbound.status === "Succeeded" && outbound.status === "Succeeded") {
        timer = setTimeout(async () => {
          if (isEditMode && action === 'VALIDATE') {
            notification.success({
              message: "Connection validated successfully",
            });
            resetMailboxeSettings()
          } else if (isEditMode && action === 'UPDATE') {
            notification.success({
              message: "Password updated successfully",
            });
            resetMailboxeSettings()
          } else if (isEditMode && action === 'RECONNECT') {
            const response: any = await reconnectMailBox(userInfo);
            console.log("response", response)
            if (response?.success) {
              notification.success({
                message: "Reconnected successfully",
              });
            }
            resetMailboxeSettings();
          } else {
            if (taskDone) {
              setIsSettingSmtp(true);
              resetMailboxeSettings()
              return;
            }
          }
        }, 5000)
      }

      if (inbound.status === "Created" || inbound.status === "Started"
        || outbound.status === "Created" || outbound.status === "Started") {
        let currentTime = new Date().getTime();
        if (endTime - currentTime > 0) {
          timer = setTimeout(() => callProviderSetupSuccessResponse(requestId, endTime, userInfo), 5000)
        }
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCredentialsService = async (userInfo: any) => {
    console.log("userInfo", userInfo)
    const { email, password, imapUsername, imapPassword, imapHost, imapPort, inboxEmail, smtpHost, smtpPort, smtpTLS, imapTLS } = userInfo;
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.smtpValidate();
    let payload: any = {
      provider: getProviderDetails(providers)?.providerName === "CUSTOM" ? "SMTP" : getProviderDetails(providers)?.providerName,
      protocol: getProviderDetails(providers)?.protocol,
      // firstName: Firstname,
      // lastName: Lastname,
      smtp: {
        username: email,
        password: password,
        ...(smtpHost && { host: smtpHost }),
        ...(smtpPort && { port: smtpPort }),
        tls: smtpTLS
      },
      inboxEmail: inboxEmail,
      action: action ? action : 'VALIDATE',
      accountId: String(localStorage.getItem("account_id")),
    };
    if (imapUsername) {
      payload.imap = {
        username: imapUsername,
        password: imapPassword,
        host: imapHost,
        port: imapPort,
        tls: imapTLS,
      }
    }
    const response = await callApi({
      url,
      method,
      data: payload,
      external: true,
    });

    if (response.success) {
      const payload = { email: email as string, provider: providers, id: null, campaignName: null };
      if (!settingEditView) {
        setSelectedMailbox(payload);
      }

      let toDate = new Date();
      let nextTime = toDate.getTime() + 300000;
      const payloadData = { imapUsername, imapPassword, imapHost, imapPort, inboxEmail, smtpHost, smtpPort, smtpTLS, imapTLS }
      const userinfo = {
        email, password, imapUserName: imapUsername, imapPassword, payload: payloadData
      };
      console.log("api call")
      await callProviderSetupSuccessResponse(response.data.requestId, nextTime, userinfo);
    }
  }

  useEffect(() => {
    console.log('opensfromValuefromValuefromValue', fromValue)
    updateCredentialsService(fromValue);

    return () => {
      clearTimeout(timer);
    }
  }, [editableId, isEnable])


  return (
    <Modal visible onCancel={toggleMailboxValidating} footer={null} width={600} maskClosable={false}>
      {/* <Div className="custom-loader"> */}
      <Text variant="h6" className="fs-16px" mt={0} mb={25} block color={"secondary"}>
        Validating
      </Text>
      <Div className="d-flex flex-column align-items-center checking-mn-box">
        <Row className="mb-2 checking-box">
          <Col md="auto" className="me-2 d-flex">
            <Div className="d-flex flex-column icon-round">
              {
                !validating ?
                  <span role="img" aria-label="loading"
                    className="anticon anticon-loading anticon-spin" style={{ color: 'rgb(250, 140, 22)', fontSize: '16px' }}>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1.2em" height="1.2em" fill="currentColor" aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  </span> :
                  isInBoundConnection ?
                    <span className="fs-20px text-success"><i className="fa fa-check" aria-hidden="true"></i></span>
                    : <span className="fs-20px text-danger ml-2px"><i className="fa fa-times" aria-hidden="true"></i></span>

              }
            </Div>

            <Div>
              <Text variant="sub2" className="ms-1 check-label">
                Checking Inbound Connection
              </Text>
              {
                smtpInBoundError && smtpInBoundError.length ?
                  <p className="ml-10px text-danger lh-16px fs-12px">Error: {smtpInBoundError}</p>
                  : null
              }
            </Div>
          </Col>
        </Row>

        <Row className="mb-2 checking-box">
          <Col md="auto" className="me-2 d-flex">

            <Div className="d-flex flex-column icon-round">
              {
                !validating ?
                  <span role="img" aria-label="loading"
                    className="anticon anticon-loading anticon-spin" style={{ color: 'rgb(250, 140, 22)', fontSize: '16px' }}>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1.2em" height="1.2em" fill="currentColor" aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  </span>
                  :
                  isOutBoundConnection ?
                    <span className="fs-20px text-success"><i className="fa fa-check" aria-hidden="true"></i></span>
                    : <span className="fs-20px text-danger ml-2px"><i className="fa fa-times" aria-hidden="true"></i></span>

              }
            </Div>

            <Div>
              <Text variant="sub2" className="ms-1 check-label">
                Checking Outbound Connection
              </Text>
              {smtpOutBoundError && smtpOutBoundError.length ?
                <p className="ml-10px text-danger lh-16px fs-12px">Error: {smtpOutBoundError}</p>
                : null}
            </Div>
          </Col>
        </Row>
      </Div>
      {/* </Div> */}
    </Modal>
  )
}

export default ConnectionModal;
