import { DeleteOutlined, CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";
import SpinnerInput from "components/Spinners/SpinnerInput";
import { API_ENDPOINTS } from "constants/api";
import withLoader from "hoc/withLoader";
import useApi from "hooks/useApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  areSpinTypesLoadingSelector,
  // canModifyCampaignState,
  getCampaignNonQuickLineVariablesSelector,
  getCampaignQuickLineVariablesSelector,
  // getCampaignVariablesSelector,
  spinTypesSelector
} from "state/selectors";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { SpinDetailType, SpinnerTypeEnum } from "types/Spinner";
import { WHITE_COLOR, BOX_SHADOW } from "utils/colors";
import SpinTemplateModal from "./SpinTemplateModal";
import translationStrings from "constants/strings.json";
import { cloneDeep, concat, set } from "lodash";
const { Option } = Select;

interface SpinnerListProps {
  loading: boolean;
  setLoading: (message: string) => void;
  campaignId: string;
  // sequenceId: string;
}

const SpinnerList = ({
  setLoading,
  campaignId
}: // sequenceId,
SpinnerListProps) => {
  const spinTypes = useSelector(spinTypesSelector);
  const { callApi } = useApi();
  const areSpinTypesLoading = useSelector(areSpinTypesLoadingSelector);
  const [selectedSpinnerTypeName, setSelectedSpinnerTypeName] = useState("");
  const [selectedSpinType, setSelectedSpinType] = useState<SpinDetailType | null>(null);
  const [showSpinTemplateModal, setShowSpinTemplateModal] = useState(false);
  const [editIndex, setEditIndex] = useState<any>(null);
  const [state, setState] = useState<any>({ spinLines: [] });

  const isFree = true;

  const campaignVariablesSelector = useMemo(() => getCampaignNonQuickLineVariablesSelector(campaignId), [campaignId]);
  const campaignQuickLinkVariablesSelector = useMemo(() => getCampaignQuickLineVariablesSelector(campaignId), [campaignId]);
  const variables = useSelector(campaignVariablesSelector);
  const quickLins = useSelector(campaignQuickLinkVariablesSelector);

  const fetchSpinLines = useCallback(async () => {
    setSelectedSpinType((prevSpinType) => (prevSpinType ? { ...prevSpinType, spinLines: [] } : prevSpinType));

    setLoading("Fetching Spinners ...");

    const { url, method } = API_ENDPOINTS.CAMPAIGN_SPIN_TYPE.getSpinnerList(
      campaignId,
      // sequenceId
      selectedSpinnerTypeName
    );

    const response = await callApi({
      url,
      method
    });
    if (response.success) {
      setSelectedSpinType({
        spinLines: (response.data.lines as any[]) ?? [],
        name: selectedSpinnerTypeName,
        campId: campaignId,
        id: selectedSpinnerTypeName
      });
      // setState((prev: any) => ({ ...prev, spinLines: response.data.lines as any[] }));
    }
    setLoading("");
  }, [setLoading, selectedSpinnerTypeName, campaignId, callApi]);

  useEffect(() => {
    if (areSpinTypesLoading || spinTypes.length === 0) {
      return;
    }

    setSelectedSpinnerTypeName(spinTypes[0].name);
  }, [spinTypes, areSpinTypesLoading]);

  useEffect(() => {
    setLoading("Fetching Spinners ...");

    if (areSpinTypesLoading || !selectedSpinnerTypeName) return;

    fetchSpinLines();
  }, [areSpinTypesLoading, fetchSpinLines, selectedSpinnerTypeName, setLoading]);

  const getOnChange = (index: number, value: string) => {
    const cloneSpineLInes = [...(selectedSpinType?.spinLines || [])];
    if (!cloneSpineLInes[index]) {
      cloneSpineLInes[index] = {};
    }
    cloneSpineLInes[index].line = value;
    setSelectedSpinType((prev: any) => ({ ...prev, spinLines: cloneSpineLInes }));
  };

  // const getOnChange = useCallback(
  //   (index: number) => (value: string) => {
  //     setSelectedSpinType((prev) => {
  //       if (!prev) return prev;
  //       const newSpinLines = [...prev.spinLines];
  //       newSpinLines.splice(index, 1, { line: value });
  //       return { ...prev, spinLines: newSpinLines };
  //     });
  //   },
  //   []
  // );

  // const getOnDelete = useCallback(
  //   (index: number, lineId: string) => () =>
  //     setSelectedSpinType((prev) => {
  //       if (!prev) return prev;

  //       const newSpinLines = [...prev.spinLines];
  //       newSpinLines.splice(index, 1);
  //       return { ...prev, spinLines: newSpinLines };
  //     }),
  //   []
  // );

  const getOnDelete = useCallback(
    async (index: number, lineId: string) => {
      if (!selectedSpinType) return;
      setLoading("Deleting Spinners ...");
      const { name } = selectedSpinType;
      const { url, method } = API_ENDPOINTS.CAMPAIGN_SPIN_TYPE.deleteSpinner(campaignId, name, lineId);

      const response = await callApi({
        url,
        method
      });
      if (response?.success) {
        setSelectedSpinType((prev) => {
          if (!prev) return prev;
          let newSpinLines = [...prev.spinLines];
          newSpinLines = newSpinLines?.filter((vl) => vl?.id !== lineId);
          return { ...prev, spinLines: newSpinLines };
        });
        setLoading("");
      }
    },
    [selectedSpinType]
  );

  const spinContentType = useMemo(() => {
    const selectedSpinnerType = spinTypes.find(({ name }) => name === selectedSpinnerTypeName);

    if (!selectedSpinnerType) {
      return SpinnerTypeEnum.PLAIN_TEXT;
    }

    return selectedSpinnerType.contentType;
  }, [selectedSpinnerTypeName, spinTypes]);

  // const onSave = useCallback(async () => {
  //   if (!selectedSpinType) return;
  //   setLoading("Updating spinners ...");
  //   const { name, spinLines } = selectedSpinType;
  //   let nonEmptySpinLines: string[] = spinLines;
  //   if (spinContentType === SpinnerTypeEnum.PLAIN_TEXT) {
  //     nonEmptySpinLines = spinLines.map((slHtml: any) => {
  //       if (slHtml.indexOf("<p fr-original-style") === -1) return slHtml;
  //       const divElement = document.createElement("div");
  //       divElement.innerHTML = slHtml;
  //       return divElement.querySelector("p")?.innerText || "";
  //     });
  //   }
  //   nonEmptySpinLines = nonEmptySpinLines.filter((sl) => !!sl);
  //   const { url, method } = API_ENDPOINTS.SPIN_TYPES.getUpdateUrl(campaignId, name);
  //   const response = await callApi({
  //     url,
  //     method,
  //     data: {
  //       lines: nonEmptySpinLines
  //     }
  //   });
  //   setLoading("");
  //   if (response.success) {
  //     setSelectedSpinType({
  //       ...selectedSpinType,
  //       spinLines: nonEmptySpinLines
  //     });
  //   }
  // }, [callApi, selectedSpinType, setLoading, campaignId, spinContentType]);

  const onEditSave = useCallback(
    async (spinObj, index) => {
      if (!selectedSpinType) return;
      setLoading(`${spinObj?.id ? "Updating" : "Adding"} spinner ...`);
      const { name, spinLines } = selectedSpinType;
      console.log("spinObjspinObjspinObj", spinObj);
      let nonEmptySpinLines: any = spinObj;
      if (spinContentType === SpinnerTypeEnum.PLAIN_TEXT) {
        if (nonEmptySpinLines?.line?.indexOf("<p fr-original-style") === -1) {
          nonEmptySpinLines.line = nonEmptySpinLines?.line;
        } else {
          const divElement = document.createElement("div");
          divElement.innerHTML = nonEmptySpinLines?.line;
          nonEmptySpinLines.line = divElement.querySelector("p")?.innerText || "";
        }
      }
      let response: any = null;
      if (spinObj?.id) {
        const { url, method } = API_ENDPOINTS.SPIN_TYPES.getUpdateUrl(campaignId, name);
        response = await callApi({
          url,
          method,
          data: nonEmptySpinLines
        });
      } else {
        const { url, method } = API_ENDPOINTS.SPIN_TYPES.addSpinners(campaignId, name);
        response = await callApi({
          url,
          method,
          data: { lines: [spinObj?.line] }
        });
      }
      if (response.success) {
        setLoading("");
        setEditIndex(null);
        if (!spinObj?.id) {
          setSelectedSpinType((prev) => {
            if (!prev) return prev;
            let newSpinLines = [...prev.spinLines];
            newSpinLines[index] = response?.data?.lines[0];
            return { ...prev, spinLines: newSpinLines };
          });
        }
      }
    },
    [callApi, selectedSpinType, setLoading, campaignId, spinContentType]
  );

  const toggleShowSpinTemplateModal = useCallback(() => setShowSpinTemplateModal((show) => !show), []);

  const onTemplateAdd = useCallback(
    async (templates: string[]) => {
      setShowSpinTemplateModal(false);

      setLoading("Adding spinners ...");
      if (!selectedSpinType) return;

      const { name } = selectedSpinType;
      const { url, method } = API_ENDPOINTS.SPIN_TYPES.addSpinners(campaignId, name);
      const response = await callApi({
        url,
        method,
        data: { lines: templates }
      });
      if (response?.success) {
        setLoading("");
        setSelectedSpinType((prev) => {
          if (!prev) return prev;
          return { ...prev, spinLines: concat(prev?.spinLines, response?.data?.lines) };
        });
        // toggleShowSpinTemplateModal();
      }
      // setSelectedSpinType((prev) => {
      //   return prev ? { ...prev, spinLines: [...prev.spinLines, ...templates] } : prev;
      // });
    },
    [selectedSpinType]
  );

  const spinLines = useMemo(() => {
    if (selectedSpinType && Array.isArray(selectedSpinType?.spinLines) && selectedSpinType?.spinLines?.length > 0) {
      const isWithoutIdExist = selectedSpinType?.spinLines?.find((vl) => !vl?.id);
      if (isWithoutIdExist) {
        const sortedSpinLines = selectedSpinType?.spinLines.sort((a, b) => {
          if (!a.id && b.id) {
            return 1;
          } else if (a.id && !b.id) {
            return -1;
          } else {
            return 0;
          }
        });

        return sortedSpinLines;
      } else {
        return [...selectedSpinType?.spinLines, { line: "" }];
      }
    }
    return [{ line: "" }];
  }, [selectedSpinType?.spinLines]);
  return (
    <Div mh="calc(100vh - 180px)" bgColor={WHITE_COLOR} p={20} mt={10} style={{ boxShadow: BOX_SHADOW }}>
      <Flex align="flex-end" justify="space-between">
        <Div>
          <Text variant="caption" color="secondary" mb={20}>
            Spinners are rotating variants for different categories within an email template. There are default variants for generic categories. You can add,
            subtract or modify them at any time.
          </Text>
          <Text variant="overline" color="secondary" block mb={10}>
            Spinner Type
          </Text>
          <Select value={selectedSpinnerTypeName} onChange={setSelectedSpinnerTypeName} style={{ width: 200 }}>
            {spinTypes.map(({ name, label }) => (
              <Option key={name} value={name}>
                {label}
              </Option>
            ))}
          </Select>
          <Button onClick={toggleShowSpinTemplateModal} type="link">
            View Templates
          </Button>
        </Div>
        {/* <Div>
          <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
            <Button type="primary" disabled={!isFree} onClick={onSave}>
              Save
            </Button>
          </Tooltip>
        </Div> */}
      </Flex>
      <Div p="10px 0 0 0">
        {spinLines?.map((spinLine: any, index: number) => (
          <Flex mt={10} mb={10} key={index}>
            <Flex mr={10} w={`calc(100% - 42px)`}>
              <SpinnerInput
                value={spinLine?.line}
                quicklinks={quickLins}
                variables={variables}
                type={spinContentType}
                onChange={(val) => getOnChange(index, val)}
                placeholder={`Add Spinner`}
                isDisabled={editIndex !== index && spinLine?.id}
              />
            </Flex>
            {editIndex === index || !spinLine?.id ? (
              <Button icon={<CheckOutlined />} disabled={!spinLine?.line?.trim()} ghost type="text" onClick={() => onEditSave(spinLine, index)} />
            ) : spinLine?.line ? (
              <Button icon={<EditOutlined onClick={() => setEditIndex(index)} />} ghost type="text" />
            ) : null}
            {spinLine?.id ? <Button icon={<DeleteOutlined />} danger ghost type="text" onClick={() => getOnDelete(index, spinLine?.id)} /> : null}
          </Flex>
        ))}
      </Div>
      {showSpinTemplateModal && <SpinTemplateModal spinType={selectedSpinType} onAdd={onTemplateAdd} onCancel={toggleShowSpinTemplateModal} />}
    </Div>
  );
};

export default withLoader(SpinnerList);
