import Icon, {
  ApiOutlined,
  CoffeeOutlined,
  CreditCardOutlined,
  AlertFilled,
  CustomerServiceOutlined,
  EnvironmentOutlined,
  EyeInvisibleOutlined,
  IdcardOutlined,
  InboxOutlined,
  LogoutOutlined,
  SendOutlined,
  TeamOutlined,
  UserOutlined,
  CalendarOutlined,
  
} from "@ant-design/icons";
import { Dropdown, Menu, Select, Tooltip } from "antd";
import { useAuth } from "auth/AuthProvider";
import Avatar from "components/Avatar";
import { Logo } from "components/Logo";
import { API_ENDPOINTS } from "constants/api";
import { APPBAR_HEIGHT } from "constants/layout";
import { IVY_PATH, ROUTE_PATHS } from "constants/routes";
import AppContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { debounce, isEmpty } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import classes from "styles/common.module.css";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import { APP_BAR_COLOR, WHITE_COLOR } from "utils/colors";
import { rootDomain } from "utils/string";
import NotificationsDrawer from "./NotificationsDrawer";
import { ReactComponent as mailboxSvg } from "images/mailboxes.svg";
import ContactSupport from "./ContactSupport";
import useLocalStorage from "pages/Unibox/hooks";
import { useUserlistState } from "contexts/userListContext";

const MailBoxIcon = (props: any) => <Icon component={mailboxSvg} {...props} style={{ fontSize: "21px" }} />;

const { Option } = Select;
const MINUTE_MS = 1200000;

interface optionProps {
  id: string;
  name: string;
  type: string;
}

interface AccountDetailsProps {
  name?: string;
  email?: string;
  userId?: string;
}

export interface NavPathType {
  linkTo?: string;
  label: string;
}

export interface AppBarProps extends AccountDetailsProps {
  logout: () => Promise<void>;
  navPaths: NavPathType[];
}

enum NavPathIdEnum {
  CAMPAIGNS = "campaigns",
  SETTINGS = "settings",
  NOTIFICATIONS = "notification",
  COMPANY_SEARCH = "contact search",
  INTEGRATION = "integration",
  PROFILE = "profile",
  MAILBOXES = "mailboxes",
  MY_TEAM = "my team",
  HOLIDAYS = "holidays",
  DNC_LIST = "dnc list",
  CUSTOM_DOMAIN_TRACKING = "domain_tracking",
  UNIBOX = "unibox",
  NOTIFICATION = "notification",
  SUBSCRIPTION = "subscription",
  REPORT = "report"
}

interface NavIconProps {
  id: NavPathIdEnum;
  tooltipTitle: string;
  Icon: any;
  path?: string;
  onClick?: () => void;
  isActive: () => boolean;
}

export interface searchOptions {
  labels: string;
  options: optionProps[];
}

const NavIcon = ({ path, tooltipTitle, Icon, onClick, isActive }: NavIconProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { theme } = useAuth();
  const primaryColor = theme.antdConf.primaryColor;

  const handleNavPathClick = useCallback(() => {
    if (!path) return;
    history.push(path);
  }, [history, path]);

  const handleClick = useCallback(() => {
    if (typeof onClick === "function") {
      onClick();
      return;
    }
    handleNavPathClick();
  }, [handleNavPathClick, onClick]);

  return (
    <Tooltip placement="bottom" title={t(tooltipTitle)}>
      <Flex mr={20} cursor="pointer" hoverColor={primaryColor} onClick={handleClick} color={isActive() ? primaryColor : WHITE_COLOR}>
        <Icon
          style={{
            fontSize: 20
          }}
        />
      </Flex>
    </Tooltip>
  );
};

const AccountDetails = ({ name, email }: AccountDetailsProps) => (
  <>
    <div
      style={{
        padding: "10px 25px 0 25px",
        marginBottom: "2px",
        fontWeight: 600,
        fontSize: "1.25em"
      }}
    >
      {name || ""}
    </div>
    <div style={{ padding: "0 25px", marginBottom: "10px" }}>{email || ""}</div>
  </>
);

const ivyPathUrl = (path: string) => {
  const isLocal = window.location.hostname === "localhost";
  const curHost = isLocal ? "localhost:5500" : window.location.hostname;
  const thirdParty = isLocal ? "localhost:5500" : IVY_PATH + ".";
  const updatedHost = rootDomain(curHost);
  const protocol = isLocal ? "http:" : window.location.protocol;
  return protocol + "//" + thirdParty + updatedHost + "/#/" + path;
};

const AppBar = ({ logout, name, email, navPaths, userId }: AppBarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showNotificationsDrawer, setShowNotificationsDrawer] = useState(false);
  const [isUserAgent, setUserAgent] = useState(false);
  const { user, metaConfig, theme } = useAuth();
  const match = useRouteMatch<{ id: string }>();
  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(AppContext);
  // const [searchBarClass, setActiveSearchBar] = useState(false);
  const [optionsArr, setOptions] = useState<searchOptions[] | []>([]);
  const [breadCrumbPath, setAppNavPath] = useState<NavPathType[]>(navPaths);
  // const [userList,setUserList]=useState<any>([])
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const { state, setState } = useLocalStorage("selectedUserId", "");
  const { userList } = useUserlistState();
  const [locState, setLocState] = useState<any>({
    unreadNotiricationsCount: 0
  });
  const [userDetails, setUserDetailData] = useState<any>(null)
  useMemo(() => {
    if (state) {
      localStorage.setItem("selectedUserId", state);
    }
    if (userId && !state) {
      localStorage.setItem("selectedUserId", userId);
    }
  }, [userId, state]);
  useEffect(() => {
    if (user) {
      setUserAgent(isAgency(user));
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      fetchUserDetails(userId)
    }
  }, [userId])

  const fetchUserDetails = async (id: string) => {
    setLoadingMessage("Fetching users details...");
    const { url, method } = API_ENDPOINTS.ACCOUNTS.getUserDetails(id);
    const response = await callApi({
      url,
      method
    });
    if (response.success) {
      setUserDetailData(response?.data?.user)
    }
    setLoadingMessage("");
  };

  useMemo(() => {
    if (navPaths) {
      let cloneNavpath = [...navPaths];
      cloneNavpath.splice(0, 0, { label: "Home", linkTo: "/accounts" });
      setAppNavPath(cloneNavpath);
    }
  }, [navPaths]);
  const isAgency = (user: any) => {
    return true;
  };

  // useEffect(() => {
  //   fetchUserList()
  // }, [])

  // const fetchUserList = async () => {
  //   const { url, method } = API_ENDPOINTS.ACCOUNTS.getUserListUrl();
  //   const response = await callApi({
  //     url,
  //     method,
  //   });
  //   if (response.success) {
  //     setUserList(response.data.users)
  //     // if(response && response.data && response.data.users){
  //     //   const userID= response.data.users.filter((user:any)=> user.email===email)
  //     //   if(userID.length){
  //     //     console.log("userID[0]['id']",userID, userID[0]['userId'])
  //     //     localStorage.setItem("selectedUserId", String(userID[0]['userId']));
  //     //     setState(String(userID[0]['userId']))
  //     //   }
  //     // }
  //   }
  // }
  const routeSubscription = () => {
    if (userDetails?.userType === 'agencyClient') {
      ivyPathUrl("profile?cur=0")
    } else {
      history.push(`/subscription`, true)
    }
  }

  const DropDownMenu = useMemo(
    () => (
      <Menu style={{ borderRadius: "8px" }}>
        <AccountDetails name={name} email={email} />
        <Menu.Divider style={{ margin: 0 }} />
        <Menu.Item
          icon={<ApiOutlined color="primary" style={{ fontSize: "1.3em", marginRight: "15px" }} />}
          onClick={() => history.push(`/integrations`, true)}
          style={{ minWidth: "250px", padding: "15px 25px", fontSize: "1.1em" }}
        >
          <div>{t("pageLabels.integrations")}</div>
        </Menu.Item>
        {metaConfig?.supportEmail && <Menu.Item
          icon={<CustomerServiceOutlined style={{ fontSize: "1.3em", marginRight: "15px" }} />}
          onClick={() => {
            setIsSupportModalOpen(true);
          }}
          style={{ minWidth: "250px", padding: "15px 25px", fontSize: "1.1em" }}
        >
          {t("pageLabels.support")}
        </Menu.Item>}
        <Menu.Item
          icon={<IdcardOutlined color="primary" style={{ fontSize: "1.3em", marginRight: "15px" }} />}
          onClick={() => (window.location.href = ivyPathUrl("profile?cur=1"))}
          style={{ minWidth: "250px", padding: "15px 25px", fontSize: "1.1em" }}
        >
          <div>{t("pageLabels.profile")}</div>
        </Menu.Item>
        {userDetails?.userType === "agencyClient" ? null :
          <Menu.Item
            icon={<CreditCardOutlined color="primary" style={{ fontSize: "1.3em", marginRight: "15px" }} />}
            // onClick={() => (window.location.href = ivyPathUrl("profile?cur=0"))}
            onClick={() =>routeSubscription()}
            style={{ minWidth: "250px", padding: "15px 25px", fontSize: "1.1em" }}
          >
            <div>{t("pageLabels.billing")}</div>
          </Menu.Item>}
        <Menu.Item
          icon={<CalendarOutlined color="primary" style={{ fontSize: "1.3em", marginRight: "15px" }} />}
          onClick={() => history.push(`/holidays`, true)}
          style={{ minWidth: "250px", padding: "15px 25px", fontSize: "1.1em" }}
        >
          <div>{t("pageLabels.holidays")}</div>
        </Menu.Item>
        <Menu.Divider style={{ margin: 0 }} />
        <Menu.Item
          icon={<LogoutOutlined style={{ fontSize: "1.3em", marginRight: "15px" }} />}
          onClick={logout}
          style={{ minWidth: "250px", padding: "15px 25px", fontSize: "1.1em" }}
        >
          {t("pageLabels.logout")}
        </Menu.Item>
      </Menu>
    ),
    [logout, name, email, t, userDetails?.userType]
  );

  const toggleNotificationsDrawer = useCallback(() => setShowNotificationsDrawer((prev) => !prev), []);
  const handleSupportModal = useCallback(() => setIsSupportModalOpen((prev) => !prev), []);

  let accountId = String(localStorage.getItem("account_id"));
  let integration = JSON.parse(localStorage.getItem("integrationData") as string);
  const prospectsFinder = useMemo(() => {
    return integration && integration.prospectsFinder && integration.prospectsFinder.enable ? integration.prospectsFinder.enable : false;
  }, [integration?.prospectsFinder]);

  useEffect(() => {
    if (isEmpty(integration)) {
      fetchIntegrationSettings();
    }
  }, []);

  const callAlertCountFun = () => {
    const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";
    if (uId || userId) {
      localStorage.setItem("selectedUserId", uId || userId || '');
      fetchAlertCounts(uId || userId || '')
    }
  };
  useEffect(() => {
    callAlertCountFun();
  }, [localStorage.getItem("selectedUserId"), userId]);

  const fetchAlertCounts = async (uId: string) => {
    // setLoadingMessage("Fetching Alert Counts ...");
    const { url, method } = API_ENDPOINTS.NOTIFICATION.getAlertCounts(uId);
    const response = await callApi({
      url,
      method
    });
    if (response.success) {
      setLocState((prev: any) => ({
        ...prev,
        unreadNotiricationsCount: response?.data?.unread
      }));
    }
    // setLoadingMessage("");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      callAlertCountFun();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  const fetchIntegrationSettings = async () => {
    setLoadingMessage("Fetching Integration Settings ...");
    const { url, method } = API_ENDPOINTS.INTEGRATIONS.getIntegrationSettingsUrl();
    const response = await callApi({
      url,
      method
    });
    if (response.success) {
      localStorage.setItem("integrationData", JSON.stringify(response.data.integration));
    }
    setLoadingMessage("");
  };
  const NAV_PATHS = useMemo(() => {
    const NAV_ICONS_SET_1 =
      prospectsFinder && prospectsFinder
        ? [
          {
            id: NavPathIdEnum.REPORT,
            tooltipTitle: ROUTE_PATHS.REPORT.label,
            path: `/report`,
            Icon: CalendarOutlined
          },
          {
            id: NavPathIdEnum.MAILBOXES,
            tooltipTitle: ROUTE_PATHS.MAILBOXES.label,
            path: ROUTE_PATHS.MAILBOXES.path,
            Icon: MailBoxIcon
          },
          {
            id: NavPathIdEnum.CAMPAIGNS,
            tooltipTitle: ROUTE_PATHS.CAMPAIGNS.label,
            path: `/account/${accountId}/campaigns`,
            Icon: SendOutlined
          },
          // {
          //   id: NavPathIdEnum.UNIBOX,
          //   tooltipTitle: ROUTE_PATHS.UNIBOX.label,
          //   path: `/account/${accountId}/unibox`,
          //   Icon: InboxOutlined
          // },
          {
            id: NavPathIdEnum.COMPANY_SEARCH,
            tooltipTitle: ROUTE_PATHS.COMPANY_SEARCH.label,
            path: ROUTE_PATHS.COMPANY_SEARCH.path,
            Icon: UserOutlined
          },
          // {
          //   id: NavPathIdEnum.MY_TEAM,
          //   tooltipTitle: ROUTE_PATHS.MY_TEAM.label,
          //   path: ROUTE_PATHS.MY_TEAM.path,
          //   Icon: TeamOutlined
          // },
          {
            id: NavPathIdEnum.HOLIDAYS,
            tooltipTitle: ROUTE_PATHS.HOLIDAYS.label,
            path: ROUTE_PATHS.HOLIDAYS.path,
            Icon: CoffeeOutlined
          },
          {
            id: NavPathIdEnum.DNC_LIST,
            tooltipTitle: ROUTE_PATHS.DNC_LIST.label,
            path: ROUTE_PATHS.DNC_LIST.path,
            Icon: EyeInvisibleOutlined
          },
          {
            id: NavPathIdEnum.CUSTOM_DOMAIN_TRACKING,
            tooltipTitle: ROUTE_PATHS.DOMAIN.label,
            path: `/account/${accountId}` + ROUTE_PATHS.DOMAIN.path,
            Icon: EnvironmentOutlined
          }
        ]
        : [  {
          id: NavPathIdEnum.REPORT,
          tooltipTitle: ROUTE_PATHS.REPORT.label,
          path: `/report`,
          Icon: CalendarOutlined
        },
          {
            id: NavPathIdEnum.MAILBOXES,
            tooltipTitle: ROUTE_PATHS.MAILBOXES.label,
            path: ROUTE_PATHS.MAILBOXES.path,
            Icon: MailBoxIcon
          },
          {
            id: NavPathIdEnum.CAMPAIGNS,
            tooltipTitle: ROUTE_PATHS.CAMPAIGNS.label,
            path: `/account/${accountId}/campaigns`,
            Icon: SendOutlined
          },
          // {
          //   id: NavPathIdEnum.NOTIFICATIONS,
          //   tooltipTitle: ROUTE_PATHS.NOTIFICATION.label,
          //   path: `/notifications`,
          //   Icon: AlertFilled
          // },
          // {
          //   id: NavPathIdEnum.UNIBOX,
          //   tooltipTitle: ROUTE_PATHS.UNIBOX.label,
          //   path: `/account/${accountId}/unibox`,
          //   Icon: InboxOutlined
          // },
          // {
          //   id: NavPathIdEnum.MY_TEAM,
          //   tooltipTitle: ROUTE_PATHS.MY_TEAM.label,
          //   path: ROUTE_PATHS.MY_TEAM.path,
          //   Icon: TeamOutlined
          // },
          {
            id: NavPathIdEnum.HOLIDAYS,
            tooltipTitle: ROUTE_PATHS.HOLIDAYS.label,
            path: ROUTE_PATHS.HOLIDAYS.path,
            Icon: CoffeeOutlined
          },
          {
            id: NavPathIdEnum.DNC_LIST,
            tooltipTitle: ROUTE_PATHS.DNC_LIST.label,
            path: ROUTE_PATHS.DNC_LIST.path,
            Icon: EyeInvisibleOutlined
          },
          {
            id: NavPathIdEnum.CUSTOM_DOMAIN_TRACKING,
            tooltipTitle: ROUTE_PATHS.DOMAIN.label,
            path: `/account/${accountId}` + ROUTE_PATHS.DOMAIN.path,
            Icon: EnvironmentOutlined
          }
        ];

    const NAV_ICONS_SET_2 = [
      {
        id: NavPathIdEnum.UNIBOX,
        tooltipTitle: ROUTE_PATHS.UNIBOX.label,
        path: `/unibox`,
        Icon: InboxOutlined
      },
      // {
      //   id: NavPathIdEnum.NOTIFICATIONS,
      //   tooltipTitle: ROUTE_PATHS.NOTIFICATION.label,
      //   path: `/notifications`,
      //   Icon: AlertFilled
      // }
      //  {
      //   id: NavPathIdEnum.INTEGRATION,
      //   tooltipTitle: ROUTE_PATHS.INTEGRATION.label,
      //   path: ROUTE_PATHS.INTEGRATION.path,
      //   Icon: ApiOutlined
      // },
    ];

    return match.path.match(/accounts$|billing$|unibox$|notifications$/) || match.path === "/unibox"
      ? NAV_ICONS_SET_2
      : match.path.match(/(integrations)/)
        ? []
        : match.path.match(/(account|holidays|mailboxes|dnclist|search)/) || match.path === "/account/:id/unibox"
          ? NAV_ICONS_SET_1
          : [];
  }, [match, accountId, prospectsFinder]);

  const getIsNavBarIconActive = useCallback(
    (navPathId: NavPathIdEnum) => () => {
      const {
        location: { pathname }
      } = history;

      switch (navPathId) {
        case NavPathIdEnum.COMPANY_SEARCH:
        case NavPathIdEnum.CAMPAIGNS:
        case NavPathIdEnum.DNC_LIST:
        case NavPathIdEnum.HOLIDAYS:
        // case NavPathIdEnum.INTEGRATION:
        case NavPathIdEnum.MAILBOXES:
        case NavPathIdEnum.MY_TEAM:
        case NavPathIdEnum.UNIBOX:
        case NavPathIdEnum.NOTIFICATION:
        case NavPathIdEnum.SETTINGS: {
          const navPath = NAV_PATHS.find(({ id }) => id === navPathId);
          if (!navPath || !navPath.path) return false;
          return pathname.indexOf(navPath.path) === 0;
        }
        default:
          return false;
      }
    },
    [history, NAV_PATHS, prospectsFinder]
  );

  // Header Global Search : Start
  const renderTitle = (title: any) => <span>{title}</span>;

  const renderItem = (title: any, id: string, type: string) => ({
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
        onClick={() => {
          type === "inbox" || type === "mailbox"
            ? history.push(`/mailboxes/${id}`, true)
            : type === "campaign"
              ? history.push(`/campaigns/${id}/spinners`, true)
              : type === "prospect"
                ? history.push(`/prospects/${id}`, true)
                : history.push(`/accounts`, true);
        }}
      >
        {title}
      </div>
    )
  });

  const onChange = useCallback(
    async (search) => {
      console.log("search::::", search);
      // if (!search || !search.length) {
      //   setOptions([])
      //   return;
      // }

      const { url, method } = API_ENDPOINTS.ACCOUNTS.getGlobalSearch(search);
      const response = await callApi({
        url,
        method
      });

      if (response.success) {
        var cars = response.data.items;
        var result = cars.reduce(function (r: any, a: any) {
          r[a.type] = r[a.type] || [];
          r[a.type].push(a);
          return r;
        }, Object.create(null));

        let data: any = [];
        Object.keys(result).map((sa) => {
          data.push({
            labels: sa,
            options: result[sa]
          });
        });
        setOptions(data);
      }
    },
    [callApi, setLoadingMessage]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((vx) => onChange(vx), 420),
    [onChange]
  );

  const onFormValueChange = async (changedValues: any) => {
    console.log("changedValues:::", changedValues);
    try {
      debouncedSearch(changedValues);
    } catch (error: any) {
      const textValue = changedValues;
      if (textValue === "" || !error?.errorFields?.length) {
        debouncedSearch(textValue);
      }
    }
  };
  const selectUser = (e: any) => {
    setState(String(e.value));
    localStorage.setItem("selectedUserId", String(e.value));
    const id = localStorage.getItem("selectedUserId");
    if (window.location.pathname === "/unibox") {
    } else if (!["account", "notifications"].includes(window.location.pathname)) {
      history.push(`/accounts`, true);
    }
  };

  return (
    <Flex
      h={APPBAR_HEIGHT}
      position="fixed"
      p="0 40px"
      justify="space-between"
      align="center"
      zIndex={10}
      style={{
        top: 0,
        left: 0,
        right: 0
      }}
      bgColor={theme?.headerColor ?? APP_BAR_COLOR}
    >
      <Flex align="center">
        <Tooltip placement="bottom" title={t("pageLabels.home")}>
          <Link
            to={{
              pathname: "/accounts",
              state: { state: true }
            }}
            style={{ display: "flex" }}
          >
            <Logo config={metaConfig} />
          </Link>
        </Tooltip>
        <Flex ml={10} mt={2}>
          {breadCrumbPath.map(({ label, linkTo }, index) => (
            <Flex key={label}>
              {label === "Home" ? (
                <Link
                  to={{
                    pathname: linkTo,
                    state: { state: true }
                  }}
                >
                  <Text
                    variant="body1"
                    className="linkhover"
                    style={{
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      height: 18,
                      color: "#bdbdbd",
                      cursor: "pointer"
                    }}
                  >
                    {label}
                  </Text>
                </Link>
              ) : linkTo ? (
                <Link to={linkTo}>
                  <Text
                    variant="body1"
                    className="linkhover"
                    style={{
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      height: 18,
                      color: "#bdbdbd",
                      cursor: "pointer"
                    }}
                  >
                    {label}
                  </Text>
                </Link>
              ) : (
                <Text
                  variant="body1"
                  className="lastactive"
                  style={{
                    maxWidth: "400px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    height: 18,
                    color: "#bdbdbd"
                  }}
                >
                  {label}
                </Text>
              )}
              {index !== breadCrumbPath.length - 1 && (
                //    <Text variant="body1" ml={5} mr={5}>
                //    <RightOutlined
                //      style={{ fontSize: 12, color: PRIMARY_COLOR }}
                //    />
                //  </Text>
                <div style={{ margin: "0 10px", color: "#bdbdbd", fontSize: "18px" }}>/</div>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex align="center" className={classes.searchMainBox}>
        {userList && userList.length ? (
          <div style={{ marginRight: "20px" }}>
            <Select
              placeholder="select user"
              onSelect={(val: any, event: any) => selectUser(event)}
              style={{ width: "300px" }}
              defaultValue={
                localStorage.getItem("selectedUserId") &&
                  localStorage.getItem("selectedUserId") !== "null" &&
                  localStorage.getItem("selectedUserId") !== "undefined"
                  ? localStorage.getItem("selectedUserId")
                  : ""
              }
            >
              {(userList || []).map(({ companyName, userId, email }: any) => (
                <Option key={userId} value={userId}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <span>{companyName}</span>
                    <strong>{email}</strong>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
        ) : null}
        {/*  <Flex className={!searchBarClass ? classes.searchInputHideShow : classes.active}>
          <Div>
            <AutoComplete
              dropdownClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={500}
              style={{
                width: 400,
                backgroundColor: "#030b18",
                border: "1px solid #030b18",
                borderRadius: "2px"
              }}
              listHeight={500}
              options={optionsArr.map((oA) => ({
                label: renderTitle(oA.labels),
                options: oA.options.map((a) => renderItem(a?.name, a?.id, a?.type.toLowerCase()))
              }))}
            >
              <Input.Search
                style={{
                  width: 400,
                  backgroundColor: "#030b18",
                  border: "1px solid #030b18",
                  borderRadius: "2px"
                }}
                placeholder="Search here"
                onChange={(e) => onFormValueChange(e.target.value)}
              />
            </AutoComplete>
          </Div>
          <Flex align="center" style={{ color: "#fff", marginRight: "20px", fontSize: "18px", marginLeft: "20px", cursor: "pointer" }}>
            <Tooltip placement="bottom" title="Search">
              <span className={classes.searchShowHide}>
                <SearchOutlined onClick={() => setActiveSearchBar((prev) => !prev)} />
              </span>
              <span className={classes.closeShowHide}>
                <CloseCircleOutlined onClick={() => setActiveSearchBar((prev) => !prev)} />
              </span>
            </Tooltip>
          </Flex>
        </Flex> */}

        <Flex>
          {NAV_PATHS.map(
            (
              { tooltipTitle, path, Icon, id } //onClick
            ) => (
              <>
                <div className={`${NavPathIdEnum.NOTIFICATIONS === id ? "notification_nav" : ""}`}>
                  <NavIcon
                    key={id}
                    id={id}
                    path={path}
                    Icon={Icon}
                    tooltipTitle={tooltipTitle}
                    isActive={getIsNavBarIconActive(id)}
                  // onClick={onClick}
                  />
                  {/* {NavPathIdEnum.NOTIFICATIONS === id && (
                    <span className="notification__icon">{path === "/notifications" && locState?.unreadNotiricationsCount}</span>
                  )} */}
                </div>
              </>
            )
          )}
        </Flex>
        <Dropdown overlay={DropDownMenu} placement="bottomRight" trigger={["click"]}>
          <Avatar theme={theme.antdConf} onClick={(e: React.SyntheticEvent) => e.preventDefault()} />
        </Dropdown>
      </Flex>

      <NotificationsDrawer visible={showNotificationsDrawer} onClose={toggleNotificationsDrawer} />
      {isSupportModalOpen && <ContactSupport handleOpenClose={handleSupportModal} />}
    </Flex>
  );
};

export default AppBar;
