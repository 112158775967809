import { assign, createMachine } from "xstate";
import {
  OpenTrackingActionDataArgs, OpenTrackingResponse
} from "./OpenTrackingTypes";

const schema = {
  context: {} as { data: OpenTrackingResponse | undefined; error: any },
  events: {} as { type: "FETCH"; data?: OpenTrackingActionDataArgs } | { type: "RETRY"; data: OpenTrackingActionDataArgs }
};
export const FETCH_MACHINE_OPEN_TRACKING = createMachine<typeof schema["context"], typeof schema["events"]>({
  id: "fetch-machine",
  initial: "idle",
  context: {
    data: undefined,
    error: undefined
  },
  states: {
    idle: {
      on: { FETCH: "loading" }
    },
    loading: {
      invoke: {
        src: "fetchData",
        onDone: {
          target: "success",
          actions: assign({
            data: (ctx, event) => ({ ...ctx.data, ...event.data })
          })
        },
        onError: {
          target: "failure",
          actions: assign({
            error: (_, event) => event.data
          })
        }
      }
    },
    success: {
      entry: "notifySuccess",
      on: { FETCH: "loading" }
    },
    failure: {
      entry: "notifyError",
      on: {
        RETRY: [{ target: "loading" }],
        FETCH: {
          target: "loading"
        }
      }
    }
  }
});
