import React, { useCallback, useContext, useEffect, useState } from "react";
import { Dropdown, Modal, Tag, Menu, Button, Tooltip, Form, Input, Space } from "antd";
import { InboundMailWithThreadType } from "types/Campaign";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import moment from "moment";
import { getStageColor, getStatusColor } from "constants/prospect";
import { isNumber } from "lodash";
import AutoTag from "./AutoTag";
import classes from "styles/common.module.css";
import Icon, {
  RollbackOutlined,
  MailOutlined,
  DeliveredProcedureOutlined,
  DownOutlined,
  DeleteOutlined,
  ForwardOutlined,
  ForwardFilled,
  ArrowRightOutlined
} from "@ant-design/icons";
import { ReactComponent as UnSubscribeAll } from "images/removeall.svg";
import { ReactComponent as UnSubscribe } from "images/unsubscribe.svg";
import appContext from "contexts/appContext";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import { getCampaignSequencesSelector } from "state/selectors";
import { useSelector } from "react-redux";
import CampaignActions from "state/actions/campaign";
import { useDispatch } from "react-redux";
import { ReactComponent as externalLink } from "images/external-link.svg";
import { Link, useHistory } from "react-router-dom";
import DeliveryTemplateModal from "components/Campaign/DeliveryTemplateModal/index";
import DeliveryViewModal from "components/Campaign/DeliveryTemplateModal/DeliveryViewModal";
import DeliveryTempModal from "components/Campaign/DeliveryTemplateModal/DeliveryTempModal";

const ExternalLinkIcon = (props: any) => <Icon component={externalLink} {...props} style={{ fontSize: "19px" }} />;

interface MailDetailModalProps {
  onCancel: () => void;
  mail: InboundMailWithThreadType;
  sequences: any;
  sequenceName: any;
  fetchTags: () => void;
  getMailOnClick: () => void;
  onStartInfoSequence: () => void;
  onStartPowerSequence: () => void;
  onStartFutureSequence: () => void;
  onStartReferralSequence: () => void;
  onStartMeetingSequence: () => void;
  onStartRescheduleSequence: () => void;
  onStartCustomSequence: () => void;
  onTag: () => void;
  onDeliver: () => void;
  onReply: () => void;
  onActivate: () => void;
  onUnsubscribe: () => void;
  onMarkAsUnRead: () => void;
  onRemoveAccount: () => void;
  onDiscardMail: () => void;
  onQualifyMail: () => void;
  onRemoveMails: () => void;
  onStartSequence: (email: string, id: string, sequenceId: string, type: string) => void;
  tagData: any;
  levelType: string;
  getMailOnClicks: () => void;
  checkIsSpam: boolean;
  refreshContained?: () => void;
  isProccesed?: boolean;
  onMarkAsProccessed: () => void;
  checkIsReplies: boolean;
  openDetailsMessageOnModal?: () => void;
}

const MailDetailModal = ({
  getMailOnClick,
  fetchTags,
  onCancel,
  mail,
  onStartFutureSequence,
  onStartInfoSequence,
  onStartPowerSequence,
  onStartReferralSequence,
  onStartMeetingSequence,
  onStartRescheduleSequence,
  onStartCustomSequence,
  onTag,
  onDeliver,
  onReply,
  onActivate,
  onUnsubscribe,
  onMarkAsUnRead,
  onRemoveAccount,
  onDiscardMail,
  onQualifyMail,
  onStartSequence,
  sequences,
  sequenceName,
  onRemoveMails,
  tagData,
  levelType,
  getMailOnClicks,
  checkIsSpam = false,
  refreshContained,
  isProccesed = false,
  onMarkAsProccessed,
  checkIsReplies = false,
  openDetailsMessageOnModal
}: MailDetailModalProps) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { callApi } = useApi();

  const [tootTipTitle, setTollTipTitle] = useState("");
  const [qualifiedEmail, setQualifiedEmail] = useState("");
  const [showQualifyModal, setShowQualifyModal] = useState(false);
  const { setLoadingMessage } = useContext(appContext);
  const [tagName, setTagName] = useState("");
  const [form] = Form.useForm();
  const [state, setState] = useState<any>({ openClosedeliveryModal: false, openClosedeliveryViewModal: false, selectedTags: null });
  const { openClosedeliveryModal, openClosedeliveryViewModal, selectedTags } = state;

  useEffect(() => {
    // console.log(tagData)
    if (mail && mail.mailThread && mail.mailThread.tagIds && mail.mailThread.tagIds.length) {
      const tagName = tagData.filter((td: any) => td.id === mail.mailThread.tagIds[0]).map((obj: any) => obj.name);
      // console.log("tagName", tagName)
      setTagName(tagName[0]);
    }
    setTollTipTitle(`Remove all prospects from domain ${mail.inboundMessage.from.split("@")[1]}`);
  }, [mail]);

  const dispatchFetchSequences = useCallback(() => {
    dispatch(CampaignActions.fetchSequences(mail.mailThread.campaignId));
  }, [dispatch, mail.mailThread.campaignId]);

  const sequencesData: any = useSelector(getCampaignSequencesSelector(mail.mailThread.campaignId));

  useEffect(() => {
    if (levelType !== "CAMPAIGN") {
      dispatchFetchSequences();
    }
  }, [dispatchFetchSequences, levelType]);

  const handleInfoSequence = useCallback(() => {
    onStartInfoSequence();
  }, [onStartInfoSequence]);

  const handlePowerSequence = useCallback(() => {
    onStartPowerSequence();
  }, [onStartPowerSequence]);

  const handleFutureSequence = useCallback(() => {
    onStartFutureSequence();
  }, [onStartFutureSequence]);

  const handleReferralSequence = useCallback(() => {
    onStartReferralSequence();
  }, [onStartReferralSequence]);

  const handleMeetingSequence = useCallback(() => {
    onStartMeetingSequence();
  }, [onStartMeetingSequence]);

  const handleRescheduleSequence = useCallback(() => {
    onStartRescheduleSequence();
  }, [onStartRescheduleSequence]);

  const handleCustomSequence = useCallback(() => {
    onStartCustomSequence();
  }, [onStartCustomSequence]);

  const handleOnTag = useCallback(() => {
    onTag();
  }, [onTag]);

  const handleOnDeliver = useCallback(() => {
    onDeliver();
  }, [onDeliver]);

  const handleOnReply = useCallback(() => {
    onReply();
  }, [onReply]);
  const handleMarkAsUnRead = useCallback(() => {
    onMarkAsUnRead();
  }, [onMarkAsUnRead]);

  const handleOnActivate = useCallback(() => {
    onActivate();
  }, [onActivate]);

  const handleOnUnsubscribe = useCallback(() => {
    onUnsubscribe();
  }, [onUnsubscribe]);
  const handleOnRemoveAllDomain = useCallback(() => {
    onRemoveAccount();
  }, [onRemoveAccount]);

  const handleOnDiscardMail = useCallback(() => {
    onDiscardMail();
  }, [onDiscardMail]);

  const handleQualifyMail = useCallback(async () => {
    setLoadingMessage("Qualifying the Inbox...");
    const { url, method } = API_ENDPOINTS.INBOUND.getSpamMailUpdateUrl(mail.inboundMessage.id);
    const body: any = {
      action: "Qualify",
      qualifiedEmail: qualifiedEmail
    };

    try {
      await callApi({
        url,
        method,
        data: body
      });
      onQualifyMail();
    } catch (err) {
    } finally {
      setLoadingMessage("");
    }
  }, [callApi, setLoadingMessage, onQualifyMail, qualifiedEmail]);

  const capitalize = (seqName: string) => {
    if (seqName.includes("_")) {
      seqName = seqName.replace("_", " ");
    }
    return seqName.charAt(0).toUpperCase() + seqName.slice(1).toLowerCase();
  };

  const handleStartSequence = useCallback(
    (email: string, id: string, sequenceId: string, type: string) => {
      onStartSequence(email, id, sequenceId, type);
    },
    [onStartSequence]
  );

  const handleMarkAsProcessed = useCallback(() => {
    onMarkAsProccessed();
  }, [onMarkAsProccessed]);

  const DropDownMenu = useCallback(() => {
    const sequencesMapData = sequences && sequences.length ? sequences : sequencesData;
    return (
      <Menu>
        {sequencesMapData
          ?.filter((s: any) => s.type !== "INITIAL")
          .map((seq: any) => (
            <Menu.Item
              onClick={() => {
                handleStartSequence(mail.inboundMessage.from, mail.inboundMessage.id, seq.id, seq.type);
              }}
              key={seq.id}
            >
              Start {capitalize(seq.name)} Sequence
            </Menu.Item>
          ))}
      </Menu>
    );
  }, [
    sequencesData,
    handleFutureSequence,
    handleInfoSequence,
    handleOnReply,
    handlePowerSequence,
    handleReferralSequence,
    handleOnActivate,
    handleOnUnsubscribe,
    handleMarkAsUnRead
  ]);

  const redirectToCampaign = (campaignId: string) => {
    // e.stopPropagation();
    // history.push(`/campaigns/${campaignId}`);
    window.open(`/campaigns/${campaignId}`, "_blank");
  };

  const getMailOnClicked = async () => {
    await getMailOnClicks();
  };

  const discardEmails = async (id: string) => {
    if (id) {
      const { url, method } = API_ENDPOINTS.INBOUND.discardMails();
      const res = await callApi({ url, method, data: { ids: [id] } });
      console.log("resresresres", res);
      if (res?.success) {
        refreshContained && refreshContained();
      }
    }
  };

  const closeDeliverytemplateModal = () => {
    setState((prev: any) => ({ ...prev, openClosedeliveryModal: false, openClosedeliveryViewModal: false, selectedTags: null }));
  };
  const openDeliverytemplateModal = () => {
    setState((prev: any) => ({ ...prev, openClosedeliveryModal: true }));
  };

  const closeDeliveryViewModal = () => {
    setState((prev: any) => ({ ...prev, openClosedeliveryViewModal: false }));
  };
  const openDeliveryViewModal = () => {
    setState((prev: any) => ({ ...prev, openClosedeliveryViewModal: true }));
  };

  const proceedWithoutTemplate = () => {
    setState((prev: any) => ({ ...prev, openClosedeliveryModal: true, selectedTags: null, openClosedeliveryViewModal: false }));
  };
  const selectThisTemplate = (tag: any) => {
    setState((prev: any) => ({ ...prev, openClosedeliveryModal: true, selectedTags: tag, openClosedeliveryViewModal: false }));
  };
  const onBack = () => {
    setState((prev: any) => ({ ...prev, openClosedeliveryModal: false, openClosedeliveryViewModal: true }));
  };
  return (
    <>
      <Div className="fixed__response-header">
        <Flex mt={1} gap={15} justify="space-between" align="center">
          <Text variant="h6">{mail.inboundMessage.subject}</Text>
          <Tag color="green">{isProccesed ? "Processed" : "Pending"}</Tag>
          {mail && mail.mailThread && mail.mailThread.prospectId ? (
            <Flex ml="auto" align="center" justify="flex-end">
              <Dropdown overlay={DropDownMenu} trigger={["click"]}>
                <Button style={{ marginRight: 8 }}>
                  <Space>
                    Start New Sequence
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>

              <Tooltip title="Reply" placement="bottom">
                <Button type="default" shape="circle" icon={<RollbackOutlined />} size="small" style={{ marginRight: 8 }} onClick={handleOnReply} />
              </Tooltip>
              <Tooltip title="Forward" placement="bottom">
                <Button type="default" shape="circle" icon={<ArrowRightOutlined />} size="small" style={{ marginRight: 8 }} onClick={openDeliveryViewModal} />
              </Tooltip>
              {/* <Tooltip title="Deliver" placement="bottom">
                <Button
                  type="default"
                  shape="circle"
                  icon={<DeliveredProcedureOutlined />}
                  size="small"
                  style={{ marginRight: 8 }}
                  onClick={openDeliveryViewModal}
                />
              </Tooltip> */}
              <Tooltip title="Mark As Unread" placement="bottom">
                <Button type="default" shape="circle" icon={<MailOutlined />} size="small" style={{ marginRight: 8 }} onClick={handleMarkAsUnRead} />
              </Tooltip>
              {/* <Tooltip title="Activate" placement="bottom">
                <Button
                  type="default"
                  shape="circle"
                  icon={<CheckCircleOutlined />}
                  size="small"
                  style={{ marginRight: 8 }}
                  onClick={handleOnActivate}
                />
              </Tooltip> */}
              <Tooltip title="Unsubscribe Prospect" placement="bottom">
                <Button
                  type="default"
                  shape="circle"
                  icon={<UnSubscribe />}
                  size="small"
                  style={{ width: "90%", marginRight: 8, display: "flex", alignItems: "center", justifyContent: "center" }}
                  onClick={handleOnUnsubscribe}
                />
              </Tooltip>
              <Tooltip title={tootTipTitle} placement="bottom">
                <Button
                  type="default"
                  shape="circle"
                  icon={<UnSubscribeAll />}
                  size="small"
                  style={{ width: "90%", marginRight: 8, display: "flex", alignItems: "center", justifyContent: "center" }}
                  onClick={handleOnRemoveAllDomain}
                />
              </Tooltip>
              { !isProccesed && (
                <Tooltip title="Mark As Processed" placement="bottom">
                  <Button type="default" shape="circle" icon={<ForwardFilled />} size="small" style={{ marginRight: 8 }} onClick={handleMarkAsProcessed} />
                </Tooltip>
              )}
              {!checkIsSpam ? (
                <Tooltip title="Delete" placement="bottom">
                  <Button
                    type="default"
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="small"
                    style={{ width: "90%", marginRight: 8, display: "flex", alignItems: "center", justifyContent: "center" }}
                    onClick={() => discardEmails(mail?.inboundMessage?.id)}
                  />
                </Tooltip>
              ) : null}
            </Flex>
          ) : (
            <Flex>
              <Button
                type="default"
                size="small"
                style={{ width: "90%", marginRight: 8, display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={() => {
                  setShowQualifyModal((prev) => !prev);
                }}
              >
                Qualify
              </Button>
              <Button
                type="default"
                size="small"
                style={{ width: "90%", marginRight: 8, display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={handleOnDiscardMail}
              >
                Discard
              </Button>
            </Flex>
          )}
        </Flex>
        {mail.prospect && (
          <Flex mt={5}>
            {isNumber(mail.prospect.lastTouchNumber) && (
              <Tag
                color="blue"
                style={{
                  borderRadius: 5
                }}
              >
                Touch {mail.prospect.lastTouchNumber}
              </Tag>
            )}
            {/* {mail.prospect.status && (
              <Tag
                color={getStageColor(mail.prospect.status)}
                style={{
                  borderRadius: 5
                }}
              >
                <Text variant="caption" bold color="white">
                  {mail.prospect.status}
                </Text>
              </Tag>
            )
            } */}

            {mail.prospect.subStatus && (
              <Tag
                color={getStatusColor(mail.prospect.subStatus)}
                style={{
                  borderRadius: 5
                }}
              >
                <Text variant="caption" bold color="white">
                  {mail.prospect.subStatus}
                </Text>
              </Tag>
            )}
            <AutoTag
              data={mail}
              getMailOnClick={getMailOnClick}
              getMailOnClicks={getMailOnClicked}
              fetchTags={() => fetchTags()}
              tagData={tagData}
              tagIds={mail.mailThread && mail.mailThread.tagIds ? mail.mailThread.tagIds : []}
              onRemoveMails={onRemoveMails}
              isProccesed={isProccesed}
              refreshContained={refreshContained}
              openDetailsMessageOnModal={openDetailsMessageOnModal}
            />
          </Flex>
        )}
        {/* {sequenceName.type && (
          <Tag
            color="blue"
            style={{
              borderRadius: 5
            }}
          >
            {sequenceName.type}
          </Tag>
        )} */}

        <Flex justify="space-between" mt={20}>
          <Flex gap={20}>
            <Flex direction="column">
              <Text variant="overline" color="secondary">
                From
              </Text>
              <Text variant="caption">{mail.inboundMessage.from}</Text>
            </Flex>
            <Flex direction="column">
              <Text variant="overline" color="secondary">
                To
              </Text>
              <Text variant="caption">{mail.inboundMessage.to}</Text>
            </Flex>
          </Flex>
          {mail?.mailThread && mail?.mailThread?.campaignName && (
            <Flex direction="column">
              <Text variant="overline" color="secondary">
                Campaign Name
              </Text>
              <Flex direction="row" align="start">
                <Text variant="caption">{mail?.mailThread?.campaignName || ""}</Text>
                {levelType !== "CAMPAIGN" ? (
                  <Tooltip title={"Click to open the campaign"} mouseLeaveDelay={0} placement="top" className={classes.emailCopy}>
                    <Button
                      className={classes.emailButton}
                      onClick={() => redirectToCampaign(mail.mailThread.campaignId)}
                      style={{ marginTop: "-6px", border: "none" }}
                    >
                      <ExternalLinkIcon fontSize="19px" />
                    </Button>
                  </Tooltip>
                ) : null}
              </Flex>
            </Flex>
          )}

          <Flex direction="column">
            <Text variant="overline" color="secondary">
              Received at
            </Text>
            <Text variant="caption">{moment(mail.inboundMessage.sentTime).format("ddd, MMM DD, hh:mm a")}</Text>
          </Flex>
        </Flex>
        {mail.mailThread?.ccs?.length ? (
          <Flex mt={10} align="baseline">
            <Text variant="overline" color="secondary">
              Cc
            </Text>
            <Div>
              {mail.mailThread.ccs?.map((c) => (
                <Tooltip title={c?.encodedPersonal}>
                  <Text pl={10} variant="caption">
                    {c?.address}
                  </Text>
                </Tooltip>
              ))}
            </Div>
          </Flex>
        ) : null}
      </Div>
      <Flex direction="column" style={{ padding: "20px" }}>
        <div dangerouslySetInnerHTML={{ __html: mail.mailThread.html_body }} />
      </Flex>

      {showQualifyModal ? (
        <Modal
          visible
          footer={null}
          onCancel={() => {
            setShowQualifyModal((prev) => !prev);
            form.resetFields();
          }}
        >
          <Flex p="10px" direction="column">
            <Text variant="h6" align="center" block>
              Qualify
            </Text>

            <Div mt={20}>
              <Form form={form} scrollToFirstError layout="vertical" requiredMark={true} onFinish={handleQualifyMail}>
                <Text variant="overline" color="secondary">
                  Prospect email address
                </Text>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail."
                    },
                    {
                      required: true,
                      message: "Please input your E-mail"
                    }
                  ]}
                  style={{ width: "60%" }}
                >
                  <Input
                    type="email"
                    onChange={(e) => {
                      setQualifiedEmail(e.target.value);
                    }}
                  />
                </Form.Item>
                <Flex style={{ justifyContent: "flex-end", borderTop: "1px solid #eee", paddingTop: "10px" }}>
                  <Div>
                    <Button style={{ marginLeft: "10px" }} htmlType="submit" type="primary">
                      Save
                    </Button>
                  </Div>
                </Flex>
              </Form>
            </Div>
          </Flex>
        </Modal>
      ) : null}
      {/* {openClosedeliveryViewModal &&
        <DeliveryViewModal
          onCancel={closeDeliveryViewModal}
          campaignId={mail?.mailThread?.campaignId}
          proceedWithoutTemplate={proceedWithoutTemplate}
          selectThisTemplate={selectThisTemplate}
          responseBody={mail?.mailThread?.html_body}
          openClosedeliveryViewModal={openClosedeliveryViewModal}
        />
      } */}
      {openClosedeliveryViewModal && (
        <DeliveryTempModal onBack={closeDeliveryViewModal} campaignId={mail?.mailThread?.campaignId} mailData={mail} onCancel={closeDeliveryViewModal} />
      )}
    </>
  );
};

export default MailDetailModal;
