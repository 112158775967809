import moment from "moment";
import { CampaignResponseEnum } from "types/Campaign";
import { EventEnum, EventType } from "types/Event";
import { MemberStatusEnum } from "types/Member";
import { SpinnerTypeEnum, SpinType } from "types/Spinner";
import { v4 as uuid } from "uuid";

export const SPIN_TYPES_LIST_MOCK: SpinType[] = [
  {
    name: "Subject",
    sequence: 1,
    label: "Subject",
    value: "{{spin_subject}}",
    contentType: SpinnerTypeEnum.HTML,
    variables: ["{{first_name}}", "{{last_name}}"],
  },
  {
    name: "Greeting",
    sequence: 2,
    label: "Greeting",
    value: "{{spin_greeting}}",
    contentType: SpinnerTypeEnum.HTML,
    variables: ["{{first_name}}", "{{last_name}}"],
  },
  {
    name: "Compliment",
    sequence: 3,
    label: "Compliment",
    value: "{{spin_compliment}}",
    contentType: SpinnerTypeEnum.HTML,
    variables: ["{{first_name}}", "{{last_name}}"],
  },
];

export const VARIABLES_LIST_MOCK = ["{{first_name}}", "{{last_name}}"];

export const CAMPAIGN_EVENTS_MOCK: EventType[] = [
  {
    title: "apilius70@gmail.com opened your email",
    subtitle:
      '<p fr-original-style="" style="margin: 0px; box-sizing: border-box; padding: 0px; border: 0px;"><p fr-original-style="" style="margin: 0px; box-sizing: border-box; padding: 0px; border: 0px;">Strengthen brand integrity, John </p></p> - null',
    event: EventEnum.OPENED,
    created: 1622624017427,
  },
  {
    title: "anxioushoneydew@gmail.com opened your email",
    subtitle:
      '<p fr-original-style="" style="margin: 0px; box-sizing: border-box; padding: 0px; border: 0px;"><p fr-original-style="" style="margin: 0px; box-sizing: border-box; padding: 0px; border: 0px;">Strengthen brand integrity, John </p></p> - null',
    event: EventEnum.OPENED,
    created: 1622623314361,
  },
  {
    title: "apilius70@gmail.com opened your email",
    subtitle:
      '<p fr-original-style="" style="margin: 0px; box-sizing: border-box; padding: 0px; border: 0px;"><p fr-original-style="" style="margin: 0px; box-sizing: border-box; padding: 0px; border: 0px;">Strengthen brand integrity, John </p></p> - null',
    event: EventEnum.OPENED,
    created: 1622548837489,
  },
  {
    title: "apilius70@gmail.com opened your email",
    subtitle:
      '<p fr-original-style="" style="margin: 0px; box-sizing: border-box; padding: 0px; border: 0px;"><p fr-original-style="" style="margin: 0px; box-sizing: border-box; padding: 0px; border: 0px;">Strengthen brand integrity, John </p></p> - null',
    event: EventEnum.OPENED,
    created: 1622545043383,
  },
];

export const TOUCH_OVERALL_METRICS_MOCK = {
  notReplied: 100,
  paused: 85,
  bounced: 15,
  total: 200,
};

export const TOUCH_EVENT_METRICS_SINGLE_DAY_MOCK = [
  {
    touchNumber: 1,
    data: [
      {
        Queued: 100,
        Clicked: 50,
        Sent: 20,
        Opened: 10,
        Replied: 50,
        Bounced: 10,
        "Opted Out": 30,
        date: moment().toISOString(),
      },
    ],
  },
  {
    touchNumber: 2,
    data: [
      {
        Queued: 100,
        Clicked: 50,
        Sent: 20,
        Opened: 10,
        Replied: 50,
        Bounced: 10,
        "Opted Out": 30,
        date: moment().toISOString(),
      },
    ],
  },
];

export const TOUCH_EVENT_METRICS_MULTIPLE_DAY_MOCK = [
  {
    touchNumber: 1,
    data: [
      {
        Queued: 10,
        Clicked: 5,
        Sent: 2,
        Opened: 1,
        Replied: 5,
        Bounced: 1,
        "Opted Out": 3,
        date: moment().subtract(3, "days").toISOString(),
      },
      {
        Queued: 100,
        Clicked: 50,
        Sent: 20,
        Opened: 10,
        Replied: 50,
        Bounced: 10,
        "Opted Out": 30,
        date: moment().subtract(2, "days").toISOString(),
      },
      {
        Queued: 200,
        Clicked: 50,
        Sent: 40,
        Opened: 50,
        Replied: 90,
        Bounced: 10,
        "Opted Out": 30,
        date: moment().subtract(1, "days").toISOString(),
      },
      {
        Queued: 10,
        Clicked: 5,
        Sent: 2,
        Opened: 1,
        Replied: 5,
        Bounced: 1,
        "Opted Out": 3,
        date: moment().toISOString(),
      },
    ],
  },
  {
    touchNumber: 2,
    data: [
      {
        Queued: 10,
        Clicked: 5,
        Sent: 2,
        Opened: 1,
        Replied: 5,
        Bounced: 1,
        "Opted Out": 3,
        date: moment().subtract(3, "days").toISOString(),
      },
      {
        Queued: 100,
        Clicked: 50,
        Sent: 20,
        Opened: 10,
        Replied: 50,
        Bounced: 10,
        "Opted Out": 30,
        date: moment().subtract(2, "days").toISOString(),
      },
      {
        Queued: 200,
        Clicked: 50,
        Sent: 40,
        Opened: 50,
        Replied: 90,
        Bounced: 10,
        "Opted Out": 30,
        date: moment().subtract(1, "days").toISOString(),
      },
      {
        Queued: 10,
        Clicked: 5,
        Sent: 2,
        Opened: 1,
        Replied: 5,
        Bounced: 1,
        "Opted Out": 3,
        date: moment().toISOString(),
      },
    ],
  },
];

export const NOTIFICATIONS_MOCK = [
  {
    campaignName: "Spring Campaign 2021",
    totalUnreadCound: 3,
  },
  {
    campaignName: "Winter Campaign 2021",
    totalUnreadCound: 10,
  },
];

export const INBOUND_MAILS_MOCK: any[] = [
  {
    inboundMessage: {
      id: uuid(),
      subject: "This mail is unread",
      sentTime: moment().subtract(2, "days").valueOf(),
      read: false,
      from: "johndoe@example.com",
      replyType: CampaignResponseEnum.MAIN,
      campaignId: uuid(),
      to: "",
    },
  },
  {
    inboundMessage: {
      id: uuid(),
      subject:
        "This mail subject is very very long and infact its longer than 65 characters",
      sentTime: moment().subtract(3, "days").valueOf(),
      read: true,
      from: "johndoe@example.com",
      replyType: CampaignResponseEnum.MAIN,
      campaignId: uuid(),
      to: "",
    },
  },
  {
    inboundMessage: {
      id: uuid(),
      subject: "This mail subject is quite long",
      sentTime: moment().subtract(3, "days").valueOf(),
      read: true,
      from: "johndoe@example.com",
      replyType: CampaignResponseEnum.MAIN,
      campaignId: uuid(),
      to: "",
    },
  },
];

export const MOCK_MEMBERS_LIST = [
  {
    name: "John Doe",
    email: "johnDoe@example.com",
    status: MemberStatusEnum.ADDED,
  },
  {
    name: "Jane Doe",
    email: "janeDoe@example.com",
    status: MemberStatusEnum.INVITED,
  },
];
