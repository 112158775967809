import { Button, Form, Card, Radio, Modal, Typography } from "antd";
import { Div } from "styles/layout";
import { MailOutlined } from '@ant-design/icons';

interface VerificationOptionsModalProps {
  subMessage: string
  handleVerificationOptionsModal: () => void;
  onOptionSelectHandler: (formValues: any) => void;
}

const VerificationOptionsModal = ({
  subMessage,
  handleVerificationOptionsModal,
  onOptionSelectHandler,
}: VerificationOptionsModalProps) => {
  const { Title } = Typography;
  const [gmailVerificationOptions] = Form.useForm();

  return (
    <Modal
      visible
      footer={null}
      onCancel={() => handleVerificationOptionsModal()}
      width={500}
      centered
    >
      <Form
        scrollToFirstError
        form={gmailVerificationOptions}
        requiredMark={false}
      // onFinish={onOptionSelectHandler}
      >
        <Title level={3} style={{ textAlign: "center" }}>Verify that it’s you</Title>
        <p className="fs-13" style={{ marginBottom: "30px", textAlign: "center", fontSize: "15px", fontWeight: "500", color: "#555" }}>To help keep your account safe, Google wants to <br />make sure that it’s really you trying to sign in.</p>

        <Title level={5} className="mb-2 mt-3">Choose how you want to sign in?</Title>
        <Div style={{ minHeight: "120px" }}>
          <Form.Item name="selectedFields" className="radioselect">

            <Radio.Group style={{ width: "100%" }}>
              <Div style={{ position: "relative" }}>
                <Card size="small">
                  <Div style={{ display: "flex", alignItems: "center" }}>
                    <MailOutlined style={{ margin: "0 18px 0 10px", fontSize: "16px", color: "#fa8c16" }} />
                    <Radio className="" value={"code"} onClick={() => onOptionSelectHandler('code')}>
                    </Radio>
                    {subMessage}
                  </Div>

                </Card>
              </Div>
              <Div>
                <Card size="small">
                  <Div style={{ display: "flex", alignItems: "center" }}>
                    <MailOutlined style={{ margin: "0 18px 0 10px", fontSize: "16px", color: "#fa8c16" }} />
                    <Radio className="" value={"recovery"} onClick={() => onOptionSelectHandler('recovery')}>
                    </Radio>
                    Confirm your recovery email
                  </Div>
                </Card>
              </Div>
            </Radio.Group>
          </Form.Item>
        </Div>
      </Form>
    </Modal >
  );
};

export default VerificationOptionsModal;