import React, { useState } from "react";
import { Button, Spin } from "antd";
import { BgImage, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { EventType } from "types/Event";
import { BOX_SHADOW } from "utils/colors";
import { LoadingOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { NotificationOutlined } from "@ant-design/icons";
import classes from "styles/common.module.css";
import NotFoundImage from "images/404.svg";

import Event from "./Event";
import { useAuth } from "auth/AuthProvider";
import "./Event.css"
const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

export interface LiveFeedProps {
  events: EventType[];
  loading?: boolean;
  paused?: boolean;
  onPlayPause?: (status: boolean) => void;
}

const LiveFeed = ({ events, onPlayPause, loading = false, paused = true }: LiveFeedProps) => {
  const { theme } = useAuth();
  const [showMore, setShowMore] = useState(false);

  const toggleFeed = () => {
    setShowMore(!showMore)
  }

  return (
    <Flex direction="column" h="100%" p={20} style={{ boxShadow: BOX_SHADOW }} bgColor={theme.whiteColor}>
      <Flex
        align="center"
        // style={{
        //   borderBottom: "1px solid rgba(0,0,0,0.4)"
        // }}
        pb={10}
      >

        <Text variant="body1" block align="center" ml={2} mr={10}>
          Recent Activities
        </Text>
        {loading && <Spin indicator={antIcon} style={{ lineHeight: 1 }} />}
        <Flex cursor="pointer" align="center" ml="auto">
          {
            <Flex cursor="pointer" align="center" ml="auto" className="pause__btn" onClick={() => onPlayPause && onPlayPause(!paused)}

            >
              {paused ? (
                <>
                  {" "}
                  <PlayCircleOutlined />{" "}
                </>
              ) : (
                <>
                  <PauseCircleOutlined />{" "}
                </>
              )}
              <Text style={{ userSelect: "none" }} variant="sub2" block align="center" ml={10} >
                {paused ? "Resume" : "Pause"}
              </Text>
            </Flex>
          }
           <span className="hr__line">|</span>
          <Button type="link" onClick={() => toggleFeed()} className="show__more-btn">{showMore ? ' show less' : 'show more'}</Button>
        </Flex>
      </Flex>
      <Flex
        // style={{
        //   height: "calc(100% - 25px)",
        //   maxHeight: "calc(100% - 25px)",
        //   overflowY: "scroll",
        //   paddingTop:"0"
        // }}
        className={`${classes.scrollContainer} rac-inner ${showMore ? 'live__feed-expanded ' : 'live__feed-containter'}`}
      >
        <Flex direction="column" h="100%" w="100%">
          {!!events.length ? (
            events.map((event, index) => <Event event={event} key={`${event.created}-${index}`} />)
          ) : (
            <Flex h="100%" align="center" justify="center" w="100%" position="relative" direction="column">
              <BgImage imageUrl={NotFoundImage} h={200} w={200} />
              <Text variant="body1" mt={15} align="center" style={{ lineHeight: 1.2 }}>
                We haven't recieved any notifications for your campaign.
              </Text>
              {loading && (
                <Flex
                  align="center"
                  justify="center"
                  position="absolute"
                  style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                  bgColor="rgba(255, 255, 255, 0.8)"
                >
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 26 }} spin />} />
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>

    </Flex>
  );
};

export default LiveFeed;