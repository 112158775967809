
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import React, { useContext } from "react";
export const StateContext = React.createContext<any>(null);
export default function UserListStateProvider({ children }: any) {
  const [userList, setUserList] = React.useState<any>([])
  const { callApi } = useApi();
  React.useEffect(() => {
    fetchUserList()
  }, [])

  const fetchUserList = async () => {
    const { url, method } = API_ENDPOINTS.ACCOUNTS.getUserListUrl();
    const response = await callApi({
      url,
      method,
    });
    if (response.success) {
      setUserList(response.data.users)
    }
  }

  let contextValue = {
    userList
  };

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useUserlistState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error(
      "useAppState must be used within the UserListStateProvider"
    );
  }
  return context;
}
