import { Button, Checkbox, Form, notification, Modal } from "antd";
import { useEffect, useMemo, useState, useCallback } from "react";
import { Flex, Div } from "styles/layout";
import { Table } from "antd";
import UploadCsv, { CSVContentsType } from "components/UploadPropects/UploadCsv";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import Column from "antd/lib/table/Column";

//const MAX_CSV_FILE_SIZE = 0.286 * 1024;
const ONE_MB_VALUE = 1024;
const MAX_CSV_FILE_SIZE = 50 * 1024;
const NOTIFICATION_KEY = "CSV_UPLOAD_ERROR";

type PreviewTableProps = {
    columns: string[];
    data: any[][];
    onColumnSelected?: (id: string) => void;
};

const PreviewTable = ({ columns, data, onColumnSelected }: PreviewTableProps) => {
    const [curColSelected, setCurColSelected] = useState("");
    
    useEffect(() => {
        onColumnSelected && onColumnSelected(curColSelected);
    }, [curColSelected, onColumnSelected]);
    
    let removedIndex: any = [];
    data[0].map((d, i) => {
        if (!(d.indexOf(" ") > -1) && !(d.indexOf("@") > -1)) {
            if (!(d.indexOf(".") > -1))
            removedIndex.push(i);
            else
            return d;
        }
        else
        removedIndex.push(i);
    });
    console.log("value PreviewTable ",columns, data, "removedIndex", removedIndex)

    return (
        <Table dataSource={data} pagination={false}>
            {columns.map((col, idx) => (
                (removedIndex !== idx) ? (
                    <Column
                        title={() => {
                            return (
                                <Flex>
                                    <Checkbox
                                        checked={curColSelected === idx.toString()}
                                        onChange={() => setCurColSelected(idx.toString())}
                                    >
                                        {col}
                                    </Checkbox>
                                </Flex>
                            );
                        }}
                        dataIndex={idx}
                        key={col}
                    ></Column>
                ): null
            ))}
        </Table>
    );
};

export type CSVContactsDncProps = {
    onCancel: () => void;
    onDone?: (domainS3key: string, domainIndex: string, fileUrl: string, domainCount: number, uploadedFileName: string) => void;
};

type CSVContactsDncState = {
    step: number;
    isProcessing: boolean;
    disableUploadCSV?: boolean;
    csvData?: CSVContentsType;
    domainColumn: string;
};

export const CSVUploadDnc = ({ onCancel, onDone, }: CSVContactsDncProps) => {
    const [domainUploadForm] = Form.useForm();
    const [state, setState] = useState<CSVContactsDncState>({ step: 1, isProcessing: false, domainColumn: "" });
    const processing = useMemo(() => state.disableUploadCSV || state.isProcessing, [
        state.disableUploadCSV,
        state.isProcessing
    ]);

    const { callApi, uploadFileToS3 } = useApi();
    const [isFileSelected, setIsFileSelected] = useState(true)

    const onUploadCsvFormSubmit = useCallback(async ({ columnHeaders, file, prospects }: CSVContentsType) => {
console.log("prospects", prospects)
        let arr: any = [];
        for (let i = 0; i < prospects.length; i++) {
          let columnData = prospects[i]
    
          columnData.map((abc: any) => {
            if ((abc.includes(".")) && !abc.includes("@")) {
              arr.push(abc)
            }
          })
        }
        const uniqueDomains: string[] = Array.from(new Set(arr));
        if (uniqueDomains.length > 50000) {
          notification.error({
            message: "Maximum domains or websites allowed in a single file is 50,000. Please reduce the entries and retry.",
            description: "",
            key: NOTIFICATION_KEY
          });
        } else {
          prospects = prospects.filter(prospects => {
            if (prospects.filter((one) => one !== '').length > 0) {
              return prospects;
            }
          });
          console.log("prospects21",prospects)
          setState((st) => ({ ...st, step: 2, csvData: { columnHeaders, file, prospects } }));
        }
        const fsize = file.size;
        const fileSize = Math.round((fsize / ONE_MB_VALUE));
        // if (fileSize > MAX_CSV_FILE_SIZE) {
        //   notification.error({
        //     message: "File size should not exceed 50mb.",
        //     description: "",
        //     key: NOTIFICATION_KEY
        //   });
        // }
        // else {
        //   prospects = prospects.filter(prospects => {
        //     if (prospects.filter((one) => one !== '').length > 0) {
        //       return prospects;
        //     }
        //   });
        //   setState((st) => ({ ...st, step: 2, csvData: { columnHeaders, file, prospects } }));
        // }
      }, []);
console.log("state", state)
    const onCSVFormSubmit = useCallback(() => domainUploadForm.submit(), [domainUploadForm]);

    const onBack = useCallback(() => {
        setState((st) => ({ ...st, step: 1 }));
    }, []);

    const onColumnSelected = useCallback((id) => {
        setState((st) => ({ ...st, domainColumn: id }));
        setIsFileSelected(true);
    }, []);

    const onUpload = useCallback(async () => {
        if (!state.domainColumn) {
            return notification.error({
                message: "No domain column",
                description: `Please select one column for domain to proceed further.`
            });
        }
        if (!state.csvData?.file) {
            return;
        }
        const uploadedFileName = state.csvData?.file.name;
        setState((st) => ({ ...st, isProcessing: true }));
        const { fileUrl, response } = await uploadFileToS3(state.csvData.file);
        setState((st) => ({ ...st, isProcessing: false }));
        if (fileUrl) {
            onDone && onDone(response?.data.url, state.domainColumn, fileUrl, state.csvData.prospects.length, uploadedFileName);
        }
    }, [uploadFileToS3, state, onDone]);

    return (
        <>

            <Modal
                maskClosable={!processing}
                // confirmLoading={state.disableUploadCSV || !state.isProcessing}
                closable={!processing}
                visible
                title="Select the column that has the domain or email address"
                onCancel={onCancel}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
                width={"65vw"}
                style={{ overflowX: "auto" }}
                footer={null}
            >
                {state.step === 1 && (
                    <Div style={{ textAlign: "right" }}>
                        <UploadCsv continueButtonCheck={() => setIsFileSelected(!isFileSelected)} onContinue={onUploadCsvFormSubmit} form={domainUploadForm} />
                        <Button disabled={isFileSelected} onClick={onCSVFormSubmit} style={{ marginTop: "10px" }} type="primary">
                            Continue
                        </Button>
                    </Div>
                )}
                {state.step === 2 && (
                    <>
                        <Flex direction="column" style={{ overflow: "auto" }}>
                            <PreviewTable
                                columns={state.csvData?.columnHeaders ?? []}
                                data={state.csvData?.prospects.slice(0, 5) ?? []}
                                onColumnSelected={onColumnSelected}
                            />
                        </Flex>
                        <Flex justify="end" mt={20} style={{ gap: "10px" }}>
                            <Button disabled={state.isProcessing} onClick={onBack} type="text">
                                Back
                            </Button>
                            <Button disabled={state.isProcessing} onClick={onUpload} type="primary">
                                Upload
                            </Button>
                        </Flex>
                    </>
                )}
            </Modal>
        </>
    );
};
