import { useEffect, useState, ReactNode, useContext } from "react";
import { Button, Row, Col, Space, Tooltip } from "antd";
import { Flex } from "styles/layout";
import { WHITE_COLOR } from "utils/colors";
import { Text } from "styles/typography";
import classes from "./styles.module.css"
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import {
    DeleteOutlined,
} from "@ant-design/icons";
import appContext from "contexts/appContext";

const TemplateList = ({ handleTemplateSelection, proceedWithoutTemplate }: any) => {
    const { callApi } = useApi();
    const { setLoadingMessage } = useContext(appContext);

    const [state, setState] = useState<any>({ templateView: false, withoutTemp: false, templateList: [], selectedTemplate: null })
    const { templateView, templateList, selectedTemplate } = state
    let account_id = String(localStorage.getItem("account_id"));
    const fetchtemplateList = async () => {
        if (account_id) {
            setLoadingMessage("Fetching Template list...")
            const { url, method } = API_ENDPOINTS.MAILBOX.fetchTemplateList(account_id);
            const response = await callApi({
                url,
                method,
            });
            if (response?.success) {
                setLoadingMessage("")
                setState((prev: any) => ({ ...prev, templateList: response?.data?.templates || [] }))
            }
        }
    };

    useEffect(() => {
        fetchtemplateList();
    }, []);

    const TagHeading = ({ children }: { children: ReactNode }) => (
        <span style={{ color: 'rgba(0,0,0,0.45)', fontSize: '16px' }}>
            {children}
        </span>
    );

    const deleteTemplate = async (template: any) => {
        if (template) {
            setLoadingMessage("Deleting Template...")

            const { url, method } = API_ENDPOINTS.MAILBOX.deleteTemplates(template.id, account_id);
            const response = await callApi({
                url,
                method,
            });
            if (response?.success) {
                setLoadingMessage("")
                fetchtemplateList()
            }
        }
    }

    return (
        <>
            {/* // <Modal visible onCancel={onCancel} footer={null} width={850}> */}
            <Text variant="h6" block mb={10}>{!templateView ? "Select Template" : ''} </Text>
            {/* {!templateView ?  */}
            <Row wrap={false}>
                <Col >
                    <Flex
                        bgColor={WHITE_COLOR}
                        style={{
                        }}
                        mb={20}
                    >
                        <Flex grow={1} mt={0} mr={25} direction="column">
                            <div className={classes.tagWrapper}>
                                <ul className={`${classes.tagList} ${classes.scrollContainer}`} style={{
                                    maxHeight:"500px", height:"500px"
                                }}>
                                    {templateList?.map((tag: any) => {
                                        return <><li onClick={() => {
                                            setState((prev: any) => ({ ...prev, selectedTemplate: tag }))
                                        }}
                                            className={tag?.id === selectedTemplate?.id ? classes.active : ''}>{tag?.name}
                                        </li>
{/* 
                                            <Tooltip title="Delete" placement="bottom">
                                                <Button
                                                    type="default"
                                                    danger
                                                    shape="circle"
                                                    icon={<DeleteOutlined />}
                                                    size="small"
                                                    style={{ width: "90%", marginRight: 8, display: "flex", alignItems: "center", justifyContent: "center" }}
                                                    onClick={() => deleteTemplate(tag)}
                                                />
                                            </Tooltip> */}
                                        </>
                                    })}
                                </ul>
                            </div>
                        </Flex>
                    </Flex>
                </Col>
                <Col flex="auto" style={{maxHeight:"500px", overflowY:"auto"}}>
                    {!!selectedTemplate && <>
                        <Text variant="body2" block align="left" mb={15}>
                            <p> <TagHeading>To: </TagHeading><span>{selectedTemplate?.tos}</span></p>
                        </Text>
                        <Text variant="body2" block align="left" mb={15}>
                            <p>  <TagHeading>Cc: </TagHeading><span>{selectedTemplate?.ccs}</span></p>
                        </Text>
                        <Text variant="body2" block align="left" mb={15}>
                            <p> <TagHeading>Bcc: </TagHeading><span>{selectedTemplate?.bccs}</span></p>
                        </Text>
                        <Text variant="body2" block align="left" mb={15}>
                            <p> <TagHeading>Body: </TagHeading> <span>
                                <div
                                    dangerouslySetInnerHTML={{ __html: selectedTemplate?.body }}
                                /></span></p>
                        </Text>
                    </>}
                </Col>
            </Row>
            {/* // :
            //     <DeliveryTempModal
                //         selectedTags={withoutTemp ? null : selectedTags}
                //         onBack={() => setState((prev) => ({ ...prev, templateView: false, withoutTemp: false }))}
                //         campaignId={campaignId} responseBody={responseBody} />} */}
            <Flex mb={5} justify="flex-end" mt={0}>
                <Space>
                    <Flex mb={10} justify="space-between" mt={10}>
                        <Space>
                            <Button htmlType="button" type="default" onClick={() => proceedWithoutTemplate()}>
                                Proceed without template
                            </Button>
                        </Space>
                        <Space>
                            <Button htmlType="button" disabled={!selectedTemplate} type="primary" onClick={() => handleTemplateSelection(selectedTemplate)} style={{ marginLeft: "15px" }}>
                                Select this template
                            </Button>
                        </Space>
                    </Flex>
                </Space>
            </Flex>
            <></>
            {/* // </Modal> */}
        </>
    );
};

export default TemplateList;