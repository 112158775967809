import React, { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { Select, Button, Row, Col, message } from "antd";
import { Div, Flex } from "styles/layout";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import Form from "antd/lib/form/Form";
import { Text } from "styles/typography";
import moment from "moment";

interface PauseMailboxModalProps {
    inboxId: string;
    setShowPausedMailboxModal: any;
}

const PauseMailboxModal = ({
    inboxId,
    setShowPausedMailboxModal
}: PauseMailboxModalProps) => {
    const { setLoadingMessage } = useContext(appContext);
    const { callApi } = useApi();

    const [logs, setLogs] = useState([]);

    const fetchMailboxLogs = async () => {
        setLoadingMessage("Fetching Mailbox Logs ...");
        const { url, method } = API_ENDPOINTS.MAILBOX_LOG.getLogListUrl(inboxId);
        const response = await callApi({
            url,
            method,
        });

        if (response.success) {
            setLogs(response.data.logs)
        }
        setLoadingMessage("");
    };

    useEffect(() => {
        fetchMailboxLogs();
    }, [])

    const clearMailboxErrors = async () => {
        setLoadingMessage("Clear Errors and Re-Activating Mailbox...");
        const payload = {
            "action": "Activate"
        }
        const { url, method } = API_ENDPOINTS.MAILBOX.getUpdatelUrl(inboxId);
        const response = await callApi({
            url,
            method,
            data: payload
        });

        if (response.success) {
            setShowPausedMailboxModal((prev: any) => !prev);
            message.success({
                content: `Errors cleared and Mailbox Activated.`,
                key: "updating integrations setting",
                duration: 2
            });
        }
        setLoadingMessage("");
    };

    return (
        <Div>
            <Form>
                <Flex direction="column">
                    {logs.map(
                        (val: any, index) => {
                            return (
                                <Row>
                                    <Col>
                                        <Text variant="caption" ml={5} color="secondary">
                                            {moment(val.time).format("DD.MM.YYYY HH:MM")}
                                            {/* {new Date(val.time).toLocaleDateString("en-US")} */}
                                        </Text>
                                        <Text variant="caption" ml={20}>
                                            {val.message}
                                        </Text>
                                    </Col>
                                </Row>
                            )
                        })}

                    <Button type="primary" style={{ marginTop: '20px', alignSelf: 'end' }} onClick={clearMailboxErrors}>
                        Clear Errors and Re-Activate Mailbox
                    </Button>
                </Flex>
            </Form>
        </Div>
    );
};

export default PauseMailboxModal;