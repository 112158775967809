import React, { useMemo, } from "react";
import { Modal } from "antd";
import {
    canModifyCampaignState,
} from "state/selectors";
import { useSelector } from "react-redux";
import CreateTemplateModal from './CreateTemplateModal'



const DeliveryTempModal = ({ onCancel, campaignId, selectedTags, onBack, responseBody, mailData }: any) => {
    const isFree = useSelector(canModifyCampaignState(campaignId));

    const mailKeys = useMemo(() => {
        if (mailData) {
            return {
                ...mailData?.mailThread,
                to: [mailData?.inboundMessage?.to],
                bcc: mailData?.mailThread?.bccs,
                cc: mailData?.mailThread?.ccs,
                responseBody: mailData?.mailThread?.html_body,
                subjectTitle: mailData?.mailThread?.subject
            }
        }
    }, [mailData])
    return (
        <Modal visible footer={null} width={850} onCancel={onCancel}>
            <CreateTemplateModal
                onCancel={onCancel}
                campaignId={campaignId}
                to={mailKeys?.to || []}
                bcc={mailKeys?.bcc || []}
                cc={mailKeys?.cc || []}
                isCreate={true}
                canEdit={isFree}
                onBack={onBack}
                responseBody={mailKeys?.responseBody}
                mailData={mailData}
                subjectTitle={mailKeys?.subjectTitle}
            />
        </Modal>
    );
};

export default DeliveryTempModal;