export enum EventEnum {
  REPLIED = "replied",
  AUTOREPLY = "autoreply",
  OUTOFOFFICE = "outofoffice",
  FAILED = "failed",
  BOUNCED = "bounced",
  CLICKED = "clicked",
  OPENED = "opened",
  SENT = "sent",
  SOFTBOUNCED = "softbounced",
  HARDBOUNCED = "hardbounced",
  UNSUBSCRIBED = "unsubcribed",
}

export interface EventType {
  created: number;
  title: string;
  subtitle: string;
  event: EventEnum;
}