import React, { useState, ReactNode } from 'react';
import { Modal } from "antd";
import { Text } from "styles/typography";

interface MailExistModalProps {
   handleOpenClose: () => void;
   handleSubmit: () => Promise<void>;
   message: string;
}

const MailExistModal = ({ handleOpenClose, message, handleSubmit }: MailExistModalProps) => {
   const DetailHeading = ({ children }: { children: ReactNode }) => (
      <Text variant="sub1" color="secondary" mr={20} style={{ width: 150, fontSize: "12px" }} >
         {children}
      </Text>
   );
   return (
      <Modal
         visible
         closable={true}
         onCancel={() => handleOpenClose()}
         onOk={() => handleSubmit()}
         okText={'Confirm'}
         centered
      >
        <DetailHeading>Warning</DetailHeading>
        <p>{message}</p>
      </Modal>
   )
}

export default MailExistModal;