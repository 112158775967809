import React, { useState, ReactNode } from 'react';
import { Button, Modal, Tooltip } from "antd";
import { Text } from "styles/typography";
import { CopyOutlined } from '@ant-design/icons';
import classes from "../MailBox/Card/styles.module.css";
import clipboardCopy from "clipboard-copy";
import { Flex } from 'styles/layout';

interface ContactSupportModalProps {
   handleOpenClose: () => void;

}

const ContactSupport = ({ handleOpenClose }: ContactSupportModalProps) => {
   const [copyTooltipText, setCopyTooltipText] = useState(true);
   const CopyTooltip = (e: any) => {
      e.stopPropagation();
      clipboardCopy("support@getemy.io");
      setCopyTooltipText(false);
   };
   const DetailHeading = ({ children }: { children: ReactNode }) => (
      <Text variant="sub1" color="secondary" mr={20} style={{ width: 150, fontSize: "12px" }} >
         {children}
      </Text>
   );
   return (
      <Modal
         visible
         closable={true}
         onCancel={() => handleOpenClose()}
         onOk={() => handleOpenClose()}
         okText={'Close'}
         centered
         footer={null}
         cancelButtonProps={{ style: { display: 'none' } }}
         okButtonProps={{ style: { display: 'none' } }}
      >
         <DetailHeading>Support Contact Email</DetailHeading>
         <Flex >
            <p>support@getemy.io</p>
            <Text variant="caption" ml={5}>
               <Tooltip
                  title={copyTooltipText ? "Click to Copy Email" : "Copied"}
                  mouseLeaveDelay={0}
                  placement="top"
                  className={classes.emailCopy}
               >
                  <Button className={classes.copyEmailButton} onClick={(e) => CopyTooltip(e)}>
                     <CopyOutlined
                        onMouseLeave={() => {
                           setTimeout(() => setCopyTooltipText(true), 100);
                        }}
                     />
                  </Button>
               </Tooltip>
            </Text>
         </Flex>

      </Modal>
   )
}

export default ContactSupport;