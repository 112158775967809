import logo from "images/emy_logo_dark.png";
import { MetaConfig } from "auth/AuthProvider";

export const LogoWithText = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px"
      }}
    >
      <img src={logo} style={{ width: "300px" }} alt="App Logo" />
    </div>
  );
};

export const Logo = (props: { config: MetaConfig | null }) => {
  const config = { ...(props.config ?? {}) };
  const style = config?.logoBackgroundColor ? { backgroundColor: config.logoBackgroundColor, padding: "2px" } : {};
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <img src={props?.config?.appLogoS3Url || "/favicon.png"} height="30px" alt="App Logo" style={style} />
    </div>
  );
};
