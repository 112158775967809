import { Card, Spin } from "antd";
import { Flex } from "styles/layout";
import { BOX_SHADOW } from "utils/colors";
import NumberChart from "components/Charts/Number/NumberChart";
import { isNumber } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { Text } from "styles/typography";

const antLoadingIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export interface TouchNumberMetric {
  title: string;
  value?: number;
  percentage?: number;
  strokeColor?: string;
}

export interface TouchNumberChartProps extends TouchNumberMetric {
  loading: boolean;
}

const TouchNumberChart = ({ title, value, percentage, strokeColor, loading }: TouchNumberChartProps) => {
  return (
    <Flex style={{ paddingRight: 5, minWidth: 210, minHeight: 210 }}>
      <Card
        style={{ boxShadow: 'none', width: "100%", borderRadius: "0", border: "none" }}
        bodyStyle={{
          height: "100%"
        }}
      >
        <Flex direction="column" h="100%">
          <Text variant="body2" block align="center" mb={5} uppercase color="secondary">
            {title}
          </Text>
          {loading ? (
            <Flex w="100%" h="100%" align="center" justify="center">
              <Spin indicator={antLoadingIcon} />
            </Flex>
          ) : isNumber(value) && isNumber(percentage) ? (
            <NumberChart value={value} percent={percentage} strokeColor={strokeColor} />
          ) : (
            <Flex w="100%" h="100%" align="center" justify="center">
              <Text variant="caption" uppercase color="secondary">
                No data found
              </Text>
            </Flex>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};

export default TouchNumberChart;