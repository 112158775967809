import { MailboxViewEnum } from "pages/Settings/ViewMode";
import { assign, createMachine } from "xstate";
import { DomainListResponse, DomainListActionDataArgs } from "./Domain.types";
import { KEY_LS_DOMAIN_VIEW } from "./DomainCommon";
 

type DataContext = {
  data: DomainListResponse | undefined;
  error: any,
  domain: string;
  currentViewType: MailboxViewEnum,
  pagination: { current: number; pageSize: number; };
  searchTerm: string;
};

const schema = {
  context: {} as DataContext,
  events: {} as { type: "FETCH"; data?: DomainListActionDataArgs } | { type: 'TOGGLE_VIEW', data: { view: MailboxViewEnum } } | {
    type: 'TOGGLE_DOMAIN', data: { domain: string }
  } | {
    type: 'UPDATE_CTX', data: Partial<DataContext>
  }
};
export const FETCH_MACHINE_DOMAIN_LIST = createMachine<typeof schema["context"], typeof schema["events"]>({
  id: "fetch-machine",
  initial: "idle",
  context: {
    data: undefined,
    error: undefined,
    currentViewType: localStorage.getItem(KEY_LS_DOMAIN_VIEW) as MailboxViewEnum ?? MailboxViewEnum.CARD,
    domain: "",
    pagination: { current: 1, pageSize: 6 },
    searchTerm: ""
  },
  states: {
    idle: {
      on: { FETCH: "loading" }
    },
    loading: {
      invoke: {
        src: "fetchData",
        onDone: {
          target: "success",
          actions: assign({
            data: (ctx, event) => ({ ...ctx.data, ...event.data })
          })
        },
        onError: {
          target: "failure",
          actions: assign({
            error: (_, event) => event.data
          })
        }
      }
    },
    success: {
      entry: "notifySuccess",
      on: {
        TOGGLE_VIEW: {
          actions: [
            assign({
              currentViewType: (ctx, event) => event.data.view
            }),
            (_, event) => {
              localStorage.setItem(KEY_LS_DOMAIN_VIEW, event.data.view)
            },
          ]
        },
        TOGGLE_DOMAIN: {
          actions: assign({ domain: (_, event) => event.data.domain })
        },
        UPDATE_CTX: {
          actions: assign((ctx, event) => ({ ...ctx, ...event.data }))
        }
      }
    },
    failure: {
      entry: "notifyError",
      on: {
        FETCH: {
          target: "loading"
        }
      }
    }
  }
});
