import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import appContext from "./appContext";

type Props = {
  children?: ReactNode;
};
type TimeZoneItem = { name: string; label: string };

const APIContext = createContext<{ timeZones: TimeZoneItem[] }>({ timeZones: [] });

export function TimeZoneContextProvider({ children }: Props) {
  const { callApi } = useApi();
  const { url, method } = API_ENDPOINTS.CAMPAIGNS.getTimeZonesUrl();
  const [timeZones, setTimeZones] = useState<Array<TimeZoneItem>>([]);
  const { setLoadingMessage } = useContext(appContext);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoadingMessage(`Loading ...`);
        const res = await callApi({ url, method });
        if (res.success) {
          setTimeZones(res?.data?.timezones);
        }
      } catch (error) {}
      setLoadingMessage(``); // race condition will occur
      return () => {
        // should i cancel the request
      };
    };
    fetch();
  }, [callApi, setLoadingMessage, method, url]);

  return <APIContext.Provider value={{ timeZones }}>{children}</APIContext.Provider>;
}

export function useTimeZone() {
  const context = useContext(APIContext);
  return context;
}
