import {
  ClearOutlined,
  DeleteOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  LinkOutlined,
  LoadingOutlined,
  MailOutlined,
  MenuOutlined,
  MoreOutlined,
  ProfileOutlined,
  ReloadOutlined,
  CalculatorOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Dropdown, Form, FormInstance, Input, Menu, message, Modal, notification, Popover, Row, Select, Slider, Tooltip } from "antd";
import { useAuth } from "auth/AuthProvider";
import SimpleBarChart from "components/Charts/Bar/SimpleBarChart";
import { Editor as WYSIWYGEditor } from "components/Froala";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { AccountTypeEnum, MailboxTypeEnum } from "types/MailBox";
import { humanizeHour, TimeZoneType } from "utils/dateTime";
import TimeZones from "../../../commonAPIs/timezones";
import PauseMailboxModal from "../List/Modal";
// import { ERROR_COLOR, PRIMARY_COLOR } from "utils/colors";
import { getProviderDetails } from "../Provider";
import { useSelector } from "react-redux";
import { canModifyCampaignState } from "state/selectors";
import { isEmpty } from "lodash";
import classes from "../Settings/styles.module.css";
import { FormControlWrapper, Label } from "./styles";
const { Option } = Select;

export interface MailBoxSettingsProps {
  onSave: (settings: MailBoxSettingsType) => void;
  id?: any;
  email: string;
  settings?: MailBoxSettingsType;
  campaignName?: string;
  campaignId?: string | null;
  paused?: boolean | null;
  form: FormInstance<any>;
  provider?: string;
  protocol?: string;
  formValues?: FormValueType;
  settingEditViewMode?: (value: boolean) => void;
  updateCredentails?: (mailboxId: string, action: string) => void;
  validateCredentails?: (mailboxId: string, action: string) => void;
  mailboxDelinking?: boolean;
  onDelete?: ((id: string, email: string) => void) | null;
  onViewIpsDetails?: (email: string) => void;
  onOpenTracking?: (email: string) => void;
  onViewLogsDetails?: (id: string) => void;
  onSendEmail?: (name: string, email: string) => void;
  onReconnect?: (id: string, action: string) => void;
  firstname?: string | null;
  showSkipCodes?: boolean;
  responseSkip?: () => void;
}

export interface FormValueType {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
export interface configInboxLimitType {
  capRange: { min: number; max: number };
  dailyRate: number;
  defaultRange: { min: number; max: number };
  sliderIncrement: number;
}

export interface MailBoxSettingsType {
  signature: string;
  maxEmails: number;
  minEmails: number;
  incrementPercent: number;
  timeZone: string;
  startHour: number;
  endHour: number;
  days: number[];
  firstName: string;
  lastName: string;
  email: string;
  capacity?: number;
  autoForwardEmail?: string;
  replyTo?: string | undefined;
  ips?: number
}

const marks = {};
// const MAILBOX_SETTINGS_INITIAL_VALUE = {
//   emailVolumeDailyRange: [5, 90],
//   incrementPercent: 20,
//   days: [1, 2, 3, 4, 5],
//   emailSendingHours: [8, 17],
//   // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
// };

const DEFAULT_MAIL_SETTING = "defffault";

const MAILBOX_SETTINGS_INITIAL_VALUE = {
  emailVolumeDailyRange: [100, 1000],
  incrementPercent: 20,
  days: [1, 2, 3, 4, 5],
  emailSendingHours: [8, 17]
};

const MAILBOX_SETTINGS_INITIAL_VALUE_FREE = {
  emailVolumeDailyRange: [5, 25],
  incrementPercent: 15,
  days: [1, 2, 3, 4, 5],
  emailSendingHours: [8, 17]
};
const MAILBOX_SETTINGS_INITIAL_VALUE_PAID = {
  emailVolumeDailyRange: [20, 80],
  incrementPercent: 15,
  days: [1, 2, 3, 4, 5],
  emailSendingHours: [8, 17]
};
const MAILBOX_SETTINGS_INITIAL_VALUE_OTHER = {
  emailVolumeDailyRange: [100, 1000],
  incrementPercent: 20,
  days: [1, 2, 3, 4, 5],
  emailSendingHours: [8, 17]
};

const MAILBOX_SETTING_PER_PROVIDER_WITH_ACCOUNTTYPE = {
  [AccountTypeEnum.FREE]: {
    emailVolumeDailyRange: [5, 75],
    emailVolumeIncrement: 5
  },
  [AccountTypeEnum.PAID]: {
    emailVolumeDailyRange: [5, 125],
    emailVolumeIncrement: 5
  },
  [AccountTypeEnum.OTHER]: {
    emailVolumeDailyRange: [55, 5000],
    emailVolumeIncrement: 50
  },
  [DEFAULT_MAIL_SETTING]: {
    emailVolumeDailyRange: [5, 5000],
    emailVolumeIncrement: 50
  }
};

const getRequiredRules = (message: string) => [
  {
    required: true,
    message
  }
];

const MailboxSettings = ({
  id,
  onSave,
  email,
  settings,
  provider = "",
  protocol,
  campaignName,
  campaignId,
  paused,
  form,
  formValues,
  mailboxDelinking,
  settingEditViewMode,
  onDelete,
  updateCredentails,
  validateCredentails,
  onViewIpsDetails,
  onViewLogsDetails,
  onSendEmail,
  onReconnect,
  onOpenTracking,
  firstname,
  showSkipCodes,
  responseSkip
}: MailBoxSettingsProps) => {
  const isIMAP =
    MailboxTypeEnum.SENDGRID === provider ||
    MailboxTypeEnum.MAIL_GUN === provider ||
    MailboxTypeEnum.TECHCOMPILER === provider ||
    MailboxTypeEnum.GMAIL === provider ||
    MailboxTypeEnum.GMAILONE === provider ||
    provider === MailboxTypeEnum.OFFICE_OAUTH ||
    provider === MailboxTypeEnum.OFFICE_SMTP;
  const { setLoadingMessage } = useContext(appContext);
  const { callApi } = useApi();
  const { theme } = useAuth();
  const isSMTP = ["SMTP", "CUSTOM", "SMTP_IMAP"].includes(protocol ? protocol.toUpperCase() : "");
  const [showPausedMailboxModal, setShowPausedMailboxModal] = useState(false);
  const [Capacity, setCapacity] = useState(settings?.capacity);
  const togglePausedMailboxDialog = (id: any) => {
    // setSelectedID(id);
    setShowPausedMailboxModal((prev) => !prev);
  };

  const [accountInboxLimitConfig, setAccountInboxLimitConfig] = useState<configInboxLimitType | any>({});

  useEffect(() => {
    setLoadingMessage("Fetching Provider Limit...");
    if (provider === "GOOGLEONE") {
      inboxLimitesConfig("GOOGLE");
    } else if (provider) {
      inboxLimitesConfig(provider);
    }
  }, [provider]);

  const calculateIps = async (mailboxId: string) => {
    setLoadingMessage("fetching your mailbox IPS");
    const { url, method } = API_ENDPOINTS.MAILBOX.calculateIps(mailboxId);
    const response = await callApi({
      url,
      method
    });
    console.log("responseresponse", response);
    if (response.success) {
      notification.success({
        message:
          "IPS calculation process has been initiated. It may take about 1-2 minutes to complete this process. To check the latest IPS score, refresh this mailbox."
      });
      setLoadingMessage("");
    }
  };

  const inboxLimitesConfig = async (value: string) => {
    console.log("valuevaluevaluevalue",value)
    const { url, method } = API_ENDPOINTS.INTEGRATIONS.getInboxesLimit(value);
    const response = await callApi({
      url,
      method
    });

    if (response.success) {
      setLoadingMessage("");
      setAccountInboxLimitConfig(response.data);
    }
  };

  const getAccountTypeByProvider = (provider: any, email: any) => {
    if ([MailboxTypeEnum.GMAIL, MailboxTypeEnum.GMAILONE].includes(provider)) {
      return email.includes("gmail.com") ? AccountTypeEnum.FREE : AccountTypeEnum.PAID;
    } else if ([MailboxTypeEnum.OFFICE_OAUTH, MailboxTypeEnum.OFFICE_SMTP].includes(provider)) {
      return email.includes("outlook.com") ? AccountTypeEnum.FREE : AccountTypeEnum.PAID;
    } else {
      return "other";
    }
  };

  const setSliderValues = () => {
    if (settings) {
      const {
        minEmails,
        incrementPercent,
        maxEmails,
        startHour,
        endHour,
        days,
        autoForwardEmail,
        replyTo
        // emailSendingHours
      } = settings;
      if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
        if (minEmails === 100 && maxEmails === 1000) {
          return {
            emailVolumeDailyRange: MAILBOX_SETTINGS_INITIAL_VALUE_FREE.emailVolumeDailyRange,
            incrementPercent,
            emailSendingHours: [startHour, endHour],
            days,
            autoForwardEmail,
            replyTo
          };
        } else {
          return {
            emailVolumeDailyRange: [minEmails, maxEmails],
            incrementPercent,
            emailSendingHours: [startHour, endHour],
            days,
            autoForwardEmail,
            replyTo
          };
        }
      }
      return {
        emailVolumeDailyRange: [minEmails, maxEmails],
        incrementPercent,
        emailSendingHours: [startHour, endHour],
        days,
        autoForwardEmail,
        replyTo
      };
    }

    var accountType = getAccountTypeByProvider(provider, email);
    // if (accountType === AccountTypeEnum.FREE) {
    //   return {
    //     ...MAILBOX_SETTINGS_INITIAL_VALUE_FREE
    //   };
    // }
    // if (accountType === AccountTypeEnum.PAID) {
    //   return {
    //     ...MAILBOX_SETTINGS_INITIAL_VALUE_PAID
    //   };
    // }
    // if (accountType === AccountTypeEnum.OTHER) {
    //   return {
    //     ...MAILBOX_SETTINGS_INITIAL_VALUE_OTHER
    //   };
    // }
    return {
      ...MAILBOX_SETTINGS_INITIAL_VALUE
    };
  };
  const formInitialValue = useMemo(() => {
    return setSliderValues();
  }, [settings]);

  useEffect(() => {
    if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
      let valuesRes = setSliderValues();
      setEmailVolumeDailyRange(valuesRes?.emailVolumeDailyRange);
    }
  }, [provider]);
  // const emailVolumeDailyRangeBounds = useMemo(() => {
  //   if(protocol === undefined) {
  //     return (MAILBOX_SETTING_PER_PROVIDER as any)[provider ?? DEFAULT_MAIL_SETTING];
  //   }
  //   if (protocol === MailboxProtocolEnum.SMTP_IMAP &&
  //     provider != MailboxTypeEnum.GMAIL && provider != MailboxTypeEnum.OFFICE_OAUTH) {
  //     debugger;
  //     return (MAILBOX_SETTING_PER_PROVIDER_WITH_PROTOCOL as any)[protocol ?? DEFAULT_MAIL_SETTING];
  //   }
  //   return (MAILBOX_SETTING_PER_PROVIDER_WITH_PROTOCOL as any)[`${provider}${protocol}` ?? DEFAULT_MAIL_SETTING];
  // }, [provider, protocol]);

  const showUpdateMenuItem = useMemo(() => typeof updateCredentails === "function", [updateCredentails]);

  const showDeleteMenuItem = useMemo(() => typeof onDelete === "function", [onDelete]);

  const showReconnectItem = useMemo(() => typeof onReconnect === "function", [onReconnect]);

  const showViewIpsDetailsMenuItem = useMemo(() => typeof onViewIpsDetails === "function", [onViewIpsDetails]); //onViewLogsDetails

  const showViewLogDetailsMenuItem = useMemo(() => typeof onViewLogsDetails === "function", [onViewLogsDetails]);

  const showSendEmailMenuItem = useMemo(() => typeof onSendEmail === "function", [onSendEmail]);

  const showOpenTracking = useMemo(() => typeof onOpenTracking === "function", [onOpenTracking]);

  const [sliderIncrement, setSliderIncrement] = useState(0);
  const handleUpdateCredentials = useCallback(
    (mailboxId: string) => {
      if (typeof updateCredentails === "function") {
        if (typeof settingEditViewMode === "function") {
          settingEditViewMode(true);
        }
        updateCredentails(mailboxId, "UPDATE");
      }
    },
    [updateCredentails, settingEditViewMode]
  );

  const handleConnectionValidate = useCallback(
    (mailboxId: string) => {
      if (typeof validateCredentails === "function") {
        if (typeof settingEditViewMode === "function") {
          settingEditViewMode(true);
        }
        validateCredentails(mailboxId, "VALIDATE");
      }
    },
    [validateCredentails]
  );

  // const showUpdateMenuItem = useMemo(() => typeof updateCredentails === "function", [
  //   updateCredentails,
  // ]);

  const [selectedID, setSelectedID] = useState("");
  const handleDelete = useCallback(
    (mailboxId: string, email: string) => {
      if (typeof onDelete === "function") {
        // const updList = selectedMailBoxes.filter((mbId) => mbId !== mailboxId);
        //onDelete({ selectedMailBoxes: updList });
        onDelete(mailboxId, email);
        setSelectedID(mailboxId);
      }
    },
    [onDelete]
  );

  const handleViewIpsDetails = useCallback(
    (email: string) => {
      if (typeof onViewIpsDetails === "function") {
        onViewIpsDetails(email);
      }
    },
    [onViewIpsDetails]
  );

  //onViewLogsDetails
  const handleViewLogDetails = useCallback(
    (id: string) => {
      if (typeof onViewLogsDetails === "function") {
        onViewLogsDetails(id);
      }
    },
    [onViewLogsDetails]
  );

  const handleSendEmail = useCallback(
    (name: string, email: string) => {
      if (typeof onSendEmail === "function") {
        onSendEmail(name, email);
      }
    },
    [onSendEmail]
  );
  const handleReconnect = useCallback(
    (id: string, action: string) => {
      if (typeof onReconnect === "function") {
        onReconnect(id, action);
      }
    },
    [onReconnect]
  );
  const handleOpenTracking = useCallback(
    (email: string) => {
      if (typeof onOpenTracking === "function") {
        onOpenTracking(email);
      }
    },
    [onOpenTracking]
  );

  const showBrooklynMenuItem = (protocol: string, provider: string) => {
    return protocol === "SMTP_IMAP" && provider === "GOOGLE" ? true : false;
  };
  const emailVolumeDailyRangeBounds = useMemo(() => {
    if (isEmpty(id)) {
      setSliderIncrement(accountInboxLimitConfig?.sliderIncrement);
      return {
        emailVolumeDailyRange: [accountInboxLimitConfig?.defaultRange?.min, accountInboxLimitConfig?.defaultRange?.max],
        emailVolumeIncrement: accountInboxLimitConfig?.sliderIncrement
      };
    } else {
      const accountType = getAccountTypeByProvider(provider, email);
      return (MAILBOX_SETTING_PER_PROVIDER_WITH_ACCOUNTTYPE as any)[accountType ?? DEFAULT_MAIL_SETTING];
    }
  }, [provider, email, id, accountInboxLimitConfig]);

  useMemo(() => {
    if (id) setSliderIncrement(emailVolumeDailyRangeBounds?.emailVolumeIncrement);
  }, [emailVolumeDailyRangeBounds?.emailVolumeIncrement, id]);

  const [emailSignature, setEmailSignature] = useState(settings?.signature || "");
  const [emailVolumeDailyRange, setEmailVolumeDailyRange] = useState<any>(formInitialValue.emailVolumeDailyRange);
  const [emailHours, setEmailHours] = useState(formInitialValue.emailSendingHours);
  const [incrementPercent, setincrementPercent] = useState(formInitialValue.incrementPercent);
  const [timezonArr, setTimezonArr] = useState<TimeZoneType[] | []>([]);
  const [isEmailAsk, setisEmailAsk] = useState(false);
  const [emailRange, setStateEmailRange] = useState(formInitialValue.emailVolumeDailyRange);
  const [state, setState] = useState<any>({ autoForwardEmail: "", replyTo: "" });
  const isEmailAskFiled = useCallback(() => {
    if (provider === "TECHCOMPILER" || provider === "SENDGRID" || provider === "MAILGUN" || provider === "CUSTOM") {
      setisEmailAsk(true);
    }
  }, [provider]);

  useEffect(() => {
    setState((prev: any) => ({ ...prev, autoForwardEmail: settings?.autoForwardEmail, replyTo: settings?.replyTo }));
  }, [settings?.autoForwardEmail, settings?.replyTo]);

  const fetchTimezones = async () => {
    var timezones = await TimeZones.getTimezones();
    setTimezonArr(timezones);
  };

  useEffect(() => {
    fetchTimezones();
  }, []);

  useEffect(() => {
    isEmailAskFiled();
  });

  useEffect(() => {
    form.setFieldsValue({
      emailVolumeDailyRange: emailVolumeDailyRange
    });
  }, [emailVolumeDailyRange]);

  useEffect(() => {
    if (isEmpty(id)) {
      setEmailVolumeDailyRange([accountInboxLimitConfig?.defaultRange?.min, accountInboxLimitConfig?.defaultRange?.max]);
      setincrementPercent(accountInboxLimitConfig?.dailyRate);
      setStateEmailRange([accountInboxLimitConfig?.defaultRange?.min, accountInboxLimitConfig?.defaultRange?.max]);
    } else {
      setEmailVolumeDailyRange(emailVolumeDailyRange);
      setStateEmailRange(emailVolumeDailyRange);
    }
  }, [accountInboxLimitConfig, id]);

  // useEffect(() => {
  //   // return () => {
  //   form.resetFields();
  //   // }
  // }, [form]);

  useEffect(() => {
    return () => {
      if (typeof settingEditViewMode === "function") {
        settingEditViewMode(false);
      }
    };
  }, []);

  const { barChartData, xAxisDataKey, barDataKey, barChartXAxisLabel, barChartYAxisLabel } = useMemo(() => {
    if (!incrementPercent) {
      return {
        barChartData: [],
        barDataKey: "emails",
        xAxisDataKey: "day",
        barChartXAxisLabel: "Days",
        barChartYAxisLabel: ""
      };
    }

    const firstDayEmails = emailVolumeDailyRange[0];
    const lastDayEmails = emailVolumeDailyRange[1];

    const numberOfDays = Math.ceil(Math.log(lastDayEmails / firstDayEmails) / Math.log(1 + incrementPercent * 0.01));

    const data = [];

    for (let i = 0; i <= numberOfDays; i++) {
      const calculatedValue = Math.round(firstDayEmails * Math.pow(1 + incrementPercent * 0.01, i));

      if (calculatedValue > lastDayEmails) {
        data.push(lastDayEmails);
        break;
      }
      data.push(calculatedValue);
    }
    const lastValue = data[data.length - 1];
    data.push(lastValue, lastValue, lastValue);

    return {
      barChartData: data.map((emails, index) => ({
        Emails: emails,
        day: `Day ${index + 1}`
      })),
      barDataKey: "Emails",
      xAxisDataKey: "day",
      barChartXAxisLabel: "Days",
      barChartYAxisLabel: "Emails"
    };
  }, [emailVolumeDailyRange, incrementPercent]);

  const handleSave = useCallback(
    (form) => {
      const {
        emailVolumeDailyRange: [minEmails, maxEmails],
        incrementPercent,
        timeZone,
        days,
        emailSendingHours,
        firstName,
        lastName
        // email,
      } = form;
      console.log("emailemail", email);

      // if (minEmails === maxEmails) {
      //   notification.error({ message: "Daily range can't be same" })
      //   return;
      // }

      // if (emailSendingHours) {
      //   if (emailSendingHours[0] === emailSendingHours[1]) {
      //     notification.error({ message: "Email sending hours can't be same" })
      //     return;
      //   }
      // }

      if (timeZone === undefined || timeZone === "") {
        notification.error({
          message: "Please select campaign timezone."
        });
        return;
      }

      if (minEmails && onBlurCheckRange()) {
        onSave({
          minEmails,
          maxEmails,
          email,
          signature: emailSignature,
          incrementPercent,
          timeZone,
          days,
          startHour: emailSendingHours[0],
          endHour: emailSendingHours[1],
          firstName,
          lastName,
          capacity: Capacity,
          autoForwardEmail: state?.autoForwardEmail,
          replyTo: state?.replyTo
        });
      }
    },
    [emailSignature, onSave, Capacity, accountInboxLimitConfig, emailRange, state?.autoForwardEmail, state?.replyTo]
  );
  const getOverLayMenu = useCallback(
    (id: string, email: string, name: string, provider: string, protocol: any) => {
      return (
        <Menu>
          {/* {showUpdateMenuItem && (
            <Menu.Item icon={<ClearOutlined />} key="change" style={{ width: "100%" }} onClick={() => handleUpdateCredentials(id)}>
              Update Credentials
            </Menu.Item>
          )} */}

          {showUpdateMenuItem && (
            <Menu.Item icon={<LinkOutlined />} key="change" style={{ width: "100%" }} onClick={() => handleConnectionValidate(id)}>
              Validate Connection
            </Menu.Item>
          )}

          {showReconnectItem && isSMTP && (
            <Menu.Item icon={<ReloadOutlined />} key="change" style={{ width: "100%" }} onClick={() => handleReconnect(id, "RECONNECT")}>
              Reconnect
            </Menu.Item>
          )}

          {/* {showBrooklynMenuItem(protocol, provider) && (
            <Menu.Item icon={<LinkOutlined />}
              key="change" style={{ width: "100%" }}
              onClick={() => console.log("clicked...")}>
              Migrate to Brooklyn
            </Menu.Item>
          )} */}
          {/* 
          {showDeleteMenuItem && (
            <Menu.Item
              key="delete"
              style={{ width: "100%" }}
              onClick={() => handleDelete(id, email)}
            >
              <DeleteOutlined /> Delink
            </Menu.Item>
          )} */}
          {/* {showViewIpsDetailsMenuItem && (
            <Menu.Item key="ipsDetails" style={{ width: "100%" }} onClick={() => handleViewIpsDetails(email)}>
              <ProfileOutlined /> View IPS details
            </Menu.Item>
          )} */}
          {showOpenTracking && (
            <Menu.Item key="showOpenTracking" style={{ width: "100%" }} onClick={() => handleOpenTracking(email)}>
              <ProfileOutlined /> Custom Domain Tracking
            </Menu.Item>
          )}
          {showSkipCodes && (
            <Menu.Item icon={<MailOutlined />} key="sendEmail" style={{ width: "100%" }} onClick={responseSkip}>
              Response Skip Codes
            </Menu.Item>
          )}
          {showViewLogDetailsMenuItem && (
            <Menu.Item key="logs" style={{ width: "100%" }} onClick={() => handleViewLogDetails(id)}>
              <InfoCircleOutlined /> View Logs
            </Menu.Item>
          )}
          {showSendEmailMenuItem && (
            <Menu.Item key="logs" style={{ width: "100%" }} onClick={() => handleSendEmail(name, email)}>
              <MailOutlined /> Send Email
            </Menu.Item>
          )}
          <Menu.Item key="logs" style={{ width: "100%" }} onClick={() => calculateIps(id)}>
            <CalculatorOutlined /> Calculate IPS
          </Menu.Item>
          {showDeleteMenuItem && (
            <Menu.Item
              icon={<DeleteOutlined />}
              key="change"
              style={{ width: "100%", color: theme.antdConf.errorColor }}
              onClick={() => handleDelete(id, email)}
            >
              Remove Inbox
            </Menu.Item>
          )}
        </Menu>
      );
    },
    [handleDelete, handleViewIpsDetails, handleViewLogDetails, showUpdateMenuItem, showDeleteMenuItem, showViewIpsDetailsMenuItem, showViewLogDetailsMenuItem]
  );

  const clearMailboxErrors = async () => {
    setLoadingMessage("Clear Errors and Re-Activating Mailbox...");
    const payload = {
      action: "Activate"
    };
    const { url, method } = API_ENDPOINTS.MAILBOX.getUpdatelUrl(id);
    const response = await callApi({
      url,
      method,
      data: payload
    });

    if (response.success) {
      message.success({
        content: `Errors cleared and Mailbox Activated.`,
        key: "updating integrations setting",
        duration: 2
      });
    }
    setLoadingMessage("");
  };

  const helpIconContent = (
    <div>
      <p>View error logs</p>
    </div>
  );

  const onCapacityChanage = (e: any) => {
    setCapacity(parseInt(e.target.value));
  };

  const setEmailRange = (value: any) => {
    setEmailVolumeDailyRange(value);
    setStateEmailRange(value);
  };

  const onRangeChange = (value: string, type: string) => {
    let numberVlaue = parseInt(value) > 0 ? parseInt(value) : 0;
    if (type === "fromRange") {
      // setEmailVolumeDailyRange([numberVlaue, emailVolumeDailyRange[1]])
      setStateEmailRange([numberVlaue, emailRange[1]]);
    } else if (type === "toRange") {
      // setEmailVolumeDailyRange([emailVolumeDailyRange[0], numberVlaue])
      setStateEmailRange([emailRange[0], numberVlaue]);
    }
  };

  const onBlurCheckRange = () => {
    let validateRange = true;
    if (emailRange[0] < accountInboxLimitConfig?.capRange?.min) {
      validateRange = false;
      notification.error({ message: `Minimum value can't be less than ${accountInboxLimitConfig?.capRange?.min}` });
    } else if (emailRange[0] > accountInboxLimitConfig?.capRange?.max) {
      validateRange = false;
      notification.error({ message: `Minimum value can't be greater than ${accountInboxLimitConfig?.capRange?.max} ` });
    } else if (emailRange[1] < emailRange[0]) {
      validateRange = false;
      notification.error({ message: `Maximum value can't be less than ${emailRange[0]} ` });
    } else if (emailRange[1] < accountInboxLimitConfig?.capRange?.min) {
      validateRange = false;
      notification.error({ message: `Maximum value can't be less than ${accountInboxLimitConfig?.capRange?.min} ` });
    } else if (emailRange[1] > accountInboxLimitConfig?.capRange?.max) {
      validateRange = false;
      notification.error({ message: `Maximum value can't be greater than ${accountInboxLimitConfig?.capRange?.max} ` });
    }
    if (validateRange) {
      setEmailVolumeDailyRange(emailRange);
    }
    return validateRange;
  };
  console.log("formInitialValueformInitialValue", formInitialValue);




// const [ips, setIps] = useState<number>();
const [ips, setIps] = useState<number[]>([]);


useEffect(() => {
  const Ipsupdate = async (headerId: any) => {
    setLoadingMessage("Fetching IPS..."); 
    const { url, method } = API_ENDPOINTS.MAILBOX.Ipsupdate(id);
    const response = await callApi({
      url,
      method,
      data: {
        "action": "DELETE_HEADER",
        "headerId": headerId
      }
    });
    
    if (response.success) {
      setIps(response.data.ips); 
    }
    setLoadingMessage(""); 
  };
}, [id, callApi, setLoadingMessage,ips]); 




  return (
    <Flex direction="column">
      <Text variant="h6" align="center" block style={{ minHeight: "50px" }}>
        Mailbox settings
      </Text>
      {email && (
        <Div>
          <Text variant="overline" color="secondary" block mb={5}>
            <p className="text-email-center" style={{ textTransform: "none" }}>
              {email}
            </p>
            <p className="text-email-center" style={{ textTransform: "none", fontSize: "12px" }}>
              {provider} {`(${protocol})`}
            </p>

            {/* Email */}
          </Text>
        </Div>
      )}
      {campaignName && campaignId && (
        <Div mb={20}>
          <Text variant="overline" color="secondary" block mb={5}>
            <p className="text-email-center" style={{ textTransform: "none", textDecoration: "underline" }}>
              {<Link to={`/campaigns/${campaignId}/sequences`}>{campaignName}</Link>}
            </p>
          </Text>
        </Div>
      )}
      <Div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
          top: "-22px"
        }}
      >
        <Text variant="caption" block mr={10} color="secondary">
        IPS
          <Tooltip
            placement="rightTop"
            title="IPS or Inbox Placement Score is a reputation index on how well your emails are landing or placed into the primary inbox instead of promotion folder or the spam folder. For example, a 30% IPS, means only 3 out of 10 of your emails are going into the primary inbox, whereas an 90% IPS, means 9 out of 10 of your emails are hitting the inbox."
            overlayInnerStyle={{ textAlign: "center" }}
          >
            <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f", fontSize: "14px" }} />
          </Tooltip>
          {ips !== null ? ips : 'Loading IPS...'}
        </Text>
        <Dropdown
          placement="bottomRight"
          overlay={getOverLayMenu(
            id,
            email || "",
            `${formValues?.firstName || settings?.firstName || ""}
         ${formValues?.lastName || settings?.lastName || ""}`,
            provider || "",
            protocol
          )}
          trigger={["click"]}
        >
          {selectedID === id && mailboxDelinking ? <LoadingOutlined style={{ color: theme.antdConf.primaryColor }} /> : <MenuOutlined />}
        </Dropdown>
      </Div>
      {paused && id && (
        <Div mb={20} style={{ textAlign: "right" }}>
          <Button type="primary" style={{ marginTop: "20px", alignSelf: "end" }} onClick={clearMailboxErrors}>
            Clear Errors and Re-Activate Mailbox
          </Button>
          <Popover content={helpIconContent}>
            <InfoCircleFilled style={{ alignSelf: "center", marginLeft: "5px", color: "#f1c40f", cursor: "pointer" }} onClick={togglePausedMailboxDialog} />
          </Popover>
        </Div>
      )}
      {showPausedMailboxModal && (
        <Modal visible onCancel={togglePausedMailboxDialog} footer={null} width={600} maskClosable={false}>
          <PauseMailboxModal inboxId={id} setShowPausedMailboxModal={setShowPausedMailboxModal} />
        </Modal>
      )}
      <Form
        scrollToFirstError
        form={form}
        onFinish={handleSave}
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        initialValues={formInitialValue}
        // fields={[
        //   {
        //     name: ["firstName"],
        //     value: formValues?.firstName || settings?.firstName || ""
        //   },
        //   {
        //     name: ["lastName"],
        //     value: formValues?.lastName || settings?.lastName || ""
        //   },
        //   // {
        //   //   name: ["emailVolumeDailyRange"],
        //   //   value: emailVolumeDailyRangeBounds?.emailVolumeDailyRange
        //   // }
        // ]}
      >
        {isEmailAsk && (
          <>
            <Row gutter={[30, 30]} style={{ marginBottom: "1em" }}>
              <Col className="w-50">
                <Text variant="overline" color="secondary">
                  Email
                </Text>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter valid email"
                    },
                    {
                      required: true,
                      message: "Please enter email"
                    }
                  ]}
                  initialValue={email}
                >
                  <Input tabIndex={1} className={classes.inputStyle} size="middle" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <Row gutter={[30, 30]} style={{ marginBottom: "1em" }}>
          <Col className="w-50">
            <Text variant="overline" color="secondary">
              First Name
            </Text>
            <Form.Item
              name={"firstName"}
              rules={[
                {
                  required: true,
                  message: "Please write firstname"
                }
              ]}
              initialValue={formValues?.firstName || settings?.firstName || ""}
            >
              <Input tabIndex={3} className={classes.inputStyle} size="middle" />
            </Form.Item>
          </Col>

          <Col className="w-50">
            <Text variant="overline" color="secondary">
              Last Name
            </Text>
            <Form.Item
              name={"lastName"}
              rules={[
                {
                  required: true,
                  message: "Please write lastname"
                }
              ]}
              initialValue={formValues?.lastName || settings?.lastName || ""}
            >
              <Input tabIndex={3} className={classes.inputStyle} size="middle" />
            </Form.Item>
          </Col>
        </Row>

        <div className="chart-content">
          <div className="range-inputwrapper">
            <div style={{ marginBottom: "-15px" }}>
              <Flex>
                <Div
                  style={{
                    maxWidth: "55%"
                  }}
                >
                  <Text variant="overline" color="secondary">
                    <span> Daily range of email volume</span>
                    <Popover content="Define your daily email volume here" trigger="hover" placement="right">
                      <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f", height: "14px", width: "14px" }} />
                    </Popover>
                  </Text>
                </Div>{" "}
                {accountInboxLimitConfig && accountInboxLimitConfig.capRange && (
                  <Text variant="overline" ml={10}>
                    <Flex align="baseline">
                      {/* {emailVolumeDailyRange[0]} to {emailVolumeDailyRange[1]} emails */}
                      <Input
                        min={accountInboxLimitConfig?.capRange?.min}
                        max={accountInboxLimitConfig?.capRange?.max}
                        tabIndex={3}
                        type="number"
                        className={classes.inputNumberStyle}
                        size="middle"
                        onChange={(e) => onRangeChange(e.target.value, "fromRange")}
                        value={emailRange[0]}
                        onBlur={onBlurCheckRange}
                      />
                      {/* {emailVolumeDailyRange[0] < accountInboxLimitConfig?.capRange?.min ? <span>
                        {`Minimum value can't be less than ${accountInboxLimitConfig?.capRange?.min} `}
                      </span> : emailVolumeDailyRange[0] > accountInboxLimitConfig?.capRange?.max ? <span>
                        {`Minimum value can't be greater than ${accountInboxLimitConfig?.capRange?.max} `}
                      </span> : null} */}
                      <Text variant="overline" ml={5} mr={5}>
                        to
                      </Text>
                      <Input
                        min={accountInboxLimitConfig?.capRange?.min}
                        max={accountInboxLimitConfig?.capRange?.max}
                        tabIndex={3}
                        type="number"
                        className={classes.inputNumberStyle}
                        size="middle"
                        onChange={(e) => onRangeChange(e.target.value, "toRange")}
                        value={emailRange[1]}
                        onBlur={onBlurCheckRange}
                      />
                      {/* {emailVolumeDailyRange[1] < accountInboxLimitConfig?.capRange?.min ? <span>
                        {`Maximum value can't be less than ${accountInboxLimitConfig?.capRange?.min} `}
                      </span> : emailVolumeDailyRange[1] > accountInboxLimitConfig?.capRange?.max ? <span>
                        {`Maximum value can't be greater than ${accountInboxLimitConfig?.capRange?.max} `}
                      </span> : null} */}
                      <Text variant="overline" ml={5} mr={5}>
                        emails
                      </Text>
                    </Flex>
                  </Text>
                )}
              </Flex>
              <Form.Item name="emailVolumeDailyRange">
                <Slider
                  marks={marks}
                  range
                  min={accountInboxLimitConfig?.capRange?.min}
                  max={accountInboxLimitConfig?.capRange?.max}
                  step={1}
                  tipFormatter={(value) => `${value} email(s)`}
                  onChange={(value) => setEmailRange(value)}
                  // value={emailVolumeDailyRangeBounds?.emailVolumeDailyRange}
                />
              </Form.Item>
            </div>
            <div style={{ marginBottom: "-15px" }}>
              <Flex>
                <Text variant="overline" color="secondary">
                  Daily rate change
                </Text>{" "}
                <Popover content="Define your daily rate of change or increase in volume here" trigger="hover" placement="right">
                  <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f" }} />
                </Popover>
                <Text variant="overline" ml={10}>
                  +/- {incrementPercent}%
                </Text>
              </Flex>
              <Form.Item name="incrementPercent">
                <Slider marks={marks} min={10} step={5} max={50} tipFormatter={(value) => `+/- ${value}%`} onChange={setincrementPercent} />
              </Form.Item>
            </div>
            {settings && settings.capacity ? (
              <div style={{ marginBottom: "-15px" }}>
                <Flex>
                  <Text variant="overline" color="secondary">
                    current capacity
                  </Text>
                  <Input
                    style={{ width: "60px", marginLeft: 10, marginTop: -8 }}
                    type="text"
                    defaultValue={Capacity}
                    onChange={(e: any) => onCapacityChanage(e)}
                  />{" "}
                  {/* <Text variant="overline" ml={10}>
                    {settings?.capacity}
                  </Text>{" "} */}
                </Flex>
              </div>
            ) : null}
          </div>

          <Div h={200} w="100%" className="chart-wrap">
            <SimpleBarChart
              data={barChartData}
              xAxisDataKey={xAxisDataKey}
              barDataKey={barDataKey}
              xAxisLabel={barChartXAxisLabel}
              yAxisLabel={barChartYAxisLabel}
            />
          </Div>
        </div>

        <Div className="w-50">
          <Text variant="overline" color="secondary">
            Reply to
          </Text>
          <Form.Item rules={[{ type: "email", message: "Invalid email" }]}>
            <Input
              tabIndex={3}
              className={classes.inputStyle}
              size="middle"
              value={state?.replyTo ?? ""}
              onChange={(e) => setState((prev: any) => ({ ...prev, replyTo: e.target.value }))}
            />
          </Form.Item>
          <Text variant="overline" color="secondary">
            Auto forward email
          </Text>
          <Form.Item rules={[{ type: "email", message: "Invalid email" }]}>
            <Input
              tabIndex={3}
              className={classes.inputStyle}
              size="middle"
              onChange={(e) => setState((prev: any) => ({ ...prev, autoForwardEmail: e.target.value }))}
              value={state?.autoForwardEmail ?? ""}
            />
          </Form.Item>
        </Div>
        <div className="time-data-content">
          <FormControlWrapper className="time-zone-left">
            <Label htmlFor="timeZone">
              <Text variant="overline" color="secondary">
                Timezone
              </Text>
            </Label>
            <Form.Item name="timeZone" initialValue={settings?.timeZone}>
              <Select showSearch>
                {timezonArr &&
                  timezonArr.map(({ label, tzCode }: TimeZoneType) => (
                    <Option key={label} value={tzCode}>
                      {label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </FormControlWrapper>

          <Div style={{ marginBottom: "-15px" }} className="email-hours-right">
            <Text variant="overline" color="secondary">
              Email sending hours
            </Text>
            <Text variant="overline" ml={10}>
              {humanizeHour(emailHours[0])} to {humanizeHour(emailHours[1])}
            </Text>
            <Form.Item name="emailSendingHours">
              <Slider marks={marks} range min={0} max={24} tipFormatter={humanizeHour} onChange={setEmailHours} />
            </Form.Item>
          </Div>
        </div>

        <Form.Item name="days" rules={getRequiredRules("Select email sending days")}>
          <Checkbox.Group style={{ width: "100%" }}>
            <Text variant="overline" block mb={10} color="secondary">
              Email Sending Days
            </Text>
            <Div>
              <Checkbox value={1}>Mon</Checkbox>
              <Checkbox value={2}>
                <Text variant="sub2">Tue</Text>
              </Checkbox>
              <Checkbox value={3}>
                <Text variant="sub2">Wed</Text>
              </Checkbox>
              <Checkbox value={4}>
                <Text variant="sub2">Thu</Text>
              </Checkbox>
              <Checkbox value={5}>
                <Text variant="sub2">Fri</Text>
              </Checkbox>
              <Checkbox value={6}>
                <Text variant="sub2">Sat</Text>
              </Checkbox>
              <Checkbox value={7}>
                <Text variant="sub2">Sun</Text>
              </Checkbox>
            </Div>
          </Checkbox.Group>
        </Form.Item>
        <div style={{ marginTop: "5px" }}>
          <div style={{ marginBottom: "5px" }}>
            <Text variant="overline" color="secondary">
              Signature
            </Text>
          </div>
          <Div mh={100}>
            <WYSIWYGEditor
              value={emailSignature}
              onChange={setEmailSignature}
              config={{
                height: 100,
                toolbarBottom: true,
                imageMaxSize: 1024 * 1024 * 1,
                imageEditButtons: ["imageDisplay", "imageAlign", "imageRemove"],
                placeholderText: "Enter email signature",
                pastePlain: true
              }}
              moreRichButtons={[]}
              moreTextButtons={["textColor", "bold", "italic", "backgroundColor", "underline", "insertLink", "fontFamily", "fontSize"]}
              moreTextButtonsVisible={5}
              tag="textarea"
            />
          </Div>
        </div>
      </Form>
    </Flex>
  );
};

export default MailboxSettings;
