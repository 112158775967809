import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Text } from 'styles/typography';
import { Flex } from 'styles/layout';

export interface DncModalProps {
    title: string;
    okText: string;
    onOk: () => void;
    onCancel: () => void;
    textAreaChange: any;
}

const DncModal = ({ title, okText, onOk, onCancel, textAreaChange }: DncModalProps) => {

    return (
        <>
            <Modal
                visible
                okText={okText}
                onOk={onOk}
                onCancel={onCancel}
                centered
                footer={null}
            ><Text variant='h6'>{title}</Text>
                <TextArea
                    style={{ marginTop: 20 }}
                    rows={4}
                    onChange={(e) => {
                        textAreaChange(e.target.value.split(/[\n,',' ]+/));
                    }}
                />
                <p style={{ color: 'rgba(96,99,103,.55)', fontSize: '12px', marginTop: 4 }} >Use comma or new line to separate multiple items</p>
                <Flex justify="end" mt={20} style={{ gap: "10px" }}>
                    <Button onClick={onCancel} type="text">
                        Cancel
                    </Button>
                    <Button onClick={onOk} type="primary">
                        {okText}
                    </Button>
                </Flex>
            </Modal>
        </>
    );
};

export default DncModal;