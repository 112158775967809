import { Empty } from "antd";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";

type NothingBurgerProps = {
  msg?: string;
  header?: string;
};
export const NothingBurger = (props: NothingBurgerProps) => {
  return (
    <Flex direction="column" align="center">
      <Empty
        description={false}
        imageStyle={{
          height: 200,
          width: 200
        }}
      />
      <Text variant="body1" block mt={10}>
        {props.header ?? "Nothing Burger"}
      </Text>
      <Text variant="sub2" block color="secondary" mt={10}>
        {props.msg ? props.msg : `You haven't added any mailbox yet.`}
      </Text>
      <Div mt={20}>
        {/* <Button icon={<PlusOutlined />} type="primary" onClick={onAdd}>
          Add Campaign
        </Button> */}
      </Div>
    </Flex>
  );
};
