import React, { useCallback, useState } from "react";
import {
  RollbackOutlined,
  InfoOutlined,
  UserSwitchOutlined,
  ClockCircleOutlined,
  TagOutlined,
  ThunderboltOutlined,
  MailOutlined,
  DeliveredProcedureOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Skeleton, Checkbox } from "antd";
import moment from "moment";
import { Flex, Div } from "styles/layout";
import { Text } from "styles/typography";
import { LIGHT_GRAY_COLOR, WHITE_COLOR } from "utils/colors";
import classes from "./styles.module.css";

type MailPreviewPaneProps = {
  id: string;
  subject: string;
  textPreview: string;
  isRead: boolean;
  onClick: () => void;
  recievedAt: number;
  from: string;
  onStartInfoSequence: () => void;
  onStartPowerSequence: () => void;
  onStartFutureSequence: () => void;
  onStartReferralSequence: () => void;
  onReply: () => void;
  onTag: () => void;
  onMarkAsUnRead: () => void;
  onDeliver: () => void;
  selectedMail?: any,
  isLoading?: Boolean,
  checkSpamMessages?: (id: string, e: any) => void
  isChecked: boolean
  checkIsSpam?: boolean;
  checkIsAll?:boolean
};

const MailPreviewPane = ({
  subject,
  textPreview,
  recievedAt,
  isRead,
  onClick,
  from,
  onStartFutureSequence,
  onStartInfoSequence,
  onStartPowerSequence,
  onStartReferralSequence,
  onMarkAsUnRead,
  onReply,
  onTag,
  onDeliver,
  selectedMail,
  id,
  isLoading,
  checkSpamMessages,
  isChecked = false,
  checkIsSpam = false,
  checkIsAll = false
}: MailPreviewPaneProps) => {
  // console.log("subject  ::", subject);
  // const subjectPreviewText = subject;
  // subject.length >= 66
  //   ? subject.substr(0, 63).trim().concat(" ...")
  //   : subject;

  const [style, setStyle] = useState({ display: isChecked ? "inline-block" : "block" });


  const handleInfoSequence: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onStartInfoSequence();
    },
    [onStartInfoSequence]
  );

  const handlePowerSequence: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onStartPowerSequence();
    },
    [onStartPowerSequence]
  );

  const handleFutureSequence: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onStartFutureSequence();
    },
    [onStartFutureSequence]
  );

  const handleReferralSequence: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onStartReferralSequence();
    },
    [onStartReferralSequence]
  );

  const handleMarkAsUnRead: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onMarkAsUnRead();
    },
    [onMarkAsUnRead]
  );

  const handleOnReply: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onReply();
    },
    [onReply]
  );

  const handleOnTag: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onTag();
    },
    [onTag]
  );

  const handleOnDeliver: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onDeliver();
    },
    [onDeliver]
  );
  const isToday = moment().isSame(moment(recievedAt), 'day')

  const checkbocCss = checkIsSpam || checkIsAll
  // console.log("checkbocCsscheckbocCss",checkbocCss)

  return (
    <>
      <Flex position="relative" w="100%">
      <div>
        {checkIsSpam  || checkIsAll? (
          <Div style={{ position: 'absolute', margin: '5px 0px 0px 5px',left:"6px", top:"2px", zIndex:"9" }}>
            <span
              style={style}
            >
              <Checkbox checked={isChecked} onChange={(e) => checkSpamMessages && checkSpamMessages(id, e)} />
            </span>
          </Div>
        ):null}
      </div>
      <Flex
        w="100%"
        bgColor={isRead ? LIGHT_GRAY_COLOR : WHITE_COLOR}
        className={` ${selectedMail === id ? classes.mailPaneActive : ''} ${!isRead ? classes.mailPaneUnread : classes.mailPane}`}
        p="5px 10px"
        align="center"
        style={{ lineHeight: 1.3, }}
        // onMouseEnter={e => {
        //   setStyle({ display: 'block' });
        // }}
        // onMouseLeave={e => {
        //   setStyle({ display: isChecked ? "inline-block" : "none" })
        // }}
        onClick={onClick}
      >

        <Flex w="100%" align="center">
          <Text
            variant="sub2"
            bold={!isRead}
            mr={20}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "#111",
              ...(checkbocCss && { marginLeft: '21px' })
            }}
          >
            {from}
          </Text>
          <Flex ml="auto" justify="end" align="center" >
            <Text variant="caption" bold={!isRead} style={{ color: "#555", fontWeight: "500", fontSize: "11px", whiteSpace:"nowrap" }}>
              {isToday ? moment(recievedAt).format("hh:mm a") :
                moment(recievedAt).format("MMM DD")}
            </Text>
          </Flex>
        </Flex>
        <Text
          variant="sub2"
          bold={!isRead}
          mt={4}
          mb={2}
          mr={6}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            width: "100%",
            color: "#777",
            fontSize: "13px",
            marginLeft: '0px' 
          }}
        >
          <Div className={classes.emailSubject}>{subject} -</Div>
          <Div className={classes.responseInbound}>{textPreview}</Div>
        </Text>
        {/* <Flex ml="auto" mt={4} align="center">
        <Text variant="caption" bold={!isRead} style={{ color: "#555", fontWeight: "500", fontSize: "11px" }}>
          {moment(recievedAt).format("DD MMM YYYY hh:mm a")}
        </Text>
      </Flex> */}
      </Flex>
      </Flex>
   
    </>
  );
};

export default MailPreviewPane;