import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Input, Modal, Tag, Button, Form, Space, Select } from "antd";
import { Div, Flex } from "styles/layout";
import { CampaignProspectType } from "types/Campaign";
import { Text } from "styles/typography";
import { LIGHT_GRAY_COLOR } from "utils/colors";
import { capitalize } from "utils/string";
import { getStageColor, getStatusColor } from "constants/prospect";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { TimeZoneType } from "utils/dateTime";
import TimeZones from "../../commonAPIs/timezones";
import { ProspectFieldType } from "components/UploadPropects/MapColumns";

const { Option } = Select;

interface ProspectDetailModalProps {
  onCancel: () => void;
  prospect: CampaignProspectType;
  campaignId: string;
  onFetch: () => void;
  isCreate: boolean;
  timezoneList?: { label: string; tzCode: string }[];
  canEdit?: boolean;
  prospectVariables?: ProspectFieldType[];
}

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex style={{ borderBottom: `1px solid ${LIGHT_GRAY_COLOR}` }} pt={10}>
    {children}
  </Flex>
);

const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="sub1" color="secondary" mr={20} style={{ width: 150 }}>
    {children}
  </Text>
);

const variableToText = (variable: string) =>
  capitalize(variable.replaceAll("{", "").replaceAll("}", ""));

const ProspectDetailModal = ({
  onCancel,
  prospect,
  campaignId,
  isCreate,
  onFetch,
  timezoneList = [],
  canEdit = true,
  prospectVariables,
}: ProspectDetailModalProps) => {
  const [form] = Form.useForm();
  const [keyform] = Form.useForm();
  const { setLoadingMessage } = useContext(appContext);
  const { callApi } = useApi();
  const [disabledTextBoxs, setDisabled] = useState(true);
  const [openKeyModal, setOpenKeyModal] = useState(false);
  const [attributes, setAttributes] = useState<
    { value: any; isEdit: boolean }[]
  >(() =>
    (prospect?.attributes ?? []).map((val) => ({ value: val, isEdit: false }))
  );



  useEffect(() => {
    if (isCreate) {
      setDisabled(false);
    }
    if (prospectVariables) {
      const attributesKeys = attributes?.map((attribute) => Object.keys(attribute.value)).flat();
      const standardFields: string[] = ["{{first_name}}", "{{last_name}}", "{{email}}", "{{company}}", "{{linkedin}}"].concat(attributesKeys || []);

      const arr1 = Array.from(new Set(standardFields))
      const arr2 = prospectVariables.map((pv) => pv.caseNeutralName)
      let unique1 = arr1.filter((o) => arr2.indexOf(o) === -1);
      let unique2 = arr2.filter((o) => arr1.indexOf(o) === -1);
      const unique = unique1.concat(unique2);

      setAttributes(state =>
        [...state,
        ...(unique ?? []).map((val) =>
          ({ value: { [val]: "" }, isEdit: false }))]
      )
    }
  }, [isCreate]);

  useEffect(() => {
    return () => {
      setAttributes([])
    };
  }, [])

  // const [timezonArr, setTimezonArr] = useState<TimeZoneType[] | []>([]);

  // const fetchTimezones = async () => {
  //   var timezones = await TimeZones.getTimezones();
  //   setTimezonArr(timezones);
  // };

  // useEffect(() => {
  //   fetchTimezones();
  // }, [])

  const onFinish = async (formValues: any) => {
    const {
      timezone,
      firstname,
      lastname,
      email,
      domain,
      company,
      linkedinId,
      // attributes,
    } = formValues;

    // const updatedAttributes21 = prospectVariables?.map((attr) => attr.name));

    const updatedAttributes = attributes.map((attr) => attr.value);
    setLoadingMessage("Saving prospects ...");

    const {
      url,
      method,
    } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getUpdateDetailsUrl();

    let payload = {};
    if (isCreate) {
      payload = {
        campaignId,
        timezone,
        firstname,
        lastname,
        email,
        domain,
        company,
        linkedinId,
        attributes: updatedAttributes,
      };
    } else {
      payload = {
        campaignId,
        timezone,
        firstname,
        lastname,
        email,
        domain,
        company,
        linkedinId,
        attributes: updatedAttributes,
        prospectId: prospect && prospect.id,
      };
    }

    const response = await callApi({
      url,
      method,
      data: payload,
    });
    if (response.success) {
      setDisabled(true);
    }

    setLoadingMessage("");
    onCancel();
    onFetch();
  };
  const onEditPress = () => setDisabled(false);
  const onCanelPress = () => {
    setDisabled(true);
    onCancel();
  };

  const onCanelKeyPress = () => {
    setOpenKeyModal(false);
  };

  const addMoreAttributes = () => {
    setOpenKeyModal(true);
  };

  const addNewAttribute = useCallback(
    (attrKey) => {
      setOpenKeyModal(false);
      setAttributes((attr) => [
        ...attr,
        { isEdit: true, value: { ["{{" + attrKey.keyName + "}}"]: "" } },
      ]);
      keyform.resetFields();
    },
    [keyform]
  );

  const onAttributeChange = useCallback((attrKey, newVal, index) => {
    setAttributes((attr) =>
      attr.map((attrElem, idx) =>
        index === idx ? { ...attrElem, value: { [attrKey]: newVal } } : attrElem
      )
    );
  }, []);

  const deleteAttribute = useCallback((index: any) => {
    setAttributes((attr) => attr.filter((attrElem, idx) => index !== idx));
  }, []);

  console.log("disabledTextBoxs", disabledTextBoxs, "isCreate", isCreate)

  return (
    <Modal
      visible
      onCancel={onCancel}
      footer={null}
      width="max-content"
      centered
    >
      <Flex direction="column" p="0 20px" w={600}>
        <Form
          scrollToFirstError
          onFinish={onFinish}
          form={form}
          requiredMark={false}
        >
          <Text variant="h6" mb={10} mr={10}>
            Prospect Detail
          </Text>
          {!isCreate && (
            <Flex mb={10} justify="space-between" align="center">

              <Tag>
                {prospect.subStatus}
              </Tag>

              <Flex>
                {disabledTextBoxs === true && (
                  <Button
                    icon={<EditOutlined />}
                    type="default"
                    disabled={!canEdit}
                    onClick={onEditPress}
                  >
                    Edit
                  </Button>
                )}
              </Flex>
            </Flex>
          )}
          <DetailWrapper>
            <DetailHeading>First Name</DetailHeading>
            <Form.Item
              name={"firstname"}
              initialValue={prospect && prospect.firstname}
              style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
              ]}
            >
              <Input
                style={{ color: "#000" }}
                bordered={disabledTextBoxs ? false : true}
                size="small"
                disabled={disabledTextBoxs}
              />
            </Form.Item>
          </DetailWrapper>
          <DetailWrapper>
            <DetailHeading>Last Name</DetailHeading>
            <Form.Item
              name={"lastname"}
              initialValue={prospect && prospect.lastname}
              style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                },
              ]}
            >
              <Input
                style={{ color: "#000" }}
                bordered={disabledTextBoxs ? false : true}
                size="small"
                disabled={disabledTextBoxs}
              />
            </Form.Item>
          </DetailWrapper>
          <DetailWrapper>
            <DetailHeading>Email</DetailHeading>
            <Form.Item
              name={"email"}
              initialValue={prospect && prospect.email}
              style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input
                style={{ color: "#000" }}
                bordered={disabledTextBoxs ? false : true}
                size="small"
                disabled={disabledTextBoxs}
              />
            </Form.Item>
          </DetailWrapper>
          <DetailWrapper>
            <DetailHeading>Company</DetailHeading>
            <Form.Item
              name={"company"}
              initialValue={prospect && prospect.company}
              style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
              rules={[
                {
                  required: true,
                  message: "Please enter company",
                },
              ]}
            >
              <Input
                style={{ color: "#000" }}
                bordered={disabledTextBoxs ? false : true}
                size="small"
                disabled={disabledTextBoxs}
              />
            </Form.Item>
          </DetailWrapper>
          <DetailWrapper>
            <DetailHeading>Linkedin Id</DetailHeading>
            <Form.Item
              name={"linkedinId"}
              initialValue={prospect && prospect.linkedinId}
              style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter linkedinId",
              //   },
              // ]}
            >
              <Input
                style={{ color: "#000" }}
                bordered={disabledTextBoxs ? false : true}
                size="small"
                disabled={disabledTextBoxs}
              />
            </Form.Item>
          </DetailWrapper>
          {/* {(isCreate || prospect?.timezone) && (
            <DetailWrapper>
              <DetailHeading>Timezone</DetailHeading>
              <Form.Item
                name="timezone"
                initialValue={prospect?.timezone ?? null}
                style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
                rules={[{ required: true, message: "Please select timezone" }]}
              >
                <Select placeholder="Timezone" disabled={disabledTextBoxs}>
                  {timezonArr && timezonArr.map(({ label, tzCode }: TimeZoneType) => (
                    <Option key={label} value={tzCode}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </DetailWrapper>
          )} */}
          {((!disabledTextBoxs && !isCreate) || (!disabledTextBoxs && isCreate)) ?
            <>
              {attributes?.map((attribute: any, index: number) =>
                Object.keys(attribute.value).map((attrKey: string) => (
                  <DetailWrapper key={attrKey + index}>
                    <DetailHeading>{variableToText(attrKey)}</DetailHeading>
                    <Form.Item
                      name={variableToText(attrKey) + index}
                      initialValue={attribute.value[attrKey]}
                      style={{ marginBottom: `10px`, width: "100%", flex: "1" }}
                    >
                      <Input
                        style={{ color: "#000" }}
                        bordered={disabledTextBoxs ? false : true}
                        size="small"
                        disabled={disabledTextBoxs}
                        onChange={(text) =>
                          onAttributeChange(attrKey, text.target.value, index)
                        }
                      />
                    </Form.Item>
                    {attribute?.isEdit && (
                      <Button
                        shape="circle"
                        icon={<DeleteOutlined />}
                        danger
                        style={{ marginLeft: 10, marginTop: 5 }}
                        type="primary"
                        size="small"
                        onClick={() => deleteAttribute(index)}
                      ></Button>
                    )}
                  </DetailWrapper>
                ))
              )}
            </> :
            <>
              {attributes?.map((attribute: any, index: number) =>
                Object.keys(attribute.value).map((attrKey: string) => (
                  <>
                    {/* {console.log("attribute.value[attrKey]", attrKey, attribute.value[attrKey])} */}
                    {
                      attribute.value[attrKey].length ?
                        <DetailWrapper key={attrKey + index}>
                          <DetailHeading>{variableToText(attrKey)}</DetailHeading>
                          <Form.Item
                            name={variableToText(attrKey) + index}
                            initialValue={attribute.value[attrKey]}
                            style={{ marginBottom: `10px`, width: "100%", flex: "1" }}
                          >
                            <Input
                              style={{ color: "#000" }}
                              bordered={disabledTextBoxs ? false : true}
                              size="small"
                              disabled={disabledTextBoxs}
                              onChange={(text) =>
                                onAttributeChange(attrKey, text.target.value, index)
                              }
                            />
                          </Form.Item>
                          {attribute?.isEdit && (
                            <Button
                              shape="circle"
                              icon={<DeleteOutlined />}
                              danger
                              style={{ marginLeft: 10, marginTop: 5 }}
                              type="primary"
                              size="small"
                              onClick={() => deleteAttribute(index)}
                            ></Button>
                          )}
                        </DetailWrapper> : null
                    }
                  </>
                ))
              )}
            </>
          }
          {disabledTextBoxs === false && (
            <Flex mb={10} justify="space-between" mt={34}>
              {/*  <Button
                type="link"
                size="small"
                icon={<PlusOutlined />}
                style={{ marginLeft: -8 }}
                onClick={addMoreAttributes}
              >
                Add Attributes
              </Button> */}
              <Space>
                <Button type="default" onClick={onCanelPress}>
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </Space>
            </Flex>
          )}
        </Form>
      </Flex>
      {openKeyModal && (
        <Modal
          visible
          footer={null}
          onCancel={onCanelKeyPress}
          width="max-content"
          centered
          onOk={addNewAttribute}
        >
          <Form scrollToFirstError onFinish={addNewAttribute} form={keyform} style={{ padding: "0 20px" }}>
            <Flex direction="column" w={350}>
              <DetailWrapper>
                <Form.Item
                  name={"keyName"}
                  label={"Attribute Name"}
                  initialValue={""}
                  style={{ marginBottom: `5px`, marginTop: `15px`, width: `100%`, color: `#808080` }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter attribute name",
                    },
                  ]}
                >
                  <Input
                    style={{ color: "#000" }}
                    bordered={disabledTextBoxs ? false : true}
                    size="small"
                    disabled={disabledTextBoxs}
                  />
                </Form.Item>
              </DetailWrapper>
            </Flex>
            <Flex align="center" justify="flex-end" mt={15}>
              <Button htmlType="submit" type="primary">
                Add
              </Button>
            </Flex>
          </Form>
        </Modal>
      )}
    </Modal>
  );
};

export default ProspectDetailModal;