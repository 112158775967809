interface AntField {
  getFieldValue: (fieldName: string) => any;
}

const emailRegexp = new RegExp(
  new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
);

const domainRegexp = new RegExp(
  /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const minCharacters = (stringLength: number) => ({
  getFieldValue,
}: AntField) => ({
  validator(_: any, value: any) {
    if (typeof value === "string" && value.length >= stringLength) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(`Atleast ${stringLength} characters`)
    );
  },
});

export const isEmailValid = (input: string) => emailRegexp.test(input);
export const isDomainValid = (input: string) => domainRegexp.test(input);

export const stringToArray = (string: string) =>
string
  .replaceAll(" ", "\n")
  .split(/[\n,\s]/)
  .filter((str) => !!str);
