import { useEffect, useContext, useState, useMemo } from "react";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import moment from "moment";
import AppContext from "contexts/appContext";
import { isArray } from "lodash";
import {
    Select,
    Form,
    Button,
    Skeleton,

} from "antd";
import { useAuth } from "auth/AuthProvider";
import SettingsModal from './SettingsModal'
import "./notification.css"
import Icon, { InfoCircleOutlined, WarningOutlined, CloseCircleOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import AlertsList from "./AlertsList";
import classes1 from "styles/common.module.css";
import classes from "pages/CampaignDetail/TagV2/styles.module.css";
import NewMailImg from "images/new_message_inbox.svg";
import RenderAlertDetailPage from './RenderAlertDetailPage'
import ResponseMailBox from "images/responseinbox.svg";
import { NavPathType } from "components/AppBar";
import { useLocation } from "react-router-dom";

interface NotificationProps {
    campaignId: string;
    levelType: string;
    setNavPaths?: SetNavPathsFn
}
interface NotificationState {
    severity: string;
    notificationList: NotificationList[];
    showSettingsModal: boolean;
    mailboxId: string;
    isLoading: boolean;
    selectedAlert: any
}
interface NotificationList {
    "severity": string;
    "read": boolean;
    "mailbox": {
        "id": string;
        "email": string
    },
    "subject": string
    "created": number,
    "id": string,
    "body": string,
    "campaign": {
        "name": string,
        "id": string
    },
}


const Notifications = ({ setNavPaths }: any) => {
    const { callApi } = useApi();
    const { user }: any = useAuth();

    const { setLoadingMessage } = useContext(AppContext);
    const [state, setState] = useState<NotificationState>({
        severity: "all",
        notificationList: [],
        showSettingsModal: false,
        mailboxId: '',
        isLoading: false,
        selectedAlert: null
    })
    const location = useLocation();

    const { severity, notificationList, showSettingsModal, mailboxId, isLoading, selectedAlert } = state
    const uuid = useMemo(() => {
        let uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : ""
        if (!uId) {
            if (user && user?.attributes && user?.attributes?.sub) {
                uId = user?.attributes?.sub || ''
            }
        }
        return uId
    }, [localStorage.getItem("selectedUserId"), user?.attributes])

    useEffect(() => {
        setState((prev: any) => ({
            ...prev, isLoading: true
        }))
        fetchAlertLists(uuid || '', severity)
    }, [uuid, severity])

    const fetchAlertLists = async (uId: string, severity: string) => {
        setLoadingMessage("Fetching Alerts...");
        let severityValue = severity
        if (severity === "all") {
            severityValue = ""
        }
        const { url, method } = API_ENDPOINTS.NOTIFICATION.getAlertLists(uId, severityValue);
        const response = await callApi({
            url,
            method,
        });
        if (response.success) {
            setState((prev: any) => ({
                ...prev, notificationList: response?.data?.items || [],
                isLoading: false
            }))
        }
        setLoadingMessage("");
    };

    const markAsRead = async (alertId: string, index: number) => {
        if (alertId && uuid) {
            const { url, method } = API_ENDPOINTS.NOTIFICATION.markAsRead(alertId);
            const response = await callApi({
                url,
                method,
            });
            if (response.success) {
                let clonedNotificatioList = [...notificationList]
                clonedNotificatioList[index].read = true
                setState((prev: any) => ({
                    ...prev, notificationList: clonedNotificatioList
                }))
            }
        }
    }

    const severityArry = [
        { label: "All", value: "all" },
        { label: "Info", value: "info" },
        { label: "Warn", value: "warn" },
        { label: "Error", value: "error" },
    ]

    const openSettingModal = async (e: any, id: string = "box_QvQz2twrWd") => {
        console.log("sssssssss", id, e)
        e.stopPropagation()
        if (id) {
            setState((prev) => ({ ...prev, showSettingsModal: true, mailboxId: id }))
        }
    }
    console.log("mailboxIdmailboxId", notificationList)

    const unmount = () => {
        setState((prev) => ({ ...prev, showSettingsModal: false, mailboxId: '' }))
    }

    const renderSelectedAlerts = (alert: any, index: number) => {
        if (!alert?.read) {
            markAsRead(alert?.id, index)
        }
        setState((prev) => ({ ...prev, selectedAlert: alert }))
    }

    useEffect(() => {
        const navPaths: NavPathType[] = [];
        if (location?.pathname.includes('/notifications')) {
            navPaths.push({ label: "Notification" });
            setNavPaths && setNavPaths(navPaths);
        }

        return () => setNavPaths && setNavPaths([]);
    }, [setNavPaths, location?.pathname]);
    return (
        <>
            <div className="unibox__page notification__page">
                <Flex ml={10} mb={20} justify="space-between" align="center">
                    <Text variant="body1" block align="center" ml={2} mr={10}>
                        Alerts
                    </Text>
                    <Select
                        value={severity}
                        style={{ width: 200 }}
                        onChange={(v: any) => setState((prev) => ({ ...prev, severity: v, selectedAlert: null }))}
                        placeholder="Select serverity"
                    >
                        {severityArry?.map((acc: any) => {
                            return <Select.Option value={acc?.value}>{acc?.label}</Select.Option>
                        })}
                    </Select>
                </Flex>

                <Flex direction="column" >
                    <Flex mb={10}>
                        <Flex
                            mb={20}
                            direction="column"
                            position="relative"
                            w={"100%"}
                        >
                            <Flex className={classes.emailSidebarContainer}>
                                <Div className={classes.emailSidebar} >
                                    {isLoading ? <>
                                        <Div p={20}>
                                            <Skeleton />
                                        </Div>
                                        <Div p={20}>
                                            <Skeleton />
                                        </Div>
                                        <Div p={20}>
                                            <Skeleton />
                                        </Div>
                                    </> :
                                        (!notificationList?.length && !isLoading ? <Div style={{ height: "100%", maxHeight: "100%", overflowY: "scroll", }} className={classes1.scrollContainer}>
                                            <Flex mt={100} justify="center" style={{ textAlign: "center" }} direction="column" >
                                                <img src={NewMailImg} style={{ width: "100px", margin: "0 auto" }} alt="No Email" />
                                                <Text
                                                    variant="body1"
                                                    mt={20}
                                                    align="center"
                                                    style={{ lineHeight: 1.2 }}
                                                    block
                                                >
                                                    There are no responses yet.
                                                </Text>
                                            </Flex>

                                        </Div> :
                                            <Div style={{ height: "100%", maxHeight: "100%", overflowY: "auto", }} className={classes1.scrollContainer} id="scrollableDiv">
                                                <InfiniteScroll
                                                    loader={<Div p={12}><Skeleton /></Div>}
                                                    dataLength={notificationList && notificationList?.length}
                                                    next={() => { }}
                                                    hasMore={false}
                                                    scrollableTarget="scrollableDiv"
                                                >
                                                    {notificationList && notificationList?.length > 0 && notificationList?.map(
                                                        (item, index) => (
                                                            <AlertsList alerts={item} getDetails={() => renderSelectedAlerts(item, index)} />
                                                        )
                                                    )}
                                                </InfiniteScroll>
                                            </Div>)}
                                </Div>

                                <span className={classes.emailContent} >
                                    <Div style={{ height: "calc(100% - 25px)", maxHeight: "calc(100% - 25px)", overflowY: "scroll", }} className={classes1.scrollContainer}>
                                        {selectedAlert ? <RenderAlertDetailPage alerts={selectedAlert} openSettingModal={openSettingModal} /> : <>
                                            <Flex mt={120} justify="center" direction="column">
                                                <Div align="center" mt={10}>
                                                    <img src={ResponseMailBox} style={{ width: "150px" }} alt="No Email" />
                                                    <Text
                                                        variant="body1"
                                                        mt={30}
                                                        align="center"
                                                        style={{ lineHeight: 1.2 }}
                                                        block
                                                    >
                                                        No conversations selected
                                                    </Text>
                                                </Div>
                                            </Flex>
                                        </>}
                                    </Div>
                                </span>
                            </Flex>
                        </Flex>
                    </Flex>

                </Flex>
                {/* <Div className="notification__list">
                    {isArray(notificationList) && notificationList.length > 0 ?
                        notificationList?.map((aler, i) => {
                            return <>
                                <Div className={`fixed__response-header notification__item ${aler?.read ? 'read_notification' : 'unread_notification'}`} key={i}>
                                    <Flex align="center">
                                        <Div mr={15}>
                                            {aler?.severity === "Info" ? <InfoCircleOutlined style={{ fontSize: '26px', color: "#ffa940" }} /> : aler?.severity === "Warn" ? <WarningOutlined style={{ fontSize: '26px', color: "#38cdff" }} /> : <CloseCircleOutlined style={{ fontSize: '26px', color: "#ff4d4f" }} />}
                                        </Div>
                                        <Div>
                                            <Flex mt={1} gap={15} justify="start" align="baseline" >

                                                <Text variant="h6" className="notification__subject">{aler?.subject}</Text>
                                                <Text variant="caption" style={{ opacity: '0.6' }}>{moment(aler?.created).format("ddd, MMM DD, hh:mm a")}</Text>

                                            </Flex>
                                            <Flex justify="space-between" mt={10}>
                                                <Flex gap={20}>


                                                    <Text variant="caption">{aler?.campaign?.name || '--'}</Text>
                                                    <Text variant="caption">{aler?.mailbox?.email || '--'}</Text>
                                                </Flex>
                                            </Flex>
                                            <Flex mt={10}>
                                                <span >{aler?.body || "test body1\n\nline1\nline2test body1\n\nline1\nline2test body1\n\nline1\nline2test body1\n\nline1\nline2"}</span>
                                            </Flex>
                                            <div className="action__btns">
                                                {!aler?.read && <Button type="link"
                                                    className="fix__error-btn"
                                                    onClick={(e) => markAsRead(aler?.id, e)}
                                                >Mark as read</Button>}
                                                {aler?.severity === 'Error' && <Button type="link"
                                                    className="fix__error-btn"
                                                    onClick={(e) => {
                                                        openSettingModal(e);
                                                    }}
                                                >Fix Error</Button>}
                                            </div>
                                        </Div>
                                    </Flex>
                                </Div>
                            </>
                        }) : "No records"}
                </Div> */}
            </div >
            {showSettingsModal && (
                <SettingsModal mailBoxId={"box_upThpLwjqb" || mailboxId} isFromNotifications={showSettingsModal}
                    unmount={() => unmount()} />
            )}
        </>
    );
};

export default withAppBar(Notifications);