export enum MailboxTypeEnum {
  GMAIL = "GOOGLE",
  GMAILONE = "GOOGLEONE",
  
  OTHER = "OTHER",

  CUSTOMMAIL = "SMTP",


  OFFICE_OAUTH = "MICROSOFT",
  OFFICE_BROWSER = "MICROSOFTONE",
  OFFICE_SMTP = "MICROSOFT_SMTP",

  AMAZON = "AMAZON",
  SENDGRID = "SENDGRID",
  TECHCOMPILER = "TECHCOMPILER",
  MAIL_GUN = "MAILGUN",
  DUMMY="DUMMY",
  GSUITE="GSUITE"
}

export enum MailboxProtocolEnum {
  SMTP_IMAP = 'SMTP_IMAP',
  OAUTH = 'OAUTH',
  BROWSER = 'BROWSER'
}

export enum AccountTypeEnum {
  FREE = 'free',
  PAID = 'paid',
  OTHER = 'other'
}

export type MailBoxResponseType = {
  email: string;
  id: string;
  limit: number;
  queued: number;
  paused: boolean;
  sent: number;
  pictureUrl?: string;
  firstname?: string;
  lastname?: string;
  protocol?: string;
  provider: MailboxTypeEnum;
  ips: number;
  action?: string;
  capacity?: number;
  Campaign?: string;
  campaignId?: string;
  campaignName?:string;
};

export type MailBoxIpsDetailType = {
  provider: string;
  deliveredTo: string;
  SPF: string;
  DKIM: string;
  deliveredAt: number;
};

export type MailBoxLogDetailType = {
  created: string;
  message: string;
  // type: "INFO" | "ERROR",
  // data: string;
  user: string;
  // time: number;
  err?: boolean;
};
