import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import { DeleteOutlined, PlusOutlined, MailOutlined } from "@ant-design/icons";
import { notification, Row, Col, Modal, Typography, Form, Input, Button, Skeleton, Pagination, Checkbox, Tabs } from "antd";
import useApi from "hooks/useApi";
import { BgImage, Div, Flex } from "styles/layout";
import AppContext from "contexts/appContext";
import LocalStorage from "utils/localstorage";
import { API_ENDPOINTS } from "constants/api";
import MailBoxCard from "components/MailBox/Card";
import MailBoxSettings, { MailBoxSettingsType } from "components/MailBox/Settings";
import { OAUTH_STATE_LS_KEY } from "constants/localstorage";
import MailBoxProvider, { getProviderAndProtocol, getProviderDetails } from "components/MailBox/Provider";
import MailBoxProviderDetailModal from "components/MailBox/Modal/MailBoxDetailProviderModal";
import { prefixSelector } from "components/MailBox/CountryCodePicker";
import { MailBoxResponseType, MailboxTypeEnum, MailboxProtocolEnum } from "types/MailBox";
import MailBoxImage from "images/mailbox.svg";
import { Text } from "styles/typography";
import IpsDetailsModal from "containers/IpsDetailsModal";
import LogDetailsModal from "containers/LogDetailsModal";
import ChangePasswordModal from "containers/changePasswordModal";
import classes from "./styles.module.css";
import { DARK_GRAY_COLOR } from "utils/colors";
import { MailboxToggleView, MailboxViewEnum, MailboxViewModes } from "./ViewMode";
import { MailboxTableView } from "./MailboxTableView";
import { LOCAL_STORAGE_MAP, useLocalStorage } from "hooks/useLocalStorage";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { NavPathType } from "components/AppBar";
import SendEmailModal from "containers/sendEmailModal";
import VerficationModal from "pages/CampaignDetail/Modals/VerficationModal";
import RecoveryEmailModal from "pages/CampaignDetail/Modals/RecoveryEmailModal";
import UpdatePasswordModal from "pages/CampaignDetail/Modals/UpdatePasswordModal";
import VerifyPhoneNoModal from "pages/CampaignDetail/Modals/VerifyPhoneNoModal";
import VerifyAccessCodeModal from "pages/CampaignDetail/Modals/VerifyAccessCodeModal";
import VerifyEmailByPhoneNoModal from "pages/CampaignDetail/Modals/VerifyEmailByPhoneNoModal";
import VerificationOptionsModal from "pages/CampaignDetail/Modals/VerificationOptionsModal";
import TroubleShootModal from "pages/CampaignDetail/Modals/TroubleShootModal";
import ReconnectProxyModal from "pages/CampaignDetail/Modals/ReconnectProxyModal";
import ManualGmailLoginModal from "pages/CampaignDetail/Modals/ManualGmailLoginModal";
import GmailIMAPLoginModal from "pages/CampaignDetail/Modals/GmailIMAPLoginModal";
import BoundConnectionModal from "pages/CampaignDetail/Modals/ConnectionModal";
import { WHISPER_BASE_URL } from "constants/api";
import Axios from "utils/axios";
import { OpenTrackingModal } from "./OpenTracking/OpenTracking";
import ReponseSkipModal from "./ReponseSkipModal";
import { isEmpty } from "lodash";
import MailTransferModal from "./MailTransferModal";
import { useUserlistState } from "contexts/userListContext";

import GsuiteLoginModal from "pages/CampaignDetail/Modals/GsuiteLoginModal";
// import GsuiteSetupModal from "pages/CampaignDetail/Modals/GsuiteSetupModal";
interface MailBoxPageProps {
  setNavPaths: SetNavPathsFn;
}

interface Proxy {
  id: string;
  host: string;
  port: string;
  user: string;
  pwd: string;
}

export const EmptyMailboxPlaceholder = ({ onAdd }: { onAdd: () => void }) => (
  <Flex align="center" justify="center" mt={50} direction="column">
    <BgImage h={150} w={150} imageUrl={MailBoxImage} />
    <Text variant="sub1" mt={20} mb={20} color="secondary">
      You haven't added any mailboxes yet.
    </Text>
    <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
      Add Mailbox
    </Button>
  </Flex>
);

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex pt={10} pb={5} ml={10} m={30} direction="column">
    {children}
  </Flex>
);

const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="caption" color="secondary" style={{ paddingLeft: 3 }}>
    {children}
  </Text>
);

const MailBoxList = ({ setNavPaths }: MailBoxPageProps) => {
  const [showAddMailboxModal, setShowAddMailboxModal] = useState(false);
  const [inboxes, setInboxes] = useState<MailBoxResponseType[]>([]);
  const [areInboxesLoading, setAreInboxesLoading] = useState(true);
  const location = useLocation<{ addEmailState?: string }>();
  const history = useHistory();
  const { setLoadingMessage } = useContext(AppContext);
  const { callApi } = useApi();
  const [mailBoxSettingsForm] = Form.useForm();
  const [mailBoxProviderForm] = Form.useForm();
  const [selectedEmailForIps, setSelectedEmailForIps] = useState(""); //inboxId
  const [selectedInboxNameForSendEmail, setselectedInboxNameForSendEmail] = useState(""); //inboxId
  const [settingEditView, setSettingEditView] = useState(false); //inboxId

  const [selectedInboxEmailForSendEmail, setselectedInboxEmailForSendEmail] = useState("");
  const [selectedInboxIdForLogs, setselectedInboxIdForLogs] = useState("");
  const [selectedInboxIdForChangePassword, setselectedInboxIdForChangePassword] = useState("");
  const [editableId, setEditableId] = useState("");
  const [selectedInboxEmailForChangePassword, setselectedInboxEmailForChangePassword] = useState("");
  const [providers, setProviders] = useState("");
  const [subform] = Form.useForm();
  const [mailBoxOfficeSmtp] = Form.useForm();
  const [microsoftValidation] = Form.useForm();
  const [updatePasswordform] = Form.useForm();
  const [accessCodeValidation] = Form.useForm();
  const [alernateEmailValidation] = Form.useForm();
  const [showConnectMailboxModal, setShowConnectMailboxModal] = useState(false);
  const [showConnectMailStmpModal, setshowConnectMailStmpModal] = useState(false);
  const [showConnectEmailValidationModal, setshowConnectEmailValidationModal] = useState(false);
  const [showConnectMicrosoftValidation, setshowConnectMicrosoftValidationl] = useState(false);
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [isEmailValidation, setisEmailValidation] = useState(false);
  const [isWrongPassword, setisWrongPassword] = useState(false);
  const [isWrongEmail, setisWrongEmail] = useState(false);
  const [isVerifyEmail, setisVerifyEmail] = useState(false);
  const [isInBoundConnection, setisInBoundConnection] = useState(false);
  const [isOutBoundConnection, setisOutBoundConnection] = useState(false);
  const [isBoundConnection, setisBoundConnection] = useState(false);
  const [showReconnect, setShowReconnect] = useState(false);
  const [showManualGmailLogin, setManualGmailLogin] = useState(false);
  const [smtpValidationStatus, setsmtpValidationStatus] = useState("");
  const [smtpValidationError, setsmtpValidationError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [task, setTask] = useState("");
  const [showText, setShowText] = useState("");
  const [browserId, setBrowserId] = useState("");
  const [mailboxSearchText, setMailboxSearchText] = useState("");
  const [showTextVerifyModal, setshowTextVerifyModal] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [showUpdatePasswordModal, setshowUpdatePasswordModal] = useState(false);
  const [extensionStatus, setExtensionStatus] = useState("");
  const [installExtension, setInstallExtension] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState({ modalVisible: false, data: { email: "", id: "" } });
  const [mailboxViewMode, setMailboxViewMode] = useLocalStorage(LOCAL_STORAGE_MAP.MAILBOX_VIEW_MODE, MailboxViewEnum.CARD);
  const [instanceId, setInstanceId] = useState("");
  const [smtpInBoundError, setSmtpInBoundError] = useState("");
  const [smtpOutBoundError, setSmtpOutBoundError] = useState("");
  const [validating, setValidating] = useState(false);
  const [action, setAction] = useState("");
  const [proxy, setProxy] = useState<Proxy>({
    id: "",
    host: "",
    port: "",
    user: "",
    pwd: ""
  });
  const [showTroubleShootModal, setShowTroubleShootModal] = useState(false);
  const [showGmailConfirmationMailModal, setshowGmailConfirmationMailModal] = useState(false);
  const [show2StepVerificationModal, setshow2StepVerificationModal] = useState(false);
  const [subMessage, setSubMessage] = useState("");
  const [isVerifyEmailForGmail, setisVerifyEmailForGmail] = useState(false);
  const [showVerificationOptionsModal, setVerificationOptionsModal] = useState(false);
  const [Data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: ""
  });
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const [openTrackingModal, setOpenTrackingModal] = useState("");
  const [state, setState] = useState({ responseSkipModalState: false, responseSkipValue: "", selectedEmail: "", transferMailBoxState: false });
  const { responseSkipModalState, responseSkipValue, selectedEmail, transferMailBoxState } = state;
  // const [state, setState] = useState({
  //   responseSkipModalState: false, responseSkipValue: "", selectedEmail: "",
  //   // openDetailedSetup: false
  // });
  // const { responseSkipModalState, responseSkipValue, selectedEmail,
  //   // openDetailedSetup
  // } = state;
  const handleRecoveryEmailModal = () => setshowGmailConfirmationMailModal((show) => !show);
  const handleVerificationModal = () => setshow2StepVerificationModal((show) => !show);
  const [connectModalStep, setStepWhenConnectionModalClosed] = useState(0);
  const [isEditModeForWorkspacePersonal, setEditModeWhileProviderWorkspacePersonal] = useState(false);
  const [statusFilter, setStatusFilter] = useState("Active");
  const { userList } = useUserlistState();

  console.log("actionaction", action);
  const [showGsuiteMail, setShowGsuiteModal] = useState(false)
  // const [gsuiteSetupModlal, setOpenSetupModal] = useState(false)
  const [selectedMailbox, setSelectedMailbox] = useState<{
    email: string;
    id: null | string;
    settings?: MailBoxSettingsType;
    campaignName: string | null;
    provider?: string;
    protocol?: string;
  } | null>({
    email: "",
    id: null,
    campaignName: ""
  });
  const [settingModal, setSettingModal] = useState(true)
  console.log("showGsuiteMailshowGsuiteMail", showGsuiteMail)
  const localStorageValues = LocalStorage.getValue(OAUTH_STATE_LS_KEY);
  const getAccountName = useMemo((): any => {
    return location.state;
  }, []);
  useEffect(() => {
    if (getAccountName && Object.keys(getAccountName).length) {
      const localStore = localStorageValues;
      if (localStore?.provider) {
        setProviders(localStore?.provider);
      }
      setAccountData({
        accountId: getAccountName.accountId || String(localStorage.getItem("account_id")),
        accountName: getAccountName.name || String(localStorage.getItem("accountName"))
      });
      // localStorage.setItem("accountId", String(getAccountName.accountId));
      // localStorage.setItem("accountName", String(getAccountName.name));
      setSelectedMailbox({
        email: getAccountName?.email,
        campaignName: "",
        id: null,
        provider: providers || localStore?.provider
        // settings: {
        //   firstName: getAccountName?.firstname,
        //   lastName: getAccountName?.lastname,
        //   signature: "",
        //   maxEmails: 0,
        //   minEmails: 0,
        //   incrementPercent: 0,
        //   timeZone: "",
        //   startHour: 0,
        //   endHour: 0,
        //   days: [],
        //   email: "",
        //   autoForwardEmail: ""
        // }
      });
      setData((prevState) => ({
        ...prevState,
        lastName: getAccountName?.lastname,
        firstName: getAccountName?.firstname
      }));
    } else {
      setAccountData({
        accountId: String(localStorage.getItem("account_id")),
        accountName: String(localStorage.getItem("accountName"))
      });
    }
  }, [getAccountName, setAccountData]);

  // useEffect(() => {
  //   if (localStorageValues?.provider === MailboxTypeEnum.GSUITE) {
  //     setGsuiteSetupModal()
  //     setSettingModal(false)
  //   }
  // }, [localStorageValues?.provider])

  useEffect(() => {
    const navPaths: NavPathType[] = [];
    if (accountData) {
      navPaths.push(
        {
          label: accountData.accountName,
          linkTo: `/account/${accountData.accountId}/campaigns`
        },
        { label: "Mailboxes" }
      );
    }

    setNavPaths(navPaths);
    return () => setNavPaths([]);
  }, [setNavPaths, location, accountData]);

  const oauthState = useMemo(() => {
    const oauthLocalState: {
      state: string;
      action?: string;
    } | null = LocalStorage.getValue(OAUTH_STATE_LS_KEY);
    if (location.state?.addEmailState && oauthLocalState?.state && location.state?.addEmailState === oauthLocalState.state && !oauthLocalState.action) {
      return oauthLocalState.state;
    }
    return "";
  }, [location]);

  const [isSettingSmtp, setIsSettingSmtp] = useState(false);
  const isSelectedMailboxNew = useMemo(() => !!oauthState, [oauthState]);
  const showSettingsModal = useMemo(() => (isSelectedMailboxNew || selectedMailbox?.id || isSettingSmtp), [isSelectedMailboxNew, selectedMailbox, isSettingSmtp]);
  const handleVerificationOptionsModal = () => setVerificationOptionsModal((show) => !show);
  const handleTroubleShootModal = () => setShowTroubleShootModal((show) => !show);
  const handleReconnectProxyModal = () => setShowReconnect((show) => !show);
  const handleManualGmailLoginModal = () => setManualGmailLogin((show) => !show);
  const { Title } = Typography;
  const fetchInboxes = useCallback(async () => {
    setAreInboxesLoading(true);
    if (!isSelectedMailboxNew) {
      // setLoadingMessage("Fetching your mailboxes ...");
    }
    // const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl();
    let account_id = String(localStorage.getItem("account_id"));
    const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl(account_id);
    const response = await callApi({
      url,
      method
    });

    if (response.success) {
      setInboxes(response.data.inboxes);
    }
    // setLoadingMessage("");
    setAreInboxesLoading(false);
  }, [setLoadingMessage, callApi, setAreInboxesLoading, isSelectedMailboxNew]);

  const getDnsPublicDnsUrl = useCallback(
    async (email: string, password: string) => {
      let payload = {
        email: email,
        password: password,
        inboxProvider: "GOOGLE",
        test: false
      };
      let response;
      if (window.location && window.location.origin.includes("localhost")) {
        console.log("localhost called............");
        response = {
          success: true,
          data: {
            browserId: uuid(),
            publicDNS: "localhost",
            instanceId: "i-0864145ea18867fcb",
            proxy: {
              host: "135.148.125.52",
              id: uuid(),
              port: "8181",
              pwd: "vendisys@135",
              user: "vendisys"
            }
          }
        };
      } else {
        console.log("dev and live called............");
        const { url, method } = API_ENDPOINTS.DNSCONNECTION.getListUrl();
        response = await callApi({
          url,
          method,
          data: payload,
          external: true
        });
      }

      // let publicDns = 'https://brooklyn.outwinhq.io';
      let instanceId = "";
      let browserIds = "";
      let proxyServer: Proxy = {} as Proxy;
      if (response.success && response.data) {
        instanceId = response.data.instanceId;
        // publicDns = `https://brooklyn.outwinhq.io`;
        browserIds = response.data.browserId;
        setBrowserId(browserIds);
        if (response.data && response.data.proxy) {
          proxyServer = response.data?.proxy;
          setProxy(proxyServer);
          // proxyServer = {
          //   "host": "144.217.35.166",
          //   "port": "8181",
          //   "user": "vendisys",
          //   "pwd": "vendisys@135"
          // }
        }
        // setPublicDns(publicDns);
        setInstanceId(instanceId);
      } else {
        setLoadingMessage("");
        notification.error({
          message: "Server resources are currently being allocated. Please try again after 5 minutes."
        });
      }
      return { browserId: browserIds, instanceId, proxy: proxyServer };
    },
    [callApi, setProxy, setBrowserId, setInstanceId]
  );

  useEffect(() => {
    fetchInboxes();
  }, []);

  const onOptionSelectHandler = useCallback(
    async (formValues: any) => {
      setLoadingMessage("Connect to the Mailbox");
      if (!formValues) {
        notification.error({
          message: "Please select any one option"
        });
        setLoadingMessage("");
      } else {
        if (formValues === "recovery") {
          let data = {
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "G_recovery_email_option",
            browserId
          };
          await performTask(data);
        } else {
          let data = {
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "G_access_code_on_email_option",
            browserId
          };
          await performTask(data);
        }
      }
    },
    [setLoadingMessage, browserId, Data, instanceId]
  );
  const getTaskFromAPI = useCallback(
    (task: string) => {
      setLoadingMessage("");
      switch (task) {
        case "wrong_password":
          setisWrongPassword(true);
          notification.error({
            message: "Your Password is Wrong"
          });
          break;
        case "verify_email":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(false);
          setisVerifyEmail(true);
          setshowConnectEmailValidationModal(true);
          break;
        case "error_notify":
          if (errorMsg.length) {
            notification.error({
              message: errorMsg
            });
          }
          break;
        case "wrong_email_account":
          setisWrongEmail(true);
          if (errorMsg.length) {
            notification.error({
              message: errorMsg
            });
          }
          break;
        case "accountLock":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(true);
          if (errorMsg.length) {
            notification.error({
              message: errorMsg
            });
          }
          break;
        case "phoneNoCheckVerify":
          setshowConnectMailStmpModal(false);
          setshowTextVerifyModal(true);
          break;
        case "invalidPhoneNo":
          //setisWrongPassword(true);
          if (errorMsg.length) {
            notification.error({
              message: errorMsg
            });
          }
          break;
        case "update_password":
          setisWrongPassword(false);
          setShowAccessCodeModal(false);
          setshowUpdatePasswordModal(true);
          break;
        case "accessCode":
          setisEmailValidation(false);
          setshowConnectMicrosoftValidationl(false);
          setShowAccessCodeModal(true);
          break;
        case "code_screen":
          setisEmailValidation(true);
          setshowConnectEmailValidationModal(false);
          setShowAccessCodeModal(true);
          break;
        case "login_success":
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setisEmailValidation(false);
          setisVerifyEmail(false);
          setisVerifyEmailForGmail(false);
          setshowConnectMailStmpModal(false);
          setShowAccessCodeModal(false);
          setExtensionStatus("");
          setManualGmailLogin(false);
          setshowGmailConfirmationMailModal(false);
          if (action === "RECONNECT" || action === "UPDATE" || action === "VALIDATE") {
            if (action === "RECONNECT") showSucess('Reconnected successfully"');
            if (action === "UPDATE") showSucess("Password updated successfully");
            if (action === "VALIDATE") showSucess("Connection validated successfully");
            setIsSettingSmtp(false);
          } else {
            setIsSettingSmtp(true);
            setAction("");
          }
          break;
        case "gmail_recover_email":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(false);
          setVerificationOptionsModal(false);
          setisVerifyEmail(true);
          setisVerifyEmailForGmail(true);
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setshowGmailConfirmationMailModal(true);
          break;
        case "gamil_2_step_verification":
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(true);
          break;
        case "gmail_invalid_email":
          setisWrongEmail(true);
          setisWrongPassword(false);
          showError(errorMsg);
          break;
        case "gmail_process_again":
          setshowConnectMailStmpModal(true);
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(false);
          showError(errorMsg);
          break;
        case "gmail_wrong_password":
          setisWrongPassword(true);
          setisWrongEmail(false);
          showError(errorMsg);
          break;
        case "gamil_login_success":
          setshowConnectMailStmpModal(false);
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(false);
          setShowAccessCodeModal(false);
          setIsSettingSmtp(true);
          break;
        case "G_ChooseVerification":
          setisEmailValidation(false);
          setVerificationOptionsModal(false);
          setshowConnectMicrosoftValidationl(false);
          setShowAccessCodeModal(true);
          break;
        case "G_verification_options":
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setVerificationOptionsModal(true);
          break;
        case "unable_to_connect":
          showError("");
          setisWrongPassword(false);
          setisWrongEmail(false);
          setisEmailValidation(false);
          setisVerifyEmail(false);
          setisVerifyEmailForGmail(false);
          setshowConnectMailStmpModal(false);
          setShowAccessCodeModal(false);
          setshowGmailConfirmationMailModal(false);
          setShowTroubleShootModal(true);
          break;
        case "navigation_timeout":
          setExtensionStatus("installed");
          setManualGmailLogin(true);
          break;
        default:
          break;
      }
    },
    [task, action, errorMsg]
  );

  // useEffect(() => {
  //   console.log("localStorageValueslocalStorageValues", localStorageValues);
  //   if (!isEmpty(localStorageValues)) {
  //     const { email, provider } = localStorageValues;
  //     setSelectedMailbox({
  //       email,
  //       campaignId: campaignDetail?.id,
  //       campaignName: campaignDetail?.name,
  //       paused: false,
  //       id: null
  //     });
  //     providesType(provider);
  //   }
  // }, [campaignDetail]);

  const showError = (error: any) => {
    if (error && error.length) {
      notification.error({
        message: error
      });
    }
  };

  const showSucess = (msg: string) => {
    if (msg && msg.length) {
      notification.success({
        message: msg
      });
    }
  };

  useEffect(() => {
    if (task) {
      getTaskFromAPI(task);
    }
  }, [getTaskFromAPI, task, errorMsg]);

  const toggleMailBoxProviderDatialDialog = () => setShowMailBoxProviderDetailModal((prev) => !prev);

  const gotoBackButton = (isFromValidationModal?: boolean) => {
    // setShowAddMailboxModal((prev) => !prev);
    if (isFromValidationModal) {
      setShowMailBoxProviderDetailModal((prev) => !prev);
    }
  };

  const onMailboxProviderContinue = useCallback(
    async (provider: MailboxTypeEnum, email: string) => {
      console.log("hellllllllllllllllllll", provider, email);
      setLoadingMessage("Connecting to mailbox...");
      if (!getProviderDetails(provider)?.smtpImapDetailsKnown) {
        setShowMailBoxProviderDetailModal((prev) => !prev);
        setProviderType(provider);
        setLoadingMessage("");
        return;
      }
      if (getProviderDetails(provider)?.smtpImapDetailsKnown && (provider !== MailboxTypeEnum.OFFICE_OAUTH && provider !== MailboxTypeEnum.GSUITE)) {
        console.log("33333333333333")
        setLoadingMessage("");
        subform.resetFields();
        setShowConnectMailboxModal(true);
        return;
      }
      const state = uuid();
      // Persist state
      if (provider === MailboxTypeEnum.GSUITE) {
        const { url, method } = API_ENDPOINTS.OAUTH.getGoogleOauthUrl({
          provider: 'GOOGLE',
          state,
          reconnect: false
        });
        // Get oauth url
        console.log("222222222");
        const response = await Axios.callApi({
          method: method,
          url: `${WHISPER_BASE_URL}${url}`
        });
        if (response.success) {
          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            state,
            redirectTo: "/mailboxes",
            email,
            provider: provider
          });

          try {
            const { oauth_url } = response.data;
            console.log("oauth_urloauth_urloauth_url", oauth_url);
            window.location.assign(oauth_url);
          } catch (err) {
            notification.error({
              message: "Something went wrong!"
            });
            setLoadingMessage("");
          }
          return;
        }
      } else {
        const { url, method } = API_ENDPOINTS.OAUTH.getOauthUrl({
          provider,
          state
        });
        // Get oauth url
        console.log("222222222");
        const response = await Axios.callApi({
          method: method,
          url: `${WHISPER_BASE_URL}${url}`
        });
        // const response = await callApi({ url, method });

        if (response.success) {
          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            state,
            redirectTo: "/mailboxes",
            email,
            provider: provider
          });

          try {
            const { oauth_url } = response.data;
            window.location.assign(oauth_url);
          } catch (err) {
            notification.error({
              message: "Something went wrong!"
            });
            setLoadingMessage("");
          }
          return;
        }
      }


      setLoadingMessage("");
    },
    [callApi, setLoadingMessage]
  );

  const toggleShowModal = () => setShowAddMailboxModal((show) => !show);
  const toggleShowModalSmtp = () => setshowConnectMailStmpModal((show) => !show);
  const handleVerifyEmailByPhoneNo = () => setshowConnectMicrosoftValidationl((show) => !show);
  const toggleShowModalEmailValidation = () => setshowConnectEmailValidationModal((show) => !show);
  const handleVerifyAccessCodeModal = () => setShowAccessCodeModal((show) => !show);
  const toggleVerifyPhoneNoForm = () => setshowTextVerifyModal((show) => !show);
  const handleUpdatePasswordModal = () => setshowTextVerifyModal((show) => !show);

  const [showMailBoxProviderDetailModal, setShowMailBoxProviderDetailModal] = useState(false);
  const [providerType, setProviderType] = useState("");

  const updateSpotBrowser = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.DNSCONNECTION.getCreateUrl();
    let payload = { browserId: browserId };
    const response = await callApi({
      url,
      method,
      data: payload,
      external: false
    });

    if (response.success) {
      console.log("spot updated scuccessfully :::");
    } else {
      console.log("some thing going wrong.");
    }
  }, [browserId]);

  const createMailBox = useCallback(
    async (settings: MailBoxSettingsType) => {
      const {
        minEmails,
        maxEmails,
        incrementPercent,
        signature,
        timeZone,
        days,
        startHour,
        endHour,
        firstName,
        lastName,
        autoForwardEmail,
        replyTo,
        email
        // emailSendingHours
      } = settings;

      const { url, method } = API_ENDPOINTS.CAMPAIGNS.createMailBox();

      let payload = {};
      console.log("providerInfoproviderInfo",providers)

      const providerInfo = getProviderDetails(providers);
      console.log("providerInfoproviderInfo",providerInfo)
      if (providers !== MailboxTypeEnum.AMAZON) {
        payload = {
          minEmails,
          maxEmails,
          incrementPercent,
          signature,
          timeZone,
          days,
          startHour,
          endHour,
          firstname: settings.firstName ? settings.firstName : Data.firstName,
          lastname: settings.lastName ? settings.lastName : Data.lastName,
          accountId: String(localStorage.getItem("account_id")),
          email: Data.email || selectedMailbox?.email || email,
          username: Data.email || selectedMailbox?.email,
          password: Data.password,
          protocol: providerInfo?.protocol,
          provider: providerInfo?.providerName,
          autoForwardEmail,
          replyTo
        };
      } else {
        console.log("eeeeeeeeeeeeeeeeeeeeeee", providers);
        payload = {
          ...payload,
          state: oauthState
        };
      }
      setLoadingMessage("Setting up the mailbox... ...");

      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        LocalStorage.resetKey(OAUTH_STATE_LS_KEY);
        setIsSettingSmtp(false);
        if (!settingEditView) {
          setSelectedMailbox(null);
        }
        setLoadingMessage("");
        setShowConnectMailboxModal(false);
        setShowAddMailboxModal(false);
        history.replace("/mailboxes");

        // history.replace("/settings/mailbox");
        fetchInboxes();
        return;
      }

      setLoadingMessage("");
      if (providers === MailboxTypeEnum.OFFICE_BROWSER) updateSpotBrowser(); // todo: NC verify
    },
    [Data, callApi, action, fetchInboxes, settingEditView, history, oauthState, providers, setLoadingMessage, updateSpotBrowser]
  );

  const updateMailbox = useCallback(
    async (settings: MailBoxSettingsType, id) => {
      const {
        signature,
        incrementPercent,
        minEmails,
        maxEmails,
        days,
        timeZone,
        startHour,
        endHour,
        firstName,
        lastName,
        capacity,
        autoForwardEmail,
        email,
        replyTo
      } = settings;
      const { url, method } = API_ENDPOINTS.MAILBOX.getUpdatelUrl(id);
      console.log("4444444444444", settings);

      const payload = {
        accountId: String(localStorage.getItem("account_id")),
        incrementPercent,
        minEmails,
        maxEmails,
        signature,
        days,
        timeZone,
        startHour,
        endHour,
        firstname: firstName,
        lastname: lastName,
        capacity: capacity,
        autoForwardEmail,
        email,
        replyTo
      };

      setLoadingMessage("Updating your mailbox ...");

      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        if (!settingEditView) {
          setSelectedMailbox(null);
        }
        setLoadingMessage("");
        return;
      }

      setLoadingMessage("");
    },
    [callApi, settingEditView, setLoadingMessage]
  );

  const handleMailboxSettingsSave = useCallback(
    async (settings: MailBoxSettingsType) => {
      console.log("settingssettings", settings);
      if (!selectedMailbox?.id) {
        createMailBox(settings);
        return;
      }

      updateMailbox(settings, selectedMailbox.id);
    },
    [selectedMailbox, createMailBox, updateMailbox]
  );

  const handleEditClick = useCallback(
    async (id: string) => {
      setLoadingMessage("Fetching your mailbox settings ...");
      const { url, method } = API_ENDPOINTS.MAILBOX.getDetailUrl(id);

      const response = await callApi({
        url,
        method
      });

      if (response.success) {
        const {
          email,
          signature = "",
          incrementPercent = 20,
          minEmails,
          maxEmails,
          timezone,
          days,
          startHour,
          endHour,
          provider,
          firstname,
          lastname,
          protocol,
          campaignName,
          capacity,
          autoForwardEmail,
          replyTo
        } = response.data.inbox;

        setSelectedMailbox({
          email,
          id,
          provider,
          protocol,
          campaignName,
          settings: {
            incrementPercent,
            minEmails,
            maxEmails,
            signature,
            firstName: firstname,
            lastName: lastname,
            email,
            timeZone: timezone,
            days,
            startHour,
            endHour,
            capacity,
            autoForwardEmail,
            replyTo
            // emailSendingHours
          }
        });
        console.log(">>>>>>>>", firstname, lastname);
        mailBoxSettingsForm.setFieldsValue({
          firstName: firstname,
          lastName: lastname,
          email
        });
        setSettingModal(true)
        // setData({
        //   email: email,
        //   password: Password,
        //   lastName: Lastname,
        //   firstName: Firstname
        // })
        setLoadingMessage("");
        return;
      }
      setLoadingMessage("");
    },
    [setLoadingMessage, setSelectedMailbox, callApi]
  );

  const getOnEdit = (id: string) => () => handleEditClick(id);

  const handleDeleteClick = useCallback(
    async (id: string) => {
      setLoadingMessage("Deleting mailbox ...");

      const { url, method } = API_ENDPOINTS.MAILBOX.getDeletelUrl(id);

      const response = await callApi({
        url,
        method
      });
      setLoadingMessage("");
      if (response.success) {
        fetchInboxes();
        onCloseModal();
      }
    },
    [callApi, setLoadingMessage, setDeleteConfirm, deleteConfirm, fetchInboxes]
  );

  const onCloseModal = useCallback(() => {
    setSelectedMailbox(null);
    setIsSettingSmtp(false);
    setErrorMsg("");
    if (isSelectedMailboxNew) {
      history.replace("/mailboxes");
    }
  }, [setSelectedMailbox, isSelectedMailboxNew, history]);

  const onSettingsFormSubmit = useCallback(() => {
    mailBoxSettingsForm.submit();
  }, [mailBoxSettingsForm]);

  const resetSelectedEmailForIps = () => setSelectedEmailForIps("");
  const resetSelectedInboxIdForlogs = () => setselectedInboxIdForLogs("");

  const resetSelectedInboxIdForSendEmail = () => {
    setselectedInboxNameForSendEmail("");
    setselectedInboxEmailForSendEmail("");
  };

  const getOnViewIpsDetails = (email: string) => () => setSelectedEmailForIps(email);
  const getOnViewLogsDetails = (id: string) => () => setselectedInboxIdForLogs(id);
  const getOnSendEmail = (name: string, email: string) => () => {
    setselectedInboxNameForSendEmail(name);
    setselectedInboxEmailForSendEmail(email);
  };
  const callSendEmail = (name: string, email: string) => {
    setselectedInboxNameForSendEmail(name);
    setselectedInboxEmailForSendEmail(email);
  };
  const resetSelectedEmailChangePassword = () => {
    setselectedInboxIdForChangePassword("");
    setselectedInboxEmailForChangePassword("");
  };
  //id, email,provider,firstname,lastname
  const getOnChangePassword = (id: string, email: string) => () => {
    setselectedInboxIdForChangePassword(id);
    setselectedInboxEmailForChangePassword(email);
  };

  const getOnOpenTracking = (id: string) => () => {
    setOpenTrackingModal(id);
  };

  useEffect(() => {
    const callFn = async () => {
      if (localStorageValues?.action === "Reconnect" && localStorageValues?.redirectTo === "/mailboxes" && localStorageValues?.state) {
        console.log("localStorageValues?.mailId", localStorageValues?.mailId);
        const { url, method } = API_ENDPOINTS.CAMPAIGNS.reConnectMail(localStorageValues?.mailId);
        let data: any = {
          brooklynReconnect: false
        };
        const ress = await callApi({
          url,
          method,
          data
        });
        if (ress.success) {
          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            redirectTo: localStorageValues?.redirectTo,
            email: localStorageValues?.email,
            provider: localStorageValues?.provider
          });
        } else {
          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            redirectTo: localStorageValues?.redirectTo,
            email: localStorageValues?.email,
            provider: localStorageValues?.provider
          });
        }
      }
    };
    callFn();
  }, []);

  const updateCredentials = (id: any, action: string) => async () => {
    setErrorMsg("");
    setTask("");
    let fetchData = inboxes.filter((x) => x.id === id);
    console.log("fetchDatafetchData",fetchData)
    let { email, provider, firstname, lastname, protocol } = fetchData[0];
    console.log("updateCredentialsprovider", provider);
    console.log("getProviderDetails(provider)?.smtpImapDetailsKnown", provider, getProviderDetails(provider)?.smtpImapDetailsKnown);

    setData({
      email: email || "",
      password: "",
      lastName: lastname || "",
      firstName: firstname || ""
    });
    if (provider === "MICROSOFT") {
      setProviders(MailboxTypeEnum.OFFICE_SMTP);
    } else if (provider === "GOOGLE" && protocol === "BROWSER") {
      setProviders(MailboxTypeEnum.GMAILONE);
    }else if (provider === "GOOGLE" && protocol === "OAUTH") {
      setProviders(MailboxTypeEnum.GSUITE);
      setProviderType("GSUITE")
    } else {
      setProviders(provider);
    }
    setisEditMode(true);
    setAction(action);
    // isEditMode, setisEditMode

    if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
      const state = uuid();
      setLoadingMessage("Connecting to mailbix...");

      const { url, method } = API_ENDPOINTS.OAUTH.getOauthUrl({
        provider,
        state,
        email: "",
        reconnect: true
      });
      // Get oauth url
      console.log("222222222");
      const response = await Axios.callApi({
        method: method,
        url: `${WHISPER_BASE_URL}${url}`
      });
      if (response.success) {
        LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
          state,
          redirectTo: "/mailboxes",
          email,
          provider: provider,
          action: "Reconnect",
          mailId: id
        });

        try {
          const { oauth_url } = response.data;
          window.location.assign(oauth_url);
        } catch (err) {
          notification.error({
            message: "Something went wrong!"
          });
          setLoadingMessage("");
        }
        return;
      }
    } else if (provider === MailboxTypeEnum.OFFICE_SMTP) {
      setshowConnectMailStmpModal(true);
    }else if (provider === "GOOGLE" && protocol === "OAUTH") {
      setShowGsuiteModal(true);
    } else if (getProviderDetails(provider)?.smtpImapDetailsKnown) {
      console.log("ccccccccccccc");
      setShowConnectMailboxModal(true);
    } else {
      setShowMailBoxProviderDetailModal(true);
    }
    setEditableId(id);
    setLoadingMessage("");
  };

  const handleOnReconnect = (id: any, action: string) => () => {
    console.log("this is calling......");
    let fetchData = inboxes.filter((x) => x.id === id);
    let { email, provider, firstname, lastname, protocol } = fetchData[0];
    // console.log("provider", provider, protocol)
    setData({
      email: email || "",
      password: "",
      lastName: lastname || "",
      firstName: firstname || ""
    });
    if (provider === "MICROSOFT") {
      setProviders(MailboxTypeEnum.OFFICE_SMTP);
    } else if (provider === "GOOGLE" && protocol === "BROWSER") {
      setProviders(MailboxTypeEnum.GMAILONE);
    } else {
      setProviders(provider);
    }
    setisEditMode(true);
    setAction(action);
    // isEditMode, setisEditMode
    setShowConnectMailboxModal(true);
    // if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
    //   console.log("called........1.......")
    //   setshowConnectMailStmpModal(true);
    // } else if (getProviderDetails(provider)?.smtpImapDetailsKnown) {
    //   console.log("called........2.......")
    //   setShowConnectMailboxModal(true);
    // } else {
    //   console.log("called........3.......")
    //   setShowMailBoxProviderDetailModal(true);
    // }
    setEditableId(id);
    setLoadingMessage("");
  };

  const providesType = useCallback(async (type: any) => {
    setProviders(type);
  }, []);

  const connectClick = (providerType: any) => {
    console.log("callledddddddddddddddd", providers, getProviderDetails(providers), providerType);
    setisEditMode(false);
    if (providerType) {
      setProviderType(providerType)
    }
    if (providers === MailboxTypeEnum.OFFICE_SMTP) {
      mailBoxOfficeSmtp.resetFields();
      setshowConnectMailStmpModal(true);
    }
    if (providers === MailboxTypeEnum.OFFICE_BROWSER) {
      mailBoxOfficeSmtp.resetFields();
      setshowConnectMailStmpModal(true);
    } else if (providers === MailboxTypeEnum.OFFICE_OAUTH) {
      setShowConnectMailboxModal(false);
      mailBoxProviderForm.submit();
    } else if (providerType === MailboxTypeEnum.GSUITE) {
      setShowGsuiteModal(true);
      toggleShowModal()
      // mailBoxProviderForm.submit();
    } else if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
      subform.resetFields();
      setShowConnectMailboxModal(true);
    }
    else {
      setShowConnectMailboxModal(false);
      mailBoxProviderForm.submit();
    }
  };

  const submitPhoneNoToVerify = async (formValues: any) => {
    setLoadingMessage("verifying Phone Number.");
    const { phone, prefix } = formValues;
    let data = {
      email: Data.email,
      instanceId: instanceId,
      phoneNo: phone,
      countryCode: prefix,
      site: "OUTWIN",
      task: "phoneNoCheck"
    };
    await performTask(data);
  };

  const submitAccessCode = async (formValues: any) => {
    setLoadingMessage("verifying Access Code.");
    const { accessCode } = formValues;
    if (providers === MailboxTypeEnum.GMAILONE) {
      let data = {
        email: Data.email,
        instanceId: instanceId,
        accessCode: accessCode,
        site: "OUTWIN",
        task: "G_EmailVerificationCode",
        type: "browserGmail"
      };
      await performTask(data);
    } else {
      if (isEmailValidation) {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          accessCode: accessCode,
          site: "OUTWIN",
          task: "enterTheCode"
        };
        await performTask(data);
      } else {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          accessCode: accessCode,
          site: "OUTWIN",
          task: "enterAccessCode"
        };
        await performTask(data);
      }
    }
  };

  const submitMicroSoftEmail = useCallback(
    async (formValues: any) => {
      setLoadingMessage("verifying Email.");
      const { alternate_email } = formValues;
      if (isVerifyEmail) {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          alternate_email: alternate_email.split("@")[0],
          site: "OUTWIN",
          task: "verify_alterEmail"
        };

        await performTask(data);
      } else {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          alternate_email: alternate_email,
          site: "OUTWIN",
          task: "alternateEmail"
        };

        await performTask(data);
      }
    },
    [isVerifyEmail, setLoadingMessage]
  );

  useEffect(() => {
    subform.setFieldsValue({
      Email: Data.email,
      Password: Data.password,
      Firstname: Data.firstName,
      Lastname: Data.lastName
    });
  }, [Data]);

  const reconnectApi = useCallback(async (mailboxId: string) => {
    // setShowConnectMailboxModal(false)

    // notification.success({
    //   message: "calling reconnect API",
    // });

    const { url, method } = API_ENDPOINTS.CAMPAIGNS.reconnectMailBox(mailboxId);
    const payload = {
      brooklynReconnect: false
    };
    const reconnectResponse = await callApi({
      url,
      method,
      data: payload,
      external: false
    });

    if (reconnectResponse.success) {
      setShowConnectMailboxModal(false);
      notification.success({
        message: "Email Reconnected"
      });
      // setAction("")
    } else {
      notification.error({
        message: "Something went wrong"
      });
    }
  }, []);

  const getTask: any = useCallback(
    async (data: any, startTime: number = new Date().getTime(), currTime: number = new Date().getTime()) => {
      let { email, site, instanceId } = data;

      let payload = {
        email: email,
        site: site,
        instanceId: instanceId
      };
      const { url, method } = API_ENDPOINTS.OTHERS.browserBased(instanceId);
      const response = await callApi({
        url,
        method,
        data: payload,
        external: true
      });

      if (response && response.success) {
        const { data } = response;
        if (data && data.task && data.isSuccess) {
          // if (data.task === 'gamil_2_step_verification' && !data.showText) {
          //   await timeout(1000);
          //   return await getTask(payload)
          // }
          if (data.task === "login_success") {
            let textMsg = data.msg;
            if (textMsg) {
              textMsg = textMsg.split(" ");
              if (textMsg.length) {
                let firstName = textMsg.shift();
                let lastName = textMsg.join(" ");
                setData((prevState) => ({
                  ...prevState,
                  lastName: lastName,
                  firstName: firstName
                }));
                const payload = { email: email as string, provider: providers, id: null, campaignName: null };
                console.log("payloadpayloadpayload", payload)
                setSelectedMailbox(payload);
                // if (action === "RECONNECT" && editableId) {
                //   await reconnectApi(editableId)
                // }
              }
            }
          }
          setTask(data.task);
          setShowText("");
          setErrorMsg("");
        } else if (data && data.task && data.isError) {
          if (data.msg && data.msg.length) {
            setErrorMsg(data.msg);
          }
          if (data.showText && data.showText.length) {
            setShowText(data.showText);
          }
          setTask(data.task);
        } else {
          setTask("");
          setShowText("");
          setErrorMsg("");
          await timeout(1000);
          console.log(currTime - startTime);
          if (currTime - startTime <= 1.5 * 60 * 1000) {
            return await getTask(payload, startTime);
          } else {
            setLoadingMessage("");
            notification.error({
              message: "Something went wrong"
            });
          }
        }
        if (
          data.task === "gmail_recover_email" ||
          data.task === "G_ChooseVerification" ||
          data.task === "gamil_2_step_verification" ||
          data.task === "G_verification_options"
        ) {
          setSubMessage(data.showText);
        }
      }
    },
    [editableId, action, setTask, setShowText, callApi]
  );

  const timeout = (ms: any) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // console.log("editableId:::out:::", editableId)
  const performTask = useCallback(
    async (data: any) => {
      if (!data.proxy || !Object.keys(data.proxy).length) {
        data.proxy = proxy;
      }
      let payload = {
        ...data
      };
      if (!payload.browserId) {
        payload.browserId = browserId;
      }
      setTask("");
      // console.log("data 3", data);
      const { instanceId } = data;
      const { url, method } = API_ENDPOINTS.OTHERS.launchedBrowser(instanceId ? instanceId : "localhost");
      const response = await callApi({
        url,
        method,
        data: payload,
        external: true
      });
      if (response.success) {
        await getTask(data);
      } else {
        setTimeout(() => setLoadingMessage(""), 1000);
      }
    },
    [setTask, browserId, proxy, callApi]
  );

  const socketAndPerformLogin = useCallback(
    async (email: string, password: string, browserId: string, instanceId: string, proxy: Proxy) => {
      const data = {
        email: email,
        instanceId: instanceId,
        password: password,
        browserId: browserId,
        site: "OUTWIN",
        task: "login",
        proxy: proxy
      };
      await performTask(data);
      return;
    },
    [action, proxy, browserId, editableId]
  );

  const resetMailboxeSettings = () => {
    setisBoundConnection(false);
    setShowConnectMailboxModal(false);
    setShowMailBoxProviderDetailModal(false);
    setSmtpInBoundError("");
    setSmtpOutBoundError("");
    setisInBoundConnection(false);
    setisOutBoundConnection(false);
    setValidating(false);
    setisEditMode(false);
  };

  const onFinish = async (formValues: any) => {
    const { email, password, firstName, lastName } = formValues;
    console.log("callllllllllonFinishlllll", formValues, providers);
    if (providers) {
      setProviders(providers);
    }
    setData({
      email,
      password,
      lastName,
      firstName
    });
    if (providers === MailboxTypeEnum.GMAILONE) {
      if (isWrongPassword) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email,
          password,
          instanceId: instanceId,
          site: "OUTWIN",
          task: "reEnter_password",
          browserId
        };
        await performTask(data);
      } else if (isWrongEmail) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email,
          instanceId: instanceId,
          password,
          site: "OUTWIN",
          task: "reEnterLoginCredentials",
          browserId
        };
        await performTask(data);
      } else {
        setLoadingMessage("Connecting to mailbox...");
        const { browserId, instanceId, proxy } = await getDnsPublicDnsUrl(email, password);
        if (browserId && browserId.length && instanceId && instanceId.length) {
          setProviderType(providers);
          await socketAndPerformLogin(email, password, browserId, instanceId, proxy);
        }
      }
      return;
    } else {
      if (providers === MailboxTypeEnum.OFFICE_SMTP || providers === MailboxTypeEnum.OFFICE_OAUTH) {
        setshowConnectMailStmpModal(false);
      } else if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
        setShowConnectMailboxModal(false);
      } else {
        setShowMailBoxProviderDetailModal(false);
      }
      setisBoundConnection(true);
    }
    // updateCredentialsService(email, password);
    setLoadingMessage("");
  };

  useEffect(() => {
    if (!isBoundConnection) {
      setValidating(false);
      setSmtpInBoundError("");
      setisInBoundConnection(false);
      setisOutBoundConnection(false);
      setSmtpOutBoundError("");
      if (action === "UPDATE" || action === "VALIDATE" || action === "RECONNECT") {
        setIsSettingSmtp(false);
      }
    }
  }, [isBoundConnection]);

  const verifyPhoneNoForm = async (formValues: any) => {
    setLoadingMessage("Verifying your Phone No");
    const { phone, prefix } = formValues;
    let data = {
      email: Data.email,
      instanceId: instanceId,
      phoneNo: phone,
      site: "OUTWIN",
      task: "verifyPhoneNo"
    };
    await performTask(data);
  };

  const updatePassword = async (formValues: any) => {
    setLoadingMessage("Verifying your Phone No");
    const { Password } = formValues;
    let data = {
      email: Data.email,
      updatedPassword: Password,
      instanceId: instanceId,
      site: "OUTWIN",
      task: "enter_New_Password"
    };
    await performTask(data);
  };

  const verifyTwoStep = async () => {
    let data = {
      email: Data.email,
      instanceId: instanceId,
      site: "OUTWIN",
      task: "",
      browserId
    };
    await getTask(data);
  };

  const handlePasswordChange = () => {
    notification.success({
      message: "Password has been changed successfully.",
      description: ``
    });
  };

  const [cardPage, setCardPage] = useState({ page: 1, pageSize: 12 });

  let paginatedInboxes = useMemo(() => {
    const { page, pageSize } = cardPage;
    const startIdx = (page - 1) * pageSize;
    const endIdx = startIdx + pageSize;
    let copyInboxes = inboxes;
    if (statusFilter === "Active") {
      copyInboxes = copyInboxes.filter((vl) => vl?.campaignId);
    } else {
      copyInboxes = copyInboxes.filter((vl) => !vl?.campaignId);
    }
    if (mailboxSearchText === "") {
      copyInboxes = copyInboxes.slice(startIdx, endIdx);
    }
    // return inboxes.slice(startIdx, endIdx).
    //   filter(item => item.email.indexOf(mailboxSearchText) === 0);
    // return inboxes.slice(startIdx, endIdx).
    //   filter(item => item.email.toLowerCase().includes(mailboxSearchText.toLowerCase()));
    else {
      copyInboxes = copyInboxes.filter(
        (item) =>
          item.email.toLowerCase().includes(mailboxSearchText.toLowerCase()) ||
          (item?.firstname && item?.firstname.toLowerCase().includes(mailboxSearchText.toLowerCase())) ||
          (item?.lastname && item?.lastname.toLowerCase().includes(mailboxSearchText.toLowerCase()))
      );
    }
    return copyInboxes;
  }, [cardPage, inboxes, mailboxSearchText, statusFilter]);

  const searchMailboxes = (e: string) => {
    setMailboxSearchText(e);
  };

  const onCardPagination = useCallback((page, pageSize) => {
    setCardPage({ page, pageSize });
  }, []);

  const toggleMailboxValidating = () => {
    setisBoundConnection((prev) => !prev);
    if (providers === MailboxTypeEnum.OFFICE_SMTP || providers === MailboxTypeEnum.OFFICE_OAUTH) {
      setshowConnectMailStmpModal((prev) => !prev);
    } else if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
      setShowConnectMailboxModal((prev) => !prev);
      setStepWhenConnectionModalClosed(3);
    } else {
      setShowMailBoxProviderDetailModal((prev) => !prev);
    }
    setValidating(false);
    setSmtpInBoundError("");
    setSmtpOutBoundError("");
    setisInBoundConnection(false);
    setisOutBoundConnection(false);
  };

  const confirmRecoveryEmail = useCallback(
    async (formValues: any) => {
      setLoadingMessage("verifying Email.");
      const { alternate_email } = formValues;
      // gmailMailConfirmation.resetFields();
      if (isVerifyEmail) {
        if (isVerifyEmailForGmail) {
          let data = {
            alternate_email: alternate_email,
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "verify_recovery_email",
            browserId
          };
          await performTask(data);
        }
      }
    },
    [isVerifyEmail, Data, browserId, setLoadingMessage, isVerifyEmailForGmail]
  );

  const getShowBrooklynMenuItem = (protocol: string, provider: string) => {
    return protocol === MailboxProtocolEnum.SMTP_IMAP && provider === MailboxTypeEnum.GMAIL ? true : false;
  };

  const handleSettingViewStatus = (value: boolean) => {
    setSettingEditView(value);
  };

  const updateProxy = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.DNSCONNECTION.updateProxy(proxy.id);
    const response = await callApi({
      url,
      method,
      external: true
    });
    if (true) {
      // setProxy(response.data.proxy)
      const data = {
        email: Data.email,
        password: Data.password,
        instanceId: instanceId,
        site: "OUTWIN",
        task: "login",
        proxy: {
          host: "135.148.125.52",
          id: "86004e2b-03a8-4182-ae34-003da8f82ab0",
          port: "8181",
          pwd: "vendisys@135",
          user: "vendisys"
        }
      };
      await performTask(data);
    }
  }, [proxy, instanceId, Data, setProxy]);

  const detectExtension = (extensionId: string, callback: any) => {
    let s = document.createElement("script");
    s.onload = function () {
      callback(true);
    };
    s.onerror = function () {
      callback(false);
    };
    s.src = "chrome-extension://" + extensionId + "/js/control.js";
    document.body.appendChild(s);
  };

  const checkExtension = () => {
    return new Promise((resolve, reject) => {
      detectExtension("bpekpmdcgljafocafpmjjghmhfnlggah", (sss: any) => {
        console.log("callededddddddd", sss);
        resolve(sss);
      });
    });
  };

  useEffect(() => {
    if (extensionStatus === "check") {
      var datavalue = setInterval(async () => {
        let data: any = await checkExtension();
        console.log("datassssssssss", data);
        if (data) {
          setInstallExtension(true);
          clearInterval(datavalue);
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(datavalue);
      }, 180000);
    } else if (extensionStatus === "installed") {
      const checkFn = async () => {
        let data: any = await checkExtension();
        console.log("else if", data);
        if (data) {
          setInstallExtension(true);
        }
      };
      checkFn();
    } else {
    }
    return () => {
      setExtensionStatus("");
    };
  }, [extensionStatus]);

  const handleInstallExtension = useCallback(async () => {
    // const url = "https://chrome.google.com/webstore/detail/lighthouse/blipmdconlkpinefehnmjammfjpmpbjk"
    const url = "https://chrome.google.com/webstore/";
    window.open(url, "_blank", "noopener,noreferrer");
    setExtensionStatus("check");
  }, []);

  const getCookie = (name: string) => {
    const pattern = RegExp(name + "=.[^;]*");
    const matched = document.cookie.match(pattern);
    if (matched) {
      const cookie = matched[0].split("=");
      return cookie[1];
    }
    return false;
  };

  const clearCookies = () => {
    document.cookie = "brooklyn_inbox_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "brooklyn_inbox_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  const handleGoogleLogin = () => {
    clearCookies();
    const sessionId = uuid();
    document.cookie = `brooklyn_site=${window.origin}; path=/`;
    document.cookie = "brooklyn_app=Emy; path=/";
    document.cookie = `brooklyn_session_id=${sessionId}; path=/`;
    const redirectUrl = "https://accounts.google.com/AddSession?service=mail&passive=truecontinue=https://mail.google.com/mail";
    window.open(redirectUrl, "_blank", "noopener,noreferrer");

    var datavalue = setInterval(() => {
      let cookieValue = getCookie("brooklyn_inbox_name");
      if (cookieValue) {
        const fullName: any = cookieValue.split(" ");
        if (fullName.length) {
          setData((prevState) => ({
            ...prevState,
            firstName: fullName.shift(),
            lastName: fullName.join(" ")
          }));
        }
        setIsSettingSmtp(true);
        getTaskFromAPI("login_success");
        clearCookies();
        clearInterval(datavalue);
      }
    }, 1500);

    setTimeout(() => {
      clearInterval(datavalue);
    }, 300000);
  };

  useEffect(() => {
    if (isEditMode) subform.setFieldsValue(Data);
  }, [subform, Data, isEditMode]);

  useEffect(() => {
    if (isEditMode) mailBoxOfficeSmtp.setFieldsValue(Data);
  }, [mailBoxOfficeSmtp, Data, isEditMode]);

  const responseSkip = async (email: string) => {
    if (!email) {
      return;
    }
    // const { url, method } = API_ENDPOINTS.MAILBOX.getResponseSkipCodes(inboxId);
    const response = await Axios.callApi({
      method: "get",
      url: `https://api.whisper.email/inbox/${email}/skip-response-codes`,
      headers: {}
    });
    if (response?.success) {
      setState((prev) => ({ ...prev, selectedEmail: email, responseSkipValue: response?.data?.codes.join("\n"), responseSkipModalState: true }));
    }
  };

  const onChangeResponseSkip = (value: string) => {
    setState((prev) => ({ ...prev, responseSkipValue: value }));
  };

  const saveResponseSkipCodes = async () => {
    if (!isEmpty(selectedEmail)) {
      let addedCodes = responseSkipValue ? responseSkipValue?.split("\n") : [];
      addedCodes = addedCodes.filter((str) => !isEmpty(str.trim()));
      const response = await Axios.callApi({
        method: "put",
        url: `https://api.whisper.email/inbox/${selectedEmail}/skip-response-codes`,
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          codes: addedCodes
        })
      });
      if (response?.success) {
        setState((prev) => ({ ...prev, responseSkipModalState: false, responseSkipValue: "", selectedEmail: "" }));
      }
    }
  };

  const handleModal = () => {
    setState((prev) => ({ ...prev, responseSkipModalState: false, responseSkipValue: "", selectedEmail: "" }));
  };
  const setEmail = (email: string) => {
    console.log("setEmailsetEmail", email)

    setSelectedMailbox((prev: any) => ({
      ...prev,
      email: email || ""
    }));
    setData((prevState) => ({
      ...prevState,
      email
    }));
    setEditModeWhileProviderWorkspacePersonal(true);
  };

  const calculateIps = async (mailboxId: string) => {
    setLoadingMessage("fetching your mailbox IPS");

    const { url, method } = API_ENDPOINTS.MAILBOX.calculateIps(mailboxId);
    const response = await callApi({
      url,
      method
    });
    console.log("responseresponse", response);
    if (response.success) {
      notification.success({
        message:
          "IPS calculation process has been initiated. It may take about 1-2 minutes to complete this process. To check the latest IPS score, refresh this mailbox."
      });
      setLoadingMessage("");
    }
  };

  const onChangeStatusFilter = (newStatusFilter: string) => {
    setStatusFilter(newStatusFilter);
  };
  let inboxess = inboxes && statusFilter === "Active" ? inboxes.filter((vl) => vl?.campaignId) : inboxes.filter((vl) => !vl?.campaignId);

  const setGsuiteSetupModal = () => {
    // setOpenSetupModal(true)
    setShowGsuiteModal(false)
  }
  // const instantSetup = () => {
  //   setOpenSetupModal(false)
  //   createMailBox({
  //     "incrementPercent": 20,
  //     "minEmails": 100,
  //     "maxEmails": 1000,
  //     "signature": "",
  //     "days": [
  //       1,
  //       2,
  //       3,
  //       4,
  //       5,
  //       6,
  //       7
  //     ],
  //     "timeZone": String(localStorage.getItem("accountTimezone")) || "America/New_York",
  //     "startHour": 8,
  //     "endHour": 17,
  //     "firstName": Data?.firstName || '',
  //     "lastName": Data?.lastName || '',
  //     "capacity": 100,
  //     "autoForwardEmail": "",
  //     "email": selectedMailbox?.email || Data?.email,
  //     "replyTo": ""
  //   })
  // }

  // const detailedSetup = () => {
  //   // setState((prev) => ({ ...prev, openDetailedSetup: true }))
  //   setSettingModal(true)
  //   setOpenSetupModal(false)
  //   LocalStorage.resetKey(OAUTH_STATE_LS_KEY)
  //   setSelectedMailbox((prev: any) => ({
  //     ...prev,
  //     provider: "GOOGLE" || ""
  //   }));
  // }
  console.log("selectedMailboxselectedMailbox",selectedMailbox)
  return (
    <>
      <Flex style={{ flexDirection: "row" }} justify="flex-between">
        <Flex justify="flex-start" mr="auto">
          <Tabs defaultActiveKey={statusFilter} onChange={onChangeStatusFilter}>
            <Tabs.TabPane
              tab={
                <span>
                  <MailOutlined />
                  Active Mailboxes
                </span>
              }
              key="Active"
            />
            <Tabs.TabPane
              tab={
                <span>
                  <MailOutlined />
                  Inactive Mailboxes
                </span>
              }
              key="Inactive"
            />
          </Tabs>
        </Flex>
        <Flex justify="flex-end" mt={10} mb={20} gap={15} align="center">
          {areInboxesLoading ? (
            <>
              <Skeleton.Button active style={{ width: 150 }} />
              <Skeleton.Button active style={{ width: 120 }} />
              <Skeleton.Button active style={{ width: 130 }} />
            </>
          ) : (
            !!inboxes.length && (
              <>
                <Pagination
                  size="small"
                  defaultPageSize={cardPage.pageSize}
                  current={cardPage.page}
                  hideOnSinglePage
                  total={inboxess.length}
                  onChange={onCardPagination}
                />
                <Flex>
                  <Input
                    placeholder="Search by email address, Last name, First name"
                    style={{ width: 200 }}
                    value={mailboxSearchText}
                    onChange={(e) => searchMailboxes(e.target.value)}
                  />
                </Flex>
                <MailboxToggleView active={mailboxViewMode} onChange={setMailboxViewMode} />
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    toggleShowModal();
                    setisEditMode(false);
                    setEditableId("");
                    setAction("");
                    setSelectedMailbox(null);
                  }}
                >
                  Add Mailbox
                </Button>
                {Array.isArray(userList) && userList?.length > 0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setState((prev) => ({ ...prev, transferMailBoxState: true }));
                    }}
                  >
                    Transfer
                  </Button>
                )}
              </>
            )
          )}
        </Flex>
      </Flex>
      {mailboxViewMode === MailboxViewEnum.CARD ? (
        <Row gutter={[10, 10]}>
          {(areInboxesLoading ? Array(8).fill(0) : paginatedInboxes).map(
            ({ email, id, queued, capacity, sent, pictureUrl, firstname = "", lastname = "", ips, provider, protocol, campaignId, campaignName }, idx) => (
              <Col span={6} key={id ?? idx}>
                <MailBoxCard
                  type={provider}
                  email={email}
                  queuedMails={queued}
                  sentMails={sent}
                  capacity={capacity}
                  onEdit={getOnEdit(id)}
                  changePassword={updateCredentials(id, "UPDATE")}
                  handleConnectionValidate={updateCredentials(id, "VALIDATE")}
                  showBrooklynMenuItem={() => getShowBrooklynMenuItem(protocol, provider)}
                  onReconnect={updateCredentials(id, "RECONNECT")}
                  // changePassword={protocol === MailboxProtocolEnum.SMTP_IMAP ? getOnChangePassword(id, email) : 'e'}
                  onDelete={() => setDeleteConfirm(() => ({ modalVisible: true, data: { id, email } }))}
                  imageUrl={pictureUrl}
                  name={`${firstname} ${lastname}`}
                  ips={ips}
                  onViewIpsDetails={getOnViewIpsDetails(email)}
                  onViewLogsDetails={getOnViewLogsDetails(id)}
                  onSendEmail={getOnSendEmail(`${firstname} ${lastname}`, email)}
                  onOpenTracking={getOnOpenTracking(email)}
                  loading={areInboxesLoading}
                  responseSkip={() => responseSkip(email)}
                  campaignId={campaignId}
                  campaignName={campaignName}
                  protocol={protocol}
                  calculateIps={() => calculateIps(id)}
                />
              </Col>
            )
          )}
        </Row>
      ) : (
        <Row gutter={[10, 10]} style={{ paddingBottom: "20px" }}>
          <Col span={24}>
            <MailboxTableView
              dataSource={paginatedInboxes}
              loading={areInboxesLoading}
              pagination={false}
              pageSize={10}
              onEdit={(record) => getOnEdit(record.id)()}
              onViewIpsDetails={(record) => getOnViewIpsDetails(record.email)()}
              showBrooklynMenuItem={(record) => getShowBrooklynMenuItem("SMTP_IMAP", record.provider)}
              // changePassword={(record: any) => getOnChangePassword(record.id, record.email)()}
              handleConnectionValidate={(record: any) => updateCredentials(record.id, "VALIDATE")()}
              changePassword={(record: any) => updateCredentials(record.id, "UPDATE")()}
              onViewLogsDetails={(record) => getOnViewLogsDetails(record.id)()}
              onSendEmail={(record) => {
                getOnSendEmail(`${record.firstname} ${record.lastname}`, record.email)();
              }}
              onDelete={({ id, email }) => setDeleteConfirm(() => ({ modalVisible: true, data: { id, email } }))}
              onReconnect={(record: any) => updateCredentials(record.id, "RECONNECT")()}
              responseSkip={({ email }) => responseSkip(email)}
            />
          </Col>
        </Row>
      )}
      {/* {Array.isArray(paginatedInboxes) && isEmpty(paginatedInboxes) && `No ${statusFilter} mails`} */}
      {deleteConfirm.modalVisible && (
        <Modal
          visible
          okButtonProps={{
            type: "primary",
            danger: true,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={() => setDeleteConfirm((st) => ({ ...st, modalVisible: false }))}
          onOk={() => {
            setDeleteConfirm((st) => ({ ...st, modalVisible: false }));
            handleDeleteClick(deleteConfirm.data.id);
          }}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <Text variant="body2">
            You are about to remove{" "}
            <Text variant="body2" bold>
              {deleteConfirm.data.email}
            </Text>{" "}
            <Text variant="body2">
              mailbox from EMY. If any of the campaigns are currently using this mailbox, this mailbox won't be removed and an error message will be displayed.
            </Text>
            <Text variant="body2">Would you like to proceed?</Text>
          </Text>
        </Modal>
      )}
      {!inboxes.length && !areInboxesLoading && <EmptyMailboxPlaceholder onAdd={toggleShowModal} />}
      {showAddMailboxModal && (
        <Modal visible footer={null} maskClosable={false} onCancel={toggleShowModal} width={700} centered>
          <MailBoxProvider
            onContinue={onMailboxProviderContinue}
            form={mailBoxProviderForm}
            setProviders={providesType}
            submitBtn={connectClick}
            setEmail={setEmail}
          />
          {/* <Flex justify="flex-end">
            <Button type="primary" onClick={connectClick}>
              Connect
            </Button>
          </Flex> */}
        </Modal>
      )}
      {showMailBoxProviderDetailModal && (
        <MailBoxProviderDetailModal
          showAddMailbox={setShowMailBoxProviderDetailModal}
          setShowMailBoxProviderModal={setShowAddMailboxModal}
          fetchInboxes={fetchInboxes}
          onDismiss={toggleMailBoxProviderDatialDialog}
          provider={providers}
          isEditMode={isEditMode}
          mailId={editableId}
          editableData={inboxes.find((x) => x.id === editableId)}
          isNewCampFlow={true}
          backButton={gotoBackButton}
          action={action}
        />
      )}
      {showSettingsModal && selectedMailbox ? (
        <Modal visible footer={null} centered onCancel={onCloseModal} className="modal-mainwrap">
          <MailBoxSettings
            onSave={handleMailboxSettingsSave}
            email={selectedMailbox?.email}
            id={selectedMailbox?.id}
            campaignName={selectedMailbox?.campaignName ? selectedMailbox?.campaignName : ``}
            settings={selectedMailbox?.settings}
            form={mailBoxSettingsForm}
            provider={selectedMailbox?.provider ==='GSUITE' ?'GOOGLE':selectedMailbox?.provider}
            protocol={selectedMailbox?.provider ==='GSUITE' ?"OAUTH":selectedMailbox?.protocol || providerType}
            formValues={Data}
            // mailboxDelinking={mailboxDelinking}
            settingEditViewMode={handleSettingViewStatus}
            validateCredentails={updateCredentials(selectedMailbox?.id, "VALIDATE")}
            updateCredentails={updateCredentials(selectedMailbox?.id, "UPDATE")}
            onDelete={(id, email) => setDeleteConfirm(() => ({ modalVisible: true, data: { id, email } }))}
            onViewIpsDetails={setSelectedEmailForIps}
            onViewLogsDetails={getOnViewLogsDetails(selectedMailbox?.id || "")}
            onOpenTracking={(email) => setOpenTrackingModal(email)}
            onSendEmail={(name, email) => callSendEmail(name, email)}
            onReconnect={updateCredentials(selectedMailbox?.id, "RECONNECT")}
            showSkipCodes
            responseSkip={() => responseSkip(selectedMailbox?.email)}
          />
          <Flex mt={20} justify="flex-end">
            <Button type="primary" onClick={onSettingsFormSubmit}>
              Save
            </Button>
          </Flex>
        </Modal>
      ) : null}
      {!!selectedInboxIdForChangePassword && (
        <ChangePasswordModal
          onCancel={resetSelectedEmailChangePassword}
          onOk={handlePasswordChange}
          id={selectedInboxIdForChangePassword}
          mailboxEmail={selectedInboxEmailForChangePassword}
        />
      )}
      {!!selectedEmailForIps && <IpsDetailsModal onCancel={resetSelectedEmailForIps} email={selectedEmailForIps} />}
      {!!selectedInboxIdForLogs && <LogDetailsModal onCancel={resetSelectedInboxIdForlogs} id={selectedInboxIdForLogs} logType="Inbox" />}
      {!!openTrackingModal && <OpenTrackingModal inbox={openTrackingModal} onCloseModal={() => setOpenTrackingModal("")} />}
      {!!selectedInboxNameForSendEmail && !!selectedInboxEmailForSendEmail && (
        <SendEmailModal onCancel={resetSelectedInboxIdForSendEmail} email={selectedInboxEmailForSendEmail} name={selectedInboxNameForSendEmail} />
      )}
      {!!openTrackingModal && <OpenTrackingModal inbox={openTrackingModal} onCloseModal={() => setOpenTrackingModal("")} />}

      {/* {console.log('xxxx', getProviderDetails(providers), showConnectMailboxModal)} */}
      {getProviderDetails(providers)?.name === "Google (SMTP)" && getProviderDetails(providers)?.subText === "Workspace" ? (
        <>
          {!!showConnectMailboxModal && (
            <GmailIMAPLoginModal
              handleOpenClose={() => {
                setErrorMsg("");
                // setSelectedMailbox(null);
                setShowConnectMailboxModal(!showConnectMailboxModal);
                setEditModeWhileProviderWorkspacePersonal(false);
                setProviders("");
              }}
              handleSubmit={onFinish}
              formData={Data}
              isEditMode={isEditMode}
              connectModalStep={connectModalStep}
              isEditModeForWorkspacePersonal={isEditModeForWorkspacePersonal}
            />
          )}
        </>
      ) : (
        <>
          {!!showConnectMailboxModal && (
            <Modal
              visible
              footer={null}
              onCancel={() => {
                setData({ email: "", password: "", firstName: "", lastName: "" });
                setShowConnectMailboxModal(false);
                setEditModeWhileProviderWorkspacePersonal(false);
              }}
              width={600}
              centered
            >
              <Form
                scrollToFirstError
                form={subform}
                requiredMark={false}
                onFinish={onFinish}
                initialValues={
                  isEditMode
                    ? Data
                    : isEditModeForWorkspacePersonal
                      ? {
                        email: Data?.email,
                        password: "",
                        firstName: "",
                        lastName: ""
                      }
                      : { email: "", password: "", firstName: "", lastName: "" }
                }
              >
                <Text variant="h6" mb={5} ml={10} mt={20} color={"secondary"}>
                  {"Google Setup"}
                </Text>
                <Flex style={{ flexDirection: "row" }}>
                  <DetailWrapper>
                    <DetailHeading>Email *</DetailHeading>
                    <Form.Item
                      name={"email"}
                      rules={[
                        {
                          type: "email",
                          message: "Please enter valid email"
                        },
                        {
                          required: true,
                          message: "Please enter email"
                        }
                      ]}
                    // initialValue={isEditMode ? Data.email : ""}
                    >
                      <Input disabled={isEditMode ? true : false} tabIndex={1} className={classes.inputStyle} size="middle" />
                    </Form.Item>
                  </DetailWrapper>
                  <DetailWrapper>
                    <DetailHeading>Password *</DetailHeading>
                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: "Please write password"
                        }
                      ]}
                      // initialValue={isEditMode ? Data.password : ""}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input.Password
                        tabIndex={2}
                        autoComplete="new-password"
                        style={{
                          width: 250,
                          borderWidth: 0,
                          paddingLeft: 2,
                          boxShadow: "none",
                          borderRight: "none",
                          borderBottom: `1px solid ${DARK_GRAY_COLOR}`
                        }}
                        size="middle"
                      />
                    </Form.Item>
                  </DetailWrapper>
                </Flex>
                <Flex direction="row" ml={10}>
                  <p className="fs-12" style={{ marginBottom: "15px" }}>
                    {smtpValidationError}
                  </p>
                </Flex>
                <Flex direction="row" ml={10} justify="flex-end">
                  <Flex>
                    <Button
                      onClick={() => {
                        setData({ email: "", password: "", firstName: "", lastName: "" });
                        setShowConnectMailboxModal(false);
                      }}
                    >
                      Back
                    </Button>
                  </Flex>
                  <Flex ml={10}>
                    <Button htmlType="submit" type="primary">
                      Validate
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            </Modal>
          )}
        </>
      )}

      {getProviderDetails(providers)?.name === "Google (OAUTH)" && getProviderDetails(providers)?.subText === "Workspace" ? (
        <>
          {!!showGsuiteMail && (
            <GsuiteLoginModal
              handleOpenClose={() => {
                setProviders("");
                setShowGsuiteModal(false)
              }}
              handleSubmit={onFinish}
              formData={Data}
              connectModalStep={connectModalStep}
              setOpenSetupModal={() => setGsuiteSetupModal()}
              onContinue={onMailboxProviderContinue}
              providerType={providerType}
            />
          )}
        </>)
        : null}
      {!!showConnectMailStmpModal && (
        <Modal
          visible
          footer={null}
          onCancel={() => {
            toggleShowModalSmtp();
            setProviders("");
          }}
          width={600}
          centered
        >
          <Form
            scrollToFirstError
            form={mailBoxOfficeSmtp}
            requiredMark={false}
            onFinish={onFinish}
            initialValues={isEditMode ? Data : { email: "", password: "" }}
          >
            <Text variant="h6" mb={5} ml={10} mt={20} color={"secondary"}>
              {"Microsoft"} {/* {"Gmail"}{" "} */}
              setup
            </Text>
            <Flex style={{ flexDirection: "row" }}>
              <DetailWrapper>
                <DetailHeading>Email *</DetailHeading>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter valid email"
                    },
                    {
                      required: true,
                      message: "Please enter email"
                    }
                  ]}
                // initialValue={isEditMode ? Data.email : ""}
                >
                  <Input disabled={isEditMode ? true : false} tabIndex={1} className={classes.inputStyle} size="middle" />
                </Form.Item>
              </DetailWrapper>
              <DetailWrapper>
                <DetailHeading>Password *</DetailHeading>

                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please write password"
                    }
                  ]}
                  // initialValue={isEditMode ? Data['password'] : ""}
                  style={{ marginLeft: "5px" }}
                >
                  <Input.Password
                    tabIndex={2}
                    autoComplete="new-password"
                    style={{
                      width: 250,
                      borderWidth: 0,
                      paddingLeft: 2,
                      boxShadow: "none",
                      borderRight: "none",
                      borderBottom: `1px solid ${DARK_GRAY_COLOR}`
                    }}
                    size="middle"
                  />
                </Form.Item>
              </DetailWrapper>
            </Flex>
            <Flex direction="row" ml={10}>
              <p className="fs-12" style={{ marginBottom: "15px" }}>
                {smtpValidationError}
              </p>
            </Flex>
            <Flex direction="row" ml={10} justify="flex-end">
              <Flex>
                <Button
                  onClick={() => {
                    setshowConnectMailStmpModal(false);
                    setProviders("");
                  }}
                >
                  Back
                </Button>
              </Flex>
              <Flex ml={10}>
                <Button htmlType="submit" type="primary">
                  Validate
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      )}
      {!!showConnectEmailValidationModal && (
        <Modal visible footer={null} onCancel={() => toggleShowModalEmailValidation()} width={300} centered>
          <Form scrollToFirstError form={alernateEmailValidation} requiredMark={false} onFinish={submitMicroSoftEmail}>
            {/* <p style={{ fontSize: '12px', marginBottom: '15px' }}>ddd.outwin@gmail.com</p> */}
            <Title level={5}>Help us protect your account</Title>
            <p className="fs-12" style={{ marginBottom: "15px" }}>
              Passwords can be forgotten or stolen. Just in case, add security info now to help you get back into your account if something goes wrong. We won't
              use this info to spam you—just to keep your account more secure.
            </p>
            <Div>
              <label className="fs-12 fw-600">Email *</label>
              <Form.Item
                name={"alternate_email"}
                rules={[
                  {
                    type: "email",
                    message: "Please enter valid email"
                  },
                  {
                    required: true,
                    message: "Please enter email"
                  }
                ]}
              >
                <Input tabIndex={1} className={classes.inputStyle} size="middle" />
              </Form.Item>
              {isVerifyEmail ? (
                <p className="fs-12" style={{ marginBottom: "10px" }}>
                  We'll send a security code to your email <strong>{showText.split("Email")[1]}</strong> address in the next step.
                </p>
              ) : (
                <p className="fs-12" style={{ marginBottom: "10px" }}>
                  We'll send a security code to your alternate email address in the next step.
                </p>
              )}
            </Div>
            <Flex direction="row" ml={10} justify="flex-end">
              <Flex ml={10}>
                <Button htmlType="submit" type="primary">
                  Next
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      )}
      {!!showConnectMicrosoftValidation && (
        <VerifyEmailByPhoneNoModal submitPhoneNoToVerify={submitPhoneNoToVerify} handleVerifyEmailByPhoneNo={handleVerifyEmailByPhoneNo} />
      )}
      {!!showAccessCodeModal && (
        <VerifyAccessCodeModal subMessage={subMessage} submitAccessCode={submitAccessCode} handleVerifyAccessCodeModal={handleVerifyAccessCodeModal} />
      )}
      {!!showTextVerifyModal && (
        <VerifyPhoneNoModal showText={showText} verifyPhoneNoForm={verifyPhoneNoForm} toggleVerifyPhoneNoForm={toggleVerifyPhoneNoForm} />
      )}
      {!!showUpdatePasswordModal && (
        <UpdatePasswordModal showText={showText} updatePassword={updatePassword} handleUpdatePasswordModal={handleUpdatePasswordModal} />
      )}
      {isBoundConnection && (
        <BoundConnectionModal
          isEnable={isBoundConnection}
          toggleMailboxValidating={toggleMailboxValidating}
          validating={validating}
          isInBoundConnection={isInBoundConnection}
          smtpInBoundError={smtpInBoundError}
          isOutBoundConnection={isOutBoundConnection}
          smtpOutBoundError={smtpOutBoundError}
          editableId={editableId}
          providers={providers}
          setValidating={setValidating}
          setisInBoundConnection={setisInBoundConnection}
          setSmtpInBoundError={setSmtpInBoundError}
          setisOutBoundConnection={setisOutBoundConnection}
          setSmtpOutBoundError={setSmtpOutBoundError}
          isEditMode={isEditMode}
          action={action}
          resetMailboxeSettings={resetMailboxeSettings}
          setIsSettingSmtp={setIsSettingSmtp}
          settingEditView={settingEditView}
          setSelectedMailbox={setSelectedMailbox}
          fromValue={{ email: Data?.email, password: Data?.password }}
        />
      )}
      {!!showGmailConfirmationMailModal && (
        <RecoveryEmailModal subMessage={subMessage} handleRecoveryEmailModal={handleRecoveryEmailModal} confirmRecoveryEmail={confirmRecoveryEmail} />
      )}
      {!!show2StepVerificationModal && (
        <VerficationModal subMessage={subMessage} verifyTwoStep={verifyTwoStep} handleVerificationModal={handleVerificationModal} />
      )}
      {!!showVerificationOptionsModal && (
        <VerificationOptionsModal
          subMessage={subMessage}
          handleVerificationOptionsModal={handleVerificationOptionsModal}
          onOptionSelectHandler={onOptionSelectHandler}
        />
      )}
      {!!showTroubleShootModal && <TroubleShootModal proxyDetail={proxy} handleTroubleShootModal={handleTroubleShootModal} />}
      {!!showReconnect && <ReconnectProxyModal handleReconnectProxyModal={handleReconnectProxyModal} updateProxy={updateProxy} />}
      {!!showManualGmailLogin && (
        <ManualGmailLoginModal
          handleManualGmailLoginModal={handleManualGmailLoginModal}
          installExtension={installExtension}
          handleInstallExtension={handleInstallExtension}
          handleGoogleLogin={handleGoogleLogin}
        />
      )}
      {responseSkipModalState && (
        <ReponseSkipModal
          responseSkipValue={responseSkipValue}
          modalState={responseSkipModalState}
          saveResponseSkipCodes={saveResponseSkipCodes}
          handleModal={handleModal}
          onChangeResponseSkip={onChangeResponseSkip}
        />
      )}
      {transferMailBoxState && (
        <MailTransferModal
          closeModal={() => setState((prev) => ({ ...prev, transferMailBoxState: false }))}
          mailboxList={inboxes?.filter((vl) => !vl?.campaignId)}
          refreshMailboxesList={fetchInboxes}
        />
      )}

     {/* {!!gsuiteSetupModlal && (
        <GsuiteSetupModal
          handleOpenClose={() => {
            setProviders("");
            setOpenSetupModal(false)
            LocalStorage.resetKey(OAUTH_STATE_LS_KEY)
          }}
          handleSubmit={onFinish}
          formData={Data}
          // setOpenSetupModal={() => setOpenSetupModal(true)}
          instantSetup={instantSetup}
          detailedSetup={detailedSetup}
        />
      )} */}
      {/* {openDetailedSetup &&
        <Modal visible footer={null} centered onCancel={() => setState((prev) => ({ ...prev, openDetailedSetup: false }))} className="modal-mainwrap">
          <MailBoxSettings form={gsuiteDetailsForm} provider={"GOOGLE"} email={Data.email} formValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }} onSave={onMailBoxSettingsSave} />

          <Flex mt={20} justify="flex-end">
            <Button type="primary" >
              Save
            </Button>
          </Flex></Modal>} */}

    </>
  );
};

export default withAppBar(MailBoxList);
