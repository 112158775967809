import { API_ENDPOINTS } from "constants/api";
import withLoader from "hoc/withLoader";
import useApi from "hooks/useApi";
import { List, Skeleton, Switch } from "antd";
import { Text } from "styles/typography";
import { useCallback, useEffect, useState } from "react";
import { Div, Flex } from "styles/layout";
import { MailBoxLogDetailType } from "types/MailBox";
import { Switch as RouterSwitch } from "react-router-dom";
import moment from "moment";
import classes from "./styles.module.css";
import classes1 from "styles/common.module.css";

interface CampaignLogDetailsProps {
  id: string;
  logType: string;
}

export enum NewCampaignSteps {
  ONBOARDING = "ONBOARDING",
  MAILBOX_LISTING = "MAILBOX_LISTING",
  MAILBOX_PROVIDER = "MAILBOX_PROVIDER",
  MAILBOX_SETTINGS = "MAILBOX_SETTINGS",
  PROSPECT_UPLOAD = "PROSPECT_UPLOAD",
  PROSPECT_COLUMN_MAPPING = "PROSPECT_COLUMN_MAPPING",
  PROSPECT_SUMMARY = "PROSPECT_SUMMARY",
  SETUP = "SETUP"
}
export interface CampaignStateType {
  campaignId: null | string;
  currentStepName: NewCampaignSteps;
  prospectData: {
    fileUrl: null | string;
    columnHeaders: string[];
    sampleProspectData: string[];
    totalProspects: number;
  };
  prospectSummary: {
    total: number;
    failed: number;
    failedCsvLink: string;
    added: number;
  };
  selectedMailBoxesIds: string[];
}

export const CAMPAIGN_INITIAL_STATE: CampaignStateType = {
  campaignId: null,
  currentStepName: NewCampaignSteps.ONBOARDING,
  prospectData: {
    fileUrl: null,
    columnHeaders: [],
    sampleProspectData: [],
    totalProspects: 0
  },
  prospectSummary: {
    total: 0,
    failed: 0,
    failedCsvLink: "",
    added: 0
  },
  selectedMailBoxesIds: []
};

type LogType = {
  logs: MailBoxLogDetailType[];
  loading: boolean;
};

const LogDetails = ({ id, logType }: CampaignLogDetailsProps) => {
  const { callApi } = useApi();
  const [logState, setLogState] = useState<LogType>({ logs: [], loading: true });
  const [filterError, setFilterError] = useState(false);

  const toggleShowOnError = useCallback(() => setFilterError((prev) => !prev), []);

  const fetchLogs = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.GET_LOG.getLogListUrl(id, logType);
    try {
      const response = await callApi({ url, method });
      if (response.success) {
        if (filterError) {
          let filteredData = response.data?.logs.filter((da: any) => da?.err === true);
          setLogState({ logs: filteredData, loading: false });
        } else {
          setLogState({ logs: response.data?.logs, loading: false });
        }
      } else {
        setLogState({ logs: [], loading: false });
      }
    } catch (err) {}
  }, [callApi, id, filterError, logType]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  return logState.loading ? (
    <Skeleton
      style={{ padding: "20px 0", minHeight: "calc(100vh - 300px)", maxHeight: "calc(100vh - 300px)" }}
      active
      paragraph={{ rows: 8 }}
    />
  ) : (
    <Flex direction="column" gap={20}>
      {/* <Flex align="center" justify="flex-end" gap={15} p={10}>
        <Text variant="caption" style={{ color: "#999", fontSize: "13px" }}>
          Show only errors
        </Text>
        <span className={classes.errorToggle}>
          <Switch size="default" checked={filterError} onChange={toggleShowOnError} />
        </span>
      </Flex> */}
      <Div
        className={`${classes.logDetailsCon} ${classes1.scrollContainer}`}
        style={{ minHeight: "calc(100vh - 300px)", maxHeight: "calc(100vh - 300px)", overflow: "auto" }}
      >
        <List
          loading={logState.loading}
          itemLayout="horizontal"
          dataSource={logState.logs}
          renderItem={(item) => (
            <List.Item style={{ padding: "5px 0", borderTop: "1px solid #eee", borderBottom: "1px solid #eee" }}>
              <Flex w={"100%"} align={"flex-start"}>
                <Div className={classes.textLightGray} style={{ fontSize: "13px" }}>
                  {item.created}
                </Div>
                <Div
                  className={item.err ? classes.textError : classes.textSuccess}
                  style={{ flex: 1, padding: "0 10px", wordBreak: "break-word", fontSize: "13px", lineHeight: "18px" }}
                >
                  {item.message}
                </Div>
                {/* <Div className={classes.textLightGray} style={{ fontSize: "13px", paddingRight: "5px" }} >{item.user}</Div> */}
              </Flex>
            </List.Item>
          )}
        />
      </Div>
    </Flex>
  );
};

export default withLoader(LogDetails);
