import { Skeleton, Table, TablePaginationConfig } from "antd";
import Column from "antd/lib/table/Column";
import { MailboxActionProps, MailboxActionMenu } from "components/MailBox/Card";
import MailBoxIcon from "components/MailBox/Icon";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import { MailBoxResponseType } from "types/MailBox";
import classes from "./styles.module.css";

type FunctionArgs<Type> = {
  [Property in keyof Type]: (record: MailBoxResponseType) => void;
};

type MailBoxTableActionProps = FunctionArgs<MailboxActionProps>;

export interface MailboxTableViewProps extends MailBoxTableActionProps {
  dataSource: Array<MailBoxResponseType>;
  pagination?: false | TablePaginationConfig;
  loading?: boolean;
  pageSize?: number;
  onReconnect?: any;
}

export type SkeletonTableProps = {
  columns: any[];
  rowCount?: number;
};

export const SkeletonTable = ({ columns, rowCount = 20 }: SkeletonTableProps) => {
  return (
    <>
      <Table
        rowKey="key"
        pagination={false}
        dataSource={[...Array(rowCount)].map((_, index) => ({
          key: `key${index}`
        }))}
        columns={columns.map((column) => {
          return {
            ...column,
            render: () => <Skeleton key={column.dataIndex} title={true} paragraph={false} active />
          };
        })}
      />
    </>
  );
};

export const MailboxTableView = ({ pageSize = 10, dataSource, pagination, onReconnect, ...props }: MailboxTableViewProps) => {
  const columns = [{ dataIndex: "personInfo" }, { dataIndex: "queue" }, { dataIndex: "capacity" }, { dataIndex: "ips" }];

  const pageDefaults = { pageSize, size: "small", position: ["topRight"] };
  const paginationUpd =
    pagination === false || dataSource.length <= pageSize ? false : { ...pageDefaults, ...(pagination as any) };

  return props.loading ? (
    <SkeletonTable columns={columns} />
  ) : dataSource.length ? (
    <Table
      dataSource={dataSource}
      pagination={paginationUpd}
      rowKey="id"
      rowClassName={classes.mailboxTableRow}
      loading={props.loading}
      onRow={(record) => {
        return {
          onClick: (event) => props.onEdit && props.onEdit(record)
        };
      }}

      className="antd__table"
    >
      <Column
        className={classes.mailboxTableCell}
        key="PersonInfo"
        render={(_, record: MailBoxResponseType) => (
          <Flex gap={15} align="center">
            <MailBoxIcon type={record.provider} />
            <Flex direction="column">

              <Text variant="sub1">{`${record.firstname} ${record.lastname}`}
                <Text variant="sub2" color="secondary" ml={8}>
                  {record.email}
                </Text>
              </Text>
              <Text variant="sub2" color="secondary">
                {record && record?.campaignName ? record.campaignName :'No Campaign'}
              </Text>
            </Flex>
          </Flex>
        )}
      />
      {/* <Column title="Queued" dataIndex="queued" key="queued" /> */}
      <Column title="Current capacity" dataIndex="capacity" key="capacity" />
      <Column title="IPS" dataIndex="ips" key="ips" />
      <Column
        title="Actions"
        key="actions"
        render={(_, record: MailBoxResponseType) => (
          <Flex onClick={(ev) => ev.stopPropagation()}>
            <MailboxActionMenu
              handleConnectionValidate={() => props.handleConnectionValidate && props.handleConnectionValidate(record)}
              showBrooklynMenuItem={() => props.showBrooklynMenuItem && props.showBrooklynMenuItem(record)}
              // onEdit={() => props.onEdit && props.onEdit(record)}
              changePassword={() => props.changePassword && props.changePassword(record)}
              onDelete={() => props.onDelete && props.onDelete(record)}
              onViewIpsDetails={() => props.onViewIpsDetails && props.onViewIpsDetails(record)}
              onViewLogsDetails={() => props.onViewLogsDetails && props.onViewLogsDetails(record)}
              onSendEmail={() => props.onSendEmail && props.onSendEmail(record)}
              onReconnect={() => onReconnect(record)}
              responseSkip={() => props.responseSkip && props.responseSkip(record)}
              type={record.provider}
            />
          </Flex>
        )}
      />
    </Table>
  ) : null;
};