import { ConfigProvider } from "antd";
import { AuthProvider } from "auth/AuthProvider";
import { Auth } from "aws-amplify";
import { ServiceWorkerProvider } from "contexts/serviceWorkerContext";
import React from "react";
import ReactDOM from "react-dom";
import { getCookie, JSONParse } from "utils/dom";
import { rootDomain } from "utils/string";
import { apocalypseHandler } from "./apocalypseHandler";
import "./index.less";
import App from "./App";
const isLocal = window.location.hostname?.toLocaleLowerCase() === "localhost";

const keyX = "mcc";
const awsConf = JSONParse(getCookie(keyX));

const awsConfig = {
  aws_project_region: awsConf.region,
  aws_cognito_region: awsConf.region,
  aws_user_pools_id: awsConf.cognitoPoolId,
  aws_user_pools_web_client_id: awsConf.cognitoPoolWebClientId,
  oauth: {},
  cookieStorage: {
    domain: isLocal
      ? window.location.hostname
      : rootDomain(window.location.hostname),
    path: "/",
    secure: !isLocal
  }
};
Auth.configure({ ...awsConfig });

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider>
      <AuthProvider>
        <ServiceWorkerProvider>
          <App />
        </ServiceWorkerProvider>
      </AuthProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

window.addEventListener("error", apocalypseHandler);