import styled from "styled-components";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import { APPBAR_HEIGHT } from "constants/layout";

export const Flex = styled.div<{
  gap?: number;
  grow?: number;
  mb?: number;
  pb?: number;
  pt?: number;
  p?: number | string;
  m?: number | string;
  ml?: number | string;
  mr?: number | string;
  mt?: number | string;
  align?: string;
  justify?: string;
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  w?: number | string;
  wrap?: string;
  borderColor?: string;
  hoverBorderColor?: string;
  hoverBgColor?: string;
  hoverColor?: string;
  cursor?: string;
  borderWidth?: number;
  br?: number | string;
  h?: number | string;
  position?: "absolute" | "relative" | "fixed";
  bgColor?: string;
  color?: string;
  zIndex?: number;
}>`
  display: flex;
  flex-grow: ${({ grow = 0 }) => grow};
  flex-direction: ${({ direction = "row" }) => direction};
  padding: ${({ p = 0 }) => (isNumber(p) ? `${p}px` : p)};
  ${({ gap }) => isNumber(gap) ? `gap: ${gap}px` : isString(gap) ? `gap: ${gap}` : ""};
  ${({ m }) =>
    isNumber(m) ? `margin: ${m}px;` : isString(m) ? `margin: ${m};` : ""};
  ${({ w }) => (w ? `width: ${isNumber(w) ? `${w}px` : w};` : ``)}
  ${({ mb }) =>
    isNumber(mb)
      ? `margin-bottom: ${mb}px;`
      : isString(mb)
        ? `margin-bottom: ${mb};`
        : ""};
  ${({ ml }) =>
    isNumber(ml)
      ? `margin-left: ${ml}px;`
      : isString(ml)
        ? `margin-left: ${ml};`
        : ""};
  ${({ mr }) =>
    isNumber(mr)
      ? `margin-right: ${mr}px;`
      : isString(mr)
        ? `margin-right: ${mr};`
        : ""};
  ${({ mt }) =>
    isNumber(mt)
      ? `margin-top: ${mt}px;`
      : isString(mt)
        ? `margin-top: ${mt};`
        : ""};
  ${({ pb }) => (isNumber(pb) ? `padding-bottom: ${pb}px;` : "")}
  ${({ pt }) => (isNumber(pt) ? `padding-top: ${pt}px;` : "")}
  align-items: ${({ align = "unset" }) => align};
  justify-content: ${({ justify = "unset" }) => justify};
  ${({ wrap }) => (wrap ? `flex-wrap: ${wrap};` : "")}
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : "")}
  ${({ borderColor: bc, borderWidth: bw = 1 }) =>
    bc ? `border: ${bw}px solid ${bc};` : ""}
  ${({ br }) =>
    isString(br)
      ? `border-radius: ${br};`
      : isNumber(br)
        ? `border-radius: ${br}px;`
        : ""}
  ${({ h }) =>
    isNumber(h) ? `height: ${h}px;` : isString(h) ? `height: ${h};` : ""}
  ${({ position }) => (isString(position) ? `position: ${position};` : "")}
  ${({ bgColor = "transparent" }) => `background-color: ${bgColor};`}
  ${({ color }) => (color ? `color: ${color};` : "")}
  ${({ zIndex }) => (isNumber(zIndex) ? `z-index: ${zIndex};` : "")}

    &:hover {
    ${({ hoverBorderColor: hbc, borderWidth: bw = 1 }) =>
    hbc ? `border: ${bw}px solid ${hbc};` : ""}

    ${({ hoverBgColor: hbgc }) => (hbgc ? `background: ${hbgc};` : "")}
    ${({ hoverColor: hc }) => (hc ? `color: ${hc};` : "")}
  }
`;

export const Div = styled.div<{
  mw?: number | string;
  mh?: number | string;
  mr?: number;
  hoverColor?: string;
  align?: "center" | "left" | "right";
  border?: string;
  mt?: number | string;
  ml?: number | string;
  p?: number | string;
  mb?: number | string;
  grow?: number;
  w?: number | string;
  cursor?: string;
  bgColor?: string;
  br?: number | string;
  h?: number | string;
  position?: "absolute" | "relative" | "fixed";
}>`
  ${({ w }) =>
    isNumber(w) ? `width: ${w}px;` : isString(w) ? `width: ${w};` : ""}
  ${({ mw }) =>
    isNumber(mw)
      ? `min-width: ${mw}px`
      : isString(mw)
        ? `min-width: ${mw}`
        : ""};
  ${({ mh }) =>
    isNumber(mh)
      ? `min-height: ${mh}px`
      : isString(mh)
        ? `min-height: ${mh}`
        : ""};
  margin-right: ${({ mr = 0 }) => `${mr}px`};
  margin-bottom: ${({ mb = 0 }) => `${mb}px`};
  margin-top: ${({ mt = 0 }) => `${mt}px`};
  margin-left: ${({ ml = 0 }) => `${ml}px`};
  text-align: ${({ align = "left" }) => align};
  border: ${({ border }) => border};
  padding: ${({ p = 0 }) => (isNumber(p) ? `${p}px` : p)};
  flex-grow: ${({ grow = 0 }) => grow};
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : "")}
  ${({ bgColor = "transparent" }) => `background-color: ${bgColor};`}
  ${({ br }) =>
    isString(br)
      ? `border-radius: ${br};`
      : isNumber(br)
        ? `border-radius: ${br}px;`
        : ""}
  ${({ h }) =>
    isNumber(h) ? `height: ${h}px;` : isString(h) ? `height: ${h};` : ""}
  ${({ position }) => (isString(position) ? `position: ${position};` : "")}
  &:hover {
    ${({ hoverColor: hc }) => (hc ? `color: ${hc};` : "")}
  }
`;

export const BgImage = styled.div<{
  imageUrl?: string;
  mw?: number;
  mh?: number;
  w?: number;
  h?: number;
  display?: "block" | "inline" | "inline-block" | "flex" | "inline-flex";
  br?: number | string;
  bc?: string;
  bs?: number | number;
  bgColor?: string;
}>`
  ${({ imageUrl }) =>
    isString(imageUrl) ? `background: url("${imageUrl}");` : ""}
  ${({ mh }) => (isNumber(mh) ? `min-height: ${mh}px;` : "")}
  ${({ mw }) => (isNumber(mw) ? `min-width: ${mw}px;` : "")}
  ${({ w }) => (isNumber(w) ? `width: ${w}px;` : "")}
  ${({ h }) => (isNumber(h) ? `height: ${h}px;` : "")}
  ${({ display = "block" }) => `display: ${display};`}
  ${({ br }) =>
    isString(br)
      ? `border-radius: ${br};`
      : isNumber(br)
        ? `border-radius: ${br}px;`
        : ""}
  background-size: ${({ bs = "contain" }) => (isNumber(bs) ? `${bs}px` : bs)};
  background-repeat: no-repeat;
  background-position: center;
  ${({ bgColor = "transparent" }) => `background-color: ${bgColor};`}
  ${({ bc }) => (isString(bc) ? `border: 1px solid ${bc}` : "")}
`;

export const PageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
  padding-top: calc(${APPBAR_HEIGHT}px + 20px);
  position: relative;
`;

export const PageBackground = styled.div`
  background: ${({ theme }) => theme.pageBackground};
`;
