import React from "react";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { Div, Flex } from "styles/layout";
import { Spin, Tooltip } from "antd";
import { Text } from "styles/typography";
import { FLAT_SILVER_COLOR } from "utils/colors";

type TouchPreviewProps = {
  subject: string;
  body: string;
  loading: boolean;
  onTabChange: any
};

const antLoadingIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const TouchPreview = ({ loading, subject, body, onTabChange }: TouchPreviewProps) => {
  if (loading) {
    return (
      <Flex w="100%" h={460} align="center" justify="center">
        <Spin indicator={antLoadingIcon} />
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Flex justify="end">
        <Tooltip title="Refresh preview">
          <ReloadOutlined onClick={() => onTabChange("PREVIEW")} />
        </Tooltip>
      </Flex>
      <Flex mb={20} mt={10}>
        <Text variant="body1">{subject}</Text>
      </Flex>
      <Flex
        br={5}
        style={{
          height: 400,
          overflow: "scroll",
        }}
        mb={5}
      >
        <Div dangerouslySetInnerHTML={{ __html: body }} />
      </Flex>
    </Flex>
  );
};

export default TouchPreview;
