import { useCallback, useContext, } from "react";
import { Div, Flex } from "styles/layout";
import { LoadingOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignSequencesSelector,
} from "state/selectors";
import CampaignActions from "state/actions/campaign";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import { TouchTemplateType, TouchType } from "types/Touch";
import TouchModal from "./TouchModal";
import CustomSequenceModal from "./CustomSequenceModal";
import { canModifyCampaignState } from "state/selectors";
import { CampaignSequnceTypeEnum } from "types/Campaign";
import appContext from "contexts/appContext";
import { Tooltip, Popover, Input, Button, Spin, Popconfirm } from "antd";
import { WHITE_COLOR, BOX_SHADOW } from "utils/colors";
import classes from "./styles.module.css"
import { v4 } from "uuid";

const { Search } = Input;

type SequencesListProps = {
  campaignId: string;
};
interface CampaignSequencesProps {
  id: string;
  touches: number;
  type: CampaignSequnceTypeEnum,
  name?: string,
  description?: string
}
interface touchItems {
  // campId: string;
  seqId?: string;
  touchNumber: number;
  waitDays: number;
  id: string;
  // createdAt: string;
  // updatedAt: string;
  // createdBy: string;
  // updatedBy: string;
}

const SequenceList = ({ campaignId }: SequencesListProps) => {
  const seq = useSelector(getCampaignSequencesSelector(campaignId));
  const dispatch = useDispatch();
  const { callApi } = useApi();
  const [touches, setTouches] = useState<TouchType[]>([]);
  const [sequences, setSequenceOrder] = useState<CampaignSequencesProps[]>([]);
  const [showAddTouchModal, setShowAddTouchModal] = useState(false);
  const [touchForEdit, setTouchForEdit] = useState<TouchTemplateType | null>(
    null
  );
  const isFree = true; // note: always true
  const [sequenceId, setSequenceId] = useState("");
  const [sequenceType, setSequenceType] = useState('INITIAL');
  const [loading, setIsLoading] = useState(false)
  const [isToggle, setIsToggle] = useState(false)
  const [initialTouches, setInitialTouches] = useState(0)

  const { setLoadingMessage } = useContext(appContext);
  const [editMode, isEditMode] = useState(false);
  const [templateIdForEdit, settemplateIdForEdit] = useState('');
  const [addTouchByIcon, setAddTouchByIcon] = useState(false)

  //add custom
  const [showAddCustom, setShowAddCustom] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedCustomSeqData, setSelectedCustomSeqData] = useState<CampaignSequencesProps>({ id: "", name: "", touches: 0, description: "", type: CampaignSequnceTypeEnum.CUSTOM });

  useEffect(() => {
    if (campaignId) {
      dispatch(CampaignActions.fetchSequences(campaignId));
    }
  }, [campaignId, dispatch]);

  useEffect(() => {
    try {
      let initialTouch = sequences.filter((s) => s.type === "INITIAL")
      if (initialTouch && initialTouch[0]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setInitialTouches(initialTouch[0]['touches'])
      } else {
        setInitialTouches(0)
      }
    } catch { setInitialTouches(0) }
  }, [setInitialTouches, sequences])

  const fetchTouches = async () => {
    let touch: touchItems[] = []
    // let touch = []
    if (seq && seq.length) {
      for (let i = 0; i < seq.length; i++) {
        // const { url, method } = API_ENDPOINTS.CAMPAIGN_TOUCH.getListUrl(
        //   campaignId,
        //   seq[i]['id']
        // );

        // const response = await callApi({
        //   url,
        //   method,
        // });

        // if (response.success) {
        //   touch.push(response.data.touchItems)
        // }
        if (seq[i] && seq[i].touchItems) {
          seq[i].touchItems.map((touchItem: any) => touchItem.seqId = seq[i]['id'])
        }
        touch = touch.concat(seq[i].touchItems)
      }
      // touch = touch.map(t => t).flat()
    }
    setTouches(touch);
  };

  useEffect(() => {
    const order = ["INITIAL", "INFO", "POWER", "REFERRAL", "FUTURE", "MEETING_REQUEST", "MEETING_RESCHEDULE", "CUSTOM"]
    if (seq && seq.length) {
      setSequenceOrder(seq.sort(function (a, b) {
        return order.indexOf(a.type) - order.indexOf(b.type)
      }))
      fetchTouches();
    }
    setIsLoading(prev => !prev)
  }, [seq, setSequenceOrder]);

  useEffect(() => {
    return () => { setTouches([]); setAddTouchByIcon(false) }
  }, []);

  const getDayNumber = useCallback(
    (selectedTouchOrder, _id) =>
      touches.reduce((acc, { touchNumber, waitDays, seqId }) => {
        if (_id === seqId) {
          if (touchNumber <= selectedTouchOrder) {
            return ++acc;
            // return acc + waitDays;
          }

        }
        return acc;
      }, 0),
    [touches]
  );

  const generateNewTouch = useCallback((seqId: string, mode: boolean) => {
    setSequenceId(seqId)
    isEditMode(mode)
    setTouchForEdit(null);

  }, []);

  const toggleShowAddTouchModal = useCallback((seqId: string, mode: boolean) => {
    let sequenceType = sequences.filter(seq => seq.id === seqId);
    setSequenceType(sequenceType[0].type);
    setAddTouchByIcon(!mode)
    setSequenceId(seqId)
    isEditMode(mode)
    setShowAddTouchModal((prev) => !prev);
  }, [setShowAddTouchModal, setAddTouchByIcon, sequences]);


  const switchTouchData = useCallback((seqId: string, mode: boolean) => {
    setSequenceId(seqId)
    isEditMode(mode)
  }, []);

  //add custom sequence
  const addCustomSequence = useCallback(async () => {
    setSaving(true);
    const sequenceList = [...sequences]
    // const newCustomSequence = {
    //   id: v4(),
    //   touches: 0,
    //   type: CampaignSequnceTypeEnum.CUSTOM,
    //   name: selectedCustomSeqData.name,
    //   description: selectedCustomSeqData.description
    // }

    const { url, method } = API_ENDPOINTS.CAMPAIGNS.createCustomSequence(campaignId);
    const payload = {
      name: selectedCustomSeqData.name,
      description: selectedCustomSeqData.description
    };
    const response = await callApi({
      url,
      method,
      data: payload,
    });

    if (response.success || true) {
      setSaving(false);
      // sequenceList.push(newCustomSequence);
      // setSequenceOrder(sequenceList);
      handleCustomSequenceModalClose();
      dispatch(CampaignActions.fetchSequences(campaignId));
    } else {
      setSaving(false);
    }
  }, [sequences, selectedCustomSeqData]);

  const deleteCustomSequence = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.deleteCustomSequence(campaignId);
    let payload = { id: selectedCustomSeqData.id };
    const response = await callApi({
      url,
      method,
      data: payload,
    });

    if (response.success) {
      setSaving(false);
      // setSequenceOrder(sequences.filter(s => s.id !== selectedCustomSeqData.id))
      handleCustomSequenceModalClose();
      dispatch(CampaignActions.fetchSequences(campaignId));
    } else {
      setSaving(false);
    }
  }, [selectedCustomSeqData])

  const editCustomSequence = useCallback(async () => {
    if (selectedCustomSeqData && Object.keys(selectedCustomSeqData).length) {
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.editCustomSequence(campaignId);
      let payload = { id: selectedCustomSeqData.id, name: selectedCustomSeqData.name, description: selectedCustomSeqData.description };
      const response = await callApi({
        url,
        method,
        data: payload,
      });

      if (response.success) {
        setSaving(false);
        handleCustomSequenceModalClose();
        // let updatedData = {
        //   id: selectedCustomSeqData.id,
        //   touches: 0,
        //   type: CampaignSequnceTypeEnum.CUSTOM,
        //   name: selectedCustomSeqData.name,
        //   description: selectedCustomSeqData.description
        // }
        // setSequenceOrder(sequences.map(seq => seq.id === selectedCustomSeqData.id ? updatedData : seq))
        dispatch(CampaignActions.fetchSequences(campaignId));
      } else {
        setSaving(false);
      }
    }
  }, [selectedCustomSeqData])

  const onClickCustomSequence = (id: string) => {
    const editData = sequences.find(el => el.id === id);
    if (editData && Object.keys(editData).length) {
      setSelectedCustomSeqData(editData)
      setShowAddCustom(true);
    }
  }

  const handleCustomSequenceModalClose = () => {
    setShowAddCustom(false);
    setSelectedCustomSeqData({ id: "", name: "", touches: 0, description: "", type: CampaignSequnceTypeEnum.CUSTOM });
  }

  const handleCustomSeqOnChange = useCallback((label: string, value: string) => {
    setSelectedCustomSeqData({ ...selectedCustomSeqData, ...{ [label]: value } });
  }, [selectedCustomSeqData])

  const onTouchModalCancel = useCallback(() => {
    toggleShowAddTouchModal(sequenceId, false);
    setTouchForEdit(null);
    setAddTouchByIcon(false)
  }, [toggleShowAddTouchModal, setAddTouchByIcon, sequenceId]);


  const onSaveCallback = useCallback(() => {
    dispatch(CampaignActions.fetchSequences(campaignId))
    // fetchTouches();
  }, [dispatch, fetchTouches, sequenceId]);

  const capitalize = (seq: string) => {
    if (seq.includes("_")) {
      seq = seq.replace("_", " ");
    }
    return seq.charAt(0).toUpperCase() + seq.slice(1).toLowerCase();
  }

  const getlabel = (sequenceType: string) => {
    let labelValue = ""
    switch (sequenceType) {
      case CampaignSequnceTypeEnum.INFO:
        labelValue = "When prospects request more information"
        break;
      case CampaignSequnceTypeEnum.POWER:
        labelValue = "When prospects identify themselves as decision makers"
        break;
      case CampaignSequnceTypeEnum.REFERRAL:
        labelValue = "When prospects refer other decision makers"
        break;
      case CampaignSequnceTypeEnum.FUTURE:
        labelValue = "When prospects request future followup"
        break;
      case CampaignSequnceTypeEnum.MEETING_REQUEST:
        labelValue = "When prospects request meetings"
        break;
      case CampaignSequnceTypeEnum.MEETING_RESCHEDULE:
        labelValue = "When prospects request meeting reschedules"
        break;
      case CampaignSequnceTypeEnum.CUSTOM:
        labelValue = "When prospects request for anything else"
        break;
      default:
        labelValue = ""
        break;
    }
    return labelValue
  }
  const onEditTouch = useCallback(
    async (touchId: string) => {
      const touch = touches.find(({ id }) => id === touchId);
      if (!touch) return;

      const { url, method } = API_ENDPOINTS.TOUCH.getListTemplatesUrl(touchId);

      setLoadingMessage("Fetching Touch template ...");
      const response = await callApi({
        url,
        method,
      });
      setLoadingMessage("");
      if (response.success) {
        const {
          templates: [template]
        } = response.data;
        setTouchForEdit({ ...touch, template });
        settemplateIdForEdit(template.id);
        toggleShowAddTouchModal(response.data.templates[0].seqId, true);
        setAddTouchByIcon(false)
      }
    },
    [callApi, setAddTouchByIcon, setLoadingMessage, touches]
  );

  const onSwitchTouch = useCallback(
    async (touchId: string, touches: TouchType[]) => {
      const touch = touches.find(({ id }) => id === touchId);

      if (touch) {
        console.log("hiiiiiiiiiiiii")
        const { url, method } = API_ENDPOINTS.TOUCH.getListTemplatesUrl(touchId);
        setIsToggle(true)
        // setLoadingMessage("Fetching Touch template ...");
        const response = await callApi({
          url,
          method,
        });
        // setLoadingMessage("");
        setIsToggle(false)
        if (response.success) {
          const {
            templates: [template]
          } = response.data;
          setTouchForEdit({ ...touch, template });
          // setTemplateId(touchTemplate?.template.id || null); templateIdForEdit
          // settemplateIdForEdit(template?.template.id);
          // settemplateIdForEdit
          // setTouchForEdit(template);
          console.log("templatetemplate",template)
          settemplateIdForEdit(template.id);
          switchTouchData(response.data.templates[0].seqId, true);
          return template
        }

      }
    }, [callApi, setLoadingMessage, setTouchForEdit, settemplateIdForEdit])

  const AppendTouches = ({ _id, index }: { _id: string, index: number }) => {
    return <ul>
      {touches.map(({ touchNumber, waitDays, id, seqId }) => (
        seqId === _id ?
          <>  {!!waitDays ? <Div className="day-info" style={{ whiteSpace: "nowrap" }} key={id}>Wait {waitDays} day{waitDays > 1 ? "s" : ""}</Div> : null}
            <Tooltip title={`View touch #${getDayNumber(touchNumber, _id)}`}>
              <li onClick={() => onEditTouch(id)}>
                <label >Touch {getDayNumber(touchNumber, _id)}</label>
                {/* <label >Touch {index}</label> */}
                <i className="fa fa-envelope-o"></i>
              </li>
            </Tooltip>
          </>
          : null
      ))}
    </ul>
  }

  const DisplaySequence = () => {
    return <>
      {sequences.map((sequence, index) => {
        return <Div className={`sq-li ${sequence.type === "INITIAL" ? " sq-li-main " : ""}left-line`} key={`key${index}`}>
          {sequence.type !== 'INITIAL' && (<h5 className='sq-title'>{sequence.description}</h5>)}
          {/* {sequence.type !== "INITIAL" && <h5 className="sq-title">{getlabel(sequence.type)}</h5>} */}
          <Div className={`d-flex ${sequence.type === "INITIAL" ? "" : " sq-con"}`} key={`keys${index}`}>
            {sequence.type !== "CUSTOM" && (
              <Div className="sq-box">
                {sequence.type === "MEETING_REQUEST" ? "Meeting" : sequence.type === "MEETING_RESCHEDULE" ? "Reschedule" : capitalize(sequence.type)}
              </Div>
            )}
            {sequence.type == "CUSTOM" && (
              <Div className="sq-box" onClick={() => onClickCustomSequence(sequence.id)} style={{ cursor: "pointer" }}>
                {capitalize(sequence.name ? sequence.name : sequence.type)}
              </Div>
            )}
            {isFree ?
              <Tooltip title={sequence.type === "INITIAL" ? `Create touch #${sequence.touches + 1} 
              for ${sequence.type.toLowerCase()} sequence` : initialTouches ? `Create touch #${sequence.touches + 1} 
              for ${sequence.type.toLowerCase()} sequence` : `Cannot create touch until initial touch is created`}>
                {sequence.type === "INITIAL" ? <Div className="plus-add" onClick={() => toggleShowAddTouchModal(sequence.id, false)}><span>+</span></Div> :
                  initialTouches ? <Div className="plus-add" onClick={() => toggleShowAddTouchModal(sequence.id, false)}><span>+</span></Div> :
                    <Div className="plus-add"><span>+</span></Div>
                }
              </Tooltip> :
              <Tooltip title={`Cannot create touch when campaign is active`}>
                <Div className="plus-add"><span>+</span></Div>
              </Tooltip>
            }

            <Div className="email-touch d-flex">
              <AppendTouches _id={sequence.id} index={index} key={sequence.id} />
            </Div>
          </Div>
        </Div>
      })
      }

    </>
  }

  return (
    <Flex direction="column" className="flowCenter" bgColor={WHITE_COLOR} p={20} mt={10} style={{ boxShadow: BOX_SHADOW, padding: "15px 15px 15px 80px", minHeight: "400px" }}>
      <Div mb={40} style={{ color: 'rgba(96,99,103,.55)', fontSize: '12px', textAlign: 'center' }}>
        You can create a sequence with as many touches as you want. You can also create follow up sequences to address most frequently expected responses.
      </Div>
      {!loading ? (
        <Div style={{ display: "flex", justifyContent: "center", width: "100%" }}><Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} /></Div>
      ) : (
        <Div className="sq-wrapper">
          <Div className="sq-group">
            <DisplaySequence />
            <Div className="sq-li left-line">
              <Div className="d-flex sq-con" style={{ paddingTop: '18px' }}>
                <Div className={`${classes.addCustomBtn} `} onClick={() => setShowAddCustom(true)}>
                  Add Custom
                </Div>

              </Div>
            </Div>
          </Div>
        </Div>
      )}
      {showAddTouchModal && (
        <TouchModal
          campaignId={campaignId}
          touches={touches}
          onClose={onTouchModalCancel}
          switchTouch={onSwitchTouch}
          genrateNewT={generateNewTouch}
          onSaveCallback={onSaveCallback}
          onSetTouchForEdit={setTouchForEdit}
          touchTemplate={touchForEdit}
          sequenceId={sequenceId}
          sequenceType={sequenceType}
          canEdit={isFree}
          editMode={editMode}
          addTouchByIcon={addTouchByIcon}
          isEditMode={isEditMode}
          isToggle={isToggle}
          templateIdForEdit={templateIdForEdit}
          settemplateIdForEdit={settemplateIdForEdit}
        />
      )}

      {showAddCustom ? (
        <CustomSequenceModal
          onClose={handleCustomSequenceModalClose}
          addCustomSequence={addCustomSequence}
          saving={saving}
          deleteCustomSequence={deleteCustomSequence}
          editCustomSequence={editCustomSequence}
          customSeqDetail={selectedCustomSeqData}
          onHandleChange={handleCustomSeqOnChange}
        />
      ) : null}
    </Flex>
  );
};

export default SequenceList;