import { useEffect, useContext, useState, useMemo } from "react";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { NavPathType } from "components/AppBar";
import { useUserlistState } from "contexts/userListContext";
import { Table, Button } from 'antd';
import { IVY_PATH, ROUTE_PATHS } from "constants/routes";
import { rootDomain } from "utils/string";

interface IntegrationsPageProps {
    setNavPaths: SetNavPathsFn;
}

const Subscription = ({ setNavPaths }: IntegrationsPageProps) => {
    const { userList } = useUserlistState();


    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Button type="primary" onClick={() => handleAction(record)}>Setup Subscription</Button>
            ),
        },
    ];


    const handleAction = (record: any) => {
        // Define the action to perform here
        const additionalParam = `&userId=${record?.userId}`; // Modify this with your parameter name and value
        // window.open(ivyPathUrl(`profile?cur=0&${additionalParam}`))
        window.location.href=ivyPathUrl(`profile?cur=0&${additionalParam}`)
    }

    const ivyPathUrl = (path: string) => {
        const isLocal = window.location.hostname === "localhost";
        const curHost = isLocal ? "localhost:5500" : window.location.hostname;
        const thirdParty = isLocal ? "localhost:5500" : IVY_PATH + ".";
        const updatedHost = rootDomain(curHost);
        const protocol = isLocal ? "http:" : window.location.protocol;
        return protocol + "//" + thirdParty + updatedHost + "/#/" + path;
    };

    useEffect(() => {
        const navPaths: NavPathType[] = [];
        navPaths.push({
            label: 'Subscription',
        })
        setNavPaths(navPaths);
        return () => setNavPaths([]);
    }, [setNavPaths]);


    return (
        <Table columns={columns} dataSource={userList} />
    );
};

export default withAppBar(Subscription);