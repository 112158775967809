import { createContext, ReactNode, useContext, useEffect, useState, useMemo } from "react";
import * as serviceWorker from "../serviceWorkerRegistration";

interface Props {
  children?: ReactNode;
}

const ServiceWorkerContext = createContext({
  isUpdateAvailable: false,
  updateNow: () => {}
});

const useServiceWorkerUpdate = () => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<ServiceWorker | null>(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);
      },
      onWaiting: (waiting: ServiceWorker | null) => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      }
    });
  }, []);

  useEffect(() => {
    const callback = (ev: Event) => {
      if (["activated", "redundant"].includes((ev?.target as any)?.state)) {
        window.location.reload();
      }
    };
    waitingServiceWorker?.addEventListener("statechange", callback);
    return () => waitingServiceWorker?.removeEventListener("statechange", callback);
  }, [waitingServiceWorker]);

  return useMemo(
    () => ({
      isUpdateAvailable,
      updateNow: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
      }
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );
};

export const ServiceWorkerProvider = ({ children }: Props) => {
  const value = useServiceWorkerUpdate();
  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

export const useServiceWorker = () => useContext(ServiceWorkerContext);
