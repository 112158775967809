import { useCallback, useMemo, useState, useRef } from "react";
import { DatePicker, Select, Modal, notification, Input, Skeleton, Button } from "antd";
import { Moment } from "moment";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { CampaignSequnceTypeEnum, InboundMailType, InboundMailWithThreadType, MailThreadType } from "types/Campaign";
import { isDateGreaterThanToday, isDateLesserThanToday } from "utils/dateTime";
import MailDetailModal from "./MailDetailModal";
import ReplyModal from "./ReplyModal";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import { useEffect } from "react";
import ResponseMailBox from "images/responseinbox.svg";
import NewMailImg from "images/new_message_inbox.svg";
import MailPreviewPane from "./MailPreviewPane";
import ReferralSequenceModal from "./ReferralSequenceModal";
import { getCampaignProspectVariablesSelector, getCampaignSequencesSelector } from "state/selectors";
import { useSelector } from "react-redux";
import TagMailModal from "./TagMailModal";
import { useContext } from "react";
import appContext from "contexts/appContext";
import DeliverMailModal from "./DeliverMailModal";
import { DEFAULT_PAGE_SIZE } from "constants/prospect";
import classes from "./styles.module.css";
import classes1 from "styles/common.module.css";
import useInfiniteScroll from "react-infinite-scroll-hook";
import InfiniteScroll from "react-infinite-scroll-component";
import { cloneDeep, uniqBy, orderBy, delay, concat } from "lodash";
import { ReloadOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { AccountSummaryType } from "types/Accounts";

type InboundMailsPropType = {
  campaignId: string;
  tagId: string;
  accountId?: string;
  levelType: string;
  tagData: [];
  responseType?: string;
  fetchTags: () => void;
  accountName?: string;
  isFromUnibox?: boolean;
  isProccesed?: boolean;
};

enum MailReadStatus {
  ALL = "ALL",
  READ = "READ",
  UNREAD = "UNREAD"
}
// enum MailFilterDays {
//   ALL = "all",
//   TODAY = "today",
//   LAST_7_Days = "last7",
//   YESTERDAY = "yesterday",
//   LAST_30_DAYS = "last30",
//   LAST_60_DAYS = "last60",
// }

const InboundMails = ({
  tagId,
  tagData,
  campaignId,
  levelType,
  fetchTags,
  accountId,
  responseType,
  accountName,
  isFromUnibox = false,
  isProccesed = false
}: InboundMailsPropType) => {
  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(appContext);

  const [isLoading, setIsLoading] = useState(true);
  const [mails, setMails] = useState<InboundMailType[]>([]);
  const [readStatus, setReadStatus] = useState<MailReadStatus | any>(localStorage.getItem("readStatus"));
  const [userAccounts, setAccounts] = useState<AccountSummaryType[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [uniboxVariables, setUniboxVariables] = useState<any>([]);
  const [allCampaignList, setCampaign] = useState<any>([]);
  const [selectedCampaignId, setSelectedCampaign] = useState<string | null>(null);
  const [campLoader, setCampaingLoader] = useState<boolean>(false);
  const [openMailDetailModal, setMailDetailModalState] = useState<boolean>(false);

  useEffect(() => {
    if (!readStatus) {
      localStorage.setItem("readStatus", String(MailReadStatus.UNREAD));
      setReadStatus(MailReadStatus.UNREAD);
    }
  }, [readStatus]);

  const prevtagId = usePrevious(tagId);

  // const [dayFilter, setDayFilter] = useState<MailFilterDays>(
  //   MailFilterDays.ALL
  // );
  // const [tagIdText, setTagIdText] = useState("")
  const [prospectEmail, setProspectEmail] = useState<string>("");
  const [tagIds, setTagid] = useState("");
  const [pageInfo, setPageInfo] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1
  });
  const [paginationOptions, setPaginationOptions] = useState({
    totalPages: 0
  });
  const [mainContainLoading, setMainContainedChanged] = useState(false);
  const [mailDetail, setMailDetail] = useState<InboundMailWithThreadType | null>(null);
  const [selectedMail, setSelectedMail] = useState<any>("");
  const [pageRefresh, setPageRefresh] = useState(false);
  const [newSequencename, setNewSequenceName] = useState<{
    type: null | string;
  }>({
    type: null
  });
  const [moveToSequence, setMoveToSequence] = useState<{
    type: CampaignSequnceTypeEnum | null | string;
    prospect: null | string;
    id: null | string;
    sequenceId?: string | null;
  }>({
    type: null,
    prospect: null,
    id: null
  });
  const [futureSequenceStartDate, setFutureSequenceStartDate] = useState<Moment | null>(null);
  const [dateRange, setDateRange] = useState<[Moment, Moment] | null>();
  const [replyToProspect, setReplyToProspect] = useState<InboundMailWithThreadType | null>(null);

  const [state, setState] = useState<any>({ selectedDiscardMailsIds: [], sort: "d" });
  const { selectedDiscardMailsIds, sort } = state;
  /* const campaignVariablesSelector = useMemo(
    () => getCampaignVariablesSelector(campaignId),
    [campaignId]
  );
  const variables = useSelector(campaignVariablesSelector); */
  const campaignVariablesSelector = useMemo(() => getCampaignProspectVariablesSelector(campaignId), [campaignId]);
  const variables = useSelector(campaignVariablesSelector);
  const sequences = useSelector(getCampaignSequencesSelector(campaignId));

  const [mailForDeliver, setMailForDeliver] = useState<null | InboundMailType>(null);
  const previousAccId = usePrevious(localStorage.getItem("selectedUserId"));

  const [mailForTag, setMailForTag] = useState<null | InboundMailType>(null);
  const markEmailAsRead = useCallback(
    async (mailId: string) => {
      const { url, method } = API_ENDPOINTS.INBOUND.getMarkAsReadUrl(mailId);
      await callApi({ url, method });

      const selectedMailIndex = mails.findIndex(({ inboundMessage: { id } }) => id === mailId);

      if (selectedMailIndex !== -1) {
        const mailsCopy = [...mails];
        mailsCopy.splice(selectedMailIndex, 1, {
          ...mails[selectedMailIndex],
          inboundMessage: {
            ...mails[selectedMailIndex].inboundMessage,
            read: true
          }
        });
        setMails(mailsCopy);
      }
    },
    [callApi, mails]
  );

  // Hook
  function usePrevious(value: any) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  const getMailDetail = useCallback(
    async (mailId: string, isReply: boolean): Promise<InboundMailWithThreadType | null> => {
      const selectedMail = mails?.find(({ inboundMessage: { id } }) => mailId === id);
      if (selectedMail) {
        if (!isReply) {
          setIsLoading(true);
        } else {
          setLoadingMessage("Fetching inbound email.");
        }

        const { url, method } = API_ENDPOINTS.INBOUND.getResponseDetailUrl(selectedMail.inboundMessage.id, isReply ? "true" : "false");
        try {
          const mailThreadResponse = await callApi({
            url,
            method
          });
          // data: selectedMail.inboundMessage,
          if (mailThreadResponse.success) {
            // mailThreadResponse.data['tagIds'] = "8e799e48-8de5-4dcd-8e2d-687b7e264910,2b0adcc5-7a09-42a0-b98c-a1064aea9ee5".split(',')
            const mailDetailWithThread: InboundMailWithThreadType = {
              ...selectedMail,
              mailThread: mailThreadResponse.data
            };
            // console.log("mailDetailWithThread", mailDetailWithThread)
            if (!selectedMail.inboundMessage.read) {
              markEmailAsRead(mailId);
            }
            // setMailDetail(null)
            return mailDetailWithThread;
          }
        } catch (err) {
        } finally {
          if (!isReply) {
            setIsLoading(false);
          } else {
            setLoadingMessage("");
          }
        }
      }
      return null;
    },
    [callApi, mails, markEmailAsRead, setLoadingMessage]
  );

  const getMailOnClick = useCallback(
    (clickedMailId: string) => async () => {
      // static value for campaign , this is for development purpose,will change in future
      if (selectedMail !== clickedMailId) {
        setNewSequenceName({
          type: null
        });
      }
      setSelectedMail(clickedMailId);
      const mailDetailWithThread = await getMailDetail(clickedMailId, false);
      if (mailDetailWithThread) {
        setMailDetail(mailDetailWithThread);
      }
    },
    [getMailDetail]
  );
  const getMailOnClicks = useCallback(
    async (clickedMailId: string) => {
      // static value for campaign , this is for development purpose,will change in future
      if (selectedMail !== clickedMailId) {
        setNewSequenceName({
          type: null
        });
      }
      setSelectedMail(clickedMailId);
      const mailDetailWithThread = await getMailDetail(clickedMailId, false);
      if (mailDetailWithThread) {
        if (isFromUnibox) {
          callUniboxComponentVariablesApi(mailDetailWithThread?.inboundMessage?.campaignId);
        }
        // fetchTags()
        setMailDetail(mailDetailWithThread);
        // delay(
        //   function (text) {
        //     fetchTags("getmaildetails");
        //   },
        //   2000,
        //   "later"
        // );
      }
    },
    [getMailDetail]
  );

  const callUniboxComponentVariablesApi = async (uniboxCampaignId: string) => {
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getProspectVariablesUrl(uniboxCampaignId);
    try {
      const response = await callApi({
        url,
        method
      });
      if (response.success) {
        setUniboxVariables(response?.data?.variables);
      }
    } catch (err) {}
  };

  const getOnStartSequence = (email: string, id: string, sequenceId: string, type: string) => {
    // console.log({ email, id, sequenceId, type });
    setMoveToSequence({
      type: type,
      prospect: email,
      id,
      sequenceId: sequenceId
    });
  };

  const getOnStartInfoSequence = useCallback(
    (email: string, id: string) => () => {
      // console.log("info sequence");

      setMoveToSequence({
        type: CampaignSequnceTypeEnum.INFO,
        prospect: email,
        id
      });
      onConfirmStartNewSequence();
    },
    []
  );

  const getOnStartPowerSequence = useCallback(
    (email: string, id: string) => () => {
      setMoveToSequence({
        type: CampaignSequnceTypeEnum.POWER,
        prospect: email,
        id
      });
    },
    []
  );

  const handleDiscardMail = useCallback(
    (mailId: string) => async () => {
      setLoadingMessage("Discarding...");
      const { url, method } = API_ENDPOINTS.INBOUND.getSpamMailUpdateUrl(mailId);
      const body: any = {
        action: "Discard"
      };

      try {
        await callApi({
          url,
          method,
          data: body
        });
        const mailDetailWithThread = await getMailDetail(mailId, false);
        if (mailDetailWithThread) {
          setMailDetail(mailDetailWithThread);
        }
      } catch (err) {
      } finally {
        setLoadingMessage("");
      }
    },
    [callApi, getMailDetail, setLoadingMessage]
  );

  const handleQualifyMail = useCallback(
    (mailId: string) => async () => {
      const mailDetailWithThread = await getMailDetail(mailId, false);
      if (mailDetailWithThread) {
        setMailDetail(mailDetailWithThread);
      }
    },
    [getMailDetail]
  );

  const getOnActivate = useCallback(
    (mailId: string) => async () => {
      setLoadingMessage("Activating...");
      const { url, method } = API_ENDPOINTS.INBOUND.getOnStatusUpdateUrl(mailId, "ready");
      const body: any = {
        subStatus: "Ready"
      };
      try {
        await callApi({
          url,
          method
          // data: body,
        });
      } catch (err) {
      } finally {
        setLoadingMessage("");
      }
    },
    [callApi, setLoadingMessage]
  );
  const getOnUnsubscribe = useCallback(
    (mailId: string) => async () => {
      setLoadingMessage("Unsubscribing...");
      const { url, method } = API_ENDPOINTS.INBOUND.getOnStatusUpdateUrl(mailId, "Unsubscribed");
      const body: any = {
        subStatus: "Unsubscribed"
      };
      try {
        const res = await callApi({
          url,
          method
          // data: body,
        });
        if (res.success) {
          notification.success({
            message: `Prospect ${mailDetail?.mailThread?.from?.address} has been unsubscribed from campaign ${mailDetail?.mailThread?.campaignName}`
          });
          const mailDetailWithThread = await getMailDetail(mailId, false);
          if (mailDetailWithThread) {
            setMailDetail(mailDetailWithThread);
          }
        }
      } catch (err) {
      } finally {
        setLoadingMessage("");
      }
    },
    [callApi, setLoadingMessage, mailDetail]
  );

  const getOnRemoveAllAccounts = useCallback(
    (mailId: string) => async () => {
      setLoadingMessage("Remove all prospects from domain ...");
      const { url, method } = API_ENDPOINTS.INBOUND.getOnStatusUpdateUrl(mailId, "Unsubscribed");
      const body: any = {
        // subStatus: "Unsubscribed",
        removeAccount: true
      };

      // console.log("body ::", body);
      try {
        const res = await callApi({
          url,
          method,
          data: body
        });
        if (res.success) {
          const domainName = mailDetail?.mailThread?.from?.address.split("@").pop() || "";
          notification.success({
            message: `All prospects having domain ${domainName} have been unsubscribed from all campaigns under account ${accountName}`
          });
          const mailDetailWithThread = await getMailDetail(mailId, false);
          if (mailDetailWithThread) {
            setMailDetail(mailDetailWithThread);
          }
        }
      } catch (err) {
      } finally {
        setLoadingMessage("");
      }
    },
    [callApi, setLoadingMessage, mailDetail, accountName]
  );

  const getOnReply = useCallback(
    (mailId: string) => async () => {
      const mailDetailWithThread = await getMailDetail(mailId, true);

      if (mailDetailWithThread) {
        setReplyToProspect(mailDetailWithThread);
      }
    },
    [getMailDetail]
  );

  const markEmailAsUnRead = useCallback(
    (mailId: string) => async () => {
      setLoadingMessage("Marking As Unread.");
      const { url, method } = API_ENDPOINTS.INBOUND.getMarkAsUnReadUrl(mailId);
      await callApi({ url, method });
      const selectedMailIndex = mails.findIndex(({ inboundMessage: { id } }) => id === mailId);
      if (selectedMailIndex !== -1) {
        const mailsCopy = [...mails];
        mailsCopy.splice(selectedMailIndex, 1, {
          ...mails[selectedMailIndex],
          inboundMessage: {
            ...mails[selectedMailIndex].inboundMessage,
            read: false
          }
        });
        setMails(mailsCopy);
      }
      setLoadingMessage("");
    },
    [callApi, mails]
  );
  const getOnStartFutureSequence = useCallback(
    (email: string, id: string) => () => {
      setFutureSequenceStartDate(null);
      setMoveToSequence({
        type: CampaignSequnceTypeEnum.FUTURE,
        prospect: email,
        id
      });
    },
    []
  );

  const getOnStartReferralSequence = useCallback(
    (email: string, id: string) => () => {
      setMoveToSequence({
        type: CampaignSequnceTypeEnum.REFERRAL,
        prospect: email,
        id
      });
    },
    []
  );

  const getOnStartMeetingSequence = useCallback(
    (email: string, id: string) => () => {
      setMoveToSequence({
        type: CampaignSequnceTypeEnum.MEETING_REQUEST,
        prospect: email,
        id
      });
    },
    []
  );

  const getOnStartRescheduleSequence = useCallback(
    (email: string, id: string) => () => {
      setMoveToSequence({
        type: CampaignSequnceTypeEnum.MEETING_RESCHEDULE,
        prospect: email,
        id
      });
    },
    []
  );

  const getOnStartCustomSequence = useCallback(
    (email: string, id: string) => () => {
      setMoveToSequence({
        type: CampaignSequnceTypeEnum.CUSTOM,
        prospect: email,
        id
      });
    },
    []
  );

  const handleRemoveMail = (mailId: string) => () => {
    console.log("handleRemoveMail");
    const selectedMailIndex = mails.findIndex(({ inboundMessage: { id } }) => id == mailId);

    if (selectedMailIndex !== -1) {
      const mailsCopy = [...mails];
      mailsCopy.splice(selectedMailIndex, 1);
      setMails(mailsCopy);
    }
  };

  const onDismissMailDetailModal = useCallback(() => setMailDetail(null), []);

  const onDismissMoveToSequenceModal = useCallback(() => {
    setMoveToSequence({ type: null, prospect: null, id: null });
    setIsLoading(false);
  }, []);

  const onConfirmStartNewSequence = useCallback(
    async (data?: any) => {
      if (moveToSequence.type === CampaignSequnceTypeEnum.FUTURE && !futureSequenceStartDate) {
        notification.destroy();
        notification.error({
          message: "Please select start date"
        });
        return;
      }
      // console.log("onConfirmStartNewSequence called");

      setIsLoading(true);

      // const sequenceName = moveToSequence.type
      //   ? getSequenceName(moveToSequence.type)
      //   : '';
      const sequenceName = moveToSequence.type;
      notification.destroy();
      //getMoveToSequenceUrl
      const { url, method } = API_ENDPOINTS.INBOUND.getMoveToSequenceUrl();
      let payLoad: any = {
        inboundMessageId: moveToSequence.id,
        sequenceType: moveToSequence.type,
        sequenceId: moveToSequence.sequenceId
      };
      if (moveToSequence.type === CampaignSequnceTypeEnum.FUTURE) {
        payLoad.sequenceStartDate = futureSequenceStartDate;
      }
      if (moveToSequence.type === CampaignSequnceTypeEnum.REFERRAL) {
        payLoad.refereeProspect = {
          campaignId: campaignId,
          timezone: data[0].timezone,
          attributes: data[0].attributes
        };
      }
      // console.log('payload', payLoad);
      try {
        const response = await callApi({
          url,
          method,
          data: payLoad
        });
        setIsLoading(false);
        if (!response.success) {
          return;
        } else {
          setNewSequenceName({ type: sequenceName });
        }
        notification.success({
          message: "Sequence started",
          description: `${sequenceName} started for ${moveToSequence.prospect}`
        });
        if (mailDetail) {
          getMailOnClicks(mailDetail.inboundMessage.id);
        }
        setMoveToSequence({
          type: null,
          prospect: null,
          id: null
        });
        setNewSequenceName({ type: sequenceName });
      } catch (err) {
      } finally {
      }

      // let response;
      // switch (moveToSequence.type) {
      //   case CampaignSequnceTypeEnum.INFO: {
      //     const {
      //       url,
      //       method,
      //     } = API_ENDPOINTS.INBOUND.getMoveToInfoSequenceUrl();

      //     response = await callApi({
      //       url,
      //       method,
      //       data: {
      //         inboundMessageId: moveToSequence.id,
      //       },
      //     });
      //     break;
      //   }
      //   case CampaignSequnceTypeEnum.POWER: {
      //     const {
      //       url,
      //       method,
      //     } = API_ENDPOINTS.INBOUND.getMoveToPowerSequenceUrl();

      //     response = await callApi({
      //       url,
      //       method,
      //       data: {
      //         inboundMessageId: moveToSequence.id,
      //       },
      //     });
      //     break;
      //   }
      //   case CampaignSequnceTypeEnum.FUTURE: {
      //     const {
      //       url,
      //       method,
      //     } = API_ENDPOINTS.INBOUND.getMoveToFutureSequenceUrl();

      //     response = await callApi({
      //       url,
      //       method,
      //       data: {
      //         messageId: moveToSequence.id,
      //         sequenceStartDate: futureSequenceStartDate?.valueOf(),
      //       },
      //     });
      //     break;
      //   }
      //   case CampaignSequnceTypeEnum.REFERRAL: {
      //     const {
      //       url,
      //       method,
      //     } = API_ENDPOINTS.INBOUND.getMoveToReferralSequenceUrl();

      //     const payload = {
      //       campaignId: campaignId,
      //       inboundMessageId: moveToSequence.id,
      //       sequenceStartDate: new Date().getTime(),
      //       refereeProspect: {
      //         campaignId: campaignId,
      //         timezone: data[0].timezone,
      //         attributes: data[0].attributes,
      //       },
      //     };
      //     response = await callApi({
      //       url,
      //       method,
      //       data: {
      //         ...payload,
      //       },
      //     });
      //     break;
      //   }
      //   case CampaignSequnceTypeEnum.MEETING_REQUEST: {
      //     const {
      //       url,
      //       method,
      //     } = API_ENDPOINTS.INBOUND.getMoveToMeetingSequenceUrl();

      //     response = await callApi({
      //       url,
      //       method,
      //       data: {
      //         inboundMessageId: moveToSequence.id,
      //       },
      //     });
      //     break;
      //   }
      //   case CampaignSequnceTypeEnum.MEETING_RESCHEDULE: {
      //     const {
      //       url,
      //       method,
      //     } = API_ENDPOINTS.INBOUND.getMoveToRescheduleSequenceUrl();

      //     response = await callApi({
      //       url,
      //       method,
      //       data: {
      //         inboundMessageId: moveToSequence.id,
      //       },
      //     });
      //     break;
      //   }
      //   case CampaignSequnceTypeEnum.CUSTOM: {
      //     const {
      //       url,
      //       method,
      //     } = API_ENDPOINTS.INBOUND.getMoveToCustomSequenceUrl();

      //     response = await callApi({
      //       url,
      //       method,
      //       data: {
      //         inboundMessageId: moveToSequence.id,
      //       },
      //     });
      //     break;
      //   }
      //   default:
      //     return;
      // }

      // setIsLoading(false);
      // if (!response.success) {
      //   return;
      // } else {
      //   setNewSequenceName({ type: sequenceName });
      // }

      // notification.success({
      //   message: "Sequence started",
      //   description: `${sequenceName} started for ${moveToSequence.prospect}`,
      // });
      // setMoveToSequence({
      //   type: null,
      //   prospect: null,
      //   id: null,
      // });
      // setNewSequenceName({ type: sequenceName });
    },
    [moveToSequence, futureSequenceStartDate, callApi, setNewSequenceName, mailDetail]
  );

  const onDismissReplyModal = useCallback(() => setReplyToProspect(null), []);

  const onSendReply = useCallback(
    async (reply: { toEmails: string[]; subject: string; body: string; ccsMails: string[] }) => {
      if (!replyToProspect) {
        return;
      }
      const replyToInbound: MailThreadType = {
        ...replyToProspect?.mailThread,
        from: {
          address: replyToProspect?.mailThread.tos[0].address || ""
        },
        tos: reply.toEmails.map((email: string) => ({ address: email })),
        html_body: reply.body,
        subject: reply.subject,
        ccs: reply.ccsMails.map((email: string) => ({ address: email }))
      };

      try {
        setLoadingMessage("Sending reply to inbound email.");
        const { url, method } = API_ENDPOINTS.INBOUND.getReplyToMailUrl(replyToProspect?.inboundMessage.id);
        const response = await callApi({
          url,
          method,
          data: replyToInbound
        });

        if (response.success) {
          notification.destroy();
          notification.success({
            message: `Reply sent to prospect.`
          });
        }
      } catch (error) {
      } finally {
        setLoadingMessage("");
        setReplyToProspect(null);
      }
    },
    [callApi, replyToProspect, setLoadingMessage]
  );
  const fetchInboundMails = async (pageInfo: any) => {
    // setMailDetail(null)
    // setIsLoading(true);

    let startDateInEpoch: any = "";
    let endDateInEpoch: any = "";
    if (dateRange) {
      startDateInEpoch = dateRange[0].startOf("day").valueOf();
      endDateInEpoch = dateRange[1].endOf("day").valueOf();
    }
    let sortKey = pageInfo?.sort ? pageInfo?.sort : sort;
    const { url, method } = API_ENDPOINTS.INBOUND.getTagResponseUrl(
      campaignId,
      tagId,
      pageInfo.page,
      pageInfo.pageSize,
      prospectEmail,
      startDateInEpoch,
      endDateInEpoch,
      readStatus,
      accountId || "",
      levelType,
      sortKey
    );
    try {
      const response = await callApi({ url, method });

      if (response.success) {
        const { messages, totalPages } = response.data;
        updateMail(messages);
        setPaginationOptions(() => ({
          totalPages
        }));
        setPageRefresh(false);
      }
    } catch (err) {
      setPageRefresh(false);
    }
    setIsLoading(false);
    setMainContainedChanged(false);
  };

  useEffect(() => {
    const callFn = async () => {
      if (tagId !== prevtagId && readStatus) await fetchInboundMails({ page: 1, pageSize: 10 });
      else if (readStatus) {
        await fetchInboundMails(pageInfo);
      }
    };

    callFn();
  }, [dateRange, readStatus, prospectEmail, accountId, levelType, tagId]);

  useEffect(() => {
    if (tagId) {
      setTagid(tagId);
      setPaginationOptions(() => ({
        totalPages: 0
      }));
      setMails([]);
      setPageInfo({ page: 1, pageSize: 10 });
      // fetchInboundMails({ page: 1, pageSize: 10 })
      setMailDetail(null);
      setMainContainedChanged(true);
      setNewSequenceName({ type: null });
      setState((prev: any) => ({ ...prev, selectedDiscardMailsIds: [] }));
    }
  }, [tagId]);

  /* extra */
  const updateMail = useCallback((messages) => {
    const clonemesages = cloneDeep(messages);
    setMails((prev) => uniqBy(prev.concat(clonemesages), "inboundMessage.id"));
  }, []);

  const fetchNewData = useCallback(
    (e) => {
      if (readStatus !== e) {
        console.log("eeeeeeeeee", e);
        setMails([]);
        setPageInfo({ page: 1, pageSize: 10 });
        setReadStatus(e);
        localStorage.setItem("readStatus", String(e));
        setMainContainedChanged(true);
      } else {
      }
    },
    [readStatus]
  );
  /* extra */

  useEffect(() => {
    // console.log("moveToSequence", moveToSequence);

    let sequenceType = ["POWER", "INFO", "CUSTOM", "MEETING_REQUEST", "MEETING_RESCHEDULE"];
    if (sequenceType.includes(moveToSequence.type || "")) {
      onConfirmStartNewSequence();
    }
  }, [moveToSequence]);

  const onChangeDateRange = (values: any) => {
    setMails([]);
    setPageInfo({ page: 1, pageSize: 10 });
    setDateRange(values);
    setMainContainedChanged(true);
  };

  const fetchResponseByEmail = (value: any) => {
    setMails([]);
    setPageInfo({ page: 1, pageSize: 10 });
    setProspectEmail(value);
    setMainContainedChanged(true);
  };

  const getOnTag = (selectedMailId: string) => () => {
    const selectedMail = mails.find(({ inboundMessage: { id } }) => selectedMailId === id);

    if (selectedMail) {
      setMailForTag(selectedMail);
    }
  };

  const getOnDeliver = (selectedMailId: string) => async () => {
    const selectedMail = mails.find(({ inboundMessage: { id } }) => selectedMailId === id);

    if (selectedMail) {
      setMailForDeliver(selectedMail);
    }
  };

  const onDismissDeliverModal = () => setMailForDeliver(null);

  const onDismissTagModal = () => setMailForTag(null);

  const onPaginationChange = useCallback((page, pageSize = DEFAULT_PAGE_SIZE) => {
    setPageInfo({ page, pageSize });
  }, []);

  const fetchMoreInboundMails = useCallback(
    (page, pageSize = DEFAULT_PAGE_SIZE) => {
      setPageInfo({ page, pageSize });
      fetchInboundMails({ page, pageSize });
    },
    [tagId, readStatus, sort]
  );

  const [numbers, setNumbers] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  // const [pages, setPage] = useState(0);

  const hasMoreData = numbers.length < 1000;

  const loadMoreNumbers = () => {
    // console.log("ca::call::call")
    // setPage((pages) => pages + 1);
    setPageInfo(({ page }) => ({ page: page + 1, pageSize: 10 }));

    setLoading(true);
    setTimeout(() => {
      const newNumbers = new Array(10).fill(1).map((_, i) => 1 * 10 + i);
      setNumbers((nums) => [...nums, ...newNumbers]);
      setLoading(false);
    }, 300);
  };

  // console.log("mails:::::", mails)
  // const sentryRef = useRef<any | null>(useInfiniteScroll({
  //   loading,
  //   hasNextPage: true,
  //   onLoadMore: () => { setPageInfo(({ page }) => ({ page: page + 1, pageSize: 10 })); },
  //   // When there is an error, we stop infinite loading.
  //   // It can be reactivated by setting "error" state as undefined.
  //   disabled: !!false,
  //   // `rootMargin` is passed to `IntersectionObserver`.
  //   // We can use it to trigger 'onLoadMore' when the sentry comes near to become
  //   // visible, instead of becoming fully visible on the screen.
  //   rootMargin: '0px 0px 400px 0px',
  // }));

  // const [sentryRef] = useInfiniteScroll({
  //   loading,
  //   hasNextPage: true,
  //   onLoadMore: () => { },
  //   // When there is an error, we stop infinite loading.
  //   // It can be reactivated by setting "error" state as undefined.
  //   disabled: !!false,
  //   // `rootMargin` is passed to `IntersectionObserver`.
  //   // We can use it to trigger 'onLoadMore' when the sentry comes near to become
  //   // visible, instead of becoming fully visible on the screen.
  //   rootMargin: '0px 0px 400px 0px',
  // })

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: true,
    onLoadMore: () => {
      console.log("callled::::::::::::::::");
    },
    disabled: !!false,
    rootMargin: "0px 0px 400px 0px"
  });

  const refreshContained = () => {
    if (tagIds) {
      setPageRefresh(true);
      // setTagid(tagId)
      setPaginationOptions(() => ({
        totalPages: 0
      }));
      setMails([]);
      setPageInfo({ page: 1, pageSize: 10 });
      fetchInboundMails({ page: 1, pageSize: 10 });
      if (!openMailDetailModal) {
        setMailDetail(null);
      }
      setMainContainedChanged(true);
      setSelectedMail("");
      setState((prev: any) => ({ ...prev, selectedDiscardMailsIds: [] }));
    }
  };
  const checkIsSpam = useMemo(() => {
    let isSpam = false;
    if (tagId && tagData) {
      const foundSpamID: any = tagData?.find((vl: any) => vl?.id === tagId);
      if (foundSpamID?.name === "Spam" || foundSpamID?.name === "Spam") isSpam = true;
    }
    return isSpam;
  }, [tagData, tagId]);

  const checkIsAll = useMemo(() => {
    let isAll = false;
    if (tagId && tagData) {
      const foundSpamID: any = tagData?.find((vl: any) => vl?.id === tagId);
      if (foundSpamID?.name === "All") isAll = true;
    }
    return isAll;
  }, [tagData, tagId]);

  const checkIsReplies = useMemo(() => {
    let isReplies = false;
    if (tagId && tagData) {
      const foundRepliesID: any = tagData?.find((vl: any) => vl?.id === tagId);
      if (foundRepliesID?.name === "Replies" || foundRepliesID?.name === "Replies") isReplies = true;
    }
    return isReplies;
  }, [tagData, tagId]);

  const checkSpamMessages = (id: string, event: any) => {
    event.stopPropagation();
    let copySelectedDiscardMailsIds = selectedDiscardMailsIds;
    if (copySelectedDiscardMailsIds.includes(id)) {
      copySelectedDiscardMailsIds = copySelectedDiscardMailsIds.filter((vl: any) => vl !== id);
    } else {
      copySelectedDiscardMailsIds.push(id);
    }
    setState((prev: any) => ({ ...prev, selectedDiscardMailsIds: copySelectedDiscardMailsIds }));
  };

  const discardBulkEmails = async () => {
    const { url, method } = API_ENDPOINTS.INBOUND.discardMails();
    const res = await callApi({ url, method, data: { ids: selectedDiscardMailsIds } });
    console.log(">>>>>>>>>>>>>>>>>>>>", res);
    if (res?.success) {
      refreshContained();
    }
  };

  const sortList = async () => {
    setState((prev: any) => ({ ...prev, sort: sort === "a" ? "d" : "a" }));
    setMails([]);
    setPageRefresh(true);
    if (!openMailDetailModal) {
      setMailDetail(null);
    }
    setIsLoading(true);
    await fetchInboundMails({ page: 1, pageSize: 10, sort: sort === "a" ? "d" : "a" });
  };

  const markAsReadEmails = async () => {
    const { url, method } = API_ENDPOINTS.INBOUND.markAsReadEmails();
    const res = await callApi({ url, method, data: { ids: selectedDiscardMailsIds } });
    console.log("resresresres", res);
    if (res?.success) {
      refreshContained();
    }
  };
  const fetchAccounts = async () => {
    const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";
    const { url, method } = API_ENDPOINTS.ACCOUNTS.getListUrl(uId || "");

    const response = await callApi({
      url,
      method
    });
    if (response.success) {
      let sortAlphabetic = orderBy(response?.data?.accounts, ["name"], ["asc"]);
      sortAlphabetic = concat([{ name: "All", id: "all" }], sortAlphabetic);
      setAccounts(sortAlphabetic);
    }
  };

  useEffect(() => {
    const callFn = async () => {
      await fetchAccounts();
      setSelectedCampaign(null);
      setCampaign([]);
      setSelectedUserId(null);
    };
    if (localStorage.getItem("selectedUserId") !== previousAccId) {
      callFn();
    }
  }, [localStorage.getItem("selectedUserId"), previousAccId]);

  // useEffect(() => {
  //   const callFn = async () => {
  //     const currId = localStorage.getItem("selectedUserId") || ''
  //     console.log("currIdcurrId", currId, previousAccId)
  //     await fetchAccounts()
  //     // await refreshContained()
  //   }
  //   if (localStorage.getItem("selectedUserId") !== previousAccId) {
  //     callFn()
  //   }
  // }, [localStorage.getItem("selectedUserId"), previousAccId])

  const callInboundMailsFunction = async (pageInfo: any, accId: string, campId: string) => {
    setPageRefresh(true);
    setPaginationOptions(() => ({
      totalPages: 0
    }));
    setMails([]);
    setPageInfo({ page: 1, pageSize: 10 });
    if (!openMailDetailModal) {
      setMailDetail(null);
    }
    setMainContainedChanged(true);
    setSelectedMail("");
    let startDateInEpoch: any = "";
    let endDateInEpoch: any = "";
    if (dateRange) {
      startDateInEpoch = dateRange[0].startOf("day").valueOf();
      endDateInEpoch = dateRange[1].endOf("day").valueOf();
    }
    let sortKey = pageInfo?.sort ? pageInfo?.sort : sort;
    const { url, method } = API_ENDPOINTS.INBOUND.getTagResponseUrl(
      campId,
      tagId,
      pageInfo.page,
      pageInfo.pageSize,
      prospectEmail,
      startDateInEpoch,
      endDateInEpoch,
      readStatus,
      accId !== "all" ? accId : "",
      levelType,
      sortKey
    );
    try {
      const response = await callApi({ url, method });
      if (response.success) {
        const { messages, totalPages } = response.data;
        updateMail(messages);
        setPaginationOptions(() => ({
          totalPages
        }));
        setPageRefresh(false);
      }
    } catch (err) {
      setPageRefresh(false);
    }
    setIsLoading(false);
    setMainContainedChanged(false);
  };

  const fetchUserbasedCampaing = async (e: string) => {
    if (selectedUserId !== e) {
      setSelectedCampaign(null);
      setCampaign([]);
      setSelectedUserId(e);
      setCampaingLoader(true);
      await callInboundMailsFunction(pageInfo, e, "");
      if (e !== "all") {
        // call campaign for active
        const { url, method } = API_ENDPOINTS.ACCOUNTS.getDetailUrlByFilter(e, "Active,Inactive,Completed");
        const allCampaign = await callApi({
          url,
          method
        });
        if (allCampaign.success) {
          let allCampaigns = allCampaign?.data?.campaigns || [];
          allCampaigns = orderBy(allCampaigns, ["name"], ["asc"]);
          setCampaingLoader(false);
          setCampaign(allCampaigns);
        }
      } else {
        setCampaingLoader(false);
        setCampaign([]);
      }
    }
  };
  const fetchInboundMailsOnCampaingSelection = useCallback(
    async (e: string) => {
      if (selectedCampaignId !== e) {
        setSelectedCampaign(e);
        await callInboundMailsFunction(pageInfo, "", e);
      }
    },
    [selectedCampaignId]
  );

  const markEmailAsProccessed = useCallback(
    (mailId: string) => async () => {
      console.log("1122222222222222222222");
      setLoadingMessage("Marking As Processed.");
      const { url, method } = API_ENDPOINTS.INBOUND.getMarkAsProcessedUrl(mailId);
      const response = await callApi({ url, method, data: {} });
      if (response?.success) {
        if (!openMailDetailModal) {
          setMailDetail(null);
        }
        refreshContained();
        notification.success({
          message: "Mark as Processed completed successfully"
        });
      }
      setLoadingMessage("");
    },
    [callApi, mails, openMailDetailModal]
  );

  // let filteredUniboxVariables = uniboxVariables?.filter((vl: any) => vl?.isMandatory)

  const openDetailsMessageOnModal = () => {};
  const modalDetailsPage = (mailDetails: any) => {
    return (
      <MailDetailModal
        levelType={levelType}
        getMailOnClick={() => getMailOnClick(mailDetails.inboundMessage.id)}
        fetchTags={() => {
          fetchTags();
          refreshContained();
          if (!openMailDetailModal) {
            setMailDetail(null);
          }
        }}
        onCancel={onDismissMailDetailModal}
        onStartInfoSequence={getOnStartInfoSequence(mailDetails.inboundMessage.from, mailDetails.inboundMessage.id)}
        onStartPowerSequence={getOnStartPowerSequence(mailDetails.inboundMessage.from, mailDetails.inboundMessage.id)}
        onStartFutureSequence={getOnStartFutureSequence(mailDetails.inboundMessage.from, mailDetails.inboundMessage.id)}
        onStartReferralSequence={getOnStartReferralSequence(mailDetails.inboundMessage.from, mailDetails.inboundMessage.id)}
        onStartMeetingSequence={getOnStartMeetingSequence(mailDetails.inboundMessage.from, mailDetails.inboundMessage.id)}
        onStartRescheduleSequence={getOnStartRescheduleSequence(mailDetails.inboundMessage.from, mailDetails.inboundMessage.id)}
        onStartCustomSequence={getOnStartCustomSequence(mailDetails.inboundMessage.from, mailDetails.inboundMessage.id)}
        tagData={tagData}
        onStartSequence={getOnStartSequence}
        onTag={getOnTag(mailDetails.inboundMessage.id)}
        onDeliver={getOnDeliver(mailDetails.inboundMessage.id)}
        onReply={getOnReply(mailDetails.inboundMessage.id)}
        onActivate={getOnActivate(mailDetails.inboundMessage.id)}
        onUnsubscribe={getOnUnsubscribe(mailDetails.inboundMessage.id)}
        onMarkAsUnRead={markEmailAsUnRead(mailDetails.inboundMessage.id)}
        onRemoveAccount={getOnRemoveAllAccounts(mailDetails.inboundMessage.id)}
        onDiscardMail={handleDiscardMail(mailDetails.inboundMessage.id)}
        onQualifyMail={handleQualifyMail(mailDetails.inboundMessage.id)}
        mail={mailDetails}
        sequences={sequences}
        sequenceName={newSequencename}
        onRemoveMails={handleRemoveMail(mailDetails.inboundMessage.id)}
        getMailOnClicks={() => getMailOnClicks(mailDetails.inboundMessage.id)}
        checkIsSpam={checkIsSpam}
        refreshContained={() => refreshContained()}
        isProccesed={isProccesed}
        onMarkAsProccessed={markEmailAsProccessed(mailDetails.inboundMessage.id)}
        checkIsReplies={checkIsReplies}
        openDetailsMessageOnModal={() => setMailDetailModalState(true)}
      />
    );
  };
  const openMailDetailOnmodal = (mailId: string) => {
    setMailDetailModalState(true);
    getMailOnClicks(mailId);
  };
  return (
    <>
      <Flex direction="column">
        <Flex mb={10}>
          <Flex align="center">
            {/* <Button
            type="primary"
            shape="circle"
            icon=""
            size="small"
            onClick={fetchInboundMails}
            disabled={isLoading}
          /> */}
            <Flex ml={0}>
              <Button
                type="primary"
                shape="circle"
                title="Refresh"
                icon={<ReloadOutlined spin={pageRefresh} />}
                size="small"
                onClick={() => refreshContained()}
              />
            </Flex>
            <Flex ml={10}>
              <Button
                type="primary"
                shape="circle"
                title="Sort"
                icon={sort === "a" ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
                size="small"
                onClick={() => sortList()}
              />
            </Flex>
            <Flex ml={10}>
              <DatePicker.RangePicker
                value={dateRange}
                onChange={onChangeDateRange}
                disabledDate={isDateGreaterThanToday}
                format="MM/DD/YYYY"
                disabled={isLoading}
              />
            </Flex>
            <Flex ml={10}>
              <Select value={readStatus} style={{ width: 100 }} onChange={(v) => fetchNewData(v)} disabled={isLoading}>
                <Select.Option value={MailReadStatus.ALL}>All</Select.Option>
                <Select.Option value={MailReadStatus.READ}>Read</Select.Option>
                <Select.Option value={MailReadStatus.UNREAD}>Unread</Select.Option>
              </Select>
            </Flex>

            <Flex ml={10}>
              <Input.Search disabled={isLoading} placeholder="Prospect email" onSearch={(value) => fetchResponseByEmail(value)} style={{ width: 200 }} />
            </Flex>

            {checkIsSpam && Array.isArray(selectedDiscardMailsIds) && selectedDiscardMailsIds.length > 0 ? (
              <Flex ml={10}>
                <Button onClick={discardBulkEmails}>Bulk Delete</Button>
              </Flex>
            ) : null}

            {checkIsAll && Array.isArray(selectedDiscardMailsIds) && selectedDiscardMailsIds.length > 0 ? (
              <Flex ml={10}>
                <Button onClick={markAsReadEmails}>Mark as Read</Button>
              </Flex>
            ) : null}

            <Flex ml={10}>
              <Select value={selectedUserId} style={{ width: 200 }} onChange={(v) => fetchUserbasedCampaing(v)} placeholder="Select account">
                {userAccounts?.map((acc) => {
                  return <Select.Option value={acc?.id}>{acc?.name}</Select.Option>;
                })}
              </Select>
            </Flex>

            <Flex ml={10}>
              <Select
                value={selectedCampaignId}
                style={{ width: 200 }}
                onChange={(v) => fetchInboundMailsOnCampaingSelection(v)}
                placeholder="Select campaign"
                disabled={!selectedUserId || campLoader || selectedUserId === "all"}
                dropdownMatchSelectWidth={false}
              >
                {allCampaignList?.map((acc: any) => {
                  return <Select.Option value={acc?.campId}>{acc?.name}</Select.Option>;
                })}
              </Select>
            </Flex>

            {/* <Flex ml={10}>
            <Select
              value={dayFilter}
              style={{ width: 'auto', minWidth: 120 }}
              onChange={setDayFilter}
              disabled={isLoading}
            >
              <Select.Option value={MailFilterDays.ALL}>All</Select.Option>
              <Select.Option value={MailFilterDays.TODAY}>Today</Select.Option>
              <Select.Option value={MailFilterDays.YESTERDAY}>Yesterday</Select.Option>
              <Select.Option value={MailFilterDays.LAST_7_Days}>Last 7 days</Select.Option>
              <Select.Option value={MailFilterDays.LAST_30_DAYS}>Last 30 days</Select.Option>
              <Select.Option value={MailFilterDays.LAST_60_DAYS}>Last 60 days</Select.Option>
            </Select>
          </Flex> */}
          </Flex>
          {/* <Flex align="center" ml="auto" position="relative">
          <Pagination
            simple
            current={pageInfo.page}
            showSizeChanger={false}
            onChange={onPaginationChange}
            total={paginationOptions.totalPages * pageInfo.pageSize}
          />
        </Flex> */}
        </Flex>
        <Flex mb={20} direction="column" position="relative">
          {/* {isLoading && (
          <Flex
            align="center"
            justify="center"
            position="absolute"
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 26 }} spin />}
            />
          </Flex>
        )} */}

          <Flex className={classes.emailSidebarContainer}>
            <Div className={classes.emailSidebar}>
              {/* {isLoading && pageInfo.page ===1  && !selectedMail ?<Div p={12}><LoadingSpinner/></Div> : null} */}
              {mainContainLoading ? (
                <>
                  <Div p={20}>
                    <Skeleton />
                  </Div>
                  <Div p={20}>
                    <Skeleton />
                  </Div>
                  <Div p={20}>
                    <Skeleton />
                  </Div>
                </>
              ) : !mails.length && !isLoading ? (
                <Div style={{ height: "100%", maxHeight: "100%", overflowY: "scroll" }} className={classes1.scrollContainer}>
                  <Flex mt={100} justify="center" style={{ textAlign: "center" }} direction="column">
                    <img src={NewMailImg} style={{ width: "100px", margin: "0 auto" }} alt="No Email" />
                    <Text variant="body1" mt={20} align="center" style={{ lineHeight: 1.2 }} block>
                      There are no responses yet.
                    </Text>
                  </Flex>
                </Div>
              ) : (
                <Div style={{ height: "100%", maxHeight: "100%", overflowY: "scroll" }} className={classes1.scrollContainer} id="scrollableDiv">
                  <InfiniteScroll
                    loader={
                      <Div p={12}>
                        <Skeleton />
                      </Div>
                    }
                    dataLength={mails && mails.length}
                    next={() => {
                      fetchMoreInboundMails(pageInfo.page + 1, pageInfo.pageSize);
                    }}
                    hasMore={paginationOptions.totalPages > pageInfo.page ? true : false}
                    scrollableTarget="scrollableDiv"
                  >
                    {mails?.map(({ inboundMessage: { id, subject, textPreview, read, sentTime, from } }) => (
                      <MailPreviewPane
                        key={id}
                        id={id}
                        subject={subject}
                        textPreview={textPreview}
                        isRead={read}
                        recievedAt={sentTime}
                        onClick={() => getMailOnClicks(id)}
                        from={from}
                        onStartInfoSequence={getOnStartInfoSequence(from, id)}
                        onStartPowerSequence={getOnStartPowerSequence(from, id)}
                        onStartFutureSequence={getOnStartFutureSequence(from, id)}
                        onStartReferralSequence={getOnStartReferralSequence(from, id)}
                        onTag={getOnTag(id)}
                        onDeliver={getOnDeliver(id)}
                        onReply={getOnReply(id)}
                        onMarkAsUnRead={markEmailAsUnRead(id)}
                        selectedMail={selectedMail}
                        isLoading={isLoading}
                        checkSpamMessages={checkSpamMessages}
                        isChecked={selectedDiscardMailsIds.includes(id)}
                        checkIsSpam={checkIsSpam}
                        checkIsAll={checkIsAll}
                      />
                    ))}
                  </InfiniteScroll>
                </Div>
              )}
              {/* {(loading || hasMoreData) && (
                  <div ref={sentryRef}>
                    <>loading</>
                  </div>
                )}  */}

              {/* <InfiniteScroll
                hasMoreData={hasMoreData}
                isLoading={loading}
                onBottomHit={loadMoreNumbers}
                loadOnMount={true}
              >
               
                
              </InfiniteScroll> */}
            </Div>

            <span className={classes.emailContent}>
              <Div style={{ height: "calc(100% - 25px)", maxHeight: "calc(100% - 25px)", overflowY: "scroll" }} className={classes1.scrollContainer}>
                {isLoading ? (
                  <Div p={20}>
                    <Skeleton />
                  </Div>
                ) : !openMailDetailModal && !mailDetail ? (
                  <>
                    <Flex mt={120} justify="center" direction="column">
                      <Div align="center" mt={10}>
                        <img src={ResponseMailBox} style={{ width: "150px" }} alt="No Email" />
                        <Text variant="body1" mt={30} align="center" style={{ lineHeight: 1.2 }} block>
                          No conversations selected
                        </Text>
                      </Div>
                    </Flex>
                  </>
                ) : mailDetail && !openMailDetailModal ? (
                  <MailDetailModal
                    levelType={levelType}
                    getMailOnClick={() => getMailOnClick(mailDetail.inboundMessage.id)}
                    fetchTags={() => {
                      fetchTags();
                      refreshContained();
                      if (!openMailDetailModal) {
                        setMailDetail(null);
                      }
                    }}
                    onCancel={onDismissMailDetailModal}
                    onStartInfoSequence={getOnStartInfoSequence(mailDetail.inboundMessage.from, mailDetail.inboundMessage.id)}
                    onStartPowerSequence={getOnStartPowerSequence(mailDetail.inboundMessage.from, mailDetail.inboundMessage.id)}
                    onStartFutureSequence={getOnStartFutureSequence(mailDetail.inboundMessage.from, mailDetail.inboundMessage.id)}
                    onStartReferralSequence={getOnStartReferralSequence(mailDetail.inboundMessage.from, mailDetail.inboundMessage.id)}
                    onStartMeetingSequence={getOnStartMeetingSequence(mailDetail.inboundMessage.from, mailDetail.inboundMessage.id)}
                    onStartRescheduleSequence={getOnStartRescheduleSequence(mailDetail.inboundMessage.from, mailDetail.inboundMessage.id)}
                    onStartCustomSequence={getOnStartCustomSequence(mailDetail.inboundMessage.from, mailDetail.inboundMessage.id)}
                    tagData={tagData}
                    onStartSequence={getOnStartSequence}
                    onTag={getOnTag(mailDetail.inboundMessage.id)}
                    onDeliver={getOnDeliver(mailDetail.inboundMessage.id)}
                    onReply={getOnReply(mailDetail.inboundMessage.id)}
                    onActivate={getOnActivate(mailDetail.inboundMessage.id)}
                    onUnsubscribe={getOnUnsubscribe(mailDetail.inboundMessage.id)}
                    onMarkAsUnRead={markEmailAsUnRead(mailDetail.inboundMessage.id)}
                    onRemoveAccount={getOnRemoveAllAccounts(mailDetail.inboundMessage.id)}
                    onDiscardMail={handleDiscardMail(mailDetail.inboundMessage.id)}
                    onQualifyMail={handleQualifyMail(mailDetail.inboundMessage.id)}
                    mail={mailDetail}
                    sequences={sequences}
                    sequenceName={newSequencename}
                    onRemoveMails={handleRemoveMail(mailDetail.inboundMessage.id)}
                    getMailOnClicks={() => getMailOnClicks(mailDetail.inboundMessage.id)}
                    checkIsSpam={checkIsSpam}
                    refreshContained={() => refreshContained()}
                    isProccesed={isProccesed}
                    onMarkAsProccessed={markEmailAsProccessed(mailDetail.inboundMessage.id)}
                    checkIsReplies={checkIsReplies}
                    openDetailsMessageOnModal={() => openMailDetailOnmodal(mailDetail.inboundMessage.id)}
                  />
                ) : null}
              </Div>
            </span>
          </Flex>
        </Flex>
        {moveToSequence.type === CampaignSequnceTypeEnum.FUTURE && (
          <Modal visible onOk={onConfirmStartNewSequence} onCancel={onDismissMoveToSequenceModal} okText="Start">
            <Div>
              <Text variant="sub2" block>
                Start Future sequence for {moveToSequence.prospect}
              </Text>
              <Div>
                <Text variant="overline" block mt={20} mb={10} color="secondary">
                  Sequence start date
                </Text>
                <DatePicker
                  disabledDate={isDateLesserThanToday}
                  showToday={false}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  value={futureSequenceStartDate}
                  onChange={setFutureSequenceStartDate}
                />
              </Div>
            </Div>
          </Modal>
        )}

        {moveToSequence.type === CampaignSequnceTypeEnum.REFERRAL && (
          <ReferralSequenceModal
            onOk={onConfirmStartNewSequence}
            onCancel={onDismissMoveToSequenceModal}
            variables={variables.length > 0 ? variables : uniboxVariables}
            isLoading={isLoading}
          />
        )}
        {replyToProspect && <ReplyModal onDismiss={onDismissReplyModal} onOk={onSendReply} inboundMailWithThread={replyToProspect} />}
        {/* {console.log("mails",mailDetail.inboundMessage)
      } */}
        {mailForDeliver && (
          <DeliverMailModal
            key={mailForDeliver.inboundMessage.id}
            onDismiss={onDismissDeliverModal}
            campaignId={campaignId}
            inboundMessageId={mailForDeliver.inboundMessage.id}
            tag={mailForDeliver.inboundMessage.tag === undefined || mailForDeliver.inboundMessage.tag === null ? "" : mailForDeliver.inboundMessage.tag}
          />
        )}
        {mailForTag && (
          <TagMailModal
            key={mailForTag.inboundMessage.id}
            onDismiss={onDismissTagModal}
            campaignId={campaignId}
            inboundMessageId={mailForTag.inboundMessage.id}
            fetchInboundMails={fetchInboundMails}
            pageInfo={pageInfo}
            tag={mailForTag.inboundMessage.tag === undefined || mailForTag.inboundMessage.tag === null ? "" : mailForTag.inboundMessage.tag}
          />
        )}
      </Flex>

      {openMailDetailModal && mailDetail && (
        <Modal
          visible={openMailDetailModal}
          onCancel={() => {
            setMailDetailModalState(false);
            setMailDetail(null);
          }}
          footer={null}
          width={1000}
        >
          {modalDetailsPage(mailDetail)}
        </Modal>
      )}
    </>
  );
};

export default InboundMails;
