import { API_ENDPOINTS } from "constants/api";
import { Dispatch } from "redux";
import {
  FETCH_CAMPAIGN_TOUCHES,
  FETCH_CAMPAIGN_TOUCHES_SUCCESS,
  FETCH_CAMPAIGN_PROSPECTS,
  FETCH_CAMPAIGN_PROSPECTS_SUCCESS,
  FETCH_SEQUENCES,
  FETCH_SEQUENCES_SUCCESS,
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TIMEZONES,
  FETCH_TIMEZONES_SUCCESS,
  FETCH_CAMPAIGN_DETAILS_SUCCESS,
} from "./actionTypes";
import Axios from "utils/axios";
import { ProspectStageEnum, ProspectStatusEnum } from "types/Prospect";
import { DEFAULT_PAGE_SIZE } from "constants/prospect";
import { CampaignType } from "types/Campaign";

class CampaignActions {
  static fetchTouches = (campaignId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_CAMPAIGN_TOUCHES,
      payload: { campaignId },
    });

    const { url, method } = API_ENDPOINTS.CAMPAIGN_TOUCH.getListUrl(
      campaignId,
      ""
    );

    try {
      const response = await Axios.callApi({
        url,
        method,
      });

      if (response.success) {
        dispatch({
          type: FETCH_CAMPAIGN_TOUCHES_SUCCESS,
          payload: { touches: response.data.touches || [], campaignId },
        });
      }
    } catch (err) { }

  };

  static fetchCampaignSuccess = (campaignDetail: CampaignType) => (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_CAMPAIGN_DETAILS_SUCCESS,
      payload: { campaignId: campaignDetail.id, campaignDetail },
    });
  }


  // static fetchProspects = (
  //   campaignId: string,
  //   filters: { status: ProspectStageEnum[]; subStatus: ProspectStatusEnum[], touches: any[] },
  //   pageInfo: { page: number; pageSize: number }
  // ) => async (dispatch: Dispatch) => {
  //   dispatch({
  //     type: FETCH_CAMPAIGN_PROSPECTS,
  //     payload: { campaignId },
  //   });

  //   const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getListUrl(
  //     campaignId
  //   );

  //   const { status = [], subStatus = [], touches = [] } = filters;
  //   const { page = 1, pageSize = DEFAULT_PAGE_SIZE } = pageInfo;

  //   try {
  //     const response = await Axios.callApi({
  //       url,
  //       method,
  //       data: {
  //         page,
  //         pageSize,
  //         status,
  //         subStatus,
  //         touches
  //       },
  //     });

  //     if (response.success) {
  //       dispatch({
  //         type: FETCH_CAMPAIGN_PROSPECTS_SUCCESS,
  //         payload: {
  //           prospects: response.data.prospects || [],
  //           campaignId,
  //           totalPages: response.data.totalPages || 1,
  //         },
  //       });
  //     }
  //   } catch (err) { }
  // };



  static fetchProspects = (
    campaignId: string,
    filters: {  subStatus: ProspectStatusEnum[], touches: any[], emailAddress: string, domain: string },
    pageInfo: { page: number; pageSize: number }
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_CAMPAIGN_PROSPECTS,
      payload: { campaignId },
    });

    const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getListUrl(
      campaignId
    );

    const { subStatus = [], touches = [], emailAddress, domain } = filters;
    const { page = 1, pageSize = DEFAULT_PAGE_SIZE } = pageInfo;

    try {
      const response = await Axios.callApi({
        url,
        method,
        data: {
          page,
          pageSize,
          subStatus,
          touches,
          emailAddress,
          domain
        },
      });

      if (response.success) {
        dispatch({
          type: FETCH_CAMPAIGN_PROSPECTS_SUCCESS,
          payload: {
            prospects: response.data.prospect || [],
            campaignId,
            totalPages: response.data.totalPages || 1,
          },
        });
      }
    } catch (err) { }
  };

  static fetchSequences = (campaignId: string) => async (
    dispatch: Dispatch
  ) => {
    dispatch({
      type: FETCH_SEQUENCES,
      payload: { campaignId },
    });

    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getSequencesListUrl(
      campaignId
    );

    try {
      const response = await Axios.callApi({
        url,
        method,
      });
      if (response.success) {
        dispatch({
          type: FETCH_SEQUENCES_SUCCESS,
          payload: { sequences: response.data.sequences || [], campaignId },
          // payload: { sequences: response.data.sequences || [], campaignId },
        });
      }
    } catch (err) { }
  };

  static fetchTags = (campaignId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_TAGS,
      payload: { campaignId },
    });

    const { url, method } = API_ENDPOINTS.CAMPAIGN_TAG.getTagListUrl(
      campaignId
    );

    try {
      const response = await Axios.callApi({
        url,
        method,
      });

      if (response.success) {
        dispatch({
          type: FETCH_TAGS_SUCCESS,
          payload: {
            tags: response.data.tags || [],
            campaignId,
          },
        });
      }
    } catch (err) { }
  };

  static fetchTimezones = (campaignId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_TIMEZONES,
      payload: { campaignId },
    });

    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getTimeZonesUrl();

    try {
      const response = await Axios.callApi({
        url,
        method,
      });

      if (response.success) {
        const TIMEZONES: any[] = []
        response.data.timezones.map((data: any) => {
          TIMEZONES.push({ tzCode: data.name, label: data.label });
        });
        dispatch({
          type: FETCH_TIMEZONES_SUCCESS,
          payload: {
            timezones: TIMEZONES,
            campaignId
          },
        });
      }
    } catch (err) { }
  };
}

export default CampaignActions;
