import { useCallback, useState } from "react";
import { Form, Input, Select, Radio, DatePicker, Modal, Switch } from "antd";
import moment from "moment";
import { Flex } from "styles/layout";

type ByDayType = {
  month: string;
  dayOfMonth: number;
};
type ByWeekType = {
  position: number;
  dayOfWeek: string;
  month: string;
};

type HolidayFormProps = {
  initialValues: HolidayItem | null;
  onOk: (data: HolidayItem) => void;
  onCancel: () => void;
  isLoading: boolean;
};

export interface HolidayItem {
  id?: string;
  name: string;
  fixedDate: string;
  recurringDay?: ByDayType;
  recurringWeek?: ByWeekType;
  isNew: boolean;
  // recurring?: { byDay?: ByDayType; byWeek?: ByWeekType };
}

export interface HolidayFormType {
  id?: string;
  name: string;
  fixedDate: any;
  isRecurring: boolean;
  recurring?: "recurringDay" | "recurringWeek";
  recurringWeek?: ByWeekType;
  recurringDay?: ByDayType;
}

export const MONTH_LIST = [
  { label: "January", days: 31, value: 1, valueStr: "January" },
  { label: "February", days: 29, value: 2, valueStr: "February" },
  { label: "March", days: 31, value: 3, valueStr: "March" },
  { label: "April", days: 30, value: 4, valueStr: "April" },
  { label: "May", days: 31, value: 5, valueStr: "May" },
  { label: "June", days: 30, value: 6, valueStr: "June" },
  { label: "July", days: 31, value: 7, valueStr: "July" },
  { label: "August", days: 31, value: 8, valueStr: "August" },
  { label: "September", days: 30, value: 9, valueStr: "September" },
  { label: "October", days: 31, value: 10, valueStr: "October" },
  { label: "November", days: 30, value: 11, valueStr: "November" },
  { label: "December", days: 31, value: 12, valueStr: "December" }
];

export const WEEK_LIST = [
  { label: "Sunday", value: 1, valueStr: "Sunday" },
  { label: "Monday", value: 2, valueStr: "Monday" },
  { label: "Tuesday", value: 3, valueStr: "Tuesday" },
  { label: "Wednesday", value: 4, valueStr: "Wednesday" },
  { label: "Thursday", value: 5, valueStr: "Thursday" },
  { label: "Friday", value: 6, valueStr: "Friday" },
  { label: "Saturday", value: 7, valueStr: "Saturday" }
];

export const POSITION_LIST = [
  { label: "1st", value: 1 },
  { label: "2nd", value: 2 },
  { label: "3rd", value: 3 },
  { label: "4th", value: 4 },
  { label: "last", value: 9 }
];

const MonthSelect = (props: any) => {
  return (
    <Select placeholder="Select month" {...props}>
      {MONTH_LIST.map((mnth) => (
        <Select.Option key={mnth?.label} value={mnth?.valueStr}>
          {mnth?.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const DateSelect = ({ range, ...rest }: any) => {
  return (
    <Select placeholder="Select date" {...rest}>
      {Array(range)
        .fill(0)
        .map((_, idx) => idx + 1)
        .map((day) => (
          <Select.Option key={day} value={day}>
            {day}
          </Select.Option>
        ))}
    </Select>
  );
};

const WeekSelect = (props: any) => {
  const weeks = WEEK_LIST;
  return (
    <Select placeholder="Select week" {...props}>
      {weeks.map((wk) => (
        <Select.Option key={wk.label} value={wk.valueStr}>
          {wk.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const PositionSelect = (props: any) => {
  const positions = POSITION_LIST;
  return (
    <Select placeholder="Select week" {...props}>
      {positions.map((wk) => (
        <Select.Option key={wk.label} value={wk.value}>
          {wk.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const ByDay = ({ value = {}, onChange }: any) => {
  const [month, setMonth] = useState("January");
  const [dayOfMonth, setDayOfMonth] = useState(1);
  const [range, setRange] = useState(() => MONTH_LIST.find((m) => m?.valueStr === month)?.days);

  const triggerChange = (changedValue: any) => {
    onChange?.({
      month,
      dayOfMonth,
      ...value,
      ...changedValue
    });
  };

  const onMonthChange = (e: string) => {
    setMonth(e);
    const findMonth = MONTH_LIST.find((vl) => vl?.valueStr === e);
    if (findMonth) {
      const res = dayOfMonth > findMonth.days ? findMonth.days : dayOfMonth;
      setDayOfMonth(res);
      setRange(findMonth.days);
      triggerChange({ dayOfMonth: res, month: e });
    }
  };

  const onDayChange = (e: number) => {
    setDayOfMonth(e);
    triggerChange({ dayOfMonth: e });
  };
  return (
    <Flex align="center">
      <span style={{ margin: "0 10px" }}>On Every</span>

      <MonthSelect
        style={{
          width: 120
        }}
        value={value?.month || month}
        onChange={onMonthChange}
      />
      <DateSelect
        style={{
          width: 100,
          margin: "0 10px"
        }}
        range={range}
        value={value?.dayOfMonth || dayOfMonth}
        onChange={onDayChange}
      />
    </Flex>
  );
};

const ByWeek = ({ value = {}, onChange }: any) => {
  const [month, setMonth] = useState("January");
  const [dayOfWeek, setDayOfWeek] = useState("Sunday");
  const [position, setPosition] = useState(1);

  const triggerChange = (changedValue: any) => {
    onChange?.({
      month,
      dayOfWeek,
      position,
      ...value,
      ...changedValue
    });
  };

  const onPositionChange = (e: number) => {
    setPosition(e);
    triggerChange({ position: e });
  };

  const onMonthChange = (e: string) => {
    setMonth(e);
    triggerChange({ month: e });
  };

  const onWeekChange = (e: string) => {
    setDayOfWeek(e);
    triggerChange({ dayOfWeek: e });
  };
  return (
    <Flex align="center">
      <span style={{ margin: "0 10px" }}>On the</span>

      <PositionSelect
        style={{
          width: 80,
          marginRight: 10
        }}
        value={value.position || position}
        onChange={onPositionChange}
      />

      <WeekSelect
        style={{
          width: 120
        }}
        value={value?.dayOfWeek || dayOfWeek}
        onChange={onWeekChange}
      />
      <span style={{ margin: "0 10px" }}>of</span>
      <MonthSelect
        style={{
          width: 120
        }}
        value={value.month || month}
        onChange={onMonthChange}
      />
    </Flex>
  );
};

export const HolidayForm = ({ initialValues, onOk, onCancel = () => {}, isLoading = false }: HolidayFormProps) => {
  const [holidayForm] = Form.useForm();
  const [updInitialValues] = useState<HolidayFormType>(() => ({
    ...(initialValues?.id && { id: initialValues.id }),
    name: initialValues?.name ?? "",
    fixedDate: initialValues?.fixedDate ? moment(initialValues?.fixedDate) : null,
    isRecurring: initialValues?.id ? !!initialValues?.recurringDay || !!initialValues?.recurringWeek : false,
    recurring: initialValues?.recurringWeek ? "recurringWeek" : "recurringDay",
    recurringWeek: initialValues?.recurringWeek ?? { position: 1, dayOfWeek: "Sunday", month: "January" },
    recurringDay: initialValues?.recurringDay ?? { month: "January", dayOfMonth: 1 }
  }));

  const updateData = useCallback((data: HolidayFormType): any => {
    const foo = {
      ...(data.id && { id: data.id }),
      name: data?.name,
      ...(!data?.isRecurring && { fixedDate: moment(data?.fixedDate).format("YYYY-MM-DD") }),
      ...(data?.isRecurring && (data.recurring === "recurringDay" ? { recurringDay: data?.recurringDay } : { recurringWeek: data?.recurringWeek }))
    };
    return foo;
  }, []);
  return (
    <Modal
      maskClosable={!isLoading}
      confirmLoading={isLoading}
      closable={!isLoading}
      visible
      title={(updInitialValues?.id ? "Update" : "Create") + " Holiday"}
      okText={updInitialValues?.id ? "Update" : "Create"}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={() => {
        holidayForm
          .validateFields()
          .then((values) => {
            onOk(updateData(values));
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form colon={false} form={holidayForm} initialValues={updInitialValues} autoComplete="off">
        <Form.Item noStyle name="id" />

        <Form.Item
          labelCol={{ span: 8 }}
          name="name"
          label="Holiday Name"
          rules={[
            {
              required: true,
              message: "Holiday name is required"
            },

            {
              type: "string",
              min: 2
            }
          ]}
        >
          <Input style={{ width: 200 }} placeholder="Christmas" />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.isRecurring !== currentValues.isRecurring}>
          {({ getFieldValue }) =>
            getFieldValue("isRecurring") === false ? (
              <Form.Item
                labelCol={{ span: 8 }}
                name="fixedDate"
                rules={[
                  {
                    required: true,
                    message: "Date is required"
                  }
                ]}
                label="Fixed Date"
              >
                <DatePicker />
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <Form.Item name="isRecurring" labelCol={{ span: 8 }} label="Recurring" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.isRecurring !== currentValues.isRecurring}>
          {({ getFieldValue }) =>
            getFieldValue("isRecurring") && (
              <Form.Item name="recurring" label=" ">
                <Radio.Group>
                  <Flex direction="column" justify="center">
                    <Flex align="center" pt={10} pb={10}>
                      <Radio value="recurringDay"></Radio>
                      <Form.Item name="recurringDay" style={{ margin: 0 }}>
                        <ByDay />
                      </Form.Item>
                    </Flex>
                    <Flex align="center" pt={10} pb={10}>
                      <Radio value="recurringWeek"></Radio>
                      <Form.Item name="recurringWeek" style={{ margin: 0 }}>
                        <ByWeek />
                      </Form.Item>
                    </Flex>
                  </Flex>
                </Radio.Group>
              </Form.Item>
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};
