import { Button, Form, Input, Modal, Typography } from "antd";
import { Div, Flex } from "styles/layout";
import { DARK_GRAY_COLOR } from "utils/colors";

interface UpdatePasswordModalProps {
  showText: string;
  updatePassword: (formValues: any) => void;
  handleUpdatePasswordModal: () => void;
}

const UpdatePasswordModal = ({
  showText,
  updatePassword,
  handleUpdatePasswordModal,
}: UpdatePasswordModalProps) => {
  const { Title } = Typography;
  const [updatePasswordform] = Form.useForm();


  return (
    <Modal visible footer={null} onCancel={() => handleUpdatePasswordModal()} width={300} centered>
      <Form scrollToFirstError form={updatePasswordform} requiredMark={false} onFinish={updatePassword}>
        <Title level={5}>Set a new password {showText}</Title>

        <Div>
          <label style={{ marginBottom: "5px" }} className="fs-12 fw-600 text-dark">
            New Password
          </label>
          <Form.Item
            name={"Password"}
            rules={[
              {
                required: true,
                message: "Please update password"
              }
            ]}
            style={{ marginLeft: "5px" }}
          >
            <Input.Password
              autoComplete="new-password"
              tabIndex={2}
              style={{
                width: 250,
                borderWidth: 0,
                paddingLeft: 2,
                boxShadow: "none",
                borderRight: "none",
                borderBottom: `1px solid ${DARK_GRAY_COLOR}`
              }}
              size="middle"
            />
          </Form.Item>
        </Div>
        <Flex direction="row" ml={10} justify="flex-end">
          <Flex></Flex>
          <Flex ml={10}>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default UpdatePasswordModal;
