import React, { useState, useEffect, createContext, useContext, ReactNode } from "react";
import { Auth, Hub } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { HubCallback } from "@aws-amplify/core/lib/Hub";
import { API_ENDPOINTS, BASE_URL } from "constants/api";
import { DEFAULT_THEME_CONFIG, ThemeDetails, THEME_MAP } from "constants/theme";
import { Theme } from "antd/lib/config-provider/context";
import { ThemeProvider } from "styled-components";

interface IAuthContext {
  user: CognitoUser | null;
  theme: ThemeDetails;
  login(username: string, password: string): Promise<CognitoUser>;
  logout(): Promise<void>;
  isCurrentSessionLoading: boolean;
  metaConfig: MetaConfig | null;
}

interface Props {
  children?: ReactNode;
}

export type MetaConfig = {
  // headerLogo: string;
  // logo?: string;
  // theme?: string;
  lastFetched: number;
  appLogoS3Url?: string;
  logoBackgroundColor?: string;
  colorTheme: string;
  appTitle: string;
  faviconUrl: string;
  supportEmail?:string
};
const META_STORAGE_KEY = "_mc";
const TTL_META_CONFIG = 0.5 * 3600 * 1000; // 0.5 hrs=30 mins
export const getCurrentTime = () => Date.now();

const getMetaConfig = () => {
  let res = null;
  try {
    const config = localStorage.getItem(META_STORAGE_KEY);
    res = config ? JSON.parse(config) : null;
  } catch (error) {}
  return res;
};

const login = (username: string, password: string): Promise<CognitoUser> => Auth.signIn(username, password);

export const logout = (): Promise<void> => {
  sessionStorage.removeItem("prospectFinder");
  localStorage.removeItem("account_id");
  localStorage.removeItem("accountName");
  localStorage.removeItem("accountTimezone");
  localStorage.removeItem("selectedUserId");
  localStorage.removeItem("integrationData");
  console.log("global signout")
  return Auth.signOut({global:true});
};

const useCognito = () => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [metaConfig, setMetaConfig] = useState<MetaConfig | null>(null);
  const [theme, setTheme] = useState<ThemeDetails>(DEFAULT_THEME_CONFIG);
  const [isCurrentSessionLoading, setIsCurrentSessionLoading] = useState<boolean>(true);

  const authListener: HubCallback = async ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
        setUser(data);
        break;
      case "signOut":
      case "signIn_failure":
      case "tokenRefresh_failure":
        setUser(null);
        break;
    }
  };

  useEffect(() => {
    const { url } = API_ENDPOINTS.META.config();
    const cachedConfig = getMetaConfig();
    const siteConfig = () =>
      cachedConfig && cachedConfig?.lastFetched + TTL_META_CONFIG > getCurrentTime()
        ? new Promise((res) => res(cachedConfig))
        : fetch(BASE_URL + url)
            .then((res) => res.json())
            .then((res) => res.data)
            .catch(() => null);

    Auth.currentAuthenticatedUser()
      .then(async (user) => setUser(user))
      .catch((err) => console.log(err))
      .then(async () => {
        const config = await siteConfig();
        localStorage.setItem(META_STORAGE_KEY, JSON.stringify(config));
        setMetaConfig(config);
        const antTheme = THEME_MAP[config.colorTheme] ?? THEME_MAP["default"];
        setTheme(antTheme);
      })
      .finally(() => setIsCurrentSessionLoading(false));
  }, []);

  useEffect(() => {
    Hub.listen("auth", authListener);
    return () => Hub.remove("auth", authListener);
  }, []);

  return { user, metaConfig, theme, login, logout, isCurrentSessionLoading };
};

export const AuthContext = createContext<IAuthContext>({
  user: null,
  metaConfig: null,
  theme: DEFAULT_THEME_CONFIG,
  isCurrentSessionLoading: true,
  login,
  logout
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: Props) => {
  const auth = useCognito();
  return (
    <AuthContext.Provider value={auth}>
      <ThemeProvider theme={auth.theme}>{children}</ThemeProvider>
    </AuthContext.Provider>
  );
};

export const getAccessToken = async () =>
  Auth.currentAuthenticatedUser().then((user: CognitoUser) => user.getSignInUserSession()?.getIdToken().getJwtToken() || null);
