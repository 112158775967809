import {
  FETCH_SPIN_TYPES,
  FETCH_SPIN_TYPES_SUCCESS,
} from "state/actions/actionTypes";

const reducer = (state: any = {}, action: { type: string; payload: any }) => {
  switch (action.type) {
    case FETCH_SPIN_TYPES: {
      return {
        ...state,
        types: [],
        loading: true,
      };
    }
    case FETCH_SPIN_TYPES_SUCCESS: {
      const { types } = action.payload;

      return {
        ...state,
        types,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
