import { Button, Form, Input, Modal, Typography } from "antd";
import { Div, Flex } from "styles/layout";
import classes from "../../Campaigns/styles.module.css";

interface VerifyAccessCodeModalProps {
  subMessage: string;
  submitAccessCode: (formValues: any) => void;
  handleVerifyAccessCodeModal: () => void;
}

const VerifyAccessCodeModal = ({
  subMessage,
  submitAccessCode,
  handleVerifyAccessCodeModal
}: VerifyAccessCodeModalProps) => {
  const { Title } = Typography;
  const [accessCodeValidation] = Form.useForm();


  return (
    <Modal visible footer={null} onCancel={() => handleVerifyAccessCodeModal()} width={300} centered>
      <Form scrollToFirstError form={accessCodeValidation} requiredMark={false} onFinish={submitAccessCode}>
        {/* <p style={{ marginBottom: '15px' }} className="fs-12">ddd.outwin@gmail.com</p> */}
        <Title level={5}>Enter Access Code</Title>
        <p style={{ marginBottom: "15px" }} className="fs-12">
          Enter your security code we sent to mail/phone <strong>{subMessage.split('at ')[1]}</strong>
        </p>
        <Div>
          <label className="fs-12 fw-600">Access Code *</label>
          <Form.Item
            name={"accessCode"}
            rules={[
              {
                required: true,
                message: "Please write Access code"
              }
            ]}
          >
            <Input tabIndex={4} className={classes.inputStyle} size="middle" />
          </Form.Item>
        </Div>
        <Flex direction="row" ml={10} justify="flex-end">
          <Flex ml={10}>
            <Button htmlType="submit" type="primary">
              Next
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default VerifyAccessCodeModal;
