import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import FullPageLoader from "components/Loader/FullPage";
import { useEffect } from "react";
import { TimeZoneContextProvider } from "contexts/timeZoneContext";
import UserListStateProvider from "contexts/userListContext";

export interface ProtectedRouteProps extends RouteProps {
  authenticationPath: string;
  title?: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { isCurrentSessionLoading, user, metaConfig } = useAuth();

  const { authenticationPath, title } = props;

  const isAuthenticated = React.useMemo(() => !!user, [user]);

  useEffect(() => {
    const appName = metaConfig?.appTitle ?? "Emy";
    document.title = title ? `${title} | ${appName}` : appName;
  }, [title, metaConfig]);

  if (isCurrentSessionLoading) {
    return <FullPageLoader message="" />;
  }

  return !isAuthenticated ? (
    <Route
      {...props}
      component={({ location }: RouteProps) => (
        <Redirect
          to={{
            pathname: authenticationPath,
            state: { from: location?.pathname }
          }}
        />
      )}
    />
  ) : (
    <UserListStateProvider>
      <TimeZoneContextProvider>
        <Route {...props} />
      </TimeZoneContextProvider>
    </UserListStateProvider>
  );
};
