import { API_ENDPOINTS } from "constants/api";
import { Dispatch } from "redux";
import { FETCH_SPIN_TYPES, FETCH_SPIN_TYPES_SUCCESS } from "./actionTypes";
import Axios from "utils/axios";
import { SpinType } from "types/Spinner";

class SpinnerActions {
  static fetch = () => async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_SPIN_TYPES,
      payload: {},
    });

    const { url, method } = API_ENDPOINTS.SPIN_TYPES.getListUrl();

    try {
      const response = await Axios.callApi({
        url,
        method,
      });

      if (response.success) {
        dispatch({
          type: FETCH_SPIN_TYPES_SUCCESS,
          payload: {
            types: response.data.spinTypes.sort(
              (a: SpinType, b: SpinType) => a.sequence - b.sequence
            ),
          },
        });
      }
    } catch (err) {}
  };
}

export default SpinnerActions;
