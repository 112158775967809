import { Progress } from "antd";
import { useAuth } from "auth/AuthProvider";
import { useEffect, useState } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";

export interface NumberChartProps {
  value: number | string;
  strokeColor?: string;
  percent: number;
}

const NumberChart = ({
  value,
  strokeColor,
  percent,
}: NumberChartProps) => {
  const [percentState, setPercentState] = useState(0);
  const { theme } = useAuth();

  useEffect(() => {
    setPercentState(0);
    setTimeout(() => setPercentState(percent), 300);
  }, [percent]);

  return (
    <Div align="center" mt={10}>
      <Progress
        type="circle"
        percent={percentState}
        format={() => (
          <Flex direction="column" align="center">
            <Flex>{value}</Flex>
            <Flex>
              <Text variant="caption" color="secondary" mt={10}>
                {percentState} %
              </Text>
            </Flex>
          </Flex>
        )}
        strokeColor={strokeColor ?? theme.antdConf.primaryColor}
        strokeWidth={8}
      />
    </Div>
  );
};

export default NumberChart;
