import { Table } from "antd";
import React, { ReactElement } from "react";

type ListLayoutProps<T extends object> = {
  loading?: boolean;
  list: T[];
  keyX: string;
  children(): ReactElement[];
  onClick?: (item: T) => void;
};

// export const ListLayout = <T extends object = any>(props: ListLayoutProps<T>) => {
export const ListLayout: React.FC<ListLayoutProps<any>> = (props) => {
  return (
    <Table
      dataSource={props.list}
      key={props.keyX}
      pagination={false}
      style={{ cursor: "pointer" }}
      loading={props.loading}
      onRow={(record) => {
        return {
          onClick: () => props?.onClick?.(record)
        };
      }}
    >
      {props.children && props.children()}
    </Table>
  );
};
