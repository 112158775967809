import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Form, Checkbox, Slider, Input, Select, Button, Space, Popover, Modal, FormInstance, DatePicker, Switch, InputNumber, Tooltip, Anchor } from "antd";
import { Flex, Div } from "styles/layout";
import { Text } from "styles/typography";
import moment from "moment";
import { InfoCircleFilled, InfoCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { humanizeHour } from "utils/dateTime";
import { useDispatch, useSelector } from "react-redux";
import { TimeZoneType } from "utils/dateTime";
import useApi from "hooks/useApi";
import TimeZones from "../../../commonAPIs/timezones";
import { FLAT_SILVER_COLOR } from "utils/colors";
import { Salesforce } from "types/Campaign";
import clipboardCopy from "clipboard-copy";
import { API_ENDPOINTS } from "constants/api";
import CampaignActions from "state/actions/campaign";

const { Option } = Select;

const getRequiredRules = (message: string) => [
  {
    required: true,
    message
  }
];

const SETTINGS_INITIAL_VALUE = {
  name: "New Campaign",
  // days: [2, 3, 4, 5, 6],
  sendEmailOnHolidays: false,
  rules: [],
  outboundBCC: '',
  autoRemoveEmailReplied: true,
  removeProspectEmailReplied: true,
  // autoRemoveEmailRepliedBy: "domain",
  // emailSendingHours: [8, 17],
  startDate: moment(),
  enableTargetDomainDailyLimit: true,
  // targetDomainDailyLimit: 10,
  targetDomainDailyLimitFor: "domain",
  dailyLimitPerTargetDomain: 10,
  // selectedTimezone: '',
  enableRestartInitialSeq: false,
  waitDaysForRestart: 1,
};

export interface CampaignSettingsType {
  name: string;
  // days: number[];
  outboundBCC?: string
  sendEmailOnHolidays: boolean;
  // autoRemoveEmailRepliedBy: "domain" | "location" | null;
  autoRemoveEmailReplied: boolean;
  // startDate: string;
  removeProspectEmailOpen: boolean;
  removeProspectEmailLinkClick: boolean;
  removeProspectEmailReplied: boolean;
  dailyLimitPerTargetDomain: number;
  enableSalesforce: boolean;
  enableQuickline: boolean;
  enableScrubby: boolean;
  salesforceMapping: Salesforce[];
  // startHour: number;
  // endHour: number;
  createdAt?: string;
  timezone?: string;
  startDate?: string;
  completedTime?: number;
  enableTargetDomainDailyLimit: boolean;
  targetDomainDailyLimitFor: string;
  endTime?: number
  // targetDomainDailyLimit: number;
  // selectedTimezone: string
  prospectsCreateEmailAddress?: string
  enableRestartInitialSeq: boolean,
  waitDaysForRestart: number
}

export interface CampaignSettingsProps {
  settings?: CampaignSettingsType;
  newCampaign?: boolean;
  campaignId?: string;
  onContinue: (settings: CampaignSettingsType) => void;
  afterSave: () => void;
  form: FormInstance<any>;
}

const Holidays = () => (
  <>
    <Text variant="sub2" block>
      4<sup>th</sup> July
    </Text>
    <Text variant="sub2" block>
      25<sup>th</sup> December
    </Text>
  </>
);
const marks = {};



const CampaignSettings = ({ settings, newCampaign = false, campaignId, onContinue, afterSave, form }: CampaignSettingsProps) => {
  const timezoneList = useSelector((state: any) => state?.campaign?.[campaignId as any]?.timezones?.entities);

  const [timezonArr, setTimezonArr] = useState<TimeZoneType[] | []>([]);
  const [showSalesForce, setShowSalesForce] = useState(false);
  const [enableSalesForceField, setEnableSalesForceField] = useState(false);
  const [showQuicklines, setShowQuicklines] = useState(false);
  const [leadStatus, setLeadStatus] = useState([]);
  const [salesforceMapping, setSalesforceMapping] = useState<Salesforce[]>([])
  const [timezone, setTimezone] = useState("")
  const [selectedTimezone, setSelectedTimeZone] = useState('')
  const [prospectsModalState, setProspectsModal] = useState(false)
  const [state, setState] = useState<any>({
    prospectsCreateEmailAddress: '', prospectEmailAddreValidation: true
  })
  const { callApi } = useApi();
  const [copyTooltipText, setCopyTooltipText] = useState(true);
  const dispatch = useDispatch();

  const fetchTimezones = async () => {

    const timezones = await TimeZones.getTimezones();
    try {
      let currTimeZone = timezones.filter((tz: any) => tz.tzCode === String(localStorage.getItem("accountTimezone")) ? tz.label : "");
      let valueTz = currTimeZone[0]['label'].split(" ").map((da: any) => da.charAt(0)).join("");
      setSelectedTimeZone(currTimeZone[0].tzCode)
      setTimezone(valueTz);
    } catch (err) { }
    setTimezonArr(timezones);
  };
  console.log("settings?.prospectsCreateEmailAddress", settings?.prospectsCreateEmailAddress)



  useEffect(() => {
    const integrationData = localStorage.getItem("integrationData") ? JSON.parse(String(localStorage.getItem("integrationData"))) : {}
    if (integrationData && Object.keys(integrationData).length) {
      const leadKey = ["Sent", "Opened", "OutOfOffice", "Ended", "Replied", "Unsubscribed", "Bounced"]
      setShowSalesForce(integrationData?.salesforce?.enable);
      setShowQuicklines(integrationData?.quicklines?.enable);
      setLeadStatus(integrationData?.salesforce?.leadStatus);
      if (settings && settings.salesforceMapping && settings?.salesforceMapping.length) {
        setEnableSalesForceField(settings?.enableSalesforce)
        if (settings?.salesforceMapping[0].hasOwnProperty('event')) {
          setSalesforceMapping(settings?.salesforceMapping)
        } else {
          setSalesforceMapping(leadKey.map(lk => ({ event: lk, enabled: false, sfdcLeadStatus: "" })))
        }
      } else {
        setSalesforceMapping(leadKey.map(lk => ({ event: lk, enabled: false, sfdcLeadStatus: "" })))
      }
    }
    fetchTimezones();
  }, [])

  useEffect(() => {
    if (selectedTimezone) {

    }
  }, [selectedTimezone])

  const formDefaultValue = useMemo(() => {
    if (settings) {
      console.log("settings.....", settings)
      const {
        ...rest
      } = settings;

      return {
        outboundBCC: settings?.outboundBCC,
        enableTargetDomainDailyLimit: true,
        // targetDomainDailyLimit: 10,
        dailyLimitPerTargetDomain: 20,
        targetDomainDailyLimitFor: "domain",
        ...(rest as any),
        startDate: moment(settings?.startDate),
        completedTime: settings?.completedTime ? moment(settings?.completedTime) : moment(),
        timezone: String(localStorage.getItem("accountTimezone")),
        selectedTimezone: String(localStorage.getItem("accountTimezone")),
        endTime: settings?.endTime ? moment(settings?.endTime) : null,
        enableRestartInitialSeq: false,
        waitDaysForRestart: 1,
      };
    }
    return SETTINGS_INITIAL_VALUE
  }, [settings]);


  const onSubmit = useCallback(
    (form: any) => {
      const {
        autoRemoveEmailReplied,
        name,
        enableScrubby,
        enableQuickline,
        sendEmailOnHolidays,
        enableSalesforce,
        removeProspectEmailOpen,
        removeProspectEmailLinkClick,
        removeProspectEmailReplied,
        dailyLimitPerTargetDomain,
        startDate,
        timezone,
        completedTime,
        enableTargetDomainDailyLimit,
        targetDomainDailyLimitFor,
        outboundBCC,
        endTime,
        enableRestartInitialSeq,
        waitDaysForRestart
        // targetDomainDailyLimit
      } = form

      salesforceMapping.map((sfm) => { sfm.enabled = form[sfm.event] ? form[sfm.event] : false; return sfm; })

      const newSettings: CampaignSettingsType = {
        autoRemoveEmailReplied,
        name,
        enableScrubby,
        enableSalesforce,
        enableQuickline,
        salesforceMapping,
        sendEmailOnHolidays,
        removeProspectEmailOpen,
        removeProspectEmailLinkClick,
        removeProspectEmailReplied,
        // dailyLimitPerTargetDomain: parseInt(dailyLimitPerTargetDomain),
        timezone,
        ...(startDate && { startDate: startDate?.format("MM/DD/YYYY") }),
        completedTime: completedTime?.unix(),
        enableTargetDomainDailyLimit,
        targetDomainDailyLimitFor,
        dailyLimitPerTargetDomain,
        outboundBCC,
        endTime: moment(endTime).unix(),
        enableRestartInitialSeq,
        waitDaysForRestart
        // targetDomainDailyLimit
      };

      onContinue(newSettings);
    },
    [salesforceMapping, onContinue]
  );

  const getOnChange = (label: string, value: string, checked: boolean) => {
    const changesValue = salesforceMapping.map((sfm) => {
      if (sfm.event === label) {
        if (value.length) { sfm.sfdcLeadStatus = value }
        else { sfm.enabled = checked }
      }
      return sfm
    })
    setSalesforceMapping(changesValue)
  }

  const openProspectsEmailModal = (boolState: boolean) => {
    setProspectsModal(boolState)
  }

  const generateProspectsEmailAddrees = async () => {
    if (state?.prospectEmailAddreValidation && campaignId) {
      const { url, method } = API_ENDPOINTS.INBOUND.generateProspectsCreationEmail(campaignId);
      const response = await callApi({
        url,
        method,
        data: { prospectsCreateEmailAddress: `${state?.prospectsCreateEmailAddress}@campaign.getemy.io` },
      });
      openProspectsEmailModal(false)
      if (response.success) {
        afterSave()
      }
    }
  }

  const addProspoectsEmailAddress = (value: string) => {
    if (/[-’/`~!#*$@%+=,^&(){}[\]|;:”<>?\\]/g.test(value)) {
      setState((prev: any) => ({ ...prev, prospectEmailAddreValidation: false }))
    } else {
      setState((prev: any) => ({ ...prev, prospectEmailAddreValidation: true }))
    }
    setState((prev: any) => ({ ...prev, prospectsCreateEmailAddress: value }))
  }

  const CopyTooltip = (e: any) => {
    e.stopPropagation();
    clipboardCopy(formDefaultValue?.prospectsCreateEmailAddress);
    setCopyTooltipText(false);
  };
  console.log("campaignSettingscampaignSettings", settings)
  console.log("formDefaultValueformDefaultValue", formDefaultValue)
  return (
    <>
      <Div>
        <Form
          scrollToFirstError
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          autoComplete="off"
          initialValues={formDefaultValue}
          fields={[
            {
              name: ["selectedTimezone"],
              value: selectedTimezone
            },

          ]}
        >
          <Div mb={-10} style={{ width: "300px" }}>
            <Text variant="overline" block mb={10} color="secondary">
              Campaign name
            </Text>
            <Form.Item name="name" rules={getRequiredRules("")}>
              <Input />
            </Form.Item>
          </Div>
          <Div mb={-10} style={{ width: "350px" }}>
            <Text variant="overline" block mb={10} color="secondary" >
              Send a copy of all outbound emails (BCC){' '}
              <Tooltip placement="rightTop" title="For each outbound email, send BCC to this email address">
                <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f", fontSize: "14px" }} />
              </Tooltip>
            </Text>
            <Form.Item
              name={"outboundBCC"}
              rules={[
                {
                  type: "email",
                  message: "Please enter valid email",
                }
              ]}
            >
              <Input />
            </Form.Item>

          </Div>
          {/* <Div mt={20}>
          <Text variant="overline" block mb={10} color="secondary">
            Holidays
          </Text>
          <Form.Item name="sendEmailOnHolidays" valuePropName="checked" noStyle>
            <Switch />
          </Form.Item>
          <Text variant="sub2" ml={10}>
            Send emails during standard US holidays{" "}
            <Popover content={Holidays} trigger="hover">
              <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f" }} />
            </Popover>
          </Text>
        </Div> */}
          <Div mt={20}>
            <Text variant="overline" block mb={10} color="secondary">
              Rules
            </Text>
            {/* <Div>
            <Form.Item name="removeProspectEmailReplied" noStyle valuePropName="checked">
              <Switch />
            </Form.Item>
            <Text variant="sub2" ml={10}>
              Stop sequence when replied
            </Text>
          </Div> */}
            <Flex mt={10}>
              <Form.Item name="autoRemoveEmailReplied" valuePropName="checked" noStyle>
                <Switch />
              </Form.Item>
              <Text variant="sub2" ml={10}>
                When a reply is received, stop sequence for prospects that belong to the same Domain
              </Text>
            </Flex>
            <Flex mt={10}>
              <Form.Item name="sendEmailOnHolidays" valuePropName="checked" noStyle>
                <Switch />
              </Form.Item>
              <Text variant="sub2" ml={10}>
                Send emails during holidays{" "}
                {/* <Popover content={Holidays} trigger="hover">
                <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f" }} />
              </Popover> */}
              </Text>
            </Flex>
            <Flex mt={10}>
              <Form.Item name="enableTargetDomainDailyLimit" valuePropName="checked" noStyle>
                <Switch />
              </Form.Item>
              <Text variant="sub2" ml={10}>
                Daily Limit{" "}
                {/* <Popover content={"hello bla bla"} trigger="hover">
                <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f" }} />
              </Popover> */}
              </Text>
            </Flex>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues.enableTargetDomainDailyLimit !== currentValues.enableTargetDomainDailyLimit;
              }}
            >
              {({ getFieldValue }) =>
                getFieldValue("enableTargetDomainDailyLimit") ? (
                  <Flex align="center" wrap="wrap" mt={6} gap={10} style={{ marginLeft: '54px' }}>
                    <Text variant="sub2" style={{ whiteSpace: "nowrap" }}>
                      Every day, send maximum
                    </Text>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      name="dailyLimitPerTargetDomain"
                      rules={[{ required: true, message: 'Required' }, { type: "number", min: 1, max: 99, message: 'Range 1 to 99' }]}
                    >
                      <InputNumber min={1} max={99} style={{ width: "120px" }} />
                    </Form.Item>

                    <Text variant="sub2" style={{ whiteSpace: "nowrap" }}>
                      emails from each
                    </Text>
                    <Form.Item name="targetDomainDailyLimitFor" rules={[{ required: true, message: 'Required' }]} style={{ marginBottom: 0 }}>
                      <Select style={{ width: 150, marginBottom: 0 }}>
                        {[
                          { id: "domain", label: "Mailbox Domain" },
                          { id: "address", label: "Mailbox Address" }
                        ].map((pr) => (
                          <Option key={pr.id} value={pr.id}>
                            <Div>
                              <Text variant="sub2">{pr.label}</Text>
                            </Div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Text variant="sub2" style={{ whiteSpace: "nowrap" }}>
                      to each target company domain
                    </Text>
                  </Flex>
                ) : null
              }
            </Form.Item>

            <Flex mt={10}>
              <Form.Item name="enableRestartInitialSeq" valuePropName="checked" noStyle>
                <Switch />
              </Form.Item>
              <Text variant="sub2" ml={10}>
                Enable initial sequence auto restart&nbsp;
                <Tooltip title="By enabling this functionality, the system will automatically send touch1 from the initial sequence after finishing all the touches with a certain number of days as a waiting period." placement="rightTop">
                  <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f", fontSize: "14px" }} />
                </Tooltip>
              </Text>
            </Flex>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues.enableRestartInitialSeq !== currentValues.enableRestartInitialSeq;
              }}
            >
              {({ getFieldValue }) =>
                getFieldValue("enableRestartInitialSeq") ? (
                  <Flex align="center" wrap="wrap" mt={6} gap={10} style={{ marginLeft: '54px' }}>
                    <Text variant="sub2" style={{ whiteSpace: "nowrap" }}>
                      Wait for
                    </Text>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      name="waitDaysForRestart"
                      rules={[{ required: true, message: 'Required' }, { type: "number", min: 1, max: 31, message: 'Range 1 to 31' }]}
                    >
                      <InputNumber min={1} max={99} style={{ width: "120px" }} />
                    </Form.Item>

                    <Text variant="sub2" style={{ whiteSpace: "nowrap" }}>
                    days after sending last touch and then automatically restart all touches from initial sequence
                    </Text>
                  </Flex>
                ) : null
              }
            </Form.Item>
          </Div>
          {showQuicklines || showSalesForce ?
            <Div mt={20}>
              <Text variant="overline" block mb={10} color="secondary">
                INTEGRATION
              </Text>
            </Div> : null
          }

          <Div>
            {/* {!newCampaign ?
            <Div>
              <Form.Item name="enableScrubby" noStyle valuePropName="checked">
                <Switch />
              </Form.Item>
              <Text variant="sub2" ml={10}>
                Enable Scrubby
              </Text>
            </Div> : null
          } */}

            {/* name="enableQuickline" */}
            {showQuicklines ? (
              <Div mt={10} mb={10}>
                <Form.Item name="enableQuickline" valuePropName="checked" noStyle>
                  <Switch />
                </Form.Item>
                <Text variant="sub2" ml={10}>
                  Enable Quicklines
                </Text>
              </Div>
            ) : null}
            {showSalesForce && (
              <>
                <Div>
                  <Form.Item name="enableSalesforce" noStyle valuePropName="checked">
                    {/* <Checkbox checked={enableSalesForceField} onChange={(e) => setEnableSalesForceField(e.target.checked)} /> */}
                    <Switch checked={enableSalesForceField} onChange={(e) => setEnableSalesForceField(e)} />
                    <Text variant="sub2" ml={10} mb={10}>
                      Enable Salesforce
                    </Text>
                  </Form.Item>
                </Div>

                {enableSalesForceField ? (
                  <Div
                    style={{ border: "1px solid #e3e2e1", padding: "10px 10px 0 10px", marginTop: "5px", width: "400px" }}
                  >
                    <Div mb={10}>
                      <Text variant="overline" block mb={10} color="secondary">
                        Salesforce Mapping Fields
                      </Text>
                    </Div>
                    {salesforceMapping && salesforceMapping.length
                      ? salesforceMapping.map((lk) => {
                        return (
                          <Div>
                            <Form.Item name={lk.event} style={{ marginBottom: "10px" }} valuePropName="checked">
                              <Flex>
                                <Div style={{ width: "150px" }}>
                                  <Switch checked={lk.enabled} onChange={(e) => getOnChange(lk.event, "", e)} />
                                  <Text variant="sub2" ml={10} className="gray">
                                    {lk.event}
                                  </Text>
                                </Div>
                                <Div style={{ width: "250px" }}>
                                  <Select
                                    defaultValue={
                                      lk.sfdcLeadStatus && lk.sfdcLeadStatus.length ? lk.sfdcLeadStatus : ""
                                    }
                                    onChange={(e) => getOnChange(lk.event, e, false)}
                                  >
                                    {leadStatus && leadStatus.length
                                      ? leadStatus.map((col) => (
                                        <Option key={col} value={col} title={col}>
                                          {col}
                                        </Option>
                                      ))
                                      : null}
                                  </Select>
                                </Div>
                              </Flex>
                            </Form.Item>
                          </Div>
                        );
                      })
                      : null}
                  </Div>
                ) : null}
              </>
            )}
          </Div>

          {settings?.startDate && (
            <Div mt={20}>
              <Text variant="overline" block mb={5} color="secondary">
                Start date
              </Text>
              {moment(settings?.startDate).isBefore(new Date(), "day") ? (
                <Div>
                  <Form.Item name="startDate" noStyle>
                    <DatePicker
                      style={{ width: "300px" }}
                      format="YYYY-MM-DD"
                      disabledDate={(current) => current && current > moment().endOf("day")}
                    />
                  </Form.Item>
                </Div>
              ) : (
                <Div>
                  <Text variant="sub2"> {moment(settings?.startDate).format("MMM DD, YYYY, H:mm:ss A")}</Text>
                </Div>
              )}
            </Div>
          )}

          <Div mt={20}>
            {/* <Text variant="overline" block mb={5} color="secondary">
            Completion date
          </Text> */}
            <Text variant="overline" block mb={3} color="secondary" >
              PROSPECTS CREATION BY EMAIL{' '}
              <Tooltip overlayStyle={{ maxWidth: formDefaultValue?.prospectsCreateEmailAddress ? '500px' : '350px' }} placement="rightTop" title={formDefaultValue?.prospectsCreateEmailAddress ?
                <p style={{ alignItems: "center" }}>
                  You can upload prospects to this campaign by sending an email to this email address.
                  <br /><br />
                  Make sure the email subject contains the prospects data in the following format. You may leave the email body empty as it won't be used.
                  <br /><br />
                  Subject Format: firstname lastname email <br />
                  Subject Example: Tom Brady tom.brady@nbc.com
                </p> :
                <p>You can upload prospects to this campaign directly from your mailbox by sending an email to a custom email address that you can setup in EMY</p>
              }>
                <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f", fontSize: "14px" }} />
              </Tooltip>
            </Text>
            <Div>
              <Form.Item name="prospectsCreateEmailAddress" noStyle>
                {!formDefaultValue?.prospectsCreateEmailAddress ? (<a onClick={() => openProspectsEmailModal(true)}>
                  Setup Email Service</a>) :
                  <span>{formDefaultValue?.prospectsCreateEmailAddress}
                    <Text variant="caption" ml={5}>
                      <Tooltip
                        title={copyTooltipText ? "Click to Copy Email" : "Copied"}
                        mouseLeaveDelay={0}
                        placement="top"
                      >
                        <Button onClick={(e) => CopyTooltip(e)} style={{ border: '0', padding: "5px" }}>
                          <CopyOutlined
                            onMouseLeave={() => {
                              setTimeout(() => setCopyTooltipText(true), 100);
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Text>
                  </span>
                }
              </Form.Item>
            </Div>
          </Div>

          <Div mt={20}>
            {/* <Text variant="overline" block mb={5} color="secondary">
            Completion date
          </Text> */}
            <Text variant="overline" block mb={10} color="secondary" >
              Completion date{' '}
              <Tooltip placement="rightTop" title="Populate this field to a future date if you like EMY to automatically mark this campaign as completed on that date. If you don't set it, EMY will automatically complete this campaign when all the touches for all prospects are sent out.">
                <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f", fontSize: "14px" }} />
              </Tooltip>
            </Text>
            <Div>
              <Form.Item name="endTime" noStyle>
                <DatePicker
                  style={{ width: "300px" }}
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={(current) => current && current < moment().endOf("day")}
                  showTime={{ defaultValue: moment("00:00:00", "HH:mm") }}
                />
              </Form.Item>
            </Div>
          </Div>
          {newCampaign ? (
            <>
              <Div mt={20}>
                <Text variant="overline" block mb={5} color="secondary">
                  Timezone
                </Text>
                <Div>
                  <Form.Item name="selectedTimezone" noStyle  >
                    <Select
                      placeholder="Timezone"
                      style={{ width: "300px" }}
                      disabled={timezonArr && timezonArr.length ? true : false}
                      defaultValue={selectedTimezone}
                      onChange={(v) => setSelectedTimeZone(v)}
                    >
                      {timezonArr &&
                        timezonArr.map(({ label, tzCode }: TimeZoneType) => (
                          <Option value={tzCode}>
                            {label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Div>
              </Div>
              {settings?.createdAt && (
                <Div mt={20}>
                  <Text variant="overline" block mb={5} color="secondary">
                    Created date
                  </Text>
                  <Div>
                    <Text variant="sub2"> {settings?.createdAt}</Text>
                  </Div>
                </Div>
              )}
            </>
          ) : (
            <>
              {settings?.createdAt && (
                <Div mt={20}>
                  <Text variant="overline" block mb={5} color="secondary">
                    Created date
                  </Text>
                  <Div>
                    <Text variant="sub2">
                      {" "}
                      {settings?.createdAt} {timezone}
                    </Text>
                  </Div>
                </Div>
              )}
            </>
          )}
        </Form>
      </Div>
      {
        prospectsModalState
        &&
        <Modal
          visible
          onCancel={() => openProspectsEmailModal(false)}
          footer={null}
          width={600}
          centered

        >
          <Text variant="h6" mb={15} mt={5} color={"secondary"}
            style={{
              color: "#000"
            }}
          >
            Prospects Creation Setup
          </Text>

          <Text variant="sub1" mb={9}>
            You can upload prospects to this campaign by sending an email to this email address.
          </Text>

          <Text variant="sub1" mb={9}>
            Make sure the email subject contains the prospects data in the following format. You may leave the email body empty as it won't be used.
          </Text>
          <Text variant="sub1" mb={9}>
            Subject Format: firstname lastname email <br />
            Subject Example: Tom Brady tom.brady@nbc.com
          </Text>
          <div style={{
            maxWidth: "75%"
          }}>
            <label style={{
              fontWeight: "500",
              marginBottom: "5px",
              display: "inline-block",
              marginTop: "15px",
              fontSize: "16px"
            }}>
              Email Service Address
            </label>

            {/* <input type="text" onChange={(e) => addProspoectsEmailAddress(e.target.value)} value={state?.prospectsCreateEmailAddress} /> */}
            <Input
              style={{ color: "#000" }}
              size="middle"
              onChange={(e) => addProspoectsEmailAddress(e.target.value)}
              value={state?.prospectsCreateEmailAddress}
              type="text"
              addonAfter="@campaign.getemy.io"
              placeholder="e.g spring_campaign"
            />
            {!state?.prospectEmailAddreValidation && <p className="ant-form-item-explain-error">Special character not allowed</p>}
          </div>
          <Flex mb={10} justify="flex-end" mt={20}>
            <Space>
              <Button type="primary" onClick={generateProspectsEmailAddrees} disabled={!state?.prospectEmailAddreValidation || !state?.prospectsCreateEmailAddress}>
                Generate
              </Button>
            </Space>
          </Flex>
        </Modal>
      }
    </>
  );
};

export default CampaignSettings;