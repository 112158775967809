import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import { CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

const copyTextToClipboard = async (text: string) => {
  return "clipboard" in navigator ? await navigator.clipboard.writeText(text) : document.execCommand("copy", true, text);
};

type CopyToClipboardProps = {
  text: string;
  children: React.ReactNode;
  onCopy: (text: string, result: boolean | void) => void;
  options?: {
    debug: boolean;
    message: string;
    format: string;
  };
};

export const CopyToClipboard = (props: CopyToClipboardProps) => {
  const onClick = useCallback(
    async (event) => {
      const { text, onCopy, children, options } = props;
      const elem = React.Children.only(children) as any;
      const result = await copyTextToClipboard(text);
      onCopy && onCopy(text, result);
      //  forward onClick to child
      if (elem && elem?.props && typeof elem.props.onClick === "function") {
        elem.props.onClick(event);
      }
    },
    [props]
  );

  return React.cloneElement(React.Children.only(props.children as any), { ...props, onClick });
};

type CopyIconProps = {
  text?: string;
};
export const CopyIcon = (props: CopyIconProps) => {
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard text={props.text ?? ""} onCopy={() => setCopied(true)}>
      <Tooltip title={copied ? "Copied" : "Click to copy"} mouseLeaveDelay={0} placement="top">
        <Button
          style={{
            border: "none",
            width: "auto",
            height: "auto"
          }}
          onClick={() => setCopied(true)}
          onMouseLeave={() => setTimeout(() => copied && setCopied(false), 100)}
          icon={<CopyOutlined />}
        ></Button>
      </Tooltip>
    </CopyToClipboard>
  );
};
