import React, { useState, ReactNode } from 'react';
import { Modal, Button, Form, Input, Col, Row } from "antd";
import GmailSvg from "images/gmail.svg";
import classes from "../styles.module.css";
import { Text } from "styles/typography";
import { SettingOutlined, RightOutlined, PlaySquareOutlined, LeftOutlined } from '@ant-design/icons';

interface GmailIMAPLoginModalProps {
   handleOpenClose: () => void;
   handleSubmit: (form: any) => Promise<void>;
   formData?: any;
   isEditMode?: boolean;
   connectModalStep?:Number;
   isEditModeForWorkspacePersonal?: boolean;
}

const GmailIMAPLoginModal = ({ handleOpenClose, handleSubmit, formData = {}, isEditMode = false,connectModalStep,isEditModeForWorkspacePersonal=false }: GmailIMAPLoginModalProps) => {
   // console.log("fromData", fromData);
   const currstep = connectModalStep && connectModalStep>0 ? connectModalStep:1
   const [step, setStep] = useState<any>(currstep);
   const [subform] = Form.useForm();
   if(isEditMode) subform.setFieldsValue(formData);
   if(isEditModeForWorkspacePersonal) subform.setFieldsValue(formData);

   const DetailHeading = ({ children }: { children: ReactNode }) => (
      <Text variant="sub1" color="secondary" mr={20} style={{ width: 150, fontSize: "12px" }} >
         {children}
      </Text>
   );
   return (
      <Modal
         visible
         closable={true}
         onCancel={() => handleOpenClose()}
         centered
         footer={null}
      >

         {step === 1 && (
            <div className={classes.gmailOnboarding}>
               <div className={classes.gmailOnboarding_header}>
                  <div>
                     <img src={GmailSvg} alt="gmail" />
                  </div>
                  <div className="ml-15px">
                     <h5 className="mb-0 fs-20px">Connect Your Google Account</h5>
                     <p className="text-muted mb-0 font-600 fs-15px">Gmail / G-Suite</p>
                  </div>
               </div>
               <p className="pt-3 py-3 text-center border-bottom fs-16px">
                  First, let's
                  <span className="text-primary font-600"> enable IMAP </span>access<br />for&nbsp;your&nbsp;Google&nbsp;account.
               </p>
               <ol className={classes.gmailOnboarding_orderlist}>
                  <li>On your computer, open Gmail.</li>
                  <li>
                     <span className="d-flex align-item-center">Click the <SettingOutlined style={{ margin: '0 4px' }} />
                        gear icon in the top right corner.
                     </span>
                  </li>
                  <li>Click <span className="text-dark font-600">All Settings</span>.</li>
                  <li>
                     Click the
                     <span className="text-dark font-600"><a href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
                        target="blank"> Forwarding and POP/IMAP </a></span>
                     tab.
                  </li>
                  <li>
                     In the "IMAP access" section, select <span className="text-dark font-600">Enable IMAP</span>.
                  </li>
                  <li>Click <span className="text-dark font-w">Save Changes</span>.</li>
               </ol>
               <div className="mt-20 text-center">
                  <a className="text-muted d-flex align-items-center justify-content-center"
                     href="https://inboxy-site.s3.amazonaws.com/Connect_Your_Google_Account_Into_Inboxy.pdf" target="blank">
                     Show me how  {"  "} <RightOutlined />
                  </a>
               </div>
               <div className="text-center mt-20">
                  <Button type='primary' onClick={() => setStep(step + 1)}>
                     Yes, IMAP has been enabled {" "} <RightOutlined />
                  </Button>

               </div>
               <div className="col-12 text-center mt-20">
                  <Button onClick={handleOpenClose}>
                     Cancel
                  </Button>
               </div>
            </div>
         )}

         {step === 2 && (
            <div className={classes.gmailOnboarding}>
               <div className={classes.gmailOnboarding_header}>
                  <div>
                     <img src={GmailSvg} alt="gmail" />
                  </div>
                  <div className="ml-15px">
                     <h5 className="mb-0 fs-20px">Connect Your Google Account</h5>
                     <p className="text-muted mb-0 font-600 fs-15px">Gmail / G-Suite</p>
                  </div>
               </div>
               <p className="pt-3 py-3 text-center fs-16px text-muted">
                  Enable 2-step verification & generate App password
               </p>
               <h6 className='text-center mt-4 fs-16px font-600 border-bottom py-3'>
                  <a href='https://youtu.be/J4CtP1MBtOE?t=10' target="_blank" rel="noreferrer" className='d-flex align-item-center justify-content-center'>
                     <PlaySquareOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
                     See tutorial video
                  </a>
               </h6>
               <ol className={classes.gmailOnboarding_orderlist}>
                  <li>Go to your Google Account's  <a href="https://accounts.google.com/AddSession?continue=https://myaccount.google.com/security" target="blank" className='font-600'> Security Settings</a></li>
                  <li>Enable <a href="https://accounts.google.com/AddSession?continue=https://myaccount.google.com/signinoptions/two-step-verification" target="blank" className='font-600'> 2-step verification</a></li>
                  <li>Create an <a href="https://accounts.google.com/AddSession?continue=https://myaccount.google.com/apppasswords" target="blank" className='font-600'> App password</a>
                     <p className="text-muted">Select 'Other' for both App and Device</p>
                  </li>
               </ol>
               <div className="text-center mt-30 d=flex align-item-center justify-content-center">
                  <Button onClick={() => setStep(step - 1)} type="text">
                     <LeftOutlined />   Back
                  </Button>
                  <Button type='primary' onClick={() => setStep(step + 1)} style={{ marginLeft: "10px" }}>
                     Next  <RightOutlined style={{ marginLeft: "5px" }} />
                  </Button>

               </div>
               <div className="col-12 text-center mt-20">
                  <Button onClick={handleOpenClose}>
                     Cancel
                  </Button>
               </div>
            </div>
         )}


         {step === 3 && (
            <div className={classes.gmailOnboarding}>
               <div className={classes.gmailOnboarding_header}>
                  <div>
                     <img src={GmailSvg} alt="gmail" />
                  </div>
                  <div className="ml-15px">
                     <h5 className="mb-0 fs-20px">Connect Your Google Account</h5>
                     <p className="text-muted mb-0 font-600 fs-15px">Gmail / G-Suite</p>
                  </div>
               </div>


               <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  layout="vertical"
                  form={subform}
                  onFinish={handleSubmit}
               >
                  {/* <Row gutter={12}>
                     <Col span={12}>
                        <DetailHeading>First name</DetailHeading>
                        <Form.Item
                           name="firstName"
                           rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                           <Input disabled={isEditMode} className={classes.gmailOnboardingInputStyle} size="small" />

                        </Form.Item>
                     </Col>
                     <Col span={12}>
                        <DetailHeading>Last name</DetailHeading>
                        <Form.Item
                           name="lastName"
                           rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                           <Input disabled={isEditMode} className={classes.gmailOnboardingInputStyle} size="small" />

                        </Form.Item>
                     </Col>
                  </Row> */}
                  <DetailHeading>Email</DetailHeading>
                  <Form.Item
                     name="email"
                     rules={[{ required: true, message: 'Please input your email!' }]}
                  >
                     <Input disabled={isEditMode} className={classes.gmailOnboardingInputStyle} size="small" />

                  </Form.Item>
                  <DetailHeading>App Password</DetailHeading>
                  <Form.Item
                     name="password"
                     rules={[{ required: true, message: 'Please input your App password!' }]}
                  >
                     <Input.Password className={classes.gmailOnboardingInputStyle} size="small" />
                  </Form.Item>

                  <div className="text-center mt-30 d=flex align-item-center justify-content-center">
                     <Button onClick={() => setStep(step - 1)} type="text">
                        <LeftOutlined />   Back
                     </Button>
                     <Button htmlType="submit" type="primary" style={{ marginLeft: "10px" }}>
                        Connect  <RightOutlined style={{ marginLeft: "5px" }} />
                     </Button>
                  </div>
               </Form>
               <div className="col-12 text-center mt-20">
                  <Button onClick={handleOpenClose}>
                     Cancel
                  </Button>
               </div>
            </div>
         )}

      </Modal>
   )
}

export default GmailIMAPLoginModal;