import {
  CheckCircleOutlined, ClearOutlined, CopyOutlined, DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined, LinkOutlined, LoadingOutlined,
  MailOutlined, MenuOutlined, MoreOutlined,
  PlusSquareOutlined, ProfileOutlined,
  ReloadOutlined, SketchOutlined, CalculatorOutlined, InfoCircleFilled
} from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Dropdown, Form, FormInstance, Menu, Modal, Row, Tag, Tooltip } from "antd";
import clipboardCopy from "clipboard-copy";
import MailBoxIcon from "components/MailBox/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { MailBoxResponseType } from "types/MailBox";
import classes from "../Card/styles.module.css";
import PauseMailboxModal from "./Modal";
import { useAuth } from "auth/AuthProvider";
import { isEmpty } from "lodash";

export enum MAILBOXLIST_FILTER {
  NONE = 1,
  CONNECTED = 2,
  AVAILABLE = 3,
  NOTCONNECTED = 4,
}

export interface MailBoxListProps {
  isCardView: boolean;
  mailBoxes: MailBoxResponseType[];
  selectedMailBoxes: string[];
  onAdd?: () => void;
  onSubmit: (selectedMailboxes: string[]) => void;
  form: FormInstance<any>;
  title?: string;
  subTitle?: string;
  updateCredentails?: (mailboxId: string, action: string) => void;
  onEdit?: ((id: string) => void) | null;
  mailboxDelinking?: boolean;
  onDelete?: ((id: string, email: string) => void) | null;
  //onDelete?: (((selectedMailboxes: { selectedMailBoxes: string[] }) => void) | null); // using submit with filtering curId for delete
  onViewIpsDetails?: (email: string) => void;
  onViewLogsDetails?: (id: string) => void;
  onSendEmail?: (name: string, email: string) => void;
  filterStatus?: MAILBOXLIST_FILTER;
  onReconnect?: any;
  isFromAddCampaign?: boolean
  responseSkip?: (email: string) => void
  calculateIps?: (mailboxId: string) => void
}

const MailBoxList = ({
  isCardView,
  mailBoxes,
  selectedMailBoxes,
  onSubmit,
  onAdd,
  form,
  title = "Connect to your mailboxes",
  subTitle = "The following mailboxes will be used to send the emails for this sequence.",
  onEdit,
  mailboxDelinking,
  onDelete,
  updateCredentails,
  onViewIpsDetails,
  onViewLogsDetails,
  onSendEmail,
  filterStatus = MAILBOXLIST_FILTER.NONE,
  onReconnect,
  isFromAddCampaign,
  responseSkip,
  calculateIps
}: MailBoxListProps) => {
  const { theme } = useAuth();


  const [copyTooltipText, setCopyTooltipText] = useState(true)
  const [showPausedMailboxModal, setShowPausedMailboxModal] = useState(
    false
  );

  const togglePausedMailboxDialog = (id: any) => {
    setSelectedID(id);
    setShowPausedMailboxModal((prev) => !prev);
  }
  const filterMailBoxesBasedOnCampaign = isFromAddCampaign ? mailBoxes.filter((mai) => isEmpty(mai?.campaignId)) : mailBoxes
  const filteredMailBoxes = useMemo(() => {
    const selectedMailBoxesSet = new Set(selectedMailBoxes);
    if (filterStatus === MAILBOXLIST_FILTER.NONE) {
      return filterMailBoxesBasedOnCampaign;
    }
    if (filterStatus === MAILBOXLIST_FILTER.CONNECTED) {
      return filterMailBoxesBasedOnCampaign.filter((mb) => selectedMailBoxesSet.has(mb.id));
    }
    if (filterStatus === MAILBOXLIST_FILTER.NOTCONNECTED) {
      return filterMailBoxesBasedOnCampaign.filter((mb) => !selectedMailBoxesSet.has(mb.id));
    }
    if (filterStatus === MAILBOXLIST_FILTER.AVAILABLE) {
      return filterMailBoxesBasedOnCampaign;
    }
    return filterMailBoxesBasedOnCampaign;
  }, [filterMailBoxesBasedOnCampaign, selectedMailBoxes, filterStatus]);

  const showEditMenuItem = useMemo(() => typeof onEdit === "function", [
    onEdit,
  ]);
  const showUpdateMenuItem = useMemo(() => typeof updateCredentails === "function", [
    updateCredentails,
  ]);

  const showReconnectItem = useMemo(() => typeof onReconnect === "function", [
    onReconnect,
  ]);

  const showDeleteMenuItem = useMemo(() => typeof onDelete === "function", [
    onDelete,
  ]);

  const showViewIpsDetailsMenuItem = useMemo(
    () => typeof onViewIpsDetails === "function",
    [onViewIpsDetails]
  ); //onViewLogsDetails

  const showViewLogDetailsMenuItem = useMemo(
    () => typeof onViewLogsDetails === "function",
    [onViewLogsDetails]
  );

  const showSendEmailMenuItem = useMemo(
    () => typeof onSendEmail === "function",
    [onSendEmail]
  );

  const showMoreOptions = useMemo(
    () => showViewIpsDetailsMenuItem,
    [showViewIpsDetailsMenuItem]
  );

  const handleEdit = useCallback(
    (mailboxId: string) => {
      if (typeof onEdit === "function") {
        onEdit(mailboxId);
      }
    },
    [onEdit]
  );

  const handleUpdateCredentials = useCallback(
    (mailboxId: string) => {
      if (typeof updateCredentails === "function") {
        updateCredentails(mailboxId, 'UPDATE');
      }
    },
    [updateCredentails]
  );

  const handleConnectionValidate = useCallback(
    (mailboxId: string) => {
      if (typeof updateCredentails === "function") {
        updateCredentails(mailboxId, 'VALIDATE');
      }
    },
    [updateCredentails]
  );

  const handleConnectionReconnect = useCallback(
    (mailboxId: string) => {
      if (typeof updateCredentails === "function") {
        updateCredentails(mailboxId, 'RECONNECT');
      }
    },
    [updateCredentails]
  );

  const [selectedID, setSelectedID] = useState('')
  const handleDelete = useCallback(
    (mailboxId: string, email: string) => {
      if (typeof onDelete === "function") {
        const updList = selectedMailBoxes.filter((mbId) => mbId !== mailboxId);
        //onDelete({ selectedMailBoxes: updList });
        onDelete(mailboxId, email);
        setSelectedID(mailboxId);
      }
    },
    [onDelete, selectedMailBoxes]
  );

  const handleViewIpsDetails = useCallback(
    (email: string) => {
      if (typeof onViewIpsDetails === "function") {
        onViewIpsDetails(email);
      }
    },
    [onViewIpsDetails]
  );

  //onViewLogsDetails
  const handleViewLogDetails = useCallback(
    (id: string) => {
      if (typeof onViewLogsDetails === "function") {
        onViewLogsDetails(id);
      }
    },
    [onViewLogsDetails]
  );

  const handleSendEmail = useCallback(
    (name: string, email: string) => {
      if (typeof onSendEmail === "function") {
        onSendEmail(name, email);
      }
    },
    [onSendEmail]
  );


  const showBrooklynMenuItem = (protocol: string, provider: string) => {
    console.log("showBrooklynMenuItem", protocol, provider)
    return (protocol === "SMTP_IMAP" && provider === "GOOGLE") ? true : false
  }


  const getOverLayMenu = useCallback(
    (id: string, email: string, name: string, provider: string,
      protocol: any) => {
      return (
        <Menu>
          {showEditMenuItem && (
            <Menu.Item
              key="edit"
              style={{ width: "100%" }}
              onClick={() => handleEdit(id)}
            >
              <EditOutlined /> Edit
            </Menu.Item>
          )}

          {/* {showUpdateMenuItem && (
            <Menu.Item icon={<ClearOutlined />}
              key="change" style={{ width: "100%" }}
              onClick={() => handleUpdateCredentials(id)}>
              Update Credentials
            </Menu.Item>
          )} */}

          {showUpdateMenuItem && (
            <Menu.Item icon={<LinkOutlined />}
              key="change" style={{ width: "100%" }}
              onClick={() => handleConnectionValidate(id)}>
              Validate Connection
            </Menu.Item>
          )}

          {/* {showBrooklynMenuItem(protocol, provider) && (
            <Menu.Item icon={<LinkOutlined />}
              key="change" style={{ width: "100%" }}
              onClick={() => console.log("clicked...")}>
              Migrate to Brooklyn
            </Menu.Item>
          )} */}
          {showReconnectItem && ["SMTP", "CUSTOM", "SMTP_IMAP"].includes(protocol.toUpperCase()) && (<Menu.Item
            icon={<ReloadOutlined />}
            key="validate2"
            style={{ width: "100%" }}
            // onClick={onReconnect(id, "RECONNECT")}>
            onClick={() => handleConnectionReconnect(id)}>
            Reconnect
          </Menu.Item>)}
          <Menu.Item icon={<MailOutlined />} key="sendEmail" style={{ width: "100%" }} onClick={() => responseSkip && responseSkip(email)}>
            Response Skip Codes
          </Menu.Item>

          {/* {showViewIpsDetailsMenuItem && (
            <Menu.Item
              key="ipsDetails"
              style={{ width: "100%" }}
              onClick={() => handleViewIpsDetails(email)}
            >
              <ProfileOutlined /> View IPS details
            </Menu.Item>
          )} */}

          {showViewLogDetailsMenuItem && (
            <Menu.Item
              key="logs"
              style={{ width: "100%" }}
              onClick={() => handleViewLogDetails(id)}
            >
              <InfoCircleOutlined /> View Logs
            </Menu.Item>
          )}
          {showSendEmailMenuItem && (
            <Menu.Item
              key="logs"
              style={{ width: "100%" }}
              onClick={() => handleSendEmail(name, email)}
            >
              <MailOutlined /> Send Email
            </Menu.Item>
          )}
          <Menu.Item
            key="logs"
            style={{ width: "100%" }}
            onClick={() => calculateIps && calculateIps(id)}
          >
            <CalculatorOutlined /> Calculate IPS
          </Menu.Item>
          {showDeleteMenuItem && (
            <Menu.Item
              key="delete"
              style={{ width: "100%", color: theme.antdConf.errorColor }}
              onClick={() => handleDelete(id, email)}
            >
              <DeleteOutlined /> Remove Inbox
            </Menu.Item>
          )}
        </Menu>
      );
    },
    [
      handleEdit,
      handleDelete,
      handleViewIpsDetails,
      handleViewLogDetails,
      showEditMenuItem,
      showUpdateMenuItem,
      showDeleteMenuItem,
      showViewIpsDetailsMenuItem,
      showViewLogDetailsMenuItem,
      onReconnect
    ]
  );

  useEffect(() => {
    form.setFieldsValue({ selectedMailBoxes });

  }, [selectedMailBoxes, form]);

  return (
    <Div>
      <Text variant="h6" align="center" block mb={5}>
        {title}
      </Text>
      <Text variant="sub2" align="center" block color="secondary" mb={15}>
        {subTitle}
      </Text>
      <Form
        scrollToFirstError
        form={form}
        onFinish={(value) => {
          const updatedList =
            filterStatus === MAILBOXLIST_FILTER.AVAILABLE
              ? {
                selectedMailBoxes: selectedMailBoxes.concat(
                  value.selectedMailBoxes
                ),
              }
              : value;
              console.log("selectedMailBoxesselectedMailBoxes",value)
          onSubmit(updatedList);
        }}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          selectedMailBoxes,
        }}
      >
        <Form.Item name="selectedMailBoxes" rules={[]}>
          {isCardView ? (
            <Row gutter={[10, 10]}>
              {filteredMailBoxes.map(
                ({
                  id,
                  firstname = "",
                  lastname = "",
                  paused,
                  email,
                  sent,
                  capacity,
                  limit,
                  queued,
                  provider,
                  protocol,
                  pictureUrl,
                  ips,
                }) => (
                  <Col span={6}>
                    <Flex className={classes.mailboxCard}
                      borderColor={theme.grayColor}
                      hoverBorderColor={theme.antdConf.primaryColor}
                    >
                      <Card className={classes.cardHover} onClick={() => handleEdit(id)}
                        style={{ width: "100%", height: "100%", cursor: "pointer", border: "none" }}>
                        <Flex align="center" gap={10} className={classes.emailTitle}>
                          <MailBoxIcon type={provider} />
                          <Flex direction="column" className={classes.positionRelative}>
                            <Text variant="caption" ml={5} color="secondary">
                              {`${firstname} ${lastname}`}
                            </Text>
                            <Text variant="caption" ml={5}>
                              <span>{email}</span>
                              <Tooltip title={copyTooltipText ? "Click to Copy Email" : "Copied"} mouseLeaveDelay={0} placement="top" className={classes.emailCopy}>
                                <Button
                                  className={classes.emailButton}
                                  onClick={(e) => { e.stopPropagation(); clipboardCopy(email); setCopyTooltipText(false) }}>
                                  <CopyOutlined
                                    onMouseLeave={() => { setTimeout(() => setCopyTooltipText(true), 100) }}
                                  />
                                </Button>
                              </Tooltip>
                            </Text>
                          </Flex>
                        </Flex>
                        <div className={classes.statusesWrapper}>
                          {/* <div className={classes.statusBlock}>
                            <div className={classes.statusText}>
                              <SyncOutlined className={classes.queueIcon} /> Queued
                            </div>
                            <div className={classes.queueValue}>{queued}</div>
                          </div> */}
                          <div className={classes.statusBlock}>
                            <div className={classes.statusText}>
                              <CheckCircleOutlined className={classes.sentIcon} /> Current capacity
                            </div>
                            <Flex direction="column" align="center">
                              <Text variant="caption">{capacity}</Text>
                            </Flex>
                          </div>
                          <div className={classes.statusBlock}>
                            <div className={classes.statusText}>
                              <SketchOutlined className={classes.ipsIcon} style={{ fontSize: "13px", marginRight: "3px" }} />IPS
                            </div>
                            <Flex direction="column" align="center">
                              <Text variant="caption" block mb={10} color="secondary" >
                                {ips}
                                <Tooltip placement="rightTop" title="IPS or Inbox Placement Score is a reputation index on how well your emails are landing or placed into the primary inbox instead of promotion folder or the spam folder. For example, a 30% IPS, means only 3 out of 10 of your emails are going into the primary inbox, whereas an 90% IPS, means 9 out of 10 of your emails are hitting the inbox."
                                  overlayInnerStyle={{ textAlign: "center" }}
                                >
                                  <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f", fontSize: "14px" }} />
                                </Tooltip>
                              </Text>
                            </Flex>
                          </div>
                        </div>
                      </Card>

                      {/* <Tooltip title={copyTooltipText ? "Click to Copy Email" : "Copied"} mouseLeaveDelay={0} placement="top" className={classes.emailCopy}>
                        <Button
                          className={classes.emailButton}
                          onClick={() => { clipboardCopy(email); setCopyTooltipText(false) }}>
                          <CopyOutlined
                            onMouseLeave={() => { setTimeout(() => setCopyTooltipText(true), 100) }}
                          />
                        </Button>
                      </Tooltip> */}
                      {showMoreOptions && (
                        <Flex style={{ height: "14px" }} ml="auto" className={classes.mailboxCardMenu}>
                          {paused ? <Tag
                            color="#f50"
                            className={classes.maiboxPausedTag}
                            onClick={() => togglePausedMailboxDialog(id)}>
                            PAUSED
                          </Tag> : ''}
                          <Dropdown
                            overlay={getOverLayMenu(id, email, `${firstname} ${lastname}`, provider, protocol)}
                            trigger={["click"]}
                          >
                            {(selectedID === id) && mailboxDelinking ? <LoadingOutlined style={{ color: theme.antdConf.primaryColor }} /> :
                              <MenuOutlined />
                            }
                          </Dropdown>
                        </Flex>
                      )}
                      {(selectedID === id) && showPausedMailboxModal && (
                        <Modal visible onCancel={togglePausedMailboxDialog} footer={null} width={600} maskClosable={false}>
                          <PauseMailboxModal
                            inboxId={id}
                            setShowPausedMailboxModal={setShowPausedMailboxModal}
                          />
                        </Modal>
                      )}
                    </Flex>
                  </Col>
                )
              )}
            </Row>
          ) :
            (
              <Checkbox.Group style={{ width: "100%" }}>
                {filteredMailBoxes.map(
                  ({
                    id,
                    email,
                    firstname,
                    lastname,
                    sent,
                    limit,
                    queued,
                    provider,
                    protocol,
                    pictureUrl,
                    ips,
                    campaignId
                  }) => (
                    <Div key={id} mb={15}>
                      <Flex align="center">
                        <Checkbox value={id} style={{ marginBottom: 4 }} disabled={campaignId && campaignId.length ? true : false}></Checkbox>
                        <Flex align="center" ml={15} grow={1}>
                          {/* {pictureUrl ? (
                        <BgImage imageUrl={pictureUrl} h={30} w={30} />
                      ) : (
                        <MailBoxIcon type={provider} />
                      )} */}
                          <MailBoxIcon type={provider} />
                          <Div ml={10} grow={1}>
                            <Flex direction="column">
                              <Text variant="sub2">{email}</Text>
                              <Flex>
                                <Text variant="caption" color="secondary">
                                  Limit {limit}
                                </Text>
                                <Text variant="caption" color="secondary" ml={20}>
                                  Sent Today {sent}
                                </Text>
                                {/* <Text variant="caption" color="secondary" ml={20}>
                                  Queued {queued}
                                </Text> */}
                                <Text variant="caption" color="secondary" ml={20}>
                                  IPS {ips}
                                </Text>

                                {
                                  campaignId && campaignId.length ?
                                    <Text variant="caption" color="secondary" ml={20}>
                                      <Tooltip placement="rightTop" title="This mailbox is been used in different campaign">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </Text>
                                    :
                                    null
                                }
                              </Flex>
                            </Flex>
                          </Div>
                          {showMoreOptions && (
                            <Div>
                              <Dropdown
                                overlay={getOverLayMenu(id, email, `${firstname} ${lastname}`, provider, protocol)}
                                trigger={["click"]}
                              >
                                <MenuOutlined />
                              </Dropdown>
                            </Div>
                          )}
                        </Flex>
                      </Flex>
                    </Div>
                  )
                )}
              </Checkbox.Group>

            )
          }
        </Form.Item>
      </Form>
      {
        typeof onAdd === "function" && (
          <Div mt={5}>
            <Button
              icon={<PlusSquareOutlined />}
              type="text"
              style={{ color: theme.antdConf.primaryColor, padding: 0, background: "none" }}
              onClick={onAdd}
            >
              link new mailbox
            </Button>
          </Div>
        )
      }
    </Div >
  );
};

export default MailBoxList;