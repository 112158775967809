import { useCallback, useState, useEffect, ReactNode, useContext, useMemo } from "react";
import { Tag, Input, Tooltip, Select, notification, Button, Space, Form, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useApi from "hooks/useApi";
import "./index.css";
import { API_ENDPOINTS } from "constants/api";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import appContext from "contexts/appContext";

export interface TagType {
  id: string | number;
  name: string;
}

interface TagAutoCompleteProps {
  data: any;
  tagIds: any;
  tagData: any;
  onRemoveMails: () => void;
  fetchTags: () => void;
  getMailOnClick: () => void;
  getMailOnClicks: () => void;
  isProccesed?: boolean;
  refreshContained?: () => void;
  openDetailsMessageOnModal?: () => void;
}

type AllTagsType = {
  tagId: string;
  name: string;
};

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex pt={10} pb={5}>
    {children}
  </Flex>
);

const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="sub1" color="secondary" mr={20} style={{ width: 60 }}>
    {children}
  </Text>
);

const AutoTag = ({
  refreshContained,
  getMailOnClick,
  fetchTags,
  data,
  tagIds,
  tagData,
  onRemoveMails,
  getMailOnClicks,
  isProccesed = false,
  openDetailsMessageOnModal
}: TagAutoCompleteProps) => {
  const { callApi } = useApi();
  const [form] = Form.useForm();
  const [tags, setTags] = useState<AllTagsType[]>(tagData);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [tagDatas, setTagData] = useState(tagData);
  const [name, setName] = useState("");
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const { setLoadingMessage } = useContext(appContext);
  const showInput = () => {
    setInputVisible(true);
  };
  const onCanelPress = () => {
    setShowAddTagModal((prev) => !prev);
  };

  const saveInputRef = (input: any) => {
    // eslint-disable-next-line no-self-assign
    input = input;
  };
  const [currCreatedTags, setCreatedtags] = useState<any[]>([]);

  useEffect(() => {
    fetchTags21();
  }, [tagIds]);

  const fetchTags21 = useCallback(async () => {
    let currActiveTagName = tagData.filter((tg: any) => tagIds.includes(tg.id));
    setTags(currActiveTagName);
    setCreatedtags([...currActiveTagName]);
    // let currTagsArr: any = [...multipleTagsArr]
    // currTagsArr[0].val = res.data.tags.find((tg: any) => tg.name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '') === tagName.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''))
    // addMultiTags(currTagsArr)
    // setTagData([...filteredData])
    // }
  }, [callApi, data.inboundMessage.campaignId, tagData, tagIds]);

  const addTagToInboundThread = async (val: any) => {
    console.log("addTagToInboundThreadaddTagToInboundThread", val);
    const { url, method } = API_ENDPOINTS.INBOUND.addMultipleResponseTagMailThread(data.inboundMessage.id);
    const response = await callApi({
      url,
      method,
      data: { campaignId: data.inboundMessage.campaignId, tagId: val[0].id as any, processed: isProccesed }
    });
    if (response.success) {
      // await getMailOnClicks();
      refreshContained && refreshContained();
      notification.success({
        message: response?.data?.message || "tag added successfully",
        description: (
          <Button style={{ padding: "0" }} type="link" onClick={() => openDetailsMessageOnModal && openDetailsMessageOnModal()}>
            Click here to view
          </Button>
        ),
        duration: 3
      });
    }
  };

  const remveTagToInboundThread = async (removeTag: any, removedTagName: string) => {
    console.log("removeTagremoveTag", removeTag);
    const { url, method } = API_ENDPOINTS.INBOUND.deleteMultipleResponseTagMailThread(data.inboundMessage.id, removeTag[0].id);
    const response = await callApi({
      url,
      method
    });
    if (response.success) {
      // setTags(tags.filter((tag: any) => tag.name !== removedTagName))
      setCreatedtags(currCreatedTags.filter((tag: any) => tag.name !== removedTagName));
      notification.success({ message: "tag removed successfully" });
    } else {
      console.log("hiiiiiiiiiiiiii");
      // notification.error({ message: response?.error?.message });
    }
  };

  const handleClose = async (removedTag: any) => {
    // console.log("removedTag", removedTag)
    let removedTagName = removedTag;
    const removeTag = tags.filter((tag: any) => tag.name === removedTag);
    console.log(">>>>>>>>>>>>...", removeTag, removedTag);
    await remveTagToInboundThread(removeTag, removedTagName);
  };

  const handleInputConfirm = useCallback(async () => {
    if (inputValue && tags.findIndex((elem) => elem["name"] === inputValue) === -1) {
      let val = tagData.filter((tg: any) => tg.name === inputValue);
      if (currCreatedTags.length < 5) {
        setCreatedtags([...currCreatedTags, ...val]);
        setTags([...currCreatedTags, ...val]);
        console.log(">>>>>>>>>handleInputConfirm>>>>>>>>>>>>>>>>>");
        await addTagToInboundThread(val);
      } else {
        notification.success({ message: "can't add more then 5 tags" });
      }
    }
    setInputVisible(false);
  }, [inputValue, tagData, tags, currCreatedTags]);

  useEffect(() => {
    if (inputValue) {
      handleInputConfirm();
    }
    return () => {
      setInputValue("");
    };
  }, [inputValue]);

  const handleInputChange = (value: any) => {
    console.log("valuevalue", value);
    if (value === "Create Tag") {
      setShowAddTagModal((prev) => !prev);
    } else {
      const data: any = tagData.filter((tg: any) => tg.id === value);
      if (data[0]["name"]) {
        setInputValue(data[0]["name"]);
      }
    }
  };

  const onFinish = async () => {
    setLoadingMessage("Saving tags ...");

    const { url, method } = API_ENDPOINTS.INBOUND.addMultipleResponseTagMailThread(data.inboundMessage.id);
    const response = await callApi({
      url,
      method,
      data: { tagName: name, campaignId: data.inboundMessage.campaignId, processed: isProccesed }
    });

    if (response.success) {
      // onSave("Create Tag",);
      notification.success({
        message: `Tag Created successfully and message is moved to ${name} tag`,
        description: (
          <Button style={{ padding: "0" }} type="link" onClick={() => openDetailsMessageOnModal && openDetailsMessageOnModal()}>
            Click here to view
          </Button>
        ),
        duration: 3
      });
    }
    setLoadingMessage("");
    onCanelPress();
    await fetchTags();
    // await getMailOnClicks();
    // await getMailOnClick()
    // setInputValue(name)
  };

  // const getTagsList = useMemo(() => {
  //   if (Array.isArray(tagData)) {
  //     return tagData?.filter((tg) => tg?.apply);
  //   } else return [];
  // }, [tagData]);

  const processedTags = useMemo(() => {
    return tagData?.filter((vl: any) => vl?.processed && vl?.apply);
  }, [tagData?.length, isProccesed]);

  const pendingtags = useMemo(() => {
    return tagData?.filter((vl: any) => !vl?.processed && vl?.apply);
  }, [tagData?.length, isProccesed]);
  return (
    <>
      {(currCreatedTags || []).map((tag: any, index: number) => {
        const isLongTag = tag.name.length > 20;
        const tagElem = (
          <Tag
            style={{ height: "20px" }}
            className="edit-tag"
            key={tag?.name}
            closable={currCreatedTags.length === 1 ? false : true}
            onClose={() => handleClose(tag?.name)}
          >
            <span>{isLongTag ? `${tag?.name.slice(0, 20)}...` : tag?.name}</span>
          </Tag>
        );
        return index > 0 ? (
          <Tooltip title={tag?.name} key={tag?.name}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Select
          ref={saveInputRef}
          size="small"
          className="tag-input"
          onBlur={() => handleInputConfirm()}
          style={{ width: "200px", marginBottom: 10 }}
          onChange={(v) => handleInputChange(v)}
          defaultOpen={true}
          // onSelect={onSave}
          // defaultValue={elem?.val?.name}
        >
          {/* {getTagsList &&
              getTagsList.map((data: any, index: number) => (
                <Select.Option value={data.id} key={index}>
                  {data.name}
                </Select.Option>
              ))} */}
          <Select.OptGroup label={<span style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}>Processed</span>}>
            {processedTags?.map((tag: any) => (
              <Select.Option value={tag.id} key={tag.id}>
                {tag.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
          <Select.OptGroup label={<span style={{ fontWeight: "bold", fontSize: "16px", color: "#000000" }}>Pending</span>}>
            {pendingtags?.map((tag: any) => (
              <Select.Option value={tag.id} key={tag.id}>
                {tag.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
          <Select.Option value="Create Tag">
            <PlusOutlined /> Create New Tag
          </Select.Option>
        </Select>
      )}
      {!inputVisible && (
        <Tag className="site-tag-plus" onClick={showInput}>
          <PlusOutlined /> New Tag
        </Tag>
      )}

      {showAddTagModal && (
        <Modal visible onCancel={onCanelPress} footer={null} width="max-content" centered>
          <Text variant="h6" mb={15} mt={5} color={"secondary"}>
            Create New Tag
            <Tag color="green" style={{ marginLeft: "10px" }}>
              {isProccesed ? "Processed" : "Pending"}
            </Tag>
          </Text>

          <Form scrollToFirstError form={form} requiredMark={false} onFinish={onFinish}>
            <DetailWrapper>
              <DetailHeading>Tag</DetailHeading>
              <Form.Item
                name={"Tag"}
                initialValue={name}
                style={{ marginBottom: `0px`, marginLeft: "-1%" }}
                rules={[
                  {
                    required: true,
                    message: "Please write tag name"
                  }
                ]}
              >
                <Input onChange={(e) => setName(e.target.value)} style={{ width: 200, color: "#000" }} size="middle" />
              </Form.Item>
            </DetailWrapper>

            <Flex mb={10} justify="flex-end" mt={20}>
              <Space>
                <Button type="default" onClick={onCanelPress}>
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary" disabled={!name.trim().length}>
                  Save
                </Button>
              </Space>
            </Flex>
          </Form>
        </Modal>
      )}
    </>
  );
};
export default AutoTag;
