import { CampaignSequnceTypeEnum } from "./Campaign";

export interface TouchType {
  id: string;
  waitDays: number;
  touchNumber: number;
  campId?: string;
  seqId?: string;
}

export enum TouchTypeEnum {
  NEW_THREAD = "New Thread",
  REPLY_TO_FIRST = "Reply to First Touch",
  REPLY_TO_PREVIOUS = "Reply to Previous Touch",
  REPLY_TO_PREVIOUS_SEQUENCE = "Reply to Previous Sequence"
}

export interface TouchTemplateType extends TouchType {
  template: {
    id: string;
    campId: string;
    touchId: string;
    body: string;
    subject: string;
    touchNumber: number;
    templateNumber: number;
    trackEmailOpened: boolean;
    openTrackingRate: number;
    trackLinkClicked: boolean;
    includeSignature: boolean;
    useOriginalSubject: boolean;
    plainText: boolean;
    // bodyText?: string;
    type: TouchTypeEnum;
    linkTrackingValue: number;
    isLinkTrackingEnable: boolean;
  };
}

export interface TouchEventsResponseType {
  touchNumber: number;
  sequenceType: CampaignSequnceTypeEnum;
  data: {
    optedOut: number;
    date: string;
    paused: number;
    replied: number;
    queued: number;
    opened: number;
    failed: number;
    bounced: number;
    sent: number;
    clicked: number;
  }[];
}
