import styled from "styled-components";

export const InlineEditorWrapper = styled.div<{ focus: boolean }>`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0px 11px;
  font-size: 14px;
  height: 32px;
  width: 100%;
  overflow-x: hidden;
  ${({ focus,theme }) =>
    focus
      ? `
      border-color: ${theme.antdConf.primaryColor};
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);`
      : ""}
`;
