import { Button, Modal, Typography } from "antd";
import { Div, Flex } from "styles/layout";

interface ManualGmailLoginModalProps {
  installExtension: boolean
  handleManualGmailLoginModal: () => void;
  handleInstallExtension: () => void;
  handleGoogleLogin: () => void;
}


const ManualGmailLoginModal = ({
  installExtension,
  handleManualGmailLoginModal,
  handleInstallExtension,
  handleGoogleLogin,
}: ManualGmailLoginModalProps) => {
  const { Title } = Typography;

  return (
    <Modal
      visible
      footer={null}
      closable={true}
      onCancel={() => handleManualGmailLoginModal()}
      width={700}
      centered
    >
      <Title level={5}>Unable to Connect</Title>
      <Flex direction="column" ml={10} >
        {!installExtension ?
          <>
            <Flex ml={10}>
              <p>
                <Div mb={10}>
                  Emy has trouble connecting to your mailbox. Let's try another approach using a chrome extension.
                </Div>
                <ul>
                  <li style={{ marginBottom: "10px" }}>Install Gmail connector chrome extension. It will open a new tab and make sure to come back to this page after the extension is installed.</li>
                  <li>After installation is complete, you will be presented with the new button "Open gmail.com" which will redirect to gmail.com on another tab in which you may log in to this mailbox once.</li>
                </ul>
              </p>
            </Flex>
            <Div mt={10}>
              <Button onClick={handleInstallExtension} type="primary">
                Install Chrome Extension
              </Button>
            </Div>
          </>
          :
          <>
            <Flex ml={10}>
              <Div mb={10}>
                Emy has trouble connecting to your mailbox. Let's try another approach. Click the below button which will open gmail.com on another tab in which you may log in to this mailbox once.
              </Div>
            </Flex>
            <Div mt={10}>
              <Button onClick={handleGoogleLogin} type="primary">
                Open gmail.com
              </Button>
            </Div>
          </>
        }
      </Flex>
    </Modal>
  );
};

export default ManualGmailLoginModal;
