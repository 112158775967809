import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { DARK_GRAY_COLOR } from "utils/colors";

export interface OutwinSimpleBarChartProps {
  data: any[];
  barDataKey: string;
  xAxisDataKey: string;
  xAxisLabel: string;
  yAxisLabel: string;
  colors?: string[];
  legend?: boolean;
}
const RenderLegend = (props: any) => {
  const { colors, data, xAxisDataKey } = props;

  return (
    <Flex direction="column" align="flex-start">
      {data.map((entry: any, index: any) => (
        <Flex key={`item-${index}`} mt={8} ml={5} mr={5} align="center" w={100}>
          <Div bgColor={colors[index]} h={12} w={12} mr={5} />
          <Text variant="overline" color="secondary">
            {entry[xAxisDataKey]}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

const OutwinSimpleBarChart = ({
  xAxisLabel,
  yAxisLabel,
  barDataKey,
  xAxisDataKey,
  data,
  colors = ["#3498db"],
  legend = false,
}: OutwinSimpleBarChartProps) => {
  return (
    <Flex w="100%" h="100%">
      <Text
        variant="caption"
        style={{ writingMode: "vertical-lr", transform: "rotate(-180deg)" }}
        block
        align="center"
        color="secondary"
        uppercase
      >
        {yAxisLabel}
      </Text>
      <Flex w="100%" h="100%" direction="column">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 0,
              left: -55,
              bottom: -25,
            }}
          >
            <XAxis tick={false} dataKey={xAxisDataKey} fontSize={11} />
            <YAxis tick={false} label="" fontSize={11} />
            <Tooltip
              labelStyle={{
                fontSize: 12,
                marginBottom: 5,
                color: DARK_GRAY_COLOR,
              }}
              itemStyle={{ fontSize: 12 }}
            />
            <Bar dataKey={barDataKey}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Text
          variant="caption"
          align="center"
          block
          color="secondary"
          uppercase
        >
          {xAxisLabel}
        </Text>
      </Flex>
      {legend && (
        <Legend
          wrapperStyle={{ position: "relative", marginTop: 10 }}
          content={
            <RenderLegend
              colors={colors}
              data={data}
              xAxisDataKey={xAxisDataKey}
            />
          }
        />
      )}
    </Flex>
  );
};

export default OutwinSimpleBarChart;
