import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  MenuOutlined,
  DownOutlined,
  PlusOutlined, SearchOutlined, UploadOutlined
} from "@ant-design/icons";
import { Button, Dropdown, Empty, Menu, notification, Skeleton, Card, Row, Col, Modal, Tag } from "antd";
import { CSVContentsType } from "components/UploadPropects/UploadCsv";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import { Div, Flex } from "styles/layout";
import { useAuth } from "auth/AuthProvider";
import classes from "styles/common.module.css";
import { CSVUploadDnc } from "../DoNotContact/csvUploadDnc";
import DncModal from "../DoNotContact/dncModal";
import './DNC.css'
type CSVContactsState = {
  step: number;
  isProcessing: boolean;
  disableUploadCSV?: boolean;
  csvData?: CSVContentsType;
  domainColumn: string;
};
export type CSVContactsProps = {
  onCancel: () => void;
  onDone?: (domainS3key: string, domainIndex: string, fileUrl: string, domainCount: number, uploadedFileName: string) => void;
};

const DoNotContact = () => {
  const { callApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [DncItems, setDncItems] = useState([]);
  const [searchDncModal, setSearchDncModal] = useState(false);
  const [addDncModal, setAddDncModal] = useState(false);
  const [deleteDncModal, setDeleteDncModal] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState([]);
  const [showSearchedDncItems, setShowSearchedDncItems] = useState(false);
  const [searchedItems, setSearchedItems] = useState([])
  const [openViewModal, setOpenViewModal] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [viewLinesItems, setViewLinesItems] = useState([])
  const [toggleUpload, setToggleUpload] = useState(false);
  const [state, setState] = useState<CSVContactsState>({ step: 1, isProcessing: false, domainColumn: "" });

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setAddDncModal(true)}>
        Direct Entry
      </Menu.Item>
      <Menu.Item key="2" onClick={() => { setToggleUpload(!toggleUpload) }}>
        File Upload
      </Menu.Item>
    </Menu>
  );


  const menuDelete = (
    <Menu>
      <Menu.Item key="1" onClick={() => setDeleteDncModal(true)}>
        Direct Entry
      </Menu.Item>
      <Menu.Item key="2">
        File Upload
      </Menu.Item>
    </Menu>
  );

  const onUploadDone = useCallback(
    async (domainS3key: string, domainIndex: string, fileUrl: string, domainCount: number, uploadedFileName: string) => {
      setState((st) => ({ ...st, uploadKey: domainS3key, index: domainIndex, fileUrl: fileUrl, uploadedFileName: uploadedFileName, domainCount: domainCount }))

      setToggleUpload(false);
      const accountId = localStorage.getItem('account_id');
      const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
      const response = await callApi({
        url,
        method,
        data: { "action": "UPLOAD", "s3Key": fileUrl, "filename": uploadedFileName, "index": parseInt(domainIndex) }
      });
      if (response.success) {
        setLoading(true);
        await fetchDncItems();
        setLoading(false);
        setDeleteDncModal(false);
      }
    }
    ,
    []
  );
  const deleteDncItems = async (name: any) => {
    let arr = [];
    arr.push(name)

    const accountId = localStorage.getItem('account_id');
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: { "action": "DELETE", "items": ((!(arr[0] == '')) ? arr : textAreaValue) }
    });
    if (response.success) {
      setDeleteDncModal(false);
      setLoading(true);
      await fetchDncItems();
      setLoading(false);
      notification.success({
        message: "Items have been deleted"
      })
      console.log('item deleted')
    }

    setTextAreaValue([])

  };

  const fetchDncItems = (async () => {
    const accountId = localStorage.getItem('account_id');
    setLoading(true);
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: { "action": "FETCH" }
    });
    if (response.success) {
      setDncItems(response.data.items);
    }
    setLoading(false);
  });

  useEffect(() => {
    fetchDncItems();
  }, []);

  const addDncItems = (async () => {

    const accountId = localStorage.getItem('account_id')
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: { "action": "ADD", "items": textAreaValue }
    });
    if (response.success) {
      setLoading(true);
      await fetchDncItems();
      setLoading(false);
      notification.success({
        message: "Items have been added"
      })
      setAddDncModal(false);
    }


  });

  const searchDncItems = (async () => {
    const accountId = localStorage.getItem('account_id')
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: { "action": "SEARCH", "items": textAreaValue }
    });
    if (response.success) {
      setSearchedItems(response.data.items);
      setSearchDncModal(false);
      setShowSearchedDncItems(true);
    }
  });
  return (

    <>
      <Flex justify="center" mt={10} mb={20} gap={15} align="center">
        <div style={{ padding: '20px' }}>
        <Row justify="center" gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Button icon={<SearchOutlined />} className="custom-button" style={{ marginRight: '10px' }} onClick={() => setSearchDncModal(true)}>Search</Button>
        <Dropdown overlay={menu} trigger={['click']} >
          <Button icon={<PlusOutlined />} className="custom-button" style={{ marginRight: '10px' }}>
            Add  <DownOutlined />
          </Button>
        </Dropdown>
        <Dropdown overlay={menuDelete} trigger={['click']}>
          <Button icon={<DeleteOutlined />} className="custom-button delete">
            Delete  <DownOutlined />
          </Button>
        </Dropdown>
      </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col>
              <Card
                title="2918"
                extra={<DownloadOutlined />}
                style={{ width: 300, textAlign: 'center' }}
              >
                DNC Domains
              </Card>

            </Col>
            <Col>
              <Card
                title="1772"
                extra={<DownloadOutlined />}
                style={{ width: 300, textAlign: 'center' }}
              >
                DNC Emails
              </Card>
            </Col>
          </Row>
        </div>
      </Flex>
      {addDncModal && <DncModal title="Add domains or email addresses" textAreaChange={setTextAreaValue} okText="Add" onOk={addDncItems} onCancel={() => { setAddDncModal(false) }} />}
      {deleteDncModal && <DncModal title="Delete domains or email addresses" textAreaChange={setTextAreaValue} okText="Delete" onOk={() => deleteDncItems('')} onCancel={() => { setDeleteDncModal(false) }} />}
      {searchDncModal && <DncModal title="Search domains or email addresses" textAreaChange={setTextAreaValue} okText="Search" onOk={searchDncItems} onCancel={() => { setSearchDncModal(false) }} />}
      {toggleUpload && <CSVUploadDnc onCancel={() => setToggleUpload(false)} onDone={onUploadDone} />}
      {showSearchedDncItems && <Modal visible centered footer={null} onCancel={() => setShowSearchedDncItems(false)}>
        {searchedItems.length > 0 ? (searchedItems.map((name: any) => {
          return (
            <Tag
              closable onClose={() => deleteDncItems(name)}
              style={{ marginBottom: 10 }} >
              {name}
            </Tag>
          )
        })) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Modal>}
      {openViewModal &&
        <Modal
          visible
          onCancel={() => { setOpenViewModal(!openViewModal) }}
          footer={null}
          centered>
          {viewLinesItems.length > 0 ? (viewLinesItems.map((item: any) => {
            return (
              <Tag
              >
                {item.sk}
              </Tag>
            )
          })) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Modal>
      }
    </>
  );
};

export default DoNotContact;