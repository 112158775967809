import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Modal, Steps, Form } from "antd";
import useApi from "hooks/useApi";
import UploadCsv, { CSVContentsType } from "components/UploadPropects/UploadCsv";
import ProspectMapColumns, { ProspectFieldType, ProspectMappingType } from "components/UploadPropects/MapColumns";
import { API_ENDPOINTS } from "constants/api";
import { notification } from "antd";
import ProspectSummary from "components/UploadPropects/UploadSummary";
import Actions from "components/Actions";
import { Flex, Div } from "styles/layout";
import appContext from "contexts/appContext";
import { isUndefined } from "lodash";
import { DedupeProspects } from "components/UploadPropects/DedupeProspects";
import { Applydnc } from "components/UploadPropects/ApplyDnc";

const { Step } = Steps;

interface AddProspectsModalProps {
  onCancel: () => void;
  campaignId: string;
  afterUpload: () => void;
  prospectVariables?: ProspectFieldType[];
}

enum AddProspectSteps {
  PROSPECT_UPLOAD = "PROSPECT_UPLOAD",
  PROSPECT_COLUMN_MAPPING = "PROSPECT_COLUMN_MAPPING",
  PROSPECT_COLUMN_MAPPING_SECONDARY = "PROSPECT_COLUMN_MAPPING_SECONDARY",
  PROSPECT_DEDUPE_SELECT = "PROSPECT_DEDUPE_SELECT",
  APPLY_DNC = "APPLY_DNC"
  // PROSPECT_COLUMN_SELECTION = "PROSPECT_COLUMN_SELECTION",
  // PROSPECT_SUMMARY = "PROSPECT_SUMMARY"
}

const STEPS = [
  {
    title: "Upload Prospects"
  },
  {
    title: "Map Columns"
  },
  {
    title: "Dedupe"
  }
  // {
  //   title: "Summary"
  // }
];

type FieldMappingState = {
  primary: { [x: string]: number };
  secondary: { [x: string]: number };
};

const AddProspectsModal = ({ onCancel, campaignId, afterUpload, prospectVariables }: AddProspectsModalProps) => {
  const { setLoadingMessage } = useContext(appContext);
  const [currentStepName, setCurrentStepName] = useState<AddProspectSteps>(AddProspectSteps.PROSPECT_UPLOAD);
  const [prospectData, setProspectData] = useState<{
    fileUrl: null | string;
    columnHeaders: { header: string; index: number }[];
    sampleProspectData: { prospect: string; index: number }[];
    totalProspects: number;
  }>({
    fileUrl: null,
    columnHeaders: [],
    sampleProspectData: [],
    totalProspects: 0
  });

  const [prospectSummary, setProspectSummary] = useState<{
    total: number;
    failed: number;
    failedCsvLink: string;
    added: number;
  }>({
    total: 0,
    failed: 0,
    failedCsvLink: "",
    added: 0
  });
  const [nextButton, setNextButton] = useState({
    label: "Next",
    show: true,
    disabled: false
  });
  const [backButton, setBackButton] = useState({
    label: "back",
    show: false,
    disabled: false
  });
  const [skipButton, setSkipButton] = useState({
    label: "back",
    show: false,
    disabled: false
  });
  const [filedMapping, setFieldMapping] = useState<FieldMappingState>({
    primary: {},
    secondary: {}
  });
  const [dedupeStateData, setDedupeState] = useState<any>(null);
  const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";
  const [customFieldsDedupe, setColumnsSecondaryForm] = useState<any>(null);

  const { callApi, uploadFileToS3 } = useApi();
  const [prospectsUploadForm] = Form.useForm();
  const [prospectMappingForm] = Form.useForm();
  const [prospectMappingFormSecondary] = Form.useForm();
  const [prospectDedupeCamapiaign] = Form.useForm();
  const [prospectApplyDnc] = Form.useForm();
  const disableNextButton = useCallback(() => setNextButton((prevState) => ({ ...prevState, disabled: true })), [setNextButton]);

  const enableNextButton = useCallback(() => setNextButton((prevState) => ({ ...prevState, disabled: false })), [setNextButton]);
  const [disableUploadCSV, setDisableUploadCSV] = useState(false);
  console.log("skipButtonskipButton", skipButton);
  const stepNumber = useMemo(() => {
    switch (currentStepName) {
      case AddProspectSteps.PROSPECT_UPLOAD:
        return 0;
      case AddProspectSteps.PROSPECT_COLUMN_MAPPING:
      case AddProspectSteps.PROSPECT_COLUMN_MAPPING_SECONDARY:
        return 1;
      case AddProspectSteps.PROSPECT_DEDUPE_SELECT:
      case AddProspectSteps.APPLY_DNC:
        return 2;
      // case AddProspectSteps.PROSPECT_SUMMARY:
      //   return 3;
    }
  }, [currentStepName]);
console.log("dedupeStateDatadedupeStateData",dedupeStateData)
  const onNext = useCallback(() => {
    switch (currentStepName) {
      case AddProspectSteps.PROSPECT_UPLOAD:
        prospectsUploadForm.submit();
        break;
      case AddProspectSteps.PROSPECT_COLUMN_MAPPING:
        prospectMappingForm.submit();
        break;
      case AddProspectSteps.PROSPECT_COLUMN_MAPPING_SECONDARY:
        prospectMappingFormSecondary.submit();
        break;
      case AddProspectSteps.PROSPECT_DEDUPE_SELECT:
        prospectDedupeCamapiaign.submit();
        break;
      case AddProspectSteps.APPLY_DNC:
        prospectApplyDnc.submit();
        break;
      // case AddProspectSteps.PROSPECT_SUMMARY:
      //   afterUpload();
      //   onCancel();
      //   break;
      default:
        return currentStepName;
    }
  }, [
    prospectDedupeCamapiaign,
    prospectApplyDnc,
    currentStepName,
    prospectsUploadForm,
    prospectMappingForm,
    prospectMappingFormSecondary
    // onCancel,
    // afterUpload
  ]);

  const onBack = () => {
    setCurrentStepName((prevStepName) => {
      switch (prevStepName) {
        case AddProspectSteps.PROSPECT_COLUMN_MAPPING:
          return AddProspectSteps.PROSPECT_UPLOAD;
        case AddProspectSteps.PROSPECT_COLUMN_MAPPING_SECONDARY:
          return AddProspectSteps.PROSPECT_COLUMN_MAPPING;
        case AddProspectSteps.PROSPECT_DEDUPE_SELECT:
          return AddProspectSteps.APPLY_DNC;
        case AddProspectSteps.APPLY_DNC:
          return AddProspectSteps.PROSPECT_COLUMN_MAPPING;
        default:
          return prevStepName;
      }
    });
  };

  const ONE_MB_VALUE = 1024;
  const MAX_CSV_FILE_SIZE = 120 * 1024;
  const NOTIFICATION_KEY = "CSV_UPLOAD_ERROR";

  const onUploadCsvFormSubmit = useCallback(
    async ({ columnHeaders, file, prospects }: CSVContentsType) => {
      setDisableUploadCSV(true);
      disableNextButton();
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getListEventsUrl(campaignId);
      const filteredProspects = prospects.filter((item) => item.indexOf("") === -1);
      const response = await callApi({
        url,
        method
      });
      if (response.success) {
        const fsize = file.size;
        const fileSize = Math.round(fsize / ONE_MB_VALUE);
        if (fileSize > MAX_CSV_FILE_SIZE) {
          notification.error({
            message: "File size should not exceed 100MB.",
            description: "",
            key: NOTIFICATION_KEY
          });
          return;
        }
        const { fileUrl } = await uploadFileToS3(file);
        if (fileUrl) {
          setProspectData({
            fileUrl,
            columnHeaders: columnHeaders.map((col, idx) => ({ header: col, index: idx })),
            sampleProspectData: prospects[0].map((prospect, idx) => ({ prospect, index: idx })),
            totalProspects: prospects.length
          });
          setCurrentStepName(AddProspectSteps.PROSPECT_COLUMN_MAPPING);
          setDisableUploadCSV(false);
          return;
        }
        enableNextButton();
      }
    },
    [campaignId, callApi, uploadFileToS3, disableNextButton, enableNextButton]
  );

  const delay = (timeout: number) => new Promise((resolve) => setTimeout(() => resolve(true), timeout));
  const getUploadStatusApi = async (requestId: string): Promise<any> => {
    const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getUploadStatus(requestId);
    const DEFAULT_RES_UPLOAD = {
      added: 0,
      failed: 0,
      total: 0
    };
    try {
      const apiRes = await callApi({ url, method });
      if (apiRes.success) {
        return apiRes?.data;
      }
    } catch (ex) {
      return { ...DEFAULT_RES_UPLOAD, status: "FAILED" };
    }
    return { ...DEFAULT_RES_UPLOAD, status: "FAILED" };
  };

  // const pollStatus = useCallback(async (tries: number, requestId: string, MAX_TRIES = 35): Promise<any> => {
  //   const response = await getUploadStatusApi(requestId);
  //   if (tries <= MAX_TRIES && response.status !== "FAILED" && response.status !== "COMPLETED") {
  //     await delay(5_000);
  //     return await pollStatus(tries + 1, requestId);
  //   }
  //   return response;
  // }, []);

  const onMapProspectColumnsSecondaryFormSubmit = useCallback(
    async (prospectMapping: ProspectMappingType, standardFields?: typeof filedMapping["primary"]) => {
      if (!campaignId) {
        return;
      }
      const { columnMappings } = prospectMapping;
      const crossFileMappings = columnMappings.map((col) => ({
        oldField: col.name,
        newField: prospectData.columnHeaders?.[col.columnNumber]?.header ?? -1
      }));
      console.log("crossFileMappingscrossFileMappings", crossFileMappings);
      setColumnsSecondaryForm(crossFileMappings);
      setCurrentStepName(AddProspectSteps.PROSPECT_DEDUPE_SELECT);
    },
    [campaignId, prospectData, setColumnsSecondaryForm, setCurrentStepName]
  );

  const onMapProspectApplyDncFormSubmit = useCallback(
    async (applyDncStates: any, isFromSkip?: boolean) => {
      console.log("22222222222",dedupeStateData,isFromSkip);
      if (!campaignId) {
        return;
      }
      const standardFields = filedMapping.primary;
      const { fileUrl: key } = prospectData;
      const payload = {
        key,
        standardFields: standardFields,
        crossFileMappings: customFieldsDedupe || [],
        customFields: [],
        dedupeAllCampaigns: isFromSkip ? false : dedupeStateData?.dedupeAllCampaigns,
        dedupeCampIds: isFromSkip ? [] : dedupeStateData?.dedupeCampIds ? dedupeStateData?.dedupeCampIds : [],
        applyDnc: applyDncStates?.applyDnc,
        userId: uId
      };
      console.log("22222222222payload", payload);

      setLoadingMessage("Adding prospects to the campaign ...");
      const { url, method } = API_ENDPOINTS.CAMPAIGN_PROSPECT.getUploadUrl(campaignId);
      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        // const res = await pollStatus(12, response.data.requestId);
        // const { added, failed, total } = res;
        // setProspectSummary({
        //   added: added,
        //   failed: failed,
        //   total: total,
        //   failedCsvLink: res?.failedCsvLink
        // });
        // setCurrentStepName(AddProspectSteps.PROSPECT_SUMMARY);
        notification.success({
          message: "Thank you, we will notify via email once prospects have been created."
        });
        onCancel();
        setLoadingMessage("");
      }
      setLoadingMessage("");
    },
    [campaignId, callApi, prospectData, setLoadingMessage, filedMapping, customFieldsDedupe,dedupeStateData]
  );

  const onMapProspectColumnsFormSubmit = useCallback(
    async (prospectMapping: ProspectMappingType) => {
      console.log("11111111111");
      const { columnMappings } = prospectMapping;
      if (!campaignId) {
        return;
      }

      const fieldMappings = columnMappings.reduce(
        (acc, { columnNumber, outwinField }) => ({
          ...acc,
          [outwinField]: columnNumber
        }),
        {}
      ) as { [x: string]: number };

      setFieldMapping((st) => ({ ...st, primary: fieldMappings }));

      const remainingProspectVariablesExist = (prospectVariables ?? []).filter((pv) =>
        isUndefined(fieldMappings[pv.caseNeutralName.slice(2).slice(0, -2)])
      ).length;
      console.log("remainingProspectVariablesExist", remainingProspectVariablesExist);
      if (remainingProspectVariablesExist) {
        setCurrentStepName(AddProspectSteps.PROSPECT_COLUMN_MAPPING_SECONDARY);
      } else {
        setCurrentStepName(AddProspectSteps.PROSPECT_DEDUPE_SELECT);
        // onMapProspectColumnsSecondaryFormSubmit({ columnMappings: [] }, fieldMappings);
      }
    },
    [campaignId, prospectVariables]
  );

  const remainingProspectData = useMemo(() => {
    const selectedIndexes = Object.keys(filedMapping.primary).reduce((acc, key) => ({ ...acc, [filedMapping.primary[key]]: true }), {}) as {
      [key: string]: boolean;
    };

    const availProspectData = {
      columnHeaders: prospectData.columnHeaders.filter((_, idx: number) => !selectedIndexes[idx]),
      sampleProspectData: prospectData.sampleProspectData.filter((_, idx: number) => !selectedIndexes[idx])
    };
    return availProspectData;
  }, [filedMapping.primary, prospectData]);

  const remainingProspectVariables = useMemo(() => {
    return (prospectVariables ?? [])
      .filter((pv) => isUndefined(filedMapping.primary[pv.caseNeutralName.slice(2).slice(0, -2)]))
      .map((fv) => ({ ...fv, isMandatory: true }));
  }, [prospectVariables, filedMapping.primary]);

  const onMapProspectDedupeFormSubmit = async (dedupeStates: any, isFromSkip?: boolean) => {
    console.log("onMapProspectDedupeFormSubmitonMapProspectDedupeFormSubmit", dedupeStates);
    if (!isFromSkip) {
      console.log(">>>>>>>>>>>>>>>>>>>>>1111111111111111");
      setDedupeState(dedupeStates);
    }
    setDedupeState(dedupeStates);

    setCurrentStepName(AddProspectSteps.APPLY_DNC);
  };

  const CurrentStepComponent = useMemo(() => {
    switch (currentStepName) {
      case AddProspectSteps.PROSPECT_UPLOAD:
        return <UploadCsv onContinue={onUploadCsvFormSubmit} form={prospectsUploadForm} disabled={disableUploadCSV} continueButtonCheck={() => {}} />;
      case AddProspectSteps.PROSPECT_COLUMN_MAPPING:
        return (
          <ProspectMapColumns
            key={AddProspectSteps.PROSPECT_COLUMN_MAPPING}
            onContinue={onMapProspectColumnsFormSubmit}
            form={prospectMappingForm}
            sampleProspect={prospectData.sampleProspectData}
            columnHeaders={prospectData.columnHeaders}
            totalProspects={prospectData.totalProspects}
          />
        );
      case AddProspectSteps.PROSPECT_COLUMN_MAPPING_SECONDARY:
        return (
          <ProspectMapColumns
            key={AddProspectSteps.PROSPECT_COLUMN_MAPPING_SECONDARY}
            onContinue={onMapProspectColumnsSecondaryFormSubmit}
            form={prospectMappingFormSecondary}
            prospectFields={remainingProspectVariables}
            sampleProspect={remainingProspectData.sampleProspectData}
            columnHeaders={remainingProspectData.columnHeaders}
            totalProspects={prospectData.totalProspects}
          />
        );
      case AddProspectSteps.PROSPECT_DEDUPE_SELECT:
        return <DedupeProspects form={prospectDedupeCamapiaign} onContinue={onMapProspectDedupeFormSubmit} />;
      case AddProspectSteps.APPLY_DNC:
        return <Applydnc form={prospectApplyDnc} onContinue={onMapProspectApplyDncFormSubmit} />;
      // case AddProspectSteps.PROSPECT_SUMMARY:
      //   return (
      //     <Div>
      //       <ProspectSummary
      //         added={prospectSummary.added}
      //         total={prospectSummary.total}
      //         failed={prospectSummary.failed}
      //         failedCsvLink={prospectSummary.failedCsvLink}
      //       />
      //     </Div>
      //   );

      default:
        return null;
    }
  }, [
    currentStepName,
    onUploadCsvFormSubmit,
    prospectsUploadForm,
    disableUploadCSV,
    onMapProspectColumnsFormSubmit,
    onMapProspectColumnsSecondaryFormSubmit,
    prospectMappingFormSecondary,
    prospectMappingForm,
    prospectData,
    prospectSummary,
    remainingProspectVariables,
    remainingProspectData
  ]);
  console.log("currentStepNamecurrentStepName", currentStepName);

  useEffect(() => {
    console.log("currentStepNamecurrentStepName", currentStepName);
    // Manage the button states
    switch (currentStepName) {
      case AddProspectSteps.PROSPECT_UPLOAD: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: false,
          disabled: true
        });
        break;
      }
      case AddProspectSteps.PROSPECT_COLUMN_MAPPING:
      case AddProspectSteps.PROSPECT_COLUMN_MAPPING_SECONDARY: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      // case AddProspectSteps.PROSPECT_SUMMARY: {
      //   setSkipButton({
      //     label: "Skip",
      //     show: false,
      //     disabled: true
      //   });
      //   setNextButton({
      //     label: "Done",
      //     show: true,
      //     disabled: false
      //   });
      //   setBackButton({
      //     label: "Back",
      //     show: false,
      //     disabled: true
      //   });
      //   break;
      // }
      case AddProspectSteps.PROSPECT_DEDUPE_SELECT: {
        setSkipButton({
          label: "Skip",
          show: true,
          disabled: false
        });
        setNextButton({
          label: "Next",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
      case AddProspectSteps.APPLY_DNC: {
        setSkipButton({
          label: "Skip",
          show: false,
          disabled: true
        });
        setNextButton({
          label: "Done",
          show: true,
          disabled: false
        });
        setBackButton({
          label: "Back",
          show: true,
          disabled: false
        });
        break;
      }
    }
  }, [currentStepName]);
  const onSkip = (formData: any) => {
    // prospectDedupeCamapiaign.submit();
    console.log("formDataformData", formData);
    onMapProspectDedupeFormSubmit(formData, true);
  };
  return (
    <Modal visible onCancel={onCancel} footer={null} width="max-content" bodyStyle={{ alignItems: "center" }} centered>
      <Flex align="center" direction="column" mb={20} mt={40} p="0 20px" w={800}>
        <Steps current={stepNumber}>
          {STEPS.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <Div w="100%" mt={40} mb={30}>
          {CurrentStepComponent}
        </Div>
        <Actions
          onBack={onBack}
          showBack={backButton.show}
          backButtonLabel={backButton.label}
          onNext={onNext}
          showNext={nextButton.show}
          nextButtonLabel={nextButton.label}
          backDisabled={backButton.disabled}
          nextDisabled={nextButton.disabled}
          skipButtonLabel={skipButton?.label}
          onSkip={() => onSkip(prospectDedupeCamapiaign.getFieldsValue())}
          showSkip={skipButton?.show}
          skipDisabled={skipButton.disabled}
        />
      </Flex>
    </Modal>
  );
};

export default AddProspectsModal;
