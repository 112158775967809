import { CampaignSequnceTypeEnum, CampaingStatusEnum } from "types/Campaign";

export const getCampaignStatusEnum = (status: string) => 
  status==='Active' ? CampaingStatusEnum.ACTIVE : ( status==='Completed' ? CampaingStatusEnum.COMPLETED : CampaingStatusEnum.INACTIVE);
  

export const getCampaignActiveFromStatusEnum = (status: CampaingStatusEnum) =>
  status === CampaingStatusEnum.ACTIVE ? true : false;

export const getSequenceName = (type: CampaignSequnceTypeEnum) => {
  switch (type) {
    case CampaignSequnceTypeEnum.INITIAL:
      return "Initial Sequence";
    case CampaignSequnceTypeEnum.INFO:
      return "Information Sequence";
    case CampaignSequnceTypeEnum.REFERRAL:
      return "Referral Sequence";
    case CampaignSequnceTypeEnum.FUTURE:
      return "Future Sequence";
    case CampaignSequnceTypeEnum.POWER:
      return "Power Sequence";
    default:
      return "";
  }
};

export const getSequenceNameById = (id: string) => {
  switch (id) {
    case "7f7c0671-9ab5-4348-a50c-376d6b25efd5":
      return "Initial Sequence";
    case "cef96e69-c0d0-4efc-8e36-110caad1643a":
      return "Information Sequence";
    case "a031c5b8-03fb-494f-bbfc-511e00be7146":
      return "Referral Sequence";
    case "20f341f6-d7d2-4171-a0ee-7f30c92d6419":
      return "Future Sequence";
    case "a031c5b8-03fb-494f-bbfc-511e00be7146":
      return "Power Sequence";
    default:
      return "";
  }
};

export const getCampaignStatusText = (status: boolean) =>
  status === true ? "Activate" : "Deactivate";

  export const getCampaignChangeStatusText = (status: string) =>
  status === 'Active' ? "Deactivate" : "Activate";

  export const getCampaignResponseText = (status: boolean) =>
  status === true ? "activate" : "deactivate";
