import React from "react";
import { Flex } from "styles/layout";
import { EventType } from "types/Event";
import { SECONDARY_COLOR } from "utils/colors";
import EventIcon from "./EventIcon";
import { Text } from "styles/typography";
import { humanizeDifferenceFromNow } from "utils/dateTime";
import { getEventText } from "utils/event";
import html2plaintext from "html2plaintext";

interface EventProps {
  event: EventType;
}

const Event = ({ event: { event, created, title, subtitle } }: EventProps) => {
  return (
    <Flex
      p={5}
      // style={{
      //   borderBottom: `1px solid ${SECONDARY_COLOR}`,
      // }}
      className="rac-row"
    >
      <Flex direction="column" w="100%">
        <Flex align="center" mb={0} justify="space-between" w="100%">
          <Flex align="center" w="96%">
            <EventIcon type={event} />
            <Text variant="sub2" ml={8} style={{
              minWidth: "115px",
              maxWidth:"115px",
              whiteSpace:"nowrap",
              display:"inline-block"
            }}>
              {getEventText(event)}
            </Text>
            <Text
              variant="sub2"
              color="secondary"
              block
              ml={15}
              mr={0}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "unset",
                display:"inline-block"


              }}
            >
              {title} 
              
            </Text>
            <Text
              variant="sub2"
              color="secondary"
              style={{
                lineHeight: 1.2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                //maxWidth: "140px",
                display:"inline-block",
                flexGrow:1,
                flexShrink:1,
                flexBasis:240,
                overflowY:'hidden',
              
                //width:"14%"
              }}

              block
            >
            <span style={{
              margin:"0 5px"
            }}>{" "} -  {" "}</span>  {html2plaintext(subtitle).replaceAll("\n", " ")}
            </Text>
          </Flex>
          <Text variant="caption" color="secondary">
            {humanizeDifferenceFromNow(created)}
          </Text>
        </Flex>

      </Flex>
    </Flex>
  );
};

export default Event;