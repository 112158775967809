import React, { useCallback } from "react";
import { Div, Flex } from "styles/layout";
import { TouchType } from "types/Touch";
import { EditOutlined, MailTwoTone, DeleteOutlined } from "@ant-design/icons";
import { Text } from "styles/typography";
import { Button, Popover, Tooltip } from "antd";
import { useAuth } from "auth/AuthProvider";


export interface TouchListProps {
  touches: TouchType[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  canEdit?: boolean;
}

const WaitDaysLine = ({ waitDays }: { waitDays: number }) => {
  const { theme } = useAuth();
 
  return !!waitDays ? (
    <Flex direction="column" align="center" mt={2} mb={2}>
      <Div align="center" position="relative" h={70} w={1} bgColor={theme.darkGrayColor}>
        <Flex
          position="absolute"
          w="max-content"
          bgColor="white"
          p="5px 10px"
          style={{ transform: "translateX(-50%) translateY(-50%)", top: "50%" }}
          borderColor={theme.darkGrayColor}
          br={5}
        >
          <Text variant="caption">
            Wait {waitDays} day{waitDays > 1 ? "s" : ""}
          </Text>
        </Flex>
      </Div>
      <Div
        style={{
          width: 0,
          height: 0,
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderTop: `5px solid ${theme.darkGrayColor}`
        }}
      />
    </Flex>
  ) : null;
};

const TouchList = ({ touches, onDelete, onEdit, canEdit = true }: TouchListProps) => {
  const { theme } = useAuth();

  const getDayNumber = useCallback(
    (selectedTouchOrder) =>
      touches.reduce((acc, { touchNumber, waitDays }) => {
        if (touchNumber <= selectedTouchOrder) {
          return acc + waitDays;
        }
        return acc;
      }, 0) + 1,
    [touches]
  );

  const getHandleTouchEdit = (id: string) => () => onEdit(id);
  const getHandleTouchDelete = (id: string) => () => onDelete(id);

  return (
    <Div w={200} align="center" style={{ display: "inline-block" }}>
      {touches.map(({ touchNumber, waitDays, id }) => (
        <Flex direction="column" align="center" key={id}>
          <WaitDaysLine waitDays={waitDays} />
          <Popover
            placement="right"
            title=""
            overlayStyle={{ background: "transparent" }}
            overlayInnerStyle={{ background: "transparent" }}
            overlayClassName="outwin-transparent-popover"
            content={
              <>
                <Div mb={5}>
                  <Tooltip title="Edit Touch" placement="right">
                    <Button shape="circle" icon={<EditOutlined />} type="primary" onClick={getHandleTouchEdit(id)}></Button>
                  </Tooltip>
                </Div>
                <Div>
                  <Tooltip title="Delete Touch" placement="right">
                    <Button disabled={!canEdit} shape="circle" icon={<DeleteOutlined />} danger type="primary" onClick={getHandleTouchDelete(id)}></Button>
                  </Tooltip>
                </Div>
              </>
            }
          >
            <Flex
              cursor="pointer"
              onClick={getHandleTouchEdit(id)}
              p={10}
              borderColor={theme.darkGrayColor}
              br="50%"
              w={100}
              h={100}
              direction="column"
              align="center"
              hoverBorderColor={theme.antdConf.primaryColor}
            >
              <Div align="center" mt={5}>
                <Text variant="caption" uppercase>
                  Day {getDayNumber(touchNumber)}
                </Text>
              </Div>
              <Div align="center" mt={5}>
                <MailTwoTone style={{ fontSize: 40, strokeWidth: 1 }} />
              </Div>
            </Flex>
          </Popover>
        </Flex>
      ))}
    </Div>
  );
};

export default TouchList;
