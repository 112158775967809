import Icon, {
  CheckCircleOutlined, ClearOutlined, CopyOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined, MailOutlined,
  MenuOutlined, ProfileOutlined, ReloadOutlined, SafetyCertificateOutlined, SketchOutlined, CalculatorOutlined, InfoCircleFilled
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, Modal, Skeleton, Tooltip } from "antd";
import clipboardCopy from "clipboard-copy";
import MailBoxIcon from "components/MailBox/Icon";
import { useState } from "react";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import { MailboxTypeEnum } from "types/MailBox";
import { useAuth } from "auth/AuthProvider";
import classes from "./styles.module.css";
import { Link, useHistory } from "react-router-dom";
import { protocol } from "socket.io-client";
import { ReactComponent as externalLink } from "images/external-link.svg";

const ExternalLinkIcon = (props: any) => <Icon component={externalLink} {...props} style={{ fontSize: "19px" }} />;
export interface MailboxActionProps {
  onEdit?: () => void;
  changePassword?: any;
  handleConnectionValidate?: any;
  showBrooklynMenuItem?: () => void;
  onDelete?: () => void;
  onReconnect?: () => void;
  onUnlink?: () => void;
  onViewIpsDetails?: () => void;
  onViewLogsDetails?: () => void;
  onSendEmail?: () => void;
  onOpenTracking?: () => void;
  type?: string;
  responseSkip?: () => void
  campaignId?: any,
  campaignName?: string,
  protocol?: any
  calculateIps?: () => void

}

export interface MailBoxCardProps extends MailboxActionProps {
  type: MailboxTypeEnum;
  email: string;
  queuedMails: number;
  sentMails: number;
  name: string;
  imageUrl?: string;
  ips: number;
  loading: boolean;
  capacity: number;
}

export const MailboxActionMenu = ({
  onEdit,
  changePassword,
  handleConnectionValidate,
  onDelete,
  onReconnect,
  onUnlink,
  showBrooklynMenuItem,
  onViewIpsDetails,
  onViewLogsDetails,
  onSendEmail,
  onOpenTracking,
  type,
  responseSkip,
  calculateIps,
  protocol = '',
}: any) => {
  const { theme } = useAuth();
  const isSMTP = ["SMTP", "CUSTOM", "SMTP_IMAP","OAUTH"].includes(protocol.toUpperCase())
  const isIMAP = MailboxTypeEnum.SENDGRID === type || MailboxTypeEnum.MAIL_GUN === type || MailboxTypeEnum.TECHCOMPILER === type || MailboxTypeEnum.GMAIL === type || MailboxTypeEnum.GMAILONE === type || MailboxTypeEnum.OFFICE_SMTP === type;
  const DropDownMenu = (
    <Menu>
      {typeof onEdit === "function" && (
        <Menu.Item icon={<EditOutlined />} key="edit" style={{ width: "100%" }} onClick={onEdit}>
          Edit
        </Menu.Item>
      )}
      {/* {typeof changePassword === "function" && changePassword !== "e" && (
        <Menu.Item icon={<ClearOutlined />} key="change" style={{ width: "100%" }} onClick={changePassword}>
          Update Credentials
        </Menu.Item>
      )} */}
      {typeof changePassword === "function" && changePassword !== "e" && (
        <Menu.Item
          icon={<SafetyCertificateOutlined />}
          key="validate2"
          style={{ width: "100%" }}
          onClick={handleConnectionValidate}
        >
          Validate Connection
        </Menu.Item>
      )}
      {/* {typeof showBrooklynMenuItem === "function" && showBrooklynMenuItem() ? (
        <Menu.Item icon={<LinkOutlined />} key="ips" style={{ width: "100%" }} onClick={() => console.log("called")}>
          Migrate to Brooklyn
        </Menu.Item>
      ) : null} */}
      {typeof onReconnect === "function" && isSMTP   && (
        <Menu.Item icon={<ReloadOutlined />} key="reconnect" style={{ width: "100%" }} onClick={onReconnect}>
          Reconnect
        </Menu.Item>
      )}

      {/* {typeof onViewIpsDetails === "function" && (
        <Menu.Item icon={<ProfileOutlined />} key="ips" style={{ width: "100%" }} onClick={onViewIpsDetails}>
          View IPS details
        </Menu.Item>
      )} */}
      {typeof onOpenTracking === "function" && (
        <Menu.Item icon={<ProfileOutlined />} key="openTracking" style={{ width: "100%" }} onClick={onOpenTracking}>
          Custom Domain Tracking
        </Menu.Item>
      )}
      <Menu.Item icon={<MailOutlined />} key="sendEmail" style={{ width: "100%" }} onClick={responseSkip}>
        Response Skip Codes
      </Menu.Item>
      {/* {typeof onViewLogsDetails === "function" && ( */}
      <Menu.Item icon={<InfoCircleOutlined />} key="logs" style={{ width: "100%" }} onClick={onViewLogsDetails}>
        View Logs
      </Menu.Item>
      {/* )} */}
      {
        <Menu.Item icon={<MailOutlined />} key="sendEmail" style={{ width: "100%" }} onClick={onSendEmail}>
          Send Email
        </Menu.Item>
      }
      <Menu.Item icon={<CalculatorOutlined />} key="sendEmail" style={{ width: "100%" }} onClick={() => calculateIps()}>
        Calculate IPS
      </Menu.Item>
      {typeof onDelete === "function" && (
        <Menu.Item
          icon={<DeleteOutlined />}
          key="deletep"
          style={{ width: "100%", color: theme.antdConf.errorColor }}
          onClick={() => onDelete()}
        >
          Remove Inbox
        </Menu.Item>
      )}
      {typeof onUnlink === "function" && (
        <Menu.Item
          icon={<DeleteOutlined />}
          key="unlink"
          style={{ width: "100%", color: theme.antdConf.errorColor }}
          onClick={onUnlink}
        >
          Unlink
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <Dropdown overlay={DropDownMenu} trigger={["click"]}>
      <MenuOutlined />
    </Dropdown>
  );
};

const MailBoxCard = ({
  type,
  email,
  queuedMails,
  sentMails,
  onDelete,
  onReconnect,
  onEdit,
  changePassword,
  handleConnectionValidate,
  onUnlink,
  name,
  imageUrl,
  ips,
  showBrooklynMenuItem,
  onViewIpsDetails,
  onViewLogsDetails,
  onSendEmail,
  onOpenTracking,
  loading = false,
  capacity,
  responseSkip,
  campaignId,
  campaignName,
  protocol,
  calculateIps
}: MailBoxCardProps) => {
  const { theme } = useAuth();
  const history = useHistory();

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [copyTooltipText, setCopyTooltipText] = useState(true);

  const CopyTooltip = (e: any) => {
    e.stopPropagation();
    clipboardCopy(email);
    setCopyTooltipText(false);
  };

  const redirectToCampaign = (campaignId: string) => {
    // e.stopPropagation();
    history.push(`/campaigns/${campaignId}`);
  }
  return (
    <>
      {showDeleteConfirmModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary",
            danger: true,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={() => setShowDeleteConfirmModal(false)}
          onOk={() => {
            setShowDeleteConfirmModal(false);
            onDelete && onDelete();
          }}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <Text variant="body2">
            You are about to remove{" "}
            <Text variant="body2" bold>
              {email}
            </Text>{" "}
            <Text variant="body2">
              mailbox from EMY. If any of the campaigns are currently using this mailbox, this mailbox won't be removed and an error message will be displayed.
            </Text>
            <Text variant="body2">Would you like to proceed?</Text>
          </Text>
        </Modal>
      )}

      <Flex className={classes.mailboxCard} borderColor={theme.lightGrayColor}>
        <Card
          onClick={loading ? () => { } : onEdit}
          style={{ width: "100%", height: "100%", cursor: "pointer", border: "none" }}
        >
          <Skeleton loading={loading} active>
            <Flex align="center" gap={10}>
              {/* {imageUrl ? <BgImage imageUrl={imageUrl} h={30} w={30} /> : <MailBoxIcon type={type} />} */}

              <Flex align="start" direction="column" className="mailbox_icon">
                <MailBoxIcon type={type}></MailBoxIcon>
                <Text variant="caption" mt={5} color="secondary">
                  {protocol}
                </Text>
              </Flex>


              <Flex direction="column" className={classes.positionRelative}>
                <Text variant="caption" ml={5} color="secondary">
                  {name}
                </Text>
                <Flex direction="row" align="start" ml={5} mb={5}>
                  <Text variant="caption" align={"center"}>
                    <span className={classes.emailTitle}> {email}</span>
                  </Text>
                  {!loading && (
                    <Tooltip
                      title={copyTooltipText ? "Click to Copy Email" : "Copied"}
                      mouseLeaveDelay={0}
                      placement="top"
                      className={classes.emailCopy}
                    >
                      <Button className={classes.emailButton} onClick={(e) => CopyTooltip(e)}>
                        <CopyOutlined
                          onMouseLeave={() => {
                            setTimeout(() => setCopyTooltipText(true), 100);
                          }}
                        />
                      </Button>
                    </Tooltip>
                  )}
                </Flex>
                {campaignId ?

                  <Text variant="caption" ml={5} align={"left"}>
                    <Flex direction="row" align="start" >
                      <span> {campaignName}</span>
                      {!loading && (
                        <Tooltip
                          title={"Click to open the campaign"}
                          mouseLeaveDelay={0}
                          placement="top"
                          className={classes.emailCopy}
                        >
                          <Button className={classes.emailButton} onClick={() => redirectToCampaign(campaignId)}>
                            <ExternalLinkIcon fontSize="19px" />
                          </Button>
                        </Tooltip>
                      )}
                    </Flex>
                  </Text>
                  : null}


              </Flex>
            </Flex>
            <div className={classes.statusesWrapper}>
              {/* <div className={classes.statusBlock}>
                <div className={classes.statusText}>
                  <SyncOutlined className={classes.queueIcon} /> Queued
                </div>
                <div className={classes.queueValue}>{queuedMails}</div>
              </div> */}
              <div className={classes.statusBlock}>
                <div className={classes.statusText}>
                  <CheckCircleOutlined className={classes.sentIcon} /> Current capacity
                </div>
                <Flex direction="column" align="center">
                  <Text variant="caption">{capacity}</Text>
                </Flex>
              </div>
              <div className={classes.statusBlock}>
                <div className={classes.statusText}>
                  <SketchOutlined className={classes.ipsIcon} style={{ fontSize: "13px", marginRight: "3px" }} />
                  IPS
                </div>
                <Text variant="caption" block color="secondary" >
                  {ips}
                  <Tooltip placement="rightTop"
                    title="IPS or Inbox Placement Score is a reputation index on how well your emails are landing or placed into the primary inbox instead of promotion folder or the spam folder. For example, a 30% IPS, means only 3 out of 10 of your emails are going into the primary inbox, whereas an 90% IPS, means 9 out of 10 of your emails are hitting the inbox."
                    overlayInnerStyle={{ textAlign: "center" }}
                  >
                    <InfoCircleFilled style={{ marginLeft: "5px", color: "#f1c40f", fontSize: "14px", verticalAlign: "text-bottom" }} />
                  </Tooltip>
                </Text>
              </div>
            </div>
          </Skeleton>
        </Card>


        {!loading && (
          <Flex ml="auto" className={classes.mailboxCardMenu}>
            <MailboxActionMenu
              onDelete={() => onDelete && onDelete()}
              onEdit={onEdit}
              handleConnectionValidate={handleConnectionValidate}
              changePassword={changePassword}
              onReconnect={onReconnect}
              showBrooklynMenuItem={showBrooklynMenuItem}
              onUnlink={onUnlink}
              onViewIpsDetails={onViewIpsDetails}
              onViewLogsDetails={onViewLogsDetails}
              onSendEmail={onSendEmail}
              onOpenTracking={onOpenTracking}
              type={type}
              responseSkip={responseSkip}
              calculateIps={calculateIps}
              protocol={protocol}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default MailBoxCard;