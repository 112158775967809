import { Button, Modal, Typography } from "antd";

interface VerificationModalProps {
  subMessage: string;
  verifyTwoStep?: () => void;
  handleVerificationModal: () => void;
}

const VerficationModal = ({
  subMessage,
  verifyTwoStep,
  handleVerificationModal
}: VerificationModalProps) => {
  const { Title } = Typography;

  return (
    <Modal
      visible
      footer={null}
      closable={false}
      // keyboard={false}
      onCancel={() => handleVerificationModal()}
      width={300}
      centered
    >
      <Title level={5}>2-Step Verification</Title>
      <p className="fs-12" style={{ marginBottom: "15px" }}>To help keep your account safe, Google wants to make sure that it’s really you trying to sign in.</p>
      <p className="fs-12" style={{ marginBottom: "20px" }}>{subMessage}</p>
      <p className="fs-12" style={{ marginBottom: "15px" }}>press Next once you are done with the above steps</p>
      <Button onClick={verifyTwoStep} type="primary">
        Next
      </Button>
    </Modal>
  );
};

export default VerficationModal;
