import { Form, Switch, FormInstance } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";

export interface SelectColumnsProps {
  onContinue?: (state: any, isFromSkip?: boolean) => void;
  form: FormInstance<any>;
}

export const Applydnc = ({ form, onContinue }: SelectColumnsProps) => {
  const [state, setState] = useState<any>({
    applyDnc: true
  });

  const { applyDnc } = state;

  useEffect(() => {
    form.setFieldsValue({ applyDnc: true });
  }, []);

  const handleSelectedCampaignsChange = async (name: string, value: any) => {
    form.setFieldsValue({ [name]: value });
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const onSave = useCallback(
    (form) => {
      console.log("statestate", state);
      console.log(">>>>>>>>>>>>", form);
      onContinue && onContinue(form);
    },
    [onContinue]
  );
  console.log("applyDncapplyDnc", applyDnc);
  return (
    <Flex direction="column" gap={20}>
      <Text variant="h6" align="center" block>
        Do NOT Contact List
      </Text>
      <Form form={form} scrollToFirstError onFinish={onSave} layout="vertical" autoComplete="off" onError={() => alert("Error")}>
        <Form.Item name="applyDnc" valuePropName="checked" label="Remove prospects found in DNC list">
          <Switch size="default" checked={applyDnc} onChange={(e) => handleSelectedCampaignsChange("applyDnc", e)} />
        </Form.Item>
      </Form>
    </Flex>
  );
};
