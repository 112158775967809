export enum SpinnerTypeEnum {
  PLAIN_TEXT = "PlainText",
  HTML = "HTML"
}

export interface SpinType {
  name: string;
  label: string;
  value: string;
  sequence: number;
  contentType: SpinnerTypeEnum;
  variables: string[];
}

export interface SpinDetailType {
  campId: string;
  createdAt?: string;
  createdBy?: string;
  id: string;
  name: string;
  // spinLines: string[];
  spinLines: any;
  updatedAt?: string;
  updatedBy?: string;
}
