import { Button, Form, Input, Modal, Typography } from "antd";
import { Div, Flex } from "styles/layout";
import classes from "../../Campaigns/styles.module.css";

interface RecoveryEmailModalProps {
  subMessage: string
  handleRecoveryEmailModal: () => void;
  confirmRecoveryEmail: (formValues: any) => void;
}

const RecoveryEmailModal = ({
  subMessage,
  handleRecoveryEmailModal,
  confirmRecoveryEmail,
}: RecoveryEmailModalProps) => {
  const { Title } = Typography;
  const [gmailMailConfirmation] = Form.useForm();

  return (
    <Modal
      visible
      footer={null}
      onCancel={() => handleRecoveryEmailModal()}
      width={300}
      centered
    >
      <Form
        scrollToFirstError
        form={gmailMailConfirmation}
        requiredMark={false}
        onFinish={confirmRecoveryEmail}
      >
        <Title level={5}>Verify that it’s you</Title>
        <p className="fs-12" style={{ marginBottom: "15px" }}>This device isn’t recognised. For your security, Google wants to make sure that it’s really you.</p>
        <Div>
          <label className="fs-12 fw-600">Email *</label>
          <Form.Item
            name={"alternate_email"}
            rules={[
              {
                type: "email",
                message: "Please enter valid email",
              },
              {
                required: true,
                message: "Please enter email",
              },
            ]}
          >
            <Input tabIndex={1} className={classes.inputStyle} size="middle" />
          </Form.Item>
          <p className="fs-12" style={{ marginBottom: "10px" }}>
            Confirm the recovery email address that you added to your account:<strong>{subMessage.split('account:')[1]}</strong></p>
        </Div>
        <Flex direction="row" ml={10} justify="flex-end">
          <Flex ml={10}>
            <Button htmlType="submit" type="primary">
              Next
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default RecoveryEmailModal;
