import React from "react";
import { Tag, Button, Tooltip } from "antd";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import moment from "moment";
import { getStatusColor } from "constants/prospect";
import classes from "pages/CampaignDetail/TagV2/styles.module.css";
import { ReactComponent as externalLink } from "images/external-link.svg";
import Icon from "@ant-design/icons";

const ExternalLinkIcon = (props: any) => <Icon component={externalLink} {...props} style={{ fontSize: "19px" }} />;


const RenderAlertDetailPage = (props: any) => {
    const { alerts: { mailbox, campaign, created, subject, body, severity }, openSettingModal } = props
    console.log("body", body)
    const htmlContent = body.replace(/\n/g, '<br>');
    const redirectToCampaign = (campaignId: string) => {
        // e.stopPropagation();
        // history.push(`/campaigns/${campaignId}`);
        window.open(`/campaigns/${campaignId}`, "_blank");
    }
    return (
        <>

            <Div className="fixed__response-header">
                <Flex mt={1} gap={15} justify="space-between" align="center" >
                    <Text variant="h6">{subject}</Text>
                    {/* <Flex>

                            {severity === 'Error' && <Button type="link"
                                className="fix__error-btn"
                                onClick={(e) => {
                                    openSettingModal(e);
                                }}
                            >Fix Error</Button>}
                        </Flex> */}
                </Flex>
                <Flex mt={5}>
                    <Tag
                        style={{
                            borderRadius: 5
                        }}
                    //color={getStatusColor(severity)}

                    >
                        <Text variant="caption">
                            {severity}
                        </Text>
                    </Tag>
                </Flex>

                <Flex justify="space-between" mt={20}>
                    {mailbox && <Flex gap={20}>
                        <Flex direction="column">
                            <Text variant="overline" color="secondary">
                                Mailbox
                            </Text>
                            <Flex direction="row" align="start" >
                                <Text variant="caption">{mailbox?.email}</Text>
                                <Tooltip
                                    title={"Click to open the mailbox setting"}
                                    mouseLeaveDelay={0}
                                    placement="top"
                                    className={classes.emailCopy}
                                >
                                    <Button className={classes.emailButton} style={{ marginTop: "-4px", border: "none" }}
                                        onClick={(e) => {
                                            openSettingModal(e);
                                        }}>
                                        <ExternalLinkIcon fontSize="19px" />
                                    </Button>
                                </Tooltip>
                            </Flex>
                        </Flex>
                    </Flex>}
                    {campaign && <Flex direction="column">
                        <Text variant="overline" color="secondary">
                            Campaign Name
                        </Text>
                        <Flex direction="row" align="start" >
                            <Text variant="caption">{campaign?.name || 'Name'}</Text>
                            <Tooltip
                                title={"Click to open the campaign"}
                                mouseLeaveDelay={0}
                                placement="top"
                                className={classes.emailCopy}
                            >
                                <Button className={classes.emailButton} style={{ marginTop: "-4px", border: "none" }}
                                    onClick={(e) => {
                                        redirectToCampaign(campaign.id)
                                        // openSettingModal(e);
                                    }}>
                                    <ExternalLinkIcon fontSize="19px" />
                                </Button>
                            </Tooltip>
                        </Flex>
                    </Flex>}
                    <Flex direction="column">
                        <Text variant="overline" color="secondary">
                            Received at
                        </Text>
                        <Text variant="caption">{moment(created).format("ddd, MMM DD, hh:mm a")}</Text>
                    </Flex>
                </Flex>

            </Div >

            <Flex direction="column" style={{ padding: "20px" }}>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </Flex>

        </>
    );
};

export default RenderAlertDetailPage;