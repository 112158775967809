import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";

interface LoaderProps {
  message: string;
}

const FullPageLoader = (props: LoaderProps) => {
  return (
    <Flex
      align="center"
      justify="center"
      w="100vw"
      position="fixed"
      bgColor="rgba(255,255,255,0.8)"
      style={{
        height: "100vh"
      }}
      direction="column"
      zIndex={1050}
    >
      <LoadingOutlined style={ { fontSize: 30 }} />
      <Text variant="caption" color="secondary" uppercase block mt={10}>
        {props.message}
      </Text>
    </Flex>
  );
};

export default FullPageLoader;
