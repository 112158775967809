import { Card, Skeleton } from "antd";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import { BOX_SHADOW } from "utils/colors";
import { DomainStatus } from "./DomainCommon";
import { DomainItem } from "./Domain.types";

type DomainCardProps = {
  item: DomainItem;
  loading: boolean;
  onClick?: (item: DomainItem) => void;
};
export const DomainCard = (props: DomainCardProps) => {
  const noop = () => {};
  const status = props.item.status;

  return (
    <Card style={{ width: "100%", boxShadow: BOX_SHADOW, cursor: "pointer" }} onClick={!props.loading ? () => props?.onClick?.(props.item) : noop}>
      <Skeleton loading={props.loading} active>
        <Flex direction="column" justify="space-between" gap={40}>
          <Text variant="sub1" color="primary">
            {props.item.domain}
          </Text>
          <DomainStatus status={status} />
        </Flex>
      </Skeleton>
    </Card>
  );
};
