import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  MenuOutlined,
  MoreOutlined,
  PlusOutlined, SearchOutlined, UploadOutlined
} from "@ant-design/icons";
import { Button, Dropdown, Empty, Menu, notification, Skeleton, Table, Tag } from "antd";
import Modal from "antd/lib/modal/Modal";
import { CSVContentsType } from "components/UploadPropects/UploadCsv";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import classes from "styles/common.module.css";
import { Div, Flex } from "styles/layout";
import { CSVUploadDnc } from "./csvUploadDnc";
import DncModal from "./dncModal";

import { useAuth } from "auth/AuthProvider";

type CSVContactsState = {
  step: number;
  isProcessing: boolean;
  disableUploadCSV?: boolean;
  csvData?: CSVContentsType;
  domainColumn: string;
};
export type CSVContactsProps = {
  onCancel: () => void;
  onDone?: (domainS3key: string, domainIndex: string, fileUrl: string, domainCount: number, uploadedFileName: string) => void;
};

const DoNotContact = () => {

  const { callApi } = useApi();
  const [DncItems, setDncItems] = useState([]);
  const [searchDncModal, setSearchDncModal] = useState(false);
  const [addDncModal, setAddDncModal] = useState(false);
  const [deleteDncModal, setDeleteDncModal] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearchedDncItems, setShowSearchedDncItems] = useState(false);
  const [searchedItems, setSearchedItems] = useState([])
  const [openViewModal, setOpenViewModal] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [viewLinesItems, setViewLinesItems] = useState([])

  const fetchDncItems = (async () => {
    const accountId = localStorage.getItem('account_id');
    setLoading(true);
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: { "action": "FETCH" }
    });
    if (response.success) {
      setDncItems(response.data.items);
    }
    setLoading(false);
  });

  useEffect(() => {
    fetchDncItems();
  }, []);

  const searchDncItems = (async () => {
    const accountId = localStorage.getItem('account_id')
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: { "action": "SEARCH", "items": textAreaValue }
    });
    if (response.success) {
      setSearchedItems(response.data.items);
      setSearchDncModal(false);
      setShowSearchedDncItems(true);
    }
  });

  const download = async (url: any, method: any, payload: any) => {
    const response = await callApi({
      url,
      method,
      data: payload
    });
    if (response.success) {
      if (response.data.status === "COMPLETED" || response.data.status === "FAILED") {
        return response;
      }
    }
    return ''
  }

  const downloadHeaderFile = async (headerId: any) => {
    const accountId = localStorage.getItem('account_id');
    var payload: any = { "action": "DOWNLOAD_FILE", "headerId": headerId }
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: payload
    });
    if (response.success) {
      notification.info({
        message: "DNC download started. Please wait.."
      })
      payload = { "action": "DOWNLOAD_STATUS", "requestId": response.data.requestId };
    }
    else {
      return notification.error({
        message: "Downlaod Failed"
      })
    }
    let intr = setInterval(async () => {
      let downloadRes: any = await download(url, method, payload);
      if (downloadRes.success) {
        if (downloadRes.data.status === "COMPLETED") {
          try {
            // window.open(downloadRes.data.s3URL, "_blank");
            const link = document.createElement('a');
            link.href = downloadRes.data.s3URL;
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link?.parentNode?.removeChild(link);
            clearInterval(intr);
          }
          catch (error) { console.log(error) }
        }
        else if (downloadRes.data.status === "FAILED") {
          notification.warn({
            message: "Downlaod Failed"
          })
          clearInterval(intr);
        }
      }
    }, 5000);
  }



  const DropDownMenu = (headerId: any) => {
    const { theme } = useAuth();

    return (
      <Menu>
        <Menu.Item style={{ color: theme.blackColor }} onClick={() => viewAction(headerId)}>
          <EyeOutlined style={{ marginRight: 5 }} />View
        </Menu.Item>
        <Menu.Item style={{ color: theme.blackColor }} onClick={() => downloadHeaderFile(headerId)}>
          <DownloadOutlined style={{ marginRight: 5 }} />Download
        </Menu.Item >
        <Menu.Item style={{ color: theme.antdConf.errorColor }} onClick={() => deleteHeader(headerId)}>
          <DeleteOutlined type="primary" style={{ marginRight: 5 }} />Delete
        </Menu.Item>
      </Menu>
    )
  }

  const addDncItems = (async () => {

    const accountId = localStorage.getItem('account_id')
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: { "action": "ADD", "items": textAreaValue }
    });
    if (response.success) {
      setLoading(true);
      await fetchDncItems();
      setLoading(false);
      notification.success({
        message: "Items have been added"
      })
      setAddDncModal(false);
    }


  });


  const deleteHeader = async (headerId: any) => {
    const accountId = localStorage.getItem('account_id');
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: {
        "action": "DELETE_HEADER",
        "headerId": headerId
      }
    });
    if (response.success) {
      setLoading(true);
      await fetchDncItems();
      setAddDncModal(false);
      notification.success({
        message: "Dnc header has been deleted"
      })
    }
  }

  const viewAction = async (headerId: any) => {
    const accountId = localStorage.getItem('account_id');
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: {
        "action": "FETCH_LINES",
        "headerId": headerId
      }
    });
    if (response.success) {
      setViewLinesItems(response.data.items);
    }
    setOpenViewModal(!openViewModal)
  }

  const deleteDncItems = async (name: any) => {
    let arr = [];
    arr.push(name)

    const accountId = localStorage.getItem('account_id');
    const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
    const response = await callApi({
      url,
      method,
      data: { "action": "DELETE", "items": ((!(arr[0] == '')) ? arr : textAreaValue) }
    });
    if (response.success) {
      setDeleteDncModal(false);
      setLoading(true);
      await fetchDncItems();
      setLoading(false);
      notification.success({
        message: "Items have been deleted"
      })
      console.log('item deleted')
    }

    setTextAreaValue([])

  };

  const dataSource =
    DncItems ?
      DncItems.map((dnc: any, i: any) => {
        return (
          {
            key: i,
            name: dnc.name,
            date: dnc.created,
            count: dnc.count,
            action: dnc.headerId
          }
        )
      })
      :
      [];
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Items',
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (headerId: any) => {
        return (
          <>
            <div onClick={(event) => event.stopPropagation()}>
              <Dropdown overlay={DropDownMenu(headerId)} trigger={["click"]} placement="bottomLeft" >
                <MenuOutlined />
              </Dropdown>
            </div>
          </>
        )
      }
    }
  ]

  const [state, setState] = useState<CSVContactsState>({ step: 1, isProcessing: false, domainColumn: "" });
  const [toggleUpload, setToggleUpload] = useState(false);

  const onUploadDone = useCallback(
    async (domainS3key: string, domainIndex: string, fileUrl: string, domainCount: number, uploadedFileName: string) => {
      setState((st) => ({ ...st, uploadKey: domainS3key, index: domainIndex, fileUrl: fileUrl, uploadedFileName: uploadedFileName, domainCount: domainCount }))

      setToggleUpload(false);
      const accountId = localStorage.getItem('account_id');
      const { url, method } = API_ENDPOINTS.DONOTCONTACT.getDncListUrl(accountId);
      const response = await callApi({
        url,
        method,
        data: { "action": "UPLOAD", "s3Key": fileUrl, "filename": uploadedFileName, "index": parseInt(domainIndex) }
      });
      if (response.success) {
        setLoading(true);
        await fetchDncItems();
        setLoading(false);
        setDeleteDncModal(false);
      }
    }
    ,
    []
  );

  return (
    <>
      <Flex justify="end" mt={10} mb={20} gap={15} align="center">
        {loading ? (
          <>
            <Skeleton.Button active style={{ width: 100 }} />
            <Skeleton.Button active style={{ width: 100 }} />
            <Skeleton.Button active style={{ width: 100 }} />
            <Skeleton.Button active style={{ width: 100 }} />
          </>
        ) : (<>

          <Flex>
            <Flex mr={10}>
              <Button type="primary" icon={<SearchOutlined />} onClick={() => setSearchDncModal(true)}>Search</Button>
            </Flex>
            <Flex mr={10}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddDncModal(true)}>Add</Button>
            </Flex>
            <Flex>
              <Button type="primary" icon={<UploadOutlined />} onClick={() => { setToggleUpload(!toggleUpload) }}>Upload</Button>
            </Flex>
            <Flex ml={10}>
              <Button type="primary" danger={true} icon={<DeleteOutlined />} onClick={() => setDeleteDncModal(true)}>Delete</Button>
            </Flex>
          </Flex>
        </>
        )}

      </Flex>
      <Skeleton loading={loading} active>
        <Div mt={20} mb={20}>
          <Table
            size="small"
            dataSource={dataSource}
            pagination={false}
            columns={columns}
            rowKey="id"
            rowClassName={classes.mailboxTableRow}
            onRow={(record, recordIndex) => ({
              onClick: event => { viewAction(record?.action) }
            })}
            style={{ cursor: "pointer" }}
          >
          </Table>
        </Div>
      </Skeleton>
      {addDncModal && <DncModal title="Add domains or email addresses" textAreaChange={setTextAreaValue} okText="Add" onOk={addDncItems} onCancel={() => { setAddDncModal(false) }} />}
      {deleteDncModal && <DncModal title="Delete domains or email addresses" textAreaChange={setTextAreaValue} okText="Delete" onOk={() => deleteDncItems('')} onCancel={() => { setDeleteDncModal(false) }} />}
      {searchDncModal && <DncModal title="Search domains or email addresses" textAreaChange={setTextAreaValue} okText="Search" onOk={searchDncItems} onCancel={() => { setSearchDncModal(false) }} />}
      {toggleUpload && <CSVUploadDnc onCancel={() => setToggleUpload(false)} onDone={onUploadDone} />}
      {showSearchedDncItems &&
        <Modal visible centered footer={null} onCancel={() => setShowSearchedDncItems(false)}>
          {searchedItems.length > 0 ? (searchedItems.map((name: any) => {
            return (
              <Tag
                closable onClose={() => deleteDncItems(name)}
                style={{ marginBottom: 10 }} >
                {name}
              </Tag>
            )
          })) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Modal>}
      {openViewModal &&
        <Modal
          visible
          onCancel={() => { setOpenViewModal(!openViewModal) }}
          footer={null}
          centered>
          {viewLinesItems.length > 0 ? (viewLinesItems.map((item: any) => {
            return (
              <Tag
              >
                {item.sk}
              </Tag>
            )
          })) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Modal>
      }
    </>
  );
};

export default DoNotContact;