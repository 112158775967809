import React, { useCallback, useEffect, useState, useMemo, useContext } from "react";
import { Modal, Table, Input, Form, notification } from "antd";
import { Text } from "styles/typography";
import { Flex, Div } from "styles/layout";
import useApi from "hooks/useApi";
import { useAuth } from "auth/AuthProvider";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";

interface ChangePasswordModalProps {
    id: string;
    mailboxEmail:string;
    onCancel: () => void;
    onOk: () => void;
}

const ChangePasswordModal = ({ onOk, onCancel, id,mailboxEmail }: ChangePasswordModalProps) => {
    const { setLoadingMessage } = useContext(appContext);
    const [newPassword, setNewPassword] = useState('');
    const [form] = Form.useForm();
    const { callApi } = useApi();
    const { user } = useAuth();
    const { email }: any = useMemo(() => {
        const {
            attributes: { email } = {
                email: ""
            },
        }: any = user;

        return {
            email,
        };
    }, [user]);
    console.log(newPassword);

    const getPayload = () => {

        let data = {
            "pwd": newPassword
        }
        return data;
    }

    const fetchContacts = (async () => {
        setLoadingMessage("Changing Password ...");
        // const { url, method } = API_ENDPOINTS.MAILBOX.getUpdatelUrl(id);
        const { url, method } = API_ENDPOINTS.MAILBOX.getUpdateMailboxPwdUrl(id);
        const response = await callApi({
            url,
            method,
            data: getPayload()
        });
        if (response.success) {
            onOk();
            onCancel();
        }
        setLoadingMessage("");
    });

    return (
        <Modal
            visible
            onOk={fetchContacts}
            okText="Save"
            onCancel={onCancel}
        >

            <Flex p="10px" direction="column">
                <Text variant="h6" align="center" block>
                    Change Password
                </Text>
                <Text variant="overline" color="secondary" block mb={5}>
                    <p className="text-email-center">{mailboxEmail}</p>
                </Text>
                <Div mt={20}>
                    <Form
                        form={form}
                        scrollToFirstError
                        layout="vertical"
                    >
                        <Text variant="overline" color="secondary">
                            New Password
                        </Text>
                        <Form.Item name="newPassword" style={{width:'60%'}}>
                            <Input type='password' onChange={(e) => { setNewPassword(e.target.value) }} />
                        </Form.Item>
                    </Form>
                </Div>
            </Flex>
        </Modal>
    );
};

export default ChangePasswordModal;