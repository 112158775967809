import AntelopeAvatarImage from "images/avatars/antelope.png";
import BatAvatarImage from "images/avatars/bat.png";
import CatAvatarImage from "images/avatars/cat.png";
import ChickenAvatarImage from "images/avatars/chicken.png";
import CrabAvatarImage from "images/avatars/crab.png";
import DogAvatarImage from "images/avatars/dog.png";
import Dog_2AvatarImage from "images/avatars/dog_2.png";
import EagleAvatarImage from "images/avatars/eagle.png";
import ElephantAvatarImage from "images/avatars/elephant.png";
import FoxAvatarImage from "images/avatars/fox.png";
import HamsterAvatarImage from "images/avatars/hamster.png";
import HippoAvatarImage from "images/avatars/hippo.png";
import KoalaAvatarImage from "images/avatars/koala.png";
import LadybugAvatarImage from "images/avatars/ladybug.png";
import LionAvatarImage from "images/avatars/lion.png";
import MonkeyAvatarImage from "images/avatars/monkey.png";
import OctopusAvatarImage from "images/avatars/octopus.png";
import OwlAvatarImage from "images/avatars/owl.png";
import ParrotAvatarImage from "images/avatars/parrot.png";
import PenguinAvatarImage from "images/avatars/penguin.png";
import PigAvatarImage from "images/avatars/pig.png";
import TurtleAvatarImage from "images/avatars/turtle.png";
import UnicornAvatarImage from "images/avatars/unicorn.png";
import WhaleAvatarImage from "images/avatars/whale.png";
import WolfAvatarImage from "images/avatars/wolf.png";

import AccBonFireImage from "images/avatars/account/bonfire.png";
import AccCornImage from "images/avatars/account/corn.png";
// import AccDragonFlyImage from "images/avatars/account/dragonfly.png";
import AccEggImage from "images/avatars/account/egg.png";
import AccFireImage from "images/avatars/account/fire.png";
// import AccFishImage from "images/avatars/account/fish.png";
import AccHammerImage from "images/avatars/account/hammer.png";
import AccHouseImage from "images/avatars/account/house.png";
import AccLeafImage from "images/avatars/account/leaf.png";
import AccMeatImage from "images/avatars/account/meat.png";
import AccMeteoriteImage from "images/avatars/account/meteorite.png";
import AccNecklaceImage from "images/avatars/account/necklace.png";
import AccNeedleImage from "images/avatars/account/needle.png";
import AccShellImage from "images/avatars/account/shell.png";
import AccSkinImage from "images/avatars/account/skin.png";
import AccTotemImage from "images/avatars/account/totem.png";
import { AvatarEnum } from "types/Campaign";
import { AvatarAccountEnum } from "types/Accounts";
import BeeAvatarImage from "images/avatars/bee.png";
import CatyAvatarImage from "images/avatars/caty.png";
import ChameleonAvatarImage from "images/avatars/chameleon.png";
import SnakeAvatarImage from "images/avatars/snake.png";
import HorseAvatarImage from "images/avatars/horse.png";
import LionessAvatarImage from "images/avatars/lioness.png";
import SquirrelAvatarImage from "images/avatars/squirrel.png";
import FoxxAvatarImage from "images/avatars/foxx.png";
import FrogAvatarImage from "images/avatars/frog.png";
import ClownFishAvatarImage from "images/avatars/clown-fish.png";


import AccAloeVeraImage from "images/avatars/account/aloe-vera.png";
import AccCometImage from "images/avatars/account/comet.png";
import AccConchShellImage from "images/avatars/account/conch-shell.png";
import AccDesignVaseImage from "images/avatars/account/designvase.png";
import AccEasterEggImage from "images/avatars/account/easter-egg.png";
import AccFireblastImage from "images/avatars/account/fireblast.png";
import AccFireIgniteImage from "images/avatars/account/fireignite.png";
import AccHerbalImage from "images/avatars/account/herbal.png";
import AccHomeImage from "images/avatars/account/home.png";
import AccMapleLeafImage from "images/avatars/account/maple-leaf.png";
import AccMeatsImage from "images/avatars/account/meats.png";
import AccMeteoriteCircleImage from "images/avatars/account/meteoritecircle.png";
import AccSeaSnailImage from "images/avatars/account/sea-snail.png";
import AccShotMeteoriteImage from "images/avatars/account/shotmeteorite.png";
import AccThorImage from "images/avatars/account/thor.png";
import AccTotemCircleImage from "images/avatars/account/totemcircle.png";
import AccTotemStandImage from "images/avatars/account/totemstand.png";






export const getAvatarImage = (avatar: AvatarEnum | AvatarAccountEnum) => {
  switch (avatar) {
    case AvatarEnum.ANTELOPE:
      return AntelopeAvatarImage;
    case AvatarEnum.BAT:
      return BatAvatarImage;
    case AvatarEnum.CAT:
      return CatAvatarImage;
    case AvatarEnum.CHICKEN:
      return ChickenAvatarImage;
    case AvatarEnum.CRAB:
      return CrabAvatarImage;
    case AvatarEnum.DOG:
      return DogAvatarImage;
    case AvatarEnum.DOG_2:
      return Dog_2AvatarImage;
    case AvatarEnum.EAGLE:
      return EagleAvatarImage;
    case AvatarEnum.ELEPHANT:
      return ElephantAvatarImage;
    case AvatarEnum.FOX:
      return FoxAvatarImage;
    case AvatarEnum.HAMSTER:
      return HamsterAvatarImage;
    case AvatarEnum.HIPPO:
      return HippoAvatarImage;
    case AvatarEnum.KOALA:
      return KoalaAvatarImage;
    case AvatarEnum.LADYBUG:
      return LadybugAvatarImage;
    case AvatarEnum.LION:
      return LionAvatarImage;
    case AvatarEnum.MONKEY:
      return MonkeyAvatarImage;
    case AvatarEnum.OCTOPUS:
      return OctopusAvatarImage;
    case AvatarEnum.OWL:
      return OwlAvatarImage;
    case AvatarEnum.PARROT:
      return ParrotAvatarImage;
    case AvatarEnum.PENGUIN:
      return PenguinAvatarImage;
    case AvatarEnum.PIG:
      return PigAvatarImage;
    case AvatarEnum.TURTLE:
      return TurtleAvatarImage;
    case AvatarEnum.UNICORN:
      return UnicornAvatarImage;
    case AvatarEnum.WHALE:
      return WhaleAvatarImage;
    case AvatarEnum.WOLF:
      return WolfAvatarImage;
    case AvatarEnum.BEE:
      return BeeAvatarImage;
    case AvatarEnum.HORSE:
      return HorseAvatarImage;
    case AvatarEnum.SNAKE:
      return SnakeAvatarImage;
    case AvatarEnum.CLOWNFISH:
      return ClownFishAvatarImage;
    case AvatarEnum.CHAMELEON:
      return ChameleonAvatarImage;
    case AvatarEnum.SQUIRREL:
      return SquirrelAvatarImage;
    case AvatarEnum.LIONESS:
      return LionessAvatarImage;
    case AvatarEnum.FOXX:
      return FoxxAvatarImage;
    case AvatarEnum.CATY:
      return CatyAvatarImage;
    case AvatarEnum.FROG:
      return FrogAvatarImage;


    case AvatarAccountEnum.BONFIRE:
      return AccBonFireImage;
    case AvatarAccountEnum.CORN:
      return AccCornImage;
    // case AvatarAccountEnum.DRAGONFLY:
    //   return AccDragonFlyImage;
    case AvatarAccountEnum.EGG:
      return AccEggImage;
    case AvatarAccountEnum.FIRE:
      return AccFireImage;
    // case AvatarAccountEnum.FISH:
    //   return AccFireImage;
    case AvatarAccountEnum.HAMMER:
      return AccHammerImage;
    case AvatarAccountEnum.HOUSE:
      return AccHouseImage;
    case AvatarAccountEnum.LEAF:
      return AccLeafImage;
    case AvatarAccountEnum.MEAT:
      return AccMeatImage;
    case AvatarAccountEnum.METEORITE:
      return AccMeteoriteImage;
    case AvatarAccountEnum.NECKLACE:
      return AccNecklaceImage;
    case AvatarAccountEnum.NEEDLE:
      return AccNeedleImage;
    case AvatarAccountEnum.SHELL:
      return AccShellImage;
    case AvatarAccountEnum.SKIN:
      return AccSkinImage;
    case AvatarAccountEnum.TOTEM:
      return AccTotemImage;

      case AvatarAccountEnum.ALOEVERA:
      return AccAloeVeraImage ;
      case AvatarAccountEnum.COMET:
      return AccCometImage;
      case AvatarAccountEnum.CONCHSHELL:
      return AccConchShellImage;
      case AvatarAccountEnum.DESIGNVASE:
      return AccDesignVaseImage;
      case AvatarAccountEnum.EASTEREGG:
      return AccEasterEggImage;
      case AvatarAccountEnum.FIREBLAST:
      return AccFireblastImage;
      case AvatarAccountEnum.FIREIGNITE:
      return AccFireIgniteImage;
      case AvatarAccountEnum.HOME:
      return AccHomeImage;
      case AvatarAccountEnum.MAPLELEAF:
      return AccMapleLeafImage;
      case AvatarAccountEnum.MEATS:
      return AccMeatsImage;
      case AvatarAccountEnum.METEORITECIRCLE:
      return AccMeteoriteCircleImage;
      case AvatarAccountEnum.HERBAL:
      return AccHerbalImage;
      case AvatarAccountEnum.SEASNAIL:
      return AccSeaSnailImage;
      case AvatarAccountEnum.THOR:
      return AccThorImage;
      case AvatarAccountEnum.SHOTMETEORITE:
      return AccShotMeteoriteImage;
      case AvatarAccountEnum.TOTEMCIRCLE:
      return AccTotemCircleImage;
      case AvatarAccountEnum.TOTEMSTAND:
      return AccTotemStandImage;
    default:
      return LionAvatarImage;
  }
};


const getRandomNumberBetween = (min: number, max: number): number => min + Math.floor(Math.random() * max);

const pickRandom = (list: typeof AvatarEnum | typeof AvatarAccountEnum) => {
  const allAvatars = [];
  for (const avatarEnum in list) {
    allAvatars.push(avatarEnum);
  }

  return allAvatars[getRandomNumberBetween(0, allAvatars.length)];
}

export const getRandomAvatar = () => {
  return pickRandom(AvatarEnum);
};

export const getRandomAccountAvatar = () => {
  return pickRandom(AvatarAccountEnum);
};

