import { useEffect, useState } from 'react';
import { useAuth } from "auth/AuthProvider";

function useLogoutTimer(isUserLoggedIn: boolean, inactiveDuration: number = 2 * 60 * 60 * 1000): number | null {
  // const [logoutTimer, setLogoutTimer] = useState<number | null>(null);
  const { logout } = useAuth();

  useEffect(() => {
    let timeoutId: number;

    const resetLogoutTimer = () => {
      clearTimeout(timeoutId); // Clear previous timer
      timeoutId = window.setTimeout(logoutUser, inactiveDuration); // Set new timer for inactiveDuration
      // setLogoutTimer(timeoutId);
    };

    const logoutUser = () => {
      // Perform logout operation here, such as redirecting to logout page or clearing user session
      console.log("User logged out due to inactivity.");
      logout()
    };

    const handleUserActivity = () => {
      resetLogoutTimer(); // Reset the logout timer on user activity
    };

    if (isUserLoggedIn) {
      // Add event listeners to track user activity only if user is logged in
      document.addEventListener("mousemove", handleUserActivity,{ passive: true });
      document.addEventListener("keydown", handleUserActivity,{ passive: true });

      // Initialize logout timer
      resetLogoutTimer();
    }

    // Clean up event listeners and timeout on unmount or when user logs out
    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      clearTimeout(timeoutId); // Clear timeout on unmount or when user logs out
    };
  }, [isUserLoggedIn, inactiveDuration]); // Re-run effect when user login status or inactiveDuration changes
  return 0;
}

export default useLogoutTimer;
