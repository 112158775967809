import React from "react";
import { Button } from "antd";
import { Flex } from "styles/layout";

interface ActionsProps {
  onNext: () => void;
  onBack: () => void;
  showNext: boolean;
  showBack: boolean;
  nextButtonLabel: string;
  backButtonLabel: string;
  nextDisabled: boolean;
  backDisabled: boolean;
  skipButtonLabel?: string;
  onSkip?: () => void;
  showSkip?: boolean;
  skipDisabled?: boolean;
}

const Actions = ({
  onNext,
  onBack,
  showNext,
  showBack,
  nextButtonLabel,
  backButtonLabel,
  nextDisabled,
  backDisabled,
  skipButtonLabel,
  onSkip,
  showSkip,
  skipDisabled
}: ActionsProps) => (
  <Flex w="100%" mt={20}>
    {showBack && (
      <Button style={{ marginRight: "auto" }} onClick={onBack} disabled={backDisabled}>
        {backButtonLabel}
      </Button>
    )}
    {showSkip && (
      <Button style={{ marginRight: "10px" }} onClick={onSkip} disabled={skipDisabled}>
        {skipButtonLabel}
      </Button>
    )}
    {showNext && (
      <Button onClick={onNext} style={showSkip ? {} : { marginLeft: "auto" }} type="primary" disabled={nextDisabled}>
        {nextButtonLabel}
      </Button>
    )}
  </Flex>
);

export default Actions;
