import {
  CampaignProspectType,
  CampaignTagType,
  SequenceResponseType,
} from "types/Campaign";
import { NotificationType } from "types/Notification";
import { ProspectVariableType, ProspectVariableTypeV2 } from "types/Prospect";
import { SpinType } from "types/Spinner";
import { TouchType } from "types/Touch";
import { TimeZoneType } from "../utils/dateTime";

export const spinTypesSelector = (state: any) =>
  ((state?.spinner?.types || []) as SpinType[]).sort((a, b) => a.sequence - b.sequence);

export const areSpinTypesLoadingSelector = (state: any) =>
  !!state?.spinner?.loading as boolean;

export const getCampaignVariablesSelector = (campaignId: string) => (
  state: any
) => {
  const { systemVariables = [], mailboxVariables = [], quicklineVariables = [], prospectVariables = [] } = state?.campaignVariable[campaignId]?.entities || {};
  return ([...prospectVariables, ...mailboxVariables, ...systemVariables, ...quicklineVariables]) as ProspectVariableType[]
};

export const getCampaignQuickLineVariablesSelector = (campaignId: string) => (
  state: any
) => {
  const { quicklineVariables = [] } = state?.campaignVariable[campaignId]?.entities || {};
  return ([...quicklineVariables]) as ProspectVariableType[]
};

export const getCampaignNonQuickLineVariablesSelector = (campaignId: string) => (
  state: any
) => {
  const { systemVariables = [], mailboxVariables = [], prospectVariables = [] } = state?.campaignVariable[campaignId]?.entities || {};
  return ([...prospectVariables, ...mailboxVariables, ...systemVariables]) as ProspectVariableType[]
};

export const getCampaignVariablesSelectorV2 = (campaignId: string) => (
  state: any
) => {
  const { systemVariables = [], mailboxVariables = [], prospectVariables = [] } = state?.campaignVariable[campaignId]?.entities || {};
  return { prospectVariables, mailboxVariables, systemVariables } as ProspectVariableTypeV2;
};

export const getCampaignProspectVariablesSelector = (campaignId: string) => (
  state: any
) =>
  (state?.campaignProspectVariable[campaignId]?.entities ||
    []) as ProspectVariableType[];

export const getCampaignTouchesSelector = (campaignId: string) => (
  state: any
) => (state?.campaign[campaignId]?.touches?.entities || []) as TouchType[];

export const areCampaignTouchesLoadingSelector = (campaignId: string) => (
  state: any
) => state?.campaign[campaignId]?.touches?.loading as boolean;

export const getCampaignProspectsSelector: (
  campaignId: string
) => (
    state: any
  ) => {
    prospects: CampaignProspectType[];
    totalPages: number;
  } = (campaignId: string) => (state: any) => ({
    prospects: state?.campaign[campaignId]?.prospects?.entities || [],
    totalPages: state?.campaign[campaignId]?.prospects?.totalPages,
  });

export const areCampaignProspectsLoadingSelector = (campaignId: string) => (
  state: any
) => state?.campaign[campaignId]?.prospects?.loading as boolean;

export const getCampaignTagsSelector: (
  campaignId: string
) => (
    state: any
  ) => {
    tags: CampaignTagType[];
  } = (campaignId: string) => (state: any) => ({
    tags: state?.campaign[campaignId]?.tags?.entities || [],
  });

export const areCampaignTagsLoadingSelector = (campaignId: string) => (
  state: any
) => state?.campaign[campaignId]?.tags?.loading as boolean;

export const getTimeZoneSelector = (campaignId: string) => (
  state: any
) =>
  (state?.campaign[campaignId]?.timezones?.entities ||
    []) as TimeZoneType[];

export const areTimeZoneLoadingSelector = () => (state: any) =>
  state?.timezones?.loading as boolean;

export const getCampaignSequencesSelector: (
  campaignId: string
) => (state: any) => SequenceResponseType[] = (campaignId: string) => (
  state: any
) => state?.campaign[campaignId]?.sequences?.entities || [];

export const areCampaignSequencesLoadingSelector = (campaignId: string) => (
  state: any
) => state?.campaign[campaignId]?.sequences?.loading as boolean;

export const notificationsSelector = (state: any) =>
  (state?.notification?.entities || []) as NotificationType[];

export const canModifyCampaignState = (campaignId: string) => (
  state: any
) => state?.campaign[campaignId]?.details?.status !== "Active";