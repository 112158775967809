import { EventEnum } from "types/Event";

export const getEventText = (eventType: EventEnum) => {
  switch (eventType) {
    case EventEnum.BOUNCED:
      return "Mail Bounced";
    case EventEnum.REPLIED:
      return "Reply Recieved";
    case EventEnum.CLICKED:
      return "Mail Clicked";
    case EventEnum.OPENED:
      return "Mail Opened";
    case EventEnum.SENT:
      return "Mail Sent";
    case EventEnum.AUTOREPLY:
      return "Mail Replied";
    case EventEnum.OUTOFOFFICE:
      return "Out Of Office";
    case EventEnum.FAILED:
      return "Failed To Send";
    case EventEnum.SOFTBOUNCED:
      return "Mail SoftBounced";
    case EventEnum.HARDBOUNCED:
      return "Mail HardBounced";
    case EventEnum.UNSUBSCRIBED:
      return "Mail Unsubscribed";
    default:
      return "";
  }
};
