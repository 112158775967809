export interface ProspectVariableType {
  caseNeutralName: string;
  name: string;
  isMandatory: Boolean;
}

export interface ProspectVariableTypeV2 {
  systemVariables: ProspectVariableType[]
  mailboxVariables: ProspectVariableType[]
  prospectVariables: ProspectVariableType[]
}

export enum ProspectStageEnum {
  INACTIVE = "Inactive",
  ACTIVE = "Active",
  FINISHED = "Finished",
}

export enum ProspectStatusEnum {
  PAUSED = "Paused",
  CREATED = "Created",
  READY = "Ready",
  RESUMED = "resumed",
  QUEUED = "Queued",
  CLICKED = "Clicked",
  SENT = "Sent",
  OPENED = "Opened",
  NOT_REPLIED = "Not Replied",
  REPLIED = "Replied",
  UNSUBSCRIBE = "Unsubscribed",
  BOUNCED = "Bounced",
  DROPPED = "Dropped",
}

export type ProspectType = {
  campId: string;
  company: string;
  createdAt: string;
  createdBy: string;
  domain: string;
  email: string;
  firstTouchSentTime: number;
  sequenceId: string;
  firstname: string;
  id: string;
  lastEmailRepliedTime: number;
  lastEmailSentTime: number;
  lastTouchNumber: number;
  lastname: string;
  messageIds: string[];
  nextRunTime: number;
  seqId: string;
  status?: ProspectStageEnum;
  subStatus: ProspectStatusEnum;
  timezone: string;
  updatedAt: string;
  updatedBy: string;
  whisperStatus: string;
  attributes: { [key: string]: string };
};


export type ProspectsType = {
  campaignId: string;
  company: string;
  createdAt?: string;
  createdBy?: string;
  domain: string;
  email: string;
  firstTouchSentTime?: number;
  sequenceId: string;
  firstname: string;
  id: string;
  lastEmailRepliedTime?: number;
  lastEmailSentTime?: number;
  lastTouchNumber?: number;
  lastname: string;
  messageIds?: string[];
  nextRunTime?: number;
  status?: ProspectStageEnum;
  subStatus?: ProspectStatusEnum;
  timezone?: string;
  updatedAt?: string;
  updatedBy?: string;
  whisperStatus?: string;
  attributes: Array<{}>;
  campaignName: string;
  linkedinId: string;
  sequenceName: string;
  accountId: string;
  accountName: string;
};

export type ProspectsStatusType = {
  totalProspects: number;
  totalDomains: number;
  byStatus: Array<{}>;
  bySequence: Array<{}>;
};

export type metricsType = {
  type: string;
  value: number;
};

export type statsProgressbar = {
  daysPassed: number;
  daysRemaining: number;
  startDate: String;
  endDate: String;
};
