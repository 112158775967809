import React, { useState, useCallback, useMemo } from "react";
import WYSIWYGEditor from "components/Froala/Editor";
import { InlineEditorWrapper } from "./styles";
import { SpinnerTypeEnum } from "types/Spinner";
import { ProspectVariableType } from "types/Prospect";

export interface SpinnerInputProps {
  value: string;
  type: SpinnerTypeEnum;
  onChange: (value: string) => void;
  variables?: ProspectVariableType[];
  spinners?: { label: string; value: string }[];
  quicklinks?: ProspectVariableType[];
  placeholder?: string;
  isDisabled?: boolean;
}

const SpinnerInput = ({
  value,
  type,
  onChange,
  variables = [],
  spinners = [],
  quicklinks = [],
  placeholder = "Edit Spinner",
  isDisabled = false
}: SpinnerInputProps) => {
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const onBlur = useCallback(() => setIsEditorFocused(false), [setIsEditorFocused]);

  const onFocus = useCallback(() => {
    setIsEditorFocused(true);
  }, [setIsEditorFocused]);

  const moreRichButtons = useMemo(() => (type === SpinnerTypeEnum.PLAIN_TEXT ? [] : ["insertLink"]), [type]);

  const moreTextButtons = useMemo(() => (type === SpinnerTypeEnum.PLAIN_TEXT ? [] : ["textColor", "bold", "italic", "backgroundColor", "underline"]), [type]);

  return (
    <InlineEditorWrapper focus={isEditorFocused}>
      <WYSIWYGEditor
        tag="textarea"
        editorClass="outwin-inline-editor"
        value={value}
        onChange={onChange}
        config={{
          height: 24,
          attribution: false,
          toolbarBottom: false,
          placeholderText: placeholder,
          pastePlain: true,
          toolbarInline: true,
          htmlAllowedTags: ["p"],
          multiLine: false,
          initOnClick: false,
          charCounterMax: -1,
          events: {
            blur: onBlur,
            focus: onFocus
          },
          toolbarVisibleWithoutSelection: true
        }}
        moreRichButtons={moreRichButtons}
        moreTextButtons={moreTextButtons}
        variables={variables}
        spinners={spinners}
        quicklinks={quicklinks}
        isDisabled={isDisabled}
      />
    </InlineEditorWrapper>
  );
};

export default SpinnerInput;
