import { useMachine } from "@xstate/react";
import { Button, Input, Pagination, Skeleton } from "antd";
import Column from "antd/lib/table/Column";
import { API_ENDPOINTS } from "constants/api";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import useApi from "hooks/useApi";
import { OpenTrackingModal } from "pages/Settings/OpenTracking/OpenTracking";
import { MailboxToggleView, MailboxViewEnum } from "pages/Settings/ViewMode";
import { useCallback, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { CardLayout } from "./CardLayout";
import { FETCH_MACHINE_DOMAIN_LIST } from "./Domain.machine";
import { DomainCard } from "./DomainCard";
import { apiResHandler, DOMAIN_TABLE_META } from "./DomainCommon";
import { ListLayout } from "./ListLayout";
import { DOMAIN_TRACKING_LABEL } from "./metaData";
import { NothingBurger } from "./NothingBurger";

type DomainPageProps = {
  setNavPaths: SetNavPathsFn;
};
const DomainPage = ({ setNavPaths }: DomainPageProps) => {
  const { callApi } = useApi();
  // const { callApi } = useFakeApi();
  const match = useRouteMatch<{ id: string }>();

  const { url, method } = API_ENDPOINTS.MAILBOX.getDomainList(match.params.id);
  const [state, send] = useMachine(FETCH_MACHINE_DOMAIN_LIST, {
    actions: {
      notifySuccess: () => { }
    },
    services: {
      fetchData: (_, evt) => {
        return callApi({ url, method }).then((res) => apiResHandler(res));
        // return callApi({ url, method, response: DOMAIN_LIST_RESPONSE, timeout: 3_000 }).then((res) => apiResHandler(res));
      }
    }
  });

  useEffect(() => {
    const accountName = String(localStorage.getItem("accountName"));
    if (accountName) {
      setNavPaths([
        {
          label: accountName,
          linkTo: `/account/${match.params?.id}/campaigns`
        },
        { label: DOMAIN_TRACKING_LABEL }
      ]);
    }
    return () => setNavPaths([]);
  }, [match, setNavPaths]);

  useEffect(() => {
    send({ type: "FETCH" });
  }, [send]);

  const stateCtx = state.context;
  const domainList = stateCtx.data?.domains ?? [];
  const searchedList = stateCtx.searchTerm ? domainList.filter((el) => el.domain.toLowerCase().includes(stateCtx.searchTerm.toLowerCase())) : domainList;
  const paginatedList = searchedList.slice(
    (stateCtx.pagination.current - 1) * stateCtx.pagination.pageSize,
    (stateCtx.pagination.current - 1) * stateCtx.pagination.pageSize + stateCtx.pagination.pageSize
  );
  const closeModal = useCallback(async() => {
    const { url, method } = API_ENDPOINTS.MAILBOX.getDomainList(match.params.id);
    await callApi({ url, method }).then((res) => apiResHandler(res));
    send({ type: "FETCH" });
    send({ type: "TOGGLE_DOMAIN", data: { domain: "" } })
  },[send])

  switch (state.value) {
    case "loading":
      return <Skeleton active loading />;
    case "failure":
      return (
        <Div>
          <Text variant="sub2" color="error">
            {stateCtx.error.message}
          </Text>
          <Button onClick={() => send({ type: "FETCH" })}>Retry</Button>
        </Div>
      );
    case "success":
      return (
        <>
          <Flex direction="column" gap={20} pb={40}>
            {!!domainList.length ? (
              <>
                <Flex justify="flex-end" gap={20}>
                  {domainList.length > 2 && (
                    <Flex align="center">
                      <Input placeholder="Search" onChange={(e) => send({ type: "UPDATE_CTX", data: { searchTerm: e.target.value } })} />
                    </Flex>
                  )}
                  <Flex>
                    <MailboxToggleView active={stateCtx.currentViewType} onChange={(view) => send({ type: "TOGGLE_VIEW", data: { view } })} />
                  </Flex>
                </Flex>
                {searchedList.length ? (
                  <Flex direction="column" gap={30}>
                    {stateCtx.currentViewType === MailboxViewEnum.CARD ? (
                      <CardLayout loading={false} list={paginatedList}>
                        {({ item }) => (
                          <DomainCard onClick={(arg) => send({ type: "TOGGLE_DOMAIN", data: { domain: arg.domain } })} item={item} loading={false} />
                        )}
                      </CardLayout>
                    ) : (
                      <ListLayout list={paginatedList} keyX={"domain"} onClick={(arg) => send({ type: "TOGGLE_DOMAIN", data: { domain: arg.domain } })}>
                        {() =>
                          DOMAIN_TABLE_META.map((dMeta) => <Column title={dMeta.title} key={dMeta.key} dataIndex={dMeta.dataIndex} render={dMeta?.render} />)
                        }
                      </ListLayout>
                    )}
                    {!!searchedList.length && (
                      <Flex justify="flex-end">
                        <Pagination
                          defaultCurrent={1}
                          defaultPageSize={stateCtx.pagination.pageSize}
                          total={searchedList.length}
                          onChange={(current) => send({ type: "UPDATE_CTX", data: { pagination: { current, pageSize: stateCtx.pagination.pageSize } } })}
                        />
                      </Flex>
                    )}
                  </Flex>
                ) : (
                  <NothingBurger msg={"No items found"} />
                )}
              </>
            ) : (
              <NothingBurger />
            )}
          </Flex>
          {!!stateCtx.domain.length && <OpenTrackingModal inbox={stateCtx.domain} onCloseModal={() => closeModal()} />}
        </>
      );
    default:
      return null;
  }
};

export default withAppBar(DomainPage);
