const locationFilterValues = [
    { id: 'Arizona, United States', name: 'Arizona, United States' }, //states
    { id: 'Ohio, United States', name: 'Ohio, United States' },
    { id: 'Alabama, United States', name: 'Alabama, United States' },
    { id: 'Colorado, United States', name: 'Colorado, United States' },
    { id: 'Washington, United States', name: 'Washington, United States' },
    { id: 'North Carolina, United States', name: 'North Carolina, United States' },
    { id: 'Georgia, United States', name: 'Georgia, United States' },
    { id: 'Pennsylvania, United States', name: 'Pennsylvania, United States' },
    { id: 'Connecticut, United States', name: 'Connecticut, United States' },
    { id: 'Missouri, United States', name: 'Missouri, United States' },
    { id: 'Arkansas, United States', name: 'Arkansas, United States' },
    { id: 'Indiana, United States', name: 'Indiana, United States' },
    { id: 'Louisiana, United States', name: 'Louisiana, United States' },
    { id: 'Maine, United States', name: 'Maine, United States' },
    { id: 'Mississippi, United States', name: 'Mississippi, United States' },
    { id: 'West Virginia, United States', name: 'West Virginia, United States' },
    { id: 'South Dakota, United States', name: 'South Dakota, United States' },
    { id: 'Michigan, United States', name: 'Michigan, United States' },
    { id: 'District of Columbia, United States', name: 'District of Columbia, United States' },
    { id: 'Idaho, United States', name: 'Idaho, United States' },
    { id: 'Wisconsin, United States', name: 'Wisconsin, United States' },
    { id: 'Kansas, United States', name: 'Kansas, United States' },
    { id: 'Iowa, United States', name: 'Iowa, United States' },
    { id: 'Delaware, United States', name: 'Delaware, United States' },
    { id: 'New Mexico, United States', name: 'New Mexico, United States' },
    { id: 'New Hampshire, United States', name: 'New Hampshire, United States' },
    { id: 'Alaska, United States', name: 'Alaska, United States' },
    { id: 'Nebraska, United States', name: 'Nebraska, United States' },
    { id: 'Illinois, United States', name: 'Illinois, United States' },
    { id: 'Texas, United States', name: 'Texas, United States' },
    { id: 'Oklahoma, United States', name: 'Oklahoma, United States' },
    { id: 'South Carolina, United States', name: 'South Carolina, United States' },
    { id: 'Hawaii, United States', name: 'Hawaii, United States' },
    { id: 'Kentucky, United States', name: 'Kentucky, United States' },
    { id: 'Oregon, United States', name: 'Oregon, United States' },
    { id: 'California, United States', name: 'California, United States' },
    { id: 'Vermont, United States', name: 'Vermont, United States' },
    { id: 'Virginia, United States', name: 'Virginia, United States' },
    { id: 'Massachusetts, United States', name: 'Massachusetts, United States' },
    { id: 'Rhode Island, United States', name: 'Rhode Island, United States' },
    { id: 'Maryland, United States', name: 'Maryland, United States' },
    { id: 'Nevada, United States', name: 'Nevada, United States' },
    { id: 'Tennessee, United States', name: 'Tennessee, United States' },
    { id: 'Minnesota, United States', name: 'Minnesota, United States' },
    { id: 'New York, United States', name: 'New York, United States' },
    { id: 'Wyoming, United States', name: 'Wyoming, United States' },
    { id: 'New Jersey, United States', name: 'New Jersey, United States' },
    { id: 'North Dakota, United States', name: 'North Dakota, United States' },
    { id: 'Montana, United States', name: 'Montana, United States' },
    { id: 'Utah, United States', name: 'Utah, United States' },
    { id: 'Windsor and Maidenhead, United Kingdom', name: 'Windsor and Maidenhead, United Kingdom' },
    { id: 'Bicol, Philippines', name: 'Bicol, Philippines' },
    { id: 'Changwat Ang Thong, Thailand', name: 'Changwat Ang Thong, Thailand' },
    { id: 'Ērgļi, Latvia', name: 'Ērgļi, Latvia' },
    { id: 'Yaracuy, Venezuela', name: 'Yaracuy, Venezuela' },
    { id: 'Toledo, Spain', name: 'Toledo, Spain' },
    { id: 'Copperbelt, Zambia', name: 'Copperbelt, Zambia' },
    {
        id: 'Departamento de Risaralda, Colombia',
        name: 'Departamento de Risaralda, Colombia'
    },
    { id: 'Liverpool, United Kingdom', name: 'Liverpool, United Kingdom' },
    {
        id: 'Saint Helena, Saint Helena',
        name: 'Saint Helena, Saint Helena'
    },
    { id: 'Monufia, Egypt', name: 'Monufia, Egypt' },
    { id: 'Saida, Algeria', name: 'Saida, Algeria' },
    { id: 'Nigde, Turkey', name: 'Nigde, Turkey' },
    { id: 'Hajdú-Bihar, Hungary', name: 'Hajdú-Bihar, Hungary' },
    { id: 'Trencin, Slovakia', name: 'Trencin, Slovakia' },
    { id: 'Pljevlja, Montenegro', name: 'Pljevlja, Montenegro' },
    { id: 'Hiroshima, Japan', name: 'Hiroshima, Japan' },
    {
        id: 'Provincia de Matanzas, Cuba',
        name: 'Provincia de Matanzas, Cuba'
    },
    { id: 'Queensland, Australia', name: 'Queensland, Australia' },
    {
        id: 'Province of Zamboanga del Norte, Philippines',
        name: 'Province of Zamboanga del Norte, Philippines'
    },
    { id: 'Western, Fiji', name: 'Western, Fiji' },
    { id: 'Medvode, Slovenia', name: 'Medvode, Slovenia' },
    { id: 'Cankuzo Province, Burundi', name: 'Cankuzo Province, Burundi' },
    { id: 'Bururi Province, Burundi', name: 'Bururi Province, Burundi' },
    {
        id: 'Provincia de Cotopaxi, Ecuador',
        name: 'Provincia de Cotopaxi, Ecuador'
    },
    { id: 'Almaty Oblysy, Kazakhstan', name: 'Almaty Oblysy, Kazakhstan' },
    { id: 'Vsetín District, Czechia', name: 'Vsetín District, Czechia' },
    {
        id: 'Changwat Samut Prakan, Thailand',
        name: 'Changwat Samut Prakan, Thailand'
    },
    {
        id: 'Balqa, Hashemite Kingdom of Jordan',
        name: 'Balqa, Hashemite Kingdom of Jordan'
    },
    {
        id: 'South Gloucestershire, United Kingdom',
        name: 'South Gloucestershire, United Kingdom'
    },
    {
        id: 'Cambridgeshire, United Kingdom',
        name: 'Cambridgeshire, United Kingdom'
    },
    { id: 'Uíge, Angola', name: 'Uíge, Angola' },
    {
        id: "Tyumenskaya Oblast', Russia",
        name: "Tyumenskaya Oblast', Russia"
    },
    { id: 'Khabarovsk, Russia', name: 'Khabarovsk, Russia' },
    {
        id: 'Provincia de Monsenor Nouel, Dominican Republic',
        name: 'Provincia de Monsenor Nouel, Dominican Republic'
    },
    { id: 'Mokhotlong, Lesotho', name: 'Mokhotlong, Lesotho' },
    {
        id: "Kostromskaya Oblast', Russia",
        name: "Kostromskaya Oblast', Russia"
    },
    { id: 'Saitama, Japan', name: 'Saitama, Japan' },
    { id: 'Magnesia, Greece', name: 'Magnesia, Greece' },
    { id: 'Bács-Kiskun, Hungary', name: 'Bács-Kiskun, Hungary' },
    {
        id: 'Sîngerei, Republic of Moldova',
        name: 'Sîngerei, Republic of Moldova'
    },
    {
        id: 'Provincia di Crotone, Italy',
        name: 'Provincia di Crotone, Italy'
    },
    { id: 'Sila, Chad', name: 'Sila, Chad' },
    { id: 'Siguldas Novads, Latvia', name: 'Siguldas Novads, Latvia' },
    {
        id: 'Inezgane-Ait Mellou, Morocco',
        name: 'Inezgane-Ait Mellou, Morocco'
    },
    {
        id: 'Changwat Nakhon Pathom, Thailand',
        name: 'Changwat Nakhon Pathom, Thailand'
    },
    { id: 'Tinh Tien Giang, Vietnam', name: 'Tinh Tien Giang, Vietnam' },
    { id: 'Canillo, Andorra', name: 'Canillo, Andorra' },
    { id: 'Paul, Cabo Verde', name: 'Paul, Cabo Verde' },
    { id: 'Cunene Province, Angola', name: 'Cunene Province, Angola' },
    { id: 'Zabul, Afghanistan', name: 'Zabul, Afghanistan' },
    { id: 'Eastern Region, Ghana', name: 'Eastern Region, Ghana' },
    { id: 'Bavaria, Germany', name: 'Bavaria, Germany' },
    { id: 'Lac Region, Chad', name: 'Lac Region, Chad' },
    {
        id: 'Sisacko-Moslavacka Zupanija, Croatia',
        name: 'Sisacko-Moslavacka Zupanija, Croatia'
    },
    { id: 'Dobrepolje, Slovenia', name: 'Dobrepolje, Slovenia' },
    { id: 'Var, France', name: 'Var, France' },
    { id: 'Ngouni, Gabon', name: 'Ngouni, Gabon' },
    { id: 'Is-Siggiewi, Malta', name: 'Is-Siggiewi, Malta' },
    { id: 'Fatick, Senegal', name: 'Fatick, Senegal' },
    {
        id: 'Dasoguz Welayaty, Turkmenistan',
        name: 'Dasoguz Welayaty, Turkmenistan'
    },
    {
        id: 'Departamento de Misiones, Paraguay',
        name: 'Departamento de Misiones, Paraguay'
    },
    {
        id: 'Samarqand Viloyati, Uzbekistan',
        name: 'Samarqand Viloyati, Uzbekistan'
    },
    { id: 'Oise, France', name: 'Oise, France' },
    { id: 'Zinder, Niger', name: 'Zinder, Niger' },
    { id: "Sha'biyat Misratah, Libya", name: "Sha'biyat Misratah, Libya" },
    { id: 'Co Longford, Ireland', name: 'Co Longford, Ireland' },
    { id: 'Boe, Nauru', name: 'Boe, Nauru' },
    { id: 'Nayarit, Mexico', name: 'Nayarit, Mexico' },
    { id: 'Michoacán, Mexico', name: 'Michoacán, Mexico' },
    { id: 'Malampa Province, Vanuatu', name: 'Malampa Province, Vanuatu' },
    {
        id: 'Province of Davao del Sur, Philippines',
        name: 'Province of Davao del Sur, Philippines'
    },
    {
        id: 'Provincia di Catanzaro, Italy',
        name: 'Provincia di Catanzaro, Italy'
    },
    { id: 'Oplotnica, Slovenia', name: 'Oplotnica, Slovenia' },
    { id: 'Saint Mary, Jamaica', name: 'Saint Mary, Jamaica' },
    { id: 'Tahoua, Niger', name: 'Tahoua, Niger' },
    {
        id: 'Gouvernorat de Beja, Tunisia',
        name: 'Gouvernorat de Beja, Tunisia'
    },
    { id: 'Mārupe, Latvia', name: 'Mārupe, Latvia' },
    { id: 'Kilimanjaro, Tanzania', name: 'Kilimanjaro, Tanzania' },
    {
        id: 'Newcastle upon Tyne, United Kingdom',
        name: 'Newcastle upon Tyne, United Kingdom'
    },
    { id: 'Hamilton, Bermuda', name: 'Hamilton, Bermuda' },
    {
        id: 'Ajloun, Hashemite Kingdom of Jordan',
        name: 'Ajloun, Hashemite Kingdom of Jordan'
    },
    { id: 'Cankova, Slovenia', name: 'Cankova, Slovenia' },
    { id: 'Heilongjiang, China', name: 'Heilongjiang, China' },
    {
        id: "Volyns'ka Oblast', Ukraine",
        name: "Volyns'ka Oblast', Ukraine"
    },
    {
        id: 'Provincia de Valverde, Dominican Republic',
        name: 'Provincia de Valverde, Dominican Republic'
    },
    { id: 'Syunik, Armenia', name: 'Syunik, Armenia' },
    { id: 'Tyrol, Austria', name: 'Tyrol, Austria' },
    { id: 'Chiayi County, Taiwan', name: 'Chiayi County, Taiwan' },
    {
        id: 'Obcina Sveti Jurij ob Scavnici, Slovenia',
        name: 'Obcina Sveti Jurij ob Scavnici, Slovenia'
    },
    { id: 'Hordaland Fylke, Norway', name: 'Hordaland Fylke, Norway' },
    {
        id: 'Baladiyat ash Shamal, Qatar',
        name: 'Baladiyat ash Shamal, Qatar'
    },
    { id: 'Nomos Voiotias, Greece', name: 'Nomos Voiotias, Greece' },
    {
        id: 'Cargados Carajos, Mauritius',
        name: 'Cargados Carajos, Mauritius'
    },
    {
        id: 'Changwat Mae Hong Son, Thailand',
        name: 'Changwat Mae Hong Son, Thailand'
    },
    { id: 'Puconci, Slovenia', name: 'Puconci, Slovenia' },
    {
        id: 'Departamento de Concepcion, Paraguay',
        name: 'Departamento de Concepcion, Paraguay'
    },
    { id: 'Maradi, Niger', name: 'Maradi, Niger' },
    {
        id: 'Mid and East Antrim, United Kingdom',
        name: 'Mid and East Antrim, United Kingdom'
    },
    { id: 'Tivat, Montenegro', name: 'Tivat, Montenegro' },
    {
        id: 'Departamento del Peten, Guatemala',
        name: 'Departamento del Peten, Guatemala'
    },
    {
        id: 'Wolverhampton, United Kingdom',
        name: 'Wolverhampton, United Kingdom'
    },
    { id: 'Ashgabat, Turkmenistan', name: 'Ashgabat, Turkmenistan' },
    {
        id: 'Anenii Noi, Republic of Moldova',
        name: 'Anenii Noi, Republic of Moldova'
    },
    { id: 'Muhafazat Ninawa, Iraq', name: 'Muhafazat Ninawa, Iraq' },
    { id: 'Kozje, Slovenia', name: 'Kozje, Slovenia' },
    { id: 'Samdrup Jongkhar, Bhutan', name: 'Samdrup Jongkhar, Bhutan' },
    { id: 'Aude, France', name: 'Aude, France' },
    {
        id: 'Ayeyawady Region, Myanmar [Burma]',
        name: 'Ayeyawady Region, Myanmar [Burma]'
    },
    { id: 'Doncaster, United Kingdom', name: 'Doncaster, United Kingdom' },
    {
        id: 'Nottinghamshire, United Kingdom',
        name: 'Nottinghamshire, United Kingdom'
    },
    {
        id: 'West Berkshire, United Kingdom',
        name: 'West Berkshire, United Kingdom'
    },
    {
        id: 'Krasnoyarskiy Kray, Russia',
        name: 'Krasnoyarskiy Kray, Russia'
    },
    { id: 'Khenifra, Morocco', name: 'Khenifra, Morocco' },
    { id: 'Babīte, Latvia', name: 'Babīte, Latvia' },
    {
        id: 'Cuanza Norte Province, Angola',
        name: 'Cuanza Norte Province, Angola'
    },
    {
        id: 'Province du Boulkiemde, Burkina Faso',
        name: 'Province du Boulkiemde, Burkina Faso'
    },
    { id: 'Lewisham, United Kingdom', name: 'Lewisham, United Kingdom' },
    {
        id: 'Borsod-Abaúj-Zemplén, Hungary',
        name: 'Borsod-Abaúj-Zemplén, Hungary'
    },
    { id: 'Province of Siena, Italy', name: 'Province of Siena, Italy' },
    { id: 'Newham, United Kingdom', name: 'Newham, United Kingdom' },
    { id: 'Aguascalientes, Mexico', name: 'Aguascalientes, Mexico' },
    { id: 'Central Jutland, Denmark', name: 'Central Jutland, Denmark' },
    {
        id: 'Province of North West, South Africa',
        name: 'Province of North West, South Africa'
    },
    { id: 'Tboung Khmum, Cambodia', name: 'Tboung Khmum, Cambodia' },
    {
        id: 'South Holland, Netherlands',
        name: 'South Holland, Netherlands'
    },
    {
        id: 'Gulf Province, Papua New Guinea',
        name: 'Gulf Province, Papua New Guinea'
    },
    { id: 'Sankuru, Congo', name: 'Sankuru, Congo' },
    { id: 'Guanajuato, Mexico', name: 'Guanajuato, Mexico' },
    {
        id: "Astrakhanskaya Oblast', Russia",
        name: "Astrakhanskaya Oblast', Russia"
    },
    {
        id: "Sha'biyat al Jafarah, Libya",
        name: "Sha'biyat al Jafarah, Libya"
    },
    {
        id: 'Ventspils Municipality, Latvia',
        name: 'Ventspils Municipality, Latvia'
    },
    { id: 'Tianjin, China', name: 'Tianjin, China' },
    { id: 'Loire, France', name: 'Loire, France' },
    { id: 'Banten, Indonesia', name: 'Banten, Indonesia' },
    { id: 'Jenin, Palestine', name: 'Jenin, Palestine' },
    {
        id: 'Departamento de Matagalpa, Nicaragua',
        name: 'Departamento de Matagalpa, Nicaragua'
    },
    
    {
        id: 'Canton 10, Bosnia and Herzegovina',
        name: 'Canton 10, Bosnia and Herzegovina'
    },
    { id: 'West Papua, Indonesia', name: 'West Papua, Indonesia' },
    { id: 'Changwat Trat, Thailand', name: 'Changwat Trat, Thailand' },
    {
        id: 'Taounate Province, Morocco',
        name: 'Taounate Province, Morocco'
    },
    { id: 'Eure, France', name: 'Eure, France' },
    { id: 'Leiria, Portugal', name: 'Leiria, Portugal' },
    { id: 'Faiyum, Egypt', name: 'Faiyum, Egypt' },
    {
        id: 'Obcina Sentrupert, Slovenia',
        name: 'Obcina Sentrupert, Slovenia'
    },
    {
        id: 'Riviere du Rempart District, Mauritius',
        name: 'Riviere du Rempart District, Mauritius'
    },
    {
        id: 'Šilalė District Municipality, Republic of Lithuania',
        name: 'Šilalė District Municipality, Republic of Lithuania'
    },
    { id: 'Negotino, Macedonia', name: 'Negotino, Macedonia' },
    { id: 'La Libertad, Peru', name: 'La Libertad, Peru' },
    { id: 'Hsinchu, Taiwan', name: 'Hsinchu, Taiwan' },
    { id: 'Bogra, Bangladesh', name: 'Bogra, Bangladesh' },
    { id: 'Sannat, Malta', name: 'Sannat, Malta' },
    { id: 'Tachov District, Czechia', name: 'Tachov District, Czechia' },
    {
        id: 'Tinh Thua Thien-Hue, Vietnam',
        name: 'Tinh Thua Thien-Hue, Vietnam'
    },
    {
        id: 'Departamento de Quetzaltenango, Guatemala',
        name: 'Departamento de Quetzaltenango, Guatemala'
    },
    {
        id: 'Okres Karlovy Vary, Czechia',
        name: 'Okres Karlovy Vary, Czechia'
    },
    { id: 'Imathia, Greece', name: 'Imathia, Greece' },
    {
        id: 'Rakhine State, Myanmar [Burma]',
        name: 'Rakhine State, Myanmar [Burma]'
    },
    {
        id: 'Sogn og Fjordane Fylke, Norway',
        name: 'Sogn og Fjordane Fylke, Norway'
    },
    { id: 'Iringa, Tanzania', name: 'Iringa, Tanzania' },
    {
        id: 'Departamento de Solola, Guatemala',
        name: 'Departamento de Solola, Guatemala'
    },
    { id: 'Meru, Kenya', name: 'Meru, Kenya' },
    {
        id: 'Tinh Ba Ria-Vung Tau, Vietnam',
        name: 'Tinh Ba Ria-Vung Tau, Vietnam'
    },
    {
        id: 'Eastern Cape, South Africa',
        name: 'Eastern Cape, South Africa'
    },
    { id: 'Junin, Peru', name: 'Junin, Peru' },
    {
        id: 'Udmurtskaya Respublika, Russia',
        name: 'Udmurtskaya Respublika, Russia'
    },
    {
        id: 'Lékoumou, Republic of the Congo',
        name: 'Lékoumou, Republic of the Congo'
    },
    { id: 'Madrid, Spain', name: 'Madrid, Spain' },
    { id: 'Neuquen, Argentina', name: 'Neuquen, Argentina' },
    {
        id: 'Provincia de San Cristobal, Dominican Republic',
        name: 'Provincia de San Cristobal, Dominican Republic'
    },
    { id: 'Estado de Mexico, Mexico', name: 'Estado de Mexico, Mexico' },
    { id: 'Karaman, Turkey', name: 'Karaman, Turkey' },
    { id: 'Pomoravlje, Serbia', name: 'Pomoravlje, Serbia' },
    { id: 'Mangaia, Cook Islands', name: 'Mangaia, Cook Islands' },
    {
        id: 'Changwat Nonthaburi, Thailand',
        name: 'Changwat Nonthaburi, Thailand'
    },
    { id: 'Mardin, Turkey', name: 'Mardin, Turkey' },
    { id: 'Butha-Buthe, Lesotho', name: 'Butha-Buthe, Lesotho' },
    {
        id: 'Domažlice District, Czechia',
        name: 'Domažlice District, Czechia'
    },
    { id: 'Chiesanuova, San Marino', name: 'Chiesanuova, San Marino' },
    { id: 'Bingöl, Turkey', name: 'Bingöl, Turkey' },
    { id: 'Provincia di Como, Italy', name: 'Provincia di Como, Italy' },
    { id: 'Ayacucho, Peru', name: 'Ayacucho, Peru' },
    {
        id: 'Borough of Arima, Trinidad and Tobago',
        name: 'Borough of Arima, Trinidad and Tobago'
    },
    { id: 'Lori, Armenia', name: 'Lori, Armenia' },
    { id: 'Most District, Czechia', name: 'Most District, Czechia' },
    { id: 'Tinh Phu Tho, Vietnam', name: 'Tinh Phu Tho, Vietnam' },
    { id: 'Norrbotten, Sweden', name: 'Norrbotten, Sweden' },
    { id: 'Soccsksargen, Philippines', name: 'Soccsksargen, Philippines' },
    { id: 'Corozal District, Belize', name: 'Corozal District, Belize' },
    { id: 'Luton, United Kingdom', name: 'Luton, United Kingdom' },
    {
        id: 'Point Fortin, Trinidad and Tobago',
        name: 'Point Fortin, Trinidad and Tobago'
    },
    {
        id: 'South Tyneside, United Kingdom',
        name: 'South Tyneside, United Kingdom'
    },
    { id: 'Tinh Binh Thuan, Vietnam', name: 'Tinh Binh Thuan, Vietnam' },
    { id: 'Caraga, Philippines', name: 'Caraga, Philippines' },
    {
        id: "Ternopil's'ka Oblast', Ukraine",
        name: "Ternopil's'ka Oblast', Ukraine"
    },
    { id: 'Arequipa, Peru', name: 'Arequipa, Peru' },
    { id: 'Azad Kashmir, Pakistan', name: 'Azad Kashmir, Pakistan' },
    { id: 'Maritime, Togo', name: 'Maritime, Togo' },
    {
        id: 'Departamento del Putumayo, Colombia',
        name: 'Departamento del Putumayo, Colombia'
    },
    {
        id: 'Gouvernorat de Bizerte, Tunisia',
        name: 'Gouvernorat de Bizerte, Tunisia'
    },
    { id: 'Toplica, Serbia', name: 'Toplica, Serbia' },
    { id: 'Komen, Slovenia', name: 'Komen, Slovenia' },
    {
        id: 'East Dunbartonshire, United Kingdom',
        name: 'East Dunbartonshire, United Kingdom'
    },
    { id: 'Angus, United Kingdom', name: 'Angus, United Kingdom' },
    {
        id: 'Province of Bataan, Philippines',
        name: 'Province of Bataan, Philippines'
    },
    { id: 'Dhaka, Bangladesh', name: 'Dhaka, Bangladesh' },
    {
        id: 'South Ayrshire, United Kingdom',
        name: 'South Ayrshire, United Kingdom'
    },
    { id: 'Taipei City, Taiwan', name: 'Taipei City, Taiwan' },
    {
        id: 'Šalčininkai District Municipality, Republic of Lithuania',
        name: 'Šalčininkai District Municipality, Republic of Lithuania'
    },
    { id: "Va'a-o-Fonoti, Samoa", name: "Va'a-o-Fonoti, Samoa" },
    { id: 'Tinh Soc Trang, Vietnam', name: 'Tinh Soc Trang, Vietnam' },
    { id: 'Preddvor, Slovenia', name: 'Preddvor, Slovenia' },
    { id: 'Sale, Morocco', name: 'Sale, Morocco' },
    { id: 'Cesky Keumlov, Czechia', name: 'Cesky Keumlov, Czechia' },
    {
        id: 'Obcina Sentjernej, Slovenia',
        name: 'Obcina Sentjernej, Slovenia'
    },
    { id: 'Paris, France', name: 'Paris, France' },
    {
        id: 'Departamento del Huila, Colombia',
        name: 'Departamento del Huila, Colombia'
    },
    { id: 'Bauskas Novads, Latvia', name: 'Bauskas Novads, Latvia' },
    {
        id: 'Province of Cebu, Philippines',
        name: 'Province of Cebu, Philippines'
    },
    { id: 'Hounslow, United Kingdom', name: 'Hounslow, United Kingdom' },
    { id: 'Judetul Salaj, Romania', name: 'Judetul Salaj, Romania' },
    {
        id: 'Centre-Val de Loire, France',
        name: 'Centre-Val de Loire, France'
    },
    { id: 'Alo, Wallis and Futuna', name: 'Alo, Wallis and Futuna' },
    { id: 'Obcina Solcava, Slovenia', name: 'Obcina Solcava, Slovenia' },
    { id: 'Kavango East, Namibia', name: 'Kavango East, Namibia' },
    { id: 'Glarus, Switzerland', name: 'Glarus, Switzerland' },
    {
        id: 'Obcina Race-Fram, Slovenia',
        name: 'Obcina Race-Fram, Slovenia'
    },
    { id: 'Obcina Store, Slovenia', name: 'Obcina Store, Slovenia' },
    { id: 'Beni Suweif, Egypt', name: 'Beni Suweif, Egypt' },
    { id: 'Tukuma Rajons, Latvia', name: 'Tukuma Rajons, Latvia' },
    { id: "Smith's Parish, Bermuda", name: "Smith's Parish, Bermuda" },
    { id: 'North Sinai, Egypt', name: 'North Sinai, Egypt' },
    { id: 'Praha 5, Czechia', name: 'Praha 5, Czechia' },
    {
        id: "Donets'ka Oblast', Ukraine",
        name: "Donets'ka Oblast', Ukraine"
    },
    { id: 'Praha 6, Czechia', name: 'Praha 6, Czechia' },
    { id: 'Praha 9, Czechia', name: 'Praha 9, Czechia' },
    { id: 'Praha 8, Czechia', name: 'Praha 8, Czechia' },
    {
        id: 'City of Newport, United Kingdom',
        name: 'City of Newport, United Kingdom'
    },
    { id: 'West Sulawesi, Indonesia', name: 'West Sulawesi, Indonesia' },
    { id: 'Sokolov District, Czechia', name: 'Sokolov District, Czechia' },
    {
        id: 'Hînceşti, Republic of Moldova',
        name: 'Hînceşti, Republic of Moldova'
    },
    {
        id: 'Royal Kensington and Chelsea, United Kingdom',
        name: 'Royal Kensington and Chelsea, United Kingdom'
    },
    { id: 'Valka, Latvia', name: 'Valka, Latvia' },
    { id: 'Al Asimah, Kuwait', name: 'Al Asimah, Kuwait' },
    { id: 'Okres Plzen-Jih, Czechia', name: 'Okres Plzen-Jih, Czechia' },
    { id: 'Kütahya, Turkey', name: 'Kütahya, Turkey' },
    { id: 'Badajoz, Spain', name: 'Badajoz, Spain' },
    {
        id: 'Provincia de Ciego de Avila, Cuba',
        name: 'Provincia de Ciego de Avila, Cuba'
    },
    { id: 'Province of Parma, Italy', name: 'Province of Parma, Italy' },
    {
        id: 'Changwat Mukdahan, Thailand',
        name: 'Changwat Mukdahan, Thailand'
    },
    {
        id: 'Baladiyat ad Dawhah, Qatar',
        name: 'Baladiyat ad Dawhah, Qatar'
    },
    { id: 'Munster, Ireland', name: 'Munster, Ireland' },
    { id: 'Micoud, Saint Lucia', name: 'Micoud, Saint Lucia' },
    { id: 'Sao Paulo, Brazil', name: 'Sao Paulo, Brazil' },
    {
        id: 'Ribeira Grande de Santiago, Cabo Verde',
        name: 'Ribeira Grande de Santiago, Cabo Verde'
    },
    { id: 'Lower Juba, Somalia', name: 'Lower Juba, Somalia' },
    { id: 'Rajshahi, Bangladesh', name: 'Rajshahi, Bangladesh' },
    { id: 'Kalmykiya, Russia', name: 'Kalmykiya, Russia' },
    { id: 'Engure, Latvia', name: 'Engure, Latvia' },
    {
        id: 'Appenzell Innerrhoden, Switzerland',
        name: 'Appenzell Innerrhoden, Switzerland'
    },
    {
        id: 'Šilutė District Municipality, Republic of Lithuania',
        name: 'Šilutė District Municipality, Republic of Lithuania'
    },
    { id: 'Togdheer, Somalia', name: 'Togdheer, Somalia' },
    {
        id: 'Aberdeenshire, United Kingdom',
        name: 'Aberdeenshire, United Kingdom'
    },
    { id: 'Ogun State, Nigeria', name: 'Ogun State, Nigeria' },
    { id: 'Limburg, Netherlands', name: 'Limburg, Netherlands' },
    {
        id: 'Changwat Phetchaburi, Thailand',
        name: 'Changwat Phetchaburi, Thailand'
    },
    {
        id: 'Opstina Studenicani, Macedonia',
        name: 'Opstina Studenicani, Macedonia'
    },
    { id: 'Sidi Bel Abbès, Algeria', name: 'Sidi Bel Abbès, Algeria' },
    {
        id: 'Provincia de Zamora-Chinchipe, Ecuador',
        name: 'Provincia de Zamora-Chinchipe, Ecuador'
    },
    {
        id: 'Provincia de Zambezia, Mozambique',
        name: 'Provincia de Zambezia, Mozambique'
    },
    { id: 'Tinh Bac Ninh, Vietnam', name: 'Tinh Bac Ninh, Vietnam' },
    { id: 'Ix-Xewkija, Malta', name: 'Ix-Xewkija, Malta' },
    { id: 'Penrhyn, Cook Islands', name: 'Penrhyn, Cook Islands' },
    {
        id: 'Northern Territory, Australia',
        name: 'Northern Territory, Australia'
    },
    { id: 'British Columbia, Canada', name: 'British Columbia, Canada' },
    { id: 'Oblast Pleven, Bulgaria', name: 'Oblast Pleven, Bulgaria' },
    {
        id: 'Nouakchott Sud, Mauritania',
        name: 'Nouakchott Sud, Mauritania'
    },
    { id: 'Taraba State, Nigeria', name: 'Taraba State, Nigeria' },
    { id: 'Ostan-e Tehran, Iran', name: 'Ostan-e Tehran, Iran' },
    { id: 'Mediouna, Morocco', name: 'Mediouna, Morocco' },
    { id: 'Mai Ndombe, Congo', name: 'Mai Ndombe, Congo' },
    { id: 'Lefkada, Greece', name: 'Lefkada, Greece' },
    { id: 'Obcina Krsko, Slovenia', name: 'Obcina Krsko, Slovenia' },
    { id: 'Rotuma, Fiji', name: 'Rotuma, Fiji' },
    {
        id: 'Casablanca-Settat, Morocco',
        name: 'Casablanca-Settat, Morocco'
    },
    {
        id: 'Sarajevo Canton, Bosnia and Herzegovina',
        name: 'Sarajevo Canton, Bosnia and Herzegovina'
    },
    { id: 'Al Farwaniyah, Kuwait', name: 'Al Farwaniyah, Kuwait' },
    { id: 'Kraslavas Rajons, Latvia', name: 'Kraslavas Rajons, Latvia' },
    { id: 'Yonne, France', name: 'Yonne, France' },
    { id: 'Bagmati Zone, Nepal', name: 'Bagmati Zone, Nepal' },
    {
        id: 'Province of Negros Occidental, Philippines',
        name: 'Province of Negros Occidental, Philippines'
    },
    {
        id: 'Provincia de Barahona, Dominican Republic',
        name: 'Provincia de Barahona, Dominican Republic'
    },
    { id: 'Tinh Hung Yen, Vietnam', name: 'Tinh Hung Yen, Vietnam' },
    {
        id: 'Samaná, Dominican Republic',
        name: 'Samaná, Dominican Republic'
    },
    { id: 'Huelva, Spain', name: 'Huelva, Spain' },
    { id: 'Bubanza Province, Burundi', name: 'Bubanza Province, Burundi' },
    {
        id: 'Tauragė County, Republic of Lithuania',
        name: 'Tauragė County, Republic of Lithuania'
    },
    { id: 'Hunan, China', name: 'Hunan, China' },
    {
        id: 'Baladiyat Umm Salal, Qatar',
        name: 'Baladiyat Umm Salal, Qatar'
    },
    { id: 'Opstina Plav, Montenegro', name: 'Opstina Plav, Montenegro' },
    { id: 'Haut Uele, Congo', name: 'Haut Uele, Congo' },
    { id: 'Phthiotis, Greece', name: 'Phthiotis, Greece' },
    { id: 'Tinh Tuyen Quang, Vietnam', name: 'Tinh Tuyen Quang, Vietnam' },
    { id: 'Mendoza, Argentina', name: 'Mendoza, Argentina' },
    { id: 'Qarku i Beratit, Albania', name: 'Qarku i Beratit, Albania' },
    { id: 'Milan, Italy', name: 'Milan, Italy' },
    { id: 'Penang, Malaysia', name: 'Penang, Malaysia' },
    {
        id: 'Provincia Maria Trinidad Sanchez, Dominican Republic',
        name: 'Provincia Maria Trinidad Sanchez, Dominican Republic'
    },
    { id: 'Sharqia, Egypt', name: 'Sharqia, Egypt' },
    { id: 'Central Banat, Serbia', name: 'Central Banat, Serbia' },
    { id: 'Osun State, Nigeria', name: 'Osun State, Nigeria' },
    { id: 'Vendée, France', name: 'Vendée, France' },
    {
        id: 'Provincia de El Seibo, Dominican Republic',
        name: 'Provincia de El Seibo, Dominican Republic'
    },
    {
        id: 'Northamptonshire, United Kingdom',
        name: 'Northamptonshire, United Kingdom'
    },
    {
        id: 'Floreşti, Republic of Moldova',
        name: 'Floreşti, Republic of Moldova'
    },
    { id: 'Redange, Luxembourg', name: 'Redange, Luxembourg' },
    { id: 'Miranda, Venezuela', name: 'Miranda, Venezuela' },
    { id: 'Tekirdağ, Turkey', name: 'Tekirdağ, Turkey' },
    { id: 'An Giang, Vietnam', name: 'An Giang, Vietnam' },
    { id: 'Vila Real, Portugal', name: 'Vila Real, Portugal' },
    { id: 'Sigave, Wallis and Futuna', name: 'Sigave, Wallis and Futuna' },
    {
        id: 'Departamento de Escuintla, Guatemala',
        name: 'Departamento de Escuintla, Guatemala'
    },
    { id: 'Tetouan, Morocco', name: 'Tetouan, Morocco' },
    {
        id: 'Centro Sur, Equatorial Guinea',
        name: 'Centro Sur, Equatorial Guinea'
    },
    { id: 'Mamou Prefecture, Guinea', name: 'Mamou Prefecture, Guinea' },
    {
        id: 'Bjelovarsko-Bilogorska Zupanija, Croatia',
        name: 'Bjelovarsko-Bilogorska Zupanija, Croatia'
    },
    {
        id: 'Hradec Králové District, Czechia',
        name: 'Hradec Králové District, Czechia'
    },
    {
        id: 'Province of Pampanga, Philippines',
        name: 'Province of Pampanga, Philippines'
    },
    { id: 'Pazardzhik, Bulgaria', name: 'Pazardzhik, Bulgaria' },
    { id: 'Klatovy District, Czechia', name: 'Klatovy District, Czechia' },
    { id: 'English River, Seychelles', name: 'English River, Seychelles' },
    { id: 'Thaba-Tseka, Lesotho', name: 'Thaba-Tseka, Lesotho' },
    {
        id: 'Nottingham, United Kingdom',
        name: 'Nottingham, United Kingdom'
    },
    {
        id: 'Changwat Pattani, Thailand',
        name: 'Changwat Pattani, Thailand'
    },
    {
        id: 'Haa Alifu Atholhu, Maldives',
        name: 'Haa Alifu Atholhu, Maldives'
    },
    { id: 'Dennery, Saint Lucia', name: 'Dennery, Saint Lucia' },
    {
        id: 'Provincia di Pistoia, Italy',
        name: 'Provincia di Pistoia, Italy'
    },
    { id: 'Dak Nong, Vietnam', name: 'Dak Nong, Vietnam' },
    { id: 'Hubei, China', name: 'Hubei, China' },
    { id: 'Jerada, Morocco', name: 'Jerada, Morocco' },
    { id: 'Banaadir, Somalia', name: 'Banaadir, Somalia' },
    {
        id: 'Okres Praha-Zapad, Czechia',
        name: 'Okres Praha-Zapad, Czechia'
    },
    {
        id: 'Bioko Sur, Equatorial Guinea',
        name: 'Bioko Sur, Equatorial Guinea'
    },
    { id: 'Ontario, Canada', name: 'Ontario, Canada' },
    {
        id: 'Province of Florence, Italy',
        name: 'Province of Florence, Italy'
    },
    { id: 'Rotherham, United Kingdom', name: 'Rotherham, United Kingdom' },
    {
        id: 'Bouenza, Republic of the Congo',
        name: 'Bouenza, Republic of the Congo'
    },
    { id: 'Xinjiang, China', name: 'Xinjiang, China' },
    {
        id: 'Faranah Prefecture, Guinea',
        name: 'Faranah Prefecture, Guinea'
    },
    {
        id: 'Inner Mongolia Autonomous Region, China',
        name: 'Inner Mongolia Autonomous Region, China'
    },
    { id: 'Ķekava, Latvia', name: 'Ķekava, Latvia' },
    { id: 'Ahal, Turkmenistan', name: 'Ahal, Turkmenistan' },
    {
        id: 'Departamento de Chimaltenango, Guatemala',
        name: 'Departamento de Chimaltenango, Guatemala'
    },
    {
        id: 'Ribeira Grande, Cabo Verde',
        name: 'Ribeira Grande, Cabo Verde'
    },
    { id: 'Havering, United Kingdom', name: 'Havering, United Kingdom' },
    { id: 'Vieux-Fort, Saint Lucia', name: 'Vieux-Fort, Saint Lucia' },
    {
        id: 'Birštonas Municipality, Republic of Lithuania',
        name: 'Birštonas Municipality, Republic of Lithuania'
    },
    {
        id: 'Šiauliai District Municipality, Republic of Lithuania',
        name: 'Šiauliai District Municipality, Republic of Lithuania'
    },
    { id: 'Mesto Brno, Czechia', name: 'Mesto Brno, Czechia' },
    { id: 'Hovsgol Aymag, Mongolia', name: 'Hovsgol Aymag, Mongolia' },
    {
        id: 'Provincia de San Jose, Costa Rica',
        name: 'Provincia de San Jose, Costa Rica'
    },
    {
        id: 'Spanish Wells District, Bahamas',
        name: 'Spanish Wells District, Bahamas'
    },
    {
        id: 'Mayaguana District, Bahamas',
        name: 'Mayaguana District, Bahamas'
    },
    { id: 'Tinh Lang Son, Vietnam', name: 'Tinh Lang Son, Vietnam' },
    {
        id: 'Jewish Autonomous Oblast, Russia',
        name: 'Jewish Autonomous Oblast, Russia'
    },
    {
        id: 'Sibensko-Kninska Zupanija, Croatia',
        name: 'Sibensko-Kninska Zupanija, Croatia'
    },
    { id: 'Aragon, Spain', name: 'Aragon, Spain' },
    { id: 'Clarendon, Jamaica', name: 'Clarendon, Jamaica' },
    { id: 'Kagera, Tanzania', name: 'Kagera, Tanzania' },
    {
        id: 'North-Western Province, Zambia',
        name: 'North-Western Province, Zambia'
    },
    { id: 'Raplamaa, Estonia', name: 'Raplamaa, Estonia' },
    { id: 'Kunene, Namibia', name: 'Kunene, Namibia' },
    { id: 'Tsirang Dzongkhag, Bhutan', name: 'Tsirang Dzongkhag, Bhutan' },
    { id: 'Hyōgo, Japan', name: 'Hyōgo, Japan' },
    { id: 'Hau Giang, Vietnam', name: 'Hau Giang, Vietnam' },
    { id: 'Maldonado, Uruguay', name: 'Maldonado, Uruguay' },
    {
        id: 'Provincia de Sucumbios, Ecuador',
        name: 'Provincia de Sucumbios, Ecuador'
    },
    { id: 'L-Imsida, Malta', name: 'L-Imsida, Malta' },
    {
        id: 'subdivision_2_name, country_name',
        name: 'subdivision_2_name, country_name'
    },
    {
        id: 'Province of Sondrio, Italy',
        name: 'Province of Sondrio, Italy'
    },
    { id: 'Ar Riyāḑ, Saudi Arabia', name: 'Ar Riyāḑ, Saudi Arabia' },
    { id: 'Huambo, Angola', name: 'Huambo, Angola' },
    { id: 'Hakkâri, Turkey', name: 'Hakkâri, Turkey' },
    { id: 'Lampung, Indonesia', name: 'Lampung, Indonesia' },
    { id: 'Chihuahua, Mexico', name: 'Chihuahua, Mexico' },
    { id: 'Obcina Sostanj, Slovenia', name: 'Obcina Sostanj, Slovenia' },
    {
        id: 'Chaguanas, Trinidad and Tobago',
        name: 'Chaguanas, Trinidad and Tobago'
    },
    {
        id: 'Tobago, Trinidad and Tobago',
        name: 'Tobago, Trinidad and Tobago'
    },
    {
        id: 'Central Eleuthera District, Bahamas',
        name: 'Central Eleuthera District, Bahamas'
    },
    { id: 'Kyiv, Ukraine', name: 'Kyiv, Ukraine' },
    {
        id: 'Commune de Saint-Pierre, Saint Pierre and Miquelon',
        name: 'Commune de Saint-Pierre, Saint Pierre and Miquelon'
    },
    { id: 'Changhua, Taiwan', name: 'Changhua, Taiwan' },
    {
        id: 'Provincia di Frosinone, Italy',
        name: 'Provincia di Frosinone, Italy'
    },
    {
        id: 'Provincia de Los Rios, Ecuador',
        name: 'Provincia de Los Rios, Ecuador'
    },
    { id: 'Pemagatshel, Bhutan', name: 'Pemagatshel, Bhutan' },
    { id: 'An Najaf, Iraq', name: 'An Najaf, Iraq' },
    { id: 'Ennedi-Ouest, Chad', name: 'Ennedi-Ouest, Chad' },
    { id: 'Gombe State, Nigeria', name: 'Gombe State, Nigeria' },
    {
        id: 'Province of Forlì-Cesena, Italy',
        name: 'Province of Forlì-Cesena, Italy'
    },
    {
        id: 'Province of Trapani, Italy',
        name: 'Province of Trapani, Italy'
    },
    {
        id: "Poltavs'ka Oblast', Ukraine",
        name: "Poltavs'ka Oblast', Ukraine"
    },
    {
        id: 'North Somerset, United Kingdom',
        name: 'North Somerset, United Kingdom'
    },
    { id: 'Bragança, Portugal', name: 'Bragança, Portugal' },
    { id: 'Veles, Macedonia', name: 'Veles, Macedonia' },
    { id: 'Galicia, Spain', name: 'Galicia, Spain' },
    { id: 'Coquimbo, Chile', name: 'Coquimbo, Chile' },
    { id: 'Obcina Zavrc, Slovenia', name: 'Obcina Zavrc, Slovenia' },
    {
        id: 'Acklins Island District, Bahamas',
        name: 'Acklins Island District, Bahamas'
    },
    { id: 'Oyo State, Nigeria', name: 'Oyo State, Nigeria' },
    { id: 'Planken, Liechtenstein', name: 'Planken, Liechtenstein' },
    {
        id: 'Ceredigion, United Kingdom',
        name: 'Ceredigion, United Kingdom'
    },
    { id: 'Saint Elizabeth, Jamaica', name: 'Saint Elizabeth, Jamaica' },
    {
        id: 'Saint Anne Sandy Point, St Kitts and Nevis',
        name: 'Saint Anne Sandy Point, St Kitts and Nevis'
    },
    {
        id: 'Christ Church Nichola Town, St Kitts and Nevis',
        name: 'Christ Church Nichola Town, St Kitts and Nevis'
    },
    { id: 'Tal-Pieta, Malta', name: 'Tal-Pieta, Malta' },
    { id: 'Hiiumaa, Estonia', name: 'Hiiumaa, Estonia' },
    { id: 'Rezeknes Novads, Latvia', name: 'Rezeknes Novads, Latvia' },
    { id: 'Surrey, United Kingdom', name: 'Surrey, United Kingdom' },
    { id: 'Karnataka, India', name: 'Karnataka, India' },
    { id: 'Nomos Rodopis, Greece', name: 'Nomos Rodopis, Greece' },
    { id: 'Anse-la-Raye, Saint Lucia', name: 'Anse-la-Raye, Saint Lucia' },
    { id: 'Leicester, United Kingdom', name: 'Leicester, United Kingdom' },
    { id: 'Obcina Luce, Slovenia', name: 'Obcina Luce, Slovenia' },
    { id: 'Meknes, Morocco', name: 'Meknes, Morocco' },
    { id: 'Akita, Japan', name: 'Akita, Japan' },
    { id: 'Volta Region, Ghana', name: 'Volta Region, Ghana' },
    { id: 'Quinara, Guinea-Bissau', name: 'Quinara, Guinea-Bissau' },
    { id: 'Judetul Neamt, Romania', name: 'Judetul Neamt, Romania' },
    { id: 'Catalonia, Spain', name: 'Catalonia, Spain' },
    {
        id: 'Preili Municipality, Latvia',
        name: 'Preili Municipality, Latvia'
    },
    { id: 'Haryana, India', name: 'Haryana, India' },
    {
        id: "Kirovskaya Oblast', Russia",
        name: "Kirovskaya Oblast', Russia"
    },
    { id: 'Gers, France', name: 'Gers, France' },
    {
        id: 'Departamento de Rivera, Uruguay',
        name: 'Departamento de Rivera, Uruguay'
    },
    { id: 'Zagorje ob Savi, Slovenia', name: 'Zagorje ob Savi, Slovenia' },
    { id: 'Kogi State, Nigeria', name: 'Kogi State, Nigeria' },
    { id: 'North Maluku, Indonesia', name: 'North Maluku, Indonesia' },
    { id: 'Namibe Province, Angola', name: 'Namibe Province, Angola' },
    {
        id: 'Province of Massa-Carrara, Italy',
        name: 'Province of Massa-Carrara, Italy'
    },
    { id: 'Benedikt, Slovenia', name: 'Benedikt, Slovenia' },
    {
        id: 'Gangwon-do, Republic of Korea',
        name: 'Gangwon-do, Republic of Korea'
    },
    { id: 'Bulawayo, Zimbabwe', name: 'Bulawayo, Zimbabwe' },
    { id: 'Qusar Rayon, Azerbaijan', name: 'Qusar Rayon, Azerbaijan' },
    { id: 'Oruro, Bolivia', name: 'Oruro, Bolivia' },
    { id: 'Troms Fylke, Norway', name: 'Troms Fylke, Norway' },
    { id: 'Shinyanga, Tanzania', name: 'Shinyanga, Tanzania' },
    { id: 'Devon, United Kingdom', name: 'Devon, United Kingdom' },
    { id: 'Aitutaki, Cook Islands', name: 'Aitutaki, Cook Islands' },
    {
        id: 'Estado Trujillo, Venezuela',
        name: 'Estado Trujillo, Venezuela'
    },
    { id: 'Campania, Italy', name: 'Campania, Italy' },
    { id: 'Chongqing, China', name: 'Chongqing, China' },
    { id: 'Nomos Evrytanias, Greece', name: 'Nomos Evrytanias, Greece' },
    { id: 'Dobje, Slovenia', name: 'Dobje, Slovenia' },
    {
        id: 'Okres Plzen-Mesto, Czechia',
        name: 'Okres Plzen-Mesto, Czechia'
    },
    { id: 'L-Imdina, Malta', name: 'L-Imdina, Malta' },
    {
        id: 'East Sepik Province, Papua New Guinea',
        name: 'East Sepik Province, Papua New Guinea'
    },
    {
        id: 'Manica Province, Mozambique',
        name: 'Manica Province, Mozambique'
    },
    { id: 'Maysan, Iraq', name: 'Maysan, Iraq' },
    {
        id: 'Haute-Kotto, Central African Republic',
        name: 'Haute-Kotto, Central African Republic'
    },
    { id: 'North Sulawesi, Indonesia', name: 'North Sulawesi, Indonesia' },
    { id: 'Baringo, Kenya', name: 'Baringo, Kenya' },
    {
        id: 'Changwat Nong Khai, Thailand',
        name: 'Changwat Nong Khai, Thailand'
    },
    { id: 'South Backa, Serbia', name: 'South Backa, Serbia' },
    { id: 'Gujarat, India', name: 'Gujarat, India' },
    {
        id: 'Bago Region, Myanmar [Burma]',
        name: 'Bago Region, Myanmar [Burma]'
    },
    { id: 'Māzandarān, Iran', name: 'Māzandarān, Iran' },
    {
        id: 'Maputo Province, Mozambique',
        name: 'Maputo Province, Mozambique'
    },
    {
        id: 'Obcina Ivancna Gorica, Slovenia',
        name: 'Obcina Ivancna Gorica, Slovenia'
    },
    { id: 'Lielvārde, Latvia', name: 'Lielvārde, Latvia' },
    { id: 'Podgorica, Montenegro', name: 'Podgorica, Montenegro' },
    {
        id: 'Departamento del Valle del Cauca, Colombia',
        name: 'Departamento del Valle del Cauca, Colombia'
    },
    {
        id: 'Provincia di Gorizia, Italy',
        name: 'Provincia di Gorizia, Italy'
    },
    { id: 'Baranya, Hungary', name: 'Baranya, Hungary' },
    { id: 'Selangor, Malaysia', name: 'Selangor, Malaysia' },
    { id: 'Praia, Cabo Verde', name: 'Praia, Cabo Verde' },
    { id: 'Red Sea, Sudan', name: 'Red Sea, Sudan' },
    {
        id: 'Changwat Chiang Rai, Thailand',
        name: 'Changwat Chiang Rai, Thailand'
    },
    { id: 'Lisbon, Portugal', name: 'Lisbon, Portugal' },
    {
        id: 'State of Yap, Federated States of Micronesia',
        name: 'State of Yap, Federated States of Micronesia'
    },
    { id: 'Antalya, Turkey', name: 'Antalya, Turkey' },
    {
        id: 'Colombo District, Sri Lanka',
        name: 'Colombo District, Sri Lanka'
    },
    {
        id: 'Changwat Songkhla, Thailand',
        name: 'Changwat Songkhla, Thailand'
    },
    { id: 'Judetul Botosani, Romania', name: 'Judetul Botosani, Romania' },
    { id: 'Basra, Iraq', name: 'Basra, Iraq' },
    { id: 'Riau, Indonesia', name: 'Riau, Indonesia' },
    { id: 'Tutong District, Brunei', name: 'Tutong District, Brunei' },
    {
        id: 'Distrikt Brokopondo, Suriname',
        name: 'Distrikt Brokopondo, Suriname'
    },
    {
        id: 'Departamento de Potosi, Bolivia',
        name: 'Departamento de Potosi, Bolivia'
    },
    { id: 'Al Qaḑārif, Sudan', name: 'Al Qaḑārif, Sudan' },
    {
        id: 'Provincie Zeeland, Netherlands',
        name: 'Provincie Zeeland, Netherlands'
    },
    { id: 'Upper River, Gambia', name: 'Upper River, Gambia' },
    { id: 'Obcina Kobarid, Slovenia', name: 'Obcina Kobarid, Slovenia' },
    {
        id: 'Departamento de Pando, Bolivia',
        name: 'Departamento de Pando, Bolivia'
    },
    {
        id: 'Province of Camarines Norte, Philippines',
        name: 'Province of Camarines Norte, Philippines'
    },
    {
        id: 'North East Community Development Region, Singapore',
        name: 'North East Community Development Region, Singapore'
    },
    { id: 'Kocēni, Latvia', name: 'Kocēni, Latvia' },
    {
        id: 'Provincia de Loja, Ecuador',
        name: 'Provincia de Loja, Ecuador'
    },
    { id: 'Mislinja, Slovenia', name: 'Mislinja, Slovenia' },
    {
        id: "Muhafazat al Jahra', Kuwait",
        name: "Muhafazat al Jahra', Kuwait"
    },
    { id: 'Gunma, Japan', name: 'Gunma, Japan' },
    { id: 'Aveiro, Portugal', name: 'Aveiro, Portugal' },
    { id: 'Obcina Tolmin, Slovenia', name: 'Obcina Tolmin, Slovenia' },
    { id: 'Donga, Benin', name: 'Donga, Benin' },
    { id: 'Moyen-Chari Region, Chad', name: 'Moyen-Chari Region, Chad' },
    { id: 'Capital Region, Denmark', name: 'Capital Region, Denmark' },
    {
        id: 'Provincia di Reggio Calabria, Italy',
        name: 'Provincia di Reggio Calabria, Italy'
    },
    { id: 'Batken, Kyrgyzstan', name: 'Batken, Kyrgyzstan' },
    {
        id: 'Provincia de Limon, Costa Rica',
        name: 'Provincia de Limon, Costa Rica'
    },
    { id: 'Zonguldak, Turkey', name: 'Zonguldak, Turkey' },
    {
        id: 'Changwat Chanthaburi, Thailand',
        name: 'Changwat Chanthaburi, Thailand'
    },
    { id: 'Aomori, Japan', name: 'Aomori, Japan' },
    { id: 'Debub Region, Eritrea', name: 'Debub Region, Eritrea' },
    { id: 'Chittagong, Bangladesh', name: 'Chittagong, Bangladesh' },
    {
        id: 'Southern Peninsula, Iceland',
        name: 'Southern Peninsula, Iceland'
    },
    { id: 'Wakefield, United Kingdom', name: 'Wakefield, United Kingdom' },
    { id: 'Central Bohemia, Czechia', name: 'Central Bohemia, Czechia' },
    {
        id: 'Shaviyani Atholhu, Maldives',
        name: 'Shaviyani Atholhu, Maldives'
    },
    {
        id: 'Departamento de San Marcos, Guatemala',
        name: 'Departamento de San Marcos, Guatemala'
    },
    { id: 'Kampong Chhnang, Cambodia', name: 'Kampong Chhnang, Cambodia' },
    { id: 'Jalisco, Mexico', name: 'Jalisco, Mexico' },
    { id: 'Bexley, United Kingdom', name: 'Bexley, United Kingdom' },
    {
        id: 'Cacheu Region, Guinea-Bissau',
        name: 'Cacheu Region, Guinea-Bissau'
    },
    {
        id: 'Newry Mourne and Down, United Kingdom',
        name: 'Newry Mourne and Down, United Kingdom'
    },
    { id: 'Encamp, Andorra', name: 'Encamp, Andorra' },
    {
        id: 'Tunapuna/Piarco, Trinidad and Tobago',
        name: 'Tunapuna/Piarco, Trinidad and Tobago'
    },
    { id: 'Bahia, Brazil', name: 'Bahia, Brazil' },
    { id: 'Ix-Xghajra, Malta', name: 'Ix-Xghajra, Malta' },
    { id: 'Imereti, Georgia', name: 'Imereti, Georgia' },
    { id: 'Luqa, Malta', name: 'Luqa, Malta' },
    { id: 'Fife, United Kingdom', name: 'Fife, United Kingdom' },
    {
        id: 'Provincia de Guanacaste, Costa Rica',
        name: 'Provincia de Guanacaste, Costa Rica'
    },
    { id: 'Borkou Region, Chad', name: 'Borkou Region, Chad' },
    {
        id: 'Obcina Braslovce, Slovenia',
        name: 'Obcina Braslovce, Slovenia'
    },
    {
        id: 'Mažeikiai District Municipality, Republic of Lithuania',
        name: 'Mažeikiai District Municipality, Republic of Lithuania'
    },
    {
        id: 'Baden-Württemberg Region, Germany',
        name: 'Baden-Württemberg Region, Germany'
    },
    {
        id: "Kaliningradskaya Oblast', Russia",
        name: "Kaliningradskaya Oblast', Russia"
    },
    {
        id: 'Province of Davao Oriental, Philippines',
        name: 'Province of Davao Oriental, Philippines'
    },
    {
        id: 'South Province, New Caledonia',
        name: 'South Province, New Caledonia'
    },
    { id: 'Hautes-Pyrénées, France', name: 'Hautes-Pyrénées, France' },
    { id: 'Lienchiang, Taiwan', name: 'Lienchiang, Taiwan' },
    { id: 'Svay Rieng, Cambodia', name: 'Svay Rieng, Cambodia' },
    { id: 'Jeseník District, Czechia', name: 'Jeseník District, Czechia' },
    { id: 'Rum Cay, Bahamas', name: 'Rum Cay, Bahamas' },
    { id: 'Adrar, Algeria', name: 'Adrar, Algeria' },
    { id: 'Břeclav District, Czechia', name: 'Břeclav District, Czechia' },
    { id: 'Tinh Hoa Binh, Vietnam', name: 'Tinh Hoa Binh, Vietnam' },
    { id: "Sums'ka Oblast', Ukraine", name: "Sums'ka Oblast', Ukraine" },
    { id: 'Delta Amacuro, Venezuela', name: 'Delta Amacuro, Venezuela' },
    { id: 'Obcina Bovec, Slovenia', name: 'Obcina Bovec, Slovenia' },
    { id: 'Poitou-Charentes, France', name: 'Poitou-Charentes, France' },
    { id: 'Sirdaryo, Uzbekistan', name: 'Sirdaryo, Uzbekistan' },
    { id: 'Province of Enna, Italy', name: 'Province of Enna, Italy' },
    { id: 'Yozgat, Turkey', name: 'Yozgat, Turkey' },
    {
        id: 'Telsiai, Republic of Lithuania',
        name: 'Telsiai, Republic of Lithuania'
    },
    {
        id: 'Changwat Nakhon Sawan, Thailand',
        name: 'Changwat Nakhon Sawan, Thailand'
    },
    {
        id: 'Departamento de Santander, Colombia',
        name: 'Departamento de Santander, Colombia'
    },
    {
        id: 'Al Fujayrah, United Arab Emirates',
        name: 'Al Fujayrah, United Arab Emirates'
    },
    { id: 'Rondonia, Brazil', name: 'Rondonia, Brazil' },
    {
        id: 'Changwat Lamphun, Thailand',
        name: 'Changwat Lamphun, Thailand'
    },
    { id: 'Bloke, Slovenia', name: 'Bloke, Slovenia' },
    { id: 'Kwai Tsing, Hong Kong', name: 'Kwai Tsing, Hong Kong' },
    { id: 'Rangpur, Bangladesh', name: 'Rangpur, Bangladesh' },
    { id: 'Tacna, Peru', name: 'Tacna, Peru' },
    {
        id: 'Shiselweni District, Swaziland',
        name: 'Shiselweni District, Swaziland'
    },
    { id: 'Vransko, Slovenia', name: 'Vransko, Slovenia' },
    {
        id: 'Dadra and Nagar Haveli, India',
        name: 'Dadra and Nagar Haveli, India'
    },
    { id: "M'Sila, Algeria", name: "M'Sila, Algeria" },
    { id: 'Andijan, Uzbekistan', name: 'Andijan, Uzbekistan' },
    { id: 'Blue Nile, Sudan', name: 'Blue Nile, Sudan' },
    { id: 'Nomos Dramas, Greece', name: 'Nomos Dramas, Greece' },
    {
        id: 'Makira-Ulawa Province, Solomon Islands',
        name: 'Makira-Ulawa Province, Solomon Islands'
    },
    {
        id: 'Kingston upon Hull, United Kingdom',
        name: 'Kingston upon Hull, United Kingdom'
    },
    {
        id: 'Province du Mouhoun, Burkina Faso',
        name: 'Province du Mouhoun, Burkina Faso'
    },
    { id: 'Novo Selo, Macedonia', name: 'Novo Selo, Macedonia' },
    { id: 'Central, Fiji', name: 'Central, Fiji' },
    { id: 'Bitola, Macedonia', name: 'Bitola, Macedonia' },
    { id: 'Makamba Province, Burundi', name: 'Makamba Province, Burundi' },
    { id: 'Ciudad Real, Spain', name: 'Ciudad Real, Spain' },
    { id: 'Negeri Sembilan, Malaysia', name: 'Negeri Sembilan, Malaysia' },
    {
        id: 'Staffordshire, United Kingdom',
        name: 'Staffordshire, United Kingdom'
    },
    { id: 'Wokingham, United Kingdom', name: 'Wokingham, United Kingdom' },
    {
        id: 'Tristan da Cunha, Saint Helena',
        name: 'Tristan da Cunha, Saint Helena'
    },
    { id: 'Sylhet, Bangladesh', name: 'Sylhet, Bangladesh' },
    { id: 'Lukovica, Slovenia', name: 'Lukovica, Slovenia' },
    { id: 'Tasman, New Zealand', name: 'Tasman, New Zealand' },
    {
        id: 'Central Singapore Community Development Council, Singapore',
        name: 'Central Singapore Community Development Council, Singapore'
    },
    {
        id: 'St. Helens, United Kingdom',
        name: 'St. Helens, United Kingdom'
    },
    { id: 'Segovia, Spain', name: 'Segovia, Spain' },
    { id: 'Tinh Thai Nguyen, Vietnam', name: 'Tinh Thai Nguyen, Vietnam' },
    {
        id: 'Thanh Pho GJa Nang, Vietnam',
        name: 'Thanh Pho GJa Nang, Vietnam'
    },
    { id: 'Khomas, Namibia', name: 'Khomas, Namibia' },
    {
        id: 'Marijampole Municipality, Republic of Lithuania',
        name: 'Marijampole Municipality, Republic of Lithuania'
    },
    {
        id: 'Yuen Long District, Hong Kong',
        name: 'Yuen Long District, Hong Kong'
    },
    { id: 'Sal, Cabo Verde', name: 'Sal, Cabo Verde' },
    { id: 'Kampot, Cambodia', name: 'Kampot, Cambodia' },
    { id: 'Provincia di Lucca, Italy', name: 'Provincia di Lucca, Italy' },
    {
        id: 'South East Community Development Council, Singapore',
        name: 'South East Community Development Council, Singapore'
    },
    { id: 'Strumica, Macedonia', name: 'Strumica, Macedonia' },
    { id: 'Ostan-e Kordestan, Iran', name: 'Ostan-e Kordestan, Iran' },
    { id: 'Kirkuk, Iraq', name: 'Kirkuk, Iraq' },
    {
        id: 'Distrikt Paramaribo, Suriname',
        name: 'Distrikt Paramaribo, Suriname'
    },
    { id: 'Neuchâtel, Switzerland', name: 'Neuchâtel, Switzerland' },
    { id: 'Mbarara District, Uganda', name: 'Mbarara District, Uganda' },
    {
        id: 'Telford and Wrekin, United Kingdom',
        name: 'Telford and Wrekin, United Kingdom'
    },
    { id: 'Davao, Philippines', name: 'Davao, Philippines' },
    {
        id: 'New South Wales, Australia',
        name: 'New South Wales, Australia'
    },
    { id: 'Hautes-Alpes, France', name: 'Hautes-Alpes, France' },
    {
        id: 'Princes Town, Trinidad and Tobago',
        name: 'Princes Town, Trinidad and Tobago'
    },
    { id: 'Anhui, China', name: 'Anhui, China' },
    { id: 'Obcina Zrece, Slovenia', name: 'Obcina Zrece, Slovenia' },
    { id: 'Chelyabinsk, Russia', name: 'Chelyabinsk, Russia' },
    { id: 'Övörhangay, Mongolia', name: 'Övörhangay, Mongolia' },
    {
        id: 'Klaipėda City Municipality, Republic of Lithuania',
        name: 'Klaipėda City Municipality, Republic of Lithuania'
    },
    {
        id: 'Saint Mary Cayon, St Kitts and Nevis',
        name: 'Saint Mary Cayon, St Kitts and Nevis'
    },
    { id: 'Yobe State, Nigeria', name: 'Yobe State, Nigeria' },
    { id: 'Kamtchatski Kray, Russia', name: 'Kamtchatski Kray, Russia' },
    { id: 'Nunavut, Canada', name: 'Nunavut, Canada' },
    { id: 'Aswan, Egypt', name: 'Aswan, Egypt' },
    { id: 'Asyut, Egypt', name: 'Asyut, Egypt' },
    { id: 'Hrastnik, Slovenia', name: 'Hrastnik, Slovenia' },
    {
        id: 'North Kazakhstan, Kazakhstan',
        name: 'North Kazakhstan, Kazakhstan'
    },
    { id: 'Obcina Zetale, Slovenia', name: 'Obcina Zetale, Slovenia' },
    { id: 'Armavir, Armenia', name: 'Armavir, Armenia' },
    { id: 'Dobrna, Slovenia', name: 'Dobrna, Slovenia' },
    {
        id: 'České Budějovice District, Czechia',
        name: 'České Budějovice District, Czechia'
    },
    {
        id: 'Parish of Saint Andrew, Saint Vincent and the Grenadines',
        name: 'Parish of Saint Andrew, Saint Vincent and the Grenadines'
    },
    {
        id: 'Departamento de Atlantida, Honduras',
        name: 'Departamento de Atlantida, Honduras'
    },
    {
        id: 'Devonshire Parish, Bermuda',
        name: 'Devonshire Parish, Bermuda'
    },
    { id: 'Southern, Hong Kong', name: 'Southern, Hong Kong' },
    { id: 'Baghdad, Iraq', name: 'Baghdad, Iraq' },
    {
        id: 'Provincie Utrecht, Netherlands',
        name: 'Provincie Utrecht, Netherlands'
    },
    {
        id: 'Departamento de Masaya, Nicaragua',
        name: 'Departamento de Masaya, Nicaragua'
    },
    {
        id: 'Torfaen County Borough, United Kingdom',
        name: 'Torfaen County Borough, United Kingdom'
    },
    { id: 'Changwat Roi Et, Thailand', name: 'Changwat Roi Et, Thailand' },
    { id: 'Saare, Estonia', name: 'Saare, Estonia' },
    { id: 'Ceara, Brazil', name: 'Ceara, Brazil' },
    {
        id: 'Oblast Targovishte, Bulgaria',
        name: 'Oblast Targovishte, Bulgaria'
    },
    { id: 'Salford, United Kingdom', name: 'Salford, United Kingdom' },
    {
        id: 'Castello di Montegiardino, San Marino',
        name: 'Castello di Montegiardino, San Marino'
    },
    { id: 'Algiers, Algeria', name: 'Algiers, Algeria' },
    { id: 'Kasai, Congo', name: 'Kasai, Congo' },
    { id: 'Tokyo, Japan', name: 'Tokyo, Japan' },
    { id: 'Lavalleja, Uruguay', name: 'Lavalleja, Uruguay' },
    { id: 'Central District, Israel', name: 'Central District, Israel' },
    { id: 'El-Hajeb, Morocco', name: 'El-Hajeb, Morocco' },
    {
        id: 'Teleneşti, Republic of Moldova',
        name: 'Teleneşti, Republic of Moldova'
    },
    { id: 'Vaitupu, Tuvalu', name: 'Vaitupu, Tuvalu' },
    {
        id: 'Departamento del Magdalena, Colombia',
        name: 'Departamento del Magdalena, Colombia'
    },
    { id: 'Maniema, Congo', name: 'Maniema, Congo' },
    {
        id: 'Şoldăneşti, Republic of Moldova',
        name: 'Şoldăneşti, Republic of Moldova'
    },
    { id: 'Oued-Ed-Dahab, Morocco', name: 'Oued-Ed-Dahab, Morocco' },
    {
        id: 'Saint Paul Charlestown, St Kitts and Nevis',
        name: 'Saint Paul Charlestown, St Kitts and Nevis'
    },
    { id: 'Bartın, Turkey', name: 'Bartın, Turkey' },
    { id: 'Saint Mark, Grenada', name: 'Saint Mark, Grenada' },
    { id: 'Pieria, Greece', name: 'Pieria, Greece' },
    {
        id: 'Transbaikal Territory, Russia',
        name: 'Transbaikal Territory, Russia'
    },
    { id: 'Kedah, Malaysia', name: 'Kedah, Malaysia' },
    {
        id: 'Departamento de Tolima, Colombia',
        name: 'Departamento de Tolima, Colombia'
    },
    { id: 'Warmia-Masuria, Poland', name: 'Warmia-Masuria, Poland' },
    {
        id: 'Provincia de Peravia, Dominican Republic',
        name: 'Provincia de Peravia, Dominican Republic'
    },
    { id: 'São Filipe, Cabo Verde', name: 'São Filipe, Cabo Verde' },
    {
        id: 'Bas-Sassandra, Ivory Coast',
        name: 'Bas-Sassandra, Ivory Coast'
    },
    { id: 'Edirne, Turkey', name: 'Edirne, Turkey' },
    {
        id: 'Klaipėda District Municipality, Republic of Lithuania',
        name: 'Klaipėda District Municipality, Republic of Lithuania'
    },
    { id: 'Jilin, China', name: 'Jilin, China' },
    {
        id: 'Departamento de San Jose, Uruguay',
        name: 'Departamento de San Jose, Uruguay'
    },
    { id: 'Misiones, Argentina', name: 'Misiones, Argentina' },
    { id: 'Tataouine, Tunisia', name: 'Tataouine, Tunisia' },
    {
        id: 'Armagh City Banbridge and Craigavon, United Kingdom',
        name: 'Armagh City Banbridge and Craigavon, United Kingdom'
    },
    {
        id: 'Rrethi i Permetit, Albania',
        name: 'Rrethi i Permetit, Albania'
    },
    { id: 'Nomos Prevezis, Greece', name: 'Nomos Prevezis, Greece' },
    { id: 'Shizuoka, Japan', name: 'Shizuoka, Japan' },
    {
        id: 'City of Port of Spain, Trinidad and Tobago',
        name: 'City of Port of Spain, Trinidad and Tobago'
    },
    { id: 'Satu Mare, Romania', name: 'Satu Mare, Romania' },
    {
        id: 'Kabardino-Balkarskaya Respublika, Russia',
        name: 'Kabardino-Balkarskaya Respublika, Russia'
    },
    { id: 'Värmland, Sweden', name: 'Värmland, Sweden' },
    { id: 'Capital, Venezuela', name: 'Capital, Venezuela' },
    { id: 'Diekirch, Luxembourg', name: 'Diekirch, Luxembourg' },
    { id: 'Al Hudaydah, Yemen', name: 'Al Hudaydah, Yemen' },
    {
        id: 'City of London, United Kingdom',
        name: 'City of London, United Kingdom'
    },
    {
        id: 'Nzerekore Prefecture, Guinea',
        name: 'Nzerekore Prefecture, Guinea'
    },
    { id: 'Obcina Trzic, Slovenia', name: 'Obcina Trzic, Slovenia' },
    { id: 'Berat District, Albania', name: 'Berat District, Albania' },
    { id: 'Sinop, Turkey', name: 'Sinop, Turkey' },
    { id: 'Aysen, Chile', name: 'Aysen, Chile' },
    { id: 'Sinnār, Sudan', name: 'Sinnār, Sudan' },
    { id: 'Ajaria, Georgia', name: 'Ajaria, Georgia' },
    { id: 'Prebold, Slovenia', name: 'Prebold, Slovenia' },
    { id: 'Gävleborg, Sweden', name: 'Gävleborg, Sweden' },
    {
        id: 'National Capital, Papua New Guinea',
        name: 'National Capital, Papua New Guinea'
    },
    { id: 'Niuas, Tonga', name: 'Niuas, Tonga' },
    { id: 'Al Marqab, Libya', name: 'Al Marqab, Libya' },
    {
        id: 'Essaouira Province, Morocco',
        name: 'Essaouira Province, Morocco'
    },
    {
        id: 'Sagaing Region, Myanmar [Burma]',
        name: 'Sagaing Region, Myanmar [Burma]'
    },
    { id: 'Zilina, Slovakia', name: 'Zilina, Slovakia' },
    { id: 'Chichaoua, Morocco', name: 'Chichaoua, Morocco' },
    { id: 'Dagana, Bhutan', name: 'Dagana, Bhutan' },
    { id: 'Budapest, Hungary', name: 'Budapest, Hungary' },
    { id: 'Nukulaelae, Tuvalu', name: 'Nukulaelae, Tuvalu' },
    {
        id: 'Richmond upon Thames, United Kingdom',
        name: 'Richmond upon Thames, United Kingdom'
    },
    { id: 'Jakarta, Indonesia', name: 'Jakarta, Indonesia' },
    { id: 'Csongrad megye, Hungary', name: 'Csongrad megye, Hungary' },
    {
        id: "Saratovskaya Oblast', Russia",
        name: "Saratovskaya Oblast', Russia"
    },
    {
        id: 'Alytus District Municipality, Republic of Lithuania',
        name: 'Alytus District Municipality, Republic of Lithuania'
    },
    {
        id: 'Uherské Hradiště District, Czechia',
        name: 'Uherské Hradiště District, Czechia'
    },
    {
        id: 'Guelmim-Oued Noun, Morocco',
        name: 'Guelmim-Oued Noun, Morocco'
    },
    { id: 'Bristol, United Kingdom', name: 'Bristol, United Kingdom' },
    { id: 'Qarku i Tiranes, Albania', name: 'Qarku i Tiranes, Albania' },
    { id: 'Suhbaatar Aymag, Mongolia', name: 'Suhbaatar Aymag, Mongolia' },
    { id: 'Palencia, Spain', name: 'Palencia, Spain' },
    { id: 'Chlef, Algeria', name: 'Chlef, Algeria' },
    { id: 'Sérres, Greece', name: 'Sérres, Greece' },
    { id: 'Grodnenskaya, Belarus', name: 'Grodnenskaya, Belarus' },
    { id: 'Lovech, Bulgaria', name: 'Lovech, Bulgaria' },
    { id: 'Falkirk, United Kingdom', name: 'Falkirk, United Kingdom' },
    { id: 'Upper Garonne, France', name: 'Upper Garonne, France' },
    { id: 'Durango, Mexico', name: 'Durango, Mexico' },
    { id: 'Rogatec, Slovenia', name: 'Rogatec, Slovenia' },
    { id: 'Odisha, India', name: 'Odisha, India' },
    {
        id: 'Loyalty Islands, New Caledonia',
        name: 'Loyalty Islands, New Caledonia'
    },
    { id: 'Bas-Rhin, France', name: 'Bas-Rhin, France' },
    { id: 'Rome, Italy', name: 'Rome, Italy' },
    {
        id: 'Bīnshangul Gumuz, Ethiopia',
        name: 'Bīnshangul Gumuz, Ethiopia'
    },
    { id: 'Provincia di Rieti, Italy', name: 'Provincia di Rieti, Italy' },
    {
        id: 'Departamento del Vaupes, Colombia',
        name: 'Departamento del Vaupes, Colombia'
    },
    { id: 'Line Islands, Kiribati', name: 'Line Islands, Kiribati' },
    { id: 'Portland, Jamaica', name: 'Portland, Jamaica' },
    { id: 'Southern Savonia, Finland', name: 'Southern Savonia, Finland' },
    { id: 'Piura, Peru', name: 'Piura, Peru' },
    {
        id: 'Respublika Mordoviya, Russia',
        name: 'Respublika Mordoviya, Russia'
    },
    {
        id: 'Malaita Province, Solomon Islands',
        name: 'Malaita Province, Solomon Islands'
    },
    { id: 'Kerman, Iran', name: 'Kerman, Iran' },
    {
        id: 'Warwickshire, United Kingdom',
        name: 'Warwickshire, United Kingdom'
    },
    {
        id: "Leningradskaya Oblast', Russia",
        name: "Leningradskaya Oblast', Russia"
    },
    {
        id: 'Crooked Island and Long Cay District, Bahamas',
        name: 'Crooked Island and Long Cay District, Bahamas'
    },
    { id: 'Trebnje, Slovenia', name: 'Trebnje, Slovenia' },
    { id: 'Idrija, Slovenia', name: 'Idrija, Slovenia' },
    { id: 'Atiu, Cook Islands', name: 'Atiu, Cook Islands' },
    {
        id: 'Province of Negros Oriental, Philippines',
        name: 'Province of Negros Oriental, Philippines'
    },
    { id: 'Calvados, France', name: 'Calvados, France' },
    {
        id: 'North-West Region, Cameroon',
        name: 'North-West Region, Cameroon'
    },
    { id: 'Ashanti Region, Ghana', name: 'Ashanti Region, Ghana' },
    { id: 'Nord Kivu, Congo', name: 'Nord Kivu, Congo' },
    { id: 'Gipuzkoa, Spain', name: 'Gipuzkoa, Spain' },
    { id: 'Nicosia, Cyprus', name: 'Nicosia, Cyprus' },
    {
        id: 'Eastern Highlands Province, Papua New Guinea',
        name: 'Eastern Highlands Province, Papua New Guinea'
    },
    { id: 'Provincia di Pavia, Italy', name: 'Provincia di Pavia, Italy' },
    { id: 'Provincia di Cuneo, Italy', name: 'Provincia di Cuneo, Italy' },
    {
        id: 'Dol pri Ljubljani, Slovenia',
        name: 'Dol pri Ljubljani, Slovenia'
    },
    {
        id: 'Provincia di Ancona, Italy',
        name: 'Provincia di Ancona, Italy'
    },
    {
        id: 'Carmarthenshire, United Kingdom',
        name: 'Carmarthenshire, United Kingdom'
    },
    { id: 'Çorum, Turkey', name: 'Çorum, Turkey' },
    {
        id: 'Pyrénées-Orientales, France',
        name: 'Pyrénées-Orientales, France'
    },
    { id: 'Gao, Mali', name: 'Gao, Mali' },
    { id: 'Halland, Sweden', name: 'Halland, Sweden' },
    { id: 'West Bačka, Serbia', name: 'West Bačka, Serbia' },
    { id: 'Suchitepeque, Guatemala', name: 'Suchitepeque, Guatemala' },
    {
        id: 'Obcina Ravne na Koroskem, Slovenia',
        name: 'Obcina Ravne na Koroskem, Slovenia'
    },
    { id: 'Andalusia, Spain', name: 'Andalusia, Spain' },
    { id: 'Obcina Apace, Slovenia', name: 'Obcina Apace, Slovenia' },
    { id: 'Narok District, Kenya', name: 'Narok District, Kenya' },
    {
        id: 'Stann Creek District, Belize',
        name: 'Stann Creek District, Belize'
    },
    { id: 'Province of Pisa, Italy', name: 'Province of Pisa, Italy' },
    {
        id: 'Orhei, Republic of Moldova',
        name: 'Orhei, Republic of Moldova'
    },
    { id: 'Brussels Capital, Belgium', name: 'Brussels Capital, Belgium' },
    { id: 'Il-Fontana, Malta', name: 'Il-Fontana, Malta' },
    { id: 'Eure-et-Loir, France', name: 'Eure-et-Loir, France' },
    { id: 'Manouba, Tunisia', name: 'Manouba, Tunisia' },
    {
        id: 'Donduşeni, Republic of Moldova',
        name: 'Donduşeni, Republic of Moldova'
    },
    { id: 'White Nile, Sudan', name: 'White Nile, Sudan' },
    { id: 'Tigray, Ethiopia', name: 'Tigray, Ethiopia' },
    { id: 'Alexandria, Egypt', name: 'Alexandria, Egypt' },
    { id: 'Kidal, Mali', name: 'Kidal, Mali' },
    { id: 'Varna, Bulgaria', name: 'Varna, Bulgaria' },
    { id: 'Thanh Pho Ha Noi, Vietnam', name: 'Thanh Pho Ha Noi, Vietnam' },
    { id: 'Avila, Spain', name: 'Avila, Spain' },
    {
        id: 'Departamento de Morazan, El Salvador',
        name: 'Departamento de Morazan, El Salvador'
    },
    { id: 'Barnsley, United Kingdom', name: 'Barnsley, United Kingdom' },
    {
        id: 'Departamento de Paysandu, Uruguay',
        name: 'Departamento de Paysandu, Uruguay'
    },
    {
        id: 'Bayan-OElgiy Aymag, Mongolia',
        name: 'Bayan-OElgiy Aymag, Mongolia'
    },
    {
        id: 'Region de Arica y Parinacota, Chile',
        name: 'Region de Arica y Parinacota, Chile'
    },
    {
        id: 'Schleswig-Holstein, Germany',
        name: 'Schleswig-Holstein, Germany'
    },
    { id: 'Salah ad Din, Iraq', name: 'Salah ad Din, Iraq' },
    { id: 'Northland, New Zealand', name: 'Northland, New Zealand' },
    { id: 'Pesnica, Slovenia', name: 'Pesnica, Slovenia' },
    { id: 'Oromiya, Ethiopia', name: 'Oromiya, Ethiopia' },
    { id: 'Liaoning, China', name: 'Liaoning, China' },
    {
        id: 'Eastern Province, Sierra Leone',
        name: 'Eastern Province, Sierra Leone'
    },
    { id: 'Maribor, Slovenia', name: 'Maribor, Slovenia' },
    { id: 'Makole, Slovenia', name: 'Makole, Slovenia' },
    {
        id: 'Province of Agusan del Norte, Philippines',
        name: 'Province of Agusan del Norte, Philippines'
    },
    { id: 'Branicevo, Serbia', name: 'Branicevo, Serbia' },
    { id: 'Tindouf, Algeria', name: 'Tindouf, Algeria' },
    { id: 'Leribe, Lesotho', name: 'Leribe, Lesotho' },
    { id: 'Ewa, Nauru', name: 'Ewa, Nauru' },
    { id: 'Brandenburg, Germany', name: 'Brandenburg, Germany' },
    {
        id: 'Castello di Fiorentino, San Marino',
        name: 'Castello di Fiorentino, San Marino'
    },
    { id: 'Blansko District, Czechia', name: 'Blansko District, Czechia' },
    {
        id: 'Santa Cruz de Tenerife, Spain',
        name: 'Santa Cruz de Tenerife, Spain'
    },
    { id: 'Pest megye, Hungary', name: 'Pest megye, Hungary' },
    {
        id: 'Meurthe et Moselle, France',
        name: 'Meurthe et Moselle, France'
    },
    { id: 'Kaohsiung, Taiwan', name: 'Kaohsiung, Taiwan' },
    { id: 'Lofa County, Liberia', name: 'Lofa County, Liberia' },
    { id: 'Creuse, France', name: 'Creuse, France' },
    {
        id: 'Andaman and Nicobar, India',
        name: 'Andaman and Nicobar, India'
    },
    { id: 'Liberec District, Czechia', name: 'Liberec District, Czechia' },
    { id: 'Najran, Saudi Arabia', name: 'Najran, Saudi Arabia' },
    { id: 'Edinburgh, United Kingdom', name: 'Edinburgh, United Kingdom' },
    { id: 'South Corsica, France', name: 'South Corsica, France' },
    { id: 'Manufahi, East Timor', name: 'Manufahi, East Timor' },
    {
        id: 'Changwat Buri Ram, Thailand',
        name: 'Changwat Buri Ram, Thailand'
    },
    { id: 'Nova Gorica, Slovenia', name: 'Nova Gorica, Slovenia' },
    {
        id: 'Leova, Republic of Moldova',
        name: 'Leova, Republic of Moldova'
    },
    {
        id: 'Gouvernorat de Tozeur, Tunisia',
        name: 'Gouvernorat de Tozeur, Tunisia'
    },
    { id: 'Xanthi, Greece', name: 'Xanthi, Greece' },
    { id: 'Limpopo, South Africa', name: 'Limpopo, South Africa' },
    {
        id: 'Mafraq, Hashemite Kingdom of Jordan',
        name: 'Mafraq, Hashemite Kingdom of Jordan'
    },
    {
        id: 'Judetul Caras-Severin, Romania',
        name: 'Judetul Caras-Severin, Romania'
    },
    {
        id: 'Iles Tuamotu-Gambier, French Polynesia',
        name: 'Iles Tuamotu-Gambier, French Polynesia'
    },
    { id: 'Limburg Province, Belgium', name: 'Limburg Province, Belgium' },
    {
        id: 'Vilnius City Municipality, Republic of Lithuania',
        name: 'Vilnius City Municipality, Republic of Lithuania'
    },
    {
        id: 'Provincia di Cosenza, Italy',
        name: 'Provincia di Cosenza, Italy'
    },
    { id: 'Sohag, Egypt', name: 'Sohag, Egypt' },
    { id: 'Mchinji District, Malawi', name: 'Mchinji District, Malawi' },
    { id: 'Bitlis, Turkey', name: 'Bitlis, Turkey' },
    { id: 'Arcadia, Greece', name: 'Arcadia, Greece' },
    { id: 'Changwat Phrae, Thailand', name: 'Changwat Phrae, Thailand' },
    { id: 'Comoe, Ivory Coast', name: 'Comoe, Ivory Coast' },
    { id: 'Isère, France', name: 'Isère, France' },
    { id: 'Guemueshane, Turkey', name: 'Guemueshane, Turkey' },
    { id: 'Liquiçá, East Timor', name: 'Liquiçá, East Timor' },
    { id: 'Toyama, Japan', name: 'Toyama, Japan' },
    {
        id: 'North Chungcheong, Republic of Korea',
        name: 'North Chungcheong, Republic of Korea'
    },
    {
        id: 'Esch-sur-Alzette, Luxembourg',
        name: 'Esch-sur-Alzette, Luxembourg'
    },
    {
        id: 'Provincia de Camagueey, Cuba',
        name: 'Provincia de Camagueey, Cuba'
    },
    {
        id: "Vinnyts'ka Oblast', Ukraine",
        name: "Vinnyts'ka Oblast', Ukraine"
    },
    { id: 'Bamako Region, Mali', name: 'Bamako Region, Mali' },
    { id: 'Manche, France', name: 'Manche, France' },
    { id: 'Bogdanci, Macedonia', name: 'Bogdanci, Macedonia' },
    { id: 'Tripura, India', name: 'Tripura, India' },
    { id: 'Oblast Shumen, Bulgaria', name: 'Oblast Shumen, Bulgaria' },
    { id: 'Powys, United Kingdom', name: 'Powys, United Kingdom' },
    { id: 'Guangxi, China', name: 'Guangxi, China' },
    {
        id: 'Departamento de Rio Negro, Uruguay',
        name: 'Departamento de Rio Negro, Uruguay'
    },
    { id: 'Tubas, Palestine', name: 'Tubas, Palestine' },
    { id: 'Tinh Ha Giang, Vietnam', name: 'Tinh Ha Giang, Vietnam' },
    { id: 'Guelma, Algeria', name: 'Guelma, Algeria' },
    { id: 'Gomel, Belarus', name: 'Gomel, Belarus' },
    { id: 'Anglesey, United Kingdom', name: 'Anglesey, United Kingdom' },
    { id: 'Cajamarca, Peru', name: 'Cajamarca, Peru' },
    { id: 'Wanchai, Hong Kong', name: 'Wanchai, Hong Kong' },
    { id: 'Ghardaia, Algeria', name: 'Ghardaia, Algeria' },
    {
        id: 'North Yorkshire, United Kingdom',
        name: 'North Yorkshire, United Kingdom'
    },
    { id: 'Gaoual Prefecture, Guinea', name: 'Gaoual Prefecture, Guinea' },
    {
        id: 'Obcina Mirna Pec, Slovenia',
        name: 'Obcina Mirna Pec, Slovenia'
    },
    { id: 'Alagoas, Brazil', name: 'Alagoas, Brazil' },
    {
        id: 'KwaZulu-Natal, South Africa',
        name: 'KwaZulu-Natal, South Africa'
    },
    { id: 'Aitoloakarnania, Greece', name: 'Aitoloakarnania, Greece' },
    {
        id: 'Luang Prabang Province, Laos',
        name: 'Luang Prabang Province, Laos'
    },
    {
        id: 'Qyzylorda Oblysy, Kazakhstan',
        name: 'Qyzylorda Oblysy, Kazakhstan'
    },
    {
        id: 'Departamento de Bolivar, Colombia',
        name: 'Departamento de Bolivar, Colombia'
    },
    {
        id: 'Departamento de Leon, Nicaragua',
        name: 'Departamento de Leon, Nicaragua'
    },
    { id: 'Horjul, Slovenia', name: 'Horjul, Slovenia' },
    { id: 'Adana, Turkey', name: 'Adana, Turkey' },
    { id: 'Fes, Morocco', name: 'Fes, Morocco' },
    {
        id: 'Departamento de Managua, Nicaragua',
        name: 'Departamento de Managua, Nicaragua'
    },
    {
        id: 'Departamento de Cochabamba, Bolivia',
        name: 'Departamento de Cochabamba, Bolivia'
    },
    { id: 'Rutana Province, Burundi', name: 'Rutana Province, Burundi' },
    {
        id: 'Orange Free State, South Africa',
        name: 'Orange Free State, South Africa'
    },
    { id: 'Far Western, Nepal', name: 'Far Western, Nepal' },
    { id: 'Põlvamaa, Estonia', name: 'Põlvamaa, Estonia' },
    {
        id: 'Siauliai, Republic of Lithuania',
        name: 'Siauliai, Republic of Lithuania'
    },
    {
        id: 'Tauragė District Municipality, Republic of Lithuania',
        name: 'Tauragė District Municipality, Republic of Lithuania'
    },
    {
        id: 'Province de la Bougouriba, Burkina Faso',
        name: 'Province de la Bougouriba, Burkina Faso'
    },
    {
        id: 'Prince Edward Island, Canada',
        name: 'Prince Edward Island, Canada'
    },
    { id: 'Tan-Tan, Morocco', name: 'Tan-Tan, Morocco' },
    { id: 'Arkhangelskaya, Russia', name: 'Arkhangelskaya, Russia' },
    { id: 'Schaan, Liechtenstein', name: 'Schaan, Liechtenstein' },
    { id: 'Hedmark, Norway', name: 'Hedmark, Norway' },
    { id: 'Vukovar-Sirmium, Croatia', name: 'Vukovar-Sirmium, Croatia' },
    {
        id: 'Hope Town District, Bahamas',
        name: 'Hope Town District, Bahamas'
    },
    { id: 'Saint Patrick, Grenada', name: 'Saint Patrick, Grenada' },
    {
        id: 'Departamento de Cuscatlan, El Salvador',
        name: 'Departamento de Cuscatlan, El Salvador'
    },
    { id: 'Bheri Zone, Nepal', name: 'Bheri Zone, Nepal' },
    { id: 'Ibaraki, Japan', name: 'Ibaraki, Japan' },
    {
        id: 'Obcina Ajdovscina, Slovenia',
        name: 'Obcina Ajdovscina, Slovenia'
    },
    { id: 'Punakha Dzongkhag, Bhutan', name: 'Punakha Dzongkhag, Bhutan' },
    { id: 'Mauren, Liechtenstein', name: 'Mauren, Liechtenstein' },
    { id: 'Changwat Ranong, Thailand', name: 'Changwat Ranong, Thailand' },
    { id: 'Azilal Province, Morocco', name: 'Azilal Province, Morocco' },
    { id: 'Araba / Álava, Spain', name: 'Araba / Álava, Spain' },
    { id: 'Barinas, Venezuela', name: 'Barinas, Venezuela' },
    { id: 'Province of Terni, Italy', name: 'Province of Terni, Italy' },
    { id: 'Tuen Mun, Hong Kong', name: 'Tuen Mun, Hong Kong' },
    { id: 'Tuamasaga, Samoa', name: 'Tuamasaga, Samoa' },
    { id: 'Las Palmas, Spain', name: 'Las Palmas, Spain' },
    { id: 'Capellen, Luxembourg', name: 'Capellen, Luxembourg' },
    { id: 'Babek Rayon, Azerbaijan', name: 'Babek Rayon, Azerbaijan' },
    { id: 'Akwa Ibom State, Nigeria', name: 'Akwa Ibom State, Nigeria' },
    {
        id: 'Provincia de Holguin, Cuba',
        name: 'Provincia de Holguin, Cuba'
    },
    { id: 'Tetovo, Macedonia', name: 'Tetovo, Macedonia' },
    {
        id: 'Mtskheta-Mtianeti, Georgia',
        name: 'Mtskheta-Mtianeti, Georgia'
    },
    {
        id: 'North Tyneside, United Kingdom',
        name: 'North Tyneside, United Kingdom'
    },
    { id: 'Aiwo, Nauru', name: 'Aiwo, Nauru' },
    {
        id: 'Nový Jičín District, Czechia',
        name: 'Nový Jičín District, Czechia'
    },
    {
        id: 'Vilnius District Municipality, Republic of Lithuania',
        name: 'Vilnius District Municipality, Republic of Lithuania'
    },
    {
        id: 'Annobon, Equatorial Guinea',
        name: 'Annobon, Equatorial Guinea'
    },
    { id: 'Changwat Yala, Thailand', name: 'Changwat Yala, Thailand' },
    { id: 'Kagoshima, Japan', name: 'Kagoshima, Japan' },
    { id: 'Södermanland, Sweden', name: 'Södermanland, Sweden' },
    { id: 'Danilovgrad, Montenegro', name: 'Danilovgrad, Montenegro' },
    { id: 'Changwat Loei, Thailand', name: 'Changwat Loei, Thailand' },
    {
        id: 'Province of Palawan, Philippines',
        name: 'Province of Palawan, Philippines'
    },
    { id: 'Kingston, Jamaica', name: 'Kingston, Jamaica' },
    { id: 'Kampong Thom, Cambodia', name: 'Kampong Thom, Cambodia' },
    {
        id: 'Tarrafal de São Nicolau, Cabo Verde',
        name: 'Tarrafal de São Nicolau, Cabo Verde'
    },
    { id: 'Sveti Nikole, Macedonia', name: 'Sveti Nikole, Macedonia' },
    {
        id: 'Provincia di Perugia, Italy',
        name: 'Provincia di Perugia, Italy'
    },
    {
        id: 'Isle of Wight, United Kingdom',
        name: 'Isle of Wight, United Kingdom'
    },
    { id: 'Murska Sobota, Slovenia', name: 'Murska Sobota, Slovenia' },
    { id: 'Rhône, France', name: 'Rhône, France' },
    { id: 'Kasaï-Oriental, Congo', name: 'Kasaï-Oriental, Congo' },
    {
        id: 'Upper Demerara-Berbice Region, Guyana',
        name: 'Upper Demerara-Berbice Region, Guyana'
    },
    {
        id: 'Ignalina District Municipality, Republic of Lithuania',
        name: 'Ignalina District Municipality, Republic of Lithuania'
    },
    { id: 'Inchiri, Mauritania', name: 'Inchiri, Mauritania' },
    {
        id: 'Nord-Pas-de-Calais, France',
        name: 'Nord-Pas-de-Calais, France'
    },
    {
        id: "Sha'biyat az Zawiyah, Libya",
        name: "Sha'biyat az Zawiyah, Libya"
    },
    { id: 'Tibet, China', name: 'Tibet, China' },
    { id: 'Remich, Luxembourg', name: 'Remich, Luxembourg' },
    {
        id: 'Departamento de San Vicente, El Salvador',
        name: 'Departamento de San Vicente, El Salvador'
    },
    { id: 'Cornwall, United Kingdom', name: 'Cornwall, United Kingdom' },
    {
        id: 'Provincia de La Altagracia, Dominican Republic',
        name: 'Provincia de La Altagracia, Dominican Republic'
    },
    { id: 'Bucuresti, Romania', name: 'Bucuresti, Romania' },
    {
        id: 'Aghstafa Rayon, Azerbaijan',
        name: 'Aghstafa Rayon, Azerbaijan'
    },
    {
        id: 'Anykščiai District Municipality, Republic of Lithuania',
        name: 'Anykščiai District Municipality, Republic of Lithuania'
    },
    {
        id: 'Changwat Sa Kaeo, Thailand',
        name: 'Changwat Sa Kaeo, Thailand'
    },
    { id: 'Paget, Bermuda', name: 'Paget, Bermuda' },
    {
        id: 'Northwest Territories, Canada',
        name: 'Northwest Territories, Canada'
    },
    { id: 'Meemu Atholhu, Maldives', name: 'Meemu Atholhu, Maldives' },
    {
        id: 'Saipan, Northern Mariana Islands',
        name: 'Saipan, Northern Mariana Islands'
    },
    {
        id: "Orlovskaya Oblast', Russia",
        name: "Orlovskaya Oblast', Russia"
    },
    {
        id: 'Karachayevo-Cherkesiya, Russia',
        name: 'Karachayevo-Cherkesiya, Russia'
    },
    {
        id: 'Viloyati Khatlon, Tajikistan',
        name: 'Viloyati Khatlon, Tajikistan'
    },
    { id: 'Riebinu Novads, Latvia', name: 'Riebinu Novads, Latvia' },
    { id: 'Cusco, Peru', name: 'Cusco, Peru' },
    { id: 'Anibare, Nauru', name: 'Anibare, Nauru' },
    { id: 'Meneng, Nauru', name: 'Meneng, Nauru' },
    { id: 'Portalegre, Portugal', name: 'Portalegre, Portugal' },
    { id: 'Giza, Egypt', name: 'Giza, Egypt' },
    { id: 'Gilbert Islands, Kiribati', name: 'Gilbert Islands, Kiribati' },
    { id: 'Saragossa, Spain', name: 'Saragossa, Spain' },
    { id: 'Provincia di Bari, Italy', name: 'Provincia di Bari, Italy' },
    { id: 'Jabal al Gharbi, Libya', name: 'Jabal al Gharbi, Libya' },
    { id: 'Saint Philip, Barbados', name: 'Saint Philip, Barbados' },
    { id: 'Muyinga Province, Burundi', name: 'Muyinga Province, Burundi' },
    { id: 'Ealing, United Kingdom', name: 'Ealing, United Kingdom' },
    { id: 'Luxor, Egypt', name: 'Luxor, Egypt' },
    { id: 'Nomos Kefallinias, Greece', name: 'Nomos Kefallinias, Greece' },
    { id: 'Fukushima-ken, Japan', name: 'Fukushima-ken, Japan' },
    {
        id: 'Province of Rizal, Philippines',
        name: 'Province of Rizal, Philippines'
    },
    { id: 'Ulyanovsk Oblast, Russia', name: 'Ulyanovsk Oblast, Russia' },
    { id: 'Pemba South, Tanzania', name: 'Pemba South, Tanzania' },
    { id: 'Arusha, Tanzania', name: 'Arusha, Tanzania' },
    {
        id: 'Region de Magallanes y de la Antartica Chilena, Chile',
        name: 'Region de Magallanes y de la Antartica Chilena, Chile'
    },
    { id: "Muhafazat Karbala', Iraq", name: "Muhafazat Karbala', Iraq" },
    { id: 'Safi, Malta', name: 'Safi, Malta' },
    {
        id: 'Provincia di Piacenza, Italy',
        name: 'Provincia di Piacenza, Italy'
    },
    { id: 'South Eleuthera, Bahamas', name: 'South Eleuthera, Bahamas' },
    { id: 'Taichung City, Taiwan', name: 'Taichung City, Taiwan' },
    {
        id: 'Departamento del Meta, Colombia',
        name: 'Departamento del Meta, Colombia'
    },
    {
        id: 'Province du Bam, Burkina Faso',
        name: 'Province du Bam, Burkina Faso'
    },
    {
        id: 'North West Community Development Council, Singapore',
        name: 'North West Community Development Council, Singapore'
    },
    { id: 'Madeira, Portugal', name: 'Madeira, Portugal' },
    {
        id: 'Perth and Kinross, United Kingdom',
        name: 'Perth and Kinross, United Kingdom'
    },
    {
        id: 'Saint George Basseterre, St Kitts and Nevis',
        name: 'Saint George Basseterre, St Kitts and Nevis'
    },
    { id: 'Zajecar, Serbia', name: 'Zajecar, Serbia' },
    { id: 'Ermera, East Timor', name: 'Ermera, East Timor' },
    { id: 'Tanga, Tanzania', name: 'Tanga, Tanzania' },
    { id: 'Tabora, Tanzania', name: 'Tabora, Tanzania' },
    { id: 'Brezovica, Slovenia', name: 'Brezovica, Slovenia' },
    { id: 'Oblast Ruse, Bulgaria', name: 'Oblast Ruse, Bulgaria' },
    { id: 'Sermersooq, Greenland', name: 'Sermersooq, Greenland' },
    { id: 'Hunedoara, Romania', name: 'Hunedoara, Romania' },
    { id: 'Vestfold, Norway', name: 'Vestfold, Norway' },
    {
        id: 'Departamento de Cabanas, El Salvador',
        name: 'Departamento de Cabanas, El Salvador'
    },
    { id: 'Bouira, Algeria', name: 'Bouira, Algeria' },
    { id: 'Ziguinchor, Senegal', name: 'Ziguinchor, Senegal' },
    { id: 'Hal Gharghur, Malta', name: 'Hal Gharghur, Malta' },
    {
        id: 'Departamento de Jutiapa, Guatemala',
        name: 'Departamento de Jutiapa, Guatemala'
    },
    {
        id: 'Provincia di Potenza, Italy',
        name: 'Provincia di Potenza, Italy'
    },
    { id: 'Kirklees, United Kingdom', name: 'Kirklees, United Kingdom' },
    { id: 'Cerknica, Slovenia', name: 'Cerknica, Slovenia' },
    { id: 'Swindon, United Kingdom', name: 'Swindon, United Kingdom' },
    {
        id: 'Wilaya du Trarza, Mauritania',
        name: 'Wilaya du Trarza, Mauritania'
    },
    { id: 'Ustecky kraj, Czechia', name: 'Ustecky kraj, Czechia' },
    {
        id: 'Dar es Salaam Region, Tanzania',
        name: 'Dar es Salaam Region, Tanzania'
    },
    { id: 'Tinh Son La, Vietnam', name: 'Tinh Son La, Vietnam' },
    {
        id: 'Blaenau Gwent, United Kingdom',
        name: 'Blaenau Gwent, United Kingdom'
    },
    {
        id: 'Provincia di Vercelli, Italy',
        name: 'Provincia di Vercelli, Italy'
    },
    { id: 'Bolu, Turkey', name: 'Bolu, Turkey' },
    { id: 'Laccadives, India', name: 'Laccadives, India' },
    {
        id: 'Nisporeni, Republic of Moldova',
        name: 'Nisporeni, Republic of Moldova'
    },
    { id: 'Trnava, Slovakia', name: 'Trnava, Slovakia' },
    { id: 'Diourbel, Senegal', name: 'Diourbel, Senegal' },
    { id: 'Hodonín District, Czechia', name: 'Hodonín District, Czechia' },
    { id: 'Rio Grande do Sul, Brazil', name: 'Rio Grande do Sul, Brazil' },
    { id: 'Tinh Lai Chau, Vietnam', name: 'Tinh Lai Chau, Vietnam' },
    { id: 'Al Marj, Libya', name: 'Al Marj, Libya' },
    { id: 'Harari Region, Ethiopia', name: 'Harari Region, Ethiopia' },
    { id: 'South Banat, Serbia', name: 'South Banat, Serbia' },
    { id: 'Kigali, Rwanda', name: 'Kigali, Rwanda' },
    {
        id: 'Departamento de Nueva Segovia, Nicaragua',
        name: 'Departamento de Nueva Segovia, Nicaragua'
    },
    { id: 'Finland Proper, Finland', name: 'Finland Proper, Finland' },
    {
        id: 'Chin State, Myanmar [Burma]',
        name: 'Chin State, Myanmar [Burma]'
    },
    { id: 'Solihull, United Kingdom', name: 'Solihull, United Kingdom' },
    { id: 'Sha`biyat Nalut, Libya', name: 'Sha`biyat Nalut, Libya' },
    {
        id: 'Castello di San Marino Citta, San Marino',
        name: 'Castello di San Marino Citta, San Marino'
    },
    { id: 'Podunavlje, Serbia', name: 'Podunavlje, Serbia' },
    {
        id: 'Departamento de Carazo, Nicaragua',
        name: 'Departamento de Carazo, Nicaragua'
    },
    { id: 'Naama, Algeria', name: 'Naama, Algeria' },
    { id: 'Nevsehir, Turkey', name: 'Nevsehir, Turkey' },
    { id: 'Taranaki, New Zealand', name: 'Taranaki, New Zealand' },
    { id: 'Gaza Province, Mozambique', name: 'Gaza Province, Mozambique' },
    {
        id: 'Isabel Province, Solomon Islands',
        name: 'Isabel Province, Solomon Islands'
    },
    { id: 'Järvamaa, Estonia', name: 'Järvamaa, Estonia' },
    { id: 'Harrow, United Kingdom', name: 'Harrow, United Kingdom' },
    { id: 'Haut-Ogooué, Gabon', name: 'Haut-Ogooué, Gabon' },
    {
        id: 'Puerto Plata, Dominican Republic',
        name: 'Puerto Plata, Dominican Republic'
    },
    {
        id: 'Qarku i Gjirokastres, Albania',
        name: 'Qarku i Gjirokastres, Albania'
    },
    { id: 'Darnah, Libya', name: 'Darnah, Libya' },
    {
        id: 'Departamento de Chiquimula, Guatemala',
        name: 'Departamento de Chiquimula, Guatemala'
    },
    { id: 'Syracuse, Italy', name: 'Syracuse, Italy' },
    { id: 'Rostov, Russia', name: 'Rostov, Russia' },
    {
        id: 'Basse-Kotto, Central African Republic',
        name: 'Basse-Kotto, Central African Republic'
    },
    {
        id: 'Provincia di Oristano, Italy',
        name: 'Provincia di Oristano, Italy'
    },
    { id: 'West Pomerania, Poland', name: 'West Pomerania, Poland' },
    { id: 'Porto Novo, Cabo Verde', name: 'Porto Novo, Cabo Verde' },
    {
        id: 'Provincia de Elias Pina, Dominican Republic',
        name: 'Provincia de Elias Pina, Dominican Republic'
    },
    {
        id: 'Provincia di Verona, Italy',
        name: 'Provincia di Verona, Italy'
    },
    {
        id: 'Vallee du Bandama, Ivory Coast',
        name: 'Vallee du Bandama, Ivory Coast'
    },
    {
        id: 'Jan Mayen, Svalbard and Jan Mayen',
        name: 'Jan Mayen, Svalbard and Jan Mayen'
    },
    { id: 'Waikato, New Zealand', name: 'Waikato, New Zealand' },
    { id: 'Ancash, Peru', name: 'Ancash, Peru' },
    {
        id: 'Baladiyat ar Rayyan, Qatar',
        name: 'Baladiyat ar Rayyan, Qatar'
    },
    { id: 'Northwest, Iceland', name: 'Northwest, Iceland' },
    { id: 'Łódź Voivodeship, Poland', name: 'Łódź Voivodeship, Poland' },
    { id: 'Beau Vallon, Seychelles', name: 'Beau Vallon, Seychelles' },
    {
        id: 'Midlothian, United Kingdom',
        name: 'Midlothian, United Kingdom'
    },
    {
        id: 'Ostan-e Khorasan-e Shomali, Iran',
        name: 'Ostan-e Khorasan-e Shomali, Iran'
    },
    { id: 'Corrientes, Argentina', name: 'Corrientes, Argentina' },
    { id: 'Ribnica, Slovenia', name: 'Ribnica, Slovenia' },
    { id: 'Bomi County, Liberia', name: 'Bomi County, Liberia' },
    { id: 'El Tarf, Algeria', name: 'El Tarf, Algeria' },
    { id: 'Cundinamarca, Colombia', name: 'Cundinamarca, Colombia' },
    { id: 'Podvelka, Slovenia', name: 'Podvelka, Slovenia' },
    { id: 'Westfjords, Iceland', name: 'Westfjords, Iceland' },
    { id: 'Sumqayit City, Azerbaijan', name: 'Sumqayit City, Azerbaijan' },
    {
        id: 'Changwat Si Sa Ket, Thailand',
        name: 'Changwat Si Sa Ket, Thailand'
    },
    {
        id: 'Zanzibar Urban/West, Tanzania',
        name: 'Zanzibar Urban/West, Tanzania'
    },
    { id: 'Kuala Lumpur, Malaysia', name: 'Kuala Lumpur, Malaysia' },
    { id: 'Iwate, Japan', name: 'Iwate, Japan' },
    {
        id: 'Madang Province, Papua New Guinea',
        name: 'Madang Province, Papua New Guinea'
    },
    { id: 'Amapa, Brazil', name: 'Amapa, Brazil' },
    { id: 'Naklo, Slovenia', name: 'Naklo, Slovenia' },
    {
        id: 'Diego Martin, Trinidad and Tobago',
        name: 'Diego Martin, Trinidad and Tobago'
    },
    { id: 'Salavan, Laos', name: 'Salavan, Laos' },
    { id: 'Kebbi State, Nigeria', name: 'Kebbi State, Nigeria' },
    { id: 'Tabasco, Mexico', name: 'Tabasco, Mexico' },
    { id: 'Indre and Loire, France', name: 'Indre and Loire, France' },
    { id: 'Sofia-Capital, Bulgaria', name: 'Sofia-Capital, Bulgaria' },
    { id: 'Dagestan, Russia', name: 'Dagestan, Russia' },
    { id: 'Pafos, Cyprus', name: 'Pafos, Cyprus' },
    {
        id: 'Irbid, Hashemite Kingdom of Jordan',
        name: 'Irbid, Hashemite Kingdom of Jordan'
    },
    { id: 'Ankaran, Slovenia', name: 'Ankaran, Slovenia' },
    { id: 'Rabat, Morocco', name: 'Rabat, Morocco' },
    { id: 'Waterford, Ireland', name: 'Waterford, Ireland' },
    {
        id: 'Al Madinah al Munawwarah, Saudi Arabia',
        name: 'Al Madinah al Munawwarah, Saudi Arabia'
    },
    { id: 'Lima region, Peru', name: 'Lima region, Peru' },
    { id: 'Changwat Rayong, Thailand', name: 'Changwat Rayong, Thailand' },
    {
        id: 'Hhohho District, Swaziland',
        name: 'Hhohho District, Swaziland'
    },
    { id: 'Hauts-de-Seine, France', name: 'Hauts-de-Seine, France' },
    { id: 'Il-Gzira, Malta', name: 'Il-Gzira, Malta' },
    { id: 'Trafford, United Kingdom', name: 'Trafford, United Kingdom' },
    { id: 'Eastern Darfur, Sudan', name: 'Eastern Darfur, Sudan' },
    {
        id: 'Sai Kung District, Hong Kong',
        name: 'Sai Kung District, Hong Kong'
    },
    { id: 'Satakunta, Finland', name: 'Satakunta, Finland' },
    {
        id: 'Departamento de La Paz, Bolivia',
        name: 'Departamento de La Paz, Bolivia'
    },
    { id: 'Andorra la Vella, Andorra', name: 'Andorra la Vella, Andorra' },
    {
        id: 'Departamento de Salto, Uruguay',
        name: 'Departamento de Salto, Uruguay'
    },
    { id: 'South Sumatra, Indonesia', name: 'South Sumatra, Indonesia' },
    {
        id: "Province d'Oubritenga, Burkina Faso",
        name: "Province d'Oubritenga, Burkina Faso"
    },
    {
        id: 'Provincia de Villa Clara, Cuba',
        name: 'Provincia de Villa Clara, Cuba'
    },
    { id: 'Oujda-Angad, Morocco', name: 'Oujda-Angad, Morocco' },
    { id: 'Sidi-Kacem, Morocco', name: 'Sidi-Kacem, Morocco' },
    {
        id: 'Province of Ilocos Norte, Philippines',
        name: 'Province of Ilocos Norte, Philippines'
    },
    {
        id: 'Kėdainiai District Municipality, Republic of Lithuania',
        name: 'Kėdainiai District Municipality, Republic of Lithuania'
    },
    { id: 'Nova Scotia, Canada', name: 'Nova Scotia, Canada' },
    {
        id: 'Zarqa, Hashemite Kingdom of Jordan',
        name: 'Zarqa, Hashemite Kingdom of Jordan'
    },
    {
        id: 'Haut-Mbomou, Central African Republic',
        name: 'Haut-Mbomou, Central African Republic'
    },
    {
        id: 'Kaunas District Municipality, Republic of Lithuania',
        name: 'Kaunas District Municipality, Republic of Lithuania'
    },
    {
        id: 'Mashonaland Central, Zimbabwe',
        name: 'Mashonaland Central, Zimbabwe'
    },
    { id: 'Biscay, Spain', name: 'Biscay, Spain' },
    {
        id: 'Changwat Sing Buri, Thailand',
        name: 'Changwat Sing Buri, Thailand'
    },
    { id: 'Ostan-e Gilan, Iran', name: 'Ostan-e Gilan, Iran' },
    { id: 'Mimaropa, Philippines', name: 'Mimaropa, Philippines' },
    {
        id: 'Qarku i Elbasanit, Albania',
        name: 'Qarku i Elbasanit, Albania'
    },
    {
        id: 'Okres Plzen-Sever, Czechia',
        name: 'Okres Plzen-Sever, Czechia'
    },
    { id: 'Velika Polana, Slovenia', name: 'Velika Polana, Slovenia' },
    { id: 'Alberta, Canada', name: 'Alberta, Canada' },
    { id: 'Obcina Verzej, Slovenia', name: 'Obcina Verzej, Slovenia' },
    { id: 'Kratovo, Macedonia', name: 'Kratovo, Macedonia' },
    {
        id: 'Bath and North East Somerset, United Kingdom',
        name: 'Bath and North East Somerset, United Kingdom'
    },
    { id: 'Judetul Buzau, Romania', name: 'Judetul Buzau, Romania' },
    {
        id: 'Gouvernorat de Ben Arous, Tunisia',
        name: 'Gouvernorat de Ben Arous, Tunisia'
    },
    { id: 'Govi-Sumber, Mongolia', name: 'Govi-Sumber, Mongolia' },
    {
        id: 'Sveti Jurij v Slovenskih Goricah, Slovenia',
        name: 'Sveti Jurij v Slovenskih Goricah, Slovenia'
    },
    { id: 'Blekinge, Sweden', name: 'Blekinge, Sweden' },
    { id: 'Semily District, Czechia', name: 'Semily District, Czechia' },
    { id: 'Altai, Russia', name: 'Altai, Russia' },
    {
        id: 'Xorazm Viloyati, Uzbekistan',
        name: 'Xorazm Viloyati, Uzbekistan'
    },
    { id: 'Jõgevamaa, Estonia', name: 'Jõgevamaa, Estonia' },
    { id: 'Provincia di Udine, Italy', name: 'Provincia di Udine, Italy' },
    { id: 'Quintana Roo, Mexico', name: 'Quintana Roo, Mexico' },
    {
        id: 'Distrikt Marowijne, Suriname',
        name: 'Distrikt Marowijne, Suriname'
    },
    {
        id: 'Departamento de Canindeyu, Paraguay',
        name: 'Departamento de Canindeyu, Paraguay'
    },
    { id: 'North Banat, Serbia', name: 'North Banat, Serbia' },
    {
        id: 'Zhambyl Oblysy, Kazakhstan',
        name: 'Zhambyl Oblysy, Kazakhstan'
    },
    {
        id: 'Raionul Edineţ, Republic of Moldova',
        name: 'Raionul Edineţ, Republic of Moldova'
    },
    { id: 'Ghajnsielem, Malta', name: 'Ghajnsielem, Malta' },
    { id: 'Malaga, Spain', name: 'Malaga, Spain' },
    { id: 'Tanganika, Congo', name: 'Tanganika, Congo' },
    {
        id: 'Couva-Tabaquite-Talparo, Trinidad and Tobago',
        name: 'Couva-Tabaquite-Talparo, Trinidad and Tobago'
    },
    {
        id: 'Šakiai District Municipality, Republic of Lithuania',
        name: 'Šakiai District Municipality, Republic of Lithuania'
    },
    {
        id: "K'alak'i T'bilisi, Georgia",
        name: "K'alak'i T'bilisi, Georgia"
    },
    { id: 'Grisons, Switzerland', name: 'Grisons, Switzerland' },
    { id: 'Ngozi Province, Burundi', name: 'Ngozi Province, Burundi' },
    { id: 'Kostel, Slovenia', name: 'Kostel, Slovenia' },
    { id: 'Qarku i Korces, Albania', name: 'Qarku i Korces, Albania' },
    {
        id: 'Provincia di Trieste, Italy',
        name: 'Provincia di Trieste, Italy'
    },
    {
        id: 'Changwat Saraburi, Thailand',
        name: 'Changwat Saraburi, Thailand'
    },
    {
        id: 'Magway Region, Myanmar [Burma]',
        name: 'Magway Region, Myanmar [Burma]'
    },
    { id: 'Saga, Japan', name: 'Saga, Japan' },
    { id: 'Trashi Yangste, Bhutan', name: 'Trashi Yangste, Bhutan' },
    {
        id: 'Grand Anse Mahe, Seychelles',
        name: 'Grand Anse Mahe, Seychelles'
    },
    { id: 'Bursa, Turkey', name: 'Bursa, Turkey' },
    { id: ' and Saba, Bonaire', name: ' and Saba, Bonaire' },
    { id: 'Acre, Brazil', name: 'Acre, Brazil' },
    { id: 'Gulbenes Rajons, Latvia', name: 'Gulbenes Rajons, Latvia' },
    {
        id: "L'vivs'ka Oblast', Ukraine",
        name: "L'vivs'ka Oblast', Ukraine"
    },
    {
        id: 'Lazdijai District Municipality, Republic of Lithuania',
        name: 'Lazdijai District Municipality, Republic of Lithuania'
    },
    { id: 'Praha 19, Czechia', name: 'Praha 19, Czechia' },
    { id: 'Beyrouth, Lebanon', name: 'Beyrouth, Lebanon' },
    { id: 'Rakahanga, Cook Islands', name: 'Rakahanga, Cook Islands' },
    {
        id: 'Stoke-on-Trent, United Kingdom',
        name: 'Stoke-on-Trent, United Kingdom'
    },
    {
        id: 'Bazega Province, Burkina Faso',
        name: 'Bazega Province, Burkina Faso'
    },
    { id: 'Tameside, United Kingdom', name: 'Tameside, United Kingdom' },
    {
        id: 'Portsmouth, United Kingdom',
        name: 'Portsmouth, United Kingdom'
    },
    { id: 'El Bayadh, Algeria', name: 'El Bayadh, Algeria' },
    {
        id: 'Province of Samar, Philippines',
        name: 'Province of Samar, Philippines'
    },
    { id: 'Mie, Japan', name: 'Mie, Japan' },
    { id: 'Bilecik, Turkey', name: 'Bilecik, Turkey' },
    { id: 'Saint Luke, Dominica', name: 'Saint Luke, Dominica' },
    { id: 'Changwat Trang, Thailand', name: 'Changwat Trang, Thailand' },
    { id: 'Al-Qassim, Saudi Arabia', name: 'Al-Qassim, Saudi Arabia' },
    {
        id: 'Province of  Zamboanga del Sur, Philippines',
        name: 'Province of  Zamboanga del Sur, Philippines'
    },
    { id: 'South Andros, Bahamas', name: 'South Andros, Bahamas' },
    { id: 'West Region, Cameroon', name: 'West Region, Cameroon' },
    { id: 'Chrudim District, Czechia', name: 'Chrudim District, Czechia' },
    { id: 'Shiga, Japan', name: 'Shiga, Japan' },
    { id: 'Charente-Maritime, France', name: 'Charente-Maritime, France' },
    { id: 'Aargau, Switzerland', name: 'Aargau, Switzerland' },
    { id: 'Nenets, Russia', name: 'Nenets, Russia' },
    {
        id: 'Dundee City, United Kingdom',
        name: 'Dundee City, United Kingdom'
    },
    { id: 'Arhangay Aymag, Mongolia', name: 'Arhangay Aymag, Mongolia' },
    {
        id: "Belgorodskaya Oblast', Russia",
        name: "Belgorodskaya Oblast', Russia"
    },
    { id: 'Opstina Kocani, Macedonia', name: 'Opstina Kocani, Macedonia' },
    { id: 'Prey Veng, Cambodia', name: 'Prey Veng, Cambodia' },
    { id: 'Puno, Peru', name: 'Puno, Peru' },
    { id: 'Lublin, Poland', name: 'Lublin, Poland' },
    { id: 'Ōsaka, Japan', name: 'Ōsaka, Japan' },
    { id: 'Kalmar, Sweden', name: 'Kalmar, Sweden' },
    { id: 'Praha 12, Czechia', name: 'Praha 12, Czechia' },
    {
        id: 'Rychnov nad Kněžnou District, Czechia',
        name: 'Rychnov nad Kněžnou District, Czechia'
    },
    { id: 'Katsina State, Nigeria', name: 'Katsina State, Nigeria' },
    {
        id: 'Mayaro, Trinidad and Tobago',
        name: 'Mayaro, Trinidad and Tobago'
    },
    { id: 'Muş, Turkey', name: 'Muş, Turkey' },
    {
        id: 'Druskininkai Municipality, Republic of Lithuania',
        name: 'Druskininkai Municipality, Republic of Lithuania'
    },
    { id: 'New Taipei, Taiwan', name: 'New Taipei, Taiwan' },
    { id: 'Cerro Largo, Uruguay', name: 'Cerro Largo, Uruguay' },
    { id: "Vava'u, Tonga", name: "Vava'u, Tonga" },
    { id: 'Samsun, Turkey', name: 'Samsun, Turkey' },
    { id: 'Uri, Switzerland', name: 'Uri, Switzerland' },
    { id: 'Huancavelica, Peru', name: 'Huancavelica, Peru' },
    { id: 'Kastamonu, Turkey', name: 'Kastamonu, Turkey' },
    {
        id: 'Okres Praha-Vychod, Czechia',
        name: 'Okres Praha-Vychod, Czechia'
    },
    { id: "Sha'biyat Banghazi, Libya", name: "Sha'biyat Banghazi, Libya" },
    {
        id: 'Departamento de Rivas, Nicaragua',
        name: 'Departamento de Rivas, Nicaragua'
    },
    {
        id: 'Provincia de Cocle, Panama',
        name: 'Provincia de Cocle, Panama'
    },
    { id: 'Trento, Italy', name: 'Trento, Italy' },
    { id: 'Kirinyaga District, Kenya', name: 'Kirinyaga District, Kenya' },
    { id: 'Shandong, China', name: 'Shandong, China' },
    {
        id: 'Mohafazat Baalbek-Hermel, Lebanon',
        name: 'Mohafazat Baalbek-Hermel, Lebanon'
    },
    { id: 'Olomouc District, Czechia', name: 'Olomouc District, Czechia' },
    { id: 'Provincia di Nuoro, Italy', name: 'Provincia di Nuoro, Italy' },
    { id: 'Anse-aux-Pins, Seychelles', name: 'Anse-aux-Pins, Seychelles' },
    { id: 'Edo, Nigeria', name: 'Edo, Nigeria' },
    { id: 'Uvs Aymag, Mongolia', name: 'Uvs Aymag, Mongolia' },
    {
        id: 'Opstina Radovis, Macedonia',
        name: 'Opstina Radovis, Macedonia'
    },
    { id: 'County Sligo, Ireland', name: 'County Sligo, Ireland' },
    {
        id: 'Province of Misamis Occidental, Philippines',
        name: 'Province of Misamis Occidental, Philippines'
    },
    {
        id: 'Provincia de Veraguas, Panama',
        name: 'Provincia de Veraguas, Panama'
    },
    { id: 'Kuldigas Rajons, Latvia', name: 'Kuldigas Rajons, Latvia' },
    { id: 'Ngoebe-Bugle, Panama', name: 'Ngoebe-Bugle, Panama' },
    { id: 'Kwango, Congo', name: 'Kwango, Congo' },
    {
        id: 'Central Sulawesi, Indonesia',
        name: 'Central Sulawesi, Indonesia'
    },
    { id: 'Louangnamtha, Laos', name: 'Louangnamtha, Laos' },
    {
        id: 'Departamento de Sonsonate, El Salvador',
        name: 'Departamento de Sonsonate, El Salvador'
    },
    { id: 'Kırşehir, Turkey', name: 'Kırşehir, Turkey' },
    {
        id: 'Peterborough, United Kingdom',
        name: 'Peterborough, United Kingdom'
    },
    {
        id: 'Southern Ostrobothnia, Finland',
        name: 'Southern Ostrobothnia, Finland'
    },
    {
        id: "Rivnens'ka Oblast', Ukraine",
        name: "Rivnens'ka Oblast', Ukraine"
    },
    {
        id: 'Wangdue Phodrang Dzongkhag, Bhutan',
        name: 'Wangdue Phodrang Dzongkhag, Bhutan'
    },
    { id: 'Al Wakrah, Qatar', name: 'Al Wakrah, Qatar' },
    { id: 'Rio de Janeiro, Brazil', name: 'Rio de Janeiro, Brazil' },
    { id: 'Barnet, United Kingdom', name: 'Barnet, United Kingdom' },
    { id: 'Al-Hoceima, Morocco', name: 'Al-Hoceima, Morocco' },
    { id: 'Aichi, Japan', name: 'Aichi, Japan' },
    { id: 'Obwalden, Switzerland', name: 'Obwalden, Switzerland' },
    {
        id: 'Provincia de El Oro, Ecuador',
        name: 'Provincia de El Oro, Ecuador'
    },
    { id: 'Khenchela, Algeria', name: 'Khenchela, Algeria' },
    {
        id: 'Provincia del Pastaza, Ecuador',
        name: 'Provincia del Pastaza, Ecuador'
    },
    { id: 'Alibori, Benin', name: 'Alibori, Benin' },
    { id: 'Ordu, Turkey', name: 'Ordu, Turkey' },
    { id: 'Erzincan, Turkey', name: 'Erzincan, Turkey' },
    {
        id: 'Trashigang Dzongkhag, Bhutan',
        name: 'Trashigang Dzongkhag, Bhutan'
    },
    {
        id: 'Brighton and Hove, United Kingdom',
        name: 'Brighton and Hove, United Kingdom'
    },
    {
        id: 'Jeollanam-do, Republic of Korea',
        name: 'Jeollanam-do, Republic of Korea'
    },
    { id: 'Western Darfur, Sudan', name: 'Western Darfur, Sudan' },
    { id: 'Al Aḩmadī, Kuwait', name: 'Al Aḩmadī, Kuwait' },
    { id: 'Obcina Menges, Slovenia', name: 'Obcina Menges, Slovenia' },
    {
        id: 'Saint Croix Island, U.S. Virgin Islands',
        name: 'Saint Croix Island, U.S. Virgin Islands'
    },
    {
        id: 'Berry Islands District, Bahamas',
        name: 'Berry Islands District, Bahamas'
    },
    {
        id: 'Derry City and Strabane, United Kingdom',
        name: 'Derry City and Strabane, United Kingdom'
    },
    {
        id: 'Unitatea Teritoriala din Stinga Nistrului, Republic of Moldova',
        name: 'Unitatea Teritoriala din Stinga Nistrului, Republic of Moldova'
    },
    { id: 'Iz-Zejtun, Malta', name: 'Iz-Zejtun, Malta' },
    { id: 'Mayo-Kebbi Ouest, Chad', name: 'Mayo-Kebbi Ouest, Chad' },
    {
        id: 'Provincia de San Pedro de Macoris, Dominican Republic',
        name: 'Provincia de San Pedro de Macoris, Dominican Republic'
    },
    { id: 'Şa‘dah, Yemen', name: 'Şa‘dah, Yemen' },
    { id: 'Norfolk, United Kingdom', name: 'Norfolk, United Kingdom' },
    {
        id: 'Chaharmahal and Bakhtiari, Iran',
        name: 'Chaharmahal and Bakhtiari, Iran'
    },
    {
        id: 'Provincia de Bolivar, Ecuador',
        name: 'Provincia de Bolivar, Ecuador'
    },
    {
        id: 'Provincia di Genova, Italy',
        name: 'Provincia di Genova, Italy'
    },
    { id: 'Hampshire, United Kingdom', name: 'Hampshire, United Kingdom' },
    { id: 'Achaea, Greece', name: 'Achaea, Greece' },
    { id: 'Phnom Penh, Cambodia', name: 'Phnom Penh, Cambodia' },
    { id: 'Soriano, Uruguay', name: 'Soriano, Uruguay' },
    { id: 'Ventspils, Latvia', name: 'Ventspils, Latvia' },
    { id: 'Goias, Brazil', name: 'Goias, Brazil' },
    { id: 'Oslo County, Norway', name: 'Oslo County, Norway' },
    { id: 'Jičín District, Czechia', name: 'Jičín District, Czechia' },
    { id: 'Singida, Tanzania', name: 'Singida, Tanzania' },
    { id: 'Khoueng Phongsali, Laos', name: 'Khoueng Phongsali, Laos' },
    { id: 'Thurrock, United Kingdom', name: 'Thurrock, United Kingdom' },
    {
        id: 'Province of Tarlac, Philippines',
        name: 'Province of Tarlac, Philippines'
    },
    {
        id: 'Rrethi i Shkodres, Albania',
        name: 'Rrethi i Shkodres, Albania'
    },
    { id: 'Burgos, Spain', name: 'Burgos, Spain' },
    { id: 'Malatya, Turkey', name: 'Malatya, Turkey' },
    {
        id: 'East Berbice-Corentyne Region, Guyana',
        name: 'East Berbice-Corentyne Region, Guyana'
    },
    {
        id: 'Barima-Waini Region, Guyana',
        name: 'Barima-Waini Region, Guyana'
    },
    { id: 'Saulkrastu Novads, Latvia', name: 'Saulkrastu Novads, Latvia' },
    { id: 'Yazd, Iran', name: 'Yazd, Iran' },
    { id: 'Jiwaka, Papua New Guinea', name: 'Jiwaka, Papua New Guinea' },
    { id: 'Seville, Spain', name: 'Seville, Spain' },
    { id: "Fa'asaleleaga, Samoa", name: "Fa'asaleleaga, Samoa" },
    { id: 'Funafuti, Tuvalu', name: 'Funafuti, Tuvalu' },
    { id: 'Mila, Algeria', name: 'Mila, Algeria' },
    {
        id: 'Changwat Amnat Charoen, Thailand',
        name: 'Changwat Amnat Charoen, Thailand'
    },
    {
        id: 'Islands District, Hong Kong',
        name: 'Islands District, Hong Kong'
    },
    { id: 'Ostrobothnia, Finland', name: 'Ostrobothnia, Finland' },
    { id: 'Zealand, Denmark', name: 'Zealand, Denmark' },
    { id: 'Pontevedra, Spain', name: 'Pontevedra, Spain' },
    {
        id: 'Brčko, Bosnia and Herzegovina',
        name: 'Brčko, Bosnia and Herzegovina'
    },
    { id: 'Oshana, Namibia', name: 'Oshana, Namibia' },
    { id: 'Maale, Maldives', name: 'Maale, Maldives' },
    {
        id: 'Pakruojis District Municipality, Republic of Lithuania',
        name: 'Pakruojis District Municipality, Republic of Lithuania'
    },
    { id: 'Guadalajara, Spain', name: 'Guadalajara, Spain' },
    { id: "Tverskaya Oblast', Russia", name: "Tverskaya Oblast', Russia" },
    { id: 'Zanjan, Iran', name: 'Zanjan, Iran' },
    { id: 'Cayo District, Belize', name: 'Cayo District, Belize' },
    { id: 'Bafata, Guinea-Bissau', name: 'Bafata, Guinea-Bissau' },
    {
        id: 'Zadarska Zupanija, Croatia',
        name: 'Zadarska Zupanija, Croatia'
    },
    {
        id: 'Hodh ech Chargui, Mauritania',
        name: 'Hodh ech Chargui, Mauritania'
    },
    { id: 'Valais, Switzerland', name: 'Valais, Switzerland' },
    { id: 'Marlborough, New Zealand', name: 'Marlborough, New Zealand' },
    { id: 'Jiangsu, China', name: 'Jiangsu, China' },
    {
        id: 'Saint James Windward, St Kitts and Nevis',
        name: 'Saint James Windward, St Kitts and Nevis'
    },
    { id: 'Nakuru District, Kenya', name: 'Nakuru District, Kenya' },
    {
        id: 'Svalbard, Svalbard and Jan Mayen',
        name: 'Svalbard, Svalbard and Jan Mayen'
    },
    {
        id: 'Matabeleland North, Zimbabwe',
        name: 'Matabeleland North, Zimbabwe'
    },
    {
        id: 'Lovrenc na Pohorju, Slovenia',
        name: 'Lovrenc na Pohorju, Slovenia'
    },
    { id: 'Constanta, Romania', name: 'Constanta, Romania' },
    {
        id: 'East Sussex, United Kingdom',
        name: 'East Sussex, United Kingdom'
    },
    { id: 'North-West, Botswana', name: 'North-West, Botswana' },
    {
        id: "Yaroslavskaya Oblast', Russia",
        name: "Yaroslavskaya Oblast', Russia"
    },
    {
        id: 'Departamento de Moquegua, Peru',
        name: 'Departamento de Moquegua, Peru'
    },
    { id: 'Mazovia, Poland', name: 'Mazovia, Poland' },
    {
        id: 'Manchester, United Kingdom',
        name: 'Manchester, United Kingdom'
    },
    { id: 'Muhafazat Babil, Iraq', name: 'Muhafazat Babil, Iraq' },
    { id: 'Region de Thies, Senegal', name: 'Region de Thies, Senegal' },
    {
        id: 'Departamento de Rio San Juan, Nicaragua',
        name: 'Departamento de Rio San Juan, Nicaragua'
    },
    { id: "Hawke's Bay, New Zealand", name: "Hawke's Bay, New Zealand" },
    { id: 'Mongar, Bhutan', name: 'Mongar, Bhutan' },
    { id: 'Kep, Cambodia', name: 'Kep, Cambodia' },
    {
        id: 'Jász-Nagykun-Szolnok, Hungary',
        name: 'Jász-Nagykun-Szolnok, Hungary'
    },
    {
        id: 'Provincia de Hermanas Mirabal, Dominican Republic',
        name: 'Provincia de Hermanas Mirabal, Dominican Republic'
    },
    {
        id: 'Central Kalimantan, Indonesia',
        name: 'Central Kalimantan, Indonesia'
    },
    { id: 'Provincia di Lecce, Italy', name: 'Provincia di Lecce, Italy' },
    { id: 'Tinh Ca Mau, Vietnam', name: 'Tinh Ca Mau, Vietnam' },
    {
        id: 'Basarabeasca, Republic of Moldova',
        name: 'Basarabeasca, Republic of Moldova'
    },
    { id: 'Ituri, Congo', name: 'Ituri, Congo' },
    { id: 'Provincia di Lecco, Italy', name: 'Provincia di Lecco, Italy' },
    { id: 'Callao, Peru', name: 'Callao, Peru' },
    { id: 'Maharashtra, India', name: 'Maharashtra, India' },
    { id: 'Tinh Nghe An, Vietnam', name: 'Tinh Nghe An, Vietnam' },
    {
        id: 'Province du Seno, Burkina Faso',
        name: 'Province du Seno, Burkina Faso'
    },
    { id: 'Port Glaud, Seychelles', name: 'Port Glaud, Seychelles' },
    { id: 'Praha 20, Czechia', name: 'Praha 20, Czechia' },
    { id: 'Praha 21, Czechia', name: 'Praha 21, Czechia' },
    { id: 'Morava, Serbia', name: 'Morava, Serbia' },
    {
        id: 'Inverclyde, United Kingdom',
        name: 'Inverclyde, United Kingdom'
    },
    { id: 'Woroba, Ivory Coast', name: 'Woroba, Ivory Coast' },
    { id: 'Buskerud, Norway', name: 'Buskerud, Norway' },
    { id: 'Dhofar, Oman', name: 'Dhofar, Oman' },
    { id: 'Obcina Turnisce, Slovenia', name: 'Obcina Turnisce, Slovenia' },
    { id: 'Kirundo Province, Burundi', name: 'Kirundo Province, Burundi' },
    { id: 'Haz-Zabbar, Malta', name: 'Haz-Zabbar, Malta' },
    { id: 'Smiltenes Novads, Latvia', name: 'Smiltenes Novads, Latvia' },
    { id: 'Settat Province, Morocco', name: 'Settat Province, Morocco' },
    {
        id: 'Province of Surigao del Norte, Philippines',
        name: 'Province of Surigao del Norte, Philippines'
    },
    {
        id: 'Gouvernorat de Sousse, Tunisia',
        name: 'Gouvernorat de Sousse, Tunisia'
    },
    { id: 'Shimane, Japan', name: 'Shimane, Japan' },
    { id: 'Maramureş, Romania', name: 'Maramureş, Romania' },
    {
        id: 'Provincia di Cagliari, Italy',
        name: 'Provincia di Cagliari, Italy'
    },
    {
        id: 'West Kalimantan, Indonesia',
        name: 'West Kalimantan, Indonesia'
    },
    { id: 'Dnipro, Ukraine', name: 'Dnipro, Ukraine' },
    {
        id: 'Taninthayi Region, Myanmar [Burma]',
        name: 'Taninthayi Region, Myanmar [Burma]'
    },
    {
        id: 'District de Luxembourg, Luxembourg',
        name: 'District de Luxembourg, Luxembourg'
    },
    { id: 'Tábor District, Czechia', name: 'Tábor District, Czechia' },
    {
        id: 'Changwat Chaiyaphum, Thailand',
        name: 'Changwat Chaiyaphum, Thailand'
    },
    { id: 'Ljubno, Slovenia', name: 'Ljubno, Slovenia' },
    { id: 'Trbovlje, Slovenia', name: 'Trbovlje, Slovenia' },
    { id: 'Gwynedd, United Kingdom', name: 'Gwynedd, United Kingdom' },
    { id: 'Elazığ, Turkey', name: 'Elazığ, Turkey' },
    {
        id: 'Provincie Overijssel, Netherlands',
        name: 'Provincie Overijssel, Netherlands'
    },
    {
        id: 'Departamento de Usulutan, El Salvador',
        name: 'Departamento de Usulutan, El Salvador'
    },
    {
        id: 'Provincia di Caserta, Italy',
        name: 'Provincia di Caserta, Italy'
    },
    {
        id: 'Provincia di Macerata, Italy',
        name: 'Provincia di Macerata, Italy'
    },
    { id: 'Il-Munxar, Malta', name: 'Il-Munxar, Malta' },
    { id: 'Miyagi, Japan', name: 'Miyagi, Japan' },
    { id: 'Bayburt, Turkey', name: 'Bayburt, Turkey' },
    { id: 'Raa Atoll, Maldives', name: 'Raa Atoll, Maldives' },
    { id: 'Kotor, Montenegro', name: 'Kotor, Montenegro' },
    { id: 'Tas-Sliema, Malta', name: 'Tas-Sliema, Malta' },
    { id: 'Woleu-Ntem, Gabon', name: 'Woleu-Ntem, Gabon' },
    { id: 'Abruzzo, Italy', name: 'Abruzzo, Italy' },
    {
        id: 'Leicestershire, United Kingdom',
        name: 'Leicestershire, United Kingdom'
    },
    {
        id: 'Parish of Saint David, Saint Vincent and the Grenadines',
        name: 'Parish of Saint David, Saint Vincent and the Grenadines'
    },
    { id: 'Battambang, Cambodia', name: 'Battambang, Cambodia' },
    { id: 'Pemba North, Tanzania', name: 'Pemba North, Tanzania' },
    { id: 'Île-de-France, France', name: 'Île-de-France, France' },
    { id: 'Terengganu, Malaysia', name: 'Terengganu, Malaysia' },
    { id: 'Tailevu Province, Fiji', name: 'Tailevu Province, Fiji' },
    { id: 'Obock, Djibouti', name: 'Obock, Djibouti' },
    { id: 'Marne, France', name: 'Marne, France' },
    {
        id: 'Provincia di La Spezia, Italy',
        name: 'Provincia di La Spezia, Italy'
    },
    { id: 'Osmaniye, Turkey', name: 'Osmaniye, Turkey' },
    { id: 'Batna, Algeria', name: 'Batna, Algeria' },
    {
        id: 'Obcina Loska Dolina, Slovenia',
        name: 'Obcina Loska Dolina, Slovenia'
    },
    {
        id: 'Muhafazat Hadramawt, Yemen',
        name: 'Muhafazat Hadramawt, Yemen'
    },
    { id: 'River Gee County, Liberia', name: 'River Gee County, Liberia' },
    { id: "Valle d'Aosta, Italy", name: "Valle d'Aosta, Italy" },
    { id: 'L-Ghasri, Malta', name: 'L-Ghasri, Malta' },
    { id: 'Woqooyi Galbeed, Somalia', name: 'Woqooyi Galbeed, Somalia' },
    { id: 'Iz-Zebbug, Malta', name: 'Iz-Zebbug, Malta' },
    { id: 'Mwanza, Tanzania', name: 'Mwanza, Tanzania' },
    { id: 'Belgrade, Serbia', name: 'Belgrade, Serbia' },
    { id: 'Perm Krai, Russia', name: 'Perm Krai, Russia' },
    {
        id: 'Luxembourg Province, Belgium',
        name: 'Luxembourg Province, Belgium'
    },
    { id: 'Aḑ Ḑāli‘, Yemen', name: 'Aḑ Ḑāli‘, Yemen' },
    {
        id: 'Departamento de Cordoba, Colombia',
        name: 'Departamento de Cordoba, Colombia'
    },
    {
        id: 'Parish of Saint Paul, Antigua and Barbuda',
        name: 'Parish of Saint Paul, Antigua and Barbuda'
    },
    { id: 'Knowsley, United Kingdom', name: 'Knowsley, United Kingdom' },
    { id: 'Pabna, Bangladesh', name: 'Pabna, Bangladesh' },
    {
        id: 'Escaldes-Engordany, Andorra',
        name: 'Escaldes-Engordany, Andorra'
    },
    { id: 'Ulcinj, Montenegro', name: 'Ulcinj, Montenegro' },
    { id: 'Saint Paul, Dominica', name: 'Saint Paul, Dominica' },
    { id: 'Nomos Zakynthou, Greece', name: 'Nomos Zakynthou, Greece' },
    {
        id: 'Changwat Maha Sarakham, Thailand',
        name: 'Changwat Maha Sarakham, Thailand'
    },
    { id: 'Chaco, Argentina', name: 'Chaco, Argentina' },
    {
        id: 'River Cess County, Liberia',
        name: 'River Cess County, Liberia'
    },
    { id: 'Vipava, Slovenia', name: 'Vipava, Slovenia' },
    { id: 'Lääne-Virumaa, Estonia', name: 'Lääne-Virumaa, Estonia' },
    { id: 'Stung Treng, Cambodia', name: 'Stung Treng, Cambodia' },
    { id: 'Obcina Divaca, Slovenia', name: 'Obcina Divaca, Slovenia' },
    {
        id: 'Mato Grosso do Sul, Brazil',
        name: 'Mato Grosso do Sul, Brazil'
    },
    {
        id: 'Ostan-e Azarbayjan-e Gharbi, Iran',
        name: 'Ostan-e Azarbayjan-e Gharbi, Iran'
    },
    {
        id: 'Province of Leyte, Philippines',
        name: 'Province of Leyte, Philippines'
    },
    { id: 'Maio, Cabo Verde', name: 'Maio, Cabo Verde' },
    {
        id: 'Gouvernorat de Kairouan, Tunisia',
        name: 'Gouvernorat de Kairouan, Tunisia'
    },
    { id: 'Naples, Italy', name: 'Naples, Italy' },
    {
        id: 'Western Cape, South Africa',
        name: 'Western Cape, South Africa'
    },
    { id: 'Nomos Argolidos, Greece', name: 'Nomos Argolidos, Greece' },
    { id: 'Istria, Croatia', name: 'Istria, Croatia' },
    {
        id: 'Provincia di Bergamo, Italy',
        name: 'Provincia di Bergamo, Italy'
    },
    { id: 'Hormozgan, Iran', name: 'Hormozgan, Iran' },
    { id: 'Praha 3, Czechia', name: 'Praha 3, Czechia' },
    { id: 'Okinawa, Japan', name: 'Okinawa, Japan' },
    {
        id: 'Aqaba, Hashemite Kingdom of Jordan',
        name: 'Aqaba, Hashemite Kingdom of Jordan'
    },
    { id: 'Sergipe, Brazil', name: 'Sergipe, Brazil' },
    {
        id: 'Provincia di Reggio Emilia, Italy',
        name: 'Provincia di Reggio Emilia, Italy'
    },
    { id: 'Saint James, Jamaica', name: 'Saint James, Jamaica' },
    {
        id: 'Licko-Senjska Zupanija, Croatia',
        name: 'Licko-Senjska Zupanija, Croatia'
    },
    { id: 'Ma’rib, Yemen', name: 'Ma’rib, Yemen' },
    { id: 'County Monaghan, Ireland', name: 'County Monaghan, Ireland' },
    { id: 'Teleorman, Romania', name: 'Teleorman, Romania' },
    { id: 'Somme, France', name: 'Somme, France' },
    {
        id: 'Pyrénées-Atlantiques, France',
        name: 'Pyrénées-Atlantiques, France'
    },
    { id: 'Limassol, Cyprus', name: 'Limassol, Cyprus' },
    { id: 'Nagano, Japan', name: 'Nagano, Japan' },
    { id: 'Himachal Pradesh, India', name: 'Himachal Pradesh, India' },
    { id: 'Équateur, Congo', name: 'Équateur, Congo' },
    { id: 'Chiapas, Mexico', name: 'Chiapas, Mexico' },
    { id: 'Saône-et-Loire, France', name: 'Saône-et-Loire, France' },
    { id: 'Nueva Esparta, Venezuela', name: 'Nueva Esparta, Venezuela' },
    { id: 'Styria, Austria', name: 'Styria, Austria' },
    { id: 'Constantine, Algeria', name: 'Constantine, Algeria' },
    { id: 'Manitoba, Canada', name: 'Manitoba, Canada' },
    { id: 'Atacama, Chile', name: 'Atacama, Chile' },
    { id: 'Metlika, Slovenia', name: 'Metlika, Slovenia' },
    {
        id: 'Telšiai District Municipality, Republic of Lithuania',
        name: 'Telšiai District Municipality, Republic of Lithuania'
    },
    {
        id: 'Joniškis District Municipality, Republic of Lithuania',
        name: 'Joniškis District Municipality, Republic of Lithuania'
    },
    { id: 'Cher, France', name: 'Cher, France' },
    { id: 'Portuguesa, Venezuela', name: 'Portuguesa, Venezuela' },
    { id: 'Tillaberi Region, Niger', name: 'Tillaberi Region, Niger' },
    { id: 'Obcina Crnomelj, Slovenia', name: 'Obcina Crnomelj, Slovenia' },
    {
        id: 'Logone Occidental Region, Chad',
        name: 'Logone Occidental Region, Chad'
    },
    { id: 'Lualaba, Congo', name: 'Lualaba, Congo' },
    {
        id: "Sha'biyat an Nuqat al Khams, Libya",
        name: "Sha'biyat an Nuqat al Khams, Libya"
    },
    { id: 'Naryn oblast, Kyrgyzstan', name: 'Naryn oblast, Kyrgyzstan' },
    { id: 'Dalarna, Sweden', name: 'Dalarna, Sweden' },
    { id: 'Suffolk, United Kingdom', name: 'Suffolk, United Kingdom' },
    {
        id: 'Departamento de Yoro, Honduras',
        name: 'Departamento de Yoro, Honduras'
    },
    {
        id: 'Aizkraukles Rajons, Latvia',
        name: 'Aizkraukles Rajons, Latvia'
    },
    { id: 'Pernik, Bulgaria', name: 'Pernik, Bulgaria' },
    { id: 'Northeast, Iceland', name: 'Northeast, Iceland' },
    {
        id: 'Province of Vibo Valentia, Italy',
        name: 'Province of Vibo Valentia, Italy'
    },
    {
        id: 'Fermanagh and Omagh, United Kingdom',
        name: 'Fermanagh and Omagh, United Kingdom'
    },
    {
        id: "Hai'l Region, Saudi Arabia",
        name: "Hai'l Region, Saudi Arabia"
    },
    {
        id: 'Province of Lanao del Sur, Philippines',
        name: 'Province of Lanao del Sur, Philippines'
    },
    { id: 'Seine-et-Marne, France', name: 'Seine-et-Marne, France' },
    { id: 'Marsaxlokk, Malta', name: 'Marsaxlokk, Malta' },
    {
        id: 'Agalega Islands, Mauritius',
        name: 'Agalega Islands, Mauritius'
    },
    { id: 'Adiyaman, Turkey', name: 'Adiyaman, Turkey' },
    {
        id: 'Viana do Castelo, Portugal',
        name: 'Viana do Castelo, Portugal'
    },
    {
        id: 'Changwat Khon Kaen, Thailand',
        name: 'Changwat Khon Kaen, Thailand'
    },
    { id: 'Kent, United Kingdom', name: 'Kent, United Kingdom' },
    { id: 'Judetul Cluj, Romania', name: 'Judetul Cluj, Romania' },
    { id: 'Rio Negro, Argentina', name: 'Rio Negro, Argentina' },
    { id: 'Obcina Straza, Slovenia', name: 'Obcina Straza, Slovenia' },
    {
        id: 'Bumthang Dzongkhag, Bhutan',
        name: 'Bumthang Dzongkhag, Bhutan'
    },
    { id: 'Bushehr, Iran', name: 'Bushehr, Iran' },
    { id: 'Kon Tum, Vietnam', name: 'Kon Tum, Vietnam' },
    { id: 'Noonu Atoll, Maldives', name: 'Noonu Atoll, Maldives' },
    {
        id: 'Departamento de Montevideo, Uruguay',
        name: 'Departamento de Montevideo, Uruguay'
    },
    { id: 'East Gobi Aymag, Mongolia', name: 'East Gobi Aymag, Mongolia' },
    {
        id: 'Provincia Duarte, Dominican Republic',
        name: 'Provincia Duarte, Dominican Republic'
    },
    {
        id: 'Departamento de El Paraiso, Honduras',
        name: 'Departamento de El Paraiso, Honduras'
    },
    { id: 'Kiambu District, Kenya', name: 'Kiambu District, Kenya' },
    { id: "Amurskaya Oblast', Russia", name: "Amurskaya Oblast', Russia" },
    { id: 'Grand Kru County, Liberia', name: 'Grand Kru County, Liberia' },
    { id: 'Wrexham, United Kingdom', name: 'Wrexham, United Kingdom' },
    { id: 'Ourense, Spain', name: 'Ourense, Spain' },
    {
        id: 'Zhemgang Dzongkhag, Bhutan',
        name: 'Zhemgang Dzongkhag, Bhutan'
    },
    { id: 'Saint Andrew, Grenada', name: 'Saint Andrew, Grenada' },
    { id: 'Pirot, Serbia', name: 'Pirot, Serbia' },
    { id: 'Torba Province, Vanuatu', name: 'Torba Province, Vanuatu' },
    { id: 'Gornji Petrovci, Slovenia', name: 'Gornji Petrovci, Slovenia' },
    { id: 'Lusaka Province, Zambia', name: 'Lusaka Province, Zambia' },
    { id: "Qacha's Nek, Lesotho", name: "Qacha's Nek, Lesotho" },
    { id: 'Otago, New Zealand', name: 'Otago, New Zealand' },
    { id: 'Khemisset, Morocco', name: 'Khemisset, Morocco' },
    { id: 'Ağrı, Turkey', name: 'Ağrı, Turkey' },
    { id: 'Beheira, Egypt', name: 'Beheira, Egypt' },
    { id: 'Takamaka, Seychelles', name: 'Takamaka, Seychelles' },
    { id: 'Guizhou, China', name: 'Guizhou, China' },
    { id: 'Wirral, United Kingdom', name: 'Wirral, United Kingdom' },
    { id: 'Cordillera, Philippines', name: 'Cordillera, Philippines' },
    { id: 'Judetul Bacau, Romania', name: 'Judetul Bacau, Romania' },
    { id: 'Kranj, Slovenia', name: 'Kranj, Slovenia' },
    {
        id: 'San Juan/Laventille, Trinidad and Tobago',
        name: 'San Juan/Laventille, Trinidad and Tobago'
    },
    { id: 'Trzin, Slovenia', name: 'Trzin, Slovenia' },
    { id: 'Třebíč District, Czechia', name: 'Třebíč District, Czechia' },
    {
        id: 'Departamento de Santa Ana, El Salvador',
        name: 'Departamento de Santa Ana, El Salvador'
    },
    {
        id: "Lipetskaya Oblast', Russia",
        name: "Lipetskaya Oblast', Russia"
    },
    { id: 'Sikasso, Mali', name: 'Sikasso, Mali' },
    { id: 'Apure, Venezuela', name: 'Apure, Venezuela' },
    { id: 'Kosice, Slovakia', name: 'Kosice, Slovakia' },
    { id: 'Minas Gerais, Brazil', name: 'Minas Gerais, Brazil' },
    {
        id: 'Black River District, Mauritius',
        name: 'Black River District, Mauritius'
    },
    {
        id: "Irkutskaya Oblast', Russia",
        name: "Irkutskaya Oblast', Russia"
    },
    {
        id: 'Departamento de Presidente Hayes, Paraguay',
        name: 'Departamento de Presidente Hayes, Paraguay'
    },
    { id: 'Chagang-do, North Korea', name: 'Chagang-do, North Korea' },
    {
        id: 'Sor-Trondelag Fylke, Norway',
        name: 'Sor-Trondelag Fylke, Norway'
    },
    { id: 'San Juan, Argentina', name: 'San Juan, Argentina' },
    { id: "Sha'biyat Ghat, Libya", name: "Sha'biyat Ghat, Libya" },
    {
        id: "Sha'biyat Wadi al Hayat, Libya",
        name: "Sha'biyat Wadi al Hayat, Libya"
    },
    { id: 'Karelia, Russia', name: 'Karelia, Russia' },
    {
        id: 'Province of Belluno, Italy',
        name: 'Province of Belluno, Italy'
    },
    {
        id: 'Respublika Ingushetiya, Russia',
        name: 'Respublika Ingushetiya, Russia'
    },
    { id: 'Anambra, Nigeria', name: 'Anambra, Nigeria' },
    { id: 'Okayama, Japan', name: 'Okayama, Japan' },
    { id: 'Hal Ghaxaq, Malta', name: 'Hal Ghaxaq, Malta' },
    {
        id: 'Obcina Skofljica, Slovenia',
        name: 'Obcina Skofljica, Slovenia'
    },
    {
        id: 'Provincia di Pescara, Italy',
        name: 'Provincia di Pescara, Italy'
    },
    { id: 'Shirak, Armenia', name: 'Shirak, Armenia' },
    { id: 'Kırklareli, Turkey', name: 'Kırklareli, Turkey' },
    { id: 'Cirkulane, Slovenia', name: 'Cirkulane, Slovenia' },
    { id: 'Goygol Rayon, Azerbaijan', name: 'Goygol Rayon, Azerbaijan' },
    { id: 'Västmanland, Sweden', name: 'Västmanland, Sweden' },
    { id: 'Judetul Alba, Romania', name: 'Judetul Alba, Romania' },
    { id: 'Distrikt Para, Suriname', name: 'Distrikt Para, Suriname' },
    { id: 'Biombo, Guinea-Bissau', name: 'Biombo, Guinea-Bissau' },
    { id: 'Hebei, China', name: 'Hebei, China' },
    {
        id: 'Southeast Sulawesi, Indonesia',
        name: 'Southeast Sulawesi, Indonesia'
    },
    {
        id: 'Blackburn with Darwen, United Kingdom',
        name: 'Blackburn with Darwen, United Kingdom'
    },
    { id: 'Tissemsilt, Algeria', name: 'Tissemsilt, Algeria' },
    {
        id: 'Changwat Phetchabun, Thailand',
        name: 'Changwat Phetchabun, Thailand'
    },
    { id: 'Schaffhausen, Switzerland', name: 'Schaffhausen, Switzerland' },
    {
        id: 'Southern Governorate, Bahrain',
        name: 'Southern Governorate, Bahrain'
    },
    { id: 'Dundaga, Latvia', name: 'Dundaga, Latvia' },
    { id: 'Leeds, United Kingdom', name: 'Leeds, United Kingdom' },
    { id: 'Uaboe, Nauru', name: 'Uaboe, Nauru' },
    { id: 'Jurmala, Latvia', name: 'Jurmala, Latvia' },
    { id: 'Tombali, Guinea-Bissau', name: 'Tombali, Guinea-Bissau' },
    { id: 'Grandans, Haiti', name: 'Grandans, Haiti' },
    { id: 'Jizan, Saudi Arabia', name: 'Jizan, Saudi Arabia' },
    { id: 'Gevgelija, Macedonia', name: 'Gevgelija, Macedonia' },
    { id: 'Skikda, Algeria', name: 'Skikda, Algeria' },
    { id: 'Lautém, East Timor', name: 'Lautém, East Timor' },
    {
        id: 'Jablonec nad Nisou District, Czechia',
        name: 'Jablonec nad Nisou District, Czechia'
    },
    {
        id: 'Northumberland, United Kingdom',
        name: 'Northumberland, United Kingdom'
    },
    {
        id: 'Obcina Rogaska Slatina, Slovenia',
        name: 'Obcina Rogaska Slatina, Slovenia'
    },
    { id: 'County Leitrim, Ireland', name: 'County Leitrim, Ireland' },
    { id: 'Oppland, Norway', name: 'Oppland, Norway' },
    {
        id: 'West Grand Bahama District, Bahamas',
        name: 'West Grand Bahama District, Bahamas'
    },
    { id: 'Bas Uele, Congo', name: 'Bas Uele, Congo' },
    { id: 'Brest, Belarus', name: 'Brest, Belarus' },
    { id: 'Granada, Spain', name: 'Granada, Spain' },
    {
        id: 'Parish of Charlotte, Saint Vincent and the Grenadines',
        name: 'Parish of Charlotte, Saint Vincent and the Grenadines'
    },
    {
        id: 'Lesser Poland Voivodeship, Poland',
        name: 'Lesser Poland Voivodeship, Poland'
    },
    { id: 'Bihar, India', name: 'Bihar, India' },
    { id: 'Provincia di Lodi, Italy', name: 'Provincia di Lodi, Italy' },
    {
        id: 'Umm al Qaywayn, United Arab Emirates',
        name: 'Umm al Qaywayn, United Arab Emirates'
    },
    {
        id: 'Departamento del Guaviare, Colombia',
        name: 'Departamento del Guaviare, Colombia'
    },
    { id: 'Anzoátegui, Venezuela', name: 'Anzoátegui, Venezuela' },
    {
        id: 'Provincia di Avellino, Italy',
        name: 'Provincia di Avellino, Italy'
    },
    { id: 'Tinh Ha Tinh, Vietnam', name: 'Tinh Ha Tinh, Vietnam' },
    { id: 'Katavi, Tanzania', name: 'Katavi, Tanzania' },
    { id: 'Atyrau Oblysy, Kazakhstan', name: 'Atyrau Oblysy, Kazakhstan' },
    { id: 'Chukotka, Russia', name: 'Chukotka, Russia' },
    { id: 'Pas-de-Calais, France', name: 'Pas-de-Calais, France' },
    { id: 'Tinh Ninh Thuan, Vietnam', name: 'Tinh Ninh Thuan, Vietnam' },
    { id: 'Gedo, Somalia', name: 'Gedo, Somalia' },
    { id: 'Geita, Tanzania', name: 'Geita, Tanzania' },
    { id: 'Konya, Turkey', name: 'Konya, Turkey' },
    {
        id: 'Bangui, Central African Republic',
        name: 'Bangui, Central African Republic'
    },
    {
        id: 'Western Visayas, Philippines',
        name: 'Western Visayas, Philippines'
    },
    { id: 'Machakos District, Kenya', name: 'Machakos District, Kenya' },
    {
        id: 'Provincia di Novara, Italy',
        name: 'Provincia di Novara, Italy'
    },
    {
        id: 'Mbomou, Central African Republic',
        name: 'Mbomou, Central African Republic'
    },
    { id: 'Sumadija, Serbia', name: 'Sumadija, Serbia' },
    { id: 'Bengo Province, Angola', name: 'Bengo Province, Angola' },
    { id: 'Region de Los Rios, Chile', name: 'Region de Los Rios, Chile' },
    { id: 'Anabar, Nauru', name: 'Anabar, Nauru' },
    {
        id: 'Milne Bay Province, Papua New Guinea',
        name: 'Milne Bay Province, Papua New Guinea'
    },
    {
        id: 'Gouvernorat de Kasserine, Tunisia',
        name: 'Gouvernorat de Kasserine, Tunisia'
    },
    { id: 'Kanagawa, Japan', name: 'Kanagawa, Japan' },
    { id: 'Opole Voivodeship, Poland', name: 'Opole Voivodeship, Poland' },
    { id: 'Mirna, Slovenia', name: 'Mirna, Slovenia' },
    {
        id: 'Provincia di Viterbo, Italy',
        name: 'Provincia di Viterbo, Italy'
    },
    { id: 'Koknese, Latvia', name: 'Koknese, Latvia' },
    { id: 'Kwun Tong, Hong Kong', name: 'Kwun Tong, Hong Kong' },
    { id: 'Gansu, China', name: 'Gansu, China' },
    { id: 'Tlaxcala, Mexico', name: 'Tlaxcala, Mexico' },
    { id: 'Loiret, France', name: 'Loiret, France' },
    { id: 'Latium, Italy', name: 'Latium, Italy' },
    {
        id: 'New Providence District, Bahamas',
        name: 'New Providence District, Bahamas'
    },
    {
        id: 'Kgatleng District, Botswana',
        name: 'Kgatleng District, Botswana'
    },
    { id: 'La Massana, Andorra', name: 'La Massana, Andorra' },
    {
        id: 'Provincia Espaillat, Dominican Republic',
        name: 'Provincia Espaillat, Dominican Republic'
    },
    {
        id: 'Northern Province, Sierra Leone',
        name: 'Northern Province, Sierra Leone'
    },
    { id: 'Qashqadaryo, Uzbekistan', name: 'Qashqadaryo, Uzbekistan' },
    { id: 'Surt, Libya', name: 'Surt, Libya' },
    { id: 'Drôme, France', name: 'Drôme, France' },
    {
        id: "Kharkivs'ka Oblast', Ukraine",
        name: "Kharkivs'ka Oblast', Ukraine"
    },
    {
        id: 'Ouham-Pendé, Central African Republic',
        name: 'Ouham-Pendé, Central African Republic'
    },
    { id: 'Castellon, Spain', name: 'Castellon, Spain' },
    {
        id: 'Prachatice District, Czechia',
        name: 'Prachatice District, Czechia'
    },
    {
        id: 'Santiago del Estero, Argentina',
        name: 'Santiago del Estero, Argentina'
    },
    { id: 'Luapula Province, Zambia', name: 'Luapula Province, Zambia' },
    {
        id: 'Shetland Islands, United Kingdom',
        name: 'Shetland Islands, United Kingdom'
    },
    { id: 'Kymenlaakso, Finland', name: 'Kymenlaakso, Finland' },
    { id: 'Coimbra, Portugal', name: 'Coimbra, Portugal' },
    {
        id: 'Sofala Province, Mozambique',
        name: 'Sofala Province, Mozambique'
    },
    { id: 'Ir-Rabat, Malta', name: 'Ir-Rabat, Malta' },
    { id: 'Southern Kordofan, Sudan', name: 'Southern Kordofan, Sudan' },
    {
        id: 'Mid Ulster, United Kingdom',
        name: 'Mid Ulster, United Kingdom'
    },
    {
        id: 'Judetul Dambovita, Romania',
        name: 'Judetul Dambovita, Romania'
    },
    { id: 'Comilla, Bangladesh', name: 'Comilla, Bangladesh' },
    { id: 'Il-Gudja, Malta', name: 'Il-Gudja, Malta' },
    { id: 'Luanda Norte, Angola', name: 'Luanda Norte, Angola' },
    { id: 'County Galway, Ireland', name: 'County Galway, Ireland' },
    {
        id: 'Pardubice District, Czechia',
        name: 'Pardubice District, Czechia'
    },
    {
        id: 'Gouvernorat de Tunis, Tunisia',
        name: 'Gouvernorat de Tunis, Tunisia'
    },
    {
        id: 'Changwat Lop Buri, Thailand',
        name: 'Changwat Lop Buri, Thailand'
    },
    { id: 'Province of Ragusa, Italy', name: 'Province of Ragusa, Italy' },
    { id: 'Khoueng Xekong, Laos', name: 'Khoueng Xekong, Laos' },
    { id: 'Nomos Irakleiou, Greece', name: 'Nomos Irakleiou, Greece' },
    { id: 'Tizi Ouzou, Algeria', name: 'Tizi Ouzou, Algeria' },
    {
        id: 'Rrethi i Lushnjes, Albania',
        name: 'Rrethi i Lushnjes, Albania'
    },
    { id: 'Gagaifomauga, Samoa', name: 'Gagaifomauga, Samoa' },
    { id: 'Saint Joseph, Dominica', name: 'Saint Joseph, Dominica' },
    { id: 'Canakkale, Turkey', name: 'Canakkale, Turkey' },
    {
        id: 'Opstina Probistip, Macedonia',
        name: 'Opstina Probistip, Macedonia'
    },
    { id: 'Tinh Bac Lieu, Vietnam', name: 'Tinh Bac Lieu, Vietnam' },
    { id: 'Perak, Malaysia', name: 'Perak, Malaysia' },
    { id: 'Santa Fe, Argentina', name: 'Santa Fe, Argentina' },
    { id: 'Ndzuwani, Comoros', name: 'Ndzuwani, Comoros' },
    {
        id: 'Krasnodarskiy Kray, Russia',
        name: 'Krasnodarskiy Kray, Russia'
    },
    {
        id: 'Londonderry, United Kingdom',
        name: 'Londonderry, United Kingdom'
    },
    {
        id: 'Latakia Governorate, Syria',
        name: 'Latakia Governorate, Syria'
    },
    {
        id: 'East Ayrshire, United Kingdom',
        name: 'East Ayrshire, United Kingdom'
    },
    { id: 'Lapponia, Finland', name: 'Lapponia, Finland' },
    {
        id: 'Provincia del Guayas, Ecuador',
        name: 'Provincia del Guayas, Ecuador'
    },
    {
        id: 'Guadalcanal Province, Solomon Islands',
        name: 'Guadalcanal Province, Solomon Islands'
    },
    { id: 'Feni, Bangladesh', name: 'Feni, Bangladesh' },
    { id: 'Judetul Timis, Romania', name: 'Judetul Timis, Romania' },
    { id: 'South-East, Botswana', name: 'South-East, Botswana' },
    { id: 'El-Jadida, Morocco', name: 'El-Jadida, Morocco' },
    {
        id: 'Changwat Prachin Buri, Thailand',
        name: 'Changwat Prachin Buri, Thailand'
    },
    { id: 'Somali, Ethiopia', name: 'Somali, Ethiopia' },
    { id: 'Valencia, Spain', name: 'Valencia, Spain' },
    {
        id: "Departement de l'Ouest, Haiti",
        name: "Departement de l'Ouest, Haiti"
    },
    { id: 'Ad Dakhiliyah, Oman', name: 'Ad Dakhiliyah, Oman' },
    {
        id: 'Obcina Trnovska vas, Slovenia',
        name: 'Obcina Trnovska vas, Slovenia'
    },
    { id: "Tomskaya Oblast', Russia", name: "Tomskaya Oblast', Russia" },
    {
        id: 'City of Westminster, United Kingdom',
        name: 'City of Westminster, United Kingdom'
    },
    {
        id: 'Raionul Causeni, Republic of Moldova',
        name: 'Raionul Causeni, Republic of Moldova'
    },
    { id: 'Cairo Governorate, Egypt', name: 'Cairo Governorate, Egypt' },
    { id: 'Brda, Slovenia', name: 'Brda, Slovenia' },
    { id: 'Kolubara, Serbia', name: 'Kolubara, Serbia' },
    { id: 'Nanumea, Tuvalu', name: 'Nanumea, Tuvalu' },
    {
        id: 'Northern Borders, Saudi Arabia',
        name: 'Northern Borders, Saudi Arabia'
    },
    { id: 'Corinthia, Greece', name: 'Corinthia, Greece' },
    { id: 'Kanem Region, Chad', name: 'Kanem Region, Chad' },
    { id: 'Inagua, Bahamas', name: 'Inagua, Bahamas' },
    { id: 'Moyen-Ogooué, Gabon', name: 'Moyen-Ogooué, Gabon' },
    { id: 'Morogoro, Tanzania', name: 'Morogoro, Tanzania' },
    { id: 'Phuket, Thailand', name: 'Phuket, Thailand' },
    {
        id: 'Karak, Hashemite Kingdom of Jordan',
        name: 'Karak, Hashemite Kingdom of Jordan'
    },
    {
        id: 'Departamento de Paraguari, Paraguay',
        name: 'Departamento de Paraguari, Paraguay'
    },
    { id: 'Barcelona, Spain', name: 'Barcelona, Spain' },
    {
        id: 'Kouilou, Republic of the Congo',
        name: 'Kouilou, Republic of the Congo'
    },
    { id: 'Karas, Namibia', name: 'Karas, Namibia' },
    { id: 'Calabarzon, Philippines', name: 'Calabarzon, Philippines' },
    {
        id: 'Sveta Trojica v Slovenskih Goricah, Slovenia',
        name: 'Sveta Trojica v Slovenskih Goricah, Slovenia'
    },
    { id: 'Uusimaa, Finland', name: 'Uusimaa, Finland' },
    { id: 'Entre Rios, Argentina', name: 'Entre Rios, Argentina' },
    { id: 'Hualien, Taiwan', name: 'Hualien, Taiwan' },
    {
        id: 'Oblast Stara Zagora, Bulgaria',
        name: 'Oblast Stara Zagora, Bulgaria'
    },
    {
        id: "Novosibirskaya Oblast', Russia",
        name: "Novosibirskaya Oblast', Russia"
    },
    {
        id: 'Departamento de Olancho, Honduras',
        name: 'Departamento de Olancho, Honduras'
    },
    { id: 'Emilia-Romagna, Italy', name: 'Emilia-Romagna, Italy' },
    {
        id: 'Province of Caltanissetta, Italy',
        name: 'Province of Caltanissetta, Italy'
    },
    {
        id: 'Province of Nueva Vizcaya, Philippines',
        name: 'Province of Nueva Vizcaya, Philippines'
    },
    {
        id: 'Subcarpathian Voivodeship, Poland',
        name: 'Subcarpathian Voivodeship, Poland'
    },
    {
        id: 'City and County of Swansea, United Kingdom',
        name: 'City and County of Swansea, United Kingdom'
    },
    { id: 'Astana Qalasy, Kazakhstan', name: 'Astana Qalasy, Kazakhstan' },
    { id: 'Lagos, Nigeria', name: 'Lagos, Nigeria' },
    {
        id: "Khersons'ka Oblast', Ukraine",
        name: "Khersons'ka Oblast', Ukraine"
    },
    { id: 'Lucerne, Switzerland', name: 'Lucerne, Switzerland' },
    {
        id: 'Česká Lípa District, Czechia',
        name: 'Česká Lípa District, Czechia'
    },
    { id: 'Ig, Slovenia', name: 'Ig, Slovenia' },
    { id: 'Hamburg, Germany', name: 'Hamburg, Germany' },
    { id: 'Prevalje, Slovenia', name: 'Prevalje, Slovenia' },
    { id: 'Gambela, Ethiopia', name: 'Gambela, Ethiopia' },
    { id: 'Laois, Ireland', name: 'Laois, Ireland' },
    {
        id: 'Province de la Comoe, Burkina Faso',
        name: 'Province de la Comoe, Burkina Faso'
    },
    {
        id: 'Changwat Surat Thani, Thailand',
        name: 'Changwat Surat Thani, Thailand'
    },
    { id: 'Kandava, Latvia', name: 'Kandava, Latvia' },
    { id: 'Ribeira Brava, Cabo Verde', name: 'Ribeira Brava, Cabo Verde' },
    { id: 'Enfield, United Kingdom', name: 'Enfield, United Kingdom' },
    { id: 'Chernivtsi, Ukraine', name: 'Chernivtsi, Ukraine' },
    {
        id: 'Departamento de Guatemala, Guatemala',
        name: 'Departamento de Guatemala, Guatemala'
    },
    {
        id: 'Haa Dhaalu Atholhu, Maldives',
        name: 'Haa Dhaalu Atholhu, Maldives'
    },
    {
        id: 'Departamento de Alto Paraguay, Paraguay',
        name: 'Departamento de Alto Paraguay, Paraguay'
    },
    { id: 'Trelawny, Jamaica', name: 'Trelawny, Jamaica' },
    {
        id: 'Obcina Gorisnica, Slovenia',
        name: 'Obcina Gorisnica, Slovenia'
    },
    { id: 'Tinh Binh GJinh, Vietnam', name: 'Tinh Binh GJinh, Vietnam' },
    { id: 'Sirajganj, Bangladesh', name: 'Sirajganj, Bangladesh' },
    {
        id: 'Distrikt Commewijne, Suriname',
        name: 'Distrikt Commewijne, Suriname'
    },
    { id: 'Kumanovo, Macedonia', name: 'Kumanovo, Macedonia' },
    { id: 'Yamalo-Nenets, Russia', name: 'Yamalo-Nenets, Russia' },
    { id: 'Khulna, Bangladesh', name: 'Khulna, Bangladesh' },
    { id: 'Tavush, Armenia', name: 'Tavush, Armenia' },
    { id: 'Narayanganj, Bangladesh', name: 'Narayanganj, Bangladesh' },
    {
        id: 'Quindio Department, Colombia',
        name: 'Quindio Department, Colombia'
    },
    {
        id: 'Departamento de Santa Rosa, Guatemala',
        name: 'Departamento de Santa Rosa, Guatemala'
    },
    { id: 'Gorod Sevastopol, Ukraine', name: 'Gorod Sevastopol, Ukraine' },
    { id: 'Niutao, Tuvalu', name: 'Niutao, Tuvalu' },
    {
        id: 'Provincia di Rimini, Italy',
        name: 'Provincia di Rimini, Italy'
    },
    { id: 'Giresun, Turkey', name: 'Giresun, Turkey' },
    {
        id: 'Agadir-Ida-ou-Tnan, Morocco',
        name: 'Agadir-Ida-ou-Tnan, Morocco'
    },
    { id: 'Niger State, Nigeria', name: 'Niger State, Nigeria' },
    { id: 'Errachidia, Morocco', name: 'Errachidia, Morocco' },
    { id: 'Līgatne, Latvia', name: 'Līgatne, Latvia' },
    { id: 'Oblast Razgrad, Bulgaria', name: 'Oblast Razgrad, Bulgaria' },
    {
        id: 'Province of Iloilo, Philippines',
        name: 'Province of Iloilo, Philippines'
    },
    {
        id: 'Departamento de Alta Verapaz, Guatemala',
        name: 'Departamento de Alta Verapaz, Guatemala'
    },
    { id: 'Changwat Phayao, Thailand', name: 'Changwat Phayao, Thailand' },
    { id: 'Val-de-Marne, France', name: 'Val-de-Marne, France' },
    {
        id: 'Jindřichův Hradec District, Czechia',
        name: 'Jindřichův Hradec District, Czechia'
    },
    { id: 'Rogaland Fylke, Norway', name: 'Rogaland Fylke, Norway' },
    { id: 'Brava, Cabo Verde', name: 'Brava, Cabo Verde' },
    { id: 'Sud-Est, Haiti', name: 'Sud-Est, Haiti' },
    {
        id: 'Provincia di Alessandria, Italy',
        name: 'Provincia di Alessandria, Italy'
    },
    {
        id: 'Provincia de Dajabon, Dominican Republic',
        name: 'Provincia de Dajabon, Dominican Republic'
    },
    {
        id: 'West Sepik Province, Papua New Guinea',
        name: 'West Sepik Province, Papua New Guinea'
    },
    { id: 'Obcina Ziri, Slovenia', name: 'Obcina Ziri, Slovenia' },
    { id: 'Trutnov District, Czechia', name: 'Trutnov District, Czechia' },
    { id: 'South Sinai, Egypt', name: 'South Sinai, Egypt' },
    {
        id: 'Cantemir, Republic of Moldova',
        name: 'Cantemir, Republic of Moldova'
    },
    { id: 'Burdur, Turkey', name: 'Burdur, Turkey' },
    { id: 'Herat, Afghanistan', name: 'Herat, Afghanistan' },
    {
        id: 'Ash Shariqah, United Arab Emirates',
        name: 'Ash Shariqah, United Arab Emirates'
    },
    {
        id: 'Utena District Municipality, Republic of Lithuania',
        name: 'Utena District Municipality, Republic of Lithuania'
    },
    {
        id: "Bryanskaya Oblast', Russia",
        name: "Bryanskaya Oblast', Russia"
    },
    {
        id: 'Departamento de Santa Cruz, Bolivia',
        name: 'Departamento de Santa Cruz, Bolivia'
    },
    { id: 'Savoy, France', name: 'Savoy, France' },
    { id: 'Nymburk District, Czechia', name: 'Nymburk District, Czechia' },
    { id: 'Mara, Tanzania', name: 'Mara, Tanzania' },
    { id: 'Auckland, New Zealand', name: 'Auckland, New Zealand' },
    {
        id: 'Provincia di Rovigo, Italy',
        name: 'Provincia di Rovigo, Italy'
    },
    { id: 'Punjab, India', name: 'Punjab, India' },
    { id: 'Saarland, Germany', name: 'Saarland, Germany' },
    { id: 'Gbarpolu County, Liberia', name: 'Gbarpolu County, Liberia' },
    { id: 'Aloja, Latvia', name: 'Aloja, Latvia' },
    { id: 'Inner Islands, Seychelles', name: 'Inner Islands, Seychelles' },
    {
        id: 'Denbighshire, United Kingdom',
        name: 'Denbighshire, United Kingdom'
    },
    {
        id: 'Region de Kaffrine, Senegal',
        name: 'Region de Kaffrine, Senegal'
    },
    { id: 'Vaslui, Romania', name: 'Vaslui, Romania' },
    { id: 'Berane, Montenegro', name: 'Berane, Montenegro' },
    { id: 'Vienne, France', name: 'Vienne, France' },
    { id: 'Mary, Turkmenistan', name: 'Mary, Turkmenistan' },
    {
        id: 'Region de San Martin, Peru',
        name: 'Region de San Martin, Peru'
    },
    { id: 'Vienna, Austria', name: 'Vienna, Austria' },
    { id: 'Obcina Bled, Slovenia', name: 'Obcina Bled, Slovenia' },
    { id: 'Hainan, China', name: 'Hainan, China' },
    { id: 'Colima, Mexico', name: 'Colima, Mexico' },
    {
        id: "Sha'biyat al Butnan, Libya",
        name: "Sha'biyat al Butnan, Libya"
    },
    { id: 'Tinh Nam GJinh, Vietnam', name: 'Tinh Nam GJinh, Vietnam' },
    {
        id: 'Sant Julià de Loria, Andorra',
        name: 'Sant Julià de Loria, Andorra'
    },
    { id: 'Medea, Algeria', name: 'Medea, Algeria' },
    {
        id: 'Castello di Borgo Maggiore, San Marino',
        name: 'Castello di Borgo Maggiore, San Marino'
    },
    {
        id: 'Saint John Capesterre, St Kitts and Nevis',
        name: 'Saint John Capesterre, St Kitts and Nevis'
    },
    { id: 'Cheb District, Czechia', name: 'Cheb District, Czechia' },
    {
        id: 'Jurbarkas District Municipality, Republic of Lithuania',
        name: 'Jurbarkas District Municipality, Republic of Lithuania'
    },
    { id: 'Savanes, Togo', name: 'Savanes, Togo' },
    { id: 'Nisava, Serbia', name: 'Nisava, Serbia' },
    {
        id: 'Provincia di Ferrara, Italy',
        name: 'Provincia di Ferrara, Italy'
    },
    { id: 'Lambayeque, Peru', name: 'Lambayeque, Peru' },
    { id: 'Oblast Silistra, Bulgaria', name: 'Oblast Silistra, Bulgaria' },
    { id: 'Sonora, Mexico', name: 'Sonora, Mexico' },
    {
        id: 'Bay of Plenty, New Zealand',
        name: 'Bay of Plenty, New Zealand'
    },
    {
        id: 'Provincia di Biella, Italy',
        name: 'Provincia di Biella, Italy'
    },
    { id: 'Ilfov, Romania', name: 'Ilfov, Romania' },
    { id: 'Bengkulu, Indonesia', name: 'Bengkulu, Indonesia' },
    { id: 'Vianden, Luxembourg', name: 'Vianden, Luxembourg' },
    { id: 'Tarxien, Malta', name: 'Tarxien, Malta' },
    {
        id: 'Province du Houet, Burkina Faso',
        name: 'Province du Houet, Burkina Faso'
    },
    { id: 'Bay Islands, Honduras', name: 'Bay Islands, Honduras' },
    { id: 'Monza Brianza, Italy', name: 'Monza Brianza, Italy' },
    { id: 'Cojedes, Venezuela', name: 'Cojedes, Venezuela' },
    { id: 'Mangrove Cay, Bahamas', name: 'Mangrove Cay, Bahamas' },
    {
        id: 'Trakai District Municipality, Republic of Lithuania',
        name: 'Trakai District Municipality, Republic of Lithuania'
    },
    { id: 'Chania, Greece', name: 'Chania, Greece' },
    { id: 'Opstina Kicevo, Macedonia', name: 'Opstina Kicevo, Macedonia' },
    { id: 'Guria, Georgia', name: 'Guria, Georgia' },
    { id: 'Changwat Surin, Thailand', name: 'Changwat Surin, Thailand' },
    {
        id: "Baladiyat az Za'ayin, Qatar",
        name: "Baladiyat az Za'ayin, Qatar"
    },
    { id: 'Lozère, France', name: 'Lozère, France' },
    { id: 'Pahang, Malaysia', name: 'Pahang, Malaysia' },
    { id: 'Nomos Kardhitsas, Greece', name: 'Nomos Kardhitsas, Greece' },
    { id: 'County Offaly, Ireland', name: 'County Offaly, Ireland' },
    {
        id: 'South Kivu Province, Congo',
        name: 'South Kivu Province, Congo'
    },
    { id: 'Taza, Morocco', name: 'Taza, Morocco' },
    { id: 'Benslimane, Morocco', name: 'Benslimane, Morocco' },
    { id: 'Tinh Binh Duong, Vietnam', name: 'Tinh Binh Duong, Vietnam' },
    {
        id: "Tambovskaya Oblast', Russia",
        name: "Tambovskaya Oblast', Russia"
    },
    {
        id: 'Departamento de Caldas, Colombia',
        name: 'Departamento de Caldas, Colombia'
    },
    { id: 'County Donegal, Ireland', name: 'County Donegal, Ireland' },
    {
        id: 'Raionul Ocniţa, Republic of Moldova',
        name: 'Raionul Ocniţa, Republic of Moldova'
    },
    { id: 'Cumbria, United Kingdom', name: 'Cumbria, United Kingdom' },
    { id: 'Jijel, Algeria', name: 'Jijel, Algeria' },
    {
        id: 'Provincia de Cartago, Costa Rica',
        name: 'Provincia de Cartago, Costa Rica'
    },
    {
        id: 'Grand Port District, Mauritius',
        name: 'Grand Port District, Mauritius'
    },
    { id: 'Tinh Lam GJong, Vietnam', name: 'Tinh Lam GJong, Vietnam' },
    { id: 'Guangdong, China', name: 'Guangdong, China' },
    { id: 'Kladno District, Czechia', name: 'Kladno District, Czechia' },
    { id: 'West Coast, New Zealand', name: 'West Coast, New Zealand' },
    { id: 'Nuevo León, Mexico', name: 'Nuevo León, Mexico' },
    { id: 'ʻEua, Tonga', name: 'ʻEua, Tonga' },
    {
        id: 'East Lothian, United Kingdom',
        name: 'East Lothian, United Kingdom'
    },
    {
        id: 'Okres Ostrava-Mesto, Czechia',
        name: 'Okres Ostrava-Mesto, Czechia'
    },
    {
        id: 'Western Province, Sri Lanka',
        name: 'Western Province, Sri Lanka'
    },
    {
        id: 'Rajshahi Division, Bangladesh',
        name: 'Rajshahi Division, Bangladesh'
    },
    {
        id: 'Manawatu-Wanganui, New Zealand',
        name: 'Manawatu-Wanganui, New Zealand'
    },
    {
        id: 'Gouvernorat de Kef, Tunisia',
        name: 'Gouvernorat de Kef, Tunisia'
    },
    {
        id: 'Gouvernorat de Kebili, Tunisia',
        name: 'Gouvernorat de Kebili, Tunisia'
    },
    {
        id: 'Changwat Nakhon Phanom, Thailand',
        name: 'Changwat Nakhon Phanom, Thailand'
    },
    {
        id: 'East Kazakhstan, Kazakhstan',
        name: 'East Kazakhstan, Kazakhstan'
    },
    { id: 'Atua, Samoa', name: 'Atua, Samoa' },
    { id: 'Ségou, Mali', name: 'Ségou, Mali' },
    { id: 'Slovenj Gradec, Slovenia', name: 'Slovenj Gradec, Slovenia' },
    { id: 'Preah Sihanouk, Cambodia', name: 'Preah Sihanouk, Cambodia' },
    {
        id: 'Redcar and Cleveland, United Kingdom',
        name: 'Redcar and Cleveland, United Kingdom'
    },
    {
        id: 'Gorno-Badakhshan, Tajikistan',
        name: 'Gorno-Badakhshan, Tajikistan'
    },
    { id: 'Chiayi, Taiwan', name: 'Chiayi, Taiwan' },
    { id: 'Srem, Serbia', name: 'Srem, Serbia' },
    {
        id: 'Talsi Municipality, Latvia',
        name: 'Talsi Municipality, Latvia'
    },
    { id: 'Santarém, Portugal', name: 'Santarém, Portugal' },
    {
        id: 'Saint Paul Capesterre, St Kitts and Nevis',
        name: 'Saint Paul Capesterre, St Kitts and Nevis'
    },
    { id: 'Herceg Novi, Montenegro', name: 'Herceg Novi, Montenegro' },
    { id: 'Shabwah, Yemen', name: 'Shabwah, Yemen' },
    { id: 'Assam, India', name: 'Assam, India' },
    { id: 'Santa Cruz, Argentina', name: 'Santa Cruz, Argentina' },
    { id: 'Lebap, Turkmenistan', name: 'Lebap, Turkmenistan' },
    { id: 'Vaud, Switzerland', name: 'Vaud, Switzerland' },
    {
        id: 'Province of Palermo, Italy',
        name: 'Province of Palermo, Italy'
    },
    { id: 'Tinh Quang Ngai, Vietnam', name: 'Tinh Quang Ngai, Vietnam' },
    { id: 'Pwani, Tanzania', name: 'Pwani, Tanzania' },
    { id: 'Thimphu Dzongkhag, Bhutan', name: 'Thimphu Dzongkhag, Bhutan' },
    {
        id: 'Collines Department, Benin',
        name: 'Collines Department, Benin'
    },
    { id: 'Ha`apai, Tonga', name: 'Ha`apai, Tonga' },
    { id: 'Benešov District, Czechia', name: 'Benešov District, Czechia' },
    { id: 'Mono, Benin', name: 'Mono, Benin' },
    {
        id: 'Mohafazat Liban-Nord, Lebanon',
        name: 'Mohafazat Liban-Nord, Lebanon'
    },
    { id: 'Sud, Haiti', name: 'Sud, Haiti' },
    {
        id: 'Pointe-Noire, Republic of the Congo',
        name: 'Pointe-Noire, Republic of the Congo'
    },
    {
        id: 'Departamento de Gracias a Dios, Honduras',
        name: 'Departamento de Gracias a Dios, Honduras'
    },
    { id: 'Bromley, United Kingdom', name: 'Bromley, United Kingdom' },
    {
        id: 'Montserrado County, Liberia',
        name: 'Montserrado County, Liberia'
    },
    { id: 'Vosges, France', name: 'Vosges, France' },
    { id: 'Huíla, Angola', name: 'Huíla, Angola' },
    {
        id: 'West Lothian, United Kingdom',
        name: 'West Lothian, United Kingdom'
    },
    { id: 'Afar Region, Ethiopia', name: 'Afar Region, Ethiopia' },
    {
        id: 'Departamento del Alto Parana, Paraguay',
        name: 'Departamento del Alto Parana, Paraguay'
    },
    { id: 'Stopinu Novads, Latvia', name: 'Stopinu Novads, Latvia' },
    { id: 'Osh City, Kyrgyzstan', name: 'Osh City, Kyrgyzstan' },
    {
        id: 'Derbyshire, United Kingdom',
        name: 'Derbyshire, United Kingdom'
    },
    { id: 'Telangana, India', name: 'Telangana, India' },
    { id: 'Rodrigues, Mauritius', name: 'Rodrigues, Mauritius' },
    { id: 'Region de Huanuco, Peru', name: 'Region de Huanuco, Peru' },
    {
        id: 'Gorenja Vas-Poljane, Slovenia',
        name: 'Gorenja Vas-Poljane, Slovenia'
    },
    { id: 'Aragatsotn, Armenia', name: 'Aragatsotn, Armenia' },
    {
        id: 'Ouham, Central African Republic',
        name: 'Ouham, Central African Republic'
    },
    { id: 'Boulemane, Morocco', name: 'Boulemane, Morocco' },
    {
        id: 'Provincia di Livorno, Italy',
        name: 'Provincia di Livorno, Italy'
    },
    {
        id: 'São Lourenço dos Órgãos, Cabo Verde',
        name: 'São Lourenço dos Órgãos, Cabo Verde'
    },
    { id: 'Berea, Lesotho', name: 'Berea, Lesotho' },
    { id: 'Mohéli, Comoros', name: 'Mohéli, Comoros' },
    { id: 'Guidimaka, Mauritania', name: 'Guidimaka, Mauritania' },
    { id: 'Morelos, Mexico', name: 'Morelos, Mexico' },
    {
        id: 'Provincia di Barletta - Andria - Trani, Italy',
        name: 'Provincia di Barletta - Andria - Trani, Italy'
    },
    {
        id: 'Federation of B&H, Bosnia and Herzegovina',
        name: 'Federation of B&H, Bosnia and Herzegovina'
    },
    { id: 'Barh el Gazel, Chad', name: 'Barh el Gazel, Chad' },
    { id: 'Tandjilé, Chad', name: 'Tandjilé, Chad' },
    {
        id: 'Tanger-Tetouan-Al Hoceima, Morocco',
        name: 'Tanger-Tetouan-Al Hoceima, Morocco'
    },
    { id: 'Rasina, Serbia', name: 'Rasina, Serbia' },
    { id: 'Tochigi, Japan', name: 'Tochigi, Japan' },
    { id: 'Tulcea, Romania', name: 'Tulcea, Romania' },
    {
        id: 'Nacional, Dominican Republic',
        name: 'Nacional, Dominican Republic'
    },
    { id: 'Nui, Tuvalu', name: 'Nui, Tuvalu' },
    { id: 'Braga, Portugal', name: 'Braga, Portugal' },
    { id: 'Mpumalanga, South Africa', name: 'Mpumalanga, South Africa' },
    {
        id: 'Provincia de Los Santos, Panama',
        name: 'Provincia de Los Santos, Panama'
    },
    {
        id: 'Walloon Brabant Province, Belgium',
        name: 'Walloon Brabant Province, Belgium'
    },
    { id: 'Panama Oeste, Panama', name: 'Panama Oeste, Panama' },
    { id: "'Asir, Saudi Arabia", name: "'Asir, Saudi Arabia" },
    { id: 'Jigawa State, Nigeria', name: 'Jigawa State, Nigeria' },
    { id: 'Ptuj, Slovenia', name: 'Ptuj, Slovenia' },
    { id: 'Sarawak, Malaysia', name: 'Sarawak, Malaysia' },
    { id: 'Apurimac, Peru', name: 'Apurimac, Peru' },
    {
        id: 'Provincia de Pichincha, Ecuador',
        name: 'Provincia de Pichincha, Ecuador'
    },
    {
        id: 'Province of Batangas, Philippines',
        name: 'Province of Batangas, Philippines'
    },
    {
        id: "Volgogradskaya Oblast', Russia",
        name: "Volgogradskaya Oblast', Russia"
    },
    {
        id: 'Pelhřimov District, Czechia',
        name: 'Pelhřimov District, Czechia'
    },
    { id: 'Grobiņa, Latvia', name: 'Grobiņa, Latvia' },
    { id: 'County Westmeath, Ireland', name: 'County Westmeath, Ireland' },
    {
        id: 'Mokronog-Trebelno, Slovenia',
        name: 'Mokronog-Trebelno, Slovenia'
    },
    {
        id: 'Sunderland, United Kingdom',
        name: 'Sunderland, United Kingdom'
    },
    { id: 'Ankara, Turkey', name: 'Ankara, Turkey' },
    {
        id: 'Province of Ilocos Sur, Philippines',
        name: 'Province of Ilocos Sur, Philippines'
    },
    { id: 'Daegu, Republic of Korea', name: 'Daegu, Republic of Korea' },
    {
        id: 'National Capital Region, Philippines',
        name: 'National Capital Region, Philippines'
    },
    { id: 'Islamabad, Pakistan', name: 'Islamabad, Pakistan' },
    { id: 'Haringey, United Kingdom', name: 'Haringey, United Kingdom' },
    {
        id: 'Bolama and Bijagos, Guinea-Bissau',
        name: 'Bolama and Bijagos, Guinea-Bissau'
    },
    { id: 'Pomerania, Poland', name: 'Pomerania, Poland' },
    { id: 'Telemark, Norway', name: 'Telemark, Norway' },
    { id: 'Uttar Pradesh, India', name: 'Uttar Pradesh, India' },
    {
        id: 'Departamento de Rocha, Uruguay',
        name: 'Departamento de Rocha, Uruguay'
    },
    { id: 'Gauteng, South Africa', name: 'Gauteng, South Africa' },
    { id: "Satupa'itea, Samoa", name: "Satupa'itea, Samoa" },
    {
        id: 'Zamboanga Peninsula, Philippines',
        name: 'Zamboanga Peninsula, Philippines'
    },
    { id: 'Bíe, Angola', name: 'Bíe, Angola' },
    { id: 'Vaduz, Liechtenstein', name: 'Vaduz, Liechtenstein' },
    { id: 'Ascoli Piceno, Italy', name: 'Ascoli Piceno, Italy' },
    { id: 'Wellington, New Zealand', name: 'Wellington, New Zealand' },
    { id: 'Cardiff, United Kingdom', name: 'Cardiff, United Kingdom' },
    {
        id: 'Lubombo District, Swaziland',
        name: 'Lubombo District, Swaziland'
    },
    { id: 'Minsk, Belarus', name: 'Minsk, Belarus' },
    { id: 'Yau Tsim Mong, Hong Kong', name: 'Yau Tsim Mong, Hong Kong' },
    {
        id: 'Širvintos District Municipality, Republic of Lithuania',
        name: 'Širvintos District Municipality, Republic of Lithuania'
    },
    { id: 'Nidwalden, Switzerland', name: 'Nidwalden, Switzerland' },
    { id: 'Mersch, Luxembourg', name: 'Mersch, Luxembourg' },
    {
        id: 'Chari-Baguirmi Region, Chad',
        name: 'Chari-Baguirmi Region, Chad'
    },
    {
        id: 'Departamento de La Guajira, Colombia',
        name: 'Departamento de La Guajira, Colombia'
    },
    { id: 'Sandwell, United Kingdom', name: 'Sandwell, United Kingdom' },
    {
        id: 'Province of Pordenone, Italy',
        name: 'Province of Pordenone, Italy'
    },
    { id: 'Prilep, Macedonia', name: 'Prilep, Macedonia' },
    {
        id: 'Costa Caribe Sur, Nicaragua',
        name: 'Costa Caribe Sur, Nicaragua'
    },
    { id: 'Olaine, Latvia', name: 'Olaine, Latvia' },
    { id: 'Berkane, Morocco', name: 'Berkane, Morocco' },
    { id: 'Qinghai, China', name: 'Qinghai, China' },
    {
        id: 'Province of Cotabato, Philippines',
        name: 'Province of Cotabato, Philippines'
    },
    { id: 'Somogy megye, Hungary', name: 'Somogy megye, Hungary' },
    {
        id: 'Miquelon-Langlade, Saint Pierre and Miquelon',
        name: 'Miquelon-Langlade, Saint Pierre and Miquelon'
    },
    { id: 'East Java, Indonesia', name: 'East Java, Indonesia' },
    { id: 'Coahuila, Mexico', name: 'Coahuila, Mexico' },
    { id: 'São Miguel, Cabo Verde', name: 'São Miguel, Cabo Verde' },
    { id: 'Tinh Khanh Hoa, Vietnam', name: 'Tinh Khanh Hoa, Vietnam' },
    { id: 'Mersraga Novads, Latvia', name: 'Mersraga Novads, Latvia' },
    { id: 'Tottori, Japan', name: 'Tottori, Japan' },
    { id: 'Guárico, Venezuela', name: 'Guárico, Venezuela' },
    { id: 'Southern Darfur, Sudan', name: 'Southern Darfur, Sudan' },
    { id: 'Van, Turkey', name: 'Van, Turkey' },
    {
        id: 'Provincia di Bologna, Italy',
        name: 'Provincia di Bologna, Italy'
    },
    { id: 'Lower Normandy, France', name: 'Lower Normandy, France' },
    { id: 'Rezekne, Latvia', name: 'Rezekne, Latvia' },
    {
        id: 'Province of Davao del Norte, Philippines',
        name: 'Province of Davao del Norte, Philippines'
    },
    { id: 'Kilifi District, Kenya', name: 'Kilifi District, Kenya' },
    { id: 'Kinshasa City, Congo', name: 'Kinshasa City, Congo' },
    {
        id: 'Provincia de Heredia, Costa Rica',
        name: 'Provincia de Heredia, Costa Rica'
    },
    { id: 'Ebonyi State, Nigeria', name: 'Ebonyi State, Nigeria' },
    {
        id: 'Nouakchott Ouest, Mauritania',
        name: 'Nouakchott Ouest, Mauritania'
    },
    { id: 'Karuzi Province, Burundi', name: 'Karuzi Province, Burundi' },
    { id: 'Nyanga, Gabon', name: 'Nyanga, Gabon' },
    { id: 'Vas, Hungary', name: 'Vas, Hungary' },
    { id: 'Draa-Tafilalet, Morocco', name: 'Draa-Tafilalet, Morocco' },
    { id: 'Rochdale, United Kingdom', name: 'Rochdale, United Kingdom' },
    {
        id: 'Tsuen Wan District, Hong Kong',
        name: 'Tsuen Wan District, Hong Kong'
    },
    { id: 'Il-Mosta, Malta', name: 'Il-Mosta, Malta' },
    { id: 'Chalkidikí, Greece', name: 'Chalkidikí, Greece' },
    {
        id: 'Province of Antique, Philippines',
        name: 'Province of Antique, Philippines'
    },
    { id: 'Zanzibar North, Tanzania', name: 'Zanzibar North, Tanzania' },
    { id: 'Bong County, Liberia', name: 'Bong County, Liberia' },
    {
        id: "Novgorodskaya Oblast', Russia",
        name: "Novgorodskaya Oblast', Russia"
    },
    { id: 'Khanty-Mansia, Russia', name: 'Khanty-Mansia, Russia' },
    { id: 'Kainuu, Finland', name: 'Kainuu, Finland' },
    { id: 'Qaasuitsup, Greenland', name: 'Qaasuitsup, Greenland' },
    { id: 'New Brunswick, Canada', name: 'New Brunswick, Canada' },
    {
        id: 'Provincia di Savona, Italy',
        name: 'Provincia di Savona, Italy'
    },
    {
        id: 'Obcina Rence-Vogrsko, Slovenia',
        name: 'Obcina Rence-Vogrsko, Slovenia'
    },
    {
        id: 'Departamento de Lempira, Honduras',
        name: 'Departamento de Lempira, Honduras'
    },
    { id: 'Puy-de-Dôme, France', name: 'Puy-de-Dôme, France' },
    {
        id: 'Departamento de Tacuarembo, Uruguay',
        name: 'Departamento de Tacuarembo, Uruguay'
    },
    { id: "O'Higgins Region, Chile", name: "O'Higgins Region, Chile" },
    { id: 'Mandoul, Chad', name: 'Mandoul, Chad' },
    {
        id: 'Departamento de Casanare, Colombia',
        name: 'Departamento de Casanare, Colombia'
    },
    {
        id: 'Lhaviyani Atholhu, Maldives',
        name: 'Lhaviyani Atholhu, Maldives'
    },
    {
        id: 'Province of Nueva Ecija, Philippines',
        name: 'Province of Nueva Ecija, Philippines'
    },
    { id: 'Assaba, Mauritania', name: 'Assaba, Mauritania' },
    {
        id: 'Middle Island, St Kitts and Nevis',
        name: 'Middle Island, St Kitts and Nevis'
    },
    { id: 'Daugavpils, Latvia', name: 'Daugavpils, Latvia' },
    { id: 'Nugaal, Somalia', name: 'Nugaal, Somalia' },
    { id: 'Corrèze, France', name: 'Corrèze, France' },
    { id: 'Laḩij, Yemen', name: 'Laḩij, Yemen' },
    {
        id: 'Departamento de Cortes, Honduras',
        name: 'Departamento de Cortes, Honduras'
    },
    {
        id: 'Departamento de Valle, Honduras',
        name: 'Departamento de Valle, Honduras'
    },
    { id: 'Carnikava, Latvia', name: 'Carnikava, Latvia' },
    { id: 'Anse Boileau, Seychelles', name: 'Anse Boileau, Seychelles' },
    {
        id: 'Jeju-do, Republic of Korea',
        name: 'Jeju-do, Republic of Korea'
    },
    { id: 'Ngwaketsi, Botswana', name: 'Ngwaketsi, Botswana' },
    { id: 'Borovnica, Slovenia', name: 'Borovnica, Slovenia' },
    {
        id: 'Navoiy Province, Uzbekistan',
        name: 'Navoiy Province, Uzbekistan'
    },
    { id: 'Biskra, Algeria', name: 'Biskra, Algeria' },
    {
        id: 'Majuro Atoll, Marshall Islands',
        name: 'Majuro Atoll, Marshall Islands'
    },
    {
        id: 'Province of Maguindanao, Philippines',
        name: 'Province of Maguindanao, Philippines'
    },
    { id: 'Wadi Fira Region, Chad', name: 'Wadi Fira Region, Chad' },
    {
        id: 'Hartlepool, United Kingdom',
        name: 'Hartlepool, United Kingdom'
    },
    {
        id: 'Provincie Drenthe, Netherlands',
        name: 'Provincie Drenthe, Netherlands'
    },
    { id: 'Lara, Venezuela', name: 'Lara, Venezuela' },
    { id: 'Sivas, Turkey', name: 'Sivas, Turkey' },
    {
        id: 'Drochia, Republic of Moldova',
        name: 'Drochia, Republic of Moldova'
    },
    {
        id: 'Akmenė District Municipality, Republic of Lithuania',
        name: 'Akmenė District Municipality, Republic of Lithuania'
    },
    {
        id: 'Kazlu Ruda Municipality, Republic of Lithuania',
        name: 'Kazlu Ruda Municipality, Republic of Lithuania'
    },
    { id: 'Upper Corsica, France', name: 'Upper Corsica, France' },
    {
        id: 'Province of Basilan, Philippines',
        name: 'Province of Basilan, Philippines'
    },
    { id: 'Asuncion, Paraguay', name: 'Asuncion, Paraguay' },
    {
        id: 'Vilnius, Republic of Lithuania',
        name: 'Vilnius, Republic of Lithuania'
    },
    { id: 'Parana, Brazil', name: 'Parana, Brazil' },
    { id: 'Tinh GJong Nai, Vietnam', name: 'Tinh GJong Nai, Vietnam' },
    {
        id: 'Southern Red Sea Region, Eritrea',
        name: 'Southern Red Sea Region, Eritrea'
    },
    { id: 'Chechnya, Russia', name: 'Chechnya, Russia' },
    { id: 'Zala, Hungary', name: 'Zala, Hungary' },
    { id: 'Debar, Macedonia', name: 'Debar, Macedonia' },
    { id: 'Trabzon, Turkey', name: 'Trabzon, Turkey' },
    { id: 'Struga, Macedonia', name: 'Struga, Macedonia' },
    {
        id: 'Hammersmith and Fulham, United Kingdom',
        name: 'Hammersmith and Fulham, United Kingdom'
    },
    { id: 'Tinh Quang Binh, Vietnam', name: 'Tinh Quang Binh, Vietnam' },
    {
        id: 'Slovenske Konjice, Slovenia',
        name: 'Slovenske Konjice, Slovenia'
    },
    { id: 'Andhra Pradesh, India', name: 'Andhra Pradesh, India' },
    { id: 'Loire-Atlantique, France', name: 'Loire-Atlantique, France' },
    { id: 'Aragua, Venezuela', name: 'Aragua, Venezuela' },
    { id: 'Tinh Ha Nam, Vietnam', name: 'Tinh Ha Nam, Vietnam' },
    { id: 'Ho Chi Minh City, Vietnam', name: 'Ho Chi Minh City, Vietnam' },
    { id: 'Verbania, Italy', name: 'Verbania, Italy' },
    { id: 'East, Iceland', name: 'East, Iceland' },
    {
        id: 'Province of Cavite, Philippines',
        name: 'Province of Cavite, Philippines'
    },
    { id: 'Schwyz, Switzerland', name: 'Schwyz, Switzerland' },
    { id: 'Saint Catherine, Jamaica', name: 'Saint Catherine, Jamaica' },
    { id: 'Lot-et-Garonne, France', name: 'Lot-et-Garonne, France' },
    { id: 'Upper Austria, Austria', name: 'Upper Austria, Austria' },
    { id: 'Central Darfur, Sudan', name: 'Central Darfur, Sudan' },
    {
        id: 'Respublika Khakasiya, Russia',
        name: 'Respublika Khakasiya, Russia'
    },
    {
        id: 'Raionul Stefan Voda, Republic of Moldova',
        name: 'Raionul Stefan Voda, Republic of Moldova'
    },
    { id: 'Bratislava, Slovakia', name: 'Bratislava, Slovakia' },
    {
        id: 'Ukmergė District Municipality, Republic of Lithuania',
        name: 'Ukmergė District Municipality, Republic of Lithuania'
    },
    { id: 'Salzburg, Austria', name: 'Salzburg, Austria' },
    { id: 'Soufriere, Saint Lucia', name: 'Soufriere, Saint Lucia' },
    { id: 'Padua, Italy', name: 'Padua, Italy' },
    { id: 'Valmiera District, Latvia', name: 'Valmiera District, Latvia' },
    { id: 'Medway, United Kingdom', name: 'Medway, United Kingdom' },
    { id: 'Zamora, Spain', name: 'Zamora, Spain' },
    {
        id: 'Moravskoslezsky kraj, Czechia',
        name: 'Moravskoslezsky kraj, Czechia'
    },
    { id: 'Vaucluse, France', name: 'Vaucluse, France' },
    { id: 'Gifu, Japan', name: 'Gifu, Japan' },
    { id: 'Jablanica, Serbia', name: 'Jablanica, Serbia' },
    {
        id: 'Departamento del Cesar, Colombia',
        name: 'Departamento del Cesar, Colombia'
    },
    { id: 'Belize District, Belize', name: 'Belize District, Belize' },
    {
        id: 'Bujumbura Mairie Province, Burundi',
        name: 'Bujumbura Mairie Province, Burundi'
    },
    { id: 'Oum el Bouaghi, Algeria', name: 'Oum el Bouaghi, Algeria' },
    {
        id: 'District des Montagnes, Ivory Coast',
        name: 'District des Montagnes, Ivory Coast'
    },
    {
        id: 'Orange Walk District, Belize',
        name: 'Orange Walk District, Belize'
    },
    { id: 'Beroun District, Czechia', name: 'Beroun District, Czechia' },
    {
        id: 'Litoměřice District, Czechia',
        name: 'Litoměřice District, Czechia'
    },
    { id: 'Anbar, Iraq', name: 'Anbar, Iraq' },
    { id: 'Houaphan, Laos', name: 'Houaphan, Laos' },
    { id: 'Dolj, Romania', name: 'Dolj, Romania' },
    { id: 'Ticino, Switzerland', name: 'Ticino, Switzerland' },
    { id: 'Zurich, Switzerland', name: 'Zurich, Switzerland' },
    { id: 'Clare, Ireland', name: 'Clare, Ireland' },
    {
        id: 'Grand Cape Mount County, Liberia',
        name: 'Grand Cape Mount County, Liberia'
    },
    { id: 'Vecumnieku Novads, Latvia', name: 'Vecumnieku Novads, Latvia' },
    { id: 'Beltinci, Slovenia', name: 'Beltinci, Slovenia' },
    {
        id: 'Provincia de Monte Cristi, Dominican Republic',
        name: 'Provincia de Monte Cristi, Dominican Republic'
    },
    { id: 'Oblast Vratsa, Bulgaria', name: 'Oblast Vratsa, Bulgaria' },
    { id: 'Mbeya, Tanzania', name: 'Mbeya, Tanzania' },
    {
        id: "Aktyubinskaya Oblast', Kazakhstan",
        name: "Aktyubinskaya Oblast', Kazakhstan"
    },
    {
        id: 'Jēkabpils Municipality, Latvia',
        name: 'Jēkabpils Municipality, Latvia'
    },
    {
        id: 'Province of Romblon, Philippines',
        name: 'Province of Romblon, Philippines'
    },
    {
        id: 'Chomutov District, Czechia',
        name: 'Chomutov District, Czechia'
    },
    { id: 'Obcina Sezana, Slovenia', name: 'Obcina Sezana, Slovenia' },
    { id: 'Kriva Palanka, Macedonia', name: 'Kriva Palanka, Macedonia' },
    { id: 'Istanbul, Turkey', name: 'Istanbul, Turkey' },
    { id: 'Judetul Valcea, Romania', name: 'Judetul Valcea, Romania' },
    { id: 'Obcina Poljcane, Slovenia', name: 'Obcina Poljcane, Slovenia' },
    { id: 'Venice, Italy', name: 'Venice, Italy' },
    { id: 'Yogyakarta, Indonesia', name: 'Yogyakarta, Indonesia' },
    { id: 'Province of Fermo, Italy', name: 'Province of Fermo, Italy' },
    {
        id: 'Obcina Hoce-Slivnica, Slovenia',
        name: 'Obcina Hoce-Slivnica, Slovenia'
    },
    { id: 'Conwy, United Kingdom', name: 'Conwy, United Kingdom' },
    { id: 'Kamnik, Slovenia', name: 'Kamnik, Slovenia' },
    { id: 'North-East, Botswana', name: 'North-East, Botswana' },
    {
        id: 'Gouvernorat de Medenine, Tunisia',
        name: 'Gouvernorat de Medenine, Tunisia'
    },
    { id: 'Obcina Lasko, Slovenia', name: 'Obcina Lasko, Slovenia' },
    {
        id: 'Parish of Saint Mary, Antigua and Barbuda',
        name: 'Parish of Saint Mary, Antigua and Barbuda'
    },
    { id: 'FCT, Nigeria', name: 'FCT, Nigeria' },
    { id: 'Balkan, Turkmenistan', name: 'Balkan, Turkmenistan' },
    { id: 'Johor, Malaysia', name: 'Johor, Malaysia' },
    { id: 'East Aimak, Mongolia', name: 'East Aimak, Mongolia' },
    { id: 'Changwat Krabi, Thailand', name: 'Changwat Krabi, Thailand' },
    { id: 'Bobonaro, East Timor', name: 'Bobonaro, East Timor' },
    { id: 'Gafsa, Tunisia', name: 'Gafsa, Tunisia' },
    { id: 'Judetul Arges, Romania', name: 'Judetul Arges, Romania' },
    { id: 'Mosteiros, Cabo Verde', name: 'Mosteiros, Cabo Verde' },
    { id: 'Salaspils Novads, Latvia', name: 'Salaspils Novads, Latvia' },
    { id: 'Monagas, Venezuela', name: 'Monagas, Venezuela' },
    { id: 'Ehime, Japan', name: 'Ehime, Japan' },
    { id: 'Tuscany, Italy', name: 'Tuscany, Italy' },
    { id: 'Souk Ahras, Algeria', name: 'Souk Ahras, Algeria' },
    { id: 'Kampong Speu, Cambodia', name: 'Kampong Speu, Cambodia' },
    {
        id: 'Bamingui-Bangoran, Central African Republic',
        name: 'Bamingui-Bangoran, Central African Republic'
    },
    {
        id: 'Shropshire, United Kingdom',
        name: 'Shropshire, United Kingdom'
    },
    { id: 'Tabuk, Saudi Arabia', name: 'Tabuk, Saudi Arabia' },
    {
        id: 'Brunei and Muara District, Brunei',
        name: 'Brunei and Muara District, Brunei'
    },
    {
        id: 'Provincia del Carchi, Ecuador',
        name: 'Provincia del Carchi, Ecuador'
    },
    {
        id: 'Departamento de Choluteca, Honduras',
        name: 'Departamento de Choluteca, Honduras'
    },
    { id: 'North Region, Cameroon', name: 'North Region, Cameroon' },
    { id: 'Antioquia, Colombia', name: 'Antioquia, Colombia' },
    { id: 'Shariatpur, Bangladesh', name: 'Shariatpur, Bangladesh' },
    { id: 'Aden, Yemen', name: 'Aden, Yemen' },
    { id: 'Sefrou, Morocco', name: 'Sefrou, Morocco' },
    { id: 'Oran, Algeria', name: 'Oran, Algeria' },
    { id: 'Yerevan, Armenia', name: 'Yerevan, Armenia' },
    { id: 'Baku City, Azerbaijan', name: 'Baku City, Azerbaijan' },
    {
        id: 'Kié-Ntem, Equatorial Guinea',
        name: 'Kié-Ntem, Equatorial Guinea'
    },
    { id: 'Lower Saxony, Germany', name: 'Lower Saxony, Germany' },
    {
        id: 'Yamoussoukro Autonomous District, Ivory Coast',
        name: 'Yamoussoukro Autonomous District, Ivory Coast'
    },
    { id: 'Salta, Argentina', name: 'Salta, Argentina' },
    {
        id: 'Rabat-Sale-Kenitra, Morocco',
        name: 'Rabat-Sale-Kenitra, Morocco'
    },
    { id: 'Hama Governorate, Syria', name: 'Hama Governorate, Syria' },
    { id: 'Dakahlia, Egypt', name: 'Dakahlia, Egypt' },
    { id: 'Awdal, Somalia', name: 'Awdal, Somalia' },
    {
        id: 'East Flanders Province, Belgium',
        name: 'East Flanders Province, Belgium'
    },
    {
        id: 'Province of Siquijor, Philippines',
        name: 'Province of Siquijor, Philippines'
    },
    {
        id: 'Gouvernorat de Siliana, Tunisia',
        name: 'Gouvernorat de Siliana, Tunisia'
    },
    {
        id: 'Province of Bukidnon, Philippines',
        name: 'Province of Bukidnon, Philippines'
    },
    {
        id: 'Departamento del Caqueta, Colombia',
        name: 'Departamento del Caqueta, Colombia'
    },
    {
        id: 'Northern Ari Atoll, Maldives',
        name: 'Northern Ari Atoll, Maldives'
    },
    { id: 'Alborz, Iran', name: 'Alborz, Iran' },
    { id: 'Minsk City, Belarus', name: 'Minsk City, Belarus' },
    { id: 'Yamanashi, Japan', name: 'Yamanashi, Japan' },
    { id: 'Issyk-Kul, Kyrgyzstan', name: 'Issyk-Kul, Kyrgyzstan' },
    { id: 'Kagawa, Japan', name: 'Kagawa, Japan' },
    { id: 'Kisii District, Kenya', name: 'Kisii District, Kenya' },
    { id: 'Oecusse, East Timor', name: 'Oecusse, East Timor' },
    { id: 'Christ Church, Barbados', name: 'Christ Church, Barbados' },
    { id: 'Narsingdi, Bangladesh', name: 'Narsingdi, Bangladesh' },
    {
        id: 'Iles Marquises, French Polynesia',
        name: 'Iles Marquises, French Polynesia'
    },
    {
        id: 'Judetul Bistrita-Nasaud, Romania',
        name: 'Judetul Bistrita-Nasaud, Romania'
    },
    { id: 'Dhaalu Atholhu, Maldives', name: 'Dhaalu Atholhu, Maldives' },
    { id: 'Praha 18, Czechia', name: 'Praha 18, Czechia' },
    { id: 'Sveta Ana, Slovenia', name: 'Sveta Ana, Slovenia' },
    { id: 'Nord, Haiti', name: 'Nord, Haiti' },
    { id: 'Praha 15, Czechia', name: 'Praha 15, Czechia' },
    { id: 'Praha 14, Czechia', name: 'Praha 14, Czechia' },
    { id: 'Praha 17, Czechia', name: 'Praha 17, Czechia' },
    { id: 'Praha 11, Czechia', name: 'Praha 11, Czechia' },
    { id: 'Praha 10, Czechia', name: 'Praha 10, Czechia' },
    { id: 'Praha 13, Czechia', name: 'Praha 13, Czechia' },
    {
        id: 'Rennell and Bellona, Solomon Islands',
        name: 'Rennell and Bellona, Solomon Islands'
    },
    {
        id: 'Departamento de Santa Barbara, Honduras',
        name: 'Departamento de Santa Barbara, Honduras'
    },
    {
        id: 'Sangha-Mbaéré, Central African Republic',
        name: 'Sangha-Mbaéré, Central African Republic'
    },
    {
        id: 'Gaafu Dhaalu Atholhu, Maldives',
        name: 'Gaafu Dhaalu Atholhu, Maldives'
    },
    { id: 'Grevenmacher, Luxembourg', name: 'Grevenmacher, Luxembourg' },
    { id: 'Covasna, Romania', name: 'Covasna, Romania' },
    { id: 'Madhya Pradesh, India', name: 'Madhya Pradesh, India' },
    {
        id: 'Gouvernorat de Zaghouan, Tunisia',
        name: 'Gouvernorat de Zaghouan, Tunisia'
    },
    {
        id: 'Departamento de Comayagua, Honduras',
        name: 'Departamento de Comayagua, Honduras'
    },
    { id: 'Sinoe County, Liberia', name: 'Sinoe County, Liberia' },
    { id: 'Tainan, Taiwan', name: 'Tainan, Taiwan' },
    { id: 'Hanover, Jamaica', name: 'Hanover, Jamaica' },
    { id: 'Ardèche, France', name: 'Ardèche, France' },
    {
        id: 'Departamento de Itapua, Paraguay',
        name: 'Departamento de Itapua, Paraguay'
    },
    {
        id: 'Chișinău Municipality, Republic of Moldova',
        name: 'Chișinău Municipality, Republic of Moldova'
    },
    {
        id: 'Province of Masbate, Philippines',
        name: 'Province of Masbate, Philippines'
    },
    { id: 'L-Isla, Malta', name: 'L-Isla, Malta' },
    { id: 'Thesprotia, Greece', name: 'Thesprotia, Greece' },
    {
        id: "Kemerovskaya Oblast', Russia",
        name: "Kemerovskaya Oblast', Russia"
    },
    { id: 'Celje, Slovenia', name: 'Celje, Slovenia' },
    {
        id: 'Departamento del Guainia, Colombia',
        name: 'Departamento del Guainia, Colombia'
    },
    { id: 'Manihiki, Cook Islands', name: 'Manihiki, Cook Islands' },
    { id: 'Saint Michael, Barbados', name: 'Saint Michael, Barbados' },
    {
        id: 'Klaipėda County, Republic of Lithuania',
        name: 'Klaipėda County, Republic of Lithuania'
    },
    { id: 'Yamaguchi, Japan', name: 'Yamaguchi, Japan' },
    { id: 'Rojas Novads, Latvia', name: 'Rojas Novads, Latvia' },
    { id: 'Southland, New Zealand', name: 'Southland, New Zealand' },
    { id: "Cote d'Or, France", name: "Cote d'Or, France" },
    {
        id: 'Province of Surigao del Sur, Philippines',
        name: 'Province of Surigao del Sur, Philippines'
    },
    {
        id: 'Mohafazat Mont-Liban, Lebanon',
        name: 'Mohafazat Mont-Liban, Lebanon'
    },
    {
        id: 'Departamento de Sucre, Colombia',
        name: 'Departamento de Sucre, Colombia'
    },
    { id: 'Attard, Malta', name: 'Attard, Malta' },
    { id: 'Ludzas Rajons, Latvia', name: 'Ludzas Rajons, Latvia' },
    { id: 'Tarapacá, Chile', name: 'Tarapacá, Chile' },
    {
        id: 'Prienai District Municipality, Republic of Lithuania',
        name: 'Prienai District Municipality, Republic of Lithuania'
    },
    {
        id: 'Lower River Division, Gambia',
        name: 'Lower River Division, Gambia'
    },
    { id: 'Warwick Parish, Bermuda', name: 'Warwick Parish, Bermuda' },
    { id: 'Sétif, Algeria', name: 'Sétif, Algeria' },
    { id: 'Yukon, Canada', name: 'Yukon, Canada' },
    {
        id: 'Oblast Kardzhali, Bulgaria',
        name: 'Oblast Kardzhali, Bulgaria'
    },
    { id: 'Tagant, Mauritania', name: 'Tagant, Mauritania' },
    { id: 'Hentiy Aymag, Mongolia', name: 'Hentiy Aymag, Mongolia' },
    { id: 'Northern Region, Ghana', name: 'Northern Region, Ghana' },
    { id: 'Bolívar, Venezuela', name: 'Bolívar, Venezuela' },
    { id: 'County Kilkenny, Ireland', name: 'County Kilkenny, Ireland' },
    { id: 'Ulsan, Republic of Korea', name: 'Ulsan, Republic of Korea' },
    { id: 'Bijelo Polje, Montenegro', name: 'Bijelo Polje, Montenegro' },
    { id: 'Pernambuco, Brazil', name: 'Pernambuco, Brazil' },
    {
        id: 'Redonda, Antigua and Barbuda',
        name: 'Redonda, Antigua and Barbuda'
    },
    { id: 'Rivers State, Nigeria', name: 'Rivers State, Nigeria' },
    {
        id: 'Wele-Nzas, Equatorial Guinea',
        name: 'Wele-Nzas, Equatorial Guinea'
    },
    { id: 'Rheinland-Pfalz, Germany', name: 'Rheinland-Pfalz, Germany' },
    {
        id: 'Departamento de Chuquisaca, Bolivia',
        name: 'Departamento de Chuquisaca, Bolivia'
    },
    {
        id: 'Essequibo Islands-West Demerara Region, Guyana',
        name: 'Essequibo Islands-West Demerara Region, Guyana'
    },
    {
        id: 'Causeway Coast and Glens, United Kingdom',
        name: 'Causeway Coast and Glens, United Kingdom'
    },
    { id: 'Njombe, Tanzania', name: 'Njombe, Tanzania' },
    { id: 'Judetul Braila, Romania', name: 'Judetul Braila, Romania' },
    {
        id: 'Changwat Uttaradit, Thailand',
        name: 'Changwat Uttaradit, Thailand'
    },
    { id: 'Mwaro, Burundi', name: 'Mwaro, Burundi' },
    { id: 'Tinh Phu Yen, Vietnam', name: 'Tinh Phu Yen, Vietnam' },
    { id: 'Euboea, Greece', name: 'Euboea, Greece' },
    { id: 'Hardap, Namibia', name: 'Hardap, Namibia' },
    { id: 'Altai Krai, Russia', name: 'Altai Krai, Russia' },
    { id: 'Çankırı, Turkey', name: 'Çankırı, Turkey' },
    { id: 'Otar Meanchey, Cambodia', name: 'Otar Meanchey, Cambodia' },
    { id: 'Gazipur, Bangladesh', name: 'Gazipur, Bangladesh' },
    {
        id: 'Departamento de Retalhuleu, Guatemala',
        name: 'Departamento de Retalhuleu, Guatemala'
    },
    { id: 'Vest-Agder Fylke, Norway', name: 'Vest-Agder Fylke, Norway' },
    { id: 'East Azerbaijan, Iran', name: 'East Azerbaijan, Iran' },
    { id: 'Bar, Montenegro', name: 'Bar, Montenegro' },
    { id: 'Kirkop, Malta', name: 'Kirkop, Malta' },
    { id: 'Lima, Peru', name: 'Lima, Peru' },
    { id: 'Sokoto State, Nigeria', name: 'Sokoto State, Nigeria' },
    {
        id: 'Sejong-si, Republic of Korea',
        name: 'Sejong-si, Republic of Korea'
    },
    {
        id: 'Bournemouth, United Kingdom',
        name: 'Bournemouth, United Kingdom'
    },
    {
        id: 'Raseiniai District Municipality, Republic of Lithuania',
        name: 'Raseiniai District Municipality, Republic of Lithuania'
    },
    { id: 'Enugu State, Nigeria', name: 'Enugu State, Nigeria' },
    { id: 'County Cork, Ireland', name: 'County Cork, Ireland' },
    { id: 'Gia Lai, Vietnam', name: 'Gia Lai, Vietnam' },
    { id: 'Vargas, Venezuela', name: 'Vargas, Venezuela' },
    { id: 'Tiznit Province, Morocco', name: 'Tiznit Province, Morocco' },
    { id: 'Albacete, Spain', name: 'Albacete, Spain' },
    {
        id: 'Thanh Pho Hai Phong, Vietnam',
        name: 'Thanh Pho Hai Phong, Vietnam'
    },
    { id: 'Obcina Jursinci, Slovenia', name: 'Obcina Jursinci, Slovenia' },
    {
        id: 'Ouarzazate Province, Morocco',
        name: 'Ouarzazate Province, Morocco'
    },
    { id: 'Mitiaro, Cook Islands', name: 'Mitiaro, Cook Islands' },
    {
        id: 'Municipiul Balti, Republic of Moldova',
        name: 'Municipiul Balti, Republic of Moldova'
    },
    {
        id: 'Province du Sud-Ubangi, Congo',
        name: 'Province du Sud-Ubangi, Congo'
    },
    {
        id: 'Rîşcani, Republic of Moldova',
        name: 'Rîşcani, Republic of Moldova'
    },
    { id: 'Bormla, Malta', name: 'Bormla, Malta' },
    {
        id: 'Viloyati Sughd, Tajikistan',
        name: 'Viloyati Sughd, Tajikistan'
    },
    {
        id: 'Mestna Obcina Novo mesto, Slovenia',
        name: 'Mestna Obcina Novo mesto, Slovenia'
    },
    { id: 'Lacs, Ivory Coast', name: 'Lacs, Ivory Coast' },
    { id: 'Obcina Mezica, Slovenia', name: 'Obcina Mezica, Slovenia' },
    {
        id: 'New Ireland, Papua New Guinea',
        name: 'New Ireland, Papua New Guinea'
    },
    { id: 'Rumonge, Burundi', name: 'Rumonge, Burundi' },
    {
        id: "Mykolayivs'ka Oblast', Ukraine",
        name: "Mykolayivs'ka Oblast', Ukraine"
    },
    { id: 'Ohrid, Macedonia', name: 'Ohrid, Macedonia' },
    { id: 'Tarn, France', name: 'Tarn, France' },
    { id: 'Preah Vihear, Cambodia', name: 'Preah Vihear, Cambodia' },
    { id: 'Lambeth, United Kingdom', name: 'Lambeth, United Kingdom' },
    { id: 'Harare, Zimbabwe', name: 'Harare, Zimbabwe' },
    { id: 'Il-Qrendi, Malta', name: 'Il-Qrendi, Malta' },
    { id: 'Oldham, United Kingdom', name: 'Oldham, United Kingdom' },
    { id: 'Cavan, Ireland', name: 'Cavan, Ireland' },
    { id: 'Imo State, Nigeria', name: 'Imo State, Nigeria' },
    { id: 'Halton, United Kingdom', name: 'Halton, United Kingdom' },
    { id: 'Chubut, Argentina', name: 'Chubut, Argentina' },
    { id: 'Tripoli, Libya', name: 'Tripoli, Libya' },
    { id: "Gaga'emauga, Samoa", name: "Gaga'emauga, Samoa" },
    { id: 'Sarthe, France', name: 'Sarthe, France' },
    {
        id: 'Pamplemousses District, Mauritius',
        name: 'Pamplemousses District, Mauritius'
    },
    { id: 'Dravograd, Slovenia', name: 'Dravograd, Slovenia' },
    {
        id: 'Province of South Cotabato, Philippines',
        name: 'Province of South Cotabato, Philippines'
    },
    {
        id: 'Ústí nad Orlicí District, Czechia',
        name: 'Ústí nad Orlicí District, Czechia'
    },
    { id: 'Diyarbakir, Turkey', name: 'Diyarbakir, Turkey' },
    { id: 'Reading, United Kingdom', name: 'Reading, United Kingdom' },
    { id: 'Putrajaya, Malaysia', name: 'Putrajaya, Malaysia' },
    { id: 'Choiseul, Solomon Islands', name: 'Choiseul, Solomon Islands' },
    { id: 'Zanzan, Ivory Coast', name: 'Zanzan, Ivory Coast' },
    {
        id: 'Province of Bohol, Philippines',
        name: 'Province of Bohol, Philippines'
    },
    {
        id: 'Provincia di Grosseto, Italy',
        name: 'Provincia di Grosseto, Italy'
    },
    {
        id: 'Cahul, Republic of Moldova',
        name: 'Cahul, Republic of Moldova'
    },
    { id: 'Yvelines, France', name: 'Yvelines, France' },
    { id: 'Jharkhand, India', name: 'Jharkhand, India' },
    {
        id: 'Neringa Municipality, Republic of Lithuania',
        name: 'Neringa Municipality, Republic of Lithuania'
    },
    {
        id: 'Departamento de Esteli, Nicaragua',
        name: 'Departamento de Esteli, Nicaragua'
    },
    { id: 'Vrancea, Romania', name: 'Vrancea, Romania' },
    { id: 'Province of Arezzo, Italy', name: 'Province of Arezzo, Italy' },
    {
        id: 'Changwat Phitsanulok, Thailand',
        name: 'Changwat Phitsanulok, Thailand'
    },
    {
        id: 'Beni Mellal-Khenifra, Morocco',
        name: 'Beni Mellal-Khenifra, Morocco'
    },
    { id: 'Chandpur, Bangladesh', name: 'Chandpur, Bangladesh' },
    {
        id: 'Grand Gedeh County, Liberia',
        name: 'Grand Gedeh County, Liberia'
    },
    { id: 'Tongatapu, Tonga', name: 'Tongatapu, Tonga' },
    { id: 'Kavadarci, Macedonia', name: 'Kavadarci, Macedonia' },
    { id: 'Komi, Russia', name: 'Komi, Russia' },
    { id: 'Long Island, Bahamas', name: 'Long Island, Bahamas' },
    {
        id: 'Changwat Yasothon, Thailand',
        name: 'Changwat Yasothon, Thailand'
    },
    { id: 'Trongsa Dzongkhag, Bhutan', name: 'Trongsa Dzongkhag, Bhutan' },
    { id: 'Tinh Dien Bien, Vietnam', name: 'Tinh Dien Bien, Vietnam' },
    { id: 'Gitega Province, Burundi', name: 'Gitega Province, Burundi' },
    { id: 'Jämtland, Sweden', name: 'Jämtland, Sweden' },
    { id: 'Kocaeli, Turkey', name: 'Kocaeli, Turkey' },
    { id: 'Macva, Serbia', name: 'Macva, Serbia' },
    { id: 'Salamat Region, Chad', name: 'Salamat Region, Chad' },
    { id: 'Lagunes, Ivory Coast', name: 'Lagunes, Ivory Coast' },
    { id: 'Papua, Indonesia', name: 'Papua, Indonesia' },
    { id: 'Ordino, Andorra', name: 'Ordino, Andorra' },
    {
        id: 'Borough of Bury, United Kingdom',
        name: 'Borough of Bury, United Kingdom'
    },
    { id: 'Carabobo, Venezuela', name: 'Carabobo, Venezuela' },
    { id: 'Bas-Congo, Congo', name: 'Bas-Congo, Congo' },
    {
        id: 'Viroviticko-Podravska Zupanija, Croatia',
        name: 'Viroviticko-Podravska Zupanija, Croatia'
    },
    { id: 'Muchinga, Zambia', name: 'Muchinga, Zambia' },
    {
        id: 'Central Ostrobothnia, Finland',
        name: 'Central Ostrobothnia, Finland'
    },
    { id: 'Upper East Region, Ghana', name: 'Upper East Region, Ghana' },
    { id: 'Indre, France', name: 'Indre, France' },
    {
        id: 'Provincia di Vicenza, Italy',
        name: 'Provincia di Vicenza, Italy'
    },
    { id: 'Beja, Portugal', name: 'Beja, Portugal' },
    { id: 'County Wexford, Ireland', name: 'County Wexford, Ireland' },
    { id: 'La Rioja, Argentina', name: 'La Rioja, Argentina' },
    { id: 'Zou Department, Benin', name: 'Zou Department, Benin' },
    { id: 'Aleppo Governorate, Syria', name: 'Aleppo Governorate, Syria' },
    { id: 'Zilupes Novads, Latvia', name: 'Zilupes Novads, Latvia' },
    { id: 'Cerkno, Slovenia', name: 'Cerkno, Slovenia' },
    {
        id: 'East Renfrewshire, United Kingdom',
        name: 'East Renfrewshire, United Kingdom'
    },
    { id: 'Hidalgo, Mexico', name: 'Hidalgo, Mexico' },
    { id: 'Kurigram, Bangladesh', name: 'Kurigram, Bangladesh' },
    {
        id: 'Slavonski Brod-Posavina, Croatia',
        name: 'Slavonski Brod-Posavina, Croatia'
    },
    { id: 'Judetul Calarasi, Romania', name: 'Judetul Calarasi, Romania' },
    {
        id: 'Päijänne Tavastia, Finland',
        name: 'Päijänne Tavastia, Finland'
    },
    { id: 'Tocantins, Brazil', name: 'Tocantins, Brazil' },
    {
        id: 'Southend-on-Sea, United Kingdom',
        name: 'Southend-on-Sea, United Kingdom'
    },
    {
        id: 'Gagauzia, Republic of Moldova',
        name: 'Gagauzia, Republic of Moldova'
    },
    { id: 'Burgas, Bulgaria', name: 'Burgas, Bulgaria' },
    {
        id: 'West Kazakhstan, Kazakhstan',
        name: 'West Kazakhstan, Kazakhstan'
    },
    { id: 'Rrethi i Pukes, Albania', name: 'Rrethi i Pukes, Albania' },
    {
        id: 'Triesenberg, Liechtenstein',
        name: 'Triesenberg, Liechtenstein'
    },
    { id: 'Soqatra, Yemen', name: 'Soqatra, Yemen' },
    { id: 'Aydın, Turkey', name: 'Aydın, Turkey' },
    { id: 'Aveyron, France', name: 'Aveyron, France' },
    {
        id: 'Varėna District Municipality, Republic of Lithuania',
        name: 'Varėna District Municipality, Republic of Lithuania'
    },
    { id: 'Doubs, France', name: 'Doubs, France' },
    { id: 'Obcina Salovci, Slovenia', name: 'Obcina Salovci, Slovenia' },
    { id: 'Mato Grosso, Brazil', name: 'Mato Grosso, Brazil' },
    {
        id: 'Provincia de Imbabura, Ecuador',
        name: 'Provincia de Imbabura, Ecuador'
    },
    { id: 'Salamanca, Spain', name: 'Salamanca, Spain' },
    { id: 'Chuvashia, Russia', name: 'Chuvashia, Russia' },
    {
        id: 'Departamento de Neembucu, Paraguay',
        name: 'Departamento de Neembucu, Paraguay'
    },
    {
        id: 'Leeward Islands, French Polynesia',
        name: 'Leeward Islands, French Polynesia'
    },
    { id: 'Rethymno, Greece', name: 'Rethymno, Greece' },
    {
        id: 'Parish of Saint Patrick, Saint Vincent and the Grenadines',
        name: 'Parish of Saint Patrick, Saint Vincent and the Grenadines'
    },
    { id: 'Antofagasta, Chile', name: 'Antofagasta, Chile' },
    { id: 'Balvu Novads, Latvia', name: 'Balvu Novads, Latvia' },
    {
        id: "Sha'biyat Wadi ash Shati', Libya",
        name: "Sha'biyat Wadi ash Shati', Libya"
    },
    { id: 'Nantou, Taiwan', name: 'Nantou, Taiwan' },
    { id: 'Izola, Slovenia', name: 'Izola, Slovenia' },
    { id: 'Silesia, Poland', name: 'Silesia, Poland' },
    { id: 'Canelones, Uruguay', name: 'Canelones, Uruguay' },
    {
        id: 'Tai Po District, Hong Kong',
        name: 'Tai Po District, Hong Kong'
    },
    {
        id: 'Ajman, United Arab Emirates',
        name: 'Ajman, United Arab Emirates'
    },
    { id: 'Nagasaki, Japan', name: 'Nagasaki, Japan' },
    { id: 'Obcina Sencur, Slovenia', name: 'Obcina Sencur, Slovenia' },
    { id: 'Pesaro and Urbino, Italy', name: 'Pesaro and Urbino, Italy' },
    {
        id: 'São Tomé Island, São Tomé and Príncipe',
        name: 'São Tomé Island, São Tomé and Príncipe'
    },
    { id: 'Tinh Quang Nam, Vietnam', name: 'Tinh Quang Nam, Vietnam' },
    { id: 'Paola, Malta', name: 'Paola, Malta' },
    { id: 'Ouadaï, Chad', name: 'Ouadaï, Chad' },
    {
        id: 'Mambéré-Kadéï, Central African Republic',
        name: 'Mambéré-Kadéï, Central African Republic'
    },
    {
        id: 'Changwat Phatthalung, Thailand',
        name: 'Changwat Phatthalung, Thailand'
    },
    { id: 'Boumerdes, Algeria', name: 'Boumerdes, Algeria' },
    { id: 'North Bačka, Serbia', name: 'North Bačka, Serbia' },
    { id: 'Tavastia Proper, Finland', name: 'Tavastia Proper, Finland' },
    { id: 'Khammouan, Laos', name: 'Khammouan, Laos' },
    { id: 'Larnaka, Cyprus', name: 'Larnaka, Cyprus' },
    { id: 'Anse Royale, Seychelles', name: 'Anse Royale, Seychelles' },
    {
        id: 'Lancashire, United Kingdom',
        name: 'Lancashire, United Kingdom'
    },
    {
        id: 'Incheon, Republic of Korea',
        name: 'Incheon, Republic of Korea'
    },
    {
        id: 'West Dunbartonshire, United Kingdom',
        name: 'West Dunbartonshire, United Kingdom'
    },
    { id: 'Croydon, United Kingdom', name: 'Croydon, United Kingdom' },
    { id: 'Haute-Normandie, France', name: 'Haute-Normandie, France' },
    { id: 'Aube, France', name: 'Aube, France' },
    {
        id: 'Ilirska Bistrica, Slovenia',
        name: 'Ilirska Bistrica, Slovenia'
    },
    {
        id: 'Potaro-Siparuni Region, Guyana',
        name: 'Potaro-Siparuni Region, Guyana'
    },
    {
        id: 'Obcina Zelezniki, Slovenia',
        name: 'Obcina Zelezniki, Slovenia'
    },
    { id: 'Limerick, Ireland', name: 'Limerick, Ireland' },
    {
        id: 'Province du Boulgou, Burkina Faso',
        name: 'Province du Boulgou, Burkina Faso'
    },
    {
        id: 'Mon State, Myanmar [Burma]',
        name: 'Mon State, Myanmar [Burma]'
    },
    { id: 'Guéra, Chad', name: 'Guéra, Chad' },
    {
        id: 'Kadiogo Province, Burkina Faso',
        name: 'Kadiogo Province, Burkina Faso'
    },
    { id: 'Bulgan, Mongolia', name: 'Bulgan, Mongolia' },
    {
        id: 'Al Batinah North Governorate, Oman',
        name: 'Al Batinah North Governorate, Oman'
    },
    {
        id: 'North Andros District, Bahamas',
        name: 'North Andros District, Bahamas'
    },
    { id: 'Namur Province, Belgium', name: 'Namur Province, Belgium' },
    { id: 'L-Gharb, Malta', name: 'L-Gharb, Malta' },
    {
        id: 'Provincia de Chiriqui, Panama',
        name: 'Provincia de Chiriqui, Panama'
    },
    { id: 'Blida, Algeria', name: 'Blida, Algeria' },
    {
        id: 'Renfrewshire, United Kingdom',
        name: 'Renfrewshire, United Kingdom'
    },
    { id: 'L-Imgarr, Malta', name: 'L-Imgarr, Malta' },
    { id: 'Goa, India', name: 'Goa, India' },
    {
        id: " and People's Region, Ethiopia",
        name: " and People's Region, Ethiopia"
    },
    { id: 'Penama Province, Vanuatu', name: 'Penama Province, Vanuatu' },
    { id: 'Meath, Ireland', name: 'Meath, Ireland' },
    {
        id: 'Selnica ob Dravi, Slovenia',
        name: 'Selnica ob Dravi, Slovenia'
    },
    { id: 'Saint Gallen, Switzerland', name: 'Saint Gallen, Switzerland' },
    { id: 'Somerset, United Kingdom', name: 'Somerset, United Kingdom' },
    { id: 'Odessa, Ukraine', name: 'Odessa, Ukraine' },
    {
        id: 'Changwat Phichit, Thailand',
        name: 'Changwat Phichit, Thailand'
    },
    {
        id: 'East Nusa Tenggara, Indonesia',
        name: 'East Nusa Tenggara, Indonesia'
    },
    { id: 'Ekiti State, Nigeria', name: 'Ekiti State, Nigeria' },
    { id: 'Budva, Montenegro', name: 'Budva, Montenegro' },
    {
        id: 'Northern Samar, Philippines',
        name: 'Northern Samar, Philippines'
    },
    { id: 'Kyoto, Japan', name: 'Kyoto, Japan' },
    {
        id: 'North Lincolnshire, United Kingdom',
        name: 'North Lincolnshire, United Kingdom'
    },
    { id: 'Lleida, Spain', name: 'Lleida, Spain' },
    { id: 'Hawalli, Kuwait', name: 'Hawalli, Kuwait' },
    { id: 'Kigoma, Tanzania', name: 'Kigoma, Tanzania' },
    {
        id: 'Makedonski Brod, Macedonia',
        name: 'Makedonski Brod, Macedonia'
    },
    { id: "Val d'Oise, France", name: "Val d'Oise, France" },
    { id: 'Souss-Massa, Morocco', name: 'Souss-Massa, Morocco' },
    {
        id: 'Provincie Flevoland, Netherlands',
        name: 'Provincie Flevoland, Netherlands'
    },
    { id: 'Destrnik, Slovenia', name: 'Destrnik, Slovenia' },
    { id: 'Seine-Saint-Denis, France', name: 'Seine-Saint-Denis, France' },
    {
        id: 'Cabo Delgado Province, Mozambique',
        name: 'Cabo Delgado Province, Mozambique'
    },
    { id: 'Coventry, United Kingdom', name: 'Coventry, United Kingdom' },
    { id: 'Lomami, Congo', name: 'Lomami, Congo' },
    { id: 'Laghouat, Algeria', name: 'Laghouat, Algeria' },
    { id: 'Ruggell, Liechtenstein', name: 'Ruggell, Liechtenstein' },
    { id: 'Presov, Slovakia', name: 'Presov, Slovakia' },
    {
        id: 'Merthyr Tydfil County Borough, United Kingdom',
        name: 'Merthyr Tydfil County Borough, United Kingdom'
    },
    { id: 'Fukui, Japan', name: 'Fukui, Japan' },
    {
        id: 'Departamento de Madriz, Nicaragua',
        name: 'Departamento de Madriz, Nicaragua'
    },
    {
        id: 'Changwat Samut Songkhram, Thailand',
        name: 'Changwat Samut Songkhram, Thailand'
    },
    {
        id: 'San Andres y Providencia, Colombia',
        name: 'San Andres y Providencia, Colombia'
    },
    { id: 'Centrale, Togo', name: 'Centrale, Togo' },
    { id: 'Kavango West, Namibia', name: 'Kavango West, Namibia' },
    {
        id: 'West Nusa Tenggara, Indonesia',
        name: 'West Nusa Tenggara, Indonesia'
    },
    {
        id: 'Obcina Zuzemberk, Slovenia',
        name: 'Obcina Zuzemberk, Slovenia'
    },
    { id: 'Zacatecas, Mexico', name: 'Zacatecas, Mexico' },
    {
        id: 'Territoire de Belfort, France',
        name: 'Territoire de Belfort, France'
    },
    { id: 'Sheffield, United Kingdom', name: 'Sheffield, United Kingdom' },
    { id: 'Omusati, Namibia', name: 'Omusati, Namibia' },
    { id: 'Mexico City, Mexico', name: 'Mexico City, Mexico' },
    { id: 'Balzan, Malta', name: 'Balzan, Malta' },
    {
        id: 'Qostanay Oblysy, Kazakhstan',
        name: 'Qostanay Oblysy, Kazakhstan'
    },
    { id: 'Lola, Guinea', name: 'Lola, Guinea' },
    { id: 'Haz-Zebbug, Malta', name: 'Haz-Zebbug, Malta' },
    {
        id: 'Bayanhongor Aymag, Mongolia',
        name: 'Bayanhongor Aymag, Mongolia'
    },
    {
        id: 'Distrikt Coronie, Suriname',
        name: 'Distrikt Coronie, Suriname'
    },
    { id: 'Haa, Bhutan', name: 'Haa, Bhutan' },
    { id: 'Chhattisgarh, India', name: 'Chhattisgarh, India' },
    { id: 'Penghu, Taiwan', name: 'Penghu, Taiwan' },
    { id: 'Veneto, Italy', name: 'Veneto, Italy' },
    { id: 'Rukwa, Tanzania', name: 'Rukwa, Tanzania' },
    { id: 'Mālpils, Latvia', name: 'Mālpils, Latvia' },
    { id: 'Valandovo, Macedonia', name: 'Valandovo, Macedonia' },
    { id: 'Plymouth, United Kingdom', name: 'Plymouth, United Kingdom' },
    {
        id: "Sverdlovskaya Oblast', Russia",
        name: "Sverdlovskaya Oblast', Russia"
    },
    { id: 'Jaen, Spain', name: 'Jaen, Spain' },
    { id: 'Kobilje, Slovenia', name: 'Kobilje, Slovenia' },
    {
        id: 'Flemish Brabant Province, Belgium',
        name: 'Flemish Brabant Province, Belgium'
    },
    {
        id: 'Abu Dhabi, United Arab Emirates',
        name: 'Abu Dhabi, United Arab Emirates'
    },
    {
        id: 'West Flanders Province, Belgium',
        name: 'West Flanders Province, Belgium'
    },
    { id: 'Hrpelje-Kozina, Slovenia', name: 'Hrpelje-Kozina, Slovenia' },
    {
        id: 'Cerklje na Gorenjskem, Slovenia',
        name: 'Cerklje na Gorenjskem, Slovenia'
    },
    { id: 'West Java, Indonesia', name: 'West Java, Indonesia' },
    { id: 'Haute-Loire, France', name: 'Haute-Loire, France' },
    {
        id: 'Changwat Sakon Nakhon, Thailand',
        name: 'Changwat Sakon Nakhon, Thailand'
    },
    {
        id: 'Ribnica na Pohorju, Slovenia',
        name: 'Ribnica na Pohorju, Slovenia'
    },
    { id: 'Ömnögovĭ, Mongolia', name: 'Ömnögovĭ, Mongolia' },
    { id: 'Simiyu, Tanzania', name: 'Simiyu, Tanzania' },
    {
        id: 'Western Isles, United Kingdom',
        name: 'Western Isles, United Kingdom'
    },
    {
        id: 'Parish of Saint John, Antigua and Barbuda',
        name: 'Parish of Saint John, Antigua and Barbuda'
    },
    { id: 'Qarku i Lezhes, Albania', name: 'Qarku i Lezhes, Albania' },
    { id: 'Mubārak al Kabīr, Kuwait', name: 'Mubārak al Kabīr, Kuwait' },
    { id: 'Bedford, United Kingdom', name: 'Bedford, United Kingdom' },
    { id: 'Kungota, Slovenia', name: 'Kungota, Slovenia' },
    { id: 'Antwerp Province, Belgium', name: 'Antwerp Province, Belgium' },
    { id: 'Zlín District, Czechia', name: 'Zlín District, Czechia' },
    {
        id: 'Provincia di Imperia, Italy',
        name: 'Provincia di Imperia, Italy'
    },
    { id: 'Rajbari, Bangladesh', name: 'Rajbari, Bangladesh' },
    { id: 'Labuan, Malaysia', name: 'Labuan, Malaysia' },
    {
        id: 'Departamento de Norte de Santander, Colombia',
        name: 'Departamento de Norte de Santander, Colombia'
    },
    { id: 'Liepaja, Latvia', name: 'Liepaja, Latvia' },
    { id: 'Central River, Gambia', name: 'Central River, Gambia' },
    { id: 'Jaunpiebalga, Latvia', name: 'Jaunpiebalga, Latvia' },
    {
        id: 'Provincia de Pedernales, Dominican Republic',
        name: 'Provincia de Pedernales, Dominican Republic'
    },
    {
        id: 'Santiago Metropolitan, Chile',
        name: 'Santiago Metropolitan, Chile'
    },
    { id: 'Fergana, Uzbekistan', name: 'Fergana, Uzbekistan' },
    { id: 'Moscow Oblast, Russia', name: 'Moscow Oblast, Russia' },
    {
        id: 'Western Australia, Australia',
        name: 'Western Australia, Australia'
    },
    { id: 'Bern, Switzerland', name: 'Bern, Switzerland' },
    {
        id: 'Black Point District, Bahamas',
        name: 'Black Point District, Bahamas'
    },
    { id: 'Lasithi, Greece', name: 'Lasithi, Greece' },
    {
        id: 'Changwat Prachuap Khiri Khan, Thailand',
        name: 'Changwat Prachuap Khiri Khan, Thailand'
    },
    { id: 'Limbazu Rajons, Latvia', name: 'Limbazu Rajons, Latvia' },
    { id: 'Kandahar, Afghanistan', name: 'Kandahar, Afghanistan' },
    { id: 'Al Bahah, Saudi Arabia', name: 'Al Bahah, Saudi Arabia' },
    {
        id: 'Southeastern Governorate, Oman',
        name: 'Southeastern Governorate, Oman'
    },
    {
        id: 'Švenčionys District Municipality, Republic of Lithuania',
        name: 'Švenčionys District Municipality, Republic of Lithuania'
    },
    {
        id: 'Region de Sedhiou, Senegal',
        name: 'Region de Sedhiou, Senegal'
    },
    { id: 'Oblast Dobrich, Bulgaria', name: 'Oblast Dobrich, Bulgaria' },
    {
        id: 'Changwat Phangnga, Thailand',
        name: 'Changwat Phangnga, Thailand'
    },
    { id: 'Brakna, Mauritania', name: 'Brakna, Mauritania' },
    { id: 'Estuaire, Gabon', name: 'Estuaire, Gabon' },
    { id: 'Rrethi i Korces, Albania', name: 'Rrethi i Korces, Albania' },
    { id: 'Northern Savo, Finland', name: 'Northern Savo, Finland' },
    { id: 'Northern Darfur, Sudan', name: 'Northern Darfur, Sudan' },
    {
        id: 'Baja California Sur, Mexico',
        name: 'Baja California Sur, Mexico'
    },
    { id: 'Rize, Turkey', name: 'Rize, Turkey' },
    { id: 'Boke Prefecture, Guinea', name: 'Boke Prefecture, Guinea' },
    { id: 'Slough, United Kingdom', name: 'Slough, United Kingdom' },
    { id: 'Isfahan, Iran', name: 'Isfahan, Iran' },
    {
        id: 'Obcina Smarje pri Jelsah, Slovenia',
        name: 'Obcina Smarje pri Jelsah, Slovenia'
    },
    { id: "Sha'biyat Sabha, Libya", name: "Sha'biyat Sabha, Libya" },
    { id: 'Duezce, Turkey', name: 'Duezce, Turkey' },
    {
        id: 'Buenos Aires F.D., Argentina',
        name: 'Buenos Aires F.D., Argentina'
    },
    { id: 'In-Nadur, Malta', name: 'In-Nadur, Malta' },
    {
        id: 'Northern Cape, South Africa',
        name: 'Northern Cape, South Africa'
    },
    { id: 'Shaanxi, China', name: 'Shaanxi, China' },
    { id: 'Para, Brazil', name: 'Para, Brazil' },
    {
        id: 'Masvingo Province, Zimbabwe',
        name: 'Masvingo Province, Zimbabwe'
    },
    { id: 'Paro, Bhutan', name: 'Paro, Bhutan' },
    { id: 'Heves megye, Hungary', name: 'Heves megye, Hungary' },
    {
        id: 'Greater Accra Region, Ghana',
        name: 'Greater Accra Region, Ghana'
    },
    { id: 'Relizane, Algeria', name: 'Relizane, Algeria' },
    {
        id: 'Provincia de Napo, Ecuador',
        name: 'Provincia de Napo, Ecuador'
    },
    {
        id: 'Departamento de Boyaca, Colombia',
        name: 'Departamento de Boyaca, Colombia'
    },
    { id: 'Gostivar, Macedonia', name: 'Gostivar, Macedonia' },
    { id: 'Manipur, India', name: 'Manipur, India' },
    {
        id: 'Obcina Kidricevo, Slovenia',
        name: 'Obcina Kidricevo, Slovenia'
    },
    { id: 'Kayanza Province, Burundi', name: 'Kayanza Province, Burundi' },
    {
        id: 'Obcina Razkrizje, Slovenia',
        name: 'Obcina Razkrizje, Slovenia'
    },
    {
        id: 'Southampton, United Kingdom',
        name: 'Southampton, United Kingdom'
    },
    { id: 'Murmansk, Russia', name: 'Murmansk, Russia' },
    { id: 'Deux-Sèvres, France', name: 'Deux-Sèvres, France' },
    { id: 'Mohammedia, Morocco', name: 'Mohammedia, Morocco' },
    {
        id: 'Southern Province, Sierra Leone',
        name: 'Southern Province, Sierra Leone'
    },
    { id: 'Judetul Sibiu, Romania', name: 'Judetul Sibiu, Romania' },
    { id: 'Upper West Region, Ghana', name: 'Upper West Region, Ghana' },
    { id: 'Dodecanese, Greece', name: 'Dodecanese, Greece' },
    { id: 'Florida, Uruguay', name: 'Florida, Uruguay' },
    { id: 'Valgamaa, Estonia', name: 'Valgamaa, Estonia' },
    { id: 'Flacq District, Mauritius', name: 'Flacq District, Mauritius' },
    {
        id: 'Zarasai District Municipality, Republic of Lithuania',
        name: 'Zarasai District Municipality, Republic of Lithuania'
    },
    { id: 'Embera-Wounaan, Panama', name: 'Embera-Wounaan, Panama' },
    { id: 'Ariège, France', name: 'Ariège, France' },
    {
        id: 'Muhafazat al Muthanna, Iraq',
        name: 'Muhafazat al Muthanna, Iraq'
    },
    { id: 'Muhafazat Arbil, Iraq', name: 'Muhafazat Arbil, Iraq' },
    { id: 'Balearic Islands, Spain', name: 'Balearic Islands, Spain' },
    {
        id: 'Osjecko-Baranjska Zupanija, Croatia',
        name: 'Osjecko-Baranjska Zupanija, Croatia'
    },
    { id: 'Tarrafal, Cabo Verde', name: 'Tarrafal, Cabo Verde' },
    {
        id: 'Ma’an, Hashemite Kingdom of Jordan',
        name: 'Ma’an, Hashemite Kingdom of Jordan'
    },
    { id: 'Ta‘izz, Yemen', name: 'Ta‘izz, Yemen' },
    { id: 'Harjumaa, Estonia', name: 'Harjumaa, Estonia' },
    { id: 'The Marches, Italy', name: 'The Marches, Italy' },
    {
        id: 'Kayin State, Myanmar [Burma]',
        name: 'Kayin State, Myanmar [Burma]'
    },
    { id: 'Pursat, Cambodia', name: 'Pursat, Cambodia' },
    { id: 'Viseu, Portugal', name: 'Viseu, Portugal' },
    {
        id: 'Ningsia Hui Autonomous Region, China',
        name: 'Ningsia Hui Autonomous Region, China'
    },
    { id: 'Kırıkkale, Turkey', name: 'Kırıkkale, Turkey' },
    {
        id: 'Alytus City Municipality, Republic of Lithuania',
        name: 'Alytus City Municipality, Republic of Lithuania'
    },
    {
        id: 'Aberdeen City, United Kingdom',
        name: 'Aberdeen City, United Kingdom'
    },
    { id: 'Torbay, United Kingdom', name: 'Torbay, United Kingdom' },
    { id: 'Maine-et-Loire, France', name: 'Maine-et-Loire, France' },
    {
        id: 'Provincia de Azua, Dominican Republic',
        name: 'Provincia de Azua, Dominican Republic'
    },
    {
        id: 'Barking and Dagenham, United Kingdom',
        name: 'Barking and Dagenham, United Kingdom'
    },
    { id: 'Podlehnik, Slovenia', name: 'Podlehnik, Slovenia' },
    { id: 'Mongala, Congo', name: 'Mongala, Congo' },
    {
        id: 'Megimurska Zupanija, Croatia',
        name: 'Megimurska Zupanija, Croatia'
    },
    { id: 'Nomos Lesvou, Greece', name: 'Nomos Lesvou, Greece' },
    {
        id: 'Respublika Buryatiya, Russia',
        name: 'Respublika Buryatiya, Russia'
    },
    {
        id: 'Ismailia Governorate, Egypt',
        name: 'Ismailia Governorate, Egypt'
    },
    { id: 'Qeqqata, Greenland', name: 'Qeqqata, Greenland' },
    { id: 'Lubusz, Poland', name: 'Lubusz, Poland' },
    {
        id: 'City of Freeport District, Bahamas',
        name: 'City of Freeport District, Bahamas'
    },
    { id: 'Guerrero, Mexico', name: 'Guerrero, Mexico' },
    { id: 'Vitebsk, Belarus', name: 'Vitebsk, Belarus' },
    {
        id: 'Provincia di Teramo, Italy',
        name: 'Provincia di Teramo, Italy'
    },
    { id: 'Embu District, Kenya', name: 'Embu District, Kenya' },
    { id: 'Khartoum, Sudan', name: 'Khartoum, Sudan' },
    { id: 'Kayseri, Turkey', name: 'Kayseri, Turkey' },
    { id: 'ARMM, Philippines', name: 'ARMM, Philippines' },
    { id: 'Rajasthan, India', name: 'Rajasthan, India' },
    { id: 'Uşak, Turkey', name: 'Uşak, Turkey' },
    { id: 'Littoral, Benin', name: 'Littoral, Benin' },
    { id: 'Bakool, Somalia', name: 'Bakool, Somalia' },
    { id: 'Quezon, Philippines', name: 'Quezon, Philippines' },
    { id: 'Region del Biobio, Chile', name: 'Region del Biobio, Chile' },
    { id: 'Garissa District, Kenya', name: 'Garissa District, Kenya' },
    { id: 'Allier, France', name: 'Allier, France' },
    { id: 'Lija, Malta', name: 'Lija, Malta' },
    { id: 'Agadez, Niger', name: 'Agadez, Niger' },
    {
        id: 'Kaunas, Republic of Lithuania',
        name: 'Kaunas, Republic of Lithuania'
    },
    { id: 'Stockholm, Sweden', name: 'Stockholm, Sweden' },
    { id: 'Belait District, Brunei', name: 'Belait District, Brunei' },
    {
        id: 'Sassandra-Marahoue, Ivory Coast',
        name: 'Sassandra-Marahoue, Ivory Coast'
    },
    { id: 'Oblast Smolyan, Bulgaria', name: 'Oblast Smolyan, Bulgaria' },
    {
        id: 'Strășeni, Republic of Moldova',
        name: 'Strășeni, Republic of Moldova'
    },
    { id: 'Luanda Province, Angola', name: 'Luanda Province, Angola' },
    { id: 'Dinajpur, Bangladesh', name: 'Dinajpur, Bangladesh' },
    { id: 'Ostan-e Ilam, Iran', name: 'Ostan-e Ilam, Iran' },
    { id: 'Bonaire, Bonaire', name: 'Bonaire, Bonaire' },
    { id: 'Oblast Montana, Bulgaria', name: 'Oblast Montana, Bulgaria' },
    { id: 'Macuata Province, Fiji', name: 'Macuata Province, Fiji' },
    { id: 'Cova Lima, East Timor', name: 'Cova Lima, East Timor' },
    { id: 'Opava District, Czechia', name: 'Opava District, Czechia' },
    {
        id: 'Departamento del Choco, Colombia',
        name: 'Departamento del Choco, Colombia'
    },
    {
        id: 'Provincia di Foggia, Italy',
        name: 'Provincia di Foggia, Italy'
    },
    { id: 'Uasin Gishu, Kenya', name: 'Uasin Gishu, Kenya' },
    { id: 'Hodos, Slovenia', name: 'Hodos, Slovenia' },
    { id: 'Olt, Romania', name: 'Olt, Romania' },
    { id: 'Triesen, Liechtenstein', name: 'Triesen, Liechtenstein' },
    { id: 'Aïn Defla, Algeria', name: 'Aïn Defla, Algeria' },
    { id: 'Muhafazat Wasit, Iraq', name: 'Muhafazat Wasit, Iraq' },
    { id: 'Aisne, France', name: 'Aisne, France' },
    { id: 'Hokkaido, Japan', name: 'Hokkaido, Japan' },
    {
        id: 'East Grand Bahama District, Bahamas',
        name: 'East Grand Bahama District, Bahamas'
    },
    {
        id: "Magadanskaya Oblast', Russia",
        name: "Magadanskaya Oblast', Russia"
    },
    { id: 'Blagoevgrad, Bulgaria', name: 'Blagoevgrad, Bulgaria' },
    { id: 'Colonia, Uruguay', name: 'Colonia, Uruguay' },
    { id: 'Judetul Brasov, Romania', name: 'Judetul Brasov, Romania' },
    { id: 'Chiba, Japan', name: 'Chiba, Japan' },
    {
        id: 'The Scottish Borders, United Kingdom',
        name: 'The Scottish Borders, United Kingdom'
    },
    {
        id: 'Ards and North Down, United Kingdom',
        name: 'Ards and North Down, United Kingdom'
    },
    {
        id: 'Saldus Municipality, Latvia',
        name: 'Saldus Municipality, Latvia'
    },
    { id: 'Oblast Yambol, Bulgaria', name: 'Oblast Yambol, Bulgaria' },
    { id: 'Örebro, Sweden', name: 'Örebro, Sweden' },
    { id: 'Ramallah, Palestine', name: 'Ramallah, Palestine' },
    {
        id: 'Province of Sabaragamuwa, Sri Lanka',
        name: 'Province of Sabaragamuwa, Sri Lanka'
    },
    {
        id: 'Province of Bulacan, Philippines',
        name: 'Province of Bulacan, Philippines'
    },
    {
        id: "Sakhalinskaya Oblast', Russia",
        name: "Sakhalinskaya Oblast', Russia"
    },
    { id: 'Dikhil, Djibouti', name: 'Dikhil, Djibouti' },
    { id: 'Tombouctou, Mali', name: 'Tombouctou, Mali' },
    { id: 'Bouches-du-Rhône, France', name: 'Bouches-du-Rhône, France' },
    { id: 'Tshuapa, Congo', name: 'Tshuapa, Congo' },
    {
        id: 'Provincia Sanchez Ramirez, Dominican Republic',
        name: 'Provincia Sanchez Ramirez, Dominican Republic'
    },
    { id: 'Centre, Haiti', name: 'Centre, Haiti' },
    { id: 'Kyiv City, Ukraine', name: 'Kyiv City, Ukraine' },
    { id: 'Dodoma, Tanzania', name: 'Dodoma, Tanzania' },
    { id: 'Zaporizhia, Ukraine', name: 'Zaporizhia, Ukraine' },
    { id: 'County Wicklow, Ireland', name: 'County Wicklow, Ireland' },
    { id: 'Hackney, United Kingdom', name: 'Hackney, United Kingdom' },
    { id: 'Raska, Serbia', name: 'Raska, Serbia' },
    {
        id: 'Province of Camarines Sur, Philippines',
        name: 'Province of Camarines Sur, Philippines'
    },
    { id: 'Pembroke, Malta', name: 'Pembroke, Malta' },
    { id: 'Al Batinah South, Oman', name: 'Al Batinah South, Oman' },
    { id: 'Hamilton city, Bermuda', name: 'Hamilton city, Bermuda' },
    { id: 'Praha 4, Czechia', name: 'Praha 4, Czechia' },
    { id: 'Šumperk District, Czechia', name: 'Šumperk District, Czechia' },
    { id: 'North Eleuthera, Bahamas', name: 'North Eleuthera, Bahamas' },
    { id: "Omskaya Oblast', Russia", name: "Omskaya Oblast', Russia" },
    { id: 'Harbour Island, Bahamas', name: 'Harbour Island, Bahamas' },
    {
        id: 'Vilkaviskis District Municipality, Republic of Lithuania',
        name: 'Vilkaviskis District Municipality, Republic of Lithuania'
    },
    { id: 'Zulia, Venezuela', name: 'Zulia, Venezuela' },
    {
        id: 'Chatham Islands, New Zealand',
        name: 'Chatham Islands, New Zealand'
    },
    { id: 'Kars, Turkey', name: 'Kars, Turkey' },
    {
        id: 'Eastern Visayas, Philippines',
        name: 'Eastern Visayas, Philippines'
    },
    {
        id: 'South Governorate, Lebanon',
        name: 'South Governorate, Lebanon'
    },
    {
        id: 'Lincolnshire, United Kingdom',
        name: 'Lincolnshire, United Kingdom'
    },
    { id: 'Obcina Zalec, Slovenia', name: 'Obcina Zalec, Slovenia' },
    { id: 'Tibesti Region, Chad', name: 'Tibesti Region, Chad' },
    {
        id: 'Krapinsko-Zagorska Zupanija, Croatia',
        name: 'Krapinsko-Zagorska Zupanija, Croatia'
    },
    {
        id: 'Central Abaco District, Bahamas',
        name: 'Central Abaco District, Bahamas'
    },
    { id: 'Nomos Larisis, Greece', name: 'Nomos Larisis, Greece' },
    {
        id: 'Lisburn and Castlereagh, United Kingdom',
        name: 'Lisburn and Castlereagh, United Kingdom'
    },
    { id: 'Kara, Togo', name: 'Kara, Togo' },
    { id: 'Jerusalem, Israel', name: 'Jerusalem, Israel' },
    { id: 'Izmir, Turkey', name: 'Izmir, Turkey' },
    { id: 'Nelson, New Zealand', name: 'Nelson, New Zealand' },
    { id: 'Saint-Louis, Senegal', name: 'Saint-Louis, Senegal' },
    {
        id: 'Salacgrivas Novads, Latvia',
        name: 'Salacgrivas Novads, Latvia'
    },
    { id: 'Guna Yala, Panama', name: 'Guna Yala, Panama' },
    { id: 'Bremen, Germany', name: 'Bremen, Germany' },
    { id: 'Delta, Nigeria', name: 'Delta, Nigeria' },
    { id: 'Ammochostos, Cyprus', name: 'Ammochostos, Cyprus' },
    {
        id: 'Changwat Sukhothai, Thailand',
        name: 'Changwat Sukhothai, Thailand'
    },
    { id: 'Takeo, Cambodia', name: 'Takeo, Cambodia' },
    {
        id: 'North Province, New Caledonia',
        name: 'North Province, New Caledonia'
    },
    { id: 'Land Berlin, Germany', name: 'Land Berlin, Germany' },
    { id: 'Tamil Nadu, India', name: 'Tamil Nadu, India' },
    { id: 'Cetinje, Montenegro', name: 'Cetinje, Montenegro' },
    {
        id: 'Respublika Adygeya, Russia',
        name: 'Respublika Adygeya, Russia'
    },
    { id: 'Tafea Province, Vanuatu', name: 'Tafea Province, Vanuatu' },
    { id: 'Pembroke Parish, Bermuda', name: 'Pembroke Parish, Bermuda' },
    {
        id: 'Changwat Suphan Buri, Thailand',
        name: 'Changwat Suphan Buri, Thailand'
    },
    {
        id: 'Southampton Parish, Bermuda',
        name: 'Southampton Parish, Bermuda'
    },
    {
        id: 'Saint Peter Basseterre, St Kitts and Nevis',
        name: 'Saint Peter Basseterre, St Kitts and Nevis'
    },
    { id: 'Stockport, United Kingdom', name: 'Stockport, United Kingdom' },
    {
        id: 'Demerara-Mahaica Region, Guyana',
        name: 'Demerara-Mahaica Region, Guyana'
    },
    { id: 'Lääne, Estonia', name: 'Lääne, Estonia' },
    { id: 'West Bengal, India', name: 'West Bengal, India' },
    {
        id: 'Toshkent Viloyati, Uzbekistan',
        name: 'Toshkent Viloyati, Uzbekistan'
    },
    {
        id: 'Biržai District Municipality, Republic of Lithuania',
        name: 'Biržai District Municipality, Republic of Lithuania'
    },
    { id: 'Kozani, Greece', name: 'Kozani, Greece' },
    {
        id: 'Provincia de Puntarenas, Costa Rica',
        name: 'Provincia de Puntarenas, Costa Rica'
    },
    {
        id: 'Hertfordshire, United Kingdom',
        name: 'Hertfordshire, United Kingdom'
    },
    { id: 'Ilocos, Philippines', name: 'Ilocos, Philippines' },
    { id: 'Fukuoka, Japan', name: 'Fukuoka, Japan' },
    { id: 'Changwat Satun, Thailand', name: 'Changwat Satun, Thailand' },
    { id: 'Miyazaki, Japan', name: 'Miyazaki, Japan' },
    { id: 'Arad, Romania', name: 'Arad, Romania' },
    { id: 'Østfold, Norway', name: 'Østfold, Norway' },
    { id: 'Marsaskala, Malta', name: 'Marsaskala, Malta' },
    {
        id: 'Gnyaviyani Atoll, Maldives',
        name: 'Gnyaviyani Atoll, Maldives'
    },
    { id: 'County Roscommon, Ireland', name: 'County Roscommon, Ireland' },
    {
        id: 'Milton Keynes, United Kingdom',
        name: 'Milton Keynes, United Kingdom'
    },
    {
        id: 'Strakonice District, Czechia',
        name: 'Strakonice District, Czechia'
    },
    { id: 'Piaui, Brazil', name: 'Piaui, Brazil' },
    {
        id: 'Waltham Forest, United Kingdom',
        name: 'Waltham Forest, United Kingdom'
    },
    { id: 'Ucayali, Peru', name: 'Ucayali, Peru' },
    { id: 'Tabor, Slovenia', name: 'Tabor, Slovenia' },
    { id: 'Ain, France', name: 'Ain, France' },
    {
        id: 'Racha-Lechkhumi and Kvemo Svaneti, Georgia',
        name: 'Racha-Lechkhumi and Kvemo Svaneti, Georgia'
    },
    { id: 'Mascara, Algeria', name: 'Mascara, Algeria' },
    {
        id: 'Province du Nord-Ubangi, Congo',
        name: 'Province du Nord-Ubangi, Congo'
    },
    { id: 'Táchira, Venezuela', name: 'Táchira, Venezuela' },
    { id: 'Nomos Artas, Greece', name: 'Nomos Artas, Greece' },
    {
        id: 'Changwat Ratchaburi, Thailand',
        name: 'Changwat Ratchaburi, Thailand'
    },
    { id: 'North Denmark, Denmark', name: 'North Denmark, Denmark' },
    { id: 'Jura, Switzerland', name: 'Jura, Switzerland' },
    { id: 'Alpes-Maritimes, France', name: 'Alpes-Maritimes, France' },
    { id: 'Attapu, Laos', name: 'Attapu, Laos' },
    {
        id: 'Panevėžys City, Republic of Lithuania',
        name: 'Panevėžys City, Republic of Lithuania'
    },
    {
        id: 'Kostanjevica na Krki, Slovenia',
        name: 'Kostanjevica na Krki, Slovenia'
    },
    {
        id: "Departement de l'Artibonite, Haiti",
        name: "Departement de l'Artibonite, Haiti"
    },
    {
        id: 'Departamento de Baja Verapaz, Guatemala',
        name: 'Departamento de Baja Verapaz, Guatemala'
    },
    {
        id: 'Savanne District, Mauritius',
        name: 'Savanne District, Mauritius'
    },
    { id: 'South Denmark, Denmark', name: 'South Denmark, Denmark' },
    { id: 'Split-Dalmatia, Croatia', name: 'Split-Dalmatia, Croatia' },
    { id: 'Kabale District, Uganda', name: 'Kabale District, Uganda' },
    {
        id: 'West Kordofan State, Sudan',
        name: 'West Kordofan State, Sudan'
    },
    { id: 'Samos, Greece', name: 'Samos, Greece' },
    {
        id: 'Bujumbura Rural Province, Burundi',
        name: 'Bujumbura Rural Province, Burundi'
    },
    { id: 'Ruvuma, Tanzania', name: 'Ruvuma, Tanzania' },
    { id: 'Tiaret, Algeria', name: 'Tiaret, Algeria' },
    { id: 'Tinh Quang Tri, Vietnam', name: 'Tinh Quang Tri, Vietnam' },
    { id: 'Bel Ombre, Seychelles', name: 'Bel Ombre, Seychelles' },
    {
        id: 'Jeollabuk-do, Republic of Korea',
        name: 'Jeollabuk-do, Republic of Korea'
    },
    { id: 'Podlasie, Poland', name: 'Podlasie, Poland' },
    {
        id: 'Bangka–Belitung Islands, Indonesia',
        name: 'Bangka–Belitung Islands, Indonesia'
    },
    { id: 'Melaka, Malaysia', name: 'Melaka, Malaysia' },
    { id: 'Tinh GJong Thap, Vietnam', name: 'Tinh GJong Thap, Vietnam' },
    { id: 'Tarragona, Spain', name: 'Tarragona, Spain' },
    {
        id: 'Departamento de San Miguel, El Salvador',
        name: 'Departamento de San Miguel, El Salvador'
    },
    {
        id: 'Australian Capital Territory, Australia',
        name: 'Australian Capital Territory, Australia'
    },
    { id: 'Kishoregonj, Bangladesh', name: 'Kishoregonj, Bangladesh' },
    { id: 'Dakar, Senegal', name: 'Dakar, Senegal' },
    { id: 'Judetul Ialomita, Romania', name: 'Judetul Ialomita, Romania' },
    { id: 'Espirito Santo, Brazil', name: 'Espirito Santo, Brazil' },
    {
        id: 'Worcestershire, United Kingdom',
        name: 'Worcestershire, United Kingdom'
    },
    { id: 'Westmoreland, Jamaica', name: 'Westmoreland, Jamaica' },
    { id: 'Dzavhan Aymag, Mongolia', name: 'Dzavhan Aymag, Mongolia' },
    { id: 'Palmerston, Cook Islands', name: 'Palmerston, Cook Islands' },
    { id: 'Nukunonu, Tokelau', name: 'Nukunonu, Tokelau' },
    { id: 'Seoul, Republic of Korea', name: 'Seoul, Republic of Korea' },
    {
        id: 'Newfoundland and Labrador, Canada',
        name: 'Newfoundland and Labrador, Canada'
    },
    { id: 'Galguduud, Somalia', name: 'Galguduud, Somalia' },
    { id: 'Moscow, Russia', name: 'Moscow, Russia' },
    { id: 'L-Imqabba, Malta', name: 'L-Imqabba, Malta' },
    {
        id: 'Departamento de Huehuetenango, Guatemala',
        name: 'Departamento de Huehuetenango, Guatemala'
    },
    { id: 'Zhejiang, China', name: 'Zhejiang, China' },
    {
        id: 'Province du Haut-Katanga, Congo',
        name: 'Province du Haut-Katanga, Congo'
    },
    {
        id: 'Provincie Gelderland, Netherlands',
        name: 'Provincie Gelderland, Netherlands'
    },
    {
        id: 'Antrim and Newtownabbey, United Kingdom',
        name: 'Antrim and Newtownabbey, United Kingdom'
    },
    { id: 'Moxico, Angola', name: 'Moxico, Angola' },
    { id: 'Republic of Tyva, Russia', name: 'Republic of Tyva, Russia' },
    { id: 'Viqueque, East Timor', name: 'Viqueque, East Timor' },
    { id: 'Laborie, Saint Lucia', name: 'Laborie, Saint Lucia' },
    {
        id: 'Slovenska Bistrica, Slovenia',
        name: 'Slovenska Bistrica, Slovenia'
    },
    { id: 'Bor, Serbia', name: 'Bor, Serbia' },
    { id: 'Cascade, Seychelles', name: 'Cascade, Seychelles' },
    {
        id: 'Provincia di Ravenna, Italy',
        name: 'Provincia di Ravenna, Italy'
    },
    { id: 'Okres Kolin, Czechia', name: 'Okres Kolin, Czechia' },
    {
        id: 'Provincia de Santa Elena, Ecuador',
        name: 'Provincia de Santa Elena, Ecuador'
    },
    {
        id: 'Samegrelo and Zemo Svaneti, Georgia',
        name: 'Samegrelo and Zemo Svaneti, Georgia'
    },
    { id: 'Fujian, China', name: 'Fujian, China' },
    { id: 'Attica, Greece', name: 'Attica, Greece' },
    { id: 'Abia State, Nigeria', name: 'Abia State, Nigeria' },
    { id: 'Lenart, Slovenia', name: 'Lenart, Slovenia' },
    {
        id: 'Plaines Wilhems District, Mauritius',
        name: 'Plaines Wilhems District, Mauritius'
    },
    {
        id: 'South Kalimantan, Indonesia',
        name: 'South Kalimantan, Indonesia'
    },
    {
        id: 'Saint John Figtree, St Kitts and Nevis',
        name: 'Saint John Figtree, St Kitts and Nevis'
    },
    {
        id: 'Provincia de Monte Plata, Dominican Republic',
        name: 'Provincia de Monte Plata, Dominican Republic'
    },
    { id: 'Balıkesir, Turkey', name: 'Balıkesir, Turkey' },
    {
        id: 'Provincia de Manabi, Ecuador',
        name: 'Provincia de Manabi, Ecuador'
    },
    {
        id: 'Departamento de Totonicapan, Guatemala',
        name: 'Departamento de Totonicapan, Guatemala'
    },
    { id: 'Busan, Republic of Korea', name: 'Busan, Republic of Korea' },
    {
        id: 'Monmouthshire, United Kingdom',
        name: 'Monmouthshire, United Kingdom'
    },
    { id: 'Bari, Somalia', name: 'Bari, Somalia' },
    { id: 'Essex, United Kingdom', name: 'Essex, United Kingdom' },
    { id: 'Manatuto, East Timor', name: 'Manatuto, East Timor' },
    {
        id: 'Chungcheongnam-do, Republic of Korea',
        name: 'Chungcheongnam-do, Republic of Korea'
    },
    { id: 'Pasco, Peru', name: 'Pasco, Peru' },
    { id: 'Yilan, Taiwan', name: 'Yilan, Taiwan' },
    {
        id: 'Primorsko-Goranska Zupanija, Croatia',
        name: 'Primorsko-Goranska Zupanija, Croatia'
    },
    { id: 'Grevena, Greece', name: 'Grevena, Greece' },
    { id: 'Seine-Maritime, France', name: 'Seine-Maritime, France' },
    { id: 'Tamaulipas, Mexico', name: 'Tamaulipas, Mexico' },
    { id: 'Manama, Bahrain', name: 'Manama, Bahrain' },
    { id: 'County Carlow, Ireland', name: 'County Carlow, Ireland' },
    { id: 'Bokeo, Laos', name: 'Bokeo, Laos' },
    {
        id: 'Province of Aklan, Philippines',
        name: 'Province of Aklan, Philippines'
    },
    {
        id: 'Pozesko-Slavonska Zupanija, Croatia',
        name: 'Pozesko-Slavonska Zupanija, Croatia'
    },
    { id: 'Saint Julian, Malta', name: 'Saint Julian, Malta' },
    { id: 'Pella, Greece', name: 'Pella, Greece' },
    { id: 'Charente, France', name: 'Charente, France' },
    { id: 'Zamfara State, Nigeria', name: 'Zamfara State, Nigeria' },
    { id: 'Tamanrasset, Algeria', name: 'Tamanrasset, Algeria' },
    { id: "Ta' Xbiex, Malta", name: "Ta' Xbiex, Malta" },
    {
        id: 'Mecklenburg-Vorpommern, Germany',
        name: 'Mecklenburg-Vorpommern, Germany'
    },
    {
        id: 'Changwat Nakhon Ratchasima, Thailand',
        name: 'Changwat Nakhon Ratchasima, Thailand'
    },
    { id: 'Grande Comore, Comoros', name: 'Grande Comore, Comoros' },
    {
        id: 'Province of Misamis Oriental, Philippines',
        name: 'Province of Misamis Oriental, Philippines'
    },
    { id: 'Tokat, Turkey', name: 'Tokat, Turkey' },
    { id: 'West, Iceland', name: 'West, Iceland' },
    { id: 'Ille-et-Vilaine, France', name: 'Ille-et-Vilaine, France' },
    { id: 'Yamagata, Japan', name: 'Yamagata, Japan' },
    { id: 'Jiangxi, China', name: 'Jiangxi, China' },
    { id: 'Saint Lucia, Malta', name: 'Saint Lucia, Malta' },
    {
        id: 'Provincia de Hato Mayor, Dominican Republic',
        name: 'Provincia de Hato Mayor, Dominican Republic'
    },
    { id: 'Batha Region, Chad', name: 'Batha Region, Chad' },
    { id: 'Sutton, United Kingdom', name: 'Sutton, United Kingdom' },
    { id: 'Serravalle, San Marino', name: 'Serravalle, San Marino' },
    {
        id: 'Iles Australes, French Polynesia',
        name: 'Iles Australes, French Polynesia'
    },
    { id: 'Nièvre, France', name: 'Nièvre, France' },
    { id: 'Ogooué-Lolo, Gabon', name: 'Ogooué-Lolo, Gabon' },
    { id: 'Djelfa, Algeria', name: 'Djelfa, Algeria' },
    { id: 'Tinh Hai Duong, Vietnam', name: 'Tinh Hai Duong, Vietnam' },
    { id: 'Central Java, Indonesia', name: 'Central Java, Indonesia' },
    { id: 'Náchod District, Czechia', name: 'Náchod District, Czechia' },
    { id: 'Grosuplje, Slovenia', name: 'Grosuplje, Slovenia' },
    {
        id: 'Departamento de Durazno, Uruguay',
        name: 'Departamento de Durazno, Uruguay'
    },
    { id: 'Larache, Morocco', name: 'Larache, Morocco' },
    { id: 'Tshopo, Congo', name: 'Tshopo, Congo' },
    {
        id: 'Provincia de Santiago Rodriguez, Dominican Republic',
        name: 'Provincia de Santiago Rodriguez, Dominican Republic'
    },
    { id: 'Vojnik, Slovenia', name: 'Vojnik, Slovenia' },
    { id: 'Anseba Region, Eritrea', name: 'Anseba Region, Eritrea' },
    {
        id: 'Kelmė District Municipality, Republic of Lithuania',
        name: 'Kelmė District Municipality, Republic of Lithuania'
    },
    { id: 'Beijing, China', name: 'Beijing, China' },
    { id: 'Taroudannt, Morocco', name: 'Taroudannt, Morocco' },
    { id: 'Annaba, Algeria', name: 'Annaba, Algeria' },
    { id: 'Saint George, Grenada', name: 'Saint George, Grenada' },
    {
        id: 'Kupiškis District Municipality, Republic of Lithuania',
        name: 'Kupiškis District Municipality, Republic of Lithuania'
    },
    {
        id: 'North Ayrshire, United Kingdom',
        name: 'North Ayrshire, United Kingdom'
    },
    { id: 'Catamarca, Argentina', name: 'Catamarca, Argentina' },
    { id: 'Pärnumaa, Estonia', name: 'Pärnumaa, Estonia' },
    {
        id: 'Port Louis District, Mauritius',
        name: 'Port Louis District, Mauritius'
    },
    { id: 'Leon, Spain', name: 'Leon, Spain' },
    { id: 'Sool, Somalia', name: 'Sool, Somalia' },
    {
        id: 'Province of Messina, Italy',
        name: 'Province of Messina, Italy'
    },
    { id: 'Nomos Kilkis, Greece', name: 'Nomos Kilkis, Greece' },
    { id: 'Setúbal, Portugal', name: 'Setúbal, Portugal' },
    {
        id: 'Gyeongsangnam-do, Republic of Korea',
        name: 'Gyeongsangnam-do, Republic of Korea'
    },
    {
        id: 'Oblast Veliko Tarnovo, Bulgaria',
        name: 'Oblast Veliko Tarnovo, Bulgaria'
    },
    {
        id: "Orenburgskaya Oblast', Russia",
        name: "Orenburgskaya Oblast', Russia"
    },
    { id: 'Cuando Cobango, Angola', name: 'Cuando Cobango, Angola' },
    { id: 'Markazi, Iran', name: 'Markazi, Iran' },
    { id: 'Judetul Mures, Romania', name: 'Judetul Mures, Romania' },
    { id: 'Sofia, Bulgaria', name: 'Sofia, Bulgaria' },
    { id: 'Rutland, United Kingdom', name: 'Rutland, United Kingdom' },
    {
        id: 'Central Province, Solomon Islands',
        name: 'Central Province, Solomon Islands'
    },
    { id: 'Saxony, Germany', name: 'Saxony, Germany' },
    {
        id: 'Friuli Venezia Giulia, Italy',
        name: 'Friuli Venezia Giulia, Italy'
    },
    { id: 'Bogota D.C., Colombia', name: 'Bogota D.C., Colombia' },
    { id: 'Ica, Peru', name: 'Ica, Peru' },
    { id: 'Andrijevica, Montenegro', name: 'Andrijevica, Montenegro' },
    {
        id: 'Province of Sultan Kudarat, Philippines',
        name: 'Province of Sultan Kudarat, Philippines'
    },
    { id: 'Baikonur, Kazakhstan', name: 'Baikonur, Kazakhstan' },
    { id: 'Mogilev, Belarus', name: 'Mogilev, Belarus' },
    { id: 'Tinh Vinh Long, Vietnam', name: 'Tinh Vinh Long, Vietnam' },
    { id: 'Lindi, Tanzania', name: 'Lindi, Tanzania' },
    { id: 'Northern Red Sea, Eritrea', name: 'Northern Red Sea, Eritrea' },
    { id: 'San Luis, Argentina', name: 'San Luis, Argentina' },
    {
        id: 'Karlovacka Zupanija, Croatia',
        name: 'Karlovacka Zupanija, Croatia'
    },
    {
        id: 'Changwat Pathum Thani, Thailand',
        name: 'Changwat Pathum Thani, Thailand'
    },
    { id: 'Évora, Portugal', name: 'Évora, Portugal' },
    {
        id: 'Jalal-Abad oblast, Kyrgyzstan',
        name: 'Jalal-Abad oblast, Kyrgyzstan'
    },
    { id: 'Ostan-e Hamadan, Iran', name: 'Ostan-e Hamadan, Iran' },
    { id: 'Ogooué-Ivindo, Gabon', name: 'Ogooué-Ivindo, Gabon' },
    { id: 'Keelung, Taiwan', name: 'Keelung, Taiwan' },
    { id: 'Kajiado District, Kenya', name: 'Kajiado District, Kenya' },
    {
        id: 'Hlavni mesto Praha, Czechia',
        name: 'Hlavni mesto Praha, Czechia'
    },
    { id: 'Chandigarh, India', name: 'Chandigarh, India' },
    {
        id: "Luhans'ka Oblast', Ukraine",
        name: "Luhans'ka Oblast', Ukraine"
    },
    {
        id: 'Panevėžys, Republic of Lithuania',
        name: 'Panevėžys, Republic of Lithuania'
    },
    { id: 'Ix-Xaghra, Malta', name: 'Ix-Xaghra, Malta' },
    {
        id: 'Provincia di Brescia, Italy',
        name: 'Provincia di Brescia, Italy'
    },
    { id: 'Falcón, Venezuela', name: 'Falcón, Venezuela' },
    { id: 'Carinthia, Austria', name: 'Carinthia, Austria' },
    { id: 'Brent, United Kingdom', name: 'Brent, United Kingdom' },
    { id: 'Västra Götaland, Sweden', name: 'Västra Götaland, Sweden' },
    { id: 'Tinh Tay Ninh, Vietnam', name: 'Tinh Tay Ninh, Vietnam' },
    {
        id: 'Western Area, Sierra Leone',
        name: 'Western Area, Sierra Leone'
    },
    { id: 'North Ossetia, Russia', name: 'North Ossetia, Russia' },
    {
        id: 'Sangre Grande, Trinidad and Tobago',
        name: 'Sangre Grande, Trinidad and Tobago'
    },
    { id: 'Saint Lucy, Barbados', name: 'Saint Lucy, Barbados' },
    {
        id: 'Departamento de Chinandega, Nicaragua',
        name: 'Departamento de Chinandega, Nicaragua'
    },
    { id: 'Béjaïa, Algeria', name: 'Béjaïa, Algeria' },
    {
        id: 'Mashonaland West, Zimbabwe',
        name: 'Mashonaland West, Zimbabwe'
    },
    { id: 'Vaisigano, Samoa', name: 'Vaisigano, Samoa' },
    {
        id: 'Koprivnicko-Krizevacka Zupanija, Croatia',
        name: 'Koprivnicko-Krizevacka Zupanija, Croatia'
    },
    {
        id: 'Muhafazat as Sulaymaniyah, Iraq',
        name: 'Muhafazat as Sulaymaniyah, Iraq'
    },
    { id: 'Yunlin County, Taiwan', name: 'Yunlin County, Taiwan' },
    {
        id: 'Obcina Sveti Tomaz, Slovenia',
        name: 'Obcina Sveti Tomaz, Slovenia'
    },
    { id: 'Murcia, Spain', name: 'Murcia, Spain' },
    {
        id: 'Rezina, Republic of Moldova',
        name: 'Rezina, Republic of Moldova'
    },
    { id: 'Eschen, Liechtenstein', name: 'Eschen, Liechtenstein' },
    {
        id: 'Departamento de Boqueron, Paraguay',
        name: 'Departamento de Boqueron, Paraguay'
    },
    { id: 'Zug, Switzerland', name: 'Zug, Switzerland' },
    { id: 'Derby, United Kingdom', name: 'Derby, United Kingdom' },
    { id: 'Liège Province, Belgium', name: 'Liège Province, Belgium' },
    { id: 'Příbram District, Czechia', name: 'Příbram District, Czechia' },
    { id: 'Gorod Bishkek, Kyrgyzstan', name: 'Gorod Bishkek, Kyrgyzstan' },
    { id: 'Obcina Starse, Slovenia', name: 'Obcina Starse, Slovenia' },
    { id: 'Kisumu, Kenya', name: 'Kisumu, Kenya' },
    {
        id: 'Changwat Chon Buri, Thailand',
        name: 'Changwat Chon Buri, Thailand'
    },
    {
        id: 'Western Highlands Province, Papua New Guinea',
        name: 'Western Highlands Province, Papua New Guinea'
    },
    { id: 'North Karelia, Finland', name: 'North Karelia, Finland' },
    {
        id: 'Departamento de La Libertad, El Salvador',
        name: 'Departamento de La Libertad, El Salvador'
    },
    { id: 'Banjul, Gambia', name: 'Banjul, Gambia' },
    { id: 'Kanal, Slovenia', name: 'Kanal, Slovenia' },
    { id: 'Nanumanga, Tuvalu', name: 'Nanumanga, Tuvalu' },
    { id: 'Nomos Kerkyras, Greece', name: 'Nomos Kerkyras, Greece' },
    { id: 'Mudug, Somalia', name: 'Mudug, Somalia' },
    { id: 'Anetan, Nauru', name: 'Anetan, Nauru' },
    { id: 'Hainaut Province, Belgium', name: 'Hainaut Province, Belgium' },
    { id: 'Vyškov District, Czechia', name: 'Vyškov District, Czechia' },
    { id: 'Benguela, Angola', name: 'Benguela, Angola' },
    {
        id: 'Zanzibar Central/South, Tanzania',
        name: 'Zanzibar Central/South, Tanzania'
    },
    {
        id: 'Litoral, Equatorial Guinea',
        name: 'Litoral, Equatorial Guinea'
    },
    { id: 'Tipaza, Algeria', name: 'Tipaza, Algeria' },
    { id: 'Mojkovac, Montenegro', name: 'Mojkovac, Montenegro' },
    { id: 'Clervaux, Luxembourg', name: 'Clervaux, Luxembourg' },
    { id: 'Dire Dawa, Ethiopia', name: 'Dire Dawa, Ethiopia' },
    { id: 'Nampula, Mozambique', name: 'Nampula, Mozambique' },
    { id: 'Haskovo, Bulgaria', name: 'Haskovo, Bulgaria' },
    {
        id: 'Languedoc-Roussillon, France',
        name: 'Languedoc-Roussillon, France'
    },
    { id: 'Vodice, Slovenia', name: 'Vodice, Slovenia' },
    { id: 'Friesland, Netherlands', name: 'Friesland, Netherlands' },
    { id: 'Osilnica, Slovenia', name: 'Osilnica, Slovenia' },
    { id: 'Merton, United Kingdom', name: 'Merton, United Kingdom' },
    {
        id: 'East Kalimantan, Indonesia',
        name: 'East Kalimantan, Indonesia'
    },
    {
        id: 'Rhondda Cynon Taf, United Kingdom',
        name: 'Rhondda Cynon Taf, United Kingdom'
    },
    { id: 'Gaziantep, Turkey', name: 'Gaziantep, Turkey' },
    {
        id: 'Carriacou and Petite Martinique, Grenada',
        name: 'Carriacou and Petite Martinique, Grenada'
    },
    {
        id: 'Rrethi i Tepelenes, Albania',
        name: 'Rrethi i Tepelenes, Albania'
    },
    {
        id: 'Departamento de Copan, Honduras',
        name: 'Departamento de Copan, Honduras'
    },
    {
        id: 'Kroměříž District, Czechia',
        name: 'Kroměříž District, Czechia'
    },
    {
        id: 'Provincia de Panama, Panama',
        name: 'Provincia de Panama, Panama'
    },
    {
        id: 'Distrikt Nickerie, Suriname',
        name: 'Distrikt Nickerie, Suriname'
    },
    { id: 'Shaki City, Azerbaijan', name: 'Shaki City, Azerbaijan' },
    {
        id: 'Nana-Mambéré, Central African Republic',
        name: 'Nana-Mambéré, Central African Republic'
    },
    { id: 'North Bank, Gambia', name: 'North Bank, Gambia' },
    { id: 'Sardinia, Italy', name: 'Sardinia, Italy' },
    { id: 'Gironde, France', name: 'Gironde, France' },
    { id: 'Sefton, United Kingdom', name: 'Sefton, United Kingdom' },
    { id: 'Kochi, Japan', name: 'Kochi, Japan' },
    { id: 'Bohinj, Slovenia', name: 'Bohinj, Slovenia' },
    { id: 'Valladolid, Spain', name: 'Valladolid, Spain' },
    {
        id: 'South-West Region, Cameroon',
        name: 'South-West Region, Cameroon'
    },
    { id: 'Saint Thomas, Jamaica', name: 'Saint Thomas, Jamaica' },
    { id: 'Louth, Ireland', name: 'Louth, Ireland' },
    {
        id: 'Manus Province, Papua New Guinea',
        name: 'Manus Province, Papua New Guinea'
    },
    { id: 'Saint Venera, Malta', name: 'Saint Venera, Malta' },
    {
        id: 'Gilgit-Baltistan, Pakistan',
        name: 'Gilgit-Baltistan, Pakistan'
    },
    { id: 'Guelmim, Morocco', name: 'Guelmim, Morocco' },
    { id: 'Obcina Domzale, Slovenia', name: 'Obcina Domzale, Slovenia' },
    { id: 'Qarku i Durresit, Albania', name: 'Qarku i Durresit, Albania' },
    { id: 'Tolna megye, Hungary', name: 'Tolna megye, Hungary' },
    {
        id: 'South Australia, Australia',
        name: 'South Australia, Australia'
    },
    {
        id: 'Province of Albay, Philippines',
        name: 'Province of Albay, Philippines'
    },
    {
        id: "Ra's al Khaymah, United Arab Emirates",
        name: "Ra's al Khaymah, United Arab Emirates"
    },
    { id: 'Goh-Djiboua, Ivory Coast', name: 'Goh-Djiboua, Ivory Coast' },
    {
        id: 'Departamento de Treinta y Tres, Uruguay',
        name: 'Departamento de Treinta y Tres, Uruguay'
    },
    { id: 'Laayoune, Morocco', name: 'Laayoune, Morocco' },
    { id: 'Lapland, Finland', name: 'Lapland, Finland' },
    { id: 'Karabuek, Turkey', name: 'Karabuek, Turkey' },
    { id: 'Co Kildare, Ireland', name: 'Co Kildare, Ireland' },
    { id: 'Murzuq, Libya', name: 'Murzuq, Libya' },
    { id: 'Viljandimaa, Estonia', name: 'Viljandimaa, Estonia' },
    { id: 'Ondo State, Nigeria', name: 'Ondo State, Nigeria' },
    { id: 'Saskatchewan, Canada', name: 'Saskatchewan, Canada' },
    {
        id: 'Vale of Glamorgan, United Kingdom',
        name: 'Vale of Glamorgan, United Kingdom'
    },
    {
        id: 'South Abaco District, Bahamas',
        name: 'South Abaco District, Bahamas'
    },
    { id: 'Il-Hamrun, Malta', name: 'Il-Hamrun, Malta' },
    { id: 'Boa Vista, Cabo Verde', name: 'Boa Vista, Cabo Verde' },
    {
        id: 'Khyber Pakhtunkhwa, Pakistan',
        name: 'Khyber Pakhtunkhwa, Pakistan'
    },
    { id: 'Gisborne, New Zealand', name: 'Gisborne, New Zealand' },
    {
        id: 'Calderdale, United Kingdom',
        name: 'Calderdale, United Kingdom'
    },
    {
        id: 'Cross River State, Nigeria',
        name: 'Cross River State, Nigeria'
    },
    {
        id: 'Provincia de Santiago, Dominican Republic',
        name: 'Provincia de Santiago, Dominican Republic'
    },
    {
        id: 'Gouvernorat de Sidi Bouzid, Tunisia',
        name: 'Gouvernorat de Sidi Bouzid, Tunisia'
    },
    { id: 'In-Naxxar, Malta', name: 'In-Naxxar, Malta' },
    { id: 'Aiga-i-le-Tai, Samoa', name: 'Aiga-i-le-Tai, Samoa' },
    { id: 'North Kordofan, Sudan', name: 'North Kordofan, Sudan' },
    {
        id: 'Raionul Dubasari, Republic of Moldova',
        name: 'Raionul Dubasari, Republic of Moldova'
    },
    {
        id: 'Departamento Central, Paraguay',
        name: 'Departamento Central, Paraguay'
    },
    { id: 'Sinaloa, Mexico', name: 'Sinaloa, Mexico' },
    { id: 'Cordoba, Spain', name: 'Cordoba, Spain' },
    { id: 'Jesenice, Slovenia', name: 'Jesenice, Slovenia' },
    {
        id: 'Province de la Tapoa, Burkina Faso',
        name: 'Province de la Tapoa, Burkina Faso'
    },
    { id: 'Extremadura, Spain', name: 'Extremadura, Spain' },
    { id: 'Iğdır, Turkey', name: 'Iğdır, Turkey' },
    { id: 'Qormi, Malta', name: 'Qormi, Malta' },
    {
        id: 'Provincia di Sassari, Italy',
        name: 'Provincia di Sassari, Italy'
    },
    {
        id: "Mohale's Hoek District, Lesotho",
        name: "Mohale's Hoek District, Lesotho"
    },
    {
        id: 'Tierra del Fuego, Argentina',
        name: 'Tierra del Fuego, Argentina'
    },
    { id: 'Obcina Brezice, Slovenia', name: 'Obcina Brezice, Slovenia' },
    {
        id: 'Provincia del Tungurahua, Ecuador',
        name: 'Provincia del Tungurahua, Ecuador'
    },
    { id: 'Obcina Ruse, Slovenia', name: 'Obcina Ruse, Slovenia' },
    { id: 'Baie Lazare, Seychelles', name: 'Baie Lazare, Seychelles' },
    {
        id: 'Bioko Norte, Equatorial Guinea',
        name: 'Bioko Norte, Equatorial Guinea'
    },
    {
        id: 'Nord-Trondelag Fylke, Norway',
        name: 'Nord-Trondelag Fylke, Norway'
    },
    { id: 'Kaolack, Senegal', name: 'Kaolack, Senegal' },
    { id: 'Otjozondjupa, Namibia', name: 'Otjozondjupa, Namibia' },
    { id: 'Plateaux, Togo', name: 'Plateaux, Togo' },
    { id: 'Nimba County, Liberia', name: 'Nimba County, Liberia' },
    { id: 'Gorgol, Mauritania', name: 'Gorgol, Mauritania' },
    {
        id: 'Appenzell Ausserrhoden, Switzerland',
        name: 'Appenzell Ausserrhoden, Switzerland'
    },
    {
        id: 'Distrikt Sipaliwini, Suriname',
        name: 'Distrikt Sipaliwini, Suriname'
    },
    { id: 'Primorskiy Kray, Russia', name: 'Primorskiy Kray, Russia' },
    { id: 'Querétaro, Mexico', name: 'Querétaro, Mexico' },
    { id: 'Khoueng Oudomxai, Laos', name: 'Khoueng Oudomxai, Laos' },
    { id: 'Kano State, Nigeria', name: 'Kano State, Nigeria' },
    {
        id: 'Departamento de Sacatepequez, Guatemala',
        name: 'Departamento de Sacatepequez, Guatemala'
    },
    { id: 'Nordland Fylke, Norway', name: 'Nordland Fylke, Norway' },
    { id: 'Erongo, Namibia', name: 'Erongo, Namibia' },
    {
        id: 'Provincia di Taranto, Italy',
        name: 'Provincia di Taranto, Italy'
    },
    { id: 'Ogooué-Maritime, Gabon', name: 'Ogooué-Maritime, Gabon' },
    { id: 'Gorje, Slovenia', name: 'Gorje, Slovenia' },
    {
        id: 'Atlantique Department, Benin',
        name: 'Atlantique Department, Benin'
    },
    {
        id: 'Nay Pyi Taw, Myanmar [Burma]',
        name: 'Nay Pyi Taw, Myanmar [Burma]'
    },
    { id: 'A Coruña, Spain', name: 'A Coruña, Spain' },
    {
        id: 'Provincia de Baoruco, Dominican Republic',
        name: 'Provincia de Baoruco, Dominican Republic'
    },
    { id: 'Moselle, France', name: 'Moselle, France' },
    { id: 'Tarn-et-Garonne, France', name: 'Tarn-et-Garonne, France' },
    { id: 'Kilis, Turkey', name: 'Kilis, Turkey' },
    { id: 'Finistère, France', name: 'Finistère, France' },
    { id: 'Labe Prefecture, Guinea', name: 'Labe Prefecture, Guinea' },
    { id: 'West Sumatra, Indonesia', name: 'West Sumatra, Indonesia' },
    { id: 'Banska Bystrica, Slovakia', name: 'Banska Bystrica, Slovakia' },
    {
        id: 'Provincia del Chimborazo, Ecuador',
        name: 'Provincia del Chimborazo, Ecuador'
    },
    {
        id: 'Briceni, Republic of Moldova',
        name: 'Briceni, Republic of Moldova'
    },
    { id: 'Burgenland, Austria', name: 'Burgenland, Austria' },
    {
        id: "Vologodskaya Oblast', Russia",
        name: "Vologodskaya Oblast', Russia"
    },
    {
        id: 'Provincia di Matera, Italy',
        name: 'Provincia di Matera, Italy'
    },
    { id: 'Okres Jihlava, Czechia', name: 'Okres Jihlava, Czechia' },
    { id: 'Greenwich, United Kingdom', name: 'Greenwich, United Kingdom' },
    { id: 'Mondolkiri, Cambodia', name: 'Mondolkiri, Cambodia' },
    {
        id: 'Pavlodar Oblysy, Kazakhstan',
        name: 'Pavlodar Oblysy, Kazakhstan'
    },
    { id: 'Ādaži, Latvia', name: 'Ādaži, Latvia' },
    {
        id: 'Rokiškis District Municipality, Republic of Lithuania',
        name: 'Rokiškis District Municipality, Republic of Lithuania'
    },
    { id: 'Soria, Spain', name: 'Soria, Spain' },
    {
        id: 'Departamento de Colon, Honduras',
        name: 'Departamento de Colon, Honduras'
    },
    { id: 'Hovd, Mongolia', name: 'Hovd, Mongolia' },
    {
        id: 'Province of Agrigento, Italy',
        name: 'Province of Agrigento, Italy'
    },
    {
        id: 'Brazzaville, Republic of the Congo',
        name: 'Brazzaville, Republic of the Congo'
    },
    { id: 'Sabah, Malaysia', name: 'Sabah, Malaysia' },
    { id: 'Saxony-Anhalt, Germany', name: 'Saxony-Anhalt, Germany' },
    { id: 'Aquitaine, France', name: 'Aquitaine, France' },
    { id: 'Fribourg, Switzerland', name: 'Fribourg, Switzerland' },
    {
        id: 'Respublika Mariy-El, Russia',
        name: 'Respublika Mariy-El, Russia'
    },
    { id: 'Kayes, Mali', name: 'Kayes, Mali' },
    {
        id: 'Marsh Harbour District, Bahamas',
        name: 'Marsh Harbour District, Bahamas'
    },
    {
        id: 'Dubrovacko-Neretvanska Zupanija, Croatia',
        name: 'Dubrovacko-Neretvanska Zupanija, Croatia'
    },
    { id: 'Fejér, Hungary', name: 'Fejér, Hungary' },
    { id: 'Quthing, Lesotho', name: 'Quthing, Lesotho' },
    { id: 'Aqmola Oblysy, Kazakhstan', name: 'Aqmola Oblysy, Kazakhstan' },
    {
        id: "Zhytomyrs'ka Oblast', Ukraine",
        name: "Zhytomyrs'ka Oblast', Ukraine"
    },
    { id: 'Škofja Loka, Slovenia', name: 'Škofja Loka, Slovenia' },
    { id: 'Ljutomer, Slovenia', name: 'Ljutomer, Slovenia' },
    { id: 'Ohangwena, Namibia', name: 'Ohangwena, Namibia' },
    {
        id: 'Obcina Loski Potok, Slovenia',
        name: 'Obcina Loski Potok, Slovenia'
    },
    { id: 'Diffa, Niger', name: 'Diffa, Niger' },
    { id: 'South Tyrol, Italy', name: 'South Tyrol, Italy' },
    {
        id: 'Changwat Kanchanaburi, Thailand',
        name: 'Changwat Kanchanaburi, Thailand'
    },
    { id: 'El Beni, Bolivia', name: 'El Beni, Bolivia' },
    { id: 'Nukufetau, Tuvalu', name: 'Nukufetau, Tuvalu' },
    { id: 'Skhirate-Temara, Morocco', name: 'Skhirate-Temara, Morocco' },
    { id: 'Basel-City, Switzerland', name: 'Basel-City, Switzerland' },
    { id: 'Nagaland, India', name: 'Nagaland, India' },
    {
        id: 'Changwat Kamphaeng Phet, Thailand',
        name: 'Changwat Kamphaeng Phet, Thailand'
    },
    {
        id: 'Glasgow City, United Kingdom',
        name: 'Glasgow City, United Kingdom'
    },
    { id: 'Kenitra Province, Morocco', name: 'Kenitra Province, Morocco' },
    { id: 'Sucre, Venezuela', name: 'Sucre, Venezuela' },
    {
        id: 'Cidade de Maputo, Mozambique',
        name: 'Cidade de Maputo, Mozambique'
    },
    { id: 'Lunda Sul, Angola', name: 'Lunda Sul, Angola' },
    {
        id: 'As-Suwayda Governorate, Syria',
        name: 'As-Suwayda Governorate, Syria'
    },
    { id: 'Coast Province, Kenya', name: 'Coast Province, Kenya' },
    { id: 'Sanaa, Yemen', name: 'Sanaa, Yemen' },
    { id: 'Marrakesh-Safi, Morocco', name: 'Marrakesh-Safi, Morocco' },
    { id: 'Dobrovnik, Slovenia', name: 'Dobrovnik, Slovenia' },
    { id: 'Brong-Ahafo, Ghana', name: 'Brong-Ahafo, Ghana' },
    { id: 'Balochistan, Pakistan', name: 'Balochistan, Pakistan' },
    {
        id: 'Kémo, Central African Republic',
        name: 'Kémo, Central African Republic'
    },
    { id: 'Changwat Tak, Thailand', name: 'Changwat Tak, Thailand' },
    {
        id: 'Birmingham, United Kingdom',
        name: 'Birmingham, United Kingdom'
    },
    {
        id: 'Pool, Republic of the Congo',
        name: 'Pool, Republic of the Congo'
    },
    { id: 'Videm, Slovenia', name: 'Videm, Slovenia' },
    { id: 'Litija, Slovenia', name: 'Litija, Slovenia' },
    { id: 'Jihocesky kraj, Czechia', name: 'Jihocesky kraj, Czechia' },
    {
        id: 'Departamento de Narino, Colombia',
        name: 'Departamento de Narino, Colombia'
    },
    { id: 'Minya, Egypt', name: 'Minya, Egypt' },
    {
        id: 'Departamento del Amambay, Paraguay',
        name: 'Departamento del Amambay, Paraguay'
    },
    { id: 'Distrikt Wanica, Suriname', name: 'Distrikt Wanica, Suriname' },
    { id: 'Middle Govĭ, Mongolia', name: 'Middle Govĭ, Mongolia' },
    { id: 'Northern, Sudan', name: 'Northern, Sudan' },
    { id: 'Vaavu Atholhu, Maldives', name: 'Vaavu Atholhu, Maldives' },
    {
        id: 'Cibitoke Province, Burundi',
        name: 'Cibitoke Province, Burundi'
    },
    { id: 'Haute-Savoie, France', name: 'Haute-Savoie, France' },
    { id: 'Obcina Kocevje, Slovenia', name: 'Obcina Kocevje, Slovenia' },
    { id: 'Jezersko, Slovenia', name: 'Jezersko, Slovenia' },
    { id: 'Kerala, India', name: 'Kerala, India' },
    {
        id: 'Changwat Ubon Ratchathani, Thailand',
        name: 'Changwat Ubon Ratchathani, Thailand'
    },
    { id: 'Baa Atholhu, Maldives', name: 'Baa Atholhu, Maldives' },
    {
        id: 'Penal/Debe, Trinidad and Tobago',
        name: 'Penal/Debe, Trinidad and Tobago'
    },
    { id: 'Pukapuka, Cook Islands', name: 'Pukapuka, Cook Islands' },
    {
        id: 'Szabolcs-Szatmár-Bereg, Hungary',
        name: 'Szabolcs-Szatmár-Bereg, Hungary'
    },
    {
        id: 'Parish of Saint Ann, Jamaica',
        name: 'Parish of Saint Ann, Jamaica'
    },
    { id: 'Loir-et-Cher, France', name: 'Loir-et-Cher, France' },
    {
        id: 'Lobaye, Central African Republic',
        name: 'Lobaye, Central African Republic'
    },
    { id: 'Province of Mantua, Italy', name: 'Province of Mantua, Italy' },
    {
        id: 'Győr-Moson-Sopron, Hungary',
        name: 'Győr-Moson-Sopron, Hungary'
    },
    { id: 'Manikgonj, Bangladesh', name: 'Manikgonj, Bangladesh' },
    {
        id: 'Departement de Nippes, Haiti',
        name: 'Departement de Nippes, Haiti'
    },
    {
        id: 'Daejeon, Republic of Korea',
        name: 'Daejeon, Republic of Korea'
    },
    {
        id: 'Varazdinska Zupanija, Croatia',
        name: 'Varazdinska Zupanija, Croatia'
    },
    {
        id: 'Okres Frydek-Mistek, Czechia',
        name: 'Okres Frydek-Mistek, Czechia'
    },
    { id: 'Louga, Senegal', name: 'Louga, Senegal' },
    { id: 'Koper, Slovenia', name: 'Koper, Slovenia' },
    { id: 'Okres Karvina, Czechia', name: 'Okres Karvina, Czechia' },
    {
        id: 'Province of Cagayan, Philippines',
        name: 'Province of Cagayan, Philippines'
    },
    { id: 'Gorj, Romania', name: 'Gorj, Romania' },
    {
        id: 'Chimbu Province, Papua New Guinea',
        name: 'Chimbu Province, Papua New Guinea'
    },
    { id: 'Tinh Binh Phuoc, Vietnam', name: 'Tinh Binh Phuoc, Vietnam' },
    { id: 'Gegharkunik, Armenia', name: 'Gegharkunik, Armenia' },
    { id: 'Sikkim, India', name: 'Sikkim, India' },
    { id: 'Şanlıurfa, Turkey', name: 'Şanlıurfa, Turkey' },
    { id: 'Erzurum, Turkey', name: 'Erzurum, Turkey' },
    { id: 'Guarda, Portugal', name: 'Guarda, Portugal' },
    { id: 'Nyanza Province, Kenya', name: 'Nyanza Province, Kenya' },
    {
        id: 'Region de Kedougou, Senegal',
        name: 'Region de Kedougou, Senegal'
    },
    {
        id: 'Miren-Kostanjevica, Slovenia',
        name: 'Miren-Kostanjevica, Slovenia'
    },
    {
        id: "Kurganskaya Oblast', Russia",
        name: "Kurganskaya Oblast', Russia"
    },
    { id: 'Obcina Moravce, Slovenia', name: 'Obcina Moravce, Slovenia' },
    { id: 'Stirling, United Kingdom', name: 'Stirling, United Kingdom' },
    { id: 'Vientiane Province, Laos', name: 'Vientiane Province, Laos' },
    {
        id: 'Departamento de Tarija, Bolivia',
        name: 'Departamento de Tarija, Bolivia'
    },
    { id: 'Southern Finland, Finland', name: 'Southern Finland, Finland' },
    {
        id: 'Okres Usti nad Labem, Czechia',
        name: 'Okres Usti nad Labem, Czechia'
    },
    {
        id: 'Rio Grande do Norte, Brazil',
        name: 'Rio Grande do Norte, Brazil'
    },
    { id: 'Ida-Virumaa, Estonia', name: 'Ida-Virumaa, Estonia' },
    { id: 'Kankan Prefecture, Guinea', name: 'Kankan Prefecture, Guinea' },
    {
        id: 'Province of Zambales, Philippines',
        name: 'Province of Zambales, Philippines'
    },
    { id: 'Plateau State, Nigeria', name: 'Plateau State, Nigeria' },
    {
        id: 'Bistrica ob Sotli, Slovenia',
        name: 'Bistrica ob Sotli, Slovenia'
    },
    { id: 'Dili, East Timor', name: 'Dili, East Timor' },
    { id: 'Béchar, Algeria', name: 'Béchar, Algeria' },
    {
        id: 'Kujawsko-Pomorskie, Poland',
        name: 'Kujawsko-Pomorskie, Poland'
    },
    {
        id: "Ombella-M'Poko, Central African Republic",
        name: "Ombella-M'Poko, Central African Republic"
    },
    { id: 'Illizi, Algeria', name: 'Illizi, Algeria' },
    { id: 'Lendava, Slovenia', name: 'Lendava, Slovenia' },
    { id: 'Giurgiu, Romania', name: 'Giurgiu, Romania' },
    { id: 'Mizoram, India', name: 'Mizoram, India' },
    { id: 'Blackpool, United Kingdom', name: 'Blackpool, United Kingdom' },
    {
        id: 'Northern Ireland, United Kingdom',
        name: 'Northern Ireland, United Kingdom'
    },
    {
        id: 'Surxondaryo Viloyati, Uzbekistan',
        name: 'Surxondaryo Viloyati, Uzbekistan'
    },
    {
        id: 'Rift Valley Province, Kenya',
        name: 'Rift Valley Province, Kenya'
    },
    {
        id: 'Mafeteng District, Lesotho',
        name: 'Mafeteng District, Lesotho'
    },
    {
        id: 'Obcina Recica ob Savinji, Slovenia',
        name: 'Obcina Recica ob Savinji, Slovenia'
    },
    {
        id: 'São Salvador do Mundo, Cabo Verde',
        name: 'São Salvador do Mundo, Cabo Verde'
    },
    {
        id: 'Šiauliai City Municipality, Republic of Lithuania',
        name: 'Šiauliai City Municipality, Republic of Lithuania'
    },
    { id: 'Provincia di Prato, Italy', name: 'Provincia di Prato, Italy' },
    { id: 'Yucatán, Mexico', name: 'Yucatán, Mexico' },
    { id: 'Manyara, Tanzania', name: 'Manyara, Tanzania' },
    {
        id: 'Trinity Palmetto Point, St Kitts and Nevis',
        name: 'Trinity Palmetto Point, St Kitts and Nevis'
    },
    { id: 'Leinster, Ireland', name: 'Leinster, Ireland' },
    {
        id: 'Changwat Udon Thani, Thailand',
        name: 'Changwat Udon Thani, Thailand'
    },
    {
        id: 'Warrington, United Kingdom',
        name: 'Warrington, United Kingdom'
    },
    { id: 'Yalova, Turkey', name: 'Yalova, Turkey' },
    { id: 'Afyonkarahisar, Turkey', name: 'Afyonkarahisar, Turkey' },
    {
        id: 'Tower Hamlets, United Kingdom',
        name: 'Tower Hamlets, United Kingdom'
    },
    { id: 'Daman and Diu, India', name: 'Daman and Diu, India' },
    { id: 'Oriental, Morocco', name: 'Oriental, Morocco' },
    { id: 'Changwat Nan, Thailand', name: 'Changwat Nan, Thailand' },
    { id: 'Tinh Kien Giang, Vietnam', name: 'Tinh Kien Giang, Vietnam' },
    { id: 'Plateau Department, Benin', name: 'Plateau Department, Benin' },
    { id: 'Canaries, Saint Lucia', name: 'Canaries, Saint Lucia' },
    { id: 'Navarre, Spain', name: 'Navarre, Spain' },
    { id: 'Meuse, France', name: 'Meuse, France' },
    { id: 'Madre de Dios, Peru', name: 'Madre de Dios, Peru' },
    { id: 'Haute-Marne, France', name: 'Haute-Marne, France' },
    { id: 'Adamawa, Nigeria', name: 'Adamawa, Nigeria' },
    { id: 'Adamaoua Region, Cameroon', name: 'Adamaoua Region, Cameroon' },
    {
        id: 'Basel-Landschaft, Switzerland',
        name: 'Basel-Landschaft, Switzerland'
    },
    {
        id: 'Neath Port Talbot, United Kingdom',
        name: 'Neath Port Talbot, United Kingdom'
    },
    {
        id: "Samarskaya Oblast', Russia",
        name: "Samarskaya Oblast', Russia"
    },
    {
        id: 'Departamento de San Pedro, Paraguay',
        name: 'Departamento de San Pedro, Paraguay'
    },
    {
        id: 'Parish of Saint Philip, Antigua and Barbuda',
        name: 'Parish of Saint Philip, Antigua and Barbuda'
    },
    {
        id: 'Province of Marinduque, Philippines',
        name: 'Province of Marinduque, Philippines'
    },
    {
        id: 'Prostějov District, Czechia',
        name: 'Prostějov District, Czechia'
    },
    {
        id: 'Provincia di Salerno, Italy',
        name: 'Provincia di Salerno, Italy'
    },
    { id: 'Piran, Slovenia', name: 'Piran, Slovenia' },
    {
        id: 'Central Visayas, Philippines',
        name: 'Central Visayas, Philippines'
    },
    { id: 'Hela, Papua New Guinea', name: 'Hela, Papua New Guinea' },
    {
        id: 'Dubai, United Arab Emirates',
        name: 'Dubai, United Arab Emirates'
    },
    { id: 'Addis Ababa, Ethiopia', name: 'Addis Ababa, Ethiopia' },
    { id: 'Postojna, Slovenia', name: 'Postojna, Slovenia' },
    {
        id: 'Glodeni, Republic of Moldova',
        name: 'Glodeni, Republic of Moldova'
    },
    { id: 'Mozirje, Slovenia', name: 'Mozirje, Slovenia' },
    { id: 'Sham Shui Po, Hong Kong', name: 'Sham Shui Po, Hong Kong' },
    { id: 'Ibb, Yemen', name: 'Ibb, Yemen' },
    {
        id: 'Departamento del Vichada, Colombia',
        name: 'Departamento del Vichada, Colombia'
    },
    { id: 'Skåne, Sweden', name: 'Skåne, Sweden' },
    {
        id: 'Distrikt Saramacca, Suriname',
        name: 'Distrikt Saramacca, Suriname'
    },
    { id: 'Sindh, Pakistan', name: 'Sindh, Pakistan' },
    { id: 'Long An, Vietnam', name: 'Long An, Vietnam' },
    { id: 'Artigas, Uruguay', name: 'Artigas, Uruguay' },
    {
        id: 'Changwat Nakhon Nayok, Thailand',
        name: 'Changwat Nakhon Nayok, Thailand'
    },
    { id: 'Iz-Zurrieq, Malta', name: 'Iz-Zurrieq, Malta' },
    {
        id: 'Provincia de San Juan, Dominican Republic',
        name: 'Provincia de San Juan, Dominican Republic'
    },
    { id: 'Uttarakhand, India', name: 'Uttarakhand, India' },
    {
        id: 'Bender Municipality, Republic of Moldova',
        name: 'Bender Municipality, Republic of Moldova'
    },
    { id: 'Tinh Ben Tre, Vietnam', name: 'Tinh Ben Tre, Vietnam' },
    { id: 'York, United Kingdom', name: 'York, United Kingdom' },
    { id: 'Gard, France', name: 'Gard, France' },
    { id: 'Perlis, Malaysia', name: 'Perlis, Malaysia' },
    { id: 'Abidjan, Ivory Coast', name: 'Abidjan, Ivory Coast' },
    { id: 'Miaoli, Taiwan', name: 'Miaoli, Taiwan' },
    { id: 'Haute-Vienne, France', name: 'Haute-Vienne, France' },
    {
        id: 'Parish of Saint Peter, Antigua and Barbuda',
        name: 'Parish of Saint Peter, Antigua and Barbuda'
    },
    { id: 'Kratie, Cambodia', name: 'Kratie, Cambodia' },
    {
        id: 'Changwat Narathiwat, Thailand',
        name: 'Changwat Narathiwat, Thailand'
    },
    { id: 'Cantabria, Spain', name: 'Cantabria, Spain' },
    { id: 'Blantyre District, Malawi', name: 'Blantyre District, Malawi' },
    { id: 'Hsinchu County, Taiwan', name: 'Hsinchu County, Taiwan' },
    { id: 'Mymensingh, Bangladesh', name: 'Mymensingh, Bangladesh' },
    { id: 'Zagreb County, Croatia', name: 'Zagreb County, Croatia' },
    { id: 'Jessore, Bangladesh', name: 'Jessore, Bangladesh' },
    { id: 'Rangamati, Bangladesh', name: 'Rangamati, Bangladesh' },
    { id: 'Ardahan, Turkey', name: 'Ardahan, Turkey' },
    { id: 'Il-Furjana, Malta', name: 'Il-Furjana, Malta' },
    { id: 'Ljubljana, Slovenia', name: 'Ljubljana, Slovenia' },
    {
        id: 'Rrethi i Devollit, Albania',
        name: 'Rrethi i Devollit, Albania'
    },
    { id: 'Oita, Japan', name: 'Oita, Japan' },
    { id: 'Nador, Morocco', name: 'Nador, Morocco' },
    { id: 'Kaduna State, Nigeria', name: 'Kaduna State, Nigeria' },
    { id: 'Lugo, Spain', name: 'Lugo, Spain' },
    {
        id: 'Temburong District, Brunei',
        name: 'Temburong District, Brunei'
    },
    {
        id: 'Pagėgiai Municipality, Republic of Lithuania',
        name: 'Pagėgiai Municipality, Republic of Lithuania'
    },
    {
        id: 'Cuvette-Ouest, Republic of the Congo',
        name: 'Cuvette-Ouest, Republic of the Congo'
    },
    { id: 'Cantal, France', name: 'Cantal, France' },
    { id: 'Qarku i Dibres, Albania', name: 'Qarku i Dibres, Albania' },
    {
        id: 'Provincia del Darien, Panama',
        name: 'Provincia del Darien, Panama'
    },
    { id: 'Kafr el-Sheikh, Egypt', name: 'Kafr el-Sheikh, Egypt' },
    { id: 'Tanger-Assilah, Morocco', name: 'Tanger-Assilah, Morocco' },
    {
        id: 'Niari, Republic of the Congo',
        name: 'Niari, Republic of the Congo'
    },
    { id: 'Khoueng Savannakhet, Laos', name: 'Khoueng Savannakhet, Laos' },
    {
        id: 'North East Lincolnshire, United Kingdom',
        name: 'North East Lincolnshire, United Kingdom'
    },
    { id: 'Opstina Karpos, Macedonia', name: 'Opstina Karpos, Macedonia' },
    {
        id: 'Yangon Region, Myanmar [Burma]',
        name: 'Yangon Region, Myanmar [Burma]'
    },
    {
        id: 'Gouvernorat de Mahdia, Tunisia',
        name: 'Gouvernorat de Mahdia, Tunisia'
    },
    { id: 'Wigan, United Kingdom', name: 'Wigan, United Kingdom' },
    { id: 'Mandera District, Kenya', name: 'Mandera District, Kenya' },
    { id: 'Tasmania, Australia', name: 'Tasmania, Australia' },
    { id: 'Ķegums, Latvia', name: 'Ķegums, Latvia' },
    {
        id: 'Province of La Union, Philippines',
        name: 'Province of La Union, Philippines'
    },
    {
        id: 'Province of Capiz, Philippines',
        name: 'Province of Capiz, Philippines'
    },
    { id: 'Haifa, Israel', name: 'Haifa, Israel' },
    {
        id: 'Rokycany District, Czechia',
        name: 'Rokycany District, Czechia'
    },
    { id: 'Lower Austria, Austria', name: 'Lower Austria, Austria' },
    { id: 'Judetul Galati, Romania', name: 'Judetul Galati, Romania' },
    {
        id: 'Changwat Chumphon, Thailand',
        name: 'Changwat Chumphon, Thailand'
    },
    { id: 'Kwara State, Nigeria', name: 'Kwara State, Nigeria' },
    { id: 'São Domingos, Cabo Verde', name: 'São Domingos, Cabo Verde' },
    {
        id: 'Vakaga, Central African Republic',
        name: 'Vakaga, Central African Republic'
    },
    {
        id: 'Makkah Province, Saudi Arabia',
        name: 'Makkah Province, Saudi Arabia'
    },
    {
        id: 'Opstina Niksic, Montenegro',
        name: 'Opstina Niksic, Montenegro'
    },
    { id: 'Tumbes, Peru', name: 'Tumbes, Peru' },
    { id: 'Bolikhamsai, Laos', name: 'Bolikhamsai, Laos' },
    {
        id: 'Changwat Kalasin, Thailand',
        name: 'Changwat Kalasin, Thailand'
    },
    { id: 'Niigata, Japan', name: 'Niigata, Japan' },
    {
        id: 'Republican Subordination, Tajikistan',
        name: 'Republican Subordination, Tajikistan'
    },
    { id: 'Aceh, Indonesia', name: 'Aceh, Indonesia' },
    { id: 'Nibok, Nauru', name: 'Nibok, Nauru' },
    { id: 'Echternach, Luxembourg', name: 'Echternach, Luxembourg' },
    {
        id: "Voronezhskaya Oblast', Russia",
        name: "Voronezhskaya Oblast', Russia"
    },
    { id: 'Tokushima, Japan', name: 'Tokushima, Japan' },
    { id: 'Formosa, Argentina', name: 'Formosa, Argentina' },
    { id: 'Northern District, Israel', name: 'Northern District, Israel' },
    { id: 'Tatarstan, Russia', name: 'Tatarstan, Russia' },
    { id: 'San Luis Potosí, Mexico', name: 'San Luis Potosí, Mexico' },
    {
        id: 'Provincia del Canar, Ecuador',
        name: 'Provincia del Canar, Ecuador'
    },
    { id: 'Khuzestan, Iran', name: 'Khuzestan, Iran' },
    { id: 'Kowloon City, Hong Kong', name: 'Kowloon City, Hong Kong' },
    { id: 'Přerov District, Czechia', name: 'Přerov District, Czechia' },
    {
        id: 'Enga Province, Papua New Guinea',
        name: 'Enga Province, Papua New Guinea'
    },
    {
        id: 'Province of Pangasinan, Philippines',
        name: 'Province of Pangasinan, Philippines'
    },
    { id: 'Dihok, Iraq', name: 'Dihok, Iraq' },
    { id: 'Mombasa District, Kenya', name: 'Mombasa District, Kenya' },
    { id: 'Belfast, United Kingdom', name: 'Belfast, United Kingdom' },
    {
        id: 'Oxfordshire, United Kingdom',
        name: 'Oxfordshire, United Kingdom'
    },
    { id: 'Port Said, Egypt', name: 'Port Said, Egypt' },
    { id: 'Ennedi-Est, Chad', name: 'Ennedi-Est, Chad' },
    { id: 'Saint David, Grenada', name: 'Saint David, Grenada' },
    { id: 'Ulaanbaatar Hot, Mongolia', name: 'Ulaanbaatar Hot, Mongolia' },
    {
        id: 'Obcina Smartno ob Paki, Slovenia',
        name: 'Obcina Smartno ob Paki, Slovenia'
    },
    { id: 'Gotland, Sweden', name: 'Gotland, Sweden' },
    { id: 'Maseru, Lesotho', name: 'Maseru, Lesotho' },
    {
        id: 'Province of Laguna, Philippines',
        name: 'Province of Laguna, Philippines'
    },
    {
        id: 'City of San Fernando, Trinidad and Tobago',
        name: 'City of San Fernando, Trinidad and Tobago'
    },
    { id: 'Auces, Latvia', name: 'Auces, Latvia' },
    {
        id: 'Molėtai District Municipality, Republic of Lithuania',
        name: 'Molėtai District Municipality, Republic of Lithuania'
    },
    { id: 'Nara, Japan', name: 'Nara, Japan' },
    { id: 'Mtwara, Tanzania', name: 'Mtwara, Tanzania' },
    { id: 'Qena, Egypt', name: 'Qena, Egypt' },
    { id: 'Hajdina, Slovenia', name: 'Hajdina, Slovenia' },
    {
        id: 'More og Romsdal fylke, Norway',
        name: 'More og Romsdal fylke, Norway'
    },
    { id: 'North, Hong Kong', name: 'North, Hong Kong' },
    { id: "Kurskaya Oblast', Russia", name: "Kurskaya Oblast', Russia" },
    { id: 'Eastern, Hong Kong', name: 'Eastern, Hong Kong' },
    {
        id: 'Žďár nad Sázavou District, Czechia',
        name: 'Žďár nad Sázavou District, Czechia'
    },
    { id: 'Faro, Portugal', name: 'Faro, Portugal' },
    { id: 'Kandal, Cambodia', name: 'Kandal, Cambodia' },
    { id: 'Greater Poland, Poland', name: 'Greater Poland, Poland' },
    {
        id: 'Departamento del Quiche, Guatemala',
        name: 'Departamento del Quiche, Guatemala'
    },
    {
        id: 'Thanh Pho Can Tho, Vietnam',
        name: 'Thanh Pho Can Tho, Vietnam'
    },
    { id: 'Fars, Iran', name: 'Fars, Iran' },
    {
        id: 'Cheshire East, United Kingdom',
        name: 'Cheshire East, United Kingdom'
    },
    { id: 'Mohafazat Aakkar, Lebanon', name: 'Mohafazat Aakkar, Lebanon' },
    { id: 'Västernorrland, Sweden', name: 'Västernorrland, Sweden' },
    {
        id: 'Republika Srpska, Bosnia and Herzegovina',
        name: 'Republika Srpska, Bosnia and Herzegovina'
    },
    { id: 'Võrumaa, Estonia', name: 'Võrumaa, Estonia' },
    { id: 'La Pampa, Argentina', name: 'La Pampa, Argentina' },
    { id: 'Kronoberg, Sweden', name: 'Kronoberg, Sweden' },
    {
        id: 'Rakovník District, Czechia',
        name: 'Rakovník District, Czechia'
    },
    { id: 'Kropyvnytskyi, Ukraine', name: 'Kropyvnytskyi, Ukraine' },
    {
        id: 'North Holland, Netherlands',
        name: 'North Holland, Netherlands'
    },
    { id: 'Messenia, Greece', name: 'Messenia, Greece' },
    { id: 'Thuringia, Germany', name: 'Thuringia, Germany' },
    { id: 'Ostan-e Kermanshah, Iran', name: 'Ostan-e Kermanshah, Iran' },
    { id: 'Laconia, Greece', name: 'Laconia, Greece' },
    { id: 'Bihor, Romania', name: 'Bihor, Romania' },
    { id: 'Tinh Tra Vinh, Vietnam', name: 'Tinh Tra Vinh, Vietnam' },
    { id: 'Sanma Province, Vanuatu', name: 'Sanma Province, Vanuatu' },
    {
        id: 'Provincia de La Romana, Dominican Republic',
        name: 'Provincia de La Romana, Dominican Republic'
    },
    {
        id: 'Mladá Boleslav District, Czechia',
        name: 'Mladá Boleslav District, Czechia'
    },
    {
        id: "Ivanovskaya Oblast', Russia",
        name: "Ivanovskaya Oblast', Russia"
    },
    { id: 'Bolton, United Kingdom', name: 'Bolton, United Kingdom' },
    {
        id: "Kaluzhskaya Oblast', Russia",
        name: "Kaluzhskaya Oblast', Russia"
    },
    { id: "Sha'biyat al Wahat, Libya", name: "Sha'biyat al Wahat, Libya" },
    { id: 'L-Iklin, Malta', name: 'L-Iklin, Malta' },
    {
        id: 'Buckinghamshire, United Kingdom',
        name: 'Buckinghamshire, United Kingdom'
    },
    {
        id: "Penzenskaya Oblast', Russia",
        name: "Penzenskaya Oblast', Russia"
    },
    {
        id: 'Iles du Vent, French Polynesia',
        name: 'Iles du Vent, French Polynesia'
    },
    {
        id: "Smolenskaya Oblast', Russia",
        name: "Smolenskaya Oblast', Russia"
    },
    {
        id: "Cherkas'ka Oblast', Ukraine",
        name: "Cherkas'ka Oblast', Ukraine"
    },
    { id: 'Jamalpur, Bangladesh', name: 'Jamalpur, Bangladesh' },
    { id: 'South Karelia, Finland', name: 'South Karelia, Finland' },
    { id: 'Djibouti, Djibouti', name: 'Djibouti, Djibouti' },
    { id: 'Zaire, Angola', name: 'Zaire, Angola' },
    { id: 'Il-Birgu, Malta', name: 'Il-Birgu, Malta' },
    {
        id: 'Baladiyat al Khawr wa adh Dhakhirah, Qatar',
        name: 'Baladiyat al Khawr wa adh Dhakhirah, Qatar'
    },
    { id: 'Bradford, United Kingdom', name: 'Bradford, United Kingdom' },
    { id: 'Muğla, Turkey', name: 'Muğla, Turkey' },
    {
        id: 'Obcina Sempeter-Vrtojba, Slovenia',
        name: 'Obcina Sempeter-Vrtojba, Slovenia'
    },
    { id: 'Aileu, East Timor', name: 'Aileu, East Timor' },
    { id: 'Haut-Rhin, France', name: 'Haut-Rhin, France' },
    { id: 'Los Lagos, Chile', name: 'Los Lagos, Chile' },
    { id: 'Al Buraimi, Oman', name: 'Al Buraimi, Oman' },
    { id: 'Vasilevo, Macedonia', name: 'Vasilevo, Macedonia' },
    { id: 'Ouargla, Algeria', name: 'Ouargla, Algeria' },
    {
        id: 'Provincia de Bocas del Toro, Panama',
        name: 'Provincia de Bocas del Toro, Panama'
    },
    {
        id: 'South Lanarkshire, United Kingdom',
        name: 'South Lanarkshire, United Kingdom'
    },
    {
        id: 'Parish of Saint George, Saint Vincent and the Grenadines',
        name: 'Parish of Saint George, Saint Vincent and the Grenadines'
    },
    { id: 'Judetul Iasi, Romania', name: 'Judetul Iasi, Romania' },
    { id: 'Jammu and Kashmir, India', name: 'Jammu and Kashmir, India' },
    { id: 'Loreto, Peru', name: 'Loreto, Peru' },
    { id: 'Batman, Turkey', name: 'Batman, Turkey' },
    { id: 'Nouaceur, Morocco', name: 'Nouaceur, Morocco' },
    {
        id: 'Royal Kingston upon Thames, United Kingdom',
        name: 'Royal Kingston upon Thames, United Kingdom'
    },
    {
        id: 'Damascus Governorate, Syria',
        name: 'Damascus Governorate, Syria'
    },
    { id: 'Taitung, Taiwan', name: 'Taitung, Taiwan' },
    {
        id: 'Provincia de La Vega, Dominican Republic',
        name: 'Provincia de La Vega, Dominican Republic'
    },
    { id: "Tul'skaya Oblast', Russia", name: "Tul'skaya Oblast', Russia" },
    { id: 'Gamprin, Liechtenstein', name: 'Gamprin, Liechtenstein' },
    { id: 'Kahramanmaraş, Turkey', name: 'Kahramanmaraş, Turkey' },
    {
        id: 'Rrethi i Mirdites, Albania',
        name: 'Rrethi i Mirdites, Albania'
    },
    { id: 'Cerkvenjak, Slovenia', name: 'Cerkvenjak, Slovenia' },
    { id: 'Chernihiv, Ukraine', name: 'Chernihiv, Ukraine' },
    { id: 'Nitra, Slovakia', name: 'Nitra, Slovakia' },
    {
        id: 'Gwangju, Republic of Korea',
        name: 'Gwangju, Republic of Korea'
    },
    { id: 'Osh Oblasty, Kyrgyzstan', name: 'Osh Oblasty, Kyrgyzstan' },
    { id: 'Puebla, Mexico', name: 'Puebla, Mexico' },
    { id: 'Poole, United Kingdom', name: 'Poole, United Kingdom' },
    {
        id: 'Obcina Smartno pri Litiji, Slovenia',
        name: 'Obcina Smartno pri Litiji, Slovenia'
    },
    {
        id: 'Obcina Rogasovci, Slovenia',
        name: 'Obcina Rogasovci, Slovenia'
    },
    { id: 'Ba Province, Fiji', name: 'Ba Province, Fiji' },
    { id: 'Aust-Agder, Norway', name: 'Aust-Agder, Norway' },
    { id: 'Sandys Parish, Bermuda', name: 'Sandys Parish, Bermuda' },
    { id: 'Denizli, Turkey', name: 'Denizli, Turkey' },
    { id: 'Lower Shabeelle, Somalia', name: 'Lower Shabeelle, Somalia' },
    { id: 'Cesu Novads, Latvia', name: 'Cesu Novads, Latvia' },
    { id: 'Tinh Thanh Hoa, Vietnam', name: 'Tinh Thanh Hoa, Vietnam' },
    {
        id: 'Departamento de San Salvador, El Salvador',
        name: 'Departamento de San Salvador, El Salvador'
    },
    {
        id: 'Kutná Hora District, Czechia',
        name: 'Kutná Hora District, Czechia'
    },
    {
        id: 'Manzini District, Swaziland',
        name: 'Manzini District, Swaziland'
    },
    { id: 'Piedmont, Italy', name: 'Piedmont, Italy' },
    {
        id: 'North Kalimantan, Indonesia',
        name: 'North Kalimantan, Indonesia'
    },
    { id: 'Velenje, Slovenia', name: 'Velenje, Slovenia' },
    { id: 'Vrhnika, Slovenia', name: 'Vrhnika, Slovenia' },
    { id: 'Oblast Vidin, Bulgaria', name: 'Oblast Vidin, Bulgaria' },
    { id: 'Kotayk, Armenia', name: 'Kotayk, Armenia' },
    { id: 'Barisal, Bangladesh', name: 'Barisal, Bangladesh' },
    {
        id: 'Region de la Araucania, Chile',
        name: 'Region de la Araucania, Chile'
    },
    { id: 'Tambacounda, Senegal', name: 'Tambacounda, Senegal' },
    { id: 'Sentjur, Slovenia', name: 'Sentjur, Slovenia' },
    { id: 'Canterbury, New Zealand', name: 'Canterbury, New Zealand' },
    { id: 'Buada, Nauru', name: 'Buada, Nauru' },
    {
        id: "Ryazanskaya Oblast', Russia",
        name: "Ryazanskaya Oblast', Russia"
    },
    { id: 'Maule, Chile', name: 'Maule, Chile' },
    { id: 'Pailin, Cambodia', name: 'Pailin, Cambodia' },
    { id: 'Kolda, Senegal', name: 'Kolda, Senegal' },
    {
        id: 'Visaginas Municipality, Republic of Lithuania',
        name: 'Visaginas Municipality, Republic of Lithuania'
    },
    {
        id: 'Gyeongsangbuk-do, Republic of Korea',
        name: 'Gyeongsangbuk-do, Republic of Korea'
    },
    { id: 'Suceava, Romania', name: 'Suceava, Romania' },
    {
        id: 'Rietavas Municipality, Republic of Lithuania',
        name: 'Rietavas Municipality, Republic of Lithuania'
    },
    {
        id: 'Flintshire, United Kingdom',
        name: 'Flintshire, United Kingdom'
    },
    { id: 'Berovo, Macedonia', name: 'Berovo, Macedonia' },
    { id: 'Boujdour, Morocco', name: 'Boujdour, Morocco' },
    {
        id: 'Jerash, Hashemite Kingdom of Jordan',
        name: 'Jerash, Hashemite Kingdom of Jordan'
    },
    { id: 'Almaty Qalasy, Kazakhstan', name: 'Almaty Qalasy, Kazakhstan' },
    {
        id: 'Gouvernorat de Gabes, Tunisia',
        name: 'Gouvernorat de Gabes, Tunisia'
    },
    { id: 'Fakaofo, Tokelau', name: 'Fakaofo, Tokelau' },
    { id: 'Naogaon, Bangladesh', name: 'Naogaon, Bangladesh' },
    { id: 'Riau Islands, Indonesia', name: 'Riau Islands, Indonesia' },
    { id: 'Balzers, Liechtenstein', name: 'Balzers, Liechtenstein' },
    { id: 'Haut-Lomani, Congo', name: 'Haut-Lomani, Congo' },
    { id: 'Province of Modena, Italy', name: 'Province of Modena, Italy' },
    { id: 'Solothurn, Switzerland', name: 'Solothurn, Switzerland' },
    {
        id: 'Union Territory of Puducherry, India',
        name: 'Union Territory of Puducherry, India'
    },
    { id: 'Maluku, Indonesia', name: 'Maluku, Indonesia' },
    { id: 'Tébessa, Algeria', name: 'Tébessa, Algeria' },
    { id: 'Mostaganem, Algeria', name: 'Mostaganem, Algeria' },
    { id: 'Exuma District, Bahamas', name: 'Exuma District, Bahamas' },
    { id: 'Gasa, Bhutan', name: 'Gasa, Bhutan' },
    { id: 'Thurgau, Switzerland', name: 'Thurgau, Switzerland' },
    { id: 'Fes-Meknes, Morocco', name: 'Fes-Meknes, Morocco' },
    { id: 'Artvin, Turkey', name: 'Artvin, Turkey' },
    { id: 'Beni-Mellal, Morocco', name: 'Beni-Mellal, Morocco' },
    { id: 'Tunceli, Turkey', name: 'Tunceli, Turkey' },
    { id: 'North Sumatra, Indonesia', name: 'North Sumatra, Indonesia' },
    { id: 'Nograd megye, Hungary', name: 'Nograd megye, Hungary' },
    {
        id: "Chuyskaya Oblast', Kyrgyzstan",
        name: "Chuyskaya Oblast', Kyrgyzstan"
    },
    {
        id: 'Kweneng District, Botswana',
        name: 'Kweneng District, Botswana'
    },
    { id: 'Ouémé, Benin', name: 'Ouémé, Benin' },
    {
        id: "Province of L'Aquila, Italy",
        name: "Province of L'Aquila, Italy"
    },
    { id: 'Ishikawa, Japan', name: 'Ishikawa, Japan' },
    {
        id: "Vladimirskaya Oblast', Russia",
        name: "Vladimirskaya Oblast', Russia"
    },
    { id: 'Västerbotten, Sweden', name: 'Västerbotten, Sweden' },
    { id: 'Mersin, Turkey', name: 'Mersin, Turkey' },
    { id: 'Mělník District, Czechia', name: 'Mělník District, Czechia' },
    { id: 'Kampala District, Uganda', name: 'Kampala District, Uganda' },
    { id: "Côtes-d'Armor, France", name: "Côtes-d'Armor, France" },
    { id: 'Bashkortostan, Russia', name: 'Bashkortostan, Russia' },
    { id: 'Lakshmipur, Bangladesh', name: 'Lakshmipur, Bangladesh' },
    { id: 'Dudley, United Kingdom', name: 'Dudley, United Kingdom' },
    { id: 'Amhara, Ethiopia', name: 'Amhara, Ethiopia' },
    {
        id: 'Ouaka, Central African Republic',
        name: 'Ouaka, Central African Republic'
    },
    {
        id: 'Likouala, Republic of the Congo',
        name: 'Likouala, Republic of the Congo'
    },
    { id: 'Arunachal Pradesh, India', name: 'Arunachal Pradesh, India' },
    { id: 'Mayenne, France', name: 'Mayenne, France' },
    { id: 'Isparta, Turkey', name: 'Isparta, Turkey' },
    { id: 'Tete, Mozambique', name: 'Tete, Mozambique' },
    { id: 'Provincia di Asti, Italy', name: 'Provincia di Asti, Italy' },
    { id: 'Vuzenica, Slovenia', name: 'Vuzenica, Slovenia' },
    { id: 'Namangan, Uzbekistan', name: 'Namangan, Uzbekistan' },
    { id: 'Muta, Slovenia', name: 'Muta, Slovenia' },
    {
        id: 'Gouvernorat de Nabeul, Tunisia',
        name: 'Gouvernorat de Nabeul, Tunisia'
    },
    {
        id: 'Raionul Soroca, Republic of Moldova',
        name: 'Raionul Soroca, Republic of Moldova'
    },
    { id: 'Amasya, Turkey', name: 'Amasya, Turkey' },
    { id: 'Redbridge, United Kingdom', name: 'Redbridge, United Kingdom' },
    { id: 'Kampong Cham, Cambodia', name: 'Kampong Cham, Cambodia' },
    { id: 'Radovljica, Slovenia', name: 'Radovljica, Slovenia' },
    { id: 'Tlemcen, Algeria', name: 'Tlemcen, Algeria' },
    { id: 'Camden, United Kingdom', name: 'Camden, United Kingdom' },
    {
        id: 'Departamento del Cauca, Colombia',
        name: 'Departamento del Cauca, Colombia'
    },
    {
        id: 'Darlington, United Kingdom',
        name: 'Darlington, United Kingdom'
    },
    { id: 'Kwilu, Congo', name: 'Kwilu, Congo' },
    { id: 'Tinh Vinh Phuc, Vietnam', name: 'Tinh Vinh Phuc, Vietnam' },
    { id: 'Gorontalo, Indonesia', name: 'Gorontalo, Indonesia' },
    {
        id: 'Orkney Islands, United Kingdom',
        name: 'Orkney Islands, United Kingdom'
    },
    {
        id: 'Provincia de Morona-Santiago, Ecuador',
        name: 'Provincia de Morona-Santiago, Ecuador'
    },
    {
        id: 'Nouakchott Nord, Mauritania',
        name: 'Nouakchott Nord, Mauritania'
    },
    { id: 'Uppsala, Sweden', name: 'Uppsala, Sweden' },
    { id: 'Tinh GJak Lak, Vietnam', name: 'Tinh GJak Lak, Vietnam' },
    { id: 'Kumamoto, Japan', name: 'Kumamoto, Japan' },
    { id: 'Musandam, Oman', name: 'Musandam, Oman' },
    {
        id: 'Upper Takutu-Upper Essequibo Region, Guyana',
        name: 'Upper Takutu-Upper Essequibo Region, Guyana'
    },
    {
        id: 'Province of Brindisi, Italy',
        name: 'Province of Brindisi, Italy'
    },
    {
        id: 'Inhambane Province, Mozambique',
        name: 'Inhambane Province, Mozambique'
    },
    {
        id: 'Temotu Province, Solomon Islands',
        name: 'Temotu Province, Solomon Islands'
    },
    { id: 'Radenci, Slovenia', name: 'Radenci, Slovenia' },
    {
        id: 'North Atlantic Autonomous Region (RAAN), Nicaragua',
        name: 'North Atlantic Autonomous Region (RAAN), Nicaragua'
    },
    { id: 'Markovci, Slovenia', name: 'Markovci, Slovenia' },
    {
        id: "Ivano-Frankivs'ka Oblast', Ukraine",
        name: "Ivano-Frankivs'ka Oblast', Ukraine"
    },
    { id: 'Vorarlberg, Austria', name: 'Vorarlberg, Austria' },
    { id: 'Şırnak, Turkey', name: 'Şırnak, Turkey' },
    {
        id: 'National Capital Territory of Delhi, India',
        name: 'National Capital Territory of Delhi, India'
    },
    {
        id: 'Northeastern Governorate, Oman',
        name: 'Northeastern Governorate, Oman'
    },
    { id: 'Kujalleq, Greenland', name: 'Kujalleq, Greenland' },
    { id: 'Saint Lawrence, Malta', name: 'Saint Lawrence, Malta' },
    { id: 'Honiara, Solomon Islands', name: 'Honiara, Solomon Islands' },
    { id: 'Znojmo District, Czechia', name: 'Znojmo District, Czechia' },
    { id: 'Inčukalns, Latvia', name: 'Inčukalns, Latvia' },
    { id: 'Phoenix Islands, Kiribati', name: 'Phoenix Islands, Kiribati' },
    { id: 'Orne, France', name: 'Orne, France' },
    { id: 'Omaheke, Namibia', name: 'Omaheke, Namibia' },
    { id: 'Moka District, Mauritius', name: 'Moka District, Mauritius' },
    {
        id: 'North Central Province, Sri Lanka',
        name: 'North Central Province, Sri Lanka'
    },
    {
        id: 'Provincia di Latina, Italy',
        name: 'Provincia di Latina, Italy'
    },
    { id: 'São Vicente, Cabo Verde', name: 'São Vicente, Cabo Verde' },
    {
        id: 'Province of Sorsogon, Philippines',
        name: 'Province of Sorsogon, Philippines'
    },
    { id: 'Oshikoto, Namibia', name: 'Oshikoto, Namibia' },
    { id: 'Aluksnes Novads, Latvia', name: 'Aluksnes Novads, Latvia' },
    { id: 'County Mayo, Ireland', name: 'County Mayo, Ireland' },
    { id: 'Dobeles Rajons, Latvia', name: 'Dobeles Rajons, Latvia' },
    { id: 'Nairobi Province, Kenya', name: 'Nairobi Province, Kenya' },
    { id: 'Alicante, Spain', name: 'Alicante, Spain' },
    {
        id: 'Departamento de Flores, Uruguay',
        name: 'Departamento de Flores, Uruguay'
    },
    {
        id: 'Schellenberg, Liechtenstein',
        name: 'Schellenberg, Liechtenstein'
    },
    {
        id: 'Departamento de Chalatenango, El Salvador',
        name: 'Departamento de Chalatenango, El Salvador'
    },
    { id: 'Chukha, Bhutan', name: 'Chukha, Bhutan' },
    { id: 'Obcina Ormoz, Slovenia', name: 'Obcina Ormoz, Slovenia' },
    { id: 'Thessaloniki, Greece', name: 'Thessaloniki, Greece' },
    { id: 'Koulikoro, Mali', name: 'Koulikoro, Mali' },
    {
        id: 'Departamento de Granada, Nicaragua',
        name: 'Departamento de Granada, Nicaragua'
    },
    { id: 'Dabola, Guinea', name: 'Dabola, Guinea' },
    {
        id: 'Gouvernorat de Jendouba, Tunisia',
        name: 'Gouvernorat de Jendouba, Tunisia'
    },
    { id: 'Oblast Sliven, Bulgaria', name: 'Oblast Sliven, Bulgaria' },
    { id: 'Laikipia, Kenya', name: 'Laikipia, Kenya' },
    {
        id: 'Provincia di Isernia, Italy',
        name: 'Provincia di Isernia, Italy'
    },
    { id: 'Östergötland, Sweden', name: 'Östergötland, Sweden' },
    {
        id: 'Provincia de Herrera, Panama',
        name: 'Provincia de Herrera, Panama'
    },
    {
        id: 'Changwat Chai Nat, Thailand',
        name: 'Changwat Chai Nat, Thailand'
    },
    {
        id: 'Provincia de Santo Domingo, Dominican Republic',
        name: 'Provincia de Santo Domingo, Dominican Republic'
    },
    { id: 'Hérault, France', name: 'Hérault, France' },
    {
        id: 'Departamento del Guaira, Paraguay',
        name: 'Departamento del Guaira, Paraguay'
    },
    {
        id: 'Middlesbrough, United Kingdom',
        name: 'Middlesbrough, United Kingdom'
    },
    { id: 'Quebec, Canada', name: 'Quebec, Canada' },
    { id: 'Cabinda, Angola', name: 'Cabinda, Angola' },
    { id: 'Veracruz, Mexico', name: 'Veracruz, Mexico' },
    { id: 'Almeria, Spain', name: 'Almeria, Spain' },
    {
        id: 'Province du Yatenga, Burkina Faso',
        name: 'Province du Yatenga, Burkina Faso'
    },
    { id: 'Baucau, East Timor', name: 'Baucau, East Timor' },
    {
        id: 'Judetul Mehedinti, Romania',
        name: 'Judetul Mehedinti, Romania'
    },
    { id: 'Kavala, Greece', name: 'Kavala, Greece' },
    { id: 'Girona, Spain', name: 'Girona, Spain' },
    { id: 'Co Kerry, Ireland', name: 'Co Kerry, Ireland' },
    {
        id: 'Logone Oriental Region, Chad',
        name: 'Logone Oriental Region, Chad'
    },
    { id: 'Luxembourg, Luxembourg', name: 'Luxembourg, Luxembourg' },
    { id: 'Lomaiviti Province, Fiji', name: 'Lomaiviti Province, Fiji' },
    {
        id: 'San Salvador District, Bahamas',
        name: 'San Salvador District, Bahamas'
    },
    { id: 'Lot, France', name: 'Lot, France' },
    { id: 'Pingtung, Taiwan', name: 'Pingtung, Taiwan' },
    { id: 'Evros, Greece', name: 'Evros, Greece' },
    {
        id: 'Provincia di Treviso, Italy',
        name: 'Provincia di Treviso, Italy'
    },
    { id: 'Turin, Italy', name: 'Turin, Italy' },
    {
        id: 'Banteay Meanchey, Cambodia',
        name: 'Banteay Meanchey, Cambodia'
    },
    {
        id: 'Provincia di Benevento, Italy',
        name: 'Provincia di Benevento, Italy'
    },
    {
        id: 'Sangha, Republic of the Congo',
        name: 'Sangha, Republic of the Congo'
    },
    {
        id: 'Cuyuni-Mazaruni Region, Guyana',
        name: 'Cuyuni-Mazaruni Region, Guyana'
    },
    {
        id: "Stavropol'skiy Kray, Russia",
        name: "Stavropol'skiy Kray, Russia"
    },
    {
        id: "Pskovskaya Oblast', Russia",
        name: "Pskovskaya Oblast', Russia"
    },
    { id: 'Federal District, Brazil', name: 'Federal District, Brazil' },
    { id: 'Svitavy District, Czechia', name: 'Svitavy District, Czechia' },
    { id: 'Yunnan, China', name: 'Yunnan, China' },
    { id: 'Birkirkara, Malta', name: 'Birkirkara, Malta' },
    { id: 'Transcarpathia, Ukraine', name: 'Transcarpathia, Ukraine' },
    { id: 'Teplice District, Czechia', name: 'Teplice District, Czechia' },
    {
        id: 'Southern Highlands Province, Papua New Guinea',
        name: 'Southern Highlands Province, Papua New Guinea'
    },
    {
        id: 'Criuleni, Republic of Moldova',
        name: 'Criuleni, Republic of Moldova'
    },
    { id: 'Haute-Saône, France', name: 'Haute-Saône, France' },
    {
        id: 'Provincia de Independencia, Dominican Republic',
        name: 'Provincia de Independencia, Dominican Republic'
    },
    { id: 'Komenda, Slovenia', name: 'Komenda, Slovenia' },
    {
        id: 'Okres Brno-Venkov, Czechia',
        name: 'Okres Brno-Venkov, Czechia'
    },
    {
        id: 'Province of Mindoro Occidental, Philippines',
        name: 'Province of Mindoro Occidental, Philippines'
    },
    { id: 'Groningen, Netherlands', name: 'Groningen, Netherlands' },
    {
        id: 'Province of Lanao del Norte, Philippines',
        name: 'Province of Lanao del Norte, Philippines'
    },
    { id: 'Dushanbe, Tajikistan', name: 'Dushanbe, Tajikistan' },
    {
        id: 'Făleşti, Republic of Moldova',
        name: 'Făleşti, Republic of Moldova'
    },
    { id: 'Sarpang Dzongkhag, Bhutan', name: 'Sarpang Dzongkhag, Bhutan' },
    { id: 'Il-Kalkara, Malta', name: 'Il-Kalkara, Malta' },
    {
        id: 'Opstina Vevcani, Macedonia',
        name: 'Opstina Vevcani, Macedonia'
    },
    { id: 'Trikala, Greece', name: 'Trikala, Greece' },
    { id: 'Catania, Italy', name: 'Catania, Italy' },
    {
        id: 'Principe, São Tomé and Príncipe',
        name: 'Principe, São Tomé and Príncipe'
    },
    { id: 'Al Jawf, Saudi Arabia', name: 'Al Jawf, Saudi Arabia' },
    { id: 'Nomos Kykladon, Greece', name: 'Nomos Kykladon, Greece' },
    { id: 'Tel Aviv, Israel', name: 'Tel Aviv, Israel' },
    { id: 'Gharbia, Egypt', name: 'Gharbia, Egypt' },
    {
        id: 'Provincia de Colon, Panama',
        name: 'Provincia de Colon, Panama'
    },
    { id: 'Děčín District, Czechia', name: 'Děčín District, Czechia' },
    {
        id: 'Estado de Baja California, Mexico',
        name: 'Estado de Baja California, Mexico'
    },
    {
        id: 'Caerphilly County Borough, United Kingdom',
        name: 'Caerphilly County Borough, United Kingdom'
    },
    { id: 'Melilla, Spain', name: 'Melilla, Spain' },
    { id: 'Chios, Greece', name: 'Chios, Greece' },
    {
        id: 'Madaba, Hashemite Kingdom of Jordan',
        name: 'Madaba, Hashemite Kingdom of Jordan'
    },
    { id: 'Sha Tin, Hong Kong', name: 'Sha Tin, Hong Kong' },
    { id: 'Nasarawa State, Nigeria', name: 'Nasarawa State, Nigeria' },
    { id: 'Tinh Ninh Binh, Vietnam', name: 'Tinh Ninh Binh, Vietnam' },
    { id: 'Saint Peter, Dominica', name: 'Saint Peter, Dominica' },
    { id: 'Bruntál District, Czechia', name: 'Bruntál District, Czechia' },
    {
        id: 'Northern Ostrobothnia, Finland',
        name: 'Northern Ostrobothnia, Finland'
    },
    { id: 'Zambezi Region, Namibia', name: 'Zambezi Region, Namibia' },
    {
        id: 'Argyll and Bute, United Kingdom',
        name: 'Argyll and Bute, United Kingdom'
    },
    {
        id: 'Obcina Miklavz na Dravskem Polju, Slovenia',
        name: 'Obcina Miklavz na Dravskem Polju, Slovenia'
    },
    { id: 'Bethlehem, Palestine', name: 'Bethlehem, Palestine' },
    {
        id: 'Eastern Samar, Philippines',
        name: 'Eastern Samar, Philippines'
    },
    { id: 'Southwark, United Kingdom', name: 'Southwark, United Kingdom' },
    { id: 'Porto, Portugal', name: 'Porto, Portugal' },
    { id: 'Shanghai, China', name: 'Shanghai, China' },
    {
        id: 'Havlíčkův Brod District, Czechia',
        name: 'Havlíčkův Brod District, Czechia'
    },
    { id: 'Kabul, Afghanistan', name: 'Kabul, Afghanistan' },
    { id: 'Caceres, Spain', name: 'Caceres, Spain' },
    { id: 'La Habana, Cuba', name: 'La Habana, Cuba' },
    {
        id: 'West New Britain Province, Papua New Guinea',
        name: 'West New Britain Province, Papua New Guinea'
    },
    { id: 'Lau Province, Fiji', name: 'Lau Province, Fiji' },
    { id: 'Borgou Department, Benin', name: 'Borgou Department, Benin' },
    {
        id: 'Chapai Nababganj, Bangladesh',
        name: 'Chapai Nababganj, Bangladesh'
    },
    {
        id: 'Federally Administered Tribal Areas, Pakistan',
        name: 'Federally Administered Tribal Areas, Pakistan'
    },
    { id: 'Kouffo Department, Benin', name: 'Kouffo Department, Benin' },
    {
        id: 'Castello di Acquaviva, San Marino',
        name: 'Castello di Acquaviva, San Marino'
    },
    { id: 'Azores, Portugal', name: 'Azores, Portugal' },
    { id: 'Matruh, Egypt', name: 'Matruh, Egypt' },
    { id: 'Buenos Aires, Argentina', name: 'Buenos Aires, Argentina' },
    { id: 'Conakry Region, Guinea', name: 'Conakry Region, Guinea' },
    { id: 'Bangkok, Thailand', name: 'Bangkok, Thailand' },
    {
        id: 'Kayah State, Myanmar [Burma]',
        name: 'Kayah State, Myanmar [Burma]'
    },
    { id: 'Cuenca, Spain', name: 'Cuenca, Spain' },
    { id: 'Prahova, Romania', name: 'Prahova, Romania' },
    { id: 'Suez, Egypt', name: 'Suez, Egypt' },
    { id: 'Atlántico, Colombia', name: 'Atlántico, Colombia' },
    { id: 'Central Region, Ghana', name: 'Central Region, Ghana' },
    {
        id: 'Castello di Faetano, San Marino',
        name: 'Castello di Faetano, San Marino'
    },
    {
        id: 'Pembrokeshire, United Kingdom',
        name: 'Pembrokeshire, United Kingdom'
    },
    { id: 'Il-Belt Valletta, Malta', name: 'Il-Belt Valletta, Malta' },
    { id: 'Ilia Prefecture, Greece', name: 'Ilia Prefecture, Greece' },
    {
        id: 'West Sussex, United Kingdom',
        name: 'West Sussex, United Kingdom'
    },
    { id: 'Dosso Region, Niger', name: 'Dosso Region, Niger' },
    { id: 'Jelgava, Latvia', name: 'Jelgava, Latvia' },
    { id: 'Dordogne, France', name: 'Dordogne, France' },
    { id: 'Asturias, Spain', name: 'Asturias, Spain' },
    {
        id: 'Cuvette, Republic of the Congo',
        name: 'Cuvette, Republic of the Congo'
    },
    {
        id: 'Kgalagadi District, Botswana',
        name: 'Kgalagadi District, Botswana'
    },
    {
        id: 'Vientiane Prefecture, Laos',
        name: 'Vientiane Prefecture, Laos'
    },
    {
        id: "Nizhegorodskaya Oblast', Russia",
        name: "Nizhegorodskaya Oblast', Russia"
    },
    { id: 'Thaa Atholhu, Maldives', name: 'Thaa Atholhu, Maldives' },
    { id: 'Ruyigi Province, Burundi', name: 'Ruyigi Province, Burundi' },
    { id: 'Saba, Bonaire', name: 'Saba, Bonaire' },
    { id: 'Lombardy, Italy', name: 'Lombardy, Italy' },
    { id: 'Plovdiv, Bulgaria', name: 'Plovdiv, Bulgaria' },
    { id: 'Jujuy, Argentina', name: 'Jujuy, Argentina' },
    { id: 'Moray, United Kingdom', name: 'Moray, United Kingdom' },
    {
        id: 'Provincia de Esmeraldas, Ecuador',
        name: 'Provincia de Esmeraldas, Ecuador'
    },
    {
        id: 'Central Bedfordshire, United Kingdom',
        name: 'Central Bedfordshire, United Kingdom'
    },
    {
        id: 'Bordj Bou Arréridj, Algeria',
        name: 'Bordj Bou Arréridj, Algeria'
    },
    {
        id: 'Province of Benguet, Philippines',
        name: 'Province of Benguet, Philippines'
    },
    { id: 'Gopalganj, Bangladesh', name: 'Gopalganj, Bangladesh' },
    { id: 'County Tipperary, Ireland', name: 'County Tipperary, Ireland' },
    { id: 'Xiangkhouang, Laos', name: 'Xiangkhouang, Laos' },
    { id: 'Sichuan, China', name: 'Sichuan, China' },
    { id: 'Sakarya, Turkey', name: 'Sakarya, Turkey' },
    {
        id: 'Dhaka Division, Bangladesh',
        name: 'Dhaka Division, Bangladesh'
    },
    {
        id: 'Provincia de Galapagos, Ecuador',
        name: 'Provincia de Galapagos, Ecuador'
    },
    {
        id: 'Toshkent Shahri, Uzbekistan',
        name: 'Toshkent Shahri, Uzbekistan'
    },
    { id: 'Il-Fgura, Malta', name: 'Il-Fgura, Malta' },
    { id: 'City of Zagreb, Croatia', name: 'City of Zagreb, Croatia' },
    {
        id: 'Region de Valparaiso, Chile',
        name: 'Region de Valparaiso, Chile'
    },
    { id: 'Shanxi, China', name: 'Shanxi, China' },
    {
        id: 'North Western Province, Sri Lanka',
        name: 'North Western Province, Sri Lanka'
    },
    { id: 'Rujienas Novads, Latvia', name: 'Rujienas Novads, Latvia' },
    { id: 'Hatay, Turkey', name: 'Hatay, Turkey' },
    {
        id: 'Amman Governorate, Hashemite Kingdom of Jordan',
        name: 'Amman Governorate, Hashemite Kingdom of Jordan'
    },
    {
        id: 'Changwat Uthai Thani, Thailand',
        name: 'Changwat Uthai Thani, Thailand'
    },
    {
        id: 'Gouvernorat de Sfax, Tunisia',
        name: 'Gouvernorat de Sfax, Tunisia'
    },
    { id: 'Demir Kapija, Macedonia', name: 'Demir Kapija, Macedonia' },
    {
        id: 'Komárom-Esztergom, Hungary',
        name: 'Komárom-Esztergom, Hungary'
    },
    { id: 'Kuzma, Slovenia', name: 'Kuzma, Slovenia' },
    {
        id: "Khmel'nyts'ka Oblast', Ukraine",
        name: "Khmel'nyts'ka Oblast', Ukraine"
    },
    {
        id: 'Grenadines, Saint Vincent and the Grenadines',
        name: 'Grenadines, Saint Vincent and the Grenadines'
    },
    {
        id: 'Changwat Samut Sakhon, Thailand',
        name: 'Changwat Samut Sakhon, Thailand'
    },
    { id: 'Uvea, Wallis and Futuna', name: 'Uvea, Wallis and Futuna' },
    { id: 'Saint John, Grenada', name: 'Saint John, Grenada' },
    { id: 'Denguele, Ivory Coast', name: 'Denguele, Ivory Coast' },
    { id: 'Western Region, Ghana', name: 'Western Region, Ghana' },
    {
        id: 'Obcina Smarjeske Toplice, Slovenia',
        name: 'Obcina Smarjeske Toplice, Slovenia'
    },
    {
        id: 'Departamento de Jalapa, Guatemala',
        name: 'Departamento de Jalapa, Guatemala'
    },
    { id: 'Meghalaya, India', name: 'Meghalaya, India' },
    {
        id: 'Clackmannanshire, United Kingdom',
        name: 'Clackmannanshire, United Kingdom'
    },
    { id: 'Radlje ob Dravi, Slovenia', name: 'Radlje ob Dravi, Slovenia' },
    { id: 'Victoria, Australia', name: 'Victoria, Australia' },
    { id: 'Ropazu Novads, Latvia', name: 'Ropazu Novads, Latvia' },
    { id: 'Al Kufrah, Libya', name: 'Al Kufrah, Libya' },
    { id: 'Opstina Stip, Macedonia', name: 'Opstina Stip, Macedonia' },
    { id: 'Malanje Province, Angola', name: 'Malanje Province, Angola' },
    {
        id: 'Karakalpakstan, Uzbekistan',
        name: 'Karakalpakstan, Uzbekistan'
    },
    { id: 'Dorset, United Kingdom', name: 'Dorset, United Kingdom' },
    {
        id: 'Changwat Bueng Kan, Thailand',
        name: 'Changwat Bueng Kan, Thailand'
    },
    { id: 'Gusinje, Montenegro', name: 'Gusinje, Montenegro' },
    {
        id: 'Central and Western District, Hong Kong',
        name: 'Central and Western District, Hong Kong'
    },
    { id: 'Vitanje, Slovenia', name: 'Vitanje, Slovenia' },
    { id: 'Manisa, Turkey', name: 'Manisa, Turkey' },
    { id: 'South Aegean, Greece', name: 'South Aegean, Greece' },
    {
        id: 'Provincia del Azuay, Ecuador',
        name: 'Provincia del Azuay, Ecuador'
    },
    {
        id: 'Changwat Nong Bua Lamphu, Thailand',
        name: 'Changwat Nong Bua Lamphu, Thailand'
    },
    { id: 'Gabu, Guinea-Bissau', name: 'Gabu, Guinea-Bissau' },
    {
        id: 'Departamento de Zacapa, Guatemala',
        name: 'Departamento de Zacapa, Guatemala'
    },
    { id: 'Oaxaca, Mexico', name: 'Oaxaca, Mexico' },
    { id: 'Cadiz, Spain', name: 'Cadiz, Spain' },
    { id: 'Gateshead, United Kingdom', name: 'Gateshead, United Kingdom' },
    { id: 'Henan, China', name: 'Henan, China' },
    { id: 'Louny District, Czechia', name: 'Louny District, Czechia' },
    { id: 'Mayo-Kebbi Est, Chad', name: 'Mayo-Kebbi Est, Chad' },
    { id: 'Selenge Aymag, Mongolia', name: 'Selenge Aymag, Mongolia' },
    { id: 'Aïn Témouchent, Algeria', name: 'Aïn Témouchent, Algeria' },
    {
        id: 'Ialoveni, Republic of Moldova',
        name: 'Ialoveni, Republic of Moldova'
    },
    {
        id: 'Prefecture de Forecariah, Guinea',
        name: 'Prefecture de Forecariah, Guinea'
    },
    {
        id: 'Provincia di Campobasso, Italy',
        name: 'Provincia di Campobasso, Italy'
    },
    { id: 'England, United Kingdom', name: 'England, United Kingdom' },
    { id: 'Casablanca, Morocco', name: 'Casablanca, Morocco' },
    { id: 'Florina, Greece', name: 'Florina, Greece' },
    { id: 'Zlatibor, Serbia', name: 'Zlatibor, Serbia' },
    { id: 'Lorraine, France', name: 'Lorraine, France' },
    {
        id: 'Oriental Mindoro, Philippines',
        name: 'Oriental Mindoro, Philippines'
    },
    {
        id: 'Central Luzon, Philippines',
        name: 'Central Luzon, Philippines'
    },
    { id: 'Razavi Khorasan, Iran', name: 'Razavi Khorasan, Iran' },
    { id: 'Durham, United Kingdom', name: 'Durham, United Kingdom' },
    { id: 'Qalyubia, Egypt', name: 'Qalyubia, Egypt' },
    {
        id: 'Obcina Podcetrtek, Slovenia',
        name: 'Obcina Podcetrtek, Slovenia'
    },
    { id: 'Sevnica, Slovenia', name: 'Sevnica, Slovenia' },
    { id: 'Roraima, Brazil', name: 'Roraima, Brazil' },
    { id: 'Wakayama, Japan', name: 'Wakayama, Japan' },
    { id: 'Central Aimak, Mongolia', name: 'Central Aimak, Mongolia' },
    { id: 'Bogovinje, Macedonia', name: 'Bogovinje, Macedonia' },
    { id: 'Świętokrzyskie, Poland', name: 'Świętokrzyskie, Poland' },
    { id: 'Ghanzi District, Botswana', name: 'Ghanzi District, Botswana' },
    {
        id: 'Provincia di Cremona, Italy',
        name: 'Provincia di Cremona, Italy'
    },
    { id: 'Talas, Kyrgyzstan', name: 'Talas, Kyrgyzstan' },
    {
        id: "Mangistauskaya Oblast', Kazakhstan",
        name: "Mangistauskaya Oblast', Kazakhstan"
    },
    {
        id: 'Opstina Rozaje, Montenegro',
        name: 'Opstina Rozaje, Montenegro'
    },
    { id: 'Chukot, Russia', name: 'Chukot, Russia' },
    { id: 'Matam, Senegal', name: 'Matam, Senegal' },
    {
        id: 'Departamento de Chontales, Nicaragua',
        name: 'Departamento de Chontales, Nicaragua'
    },
    {
        id: 'County Borough of Bridgend, United Kingdom',
        name: 'County Borough of Bridgend, United Kingdom'
    },
    { id: 'Laamu Atholhu, Maldives', name: 'Laamu Atholhu, Maldives' },
    {
        id: 'Provincia de Alajuela, Costa Rica',
        name: 'Provincia de Alajuela, Costa Rica'
    },
    {
        id: 'Bougainville, Papua New Guinea',
        name: 'Bougainville, Papua New Guinea'
    },
    {
        id: 'Santa Catarina do Fogo, Cabo Verde',
        name: 'Santa Catarina do Fogo, Cabo Verde'
    },
    { id: 'Tangail, Bangladesh', name: 'Tangail, Bangladesh' },
    { id: 'Shida Kartli, Georgia', name: 'Shida Kartli, Georgia' },
    { id: 'Duplek, Slovenia', name: 'Duplek, Slovenia' },
    { id: 'Castelo Branco, Portugal', name: 'Castelo Branco, Portugal' },
    { id: 'Abkhazia, Georgia', name: 'Abkhazia, Georgia' },
    {
        id: 'Changwat Chachoengsao, Thailand',
        name: 'Changwat Chachoengsao, Thailand'
    },
    { id: 'Log–Dragomer, Slovenia', name: 'Log–Dragomer, Slovenia' },
    {
        id: 'Bracknell Forest, United Kingdom',
        name: 'Bracknell Forest, United Kingdom'
    },
    {
        id: 'Republic of Crimea, Ukraine',
        name: 'Republic of Crimea, Ukraine'
    },
    { id: 'Ainaro, East Timor', name: 'Ainaro, East Timor' },
    { id: 'Muscat, Oman', name: 'Muscat, Oman' },
    { id: 'Maekel Region, Eritrea', name: 'Maekel Region, Eritrea' },
    { id: 'Pcinja, Serbia', name: 'Pcinja, Serbia' },
    { id: 'Eskişehir, Turkey', name: 'Eskişehir, Turkey' },
    { id: 'Tucuman, Argentina', name: 'Tucuman, Argentina' },
    { id: "A'ana, Samoa", name: "A'ana, Samoa" },
    { id: 'Ijuw, Nauru', name: 'Ijuw, Nauru' },
    { id: 'Logatec, Slovenia', name: 'Logatec, Slovenia' },
    { id: 'Wiltshire, United Kingdom', name: 'Wiltshire, United Kingdom' },
    {
        id: 'Khouribga Province, Morocco',
        name: 'Khouribga Province, Morocco'
    },
    { id: 'Gornja Radgona, Slovenia', name: 'Gornja Radgona, Slovenia' },
    { id: 'Kastoria, Greece', name: 'Kastoria, Greece' },
    {
        id: 'Departamento de Francisco Morazan, Honduras',
        name: 'Departamento de Francisco Morazan, Honduras'
    },
    { id: 'Kelantan, Malaysia', name: 'Kelantan, Malaysia' },
    {
        id: 'Gaafu Alifu Atholhu, Maldives',
        name: 'Gaafu Alifu Atholhu, Maldives'
    },
    { id: 'Lower Silesia, Poland', name: 'Lower Silesia, Poland' },
    { id: 'Ceuta, Spain', name: 'Ceuta, Spain' },
    { id: 'Santa Catarina, Brazil', name: 'Santa Catarina, Brazil' },
    { id: 'Cheshire, United Kingdom', name: 'Cheshire, United Kingdom' },
    { id: 'Tinh Lao Cai, Vietnam', name: 'Tinh Lao Cai, Vietnam' },
    { id: 'Siirt, Turkey', name: 'Siirt, Turkey' },
    { id: 'Aksaray, Turkey', name: 'Aksaray, Turkey' },
    {
        id: 'Moravske Toplice, Slovenia',
        name: 'Moravske Toplice, Slovenia'
    },
    {
        id: 'Eastern District, American Samoa',
        name: 'Eastern District, American Samoa'
    },
    {
        id: 'Wandsworth, United Kingdom',
        name: 'Wandsworth, United Kingdom'
    },
    { id: 'Bayelsa State, Nigeria', name: 'Bayelsa State, Nigeria' },
    { id: 'Qarku i Vlores, Albania', name: 'Qarku i Vlores, Albania' },
    { id: 'Ogre, Latvia', name: 'Ogre, Latvia' },
    { id: 'Gros-Islet, Saint Lucia', name: 'Gros-Islet, Saint Lucia' },
    { id: 'Chuadanga, Bangladesh', name: 'Chuadanga, Bangladesh' },
    { id: 'Essonne, France', name: 'Essonne, France' },
    { id: 'Veszprem megye, Hungary', name: 'Veszprem megye, Hungary' },
    { id: 'Il-Mellieha, Malta', name: 'Il-Mellieha, Malta' },
    { id: 'Tartu, Estonia', name: 'Tartu, Estonia' },
    {
        id: 'Matabeleland South Province, Zimbabwe',
        name: 'Matabeleland South Province, Zimbabwe'
    },
    {
        id: 'Damietta Governorate, Egypt',
        name: 'Damietta Governorate, Egypt'
    },
    { id: 'Kassala, Sudan', name: 'Kassala, Sudan' },
    { id: 'Tinh Thai Binh, Vietnam', name: 'Tinh Thai Binh, Vietnam' },
    { id: 'Wong Tai Sin, Hong Kong', name: 'Wong Tai Sin, Hong Kong' },
    {
        id: 'Departamento de Arauca, Colombia',
        name: 'Departamento de Arauca, Colombia'
    },
    { id: "Ta' Kercem, Malta", name: "Ta' Kercem, Malta" },
    {
        id: 'Ragged Island District, Bahamas',
        name: 'Ragged Island District, Bahamas'
    },
    {
        id: 'Govi-Altay Aymag, Mongolia',
        name: 'Govi-Altay Aymag, Mongolia'
    },
    {
        id: 'Qaraghandy Oblysy, Kazakhstan',
        name: 'Qaraghandy Oblysy, Kazakhstan'
    },
    { id: 'Petnjica, Montenegro', name: 'Petnjica, Montenegro' },
    {
        id: 'Changwat Phra Nakhon Si Ayutthaya, Thailand',
        name: 'Changwat Phra Nakhon Si Ayutthaya, Thailand'
    },
    { id: 'Gabrovo, Bulgaria', name: 'Gabrovo, Bulgaria' },
    { id: 'Morbihan, France', name: 'Morbihan, France' },
    { id: 'Al Jufrah, Libya', name: 'Al Jufrah, Libya' },
    { id: 'Castries, Saint Lucia', name: 'Castries, Saint Lucia' },
    {
        id: 'Alpes-de-Haute-Provence, France',
        name: 'Alpes-de-Haute-Provence, France'
    },
    { id: 'Shefa Province, Vanuatu', name: 'Shefa Province, Vanuatu' },
    { id: 'Jambi, Indonesia', name: 'Jambi, Indonesia' },
    {
        id: 'Opstina Vrapciste, Macedonia',
        name: 'Opstina Vrapciste, Macedonia'
    },
    { id: 'Ioannina, Greece', name: 'Ioannina, Greece' },
    {
        id: 'Provincia de San Jose de Ocoa, Dominican Republic',
        name: 'Provincia de San Jose de Ocoa, Dominican Republic'
    },
    {
        id: 'East Riding of Yorkshire, United Kingdom',
        name: 'East Riding of Yorkshire, United Kingdom'
    },
    { id: 'Bali, Indonesia', name: 'Bali, Indonesia' },
    {
        id: 'Siparia, Trinidad and Tobago',
        name: 'Siparia, Trinidad and Tobago'
    },
    {
        id: 'Grand Bassa County, Liberia',
        name: 'Grand Bassa County, Liberia'
    },
    { id: 'Xaignabouli, Laos', name: 'Xaignabouli, Laos' },
    {
        id: 'Changwat Nakhon Si Thammarat, Thailand',
        name: 'Changwat Nakhon Si Thammarat, Thailand'
    },
    {
        id: 'Province of Isabela, Philippines',
        name: 'Province of Isabela, Philippines'
    },
    { id: 'South, Iceland', name: 'South, Iceland' },
    {
        id: 'Departamento de Ahuachapan, El Salvador',
        name: 'Departamento de Ahuachapan, El Salvador'
    },
    { id: 'Rarotonga, Cook Islands', name: 'Rarotonga, Cook Islands' },
    {
        id: 'Kaišiadorys District Municipality, Republic of Lithuania',
        name: 'Kaišiadorys District Municipality, Republic of Lithuania'
    },
    {
        id: 'Mandalay Region, Myanmar [Burma]',
        name: 'Mandalay Region, Myanmar [Burma]'
    },
    { id: 'Ardennes, France', name: 'Ardennes, France' },
    {
        id: 'Oblast Kyustendil, Bulgaria',
        name: 'Oblast Kyustendil, Bulgaria'
    },
    { id: 'Paraíba, Brazil', name: 'Paraíba, Brazil' },
    { id: 'St.-Petersburg, Russia', name: 'St.-Petersburg, Russia' },
    {
        id: 'Herefordshire, United Kingdom',
        name: 'Herefordshire, United Kingdom'
    },
    {
        id: 'Muhafazat al Qadisiyah, Iraq',
        name: 'Muhafazat al Qadisiyah, Iraq'
    },
    { id: 'Islington, United Kingdom', name: 'Islington, United Kingdom' },
    { id: 'Akershus, Norway', name: 'Akershus, Norway' },
    {
        id: 'Hillingdon, United Kingdom',
        name: 'Hillingdon, United Kingdom'
    },
    { id: 'Lecava, Latvia', name: 'Lecava, Latvia' },
    { id: 'Jönköping, Sweden', name: 'Jönköping, Sweden' },
    { id: 'Atafu, Tokelau', name: 'Atafu, Tokelau' },
    { id: 'Teruel, Spain', name: 'Teruel, Spain' },
    {
        id: 'Barbuda, Antigua and Barbuda',
        name: 'Barbuda, Antigua and Barbuda'
    },
    { id: 'Highland, United Kingdom', name: 'Highland, United Kingdom' },
    { id: 'Hesse, Germany', name: 'Hesse, Germany' },
    { id: 'Bekes, Hungary', name: 'Bekes, Hungary' },
    { id: 'Písek District, Czechia', name: 'Písek District, Czechia' },
    {
        id: 'East New Britain Province, Papua New Guinea',
        name: 'East New Britain Province, Papua New Guinea'
    },
    { id: 'Il-Marsa, Malta', name: 'Il-Marsa, Malta' },
    {
        id: 'Provincia de Santo Domingo de los Tsachilas, Ecuador',
        name: 'Provincia de Santo Domingo de los Tsachilas, Ecuador'
    },
    { id: 'Walsall, United Kingdom', name: 'Walsall, United Kingdom' },
    { id: 'Lhuntse, Bhutan', name: 'Lhuntse, Bhutan' },
    {
        id: 'Niassa Province, Mozambique',
        name: 'Niassa Province, Mozambique'
    },
    {
        id: 'Castello di Domagnano, San Marino',
        name: 'Castello di Domagnano, San Marino'
    },
    {
        id: 'Tafielah, Hashemite Kingdom of Jordan',
        name: 'Tafielah, Hashemite Kingdom of Jordan'
    },
    {
        id: "Gouvernorat de l'Ariana, Tunisia",
        name: "Gouvernorat de l'Ariana, Tunisia"
    },
    { id: 'Niamey, Niger', name: 'Niamey, Niger' },
    { id: 'Birzebbuga, Malta', name: 'Birzebbuga, Malta' },
    { id: 'Midi-Pyrénées, France', name: 'Midi-Pyrénées, France' },
    {
        id: 'South Kazakhstan, Kazakhstan',
        name: 'South Kazakhstan, Kazakhstan'
    },
    {
        id: 'Gouvernorat de Monastir, Tunisia',
        name: 'Gouvernorat de Monastir, Tunisia'
    },
    {
        id: 'Obcina Zirovnica, Slovenia',
        name: 'Obcina Zirovnica, Slovenia'
    },
    {
        id: 'Dependencias Federales, Venezuela',
        name: 'Dependencias Federales, Venezuela'
    },
    { id: 'Vayots Dzor, Armenia', name: 'Vayots Dzor, Armenia' },
    { id: 'Riga, Latvia', name: 'Riga, Latvia' },
    { id: 'Mérida, Venezuela', name: 'Mérida, Venezuela' },
    { id: 'Kosi Zone, Nepal', name: 'Kosi Zone, Nepal' },
    { id: 'Sarandë District, Albania', name: 'Sarandë District, Albania' },
    { id: 'Kaafu Atoll, Maldives', name: 'Kaafu Atoll, Maldives' },
    { id: 'Ararat, Armenia', name: 'Ararat, Armenia' },
    {
        id: 'Provincia di Chieti, Italy',
        name: 'Provincia di Chieti, Italy'
    },
    { id: 'Tinh Quang Ninh, Vietnam', name: 'Tinh Quang Ninh, Vietnam' },
    { id: 'Koh Kong, Cambodia', name: 'Koh Kong, Cambodia' },
    { id: 'Tinh Bac Giang, Vietnam', name: 'Tinh Bac Giang, Vietnam' },
    {
        id: 'Saint Thomas Lowland, St Kitts and Nevis',
        name: 'Saint Thomas Lowland, St Kitts and Nevis'
    },
    { id: 'Taoyuan, Taiwan', name: 'Taoyuan, Taiwan' },
    { id: 'Keryneia, Cyprus', name: 'Keryneia, Cyprus' },
    { id: 'Hadjer-Lamis, Chad', name: 'Hadjer-Lamis, Chad' },
    { id: 'Landes, France', name: 'Landes, France' },
    { id: 'Geneva, Switzerland', name: 'Geneva, Switzerland' },
    {
        id: 'Jizzakh Province, Uzbekistan',
        name: 'Jizzakh Province, Uzbekistan'
    },
    { id: 'Finnmark, Norway', name: 'Finnmark, Norway' },
    { id: 'Bissau, Guinea-Bissau', name: 'Bissau, Guinea-Bissau' },
    {
        id: 'Chiang Mai Province, Thailand',
        name: 'Chiang Mai Province, Thailand'
    },
    { id: 'Mopti, Mali', name: 'Mopti, Mali' },
    { id: 'Huesca, Spain', name: 'Huesca, Spain' },
    { id: 'Kericho District, Kenya', name: 'Kericho District, Kenya' },
    { id: 'South Sulawesi, Indonesia', name: 'South Sulawesi, Indonesia' },
    { id: 'Saint Paul’s Bay, Malta', name: 'Saint Paul’s Bay, Malta' },
    {
        id: 'Province of Sulu, Philippines',
        name: 'Province of Sulu, Philippines'
    },
    { id: 'Wiltz, Luxembourg', name: 'Wiltz, Luxembourg' },
    { id: 'Southern District, Israel', name: 'Southern District, Israel' },
    { id: 'Polzela, Slovenia', name: 'Polzela, Slovenia' },
    { id: 'Muharraq, Bahrain', name: 'Muharraq, Bahrain' },
    {
        id: 'Gyeonggi-do, Republic of Korea',
        name: 'Gyeonggi-do, Republic of Korea'
    },
    { id: 'Atakora Department, Benin', name: 'Atakora Department, Benin' },
    { id: 'Amazonas, Brazil', name: 'Amazonas, Brazil' },
    { id: 'L-Imtarfa, Malta', name: 'L-Imtarfa, Malta' },
    {
        id: 'Elektrėnai Municipality, Republic of Lithuania',
        name: 'Elektrėnai Municipality, Republic of Lithuania'
    },
    {
        id: 'Departamento de Caazapa, Paraguay',
        name: 'Departamento de Caazapa, Paraguay'
    },
    {
        id: 'Changwat Lampang, Thailand',
        name: 'Changwat Lampang, Thailand'
    },
    {
        id: 'Gloucestershire, United Kingdom',
        name: 'Gloucestershire, United Kingdom'
    },
    { id: 'El Oued, Algeria', name: 'El Oued, Algeria' },
    { id: 'Oio Region, Guinea-Bissau', name: 'Oio Region, Guinea-Bissau' },
    { id: 'Ikšķile, Latvia', name: 'Ikšķile, Latvia' },
    { id: 'Margibi County, Liberia', name: 'Margibi County, Liberia' },
    {
        id: 'North Brabant, Netherlands',
        name: 'North Brabant, Netherlands'
    },
    { id: 'Kinmen County, Taiwan', name: 'Kinmen County, Taiwan' },
    { id: 'Maranhao, Brazil', name: 'Maranhao, Brazil' },
    {
        id: 'North Rhine-Westphalia, Germany',
        name: 'North Rhine-Westphalia, Germany'
    },
    { id: 'Campeche, Mexico', name: 'Campeche, Mexico' },
    { id: 'Is-Swieqi, Malta', name: 'Is-Swieqi, Malta' },
    {
        id: 'Dumfries and Galloway, United Kingdom',
        name: 'Dumfries and Galloway, United Kingdom'
    },
    { id: 'Lilongwe District, Malawi', name: 'Lilongwe District, Malawi' },
    {
        id: 'Stockton-on-Tees, United Kingdom',
        name: 'Stockton-on-Tees, United Kingdom'
    },
    { id: 'Harghita, Romania', name: 'Harghita, Romania' },
    {
        id: 'North Lanarkshire, United Kingdom',
        name: 'North Lanarkshire, United Kingdom'
    },
    {
        id: 'Provincia di Varese, Italy',
        name: 'Provincia di Varese, Italy'
    },
    {
        id: 'Muramvya Province, Burundi',
        name: 'Muramvya Province, Burundi'
    },
    {
        id: 'Northern Mindanao, Philippines',
        name: 'Northern Mindanao, Philippines'
    },
    { id: 'Corsica, France', name: 'Corsica, France' },
    { id: 'Florida, United States', name: 'Florida, United States' },


    { id: 'East Timor', name: 'East Timor' }, // country
    { id: 'Saint Martin', name: 'Saint Martin' },
    { id: 'Guernsey', name: 'Guernsey' },
    { id: 'Turkmenistan', name: 'Turkmenistan' },
    { id: 'Saint Helena', name: 'Saint Helena' },
    { id: 'Ireland', name: 'Ireland' },
    { id: 'Cambodia', name: 'Cambodia' },
    { id: 'Ethiopia', name: 'Ethiopia' },
    { id: 'Aruba', name: 'Aruba' },
    { id: 'Swaziland', name: 'Swaziland' },
    { id: 'Palestine', name: 'Palestine' },
    { id: 'Belize', name: 'Belize' },
    { id: 'Argentina', name: 'Argentina' },
    { id: 'Bolivia', name: 'Bolivia' },
    { id: 'Cameroon', name: 'Cameroon' },
    { id: 'Burkina Faso', name: 'Burkina Faso' },
    { id: 'Bahrain', name: 'Bahrain' },
    { id: 'Saudi Arabia', name: 'Saudi Arabia' },
    { id: 'Martinique', name: 'Martinique' },
    { id: 'Togo', name: 'Togo' },
    { id: 'American Samoa', name: 'American Samoa' },
    { id: 'Slovenia', name: 'Slovenia' },
    { id: 'Guatemala', name: 'Guatemala' },
    { id: 'Bosnia and Herzegovina', name: 'Bosnia and Herzegovina' },
    { id: 'Guinea', name: 'Guinea' },
    { id: 'Spain', name: 'Spain' },
    { id: 'Australia', name: 'Australia' },
    { id: 'Liberia', name: 'Liberia' },
    { id: 'Maldives', name: 'Maldives' },
    { id: 'Jamaica', name: 'Jamaica' },
    { id: 'Oman', name: 'Oman' },
    { id: 'Tanzania', name: 'Tanzania' },
    { id: 'Bonaire', name: 'Bonaire' },
    { id: 'São Tomé and Príncipe', name: 'São Tomé and Príncipe' },
    { id: 'Costa Rica', name: 'Costa Rica' },
    { id: 'Cabo Verde', name: 'Cabo Verde' },
    { id: 'Christmas Island', name: 'Christmas Island' },
    { id: 'Gabon', name: 'Gabon' },
    { id: 'Saint Pierre and Miquelon', name: 'Saint Pierre and Miquelon' },
    { id: 'Monaco', name: 'Monaco' },
    { id: 'Bahamas', name: 'Bahamas' },
    { id: 'Wallis and Futuna', name: 'Wallis and Futuna' },
    { id: 'New Zealand', name: 'New Zealand' },
    { id: 'Yemen', name: 'Yemen' },
    { id: 'Jersey', name: 'Jersey' },
    { id: 'Pakistan', name: 'Pakistan' },
    { id: 'Albania', name: 'Albania' },
    { id: 'Samoa', name: 'Samoa' },
    { id: 'Norfolk Island', name: 'Norfolk Island' },
    { id: 'United Arab Emirates', name: 'United Arab Emirates' },
    { id: 'Guam', name: 'Guam' },
    { id: 'Uruguay', name: 'Uruguay' },
    { id: 'India', name: 'India' },
    { id: 'Svalbard and Jan Mayen', name: 'Svalbard and Jan Mayen' },
    { id: 'Lesotho', name: 'Lesotho' },
    { id: 'Saint Vincent and the Grenadines', name: 'Saint Vincent and the Grenadines' },
    { id: 'Kenya', name: 'Kenya' },
    { id: 'Tajikistan', name: 'Tajikistan' },
    { id: 'Greenland', name: 'Greenland' },
    { id: 'Turkey', name: 'Turkey' },
    { id: 'Afghanistan', name: 'Afghanistan' },
    { id: 'Bangladesh', name: 'Bangladesh' },
    { id: 'Canada', name: 'Canada' },
    { id: 'Mauritania', name: 'Mauritania' },
    { id: 'Solomon Islands', name: 'Solomon Islands' },
    { id: 'Turks and Caicos Islands', name: 'Turks and Caicos Islands' },
    { id: 'Saint Lucia', name: 'Saint Lucia' },
    { id: 'San Marino', name: 'San Marino' },
    { id: 'Mongolia', name: 'Mongolia' },
    { id: 'Azerbaijan', name: 'Azerbaijan' },
    { id: 'Rwanda', name: 'Rwanda' },
    { id: 'Slovakia', name: 'Slovakia' },
    { id: 'Somalia', name: 'Somalia' },
    { id: 'Peru', name: 'Peru' },
    { id: 'Laos', name: 'Laos' },
    { id: 'Nauru', name: 'Nauru' },
    { id: 'Norway', name: 'Norway' },
    { id: 'Malawi', name: 'Malawi' },
    { id: 'Cook Islands', name: 'Cook Islands' },
    { id: 'Benin', name: 'Benin' },
    { id: 'Federated States of Micronesia', name: 'Federated States of Micronesia' },
    { id: 'Réunion', name: 'Réunion' },
    { id: 'Western Sahara', name: 'Western Sahara' },
    { id: 'Singapore', name: 'Singapore' },
    { id: 'Montenegro', name: 'Montenegro' },
    { id: 'Republic of the Congo', name: 'Republic of the Congo' },
    { id: 'British Indian Ocean Territory', name: 'British Indian Ocean Territory' },
    { id: 'China', name: 'China' },
    { id: 'Ecuador', name: 'Ecuador' },
    { id: 'Armenia', name: 'Armenia' },
    { id: 'Antigua and Barbuda', name: 'Antigua and Barbuda' },
    { id: 'Dominican Republic', name: 'Dominican Republic' },
    { id: 'Ukraine', name: 'Ukraine' },
    { id: 'Ghana', name: 'Ghana' },
    { id: 'Tonga', name: 'Tonga' },
    { id: 'Finland', name: 'Finland' },
    { id: 'Libya', name: 'Libya' },
    { id: 'Indonesia', name: 'Indonesia' },
    { id: 'Central African Republic', name: 'Central African Republic' },
    { id: 'United States', name: 'United States' },
    { id: 'Sweden', name: 'Sweden' },
    { id: 'Vietnam', name: 'Vietnam' },
    { id: 'British Virgin Islands', name: 'British Virgin Islands' },
    { id: 'Mali', name: 'Mali' },
    { id: 'Vatican City', name: 'Vatican City' },
    { id: 'Russia', name: 'Russia' },
    { id: 'Bulgaria', name: 'Bulgaria' },
    { id: 'Mauritius', name: 'Mauritius' },
    { id: 'Romania', name: 'Romania' },
    { id: 'Angola', name: 'Angola' },
    { id: 'French Southern Territories', name: 'French Southern Territories' },
    { id: 'Chad', name: 'Chad' },
    { id: 'South Africa', name: 'South Africa' },
    { id: 'Tokelau', name: 'Tokelau' },
    { id: 'Macao', name: 'Macao' },
    { id: 'South Georgia and the South Sandwich Islands', name: 'South Georgia and the South Sandwich Islands' },
    { id: 'Liechtenstein', name: 'Liechtenstein' },
    { id: 'Malaysia', name: 'Malaysia' },
    { id: 'Senegal', name: 'Senegal' },
    { id: 'Mozambique', name: 'Mozambique' },
    { id: 'Uganda', name: 'Uganda' },
    { id: 'Hungary', name: 'Hungary' },
    { id: 'Niger', name: 'Niger' },
    { id: 'Isle of Man', name: 'Isle of Man' },
    { id: 'Brazil', name: 'Brazil' },
    { id: 'Saint-Barthélemy', name: 'Saint-Barthélemy' },
    { id: 'Falkland Islands', name: 'Falkland Islands' },
    { id: 'Faroe Islands', name: 'Faroe Islands' },
    { id: 'Kuwait', name: 'Kuwait' },
    { id: 'Panama', name: 'Panama' },
    { id: 'Guyana', name: 'Guyana' },
    { id: 'Republic of Moldova', name: 'Republic of Moldova' },
    { id: 'Madagascar', name: 'Madagascar' },
    { id: 'Luxembourg', name: 'Luxembourg' },
    { id: 'Andorra', name: 'Andorra' },
    { id: 'Gibraltar', name: 'Gibraltar' },
    { id: 'Ivory Coast', name: 'Ivory Coast' },
    { id: 'Syria', name: 'Syria' },
    { id: 'Italy', name: 'Italy' },
    { id: 'Nigeria', name: 'Nigeria' },
    { id: 'Guadeloupe', name: 'Guadeloupe' },
    { id: 'Northern Mariana Islands', name: 'Northern Mariana Islands' },
    { id: 'Brunei', name: 'Brunei' },
    { id: 'Belarus', name: 'Belarus' },
    { id: 'Iran', name: 'Iran' },
    { id: 'Algeria', name: 'Algeria' },
    { id: 'Republic of Lithuania', name: 'Republic of Lithuania' },
    { id: 'El Salvador', name: 'El Salvador' },
    { id: 'Tuvalu', name: 'Tuvalu' },
    { id: 'France', name: 'France' },
    { id: 'Czechia', name: 'Czechia' },
    { id: 'Åland', name: 'Åland' },
    { id: 'Pitcairn Islands', name: 'Pitcairn Islands' },
    { id: 'Marshall Islands', name: 'Marshall Islands' },
    { id: 'Chile', name: 'Chile' },
    { id: 'Puerto Rico', name: 'Puerto Rico' },
    { id: 'Belgium', name: 'Belgium' },
    { id: 'Thailand', name: 'Thailand' },
    { id: 'Haiti', name: 'Haiti' },
    { id: 'Iraq', name: 'Iraq' },
    { id: 'Hong Kong', name: 'Hong Kong' },
    { id: 'Sierra Leone', name: 'Sierra Leone' },
    { id: 'Georgia', name: 'Georgia' },
    { id: 'Gambia', name: 'Gambia' },
    { id: 'Poland', name: 'Poland' },
    { id: 'French Guiana', name: 'French Guiana' },
    { id: 'Morocco', name: 'Morocco' },
    { id: 'Namibia', name: 'Namibia' },
    { id: 'French Polynesia', name: 'French Polynesia' },
    { id: 'Guinea-Bissau', name: 'Guinea-Bissau' },
    { id: 'Kiribati', name: 'Kiribati' },
    { id: 'Switzerland', name: 'Switzerland' },
    { id: 'Grenada', name: 'Grenada' },
    { id: 'Myanmar [Burma]', name: 'Myanmar [Burma]' },
    { id: 'U.S. Virgin Islands', name: 'U.S. Virgin Islands' },
    { id: 'Seychelles', name: 'Seychelles' },
    { id: 'Portugal', name: 'Portugal' },
    { id: 'Estonia', name: 'Estonia' },
    { id: 'Kosovo', name: 'Kosovo' },
    { id: 'Curaçao', name: 'Curaçao' },
    { id: 'Mexico', name: 'Mexico' },
    { id: 'Lebanon', name: 'Lebanon' },
    { id: 'U.S. Minor Outlying Islands', name: 'U.S. Minor Outlying Islands' },
    { id: 'Uzbekistan', name: 'Uzbekistan' },
    { id: 'Tunisia', name: 'Tunisia' },
    { id: 'Djibouti', name: 'Djibouti' },
    { id: 'Heard Island and McDonald Islands', name: 'Heard Island and McDonald Islands' },
    { id: 'Republic of Korea', name: 'Republic of Korea' },
    { id: 'Dominica', name: 'Dominica' },
    { id: 'Colombia', name: 'Colombia' },
    { id: 'Burundi', name: 'Burundi' },
    { id: 'Taiwan', name: 'Taiwan' },
    { id: 'Cyprus', name: 'Cyprus' },
    { id: 'Barbados', name: 'Barbados' },
    { id: 'Qatar', name: 'Qatar' },
    { id: 'Palau', name: 'Palau' },
    { id: 'Bhutan', name: 'Bhutan' },
    { id: 'Sudan', name: 'Sudan' },
    { id: 'Nepal', name: 'Nepal' },
    { id: 'Malta', name: 'Malta' },
    { id: 'Netherlands', name: 'Netherlands' },
    { id: 'Bermuda', name: 'Bermuda' },
    { id: 'Suriname', name: 'Suriname' },
    { id: 'Cayman Islands', name: 'Cayman Islands' },
    { id: 'Anguilla', name: 'Anguilla' },
    { id: 'Venezuela', name: 'Venezuela' },
    { id: 'Israel', name: 'Israel' },
    { id: 'St Kitts and Nevis', name: 'St Kitts and Nevis' },
    { id: 'Iceland', name: 'Iceland' },
    { id: 'Zambia', name: 'Zambia' },
    { id: 'Austria', name: 'Austria' },
    { id: 'Papua New Guinea', name: 'Papua New Guinea' },
    { id: 'Trinidad and Tobago', name: 'Trinidad and Tobago' },
    { id: 'Zimbabwe', name: 'Zimbabwe' },
    { id: 'Germany', name: 'Germany' },
    { id: 'Vanuatu', name: 'Vanuatu' },
    { id: 'Denmark', name: 'Denmark' },
    { id: 'Kazakhstan', name: 'Kazakhstan' },
    { id: 'Philippines', name: 'Philippines' },
    { id: 'Eritrea', name: 'Eritrea' },
    { id: 'Kyrgyzstan', name: 'Kyrgyzstan' },
    { id: 'Mayotte', name: 'Mayotte' },
    { id: 'Montserrat', name: 'Montserrat' },
    { id: 'New Caledonia', name: 'New Caledonia' },
    { id: 'Macedonia', name: 'Macedonia' },
    { id: 'North Korea', name: 'North Korea' },
    { id: 'Paraguay', name: 'Paraguay' },
    { id: 'Latvia', name: 'Latvia' },
    { id: 'South Sudan', name: 'South Sudan' },
    { id: 'Japan', name: 'Japan' },
    { id: 'Croatia', name: 'Croatia' },
    { id: 'Hashemite Kingdom of Jordan', name: 'Hashemite Kingdom of Jordan' },
    { id: 'Cuba', name: 'Cuba' },
    { id: 'Sint Maarten', name: 'Sint Maarten' },
    { id: 'Honduras', name: 'Honduras' },
    { id: 'Equatorial Guinea', name: 'Equatorial Guinea' },
    { id: 'Egypt', name: 'Egypt' },
    { id: 'Nicaragua', name: 'Nicaragua' },
    { id: 'Cocos [Keeling] Islands', name: 'Cocos [Keeling] Islands' },
    { id: 'Serbia', name: 'Serbia' },
    { id: 'Comoros', name: 'Comoros' },
    { id: 'United Kingdom', name: 'United Kingdom' },
    { id: 'Antarctica', name: 'Antarctica' },
    { id: 'Congo', name: 'Congo' },
    { id: 'Greece', name: 'Greece' },
    { id: 'Sri Lanka', name: 'Sri Lanka' },
    { id: 'Fiji', name: 'Fiji' },
    { id: 'Botswana', name: 'Botswana' },
    { id: 'Niue', name: 'Niue' }
]

export default locationFilterValues;