import { ProspectStageEnum, ProspectStatusEnum, ProspectType } from "./Prospect";

export enum CampaingStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  COMPLETED = "COMPLETED"
}

export enum AvatarEnum {
  ANTELOPE = "ANTELOPE",
  BAT = "BAT",
  CAT = "CAT",
  CHICKEN = "CHICKEN",
  CRAB = "CRAB",
  DOG = "DOG",
  DOG_2 = "DOG_2",
  EAGLE = "EAGLE",
  ELEPHANT = "ELEPHANT",
  FOX = "FOX",
  HAMSTER = "HAMSTER",
  HIPPO = "HIPPO",
  KOALA = "KOALA",
  LADYBUG = "LADYBUG",
  LION = "LION",
  MONKEY = "MONKEY",
  OCTOPUS = "OCTOPUS",
  OWL = "OWL",
  PARROT = "PARROT",
  PENGUIN = "PENGUIN",
  PIG = "PIG",
  TURTLE = "TURTLE",
  UNICORN = "UNICORN",
  WHALE = "WHALE",
  WOLF = "WOLF",
  BEE = "BEE",
  SNAKE = "SNAKE",
  HORSE = "HORSE",
  SQUIRREL = "SQUIRREL",
  CATY = "CATY",
  CHAMELEON = "CHAMELEON",
  FOXX = "FOXX",
  LIONESS = "LIONESS",
  FROG = "FROG",
  CLOWNFISH = "CLOWNFISH"
}

export interface Salesforce {
  event: string;
  sfdcLeadStatus: string;
  enabled: boolean;
}
export interface CampaignType {
  id: string;
  name: string;
  imageName?: string;
  accountId?: string;
  accountName?: string;
  startTime?: number;
  firstActivationTime?: number;
  active?: boolean;
  status: string,
  autoRemoveEmailReplied: boolean;
  autoRemoveEmailRepliedBy?: boolean;
  prospectFields?: any;
  tags?: any;
  prospects?: any;
  outboundBCC?: string;
  // startDate: string;
  days?: number[];
  inboxIds?: string[];
  startHour?: number;
  endHour?: number;
  timeZone?: string;
  enableQuickline?: boolean;
  enableSalesforce?: boolean;
  enableScrubby?: boolean;
  salesforceMapping?: Salesforce[];
  dailyLimitPerTargetDomain?: number;
  // autoRemoveEmailRepliedBy: "domain" | "location" | null;
  removeProspectEmailLinkClick?: boolean;
  removeProspectEmailOpen?: boolean;
  removeProspectEmailReplied?: boolean;
  sendEmailOnHolidays?: boolean;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  userId?: string;
  startDate?: string;
  timezone?: string,
  completedTime?: number;
  enableTargetDomainDailyLimit?: boolean;
  targetDomainDailyLimitFor?: string;
  // targetDomainDailyLimit: number;
  endTime?: number,
  prospectsCreateEmailAddress?: string
  life: {
    elapsed: number,
    remaining: number
  },
  enableRestartInitialSeq:boolean,
  waitDaysForRestart:number,
}

export type CampaignStatusType = "Active" | "Inactive" | "Completed";

export interface CampaignSummaryType {
  campId: string;
  name: string;
  imageName: AvatarEnum;
  activeRecipients: number;
  lastEmailSent: number; // deprecated
  lastSentTime: number;
  replied: number;  // deprecated
  mailboxes: number;
  active: boolean;
  status: CampaignStatusType;
  timezone?: string;
}

export interface CampaignProspectType {
  campId: string;
  timezone: string;
  firstname: string;
  lastname: string;
  email: string;
  domain: string;
  company: string;
  linkedinId?: string;
  attributes: Array<{}>;
  status?: ProspectStageEnum;
  subStatus: ProspectStatusEnum;
  nextRunTime: number;
  firstTouchSentTime: number;
  lastEmailSentTime: number;
  lastTouchNumber: number;
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  sequenceType?: string;
}

export interface CampaignTagType {
  tagId: string;
  name: string;
  recipients: any;
  tos: any;
  ccs: any;
  bccs: any;
  body?: any
}

export enum CampaignResponseEnum {
  BOUNCED = "BOUNCED",
  OUTOFOFFICE = "OUTOFOFFICE",
  AUTOREPLY = "AUTOREPLY",
  MAIN = "MAIN",
  SPAM = "SPAM",
  OTHERS = "OTHERS",
}

export type InboundMessageType = {
  id: string;
  to: string;
  from: string;
  read: boolean;
  subject: string;
  textPreview: string;
  sentTime: number;
  replyType: CampaignResponseEnum;
  campaignId: string;
  tag: any;
};

export type InboundMailType = {
  inboundMessage: InboundMessageType;
  prospect: ProspectType;
};

export enum CampaignSequnceTypeEnum {
  INITIAL = "INITIAL",
  INFO = "INFO",
  POWER = "POWER",
  REFERRAL = "REFERRAL",
  FUTURE = "FUTURE",
  MEETING_REQUEST = "MEETING_REQUEST",
  MEETING_RESCHEDULE = "MEETING_RESCHEDULE",
  CUSTOM = "CUSTOM"
}

export enum CampaignCloneModule {
  sequences = "sequences",
  mailboxes = "mailboxes",
  prospects = "prospects",
  settings = "settings",
  tags = "tags",
}
interface touchItems {
  seqId?: string;
  touchNumber: number;
  waitDays: number;
  id: string;
}
export type SequenceResponseType = {
  touches: number;
  id: string;
  type: CampaignSequnceTypeEnum;
  touchItems: touchItems[]
};

export type MailThreadType = {
  campaignId: string
  campaignName:string
  prospectId?: MailThreadType;
  tagIds: string[];
  ccs: {
    encodedPersonal?: string;
    address: string;
  }[];
  smtpMessageId: string;
  reply_to: {
    address: string;
  };
  subject: string;
  html_body: string;
  meta: {
    propsectId: string;
    campId: string;
    touchId: string;
    messageId: string;
  };
  plain_text_body: string;
  tos: {
    encodedPersonal?: string;
    address: string;
  }[];
  messageId: string;
  from: {
    address: string;
  };
  tagName?: string;
  inboundMessageId: string;
  bccs: string[];
};

export interface InboundMailWithThreadType extends InboundMailType {
  mailThread: MailThreadType;
}
