export const defaultDepartments = [
    { id: '1', name: 'Engineering' },
    { id: '2', name: 'Finance & Administration' },
    { id: '3', name: 'Human Resources' },
    { id: '4', name: 'IT & IS' },
    { id: '5', name: 'Marketing' },
    { id: '6', name: 'Operations' },
    { id: '7', name: 'Sales' },
    { id: '8', name: 'Support' },
    { id: '9', name: 'Other' },
]

export const defaultLevels = [
    { id: '1', name: 'C-Level' },
    { id: '2', name: 'VP-Level' },
    { id: '3', name: 'Director-Level' },
    { id: '4', name: 'Manager-Level' },
    { id: '5', name: 'Staff' },
    { id: '6', name: 'Other' }
]

export const defaultEmployeeSize = [
    { id: '1', name: '0 - 25' },
    { id: '2', name: '25 - 100' },
    { id: '3', name: '100 - 250' },
    { id: '4', name: '250 - 1000' },
    { id: '5', name: '1K - 10K' },
    { id: '6', name: '10K - 50K' },
    { id: '7', name: '50K - 100K' },
    { id: '8', name: '> 100K' }
]

export const defaultRevenue = [
    { id: '1', name: '$0 - 1M' },
    { id: '2', name: '$1 - 10M' },
    { id: '3', name: '$10 - 50M' },
    { id: '4', name: '$50 - 100M' },
    { id: '5', name: '$100 - 250M' },
    { id: '6', name: '$250 - 500M' },
    { id: '7', name: '$500M - 1B' },
    { id: '8', name: '> $1B' }
]

export const defaultDeliveryScore = [
    { id: '75', name: '75%' },
    { id: '85', name: '85%' },
    { id: '95', name: '95%' }
]

export const defaultIndustries = [
    { id: '1', name: 'Agriculture & Mining' },
    { id: '2', name: 'Business Services' },
    { id: '3', name: 'Computers & Electronics' },
    { id: '4', name: 'Consumer Services' },
    { id: '5', name: 'Education' },
    { id: '6', name: 'Energy & Utilities' },
    { id: '7', name: 'Financial Services' },
    { id: '8', name: 'Government' },
    { id: '9', name: 'Healthcare, Pharmaceuticals, & Biotech' },
    { id: '10', name: 'Manufacturing' },
    { id: '11', name: 'Media & Entertainment' },
    { id: '12', name: 'Non-Profit' },
    { id: '13', name: 'Other' },
    { id: '14', name: 'Real Estate & Construction' },
    { id: '15', name: 'Retail' },
    { id: '16', name: 'Software & Internet' },
    { id: '17', name: 'Telecommunications' },
    { id: '18', name: 'Transportation & Storage' },
    { id: '19', name: 'Travel, Recreation, and Leisure' },
    { id: '20', name: 'Wholesale & Distribution' }
]