import { ProtectedRoute } from "auth/PrivateRoute";
import { IVY_PATH } from "constants/routes";
import NotFoundPage from "pages/404";
import AccountsPage from "pages/Accounts";
import CampaignDetailPage from "pages/CampaignDetail";
import CampaignsPage from "pages/Campaigns";
import CompanySearch from "pages/CompanySearch";
import Integrations from "pages/Integrations";
import OauthCallbackPage from "pages/OauthCallback";
import DoNotContact from "pages/Settings/DoNotContact";
import Holidays from "pages/Settings/Holiday/HolidaysModule";
import MailBoxList from "pages/Settings/Mailbox";
import MailBoxDetail from "pages/Settings/MailBoxDetail";
import Members from "pages/Settings/Members";
import ProspectDetail from "pages/Settings/ProspectDetail";
import React from "react";
import { BrowserRouter, Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { rootDomain } from "utils/string";
import DomainPage from "pages/Domain/(domain)";
import UniboxPage from 'pages/Unibox'
import LoginPage from "pages/Login";
import Notification from 'pages/Notifications'
import Subscription from 'pages/Subscription'
import DoNotContactNew from "pages/Settings/DoNotContactNew";
import Report from 'pages/Report'

const AUTHENTICATION_PATH = "/login";
export const ivyRoutes = (location: any) => {
  const redirectBack = location?.state?.from?.slice(1) ?? "";
  // const urlPram = redirectBack ? `?redirect=${redirectBack}` : "";
  const urlPram = ""
  const baseUrl = rootDomain(window.location.hostname);
  const devLogin = `https://${IVY_PATH}.${baseUrl}/#/auth/login${urlPram}`;
  const prodLogin = `https://${IVY_PATH}.${baseUrl}/#/auth/login${urlPram}`;
  const localLogin = `http://localhost:5500/#/auth/login${urlPram}`;
  window.location.href = window.location.href.includes("localhost") ? localLogin : window.location.href.includes("app.getemy.xyz") ? devLogin : prodLogin;
  return null;
};

const AgencyRoutes: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      <Switch>

        <ProtectedRoute exact={true} path="/accounts" component={AccountsPage} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute path="/account/:id/campaigns" component={CampaignsPage} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute path="/campaigns/:id" component={CampaignDetailPage} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute path="/account/:id/domains" component={DomainPage} authenticationPath={AUTHENTICATION_PATH} title="Domain" />
        <ProtectedRoute exact path="/oauth/callback" component={OauthCallbackPage} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute path="/search" component={CompanySearch} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/integrations" component={Integrations} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/mailboxes" component={MailBoxList} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/team" component={Members} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/holidays" component={Holidays} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/dnclist" component={DoNotContact} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/dnclist-new" component={DoNotContactNew} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/mailboxes/:id" component={MailBoxDetail} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/prospects/:id" component={ProspectDetail} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/account/:id/unibox" render={() => <UniboxPage levelType="ACCOUNT" campaignId="" />} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/unibox" render={() => <UniboxPage levelType="USER" campaignId="" />} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/notifications" render={() => <Notification />} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/subscription" render={() => <Subscription />} authenticationPath={AUTHENTICATION_PATH} />
        <Route
          exact
          path="/login"
          component={({ location }: RouteComponentProps<{}, any, { from: string }>) => {
            return ivyRoutes(location);
          }}
        />
        <ProtectedRoute exact={true} path="/report" component={Report} authenticationPath={AUTHENTICATION_PATH} />

        {/* <Route
          exact
          path="/login"
          component={({ location }: RouteComponentProps<{}, any, { from: string }>) => {
            return ivyRoutes(location);
          }}
        />
        {/* <Route
          exact
          path="/login" component={LoginPage}></Route> */}

        <Redirect exact from="/campaigns" to="/accounts" />
        <Redirect exact from="/" to="/accounts" />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default AgencyRoutes;
