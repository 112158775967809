import { ConfigProvider, Modal } from "antd";
import { useAuth } from "auth/AuthProvider";
import FullPageLoader from "components/Loader/FullPage";
import translationStrings from "constants/strings.json";
import AppContext from "contexts/appContext";
import { useServiceWorker } from "contexts/serviceWorkerContext";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import store from "state/store";
import { changeFavicon } from "utils/dom";
import "./App.less";
import Router from "./Router";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: translationStrings
      }
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

function App() {
  const [loadingMessage, setLoadingMessage] = useState("");
  const [modalHandle, setModalHandle] = useState<any>(null);
  const { isUpdateAvailable, updateNow } = useServiceWorker();
  const { theme, metaConfig } = useAuth();

  useEffect(() => {
    ConfigProvider.config({ theme: theme.antdConf });
    const logo = metaConfig?.faviconUrl ?? metaConfig?.appLogoS3Url;
    changeFavicon(logo);
  }, [theme, metaConfig]);

  useEffect(() => {
    if (isUpdateAvailable && !loadingMessage && !modalHandle) {
      const handle = Modal.info({
        title: "New Update",
        okText: "Update and Restart",
        content: (
          <div>
            <p>A new version of the application is available.</p>
            <p>Any unsaved changes will be lost.</p>
          </div>
        ),
        onOk() {
          updateNow();
        }
      });
      setModalHandle(handle);
    }
  }, [isUpdateAvailable, modalHandle, loadingMessage, updateNow]);

  return (
    <ReduxProvider store={store}>
      <AppContext.Provider value={{ setLoadingMessage }}>
        {loadingMessage && <FullPageLoader message={loadingMessage} />}
        <Router />
      </AppContext.Provider>
    </ReduxProvider>
  );
}

export default App;
