import { useCallback } from "react";
import { Method } from "axios";
import { message, notification } from "antd";
import { v4 } from "uuid";
import Axios from "utils/axios";
import axios, { AxiosRequestConfig } from "axios";

export interface ApiType extends AxiosRequestConfig {
  url: string;
  method: Method;
  external?: boolean;
  data?: any;
  onUploadProgress?: (progressEvent: any) => void;
  raw?: boolean;
  authRequired?: boolean;
}

export type ApiResponseType =
  | { success: true; data: any }
  | { success: false; error?: any };

export const useFakeApi = () => {
  const callApi = useCallback(
    ({ response = {}, timeout = 1000 }: any): Promise<ApiResponseType> =>
      new Promise((res) =>
        setTimeout(() => {
          res(response);
        }, timeout)
      ),
    []
  );
  return { callApi };
};

const useApi = () => {
  const callApi = useCallback(
    async (props: ApiType): Promise<ApiResponseType> => {
      return Axios.callApi(props);
    },
    []
  );

  const showUploadProgress = useCallback((progress) => {
    if (progress > -1 && progress < 100) {
      message.loading({
        content: `Uploading prospects ${progress}% complete.`,
        key: "upload-progress",
        duration: 0,
      });
    }
  }, []);

  const onUploadProgress = useCallback(
    (progressEvent) => {
      const totalLength = progressEvent.lengthComputable
        ? progressEvent.total
        : progressEvent.target.getResponseHeader("content-length") ||
          progressEvent.target.getResponseHeader(
            "x-decompressed-content-length"
          );
      showUploadProgress(
        Math.floor((progressEvent.loaded * 100) / totalLength)
      );
    },
    [showUploadProgress]
  );
  
  const uploadFileToS3 = useCallback(
    async (file: File) => {
      const fileName = `${v4()}.csv`;
      showUploadProgress(0);
      try {
        const response = await Axios.callApi({
          method: "PUT",
          url: `/presignedinurl?method=PUT&fileName=${fileName}`,
        });

        if (!response.success) {
          throw new Error();
        }

        const {
          data: { url: awsPresignedUrl },
        }: { data: { url: string } } = response;

        const fileUrl = awsPresignedUrl.match(/com\/(.*)\?/)?.[1];
        await axios.put(awsPresignedUrl, file, {
          onUploadProgress: onUploadProgress,
        });

        showUploadProgress(-1);
        message.success({
          content: `File upload complete.`,
          key: "upload-progress",
        });

        return {
          fileUrl,response
        };
      } catch (err) {
        showUploadProgress(-1);
        message.destroy();
        notification.error({
          message: "File upload failed",
          description: "Something went wrong. Please try again.",
          key: "upload-progress",
        });
      }
      return {
        fileUrl: null,
      };
    },
    [showUploadProgress, onUploadProgress]
  );

  return { callApi, uploadFileToS3 };
};

export default useApi;
