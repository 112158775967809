import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Modal, Checkbox, notification } from "antd";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import { Div, Flex } from "styles/layout";
import { SpinDetailType } from "types/Spinner";
import { Text } from "styles/typography";

interface SpinTemplateModalProps {
  spinType: SpinDetailType | null;
  onAdd: (selectedTemplates: string[]) => void;
  onCancel: () => void;
}

const SpinTemplateModal = ({ spinType, onCancel, onAdd }: SpinTemplateModalProps) => {
  const [spinTemplates, setSpinTemplates] = useState<string[]>([]);
  const [selectedSpinTemplatesIds, setSelectedSpinTemplatesIds] = useState<any[]>([]);
  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(appContext);

  const handleAdd = useCallback(() => {
    if (selectedSpinTemplatesIds.length === 0) {
      notification.error({
        message: "No templates selected",
        description: "Please select atleast 1 template to add",
        key: "modal-error"
      });

      return;
    }
    const selectedTemplates = selectedSpinTemplatesIds.map((index) => spinTemplates[index]);
    onAdd(selectedTemplates);
  }, [onAdd, selectedSpinTemplatesIds, spinTemplates]);

  const fetchSpinTemplates = useCallback(async () => {
    if (!spinType) return;

    const { url, method } = API_ENDPOINTS.SPIN_TYPES.getListSampleUrl(spinType?.name);
    setLoadingMessage("Fetching sample templates ...");

    const response = await callApi({
      url,
      method
    });

    setLoadingMessage("");
    if (response.success) {
      setSpinTemplates(response.data.spinLines);
    }
  }, [callApi, setLoadingMessage, spinType]);

  const onCheckAll = useCallback(() => {
    if (selectedSpinTemplatesIds.length < spinTemplates.length) {
      setSelectedSpinTemplatesIds(spinTemplates.map((st, index) => index));
      return;
    }

    setSelectedSpinTemplatesIds([]);
  }, [spinTemplates, selectedSpinTemplatesIds]);

  const checkAll = useMemo(() => {
    return {
      checked: selectedSpinTemplatesIds.length === spinTemplates.length,
      indeterminate: selectedSpinTemplatesIds.length !== 0 && selectedSpinTemplatesIds.length < spinTemplates.length
    };
  }, [selectedSpinTemplatesIds.length, spinTemplates.length]);

  useEffect(() => {
    fetchSpinTemplates();
  }, []);

  return (
    <Modal
      onCancel={onCancel}
      onOk={handleAdd}
      okText="Add"
      visible
      bodyStyle={{
        height: "calc(100vh - 200px)",
        overflowY: "scroll"
      }}
    >
      <Flex direction="column">
        <Text variant="sub1" mb={15}>
          {spinType?.name} Templates
        </Text>

        {!!spinTemplates.length && (
          <Div mb={10} p="0 0 10px 0">
            <Checkbox onChange={onCheckAll} checked={checkAll.checked} indeterminate={checkAll.indeterminate}>
              Select all templates
            </Checkbox>
          </Div>
        )}
        <Checkbox.Group onChange={setSelectedSpinTemplatesIds} value={selectedSpinTemplatesIds}>
          {spinTemplates.map((st, index) => (
            <Div key={index + st}>
              <Checkbox value={index}>{st}</Checkbox>
            </Div>
          ))}
        </Checkbox.Group>
      </Flex>
    </Modal>
  );
};

export default SpinTemplateModal;
