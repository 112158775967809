import { useCallback, useEffect, useState, useMemo } from "react";
import { Button, notification, Table } from "antd";
import { MOCK_MEMBERS_LIST } from "constants/mocks";
import { Div, Flex } from "styles/layout";
import InviteMemberDialog from "./InviteMemberDialog";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { useLocation } from 'react-router-dom'
import { NavPathType } from "components/AppBar";

interface MembersPageProps {
  setNavPaths: SetNavPathsFn;
}

const COLUMNS = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: string) => {
      return (
        <Div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text}
        </Div>
      );
    },
    ellipsis: true,
    width: 150,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text: string) => {
      return (
        <Div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text}
        </Div>
      );
    },
    ellipsis: true,
    width: 150,
  },
];

const Members = ({ setNavPaths }: MembersPageProps) => {
  const [showInviteMemberDialog, setShowInvitememberDialog] = useState(false);
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const location = useLocation();

  const getAccountName = useMemo((): any => {
    return location.state;
  }, []);

  useEffect(() => {
    if (getAccountName && Object.keys(getAccountName).length) {
      setAccountData({
        accountId: getAccountName.accountId,
        accountName: getAccountName.name
      });
      // localStorage.setItem("accountId", String(getAccountName.accountId));
      // localStorage.setItem("accountName", String(getAccountName.name));
    } else {
      setAccountData({
        accountId: String(localStorage.getItem("account_id")),
        accountName: String(localStorage.getItem("accountName"))
      });
    }
  }, [getAccountName, setAccountData]);


  useEffect(() => {
    const navPaths: NavPathType[] = [];
    if (accountData) {
      navPaths.push({
        label: accountData.accountName,
        linkTo: `/account/${accountData.accountId}/campaigns`,
      },
        { label: "My Team" }
      );
    }

    setNavPaths(navPaths);
    return () => setNavPaths([]);
  }, [setNavPaths, location, accountData]);

  const toggleShowInviteMemberDialog = () =>
    setShowInvitememberDialog((prev) => !prev);

  const handleMemberInvite = ({
    name,
    email,
  }: {
    name: string;
    email: string;
  }) => {
    toggleShowInviteMemberDialog();
    notification.success({
      message: "Invite successfull",
      description: `${name} invited to organization`,
    });
  };

  return (
    <Div>
      <Flex mt={10} justify="flex-end">
        <Button type="primary" onClick={toggleShowInviteMemberDialog}>
          Invite Member
        </Button>
      </Flex>

      <Div style={{ flex: 1 }} mt={10}>
        <Table
          size="small"
          pagination={{ position: [] }}
          columns={COLUMNS}
          dataSource={MOCK_MEMBERS_LIST}
          className="outwin-table"
        />
      </Div>
      {showInviteMemberDialog && (
        <InviteMemberDialog
          onOk={handleMemberInvite}
          onCancel={toggleShowInviteMemberDialog}
        />
      )}
    </Div>
  );
};

export default withAppBar(Members);
