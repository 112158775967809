import { Button, Modal, Typography } from "antd";
import { Flex } from "styles/layout";


// interface Proxy {
//   id: string;
//   host: string;
//   port: string;
//   user: string;
//   pwd: string;
// }

interface ReconnectProxyModalProps {
  handleReconnectProxyModal: () => void;
  updateProxy: () => void;
}

const ReconnectProxyModal = ({
  handleReconnectProxyModal,
  updateProxy,
}: ReconnectProxyModalProps) => {
  const { Title } = Typography;

  return (
    <Modal
      visible
      footer={null}
      closable={true}
      // keyboard={false}
      onCancel={() => handleReconnectProxyModal()}
      width={300}
      centered
    >
      <Title level={5}>Session Timeout</Title>
      <p className="fs-12" style={{ marginBottom: "15px" }}>
        Unable to connect to your mailbox using our proxy server. It's taking longer time than expected, please try again.
      </p>

      <Flex direction="row" ml={10} justify="flex-end">
        <Flex ml={10}>
          <Button onClick={updateProxy} type="primary">
            Reconnect
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ReconnectProxyModal;
