import { useMachine } from "@xstate/react";
import { Button, DatePicker, Form, Input, notification, Skeleton } from "antd";
import { API_ENDPOINTS } from "constants/api";
import { useTimeZone } from "contexts/timeZoneContext";
import useApi, { ApiResponseType } from "hooks/useApi";
import moment from "moment";
import { useEffect } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { assign, createMachine } from "xstate";

export const FETCH_MACHINE_BLUE_PRINT = createMachine<any, any>({
  id: "fetch-machine",
  initial: "idle",
  context: {
    data: undefined,
    error: undefined
  },
  states: {
    idle: {
      on: { FETCH: "loading" }
    },
    loading: {
      invoke: {
        src: "fetchData",
        onDone: {
          target: "success",
          actions: assign({
            data: (ctx, event) => ({ ...ctx.data, ...event.data })
          })
        },
        onError: {
          target: "failure",
          actions: assign({
            error: (_, event) => event.data
          })
        }
      }
    },
    success: {
      entry: "notifySuccess",
      on: { FETCH: "loading" }
    },
    failure: {
      entry: "notifyError",
      on: {
        RETRY: [{ target: "loading" }],
        FETCH: {
          target: "loading"
        }
      }
    }
  }
});

// ------------------------------------------------------------------

const WebhookListData = {
  success: true,
  data: {
    webhooks: [
      {
        event: "Open",
        url: "https://foobar.com",
        time: "19:30"
      },
      {
        event: "Reply",
        url: "",
        time: "19:30"
      },
      {
        event: "Tag",
        url: "",
        time: "19:30"
      },
      {
        event: "DailyMetrics",
        url: "",
        time: "19:30"
      }
    ]
  }
};

type WebhookProps = {
  campaignId: string;
};
type WebhookItem = typeof WebhookListData["data"]["webhooks"];

// -------------------------------------------------------------

const STRINGS = {
  SAVE: "Save",
  RETRY: "Retry",
  WEBHOOK_SUCCESS: "Webhook updated successfully",
  WEBHOOK_FAILURE: "Error while updating Webhook configuration",
  INVALID_URL: "Invalid url!",
  REQUIRED: "Required!"
};

const RULES = [
  {
    type: "url" as const,
    message: STRINGS.INVALID_URL
  }
];

const WebhookFormMeta = [
  {
    label: "When a prospect opens an email, send notification to",
    placeholder: "Webhook URL (Where to send the event?)",
    event: "OPEN",
    form: {
      type: "input",
      name: "url",
      required: false,
      rules: RULES
    }
  },
  {
    label: "When a prospect replies to an email, send notification to",
    placeholder: "Webhook URL (Where to send the event?)",
    event: "REPLY",
    form: {
      type: "input",
      name: "url",
      required: false,
      rules: RULES
    }
  },
  {
    label: "When a prospect response is tagged by me, send notification to",
    placeholder: "Webhook URL (Where to send the event?)",
    event: "TAG",
    form: {
      type: "input",
      name: "url",
      required: false,
      rules: RULES
    }
  },
  {
    label: "send daily metrics to",
    placeholder: "Webhook URL (Where to send the event?)",
    event: "DAILYMETRICS",
    form: {
      type: "input",
      name: "url",
      required: true,
      rules: RULES
    },
    subForm: {
      name: "time",
      type: "time"
    }
  }
];
// ---------------------------

const WebhookInternal = (props: WebhookProps) => {
  const [webhookForm] = Form.useForm();
  const { timeZones } = useTimeZone();
  const { callApi } = useApi();
  const { url: urlList, method: methodList } = API_ENDPOINTS.MAILBOX.webhookList(props.campaignId);
  const { url: urlUpdate, method: methodUpdate } = API_ENDPOINTS.MAILBOX.webhookUpdate(props.campaignId);
  const [stateList, sendList] = useMachine(FETCH_MACHINE_BLUE_PRINT, {
    services: {
      fetchData: (_, evt) =>
        callApi({
          url: urlList,
          method: methodList
        }).then((res: ApiResponseType) => {
          if (res.success) {
            return res.data?.webhooks;
          } else {
            throw res.error;
          }
        })
    }
  });

  const [statePost, sendPost] = useMachine(FETCH_MACHINE_BLUE_PRINT, {
    actions: {
      notifySuccess: () => notification.success({ message: STRINGS.WEBHOOK_SUCCESS })
    },
    services: {
      fetchData: (_, evt) =>
        callApi({
          url: urlUpdate,
          method: methodUpdate,
          data: evt.data
        }).then((res: ApiResponseType) => {
          if (res.success) {
            return res.data;
          } else {
            throw res.error;
          }
        })
    }
  });

  useEffect(() => {
    sendList({ type: "FETCH" });
  }, [sendList]);

  const isUpdating = statePost.value === "loading";
  const timeZone = timeZones.find((elTz) => elTz.name === localStorage.getItem("accountTimezone"))?.label;

  switch (stateList.value) {
    case "loading":
      return <Skeleton active />;
    case "failure":
      return (
        <>
          <p>{stateList.context.error.message}</p>
          <Button onClick={() => sendList("RETRY")}>{STRINGS.RETRY}</Button>
        </>
      );
    case "success":
      return (
        <Form
          colon={false}
          layout="vertical"
          form={webhookForm}
          style={{ width: "100%" }}
          onFinish={(values) => {
            sendPost({ type: "FETCH", data: Object.entries(values).map(([, v]) => v) });
          }}
          initialValues={stateList.context.data}
        >
          <Flex justify="end">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                {STRINGS.SAVE}
              </Button>
            </Form.Item>
          </Flex>
          {WebhookFormMeta.map((wMeta, idx) => {
            return (
              <Div key={wMeta.event} mt={-10}>
                <Form.Item name={[idx, "event"]} initialValue={wMeta.event} hidden>
                  <Input type="hidden" />
                </Form.Item>

                <Flex direction="column" gap={10}>
                  <Flex align="center">
                    {wMeta.subForm ? (
                      <Flex gap={10} align="center">
                        <Text variant="sub2">Everyday at </Text>
                        <Flex align="center">
                          <Form.Item
                            noStyle
                            name={[idx, wMeta.subForm.name]}
                            getValueFromEvent={(onChange) => (onChange ? moment(onChange).format("HH:mm") : "")}
                            getValueProps={(i) => {
                              return { value: i ? moment(i, "HH:mm") : "" };
                            }}
                          >
                            <DatePicker picker="time" format="HH:mm" disabled={isUpdating} />
                          </Form.Item>
                          <Text variant="sub2" ml={10}>
                            {timeZone + ","} &nbsp;
                          </Text>
                        </Flex>
                      </Flex>
                    ) : null}
                    <Text variant="sub2"> {wMeta.label} </Text>
                  </Flex>

                  <Flex>
                    <Form.Item noStyle shouldUpdate={true}>
                      {({ getFieldValue }) => {
                        return getFieldValue(idx).time ? (
                          <>
                            <Text variant="caption" color="error" mt={4} mr={4}>
                              *
                            </Text>
                            <Form.Item
                              name={[idx, wMeta.form.name]}
                              rules={[...wMeta.form.rules, { required: true, message: STRINGS.REQUIRED }]}
                              initialValue=""
                              required={true}
                            >
                              <Input style={{ width: 400 }} placeholder={wMeta.placeholder} disabled={isUpdating} />
                            </Form.Item>
                          </>
                        ) : (
                          <Form.Item name={[idx, wMeta.form.name]} rules={wMeta.form.rules} initialValue="">
                            <Input style={{ width: 400 }} placeholder={wMeta.placeholder} disabled={isUpdating} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Flex>
                </Flex>
              </Div>
            );
          })}
        </Form>
      );
    default:
      return null;
  }
};

export const Webhook = (props: WebhookProps) => {
  return (
    <Flex mt={20}>
      <WebhookInternal {...props} />
    </Flex>
  );
};
