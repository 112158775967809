import { useCallback, useContext, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, notification, Select } from "antd";
import { useState } from "react";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import CampaignActions from "state/actions/campaign";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignTagsSelector } from "state/selectors";
import { useHistory } from "react-router-dom";
import appContext from "contexts/appContext";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";

interface TagMailModalProps {
  onDismiss: () => void;
  campaignId: string;
  inboundMessageId: null | string;
  fetchInboundMails: (pageInfo: any) => void;
  tag: any;
  pageInfo: any
}
const TagMailModal = ({
  onDismiss,
  campaignId,
  inboundMessageId,
  fetchInboundMails,
  tag,
  pageInfo
}: TagMailModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setLoadingMessage } = useContext(appContext);
  const { callApi } = useApi();
  const { tags } = useSelector(getCampaignTagsSelector(campaignId));
  const [tagId, setTagId] = useState("");

  const fetchTags = useCallback(() => {
    dispatch(CampaignActions.fetchTags(campaignId));
  }, [campaignId, dispatch]);

  useEffect(() => {
    fetchTags();
  }, []);

  const onSelctionClick = (v: any) => {
    if (v === "Create Tag") {
      history.push(`/campaigns/${campaignId}/tag`);
      return;
    }
    setTagId(v);
  };

  const onSave = useCallback(async () => {
    setLoadingMessage("Saving Tag ...");
    const { url, method } = API_ENDPOINTS.INBOUND.getInboundTagUrl();
    let payload = {
      inboundMessageId,
      tagId: tagId,
      campaignId,
    };
    const response = await callApi({
      url,
      method,
      data: payload,
    });
    if (response.success) {
      notification.success({ message: "Tag saved successfully" });
      onDismiss();
      fetchInboundMails(pageInfo);
    }
    setLoadingMessage("");
  }, [
    callApi,
    campaignId,
    fetchInboundMails,
    inboundMessageId,
    onDismiss,
    setLoadingMessage,
    tagId,
  ]);

  return (
    <Modal
      visible
      onCancel={onDismiss}
      bodyStyle={{ padding: 30 }}
      okText="Save"
      onOk={onSave}
    >
      <Flex direction="column" mb={10}>
        <Text variant="overline" block color="secondary">
          Select Delivery
        </Text>
        <Select
          style={{ width: "200px", marginBottom: 10 }}
          onChange={(v) => onSelctionClick(v)}
          defaultValue={tags.find((x) => x.tagId === tag.tagId)?.name}
        >
          {tags && tags.map((data, index) => (
            <Select.Option value={data.tagId} key={index}>
              {data.name}
            </Select.Option>
          ))}
          <Select.Option value="Create Tag">
            <PlusOutlined /> Create New Tag
          </Select.Option>
        </Select>
      </Flex>
    </Modal>
  );
};
export default TagMailModal;
