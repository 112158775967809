import { useCallback, useEffect, useState } from "react";
import { CampaignCloneModule } from "types/Campaign";
import { Flex, } from "styles/layout";
import { Text } from "styles/typography";
import { FormInstance, Input, notification, Button } from "antd";
import { Form, Checkbox } from "antd";
import { useSelector } from "react-redux";
import { canModifyCampaignState } from "state/selectors";
export interface CloneCampaignProps {
  onContinue: (mailboxType: any, campaignName: string) => void;
  form: FormInstance;
  setProviders: (type?: any) => void;
  campaignId: string;
}

const PROVIDERS = [
  {
    name: "Sequences",
    value: CampaignCloneModule.sequences,
    is_checked: true,
  },
  // {
  //   name: "Mailboxes",
  //   value: CampaignCloneModule.mailboxes,
  //   is_checked: false,
  // },
  {
    name: "Prospects",
    value: CampaignCloneModule.prospects,
    is_checked: false,
  },
  {
    name: "Settings",
    value: CampaignCloneModule.settings,
    is_checked: true,
  },
  // {
  //   name: "Tags",
  //   value: CampaignCloneModule.tags,
  //   is_checked: false,
  // },
];

const CloneCampaign = ({
  onContinue,
  form,
  setProviders,
  campaignId
}: CloneCampaignProps) => {
  const [provider, setProvider] = useState<any | []>([]);
  const [modules, setModules] = useState(PROVIDERS)
  const isFree = useSelector(canModifyCampaignState(campaignId));

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  useEffect(() => {
    if (!isFree) {
      setModules(modules.filter(md => md.value !== CampaignCloneModule.mailboxes))
    }
  }, [])

  const getOnChange = useCallback((field: CampaignCloneModule) => {
    setModules(modules.map((md) => {
      if (md.value === field) {
        md.is_checked = !md.is_checked
      }
      return md
    }))
    const checkedModules = modules.filter((md) => (md.is_checked === true))
    setProvider(checkedModules.map((d: any) => d.value));
  }, [modules, setModules, setProvider, setProviders])

  const onSubmit = useCallback(
    async (formValues: any) => {
      const { campaignName } = formValues;
      if (!modules.filter(md => md.is_checked === true).length) {
        notification.error({ message: "Please Select Atleast One Data." });
        return;
      }
      if (!provider || !provider.length) {
        let checkedModules: any = modules.filter((md) => (md.is_checked === true))
        checkedModules = checkedModules.map((d: any) => d.value)
        if (!checkedModules || !checkedModules.length) {
          return
        }
        onContinue(checkedModules, campaignName);
      } else {
        onContinue(provider, campaignName);
      }
    },
    [onContinue, modules, provider]
  );

  const renderCampaignInput = () => {
    return (
      <>
        <Text variant="overline" mb={5} color="secondary">
          Campaign Name
        </Text>
        <Flex direction="row">
          <Form.Item name={"campaignName"}
            rules={[
              {
                required: true,
                message: "Please write Campaign Name",
              },
            ]}>
            <Input type="text" style={{ width: 300 }} />
          </Form.Item>
        </Flex>
      </>
    );
  };

  return (
    <Form form={form} onFinish={onSubmit}>
      <Flex>
        <Text variant="h6" align="center" mt={5} mb={10} block>
          Clone Campaign
        </Text>
      </Flex>
      <Flex direction="column">{renderCampaignInput()}</Flex>
      <Flex mb={5} justify="space-between">

        <Text variant="overline" block mb={10} color="secondary">
          Identify which data need to be cloned
        </Text>
      </Flex>
      <Flex mb={30}>
        {modules.map(({ name, value, is_checked }, index) => (
          <Checkbox value={value} checked={is_checked} onChange={() => getOnChange(value)}>{name}</Checkbox>
        ))}
      </Flex>
      <Flex ml={5}>
        <Button htmlType="submit" type="primary">
          Clone
        </Button>
      </Flex>
    </Form >
  );
};

export default CloneCampaign;