import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Radio, Tooltip } from "antd";

export enum MailboxViewEnum {
  CARD = "card",
  TABLE = "table"
}

export const MailboxViewModes = [
  { id: MailboxViewEnum.CARD, label: "Card", icon: <AppstoreOutlined /> },
  { id: MailboxViewEnum.TABLE, label: "Table", icon: <UnorderedListOutlined /> }
];

export type MailboxToggleViewProps = {
  active?: string;
  onChange?: (e: any) => void;
};

export const MailboxToggleView = (props: MailboxToggleViewProps) => {
  return (
    <Radio.Group
      buttonStyle="solid"
      value={props?.active}
      onChange={(e) => props?.onChange && props.onChange(e.target.value)}
    >
      {MailboxViewModes.map((vm) => (
        <Radio.Button key={vm.id} value={vm.id}>
          <Tooltip key={vm.id} title={vm.label}>
            {vm.icon}
          </Tooltip>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
