/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Input, Button, Card, Form, message, notification, Carousel } from "antd";
import classes from "./styles.module.css";
import { useAuth } from "auth/AuthProvider";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import appContext from "contexts/appContext";
import { Div, Flex } from "styles/layout";
import LoginIllus from "images/login.svg";
import TweenOne from "rc-tween-one";
import BannerAnim, { Element } from "rc-banner-anim";

export const TweenOneYG = (props: any) => {
  const r = Math.round(Math.random() * 2) - 1 ? 1 : -1;
  return (
    <TweenOne
      component="g"
      animation={{
        y: r * (Math.random() * 20 + 10),
        yoyo: true,
        repeat: -1,
        duration: Math.random() * 2000 + 2000,
        ease: "easeInOutSine"
      }}
    >
      {props.children}
    </TweenOne>
  );
};

export const Banner = ({ wrapperClass }: any) => {
  return (
    <BannerAnim className={wrapperClass}>
      <Element
        style={{ height: "100%" }}
        followParallax={{
          delay: 1000,
          data: [
            { id: "r-1", value: 20, type: "x" },
            { id: "p-1", value: -20, type: "x" },
            { id: "p-2", value: 30, type: "x" },
            { id: "c-1", value: -30, type: "x" },
            { id: "r-2", value: -10, type: "x" },
            { id: "c-2", value: 40, type: "x" },
            { id: "c-3", value: -30, type: "x" }
          ] as any
        }}
      >
        <TweenOne component="" animation={{ opacity: 0, type: "from", delay: 300 }} key="tween">
          <svg width="100%" height="100%" viewBox={"0 0 1440 451"}>
            <g transform="translate(-30, 0)" id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group-9" opacity="0.3">
                <TweenOneYG>
                  <g id="r-1" key="r-1">
                    <rect
                      id="Rectangle-6"
                      fill="#ff0000"
                      transform="translate(41.000000, 169.000000) rotate(-27.000000) translate(-41.000000, -169.000000) "
                      x="11"
                      y="139"
                      width="60"
                      height="60"
                      rx="2"
                    />
                  </g>
                </TweenOneYG>
                <TweenOneYG>
                  <g id="p-1">
                    <polygon
                      id="Polygon"
                      stroke="#ff0000"
                      strokeWidth="2"
                      transform="translate(139.000000, 17.500000) rotate(45.000000) translate(-139.000000, -17.500000) "
                      points="139 77 152 100 126 100"
                    />
                  </g>
                </TweenOneYG>
                <TweenOneYG>
                  <g id="p-2">
                    <polygon
                      id="Polygon"
                      stroke="#ff0000"
                      transform="translate(180.000000, 446.000000) rotate(-67.000000) translate(-180.000000, -446.000000) "
                      points="180 439 188 453 172 453"
                    />
                  </g>
                </TweenOneYG>
                <TweenOneYG>
                  <g id="c-1">
                    <circle id="Oval" stroke="#ff0000" strokeWidth="3" fill="#ff0000" cx="156" cy="357" r="10" />
                  </g>
                </TweenOneYG>
              </g>
              <g id="Group-8" transform="translate(1219.000000, 69.000000)" stroke="#ff0000">
                <TweenOneYG>
                  <g id="r-2">
                    <rect
                      id="Rectangle-6"
                      strokeWidth="2"
                      opacity="0.3"
                      transform="translate(47.000000, 343.000000) rotate(-45.000000) translate(-47.000000, -343.000000) "
                      x="75"
                      y="311"
                      width="64"
                      height="64"
                      rx="2"
                    />
                  </g>
                </TweenOneYG>
                <TweenOneYG>
                  <g id="c-2">
                    <circle id="Oval" strokeWidth="3" fill="#ff0000" opacity="0.4" cx="155" cy="13" r="13" />
                  </g>
                </TweenOneYG>
                <TweenOneYG>
                  <g id="c-3">
                    <circle id="Oval" strokeWidth="2" opacity="0.4" cx="211" cy="225" r="8" />
                  </g>
                </TweenOneYG>
              </g>
            </g>
          </svg>
        </TweenOne>
      </Element>
    </BannerAnim>
  );
};
interface Credentials {
  email: string;
  password: string;
}

interface LocationState {
  from?: { pathname: string; search: string };
  payload?: any;
}

interface LoginProps extends RouteComponentProps<{}, StaticContext, LocationState> {}

const Login = ({ history }: LoginProps) => {
  const { user, isCurrentSessionLoading } = useAuth();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { setLoadingMessage } = useContext(appContext);

  React.useEffect(() => {
    document.title = "Login | Emy";
    if (isCurrentSessionLoading) {
      setLoadingMessage("Loading ...");
      return;
    }
    setLoadingMessage("");
  }, [isCurrentSessionLoading, setLoadingMessage]);

  const onSignIn: (form: Credentials) => Promise<void> = async ({ email, password }) => {
    notification.destroy();
    setIsLoading(true);
    try {
      message.loading({
        content: "Signing in...",
        key: "message-loading",
        duration: 0
      });
      const user = await Auth.signIn(email, password);
      message.success({
        content: `Hello ${user.attributes.given_name} ${user.attributes.family_name}`,
        key: "message-loading",
        duration: 2
      });
    } catch (error) {
      message.destroy();
      const { message: errorMessage, code } = error as any;

      if (code === "UserNotConfirmedException") {
        message.warning({
          content: `Account verification pending for ${email}. Please verify account.`,
          key: "message-loading",
          duration: 2
        });
        // TODO: Move account verification to another page and handle this edge case
        history.push("/forgot-password", {
          payload: { email }
        });
        return;
      }
      notification.error({
        message: "Error",
        description: errorMessage
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // TODO: Fix authenticated user redirection bug here
    if (user) {
      let redirectPathname = "/",
        redirectSearch = "";
      if (history.location.state?.from) {
        const { pathname, search } = history.location.state.from;

        redirectPathname = pathname;
        redirectSearch = decodeURI(search);
      }
      history.replace({ pathname: redirectPathname, search: redirectSearch });
    }

    return () => notification.destroy();
  }, [user, history]);

  if (isCurrentSessionLoading) {
    return null;
  }

  return (
    <Flex className={classes.loginCont} justify="center" align="center" style={{ minHeight: "100vh" }}>
      <Banner wrapperClass={classes.bgWrapper} />
      <Flex className={classes.loginBox} justify="space-between" direction="row-reverse" align="center">
        <Flex className={classes.illustrationWrapper} justify="center">
          <img style={{ width: "75%" }} src={LoginIllus} alt="Login" />
        </Flex>
        <Form
          className={classes.loginForm}
          scrollToFirstError
          form={form}
          onFinish={onSignIn}
          layout="vertical"
          requiredMark={false}
          autoComplete="off"
        >
          <Flex mb={25} align="center" gap={30} className={classes.header}>
            <Flex br="50%" h="60px" w="60px" align="center" justify="center" style={{ background: "#ffe9d5" }}>
              <img src="/favicon.png" style={{ height: "28px" }} alt="Emy Logo" />
            </Flex>

            <Div>
              <p className={classes.formTitle}>Welcome Back</p>
              <p className={classes.description}>Login to Emy</p>
            </Div>
          </Flex>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail."
              },
              {
                required: true,
                message: "Please input your E-mail"
              }
            ]}
          >
            <Input style={{ minHeight: "48px" }} autoComplete="off" placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter your password."
              }
            ]}
          >
            <Input.Password style={{ minHeight: "48px" }} placeholder="Password" />
          </Form.Item>

          <Button
            style={{ width: "100%", minHeight: "48px", borderRadius: "3px" }}
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            LOGIN
          </Button>
          <div className={classes.formFooter}>
            <Link to="/forgot-password">Forgot Password</Link>
            <Link to="/register">Sign Up</Link>
          </div>
        </Form>
      </Flex>
    </Flex>
  );
};

export default withRouter(Login);
