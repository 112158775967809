
import { Select,Form} from "antd";

 const { Option } = Select;
export const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 100 }}>
        <Option value="AL" countryphonecode="+355">Albania </Option>
        <Option value="DZ" countryphonecode="+213">Algeria </Option>
        <Option value="AD" countryphonecode="+376">Andorra </Option>
        <Option value="AO" countryphonecode="+244">Angola </Option>
        <Option value="AQ" countryphonecode="+672">Antarctica</Option>
        <Option value="AG" countryphonecode="+1">Antigua and Barbuda </Option>
        <Option value="AR" countryphonecode="+54">Argentina</Option>
        <Option value="AM" countryphonecode="+374">Armenia </Option>
        <Option value="AW" countryphonecode="+297">Aruba </Option>
        <Option value="AU" countryphonecode="+61">Australia </Option>
        <Option value="AT" countryphonecode="+43">Austria </Option>
        <Option value="AZ" countryphonecode="+994">Azerbaijan</Option>
        <Option value="BS" countryphonecode="+1">Bahamas</Option>
        <Option value="BH" countryphonecode="+973">Bahrain</Option>
        <Option value="BD" countryphonecode="+880">Bangladesh </Option>
        <Option value="BB" countryphonecode="+1">Barbados </Option>
        <Option value="BY" countryphonecode="+375">Belarus </Option>
        <Option value="BE" countryphonecode="+32">Belgium </Option>
        <Option value="BZ" countryphonecode="+501">Belize</Option>
        <Option value="BJ" countryphonecode="+229">Benin</Option>
        <Option value="BM" countryphonecode="+1">Bermuda </Option>
        <Option value="BT" countryphonecode="+975">Bhutan</Option>
        <Option value="BO" countryphonecode="+591">Bolivia</Option>
        <Option value="BQ" countryphonecode="+599">Bonaire</Option>
        <Option value="BA" countryphonecode="+387">Bosnia and Herzegovina</Option>
        <Option value="BW" countryphonecode="+267">Botswana</Option>
        <Option value="BV" countryphonecode="+47">Bouvet Island</Option>
        <Option value="BR" countryphonecode="+55">Brazil</Option>
        <Option value="IO" countryphonecode="+44">British Indian Ocean Territory</Option>
        <Option value="VG" countryphonecode="+1">British Virgin Islands</Option>
        <Option value="BN" countryphonecode="+673">Brunei</Option>
        <Option value="BG" countryphonecode="+359">Bulgaria</Option>
        <Option value="BF" countryphonecode="+226">Burkina Faso</Option>
        <Option value="BI" countryphonecode="+257">Burundi</Option>
        <Option value="CV" countryphonecode="+238">Cabo Verde</Option>
        <Option value="KH" countryphonecode="+855">Cambodia</Option>
        <Option value="CM" countryphonecode="+237">Cameroon</Option>
        <Option value="CA" countryphonecode="+1">Canada</Option>
        <Option value="KY" countryphonecode="+1">Cayman Islands</Option>
        <Option value="CF" countryphonecode="+236">Central African Republic</Option>
        <Option value="TD" countryphonecode="+235">Chad </Option>
        <Option value="CL" countryphonecode="+56">Chile </Option>
        <Option value="CN" countryphonecode="+86">China</Option>
        <Option value="CX" countryphonecode="+61">Christmas Island</Option>
        <Option value="CC" countryphonecode="+61">Cocos (Keeling) Islands</Option>
        <Option value="CO" countryphonecode="+57">Colombia</Option>
        <Option value="KM" countryphonecode="+269">Comoros</Option>
        <Option value="CG" countryphonecode="+242">Congo</Option>
        <Option value="CD" countryphonecode="+243">Congo (DRC)</Option>
        <Option value="CK" countryphonecode="+682">Cook Islands</Option>
        <Option value="CR" countryphonecode="+506">Costa Rica</Option>
        <Option value="CI" countryphonecode="+225">Côte d’Ivoire</Option>
        <Option value="HR" countryphonecode="+385">Croatia</Option>
        <Option value="CU" countryphonecode="+53">Cuba</Option>
        <Option value="CW" countryphonecode="+599">Curaçao</Option>
        <Option value="CY" countryphonecode="+357">Cyprus</Option>
        <Option value="CZ" countryphonecode="+420">Czech Republic</Option>
        <Option value="DK" countryphonecode="+45">Denmark</Option>
        <Option value="DJ" countryphonecode="+253">Djibouti</Option>
        <Option value="DM" countryphonecode="+1">Dominica1</Option>
        <Option value="DO" countryphonecode="+1">Dominican Republic</Option>
        <Option value="EC" countryphonecode="+593">Ecuador</Option>
        <Option value="EG" countryphonecode="+20">Egypt</Option>
        <Option value="SV" countryphonecode="+503">El Salvador</Option>
        <Option value="GQ" countryphonecode="+240">Equatorial Guinea</Option>
        <Option value="ER" countryphonecode="+291">Eritrea</Option>
        <Option value="EE" countryphonecode="+372">Estonia</Option>
        <Option value="SZ" countryphonecode="+268">eSwatini</Option>
        <Option value="ET" countryphonecode="+251">Ethiopia</Option>
        <Option value="FK" countryphonecode="+500">Falkland Islands</Option>
        <Option value="FO" countryphonecode="+298">Faroe Islands</Option>
        <Option value="FJ" countryphonecode="+679">Fiji</Option>
        <Option value="FI" countryphonecode="+358">Finland</Option>
        <Option value="FR" countryphonecode="+33">France</Option>
        <Option value="GF" countryphonecode="+594">French Guiana</Option>
        <Option value="PF" countryphonecode="+689">French Polynesia</Option>
        <Option value="GA" countryphonecode="+241">Gabon</Option>
        <Option value="GM" countryphonecode="+220">Gambia</Option>
        <Option value="GE" countryphonecode="+995">Georgia</Option>
        <Option value="DE" countryphonecode="+49">Germany</Option>
        <Option value="GH" countryphonecode="+233">Ghana</Option>
        <Option value="GI" countryphonecode="+350">Gibraltar</Option>
        <Option value="GR" countryphonecode="+30">Greece</Option>
        <Option value="GL" countryphonecode="+299">Greenland</Option>
        <Option value="GD" countryphonecode="+1">Grenada1</Option>
        <Option value="GP" countryphonecode="+590">Guadeloupe</Option>
        <Option value="GU" countryphonecode="+1">Guam</Option>
        <Option value="GT" countryphonecode="+502">Guatemala</Option>
        <Option value="GG" countryphonecode="+44">Guernsey</Option>
        <Option value="GN" countryphonecode="+224">Guinea</Option>
        <Option value="GW" countryphonecode="+245">Guinea-Bissau</Option>
        <Option value="GY" countryphonecode="+592">Guyana</Option>
        <Option value="HT" countryphonecode="+509">Haiti</Option>
        <Option value="HN" countryphonecode="+504">Honduras</Option>
        <Option value="HK" countryphonecode="+852">Hong Kong SAR</Option>
        <Option value="HU" countryphonecode="+36">Hungary</Option>
        <Option value="IS" countryphonecode="+354">Iceland</Option>
        <Option value="IN" countryphonecode="+91">India</Option>
        <Option value="ID" countryphonecode="+62">Indonesia</Option>
        <Option value="IR" countryphonecode="+98">Iran</Option>
        <Option value="IQ" countryphonecode="+964">Iraq</Option>
        <Option value="IE" countryphonecode="+353">Ireland</Option>
        <Option value="IM" countryphonecode="+44">Isle of Man</Option>
        <Option value="IL" countryphonecode="+972">Israel</Option>
        <Option value="IT" countryphonecode="+39">Italy</Option>
        <Option value="JM" countryphonecode="+1">Jamaica</Option>
        <Option value="JP" countryphonecode="+81">Japan</Option>
        <Option value="JE" countryphonecode="+44">Jersey</Option>
        <Option value="JO" countryphonecode="+962">Jordan</Option>
        <Option value="KZ" countryphonecode="+7">Kazakhstan</Option>
        <Option value="KE" countryphonecode="+254">Kenya</Option>
        <Option value="KI" countryphonecode="+686">Kiribati</Option>
        <Option value="KR" countryphonecode="+82">Korea</Option>
        <Option value="XK" countryphonecode="+383">Kosovo</Option>
        <Option value="KW" countryphonecode="+965">Kuwait</Option>
        <Option value="KG" countryphonecode="+996">Kyrgyzstan</Option>
        <Option value="LA" countryphonecode="+856">Laos</Option>
        <Option value="LV" countryphonecode="+371">Latvia</Option>
        <Option value="LB" countryphonecode="+961">Lebanon</Option>
        <Option value="LS" countryphonecode="+266">Lesotho</Option>
        <Option value="LR" countryphonecode="+231">Liberia</Option>
        <Option value="LY" countryphonecode="+218">Libya</Option>
        <Option value="LI" countryphonecode="+423">Liechtenstein</Option>
        <Option value="LT" countryphonecode="+370">Lithuania</Option>
        <Option value="LU" countryphonecode="+352">Luxembourg</Option>
        <Option value="MO" countryphonecode="+853">Macao SAR</Option>
        <Option value="MK" countryphonecode="+389">Macedonia, FYRO</Option>
        <Option value="MG" countryphonecode="+261">Madagascar</Option>
        <Option value="MW" countryphonecode="+265">Malawi</Option>
        <Option value="MY" countryphonecode="+60">Malaysia</Option>
        <Option value="MV" countryphonecode="+960">Maldives</Option>
        <Option value="ML" countryphonecode="+223">Mali</Option>
        <Option value="MT" countryphonecode="+356">Malta</Option>
        <Option value="MH" countryphonecode="+692">Marshall Islands</Option>
        <Option value="MQ" countryphonecode="+596">Martinique</Option>
        <Option value="MR" countryphonecode="+222">Mauritania</Option>
        <Option value="MU" countryphonecode="+230">Mauritius</Option>
        <Option value="YT" countryphonecode="+269">Mayotte</Option>
        <Option value="MX" countryphonecode="+52">Mexico</Option>
        <Option value="FM" countryphonecode="+691">Micronesia</Option>
        <Option value="MD" countryphonecode="+373">Moldova</Option>
        <Option value="MC" countryphonecode="+377">Monaco</Option>
        <Option value="MN" countryphonecode="+976">Mongolia</Option>
        <Option value="ME" countryphonecode="+382">Montenegro</Option>
        <Option value="MS" countryphonecode="+1">Montserrat</Option>
        <Option value="MA" countryphonecode="+212">Morocco</Option>
        <Option value="MZ" countryphonecode="+258">Mozambique</Option>
        <Option value="MM" countryphonecode="+95">Myanmar</Option>
        <Option value="NA" countryphonecode="+264">Namibia</Option>
        <Option value="NR" countryphonecode="+674">Nauru</Option>
        <Option value="NP" countryphonecode="+977">Nepal</Option>
        <Option value="NL" countryphonecode="+31">Netherlands</Option>
        <Option value="NC" countryphonecode="+687">New Caledonia</Option>
        <Option value="NZ" countryphonecode="+64">New Zealand6</Option>
        <Option value="NI" countryphonecode="+505">Nicaragua</Option>
        <Option value="NE" countryphonecode="+227">Niger</Option>
        <Option value="NG" countryphonecode="+234">Nigeria</Option>
        <Option value="NU" countryphonecode="+683">Niue</Option>
        <Option value="KP" countryphonecode="+850">North Korea</Option>
        <Option value="MP" countryphonecode="+1">Northern Mariana Islands</Option>
        <Option value="NO" countryphonecode="+47">Norway</Option>
        <Option value="OM" countryphonecode="+968">Oman</Option>
        <Option value="PK" countryphonecode="+92">Pakistan</Option>
        <Option value="PW" countryphonecode="+680">Palau</Option>
        <Option value="PS" countryphonecode="+970">Palestinian Authority</Option>
        <Option value="PA" countryphonecode="+507">Panama</Option>
        <Option value="PG" countryphonecode="+675">Papua New Guinea</Option>
        <Option value="PY" countryphonecode="+595">Paraguay</Option>
        <Option value="PE" countryphonecode="+51">Peru</Option>
        <Option value="PH" countryphonecode="+63">Philippines</Option>
        <Option value="PL" countryphonecode="+48">Poland</Option>
        <Option value="PT" countryphonecode="+351">Portugal</Option>
        <Option value="QA" countryphonecode="+974">Qatar</Option>
        <Option value="RE" countryphonecode="+262">Réunion</Option>
        <Option value="RO" countryphonecode="+40">Romania</Option>
        <Option value="RU" countryphonecode="+7">Russian Federation</Option>
        <Option value="RW" countryphonecode="+250">Rwanda</Option>
        <Option value="XS" countryphonecode="+599">Saba</Option>
        <Option value="KN" countryphonecode="+1">Saint Kitts and Nevis</Option>
        <Option value="LC" countryphonecode="+1">Saint Lucia</Option>
        <Option value="PM" countryphonecode="+508">Saint Pierre and Miquelon</Option>
        <Option value="VC" countryphonecode="+1">Saint Vincent and the Grenadines</Option>
        <Option value="WS" countryphonecode="+685">Samoa</Option>
        <Option value="SM" countryphonecode="+378">San Marino</Option>
        <Option value="ST" countryphonecode="+239">São Tomé and Príncipe</Option>
        <Option value="SA" countryphonecode="+966">Saudi Arabia</Option>
        <Option value="SN" countryphonecode="+221">Senegal</Option>
        <Option value="RS" countryphonecode="+381">Serbia</Option>
        <Option value="SC" countryphonecode="+248">Seychelles</Option>
        <Option value="SL" countryphonecode="+232">Sierra Leone</Option>
        <Option value="SG" countryphonecode="+65">Singapore</Option>
        <Option value="XE" countryphonecode="+599">Sint Eustatius</Option>
        <Option value="SX" countryphonecode="+599">Sint Maarten</Option>
        <Option value="SK" countryphonecode="+421">Slovakia</Option>
        <Option value="SI" countryphonecode="+386">Slovenia</Option>
        <Option value="SB" countryphonecode="+677">Solomon Islands</Option>
        <Option value="SO" countryphonecode="+252">Somalia</Option>
        <Option value="ZA" countryphonecode="+27">South Africa</Option>
        <Option value="ES" countryphonecode="+34">Spain</Option>
        <Option value="LK" countryphonecode="+94">Sri Lanka</Option>
        <Option value="SH" countryphonecode="+290">St Helena, Ascension, Tristan da Cunha</Option>
        <Option value="SD" countryphonecode="+249">Sudan</Option>
        <Option value="SR" countryphonecode="+597">Suriname</Option>
        <Option value="SJ" countryphonecode="+47">Svalbard</Option>
        <Option value="SE" countryphonecode="+46">Sweden</Option>
        <Option value="CH" countryphonecode="+41">Switzerland</Option>
        <Option value="SY" countryphonecode="+963">Syrian</Option>
        <Option value="TW" countryphonecode="+886">Taiwan</Option>
        <Option value="TJ" countryphonecode="+992">Tajikistan</Option>
        <Option value="TZ" countryphonecode="+255">Tanzania</Option>
        <Option value="TH" countryphonecode="+66">Thailand</Option>
        <Option value="TL" countryphonecode="+670">Timor-Leste</Option>
        <Option value="TG" countryphonecode="+228">Togo</Option>
        <Option value="TK" countryphonecode="+690">Tokelau</Option>
        <Option value="TO" countryphonecode="+676">Tonga</Option>
        <Option value="TT" countryphonecode="+1">Trinidad and Tobago</Option>
        <Option value="TN" countryphonecode="+216">Tunisia</Option>
        <Option value="TR" countryphonecode="+90">Turkey</Option>
        <Option value="TM" countryphonecode="+993">Turkmenistan</Option>
        <Option value="TC" countryphonecode="+1">Turks and Caicos Islands</Option>
        <Option value="TV" countryphonecode="+1">Tuvalu</Option>
        <Option value="VI" countryphonecode="+1">U.S.Virgin Islands</Option>
        <Option value="UG" countryphonecode="+256">Uganda</Option>
        <Option value="UA" countryphonecode="+380">Ukraine</Option>
        <Option value="AE" countryphonecode="+971">United Arab Emirates</Option>
        <Option value="GB" countryphonecode="+44">United Kingdom</Option>
        <Option value="US" countryphonecode="+1">United States</Option>
        <Option value="UM" countryphonecode="+1">United States Minor Outlying Islands</Option>
        <Option value="UY" countryphonecode="+598">Uruguay</Option>
        <Option value="UZ" countryphonecode="+998">Uzbekistan</Option>
        <Option value="VU" countryphonecode="+678">Vanuatu</Option>
        <Option value="VA" countryphonecode="+39">Vatican City</Option>
        <Option value="VE" countryphonecode="+58">Venezuela</Option>
        <Option value="VN" countryphonecode="+84">Vietnam</Option>
        <Option value="WF" countryphonecode="+681">Wallis and Futuna</Option>
        <Option value="YE" countryphonecode="+967">Yemen </Option>
        <Option value="ZM" countryphonecode="+260">Zambia </Option>
        <Option value="ZW" countryphonecode="+263">Zimbabwe</Option>
      </Select>
    </Form.Item>
  );