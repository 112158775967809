// export const PRIMARY_COLOR = "#fa8c16";
// export const SUCCESS_COLOR = "#2ecc71";
// export const ERROR_COLOR = "#e74c3c";
export const SECONDARY_COLOR = "lightgray";
export const LIGHT_BLUE_COLOR = "#f0f8ff";
export const DARK_GRAY_COLOR = "#95a5a6";
export const BLACK_COLOR = "#000000";
export const LIGHT_GRAY_COLOR = "#f7f7f7";
export const WHITE_COLOR = "#ffffff";
export const FLAT_ORANGE_COLOR = "#e67e22";
export const FLAT_YELLOW_COLOR = "#f1c40f";
export const FLAT_BLUE_COLOR = "#34495e";
export const FLAT_PURPLE_COLOR = "#9b59b6";
export const FLAT_LIGHT_ORANGE_COLOR = "#f39c12";
export const DARK_GREEN_COLOR = "#27ae60";
export const FLAT_TURQUOISE_COLOR = "#1abc9c";
export const FLAT_SILVER_COLOR = "#bdc3c7";
export const APP_BAR_COLOR = "#0c254e";
export const GRAY_COLOR = "#e8e4e4";

export const getColorWithOpacity = (color: string, opacity: number) => {
  if (opacity < 1 || opacity > 100) {
    return color;
  }

  const opacityInHex = Math.floor((opacity * 255) / 100).toString(16);

  return `${color}${
    opacityInHex.length === 1 ? `0${opacityInHex}` : opacityInHex
  }`;
};

export const BOX_SHADOW = "2px 2px 8px 0 rgb(0 0 0 / 4%)";
