import { ProspectStageEnum, ProspectStatusEnum } from "types/Prospect";
import {
  BLACK_COLOR
} from "utils/colors";

export const PROSPECT_STATUSES = [
  {
    label: "Created",
    value: ProspectStatusEnum.CREATED,
  },
  {
    label: "Paused",
    value: ProspectStatusEnum.PAUSED,
  },
  {
    label: "Ready",
    value: ProspectStatusEnum.READY,
  },
  {
    label: "Queued",
    value: ProspectStatusEnum.QUEUED,
  },
  {
    label: "Clicked",
    value: ProspectStatusEnum.CLICKED,
  },
  {
    label: "Sent",
    value: ProspectStatusEnum.SENT,
  },
  {
    label: "Opened",
    value: ProspectStatusEnum.OPENED,
  },
  {
    label: "Not Replied",
    value: ProspectStatusEnum.NOT_REPLIED,
  },
  {
    label: "Replied",
    value: ProspectStatusEnum.REPLIED,
  },
  {
    label: "Unsubscribe",
    value: ProspectStatusEnum.UNSUBSCRIBE,
  },
  {
    label: "Bounced",
    value: ProspectStatusEnum.BOUNCED,
  },
  {
    label: "Dropped",
    value: ProspectStatusEnum.DROPPED,
  },
];

export const PROSPECT_STAGES = [
  {
    label: "Inactive",
    value: ProspectStageEnum.INACTIVE,
    statuses: [ProspectStatusEnum.CREATED, ProspectStatusEnum.PAUSED],
  },
  {
    label: "Active",
    value: ProspectStageEnum.ACTIVE,
    statuses: [
      ProspectStatusEnum.READY,
      ProspectStatusEnum.QUEUED,
      ProspectStatusEnum.CLICKED,
      ProspectStatusEnum.SENT,
      ProspectStatusEnum.OPENED,
    ],
  },
  {
    label: "Finished",
    value: ProspectStageEnum.FINISHED,
    statuses: [
      ProspectStatusEnum.NOT_REPLIED,
      ProspectStatusEnum.REPLIED,
      ProspectStatusEnum.UNSUBSCRIBE,
      ProspectStatusEnum.BOUNCED,
      ProspectStatusEnum.DROPPED,
    ],
  },
];

export const PROSPECT_TOUCH_TYPE = [
  {
    // id: v4(),
    waitDays: 0,
    touchNumber: 1,
    // campId: v4(),
  },
  {
    // id: v4(),
    // waitDays: 1,
    touchNumber: 2,
    // campId: v4(),
  },
  {
    // id: v4(),
    // waitDays: 2,
    touchNumber: 3,
    // campId: v4(),
  },
  {
    // id: v4(),
    // waitDays: 5,
    touchNumber: 4,
    // campId: v4(),
  },
  {
    // id: v4(),
    // waitDays: 2,
    touchNumber: 5,
    // campId: v4(),
  },
];



export const DEFAULT_PAGE_SIZE = 10;

const STATUS_COLOR_MAP = {
  [ProspectStatusEnum.CREATED]: "geekblue",
  [ProspectStatusEnum.PAUSED]: "gold",
  [ProspectStatusEnum.READY]: "lime",
  [ProspectStatusEnum.QUEUED]: "blue",
  [ProspectStatusEnum.RESUMED]: "blue",
  [ProspectStatusEnum.CLICKED]: "orange",
  [ProspectStatusEnum.SENT]: "green",
  [ProspectStatusEnum.OPENED]: "lime",
  [ProspectStatusEnum.NOT_REPLIED]: "gold",
  [ProspectStatusEnum.REPLIED]: "volcano",
  [ProspectStatusEnum.UNSUBSCRIBE]: "purple",
  [ProspectStatusEnum.BOUNCED]: "red",
  [ProspectStatusEnum.DROPPED]: "lime",
};

const STAGE_COLOR_MAP = {
  [ProspectStageEnum.ACTIVE]: "green",
  [ProspectStageEnum.INACTIVE]: "gold",
  [ProspectStageEnum.FINISHED]: "geekblue",
};

export const getStatusColor = (status: ProspectStatusEnum) =>
  STATUS_COLOR_MAP[status] || BLACK_COLOR;

export const getStageColor = (stage: ProspectStageEnum) =>
  STAGE_COLOR_MAP[stage] || BLACK_COLOR;
