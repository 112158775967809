import { CheckCircleOutlined, ClockCircleOutlined, EllipsisOutlined } from "@ant-design/icons";
import { ApiResponseType } from "hooks/useApi";
import React from "react";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";

const DOMAIN_STATUS = {
  "Not Started": { icon: <EllipsisOutlined />, label: "Not started" },
  "In Progress": { icon: <ClockCircleOutlined />, label: "Pending" },
  Complete: { icon: <CheckCircleOutlined />, label: "Success" }
};

export type DomainStatusProps = {
  status: keyof typeof DOMAIN_STATUS;
};
export const DomainStatus: React.FC<DomainStatusProps> = ({ status }) => {
  const { icon, label } = DOMAIN_STATUS?.[status] ?? {};
  return (
    <Flex gap={5} align="center">
      <Text variant="body2" color={status === "Complete" ? "success" : "secondary"}>
        {icon}
      </Text>
      <Text variant="body2" color={status === "Complete" ? "success" : "secondary"}>
        {label}
      </Text>
    </Flex>
  );
};

export const DOMAIN_TABLE_META = [
  {
    title: "Domain",
    key: "domain",
    dataIndex: "domain"
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status: DomainStatusProps["status"]) => <DomainStatus status={status} />
  }
];

export const apiResHandler = (res: ApiResponseType) => {
  if (res.success) {
    return { ...res.data };
  } else {
    throw res.error;
  }
};

export const KEY_LS_DOMAIN_VIEW = "_ea.D";
