import Modal from "antd/lib/modal/Modal";
import NewTouch, { TouchPayloadType } from "components/Touch/New";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { notification, Tabs, Skeleton, Button } from "antd";
import { Div } from "styles/layout";
import { EditOutlined, EyeOutlined, SaveTwoTone } from "@ant-design/icons";
import { TouchTemplateType, TouchType, TouchTypeEnum } from "types/Touch";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import AppContext from "contexts/appContext";
import maxBy from "lodash/maxBy";
import { DeleteOutlined } from "@ant-design/icons";
import { Text } from "styles/typography";
import { useSelector } from "react-redux";
import { spinTypesSelector, getCampaignVariablesSelector, getCampaignVariablesSelectorV2, getCampaignQuickLineVariablesSelector } from "state/selectors";
import TouchPreview from "./TouchPreview";

enum TouchTabKeyEnum {
  EDIT = "EDIT",
  PREVIEW = "PREVIEW"
}

const UNEXPECTED_ERROR = "Unexpected error occurred.";
const { TabPane } = Tabs;

interface TouchModalProps {
  onClose: () => void;
  switchTouch: (id: string, touches: TouchType[]) => void;
  touches: TouchType[];
  campaignId: string;
  touchTemplate: TouchTemplateType | null;
  onSaveCallback: () => void;
  genrateNewT: (id: string, mode: boolean) => void;
  sequenceId: string;
  sequenceType: string;
  canEdit?: boolean;
  editMode: boolean;
  addTouchByIcon?: boolean;
  templateIdForEdit: string;
  isToggle?: boolean;
  onSetTouchForEdit: any;
  isEditMode: any;
  settemplateIdForEdit: any;
}

const TouchModal = ({
  onClose,
  switchTouch,
  touches,
  campaignId,
  onSaveCallback,
  genrateNewT,
  touchTemplate,
  sequenceId,
  sequenceType,
  canEdit = true,
  editMode,
  addTouchByIcon,
  isEditMode,
  templateIdForEdit,
  isToggle,
  onSetTouchForEdit,
  settemplateIdForEdit
}: TouchModalProps) => {
  const [templateId, setTemplateId] = useState(touchTemplate?.template?.id || null);

  console.log("touchTemplatetouchTemplate", touchTemplate);
  const formInitialValue = useMemo(
    () =>
      touchTemplate
        ? {
            type: touchTemplate.template.type || TouchTypeEnum.REPLY_TO_PREVIOUS_SEQUENCE || TouchTypeEnum.NEW_THREAD,
            waitDays: touchTemplate.waitDays,
            subject: touchTemplate.template.subject,
            body: touchTemplate.template.body,
            trackLinkClicked: touchTemplate.template.trackLinkClicked,
            trackEmailOpened: touchTemplate.template.trackEmailOpened,
            openTrackingRate: touchTemplate.template.openTrackingRate ?? 80,
            // useOriginalSubject: (TouchTypeEnum.NEW_THREAD ? false : touchTemplate.template.useOriginalSubject),
            useOriginalSubject: touchTemplate.template.useOriginalSubject,
            plainText: touchTemplate.template.plainText,
            linkTrackingValue: touchTemplate.template.linkTrackingValue ?? 80,
            // bodyText: touchTemplate.template.bodyText,
            isLinkTrackingEnable: touchTemplate.template.isLinkTrackingEnable
          }
        : {
            type: sequenceType === "INITIAL" ? TouchTypeEnum.NEW_THREAD : TouchTypeEnum.REPLY_TO_PREVIOUS_SEQUENCE,
            waitDays: 3,
            subject: "",
            body: "",
            trackLinkClicked: false,
            trackEmailOpened: false,
            openTrackingRate: 80,
            useOriginalSubject: false,
            plainText: false,
            linkTrackingValue: 80,
            // bodyText: "",
            isLinkTrackingEnable: false
          },
    [touchTemplate]
  );

  const [formValues, setFormValues] = useState<TouchPayloadType>(formInitialValue);
  useEffect(() => {
    setFormValues(formInitialValue);
  }, [formInitialValue]);

  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(AppContext);
  const variablesSelector = useMemo(() => getCampaignVariablesSelector(campaignId), [campaignId]);
  const quicklinksSelector = useMemo(() => getCampaignQuickLineVariablesSelector(campaignId), [campaignId]);

  // todo: will be used in future
  // const variablesSelectorV2 = useMemo(
  //   () => getCampaignVariablesSelectorV2(campaignId),
  //   [campaignId]
  // );
  // const variablesV2 = useSelector(variablesSelectorV2);

  const spinTypes = useSelector(spinTypesSelector);
  const variables = useSelector(variablesSelector);
  const quicklinks = useSelector(quicklinksSelector);
  const [preview, setPreview] = useState({
    subject: "",
    body: "",
    loading: true,
    activeTab: null
  });
  const [touchIdforDelete, setTouchIdforDelete] = useState("");
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedTouchId, setSelectedTouchId] = useState(touchTemplate?.template.touchId || "");
  const [addTouch, setAddTouch] = useState(addTouchByIcon);
  // const isFirstTouch = useCallback(() => {
  //   if (touchTemplate) {
  //     return touchTemplate.touchNumber === 1;
  //   }
  //   return !touches.some((tch) => tch.seqId === sequenceId);
  // }, [touches, touchTemplate]);
  useEffect(() => {
    return () => {
      setAddTouch(false);
    };
  }, []);

  const isFirstTouch = useCallback(() => {
    const touchPerSeq = touches.filter((tch) => tch.seqId === sequenceId);
    if (touchPerSeq.length) {
      const minTouchNumber = Math.min.apply(
        Math,
        touchPerSeq.map(function (o) {
          return o.touchNumber;
        })
      );
      try {
        const currentTouchValue = touchPerSeq.filter((tch) => tch.id === selectedTouchId)[0]["touchNumber"];
        if (currentTouchValue === minTouchNumber) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    } else {
      return true;
    }
  }, [touches, touchTemplate]);

  const isSecondTouch = useCallback(() => {
    const touchPerSeq = touches.filter((tch) => tch.seqId === sequenceId);
    if (touchPerSeq.length > 1) {
      const minTouchNumber = Math.min.apply(
        Math,
        touchPerSeq.map(function (o) {
          return o.touchNumber;
        })
      );
      let index = touchPerSeq
        .map(function (o) {
          return o.touchNumber;
        })
        .indexOf(minTouchNumber);

      touchPerSeq.splice(index, 1);
      if (touchPerSeq && touchPerSeq.length) {
        let valueMin = Math.min.apply(
          null,
          touchPerSeq.map(function (o) {
            return o.touchNumber;
          })
        ); //
        try {
          const currentTouchValue = touchPerSeq.filter((tch) => tch.id === selectedTouchId)[0]["touchNumber"];
          if (currentTouchValue === valueMin) {
            return true;
          } else {
            return false;
          }
        } catch (err) {
          return false;
        }
      } else {
        return false;
      }
    } else if (touchPerSeq.length === 1) {
      if (addTouch) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [touches, touchTemplate]);

  const touchNumber = useMemo(() => {
    const touchPerSeq = touches.filter((tch) => tch.seqId === sequenceId);
    return touchPerSeq.length + 1;
    // if (touchTemplate) {
    //   return touchTemplate.touchNumber;
    // }

    // const { touchNumber } = maxBy(touches, "touchNumber") || { touchNumber: 0 };
    // return touchNumber + 1;
  }, [touches, touchTemplate]);

  const getOnChange = (key: string, dataType: "value" | "checkbox") => (value: any) => {
    if (!value) {
      return;
    }
    let newValue = value;
    if (key === "useOriginalSubject" && formValues.type === TouchTypeEnum.NEW_THREAD) {
      newValue = false;
    }
    switch (dataType) {
      case "checkbox":
        newValue = value.target.checked;
        break;
      case "value":
      default:
        newValue = value;
    }
    if (key === "useOriginalSubject" && formValues.type !== TouchTypeEnum.NEW_THREAD) {
      if (value.target.checked) setFormValues((prev) => ({ ...prev, [key]: newValue, subject: "" }));
      else setFormValues((prev) => ({ ...prev, [key]: newValue }));
    } else {
      setFormValues((prev) => ({ ...prev, [key]: newValue }));
    }
  };

  const handlePlainTextChange = (key: string, value: any) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const insertAtCursor = (text: string, areaId: string) => {
    const field: any = document.getElementById(areaId) as HTMLInputElement;

    if (field.selection) {
      let range: any = document.createRange();

      if (!range || range.parentElement() !== field) {
        field.focus();
        range = field.createTextRange();
        range.collapse(false);
      }
      range.text = text;
      range.collapse(false);
      range.select();
    } else {
      field.focus();
      var val = field.value;
      var selStart = field.selectionStart;
      var caretPos = selStart + text.length;
      field.value = val.slice(0, selStart) + text + val.slice(field.selectionEnd);
      field.setSelectionRange(caretPos, caretPos);
      setFormValues((prev) => ({ ...prev, body: field.value }));
    }
  };
  console.log("templateIdForEdittemplateIdForEdit", templateIdForEdit);
  const onSave = useCallback(
    async (autoSave = false, templateId?: string) => {
      const {
        type,
        waitDays,
        subject,
        body,
        trackEmailOpened,
        openTrackingRate,
        trackLinkClicked,
        useOriginalSubject,
        // bodyText,
        plainText,
        linkTrackingValue,
        isLinkTrackingEnable
      } = formValues;

      if (!isFirstTouch() && waitDays < 1) {
        notification.error({
          message: "Invalid wait days",
          description: "The wait days should be greater than equal to 1."
        });
        return;
      }

      let subjectPlainText = subject;

      if (subject.indexOf("<p fr-original-style") > -1) {
        const divElement = document.createElement("div");
        divElement.innerHTML = subject;
        subjectPlainText = divElement.querySelector("p")?.innerText || "";
      }

      const touch = {
        type,
        waitDays: isFirstTouch() ? 0 : waitDays,
        subject: subjectPlainText,
        body,
        trackEmailOpened,
        trackLinkClicked,
        useOriginalSubject,
        openTrackingRate,
        // bodyText,
        plainText,
        linkTrackingValue,
        isLinkTrackingEnable
      };

      let response;
      console.log("templateIdtemplateId", templateId);
      // const action = touchTemplate ? "edited" : "created";
      const action = selectedTouchId !== "" ? "edited" : "created";
      // if (templateId) {
      //   const { url, method } = API_ENDPOINTS.CAMPAIGN_TEMPLATE.getUpdateUrl(
      //     templateId
      //   );
      console.log("??????????????????????", templateIdForEdit);
      let tempID = templateId ? templateId : templateIdForEdit;
      if (templateIdForEdit && action === "edited") {
        const { url, method } = API_ENDPOINTS.CAMPAIGN_TEMPLATE.getUpdateUrl(tempID);
        const payload = {
          waitDays: touch.waitDays,
          template: {
            type: touch.type,
            body: touch.body,
            subject: touch.subject,
            trackLinkClicked: touch.trackLinkClicked,
            trackEmailOpened: touch.trackEmailOpened,
            useOriginalSubject: touch.type === TouchTypeEnum.NEW_THREAD ? false : touch.useOriginalSubject,
            openTrackingRate: touch.openTrackingRate,
            // bodyText: touch.bodyText ? touch.bodyText : "",
            plainText: touch.plainText,
            linkTrackingValue: touch.linkTrackingValue,
            isLinkTrackingEnable: touch.isLinkTrackingEnable
          },
          campId: campaignId
        };

        if (!autoSave) {
          setLoadingMessage(autoSave ? "" : "Saving Campaign touch ...");
        }

        response = await callApi({
          url,
          method,
          data: payload
        });

        if (autoSave) {
          return { success: response.success, id: tempID, ...(!response.success && { error: response.error }) };
        }
      } else {
        const { url, method } = API_ENDPOINTS.CAMPAIGN_TEMPLATE.getCreateUrl(campaignId, sequenceId);

        const payload = {
          touchNumber,
          waitDays: touch.waitDays,
          template: {
            templateNumber: 1,
            type: touch.type,
            body: touch.body,
            subject: touch.subject,
            trackLinkClicked: touch.trackLinkClicked,
            openTrackingRate: touch.openTrackingRate,
            trackEmailOpened: touch.trackEmailOpened,
            useOriginalSubject: touch.type === TouchTypeEnum.NEW_THREAD ? false : touch.useOriginalSubject,
            // bodyText: touch.bodyText ? touch.bodyText : "",
            plainText: touch.plainText,
            linkTrackingValue: touch.linkTrackingValue,
            isLinkTrackingEnable: touch.isLinkTrackingEnable
          },
          campId: campaignId
        };

        if (!autoSave) {
          setLoadingMessage(autoSave ? "" : "Creating Campaign touch ...");
        }

        response = await callApi({
          url,
          method,
          data: payload
        });

        if (!response.success) {
          setLoadingMessage("");
          // return {response};
          return { success: response.success, id: tempID, ...(!response.success && { error: response.error }) };
        }

        setTemplateId(response.data.id);

        if (response.data.touchId) {
          const { url, method } = API_ENDPOINTS.TOUCH.getListTemplatesUrl(response.data.touchId);

          // setLoadingMessage("Fetching Touch template ...");
          const res = await callApi({
            url,
            method
          });
          // setLoadingMessage("");
          if (res.success) {
            console.log("res.datares.datares.data", res.data);
            const {
              templates: [template]
            } = res.data;
            onSetTouchForEdit({ ...touch, template });
            settemplateIdForEdit(template.id);
            // isEditMode(true)
          }
        }
        console.log("response.data.id", response.data.id);
        setSelectedTouchId(response.data.touchId);
        if (autoSave) {
          return { success: true, id: tempID };
        }
      }

      setLoadingMessage("");

      if (response.success) {
        notification.success({
          message: "Success",
          description: `Touch ${action} successfully.`
        });
        onSaveCallback();
      }
    },
    [callApi, campaignId, formValues, isFirstTouch, onSaveCallback, setLoadingMessage, touchNumber, touchTemplate, templateId, sequenceId, templateIdForEdit]
  );
  console.log("templateIdtemplateIdtemplateId", templateId);

  const getTemplateData = async () => {
    console.log("selectedTouchId", selectedTouchId);
    const { url, method } = API_ENDPOINTS.TOUCH.getListTemplatesUrl(selectedTouchId);
    const res = await callApi({
      url,
      method
    });
    if (res.success) {
      const {
        templates: [template]
      } = res.data;
      return template.id;
    }
  };

  const onTabChange = useCallback(
    async (activeTab: any, tempId: any) => {
      console.log("activeTabactiveTab", activeTab);
      if (activeTab === TouchTabKeyEnum.PREVIEW) {
        setPreview((prev) => ({ ...prev, loading: true }));
        // const res: any = await onSave(true, templateId);
        // console.log("resres", res)
        // if (res.success && res.id) {
        console.log("selectedTouchId", templateId);
        console.log("tempIdtempIdtempId", templateIdForEdit);
        // const getTemp = await getTemplateData()
        // console.log("getTempgetTemp", getTemp)

        // if(getTemp){
        const { url, method } = API_ENDPOINTS.TOUCH.getTemplatePreview(tempId || "");
        const response = await callApi({
          url,
          method
        });

        if (response.success) {
          setPreview((prev) => ({
            ...prev,
            loading: false,
            subject: response.data.subject,
            body: response.data.bodyHtml,
            activeTab
          }));
        } else {
          setPreview((prev) => ({ ...prev, body: response.error?.message ?? UNEXPECTED_ERROR, activeTab }));
        }
        // }
        setPreview((prev) => ({ ...prev, loading: false, activeTab }));
      }
    },
    [callApi, onSave, templateIdForEdit]
  );

  const toggleShowDeleteConfirmModal = useCallback(() => setShowDeleteConfirmModal((value) => !value), []);

  const confirmDelete = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.TOUCH.getDeleteUrl(touchIdforDelete);

    toggleShowDeleteConfirmModal();
    setLoadingMessage("Deleting a touch ...");
    const response = await callApi({
      url,
      method
    });

    let formInitialVal = {
      type: TouchTypeEnum.NEW_THREAD || TouchTypeEnum.REPLY_TO_PREVIOUS_SEQUENCE,
      waitDays: 0,
      subject: "",
      body: "",
      trackLinkClicked: false,
      trackEmailOpened: false,
      openTrackingRate: 80,
      useOriginalSubject: false,
      plainText: false,
      linkTrackingValue: 80,
      isLinkTrackingEnable: false
      // bodyText: "",
    };
    // touchTemplate=null

    setFormValues(formInitialVal);
    setSelectedTouchId("");
    setLoadingMessage("");
    isEditMode(false);
    onSetTouchForEdit(null);
    if (response.success) {
      notification.success({
        message: "Touch deleted successfully!"
      });
      onSaveCallback();

      // fetchTouches();
    }
  }, [touchIdforDelete, toggleShowDeleteConfirmModal, setLoadingMessage, setSelectedTouchId, callApi, setFormValues, onSaveCallback]);

  const onCancelDelete = useCallback(() => {
    setTouchIdforDelete("");
    toggleShowDeleteConfirmModal();
  }, [toggleShowDeleteConfirmModal]);

  const onDeleteTouch = useCallback(() => {
    if (touchTemplate) {
      setTouchIdforDelete(touchTemplate?.id);
    }
    toggleShowDeleteConfirmModal();
  }, [toggleShowDeleteConfirmModal, touchTemplate]);

  const getDayNumber = useCallback(
    (selectedTouchOrder, _id) =>
      touches.reduce((acc, { touchNumber, waitDays, seqId }) => {
        if (_id === seqId) {
          if (touchNumber <= selectedTouchOrder) {
            return ++acc;
          }
        }
        return acc;
      }, 0),
    [touches]
  );

  //sequence.id, false
  const getTouchDetailsAfterSwitch = useCallback(
    async (touchId: string) => {
      setSelectedTouchId(touchId);

      // setTemplateId()
      const datas: any = await switchTouch(touchId, touches);
      setAddTouch(false);
      console.log("datasdatas", datas);
      if (datas) {
        setTemplateId(datas?.id);
        onTabChange(preview?.activeTab, datas?.id);
      }
    },
    [setSelectedTouchId, setTemplateId, templateId, touches, templateIdForEdit, touchTemplate, preview?.activeTab]
  );

  const addNewTouch = useCallback(
    async (sequenceId: string, mode: boolean) => {
      setAddTouch(true);
      let defaultType = TouchTypeEnum.REPLY_TO_PREVIOUS_SEQUENCE;
      if (sequenceType === "INITIAL") {
        defaultType = TouchTypeEnum.NEW_THREAD;
      }
      setSelectedTouchId("");
      setTemplateId(null);
      let formInitialVal = {
        type: defaultType,
        waitDays: isFirstTouch() ? 0 : 3,
        subject: "",
        body: "",
        trackLinkClicked: false,
        openTrackingRate: 80,
        trackEmailOpened: false,
        useOriginalSubject: false,
        plainText: false,
        linkTrackingValue: 80,
        isLinkTrackingEnable: false
        // bodyText: "",
      };
      setPreview((prev) => ({
        ...prev,
        subject: "",
        body: ""
      }));
      setFormValues(formInitialVal);
      onSaveCallback();
      await genrateNewT(sequenceId, false);
    },
    [setSelectedTouchId, isFirstTouch, touchTemplate, sequenceType]
  );

  // const onUpdateTemplate = useCallback(() => {
  //   if (touchTemplate) {
  //     setTemplateId(touchTemplate?.id);
  //   }

  // },
  //   [touchTemplate]
  // );

  console.log("touchestouches", touches);
  const AppendTouches = ({ _id }: { _id: string }) => {
    return (
      <ul>
        <Div p={10}>
          <Button type="primary" style={{ width: "100%" }} disabled={!canEdit} onClick={() => addNewTouch(sequenceId, true)}>
            Add Touch
          </Button>
        </Div>
        {touches.map(({ touchNumber, waitDays, id, seqId }) =>
          seqId === _id ? (
            <>
              <li onClick={() => getTouchDetailsAfterSwitch(id)} className={id === selectedTouchId ? "active" : ""}>
                <i className="fa fa-envelope-o"></i>
                <label>Touch {getDayNumber(touchNumber, _id)}</label>
              </li>
            </>
          ) : null
        )}
        {/* <li onClick={() => addNewTouch(sequenceId, true)} className={(selectedTouchId === '' ? 'active' : '')}>
        <i className="fa fa-envelope-o" ></i>
        <label>Add Touch</label>
      </li> */}
      </ul>
    );
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <Modal
      visible
      //width={1800}
      onCancel={onClose}
      footer={null}
      centered
      zIndex={1030}
      className="full__width-modal outwin-full-width-modal"
      getContainer={false}
      title="Manage Touches"
    >
      <Div className="d-flex w-100">
        <Div className="email-touch1 email-touch-h d-flex">
          <AppendTouches _id={sequenceId} key={sequenceId} />
        </Div>

        <Div className="flex-1 touch-tabs">
          <Tabs defaultActiveKey={TouchTabKeyEnum.EDIT} style={{ overflow: "visible" }} onChange={(tab) => onTabChange(tab, templateId)}>
            <TabPane
              tab={
                <span>
                  <EditOutlined />
                  Edit Template
                </span>
              }
              key={TouchTabKeyEnum.EDIT}
            >
              {isToggle ? (
                <Skeleton paragraph={{ rows: 12 }} />
              ) : (
                <NewTouch
                  isFirstTouch={isFirstTouch}
                  isSecondTouch={isSecondTouch}
                  getOnChange={getOnChange}
                  handlePlainTextChange={handlePlainTextChange}
                  insertAtCursor={insertAtCursor}
                  spinTypes={spinTypes}
                  variables={variables}
                  formValues={formValues}
                  onDelete={onDeleteTouch}
                  onSave={onSave}
                  canEdit={canEdit}
                  editMode={editMode}
                  touches={touches}
                  activeTouchId={selectedTouchId}
                  sequenceType={sequenceType}
                  quicklinks={quicklinks}
                  onClose={onClose}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <span>
                  <EyeOutlined />
                  Preview
                </span>
              }
              key={TouchTabKeyEnum.PREVIEW}
            >
              <TouchPreview subject={preview.subject} loading={preview.loading} body={preview.body} onTabChange={(tab: any) => onTabChange(tab, templateId)} />
            </TabPane>
          </Tabs>
        </Div>
      </Div>

      {showDeleteConfirmModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary",
            danger: true,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={onCancelDelete}
          onOk={confirmDelete}
          cancelButtonProps={{ type: "text" }}
          centered
          zIndex={1030}
        >
          <Text variant="body2">
            Are you sure you want to{" "}
            <Text variant="body2" bold>
              delete
            </Text>{" "}
            the touch?
          </Text>
        </Modal>
      )}
    </Modal>
  );
};

export default TouchModal;
