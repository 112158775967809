import { useEffect, useCallback, useState } from "react";
import { Input, Tabs, Checkbox, Row, Col, Card, Skeleton, Button, Form, Alert } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { debounce } from "lodash";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Rule } from "antd/lib/form";
import classes from "./styles.module.css";

export type FilterPayloadType =
  | { emailAddress: string; id: string }
  | { domain: string; id: string }
  | { subStatus: string[]; id: string };

type StatItemType = {
  label: string;
  prospects: number;
  key: string;
};

type InputFilterProps = {
  placeholder: string;
  prefix?: React.ReactNode;
  allowClear?: boolean;
  onChange: (val: string) => void;
  isCurrent: boolean;
  rules?: Rule[];
};

type ContentProps = {
  onChange: (ev?: any) => any;
  curFilterActive: string;
};

type FilterTabProps = {
  filterList: {
    id: string;
    icon?: React.ReactNode;
    label: string;
    content: React.FC<ContentProps>;
  }[];
  curFilterActive: string;
  onChange?: (payload: FilterPayloadType) => void;
};

const { TabPane } = Tabs;
const { Meta } = Card;

const MOCK_SUMMARY_Data = [
  { key: "active", label: "active", prospects: 23 },
  { key: "ready", label: "ready", prospects: 213 },
  { key: "clicked", label: "clicked", prospects: 53 },
  { key: "sent", label: "sent", prospects: 13 }
];

type StatCardProps = {
  id: string;
  label: string;
  prospects: number;
};
const StatCard = ({ label, prospects, id }: StatCardProps) => {
  return (
    <>
      <Card size="small">
        <Checkbox className="filtercardCheckbox" value={id}>
          <Meta style={{ textAlign: "center", width: "100%" }} title={label} description={prospects} />
        </Checkbox>
      </Card>

    </>
  );
};

type CheckboxGroupsProps = {
  onChange?: (payload: any) => void;
  summaryList: StatItemType[];
  isCurrent: boolean;
};

const CheckBoxGroups = ({ onChange, summaryList, isCurrent }: CheckboxGroupsProps) => {
  const [val, setVal] = useState<CheckboxValueType[]>();

  useEffect(() => {
    setVal((prev) => (isCurrent ? prev : []));
  }, [isCurrent]);

  return (
    <>

      <Checkbox.Group
        value={val}
        style={{ width: "100%" }}
        onChange={(ev) => {
          setVal(ev);
          onChange && onChange(ev);
        }}
      >
        <Row gutter={[16, 20]} style={{ width: "100%" }}>
          {summaryList.map((lt) => (
            <Col key={lt.key} xs={{ span: 12 }} lg={{ span: 12 }}>
              <StatCard label={lt.label} prospects={lt.prospects} id={lt.key} />
            </Col>
          ))}
        </Row>
      </Checkbox.Group>

      <div>
  
        <p style={{ color: 'gray', fontSize: '12px', marginTop: '4px' }}>
          The above calculation could be delayed by up to 30 minutes. If you have added or updated any prospects in the last few minutes these counts may not reflect the correct value.
        </p >
        {/* <Alert
          message=""
          description={
            <div style={{ fontSize: "12px", color: "#a69674", lineHeight: "17px" }}>
                "The above calculation could be delayed by up to 30 minutes. If you have added or updated any prospects in the last few minutes these counts may not reflect the correct value."
            </div>
          }
          type="info"
          showIcon
          className="mt-2 text-muted"
        /> */}

      </div>


    </>
  );
};

// ---------------------------------------------------------------------------------------------------

const InputFilter = ({ placeholder, prefix, rules, isCurrent, onChange, allowClear = true }: InputFilterProps) => {
  const itemName = "formItemNameRnd";
  const [form] = Form.useForm();
  useEffect(() => {
    if (!isCurrent) {
      form.resetFields();
    }
  }, [isCurrent, form]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((vx) => onChange(vx), 420),
    [onChange]
  );

  const onFormValueChange = async (changedValues: any) => {
    try {
      await form.validateFields([itemName]);
      debouncedSearch(changedValues[itemName]);
    } catch (error: any) {
      const textValue = changedValues[itemName]?.trim();
      if (textValue === "" || !error?.errorFields?.length) {
        debouncedSearch(textValue);
      }
    }
  };

  return (
    <Form form={form} style={{ width: "100%" }} onValuesChange={onFormValueChange}>
      <Form.Item name={itemName} rules={rules}>
        <Input style={{ maxWidth: "300px" }} placeholder={placeholder} allowClear={allowClear} prefix={prefix} />
      </Form.Item>
    </Form>
  );
};

// ---------------------------------------------------------------------------------------------------

const getFilterTabsMetaData = (summaryList: StatItemType[]): FilterTabProps["filterList"] => [
  {
    id: "1",
    label: "Status",
    content: ({ onChange, curFilterActive }) => (
      <CheckBoxGroups
        isCurrent={curFilterActive === "1"}
        summaryList={summaryList}
        onChange={(ev) => onChange({ subStatus: ev, id: "1" })}
      />
    )
  },
  {
    id: "2",
    label: "Email",
    content: ({ onChange, curFilterActive }) => (
      <InputFilter
        isCurrent={curFilterActive === "2"}
        placeholder="Email"
        rules={[{ type: "email", message: "Invalid email" }]}
        onChange={(ev) => onChange({ emailAddress: ev, id: "2" })}
      />
    )
  },
  {
    id: "3",
    label: "Domain",
    content: ({ onChange, curFilterActive }) => (
      <InputFilter
        isCurrent={curFilterActive === "3"}
        placeholder="Domain"
        onChange={(ev) => onChange({ domain: ev, id: "3" })}
      />
    )
  }
];

const FilterTab = ({ filterList, curFilterActive, onChange }: FilterTabProps) => {
  const onFilter = useCallback(
    (payload) => {
      onChange && onChange(payload);
    },
    [onChange]
  );
  return (
    <Tabs>
      {filterList.map((fL) => (
        <TabPane
          key={fL.id}
          tab={
            <span>
              {fL.icon}
              {fL.label}
            </span>
          }
        >
          <div className="tabContent" style={{ display: "flex", padding: "10px 0", flexDirection: "column" }}>
            {<fL.content curFilterActive={curFilterActive} onChange={onFilter} />}
          </div>
        </TabPane>
      ))}
    </Tabs>
  );
};

// -----------------------------------------------------------------------------------------------------
export type ProspectFilterChangePayloadType = Omit<FilterPayloadType, "id">;

type ProspectFilterProps = {
  campaignId: string;
  onChange?: (payload: ProspectFilterChangePayloadType) => void;
};
enum STATUS {
  PENDING,
  DONE,
  ERROR
}

export const ProspectFilter = ({ campaignId, onChange }: ProspectFilterProps) => {
  const { callApi } = useApi();
  const [meta, setMeta] = useState<FilterTabProps["filterList"]>([]);
  const [status, setStatus] = useState<STATUS>(STATUS.PENDING);
  const [curFilterActive, setCurFilterActive] = useState("");

  const getSummary = useCallback(async () => {
    setStatus(STATUS.PENDING);
    const res = await callApi({ ...API_ENDPOINTS.CAMPAIGN_PROSPECT.prospectSummary(campaignId) });
    if (res.success) {
      setMeta(getFilterTabsMetaData(res.data?.prospects));
      // setMeta(getFilterTabsMetaData(MOCK_SUMMARY_Data));
      setStatus(STATUS.DONE);
    } else {
      setStatus(STATUS.ERROR);
    }
  }, [callApi, campaignId]);

  const onFilterChange = useCallback(
    (ev: FilterPayloadType) => {
      const { id, ...rest } = ev;
      setCurFilterActive(id);
      onChange && onChange(rest);
    },
    [onChange]
  );

  const onRefresh = async () => await getSummary();

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  switch (status) {
    case STATUS.PENDING:
      return (
        <Div mt={20}>
          <Skeleton active paragraph={{ rows: 6 }} />
        </Div>
      );
    case STATUS.DONE:
      return <FilterTab filterList={meta} onChange={onFilterChange} curFilterActive={curFilterActive} />;
    default:
      return (
        <Flex mt={20} gap={20} direction="column">
          <Text variant="sub2">Error Occurred </Text>
          <Div>
            <Button type="primary" icon={<SyncOutlined />} style={{ width: "auto" }} onClick={onRefresh}>
              Refresh
            </Button>
          </Div>
        </Flex>
      );
  }
};