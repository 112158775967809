import { useCallback, useEffect, useMemo, useRef, useState, useContext } from "react";
import { Div, Flex } from "styles/layout";
import { EventType } from "types/Event";
import LiveFeed from "components/Notification/LiveFeed";
import { isNumber, unionBy, cloneDeep } from "lodash";
import { BOX_SHADOW } from "utils/colors";
import { Select, Tabs, Skeleton, Card, Progress } from "antd";
import classes from "styles/common.module.css";
import TouchNumberChart, { TouchNumberMetric } from "./TouchNumberChart";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import { Column, Line } from "@ant-design/plots";
import { metricsType } from "types/Prospect";
import { CampaingStatusEnum } from "types/Campaign";
import "./stats.css"
import { Pie } from '@ant-design/plots';
import appContext from "contexts/appContext";
import { Text } from "styles/typography";
import { useAuth } from "auth/AuthProvider";


const { Option } = Select;
const { TabPane } = Tabs;
interface ActivityType {
  time: string;
  value: number;
  type: string;
}
interface PieDataType {
  data: metricsType[],
  contentName?: string,
  isContentname?: boolean
}
interface StatsProps {
  prospects: any;
  campaignId: string;
  status: string;
  life: {
    elapsed: number,
    remaining: number
  }
}
type LiveFeedType = {
  events: EventType[];
  loading: boolean;
  lastFetched: number;
  iteration: number;
  paused: boolean;
};

type MetricInfo = { emails: number; label: string };
export type ChartDataType = Array<
  {
    [key: string]: MetricInfo;
  } & { name: string }
>;
export type ChartMetaType = { label: string; strokeColor: string }[];

export type TouchNumberMetrics = {
  metrics: TouchNumberMetric[];
  loading: boolean;
};

export type SummaryMetrics = {
  summary: TouchNumberMetric[];
  loading: boolean;
};

export type TouchMetricsInsight = {
  loading: boolean;
  chartData: ChartDataType;
  chartMeta: ChartMetaType;
};

interface FixedMetrics {
  currRangeType: string;
}

const timedMetricsSortSequence = [{ type: "Email Sent", position: 1 },
{ type: "Opened", position: 2 },
{ type: "Replied", position: 3 },
{ type: "Unsubscribed", position: 4 }
  , { type: "Soft Bounced", position: 5 }
  , { type: "Hard Bounced", position: 6 },
{ type: "Out of Office", position: 7 },
{ type: "Auto Reply", position: 8 },
{ type: "Email Sending Failed", position: 9 }]



const NewStats = ({ campaignId, life, status, prospects }: StatsProps) => {
  const { callApi } = useApi();

  const rangeTypeArr = [
    { label: "Life", value: "life" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 Days", value: "last7days" },
    { label: "Last 15 Days", value: "last15days" },
    { label: "Last 30 Days", value: "last30days" }
  ];
  const [bySeqMetrice, setBySeqMetrice] = useState<metricsType[]>([
  ]);

  const [currTimedMetric, setCurrFixedMetric] = useState<FixedMetrics>({
    currRangeType: "life",
  });

  const [liveFeed, setLiveFeed] = useState<LiveFeedType>({
    events: [],
    loading: true,
    lastFetched: 0,
    iteration: 0,
    paused: false
  });

  const [state, setState] = useState<any>({
    currActiveLiveFeedTab: 'summary',
    summary: [],
    sequences: [],
    touches: [],
    isLoader: false
  });
  const { currActiveLiveFeedTab, summary, touches, isLoader, sequences } = state
  const deltaFeedIntervalHandle = useRef();
  const lastFetched = useRef(0);
  const MAX_DELTA_ITERATION = useRef(10);
  const { setLoadingMessage } = useContext(appContext);

  const { theme } = useAuth();

  useEffect(() => {
    fetchFixedMetrics();
    fetchNewNumberMetrics(currActiveLiveFeedTab, currTimedMetric?.currRangeType)
  }, []);

  const fetchNewNumberMetrics = useCallback(async (activeTab: string, rangeType: string) => {
    console.log("activeTabactiveTab", activeTab, rangeType)
    setState((prev: any) => ({ ...prev, isLoader: true }))
    const { url, method } = API_ENDPOINTS.EVENTS.CAMPAIGN_EVENTS.getActivitiesByTabs(campaignId, activeTab, rangeType);
    try {
      let keyName = activeTab ? activeTab : currActiveLiveFeedTab
      let response: any = await callApi({ url, method });
      if (response.success) {
        const { data } = response;
        switch (keyName) {
          case "summary":
            console.log("11111111111111", data?.items)
            let summarMetrics: any = data?.items || []
            setState((prev: any) => ({ ...prev, summary: summarMetrics, isLoader: false }))
            break;
          case "sequence":
            console.log("2222222222222", data?.sequences)
            let sequenceMetrcis: any = data?.sequences?.filter((vl:any)=>vl?.items)
            setState((prev: any) => ({ ...prev, sequences: sequenceMetrcis, isLoader: false }))
            break;
          case "touch":
            console.log("333333333333333333", data?.sequences, data?.touches)
            let touchesData = data?.sequences || data?.touches
            if(!["last7days", "last15days","last30days"].includes(rangeType)){
               touchesData = touchesData?.filter((obj:any) => obj?.touches && obj?.touches?.length && obj?.touches.every((touch:any) => touch?.items));
            }else{
              touchesData = touchesData?.filter((obj:any) => obj?.items);
            }
            setState((prev: any) => ({ ...prev, touches: touchesData, isLoader: false }))
            break;
          default:
            setState((prev: any) => ({ ...prev, isLoader: false }))
            break;
        }
        return;
      }
    } catch (err) { }
  }, [callApi, campaignId]);

  const fetchFixedMetrics = useCallback(async () => {
    try {
      let response: any = prospects && Object.keys(prospects).length ? Object.keys(prospects).map((key) => {
        return { type: `${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()} Prospects`, value: prospects[key] }
      }) : [
        {
          "type": "Active Prospects",
          "value": 0
        },
        {
          "type": "Untouched Prospects",
          "value": 0
        },

        {
          "type": "Ended Prospects",
          "value": 0
        }
      ]
      setBySeqMetrice(response);
    } catch (err) { }
  }, [callApi, campaignId]);

  const fetchAllLiveFeed = useCallback(async () => {
    setLiveFeed((prev) => ({ ...prev, loading: true }));
    try {
      const { url, method } = API_ENDPOINTS.EVENTS.CAMPAIGN_EVENTS.getFullEventsUrl(campaignId);
      const response = await callApi({ url, method });

      if (response.success) {
        const lf = new Date().getTime();
        lastFetched.current = lf;
        setLiveFeed({
          events: response.data.events,
          loading: false,
          lastFetched: lf,
          iteration: 1,
          paused: false
        });
        return;
      }
    } catch (err) { }

    setLiveFeed({
      events: [],
      loading: false,
      lastFetched: 0,
      iteration: 1,
      paused: false
    });
  }, [callApi, campaignId]);

  const fetchDeltaFeed = useCallback(async () => {
    setLiveFeed((prev) => ({ ...prev, loading: true }));
    try {
      const { url, method } = API_ENDPOINTS.EVENTS.CAMPAIGN_EVENTS.getDeltaEventsUrl(campaignId, lastFetched.current);
      const response = await callApi({ url, method });

      if (response.success) {
        const lf = new Date().getTime();
        lastFetched.current = lf;

        setLiveFeed((prev) => ({
          events: unionBy(response.data.events, prev.events, 'created'),
          loading: false,
          lastFetched: lf,
          iteration: prev?.iteration + 1,
          paused: false
        }));
        return;
      }
    } catch (err) { }

    setLiveFeed((prev) => ({ ...prev, loading: false }));
  }, [campaignId, callApi]);

  const onPlayPause = useCallback((state) => {
    setLiveFeed((s) => ({ ...s, paused: state }));
  }, []);

  useEffect(() => {
    fetchAllLiveFeed();
  }, [fetchAllLiveFeed]);

  useEffect(() => {
    if (deltaFeedIntervalHandle.current && liveFeed.paused) {
      clearInterval(deltaFeedIntervalHandle.current);
      deltaFeedIntervalHandle.current = undefined;
      return;
    }
    if (status.toUpperCase() === CampaingStatusEnum.ACTIVE) {
      fetchDeltaFeed();
      const intervalId = setInterval(fetchDeltaFeed, 15_000);
      deltaFeedIntervalHandle.current = intervalId as any;

      return () => {
        if (isNumber(intervalId)) clearInterval(intervalId as number);
      };
    }
  }, [fetchDeltaFeed, liveFeed.paused]);

  const handleRangeTypeChange = useCallback(
    async (val: any) => {
      if (val) {
        fetchNewNumberMetrics(currActiveLiveFeedTab, val)
        setCurrFixedMetric((prev) => ({ ...prev, currRangeType: val }));
      }
    },
    [currTimedMetric?.currRangeType, currActiveLiveFeedTab]
  );

  useEffect(() => {
    if (liveFeed.iteration > MAX_DELTA_ITERATION.current) {
      setLiveFeed((prev) => ({ ...prev, iteration: 0, paused: true }));
    }
  }, [liveFeed.iteration]);

  const renderSummaryChart = (metricsArray: any) => {
    console.log("renderSummaryChart", metricsArray)
    return ['life'].includes(currTimedMetric?.currRangeType) ? (metricsArray || [])?.map((summaryProps: any) => {
      console.log("loadd bar chart.........")
      return <TouchNumberChart key={summaryProps?.title} {...summaryProps} loading={isLoader} />
    }) : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) && Array.isArray(metricsArray) && metricsArray.length > 0 ? <BarChart activityData={metricsArray}></BarChart> : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) && Array.isArray(metricsArray) && metricsArray.length > 0 ? <DualAxesChart activityData={metricsArray} /> : null))
  }

  const renderTouchChart = (metricsArray: any, label: string) => {
    console.log("renderTouchChart", metricsArray)
    return ['life'].includes(currTimedMetric?.currRangeType) ? <Flex direction="column" style={{ height: "350px" }}>
      {bySeqMetrice && bySeqMetrice.length &&
        <PieChart data={metricsArray} contentName={label} isContentname={true} />
      }
    </Flex> : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? <Flex direction="column" style={{ height: "350px" }}>
      {bySeqMetrice && bySeqMetrice.length &&
        <PieChart data={metricsArray} contentName={label} isContentname={true} />
      }
    </Flex> : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? <DualAxesChart activityData={metricsArray} /> : null))
  }

  const addClassnameForChart = () => {
    if (currActiveLiveFeedTab === 'summary') {
      return ['life'].includes(currTimedMetric?.currRangeType) ? "cicle-chart" : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "pie-chart touch__div" : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? 'line-chart touch__div' : ''))
    }
    if (currActiveLiveFeedTab === 'sequence') {
      return ['life'].includes(currTimedMetric?.currRangeType) ? "sequence-chart" : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "pie-chart" : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? 'line-chart' : ''))
    }
    if (currActiveLiveFeedTab === 'touch') {
      return ['life'].includes(currTimedMetric?.currRangeType) ? "touch-chart" : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "pie-chart" : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? 'line-chart' : ''))
    }
  }
  const addClassnameForBlock = () => {
    if (currActiveLiveFeedTab === 'sequence') {
      return ['life'].includes(currTimedMetric?.currRangeType) ? "sequence__block" : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "" : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? 'touch__block' : ''))
    }
    if (currActiveLiveFeedTab === 'touch') {
      return ['life', 'today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "sequence__block" : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "" : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? 'touch__block' : ''))
    }
  }

  const addClassnameForDiv = () => {
    if (currActiveLiveFeedTab === 'summary') {
      return ['life'].includes(currTimedMetric?.currRangeType) ? "" : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "touch__div" : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? 'touch__div' : ''))
    }
    if (currActiveLiveFeedTab === 'sequence') {
      return ['life'].includes(currTimedMetric?.currRangeType) ? "" : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "touch__div" : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? 'touch__div' : ''))
    }
    if (currActiveLiveFeedTab === 'touch') {
      return ['life'].includes(currTimedMetric?.currRangeType) ? "touch__div" : (['today', 'yesterday'].includes(currTimedMetric?.currRangeType) ? "touch__div" : (["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ? 'touch__div' : ''))
    }
  }
  console.log(">>>>>>>>>>>>>>>>>>>>>>", touches)
  const ActivitiesArray = useMemo(() => {
    return [
      {
        key: 'summary',
        label: `Summary`,
        children: <Div
          className={addClassnameForChart()}
        >
          {renderSummaryChart(summary)}
        </Div>,
      },
      {
        key: 'sequence',
        label: `By Sequence`,
        children: <Div
          className={addClassnameForChart()}
        >
          <>
            {sequences && sequences?.map((seque: any) => {
              return <Div className={addClassnameForDiv()}>
                <p style={{ display: "block" }}><span>{seque?.sequenceName}</span></p>
                <Div className={addClassnameForBlock()}> {renderSummaryChart(seque?.items || [])}</Div>
              </Div>
            })}
          </>
        </Div>,
      },
      {
        key: 'touch',
        label: `By Touch`,
        children: <Div
          className={addClassnameForChart()}
        >
          {["last7days", "last15days","last30days"].includes(currTimedMetric?.currRangeType) ?
            touches?.map((touch: any) => {
              console.log("touchniku", touch)
              return <Div className={addClassnameForDiv()}>
                <p style={{ display: "block" }}><span>{touch?.touchName}</span></p>
                <Div className={addClassnameForBlock()} >
                  {renderTouchChart(touch?.items, touch?.touchName)}
                </Div>
              </Div>
            })
            : touches?.map((touch: any) => {
              console.log("touchniku 21", touch)
              return <Div className={addClassnameForDiv()}>
                <p style={{ display: "block" }}><span>{touch?.seqName}</span></p>
                <Div className={addClassnameForBlock()} >
                  {touch && touch?.touches?.length > 0 ? (touch?.touches?.map((its: any) => {
                    return renderTouchChart(its?.items, its?.label)
                  })) : 'No Records'}
                </Div>
              </Div>
            })
          }

        </Div>,
      }
    ];
  }, [currActiveLiveFeedTab, currTimedMetric?.currRangeType, summary, sequences, touches])

  console.log("touchesniku", touches)
  const callback = async (key: any) => {
    try {
      let currRangeType: any = currTimedMetric?.currRangeType
      fetchNewNumberMetrics(key, currRangeType)
    } catch (err) { }
    setState((prev: any) => ({ ...prev, currActiveLiveFeedTab: key }))
  }

  const renderSkeletonLoader = () => {
    if (['life'].includes(currTimedMetric?.currRangeType) && currActiveLiveFeedTab !== 'touch') {
      return Array(12).fill(0).map(el => (
        <Div style={{ width: "16%" }} mb={25}>
          <Card>
            <Skeleton active />
          </Card>
        </Div>
      ))
    } else {
      return <Div style={{ width: "100%" }} mb={25}><Card>
        <Skeleton />
        <Skeleton />
      </Card></Div>
    }

  }
  console.log("currActiveLiveFeedTabcurrActiveLiveFeedTab", currActiveLiveFeedTab)
  return (
    <Flex mt={10}>
      <Flex
        grow={1}
        h="100%"
        direction="column"
        className={classes.scrollContainer}
        mr={20}
        p={0}
           style={{ position: "relative", boxShadow: BOX_SHADOW,
       overflow:"hidden"
      }}
      >
        <Flex >
          <Div w="80%">
            <Div className="recent-activites-container">
              <LiveFeed events={liveFeed.events} loading={liveFeed.loading} paused={liveFeed.paused} onPlayPause={onPlayPause} />
            </Div>
          </Div>
          <Div className="campaign-row">
            {/* <Progress strokeLinecap="butt" percent={60}
              strokeColor={theme.antdConf.primaryColor}
              strokeWidth={11}
              showInfo={false}
            /> */}


            <Div className="campaign-block">
              <Flex justify="space-between" align="center" mt={20}>
                <Text variant="body1" block align="left" mb={8}>
                  Campaign Life
                </Text>

                <div className="battery__wrapper">
                  <div className="battery">
                    <div className="charge"
                      style={{
                        width: `calc(${Math.round((life.elapsed / (life.remaining + life.elapsed)) * 100)}% - 6px)`,
                        background: theme.antdConf.primaryColor,
                      }}
                    ></div>
                  </div>

                  <div className="charging-time">{Math.round((life.elapsed / (life.remaining + life.elapsed)) * 100)}% remaining</div>
                </div>
              </Flex>
              <Flex justify="space-between" mt={60}>
                <div className="campaign-text"><span>{life.elapsed}</span> <p>Days <br /> elapsed</p> </div>
                <div className="campaign-text"><span>{life.remaining}</span> <p>Emails <br /> remaining</p> </div>
              </Flex>
            </Div>
          </Div>
        </Flex>
        <Flex w="100%" className="campaign-tab-container">
          <Div className="campaign-tab-row">
            <Tabs activeKey={currActiveLiveFeedTab} onChange={callback} className="campaign-tab-block">
              {ActivitiesArray && ActivitiesArray?.map((tb: any) => {
                return <TabPane tab={tb?.label} key={tb?.key}>
                  {isLoader ? <Flex w="100%" h="100%" align="center" justify="space-between" wrap="wrap">
                    {renderSkeletonLoader()}
                    {/* {Array(10).fill(0).map(el => (
                      <Div style={{ width: "19%" }} mb={25}>
                        <Card>
                          <Skeleton active />
                        </Card>
                      </Div>
                    ))} */}
                  </Flex> : tb.children}
                </TabPane>
              })}

            </Tabs>

            <Div className="campaign-tab-select">
              <Select defaultValue={currTimedMetric?.currRangeType} style={{ width: 170, marginBottom: "10px" }} onChange={(val) => handleRangeTypeChange(val)}>
                {rangeTypeArr && (rangeTypeArr?.map((seq) => {
                  return <Option value={seq?.value}>{seq?.label}</Option>;
                }))}
              </Select>
            </Div>

          </Div>
        </Flex>


        <>
          <h4 className={classes.stashHeading}></h4>
          <>
          </>
        </>
      </Flex>
    </Flex>
  );
};

export default NewStats;

interface DualAxesType {
  activityData: ActivityType[];
}

const DualAxesChart = ({ activityData }: DualAxesType) => {
  console.log("DualAxesChartDualAxesChart", activityData)
  let activityDatas = activityData ? activityData : []
  const config = {
    data: activityDatas,
    xField: "time",
    yField: "value",
    seriesField: 'type',
    xAxis: {
      type: 'time',
    }
  };
  return <Line {...config} />;
};

const BarChart = ({ activityData }: DualAxesType) => {
  console.log("activityData", activityData)
  let activityDatas = activityData ? activityData : []
  const config = {
    data: activityDatas,
    xField: "title",
    yField: "value",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  return <Column {...config} />;
};

const PieChart = ({
  data = [],
  contentName = 'Prospect',
  isContentname = false
}: PieDataType) => {
  const total = data.reduce(function (sum, current) {
    return sum + current.value;
  }, 0)

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,

    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false as const,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: "16px",
        },
        content: isContentname ? contentName : total.toString(),
      },
    },
    legend: false as const
  };

  return <Pie {...config} />
};