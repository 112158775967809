import React, { useContext, useEffect } from "react";
import { OAUTH_STATE_LS_KEY } from "constants/localstorage";
import appContext from "contexts/appContext";
import withAppBar from "hoc/withAppBar";
import { useHistory } from "react-router-dom";
import LocalStorage from "utils/localstorage";
import { notification } from "antd";

interface OauthCallbackProps {}

const OauthCallback: React.FC<OauthCallbackProps> = () => {
  const history = useHistory();
  const { setLoadingMessage } = useContext(appContext);

  useEffect(() => {
    setLoadingMessage("Loading ...");
    return () => setLoadingMessage("");
  }, [setLoadingMessage]);

  useEffect(() => {
    const { search = "" } = history.location;
console.log("called here callback")
    const { state, error, email, firstname, lastname } = decodeURI(search)
      .replace("?", "")
      .split("&")
      .reduce(
        (acc, curr) => {
          const [variable, value] = curr.split("=");
          return { ...acc, [variable]: value };
        },
        { state: "", error: "", email: "", firstname: "", lastname: "" }
      );
    if (error) {
      notification.error({
        message: "Mailbox linking failed",
        description: error
      });
      history.replace("/");
      return;
    }

    const oauthLocalState: {
      state: string;
      redirectTo: string;
    } | null = LocalStorage.getValue(OAUTH_STATE_LS_KEY);

    if (oauthLocalState && oauthLocalState.state && oauthLocalState.redirectTo && state === oauthLocalState.state) {
      history.replace(`${oauthLocalState.redirectTo}`, {
        addEmailState: state,
        email,
        lastname,
        firstname
      });
    } else {
      // If oauth local state does not exist then redirect to home page
      history.replace("/");
    }
  }, [history]);

  return null;
};

export default withAppBar(OauthCallback);