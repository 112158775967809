import {
  FETCH_CAMPAIGN_TOUCHES,
  FETCH_CAMPAIGN_TOUCHES_SUCCESS,
  FETCH_CAMPAIGN_PROSPECTS,
  FETCH_CAMPAIGN_PROSPECTS_SUCCESS,
  FETCH_SEQUENCES,
  FETCH_SEQUENCES_SUCCESS,
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TIMEZONES,
  FETCH_TIMEZONES_SUCCESS,
  FETCH_CAMPAIGN_DETAILS_SUCCESS,
} from "state/actions/actionTypes";

const reducer = (state: any = {}, action: { type: string; payload: any }) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_TOUCHES: {
      const { campaignId } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          touches: {
            entities: [],
            loading: true,
          },
        },
      };
    }
    case FETCH_CAMPAIGN_TOUCHES_SUCCESS: {
      const { campaignId, touches } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          touches: {
            entities: touches,
            loading: false,
          },
        },
      };
    }
    case FETCH_CAMPAIGN_PROSPECTS: {
      const { campaignId } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          prospects: {
            entities: [],
            loading: true,
            totalPages: state?.[campaignId]?.prospects?.totalPages || 1,
          },
        },
      };
    }
    case FETCH_CAMPAIGN_PROSPECTS_SUCCESS: {
      const { campaignId, prospects, totalPages } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          prospects: {
            entities: prospects,
            loading: false,
            totalPages,
          },
        },
      };
    }
    case FETCH_SEQUENCES: {
      const { campaignId } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          sequences: {
            entities: [],
            loading: true,
          },
        },
      };
    }
    case FETCH_SEQUENCES_SUCCESS: {
      const { campaignId, sequences } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          sequences: {
            entities: sequences,
            loading: false,
          },
        },
      };
    }
    case FETCH_TAGS: {
      const { campaignId } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          tags: {
            entities: [],
            loading: true,
          },
        },
      };
    }
    case FETCH_TAGS_SUCCESS: {
      const { campaignId, tags} = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          tags: {
            entities: tags,
            loading: false,
          },
        },
      };
    }
    case FETCH_TIMEZONES: {
      const { campaignId } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          timezones: {
            entities: [],
            loading: true,
          },
        },
      };
    }
    case FETCH_TIMEZONES_SUCCESS: {
      const { campaignId, timezones} = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          timezones: {
            entities: timezones,
            loading: false,
          },
        },
      };
    }
    case FETCH_CAMPAIGN_DETAILS_SUCCESS: {
      const {campaignId, campaignDetail} = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          details: campaignDetail
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
