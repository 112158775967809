import { useEffect, useContext, useState, useMemo } from "react";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { NavPathType } from "components/AppBar";
import { Table, Button, Space, Tooltip, Modal, Form, Select, Checkbox, Input } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import { isArray, isEmpty } from "lodash";
import './report.css'
import { Text } from "styles/typography";

const { Option } = Select;

const dataSource = [
  {
    "id": "rr_ebWROqRHvi",
    "userid": "26fc2c7c-f596-4233-83e1-3dd4aca8301c",
    "frequency": "Daily",
    "coverage": "Last30Days",
    "events": "HARDBOUNCED,OUTOFOFFICE",
    "tagids": "tag_A38QA48uA5,tag_6hM5v0bzBW",
    "email_tos": "shahkm@gmail.com,shah@rei.com",
    "email_ccs": "shah@vendisys.com,another@email.com,kj@gmail.com",
    "email_bccs": "someone@email.com,whoelse@gmail.com",
    "campids": "id1,id2,id3",
    "excludeweekend": false
  },
  {
    "id": "rr_0Uk6hJEqBq",
    "userid": "26fc2c7c-f596-4233-83e1-3dd4aca8301c",
    "frequency": "Weekly",
    "coverage": "Last30Days",
    "events": "HARDBOUNCED,OUTOFOFFICE",
    "tagids": "tag_5TN6paDppF,tag_FN3ulZce93",
    "email_tos": "shahkm@gmail.com,shah@rei.com",
    "email_ccs": "shah@vendisys.com,another@email.com",
    "email_bccs": "someone@email.com,whoelse@gmail.com",
    "campids": "id1,id2,id3",
    "excludeweekend": false
  }
]



interface ReportPageProps {
  setNavPaths: SetNavPathsFn;
}

const Report = ({ setNavPaths }: ReportPageProps) => {
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const [newRuleModal, setNewRuleModal] = useState(false)
  const [state, setState] = useState<any>({ reportList: [], tags: [], editModeData: null, deleteId: null })
  const { reportList, tags, editModeData, deleteId } = state
  const { callApi } = useApi();
  const userId = String(localStorage.getItem("selectedUserId"))
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const columns = [
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
    },
    {
      title: 'Coverage',
      dataIndex: 'coverage',
      key: 'coverage',
      render: (_: any, record: any) => (
        <Space size="middle">
          {record?.coverage?.replace(/([a-zA-Z])([0-9])/g, '$1 $2').replace(/([0-9])([a-zA-Z])/g, '$1 $2')}
        </Space>
      ),
    },
    {
      title: 'Events',
      dataIndex: 'events',
      key: 'events',
    },
    {
      title: 'Reply Tags',
      dataIndex: 'tagids',
      key: 'tagids',
      render: (_: any, record: any) => {
        const tagIds = record?.tagids?.split(',')
        const findTags: any = tags?.filter((vl: any) => tagIds?.includes(vl?.id))?.map((vl: any) => vl?.name)
        return <span>{findTags?.length > 0 ? findTags?.join(', ') : '--'}</span>
      },
    },
    {
      title: 'Email Recipients',
      dataIndex: 'email_tos',
      key: 'email_tos',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record)}
              danger
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();


  const handleEdit = async (record: any) => {
    console.log(`Edit record with record: record`, record);
    try {
      const { url, method } = API_ENDPOINTS.REPORT.getReportById(record?.id);
      const response = await callApi({
        url,
        method,
      });
      if (response.success) {
        const data = response?.data?.rule
        if (!isEmpty(data)) {
          setNewRuleModal(true)
          const setdata = {
            frequency: data?.frequency,
            coverage: data?.coverage,
            events: data?.events?.split(',') || [],
            replyTags: data?.tagids?.split(',') || [],
            to: data?.email_tos || '',
            cc: data?.email_ccs || '',
            bcc: data?.email_bccs || '',
            id: data?.id
          }
          form.setFieldsValue(setdata);
          setState((prev: any) => ({ ...prev, editModeData: setdata }))
        }
      }
    }
    catch (err) {
      console.log("handleEdit ERR", err)
    }
  };


  const handleDelete = (record: any) => {
    console.log(`Delete record with record:`, record);
    setShowDeleteConfirmModal(true)
    setState((prev: any) => ({ ...prev, deleteId: record.id }))
  };


  useEffect(() => {
    const navPaths: NavPathType[] = [];
    if (accountData) {
      navPaths.push(
        {
          label: accountData.accountName,
          linkTo: `/account/${accountData.accountId}/campaigns`
        },
        { label: "Report" }
      );
    }
    setNavPaths(navPaths);
    return () => setNavPaths([]);
  }, [setNavPaths, accountData]);

  useEffect(() => {
    setAccountData({
      accountId: String(localStorage.getItem("account_id")),
      accountName: String(localStorage.getItem("accountName"))
    });
  }, [setAccountData]);


  const handleFormSubmit = async (values: any) => {
    try {
      let payload: any = {
        userid:userId,
        frequency: values?.frequency,
        "coverage": values?.coverage,
        events: values?.events?.join(','),
        tagids: values?.replyTags?.join(','),
        email_tos: values?.to,
        email_ccs: values?.cc,
        email_bccs: values?.bcc,
        campids:""
      }
      if (editModeData) {
        payload.id = editModeData?.id
        const { url, method } = API_ENDPOINTS.REPORT.updateReport(editModeData?.id);
        const response = await callApi({
          url,
          method,
          data: payload,
        });
        if (response.success) {
          fetchReportsData()
          setState((prev: any) => ({ ...prev, editModeData: null }))
          setNewRuleModal(false)
        }
      } else {
        const { url, method } = API_ENDPOINTS.REPORT.addReport();
        const response = await callApi({
          url,
          method,
          data: payload,
        });
        if (response.success) {
          fetchReportsData()
          setNewRuleModal(false)
          setState((prev: any) => ({ ...prev, editModeData: null }))
        }
      }

    } catch (err) {
      console.log("handleFormSubmit ERR:", err)
    }

  };
  console.log("editModeDataeditModeData", editModeData)
  const handleFormFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const fetchReportsData = async () => {
    try {
      if (userId) {
        const { url, method } = API_ENDPOINTS.REPORT.getReportList(userId);
        const response = await callApi({
          url,
          method
        });
        if (response.success) {
          setState((prev: any) => ({ ...prev, reportList: response?.data?.rules || [] }))
        }
      }
    } catch (err) {
      console.log("fetchReportsData Err :", err)
    }
  }

  const fetchTags = async () => {
    if (userId) {
      const { url, method } = API_ENDPOINTS.CAMPAIGN_TAG.getTagListUrl(
        userId
      );
      try {
        const response = await callApi({
          url,
          method,
        });

        if (response.success) {
          setState((prev: any) => ({ ...prev, tags: response?.data?.tags || [] }))
        }
      } catch (err) {
        console.log("fetchTags Err :", err)
      }
    }
  };

  useEffect(() => {
    fetchReportsData()
    fetchTags()
  }, [userId])

  const onDeleteHandle = async () => {
    if (deleteId) {
      const { url, method } = API_ENDPOINTS.REPORT.deleteReport(deleteId);
      try {
        const response = await callApi({ url, method });
        if (response.success) {
          setShowDeleteConfirmModal(false)
          setState((prev: any) => ({ ...prev, deleteId: null }))
          fetchReportsData()
        }
      } catch (err) {
      }
    }

  }

  const onCancel = () => {
    setNewRuleModal(false)
    form.resetFields()
    setState((prev: any) => ({ ...prev, editModeData: null, deleteId: null }))
  }

  return (
    <><div>
      <Button type="primary" style={{ marginBottom: 16 }} onClick={() => setNewRuleModal(true)}>
        New Rule
      </Button>
      <Table dataSource={reportList} columns={columns} pagination={false} />
    </div>
      {newRuleModal && <Modal
        visible={true}
        centered
        width={800}
        onCancel={onCancel}
        className="scrollable-modal"
        title={editModeData ? "Edit Report" : "Add Report"}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" onClick={() => form.submit()} type="primary" htmlType="submit">
            Submit
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          onFinishFailed={handleFormFailed}
          className="modal-content-scroll"
        >
          <Form.Item
            name="frequency"
            label="Frequency"
            rules={[{ required: true, message: 'Please select a frequency!' }]}
          >
            <Select placeholder="Select frequency">
              <Option value="Daily">Daily</Option>
              <Option value="Weekly">Weekly</Option>
              <Option value="Monthly">Monthly</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="coverage"
            label="Coverage"
            rules={[{ required: true, message: 'Please select coverage!' }]}
          >
            <Select placeholder="Select coverage">
              <Option value="Yesterday">Yesterday</Option>
              <Option value="Last7Days">Last 7 Days</Option>
              <Option value="Last14Days">Last 14 Days</Option>
              <Option value="Last30Days">Last 30 Days</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Campaign Events"  >
            <Form.Item name="events" noStyle>
              <Checkbox.Group>
                <Checkbox value="SENT">Sent</Checkbox>
                <Checkbox value="OPENED">Opened</Checkbox>
                <Checkbox value="REPLIED">Replied</Checkbox>
                <Checkbox value="UNSUBSCRIBED">Unsubscribed</Checkbox>
                <Checkbox value="OUTOFOFFICE">Out of Office</Checkbox>
                <Checkbox value="HARDBOUNCED">Hard Bounced</Checkbox>
                <Checkbox value="SOFTBOUNCED">Soft Bounced</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Form.Item>
          <Form.Item label="Reply Tags">
            <Form.Item name="replyTags" noStyle>
              <Checkbox.Group className="checkbox-group">
                {tags?.map((vl: any,ind:number) => (
                  <Checkbox style={ind === 0 ?{marginLeft:"8px"}:{}} value={vl?.id} key={vl?.id}>{vl?.name}</Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Email Recipients">
            <Form.Item
              name="to"
              label="To"
              rules={[{ required: true, message: 'Please enter recipient emails!' }]}
            >
              <Input placeholder="Use comma to separate multiple emails" />
            </Form.Item>
            <Form.Item name="cc" label="Cc">
              <Input placeholder="Use comma to separate multiple emails" />
            </Form.Item>
            <Form.Item name="bcc" label="Bcc">
              <Input placeholder="Use comma to separate multiple emails" />
            </Form.Item>
          </Form.Item>

          {/* <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </Modal>}
      {showDeleteConfirmModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary",
            danger: true,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={() => setShowDeleteConfirmModal(false)}
          onOk={() => onDeleteHandle()}
          cancelButtonProps={{ type: "text" }}
          centered

        >
          <Text variant="body2">
            Are you sure you want to{" "}
            <Text variant="body2" bold>
              delete
            </Text>
            ?
          </Text>
        </Modal>
      )}
    </>
  );
};

export default withAppBar(Report);