import { UserOutlined } from "@ant-design/icons";
import { ThemeDetails } from "constants/theme";
import React from "react";
import { Flex } from "styles/layout";
import { WHITE_COLOR } from "utils/colors";

interface AvatarProps {
  theme: ThemeDetails['antdConf'];
  onClick: (e: React.SyntheticEvent) => void;
}

const Avatar = ({ theme, onClick }: AvatarProps) => (
  <Flex
    style={{
      borderRadius: "15px",
      height: "32px",
      width: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}
    bgColor={theme.primaryColor}
    color={WHITE_COLOR}
    borderColor={theme.primaryColor}
    hoverBorderColor={theme.primaryColor}
    hoverBgColor="white"
    hoverColor={theme.primaryColor}
    borderWidth={2}
    onClick={onClick}
  >
    <UserOutlined style={{ fontSize: "20px" }} />
  </Flex>
);

export default Avatar;
