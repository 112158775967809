import { useCallback, useEffect, useState, useMemo } from "react";
import { Button, List, Modal, notification, Skeleton, Tooltip, Table } from "antd";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import { Text } from "styles/typography";
import NoCampaignsImage from "images/no-campaigns.svg";
import { BgImage, Div, Flex } from "styles/layout";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { HolidayForm, HolidayItem, MONTH_LIST, POSITION_LIST, WEEK_LIST } from "./HolidayForm";
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import classes from "../styles.module.css";
import { useLocation } from "react-router-dom";
import { NavPathType } from "components/AppBar";

interface HolidaysPageProps {
  setNavPaths: SetNavPathsFn;
}

enum RENDER_STATE {
  SUSPENSE = 1,
  LOADING = 2,
  ERROR = 3,
  COMPLETE = 4
}
export interface HolidayState {
  renderState: RENDER_STATE;
  formState: RENDER_STATE;
  holidayList: HolidayItem[];
  error: any;
  showHolidayModal: boolean;
  showDeleteConfirm: boolean;
  userId: string;
}

const ACCOUNT_ID_LS = "account_id";

const stateMerge = (newState: any) => (oldState: any) => ({
  ...oldState,
  ...newState
});

const NoHolidays = ({ onAdd }: any) => {
  return (
    <Flex direction="column" align="center">
      <BgImage imageUrl={NoCampaignsImage} mh={200} mw={200} />
      <Text variant="body1" block mt={10}>
        No Holidays found
      </Text>
      <Text variant="sub2" block color="secondary" mt={10}>
        You haven't created any holiday yet.
      </Text>
      <Div mt={20}>
        <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
          New holiday
        </Button>
      </Div>
    </Flex>
  );
};

const DeleteConfirmModal = ({ name, onCancel, onOk, isLoading }: any) => {
  return (
    <Modal
      title={
        <Div>
          <ExclamationCircleOutlined /> <Text variant="sub1"> Delete Confirm</Text>
        </Div>
      }
      visible
      maskClosable={!isLoading}
      confirmLoading={isLoading}
      closable={!isLoading}
      onOk={onOk}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={onCancel}
    >
      <p>{`Are you sure to delete the holiday ${name}?`}</p>
    </Modal>
  );
};

const HolidaysModule = ({ setNavPaths }: HolidaysPageProps) => {
  const { callApi } = useApi();
  const [holidayItem, setHolidayItem] = useState<HolidayItem | null>(null);
  const [hState, _hState] = useState<HolidayState>({
    renderState: RENDER_STATE.SUSPENSE,
    formState: RENDER_STATE.SUSPENSE,
    error: null,
    holidayList: [],
    showHolidayModal: false,
    showDeleteConfirm: false,
    userId: ""
  });
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const location = useLocation();

  const getAccountName = useMemo((): any => {
    return location.state;
  }, []);
  const uId = useMemo((): any => {
    return localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";
  }, []);

  useEffect(() => {
    if (getAccountName && Object.keys(getAccountName).length) {
      setAccountData({
        accountId: getAccountName.accountId,
        accountName: getAccountName.name
      });
      // localStorage.setItem("accountId", String(getAccountName.accountId));
      // localStorage.setItem("accountName", String(getAccountName.name));
    } else {
      setAccountData({
        accountId: String(localStorage.getItem("account_id")),
        accountName: String(localStorage.getItem("accountName"))
      });
    }
  }, [getAccountName, setAccountData]);
  useEffect(() => {
    const navPaths: NavPathType[] = [];
    navPaths.push({ label: "Holidays" });
    setNavPaths(navPaths);
    return () => setNavPaths([]);
  }, [setNavPaths, location]);

  const getAccountId = useCallback(async () => {
    let accountId = localStorage.getItem(ACCOUNT_ID_LS);
    if (!accountId) {
      const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";

      const { url, method } = API_ENDPOINTS.ACCOUNTS.getListUrl(uId || "");
      const response = await callApi({
        url,
        method
      });
      if (response.success) {
        accountId = response.data?.accounts?.[0]?.id;
      }
    }
    return accountId ?? "";
  }, [callApi]);

  const fetchHolidays = useCallback(async () => {
    if (uId) {
      const { url, method } = API_ENDPOINTS.HOLIDAYS.getHoliday(uId);
      let response: any = await callApi({
        url,
        method
      });
      // let response: any = {
      //   success: true,
      //   data: {
      //     holidays: [
      //       {
      //         id: "1",
      //         name: "1st trip",
      //         recurringDay: {
      //           month: "September",
      //           dayOfMonth: 28
      //         }
      //       },
      //       {
      //         id: "2",
      //         name: "2nd trip",
      //         recurringWeek: {
      //           position: 1,
      //           dayOfWeek: "Sunday",
      //           month: "September"
      //         }
      //       },
      //       {
      //         id: "3",
      //         name: "3rd trip",
      //         fixedDate: "2018-12-25"
      //       }
      //     ]
      //   }
      // };
      if (response.success) {
        _hState((st) => ({
          ...st,
          userId: uId,
          renderState: RENDER_STATE.COMPLETE,
          holidayList: response?.data?.holidays ?? []
        }));
      } else {
        _hState((st) => ({
          ...st,
          userId: uId,
          renderState: RENDER_STATE.ERROR,
          error: response?.error
        }));
      }
    }
  }, [callApi, getAccountId]);

  const showHolidayDialog = useCallback((holiday: HolidayItem | null) => {
    setHolidayItem(holiday);
    _hState((st) => ({ ...st, showHolidayModal: true }));
  }, []);

  const showDeleteDialog = useCallback((holiday: HolidayItem | null) => {
    setHolidayItem(holiday);
    _hState((st) => ({ ...st, showDeleteConfirm: true }));
  }, []);

  const createUpdateHoliday = useCallback(
    async (holiday: HolidayItem) => {
      _hState((st) => ({
        ...st,
        formState: RENDER_STATE.LOADING
      }));
      // console.log("[holiday] create/update data", holiday);
      let response: any = null;
      if (holiday?.id) {
        const { url, method } = API_ENDPOINTS.HOLIDAYS.updateHolidays(hState?.userId, holiday?.id);
        response = await callApi({
          url,
          method,
          data: holiday
        });
      } else {
        const { url, method } = API_ENDPOINTS.HOLIDAYS.addUpdateHolidays(hState?.userId);
        response = await callApi({
          url,
          method,
          data: holiday
        });
      }
      if (response.success) {
        if (!holiday?.id) {
          notification.success({
            message: `Holiday created succesfully`
          });
        } else {
          notification.success({
            message: `Holiday updated succesfully`
          });
        }
        _hState((st) => ({
          ...st,
          formState: RENDER_STATE.COMPLETE,
          showHolidayModal: false
          // holidayList: holiday.id && !holiday.isNew ? st.holidayList.map((hd) => (hd.id === holiday.id ? holiday : hd)) : st.holidayList.concat(holiday)
        }));
        fetchHolidays();
      } else {
        _hState(stateMerge({ formState: RENDER_STATE.ERROR, error: response.error }));
      }
    },
    [callApi, hState?.userId]
  );

  const onDeleteHoliday = useCallback(
    async (id) => {
      _hState((st) => ({
        ...st,
        formState: RENDER_STATE.LOADING
      }));
      const { url, method } = API_ENDPOINTS.HOLIDAYS.deleteHolidays(id, hState?.userId);
      let response: any = await callApi({
        url,
        method
      });
      response.success = true;
      if (response.success) {
        _hState((st) => ({
          ...st,
          showDeleteConfirm: false,
          formState: RENDER_STATE.COMPLETE,
          holidayList: st.holidayList.filter((hd) => hd.id !== id)
        }));
        notification.success({
          message: `Holiday deleted succesfully`
        });
        // fetchHolidays()
      } else {
        _hState((st) => ({
          ...st,
          showDeleteConfirm: false,
          formState: RENDER_STATE.ERROR,
          error: response.error
        }));
      }
    },
    [callApi, hState?.userId]
  );

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <a href="#" onClick={(e) => showHolidayDialog(record)} className="name-link">
          {text}
        </a>
      )
    },
    {
      title: "Date",
      dataIndex: "fixedDate",
      key: "fixedDate",
      render: (text: any, record: any) => {
        return (
          <>
            {record?.fixedDate && (
              <Div>
                <Text variant="caption">{record?.fixedDate && moment(record?.fixedDate).format("DD MMMM YYYY")}</Text>
              </Div>
            )}
            {record?.recurringDay && (
              <Text variant="caption">
                {`Occurs every ${record?.recurringDay?.month}
                               ${record?.recurringDay?.dayOfMonth}`}
              </Text>
            )}
            {record?.recurringWeek && (
              <Text variant="caption">
                {`Occurs on the ${POSITION_LIST.find((ps) => ps.value === record?.recurringWeek?.position)?.label} ${record?.recurringWeek?.dayOfWeek} of ${
                  record?.recurringWeek?.month
                }`}
              </Text>
            )}
          </>
        );
      }
    },
    {
      title: "Action",
      key: "action", // Make sure to include a unique key
      render: (text: any, record: any) => {
        return (
          <Tooltip title="Delete holiday" placement="bottom">
            <Button
              type="default"
              shape="circle"
              danger
              icon={<DeleteOutlined />}
              size="small"
              style={{ marginRight: 8 }}
              onClick={(ev) => {
                ev.stopPropagation();
                showDeleteDialog(record);
              }}
            />
          </Tooltip>
        );
      }
    }
  ];
  return (
    <>
      {hState?.holidayList?.length || hState?.renderState === RENDER_STATE.LOADING || hState?.renderState === RENDER_STATE.SUSPENSE ? (
        <Div mt={40}>
          <Flex justify="space-between">
            <Skeleton
              title={false}
              paragraph={{ rows: 1 }}
              loading={hState.renderState === RENDER_STATE.LOADING || hState.renderState === RENDER_STATE.SUSPENSE}
              active
            >
              <Text variant="sub1">Holidays</Text>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => showHolidayDialog(null)}>
                New Holiday
              </Button>
            </Skeleton>
          </Flex>
          {hState.renderState === RENDER_STATE.SUSPENSE || hState.renderState === RENDER_STATE.LOADING ? null : (
            <Flex mt={10}>
              <Table dataSource={hState?.holidayList} columns={columns} style={{ width: "100%" }} />
            </Flex>
          )}
        </Div>
      ) : (
        <Div mt={50}>
          <NoHolidays onAdd={() => showHolidayDialog(null)} />
        </Div>
      )}
      {hState?.showHolidayModal && (
        <HolidayForm
          initialValues={holidayItem}
          isLoading={hState.formState === RENDER_STATE.LOADING}
          onOk={createUpdateHoliday}
          onCancel={() => _hState((h) => ({ ...h, showHolidayModal: false }))}
        />
      )}
      {hState.showDeleteConfirm && (
        <DeleteConfirmModal
          name={holidayItem?.name}
          isLoading={hState.formState === RENDER_STATE.LOADING}
          onOk={() => onDeleteHoliday(holidayItem?.id)}
          onCancel={() => _hState((h) => ({ ...h, showDeleteConfirm: false }))}
        />
      )}
    </>
  );
};
export default withAppBar(HolidaysModule);
