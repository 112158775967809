import React, { useEffect } from "react";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";

interface InsightsPageProps {
  setNavPaths: SetNavPathsFn;
}
const Insights = ({ setNavPaths }: InsightsPageProps) => {
  useEffect(() => {
    setNavPaths([{ label: "Insights" }]);
    return () => setNavPaths([]);
  }, [setNavPaths]);

  return <></>;
};

export default withAppBar(Insights);
