export enum AvatarAccountEnum {
    TOTEM = "TOTEM",
    SKIN = "SKIN",
    SHELL = "SHELL",
    NEEDLE = "NEEDLE",
    NECKLACE = "NECKLACE",
    METEORITE = "METEORITE",
    MEAT = "MEAT",
    LEAF = "LEAF",
    HOUSE = "HOUSE",
    HAMMER = "HAMMER",
    FIRE = "FIRE",
    EGG = "EGG",
    CORN = "CORN",
    BONFIRE = "BONFIRE",
  ALOEVERA="ALOEVERA",
  COMET="COMET",
  CONCHSHELL="CONCHSHELL",
  DESIGNVASE="DESIGNVASE",
  EASTEREGG="EASTEREGG",
  FIREBLAST="FIREBLAST",
  FIREIGNITE="FIREIGNITE",
  HOME="HOME",
  MAPLELEAF="MAPLELEAF",
  MEATS="MEATS",
  METEORITECIRCLE="METEORITECIRCLE",
  HERBAL="HERBAL",
  SEASNAIL="SEASNAIL",
  THOR="THOR",
  SHOTMETEORITE="SHOTMETEORITE",
  TOTEMCIRCLE="TOTEMCIRCLE",
  TOTEMSTAND="TOTEMSTAND"
  }
export interface AccountSummaryType {
    id: string;
    name: string;
    campaigns: number,
    activeCampaigns: string[],
    inactiveCampaigns: string[],
    avatar: AvatarAccountEnum,
    timeZone?: string
    timezone?: string;
  }
