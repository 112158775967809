import React from "react";
import moment from "moment";
import { Flex, Div } from "styles/layout";
import { Text } from "styles/typography";
import { LIGHT_GRAY_COLOR, WHITE_COLOR } from "utils/colors";
import classes from "pages/CampaignDetail/TagV2/styles.module.css";
const AlertsList = (props: any) => {
    const { alerts: { read, mailbox, isToday, created, subject, body, severity }, getDetails } = props
    return (
        <>
            <Flex position="relative" w="100%" onClick={getDetails}>
                <Flex
                    w="100%"
                    bgColor={LIGHT_GRAY_COLOR}
                    p="5px 10px"
                    align="center"
                    style={{ lineHeight: 1.3, }}
                    className={`${classes.mailPaneUnread}`}
                //className={` ${selectedMail === id ? classes.mailPaneActive : ''} ${!isRead ? classes.mailPaneUnread : classes.mailPane}`}


                >
                    {/* <Flex w="100%" align="center">
                        <Text
                            variant="sub2"
                            bold={!read}
                            mr={20}
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                color: "#111",
                                marginLeft: '1px'
                            }}
                        >
                            {mailbox?.email}
                        </Text>
                        <Flex ml="auto" justify="end" align="center" >
                            <Text variant="caption" bold={!read} style={{ color: "#555", fontWeight: "500", fontSize: "11px", whiteSpace: "nowrap" }}>
                                {isToday ? moment(created).format("hh:mm a") :
                                    moment(created).format("MMM DD")}
                            </Text>
                        </Flex>
                    </Flex> */}
                    <Text
                        variant="sub2"
                        bold={!read}
                        mt={4}
                        mb={2}
                        mr={6}
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "break-spaces",
                            width: "100%",
                            color: "#777",
                            fontSize: "13px",
                            marginLeft: '0px'
                        }}
                    >
                        <Flex w="100%" align="center">
                            <Div className={classes.emailSubject}>{subject} -</Div>
                            <Flex ml="auto" justify="end" align="center" >
                                <Text variant="caption" bold={!read} style={{ color: "#555", fontWeight: "500", fontSize: "11px", whiteSpace: "nowrap" }}>
                                    {isToday ? moment(created).format("hh:mm a") :
                                        moment(created).format("MMM DD")}
                                </Text>
                            </Flex>
                        </Flex>
                        <Div className={classes.responseInbound}>{body.replace(/\n/g, ' ')}</Div>
                    </Text>
                </Flex>
            </Flex>
        </>
    );
};

export default AlertsList;