import { DURATION_IN_MS } from "constants/time";
import isNumber from "lodash/isNumber";
import moment, { Moment } from "moment";

export interface TimeZoneType {
  label: any;
  tzCode: any;
}

const TIMEZONES: TimeZoneType[] = [
  { label: "US Pacific Time", tzCode: "America/Los_Angeles" },
  { label: "US Mountain Time", tzCode: "America/Denver" },
  { label: "US Central Time", tzCode: "America/Chicago" },
  { label: "US Eastern Time", tzCode: "America/New_York" },
];

export const getAllTimeZones = (): TimeZoneType[] => TIMEZONES;

export const getCurrentTimeZone = (): TimeZoneType => TIMEZONES[0];

export const humanizeHour = (hour?: number) =>
  isNumber(hour)
    ? hour < 12
      ? `${hour < 10 ? `0${hour}` : hour}:00 am`
      : `${hour === 12 ? hour : hour - 12}:00 pm`
    : "";

export const humanizeDifferenceFromNow = (timeInMs: number): string => {
  const differenceInMs = moment().diff(moment(timeInMs));

  if (differenceInMs >= DURATION_IN_MS.year) {
    return `${Math.floor(differenceInMs / DURATION_IN_MS.year)}y`;
  }

  if (differenceInMs >= DURATION_IN_MS.month) {
    return `${Math.floor(differenceInMs / DURATION_IN_MS.month)}mo`;
  }

  if (differenceInMs >= DURATION_IN_MS.day) {
    return `${Math.floor(differenceInMs / DURATION_IN_MS.day)}d`;
  }

  if (differenceInMs >= DURATION_IN_MS.hour) {
    return `${Math.floor(differenceInMs / DURATION_IN_MS.hour)}h`;
  }

  if (differenceInMs >= DURATION_IN_MS.minute) {
    return `${Math.floor(differenceInMs / DURATION_IN_MS.minute)}m`;
  }

  return `${Math.floor(differenceInMs / DURATION_IN_MS.second)}s`;
};

export function isDateLesserThanToday(current: Moment) {
  // Can not select days before today and today
  return current && current.diff(moment(), "days") < -1;
}

export function isDateGreaterThanToday(current: Moment) {
  // Can not select days before today and today
  return current && current > moment();
}
