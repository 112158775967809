import React, { useEffect, useState } from "react";
import { useAuth } from "auth/AuthProvider";
import AgencyRoutes from "./RouterAgency";
import UserRoutes from "./RouterUser";

const Router: React.FC<{}> = () => {

  const [isUserAgent, setUserAgent] = useState(false);
  const { user } = useAuth();
  
  useEffect(() => {
    if (user) {
      setUserAgent(isAgency(user))
    }
  }, [user]);

  const isAgency = (user: any) => {
    // return !!user?.attributes?.['custom:agency_user'];
    //return !!user?.attributes?.['custom:agency'];
    return true ;
  }

  // TODO: Get route path from constants file
  return (
    <>
      {isUserAgent ? <AgencyRoutes /> : <UserRoutes />}
    </>
  );
};

export default Router;
