import { OpenTrackingStatus } from "./OpenTrackingTypes";

export const OPEN_TRACKING_META = {
  certificate: {
    vc: {
      title: "Step 1",
      description: (domain: string) => `Create the following CNAME record on your domain (${domain}). Then wait for some time and click Validate button.`,
      helpLink: "google.com"
    },
    trackerType: "certificate" as const,
    trackerData: {},
    status: "NotStarted" as OpenTrackingStatus
  },
  gateway: {
    vc: {
      title: "Step 2",
      description: (domain: string) => `Create the following CNAME record on your domain (${domain}). Then wait for some time and click Validate button.`,
      helpLink: "google.com"
    },
    trackerType: "gateway" as const,
    trackerData: {},
    status: "NotStarted" as OpenTrackingStatus
  }
};

export const OPEN_TRACKING_STRINGS = {
  setup_open_tracking: "Setup Custom Domain Tracking",
  validate: "Validate",
  create_cname: "Create CNAME",
  error_api: "Error occurred",
  cname_name: "CNAME Name",
  cname_value: "CNAME Value",
  help: "Custom Domain Tracking Help",
  helpText: {
    titleInfo: 'If you need to learn how to create CNAME record for a specific domain provider, here are a few: ',
    domains: [
      { label: 'GoDaddy', link: 'https://www.godaddy.com/help/add-a-cname-record-19236' },
      { label: 'BlueHost', link: 'https://my.bluehost.com/cgi/help/cname' },
      { label: 'Namecheap', link: 'https://www.namecheap.com/support/knowledgebase/article.aspx/9646/2237/how-to-create-a-cname-record-for-your-domain' },
      { label: 'Network Solutions', link: 'http://www.networksolutions.com/support/cname-records-host-aliases/' }
    ]
  },
  validate_success : "Operation completed successfully."
};
export const OPEN_TRACKING_STEPS = ["certificate", "gateway"] as const;
