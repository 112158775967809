import styled from "styled-components";

export interface TextProps {
  flex?: boolean;
  block?: boolean;
  hoverColor?: string;
  variant:
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "sub1"
  | "sub2"
  | "body1"
  | "body2"
  | "caption"
  | "overline";
  color?: "primary" | "secondary" | "error" | "success" | "white";
  mb?: number;
  mt?: number;
  ml?: number;
  mr?: number;
  mx?: number;
  my?: number;
  m?: number;
  pb?: number;
  pt?: number;
  pl?: number;
  pr?: number;
  px?: number;
  py?: number;
  p?: number;
  align?: "center" | "left" | "right";
  bold?: boolean;
  uppercase?: boolean;
  ellipsis?: boolean;
}
export const Text = styled.div<TextProps>`
  display: ${({ flex = false, block = false, variant }) =>
    flex ? "flex" : block ? "block" : "inline-block"};
  text-align: ${({ align = "left" }) => align};
  margin: ${({ mx = 0, my = 0, m = 0 }) => (m ? `${m}px` : `${my}px ${mx}px`)};
  margin-bottom: ${({ mb = 0 }) => `${mb}px`};
  margin-left: ${({ ml = 0 }) => `${ml}px`};
  // background-color: ${(props) => (props.theme.headerColor)};
  margin-top: ${({ mt = 0 }) => `${mt}px`};
  margin-right: ${({ mr = 0 }) => `${mr}px`};
  padding: ${({ px = 0, py = 0, p = 0 }) => (p ? `${p}px` : `${py}px ${px}px`)};
  padding-bottom: ${({ pb = 0 }) => `${pb}px`};
  padding-left: ${({ pl = 0 }) => `${pl}px`};
  padding-top: ${({ pt = 0 }) => `${pt}px`};
  padding-right: ${({ pr = 0 }) => `${pr}px`};
  color: ${({ color, theme }) =>
    color === "primary"
      ? theme.antdConf.primaryColor
      : color === "secondary"
        ? theme.textColorSecondary
        : color === "success"
          ? theme.antdConf.successColor
          : color === "error"
            ? theme.antdConf.errorColor
            : color === "white"
              ? theme.whiteColor
              : theme.textColor};
  ${({ variant }) => {
    switch (variant) {
      case "h1":
        return `
            font-size: 96px;
            font-weight: 100;
            letter-spacing: -1.5px;`;
      case "h2":
        return `  
            font-size: 59px;
            font-weight: 100;
            letter-spacing: -0.5px;  
            `;
      case "h3":
        return ` 
            font-size: 48px;
            font-weight: 400;
            letter-spacing: 0px;     
            `;
      case "h4":
        return `  
            font-size: 34px;
            font-weight: 400;
            letter-spacing: 0.25px;    
            `;
      case "h5":
        return `
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 0px;      
            `;
      case "h6":
        return `
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.15px;      
            `;
      case "sub1":
        return `
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.15px;   
            `;
      case "sub2":
        return `
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.1px;    
            `;
      case "body2":
        return `
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.25px;     
            `;
      case "caption":
        return `
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.4px;     
            `;
      case "overline":
        return `
            font-size: 10px;
            font-weight: 400;
            letter-spacing: 1.5px;
            text-transform: uppercase;  
            `;
      case "body1":
      default:
        return `    
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.5px;`;
    }
  }};
  ${({ bold = false }) => bold && "font-weight: 600;"}
  &:hover {
    ${({ hoverColor: hc }) => (hc ? `color: ${hc};` : "")}
  }
  ${({ uppercase = false }) => uppercase && "text-transform: uppercase;"}
  ${({ ellipsis = false }) =>
    ellipsis
      ? `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;`
      : ""}
`;
