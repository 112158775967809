import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo
} from "react";
import { Flex } from "styles/layout";
import DonotContact from "components/Settings/DoNotContactnew";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import { notification } from "antd";
import AppContext from "contexts/appContext";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { useLocation } from 'react-router-dom'
import { NavPathType } from "components/AppBar";

interface DoNotContactsPageProps {
  setNavPaths: SetNavPathsFn;
}

const DoNotContactContainer = ({ setNavPaths }: DoNotContactsPageProps) => {
  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(AppContext);
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const location = useLocation();

  const getAccountName = useMemo((): any => {
    return location.state;
  }, []);

  useEffect(() => {
    if (getAccountName && Object.keys(getAccountName).length) {
      setAccountData({
        accountId: getAccountName.accountId,
        accountName: getAccountName.name
      });
    } else {
      setAccountData({
        accountId: String(localStorage.getItem("account_id")),
        accountName: String(localStorage.getItem("accountName"))
      });
    }
  }, [getAccountName, setAccountData]);


  useEffect(() => {
    const navPaths: NavPathType[] = [];
    if (accountData) {
      navPaths.push({
        label: accountData.accountName,
        linkTo: `/account/${accountData.accountId}/campaigns`,
      },
        { label: "Do Not Contact List" }
      );
    }

    setNavPaths(navPaths);
    return () => setNavPaths([]);
  }, [setNavPaths, location, accountData]);


  return (
    <Flex mt={10} direction="column" >
      <DonotContact />
    </Flex>
  );
};

export default withAppBar(DoNotContactContainer);