import { Button, Modal, Input } from "antd";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";

const { TextArea } = Input;

const ReponseSkipModal = ({
    responseSkipValue,
    saveResponseSkipCodes,
    onChangeResponseSkip,
    handleModal
}: any) => {
    return (
        <>
            <Modal
                visible
                onCancel={() => handleModal()}
                centered
                footer={null}
                style={{ width: "600px" }}
            >
                <Text variant="h6">Response Skip Codes</Text>
                <Flex mt={5}>
                    <Text variant="caption" color="secondary" style={{ "textAlign": 'justify' }}>
                        Enter one or more words or codes which will be used by the inbound filtering process. If any of the replies (subject or body) match with any of these words or codes, those messages will be automatically skipped and won't be pulled into emy.
                    </Text>{" "}
                </Flex>
                <Div>
                    <TextArea
                        rows={8}
                        value={responseSkipValue}
                        onChange={(e) => onChangeResponseSkip(e.target.value)}

                        style={{ marginTop: "30px" }}
                    />
                    <Flex mt={5}>
                        <Text variant="caption" color="secondary">
                            Enter multiple codes in separate lines
                        </Text>{" "}
                    </Flex>
                    <Div mt={30} style={{ textAlign: "right" }}>
                        <Button onClick={() => handleModal()}>
                            Cancel
                        </Button>
                        <Button type="primary" style={{ marginLeft: "10px" }} onClick={saveResponseSkipCodes}>
                            Save
                        </Button>
                    </Div>
                </Div>
            </Modal>
        </>
    );
};

export default ReponseSkipModal;
