import styled from "styled-components";
import isNumber from "lodash/isNumber";

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

export const Label = styled.label<{
  bold?: boolean;
  mb?: number;
  mw?: number | string;
}>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin-bottom: ${({ mb = 0 }) => `${mb}px`};
  min-width: ${({ mw = 0 }) => (isNumber(mw) ? `${mw}px` : mw)};
  line-height: 30px;
`;

export const FormControlWrapper = styled.div<{
  flex?: boolean;
}>`
  display: ${({ flex = false }) => (flex ? "flex" : "block")};
  align-items: center;
`;