import { ReactNode, useCallback, useEffect, useState } from "react";
import { MailboxProtocolEnum, MailboxTypeEnum } from "types/MailBox";
import MailBoxIcon from "components/MailBox/Icon";
import { Flex, Div } from "styles/layout";
import { Text } from "styles/typography";
import { getColorWithOpacity, BOX_SHADOW } from "utils/colors";
import { Col, FormInstance, Input, notification, Row, Button } from "antd";
import { Form } from "antd";
import { useAuth } from "auth/AuthProvider";
import Modal from "antd/lib/modal/Modal";

export interface MailBoxProviderProps {
  onContinue: (mailboxType: MailboxTypeEnum, email: string) => void;
  form: FormInstance;
  setProviders: (type?: any) => void;
  submitBtn?: (type?: any) => void;
  setEmail?: (email: string) => void
}

const MailProviderCard = ({
  children,
  onClick,
  selected = false,
  mr,
  ml,
  disabled = false
}: {
  children: ReactNode;
  onClick: () => void;
  selected?: boolean;
  mr?: number;
  ml?: number;
  disabled?: boolean;
}) => {
  const { theme } = useAuth();

  return (
    <Flex
      p="10px 10px"
      w={210}
      align="center"
      // justify="center"
      borderColor={disabled ? theme.darkGrayColor : selected ? theme.antdConf.primaryColor : theme.blackColor}
      hoverBorderColor={disabled ? "unset" : theme.antdConf.primaryColor}
      hoverBgColor={disabled ? "unset" : getColorWithOpacity(theme.antdConf.primaryColor, 20)}
      cursor={disabled ? "not-allowed" : "pointer"}
      onClick={onClick}
      borderWidth={1}
      mr={mr}
      ml={ml}
      style={{
        boxShadow: disabled ? "unset" : BOX_SHADOW,
        opacity: disabled ? 0.6 : 1,
        minHeight: "72px"
      }}
    >
      {/* <Flex h={15} w={15} style={{ border: `2px solid ${theme.darkGrayColor}` }} br={10} mr={10} align="center" justify="center">
        <Div w={7} h={7} br={5} bgColor={selected ? theme.antdConf.primaryColor : theme.whiteColor} />
      </Flex> */}
      {children}
    </Flex>
  );
};

export const PROVIDERS = [
  {
    name: "Microsoft",
    type: MailboxTypeEnum.OFFICE_OAUTH,
    subText: "Office/Personal",
    disabled: false,
    protocol: MailboxProtocolEnum.OAUTH,
    providerName: "MICROSOFT",
    smtpImapDetailsKnown: true
  },
  // {
  //   name: "Microsoft (SMTP)",
  //   type: MailboxTypeEnum.OFFICE_SMTP,
  //   subText: "Outlook/Office",
  //   disabled: false,
  //   protocol: MailboxProtocolEnum.SMTP_IMAP,
  //   providerName: "MICROSOFT",
  //   smtpImapDetailsKnown: true
  // },
  // {
  //   name: "Microsoft",
  //   type: MailboxTypeEnum.OFFICE_BROWSER,
  //   subText: "Browser",
  //   disabled: false,
  //   protocol: MailboxProtocolEnum.BROWSER,
  //   providerName: "MICROSOFT",
  //   smtpImapDetailsKnown: true
  // },
  {
    name: "Google",
    type: MailboxTypeEnum.DUMMY,
    subText: "Workspace/Personal" /* smtp */,
    disabled: false,
    protocol: '',
    providerName: "GOOGLE",
    smtpImapDetailsKnown: true,
    isMutipleCase: true
  },
  {
    name: "Google (SMTP)",
    type: MailboxTypeEnum.GMAIL,
    subText: "Workspace" /* smtp */,
    disabled: false,
    protocol: MailboxProtocolEnum.SMTP_IMAP,
    providerName: "GOOGLE",
    smtpImapDetailsKnown: true,
    isHidden: true
  },
  {
    name: "Google (Browser)",
    type: MailboxTypeEnum.GMAILONE,
    subText: "Personal" /* brooklyn */,
    disabled: false,
    protocol: MailboxProtocolEnum.BROWSER,
    providerName: "GOOGLE",
    smtpImapDetailsKnown: true,
    isHidden: true
  },
  {
    name: "Google (OAUTH)",
    type: MailboxTypeEnum.GSUITE,
    subText: "Workspace" /* smtp */,
    disabled: false,
    protocol: MailboxProtocolEnum.OAUTH,
    providerName: "GOOGLE",
    smtpImapDetailsKnown: true,
    isHidden: false,
    googleType: MailboxTypeEnum.GSUITE
  },
  // {
  //   name: "Amazon",
  //   type: MailboxTypeEnum.AMAZON,
  //   subText: "SMTP",
  //   disabled: false,
  //   protocol: MailboxProtocolEnum.SMTP_IMAP,
  //   providerName: "AMAZONSES",
  //   smtpImapDetailsKnown: false
  // },
  // {
  //   name: "TechCompiler",
  //   type: MailboxTypeEnum.TECHCOMPILER,
  //   subText: "SMTP",
  //   disabled: false,
  //   protocol: MailboxProtocolEnum.SMTP_IMAP,
  //   providerName: "TECHCOMPILER",
  //   smtpImapDetailsKnown: false
  // },
  {
    name: "Sendgrid",
    type: MailboxTypeEnum.SENDGRID,
    subText: "SMTP",
    disabled: false,
    protocol: MailboxProtocolEnum.SMTP_IMAP,
    providerName: "SENDGRID",
    smtpImapDetailsKnown: false
  },
  {
    name: "Mailgun",
    type: MailboxTypeEnum.MAIL_GUN,
    subText: "SMTP",
    disabled: false,
    protocol: MailboxProtocolEnum.SMTP_IMAP,
    providerName: "MAILGUN",
    smtpImapDetailsKnown: false
  },
  {
    name: "Custom Mail",
    type: MailboxTypeEnum.CUSTOMMAIL,
    subText: "SMTP",
    disabled: false,
    protocol: MailboxProtocolEnum.SMTP_IMAP,
    providerName: "SMTP",
    smtpImapDetailsKnown: false
  }
];

export const getProviderDetails = (providerType: string) => {
  return PROVIDERS.find((prv) => prv.type === providerType);
};

export const getProviderAndProtocol = (providerType: string) => {
  const provider = getProviderDetails(providerType);
  return {
    provider: provider?.providerName,
    protocol: provider?.protocol
  };
};

const MailBoxProvider = ({ onContinue, form, setProviders, submitBtn, setEmail }: MailBoxProviderProps) => {
  const [provider, setProvider] = useState<MailboxTypeEnum | null>(null);
  const [caseCheckModal, openCaseCheckModal] = useState<boolean>(false);
  const [emailCheck, setEmailToCheckCase] = useState<string>('');
  const [PROVIDER_ARR, setPROVIDER_ARR] = useState<any>(PROVIDERS.filter((vl) => !vl.isHidden));
  const [state, setState] = useState<any>({ emailCheckErr: '' })

  const getOnClickMailBoxCard = useCallback(
    (type: MailboxTypeEnum, disabled: boolean, subText: string) => () => {
      if (disabled) return;
      if (subText === 'Workspace/Personal') {
        openCaseCheckModal(true)
      } else {
        setProvider(type);
        setProviders(type);
        setEmail && setEmail(emailCheck)
        submitBtn && submitBtn(type)
      }
    },
    [setProvider, setProviders]
  );

  useEffect(() => {
    form.resetFields();
  }, [form]);

  // useEffect(() => {
  //   if (window.location) {
  //     if (window.location.origin.includes('app')) {
  //       let values = PROVIDERS.filter(pr => pr.type !== MailboxTypeEnum.GMAILONE)
  //       setPROVIDER_ARR(values)
  //     }
  //   }
  // }, []);

  const onSubmit = useCallback(
    ({ email }: { email: string }) => {
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>", email)
      if (!provider) {
        notification.error({ message: "Please select a provider." });
        return;
      }
      onContinue(provider, email);
    },
    [onContinue, provider]
  );

  // const toggleShowModal = () => setShowConnectMailboxModal((show) => !show);

  const ValidateEmail = (input: string) => {
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  const checkEmailCaseWithProviders = () => {
    let findProvider = null
    if (emailCheck && ValidateEmail(emailCheck)) {
      if (/@gmail\.com$/.test(emailCheck)) {
        // This is a gmail id.
        findProvider = PROVIDERS.find((vl) => vl?.subText === 'Personal')
      } else {
        findProvider = PROVIDERS.find((vl) => vl?.subText === 'Workspace')
      }
      if (findProvider) {
        setProvider(findProvider?.type);
        setProviders(findProvider?.type);
        setState((prev: any) => ({ ...prev, emailCheckErr: '' }))
        submitBtn && submitBtn()
        setEmail && setEmail(emailCheck)

        openCaseCheckModal(false)
      }
    } else {
      setState((prev: any) => ({ ...prev, emailCheckErr: 'Please enter valid email' }))
    }
  }
  const renderEmailInput = () => {
    if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
      return (
        <>
          <Text variant="overline" mb={5} color="secondary">
            Email
          </Text>
          <Flex direction="row">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter valid email"
                },
                {
                  required: true,
                  message: ""
                }
              ]}
            >
              <Input type="email" style={{ width: 300 }} />
            </Form.Item>
          </Flex>
        </>
      );
    }
  };

  return (
    <>
      < Form form={form} onFinish={onSubmit} >
        <Text variant="h6" mt={10} mb={40} block>
          Select your mailbox provider to connect
        </Text>

        <Row gutter={[16, 16]} style={{ marginBottom: "1em" }}>
          {PROVIDER_ARR.map(({ name, type, subText, disabled }: any, index: number) => (
            <Col key={type} span={8}>
              <MailProviderCard key={type} onClick={getOnClickMailBoxCard(type, disabled, subText)} disabled={disabled} selected={type === provider}>
                <MailBoxIcon type={type} />
                <Flex direction="column" ml={10}>
                  <Text variant="body2">{name}</Text>
                  <Text variant="caption" color="secondary" block align="left">
                    {subText}
                  </Text>
                </Flex>
              </MailProviderCard>
            </Col>
          ))}
        </Row>

      </Form >
      {caseCheckModal && <Modal visible
        okButtonProps={{
          type: "primary",
        }}
        okText="Next"
        cancelText="Cancel"
        onCancel={() => openCaseCheckModal(false)}
        onOk={() => {
          checkEmailCaseWithProviders()
        }}
        width={400} maskClosable={false}>
        <Div style={{ marginTop: "25px" }}>
          <label className="fs-12 fw-600">Email *</label>
          <Div  >
            <Input tabIndex={1} size="middle" onChange={(e) => setEmailToCheckCase(e?.target?.value)} />
            {state?.emailCheckErr && <p>{state?.emailCheckErr}</p>}
          </Div>
        </Div>
      </Modal>}
    </>
  );
};

export default MailBoxProvider;