import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";

import { useAuth } from "auth/AuthProvider";
import { NavPathType } from "components/AppBar";
import { useLocation } from "react-router-dom";

import TagV2 from "pages/CampaignDetail/TagV2";
interface Uniboxprops {
  campaignId: string;
  levelType: string;
  setNavPaths?: SetNavPathsFn;
}
const UniboxPage = ({ campaignId, levelType, setNavPaths }: Uniboxprops) => {
  const { user } = useAuth();
  const isAgency = (user: any) => {
    return true;
  };
  const location = useLocation();
  const [isUserAgent, setUserAgent] = useState(false);
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const getAccountName = useMemo((): any => {
    return location.state;
  }, []);

  useEffect(() => {
    if (user) {
      setUserAgent(isAgency(user));
    }
  }, [user]);

  useEffect(() => {
    if (levelType && levelType) {
      if (levelType === "ACCOUNT") {
        if (getAccountName && Object.keys(getAccountName).length) {
          setAccountData({
            accountId: getAccountName.accountId,
            accountName: getAccountName.name
          });
        } else {
          setAccountData({
            accountId: String(localStorage.getItem("account_id")),
            accountName: String(localStorage.getItem("accountName"))
          });
        }
      } else {
        localStorage.removeItem("account_id");
        localStorage.removeItem("accountName");
        setAccountData({
          accountId: "",
          accountName: ""
        });
      }
    }
  }, [getAccountName, setAccountData, levelType]);

  useEffect(() => {
    const navPaths: NavPathType[] = [];

    if (isUserAgent) {
      if (accountData && accountData.accountId.length) {
        navPaths.push({
          label: accountData.accountName /* || localStorage.getItem('accountName') */,
          linkTo: `/account/${accountData.accountId}/campaigns`
        });
      }
    }

    if (location?.pathname.includes("/unibox")) {
      navPaths.push({ label: "Unibox" });
      setNavPaths && setNavPaths(navPaths);
    }

    return () => setNavPaths && setNavPaths([]);
  }, [setNavPaths, location?.pathname, accountData.accountId, isUserAgent]);
  return (
    <>
      <div className="unibox__page">
        <TagV2 campaignId={campaignId} accountId={accountData.accountId} levelType={levelType} accountName={accountData?.accountName} isFromUnibox={true} />
      </div>
    </>
  );
};

export default withAppBar(UniboxPage);
