import React from "react";
import { Drawer, Badge } from "antd";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import { BOX_SHADOW } from "utils/colors";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { notificationsSelector } from "state/selectors";
import { useAuth } from "auth/AuthProvider";

type NotificationsDrawerProps = {
  onClose: () => void;
  visible: boolean;
};

const Notification = ({ name, count, id }: { name: string; count: number; id: string }) => {
  const { theme } = useAuth();

  return (
    <Link to={`/campaigns/${id}`}>
      <Flex
        hoverBorderColor={theme.antdConf.primaryColor}
        borderColor={theme.darkGrayColor}
        style={{
          margin: 10,
          cursor: "pointer",
          boxShadow: BOX_SHADOW
        }}
        p={10}
        br={5}
      >
        <Flex align="center" p={3} mr={10}>
          <MailOutlined style={{ fontSize: 20 }} />
          <Badge dot count={count} offset={[-6, -7]}></Badge>
        </Flex>
        <Flex direction="column">
          <Text variant="sub2">
            <Text variant="sub2" bold>
              {count} unread
            </Text>{" "}
            Mails
          </Text>
          <Text variant="sub2">{name}</Text>
        </Flex>
      </Flex>
    </Link>
  );
};

const NotificationsDrawer = ({ visible, onClose }: NotificationsDrawerProps) => {
  const notifications = useSelector(notificationsSelector);

  return (
    <Drawer
      title="Notifications"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      bodyStyle={{
        padding: 0
      }}
      width={300}
    >
      {notifications
        .filter(({ unreadMailCount }) => unreadMailCount > 0)
        .map(({ campaignName, unreadMailCount, campaignId }) => (
          <Notification name={campaignName} count={unreadMailCount} id={campaignId} key={campaignId} />
        ))}
    </Drawer>
  );
};

export default NotificationsDrawer;
