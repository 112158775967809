import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useApi from "hooks/useApi";
import { Div, Flex } from "styles/layout";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import AppContext from "contexts/appContext";
import { API_ENDPOINTS, CAMPAIGN_SORTABLE_FIELDS } from "constants/api";
import { Button, Row, Col, notification, Tabs, Select, Pagination, Input } from "antd";
import NoCampaigns from "components/Campaign/NoCampaigns";
import CampaignCard from "components/Campaign/Card";
import { getCampaignStatusEnum, getCampaignStatusText, getCampaignResponseText } from "utils/campaign";
import NewCampaignFlow from "./NewCampaignFlow";
import LocalStorage from "utils/localstorage";
import { NEW_CAMPAIGN_LS_KEY } from "constants/localstorage";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { Text } from "styles/typography";
import { CampaignStatusType, CampaignSummaryType } from "types/Campaign";
import { Moment } from "moment";
import {
  AimOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  CarryOutOutlined,
  PlusOutlined,
  UngroupOutlined
} from "@ant-design/icons";
import ChangeCampaignStatusModal from "components/Campaign/ChangeCampaignStatusModal";
import { useAuth } from "auth/AuthProvider";
import { AccountSummaryType } from "types/Accounts";
import { NavPathType } from "components/AppBar";
import { MailboxToggleView, MailboxViewEnum } from "pages/Settings/ViewMode";
import { LOCAL_STORAGE_MAP, useLocalStorage } from "hooks/useLocalStorage";
import { CampaignTableView } from "components/Campaign/Card/campaignTable";

interface CampaignPageProps {
  setNavPaths: SetNavPathsFn;
}
const CampaignsPage = ({ setNavPaths }: CampaignPageProps) => {
  const { user } = useAuth();
  const [isUserAgent, setIsUserAgent] = useState(false);
  const isNewCampaignOngoing = !!LocalStorage.getValue(NEW_CAMPAIGN_LS_KEY);
  const [campaigns, setCampaigns] = useState<CampaignSummaryType[]>([]);
  const [areCampaignsLoading, setAreCampaignsLoading] = useState(true);
  const [timeZone, setTimeZone] = useState("");
  const { setLoadingMessage } = useContext(AppContext);
  const location = useLocation();

  const getPreviousFilter = localStorage.getItem('CampaignCurrentTab');
  const [statusFilter, setStatusFilter] = useState(getPreviousFilter || "Active");

  const [account, setAccount] = useState<AccountSummaryType>();
  const [accountName, setAccountName] = useState("");
  const match = useRouteMatch<{ id: string }>();
  const [showNewCampaignModal, setShowNewCampaignModal] = useState(isNewCampaignOngoing);
  const { callApi } = useApi();
  let history = useHistory();
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignSummaryType | null>(null);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [sort, setSort] = useState({
    direction: "DESC",
    field: CAMPAIGN_SORTABLE_FIELDS[0]
  });
  const [mailboxViewMode, setMailboxViewMode] = useLocalStorage(
    LOCAL_STORAGE_MAP.MAILBOX_VIEW_MODE,
    MailboxViewEnum.CARD
  );
  const [pageMeta, setPageMeta] = useState({ searchText: "" });
  const paginatedList = useMemo(() => {
    const fL = campaigns;
    return pageMeta.searchText
      ? fL.filter((el) => el.name.toLowerCase().includes(pageMeta.searchText.toLowerCase()))
      : fL;
  }, [pageMeta, campaigns]);

  // const isAgency = (user: any) =>  !!user?.attributes?.["custom:agency"] ;
  const isAgency = (user: any) => true;

  const fetchCampaigns = useCallback(async () => {
    setAreCampaignsLoading(true);
    if (!showNewCampaignModal) {
      // setLoadingMessage("Fetching your campaigns ...");
    }
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getListUrl(statusFilter);

    const response = await callApi({
      url,
      method
    });

    if (response.success) {
      setCampaigns(response.data.campaigns);
    }

    setAreCampaignsLoading(false);
  }, [statusFilter, callApi, showNewCampaignModal]);

  const fetchAccounts = useCallback(async () => {
    const {
      params: { id }
    } = match;
    const { url, method } = API_ENDPOINTS.ACCOUNTS.getAccountUrl(id);
    const response = await callApi({
      url,
      method
    });
    console.log("response", response)
    if (response.success) {
      setAccount(response.data.account);
      setAccountName(response.data.account.name);
      localStorage.setItem("account_id", String(response.data.account.id));
      localStorage.setItem("accountName", String(response.data.account.name));
      localStorage.setItem("accountTimezone", String(response.data.account.timezone));
    }
  }, [match, setAccount, callApi]);

  const fetchAccountCampaigns = useCallback(async () => {
    setAreCampaignsLoading(true);
    const {
      params: { id }
    } = match;
    // setLoadingMessage("Fetching your campaigns ...");
    const { url, method } = API_ENDPOINTS.ACCOUNTS.getDetailUrlByFilter(id, statusFilter);
    const response = await callApi({
      url,
      method
    });

    if (response.success) {
      setCampaigns(response.data.campaigns);
    }
    //setLoadingMessage("");
    setAreCampaignsLoading(false);
  }, [callApi, statusFilter]);

  useEffect(() => {
    const agency = isAgency(user);

    if (agency || match.path.includes("/account")) {
      // if (!campaigns || !campaigns.length) {
      fetchAccountCampaigns();
      //}
    } else {
      fetchCampaigns();
    }
  }, [user, statusFilter]);

  useEffect(() => {
    if (user) {
      setIsUserAgent(isAgency(user));
      fetchAccounts();
    }
  }, [user]);

  useEffect(() => {
    const navPaths: NavPathType[] = [];
    // const campaignNavpath = (history.location.state) ? null : 'Campaigns';
    if (isUserAgent) {
      // navPaths.push({ label: "Accounts", linkTo: "/accounts" });
      if (account) {
        const {
          params: { id }
        } = match;

        navPaths.push(
          {
            label: accountName,
            linkTo: `/account/${id}/campaigns`
          },
          {
            label: "Campaigns"
          }
        );
        /* if (!location.state) {
            navPaths.push(
              {
                label: accountName,
                linkTo: `/account/${id}/campaigns`
              },
              {
                label: "Campaigns"
              }
            );
          } else {
            navPaths.push({
              label: accountName,
              linkTo: `/account/${id}/campaigns`
            }, {
              label: "Campaigns"
            });
          }*/
      }
    } else {
      navPaths.push({ label: "Campaigns", linkTo: "/campaigns" });
    }

    setNavPaths(navPaths);
    return () => setNavPaths([]);
  }, [isUserAgent, account, match, accountName, setNavPaths]);

  const showCampaignsPlaceholder = useMemo(
    () => !areCampaignsLoading && campaigns.length === 0,
    [areCampaignsLoading, campaigns]
  );

  const onChangeStatusFilter = (newStatusFilter: string) => {
    setStatusFilter(newStatusFilter);
    localStorage.setItem('CampaignCurrentTab', newStatusFilter)
  };

  const onDismissStatusChangeModal = () => setSelectedCampaign(null);

  const onCampaignStatusChange = useCallback(async () => {
    if (!selectedCampaign) {
      return;
    }

    const active = selectedCampaign.status === "Active";

    if (!active && !startDate) {
      notification.error({
        message: "Please select start date"
      });
      return;
    }
    if (!active && (timeZone === undefined || timeZone === "")) {
      notification.error({
        message: "Please select campaign timezone."
      });
      return;
    }

    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getStatusChangeUrl(selectedCampaign.campId);
    setLoadingMessage("Changing the campaign status ...");

    let payload: {
      status: CampaignStatusType;
      startDate?: string;
      timezone?: string;
    } = {
      status: active ? "Inactive" : "Active"
    };

    if (startDate) {
      payload.startDate = startDate.format("MM/DD/YYYY HH:mm");
    }
    if (timeZone) {
      payload.timezone = timeZone;
    }

    const response = await callApi({ url, method, data: payload });

    if (response.success) {
      notification.success({
        message: "Success",
        description: `Campaign ${getCampaignResponseText(!active)}d successfully!`
      });
      fetchCampaigns();
      onDismissStatusChangeModal();
    }

    setLoadingMessage("");
  }, [selectedCampaign, startDate, timeZone, setLoadingMessage, callApi, fetchCampaigns]);

  const getOnClickStatusSwitch = (c: CampaignSummaryType) => () => {
    setStartDate(null);
    setSelectedCampaign(c);
  };

  const toggleNewCampaignModal = () => setShowNewCampaignModal((show) => !show);
  const getOnClick = (id: string) => () => {
    if (isUserAgent) {
      history.push({
        pathname: `/campaigns/${id}`,
        state: {
          user: "agency",
          name: accountName,
          accountId: match.params.id
        }
      });
    } else {
      history.push(`/campaigns/${id}`);
    }
  };
  const toggleSortDirection = () =>
    setSort((prev) => ({
      ...prev,
      direction: prev.direction === "DESC" ? "ASC" : "DESC"
    }));
  const onChangeSortField = (field: string) => setSort((prev) => ({ ...prev, field }));

  return (
    <>
      <Flex justify="space-between" align="center">
        <Tabs defaultActiveKey={statusFilter} onChange={onChangeStatusFilter}>
          <Tabs.TabPane
            tab={
              <span>
                <AimOutlined />
                Active Campaigns
              </span>
            }
            key="Active"
          />
          <Tabs.TabPane
            tab={
              <span>
                <UngroupOutlined />
                Inactive Campaigns
              </span>
            }
            key="Inactive"
          />
          <Tabs.TabPane
            tab={
              <span>
                <CarryOutOutlined />
                Completed Campaigns
              </span>
            }
            key="Completed"
          />
        </Tabs>
        {!showCampaignsPlaceholder && (
          <Flex align="flex-end">
            {!!campaigns.length && (
              <Flex align="center" gap={15} mr={10}>
                <Input
                  placeholder="Search"
                  onChange={(e) => setPageMeta((prev) => ({ ...prev, searchText: e.target.value }))}
                />
              </Flex>
            )}
            <Flex mr={10}>
              <MailboxToggleView active={mailboxViewMode} onChange={setMailboxViewMode} />
            </Flex>

            <Flex align="flex-end" direction="column" mr={10}>
              <Flex direction="column">
                <Text variant="overline" mb={5}>
                  Sort by
                </Text>
                <Flex>
                  <Button
                    type="ghost"
                    onClick={toggleSortDirection}
                    style={{ padding: 0, marginRight: 0 }}
                    icon={sort.direction === "DESC" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                  ></Button>
                  <Select value={sort.field} onChange={onChangeSortField} style={{ width: 150 }}>
                    {CAMPAIGN_SORTABLE_FIELDS.map((field) => (
                      <Select.Option value={field} key={field}>
                        {field}
                      </Select.Option>
                    ))}
                  </Select>
                </Flex>
              </Flex>
            </Flex>

            <Button type="primary" icon={<PlusOutlined />} onClick={toggleNewCampaignModal}>
              Add Campaign
            </Button>
          </Flex>
        )}
      </Flex>

      <Div mt={10} mb={20}>
        {mailboxViewMode === MailboxViewEnum.CARD ? (
          <Row gutter={[10, 10]}>
            {(areCampaignsLoading ? Array(6).fill(0) : paginatedList).map((campaign, idx) => (
              <Col span={8} key={campaign.campId ?? idx}>
                <CampaignCard
                  onClick={getOnClick(campaign.campId)}
                  name={campaign.name}
                  imageName={campaign.imageName}
                  prospectsCount={campaign.activeRecipients}
                  lastEvent={{
                    name: "Email sent",
                    time: campaign.lastSentTime
                  }}
                  repliesCount={campaign.mailboxes}
                  status={getCampaignStatusEnum(campaign.status)}
                  onStatusChange={getOnClickStatusSwitch(campaign)}
                  loading={areCampaignsLoading}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <CampaignTableView
            pagination={false}
            dataSource={paginatedList}
            loading={areCampaignsLoading}
            onClick={(campId) => getOnClick(campId)()}
            onStatusChange={(camp) => getOnClickStatusSwitch(camp)()}
          />
        )}
      </Div>
      {showCampaignsPlaceholder && (
        <Flex justify="center" w="100%" mt={50}>
          <NoCampaigns onAdd={toggleNewCampaignModal} activeTabsTitle={statusFilter} />
        </Flex>
      )}
      {showNewCampaignModal && <NewCampaignFlow onCancel={toggleNewCampaignModal} isFromAddCampaign={true} afterSave={() => { }} />}
      {!!selectedCampaign && (
        <ChangeCampaignStatusModal
          onOk={onCampaignStatusChange}
          onCancel={onDismissStatusChangeModal}
          name={selectedCampaign.name}
          detail={false}
          timeZone={selectedCampaign.timezone ? selectedCampaign.timezone : ""}
          setTimeZone={setTimeZone}
          active={selectedCampaign.active}
          status={selectedCampaign.status}
          setStartDate={setStartDate}
          startDate={startDate}
          campId={selectedCampaign?.campId}
        />
      )}
    </>
  );
};

export default withAppBar(CampaignsPage);
