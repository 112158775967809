import React, { useState, useCallback } from "react";
import WYSIWYGEditor from "components/Froala/Editor";
import { InlineEditorWrapper } from "components/Spinners/SpinnerInput/styles";
import { ProspectVariableType } from "types/Prospect";

export interface SubjectInputProps {
  value: string;
  onChange: (value: string) => void;
  variables?: ProspectVariableType[];
  spinners?: { label: string; value: string }[];
  quicklinks?: ProspectVariableType[];
  isSubjectDisable:boolean;
}

const SubjectInput = ({
  value,
  onChange,
  variables = [],
  quicklinks = [],
  spinners = [],
  isSubjectDisable,
}: SubjectInputProps) => {
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  const onBlur = useCallback(() => setIsEditorFocused(false), [
    setIsEditorFocused,
  ]);

  const onFocus = useCallback(
    () => setIsEditorFocused(true),

    [setIsEditorFocused]
  );

  return (
    <InlineEditorWrapper focus={isEditorFocused}>
      <WYSIWYGEditor
        editorClass="outwin-inline-editor"
        tag="textarea"
        value={value}
        onChange={onChange}
        config={{
          height: 24,
          attribution: false,
          toolbarBottom: true,
          placeholderText: `Subject`,
          pastePlain: true,
          toolbarInline: true,
          htmlAllowedTags: ["p"],
          multiLine: false,
          charCounterMax: -1,
          events: {
            blur: onBlur,
            focus: onFocus,
          },
          toolbarVisibleWithoutSelection: true,
        }}
        moreRichButtons={[]}
        moreTextButtons={[]}
        variables={variables}
        spinners={spinners}
        quicklinks={quicklinks}
      />
    </InlineEditorWrapper>
  );
};

export default SubjectInput;
