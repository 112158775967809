import {
  FETCH_CAMPAIGN_PROSPECTS_VARIABLES,
  FETCH_CAMPAIGN_PROSPECTS_VARIABLES_SUCCESS,
} from "state/actions/actionTypes";

const reducer = (state: any = {}, action: { type: string; payload: any }) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_PROSPECTS_VARIABLES: {
      const { campaignId } = action.payload;
      return {
        ...state,
        [campaignId]: {
          entities: [],
          loading: true,
        },
      };
    }
    case FETCH_CAMPAIGN_PROSPECTS_VARIABLES_SUCCESS: {
      const { campaignId, variables } = action.payload;
      return {
        ...state,
        [campaignId]: {
          ...state[campaignId],
          entities: variables,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
