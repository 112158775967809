import { API_ENDPOINTS } from "constants/api";
import { Dispatch } from "redux";
import {
  FETCH_CAMPAIGN_VARIABLES,
  FETCH_CAMPAIGN_VARIABLES_SUCCESS,
} from "./actionTypes";
import Axios from "utils/axios";

class CampaignVariableActions {
  static fetch = (campaignId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_CAMPAIGN_VARIABLES,
      payload: { campaignId },
    });

    const { url, method } = API_ENDPOINTS.CAMPAIGNS.getListVariablesUrl(
      campaignId
    );

    try {
      const response = await Axios.callApi({
        url,
        method,
      });

      if (response.success) {
        dispatch({
          type: FETCH_CAMPAIGN_VARIABLES_SUCCESS,
          payload: { variables: response.data || [], campaignId },
        });
      }
    } catch (err) {}
  };
}

export default CampaignVariableActions;
