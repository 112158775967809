import { useEffect, useState } from 'react'

export default function useLocalStorage(key: any, initValue: any) {
    const [state, setState] = useState(() => {
        const value = localStorage.getItem(key);
        if (value !== null) {
            return value
        }

        localStorage.setItem(key, initValue);
        window.dispatchEvent(new Event("storage"));
        return initValue;
    });

    useEffect(() => {
        localStorage.setItem(key, state);
        window.dispatchEvent(new Event("storage"));
    }, [key, state]);

    useEffect(() => {
        const listenStorageChange = () => {
            setState(() => {
                const value = localStorage.getItem(key);
                if (value !== null) {
                    return value
                }

                localStorage.setItem(key, initValue);
                window.dispatchEvent(new Event("storage"));
                return initValue;
            });
        };
        window.addEventListener("storage", listenStorageChange);
        return () => window.removeEventListener("storage", listenStorageChange);
    }, []);
    return {state, setState};
}