import { Checkbox, Col, Form, FormInstance, Row } from "antd";
import { useCallback, useMemo, useState } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { ProspectMappingType } from "./MapColumns";

export interface SelectColumnsProps {
  onContinue?: (colSelected: ProspectMappingType) => void;
  form: FormInstance<any>;
  columnHeaders: { header: string; index: number }[];
}

export const SelectColumns = ({ form, onContinue, columnHeaders }: SelectColumnsProps) => {
  const [sortedColHeaders] = useState(() => {
    return columnHeaders.sort((a, b) => {
      const nameA = a.header.toUpperCase(); // ignore upper and lowercase
      const nameB = b.header.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  });

  const onSave = useCallback(
    (form) => {
      const { selectedFields }: { selectedFields: number[] } = form;
      const columnMappings = (selectedFields ?? []).map((idx) => sortedColHeaders[idx]).map((col) => ({ outwinField: col.header, columnNumber: col.index }));
      onContinue && onContinue({ columnMappings });
    },
    [onContinue, sortedColHeaders]
  );
  console.log("sessssssssformformform", form.getFieldsValue());

  return (
    <Flex direction="column" gap={20}>
      <Text variant="h6" align="center" block>
        Select columns
      </Text>
      <Div>
        <Text variant="sub2" color="secondary">
          Which of these columns would you like to be included in the email templates?
        </Text>
      </Div>
      <Form scrollToFirstError form={form} onFinish={onSave} layout="vertical" autoComplete="off" onError={() => alert("Error")}>
        <Form.Item name="selectedFields">
          <Checkbox.Group style={{ width: "100%" }} onChange={() => {}}>
            <Row gutter={[16, 16]}>
              {sortedColHeaders?.map((colHeader, idx) => (
                <Col key={colHeader.index} span={8}>
                  <Checkbox value={idx}>{colHeader.header}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Flex>
  );
};
