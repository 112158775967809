import { ClockCircleOutlined, FileAddOutlined, MailOutlined } from "@ant-design/icons";
import { useAuth } from "auth/AuthProvider";
import NoCampaignsImage from "images/onboarding.svg";
import { BgImage, Div, Flex } from "styles/layout";
import { Text } from "styles/typography";

const Onboarding = () => {
  const { theme } = useAuth();
  const primaryColor = theme.antdConf.primaryColor;
  return (
    <Flex direction="column" align="center">
      <BgImage imageUrl={NoCampaignsImage} mh={250} mw={250} />
      <Div>
        <Text variant="sub2" block>
          A campaign is a series of automated touch points designed to drive deeper engagement with your prospects.
        </Text>
        <Text variant="sub2" block mt={5} mb={15}>
          Before continuing, make sure you have gathered the following details:
        </Text>
        <Text variant="body2" block mb={5}>
          <MailOutlined style={{ color: primaryColor, marginRight: 10 }} />
          List of mailboxes (username {"&"} password) from which the emails will go out.
        </Text>
        <Text variant="body2" block mb={5}>
          <FileAddOutlined style={{ color: primaryColor, marginRight: 10 }} />
          CSV file having the list of prospects along with email address.
        </Text>
        <Text variant="body2" block>
          <ClockCircleOutlined style={{ color: primaryColor, marginRight: 10 }} />
          Schedule time
        </Text>
      </Div>
    </Flex>
  );
};

export default Onboarding;
