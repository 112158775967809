import campaignVariable from "./campainVariable";
import campaignProspectVariable from "./prospectVariables";
import spinner from "./spinner";
import campaign from "./campaign";
import notification from "./notification";
import { combineReducers } from "redux";

export default combineReducers({
  campaignVariable,
  campaignProspectVariable,
  spinner,
  campaign,
  notification,
});
