import { Form, Checkbox, Radio, Select, FormInstance } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { Flex } from "styles/layout";
import { Text } from "styles/typography";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import appContext from "contexts/appContext";
const CheckboxGroup = Checkbox.Group;

export interface SelectColumnsProps {
  onContinue?: (state: any, isFromSkip?: boolean) => void;
  form: FormInstance<any>;
}
const campaignCheckboxgroup = [
  { label: "All active campaigns", value: "Active" },
  { label: "All inactive campaigns", value: "Inactive" },
  { label: "All completed campaigns", value: "Completed" }
];

export const DedupeProspects = ({ form, onContinue }: SelectColumnsProps) => {
  const [state, setState] = useState<any>({
    dedupeStatus: false,
    dedupeAllCampaigns: [],
    dedupeType: "allCampaigns",
    allActiveCampaign: [],
    dedupeCampIds: []
  });
  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(appContext);

  const { dedupeType, allActiveCampaign, dedupeCampIds } = state;
  let accountId = String(localStorage.getItem("account_id"));

  useEffect(() => {
    form.setFieldsValue({ dedupeType: "allCampaigns" });
  }, []);

  const handleSelectedCampaignsChange = async (name: string, value: any) => {
    // if (name === "dedupeStatus") {
    //   setState((prev: any) => ({ ...prev, dedupeAllCampaigns: value }));
    //   form.setFieldsValue({ dedupeAllCampaigns: value });
    // }
    if (name === "dedupeType" && value === "selectedCampaigns") {
      console.log("ddddddddddddd");
      await callActiveCampaignApi();
      setState((prev: any) => ({ ...prev, dedupeAllCampaigns: [] }));
      form.setFieldsValue({ dedupeAllCampaigns: [] });
    } else if (name === "dedupeType" && value === "allCampaigns") {
      setState((prev: any) => ({ ...prev, dedupeCampIds: [] }));
      form.setFieldsValue({ dedupeCampIds: [] });
    }
    form.setFieldsValue({ [name]: value });
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const callActiveCampaignApi = async () => {
    setLoadingMessage("Fecthing active campaign");

    const { url, method } = API_ENDPOINTS.ACCOUNTS.getDetailUrlByFilter(accountId, "Active,Inactive,Completed");
    const allCampaign = await callApi({
      url,
      method
    });
    if (allCampaign.success) {
      setLoadingMessage("");
      let allCampaigns = allCampaign?.data?.campaigns || [];
      setState((prev: any) => ({ ...prev, allActiveCampaign: allCampaigns }));
    }
  };

  const handleCampaignCheck = (campId: any) => {
    setState((prev: any) => ({ ...prev, dedupeCampIds: campId }));
    form.setFieldsValue({ dedupeCampIds: campId });
  };
  console.log("sssssssssssssssss", state);

  const onSave = useCallback(
    (form) => {
      console.log("statestate", state);
      console.log(">>>>>>>>>>>>", form);
      onContinue && onContinue(form);
    },
    [onContinue]
  );

  const handleCheckboxChange = (checkedValues: any) => {
    console.log("Checked values:", checkedValues);
    setState((prev: any) => ({ ...prev, dedupeAllCampaigns: checkedValues }));
    form.setFieldsValue({ dedupeAllCampaigns: checkedValues });
  };

  return (
    <Flex direction="column" gap={20}>
      <Text variant="h6" align="center" block>
        Dedupe Campaign
      </Text>
      <Form form={form} scrollToFirstError onFinish={onSave} layout="vertical" autoComplete="off" onError={() => alert("Error")}>
        <Form.Item
          name="dedupeType"
          label="Dedupe Type"
          // rules={[{ required: true, message: "Please select a dedupe type" }]}
        >
          <Radio.Group value={dedupeType} defaultValue={dedupeType} onChange={(e: any) => handleSelectedCampaignsChange("dedupeType", e.target.value)}>
            <Radio value="allCampaigns">All campaigns</Radio>
            <Radio value="selectedCampaigns">Specific campaigns</Radio>
          </Radio.Group>
        </Form.Item>

        {dedupeType === "allCampaigns" && (
          <Form.Item name="dedupeAllCampaigns" label="">
            <CheckboxGroup options={campaignCheckboxgroup} onChange={handleCheckboxChange} />
          </Form.Item>
        )}

        {dedupeType === "selectedCampaigns" && (
          <Form.Item name="dedupeCampIds" label="">
            <Select
              showSearch
              filterOption={(input, option: any) => {
                return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              mode="multiple"
              onChange={handleCampaignCheck}
              value={dedupeCampIds}
              placeholder="Select Campaigns"
            >
              {allActiveCampaign?.map((camp: any, i: number) => (
                <Select.Option key={i} value={camp?.campId}>
                  {camp?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Flex>
  );
};
