import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BgImage, Div, Flex } from "styles/layout";
import withLoader from "hoc/withLoader";
import TouchListComponent from "components/Touch/List";
import NoCampaignsImage from "images/no-campaigns.svg";
import { Button, Modal, notification, Tooltip } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Text } from "styles/typography";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import TouchModal from "./TouchModal";
import appContext from "contexts/appContext";
import { TouchTemplateType, TouchType } from "types/Touch";
import translationStrings from "constants/strings.json";
import { useSelector } from "react-redux";
import { canModifyCampaignState } from "state/selectors";

interface TouchListProps {
  setLoading: (loadingMessage: string) => void;
  loading: boolean;
  campaignId: string;
  sequenceId: string;
}

const TouchList = ({
  setLoading,
  loading,
  campaignId,
  sequenceId,
}: TouchListProps) => {
  const [touches, setTouches] = useState<TouchType[]>([]);
  const [showAddTouchModal, setShowAddTouchModal] = useState(false);
  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(appContext);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [touchIdforDelete, setTouchIdforDelete] = useState("");
  const [touchForEdit, setTouchForEdit] = useState<TouchTemplateType | null>(
    null
  );
  const isFree = true; // note: always true

  const fetchTouches = useCallback(async () => {
    setLoading("Fetch Campaign touches ...");
    const { url, method } = API_ENDPOINTS.CAMPAIGN_TOUCH.getListUrl(
      campaignId,
      sequenceId
    );

    const response = await callApi({
      url,
      method,
    });
    setLoading("");

    if (response.success) {
      setTouches(response.data.touches);
    }
  }, [callApi, campaignId, setLoading, sequenceId]);

  useEffect(() => {
    fetchTouches();

    return () => setTouches([]);
  }, [fetchTouches]);

  const toggleShowAddTouchModal = useCallback(() => {
    setShowAddTouchModal((prev) => !prev);
  }, [setShowAddTouchModal]);

  const showPlaceholder = useMemo(() => !loading && touches.length === 0, [
    loading,
    touches,
  ]);

  const showTouchList = useMemo(() => !loading && !!touches.length, [
    loading,
    touches,
  ]);

  const toggleShowDeleteConfirmModal = useCallback(
    () => setShowDeleteConfirmModal((value) => !value),
    []
  );

  const onDeleteTouch = useCallback(
    (touchId: string) => {
      setTouchIdforDelete(touchId);
      toggleShowDeleteConfirmModal();
    },
    [toggleShowDeleteConfirmModal]
  );

  const onCancelDelete = useCallback(() => {
    setTouchIdforDelete("");
    toggleShowDeleteConfirmModal();
  }, [toggleShowDeleteConfirmModal]);

  const confirmDelete = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.TOUCH.getDeleteUrl(touchIdforDelete);

    toggleShowDeleteConfirmModal();
    setLoadingMessage("Deleting a touch ...");
    const response = await callApi({
      url,
      method,
    });

    setLoadingMessage("");
    if (response.success) {
      notification.success({
        message: "Touch deleted successfully!",
      });

      fetchTouches();
    }
  }, [
    touchIdforDelete,
    toggleShowDeleteConfirmModal,
    setLoadingMessage,
    callApi,
    fetchTouches,
  ]);

  const onSaveCallback = useCallback(() => {
    toggleShowAddTouchModal();
    fetchTouches();
  }, [toggleShowAddTouchModal, fetchTouches]);

  const onEditTouch = useCallback(
    async (touchId: string) => {
      const touch = touches.find(({ id }) => id === touchId);
      if (!touch) return;

      const { url, method } = API_ENDPOINTS.TOUCH.getListTemplatesUrl(touchId);

      setLoadingMessage("Fetching Touch template ...");
      const response = await callApi({
        url,
        method,
      });
      setLoadingMessage("");

      if (response.success) {
        const {
          templates: [template],
        } = response.data;
        setTouchForEdit({ ...touch, template });
        toggleShowAddTouchModal();
      }
    },
    [callApi, setLoadingMessage, toggleShowAddTouchModal, touches]
  );

  const onTouchModalCancel = useCallback(() => {
    toggleShowAddTouchModal();
    setTouchForEdit(null);
  }, [toggleShowAddTouchModal]);

  return (
    <>
      <Div align="center" mh="500px">
        {showTouchList && (
          <>
            <Div align="right">
              <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
                <Button type="primary" disabled={!isFree} icon={<PlusOutlined />} onClick={toggleShowAddTouchModal}>
                  Add Touch
                </Button>
              </Tooltip>
            </Div>
            <TouchListComponent touches={touches} canEdit={isFree} onDelete={onDeleteTouch} onEdit={onEditTouch} />
          </>
        )}
        {showPlaceholder && (
          <Flex align="center" grow={1} direction="column">
            <BgImage imageUrl={NoCampaignsImage} w={200} h={200} />
            <Text variant="sub1" mb={20} color="secondary">
              You haven't added any touches yet.
            </Text>
            <Div>
              <Tooltip title={!isFree ? translationStrings.campaign.campaignModifyNotAllowed : ""}>
                <Button type="primary" disabled={!isFree} icon={<PlusOutlined />} onClick={toggleShowAddTouchModal}>
                  Add Touch
                </Button>
              </Tooltip>
            </Div>
          </Flex>
        )}
        {showAddTouchModal && (
          <TouchModal
            campaignId={campaignId}
            touches={touches}
            onClose={onTouchModalCancel}
            onSaveCallback={onSaveCallback}
            touchTemplate={touchForEdit}
            onSetTouchForEdit={""}
            sequenceId={sequenceId}
            canEdit={isFree}
            editMode={false}
            isEditMode={""}
            settemplateIdForEdit={""}
            sequenceType={""}
            switchTouch={() => console.log('')}
            genrateNewT={() => console.log('')}
            templateIdForEdit=''
          />
        )}
      </Div>
      {showDeleteConfirmModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary",
            danger: true,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={onCancelDelete}
          onOk={confirmDelete}
          cancelButtonProps={{ type: "text" }}
          centered
          zIndex={1030}
        >
          <Text variant="body2">
            Are you sure you want to{" "}
            <Text variant="body2" bold>
              delete
            </Text>{" "}
            the touch?
          </Text>
        </Modal>
      )}
    </>
  );
};

export default withLoader(TouchList);
