import { notification } from "antd";
import { getAccessToken } from "auth/AuthProvider";
import axios from "axios";
import { BASE_URL } from "constants/api";
import { ApiResponseType, ApiType } from "hooks/useApi";
import { capitalize } from "./string";


class Axios {
  static client = axios.create({});

  callApi = async ({
    url,
    method,
    external = false,
    data,
    onUploadProgress,
    raw = false,
    authRequired = true,
    ...rest
  }: ApiType): Promise<ApiResponseType> => {
    let accessToken;
    if(authRequired){
      accessToken = await getAccessToken();
    }
    return Axios.client({
      method,
      url,
      baseURL: external ? url.includes('/auth') || url.includes('/spot/') ?
        "https://whisper.outwin.io" : url.includes('/api/') ? "https://gobrooklyn.net"
          : "https://api.whisper.email" : url.includes('/oauthurl') ?"https://whisper.outwin.io" :
        BASE_URL,
      data,
      onUploadProgress,
      headers: {
        ...(authRequired &&  { Authorization: `Bearer ${accessToken}`}),
      },
      ...rest
    })
      .then((res) => {
        return { data: raw ? res.data : res.data.data, success: true };
      })
      .catch((error) => {
        const { message = "Something went wrong!" } =
          error?.response?.data?.error || {};
        notification.error({
          key: "api-error",
          duration: 10,
          message: capitalize(message),
        });

        return { success: false, error: error?.response?.data?.error ?? {} };
      });
  };
}

export default new Axios();
