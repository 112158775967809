import Icon, {
    BankOutlined, DownloadOutlined,
    EnvironmentOutlined, UserOutlined
} from "@ant-design/icons";
import { Button, Form, notification, Select, Table, Tabs } from "antd";
import { useAuth } from "auth/AuthProvider";
import { NavPathType } from "components/AppBar";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import useApi from "hooks/useApi";
import { ReactComponent as UndrawBuildingSvg } from "images/undraw_building.svg";
import { ContactsFilter } from "pages/ContactSearch/ContactsFilter";
import { defaultEmployeeSize, defaultRevenue } from "pages/ContactSearch/ContactsFiltersValues";
import locationFilterValues from "pages/ContactSearch/LocationFilterValues";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classes from "styles/common.module.css";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { BOX_SHADOW, WHITE_COLOR } from "utils/colors";
import adaptClasses from "../ContactSearch/styles.module.css";
import { defaultIndustries, subIndustries } from "./CompanyFilterValues";
import DownloadCompanyDialog from "./DownloadCompanyDialog";

enum ContactSearchTypeEnum {
    CSV = "CSV",
    COMPANY = "COMPANY",
    NONE = "NONe"
}

interface CompanySearchProps {
    setNavPaths: SetNavPathsFn;
}

type ContactSearchPageState = {
    searchMode?: ContactSearchTypeEnum;
    showContactsFilter: boolean;
    uploadKey?: string;
};


const CompanySearch = ({ setNavPaths }: CompanySearchProps) => {
    const { setLoadingMessage } = useContext(appContext);
    const { callApi } = useApi();
    const { theme } = useAuth();

    const { Option } = Select;
    const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
    const location = useLocation();
    const [companyName, setCompanyName] = useState<string[]>([]);
    const [companyDomain, setCompanyDomain] = useState<string[]>([]);
    const [locations, setLocations] = useState(locationFilterValues);
    const [selectedLocation, setSelectedLocation]: any = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [employeeSize, setEmployeeSize] = useState(defaultEmployeeSize);
    const [selectedEmployeeSize, setSelectedEmployeeSize] = useState([]);
    const [revenue, setRevenue] = useState(defaultRevenue);
    const [selectedRevenue, setSelectedRevenue] = useState([]);

    const getPreviousFilter = sessionStorage.getItem('ProspectsFinderCurrentTab');
    const [searchFilter, setSearchFilter] = useState(getPreviousFilter || "CompanySearch");
    const [adaptComapnies, setAdaptComapnies] = useState([])
    const [count, setCount] = useState(0)
    const [tableDataCount, setTableDataCount] = useState(0)
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [listOfSubIndustries, setListOfSubIndustries] = useState([])
    const [selectedSubIndustry, setSelectedSubIndustry] = useState(subIndustries);
    const [industry, setIndustry] = useState(defaultIndustries);
    const [showDownloadCompanyDialog, setShowDownloadCompanyDialog] = useState(false);
    const [disableButtons, setDisableButtons] = useState(true)
    const [companyPage, setCompanyPage] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState([]);
    const [selectedTitles, setselectedTitles] = useState([]);
    const [minimumDeliveryScore, setMinimumDeliveryScore] = useState(null);
    const [titleExactCheckbox, setTitleExactCheckbox] = useState(false);


    let getLocation = (city: string, state: string, country: string) => {
        let arr = [city, state, country];
        let count = 1;
        let lable = "";
        for (let i = 0; i < 3; i++) {
            if (arr[i]) {
                if (count > 1 && count < 4) {
                    lable += (", ");
                }
                lable += (arr[i]);
                count++;
            }
        }
        return lable;
    }

    const getStateCountry = () => {
        var stateArray: any = [];
        var countryArray: any = [];

        for (var i = 0; i < selectedLocation.length; i++) {
            var locationSplitArray = selectedLocation[i].split(',');
            if (locationSplitArray.length === 2) {
                stateArray.push(locationSplitArray[0].trim());
                countryArray.push(locationSplitArray[1].trim());
            }
            else if (locationSplitArray.length === 1) {
                countryArray.push(locationSplitArray[0].trim());
            }
        }

        setSelectedState(stateArray);
        setSelectedCountry(countryArray);
    }

    useEffect(() => {
        if (companyPage) {
            let Arr = [companyName.length > 0, companyDomain.length > 0, selectedState.length > 0, selectedCountry.length > 0, selectedIndustry.length > 0, selectedRevenue.length > 0, selectedEmployeeSize.length > 0, listOfSubIndustries.length > 0]
            for (let index = 0; index < Arr.length; index++) {
                if (Arr[index]) {
                    searchCompanies(selectedCity, selectedState, selectedCountry, selectedEmployeeSize, selectedRevenue, selectedIndustry, listOfSubIndustries);
                    break
                }
            }
        }
    }, [searchFilter])

    useEffect(() => {
        if (selectedLocation)
            getStateCountry();
    }, [selectedLocation]);



    useEffect(() => {
        setAccountData({
            accountId: String(localStorage.getItem("account_id")),
            accountName: String(localStorage.getItem("accountName"))
        });
    }, [setAccountData]);


    useEffect(() => {
        const navPaths: NavPathType[] = [];
        if (accountData) {
            navPaths.push({
                label: accountData.accountName,
                linkTo: `/account/${accountData.accountId}/campaigns`,
            },
                { label: "Prospects Finder" }
            );
        }

        setNavPaths(navPaths);
        return () => setNavPaths([]);
    }, [setNavPaths, location, accountData]);


    const datasource = adaptComapnies ?
        adaptComapnies.map((company: any, i: any) => {
            return (
                {
                    key: i,
                    name: company.companyName + ' ' + '\n' + getLocation(company.city, company.state, company.country),
                    industry: company.industry + ' ' + '\n' + (company.employees ? company.employees : "NA") + ' | ' + (company.companyRevenue ? company.companyRevenue : " NA"),
                    website: company.companyWebsite,
                    contacts: company.numberOfContacts,
                }
            )
        }) : [];

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'name',
            key: 'name',
            render(name: any) {
                return (
                    <>
                        <div style={{ color: '#33a3ff', fontWeight: 500 }}>{name.split('\n')[0]}</div>
                        <div><EnvironmentOutlined style={{ fontSize: 12, verticalAlign: 'middle', lineHeight: 1 }} /> {name.split('\n')[1]}</div>
                    </>
                );
            }
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
            render(name: any) {
                return (
                    <>
                        <div ><BankOutlined style={{ fontSize: 13 }} /> {name.split(/[\n|]+/)[0]}</div>
                        <div>
                            <UserOutlined style={{ fontSize: 14 }} /> {name.split(/[\n|]+/)[1]} <span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span> $ {name.split(/[\n|]+/)[2]}
                        </div>
                    </>

                );
            }
        },
        {
            title: 'Website ',
            dataIndex: 'website',
            key: 'website'
        },
        {
            title: 'Contacts',
            dataIndex: 'contacts',
            key: 'contacts'
        }
    ];

    const onCompanyNameChange = (e: any) => {
        setCompanyName(e);
    };

    const onCompanyDomainChange = (e: any) => {
        setCompanyDomain(e);
    };

    const getChildrenOption = (options: any) => {
        let arr: any = []
        options.forEach((one: any) => {
            arr.push(<Option key={one.name}>{one.name}</Option>)
        })
        return arr;
    }

    const getSubindustires = (primaryIndustryName: string) => {
        let filteredArray = subIndustries.filter(item => item.industryName == primaryIndustryName);
        console.log(filteredArray);
        return filteredArray.map(item => item.name);
    }

    const handleChange = (value: any, type: any) => {
        if (type === 'EmployeeSize') {
            setSelectedEmployeeSize(value);
            console.log(`selected employeesize ${value}`)
        }
        if (type === 'Revenue') {
            setSelectedRevenue(value);
            console.log(`selected revenue ${value}`);
        }
        if (type === 'Location') {
            var cityArray: any = [];
            var stateArray: any = [];
            var countryArray: any = [];

            for (var i = 0; i < value.length; i++) {
                var locationSplitArray = value[i].split(',');
                if (locationSplitArray.length === 3) {
                    cityArray.push(locationSplitArray[0].trim());
                    stateArray.push(locationSplitArray[1].trim());
                    countryArray.push(locationSplitArray[2].trim());
                }
                else if (locationSplitArray.length === 2) {
                    stateArray.push(locationSplitArray[0].trim());
                    countryArray.push(locationSplitArray[1].trim());
                }
                else if (locationSplitArray.length === 1) {
                    countryArray.push(locationSplitArray[0].trim());
                }
            }
            setSelectedCity(cityArray);
            setSelectedState(stateArray);
            setSelectedCountry(countryArray);
            setSelectedLocation(value);
            console.log(`selected location ${value}`);
        }
        if (type === 'Industry') {
            setSelectedIndustry(value);
            let filterArr = industry.filter((obj) => {
                if (value.includes(obj.name)) {
                    return obj;
                }
            })
            let sub: any = [];
            filterArr.forEach((one: any) => {
                sub = [...sub, ...getSubindustires(one.name)]
            })
            console.log(sub);
            let options: any = []
            sub.forEach((one: any, i: number) => {
                let obj = { id: i, name: one };
                options.push(obj);
            })
            console.log(options)
            setSelectedSubIndustry(options);
            if (value == '') {
                setSelectedSubIndustry(subIndustries);
            }
            console.log(`selected industry ${value}`);
        }
        if (type === 'SubIndustry') {
            setListOfSubIndustries(value)
            console.log(`selected subindustry ${value}`);

        }
    }

    const onChangeStatusFilter = (newSearchFilter: string) => {
        setSearchFilter(newSearchFilter);
        if (newSearchFilter === "ContactSearch")
            setCompanyPage(false)
        else
            setCompanyPage(true)
        localStorage.removeItem('fromCompanyTab')
        sessionStorage.setItem('ProspectsFinderCurrentTab', newSearchFilter)
    };

    const getPayload = (action: any, companyName: any, companyDomain: any,
        cities: any, states: any, countries: any, employees: any, subIndustry: any,
        revenue: any, industry: any,) => {

        let data = {
            "action": action,
            "adaptEntity": "ACCOUNT",
            "companyName": companyName,
            "domains": companyDomain,
            "city": cities,
            "state": states,
            "country": countries,
            "employees": employees,
            "subIndustry": subIndustry,
            "revenue": revenue,
            "industry": industry,
        }

        return data;
    }

    const searchCompanies = async (cityFilter: any, stateFilter: any, countryFilter: any, employeeSizeFilter: any,
        revenueFilter: any, industryFilter: any, subIndustryFilter: any) => {

        if (cityFilter.length === 0)
            cityFilter = null
        if (stateFilter.length === 0)
            stateFilter = null
        if (countryFilter.length === 0)
            countryFilter = null
        if (employeeSizeFilter.length === 0)
            employeeSizeFilter = null
        if (revenueFilter.length === 0)
            revenueFilter = null
        if (industryFilter.length === 0)
            industryFilter = null
        if (subIndustryFilter.length === 0)
            subIndustryFilter = null

        setLoadingMessage("Fetching Companies ...");
        const { url, method } = API_ENDPOINTS.CONTACT_SEARCH.adaptSearch();
        const response = await callApi({
            url,
            method,
            data: getPayload("SEARCH", companyName, companyDomain, cityFilter, stateFilter, countryFilter,
                employeeSizeFilter, subIndustryFilter, revenueFilter, industryFilter)
        });
        if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            if (response.data.items.length > 0) {
                setDisableButtons(false)
            } else {
                setDisableButtons(true)
            }
            setAdaptComapnies(response.data.items)
            setTableDataCount(response.data.items.length)
            setCount(response.data.count)
        }
        setLoadingMessage("");
    };

    let formRef: any = React.createRef();

    const clearAllTheFilters = () => {
        formRef.current.resetFields();
        setSelectedRevenue([]);
        setSelectedEmployeeSize([]);
        setListOfSubIndustries([]);
        setSelectedIndustry([]);
        setSelectedCountry([])
        setSelectedState([])
        setCompanyName([])
        setCompanyDomain([]);
        setCount(0);
        setDisableButtons(true);
        setSelectedLocation([])
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const onDownloadCompanies = () => {
        if (count > 20000) {
            return notification.warn({
                message: "Maximum company you can download is 20,000. Please apply more filters to reduce the total companies."
            })
        } else
            setShowDownloadCompanyDialog(!showDownloadCompanyDialog)

    }

    return (
        <>
            <Flex justify="space-between" align="center">
                <Tabs activeKey={searchFilter} onChange={onChangeStatusFilter}>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <BankOutlined />
                                {/* <Link to={`${match.url}/companysearch`}>Company Search</Link> */}
                                Company Search
                            </span>
                        }
                        key="CompanySearch"
                    />
                    <Tabs.TabPane
                        tab={
                            <span>
                                <UserOutlined />
                                {/* <Link to={`${match.url}/contactsearch`}>Contact Search</Link> */}
                                Contact Search
                            </span>
                        }
                        key="ContactSearch"
                    />
                </Tabs>
            </Flex>

            {(searchFilter === "ContactSearch") ? <ContactsFilter
                companyNames={companyName}
                companyDomains={companyDomain}
                selectedIndustries={selectedIndustry}
                selectedSubIndustries={listOfSubIndustries}
                selectedCities={selectedCity}
                selectedStates={selectedState}
                selectedCountries={selectedCountry}
                employeeSizes={selectedEmployeeSize}
                revenues={selectedRevenue}
                companyCount={count}
                adaptComapnies={adaptComapnies.map((companyWebsite: any) => { return companyWebsite.companyWebsite })}
                contactObj={""}
                companyNameState={setCompanyName}
                companyDomainState={setCompanyDomain}
                selectedEmployeeSizeState={setSelectedEmployeeSize}
                selectedRevenueState={setSelectedRevenue}
                selectedIndustryState={setSelectedIndustry}
                selectedSubInsustryState={setListOfSubIndustries}
                selectedLocations={selectedLocation}
                selectedLocationsState={setSelectedLocation}
                Departments={departments}
                setDepartments={setDepartments}
                selectedLevel={selectedLevel}
                setSelectedLevel={setSelectedLevel}
                selectedTitles={selectedTitles}
                setselectedTitles={setselectedTitles}
                minimumDeliveryScore={minimumDeliveryScore}
                setMinimumDeliveryScore={setMinimumDeliveryScore}
                titleExactCheckbox={titleExactCheckbox}
                setTitleExactCheckbox={setTitleExactCheckbox}
            /> : (
                <>
                    <Flex justify="space-between" align="center" className="top-btns" >
                        <Div>
                            {new Intl.NumberFormat('en-US').format(count)} Companies
                        </Div>
                    </Flex>
                    <Div
                        bgColor={WHITE_COLOR}
                        mt={20}
                        style={{
                            boxShadow: BOX_SHADOW,
                            paddingBottom: "20px"
                        }}
                    >
                        <Flex
                            mb={10}
                        >
                            {/* LEFT COLUMN */}

                            <Div mw={250} w={250} mr={15} ml={15} mt={15} className="filters-left-col">
                                <Text variant="sub2">
                                    Filters
                                </Text>

                                <Div w="100%" mt={20}>
                                    <Form
                                        className="filter-form"
                                        ref={formRef}
                                    >

                                        <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                                            Company Domain
                                        </Text>
                                        <Form.Item name={"Company Domain"}>
                                            <Select
                                                dropdownClassName="display-none"
                                                className="mt-2"
                                                defaultValue={companyDomain}
                                                mode="tags"
                                                style={{ width: '100%' }}
                                                onChange={onCompanyDomainChange}
                                                tokenSeparators={[',']}>
                                            </Select>
                                        </Form.Item>

                                        <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                                            Company Name
                                        </Text>
                                        <Form.Item name={"Company Name"}>
                                            <Select
                                                dropdownClassName="display-none"
                                                className="mt-2"
                                                mode="tags"
                                                defaultValue={companyName}
                                                style={{ width: '100%' }}
                                                onChange={onCompanyNameChange}
                                                tokenSeparators={[',']}>
                                            </Select>
                                        </Form.Item>

                                        <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                                            Location
                                        </Text>
                                        <Form.Item name={"Location"}>
                                            <Select
                                                mode="multiple"
                                                className="mt-2"
                                                showSearch
                                                style={{ width: '100%' }}
                                                defaultValue={selectedLocation}
                                                onChange={(value) => handleChange(value, 'Location')}
                                            >
                                                {getChildrenOption(locations)}
                                            </Select>
                                        </Form.Item>
                                        <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                                            Industry
                                        </Text>
                                        <Form.Item name={"Industry"}>
                                            <Select mode="multiple" className="mt-2" defaultValue={selectedIndustry} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'Industry')}>
                                                {getChildrenOption(industry)}
                                            </Select>
                                        </Form.Item> <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                                            Sub Industry
                                        </Text>
                                        <Form.Item name={"SubIndustry"}>
                                            <Select mode="multiple" className="mt-2" defaultValue={listOfSubIndustries} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'SubIndustry')}>
                                                {getChildrenOption(selectedSubIndustry)}
                                            </Select>
                                        </Form.Item>
                                        <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                                            Employee Size
                                        </Text>
                                        <Form.Item name={"EmployeeSize"}>
                                            <Select mode="multiple" className="mt-2" defaultValue={selectedEmployeeSize} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'EmployeeSize')}>
                                                {getChildrenOption(employeeSize)}
                                            </Select>
                                        </Form.Item>

                                        <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                                            Revenue
                                        </Text>
                                        <Form.Item name={"Revenue"}>
                                            <Select mode="multiple" className="mt-2" defaultValue={selectedRevenue} showSearch style={{ width: '100%' }} onChange={(value) => handleChange(value, 'Revenue')}>
                                                {getChildrenOption(revenue)}
                                            </Select>
                                        </Form.Item>

                                        <Flex justify="end" >
                                            <Button type="text" onClick={clearAllTheFilters}>Clear</Button>
                                            <Button type="primary" style={{ marginLeft: "10px" }} onClick={(e) => searchCompanies(selectedCity, selectedState, selectedCountry, selectedEmployeeSize, selectedRevenue, selectedIndustry, listOfSubIndustries)}>Search</Button>
                                        </Flex>

                                    </Form>
                                </Div>
                            </Div>

                            {/* RIGHT COLUMN */}

                            <Flex
                                style={{
                                    minHeight: "calc(100vh - 140px)",
                                    width: "calc(100% - 250px)"
                                }}
                                className="records-right-col"
                                grow={1}
                                mr={15}
                                direction="column"
                            >
                                <Div
                                    mt={15}
                                    className={"contacts-table " + classes.scrollContainer}
                                >
                                    <Flex justify="end" mb={20}>
                                        {!disableButtons && (
                                            <Button
                                                style={{ color: theme.antdConf.primaryColor, padding: 0, background: "none" }}
                                                type="text"
                                                icon={<DownloadOutlined />}
                                                onClick={onDownloadCompanies}>
                                                Download Companies
                                            </Button>
                                        )}
                                        {showDownloadCompanyDialog && (
                                            <DownloadCompanyDialog
                                                onCancel={() => setShowDownloadCompanyDialog(false)}
                                                city={selectedCity}
                                                state={selectedState}
                                                country={selectedCountry}
                                                industry={selectedIndustry}
                                                subIndustry={listOfSubIndustries}
                                                employees={selectedEmployeeSize}
                                                revenue={selectedRevenue}
                                                companyName={companyName}
                                                domains={companyDomain}
                                                // domains={adaptComapnies.map((companyWebsite: any) => { return companyWebsite.companyWebsite })}
                                                downloadCompanyLimit={count}

                                            />
                                        )}
                                    </Flex>
                                    {!disableButtons && (<Table
                                        size="small"
                                        id="adaptContactSearch-table"
                                        className="outwin-table"

                                        style={{ marginBottom: "20px", marginTop: "0" }}
                                        columns={columns}
                                        dataSource={datasource}
                                        pagination={false}
                                        onRow={() => {
                                            return {
                                                className: "outwin-table-row-hover",
                                            };
                                        }}
                                    />)}
                                    {disableButtons && (
                                        <Flex className={adaptClasses.illustrationDiv}>
                                            <Icon component={UndrawBuildingSvg} style={{ fontSize: "200px" }} />
                                        </Flex>
                                    )
                                    }
                                    {!disableButtons && (count > tableDataCount) && (
                                        <Flex justify="end">
                                            <p style={{ marginTop: 20, color: 'rgba(96,99,103,.55)', fontSize: '12px' }}>
                                                This table shows only limited companies. To view all companies, click the download button
                                                and you will be charged for the total companies that you download.
                                            </p>
                                        </Flex>
                                    )
                                    }

                                </Div>
                            </Flex>


                        </Flex>
                    </Div>
                </>
            )}
        </>
    )
}

export default withAppBar(CompanySearch);