import React, { useCallback, useEffect, useMemo } from "react";
import { API_ENDPOINTS } from "constants/api";
import { Flex, Div } from "styles/layout";
import { Text } from "styles/typography";
import { Layout, Menu, Tabs, Modal, Input, Form, Button, Space, notification } from "antd";
import { WarningOutlined, InboxOutlined, StopOutlined, ClockCircleOutlined, ImportOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CampaignResponseEnum } from "types/Campaign";
import { useState } from "react";
import useApi from "hooks/useApi";
import InboundMails from "./InboundMails";
import classes from "./styles.module.css";
import classStyle from "styles/common.module.css";
import useLocalStorage from "pages/Unibox/hooks";
import NewMailImg from "images/new_message_inbox.svg";
import { isArray, min } from "lodash";
const { TabPane } = Tabs;
const dataTag = {
  success: true,
  data: {
    tags: [
      {
        id: "tag_fTGc23npIY",
        name: "All",
        als: "all",
        std: true,
        seq: 0,
        show: true,
        apply: false,
        unread: 569,
        processed: true
      },
      {
        id: "tag_tZkm1Q4nZD",
        name: "Replies",
        als: "replies",
        std: true,
        seq: 1,
        show: true,
        apply: true,
        unread: 1,
        processed: false
      },
      {
        id: "tag_iPrPooZ5zi",
        name: "Out Of Office",
        als: "ooo",
        std: true,
        seq: 2,
        show: true,
        apply: true,
        unread: 69,
        processed: true
      },
      {
        id: "tag_gst0dbxb38",
        name: "Hard Bounced",
        als: "hard",
        std: true,
        seq: 3,
        show: true,
        apply: false,
        unread: 0,
        processed: true
      },
      {
        id: "tag_62tZWhOkj2",
        name: "Soft Bounced",
        als: "soft",
        std: true,
        seq: 4,
        show: true,
        apply: false,
        unread: 0,
        processed: true
      },
      {
        id: "tag_jxbPfJZc8H",
        name: "Unsubscribed",
        als: "unsub",
        std: true,
        seq: 5,
        show: true,
        apply: false,
        unread: 0,
        processed: true
      },
      {
        id: "tag_djVe6a2qHR",
        name: "Spam",
        als: "spam",
        std: true,
        seq: 6,
        show: true,
        apply: true,
        unread: 0,
        processed: true
      },
      {
        id: "tag_Hcu3w23Ubw",
        name: "Auto Reply",
        als: "auto",
        std: true,
        seq: 7,
        show: true,
        apply: true,
        unread: 499,
        processed: true
      },
      {
        id: "tag_bZfgsfsQVQ",
        name: "Discarded",
        als: "discard",
        std: true,
        seq: 8,
        show: true,
        apply: true,
        unread: 0,
        processed: true
      },
      {
        id: "tag_lJoar9mBq0",
        name: "Info Sequence",
        als: "info",
        std: true,
        seq: 9,
        show: true,
        apply: false,
        unread: 0,
        processed: true
      },
      {
        id: "tag_xg8hjwtIuS",
        name: "Power Sequence",
        als: "power",
        std: true,
        seq: 10,
        show: true,
        apply: false,
        unread: 0,
        processed: true
      },
      {
        id: "tag_EVrU5dA08M",
        name: "Referral Sequence",
        als: "referral",
        std: true,
        seq: 11,
        show: true,
        apply: false,
        unread: 0,
        processed: true
      },
      {
        id: "tag_KMr57cNzN4",
        name: "Future Sequence",
        als: "future",
        std: true,
        seq: 12,
        show: true,
        apply: false,
        unread: 0,
        processed: true
      },
      ,
      {
        id: "tag_KMrsdfcNzN4",
        name: "All",
        als: "allPending",
        std: true,
        seq: 13,
        show: true,
        apply: false,
        unread: 0,
        processed: false
      },
      {
        id: "tag_tZkm1QsnZD",
        name: "Replies",
        als: "repliesProcessed",
        std: true,
        seq: 14,
        show: true,
        apply: false,
        unread: 1,
        processed: true
      },
      {
        id: "tag_tZsdfQsnZD",
        name: "Referral Sequence",
        als: "referralPending",
        std: true,
        seq: 15,
        show: true,
        apply: false,
        unread: 1,
        processed: false
      },
      {
        id: "tag_tZsdfQsnZD",
        name: "Future Sequence",
        als: "futurePending",
        std: true,
        seq: 16,
        show: true,
        apply: false,
        unread: 1,
        processed: false
      },
      {
        id: "tag_tZsdfQsnZD",
        name: "On Hold",
        als: "onHoldPending",
        std: true,
        seq: 17,
        show: true,
        apply: false,
        unread: 1,
        processed: false
      },
      {
        id: "tag_KSlPDZedFe",
        name: "Test1",
        als: "test1",
        std: false,
        seq: 34,
        show: true,
        apply: true,
        unread: 0,
        processed: true
      },
      {
        id: "tag_d3OnN8mQfY",
        name: "Test2",
        als: "test2",
        std: false,
        seq: 35,
        show: true,
        apply: true,
        unread: 0,
        processed: true
      },
      {
        id: "tag_k7HuQdAXmU",
        name: "repliestest",
        als: "repliestest",
        std: false,
        seq: 36,
        show: true,
        apply: true,
        unread: 0,
        processed: false
      }
    ]
  }
};
const { Sider } = Layout;

type TagsResponsesList = {
  id: string;
  name: string;
  type?: string;
};

type TagsResponses = {
  success: Boolean;
  data: {
    tags: Array<TagsResponsesList>;
  };
};

type ResponsesPropType = {
  campaignId: string;
  accountId?: string;
  levelType: string;
  accountName?: string;
  isFromUnibox?: boolean;
};

type AllTagsType = {
  label: string;
  value: string;
  id: string;
  type?: string;
};

const RESPONSE_TABS = [
  {
    label: "Main",
    value: CampaignResponseEnum.MAIN,
    Icon: InboxOutlined
  },
  {
    label: "Bounced",
    value: CampaignResponseEnum.BOUNCED,
    Icon: WarningOutlined
  },
  {
    label: "Out of office",
    value: CampaignResponseEnum.OUTOFOFFICE,
    Icon: ClockCircleOutlined
  },
  {
    label: "Spam",
    value: CampaignResponseEnum.SPAM,
    Icon: StopOutlined
  },
  {
    label: "Auto Reply",
    value: CampaignResponseEnum.AUTOREPLY,
    Icon: ImportOutlined
  }
];

// const res: TagsResponses = {
//   "success": true,
//   "data": {
//     "tags": [
//       { "id": "3213-32131-32132", "name": "Bounced", "type": "standard" },
//       { "id": "3213-32131-32133", "name": "Out of office", "type": "standard" },
//       { "id": "3213-32131-32131", "name": "Main", "type": "standard" },
//       { "id": "3213-32131-32134", "name": "Spam", "type": "standard" },
//       { "id": "3213-32131-32135", "name": "Spam custom", "type": "custom" },
//       { "id": "3213-32131-32136", "name": "Auto Narendra", "type": "custom" }
//     ]
//   }
// }

const TagV2 = ({ campaignId, accountId = "", levelType = "USER", accountName = "", isFromUnibox = false }: ResponsesPropType) => {
  const { callApi } = useApi();
  const [form] = Form.useForm();
  const localStorages = useLocalStorage("selectedUserId", null);

  const [tagData, setTagData] = useState<AllTagsType[] | any>([]);
  const [allTgas, setAllTagData] = useState<AllTagsType[] | any>([]);
  const [selectedTag, setSelectedTag] = useState<AllTagsType>({ id: "", label: "", value: "" });
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem("selectedUserId"));
  const [showTagModal, setShowTagModal] = useState(false);
  const [tagId, setTagId] = useState<string>("");
  const [state, setState] = useState<any>({ activeKey: "Pending", setAllFolderTags: [] });
  const { activeKey, setAllFolderTags } = state;
  const onCanelPress = () => {
    setShowTagModal(!showTagModal);
    setSelectedTag({ id: "", label: "", value: "" });
  };
  const toggleDeleteTagModal = useCallback(() => setShowDeleteConfirmModal((value) => !value), []);
  useEffect(() => {
    // Load the todos on mount
    const todosString = localStorage.getItem("selectedUserId");
    if (todosString) {
      const todos = todosString;
      setSelectedUserId(todos);
    }
    // Respond to the `storage` event
    function storageEventHandler(event: any) {
      if (event.key === "todos") {
        const todos = event.newValue;
        setSelectedUserId(todos);
      }
    }
    // Hook up the event handler
    window.addEventListener("storage", storageEventHandler);
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener("storage", storageEventHandler);
    };
  }, [localStorage.getItem("selectedUserId")]);

  // useEffect(() => {
  //   fetchTags();
  // }, [localStorages?.state]);

  // const fetchTags = useCallback(async () => {
  //   const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";

  //   const { url, method } = API_ENDPOINTS.CAMPAIGN_TAG.getTagListUrl(uId || "");
  //   const response = await callApi({
  //     url,
  //     method
  //   });
  //   if (response.success) {
  //     if (response?.data?.tags && response?.data?.tags.length && !tagId) {
  //       // if (response?.data?.tags && response?.data?.tags.length) {

  //       const defultTag: TagsResponsesList = response?.data?.tags.find(({ seq }: any) => seq === 0) as TagsResponsesList;
  //       console.log("defultTagdefultTag", defultTag);
  //       setTagId(defultTag?.id ?? "");
  //     } else {
  //       if (tagId) {
  //         setTagId(tagId);
  //       } else {
  //         setTagId("");
  //       }
  //     }
  //     let filteredData = response.data.tags;
  //     let newNameArray = [];
  //     for (let i = 0; i < filteredData.length; i++) {
  //       let newObj = {
  //         label: filteredData[i].name,
  //         value: filteredData[i].name.toUpperCase(),
  //         id: filteredData[i].id,
  //         type: filteredData[i]?.std ? "standard" : "custom",
  //         show: filteredData[i].show,
  //         unread: filteredData[i].unread
  //       };
  //       newNameArray.push(newObj);
  //     }
  //     setTagData([...newNameArray]);
  //     setAllTagData(filteredData);
  //     if (Array.isArray(newNameArray) && newNameArray?.length > 0) {
  //       setSelectedTag(newNameArray[0]);
  //     }
  //   }
  // }, [callApi, tagData, setTagData, tagId]);

  const [responseType, setResponseType] = useState<CampaignResponseEnum>(RESPONSE_TABS[0].value);

  const onClickSideNav: any = useCallback(
    ({ key }: { key: string; keyPath: string[] }) => {
      if (key === tagId) {
        return;
      }
      setTagId(key);
      // setResponseType(key as CampaignResponseEnum)
    },

    [tagId]
  );

  const handleTagInputChange = useCallback(
    (label: string, value: string) => {
      setSelectedTag({ ...selectedTag, ...{ [label]: value } });
    },
    [selectedTag]
  );

  //Edit tag
  const onClickEditTag = useCallback(
    (e: any, id: string) => {
      e.stopPropagation();
      // console.log("id", id);
      // console.log(tagData);

      let selectedTagData = tagData.find((tag: any) => tag.id === id);
      // console.log("selectedTagData", selectedTagData);

      if (selectedTagData && Object.keys(selectedTagData).length) {
        setSelectedTag(selectedTagData);
        setShowTagModal(true);
      }
    },
    [selectedTag, setSelectedTag, tagData]
  );
  const uId = localStorage.getItem("selectedUserId") && localStorage.getItem("selectedUserId")?.length ? localStorage.getItem("selectedUserId") : "";

  const editTag = useCallback(async () => {
    // console.log("Edit tag called");

    const { url, method } = API_ENDPOINTS.CAMPAIGN_TAG.updateTagNameUrl(campaignId, selectedTag.id, uId || "");
    const payload = {
      name: selectedTag.label
    };
    // const payload = { name: selectedTag.label, campaignId };
    const response = await callApi({
      url,
      method,
      data: payload
    });
    if (response.success) {
      onCanelPress();
      // await fetchTags();
    }
  }, [selectedTag, setSelectedTag]);

  // const showTagDeleteModal = useCallback(
  //   (e: any, id: string) => {
  //     e.stopPropagation();
  //     // console.log("id", id);

  //     let selectedTagData = tagData.find((tag: any) => tag.id === id);
  //     if (selectedTagData && Object.keys(selectedTagData).length) {
  //       setSelectedTag(selectedTagData);
  //     }
  //     setShowDeleteConfirmModal((value) => !value);
  //   },
  //   [setShowDeleteConfirmModal, tagData, setSelectedTag, selectedTag]
  // );

  //Delete Tag
  const deleteTag = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.CAMPAIGN_TAG.getTagDeleteUrl(selectedTag.id);
    const response = await callApi({
      url,
      method
    });
    if (response.success || true) {
      toggleDeleteTagModal();
      // await fetchTags();
    }
  }, [selectedTag]);
  const getTagsList = useMemo(() => {
    if (Array.isArray(tagData)) {
      if (activeKey === 'Pending') {
        return tagData?.filter((tg) => tg?.show && !tg?.processed);
      } else if (activeKey === 'Processed') {
        return tagData?.filter((tg) => tg?.show && tg?.processed);
      } else {
        return tagData?.filter((tg) => tg?.show);
      }
    } else return [];
  }, [tagData, activeKey]);
  console.log("getTagsListgetTagsList", getTagsList.length)
  useEffect(() => {
    fetchFolderTags();
  }, [uId]);

  const fetchFolderTags = async () => {
    if (uId) {
      const { url, method } = API_ENDPOINTS.UNIBOX.getTags(uId || "");
      let response: any = await callApi({
        url,
        method
      });
      // response = dataTag;
      if (response?.success) {
        setState((prev: any) => ({ ...prev, setAllFolderTags: response?.data?.tags }));
      }
    }
  };

  const handleTabChange = async (key: string) => {
    setState((prev: any) => ({ ...prev, activeKey: key }));
  };
  useEffect(() => {
    if (isArray(setAllFolderTags) && setAllFolderTags?.length > 0) {
      // const filterFolderBaseTag: any = setAllFolderTags?.filter((vl) => (activeKey === "Processed" ? vl?.processed : !vl?.processed));
      const filterFolderBaseTag: any = setAllFolderTags;

      const checkSeq = filterFolderBaseTag?.map(({ seq }: any) => seq);
      const leastValue = min(checkSeq);
      const defultTag: any = filterFolderBaseTag?.find(({ seq }: any) => seq === leastValue);
      setTagId(defultTag?.id ?? "");
      let newNameArray = [];
      for (let i = 0; i < filterFolderBaseTag.length; i++) {
        let newObj = {
          label: filterFolderBaseTag[i].name,
          value: filterFolderBaseTag[i].name.toUpperCase(),
          id: filterFolderBaseTag[i].id,
          type: filterFolderBaseTag[i]?.std ? "standard" : "custom",
          show: filterFolderBaseTag[i].show,
          unread: filterFolderBaseTag[i].unread,
          seq: filterFolderBaseTag[i].seq,
          "processed": filterFolderBaseTag[i].processed,
          "apply": filterFolderBaseTag[i].apply,
        };
        newNameArray.push(newObj);
      }
      setTagData([...newNameArray]);
      setAllTagData(filterFolderBaseTag);
      if (Array.isArray(newNameArray) && newNameArray?.length > 0) {
        setSelectedTag(newNameArray[0]);
      }
    }
  }, [activeKey, setAllFolderTags]);

  return (
    <>
      <Tabs activeKey={activeKey} onChange={handleTabChange} tabBarGutter={16}>
        <TabPane tab="Processed" key="Processed" />
        <TabPane tab="Pending" key="Pending" />
      </Tabs>
      <Flex mt={10}>
        <Sider
          style={{
            background: "transparent"
          }}
          className={classes.responseTab}
        >
          {tagId.length > 0 ? (
            <Menu
              mode="inline"
              defaultSelectedKeys={[tagId]}
              defaultOpenKeys={[tagId]}
              onClick={onClickSideNav}
              selectedKeys={[tagId]}
              className={`${classStyle.scrollContainer} ${classes.responseMenu}`}
              style={{ height: "100%", maxHeight: "100%", overflowY: "auto", overflowX: "hidden" }}
            >
              {getTagsList?.map(({ label, value, id, type, show, unread }: any) => (
                <>
                  {" "}
                  {show ? (
                    <Menu.Item key={id} style={{ margin: 0, paddingLeft: "8px", paddingRight: "2px" }} title={value} className={classes.responseMenuList}>
                      <Text variant="sub2" color={tagId === id ? "primary" : undefined} className={classes.responseName} style={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "27px" }}>
                          <div style={{ maxWidth: unread === 0 ? "100%" : "82px", textOverflow: "ellipsis", overflow: "hidden" }}>{label}</div>
                          {unread > 0 ? (
                            <span
                              style={{
                                backgroundColor: "#f3f3f3",
                                borderRadius: "4px",
                                padding: "2px 3px",
                                marginLeft: "4px",
                                marginRight: "4px",
                                lineHeight: "initial"
                              }}
                            >
                              <span style={{ fontWeight: "400", color: "#000" }}>{unread}</span>
                            </span>
                          ) : null}
                        </div>
                        {type !== "standard" && (
                          <div className={classes.actionWrapper}>
                            <span className={classes.editIcon} onClick={(e: any) => onClickEditTag(e, id)}>
                              {" "}
                              <EditOutlined />
                            </span>
                            {/* <span className={classes.deleteIcon} onClick={(e: any) => showTagDeleteModal(e, id)}><DeleteOutlined /></span> */}
                          </div>
                        )}
                      </Text>
                    </Menu.Item>
                  ) : null}
                </>
              ))}
            </Menu>
          ) : null}
        </Sider>
        <Flex ml={20} grow={1} direction="column" className={classes.emailResponseDetails}>
          {tagId.length < 1 ? (
            <Flex mt={120} justify="center" direction="column">
              <Div align="center" mt={10}>
                <img src={NewMailImg} style={{ width: "150px" }} alt="No Email" />
                <Text variant="body1" mt={30} align="center" style={{ lineHeight: 1.2 }} block>
                  There are no responses yet
                </Text>
              </Div>
            </Flex>
          ) : null}
          {tagId.length > 0 && (
            <InboundMails
              fetchTags={() => fetchFolderTags()}
              tagData={allTgas}
              accountId={accountId}
              levelType={levelType}
              campaignId={campaignId}
              tagId={tagId}
              responseType={responseType}
              accountName={accountName}
              isFromUnibox={isFromUnibox}
              isProccesed={activeKey === "Processed"}
            />
          )}
        </Flex>
      </Flex>

      {showTagModal && (
        <Modal visible onCancel={onCanelPress} footer={null} width="max-content" centered>
          <Text variant="h6" mb={15} mt={5} color={"secondary"}>
            Update tag
          </Text>
          <Form onFinish={editTag} initialValues={{ remember: true }} autoComplete="off">
            <Flex pt={10} pb={5}>
              <Text variant="sub1" color="secondary" mr={20} style={{ width: 60 }}>
                Tag
              </Text>
              <Form.Item
                name={"Tag"}
                initialValue={selectedTag.label}
                style={{ marginBottom: `0px`, marginLeft: "-1%" }}
                rules={[
                  {
                    required: true,
                    message: "Please write tag name"
                  }
                ]}
              >
                <Input
                  onChange={(e) => handleTagInputChange("label", e.target.value)}
                  style={{ width: 200, color: "#000" }}
                  size="middle"
                  value={selectedTag.label}
                />
              </Form.Item>
            </Flex>
            <Flex mb={10} justify="flex-end" mt={20}>
              <Space>
                <Button type="default" onClick={onCanelPress}>
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </Space>
            </Flex>
          </Form>
        </Modal>
      )}

      {showDeleteConfirmModal && (
        <Modal
          visible
          okButtonProps={{
            type: "primary",
            danger: true,
            icon: <DeleteOutlined />
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={toggleDeleteTagModal}
          onOk={() => deleteTag()}
          cancelButtonProps={{ type: "text" }}
          centered
        >
          <Text variant="body2">
            Are you sure you want to{" "}
            <Text variant="body2" bold>
              delete?
            </Text>
          </Text>
        </Modal>
      )}
    </>
  );
};

export default TagV2;
