import { ProtectedRoute } from "auth/PrivateRoute";
import NotFoundPage from "pages/404";
import CampaignDetailPage from "pages/CampaignDetail";
import CampaignsPage from "pages/Campaigns";
import CompanySearch from "pages/CompanySearch";
import InsightsPage from "pages/Insights";
import Integrations from "pages/Integrations";
import LoginPage from "pages/Login";
import OauthCallbackPage from "pages/OauthCallback";
import DoNotContact from "pages/Settings/DoNotContact";
import Holidays from "pages/Settings/Holiday/HolidaysModule";
import MailBoxList from "pages/Settings/Mailbox";
import MailBoxDetail from "pages/Settings/MailBoxDetail";
import DomainPage from "pages/Domain/(domain)";
import ProspectDetail from "pages/Settings/ProspectDetail";
import React from "react";
import { BrowserRouter, Route, RouteComponentProps, Switch } from "react-router-dom";
import { ivyRoutes } from "./RouterAgency";
import UniboxPage from 'pages/Unibox'
import Notification from 'pages/Notifications'
import Subscription from 'pages/Subscription'
import DoNotContactNew from "pages/Settings/DoNotContactNew";
import Report from 'pages/Report'

const AUTHENTICATION_PATH = "/login";

const UserRoutes: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact={true} path="/login" render={() => <LoginPage />} /> */}

        <ProtectedRoute exact={true} path="/insights" component={InsightsPage} authenticationPath={AUTHENTICATION_PATH} title="Insights" />
        <ProtectedRoute path="/campaigns/:id" component={CampaignDetailPage} authenticationPath={AUTHENTICATION_PATH} title="Campaign Details" />
        <ProtectedRoute path="/account/:id/domains" component={DomainPage} authenticationPath={AUTHENTICATION_PATH} title="Domain" />
        <ProtectedRoute exact={true} path="/campaigns" component={CampaignsPage} authenticationPath={AUTHENTICATION_PATH} title="Campaigns" />
        <ProtectedRoute exact path="/oauth/callback" component={OauthCallbackPage} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute path="/account/:id/campaigns" component={CampaignsPage} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute path="/search" component={CompanySearch} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/dnclist" component={DoNotContact} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/dnclist-new" component={DoNotContactNew} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/mailboxes" component={MailBoxList} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/holidays" component={Holidays} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/integrations" component={Integrations} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/mailboxes/:id" component={MailBoxDetail} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/prospects/:id" component={ProspectDetail} authenticationPath={AUTHENTICATION_PATH} />
        {/* <ProtectedRoute exact={true} path="/account/:id/unibox" render={() => <UniboxPage levelType="ACCOUNT" campaignId="" />} authenticationPath={AUTHENTICATION_PATH} /> */}
        <ProtectedRoute exact={true} path="/unibox" render={() => <UniboxPage levelType="USER" campaignId="" />} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/notifications" render={() => <Notification />} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/subscription" render={() => <Subscription />} authenticationPath={AUTHENTICATION_PATH} />
        <ProtectedRoute exact={true} path="/report" component={Report} authenticationPath={AUTHENTICATION_PATH} />

        {/* <Route path="/login" component={LoginPage} /> */}
        <Route
          exact
          path="/login"
          component={({ location }: RouteComponentProps<{}, any, { from: string }>) => {
            return ivyRoutes(location);
          }}
        />
        {/* <ProtectedRoute exact={true} path="" component={LoginPage} authenticationPath={AUTHENTICATION_PATH} /> */}
        <ProtectedRoute exact={true} path="" component={LoginPage} authenticationPath={AUTHENTICATION_PATH} />

        {/* <Redirect exact from="" to="/accounts" /> */}
        {/* <Redirect exact from="/" to="/campaigns" /> */}
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default UserRoutes;
