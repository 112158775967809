import AppBar, { NavPathType } from "components/AppBar";
import React, { useMemo, useState } from "react";
import { useAuth } from "auth/AuthProvider";
import { PageContainer, PageBackground } from "styles/layout";
import useLogoutTimer from 'hooks/useLogoutTimer'; // Import your custom hook

export type SetNavPathsFn = (navPaths: NavPathType[]) => void;

const withAppBar = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, "setNavPaths">> => ({ ...props }) => {
  const { logout, user } = useAuth();
  const [navPaths, setNavPaths] = useState([]);
  useLogoutTimer(user ? true : false);
  const { name, email, sub } = useMemo(() => {
    if (!user) {
      return { name: "", email: "" };
    }
    const {
      attributes: { email, family_name, given_name, sub } = {
        email: "",
        family_name: "",
        given_name: "",
        sub: ''
      },
    }: any = user;

    return {
      name: `${given_name} ${family_name}`,
      email,
      sub
    };
  }, [user]);

  return (
    <>
      <AppBar logout={logout} name={name} email={email} navPaths={navPaths} userId={sub} />
      <PageBackground>
        <PageContainer>
          <Component {...(props as P)} setNavPaths={setNavPaths} />
        </PageContainer>
      </PageBackground>
    </>
  );
};

export default withAppBar;
