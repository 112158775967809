import { Button, Modal, Typography } from "antd";


interface Proxy {
  id: string;
  host: string;
  port: string;
  user: string;
  pwd: string;
}

interface TroubleShootModalProps {
  proxyDetail: Proxy;
  handleTroubleShootModal: () => void;
}

const TroubleShootModal = ({
  proxyDetail,
  handleTroubleShootModal
}: TroubleShootModalProps) => {
  const { Title } = Typography;

  return (
    <Modal
      visible
      footer={null}
      closable={true}
      // keyboard={false}
      onCancel={() => handleTroubleShootModal()}
      width={300}
      centered
    >
      <Title level={5}>Couldn’t sign you in</Title>
      <p className="fs-12" style={{ marginBottom: "15px" }}>
        Unable to connect to your mailbox using our proxy server. Please login to your mailbox once using the below proxy server and then come back to emy and try to onboard again. Sorry for the inconvenience.
      </p>

      <p className="fs-12" style={{ marginBottom: "1px" }}><b>Proxy Host:</b> {proxyDetail.host}</p>
      <p className="fs-12" style={{ marginBottom: "1px" }}><b>Proxy Port:</b> {proxyDetail.port}</p>
      <p className="fs-12" style={{ marginBottom: "1px" }}><b>Proxy Username:</b> {proxyDetail.user}</p>
      <p className="fs-12" style={{ marginBottom: "20px" }}><b>Proxy Password:</b> {proxyDetail.pwd}</p>
      <p>
        For detailed instructions, <a target="_blank" href={"https://emy-reports.s3.amazonaws.com/gmail_proxy.html"} rel="noreferrer">click here.</a>
      </p>
    </Modal>
  );
};

export default TroubleShootModal;
