import { useEffect, useState, useContext, useCallback } from "react";
import { Checkbox, Input, Form, Button, Row, Col, message, Card, Switch } from "antd";
import { Div, Flex } from "styles/layout";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import { NavPathType } from "components/AppBar";
import { Text } from "styles/typography";
import { BOX_SHADOW, WHITE_COLOR } from "utils/colors";
import { API_ENDPOINTS } from "constants/api";
import useApi from "hooks/useApi";
import appContext from "contexts/appContext";
import classes from "./styles.module.css";

/*import logoquicklines from "images/logo/logo-quicklines.png";
import logoscrubby from "images/logo/logo-scrubby.png";
import logoinboxy from "images/logo/logo-inboxy.png";
import logosalesforce from "images/logo/logo-salesforce.svg";*/

interface IntegrationsPageProps {
    setNavPaths: SetNavPathsFn;
}

const Integrations = ({ setNavPaths }: IntegrationsPageProps) => {
    const { setLoadingMessage } = useContext(appContext);
    const [enableQuicklines, setEnableQuicklines] = useState(false);
    const [enableScrubby, setEnableScrubby] = useState(false);
    const [enableInboxy, setEnableInboxy] = useState(false);
    const [enableSalesForce, setEnableSalesForce] = useState(false);
    const [quicklinesInput, setQuicklinesInput] = useState('');
    const [scrubbyInput, setScrubbyInput] = useState('');
    const [inboxyInput, setInboxyInput] = useState('');
    const [salesUserName, setSalesForceUserName] = useState('');
    const [salesPassword, setSalesForcePassword] = useState('');
    // const [,setEnableSalesForce]
    const { callApi } = useApi();

    useEffect(() => {
        const navPaths: NavPathType[] = [];
        navPaths.push({
            label: 'Integrations',
        })
        setNavPaths(navPaths);
        return () => setNavPaths([]);
    }, [setNavPaths]);

    const fetchIntegrationSettings = async () => {
        setLoadingMessage("Fetching Integration Settings ...");
        const { url, method } = API_ENDPOINTS.INTEGRATIONS.getIntegrationSettingsUrl();
        const response = await callApi({
            url,
            method,
        });

        if (response.success) {
            setQuicklinesInput(response.data.integration.quicklines.apiKey);
            setScrubbyInput(response.data.integration.scrubby.apiKey);
            setInboxyInput(response.data.integration.inboxy.apiKey);
            setEnableScrubby(response.data.integration.scrubby.enable);
            setEnableQuicklines(response.data.integration.quicklines.enable);
            setEnableInboxy(response.data.integration.inboxy.enable);
            setEnableSalesForce(response.data.integration.salesforce.enable);
            setSalesForceUserName(response.data.integration.salesforce.username);
            setSalesForcePassword(response.data.integration.salesforce.password);
            // response.data.integration.salesforce.leadStatus = ["Email Sent", "Email Opened"]
            // "salesforceMapping": [{ "event": "Sent", "enabled": true, "sfdcLeadStatus": "Email Sent" }],
            localStorage.setItem("integrationData", JSON.stringify(response.data.integration));
        }
        setLoadingMessage("");
    };

    useEffect(() => {
        fetchIntegrationSettings();
    }, [])


    const saveIntegrationSettings = async () => {
        setLoadingMessage("Saving Integration Settings ...");
        const { url, method } = API_ENDPOINTS.INTEGRATIONS.saveIntegrationSettings();
        const payload = {
            "scrubby": { enable: true, apiKey: scrubbyInput },
            "inboxy": { enable: enableInboxy, apiKey: inboxyInput },
            "quicklines": { enable: enableQuicklines, apiKey: quicklinesInput },
            "salesforce": { enable: enableSalesForce, username: salesUserName, password: salesPassword }
        }
        const response = await callApi({
            url,
            method,
            data: payload
        });

        if (response.success) {
            message.success({
                content: `Integrations setting updated.`,
                key: "updating integrations setting",
                duration: 2
            });
        }
        fetchIntegrationSettings();
        setLoadingMessage("");

    };

    return (
        <>
            <Form>
                <Div className={classes.formCardBox}>

                    <Row gutter={12} className={classes.formInputBorder}>

                        <Col md={8} span={24} style={{ marginBottom: "15px" }}>
                            <Card style={{ width: "100%", boxShadow: BOX_SHADOW, cursor: "pointer", height: "100%" }}>
                                <Div style={{ marginBottom: "30px", flexDirection: "column" }}>

                                    <Div style={{ display: "flex", alignItems: "center" }}><img src={"https://assets.website-files.com/61081563fd06bcd2ed476ea0/61247812d94eda6ad2512607_flash.png"} alt="" style={{ height: "30px" }} />
                                        <Text variant="h5" color="primary" style={{ width: "100%" }}>
                                            Quicklines
                                        </Text>
                                        <span><Switch checked={enableQuicklines} onChange={() => setEnableQuicklines(prev => !prev)} /></span>
                                    </Div>
                                </Div>

                                <Form.Item className="mt-2" style={{ marginBottom: "10px" }}>
                                    <Div className={classes.floatingLabelGroup}>
                                        <Input style={{ border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px" }}
                                            autoComplete="off" autoFocus required
                                            className={quicklinesInput && quicklinesInput.length ? "activeFocus" : ""}
                                            value={quicklinesInput} disabled={!enableQuicklines}
                                            onChange={(e) => setQuicklinesInput(e.target.value)}></Input>
                                        <label className={classes.floatingLabel}>
                                            <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                                Enter API Key
                                            </Text>
                                        </label>
                                    </Div>
                                </Form.Item>

                                {/*<Flex justify="start">
                                    <Checkbox style={{marginBottom:"15px"}} checked={enableQuicklines} onChange={(e) => setEnableQuicklines(e.target.checked)}>
                                        <Text variant="sub2">
                                            Enable Quicklines
                                        </Text>
                                    </Checkbox>
                                </Flex>*/}


                            </Card>
                        </Col>

                        <Col md={8} span={24} style={{ marginBottom: "15px" }}>
                            <Card style={{ width: "100%", boxShadow: BOX_SHADOW, cursor: "pointer", height: "100%" }}>
                                <Div style={{ marginBottom: "30px", flexDirection: "column" }}>
                                    <Div style={{ display: "flex", alignItems: "center" }}>
                                        <div><img src={"https://www.scrubby.io/favicon.ico"} alt="" style={{ height: "35px" }} /></div>
                                        <Text variant="h5" color="primary" style={{ width: "100%" }}>
                                            Scrubby
                                        </Text>
                                        <span><Switch checked={enableScrubby} onChange={() => setEnableScrubby(prev => !prev)} /></span>
                                    </Div>
                                </Div>

                                {/*<Form.Item className="mt-2" style={{marginBottom: "10px"}}>
                                <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                            Enter API Key
                                        </Text>
                                    <Input style={{border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px"}} 
                                    value={scrubbyInput} 
                                    disabled={!enableScrubby} 
                                    onChange={(e) => setScrubbyInput(e.target.value)}></Input>
                                </Form.Item>*/}

                                <Form.Item className="mt-2" style={{ marginBottom: "10px" }}>
                                    <Div className={classes.floatingLabelGroup}>
                                        <Input style={{ border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px" }}
                                            autoComplete="off" autoFocus required
                                            className={scrubbyInput && scrubbyInput.length ? "activeFocus" : ""}
                                            value={scrubbyInput}
                                            disabled={!enableScrubby}
                                            onChange={(e) => setScrubbyInput(e.target.value)}></Input>
                                        <label className={classes.floatingLabel}>
                                            <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                                Enter API Key
                                            </Text>
                                        </label>
                                    </Div>
                                </Form.Item>

                                {/*<Checkbox checked={enableScrubby} onChange={(e) => setEnableScrubby(e.target.checked)}>
                                <Text variant="sub2">
                                    Enable Scrubby
                                </Text>
                                </Checkbox>*/}

                            </Card>
                        </Col>

                        <Col md={8} span={24} style={{ marginBottom: "15px" }}>
                            <Card style={{ width: "100%", boxShadow: BOX_SHADOW, cursor: "pointer", height: "100%" }}>
                                <Div style={{ marginBottom: "30px", flexDirection: "column" }}>

                                    <Div style={{ display: "flex", alignItems: "center" }}><img src={"https://www.inboxy.io/favicon.png"} alt="" style={{ height: "30px" }} />
                                        <Text variant="h5" color="primary" style={{ width: "100%", marginLeft: "8px" }}>
                                            Inboxy
                                        </Text>
                                        <span><Switch checked={enableInboxy} onChange={() => setEnableInboxy(prev => !prev)} /></span>
                                    </Div>
                                </Div>

                                {/*<Form.Item className="mt-2" style={{marginBottom: "10px"}}>
                                <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                            Enter API Key
                                        </Text>
                                    <Input style={{border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px"}} 
                                    value={inboxyInput} 
                                    disabled={!enableInboxy} 
                                    onChange={(e) => setInboxyInput(e.target.value)}></Input>
                                </Form.Item>*/}

                                <Form.Item className="mt-2" style={{ marginBottom: "10px" }}>
                                    <Div className={classes.floatingLabelGroup}>
                                        <Input style={{ border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px" }}
                                            autoComplete="off" autoFocus required
                                            className={inboxyInput && inboxyInput.length ? "activeFocus" : ""}
                                            value={inboxyInput}
                                            disabled={!enableInboxy}
                                            onChange={(e) => setInboxyInput(e.target.value)}></Input>
                                        <label className={classes.floatingLabel}>
                                            <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                                Enter API Key
                                            </Text>
                                        </label>
                                    </Div>
                                </Form.Item>

                                {/*<Checkbox checked={enableInboxy} onChange={(e) => setEnableInboxy(e.target.checked)}>
                                    <Text variant="sub2">
                                    Enable Inboxy
                                    </Text>
                                </Checkbox>*/}

                            </Card>
                        </Col>

                        <Col md={8} span={24} style={{ marginBottom: "15px" }}>
                            <Card style={{ width: "100%", boxShadow: BOX_SHADOW, cursor: "pointer", height: "100%" }}>
                                <Div style={{ marginBottom: "30px", flexDirection: "column" }}>

                                    <Div style={{ display: "flex", alignItems: "center" }}> <img src={"https://www.salesforce.com/content/dam/www/ocms/common/assets/images/form/salesforce-logo-122x86.png"} alt="" style={{ height: "40px" }} />
                                        <Text variant="h5" color="primary" style={{ width: "100%", marginLeft: "8px" }}>
                                            Salesforce
                                        </Text>
                                        <span><Switch checked={enableSalesForce} onChange={() => setEnableSalesForce(prev => !prev)} /></span>
                                    </Div>
                                </Div>

                                <Div className="mt-2" style={{ marginBottom: "10px" }}>

                                    {/* <Form.Item style={{ paddingRight: "10px" }}>
                                       <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                            User Name
                                        </Text>
                                        <Input style={{border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px"}} 
                                        value={salesUserName} 
                                        disabled={!enableSalesForce} 
                                        onChange={(e) => setSalesForceUserName(e.target.value)}></Input>
                                    </Form.Item> */}

                                    <Form.Item className="mt-2" style={{ marginBottom: "35px" }}>
                                        <Div className={classes.floatingLabelGroup}>
                                            <Input style={{ border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px" }}
                                                autoComplete="off" autoFocus required
                                                className={salesUserName && salesUserName.length ? "activeFocus" : ""}
                                                value={salesUserName}
                                                disabled={!enableSalesForce}
                                                onChange={(e) => setSalesForceUserName(e.target.value)}></Input>
                                            <label className={classes.floatingLabel}>
                                                <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                                    User Name
                                                </Text>
                                            </label>
                                        </Div>
                                    </Form.Item>

                                    {/* <Form.Item >
                                    <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                            Password
                                        </Text>
                                        <Input.Password style={{border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px"}} 
                                        placeholder="Password" 
                                        value={salesPassword} 
                                        disabled={!enableSalesForce} 
                                        onChange={(e) => setSalesForcePassword(e.target.value)}></Input.Password>
                                    </Form.Item> */}

                                    <Form.Item className="mt-2" style={{ marginBottom: "10px" }}>
                                        <Div className={classes.floatingLabelGroup}>
                                            <Input.Password style={{ border: "none", borderBottom: "1px solid #d9d9d9", paddingLeft: "5px" }}
                                                autoComplete="off" autoFocus required
                                                className={salesPassword && salesPassword.length ? "activeFocus" : ""}
                                                value={salesPassword}
                                                disabled={!enableSalesForce}
                                                onChange={(e) => setSalesForcePassword(e.target.value)}></Input.Password>
                                            <label className={classes.floatingLabel}>
                                                <Text variant="overline" color="secondary" style={{ marginBottom: "5px" }}>
                                                    Password
                                                </Text>
                                            </label>
                                        </Div>
                                    </Form.Item>

                                    {/*<Checkbox checked={enableSalesForce} onChange={(e) => setEnableSalesForce(e.target.checked)}>
                                    <Text variant="sub2">
                                        Enable Salesforce
                                    </Text>
                                </Checkbox>*/}

                                </Div>
                            </Card>
                        </Col>

                    </Row>

                    <Flex justify="flex-end">
                        <Button type="primary" onClick={saveIntegrationSettings}>Save</Button>
                    </Flex>
                </Div>
            </Form>
        </>
    );
};

export default withAppBar(Integrations);