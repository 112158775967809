import { Button, Form, Input, Modal, Typography } from "antd";
import { Div, Flex } from "styles/layout";
import { DARK_GRAY_COLOR } from "utils/colors";
import { prefixSelector } from "components/MailBox/CountryCodePicker";

interface VerifyEmailByPhoneNoModalProps {
  submitPhoneNoToVerify: (formValues: any) => void;
  handleVerifyEmailByPhoneNo: () => void;
}

const VerifyEmailByPhoneNoModal = ({
  submitPhoneNoToVerify,
  handleVerifyEmailByPhoneNo,
}: VerifyEmailByPhoneNoModalProps) => {
  const { Title } = Typography;
  const [phoneNoVerifyForm] = Form.useForm();


  return (
    <Modal visible footer={null} onCancel={() => handleVerifyEmailByPhoneNo()} width={300} centered>
      <Form scrollToFirstError form={phoneNoVerifyForm} requiredMark={false} onFinish={submitPhoneNoToVerify}>
        {/* <p style={{ fontSize: '12px', marginBottom: '15px' }}>ddd.outwin@gmail.com</p> */}
        <Title level={5}>Enter your phone number, and we'll send you a security code</Title>
        {/* <p style={{ fontSize: '12px', marginTop: '15px', marginBottom: '15px' }}>We'll send a verification code in a text message. This helps us eliminate spam – we won't give out your number.
        The code will expire in about 10 minutes.</p> */}
        <Div>
          <label style={{ marginBottom: "5px" }} className="fs-12 fw-600 text-dark">
            Phone Number
          </label>
          <Form.Item name="phone" rules={[{ required: true, message: "Please input your phone number!" }]}>
            <Input addonBefore={prefixSelector} />
          </Form.Item>
        </Div>
        <Flex direction="row" ml={10} justify="flex-end">
          <Flex></Flex>
          <Flex ml={10}>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default VerifyEmailByPhoneNoModal;
