import { ReactNode, useCallback, useEffect, useState } from "react";
import classes from "./styles.module.css";
import { Flex, } from "styles/layout";
import { Text } from "styles/typography";
import { LIGHT_GRAY_COLOR } from "utils/colors";
import { FormInstance, Input, Button, Form, Select } from "antd";
import { TimeZoneType } from "utils/dateTime";
import TimeZones from "../../../commonAPIs/timezones";
const { Option } = Select;

export interface AddAccountModalProps {
  onContinue: (accountName: string, timeZone: string) => void;
  form: FormInstance;
}


const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex style={{ borderBottom: `1px solid ${LIGHT_GRAY_COLOR}`, width: `100%` }} pt={10}>
    {children}
  </Flex>
);
const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="sub1" color="secondary" mr={20} style={{ width: 150 }}>
    {children}
  </Text>
);


const AddAccountModal = ({
  onContinue,
  form,
}: AddAccountModalProps) => {

  const [timeZone, setTimeZone] = useState('');

  const [timezonArr, setTimezonArr] = useState<TimeZoneType[] | []>([]);

  const fetchTimezones = async () => {
    var timezones = await TimeZones.getTimezones();
    setTimezonArr(timezones);
  };

  useEffect(() => {
    fetchTimezones();
  }, [])

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  const timeupdate = (e: any) => {
    setTimeZone(e.value);
  }

  const onSubmit = useCallback(
    async (formValues: any) => {
      const { accountName, timezone } = formValues;
      onContinue(accountName, timezone);
    },
    [onContinue, timeZone]
  );

  return (
    <Form
      form={form}
      onFinish={onSubmit}
    >
      <Flex>
        <Text variant="h6" align="center" mt={5} mb={10} block>
          Create Account
        </Text>
      </Flex>
      <Flex justify="space-between">
        <DetailWrapper>
          <DetailHeading>Account Name</DetailHeading>
          <Form.Item
            name={"accountName"}
            rules={[
              {
                required: true,
                message: "Please enter account name",
              },
            ]}
            style={{ marginBottom: "5px", width: "100%", flex: "1 1 0", rowGap: "0" }}
          >
            <Input
              className="ant-input ant-input-sm w-100" size="small" style={{ paddingTop: "3px", paddingBottom: "3px" }}
            />
          </Form.Item>
        </DetailWrapper>
      </Flex>
      <DetailWrapper>
        <DetailHeading>Timezone</DetailHeading>
        <Form.Item
          name="timezone"
          initialValue={null}
          style={{ marginBottom: `5px`, width: "100%", flex: "1" }}
          rules={[{ required: true, message: "Please select timezone" }]}
        >
          <Select placeholder="Timezone"
          //  onSelect={(val: any, event: any) => timeupdate(event)}
          >
            {timezonArr && timezonArr.map(({ label, tzCode }: TimeZoneType) => (
              <Option key={label} value={tzCode}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </DetailWrapper>
      <Flex justify="flex-end" style={{ marginTop: "10px" }}>
        <Button htmlType="submit" type="primary">
          Create
        </Button>
      </Flex>
    </Form >
  );
};

export default AddAccountModal;