import { Button, Form, Input, Modal, Card, notification, message, Popover, Select } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { useState, useEffect, useMemo, useContext, ReactNode } from "react";
import { Flex, Div } from "styles/layout";
import { Text } from "styles/typography";
import { API_ENDPOINTS } from "constants/api";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { useAuth } from "auth/AuthProvider";
import { isNumber } from "lodash";

interface DownloadContactsProps {
    companyName: string[];
    downloadContactsLimit: number;
    onCancel: () => void;
    onOk: () => void;
    uploadedFilename: string;
    domains: string[];
    departments: string[];
    levels: string[];
    city: string[];
    state: string[];
    country: string[];
    industry: string[];
    subIndustry: string[];
    employees: string[];
    revenue: string[];
    title: string[];
    titleExactMatch: boolean;
    deliveryScore: number;

}

const DetailWrapper = ({ children }: { children: ReactNode }) => (
    <Flex pt={10} pb={5} ml={10} m={30} direction="column">
        {children}
    </Flex>
);

const DetailHeading = ({ children }: { children: ReactNode }) => (
    <Text variant="caption" color="secondary" style={{ paddingLeft: 3 }}>
        {children}
    </Text>
);

const DownloadContactsDialog = ({
    companyName,
    onCancel,
    onOk,
    uploadedFilename,
    downloadContactsLimit,
    domains,
    departments,
    levels,
    city,
    state,
    country,
    industry,
    subIndustry,
    employees,
    revenue,
    titleExactMatch,
    title,
    deliveryScore
}: DownloadContactsProps) => {
    const { setLoadingMessage } = useContext(appContext);
    const [form] = Form.useForm();
    const { callApi } = useApi();
    const [fileName, setFileName] = useState(uploadedFilename);
    var [contactPerCompany, setContactPerCompany] = useState('')

    const { user } = useAuth();
    const { email } = useMemo(() => {
        const {
            attributes: { email } = {
                email: ""
            },
        }: any = user;

        return {
            email,
        };
    }, [user]);

    const [emailId, setEmailId] = useState([email]);

    const getNotifyEmails = (e: any) => {
        setEmailId(e);
    }

    const validateNumber = (e: any) => {
        // const re = /^([1-9]|1[0123456789]|2[0123456789]|3[0123456789]|4[0123456789]|5[0])$/;
        const re = /^\d+$/
        if (e.target.value === '' || re.test(e.target.value)) {
            setContactPerCompany(e.target.value)
        }
    }

    const getPayload = (contactsPerCompany: any) => {

        // var emailSplit = emailId.split(',').map((x: any) => { return x.trim() });
        if (contactsPerCompany === '') {
            contactsPerCompany = '0';
        }
        var contactspercompany = parseInt(contactsPerCompany);
        let data = {
            "action": "DOWNLOAD",
            "adaptEntity": "CONTACT",
            "domains": domains,
            "companyName": companyName,
            "departments": departments,
            "levels": levels,
            "industry": industry,
            "subIndustry": subIndustry,
            "city": city,
            "state": state,
            "country": country,
            "revenue": revenue,
            "employees": employees,
            "title": title,
            "titleExactMatch": titleExactMatch,
            "deliveryScore": deliveryScore
        }
        return data;
    }


    const downloadContacts = async () => {

        if (fileName && emailId) {
            setLoadingMessage("Downloading Contacts ...");
            let account_id = String(localStorage.getItem("account_id"));
            const { url, method } = API_ENDPOINTS.CONTACT_SEARCH.adaptSearch();
            const extraPayload = {
                notifyEmails: emailId,
                contactsPerCompany: (!contactPerCompany || contactPerCompany === "") ? 0 : parseInt(contactPerCompany),
                downloadFilename: fileName
            }
            const response = await callApi({
                url,
                method,
                data: { ...getPayload(contactPerCompany), ...extraPayload }
            });
            if (response.success) {
                onOk();
            }
            setLoadingMessage("");
        } else {
            return
        }
    };
    return (
        <Modal
            visible
            centered
            onOk={downloadContacts}
            onCancel={onCancel}
            okText="Confirm"
        >
            <Div>
                <Text variant="h6" block mb={20}>
                    Download Contacts
                </Text>
                <Form
                    form={form}
                    name="addtocampaign"
                    scrollToFirstError
                    layout="vertical"
                    initialValues={{
                        Filename: uploadedFilename,
                        Email: email
                    }}
                >
                    <Text variant="overline" color="secondary" style={{ paddingLeft: 3 }}>
                        Contacts per Company
                    </Text>

                    <Form.Item>
                        <Input value={contactPerCompany} className="mt-2" style={{ width: '100%' }} onChange={(e) => validateNumber(e)} />
                        <p className="gray" style={{ fontSize: "12px", paddingTop: "8px" }}>To download all contacts per company, just leave this field empty.</p>
                    </Form.Item>

                    <Text variant="overline" color="secondary">
                        Filename
                    </Text>
                    <Form.Item name="Filename" rules={[{ required: true, message: "Filename is required" },]}>
                        <Input onChange={(e) => setFileName(e.target.value)} />
                    </Form.Item>
                    <Text variant="overline" color="secondary">
                        Notification email ADDRESSES
                    </Text>
                    <Flex style={{ marginBottom: '24px' }}>
                        <Form.Item style={{ width: "100%", margin: 0 }} name="Email" rules={[{ required: true, message: "Notification email address is required" },]}>
                            <Select dropdownClassName="display-none" className="mt-2"
                                mode="tags" style={{ width: '100%' }}
                                onChange={(e) => getNotifyEmails(e)} tokenSeparators={[',']}>
                            </Select>
                            {/* <Input onChange={(e) => getNotifyEmails(e)} /> */}
                        </Form.Item>
                    </Flex>
                    <Text variant="caption" color="secondary" mb={20}>
                        Contacts will be created in a file using the above filename and will be sent to you by email.
                    </Text>
                </Form>
            </Div>
        </Modal>
    )
}

export default DownloadContactsDialog;
