interface Theme {
  primaryColor: string;
  infoColor: string;
  successColor: string;
  errorColor: string;
  warningColor: string;
}
export type ThemeDetails = {
  antdConf: Theme,
  headerColor: string;
  textColor: string;
  textColorSecondary: string;
  textColorDark: string;
  textColorSecondaryDark: string;
  textColorInverse: string;
  whiteColor: string;
  blackColor: string;
  pageBackground: string;
  darkGrayColor: string;
  grayColor: string;
  lightGrayColor: string;
};

export const DEFAULT_THEME_CONFIG: ThemeDetails = {
  antdConf: {
    primaryColor: '#1890ff',
    infoColor: '#1890ff',
    successColor: '#52c41a',
    errorColor: '#ff4d4f',
    warningColor: '#faad14',
  },
  headerColor: 'grey',
  textColor: 'rgba(0,0,0,0.85)',
  textColorSecondary: 'rgba(0,0,0,0.45)',
  textColorDark: 'rgba(255,255, 255,0.85)',
  textColorSecondaryDark: 'rgba(255,255, 255,0.65)',
  textColorInverse: 'rgba(255,255, 255)',
  whiteColor: 'rgba(255,255, 255)',
  blackColor: '#000000',
  pageBackground: '#fafafa',
  darkGrayColor: '#95a5a6',
  grayColor: '#e8e4e4',
  lightGrayColor: '#f7f7f7',
};


export const THEME_MAP: { [key: string]: ThemeDetails } = {
  black: {
    ...DEFAULT_THEME_CONFIG,
    headerColor: 'grey',
    antdConf: {
      ...DEFAULT_THEME_CONFIG.antdConf,
      primaryColor: 'red'
    }
  },
  blue: {
    ...DEFAULT_THEME_CONFIG,
    headerColor: '#191954',
    antdConf: {
      ...DEFAULT_THEME_CONFIG.antdConf,
      primaryColor: '#39cdff'
    }
  },
  default: {
    ...DEFAULT_THEME_CONFIG,
    headerColor: '#0c254e',
    antdConf: {
      ...DEFAULT_THEME_CONFIG.antdConf,
      primaryColor: '#fa8c16'
    }
  }
}