import { DatePicker, Modal, Select, Button, Card, notification } from "antd";
import { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { TimeZoneType } from "utils/dateTime";
import moment from "moment";
import { getCampaignStatusText, getCampaignChangeStatusText } from "utils/campaign";
import { isDateLesserThanToday } from "utils/dateTime";
import TimeZones from "../../commonAPIs/timezones";
import { API_ENDPOINTS } from "constants/api";
import { useRouteMatch } from "react-router-dom";
import useApi from "hooks/useApi";
import appContext from "contexts/appContext";
import { useAuth } from "auth/AuthProvider";


const { Option } = Select;

interface ChangeCampaignStatusModalProps {
  onOk: () => void;
  onCancel: () => void;
  active: boolean;
  name: string;
  detail: boolean;
  status: string;
  timeZone: string;
  startDate: Moment | null;
  setStartDate: (date: Moment | null) => void;
  setTimeZone: any;
  campId:string
}

const ChangeCampaignStatusModal = ({
  onOk,
  onCancel,
  active,
  name,
  detail = false,
  startDate,
  timeZone,
  setTimeZone,
  status,
  setStartDate,campId
}: ChangeCampaignStatusModalProps) => {
  const [timezonArr, setTimezonArr] = useState<TimeZoneType[] | []>([]);
  const match = useRouteMatch<{ id: string; sequenceId?: string }>();
  const { callApi } = useApi();
  const { theme } = useAuth();

  const { setLoadingMessage } = useContext(appContext);
  let timer: any = null;

  const checkStatus = async (id: string) => {
    timer = setTimeout(async () => {
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getStatus(id);
      const res = await callApi({
        url,
        method,
      });

      if (res.success) {
        const { data } = res
        if (data) {
          if (data.isActivated === 'Completed') {
            clearTimeout(timer);
            setLoadingMessage("");
            window.location.reload();
          } else if (data.errorMessage && data.errorMessage.length) {
            notification.error({ message: data.errorMessage })
            clearTimeout(timer);
            setLoadingMessage("");
          } else {
            checkStatus(id);
          }
        } else {
          checkStatus(id);
        }
      }
      else {
        checkStatus(id);
      }
    }, 1000)
  }

  const ActivateNow = async () => {
    const {
      params: { id }
    } = match;
    setLoadingMessage("Activating Campaign........");
    if(campId){
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getStatusChangeUrl(campId);
      const payload = { "status": "Active", "activateNow": true };
      const response = await callApi({
        url,
        method,
        data: payload
      });
      setLoadingMessage("");
      if (response.success) {
        notification.success({ message: "Your campaign has been activated successfully and the emails will start going out shortly." });
        setLoadingMessage("please wait your changes are updating...");
        checkStatus(campId);
        // console.log("DONE !!");
  
        // if (detail) {
        //   window.location.reload();
        // }
      }
    }

  }

  const ActivateLater = () => {
    setIsActiveLaterActive(!isActiveLaterActive);
  }

  useEffect(() => {
    if (timeZone && timeZone.length) {
      setTimeZone(timeZone)
    }

    return () => clearTimeout(timer);
  }, [])

  const [isActiveLaterActive, setIsActiveLaterActive] = useState<boolean>(false);
  const [styleCard1, setStyleCard1] = useState<{ color: any }>({ color: "unset" });
  const [styleCard2, setStyleCard2] = useState<{ color: any }>({ color: "unset" });

  const [borderColorCard1, setBorderColorCard1] = useState({ borderColor: theme.darkGrayColor });
  const [borderColorCard2, setBorderColorCard2] = useState({ borderColor: theme.darkGrayColor });

  const [cardStyleCard1, setCardStyleCard1] = useState({ cursor: "pointer", width: "48%", border: "1px solid" });
  const [cardStyleCard2, setCardStyleCard2] = useState({ cursor: "pointer", width: "48%", border: "1px solid" });

  let cardHoverStyleCard1 = {
    ...cardStyleCard1, ...borderColorCard1
  }
  let cardHoverStyleCard2 = {
    ...cardStyleCard2, ...borderColorCard2
  }

  useEffect(() => {
    fetchTimezones();
    if (timeZone && timeZone.length) {
      setTimeZone(timeZone)
    }
  }, [])

  const fetchTimezones = async () => {
    var timezones = await TimeZones.getTimezones();
    setTimezonArr(timezones);
  };

  const getTimeZoneLabelValue = (timezone: string) => {
    const result = timezonArr.filter((time) => time.tzCode === timezone ? time.label : "")
    return result && result.length ? result[0]?.['label'] : ""
  }


  return (
    <>
      {status === "Active" ?
        <Modal
          visible={!isActiveLaterActive}
          onCancel={onCancel}
          centered
          onOk={onOk}
          okText={getCampaignStatusText(!(status === "Active"))}
        >
          <Div>
            {status === "Active" ? (
              <Text variant="sub2" block>
                Are you sure you want to {getCampaignChangeStatusText(status)} {name}
              </Text>
            ) : (
              <Text variant="h6" mt={5} mb={10} block>
                {getCampaignChangeStatusText(status)} Campaign
              </Text>
            )}
          </Div>
        </Modal> :
        <>
          <Modal
            visible={!isActiveLaterActive}
            onCancel={onCancel}
            footer={null}
            centered
            okText={getCampaignStatusText(!(status === "Active"))}
          >
            <Div>
              {status === "Active" ? (
                <Text variant="sub2" block>
                  Are you sure you want to {getCampaignChangeStatusText(status)} {name}
                </Text>
              ) : (
                <Text variant="h6" mt={5} mb={10} block>
                  {getCampaignChangeStatusText(status)} Campaign
                </Text>
              )}
              {status === "Inactive" && (

                <Flex

                  align="center"
                >
                  <Flex
                    mt={20}
                    mb={20}
                    className="contactAccountSearch-wrapper"
                    justify="space-between"
                    style={{ width: '100%' }}
                  >
                    <Card
                      style={cardHoverStyleCard1}
                      onMouseEnter={(e) => {
                        setStyleCard1({ color: theme.antdConf.primaryColor });
                        setBorderColorCard1({ borderColor: theme.antdConf.primaryColor })
                      }}
                      onMouseLeave={(e) => {
                        setStyleCard1({ color: "unset" });
                        setBorderColorCard1({ borderColor: theme.darkGrayColor })
                      }}
                      onClick={ActivateNow}
                    >
                      <Div>
                        <Text
                          style={styleCard1}
                          variant="sub1"
                          block
                          align="center"
                          className="card-title"
                          mb={10}
                          bold
                        >
                          Activate Now
                        </Text>

                        <Text
                          style={styleCard1}
                          variant="sub2"
                          block
                          align="center"
                        >Emails will start going out with in the next 30 minutes</Text>
                      </Div>

                    </Card>
                    <Card
                      style={cardHoverStyleCard2}
                      onMouseEnter={(e) => {
                        setStyleCard2({ color: theme.antdConf.primaryColor });
                        setBorderColorCard2({ borderColor: theme.antdConf.primaryColor })
                      }}
                      onMouseLeave={(e) => {
                        setStyleCard2({ color: "unset" });
                        setBorderColorCard2({ borderColor: theme.darkGrayColor })
                      }}
                      onClick={ActivateLater}
                    >
                      <Div>
                        <Text
                          style={styleCard2}
                          variant="sub1"
                          block
                          align="center"
                          className="card-title"
                          mb={10}
                          bold
                        >
                          Activate Later
                        </Text>

                        <Text
                          style={styleCard2}
                          variant="sub2"
                          block
                          align="center"
                        >You can set a future date and time </Text>
                      </Div>
                    </Card>
                  </Flex>
                </Flex>
              )}
            </Div>
          </Modal>
          <Modal
            visible={isActiveLaterActive}
            onCancel={() => {
              onCancel()
            }}
            centered
            footer={null}
          >
            {status === "Inactive" && (
              <Div>
                <Text variant="h6" mt={5} mb={10} block>
                  {getCampaignChangeStatusText(status)} Campaign
                </Text>
                <Div>
                  <Text variant="overline" block mt={30} color="secondary">
                    When should emails start going out?
                  </Text>
                  <DatePicker
                    style={{ width: "180px" }}
                    disabledDate={isDateLesserThanToday}
                    showToday={false}
                    format="YYYY-MM-DD HH:mm"
                    allowClear={false}
                    value={startDate}
                    onChange={setStartDate}
                    showTime={{ defaultValue: moment("00:00:00", "HH:mm") }}
                  />
                  <Text variant="overline" color="secondary" style={{ padding: 20, fontWeight: "bold", paddingLeft: 20 }}>
                    {getTimeZoneLabelValue(timeZone)}
                  </Text>
                </Div>
                <Div mt={30} style={{ textAlign: "right" }}>
                  <Button onClick={onOk} type="primary" >
                    Activate
                  </Button>
                </Div>
              </Div>
            )}
          </Modal>
        </>
      }
    </>
  );
};

export default ChangeCampaignStatusModal;
