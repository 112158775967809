import { Button, Form, Input, notification, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import MailBoxList, { MAILBOXLIST_FILTER } from "components/MailBox/List";
import MailBoxProviderDetailModal from "components/MailBox/Modal/MailBoxDetailProviderModal";
import MailBoxProvider, { getProviderDetails } from "components/MailBox/Provider";
import MailBoxSettings, { MailBoxSettingsType } from "components/MailBox/Settings";
import { API_ENDPOINTS } from "constants/api";
import { NEW_CAMPAIGN_LS_KEY, OAUTH_STATE_LS_KEY } from "constants/localstorage";
import appContext from "contexts/appContext";
import useApi from "hooks/useApi";
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Div, Flex } from "styles/layout";
import { Text } from "styles/typography";
import { CampaignType } from "types/Campaign";
import { MailBoxResponseType, MailboxTypeEnum } from "types/MailBox";
import { DARK_GRAY_COLOR } from "utils/colors";
import LocalStorage from "utils/localstorage";
import { v4 as uuid } from "uuid";
// import { EmptyMailboxPlaceholder } from "pages/Settings/Mailbox";
import IpsDetailsModal from "containers/IpsDetailsModal";
import LogDetailsModal from "containers/LogDetailsModal";
import SendEmailModal from "containers/sendEmailModal";
import withAppBar, { SetNavPathsFn } from "hoc/withAppBar";
import ManualGmailLoginModal from "pages/CampaignDetail/Modals/ManualGmailLoginModal";
import RecoveryEmailModal from "pages/CampaignDetail/Modals/RecoveryEmailModal";
import TroubleShootModal from "pages/CampaignDetail/Modals/TroubleShootModal";
import UpdatePasswordModal from "pages/CampaignDetail/Modals/UpdatePasswordModal";
import VerficationModal from "pages/CampaignDetail/Modals/VerficationModal";
import VerificationOptionsModal from "pages/CampaignDetail/Modals/VerificationOptionsModal";
import VerifyAccessCodeModal from "pages/CampaignDetail/Modals/VerifyAccessCodeModal";
import VerifyEmailByPhoneNoModal from "pages/CampaignDetail/Modals/VerifyEmailByPhoneNoModal";
import VerifyPhoneNoModal from "pages/CampaignDetail/Modals/VerifyPhoneNoModal";
import { useSelector } from "react-redux";
import { canModifyCampaignState } from "state/selectors";
import classes from "../Campaigns/styles.module.css";

import { NavPathType } from "components/AppBar";
import BoundConnectionModal from "pages/CampaignDetail/Modals/ConnectionModal";
import GmailIMAPLoginModal from "pages/CampaignDetail/Modals/GmailIMAPLoginModal";

interface CampaignMailboxesProps {
  campaignDetail: CampaignType;
  afterSave: () => void;
  loading: boolean;
  setLoading: (loadingMessage: string) => void;
}

const DetailHeading = ({ children }: { children: ReactNode }) => (
  <Text variant="caption" color="secondary" style={{ paddingLeft: 3 }}>
    {children}
  </Text>
);

const DetailWrapper = ({ children }: { children: ReactNode }) => (
  <Flex pt={10} pb={5} ml={10} m={30} direction="column">
    {children}
  </Flex>
);
interface Proxy {
  id: string;
  host: string;
  port: string;
  user: string;
  pwd: string;
}

export enum NewCampaignSteps {
  ONBOARDING = "ONBOARDING",
  MAILBOX_LISTING = "MAILBOX_LISTING",
  MAILBOX_PROVIDER = "MAILBOX_PROVIDER",
  MAILBOX_SETTINGS = "MAILBOX_SETTINGS",
  PROSPECT_UPLOAD = "PROSPECT_UPLOAD",
  PROSPECT_COLUMN_MAPPING = "PROSPECT_COLUMN_MAPPING",
  PROSPECT_SUMMARY = "PROSPECT_SUMMARY",
  SETUP = "SETUP"
}
export interface CampaignStateType {
  campaignId: null | string;
  currentStepName: NewCampaignSteps;
  prospectData: {
    fileUrl: null | string;
    columnHeaders: string[];
    sampleProspectData: string[];
    totalProspects: number;
  };
  prospectSummary: {
    total: number;
    failed: number;
    failedCsvLink: string;
    added: number;
  };
  selectedMailBoxesIds: string[];
}

export const CAMPAIGN_INITIAL_STATE: CampaignStateType = {
  campaignId: null,
  currentStepName: NewCampaignSteps.ONBOARDING,
  prospectData: {
    fileUrl: null,
    columnHeaders: [],
    sampleProspectData: [],
    totalProspects: 0
  },
  prospectSummary: {
    total: 0,
    failed: 0,
    failedCsvLink: "",
    added: 0
  },
  selectedMailBoxesIds: []
};

interface MailBoxPageProps {
  setNavPaths: SetNavPathsFn;
}

const Mailboxes = ({ setNavPaths }: MailBoxPageProps) => {
  const { currentStepName: currentStepNameInLs = CAMPAIGN_INITIAL_STATE.currentStepName } =
    (LocalStorage.getValue(NEW_CAMPAIGN_LS_KEY) as CampaignStateType) || {};
  const { callApi } = useApi();
  const location = useLocation<{ addEmailState?: string }>();
  const [instanceId, setInstanceId] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [task, setTask] = useState("");
  const [showText, setShowText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [mailBoxSettingsForm] = Form.useForm();
  const [subform] = Form.useForm();
  const [mailBoxOfficeSmtp] = Form.useForm();
  const [microsoftValidation] = Form.useForm();
  const [accessCodeValidation] = Form.useForm();
  const [alernateEmailValidation] = Form.useForm();
  const [updatePasswordform] = Form.useForm();
  // const [gmailMailConfirmation] = Form.useForm();

  const [showConnectEmailValidationModal, setshowConnectEmailValidationModal] = useState(false);
  const [showConnectMicrosoftValidation, setshowConnectMicrosoftValidationl] = useState(false);
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [isEmailValidation, setisEmailValidation] = useState(false);
  const [isWrongPassword, setisWrongPassword] = useState(false);
  const [isVerifyEmail, setisVerifyEmail] = useState(false);
  const [browserId, setBrowserId] = useState("");
  const [isWrongEmail, setisWrongEmail] = useState(false);
  const [editableId, setEditableId] = useState("");
  const [isEditMode, setisEditMode] = useState(false);
  const [isInBoundConnection, setisInBoundConnection] = useState(false);
  const [isOutBoundConnection, setisOutBoundConnection] = useState(false);
  const [isBoundConnection, setisBoundConnection] = useState(false);
  const [smtpInBoundError, setSmtpInBoundError] = useState("");
  const [smtpOutBoundError, setSmtpOutBoundError] = useState("");
  const [validating, setValidating] = useState(false);
  const [action, setAction] = useState("");
  const [showGmailConfirmationMailModal, setshowGmailConfirmationMailModal] = useState(false);
  const [show2StepVerificationModal, setshow2StepVerificationModal] = useState(false);
  const [showVerificationOptionsModal, setVerificationOptionsModal] = useState(false);
  const [isVerifyEmailForGmail, setisVerifyEmailForGmail] = useState(false);
  const [showManualGmailLogin, setManualGmailLogin] = useState(false);
  const [extensionStatus, setExtensionStatus] = useState("");
  const [installExtension, setInstallExtension] = useState(false);
  const [subMessage, setSubMessage] = useState("");
  const [connectModalStep, setStepWhenConnectionModalClosed] = useState(0);
  const [isEditModeForWorkspacePersonal, setEditModeWhileProviderWorkspacePersonal] = useState(false);

  const [proxy, setProxy] = useState<Proxy>({
    id: "",
    host: "",
    port: "",
    user: "",
    pwd: ""
  });
  const [showTroubleShootModal, setShowTroubleShootModal] = useState(false);

  // const [smtpValidationStatus, setsmtpValidationStatus] = useState('');

  const [providers, setProviders] = useState("");
  const [currentStepName, setCurrentStepName] = useState(currentStepNameInLs as NewCampaignSteps);
  const [Data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: ""
  });

  const isFree = useSelector(canModifyCampaignState(""));

  const history = useHistory();
  const inboxIds = useMemo(() => {
    return [];
  }, []);

  const [mailBoxes, setMailBoxes] = useState<MailBoxResponseType[]>([]);
  const [form] = Form.useForm();
  const [linkExistingForm] = Form.useForm();
  const [mailBoxProviderForm] = Form.useForm();

  const { setLoadingMessage } = useContext(appContext);
  const [showMailBoxProviderModal, setShowMailBoxProviderModal] = useState(false);
  const [showPausedMailboxModal, setShowPausedMailboxModal] = useState(false);
  const [showLinkExistingEmailModal, setShowLinkExistingEmailModal] = useState(false);
  const [showConnectMailboxModal, setShowConnectMailboxModal] = useState(false);
  const [showConnectMailStmpModal, setshowConnectMailStmpModal] = useState(false);
  const [isSettingSmtp, setIsSettingSmtp] = useState(false);
  const [showTextVerifyModal, setshowTextVerifyModal] = useState(false);
  const [showUpdatePasswordModal, setshowUpdatePasswordModal] = useState(false);
  const [accountData, setAccountData] = useState({ accountId: "", accountName: "" });
  const [campaignId, setCampaignId] = useState("");
  const [showMailBoxProviderDetailModal, setShowMailBoxProviderDetailModal] = useState(false);

  const [providerType, setProviderType] = useState("");

  const [selectedEmailForIps, setSelectedEmailForIps] = useState("");
  const [selectedInboxIdForLogs, setselectedInboxIdForLogs] = useState("");
  const [selectedInboxIdForSendEmail, setselectedInboxIdForSendEmail] = useState("");
  const [selectedInboxEmailForSendEmail, setselectedInboxEmailForSendEmail] = useState(""); //inboxId
  console.log("selectedInboxIdForLogs", selectedInboxIdForLogs);
  const oauthState = useMemo(() => {
    const oauthLocalState: {
      state: string;
    } | null = LocalStorage.getValue(OAUTH_STATE_LS_KEY);

    if (location.state?.addEmailState && oauthLocalState?.state && location.state?.addEmailState === oauthLocalState.state) {
      return oauthLocalState.state;
    }
    return "";
  }, [location]);

  const [selectedMailbox, setSelectedMailbox] = useState<{
    email: string;
    id: null | string;
    settings?: MailBoxSettingsType;
    campaignName: string | null;
    provider?: string;
    protocol?: string;
  } | null>({
    email: "",
    id: null,
    campaignName: ""
  });

  const isSelectedMailboxNew = useMemo(() => !!oauthState, [oauthState]);

  const showSettingsModal = useMemo(() => isSelectedMailboxNew || selectedMailbox?.id || isSettingSmtp, [isSelectedMailboxNew, selectedMailbox, isSettingSmtp]);
  const fetchInboxes = useCallback(async () => {
    // const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl();
    let account_id = String(localStorage.getItem("account_id"));
    const { url, method } = API_ENDPOINTS.MAILBOX.getListUrl(account_id);

    const response = await callApi({
      url,
      method
    });

    if (response.success) {
      response.data.inboxes.sort((first: any, second: any) => (first.email < second.email ? -1 : first.email > second.email ? 1 : 0));
      let sortedResult = sortingByCheckedAndUnchecked(response.data.inboxes, inboxIds);
      setMailBoxes(sortedResult);
    }
  }, [callApi]);

  const toggleShowModalSmtp = () => setshowConnectMailStmpModal((show) => !show);
  const handleVerifyEmailByPhoneNo = () => setshowConnectMicrosoftValidationl((show) => !show);
  const toggleShowModalEmailValidation = () => setshowConnectEmailValidationModal((show) => !show);
  const handleVerifyAccessCodeModal = () => setShowAccessCodeModal((show) => !show);
  const handleRecoveryEmailModal = () => setshowGmailConfirmationMailModal((show) => !show);
  const handleVerificationModal = () => setshow2StepVerificationModal((show) => !show);
  const handleVerificationOptionsModal = () => setVerificationOptionsModal((show) => !show);
  const handleTroubleShootModal = () => setShowTroubleShootModal((show) => !show);
  const handleManualGmailLoginModal = () => setManualGmailLogin((show) => !show);

  const sortingByCheckedAndUnchecked = (mailObj: any, inboxIds: any) => {
    var filteredArray = mailObj.filter(function (itm: any) {
      return inboxIds.indexOf(itm.id) > -1;
    });
    var filteredNotCheckedArray = mailObj.filter(function (itm: any) {
      return inboxIds.indexOf(itm.id) === -1;
    });
    Array.prototype.push.apply(filteredArray, filteredNotCheckedArray);
    return filteredArray;
  };

  const getDnsPublicDnsUrl = useCallback(
    async (email: string, password: string) => {
      let payload = {
        email: email,
        password: password,
        inboxProvider: "GOOGLE",
        test: false
      };
      let response;
      if (window.location && window.location.origin.includes("localhost")) {
        console.log("localhost called............");
        response = {
          success: true,
          data: {
            browserId: uuid(),
            publicDNS: "localhost",
            instanceId: "i-0864145ea18867fcb",
            proxy: {
              host: "135.148.125.52",
              id: uuid(),
              port: "8181",
              pwd: "vendisys@135",
              user: "vendisys"
            }
          }
        };
      } else {
        console.log("dev and live called............");
        const { url, method } = API_ENDPOINTS.DNSCONNECTION.getListUrl();
        response = await callApi({
          url,
          method,
          data: payload,
          external: true
        });
      }

      // let publicDns = 'https://brooklyn.outwinhq.io';
      let instanceId = "";
      let browserIds = "";
      let proxyServer: Proxy = {} as Proxy;
      if (response.success && response.data) {
        instanceId = response.data.instanceId;
        // publicDns = `https://brooklyn.outwinhq.io`;
        browserIds = response.data.browserId;
        setBrowserId(browserIds);
        if (response.data && response.data.proxy) {
          proxyServer = response.data?.proxy;
          setProxy(proxyServer);
          // proxyServer = {
          //   "host": "144.217.35.166",
          //   "port": "8181",
          //   "user": "vendisys",
          //   "pwd": "vendisys@135"
          // }
        }
        // setPublicDns(publicDns);
        setInstanceId(instanceId);
      } else {
        setLoadingMessage("");
        notification.error({
          message: "Server resources are currently being allocated. Please try again after 5 minutes."
        });
      }
      return { browserId: browserIds, instanceId, proxy: proxyServer };
    },
    [callApi, setProxy, setBrowserId, setInstanceId]
  );

  const { Title } = Typography;

  const onSubmit = useCallback(
    async ({ selectedMailBoxes }) => {
      notification.destroy();
      if (!selectedMailBoxes.length) {
        notification.error({
          message: "No mailboxes selected",
          description: "Select at least one mailbox to the campaign"
        });
        return;
      }
      // const { id } = campaignDetail;

      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(campaignId);
      console.log("hiiiiiiiiiiiiii");
      // const {
      //   autoRemoveEmailReplied,
      //   // autoRemoveEmailRepliedBy,
      //   days,
      //   endHour,
      //   name,
      //   removeProspectEmailLinkClick,
      //   removeProspectEmailOpen,
      //   removeProspectEmailReplied,
      //   startHour,
      //   sendEmailOnHolidays,
      //   timeZone,
      // } = campaignDetail;

      const payload = {};
      setLoadingMessage(`Updating mailboxes for ...`);
      const response = await callApi({
        url,
        method,
        data: payload
      });
      setLoadingMessage("");
      if (response.success) {
        notification.success({
          message: "Campaign Mailboxes updated"
        });

        // afterSave();
      }
    },
    [callApi, setLoadingMessage]
  );

  const [mailboxDelinking, setMailboxDelinking] = useState(false);
  const onDelete = useCallback(
    async (inboxId: string, email: string) => {
      setMailboxDelinking(true);
      notification.destroy();
      // const { id } = campaignDetail;
      // debugger;
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(campaignId);
      const payload = {
        action: "InboxRemove",
        inboxId: inboxId
      };
      const response = await callApi({
        url,
        method,
        data: payload
      });
      if (response.success) {
        notification.success({
          message: ""
          // message: `"${email}" removed from ${campaignDetail.name}`
        });

        // afterSave();
      }
      setMailboxDelinking(false);
    },
    [callApi]
  );

  const onSave = () => form.submit();

  const showMailBoxList = useMemo(() => !!mailBoxes.length, [mailBoxes]);

  const areNoMailBoxesLinked = useMemo(() => !mailBoxes.length, [mailBoxes]);

  const toggleMailBoxProviderDialog = () => {
    setShowMailBoxProviderModal((prev) => !prev);
    setisEditMode(false);
  };
  const togglePausedMailboxDialog = () => setShowPausedMailboxModal((prev) => !prev);

  const toggleLinkExistingMailboxDialog = () => setShowLinkExistingEmailModal((prev) => !prev);

  const toggleMailBoxProviderDatialDialog = () => {
    setShowMailBoxProviderDetailModal((prev) => !prev);
    setisEditMode(false);
  };

  const gotoBackButton = () => {
    // setShowMailBoxProviderModal((prev) => !prev);
    setShowMailBoxProviderDetailModal((prev) => !prev);
    setisEditMode(false);
  };

  const toggleMailboxValidating = () => {
    setisBoundConnection((prev) => !prev);
    setStepWhenConnectionModalClosed(3);
    toggleShowModal();
    setValidating(false);
    setSmtpInBoundError("");
    setSmtpOutBoundError("");
    setisInBoundConnection(false);
    setisOutBoundConnection(false);
  };

  const toggleVerifyPhoneNoForm = () => setshowTextVerifyModal((show) => !show);

  // useEffect(() => {
  //   fetchInboxes();
  // }, [fetchInboxes]);
  useEffect(() => {
    const mailId = location.pathname.replace("/mailboxes/", "");
    if (mailId) {
      fetchMailBoxSetting(mailId);
    }
    // fetchInboxes();
  }, [location]);

  useEffect(() => {
    const navPaths: NavPathType[] = [];
    if (accountData) {
      navPaths.push(
        {
          label: accountData.accountName,
          linkTo: `/account/${accountData.accountId}/campaigns`
        },
        { label: "Mailbox" }
      );
    }

    setNavPaths(navPaths);
    return () => setNavPaths([]);
  }, [setNavPaths, location, accountData]);

  const fetchMailBoxSetting = async (id: string) => {
    setLoadingMessage("Fetching your mailbox settings ...");
    const { url, method } = API_ENDPOINTS.MAILBOX.getDetailUrl(id);

    const response = await callApi({
      url,
      method
    });

    if (response.success) {
      const {
        email,
        signature = "",
        incrementPercent = 20,
        minEmails,
        maxEmails,
        timezone,
        days,
        startHour,
        endHour,
        provider,
        firstname,
        lastname,
        protocol,
        campaignName,
        capacity,
        accountId,
        autoForwardEmail
      } = response.data.inbox;
      setMailBoxes([response.data.inbox]);
      setAccountData({
        accountId: accountId,
        accountName: response.data.accountName
      });
      setCampaignId(response.data.id);
      setSelectedMailbox({
        email,
        id,
        provider,
        protocol,
        campaignName,
        settings: {
          incrementPercent,
          minEmails,
          maxEmails,
          signature,
          firstName: firstname,
          lastName: lastname,
          email,
          timeZone: timezone,
          days,
          startHour,
          endHour,
          capacity,
          autoForwardEmail
        }
      });
      setTimeout(() => setLoadingMessage(""), 1000);
      // setLoadingMessage("");
      setIsLoading(false);
      return;
    } else {
      setLoadingMessage("");
      setIsLoading(false);
    }
  };
  const getTaskFromAPI = useCallback(
    (task: string) => {
      setLoadingMessage("");
      switch (task) {
        case "wrong_password":
          setisWrongPassword(true);
          showError("Your Password is Wrong");
          break;
        case "verify_email":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(false);
          setisVerifyEmail(true);
          setshowConnectEmailValidationModal(true);
          break;
        case "error_notify":
          showError(errorMsg);
          break;
        case "wrong_email_account":
          setisWrongEmail(true);
          showError(errorMsg);
          break;
        case "accountLock":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(true);
          showError(errorMsg);
          break;
        case "phoneNoCheckVerify":
          setshowConnectMailStmpModal(false);
          setshowTextVerifyModal(true);
          break;
        case "invalidPhoneNo":
          showError(errorMsg);
          break;
        case "update_password":
          setisWrongPassword(false);
          setShowAccessCodeModal(false);
          setshowUpdatePasswordModal(true);
          break;
        case "accessCode":
          setisEmailValidation(false);
          setshowConnectMicrosoftValidationl(false);
          setShowAccessCodeModal(true);
          break;
        case "code_screen":
          setisEmailValidation(true);
          setshowConnectEmailValidationModal(false);
          setShowAccessCodeModal(true);
          break;
        case "login_success":
          setisEmailValidation(false);
          setisVerifyEmail(false);
          setisVerifyEmailForGmail(false);
          setshowConnectMailStmpModal(false);
          setShowAccessCodeModal(false);
          setshowGmailConfirmationMailModal(false);
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setExtensionStatus("");
          setManualGmailLogin(false);
          setIsSettingSmtp(true);
          break;
        case "gmail_recover_email":
          setshowConnectMailStmpModal(false);
          setshowConnectMicrosoftValidationl(false);
          setVerificationOptionsModal(false);
          setisVerifyEmail(true);
          setisVerifyEmailForGmail(true);
          setshowGmailConfirmationMailModal(true);
          break;
        case "gamil_2_step_verification":
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(true);
          break;
        case "gmail_invalid_email":
          setisWrongEmail(true);
          setisWrongPassword(false);
          showError(errorMsg);
          break;
        case "gmail_process_again":
          setshowConnectMailStmpModal(true);
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(false);
          showError(errorMsg);
          break;
        case "gmail_wrong_password":
          setisWrongPassword(true);
          setisWrongEmail(false);
          showError(errorMsg);
          break;
        case "gamil_login_success":
          setshowConnectMailStmpModal(false);
          setshowGmailConfirmationMailModal(false);
          setshow2StepVerificationModal(false);
          setShowAccessCodeModal(false);
          setIsSettingSmtp(true);
          break;
        case "G_ChooseVerification":
          setisEmailValidation(false);
          setVerificationOptionsModal(false);
          setshowConnectMicrosoftValidationl(false);
          setShowAccessCodeModal(true);
          break;
        case "G_verification_options":
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setVerificationOptionsModal(true);
          break;
        case "unable_to_connect":
          setisEmailValidation(false);
          setisVerifyEmail(false);
          setisVerifyEmailForGmail(false);
          setshowConnectMailStmpModal(false);
          setShowAccessCodeModal(false);
          setshowGmailConfirmationMailModal(false);
          setisWrongPassword(false);
          setisWrongEmail(false);
          showError("");
          setShowTroubleShootModal(true);
          break;
        case "navigation_timeout":
          setExtensionStatus("installed");
          setManualGmailLogin(true);
          break;
        default:
          break;
      }
    },
    [task, errorMsg]
  );

  const showError = (error: any) => {
    if (error && error.length) {
      notification.error({
        message: error
      });
    }
  };

  useEffect(() => {
    if (task) {
      getTaskFromAPI(task);
    }
  }, [getTaskFromAPI, task, errorMsg]);

  const confirmRecoveryEmail = useCallback(
    async (formValues: any) => {
      setLoadingMessage("verifying Email.");
      const { alternate_email } = formValues;
      // gmailMailConfirmation.resetFields();
      if (isVerifyEmail) {
        if (isVerifyEmailForGmail) {
          let data = {
            alternate_email: alternate_email,
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "verify_recovery_email",
            browserId
          };
          await performTask(data);
        }
      }
    },
    [isVerifyEmail, Data, browserId, setLoadingMessage, isVerifyEmailForGmail]
  );

  const onOptionSelectHandler = useCallback(
    async (formValues: any) => {
      setLoadingMessage("Connect to the Mailbox");
      if (!formValues) {
        notification.error({
          message: "Please select any one option"
        });
        setLoadingMessage("");
      } else {
        if (formValues === "recovery") {
          let data = {
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "G_recovery_email_option",
            browserId
          };
          await performTask(data);
        } else {
          let data = {
            email: Data.email,
            instanceId: instanceId,
            site: "OUTWIN",
            task: "G_access_code_on_email_option",
            browserId
          };
          await performTask(data);
        }
      }
    },
    [setLoadingMessage, Data, instanceId]
  );

  const onMailboxProviderContinue = useCallback(
    async (provider: MailboxTypeEnum, email: string) => {
      // use gmail username dialog for MICROSOFT also
      // if (provider === 'microsoftone') {
      //   setLoadingMessage("Fetching server Details ....");
      //   setLoadingMessage("");
      //   mailBoxOfficeSmtp.resetFields();
      //   setshowConnectMailStmpModal(true);
      //   return;

      // }
      setLoadingMessage("Connecting to mailbox...");

      if (provider === MailboxTypeEnum.OFFICE_SMTP) {
        setLoadingMessage("");
        mailBoxOfficeSmtp.resetFields();
        setshowConnectMailStmpModal(true);
        return;
      }

      if (!getProviderDetails(provider)?.smtpImapDetailsKnown) {
        setShowMailBoxProviderDetailModal((prev) => !prev);
        setProviderType(provider);
        setLoadingMessage("");
        return;
      }

      if (getProviderDetails(provider)?.smtpImapDetailsKnown && provider !== MailboxTypeEnum.OFFICE_OAUTH) {
        setLoadingMessage("");
        subform.resetFields();
        setShowConnectMailboxModal(true);
        return;
      }

      if (provider !== MailboxTypeEnum.OFFICE_OAUTH) {
        return;
      }
      setShowConnectMailboxModal(false);

      const state = uuid();
      const { url, method } = API_ENDPOINTS.OAUTH.getOauthUrl({
        provider,
        state,
        email
      });
      console.log("11111111111");
      const response = await callApi({ url, method });

      if (response.success) {
        LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
          state,
          email
        });

        const response = await callApi({ url, method });

        if (response.success) {
          LocalStorage.setValue(OAUTH_STATE_LS_KEY, {
            state,
            redirectTo: `/campaigns/${campaignId}/mailboxes`
          });
          try {
            const { oauthurl } = response.data;
            window.location.assign(oauthurl);
          } catch (err) {
            notification.error({
              message: "Something went wrong!"
            });
            setLoadingMessage("");
          }
          return;
        }
      }
      setLoadingMessage("");
    },
    [callApi, setLoadingMessage, subform, mailBoxOfficeSmtp]
  );

  const onCloseSettingsModal = useCallback(() => {
    // setSelectedMailbox(null);
    setIsSettingSmtp(false);
    if (isSelectedMailboxNew) {
      // history.replace(`/campaigns/${campaignDetail.id}/mailboxes`);
    }
  }, [isSelectedMailboxNew, history]);

  const updateSpotBrowser = useCallback(async () => {
    const { url, method } = API_ENDPOINTS.DNSCONNECTION.getCreateUrl();

    let payload = { browserId: browserId };
    const response = await callApi({
      url,
      method,
      data: payload,
      external: true
    });

    if (response.success) {
      console.log("spot updated scuccessfully :::");
    } else {
      console.log("some thing going wrong.");
    }
  }, [browserId]);

  const onSettingsFormSubmit = useCallback(() => {
    mailBoxSettingsForm.submit();
  }, [mailBoxSettingsForm]);

  const createMailBox = useCallback(
    async (settings: MailBoxSettingsType) => {
      const { minEmails, maxEmails, incrementPercent, signature, timeZone, days, startHour, endHour, firstName, lastName, autoForwardEmail, replyTo } =
        settings;

      const { url, method } = API_ENDPOINTS.CAMPAIGNS.createMailBox();

      let payload = {};
      const providerInfo = getProviderDetails(providers);
      if (providers !== MailboxTypeEnum.AMAZON) {
        payload = {
          minEmails,
          maxEmails,
          incrementPercent,
          signature,
          timeZone,
          days,
          startHour,
          endHour,
          firstname: Data.firstName ? Data.firstName : firstName,
          lastname: Data.lastName ? Data.lastName : lastName,
          accountId: String(localStorage.getItem("account_id")),
          email: Data.email || selectedMailbox?.email,
          username: Data.email || selectedMailbox?.email,
          password: Data.password,
          protocol: providerInfo?.protocol,
          provider: providerInfo?.providerName,
          autoForwardEmail,
          replyTo
        };
      } else {
        payload = {
          ...payload,
          state: oauthState
        };
      }

      setLoadingMessage("Setting up the mailbox... ...");

      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        LocalStorage.resetKey(OAUTH_STATE_LS_KEY);
        setShowMailBoxProviderModal(false);
        setShowConnectMailboxModal(false);
        setIsSettingSmtp(false);
        // setSelectedMailbox(null);
        setLoadingMessage("");
        onCloseSettingsModal();
        return;
      }
      if (providers === MailboxTypeEnum.OFFICE_BROWSER) await updateSpotBrowser(); // todo: NC verify
      setLoadingMessage("");
    },
    [Data, callApi, oauthState, providers, setLoadingMessage, onCloseSettingsModal, updateSpotBrowser]
  );

  const updateMailbox = useCallback(
    async (settings: MailBoxSettingsType, id) => {
      const {
        signature,
        incrementPercent,
        minEmails,
        maxEmails,
        days,
        timeZone,
        startHour,
        endHour,
        firstName,
        lastName,
        capacity,
        autoForwardEmail,
        replyTo
      } = settings;
      const { url, method } = API_ENDPOINTS.MAILBOX.getUpdatelUrl(id);
      console.log("5555555555555555", settings);

      const payload = {
        accountId: String(localStorage.getItem("account_id")),
        incrementPercent,
        minEmails,
        maxEmails,
        signature,
        days,
        timeZone,
        startHour,
        endHour,
        firstname: firstName,
        lastname: lastName,
        capacity: capacity,
        autoForwardEmail,
        replyTo
      };

      setLoadingMessage("Updating your mailbox ...");

      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        // setSelectedMailbox(null);
        setShowConnectMailboxModal(false);
        setLoadingMessage("");
        return;
      }

      setLoadingMessage("");
    },
    [callApi, setLoadingMessage]
  );

  const handleMailboxSettingsSave = useCallback(
    async (settings: MailBoxSettingsType) => {
      if (!selectedMailbox?.id) {
        createMailBox(settings);
        return;
      }

      updateMailbox(settings, selectedMailbox.id);
    },
    [selectedMailbox, createMailBox, updateMailbox]
  );

  const handleEditClick = useCallback(
    async (id: string) => {
      setLoadingMessage("Fetching your mailbox settings ...");
      const { url, method } = API_ENDPOINTS.MAILBOX.getDetailUrl(id);

      const response = await callApi({
        url,
        method
      });

      if (response.success) {
        const {
          email,
          campaignName,
          campaignId,
          paused,
          signature = "",
          incrementPercent = 20,
          minEmails,
          maxEmails,
          timezone,
          days,
          startHour,
          endHour,
          provider,
          capacity,
          firstname,
          lastname
        } = response.data.inbox;
        setSelectedMailbox({
          email,
          campaignName,
          // campaignId,
          // paused,
          id,
          provider,
          settings: {
            incrementPercent,
            minEmails,
            email,
            maxEmails,
            signature,
            timeZone: timezone,
            days,
            startHour,
            endHour,
            firstName: firstname,
            lastName: lastname,
            capacity
          }
        });
        setLoadingMessage("");
        return;
      }
      setLoadingMessage("");
    },
    [setLoadingMessage, setSelectedMailbox, callApi]
  );

  // useEffect(() => {
  //   if (oauthState && currentStepName !== NewCampaignSteps.MAILBOX_SETTINGS) {
  //     setCurrentStepName(NewCampaignSteps.MAILBOX_SETTINGS);
  //   }
  // }, [oauthState, currentStepName]);

  const toggleShowModal = () => {
    setShowConnectMailboxModal((show) => !show);
  };
  const handleUpdatePasswordModal = () => setshowTextVerifyModal((show) => !show);

  const providesType = useCallback(async (type: any) => {
    setProviders(type);
  }, []);

  const submitPhoneNoToVerify = async (formValues: any) => {
    setLoadingMessage("verifying Phone Number.");
    const { phone, prefix } = formValues;

    let data = {
      email: Data.email,
      instanceId: instanceId,
      phoneNo: phone,
      countryCode: prefix,
      site: "OUTWIN",
      task: "phoneNoCheck"
    };
    await performTask(data);
  };

  const submitAccessCode = async (formValues: any) => {
    setLoadingMessage("verifying Access Code.");
    const { accessCode } = formValues;
    if (providers === MailboxTypeEnum.GMAILONE) {
      let data = {
        email: Data.email,
        instanceId: instanceId,
        accessCode: accessCode,
        site: "OUTWIN",
        task: "G_EmailVerificationCode",
        type: "browserGmail"
      };
      await performTask(data);
    } else {
      if (isEmailValidation) {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          accessCode: accessCode,
          site: "OUTWIN",
          task: "enterTheCode"
        };
        await performTask(data);
      } else {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          accessCode: accessCode,
          site: "OUTWIN",
          task: "enterAccessCode"
        };
        await performTask(data);
      }
    }
  };

  const submitMicroSoftEmail = useCallback(
    async (formValues: any) => {
      setLoadingMessage("verifying Email.");
      const { alternate_email } = formValues;
      if (isVerifyEmail) {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          alternate_email: alternate_email.split("@")[0],
          site: "OUTWIN",
          task: "verify_alterEmail"
        };

        await performTask(data);
      } else {
        let data = {
          email: Data.email,
          instanceId: instanceId,
          alternate_email: alternate_email,
          site: "OUTWIN",
          task: "alternateEmail"
        };

        await performTask(data);
      }
    },
    [isVerifyEmail, setLoadingMessage]
  );

  const getTask: any = useCallback(
    async (data: any, startTime: number = new Date().getTime(), currTime: number = new Date().getTime()) => {
      let { email, site, instanceId } = data;

      let payload = {
        email: email,
        site: site,
        instanceId: instanceId
      };
      const { url, method } = API_ENDPOINTS.OTHERS.browserBased(instanceId ? instanceId : "localhost");
      const response = await callApi({
        url,
        method,
        data: payload,
        external: true
      });

      if (response && response.success) {
        const { data } = response;
        if (data && data.task && data.isSuccess) {
          if (data.task === "login_success") {
            let textMsg = data.msg;
            if (textMsg) {
              textMsg = textMsg.split(" ");
              if (textMsg.length) {
                let firstName = textMsg.shift();
                let lastName = textMsg.join(" ");
                setData((prevState) => ({
                  ...prevState,
                  lastName: lastName,
                  firstName: firstName
                }));
              }
            }
          }
          setTask(data.task);
          setShowText("");
          setErrorMsg("");
        } else if (data && data.task && data.isError) {
          if (data.msg && data.msg.length) {
            setErrorMsg(data.msg);
          }
          if (data.showText && data.showText.length) {
            setShowText(data.showText);
          }
          setTask(data.task);
        } else {
          setTask("");
          setShowText("");
          setErrorMsg("");
          await timeout(1000);
          console.log(currTime - startTime);
          if (currTime - startTime <= 1.5 * 60 * 1000) {
            return await getTask(payload, startTime);
          } else {
            setLoadingMessage("");
            notification.error({
              message: "Something went wrong"
            });
          }
        }
        if (
          data.task === "gmail_recover_email" ||
          data.task === "G_ChooseVerification" ||
          data.task === "gamil_2_step_verification" ||
          data.task === "G_verification_options"
        ) {
          setSubMessage(data.showText);
        }
      }
    },
    [setTask, setShowText, callApi]
  );

  const timeout = (ms: any) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const performTask = useCallback(
    async (data: any) => {
      if (!data.proxy || !Object.keys(data.proxy).length) {
        data.proxy = proxy;
      }
      let payload = {
        ...data
      };
      if (!payload.browserId) {
        payload.browserId = browserId;
      }
      setTask("");
      // console.log("data 3", data);
      const { instanceId } = data;
      const { url, method } = API_ENDPOINTS.OTHERS.launchedBrowser(instanceId ? instanceId : "localhost");
      const response = await callApi({
        url,
        method,
        data: payload,
        external: true
      });
      if (response.success) {
        await getTask(data);
      } else {
        setTimeout(() => setLoadingMessage(""), 1000);
      }
    },
    [setTask, browserId, proxy, callApi]
  );

  const socketAndPerformLogin = useCallback(
    async (email: string, password: string, browserId: string, instanceId: string, proxy: Proxy) => {
      const data = {
        email: email,
        instanceId: instanceId,
        password: password,
        browserId: browserId,
        site: "OUTWIN",
        task: "login",
        proxy: proxy
      };
      await performTask(data);
      return;
    },
    [proxy, browserId]
  );

  const onSendEmail = (name: string, email: string) => {
    setselectedInboxIdForSendEmail(name);
    setselectedInboxEmailForSendEmail(email);
  };

  const updateCredentials = useCallback(
    async (id: string, action: string) => {
      console.log("id....", id);
      // console.log("mailBoxes........", mailBoxes)
      let fetchData = mailBoxes.filter((x) => x.id === id);
      let { email, provider, firstname, lastname, protocol } = fetchData[0];
      setData({
        email: email || "",
        password: "",
        firstName: firstname || "",
        lastName: lastname || ""
      });

      if (provider === "MICROSOFT") {
        setProviders(MailboxTypeEnum.OFFICE_SMTP);
      } else if (provider === "GOOGLE" && protocol === "BROWSER") {
        setProviders(MailboxTypeEnum.GMAILONE);
      } else {
        setProviders(provider);
      }
      setisEditMode(true);
      setAction(action);
      if (provider === MailboxTypeEnum.OFFICE_OAUTH) {
        setshowConnectMailStmpModal(true);
      } else if (getProviderDetails(provider)?.smtpImapDetailsKnown) {
        setShowConnectMailboxModal(true);
      } else {
        setShowMailBoxProviderDetailModal(true);
      }
      setEditableId(id);
    },
    [setAction, mailBoxes]
  );

  const resetMailboxeSettings = () => {
    setisBoundConnection(false);
    setShowConnectMailboxModal(false);
    setSmtpInBoundError("");
    setSmtpOutBoundError("");
    setisInBoundConnection(false);
    setisOutBoundConnection(false);
    setValidating(false);
    // setisEditMode(false);
  };

  const reconnectMailBox = async (userInfo: { email: string; password: string }) => {
    // console.log('edit', editableId);
    const { email, password } = userInfo;
    const { url, method } = API_ENDPOINTS.CAMPAIGNS.reConnectMail(editableId);
    let data: any = {
      brooklynReconnect: false
    };
    if (providers !== "GOOGLEONE") {
      data["smtp"] = { host: "", port: "", username: email, password: password, tls: false };
      data["imap"] = { host: "", port: "", username: "", password: "", tls: false };
    }
    await callApi({
      url,
      method,
      data
    });
  };

  const delay = (timeout: number) => new Promise((resolve) => setTimeout(() => resolve(true), timeout));
  const callProviderSetupSuccessResponse: any = useCallback(
    async (requestId: string, endTime: number, userinfo: { email: string; password: string }) => {
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.smtpValidateStatus(requestId);

      const response = await callApi({
        url,
        method,
        external: true
      });

      if (response.success) {
        const { outbound, inbound } = response.data;

        // inbound.status = "Succeeded"
        // outbound.status = "Succeeded"
        if (
          (outbound.status === "Succeeded" && inbound.status === "Succeeded") ||
          (outbound.status === "Failed" && inbound.status === "Failed") ||
          (outbound.status === "Succeeded" && inbound.status === "Failed") ||
          (outbound.status === "Failed" && inbound.status === "Succeeded")
        ) {
          setValidating(true);
        }
        let taskDone = false;

        if (inbound.status !== "Created" && inbound.status !== "Started") {
          if (inbound.status === "Succeeded") {
            taskDone = true;
            setisInBoundConnection(true);
          } else {
            taskDone = true;
            setSmtpInBoundError(inbound.error);
            setisInBoundConnection(false);
          }
        }

        if (outbound.status !== "Created" && outbound.status !== "Started") {
          if (outbound.status === "Succeeded") {
            taskDone = true;
            setisOutBoundConnection(true);
          } else {
            taskDone = true;
            setSmtpOutBoundError(outbound.error);
            setisOutBoundConnection(false);
          }
        }

        if (inbound.status === "Succeeded" && outbound.status === "Succeeded") {
          await delay(3000);
          if (isEditMode && action === "VALIDATE") {
            notification.success({
              message: "Connection validated successfully"
            });
            resetMailboxeSettings();
          } else if (isEditMode && action === "UPDATE") {
            notification.success({
              message: "Password updated successfully"
            });
            resetMailboxeSettings();
          } else if (isEditMode && action === "RECONNECT") {
            notification.success({
              message: "Reconnect successfully"
            });
            await reconnectMailBox(userinfo);
            resetMailboxeSettings();
          } else {
            if (taskDone) {
              setIsSettingSmtp(true);
              resetMailboxeSettings();
              return;
            }
          }
        }

        if (inbound.status === "Created" || inbound.status === "Started" || outbound.status === "Created" || outbound.status === "Started") {
          let currentTime = new Date().getTime();
          if (endTime - currentTime > 0) {
            await delay(3000);
            return await callProviderSetupSuccessResponse(requestId, endTime, userinfo);
          }
        }
      }
    },
    [action, isEditMode, callApi]
  );

  const onFinish = async (formValues: any) => {
    const { email, password, firstName, lastName } = formValues;
    if (providers) {
      setProviders(providers);
    }
    setData({
      email,
      password,
      lastName,
      firstName
    });
    if (providers === MailboxTypeEnum.GMAILONE) {
      if (isWrongPassword) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email,
          password,
          instanceId: instanceId,
          site: "OUTWIN",
          task: "reEnter_password",
          browserId
        };
        await performTask(data);
      } else if (isWrongEmail) {
        setLoadingMessage("Connecting to mailbox...");
        let data = {
          email,
          instanceId: instanceId,
          password,
          site: "OUTWIN",
          task: "reEnterLoginCredentials",
          browserId
        };
        await performTask(data);
      } else {
        setLoadingMessage("Connecting to mailbox...");
        const { browserId, instanceId, proxy } = await getDnsPublicDnsUrl(email, password);
        if (browserId && browserId.length && instanceId && instanceId.length) {
          const payload = { email: email as string, provider: providers, id: null, campaignName: null, campaignId: null, paused: null };
          setSelectedMailbox(payload);
          await socketAndPerformLogin(email, password, browserId, instanceId, proxy);
        }
      }
      return;
    } else {
      if (providers === MailboxTypeEnum.OFFICE_SMTP || providers === MailboxTypeEnum.OFFICE_OAUTH) {
        setshowConnectMailStmpModal(false);
      } else if (getProviderDetails(providers)?.smtpImapDetailsKnown) {
        setShowConnectMailboxModal(false);
      } else {
        setShowMailBoxProviderDetailModal(false);
      }
      setisBoundConnection(true);
    }
    setLoadingMessage("");
  };

  useEffect(() => {
    if (!isBoundConnection) {
      setValidating(false);
      setSmtpInBoundError("");
      setisInBoundConnection(false);
      setisOutBoundConnection(false);
      setSmtpOutBoundError("");
      if (action === "UPDATE" || action === "VALIDATE" || action === "RECONNECT") {
        setIsSettingSmtp(false);
      }
    }
  }, [isBoundConnection]);

  const resetSelectedEmailForIps = () => setSelectedEmailForIps(""); //setSelectedEmailForLogs
  const resetSelectedInboxIdForlogs = () => setselectedInboxIdForLogs("");
  const resetSelectedInboxIdForSendEmail = () => {
    setselectedInboxIdForSendEmail("");
    setselectedInboxEmailForSendEmail("");
  };

  const verifyPhoneNoForm = async (formValues: any) => {
    setLoadingMessage("Verifying your Phone No");
    const { phone, prefix } = formValues;
    let data = {
      email: Data.email,
      instanceId: instanceId,
      phoneNo: phone,
      site: "OUTWIN",
      task: "verifyPhoneNo"
    };
    await performTask(data);
  };

  const updatePassword = async (formValues: any) => {
    setLoadingMessage("Verifying your Phone No");
    const { Password } = formValues;
    let data = {
      email: Data.email,
      instanceId: instanceId,
      updatedPassword: Password,
      site: "OUTWIN",
      task: "enter_New_Password"
    };
    await performTask(data);
  };

  const verifyTwoStep = async () => {
    let data = {
      email: Data.email,
      instanceId: instanceId,
      site: "OUTWIN",
      task: "",
      browserId
    };
    await getTask(data);
  };

  const detectExtension = (extensionId: string, callback: any) => {
    let s = document.createElement("script");
    s.onload = function () {
      callback(true);
    };
    s.onerror = function () {
      callback(false);
    };
    s.src = "chrome-extension://" + extensionId + "/js/control.js";
    document.body.appendChild(s);
  };

  const checkExtension = () => {
    return new Promise((resolve, reject) => {
      detectExtension("bpekpmdcgljafocafpmjjghmhfnlggah", (sss: any) => {
        console.log("callededddddddd", sss);
        resolve(sss);
      });
    });
  };

  useEffect(() => {
    if (extensionStatus === "check") {
      var datavalue = setInterval(async () => {
        let data: any = await checkExtension();
        console.log("datassssssssss", data);
        if (data) {
          setInstallExtension(true);
          clearInterval(datavalue);
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(datavalue);
      }, 180000);
    } else if (extensionStatus === "installed") {
      const checkFn = async () => {
        let data: any = await checkExtension();
        console.log("else if", data);
        if (data) {
          setInstallExtension(true);
        }
      };
      checkFn();
    } else {
    }
    return () => {
      setExtensionStatus("");
    };
  }, [extensionStatus]);

  const handleInstallExtension = useCallback(async () => {
    const url = "https://chrome.google.com/webstore/";
    window.open(url, "_blank", "noopener,noreferrer");
    setExtensionStatus("check");
  }, []);

  const getCookie = (name: string) => {
    const pattern = RegExp(name + "=.[^;]*");
    const matched = document.cookie.match(pattern);
    if (matched) {
      const cookie = matched[0].split("=");
      return cookie[1];
    }
    return false;
  };

  const clearCookies = () => {
    document.cookie = "brooklyn_inbox_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "brooklyn_inbox_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  const handleGoogleLogin = () => {
    clearCookies();
    const sessionId = uuid();
    document.cookie = `brooklyn_site=${window.origin}; path=/`;
    document.cookie = "brooklyn_app=Emy; path=/";
    document.cookie = `brooklyn_session_id=${sessionId}; path=/`;
    const redirectUrl = "https://accounts.google.com/AddSession?service=mail&passive=truecontinue=https://mail.google.com/mail";
    window.open(redirectUrl, "_blank", "noopener,noreferrer");

    var datavalue = setInterval(() => {
      let cookieValue = getCookie("brooklyn_inbox_name");
      if (cookieValue) {
        const fullName: any = cookieValue.split(" ");
        if (fullName.length) {
          setData((prevState) => ({
            ...prevState,
            firstName: fullName.shift(),
            lastName: fullName.join(" ")
          }));
        }
        setIsSettingSmtp(true);
        getTaskFromAPI("login_success");
        clearCookies();
        clearInterval(datavalue);
      }
    }, 1500);

    setTimeout(() => {
      clearInterval(datavalue);
    }, 300000);
  };

  console.log("xsxs 4");

  const setEmail = (email: string) => {
    setSelectedMailbox((prev: any) => ({
      ...prev,
      email: email || ""
    }));
    setData((prevState) => ({
      ...prevState,
      email
    }));
    setEditModeWhileProviderWorkspacePersonal(true);
  };

  return (
    <>
      <Div
        style={{
          padding: "20px",
          borderRadius: "4px",
          boxShadow: "rgb(0 0 0 / 4%) 2px 2px 8px 0px",
          background: "#fff",
          width: "1200px",
          margin: "0 auto",
          maxWidth: "100%"
        }}
        className="container"
      >
        {!isLoading ? (
          <>
            {selectedMailbox && selectedMailbox?.settings ? (
              <>
                <MailBoxSettings
                  onSave={handleMailboxSettingsSave}
                  email={selectedMailbox?.email ? selectedMailbox?.email : ""}
                  campaignName={selectedMailbox?.campaignName ? selectedMailbox?.campaignName : ``}
                  // campaignId={selectedMailbox?.campaignId}
                  settings={selectedMailbox?.settings}
                  form={mailBoxSettingsForm}
                  provider={selectedMailbox?.provider}
                  protocol={selectedMailbox?.protocol}
                  mailboxDelinking={mailboxDelinking}
                  id={selectedMailbox?.id}
                  // mailboxDelinking={mailboxDelinking}
                  updateCredentails={updateCredentials}
                  onDelete={isFree ? onDelete : null}
                  onViewIpsDetails={setSelectedEmailForIps}
                  onViewLogsDetails={setselectedInboxIdForLogs}
                  onSendEmail={onSendEmail}
                />
                <Flex mt={20} justify="flex-end">
                  <Button type="primary" onClick={onSettingsFormSubmit}>
                    Save
                  </Button>
                </Flex>
              </>
            ) : (
              <Flex mt={20} justify="center">
                <p>No Record Found</p>
              </Flex>
            )}
          </>
        ) : null}
      </Div>
      {showMailBoxProviderModal && (
        <Modal visible onCancel={toggleMailBoxProviderDialog} footer={null} width={700} maskClosable={false}>
          <MailBoxProvider
            onContinue={onMailboxProviderContinue}
            form={mailBoxProviderForm}
            setProviders={providesType}
            submitBtn={mailBoxProviderForm.submit}
            setEmail={setEmail}
          />
          {/* <Flex justify="flex-end">
            <Button type="primary" onClick={mailBoxProviderForm.submit}>
              Connect
            </Button>
          </Flex> */}
        </Modal>
      )}

      {showPausedMailboxModal && (
        <Modal visible onCancel={toggleMailBoxProviderDialog} footer={null} width={700} maskClosable={false}>
          <MailBoxProvider
            onContinue={onMailboxProviderContinue}
            form={mailBoxProviderForm}
            setProviders={providesType}
            submitBtn={mailBoxProviderForm.submit}
            setEmail={setEmail}
          />
          {/* <Flex justify="flex-end">
            <Button type="primary" onClick={mailBoxProviderForm.submit}>
              Connect
            </Button>
          </Flex> */}
        </Modal>
      )}

      {showMailBoxProviderDetailModal && (
        <MailBoxProviderDetailModal
          showAddMailbox={setShowMailBoxProviderDetailModal}
          setShowMailBoxProviderModal={setShowMailBoxProviderModal}
          fetchInboxes={fetchInboxes}
          isEditMode={isEditMode}
          editableData={Data}
          mailId={editableId}
          onDismiss={toggleMailBoxProviderDatialDialog}
          provider={providerType}
          isNewCampFlow={false}
          backButton={gotoBackButton}
          action={action}
        />
      )}

      {/* {areNoMailBoxesLinked && <EmptyMailboxPlaceholder onAdd={toggleMailBoxProviderDialog} />} */}
      {!!selectedEmailForIps && <IpsDetailsModal onCancel={resetSelectedEmailForIps} email={selectedEmailForIps} />}
      {!!selectedInboxIdForLogs && (
        <LogDetailsModal
          onCancel={resetSelectedInboxIdForlogs}
          id={selectedInboxIdForLogs}
          logType="Inbox"
          subTitle={mailBoxes.find((mb) => mb.id === selectedInboxIdForLogs)?.email}
        />
      )}
      {!!selectedInboxEmailForSendEmail && (
        <SendEmailModal onCancel={resetSelectedInboxIdForSendEmail} email={selectedInboxEmailForSendEmail} name={selectedInboxIdForSendEmail} />
      )}
      {getProviderDetails(providers)?.name === "Google (SMTP)" && getProviderDetails(providers)?.subText === "Workspace" ? (
        <>
          {!!showConnectMailboxModal && (
            <GmailIMAPLoginModal
              handleOpenClose={() => {
                setShowConnectMailboxModal(!showConnectMailboxModal);
                setEditModeWhileProviderWorkspacePersonal(false);
              }}
              handleSubmit={onFinish}
              formData={Data}
              isEditMode={isEditMode}
              connectModalStep={connectModalStep}
              isEditModeForWorkspacePersonal={isEditModeForWorkspacePersonal}
            />
          )}
        </>
      ) : (
        <>
          {!!showConnectMailboxModal && (
            <Modal
              visible
              footer={null}
              onCancel={() => {
                toggleShowModal();
                setisEditMode(false);
                setEditModeWhileProviderWorkspacePersonal(false);
              }}
              width={600}
              centered
            >
              <Form scrollToFirstError form={subform} requiredMark={false} onFinish={onFinish}>
                <Text variant="h6" mb={5} ml={10} mt={20} color={"secondary"}>
                  {getProviderDetails(providers)?.providerName + " Setup"}
                </Text>
                <Flex style={{ flexDirection: "row" }}>
                  <DetailWrapper>
                    <DetailHeading>Email *</DetailHeading>
                    <Form.Item
                      name={"email"}
                      rules={[
                        {
                          type: "email",
                          message: "Please enter valid email"
                        },
                        {
                          required: true,
                          message: "Please enter email"
                        }
                      ]}
                      initialValue={isEditMode || isEditModeForWorkspacePersonal ? Data.email : ""}
                    >
                      <Input disabled={isEditMode ? true : false} tabIndex={1} className={classes.inputStyle} size="middle" />
                    </Form.Item>
                  </DetailWrapper>
                  <DetailWrapper>
                    <DetailHeading>Password *</DetailHeading>
                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: "Please write password"
                        }
                      ]}
                      initialValue={isEditMode ? Data.password : ""}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input.Password
                        tabIndex={2}
                        autoComplete="new-password"
                        style={{
                          width: 250,
                          borderWidth: 0,
                          paddingLeft: 2,
                          boxShadow: "none",
                          borderRight: "none",
                          borderBottom: `1px solid ${DARK_GRAY_COLOR}`
                        }}
                        size="middle"
                      />
                    </Form.Item>
                  </DetailWrapper>
                </Flex>
                <Flex direction="row" ml={10} justify="flex-end">
                  <Flex>
                    <Button
                      onClick={() => {
                        setShowConnectMailboxModal(false);
                        setisEditMode(false);
                      }}
                    >
                      Back
                    </Button>
                  </Flex>
                  <Flex ml={10}>
                    <Button htmlType="submit" type="primary">
                      Validate
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            </Modal>
          )}
        </>
      )}

      {!!showConnectMailStmpModal && (
        <Modal visible footer={null} onCancel={() => toggleShowModalSmtp()} width={600} centered>
          <Form scrollToFirstError form={mailBoxOfficeSmtp} requiredMark={false} onFinish={onFinish}>
            <Text variant="h6" mb={5} ml={10} mt={20} color={"secondary"}>
              {"Microsoft"} {/* {"Gmail"}{" "} */}
              setup
            </Text>
            <Flex style={{ flexDirection: "row" }}>
              <DetailWrapper>
                <DetailHeading>Email *</DetailHeading>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter valid email"
                    },
                    {
                      required: true,
                      message: "Please enter email"
                    }
                  ]}
                  initialValue={isEditMode ? Data.email : ""}
                >
                  <Input disabled={isEditMode ? true : false} tabIndex={1} className={classes.inputStyle} size="middle" />
                </Form.Item>
              </DetailWrapper>
              <DetailWrapper>
                <DetailHeading>Password *</DetailHeading>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please write password"
                    }
                  ]}
                  style={{ marginLeft: "5px" }}
                  initialValue={isEditMode ? Data.password : ""}
                >
                  <Input.Password
                    autoComplete="new-password"
                    tabIndex={2}
                    style={{
                      width: 250,
                      borderWidth: 0,
                      paddingLeft: 2,
                      boxShadow: "none",
                      borderRight: "none",
                      borderBottom: `1px solid ${DARK_GRAY_COLOR}`
                    }}
                    size="middle"
                  />
                </Form.Item>
              </DetailWrapper>
            </Flex>
            <Flex direction="row" ml={10} justify="flex-end">
              <Flex>
                <Button onClick={() => setshowConnectMailStmpModal(false)}>Back</Button>
              </Flex>
              <Flex ml={10}>
                <Button htmlType="submit" type="primary">
                  Validate
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      )}
      {!!showConnectEmailValidationModal && (
        <Modal visible footer={null} onCancel={() => toggleShowModalEmailValidation()} width={300} centered>
          <Form scrollToFirstError form={alernateEmailValidation} requiredMark={false} onFinish={submitMicroSoftEmail}>
            {/* <p style={{ fontSize: '12px', marginBottom: '15px' }}>ddd.outwin@gmail.com</p> */}
            <Title level={5}>Help us protect your account</Title>
            <p className="fs-12" style={{ marginBottom: "15px" }}>
              Passwords can be forgotten or stolen. Just in case, add security info now to help you get back into your account if something goes wrong. We won't
              use this info to spam you—just to keep your account more secure.
            </p>
            <Div>
              <label className="fs-12 fw-600">Email *</label>
              <Form.Item
                name={"alternate_email"}
                rules={[
                  {
                    type: "email",
                    message: "Please enter valid email"
                  },
                  {
                    required: true,
                    message: "Please enter email"
                  }
                ]}
              >
                <Input tabIndex={1} className={classes.inputStyle} size="middle" />
              </Form.Item>
              {isVerifyEmail ? (
                <p className="fs-12" style={{ marginBottom: "10px" }}>
                  We'll send a security code to your email <strong>{showText.split("Email")[1]}</strong> address in the next step.
                </p>
              ) : (
                <p className="fs-12" style={{ marginBottom: "10px" }}>
                  We'll send a security code to your alternate email address in the next step.
                </p>
              )}
            </Div>
            <Flex direction="row" ml={10} justify="flex-end">
              <Flex ml={10}>
                <Button htmlType="submit" type="primary">
                  Next
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      )}
      {!!showConnectMicrosoftValidation && (
        <VerifyEmailByPhoneNoModal submitPhoneNoToVerify={submitPhoneNoToVerify} handleVerifyEmailByPhoneNo={handleVerifyEmailByPhoneNo} />
      )}

      {!!showAccessCodeModal && (
        <VerifyAccessCodeModal subMessage={subMessage} submitAccessCode={submitAccessCode} handleVerifyAccessCodeModal={handleVerifyAccessCodeModal} />
      )}
      {!!showLinkExistingEmailModal && (
        <Modal visible centered bodyStyle={{ maxHeight: "90vh", overflow: "auto" }} footer={null} onCancel={toggleLinkExistingMailboxDialog}>
          <Div style={{ overflow: "auto" }}>
            <Flex>
              <Button type="primary" onClick={() => linkExistingForm.submit()}>
                Save
              </Button>
            </Flex>
            <MailBoxList
              isCardView={false}
              mailBoxes={mailBoxes}
              selectedMailBoxes={inboxIds}
              form={linkExistingForm}
              onSubmit={(args) => {
                toggleLinkExistingMailboxDialog();
                onSubmit(args);
              }}
              title=""
              subTitle=""
              filterStatus={MAILBOXLIST_FILTER.NOTCONNECTED}
            />
          </Div>
        </Modal>
      )}
      {!!showTextVerifyModal && (
        <VerifyPhoneNoModal showText={showText} verifyPhoneNoForm={verifyPhoneNoForm} toggleVerifyPhoneNoForm={toggleVerifyPhoneNoForm} />
      )}
      {!!showUpdatePasswordModal && (
        <UpdatePasswordModal showText={showText} updatePassword={updatePassword} handleUpdatePasswordModal={handleUpdatePasswordModal} />
      )}
      {isBoundConnection && (
        <BoundConnectionModal
          isEnable={isBoundConnection}
          toggleMailboxValidating={toggleMailboxValidating}
          validating={validating}
          isInBoundConnection={isInBoundConnection}
          smtpInBoundError={smtpInBoundError}
          isOutBoundConnection={isOutBoundConnection}
          smtpOutBoundError={smtpOutBoundError}
          editableId={editableId}
          providers={providers}
          setValidating={setValidating}
          setisInBoundConnection={setisInBoundConnection}
          setSmtpInBoundError={setSmtpInBoundError}
          setisOutBoundConnection={setisOutBoundConnection}
          setSmtpOutBoundError={setSmtpOutBoundError}
          isEditMode={isEditMode}
          action={action}
          resetMailboxeSettings={resetMailboxeSettings}
          setIsSettingSmtp={setIsSettingSmtp}
          settingEditView={selectedMailbox}
          setSelectedMailbox={setSelectedMailbox}
          fromValue={{ email: Data?.email, password: Data?.password }}
        />
      )}
      {!!showGmailConfirmationMailModal && (
        <RecoveryEmailModal subMessage={subMessage} handleRecoveryEmailModal={handleRecoveryEmailModal} confirmRecoveryEmail={confirmRecoveryEmail} />
      )}
      {!!show2StepVerificationModal && (
        <VerficationModal subMessage={subMessage} verifyTwoStep={verifyTwoStep} handleVerificationModal={handleVerificationModal} />
      )}
      {!!showVerificationOptionsModal && (
        <VerificationOptionsModal
          subMessage={subMessage}
          handleVerificationOptionsModal={handleVerificationOptionsModal}
          onOptionSelectHandler={onOptionSelectHandler}
        />
      )}
      {!!showTroubleShootModal && <TroubleShootModal proxyDetail={proxy} handleTroubleShootModal={handleTroubleShootModal} />}
      {!!showManualGmailLogin && (
        <ManualGmailLoginModal
          handleManualGmailLoginModal={handleManualGmailLoginModal}
          installExtension={installExtension}
          handleInstallExtension={handleInstallExtension}
          handleGoogleLogin={handleGoogleLogin}
        />
      )}
    </>
  );
};

export default withAppBar(Mailboxes);
