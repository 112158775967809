import React, { useCallback, useContext, useMemo } from "react";
import { Div } from "styles/layout";
import CampaignSettings, { CampaignSettingsType } from "components/Campaign/Settings";
import { Button, Form, Tooltip } from "antd";
import { WHITE_COLOR } from "utils/colors";
import { CampaignType, } from "types/Campaign";
import useApi from "hooks/useApi";
import { API_ENDPOINTS } from "constants/api";
import AppContext from "contexts/appContext";
import translationStrings from "constants/strings.json";
import { useSelector } from "react-redux";
import { canModifyCampaignState } from "state/selectors";

interface CampaignSettingsProps {
  campaignDetail: CampaignType;
  afterSave: () => void;
}

const Settings = ({ campaignDetail, afterSave }: CampaignSettingsProps) => {
  const { callApi } = useApi();
  const { setLoadingMessage } = useContext(AppContext);
  const [form] = Form.useForm();
  const isFree = useSelector(canModifyCampaignState(campaignDetail.id));

  const campaignSettings = useMemo(() => {
    const {
      name,
      // autoRemoveEmailRepliedBy,
      autoRemoveEmailReplied,
      sendEmailOnHolidays = false,
      // startDate,
      startHour = 9,
      endHour = 17,
      removeProspectEmailLinkClick = false,
      removeProspectEmailOpen = false,
      removeProspectEmailReplied = false,
      days = [1, 2],
      startDate,
      createdAt,
      timezone,
      completedTime = 0,
      enableQuickline = false,
      enableSalesforce = false,
      enableScrubby = false,
      salesforceMapping = [],
      dailyLimitPerTargetDomain = 1,
      enableTargetDomainDailyLimit = false,
      targetDomainDailyLimitFor = '',
      outboundBCC,
      prospectsCreateEmailAddress = "",
      enableRestartInitialSeq = false,
      waitDaysForRestart = 1,
      // targetDomainDailyLimit
    } = campaignDetail;

    return {
      name,
      // autoRemoveEmailRepliedBy: ["domain", "location"].includes(autoRemoveEmailRepliedBy as string) ? autoRemoveEmailRepliedBy : null,
      autoRemoveEmailReplied,
      sendEmailOnHolidays,
      // startDate,
      startHour,
      endHour,
      removeProspectEmailLinkClick,
      removeProspectEmailOpen,
      removeProspectEmailReplied,
      days,
      startDate,
      createdAt,
      timezone,
      completedTime,
      enableQuickline,
      enableSalesforce,
      enableScrubby,
      salesforceMapping,
      dailyLimitPerTargetDomain,
      enableTargetDomainDailyLimit,
      targetDomainDailyLimitFor,
      outboundBCC,
      prospectsCreateEmailAddress,
      enableRestartInitialSeq,
      waitDaysForRestart
      // targetDomainDailyLimit,
    };
  }, [campaignDetail]);

  const onContinue = useCallback(
    async (newSettings: CampaignSettingsType) => {
      setLoadingMessage("Updating campaign settings ...");
      const { id } = campaignDetail;
      const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(id);
      const { inboxIds } = campaignDetail;
      const payload = {
        accountId: String(localStorage.getItem("account_id")),
        // inboxIds,
        ...newSettings
      };

      const response = await callApi({
        url,
        method,
        data: payload
      });

      if (response.success) {
        // const { url, method } = API_ENDPOINTS.CAMPAIGNS.getUpdateUrl(response.data.id);
        // await callApi({ url, method, data: { inboxIds }});
        afterSave();
      }
      setLoadingMessage("");
    },
    [campaignDetail, callApi, setLoadingMessage, afterSave]
  );

  const onSave = () => form.submit();
  return (
    <Div p={20} mt={10} bgColor={WHITE_COLOR} mh="calc(100vh - 180px)">
      <Div>
        <CampaignSettings
          campaignId={campaignDetail.id}
          form={form}
          afterSave={afterSave}

          onContinue={onContinue}
          settings={campaignSettings}
        />
      </Div>
      <Div align="left" mt="50">
        <Tooltip
          title={!isFree ? "" : ""}
        >
          <Button type="primary"
            // disabled={!isFree} 
            onClick={onSave}>
            Save
          </Button>
        </Tooltip>
      </Div>
    </Div>
  );
};

export default Settings;
